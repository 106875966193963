import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function StudentSatSurvey() {
  const currentstudent = JSON.parse(sessionStorage.getItem("studentDetails"));
  console.log("currentstudent", currentstudent);
  const [selectedDate, setSelectedDate] = useState(null);
  const handleChange = (date) => {
    setSelectedDate(date);
  };

  const [joinyear, setjoinyear] = useState(null);
  const handleChange1 = (date) => {
    setjoinyear(date);
  };

  const [name, setName] = useState("");
  const [Gender, setGender] = useState("male");
  const [Category, setCategory] = useState("");
  const [State, setState] = useState("");
  const [Country, setCountry] = useState("");
  const [Email, setEmail] = useState("");
  const [Program, setProgram] = useState("");
  const [EnrollmentId, setEnrollmentId] = useState("");
  const [mobile, setmobile] = useState("");

  const SSSFeedback = async () => {
    try {
      const config = {
        url: "student/addsssfeedback",
        method: "post",
        baseURL: "https://brightnaac.co.in/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          studentid: currentstudent?._id,
          name: name,
          gender: Gender,
          category: Category,
          state: State,
          country: Country,
          email: Email,
          program: Program,
          enrollmentid: EnrollmentId,
          mobile: mobile,
          joinyear: joinyear,
          reviewyear: selectedDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        window.location.assign("/studentsatsurveylist");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <div>
      <Container className="pt-4">
        <div>
          <div className="bgdesign">
            <h2>Student data Collection for NAAC PROCESS</h2>
            <p className="text">Dear Students,</p>
            <p className="text">
              Greetings from Govt. First Grade College, RANEBENNUR.
            </p>
            <p>
              You are hereby informed to fill the bellow required data as it is
              required for NAAC Process.
            </p>

            <p className="text">Thank you.</p>
            <p> IQAC Team </p>
            <hr />
            <div className="d-flex justify-content-between">
              <b>{currentstudent?.email} </b>
              <div>
                <span>Select Year : </span>
                <DatePicker
                  placeholderText="select year"
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleChange}
                  dateFormat="yyyy"
                  showYearPicker
                  maxDate={new Date()}
                />
              </div>
            </div>

            <hr />
            <p style={{ color: "red" }}>* Indicates required question</p>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  1. Name of the Student <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  style={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    2. Gender <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="male"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                      onChange={(e) => setGender(e.target.value)}
                    />

                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                      onChange={(e) => setGender(e.target.value)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormLabel id="demo-radio-buttons-group-label">
                  3. Category <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="category"
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <MenuItem value={"GM"}>GM</MenuItem>
                      <MenuItem value={"SC"}>SC</MenuItem>
                      <MenuItem value={"ST"}>ST</MenuItem>
                      <MenuItem value={"Cat-1"}>Cat-1</MenuItem>
                      <MenuItem value={"|| A"}>|| A</MenuItem>
                      <MenuItem value={"|| B"}>|| B</MenuItem>
                      <MenuItem value={"||| A"}>||| A</MenuItem>
                      <MenuItem value={"||| B"}>||| B</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  4. State of Domicile <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={age}
                      label="state"
                      onChange={(e) => setState(e.target.value)}
                    >
                      <MenuItem value={"Karnataka"}>Karnataka </MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  5. Nationality, if other than Indian{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={age}
                      label="Nationality"
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <MenuItem value={"India"}>India </MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  6. E-mail ID <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  style={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  7. Program Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setProgram(e.target.value)}
                    >
                      <MenuItem value={"BA"}>BA</MenuItem>
                      <MenuItem value={"BCom"}>BCom</MenuItem>
                      <MenuItem value={"BSc"}>BSc</MenuItem>
                      <MenuItem value={"BBA"}>BBA</MenuItem>
                      <MenuItem value={"MA (Economics)"}>
                        MA (Economics)
                      </MenuItem>
                      <MenuItem value={"MCom"}>MCom</MenuItem>
                      <MenuItem value={"Msc(Comp. Sci)"}>
                        Msc(Comp. Sci)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  8. Unique Enrollment ID (Exam Reg. Number){" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  style={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  onChange={(e) => setEnrollmentId(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  9. Mobile No. <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setmobile(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  10. Year of Joining <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    {/* <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel> */}
                    {/* <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      onChange={(e) => setjoinyear(e.target.value)}
                    >
                      <MenuItem value={"2019-20"}>2019-20</MenuItem>
                      <MenuItem value={"2018-19"}>2018-19</MenuItem>
                      <MenuItem value={"2017-18"}>2017-18</MenuItem>
                      <MenuItem value={"2016-17"}>2016-17</MenuItem>
                      <MenuItem value={"2015-16"}>2015-16</MenuItem>
                      <MenuItem value={"2014-15"}>2014-15</MenuItem>
                    </Select> */}
                    <DatePicker
                      placeholderText="joining year"
                      className="form-control"
                      selected={joinyear}
                      onChange={handleChange1}
                      dateFormat="yyyy"
                      showYearPicker
                      maxDate={new Date()}
                    />
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <Button variant="contained" className="mb-3" onClick={SSSFeedback}>
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default StudentSatSurvey;
