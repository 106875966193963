import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

function FacultyFeedbackAdminpanel() {
  const [FacultyFeed, setFacultyFeed] = useState([]);
  const getAllfacultyFeedback = async () => {
    try {
      const response = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/facultyfeedback"
      );
      if (response.status === 200) {
        setFacultyFeed(response.data.success);
      }
    } catch (error) {
      alert("no data present");
    }
  };
  useEffect(() => {
    getAllfacultyFeedback();
  }, []);
  console.log("AlumniFeed", FacultyFeed);
  return (
    <div className="add-gr">
      <div className="container">
        <h5 className="mt-4">Feedback from Students on Teachers</h5>
        <div
          className="ad-b mt-4"
          style={{
            display: "flex",
            gap: "21px",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <div className="do-sear mt-2 d-flex ">
            {/* <input
            type="text"
            placeholder="Search"
            className="vi_0"
            value={SearchData}
            onChange={(e) => setSearchData(e.target.value)}
          /> */}
          </div>
          {/* <div className="">
          <button
            style={{ backgroundColor: "#393186" }}
            onClick={() => navigate("/facultyfeedback")}
          >
            ADD Review
            <i
              class="fa-solid fa-plus fa-sm"
              style={{ color: "#ffffff" }}
            ></i>
          </button>
        </div> */}
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table
            responsive
            striped
            bordered
            hover
            style={{ textAlign: "center" }}
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Year</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Survey1 </th>
                <th>Survey2 </th>
                <th>Survey3 </th>
                <th>Survey4 </th>
                <th>Survey5 </th>
                <th>Survey6 </th>
                <th>Survey7 </th>
                <th>Survey8 </th>
                <th>Survey9 </th>
                <th>Survey10 </th>
                <th>Survey11 </th>
                <th>Survey12 </th>
                <th>suggestion </th>
              </tr>
            </thead>
            <tbody>
              {FacultyFeed?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{moment(item?.reviewyear).format("YYYY")}</td>
                    <td>{item?.name}</td>
                    <td>{item?.designation}</td>
                    <td>{item?.survey1}</td>
                    <td>{item?.survey2}</td>
                    <td>{item?.survey3}</td>
                    <td>{item?.survey4}</td>
                    <td>{item?.survey5}</td>
                    <td>{item?.survey6}</td>
                    <td>{item?.survey7}</td>
                    <td>{item?.survey8}</td>
                    <td>{item?.survey9}</td>
                    <td>{item?.survey10}</td>
                    <td>{item?.survey11}</td>
                    <td>{item?.survey12}</td>
                    <td>{item?.suggestion}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default FacultyFeedbackAdminpanel;
