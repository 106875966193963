import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Auto_Criteria004 = () => {
  let librarianDetails = JSON.parse(sessionStorage.getItem("Luser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //post method for Auto_criteria_04
  const [Auto_Year, setAuto_Year] = useState("");

  const [Auto_Room, setAuto_Room] = useState("");
  const [Auto_Type, setAuto_Type] = useState("");
  const [Auto_Desc1, setAuto_Desc1] = useState("");
  const [Auto_Link1, setAuto_Link1] = useState("");

  const [Auto_Budget, setAuto_Budget] = useState("");
  const [Auto_Expenditure, setAuto_Expenditure] = useState("");
  const [Auto_Total, setAuto_Total] = useState("");
  const [Auto_Expend1, setAuto_Expend1] = useState("");
  const [Auto_Expend2, setAuto_Expend2] = useState("");
  const [Auto_Desc2, setAuto_Desc2] = useState("");
  const [Auto_Link2, setAuto_Link2] = useState("");

  const [Auto_Library, setAuto_Library] = useState("");
  const [Auto_IfYes, setAuto_IfYes] = useState("");
  const [Auto_Expend3, setAuto_Expend3] = useState("");
  const [Auto_Total1, setAuto_Total1] = useState("");
  const [Auto_Desc3, setAuto_Desc3] = useState("");
  const [Auto_Link3, setAuto_Link3] = useState("");

  const [Auto_TNa, setAuto_TNa] = useState("");
  const [Auto_Develop, setAuto_Develop] = useState("");
  const [Auto_Platform, setAuto_Platform] = useState("");
  const [Auto_DateLaunch, setAuto_DateLaunch] = useState("");
  const [Auto_List, setAuto_List] = useState("");
  const [Auto_Desc4, setAuto_Desc4] = useState("");
  const [Auto_Link4, setAuto_Link4] = useState("");
  const [Auto_Link5, setAuto_Link5] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getautocriteria4 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));
      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getautocriteria4();
  }, []);

  const autocriteria4 = async () => {
    if (!Auto_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/LibraryAdmin/autoadddetails04",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Year: Auto_Year,

          Auto_Criteria04_413: obj1,
          Auto_Link1: Auto_Link1,

          Auto_Criteria04_414: obj2,
          Auto_Link2: Auto_Link2,

          Auto_Criteria04_422: obj3,
          Auto_Link3: Auto_Link3,

          Auto_Criteria04_434: obj4,
          Auto_Link4: Auto_Link4,
          Auto_Link5: Auto_Link5,

          addedByFac: librarianDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          navigate("/autocriteria04");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add1) {
        if (!Auto_Room || !Auto_Type || !Auto_Desc1 || !Auto_Link1) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Auto_Link1);
      let obj = {
        Auto_criteria: "4.1.3",
        Auto_Room: Auto_Room,
        Auto_Type: Auto_Type,
        Auto_Desc1: Auto_Desc1,
        Auto_Link1: Auto_Link1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getautocriteria4();
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add2) {
        if (
          !Auto_Budget ||
          !Auto_Expenditure ||
          !Auto_Total ||
          !Auto_Expend1 ||
          !Auto_Expend2 ||
          !Auto_Desc2 ||
          !Auto_Link2
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Auto_Link2);
      let obj = {
        Auto_criteria: "4.1.4 & 4.4.1",
        Auto_Budget: Auto_Budget,
        Auto_Expenditure: Auto_Expenditure,
        Auto_Total: Auto_Total,
        Auto_Expend1: Auto_Expend1,
        Auto_Expend2: Auto_Expend2,
        Auto_Desc2: Auto_Desc2,
        Auto_Link2: Auto_Link2,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getautocriteria4();
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add3) {
        if (
          !Auto_Library ||
          !Auto_IfYes ||
          !Auto_Expend3 ||
          !Auto_Total1 ||
          !Auto_Desc3 ||
          !Auto_Link3
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Auto_Link3);
      let obj = {
        Auto_criteria: "4.2.2 & 4.2.3",
        Auto_Library: Auto_Library,
        Auto_IfYes: Auto_IfYes,
        Auto_Expend3: Auto_Expend3,
        Auto_Total1: Auto_Total1,
        Auto_Desc3: Auto_Desc3,
        Auto_Link3: Auto_Link3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getautocriteria4();
    } catch (error) {
      console.log(error);
    }
  };

  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add4) {
        if (
          !Auto_TNa ||
          !Auto_Develop ||
          !Auto_Platform ||
          !Auto_DateLaunch ||
          !Auto_List ||
          !Auto_Desc4 ||
          !Auto_Link4 ||
          !Auto_Link5
        ) {
          return alert("Please fill all the details frontend");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("ppp,", Auto_Link4);
      console.log("qqq,", Auto_Link5);
      let obj = {
        Auto_criteria: "4.3.4",
        Auto_TNa: Auto_TNa,
        Auto_Develop: Auto_Develop,
        Auto_Platform: Auto_Platform,
        Auto_DateLaunch: Auto_DateLaunch,
        Auto_List: Auto_List,
        Auto_Desc4: Auto_Desc4,
        Auto_Link4: Auto_Link4,
        Auto_Link5: Auto_Link5,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));

      alert("Successfully added cat4");
      getautocriteria4();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    librarianDetails = JSON.parse(sessionStorage.getItem("Luser"));
    if (!librarianDetails) {
      alert("Please Login first");
      window.location.assign("/llogin");
    } else {
      getautocriteria4();
      getAllYears();
    }
  }, []);
  return (
    <div>
      <div className="mt-4" style={{ padding: "10px" }}>
        <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
          PostGraduate_Criteria-04
        </span>
      </div>

      <div className="container mt-4">
        <div
          className="row-fluid border bs"
          style={{
            padding: "30px",
          }}
        >
          <div class=" col-md-6">
            <label for="" class="form-label">
              Select Year
            </label>
            {obj1?.Auto_Year ? (
              <p>{obj1?.Auto_Year}</p>
            ) : (
              <select
                class="form-select form-select-sm vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setAuto_Year(e.target.value)}
              >
                <option selected>--Select Year--</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Auto_Criteria04-(4.1.3)</h5>

            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Room number or Name of classrooms/Seminar Hall with LCD /
                  wifi/LAN facilities with room numbers
                </label>
                {obj1?.Auto_Room ? (
                  <p>{obj1?.Auto_Room}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Room(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Type of ICT Facility
                </label>
                {obj1?.Auto_Type ? (
                  <p>{obj1?.Auto_Type}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Type(e.target.value)}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.Auto_Desc1 ? (
                  <p>{obj1?.Auto_Desc1}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc1(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the Relevant Document
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link1(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end mt-3">
              {add1 ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </Button>
              ) : (
                <>
                  {" "}
                  <Button className="btn btn-warning" onClick={handleShow}>
                    Preview
                  </Button>
                </>
              )}
            </div>
            <Modal
              size=""
              show={show}
              onHide={() => {
                handleClose();
                setedit1(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Auto_Criteria04_413</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Room number or Name of classrooms/Seminar Hall with LCD /
                      wifi/LAN facilities with room numbers
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Room}
                          onChange={(e) => setAuto_Room(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Room}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Type of ICT Facility
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Type}
                          onChange={(e) => setAuto_Type(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Type}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc1}
                          onChange={(e) => setAuto_Desc1(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload1"
                          accept="image/*"
                          onChange={(e) => setAuto_Link1(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link1.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit1 ? (
                  <>
                    <Button
                      className="btn btn-success"
                      onClick={() => {
                        cat1();
                        handleClose();
                        setedit1(false);
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit1(true)}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Auto_Criteria04-(4.1.4 & 4.4.1)</h5>
            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Budget allocated for infrastructure augmentation (INR in
                  Lakhs)
                </label>
                {obj2?.Auto_Budget ? (
                  <p>{obj2?.Auto_Budget}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Budget(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Expenditure for infrastructure augmentation (INR in Lakhs)
                </label>
                {obj2?.Auto_Expenditure ? (
                  <p>{obj2?.Auto_Expenditure}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Expenditure(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total expenditure excluding Salary (INR in Lakhs)
                </label>
                {obj2?.Auto_Total ? (
                  <p>{obj2?.Auto_Total}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Total(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Expenditure on maintenance of academic facilities (excluding
                  salary for human resources) (INR in Lakhs)
                </label>
                {obj2?.Auto_Expend1 ? (
                  <p>{obj2?.Auto_Expend1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Expend1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Expenditure on maintenance of physical facilities (excluding
                  salary for human resources) (INR in Lakh)
                </label>
                {obj2?.Auto_Expend2 ? (
                  <p>{obj2?.Auto_Expend2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Expend2(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj2?.Auto_Desc2 ? (
                <p>{obj2?.Auto_Desc2}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc2(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Upload the relevant document
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="upload2"
                id="upload2"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link2(e.target.files[0]);
                }}
              />
            </div>

            <div className="text-end mt-3">
              {add2 ? (
                <Button
                  type="submit"
                  className=" btn btn-success "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </Button>
              ) : (
                <>
                  {" "}
                  <Button className=" btn btn-warning " onClick={handleShow1}>
                    Preview
                  </Button>
                </>
              )}
            </div>
            <Modal
              size=""
              show={show1}
              onHide={() => {
                handleClose1();
                setedit2(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Auto_Criteria04_414 & 441</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Budget allocated for infrastructure Augmentation (INR in
                      Lakh)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Budget}
                          onChange={(e) => {
                            setAuto_Budget(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Budget}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Expenditure for infrastructure Augmentation (INR in Lakh)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Expenditure}
                          onChange={(e) => {
                            setAuto_Expenditure(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Expenditure}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total expenditure excluding Salary (INR in Lakh)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Total}
                          onChange={(e) => setAuto_Total(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Total}</>
                    )}
                  </div>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Expenditure on maintenance of academic facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Expend1}
                        onChange={(e) => setAuto_Expend1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Expend1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Expenditure on maintenance of physical facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Expend2}
                        onChange={(e) => setAuto_Expend2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Expend2}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Desc2}
                        onChange={(e) => setAuto_Desc2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Desc2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        onChange={(e) => setAuto_Link2(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Auto_Link2?.name}</>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit2 ? (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-success"
                      onClick={() => {
                        cat2();
                        handleClose1();
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <Button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit2(true)}
                  >
                    Edit
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Auto_Criteria04-(4.2.2 & 4.2.3)</h5>
            <h6>Average enrollement percentage(Average of last five years)</h6>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Library resources
                </label>

                {obj3?.Auto_Library ? (
                  <p>{obj3?.Auto_Library}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setAuto_Library(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="Books">Books</option>
                    <option value="Journals ">Journals </option>
                    <option value="e–journals ">e–journals </option>
                    <option value="e-books">e-books</option>
                    <option value="e-ShodhSindhu">e-ShodhSindhu</option>
                    <option value="Shodhganga">Shodhganga</option>
                    <option value="Databases">Databases</option>
                    <option value="Local_and_Remote_access_to_e-_resources_(Specify)">
                      Local_and_Remote_access_to_e-_resources_(Specify)
                    </option>
                  </select>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  If yes, details of memberships/subscriptions
                </label>
                {obj3?.Auto_IfYes ? (
                  <p>{obj3?.Auto_IfYes}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_IfYes(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Expenditure on subscription to e-journals, e-books (INR in
                  lakhs)
                </label>
                {obj3?.Auto_Expend3 ? (
                  <p>{obj3?.Auto_Expend3}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Expend3(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1"> Total Library Expenditure</label>
                {obj3?.Auto_Total1 ? (
                  <p>{obj3?.Auto_Total1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Total1(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj3?.Auto_Desc3 ? (
                  <p>{obj3?.Auto_Desc3}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc3(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload3"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link3(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="text-end mt-3 ">
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <Button onClick={handleShow2} className="btn btn-warning ">
                    Preview
                  </Button>
                </>
              )}
            </div>
            <Modal
              size=""
              show={show2}
              onHide={() => {
                handleClose2();
                setedit3(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Auto_Criteria04_422 & 423</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Library resources
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={Auto_Library}
                          onChange={(e) => setAuto_Library(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="Books">Books</option>
                          <option value="Journals ">Journals </option>
                          <option value="e – journals ">e – journals </option>
                          <option value="e-books">e-books</option>
                          <option value="e-ShodhSindhu">e-ShodhSindhu</option>
                          <option value="Shodhganga">Shodhganga</option>
                          <option value="Databases">Databases</option>
                          <option value="Local and / or Remote access to e- resources (Specify)">
                            e-ShodhSindhu
                          </option>
                        </select>
                      </>
                    ) : (
                      <>{Auto_Library}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      If yes, details of memberships/subscriptions
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_IfYes}
                          onChange={(e) => {
                            setAuto_IfYes(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_IfYes}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Expenditure on subscription to e-journals, e-books (INR in
                      lakhs)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Expend3}
                          onChange={(e) => {
                            setAuto_Expend3(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Expend3}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Total Library Expenditure
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Total1}
                          onChange={(e) => {
                            setAuto_Total1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Total1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc3}
                          onChange={(e) => setAuto_Desc3(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc3}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="upload3 "
                          onChange={(e) => setAuto_Link3(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link3?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit3 ? (
                  <>
                    {" "}
                    <Button
                      className="btn btn-success"
                      onClick={() => {
                        cat3();
                        handleClose2();
                        setedit3(false);
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit3(true)}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Auto_Criteria04-(4.3.4)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the teacher
                </label>
                <br />
                {obj4?.Auto_TNa ? (
                  <p>{obj4?.Auto_TNa}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_TNa(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the module developed
                </label>
                <br />
                {obj4?.Auto_Develop ? (
                  <p>{obj4?.Auto_Develop}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Develop(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label className="content1">
                  {" "}
                  Platform on which module is developed
                </label>
                <br />
                {obj4?.Auto_Platform ? (
                  <p>{obj4?.Auto_Platform}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Platform(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Date of launching e content</label>
                <br />
                {obj4?.Auto_DateLaunch ? (
                  <p>{obj4?.Auto_DateLaunch}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_DateLaunch(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1">
                  {" "}
                  List of the e-content development facility available
                </label>
                <br />
                {obj4?.Auto_List ? (
                  <p>{obj4?.Auto_List}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_List(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                <br />
                {obj4?.Auto_Desc4 ? (
                  <p>{obj4?.Auto_Desc4}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc4(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload4">
                  {" "}
                  Provide link to videos of the media centre and recording
                  facility
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload4"
                  id="upload4"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link4(e.target.files[0]);
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload5">
                  {" "}
                  Link to the relevant document and facility available in the
                  institution
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload5"
                  id="upload5"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link5(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="mt-3 text-end">
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success  "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button onClick={handleShow3} className="btn btn-warning">
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal
              size=""
              show={show3}
              onHide={() => {
                handleClose3();
                setedit4(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Auto_Criteria04_434</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_TNa}
                          onChange={(e) => {
                            setAuto_TNa(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_TNa}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the module developed
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Develop}
                          onChange={(e) => {
                            setAuto_Develop(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Develop}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Platform on which module is developed
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Platform}
                          onChange={(e) => {
                            Auto_Platform(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Platform}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Date of launching e content
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_DateLaunch}
                          onChange={(e) => {
                            setAuto_DateLaunch(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_DateLaunch}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      List of the e-content development facility available
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_List}
                          onChange={(e) => {
                            setAuto_List(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_List}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc4}
                          onChange={(e) => setAuto_Desc4(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc4}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document 1
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload4"
                          id="upload4 "
                          onChange={(e) => setAuto_Link4(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link4?.name}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document 2
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload5"
                          id="upload5 "
                          onChange={(e) => setAuto_Link5(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link5?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit4 ? (
                  <>
                    {" "}
                    <Button
                      className="btn btn-success"
                      onClick={() => {
                        cat4();
                        handleClose3();
                        setedit4(false);
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit4(true)}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <Button
            type="submit"
            className="btn btn-success"
            onClick={() => autocriteria4()}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Auto_Criteria004;
