import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Information = () => {
  // =================== calling api for getting the aboutus content======================
  const [Content, setContent] = useState([]);
  const getContent = async () => {
    let res = await axios.get("https://brightnaac.co.in/api/admin/getSeminar");
    if (res.status === 200) {
      setContent(res.data.allContent);
    }
  };
  useEffect(() => {
    getContent();
    window.scrollTo(0, 0);
  }, []);
  console.log("contact", Content);

  return (
    <div>
      <div className="about_us">
        <div
          className="ab-0"
          style={{
            backgroundImage: "url('../Image/about-us.jpg')",
            height: "250px",
            position: "center",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="edg container">
            <div className="edgt-has-inline-style">Information & Institute</div>
            <div class="edgt-breadcrumbs-info">
              <div
                itemProp="breadcrumb"
                classname="edgt-breadcrumbs "
                style={{ color: "#e1e0e0" }}
              >
                <Link
                  itemProp="url"
                  to="/"
                  style={{ color: "#e1e0e0", textDecoration: "none" }}
                >
                  Home
                </Link>
                <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
                <span classname="edgt-current">Information & Institute</span>
              </div>
            </div>
          </div>
        </div>

        <section>
          <Container>
            <div className="content">
              <h4 className="text-center"> Sponsered Seminars</h4>
              <br />
              <img
                className="d-block w-100 "
                src={`https://brightnaac.co.in/seminar/${Content[0]?.image}`}
                alt=""
                style={{ height: "350px" }}
              />
              <br />
              <p className="educat">{Content[0]?.text}</p>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default Information;
