import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { BiSolidReport } from "react-icons/bi";
import { BiSolidDashboard } from "react-icons/bi";
import { FaGraduationCap } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { SiGoogleforms } from "react-icons/si";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "../LibraryPanel/admin1.css";
function LibrarySideBar() {
  let libraryDetails = JSON.parse(sessionStorage.getItem("Luser"));
  useEffect(() => {
    libraryDetails = JSON.parse(sessionStorage.getItem("Luser"));
    if (!libraryDetails) {
      alert("Please login first");
      window.location.assign("/llogin");
    }
  }, []);

  return (
    <div className="left-side">
      <Sidebar
        // collapsed={collapsed}
        // breakPoint={breakpoint? "sm" : ""}
        collapsedWidth="4.5rem"
        backgroundColor="#2A2A72"
        style={{
          position: "sticky",
          top: "auto",
          padding: "0rem",
          margin: "0rem",
          height: "100vh",
        }}
      >
        <Menu
          transitionDuration={900}
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: disabled ? "blue" : "white",
                  backgroundColor: active ? "red" : undefined,
                };
            },
          }}
          rootStyles={{
            color: "#344cff",
          }}
        >
          <div className="aaa">Library Admin</div>

          <hr style={{ color: "white" }} />

          <MenuItem
            component={<Link to="/librarydashboard" />}
            icon={<BiSolidDashboard />}
          >
            Dashboard
          </MenuItem>
          <div>
            {libraryDetails?.HEI === "PG" ? (
              <>
                <SubMenu label="PostGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/pg-Criteria1" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pg-Criteria2" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pg-Criteria3" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pg-Criteria4" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pg-Criteria5" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pg-Criteria6" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria6
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pg-Criteria7" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {libraryDetails?.HEI === "UG" ? (
              <>
                <SubMenu label="UnderGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/ug-Criteria4" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {libraryDetails?.facultyAddedBy?.HEI === "University" ? (
              <>
                <SubMenu label="University" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/unicriteria04" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {libraryDetails?.facultyAddedBy?.HEI === "Autonomous" ? (
              <>
                <SubMenu label="Autonomous" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/autocriteria04" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {libraryDetails?.facultyAddedBy?.HEI === "UG&PG" ? (
              <>
                <SubMenu label="UnderGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/ugcriteria04" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                </SubMenu>

                <SubMenu label="PostGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/pgcriteria04" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>
        </Menu>
      </Sidebar>
    </div>
  );
}

export default LibrarySideBar;
