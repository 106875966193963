import React from "react";

const UG3312aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the Principal Investigator/Co-Investigator</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria03_312?.ProName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the Project/ Endowments, Chairs</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_312?.PrinciName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Department of Principal Investigator</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_312?.PrinciDept}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of Award</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_312?.YOfAw}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Amount Sanctioned</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_312?.ASanctioned}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Duration of the project</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_312?.DProject}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the Funding Agency</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_312?.NFunding}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Type (Government/non-Government)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_312?.Type}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria03_312?.Desc12}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria03/${item?.Criteria03_312?.Link12}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG3312aqar;
