import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";

const Seminar = () => {
  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
  const [View, setView] = useState({});

  // ================ add about us details modal===============================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================ delete about us details modal===============================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================ edit about us details modal===============================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [Image, setImage] = useState("");
  const [Text, setText] = useState("");

  const AddContent = async (e) => {
    e.preventDefault();
    if (!Image || !Text) {
      alert("Please fill all fields");
    }

    try {
      const config = {
        url: "/admin/seminaradd",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: {
          image: Image,
          text: Text,
        },
      };
      await axios(config).then((res) => {
        alert("Added Successfully");
        setImage("");
        setText("");
        getContent();
        handleClose();
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the aboutus content======================
  const [Content, setContent] = useState([]);
  const getContent = async () => {
    let res = await axios.get("https://brightnaac.co.in/api/admin/getSeminar");
    if (res.status === 200) {
      setContent(res.data.allContent);
    }
  };

  // =================== calling api for deleting the aboutus content======================
  const deleteContent = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/deleteSeminar/" + id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          alert("Added Successfully");
          setImage("");
          setText("");
          getContent();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };

  // =================== calling api for editing the aboutus content======================
  const editContent = async (e, id) => {
    e.preventDefault();
    if (!Image && !Text) {
      alert("Please provide at least one field");
    }

    try {
      const config = {
        url: "/admin/editSeminar/" + id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: {
          image: Image,
          text: Text,
        },
      };
      await axios(config).then((res) => {
        alert(res.data.success);
        setImage("");
        setText("");
        getContent();
        handleClose2();
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };
  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getContent();
  }, []);
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 pt-5 align-items-center ">
          <div className=" col-lg-6 ">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              Seminar
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            {Content?.length > 0 ? (
              <></>
            ) : (
              <Button onClick={handleShow}>Add</Button>
            )}
          </div>
        </div>
        <br />
        <div className="row m-0">
          <Table responsive border striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "25px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Image</th>
                <th>Text</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Content?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>
                      <AiOutlineEye
                        onClick={() => {
                          setView(item);
                          handleShow8();
                        }}
                        fontSize={20}
                      />
                    </td>
                    <td>
                      {item?.text?.slice(0, 70)}
                      ...
                      <Link
                        onClick={() => {
                          handleShow7();
                          setView(item);
                        }}
                      >
                        Read More...
                      </Link>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-evenly">
                        <div className="">
                          <span>
                            <BiEdit
                              size={20}
                              style={{ color: "blue", margin: "10px" }}
                              onClick={() => {
                                setView(item);
                                handleShow2();
                              }}
                            />
                          </span>
                        </div>
                        <div className="">
                          <span
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            style={{
                              cursor: "pointer",
                              color: "red",
                              margin: "10px",
                            }}
                          >
                            <AiFillDelete size={20} />
                          </span>
                        </div>
                      </div>
                    </td>{" "}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* show 8 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/seminar/${View?.image}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Show Body */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Body</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.text}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit about us details modal */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>
                Upload Image <span className="font-css top">*</span>
              </label>
              <br />
              <div className="mt-2">
                <input
                  type="file"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="mt-2">
              <label>
                Text <span className="font-css top">*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.text}
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(e) => editContent(e, View?._id)}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* add about us details modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Seminar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label htmlFor="upload1">
                Upload Image <span className="font-css top">*</span>
              </label>
              <br />
              <div className="mt-2">
                <input
                  type="file"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="mt-2">
              <label>
                Text <span className="font-css top">*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddContent(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete about us details modal */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteContent(e, View?._id)}>
                Delete
              </Button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Seminar;
