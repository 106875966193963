import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import moment from "moment/moment";
import { AiOutlineEye } from "react-icons/ai";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

const PostGraduateCri2 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const pgCriteria02Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcri02details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria02);
    }
  };
  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatingpg2`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          PG_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria02Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      pgCriteria02Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="mt-4"> PG Criteria2 </h4>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="mt-4">Current Rating: </h6>
                <div className="mt-4">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.PG_Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center">
            <div className="ad-b mt-4 row align-items-center">
              <div className=" col-lg-4 do-sear mt-4 d-flex ">
                <input
                  type="text"
                  placeholder="Search"
                  className="vi_0"
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {/* <button>Search</button> */}
              </div>

              <div className=" col-lg-2 lab">
                <label>From</label>
                <br />
                <DatePicker
                  onChange={onChangeFrom}
                  picker="year"
                  className="vi_0"
                />
              </div>
              <div className=" col-lg-2 lab">
                <label>To</label>
                <br />
                <DatePicker
                  onChange={onChangeTo}
                  picker="year"
                  className="vi_0"
                />
              </div>

              {/* <div className=" col-lg-2 lab mt-4">
              <Button>Submit</Button>
            </div> */}
              {/* <div className=" col-lg-2 text-end mt-4">
              <Button>Export Excel</Button>
            </div> */}
            </div>
            <div className="row">
              <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
                <label style={{ width: "130px" }}>Select Year</label>
                <select
                  className="criteriaform "
                  style={{ width: "150px" }}
                  onChange={(e) => setselectedYear(e.target.value)}
                >
                  {allYears
                    ?.sort((a, b) => {
                      return Number(b?.from) - Number(a?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg-5">
                <Nav defaultActiveKey="/pgcriteria02">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-1"
                      onClick={() =>
                        navigate(`/adminclgpgcri2211/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">2.1.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-2"
                      onClick={() =>
                        navigate(`/adminclgpgcri2212/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">2.1.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri2241/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">2.4.1 & 2.4.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri2242/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">2.4.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri2263/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">2.6.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri2271/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">2.7.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              {/* <div className="col-lg-2">
                <Button
                  type="Submit"
                  className="btn btn-warning"
                  style={{ color: "white" }}
                  onClick={() => {
                    // setValue(item);
                    handleShow();
                  }}
                >
                  Add Rating
                </Button>
              </div> */}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 w-100"></div>
        </div>
        <div className="container">
          <div className="mo-gra mt-2">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 1 */}
                  <th>Criteria</th>
                  <th>Programme name</th>
                  <th>Programme code</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of Students admitted</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 2 */}
                  <th>Criteria</th>
                  <th>
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </th>
                  <th>
                    Number of students admitted from the reserved category
                  </th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 2.4.1 & 2.4.3 */}
                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </th>
                  <th>Name of the Department</th>
                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 2.4.2 */}
                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit
                  </th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th>Name of the scholar</th>
                  <th>Year of registration of the scholar</th>
                  <th>Title of the thesis of the for scholar</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* (2.6.3) */}
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* (2.7.1) */}
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if othern than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.PG_Year &&
                    (SearchItem === "" ||
                      item1?.PG_Criteria02_211?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_211?.PG_PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_211?.PG_PrgCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_211?.PG_NoSeatSanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_211?.PG_NoStdAdmit?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_211?.PG_Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_212?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_SC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_ST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_OBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_Gen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_Divyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_Others?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_SSC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_SST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_OOBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_GGen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_DDivyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_OOthers?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_241?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_TName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_241?.PG_PAN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_Designation?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_241?.PG_YOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_NoOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_DeptName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_241?.PG_TYofExp?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_TService?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_242?.PG_SerTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_YOfQuali?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_242?.PG_Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_YOfRecog?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_242?.PG_IsThereTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_NameScholar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_YearScholar?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_242?.PG_Thesis?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_Desc9?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_263?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_263?.PG_PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_263?.PG_pCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_263?.PG_NStdAppeared?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_263?.PG_NStdPassed?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_263?.PG_Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_271?.PG_PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_NStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_Gender?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_SDomicile?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_NIndian?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_Email?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_MNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_271?.PG_YJoining?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_271?.PG_UID?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_271?.PG_Desc11?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.PG_Year}</td>

                      <td>{item?.PG_Criteria02_211?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_211?.PG_PrgName1}</td>
                      <td>{item?.PG_Criteria02_211?.PG_PrgCode}</td>
                      <td>{item?.PG_Criteria02_211?.PG_NoSeatSanctioned}</td>
                      <td>{item?.PG_Criteria02_211?.PG_NoStdAdmit}</td>
                      <td>{item?.PG_Criteria02_211?.PG_Desc6}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 212 */}
                      <td>{item?.PG_Criteria02_212?.PG_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.PG_Criteria02_212?.PG_SC}</td>
                              <td>{item?.PG_Criteria02_212?.PG_ST}</td>
                              <td>{item?.PG_Criteria02_212?.PG_OBC}</td>
                              <td>{item?.PG_Criteria02_212?.PG_Gen}</td>
                              <td>{item?.PG_Criteria02_212?.PG_Divyangjan}</td>
                              <td>{item?.PG_Criteria02_212?.PG_Others}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.PG_Criteria02_212?.PG_SSC}</td>
                              <td>{item?.PG_Criteria02_212?.PG_SST}</td>
                              <td>{item?.PG_Criteria02_212?.PG_OOBC}</td>
                              <td>{item?.PG_Criteria02_212?.PG_GGen}</td>
                              <td>{item?.PG_Criteria02_212?.PG_DDivyangjan}</td>
                              <td>{item?.PG_Criteria02_212?.PG_OOthers}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.PG_Criteria02_212?.PG_Desc7}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 241 */}
                      <td>{item?.PG_Criteria02_241?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_241?.PG_TName}</td>
                      <td>{item?.PG_Criteria02_241?.PG_PAN}</td>
                      <td>{item?.PG_Criteria02_241?.PG_Designation}</td>
                      <td>{item?.PG_Criteria02_241?.PG_YOfAppoint}</td>
                      <td>{item?.PG_Criteria02_241?.PG_NoOfAppoint}</td>
                      <td>{item?.PG_Criteria02_241?.PG_DeptName}</td>
                      <td>{item?.PG_Criteria02_241?.PG_TYofExp}</td>
                      <td>{item?.PG_Criteria02_241?.PG_TService}</td>
                      <td>{item?.PG_Criteria02_241?.PG_Desc8}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 242 */}
                      <td>{item?.PG_Criteria02_242?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_242?.PG_SerTeacher}</td>
                      <td>{item?.PG_Criteria02_242?.PG_Qualify}</td>
                      <td>{item?.PG_Criteria02_242?.PG_YOfQuali}</td>
                      <td>{item?.PG_Criteria02_242?.PG_Whether}</td>
                      <td>{item?.PG_Criteria02_242?.PG_YOfRecog}</td>
                      <td>{item?.PG_Criteria02_242?.PG_IsThereTeacher}</td>
                      <td>{item?.PG_Criteria02_242?.PG_NameScholar}</td>
                      <td>{item?.PG_Criteria02_242?.PG_YearScholar}</td>
                      <td>{item?.PG_Criteria02_242?.PG_Thesis}</td>
                      <td>{item?.PG_Criteria02_242?.PG_Desc9}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 263 */}
                      <td>{item?.PG_Criteria02_263?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_263?.PG_PrgName2}</td>
                      <td>{item?.PG_Criteria02_263?.PG_pCode}</td>
                      <td>{item?.PG_Criteria02_263?.PG_NStdAppeared}</td>
                      <td>{item?.PG_Criteria02_263?.PG_NStdPassed}</td>
                      <td>{item?.PG_Criteria02_263?.PG_Desc10}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 271 */}
                      <td>{item?.PG_Criteria02_271?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_271?.PG_PrgName3}</td>
                      <td>{item?.PG_Criteria02_271?.PG_NStd}</td>
                      <td>{item?.PG_Criteria02_271?.PG_Gender}</td>
                      <td>{item?.PG_Criteria02_271?.PG_Category}</td>
                      <td>{item?.PG_Criteria02_271?.PG_SDomicile}</td>
                      <td>{item?.PG_Criteria02_271?.PG_NIndian}</td>
                      <td>{item?.PG_Criteria02_271?.PG_Email}</td>
                      <td>{item?.PG_Criteria02_271?.PG_MNo}</td>
                      <td>{item?.PG_Criteria02_271?.PG_YJoining}</td>
                      <td>{item?.PG_Criteria02_271?.PG_UID}</td>
                      <td>{item?.PG_Criteria02_271?.PG_Desc11}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
              ,
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_211?.PG_Link6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_212?.PG_Link7}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_241?.PG_Link8}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_242?.PG_Link9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_263?.PG_Link10}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_271?.PG_Link11}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PostGraduateCri2;
