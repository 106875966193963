import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Uni_Criteria002 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);
  const [edit8, setedit8] = useState(false);
  const [add8, setadd8] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  //post method for uni_criteria_02
  const [Uni_Year, setUni_Year] = useState("");

  const [Uni_PrgCode, setUni_PrgCode] = useState("");
  const [Uni_NoSeatSanctioned, setUni_NoSeatSanctioned] = useState("");
  const [
    Uni_eligible_applications_received,
    setUni_eligible_applications_received,
  ] = useState("");
  const [Uni_NoStdAdmit, setUni_NoStdAdmit] = useState("");
  const [Uni_PrgName1, setUni_PrgName1] = useState("");
  const [Uni_Desc32, setUni_Desc32] = useState("");
  const [Uni_Link32, setUni_Link32] = useState("");

  const [Uni_SC, setUni_SC] = useState("");
  const [Uni_ST, setUni_ST] = useState("");
  const [Uni_OBC, setUni_OBC] = useState("");
  const [Uni_Gen, setUni_Gen] = useState("");
  const [Uni_Divyangjan, setUni_Divyangjan] = useState("");
  const [Uni_Others, setUni_Others] = useState("");
  const [Uni_SSC, setUni_SSC] = useState("");
  const [Uni_SST, setUni_SST] = useState("");
  const [Uni_OOBC, setUni_OOBC] = useState("");
  const [Uni_GGen, setUni_GGen] = useState("");
  const [Uni_DDivyangjan, setUni_DDivyangjan] = useState("");
  const [Uni_OOthers, setUni_OOthers] = useState("");
  const [Uni_Desc33, setUni_Desc33] = useState("");
  const [Uni_Link33, setUni_Link33] = useState("");

  const [Uni_TName, setUni_TName] = useState("");
  const [Uni_PAN, setUni_PAN] = useState("");
  const [Uni_Designation, setUni_Designation] = useState("");
  const [Uni_YOfAppoint, setUni_YOfAppoint] = useState("");
  const [Uni_NoOfAppoint, setUni_NoOfAppoint] = useState("");
  const [Uni_DeptName, setUni_DeptName] = useState("");
  const [Uni_TYofExp, setUni_TYofExp] = useState("");
  const [Uni_TService, setUni_TService] = useState("");
  const [Uni_Desc34, setUni_Desc34] = useState("");
  const [Uni_Link34, setUni_Link34] = useState("");

  const [Uni_SerTeacher, setUni_SerTeacher] = useState("");
  const [Uni_Qualify, setUni_Qualify] = useState("");
  const [Uni_YOfQuali, setUni_YOfQuali] = useState("");
  const [Uni_Desc35, setUni_Desc35] = useState("");
  const [Uni_Link35, setUni_Link35] = useState("");

  const [Uni_fullTeacherName, setUni_fullTeacherName] = useState("");
  const [Uni_YrAward, setUni_YrAward] = useState("");
  const [Uni_PAN1, setUni_PAN1] = useState("");
  const [Uni_Desi, setUni_Desi] = useState("");
  const [Uni_AwardAgency, setUni_AwardAgency] = useState("");
  const [Uni_AwardName, setUni_AwardName] = useState("");
  const [Uni_Incentives, setUni_Incentives] = useState("");
  const [Uni_Desc36, setUni_Desc36] = useState("");
  const [Uni_Link36, setUni_Link36] = useState("");

  const [Uni_pname1, setUni_pname1] = useState("");
  const [Uni_pcode1, setUni_pcode1] = useState("");
  const [Uni_semester, setUni_semester] = useState("");
  const [Uni_last, setUni_last] = useState("");
  const [Uni_Date, setUni_Date] = useState("");
  const [Uni_Desc37, setUni_Desc37] = useState("");
  const [Uni_Link37, setUni_Link37] = useState("");

  const [Uni_pCode, setUni_pCode] = useState("");
  const [Uni_PrgName2, setUni_PrgName2] = useState("");
  const [Uni_NStdAppeared, setUni_NStdAppeared] = useState("");
  const [Uni_NStdPassed, setUni_NStdPassed] = useState("");
  const [Uni_Desc38, setUni_Desc38] = useState("");
  const [Uni_Link38, setUni_Link38] = useState("");

  const [Uni_PrgName3, setUni_PrgName3] = useState("");
  const [Uni_NStd, setUni_NStd] = useState("");
  const [Uni_Gender, setUni_Gender] = useState("");
  const [Uni_Category, setUni_Category] = useState("");
  const [Uni_SDomicile, setUni_SDomicile] = useState("");
  const [Uni_NIndian, setUni_NIndian] = useState("");
  const [Uni_Email, setUni_Email] = useState("");
  const [Uni_MNo, setUni_MNo] = useState("");
  const [Uni_YJoining, setUni_YJoining] = useState("");
  const [Uni_UID, setUni_UID] = useState("");
  const [Uni_Desc39, setUni_Desc39] = useState("");
  const [Uni_Link39, setUni_Link39] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getUnicriteria2 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));
      let am8 = sessionStorage.getItem("cat8");
      setobj8(JSON.parse(am8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUnicriteria2();
  }, []);

  const Unicriteria2 = async () => {
    if (!Uni_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/Unicriteria02",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Year: Uni_Year,

          Uni_Criteria02_211: obj1,
          Uni_Link32: Uni_Link32,

          Uni_Criteria02_212: obj2,
          Uni_Link33: Uni_Link33,

          Uni_Criteria02_241_243: obj3,
          Uni_Link34: Uni_Link34,

          Uni_Criteria02_242: obj4,
          Uni_Link35: Uni_Link35,

          Uni_criteria02_244: obj5,
          Uni_Link36: Uni_Link36,

          Uni_Criteria02_263: obj7,
          Uni_Link38: Uni_Link38,

          Uni_Criteria02_271: obj8,
          Uni_Link39: Uni_Link39,

          Uni_Criteria02_251: obj6,
          Uni_Link37: Uni_Link37,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          sessionStorage.removeItem("cat8");

          navigate("/unicriteria02");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc32;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Uni_PrgName1 ||
          !Uni_PrgCode ||
          !Uni_NoSeatSanctioned ||
          !Uni_eligible_applications_received ||
          !Uni_NoStdAdmit ||
          !Uni_Desc32 ||
          !Uni_Link32
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }
      console.log("nnnnnnnn,", Uni_Link32);
      let obj = {
        Uni_criteria: "2.1.1",
        Uni_PrgName1: Uni_PrgName1,
        Uni_PrgCode: Uni_PrgCode,
        Uni_NoSeatSanctioned: Uni_NoSeatSanctioned,
        Uni_eligible_applications_received: Uni_eligible_applications_received,
        Uni_NoStdAdmit: Uni_NoStdAdmit,
        Uni_Desc32: Uni_Desc32,
        Uni_Link32: Uni_Link32,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getUnicriteria2();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc33;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !Uni_SC ||
          !Uni_SC ||
          !Uni_ST ||
          !Uni_OBC ||
          !Uni_Gen ||
          !Uni_Divyangjan ||
          !Uni_Others ||
          !Uni_SSC ||
          !Uni_SSC ||
          !Uni_ST ||
          !Uni_OOBC ||
          !Uni_GGen ||
          !Uni_DDivyangjan ||
          !Uni_OOthers ||
          !Uni_Desc33 ||
          !Uni_Link33
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Uni_Link33);
      let obj = {
        Uni_criteria: "2.1.2",
        Uni_SC: Uni_SC,
        Uni_ST: Uni_ST,
        Uni_OBC: Uni_OBC,
        Uni_Gen: Uni_Gen,
        Uni_Divyangjan: Uni_Divyangjan,
        Uni_Others: Uni_Others,
        Uni_SSC: Uni_SSC,
        Uni_SST: Uni_SST,
        Uni_OOBC: Uni_OOBC,
        Uni_GGen: Uni_GGen,
        Uni_DDivyangjan: Uni_DDivyangjan,
        Uni_OOthers: Uni_OOthers,
        Uni_Desc33: Uni_Desc33,
        Uni_Link33: Uni_Link33,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getUnicriteria2();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc34;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !Uni_TName ||
          !Uni_PAN ||
          !Uni_Designation ||
          !Uni_YOfAppoint ||
          !Uni_NoOfAppoint ||
          !Uni_DeptName ||
          !Uni_TYofExp ||
          !Uni_TService ||
          !Uni_Desc34 ||
          !Uni_Link34
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Uni_Link34);
      let obj = {
        Uni_criteria: "2.4.1 & 2.4.3",
        Uni_TName: Uni_TName,
        Uni_PAN: Uni_PAN,
        Uni_Designation: Uni_Designation,
        Uni_YOfAppoint: Uni_YOfAppoint,
        Uni_NoOfAppoint: Uni_NoOfAppoint,
        Uni_DeptName: Uni_DeptName,
        Uni_TYofExp: Uni_TYofExp,
        Uni_TService: Uni_TService,
        Uni_Desc34: Uni_Desc34,
        Uni_Link34: Uni_Link34,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getUnicriteria2();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc35;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Uni_SerTeacher ||
          !Uni_Qualify ||
          !Uni_YOfQuali ||
          !Uni_Desc35 ||
          !Uni_Link35
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Uni_Link35);
      let obj = {
        Uni_criteria: "2.4.2",
        Uni_SerTeacher: Uni_SerTeacher,
        Uni_Qualify: Uni_Qualify,
        Uni_YOfQuali: Uni_YOfQuali,
        Uni_Desc35: Uni_Desc35,
        Uni_Link35: Uni_Link35,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getUnicriteria2();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc36;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !Uni_fullTeacherName ||
          !Uni_YrAward ||
          !Uni_PAN1 ||
          !Uni_Desi ||
          !Uni_AwardAgency ||
          !Uni_AwardName ||
          !Uni_Incentives ||
          !Uni_Desc36 ||
          !Uni_Link36
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("sss,", Uni_Link36);
      let obj = {
        Uni_criteria: "2.4.4 & 3.4.2",
        Uni_fullTeacherName: Uni_fullTeacherName,
        Uni_YrAward: Uni_YrAward,
        Uni_PAN1: Uni_PAN1,
        Uni_Desi: Uni_Desi,
        Uni_AwardAgency: Uni_AwardAgency,
        Uni_AwardName: Uni_AwardName,
        Uni_Incentives: Uni_Incentives,
        Uni_Desc36: Uni_Desc36,
        Uni_Link36: Uni_Link36,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getUnicriteria2();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc37;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !Uni_pname1 ||
          !Uni_pcode1 ||
          !Uni_semester ||
          !Uni_last ||
          !Uni_Date ||
          !Uni_Desc37 ||
          !Uni_Link37
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("sss,", Uni_Link37);
      let obj = {
        Uni_criteria: "2.5.1",
        Uni_pname1: Uni_pname1,
        Uni_pcode1: Uni_pcode1,
        Uni_semester: Uni_semester,
        Uni_last: Uni_last,
        Uni_Date: Uni_Date,
        Uni_Desc37: Uni_Desc37,
        Uni_Link37: Uni_Link37,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getUnicriteria2();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc38;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !Uni_pCode ||
          !Uni_PrgName2 ||
          !Uni_NStdAppeared ||
          !Uni_NStdPassed ||
          !Uni_Desc38 ||
          !Uni_Link38
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd7(false);
        }
      }

      console.log("qqqq,", Uni_Link38);
      let obj = {
        Uni_criteria: "2.6.3",
        Uni_pCode: Uni_pCode,
        Uni_PrgName2: Uni_PrgName2,
        Uni_NStdAppeared: Uni_NStdAppeared,
        Uni_NStdPassed: Uni_NStdPassed,
        Uni_Desc38: Uni_Desc38,
        Uni_Link38: Uni_Link38,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getUnicriteria2();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat8 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc39;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add8) {
        if (
          !Uni_PrgName3 ||
          !Uni_NStd ||
          !Uni_Gender ||
          !Uni_Category ||
          !Uni_SDomicile ||
          !Uni_NIndian ||
          !Uni_Email ||
          !Uni_MNo ||
          !Uni_YJoining ||
          !Uni_UID ||
          !Uni_Desc39 ||
          !Uni_Link39
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd8(false);
        }
      }

      console.log("rrr,", Uni_Link39);
      let obj = {
        Uni_criteria: "2.7.1",
        Uni_PrgName3: Uni_PrgName3,
        Uni_NStd: Uni_NStd,
        Uni_Gender: Uni_Gender,
        Uni_Category: Uni_Category,
        Uni_SDomicile: Uni_SDomicile,
        Uni_NIndian: Uni_NIndian,
        Uni_Email: Uni_Email,
        Uni_MNo: Uni_MNo,
        Uni_YJoining: Uni_YJoining,
        Uni_UID: Uni_UID,
        Uni_Desc39: Uni_Desc39,
        Uni_Link39: Uni_Link39,
      };
      sessionStorage.setItem("cat8", JSON.stringify(obj));
      alert("Successfully added cat8");
      getUnicriteria2();
      setedit8(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div>
      <h2 className="text-center mt-5" style={{ fontWeight: "700" }}>
        University Criteria 02 Form
      </h2>
      <h6 className="text-center">(Please fill all the details)</h6>
      <br />

      <div className="container mt-3">
        <div
          className="row-fluid border mb-3"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
          <div className="row-fluid">
            <div
              class=" col-md-6-fluid border mb-3"
              style={{
                padding: "30px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Uni_Year ? (
                <p>{obj1?.Uni_Year}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setUni_Year(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_2 (2.1.1)</h5>
              <h6>Average enrolement percentage(Average of last five years)</h6>
              <div className="row">
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Programme name
                    </label>
                    {obj1?.Uni_PrgName1 ? (
                      <p>{obj1?.Uni_PrgName1}</p>
                    ) : (
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        onChange={(e) => setUni_PrgName1(e.target.value)}
                      >
                        <option selected>--select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BBA">BBA</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Programme code
                    </label>
                    {obj1?.Uni_PrgCode ? (
                      <p>{obj1?.Uni_PrgCode}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_PrgCode(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of seats sanctioned
                    </label>
                    {obj1?.Uni_NoSeatSanctioned ? (
                      <p>{obj1?.Uni_NoSeatSanctioned}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_NoSeatSanctioned(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of eligible applications received
                    </label>
                    {obj1?.Uni_eligible_applications_received ? (
                      <p>{obj1?.Uni_eligible_applications_received}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_eligible_applications_received(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of Students admitted
                    </label>
                    {obj1?.Uni_NoStdAdmit ? (
                      <p>{obj1?.Uni_NoStdAdmit}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_NoStdAdmit(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description (min 500 words)
                    </label>
                    {obj1?.Uni_Desc32 ? (
                      <p>{obj1?.Uni_Desc32}</p>
                    ) : (
                      <textarea
                        className=" forminput"
                        id="name"
                        // cols={57}
                        rows={3}
                        onChange={(e) => {
                          setUni_Desc32(e.target.value);
                        }}
                      ></textarea>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    <input
                      className="name"
                      type="file"
                      name="upload1"
                      id="upload1"
                      onChange={(e) => {
                        setUni_Link32(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add1 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat1();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_2 (2.1.1)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="row">
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label className="content1" htmlFor="name">
                            {" "}
                            Programme name
                          </label>
                          <br />
                          {edit1 ? (
                            <>
                              <select
                                class="form-select form-select-sm"
                                aria-label="Default select example"
                                style={{ width: "100%" }}
                                placeholder={Uni_PrgName1}
                                onChange={(e) => {
                                  setUni_PrgName1(e.target.value);
                                }}
                              >
                                <option selected>--Select--</option>
                                <option value="BCA">BCA</option>
                                <option value="BBA">BBA</option>
                                <option value="BBA">BBA</option>
                                <option value="B.Com">B.Com</option>
                              </select>
                            </>
                          ) : (
                            <>{Uni_PrgName1}</>
                          )}
                        </div>
                        <div className="col-md-6 mt-3">
                          <label className="content1" htmlFor="name">
                            {" "}
                            Programme code
                          </label>
                          <br />
                          {edit1 ? (
                            <>
                              <input
                                className=" forminput"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_PrgCode}
                                onChange={(e) => {
                                  setUni_PrgCode(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_PrgCode}</>
                          )}
                        </div>
                        <div className="col-md-6 mt-3">
                          <label className="content1" htmlFor="name">
                            {" "}
                            Number of seats sanctioned
                          </label>
                          <br />
                          {edit1 ? (
                            <>
                              <input
                                className=" forminput"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_NoSeatSanctioned}
                                onChange={(e) => {
                                  setUni_NoSeatSanctioned(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_NoSeatSanctioned}</>
                          )}
                        </div>
                        <div className="col-md-6 mt-3">
                          <label className="content1" htmlFor="name">
                            {" "}
                            Number of eligible applications received
                          </label>
                          <br />
                          {edit1 ? (
                            <>
                              {" "}
                              <input
                                className=" forminput"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_eligible_applications_received}
                                onChange={(e) => {
                                  setUni_eligible_applications_received(
                                    e.target.value
                                  );
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_eligible_applications_received}</>
                          )}
                        </div>
                        <div className="col-md-6 mt-3">
                          <label className="content1" htmlFor="name">
                            {" "}
                            Number of Students admitted
                          </label>
                          <br />
                          {edit1 ? (
                            <>
                              <input
                                className=" forminput"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_NoStdAdmit}
                                onChange={(e) => {
                                  setUni_NoStdAdmit(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_NoStdAdmit}</>
                          )}
                        </div>
                        <div className="col-md-12 mt-3 ">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Description(min 500 words)
                          </label>
                          <br />
                          {edit1 ? (
                            <>
                              <textarea
                                className=" forminput"
                                rows={3}
                                type="text"
                                name="email"
                                id="name"
                                placeholder={Uni_Desc32}
                                onChange={(e) => setUni_Desc32(e.target.value)}
                              />
                            </>
                          ) : (
                            <>{Uni_Desc32}</>
                          )}
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Upload the relevant document
                          </label>
                          <br />
                          {edit1 ? (
                            <>
                              <input
                                className=" forminput"
                                type="file"
                                name="upload1"
                                id="upload1"
                                accept="image/*"
                                onChange={(e) =>
                                  setUni_Link32(e.target.files[0])
                                }
                              />
                            </>
                          ) : (
                            <>{Uni_Link32.name}</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit1(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat1();
                      handleClose();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>
          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "20px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_2 (2.1.2)</h5>
              <h6>Average enrolement percentage(Average of last five years)</h6>
              <div className="row">
                <div className="row">
                  <h6 className="text-center text-decoration-underline">
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </h6>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      SC{" "}
                    </label>
                    {obj2?.Uni_SC ? (
                      <p>{obj2?.Uni_SC}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_SC(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ST{" "}
                    </label>
                    {obj2?.Uni_ST ? (
                      <p>{obj2?.Uni_ST}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_ST(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      OBC
                    </label>
                    {obj2?.Uni_OBC ? (
                      <p>{obj2?.Uni_OBC}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_OBC(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Gen
                    </label>
                    {obj2?.Uni_Gen ? (
                      <p>{obj2?.Uni_Gen}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_Gen(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Divyangjan
                    </label>
                    {obj2?.Uni_Divyangjan ? (
                      <p>{obj2?.Uni_Divyangjan}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_Divyangjan(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Others
                    </label>
                    {obj2?.Uni_Others ? (
                      <p>{obj2?.Uni_Others}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_Others(e.target.value)}
                      />
                    )}
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Number of students admitted from the reserved category
                  </h6>

                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      SC{" "}
                    </label>
                    {obj2?.Uni_SSC ? (
                      <p>{obj2?.Uni_SSC}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_SSC(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ST{" "}
                    </label>
                    {obj2?.Uni_SST ? (
                      <p>{obj2?.Uni_SST}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_SST(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      OBC
                    </label>
                    {obj2?.Uni_OOBC ? (
                      <p>{obj2?.Uni_OOBC}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_OOBC(e.target.value)}
                      />
                    )}
                  </div>

                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Gen
                    </label>
                    {obj2?.Uni_GGen ? (
                      <p>{obj2?.Uni_GGen}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_GGen(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Divyangjan
                    </label>
                    {obj2?.Uni_DDivyangjan ? (
                      <p>{obj2?.Uni_DDivyangjan}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_DDivyangjan(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Others
                    </label>
                    {obj2?.Uni_OOthers ? (
                      <p>{obj2?.Uni_OOthers}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => setUni_OOthers(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description (min 500 words)
                    </label>
                    {obj2?.Uni_Desc33 ? (
                      <p>{obj2?.Uni_Desc33}</p>
                    ) : (
                      <textarea
                        className=" forminput"
                        id="name"
                        cols={57}
                        rows={3}
                        onChange={(e) => {
                          setUni_Desc33(e.target.value);
                        }}
                      ></textarea>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload2">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    <input
                      className="name"
                      type="file"
                      name="upload2"
                      id="upload2"
                      accept="image/*"
                      onChange={(e) => {
                        setUni_Link33(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add2 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat2();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow1}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_2 (2.1.2)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="row">
                      <h6>
                        Average enrolement percentage(Average of last five
                        years)
                      </h6>
                      <h6 className="text-center text-decoration-underline">
                        Number of seats earmarked for reserved category as per
                        GOI or State Government rule
                      </h6>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          SC
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_SC}
                              onChange={(e) => {
                                setUni_SC(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_SC}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          ST{" "}
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_ST}
                              onChange={(e) => {
                                setUni_ST(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_ST}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          OBC
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_OBC}
                              onChange={(e) => {
                                setUni_OBC(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_OBC}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Gen
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_Gen}
                              onChange={(e) => {
                                setUni_Gen(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_Gen}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Divyangjan
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_Divyangjan}
                              onChange={(e) => {
                                setUni_Divyangjan(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_Divyangjan}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Others
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_Others}
                              onChange={(e) => {
                                setUni_Others(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_Others}</>
                        )}
                      </div>
                      <h6 className="text-center text-decoration-underline mt-3">
                        Number of students admitted from the reserved category
                      </h6>

                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          SC
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_SSC}
                              onChange={(e) => {
                                setUni_SSC(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_SSC}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          ST
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_SST}
                              onChange={(e) => {
                                setUni_SST(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_SST}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          OBC
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_OOBC}
                              onChange={(e) => {
                                setUni_OOBC(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_OOBC}</>
                        )}
                      </div>

                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Gen
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_GGen}
                              onChange={(e) => {
                                setUni_GGen(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_GGen}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Divyangjan
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_DDivyangjan}
                              onChange={(e) => {
                                setUni_DDivyangjan(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_DDivyangjan}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Others
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_OOthers}
                              onChange={(e) => {
                                setUni_OOthers(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_OOthers}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Description(min 500 words)
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="email"
                              id="name"
                              placeholder={Uni_Desc33}
                              onChange={(e) => setUni_Desc33(e.target.value)}
                            />
                          </>
                        ) : (
                          <>{Uni_Desc33}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Upload the relevant document
                        </label>
                        <br />
                        {edit2 ? (
                          <>
                            <input
                              className=" forminput"
                              type="file"
                              name="upload2"
                              id="upload2"
                              onChange={(e) => setUni_Link33(e.target.files[0])}
                            />
                          </>
                        ) : (
                          <>{Uni_Link33.name}</>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit2(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat2();
                      handleClose1();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "20px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_2 (2.4.1 & 2.4.3)</h5>
              <h6>Average enrolement percentage(Average of last five years)</h6>
              <div className="row">
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Full-time teacher
                    </label>
                    {obj3?.Uni_TName ? (
                      <p>{obj3?.Uni_TName}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => {
                          setUni_TName(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      PAN
                    </label>
                    {obj3?.Uni_PAN ? (
                      <p>{obj3?.Uni_PAN}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_PAN(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Designation
                    </label>
                    {obj3?.Uni_Designation ? (
                      <p>{obj3?.Uni_Designation}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_Designation(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of appointment
                    </label>
                    {obj3?.Uni_YOfAppoint ? (
                      <p>{obj3?.Uni_YOfAppoint}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_YOfAppoint(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-9 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Nature of appointment (Against Sanctioned post, temporary,
                      permanent)
                    </label>
                    {obj3?.Uni_NoOfAppoint ? (
                      <p>{obj3?.Uni_NoOfAppoint}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_NoOfAppoint(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Department
                    </label>
                    {obj3?.Uni_DeptName ? (
                      <p>{obj3?.Uni_DeptName}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_DeptName(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Total years of Experience in the same institution
                    </label>
                    {obj3?.Uni_TYofExp ? (
                      <p>{obj3?.Uni_TYofExp}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_TYofExp(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Is the teacher still serving the institution/If not last
                      year of the service of Faculty to the Institution
                    </label>
                    {obj3?.Uni_TService ? (
                      <p>{obj3?.Uni_TService}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="fname"
                        id="name"
                        onChange={(e) => {
                          setUni_TService(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description (min 500 words)
                    </label>
                    {obj3?.Uni_Desc34 ? (
                      <p>{obj3?.Uni_Desc34}</p>
                    ) : (
                      <textarea
                        className=" forminput"
                        id="name"
                        cols={57}
                        rows={3}
                        onChange={(e) => {
                          setUni_Desc34(e.target.value);
                        }}
                      ></textarea>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload3">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    <input
                      className="name"
                      type="file"
                      name="upload3"
                      id="upload3"
                      accept="image/*"
                      onChange={(e) => {
                        setUni_Link34(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add3 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat3();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow2}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_2 (2.4.1 & 2.4.3)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Full-time teacher
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_TName}
                            onChange={(e) => {
                              setUni_TName(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_TName}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        PAN
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_PAN}
                            onChange={(e) => {
                              setUni_PAN(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_PAN}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Designation
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Designation}
                            onChange={(e) => {
                              setUni_Designation(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Designation}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of appointment
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_YOfAppoint}
                            onChange={(e) => {
                              setUni_YOfAppoint(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_YOfAppoint}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Department
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_NoOfAppoint}
                            onChange={(e) => {
                              setUni_NoOfAppoint(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_NoOfAppoint}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Nature of appointment (Against Sanctioned post,
                        temporary, permanent)
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_DeptName}
                            onChange={(e) => {
                              setUni_DeptName(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_DeptName}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Total years of Experience in the same institution
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_TYofExp}
                            onChange={(e) => {
                              setUni_TYofExp(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_TYofExp}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Is the teacher still serving the institution/If not last
                        year of the service of Faculty to the Institution
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_TService}
                            onChange={(e) => {
                              setUni_TService(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_TService}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc34}
                            onChange={(e) => setUni_Desc34(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc34}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="file"
                            name="upload3"
                            id="upload3"
                            placeholder={Uni_Link34}
                            onChange={(e) => setUni_Link34(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link34.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit3(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat3();
                      handleClose2();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_2 (2.4.2)</h5>
              <h6>Average enrolement percentage(Average of last five years)</h6>
              <div className="row">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of full time serving teacher with
                      Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.
                    </label>
                    {obj4?.Uni_SerTeacher ? (
                      <p>{obj4?.Uni_SerTeacher}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => {
                          setUni_SerTeacher(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Qualification (Ph.D./D.M/M.Ch./D.N.B Super
                      speciality/D.Sc./ D.Litt.)
                    </label>
                    {obj4?.Uni_Qualify ? (
                      <p>{obj4?.Uni_Qualify}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => {
                          setUni_Qualify(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of obtaining the qualification
                    </label>
                    {obj4?.Uni_YOfQuali ? (
                      <p>{obj4?.Uni_YOfQuali}</p>
                    ) : (
                      <input
                        className=" forminput"
                        type="text"
                        name="year"
                        id="name"
                        onChange={(e) => {
                          setUni_YOfQuali(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description (min 500 words)
                    </label>
                    {obj4?.Uni_Desc35 ? (
                      <p>{obj4?.Uni_Desc35}</p>
                    ) : (
                      <textarea
                        className=" forminput"
                        id="name"
                        cols={57}
                        rows={3}
                        onChange={(e) => {
                          setUni_Desc35(e.target.value);
                        }}
                      ></textarea>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    <input
                      className="name"
                      type="file"
                      name="upload4"
                      id="upload4"
                      accept="image/*"
                      onChange={(e) => {
                        setUni_Link35(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add4 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat4();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow3}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_2 (2.4.2)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Name of full time serving teacher with
                          Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.
                        </label>
                        <br />
                        {edit4 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_SerTeacher}
                              onChange={(e) => {
                                setUni_SerTeacher(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_SerTeacher}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Qualification (Ph.D./D.M/M.Ch./D.N.B Super
                          speciality/D.Sc./ D.Litt.)
                        </label>
                        <br />
                        {edit4 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_Qualify}
                              onChange={(e) => {
                                setUni_Qualify(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_Qualify}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Year of obtaining the qualification
                        </label>
                        <br />
                        {edit4 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="year"
                              id="name"
                              placeholder={Uni_YOfQuali}
                              onChange={(e) => {
                                setUni_YOfQuali(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Uni_YOfQuali}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Description(min 500 words)
                        </label>
                        <br />
                        {edit4 ? (
                          <>
                            <input
                              className=" forminput"
                              type="text"
                              name="email"
                              id="name"
                              placeholder={Uni_Desc35}
                              onChange={(e) => setUni_Desc35(e.target.value)}
                            />
                          </>
                        ) : (
                          <>{Uni_Desc35}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Upload the relevant document
                        </label>
                        <br />
                        {edit4 ? (
                          <>
                            <input
                              className=" forminput"
                              type="file"
                              name="upload4"
                              id="upload4"
                              onChange={(e) => setUni_Link35(e.target.files[0])}
                            />
                          </>
                        ) : (
                          <>{Uni_Link35.name}</>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit4(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat4();
                      handleClose3();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_2 (2.4.4)</h5>
              <div className="row"></div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time teachers receiving awards from state
                    level, national level, international level
                  </label>
                  {obj5?.Uni_fullTeacherName ? (
                    <p>{obj5?.Uni_fullTeacherName}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_fullTeacherName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award
                  </label>
                  {obj5?.Uni_YrAward ? (
                    <p>{obj5?.Uni_YrAward}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_YrAward(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    PAN
                  </label>
                  {obj5?.Uni_PAN1 ? (
                    <p>{obj5?.Uni_PAN1}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setUni_PAN1(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Designation
                  </label>
                  {obj5?.Uni_Desi ? (
                    <p>{obj5?.Uni_Desi}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Desi(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the Awarding Agency{" "}
                  </label>
                  {obj5?.Uni_AwardAgency ? (
                    <p>{obj5?.Uni_AwardAgency}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_AwardAgency(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the award, fellowship, received from Government or
                  Government recognised bodies
                </label>
                {obj5?.Uni_AwardName ? (
                  <p>{obj5?.Uni_AwardName}</p>
                ) : (
                  <input
                    className=" forminput"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_AwardName(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Incentives/Type of the incentive given by the HEI in
                  recognition of the award{" "}
                </label>
                {obj5?.Uni_Incentives ? (
                  <p>{obj5?.Uni_Incentives}</p>
                ) : (
                  <input
                    className=" forminput"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_Incentives(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj5?.Uni_Desc36 ? (
                  <p>{obj5?.Uni_Desc36}</p>
                ) : (
                  <textarea
                    className=" forminput"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc36(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload5">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="name"
                  type="file"
                  name="upload5"
                  id="upload5"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link36(e.target.files[0]);
                  }}
                />
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add5 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat5();
                      setadd5(false);
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow6}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show6} onHide={handleClose6}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_2 (2.4.4)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of full time teachers receiving awards from state
                        level, national level, international level
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_fullTeacherName}
                            onChange={(e) => {
                              setUni_fullTeacherName(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_fullTeacherName}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of Award
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_YrAward}
                            onChange={(e) => {
                              setUni_YrAward(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_YrAward}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        PAN
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_PAN1}
                            onChange={(e) => {
                              setUni_PAN1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_PAN1}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Designation
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          {" "}
                          <input
                            className=" forminput"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desi}
                            onChange={(e) => {
                              setUni_Desi(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Desi}</>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the Awarding Agency{" "}
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_AwardAgency}
                            onChange={(e) => {
                              setUni_AwardAgency(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_AwardAgency}</>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the award, fellowship, received from Government or
                      Government recognised bodies
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className=" forminput"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_AwardName}
                          onChange={(e) => {
                            setUni_AwardName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_AwardName}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Incentives/Type of the incentive given by the HEI in
                      recognition of the award{" "}
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className=" forminput"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_Incentives}
                          onChange={(e) => {
                            setUni_Incentives(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_Incentives}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description (min 500 words)
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <textarea
                          className=" forminput"
                          id="name"
                          // cols={57}
                          rows={3}
                          placeholder={Uni_Desc36}
                          onChange={(e) => setUni_Desc36(e.target.value)}
                        ></textarea>
                      </>
                    ) : (
                      <>{Uni_Desc36}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload5">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="name"
                          type="file"
                          name="upload5"
                          id="upload5"
                          accept="image/*"
                          onChange={(e) => setUni_Link36(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_Link36.name}</>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit5(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat5();
                      handleClose6();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_2 (2.5.1)</h5>

              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme Name{" "}
                  </label>
                  {obj6?.Uni_pname1 ? (
                    <p>{obj6?.Uni_pname1}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setUni_pname1(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="BCA">BCA</option>
                      <option value="BBA">BBA</option>
                      <option value="BBA">BBA</option>
                      <option value="B.Com">B.Com</option>
                    </select>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme code
                  </label>
                  {obj6?.Uni_pcode1 ? (
                    <p>{obj6?.Uni_pcode1}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setUni_pcode1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Semester/ year
                  </label>
                  {obj6?.Uni_semester ? (
                    <p>{obj6?.Uni_semester}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setUni_semester(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="Semester-01">Semester-01</option>
                      <option value="Semester-02">Semester-02</option>
                      <option value="Semester-03">Semester-03</option>
                      <option value="Semester-04">Semester-04</option>
                      <option value="Semester-05">Semester-05</option>
                      <option value="Semester-06">Semester-06</option>
                      <option value="Semester-07">Semester-07</option>
                      <option value="Semester-08">Semester-08</option>
                    </select>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Last date of the last semester-end/ year- end examination
                  </label>
                  {obj6?.Uni_last ? (
                    <p>{obj6?.Uni_last}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_last(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date of declaration of results of semester-end/ year- end
                    examination{" "}
                  </label>
                  {obj6?.Uni_Date ? (
                    <p>{obj6?.Uni_Date}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Date(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj6?.Uni_Desc37 ? (
                    <p>{obj6?.Uni_Desc37}</p>
                  ) : (
                    <textarea
                      className=" forminput"
                      id="name"
                      // cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc37(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="name"
                    type="file"
                    name="upload6"
                    id="upload6"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link37(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add6 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat6();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow7}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show7} onHide={handleClose7}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_2 (2.5.1)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Programme Name{" "}
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            placeholder={Uni_pname1}
                            onChange={(e) => setUni_pname1(e.target.value)}
                          >
                            <option selected>--Select--</option>
                            <option value="BCA">BCA</option>
                            <option value="BBA">BBA</option>
                            <option value="BBA">BBA</option>
                            <option value="B.Com">B.Com</option>
                          </select>
                        </>
                      ) : (
                        <>{Uni_pname1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Programme Code
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_pcode1}
                            onChange={(e) => {
                              setUni_pcode1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_pcode1}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Semester/ year
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            placeholder={Uni_semester}
                            onChange={(e) => setUni_semester(e.target.value)}
                          >
                            <option selected>--Select--</option>
                            <option value="Semester-01">Semester-01</option>
                            <option value="Semester-02">Semester-02</option>
                            <option value="Semester-03">Semester-03</option>
                            <option value="Semester-04">Semester-04</option>
                            <option value="Semester-05">Semester-05</option>
                            <option value="Semester-06">Semester-06</option>
                            <option value="Semester-07">Semester-07</option>
                            <option value="Semester-08">Semester-08</option>
                          </select>
                        </>
                      ) : (
                        <>{Uni_semester}</>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Last date of the last semester-end/ year- end
                        examination
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" forminput"
                            type="date"
                            name="email"
                            id="name"
                            placeholder={Uni_last}
                            onChange={(e) => {
                              setUni_last(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_last}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Date of declaration of results of semester-end/ year-
                        end examination{" "}
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" forminput"
                            type="date"
                            name="email"
                            id="name"
                            placeholder={Uni_Date}
                            onChange={(e) => {
                              setUni_Date(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Date}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Description (min 500 words)
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <textarea
                            className=" forminput"
                            id="name"
                            // cols={57}
                            rows={3}
                            placeholder={Uni_Desc37}
                            onChange={(e) => setUni_Desc37(e.target.value)}
                          ></textarea>
                        </>
                      ) : (
                        <>{Uni_Desc37}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload76">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="name"
                            type="file"
                            name="upload6"
                            id="upload6"
                            accept="image/*"
                            onChange={(e) => setUni_Link37(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link37.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit6(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat6();
                      handleClose7();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_2 (2.6.3)</h5>

              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>
                  {obj7?.Uni_PrgName2 ? (
                    <p>{obj7?.Uni_PrgName2}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setUni_PrgName2(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="BCA">BCA</option>
                      <option value="BBA">BBA</option>
                      <option value="BBA">BBA</option>
                      <option value="B.Com">B.Com</option>
                    </select>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>
                  {obj7?.Uni_pCode ? (
                    <p>{obj7?.Uni_pCode}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setUni_pCode(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students appeared in the final year examination
                  </label>
                  {obj7?.Uni_NStdAppeared ? (
                    <p>{obj7?.Uni_NStdAppeared}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_NStdAppeared(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students passed in final year examination
                </label>
                {obj7?.Uni_NStdPassed ? (
                  <p>{obj7?.Uni_NStdPassed}</p>
                ) : (
                  <input
                    className=" forminput"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_NStdPassed(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj7?.Uni_Desc38 ? (
                  <p>{obj7?.Uni_Desc38}</p>
                ) : (
                  <textarea
                    className=" forminput"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc38(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload7">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="name"
                  type="file"
                  name="upload7"
                  id="upload7"
                  onChange={(e) => {
                    setUni_Link38(e.target.files[0]);
                  }}
                />
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add7 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat7();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow4}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show4} onHide={handleClose4}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_2 (2.6.3)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Program Name
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            placeholder={Uni_PrgName2}
                            onChange={(e) => setUni_PrgName2(e.target.value)}
                          >
                            <option selected>--Select--</option>
                            <option value="BCA">BCA</option>
                            <option value="BBA">BBA</option>
                            <option value="BBA">BBA</option>
                            <option value="B.Com">B.Com</option>
                          </select>
                        </>
                      ) : (
                        <>{Uni_PrgName2}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Program Code
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_pCode}
                            onChange={(e) => setUni_pCode(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_pCode}</>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students appeared in the final year
                        examination
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_NStdAppeared}
                            onChange={(e) =>
                              setUni_NStdAppeared(e.target.value)
                            }
                          />
                        </>
                      ) : (
                        <>{Uni_NStdAppeared}</>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students passed in final year examination
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className=" forminput"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_NStdPassed}
                          onChange={(e) => setUni_NStdPassed(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_NStdPassed}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description (min 500 words)
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <textarea
                          className=" forminput"
                          id="name"
                          // cols={57}
                          rows={3}
                          placeholder={Uni_Desc38}
                          onChange={(e) => setUni_Desc38(e.target.value)}
                        ></textarea>
                      </>
                    ) : (
                      <>{Uni_Desc38}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload7">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="name"
                          type="file"
                          name="upload7"
                          id="upload7"
                          accept="image/*"
                          placeholder={Uni_Link38}
                          onChange={(e) => {
                            setUni_Link38(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_Link38}</>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit7(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat7();
                      handleClose4();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_2 (2.7.1)</h5>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the student
                  </label>
                  {obj8?.Uni_NStd ? (
                    <p>{obj8?.Uni_NStd}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_NStd(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gender
                  </label>
                  {obj8?.Uni_Gender ? (
                    <p>{obj8?.Uni_Gender}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setUni_Gender(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category
                  </label>
                  {obj8?.Uni_Category ? (
                    <p>{obj8?.Uni_Category}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setUni_Category(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State of Domicile
                  </label>
                  {obj8?.Uni_SDomicile ? (
                    <p>{obj8?.Uni_SDomicile}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_SDomicile(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Nationality if other than Indian
                  </label>
                  {obj8?.Uni_NIndian ? (
                    <p>{obj8?.Uni_NIndian}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_NIndian(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Email-ID
                  </label>
                  {obj8?.Uni_Email ? (
                    <p>{obj8?.Uni_Email}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="email"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Email(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program name
                  </label>
                  {obj8?.Uni_PrgName3 ? (
                    <p>{obj8?.Uni_PrgName3}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setUni_PrgName3(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="BCA">BCA</option>
                      <option value="BBA">BBA</option>
                      <option value="BBA">BBA</option>
                      <option value="B.Com">B.Com</option>
                    </select>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Mobile Number
                  </label>
                  {obj8?.Uni_MNo ? (
                    <p>{obj8?.Uni_MNo}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_MNo(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of joining
                  </label>
                  {obj8?.Uni_YJoining ? (
                    <p>{obj8?.Uni_YJoining}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_YJoining(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Student Unique Enrolment ID
                  </label>
                  {obj8?.Uni_UID ? (
                    <p>{obj8?.Uni_UID}</p>
                  ) : (
                    <input
                      className=" forminput"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_UID(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj8?.Uni_Desc39 ? (
                  <p>{obj8?.Uni_Desc39}</p>
                ) : (
                  <textarea
                    className=" forminput"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc39(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload8">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="name"
                  type="file"
                  name="upload8"
                  id="upload8"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link39(e.target.files[0]);
                  }}
                />
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add8 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat8();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow5}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}{" "}
              </div>
              <Modal size="lg" show={show5} onHide={handleClose5}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_2 (2.7.1)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the student
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_NStd}
                            onChange={(e) => {
                              setUni_NStd(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_NStd}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Gender
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Gender}
                            onChange={(e) => {
                              setUni_Gender(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Gender}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Category
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Category}
                            onChange={(e) => {
                              setUni_Category(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Category}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        State of Domicile
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_SDomicile}
                            onChange={(e) => {
                              setUni_SDomicile(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_SDomicile}</>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Nationality if other than Indian
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_NIndian}
                            onChange={(e) => {
                              setUni_NIndian(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_NIndian}</>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Email-ID
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" forminput"
                            type="email"
                            name="email"
                            id="name"
                            placeholder={Uni_Email}
                            onChange={(e) => {
                              setUni_Email(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Email}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Program name
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            placeholder={Uni_PrgName3}
                            onChange={(e) => setUni_PrgName3(e.target.value)}
                          >
                            <option selected>--Select--</option>
                            <option value="BCA">BCA</option>
                            <option value="BBA">BBA</option>
                            <option value="BBA">BBA</option>
                            <option value="B.Com">B.Com</option>
                          </select>
                        </>
                      ) : (
                        <>{Uni_PrgName3}</>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Mobile Number
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_MNo}
                            onChange={(e) => {
                              setUni_MNo(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_MNo}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of joining
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_YJoining}
                            onChange={(e) => {
                              setUni_YJoining(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_YJoining}</>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Student Unique Enrolment ID
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_UID}
                            onChange={(e) => {
                              setUni_UID(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_UID}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Description (min 500 words)
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <textarea
                            className=" forminput"
                            id="name"
                            // cols={57}
                            rows={3}
                            placeholder={Uni_Desc39}
                            onChange={(e) => setUni_Desc39(e.target.value)}
                          ></textarea>
                        </>
                      ) : (
                        <>{Uni_Desc39}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload8">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="name"
                            type="file"
                            name="upload8"
                            id="upload8"
                            accept="image/*"
                            onChange={(e) => setUni_Link39(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link39.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit8(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat8();
                      handleClose5();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>
          <div className="text-center mt-3 mb-3">
            <button
              type="submit"
              className="btn btn-success "
              onClick={() => Unicriteria2()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uni_Criteria002;
