import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { DatePicker } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const UgCriteria1 = () => {
  let principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [View, setView] = useState([]);
  // ================Show doc1 modal======================

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const navigate = useNavigate();

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const ugCriteria01Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria01"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria01);
    }
  };

  // =================================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaUG01/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria01Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================

  useEffect(() => {
    principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      ugCriteria01Details();
    }
  }, []);

  // =================================================================
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-01
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div>
            <Nav defaultActiveKey="UGCri01113">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  className="p-0"
                  onClick={() => navigate("/UGCri01113")}
                >
                  <Button className="criteriabtn btn-sub1"> 1.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UGCri01121")}
                >
                  <Button className="criteriabtn  btn-sub"> 1.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UGCri01122")}
                >
                  <Button className="criteriabtn  btn-sub">
                    1.2.2 & 1.2.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-4"
                  onClick={() => navigate("/UGCri01132")}
                >
                  <Button className="criteriabtn  btn-sub"> 1.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UGCri01133")}
                >
                  <Button style={{}} className="criteriabtn  btn-sub">
                    1.3.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Id</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Name of the teacher participated</th>
                  <th>
                    Name of the body in which full time teacher participated
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Year Of Introduction</th>
                  <th>Status of implementation of CBCS/Elective course</th>
                  <th>Year of implementation of CBCS/Elective course</th>
                  <th>Year of revision </th>
                  <th>
                    If revision has been carried out in the syllabus during the
                    last 5 years, Percentage of content added or replaced
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of Add on /Certificate programs offered</th>
                  <th>Course Code (if any)</th>
                  <th>Year of offering</th>
                  <th>No. of times offered during the same year</th>
                  <th>Duration of course</th>
                  <th>Number of students enrolled in the year</th>
                  <th>Number of Students completing the course in the year</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Code</th>
                  <th>Program Name</th>
                  <th>Year of offering</th>
                  <th>
                    Name of the student studied course on experiential learning
                    through project work/field work/internship
                  </th>
                  <th>
                    Name of the Course that include experiential learning
                    through project work/field work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    List of students undertaking project work/field
                    work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    principalDetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    (item1?.approve === "false" ||
                      item1?.approve === "Pending") &&
                    (SearchItem === "" ||
                      item1?.Criteria01_113?.TeacherPar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_113?.BodyName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_113?.Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_121?.PrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_121?.YOfIntro?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.Status?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_121?.YOfCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.YOfRevision?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.Revision?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.Desc2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.CertiName?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_122?.CourseCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.YOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.NoOfTimes?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.CourseDuration?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.NoOfStudents?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.CourseCompletion?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.Desc3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_132?.PPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_132?.PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_132?.YYOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_132?.StdCourseStudied?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_132?.ExpCourse?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_132?.Desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_133?.PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_133?.PPPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_133?.StdList?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_133?.Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                }).map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Year}</td>
                      <td>{item?.Criteria01_113?.criteria}</td>
                      <td>{item?.Criteria01_113?.TeacherPar}</td>
                      <td>{item?.Criteria01_113?.BodyName}</td>
                      <td>
                        {item?.Criteria01_113?.Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria01_121?.criteria}</td>
                      <td>{item?.Criteria01_121?.PrgName1}</td>
                      <td>{item?.Criteria01_121?.PrgmCode}</td>
                      <td>{item?.Criteria01_121?.YOfIntro}</td>
                      <td>{item?.Criteria01_121?.Status}</td>
                      <td>{item?.Criteria01_121?.YOfCBCS}</td>
                      <td>{item?.Criteria01_121?.YOfRevision}</td>
                      <td>{item?.Criteria01_121?.Revision}</td>
                      <td>
                        {item?.Criteria01_121?.Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria01_122?.criteria}</td>
                      <td>{item?.Criteria01_122?.CertiName}</td>
                      <td>{item?.Criteria01_122?.CourseCode}</td>
                      <td>{item?.Criteria01_122?.YOfOffering}</td>
                      <td>{item?.Criteria01_122?.NoOfTimes}</td>
                      <td>{item?.Criteria01_122?.CourseDuration}</td>
                      <td>{item?.Criteria01_122?.NoOfStudents}</td>
                      <td>{item?.Criteria01_122?.CourseCompletion}</td>

                      <td>
                        {item?.Criteria01_122?.Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria01_132?.criteria}</td>
                      <td>{item?.Criteria01_132?.PPrgmCode}</td>
                      <td>{item?.Criteria01_132?.PrgName2}</td>
                      <td>{item?.Criteria01_132?.YYOfOffering}</td>
                      <td>{item?.Criteria01_132?.StdCourseStudied}</td>
                      <td>{item?.Criteria01_132?.ExpCourse}</td>

                      <td>
                        {item?.Criteria01_132?.Desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria01_133?.criteria}</td>
                      <td>{item?.Criteria01_133?.PrgName3}</td>
                      <td>{item?.Criteria01_133?.PPPrgmCode}</td>
                      <td>{item?.Criteria01_133?.StdList}</td>

                      <td>
                        {item?.Criteria01_133?.Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          {/* <i class="fa-solid fa-check fa-lg" style={{color: "#26a269"}}></i> */}
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-justify">
              <div>
                <p>{View?.Criteria01_113?.Desc1}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose6}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-align-justify">
              <div>
                <p> {View?.Criteria01_121?.Desc2}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-justify">
              <div>
                <p> {View?.Criteria01_122?.Desc3}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose8}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-justify">
              <div>
                <p> {View?.Criteria01_133?.Desc5}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-justify">
              <div>
                <p> {View?.Criteria01_132?.Desc4}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevent Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_113?.Link1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevent Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_121?.Link2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevent Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_122?.Link3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevent Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_132?.Link4}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevent Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_133?.Link5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="modal-head-text">UnderGraduate_Criteria-01</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-lg-12 mt-3">
                  <div>
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher participated
                    </label>

                    <p className="display">
                      {Value?.Criteria01_113?.TeacherPar}
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the body in which full time teacher participated
                    </label>
                    <p className="display">{Value?.Criteria01_113?.BodyName}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <p className="display">{Value?.Criteria01_113?.Desc1}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <p className="display">{Value?.Criteria01_113?.Link1}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria - 1.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-lg-12">
                  <div>
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>

                    <p className="display">{Value?.Criteria01_121?.PrgmCode}</p>
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program Name
                    </label>
                    <p className="display">{Value?.Criteria01_121?.PrgName1}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year Of Introduction
                    </label>
                    <p className="display">{Value?.Criteria01_121?.YOfIntro}</p>
                  </div>

                  <div className="col-lg-12 ">
                    <div>
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of revision (if any)
                      </label>
                      <p className="display">
                        {Value?.Criteria01_121?.YOfRevision}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Status of implementation of CBCS/elective course
                    </label>
                    <p className="display">{Value?.Criteria01_121?.Status}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of implementation of CBCS/elective course
                    </label>
                    <p className="display">{Value?.Criteria01_121?.YOfCBCS}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      If revision has been carried out in the syllabus during
                      the last 5 years, Percentage of content added or replaced
                    </label>
                    <p className="display">{Value?.Criteria01_121?.Revision}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <p className="display">{Value?.Criteria01_121?.Desc2}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <p className="display">{Value?.Criteria01_121?.Link2}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria - 1.2.2 & 1.2.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-lg-12">
                  <div>
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of Add on /Certificate programs offered
                    </label>

                    <p className="display">
                      {Value?.Criteria01_122?.CertiName}
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Course Code (if any)
                    </label>
                    <p className="display">
                      {Value?.Criteria01_122?.CourseCode}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of offering
                    </label>
                    <p className="display">
                      {Value?.Criteria01_122?.YOfOffering}
                    </p>
                  </div>

                  <div className="col-lg-12 ">
                    <div>
                      <label className="content1" htmlFor="email">
                        {" "}
                        No. of times offered during the same year
                      </label>
                      <p className="display">
                        {Value?.Criteria01_122?.NoOfTimes}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Duration of course
                    </label>
                    <p className="display">
                      {Value?.Criteria01_122?.CourseDuration}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students enrolled in the year
                    </label>
                    <p className="display">
                      {Value?.Criteria01_122?.NoOfStudents}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Students completing the course in the year
                    </label>
                    <p className="display">
                      {Value?.Criteria01_122?.CourseCompletion}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <p className="display">{Value?.Criteria01_122?.Desc3}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <p className="display">{Value?.Criteria01_122?.Link3}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria - 1.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-lg-12">
                  <div>
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    <p className="display">{Value?.Criteria01_132?.PrgName2}</p>
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program Code
                    </label>
                    <p className="display">
                      {Value?.Criteria01_132?.PPrgmCode}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of offering
                    </label>
                    <p className="display">
                      {Value?.Criteria01_132?.YYOfOffering}
                    </p>
                  </div>

                  <div className="col-lg-12 ">
                    <div>
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the student studied course on experiential
                        learning through project work/field work/internship
                      </label>
                      <p className="display">
                        {Value?.Criteria01_132?.StdCourseStudied}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the Course that include experiential learning
                      through project work/field work/internship
                    </label>
                    <p className="display">
                      {Value?.Criteria01_132?.ExpCourse}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <p className="display">{Value?.Criteria01_132?.Desc4}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <p className="display">{Value?.Criteria01_132?.Link4}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria - 1.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-lg-12 mt-3">
                  <div>
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    <p className="display">{Value?.Criteria01_133?.PrgName3}</p>
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program Code
                    </label>
                    <p className="display">
                      {Value?.Criteria01_133?.PPPrgmCode}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      List of students undertaking project work/field
                      work/internship
                    </label>
                    <p className="display">{Value?.Criteria01_133?.StdList}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <p className="display">{Value?.Criteria01_133?.Desc5}</p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div>
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <p className="display">{Value?.Criteria01_133?.Link5}</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  className="btn-success"
                  onClick={(e) => approveCriteria(e, true, Value?._id)}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              style={{ height: "200px" }}
              onChange={(e) => setremark(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => approveCriteria(e, false, Value?._id)}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UgCriteria1;
