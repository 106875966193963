import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function NewsVideo() {
  const [ShowItem, setShowItem] = useState({});
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // =================== calling api for getting the Latest news======================
  const [LatestNews, setLatestNews] = useState([]);
  const getNews = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getNews");
      if (res.status === 200) {
        setLatestNews(res.data.allData);
      }
    } catch (error) {
      console.log(error);
      setLatestNews(error.response.data.allData);
    }
  };

  // =================== calling api for getting the Latest videos======================
  const [LatestVideos, setLatestVideos] = useState([]);
  const getVideos = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getVideos");
      if (res.status === 200) {
        setLatestVideos(res.data.allData);
      }
    } catch (error) {
      console.log(error);
      setLatestVideos(error.response.data.allData);
    }
  };
  useEffect(() => {
    getNews();
    getVideos();
  }, []);
  return (
    <>
      <section>
        <div
          className="new"
          data-aos="fade-up"
          data-aos-duration="2000"
          style={{
            backgroundImage: "url('../Image/bg-0.avif')",
            position: "center",
            backgroundSize: "cover",
            height: "500px",
          }}
        >
          <div className="container">
            <div className="news">
              <div className="row">
                <div className="col-md-6">
                  <div className="latest ">
                    <h4 style={{ color: "black", marginTop: "10px" }}>
                      Latest News
                    </h4>
                    <div className="dg-op">
                      {LatestNews?.map((item) => {
                        return (
                          <div className="news-late mb-3">
                            <div className="news-latest-0">
                              <h6 className="h-6-0">
                                <span style={{ color: "red" }}>&#10157;</span>
                                {item?.topic}
                              </h6>
                              <div className="pass-0">
                                {item?.body?.slice(0, 100)}
                                <Link
                                  onClick={() => {
                                    setShowItem(item);
                                    handleShow3();
                                  }}
                                >
                                  Read More...
                                </Link>
                              </div>
                              {/* <div
                                className=""
                                style={{ color: "green", float: "right" }}
                              >
                                17may 2021
                              </div> */}
                            </div>
                          </div>
                        );
                      })}
                      {/* <div className="news-late mb-3">
                        <div className="news-latest-0">
                          <h6 className="h-6-0">
                            <span style={{ color: "red" }}>&#10157;</span>
                            Helping Students Is the Key
                          </h6>
                          <div className="pass-0">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard...
                          </div>
                          <div
                            className=""
                            style={{ color: "green", float: "right" }}
                          >
                            17may 2021
                          </div>
                        </div>
                      </div>
                      <div className="news-late mb-3">
                        <div className="news-latest-0">
                          <h6 className="h-6-0">
                            <span style={{ color: "red" }}>&#10157;</span>
                            Helping Students Is the Key
                          </h6>
                          <div className="pass-0">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard...
                          </div>
                          <div
                            className=""
                            style={{ color: "green", float: "right" }}
                          >
                            17may 2021
                          </div>
                        </div>
                      </div>
                      <div className="news-late mb-3">
                        <div className="news-latest-0">
                          <h6 className="h-6-0">
                            <span style={{ color: "red" }}>&#10157;</span>
                            Helping Students Is the Key
                          </h6>
                          <div className="pass-0">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard...
                          </div>
                          <div
                            className=""
                            style={{ color: "green", float: "right" }}
                          >
                            17may 2021
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="latest latest-vi">
                    <h4 style={{ color: "black", marginTop: "10px" }}>
                      Latest Videos
                    </h4>
                    <div className="videos">
                      {/* <video width="500" height="350" controls> */}
                      <iframe
                        width="100%"
                        height="360"
                        src={`https://brightnaac.co.in/Videos/${LatestVideos[0]?.video}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>

                      {/* </video> */}
                    </div>
                  </div>
                </div>
                <Modal show={show3} onHide={handleClose3}>
                  <Modal.Body>
                    <div className="mb-1">
                      <label>{ShowItem?.body}</label>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
    </>
  );
}

export default NewsVideo;
