import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Auto_Criteria001 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //post method for auto_criteria_01
  const [Auto_Year, setAuto_Year] = useState("");

  const [Auto_Pcode, setAuto_Pcode] = useState("");
  const [Auto_Pname, setAuto_Pname] = useState("");
  const [Auto_YOInt, setAuto_YOInt] = useState("");
  const [Auto_SOInt, setAuto_SOInt] = useState("");
  const [Auto_YOImple, setAuto_YOImple] = useState("");
  const [Auto_YRevi, setAuto_YRevi] = useState("");
  const [Auto_Revi, setAuto_Revi] = useState("");
  const [Auto_Desc1, setAuto_Desc1] = useState("");
  const [Auto_Link1, setAuto_Link1] = useState("");

  const [Auto_NCourse, setAuto_NCourse] = useState("");
  const [Auto_CCode, setAuto_CCode] = useState("");
  const [Auto_Activities, setAuto_Activities] = useState("");
  const [Auto_YOIntro, setAuto_YOIntro] = useState("");
  const [Auto_Desc2, setAuto_Desc2] = useState("");
  const [Auto_Link2, setAuto_Link2] = useState("");

  const [Auto_Name, setAuto_Name] = useState("");
  const [Auto_Course, setAuto_Course] = useState("");
  const [Auto_YOOff, setAuto_YOOff] = useState("");
  const [Auto_Times, setAuto_Times] = useState("");
  const [Auto_CDurat, setAuto_CDurat] = useState("");
  const [Auto_Number1, setAuto_Number1] = useState("");
  const [Auto_Number2, setAuto_Number2] = useState("");
  const [Auto_Desc3, setAuto_Desc3] = useState("");
  const [Auto_Link3, setAuto_Link3] = useState("");

  const [Auto_Prg1, setAuto_Prg1] = useState("");
  const [Auto_Prg2, setAuto_Prg2] = useState("");
  const [Auto_Name1, setAuto_Name1] = useState("");
  const [Auto_Desc4, setAuto_Desc4] = useState("");
  const [Auto_Link4, setAuto_Link4] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getautocriteria1 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getautocriteria1();
  }, []);

  const autocriteria1 = async () => {
    if (!Auto_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/autoadddetails01",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Year: Auto_Year,

          Auto_Criteria01_112: obj1,
          Auto_Link1: Auto_Link1,

          Auto_Criteria01_113: obj2,
          Auto_Link2: Auto_Link2,

          Auto_Criteria01_132: obj3,
          Auto_Link3: Auto_Link3,

          Auto_Criteria01_134: obj4,
          Auto_Link4: Auto_Link4,

          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          navigate("/autocriteria01");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Auto_Pcode ||
          !Auto_Pname ||
          !Auto_YOInt ||
          !Auto_SOInt ||
          !Auto_YOImple ||
          !Auto_YRevi ||
          !Auto_Revi ||
          !Auto_Desc1 ||
          !Auto_Link1
        ) {
          return alert("Please fill all the details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Auto_Link1);
      let obj = {
        Auto_criteria: "1.1.2 & 1.2.2",
        Auto_Pcode: Auto_Pcode,
        Auto_Pname: Auto_Pname,
        Auto_YOInt: Auto_YOInt,
        Auto_SOInt: Auto_SOInt,
        Auto_YOImple: Auto_YOImple,
        Auto_YRevi: Auto_YRevi,
        Auto_Revi: Auto_Revi,
        Auto_Desc1: Auto_Desc1,
        Auto_Link1: Auto_Link1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getautocriteria1();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !Auto_NCourse ||
          !Auto_CCode ||
          !Auto_Activities ||
          !Auto_YOIntro ||
          !Auto_Desc2 ||
          !Auto_Link2
        ) {
          return alert("Please fill all the details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Auto_Link2);
      let obj = {
        Auto_criteria: "1.1.3 & 1.2.1",
        Auto_NCourse: Auto_NCourse,
        Auto_CCode: Auto_CCode,
        Auto_Activities: Auto_Activities,
        Auto_YOIntro: Auto_YOIntro,
        Auto_Desc2: Auto_Desc2,
        Auto_Link2: Auto_Link2,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getautocriteria1();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !Auto_Name ||
          !Auto_Course ||
          !Auto_YOOff ||
          !Auto_Times ||
          !Auto_CDurat ||
          !Auto_Number1 ||
          !Auto_Number2 ||
          !Auto_Desc3 ||
          !Auto_Link3
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Auto_Link3);
      let obj = {
        Auto_criteria: "1.3.2 & 1.3.3",
        Auto_Name: Auto_Name,
        Auto_Course: Auto_Course,
        Auto_YOOff: Auto_YOOff,
        Auto_Times: Auto_Times,
        Auto_CDurat: Auto_CDurat,
        Auto_Number1: Auto_Number1,
        Auto_Number2: Auto_Number2,
        Auto_Desc3: Auto_Desc3,
        Auto_Link3: Auto_Link3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getautocriteria1();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Auto_Prg1 ||
          !Auto_Prg2 ||
          !Auto_Name1 ||
          !Auto_Desc4 ||
          !Auto_Link4
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Auto_Link4);
      let obj = {
        Auto_criteria: "1.3.4",
        Auto_Prg1: Auto_Prg1,
        Auto_Prg2: Auto_Prg2,
        Auto_Name1: Auto_Name1,
        Auto_Desc4: Auto_Desc4,
        Auto_Link4: Auto_Link4,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getautocriteria1();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div className="container">
      <div className="pt-4   " style={{ padding: "10px" }}>
        <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
          {" "}
          Autonomous_Criteria-01
        </span>
      </div>
      <div className="container mt-3 ">
        <div className="row-fluid bs border">
          <div class=" col-md-6  " style={{ padding: "20px" }}>
            <label for="" class="form-label">
              Select Year
            </label>
            {obj1?.Auto_Year ? (
              <p>{obj1?.Auto_Year}</p>
            ) : (
              <select
                class="form-select form-select-sm vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setAuto_Year(e.target.value)}
              >
                <option selected>--Select Year--</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border bs"
          style={{
            padding: "50px",
          }}
        >
          <h5>Criteria 1.1.2 & 1.2.2</h5>

          <div className="row">
            <div className="col-md-3 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Program Name
              </label>
              {obj1?.Auto_Pname ? (
                <p>{obj1?.Auto_Pname}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "100%" }}
                  onChange={(e) => setAuto_Pname(e.target.value)}
                >
                  <option selected>--Select--</option>
                  <option value="BCA">BCA</option>
                  <option value="BSC">BSC</option>
                  <option value="BBA">BBA</option>
                  <option value="B.Com">B.Com</option>
                </select>
              )}
            </div>
            <div className="col-md-5 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Status of implementation of CBCS / ECS
              </label>
              {obj1?.Auto_SOInt ? (
                <p>{obj1?.Auto_SOInt}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "100%" }}
                  onChange={(e) => setAuto_SOInt(e.target.value)}
                >
                  <option selected>--Select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              )}
            </div>
            <div className="col-md-4 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Programme code
              </label>
              {obj1?.Auto_Pcode ? (
                <p>{obj1?.Auto_Pcode}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => setAuto_Pcode(e.target.value)}
                />
              )}
            </div>
            <div className="row">
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of Introduction
                </label>
                {obj1?.Auto_YOInt ? (
                  <p>{obj1?.Auto_YOInt}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_YOInt(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of revision (if any)
                </label>
                {obj1?.Auto_YRevi ? (
                  <p>{obj1?.Auto_YRevi}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_YRevi(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of implementation of CBCS / ECS
                </label>
                {obj1?.Auto_YOImple ? (
                  <p>{obj1?.Auto_YOImple}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_YOImple(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                If revision has been carried out in the syllabus during last 5
                years, Percentage of content added or replaced
              </label>
              {obj1?.Auto_Revi ? (
                <p>{obj1?.Auto_Revi}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => setAuto_Revi(e.target.value)}
                />
              )}
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj1?.Auto_Desc1 ? (
                <p>{obj1?.Auto_Desc1}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  // cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc1(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload1"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link1(e.target.files[0]);
                }}
              />
            </div>
          </div>

          <div className="pt-2 text-end mt-3 ">
            {add1 ? (
              <button
                type="submit"
                className="btn btn-success  mt-3 "
                onClick={() => {
                  cat1();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}
          </div>
          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <span>Criteria-1.1.2 & 1.2.2</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Auto_Pname}
                        onChange={(e) => setAuto_Pname(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BBA">BBA</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Auto_Pname}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Status of implementation of CBCS / ECS
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Auto_SOInt}
                        onChange={(e) => setAuto_SOInt(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Auto_SOInt}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Programme code
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Pcode}
                        onChange={(e) => setAuto_Pcode(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Pcode}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Introduction
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_YOInt}
                        onChange={(e) => setAuto_YOInt(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_YOInt}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of revision (if any)
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_YRevi}
                        onChange={(e) => setAuto_YRevi(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_YRevi}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of implementation of CBCS / ECS
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_YOImple}
                        onChange={(e) => setAuto_YOImple(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_YOImple}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    If revision has been carried out in the syllabus during last
                    5 years, Percentage of content added or replaced
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Revi}
                        onChange={(e) => setAuto_Revi(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Revi}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Desc1}
                        onChange={(e) => setAuto_Desc1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Desc1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        onChange={(e) => setAuto_Link1(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Auto_Link1?.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit1 ? (
                <>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat1();
                      handleClose();
                    }}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit1(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_1 (1.1.3 & 1.2.1)</h5>
          <div className="row">
            <div className="col-md-4 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Course Code
              </label>
              {obj2?.Auto_CCode ? (
                <p>{obj2?.Auto_CCode}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setAuto_CCode(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-4 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Name of the Course
              </label>
              {obj2?.Auto_NCourse ? (
                <p>{obj2?.Auto_NCourse}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setAuto_NCourse(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-4 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Year Of Introduction
              </label>
              {obj2?.Auto_YOIntro ? (
                <p>{obj2?.Auto_YOIntro}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAuto_YOIntro(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Activities/Content with direct bearing on Employability/
                Entrepreneurship/ Skill development
              </label>
              {obj2?.Auto_Activities ? (
                <p>{obj2?.Auto_Activities}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAuto_Activities(e.target.value);
                  }}
                />
              )}
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <label className="content1" htmlFor="name">
              {" "}
              Description (min 500 words)
            </label>
            {obj2?.Auto_Desc2 ? (
              <p>{obj2?.Auto_Desc2}</p>
            ) : (
              <textarea
                className="vi_0"
                id="name"
                cols={57}
                rows={3}
                onChange={(e) => {
                  setAuto_Desc2(e.target.value);
                }}
              ></textarea>
            )}
          </div>
          <div className="col-md-12 mt-3">
            <label className="content1" htmlFor="upload1">
              {" "}
              Upload the relevant document
            </label>
            <br />
            <input
              className="vi_0"
              type="file"
              name="upload2"
              id="upload2"
              accept="image/*"
              onChange={(e) => {
                setAuto_Link2(e.target.files[0]);
              }}
            />
          </div>

          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add2 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat2();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow1}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}
          </div>
          <Modal size="lg" show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria_1 (1.1.3 & 1.2.1)</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Auto_CCode}
                        onChange={(e) => {
                          setAuto_CCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_CCode}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Course
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Auto_NCourse}
                        onChange={(e) => {
                          setAuto_NCourse(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_NCourse}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year Of Introduction
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_YOIntro}
                        onChange={(e) => setAuto_YOIntro(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_YOIntro}</>
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Activities/Content with direct bearing on Employability/
                  Entrepreneurship/ Skill development
                </label>
                <br />
                {edit2 ? (
                  <>
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Auto_Activities}
                      onChange={(e) => setAuto_Activities(e.target.value)}
                    />
                  </>
                ) : (
                  <>{Auto_Activities}</>
                )}
              </div>

              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Description(min 500 words)
                </label>
                <br />
                {edit2 ? (
                  <>
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Auto_Desc2}
                      onChange={(e) => setAuto_Desc2(e.target.value)}
                    />
                  </>
                ) : (
                  <>{Auto_Desc2}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                {edit2 ? (
                  <>
                    <input
                      className="vi_0"
                      type="file"
                      name="upload2"
                      id="name"
                      onChange={(e) => setAuto_Link2(e.target.files[0])}
                    />
                  </>
                ) : (
                  <>{Auto_Link2?.name}</>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit2 ? (
                <>
                  {" "}
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat2();
                      handleClose1();
                    }}
                  >
                    Submit
                  </button>{" "}
                </>
              ) : (
                <>
                  {" "}
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit2(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_1 (1.3.2 & 1.3.3)</h5>
          <h6>Average enrolement percentage(Average of last five years)</h6>
          <div className="row">
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Name of the value added courses offered (with 30 or more contact
                hours)
              </label>

              {obj3?.Auto_Name ? (
                <p>{obj3?.Auto_Name}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setAuto_Name(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Course Code (if any)
              </label>
              {obj3?.Auto_Course ? (
                <p>{obj3?.Auto_Course}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setAuto_Course(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Year of offering
              </label>
              {obj3?.Auto_YOOff ? (
                <p>{obj3?.Auto_YOOff}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setAuto_YOOff(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3">
              <label className="content1">
                {" "}
                No. of times offered during the same year
              </label>
              {obj3?.Auto_Times ? (
                <p>{obj3?.Auto_Times}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setAuto_Times(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Duration of the course
              </label>
              {obj3?.Auto_CDurat ? (
                <p>{obj3?.Auto_CDurat}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setAuto_CDurat(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Number of Students completing course in the year
              </label>
              {obj3?.Auto_Number1 ? (
                <p>{obj3?.Auto_Number1}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setAuto_Number1(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Number of students enrolled in the year
              </label>
              {obj3?.Auto_Number2 ? (
                <p>{obj3?.Auto_Number2}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setAuto_Number2(e.target.value);
                  }}
                />
              )}
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj3?.Auto_Desc3 ? (
                <p>{obj3?.Auto_Desc3}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc3(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Upload the relevant document
              </label>
              <br />

              <input
                className="vi_0"
                type="file"
                name="upload3"
                id="upload3"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link3(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add3 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat3();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow2}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}
          </div>
          <Modal size="lg" show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria_1 (1.3.2 & 1.3.3)</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the value added courses offered (with 30 or more
                    contact hours)
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Auto_Name}
                        onChange={(e) => {
                          setAuto_Name(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_Name}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code (if any)
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Auto_Course}
                        onChange={(e) => {
                          setAuto_Course(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_Course}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of offering
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Auto_YOOff}
                        onChange={(e) => {
                          setAuto_YOOff(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_YOOff}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    No. of times offered during the same year
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Auto_Times}
                        onChange={(e) => {
                          setAuto_Times(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_Times}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of course
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Auto_CDurat}
                        onChange={(e) => {
                          setAuto_CDurat(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_CDurat}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students enrolled in the year
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Auto_Number1}
                        onChange={(e) => {
                          setAuto_Number1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_Number1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students completing the course in the year
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Auto_Number2}
                        onChange={(e) => {
                          setAuto_Number2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_Number2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Desc3}
                        onChange={(e) => setAuto_Desc3(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Desc3}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3 "
                        onChange={(e) => setAuto_Link3(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Auto_Link3?.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit3 ? (
                <>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat3();
                      handleClose2();
                    }}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  {" "}
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit3(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_1 (1.3.4)</h5>
          <div className="row">
            <div className="col-md-4 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Program Name
              </label>
              {obj4?.Auto_Prg1 ? (
                <p>{obj4?.Auto_Prg1}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "100%" }}
                  onChange={(e) => setAuto_Prg1(e.target.value)}
                >
                  <option selected>--Select--</option>
                  <option value="BCA">BCA</option>
                  <option value="BBA">BBA</option>
                  <option value="BBA">BBA</option>
                  <option value="B.Com">B.Com</option>
                </select>
              )}
            </div>
            <div className="col-md-4 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Program Code
              </label>
              {obj4?.Auto_Prg2 ? (
                <p>{obj4?.Auto_Prg2}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="Year"
                  id="name"
                  onChange={(e) => {
                    setAuto_Prg2(e.target.value);
                  }}
                />
              )}
            </div>

            <div className="col-md-4 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Name of the students
              </label>
              {obj4?.Auto_Name1 ? (
                <p>{obj4?.Auto_Name1}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="Year"
                  id="name"
                  onChange={(e) => {
                    setAuto_Name1(e.target.value);
                  }}
                />
              )}
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <label className="content1" htmlFor="name">
              {" "}
              Description (min 500 words)
            </label>
            {obj4?.Auto_Desc4 ? (
              <p>{obj4?.Auto_Desc4}</p>
            ) : (
              <textarea
                className="vi_0"
                id="name"
                cols={57}
                rows={3}
                onChange={(e) => {
                  setAuto_Desc4(e.target.value);
                }}
              ></textarea>
            )}
          </div>
          <div className="col-md-12 mt-3">
            <label className="content1" htmlFor="upload4">
              {" "}
              Upload the relevant document
            </label>
            <br />
            <input
              className="vi_0"
              type="file"
              name="Year"
              id="upload4"
              accept="image/*"
              onChange={(e) => {
                setAuto_Link4(e.target.files[0]);
              }}
            />
          </div>

          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add4 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat4();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow3}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}
          </div>
          <Modal size="lg" show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria_1 (1.3.4)</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Auto_Prg1}
                        onChange={(e) => {
                          setAuto_Prg1(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BBA">BBA</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Auto_Prg1}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Auto_Prg2}
                        onChange={(e) => {
                          setAuto_Prg2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_Prg2}</>
                  )}
                </div>

                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the students
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Name1}
                        onChange={(e) => {
                          setAuto_Name1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Auto_Name1}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Desc4}
                        onChange={(e) => setAuto_Desc4(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Desc4}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        onChange={(e) => setAuto_Link4(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Auto_Link4.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit4 ? (
                <>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat4();
                      handleClose3();
                    }}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit4(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="text-center mt-3 mb-3">
        <button
          type="submit"
          className="btn btn-success "
          onClick={() => autocriteria1()}
          style={{ color: "white", marginTop: "15px" }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Auto_Criteria001;
