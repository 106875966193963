import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const Auto_Criteria07 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);

  const [Auto_Budget, setAuto_Budget] = useState("");
  const [Auto_Lighting, setAuto_Lighting] = useState("");
  const [Auto_PhyFac, setAuto_PhyFac] = useState("");
  const [Auto_Prolift, setAuto_Prolift] = useState("");
  const [Auto_Ramp, setAuto_Ramp] = useState("");
  const [Auto_Braille, setAuto_Braille] = useState("");
  const [Auto_RestRoom, setAuto_RestRoom] = useState("");
  const [Auto_Scribes, setAuto_Scribes] = useState("");
  const [Auto_SpecialSkills, setAuto_SpecialSkills] = useState("");
  const [Auto_AnyOther, setAuto_AnyOther] = useState("");
  const [Auto_Annual, setAuto_Annual] = useState("");
  const [Auto_NoOfIniti1, setAuto_NoOfIniti1] = useState("");
  const [Auto_NoOfIniti2, setAuto_NoOfIniti2] = useState("");
  const [Auto_Date1, setAuto_Date1] = useState("");
  const [Auto_DateDuration, setAuto_DateDuration] = useState("");
  const [Auto_Date2, setAuto_Date2] = useState("");
  const [Auto_Power, setAuto_Power] = useState("");
  const [Auto_TPower, setAuto_TPower] = useState("");
  const [Auto_ReEnergyS, setAuto_ReEnergyS] = useState("");
  const [Auto_ReEnergyG, setAuto_ReEnergyG] = useState("");
  const [Auto_EnergySup, setAuto_EnergySup] = useState("");
  const [Auto_PercentageLED, setAuto_PercentageLED] = useState("");
  const [Auto_PercentageLight, setAuto_PercentageLight] = useState("");
  const [Auto_NoOfParticipants, setAuto_NoOfParticipants] = useState("");
  const [Auto_NoOfActivities, setAuto_NoOfActivities] = useState("");
  const [Auto_TitlePro, setAuto_TitlePro] = useState("");
  const [Auto_NoOfParticipants1, setAuto_NoOfParticipants1] = useState("");
  const [Auto_Male, setAuto_Male] = useState("");
  const [Auto_Female, setAuto_Female] = useState("");
  const [Auto_Yea, setAuto_Yea] = useState("");
  const [Auto_Total, setAuto_Total] = useState("");
  const [Auto_PowRe, setAuto_PowRe] = useState("");
  const [Auto_InitiName, setAuto_InitiName] = useState("");
  const [Auto_DurInitiative, setAuto_DurInitiative] = useState("");
  const [Auto_Desc29, setAuto_Desc29] = useState("");
  const [Auto_Link29, setAuto_Link29] = useState("");
  const [Auto_Link30, setAuto_Link30] = useState("");
  const [Auto_Link31, setAuto_Link31] = useState("");

  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [Auto_Criteria07, setAuto_Criteria07] = useState([]);
  const [Value, setValue] = useState({});

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("AutoCri7.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>Physical Facilities</td>
                <td>${item?.Auto_Criteria07_007?.Auto_PhyFac}</td>
              </tr>
              <tr>
                <td>Provision for Lift</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Prolift}</td>
              </tr>
              <tr>
                <td>Ramp/ Rails</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Ramp}</td>
              </tr>
              <tr>
                <td>Braille Software/Facilities</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Braille}</td>
              </tr>
              <tr>
                <td>Rest Rooms</td>
                <td>${item?.Auto_Criteria07_007?.Auto_RestRoom}</td>
              </tr>
              <tr>
                <td>Scribes for Examination</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Scribes}</td>
              </tr><tr>
              <td>Special skill Development for Differently Abled Students</td>
              <td>${item?.Auto_Criteria07_007?.Auto_SpecialSkills}</td>
            </tr>
            <tr>
                <td>Any other Similar Facility</td>
                <td>${item?.Auto_Criteria07_007?.Auto_AnyOther}</td>
              </tr>
              <tr>
                <td>Annual expenditure excluding salary component of the institution</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Annual}</td>
              </tr>
              <tr>
                <td>Number of initiatives to address locational Advantages and Disadvantages</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfIniti1}</td>
              </tr>
              <tr>
                <td>Number of initiatives taken to Engage with and contribute to Local Community</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfIniti2}</td>
              </tr>
              <tr>
                <td>Date and Duration of the Initiative Name of the Initiative Issues Addressed</td>
                <td>${item?.Auto_Criteria07_007?.Auto_DateDuration}</td>
              </tr>
              <tr>
                <td>Power Requirement met by Renewable Energy Sources</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Power}</td>
              </tr>
              <tr>
                <td>Total Power requirements</td>
                <td>${item?.Auto_Criteria07_007?.Auto_TPower}</td>
              </tr>
              <tr>
                <td>Renewable Energy Source</td>
                <td>${item?.Auto_Criteria07_007?.Auto_ReEnergyS}</td>
              </tr>
              <tr>
                <td>Renewable Energy Generated and Used</td>
                <td>${item?.Auto_Criteria07_007?.Auto_ReEnergyG}</td>
              </tr>
              <tr>
              <td>Energy Supplied to the Grid</td>
              <td>${item?.Auto_Criteria07_007?.Auto_EnergySup}</td>
            </tr>
            <tr>
                <td>Percentage Lighting through LED bulbs</td>
                <td>${item?.Auto_Criteria07_007?.Auto_PercentageLED}</td>
              </tr>
              <tr>
                <td>Percentage Lighting through other sources</td>
                <td>${item?.Auto_Criteria07_007?.Auto_PercentageLight}</td>
              </tr>
              <tr>
                <td>Number of Participating Students and Staff</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfParticipants}</td>
              </tr>
              <tr>
                <td>No. of Activities</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfActivities}</td>
              </tr><tr>
              <td>TTitle of the Program/Activity</td>
              <td>${item?.Auto_Criteria07_007?.Auto_TitlePro}</td>
            </tr>
            <tr>
                <td>Number of Participants</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfParticipants1}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
          <table>
              <tr>
                <td>From</td>
                <td>${moment(item?.Auto_Criteria07_007?.Auto_Date1).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              <tr>
                <td>To</td>
                <td>${moment(item?.Auto_Criteria07_007?.Auto_Date2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              </table>
          `;
        let tableMarkup3 = `
          <table>
              <tr>
                <td>MALE</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Male}</td>
              </tr>
              <tr>
                <td>FEMALE</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Female}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Yea}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Total}</td>
              </tr>
              </table>
          `;
        let obj = {
          Year: item?.Auto_Year,
          "Total Lighting requirements":
            item?.Auto_Criteria07_007?.Auto_Lighting,
          "Budget allocated for green initiatives":
            item?.Auto_Criteria07_007?.Auto_Budget,
          "Status of implementation of CBCS / Elective Course System (ECS)":
            tableMarkup1,
          "Date and Duration": tableMarkup2,
          "Number of participants by Gender": tableMarkup3,
          "Power Requirement Renewable": item?.Auto_Criteria07_007?.Auto_PowRe,
          "Initiative names": item?.Auto_Criteria07_007?.Auto_InitiName,
          "Duration of the Initiative":
            item?.Auto_Criteria07_007?.Auto_DurInitiative,
          Description: item?.Auto_Criteria07_007?.Auto_Desc29,
          "Relevent Document": item?.Auto_Criteria07_007?.Auto_Link29,
          "Relevent Document": item?.Auto_Criteria07_007?.Auto_Link30,
          "Relevent Document": item?.Auto_Criteria07_007?.Auto_Link31,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editAuto_Criteria07_007 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc29;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Auto_Desc29) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editAuto_Criteria07_007/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Budget: Auto_Budget,
          Auto_Lighting: Auto_Lighting,
          Auto_PhyFac: Auto_PhyFac,
          Auto_Prolift: Auto_Prolift,
          Auto_Ramp: Auto_Ramp,
          Auto_Braille: Auto_Braille,
          Auto_RestRoom: Auto_RestRoom,
          Auto_Scribes: Auto_Scribes,
          Auto_SpecialSkills: Auto_SpecialSkills,
          Auto_AnyOther: Auto_AnyOther,
          Auto_Annual: Auto_Annual,
          Auto_NoOfIniti1: Auto_NoOfIniti1,
          Auto_NoOfIniti2: Auto_NoOfIniti2,
          Auto_Date1: Auto_Date1,
          Auto_DateDuration: Auto_DateDuration,
          Auto_Date2: Auto_Date2,
          Auto_Power: Auto_Power,
          Auto_TPower: Auto_TPower,
          Auto_ReEnergyS: Auto_ReEnergyS,
          Auto_ReEnergyG: Auto_ReEnergyG,
          Auto_EnergySup: Auto_EnergySup,
          Auto_PercentageLED: Auto_PercentageLED,
          Auto_PercentageLight: Auto_PercentageLight,
          Auto_NoOfParticipants: Auto_NoOfParticipants,
          Auto_NoOfActivities: Auto_NoOfActivities,
          Auto_TitlePro: Auto_TitlePro,
          Auto_NoOfParticipants1: Auto_NoOfParticipants1,
          Auto_Male: Auto_Male,
          Auto_Female: Auto_Female,
          Auto_Yea: Auto_Yea,
          Auto_Total: Auto_Total,
          Auto_PowRe: Auto_PowRe,
          Auto_InitiName: Auto_InitiName,
          Auto_DurInitiative: Auto_DurInitiative,
          Auto_Desc29: Auto_Desc29,
          Auto_Link29: Auto_Link29,
          Auto_Link30: Auto_Link30,
          Auto_Link31: Auto_Link31,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAuto_cri07details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getAuto_cri07details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getAuto_cri07details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.autocriteria07);
        setAuto_Criteria07(res.data.autocriteria07);
        setdata1(res.data.autocriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria07);
    }
  };

  useEffect(() => {
    getAuto_cri07details();
  }, []);
  console.log(Value);

  // =================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  // const ugCriteria07Details = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria7"
  //     );
  //     if (res.status === 200) {
  //       setAllDetails(res.data.ugcriteria07);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAllDetails(error.response.data.ugcriteria07);
  //   }
  // };

  // ================================================

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-07
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/autocriteria007">
                <button className="btn btn-success ">
                  Add Criteria 7{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>FacultyName</th>

                  <th>Criteria</th>
                  <th>Total Lighting requirements</th>
                  <th>Budget allocated for green initiatives:</th>
                  <th>Physical Facilities:</th>
                  <th>Provision for Lift:</th>
                  <th>Ramp/ Rails:</th>
                  <th>Braille Software/Facilities:</th>
                  <th>Rest Rooms:</th>
                  <th>Scribes for Examination</th>
                  <th>
                    Special skill Development for Differently Abled Students:
                  </th>
                  <th>Any other Similar Facility:</th>
                  <th>
                    Annual expenditure excluding salary component of the
                    institution
                  </th>
                  <th>
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </th>
                  <th>
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </th>
                  <th>
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </th>
                  <th>Power Requirement met by Renewable Energy Sources</th>
                  <th>Total Power requirements</th>
                  <th>Renewable Energy Source:</th>
                  <th>Renewable Energy Generated and Used :</th>
                  <th>Energy Supplied to the Grid:</th>
                  <th>Percentage Lighting through LED bulbs:</th>
                  <th>Percentage Lighting through other sources</th>
                  <th>Number of Participating Students and Staff:</th>
                  <th>No. of Activities:</th>
                  <th>TTitle of the Program/Activity:</th>
                  <th>Number of Participants:</th>
                  <th>From:</th>
                  <th>To:</th>
                  <th>MALE:</th>
                  <th>FEMALE:</th>
                  <th>Year</th>
                  <th>Total</th>
                  <th>Power Requirement Renewable</th>
                  <th>Initiative names:</th>
                  <th>Duration_of_the_Initiative</th>
                  <th>Description</th>
                  <th>Document 1</th>
                  <th>Document 2</th>
                  <th>Document 3</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Auto_Criteria07?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Auto_Year?.includes(SearchItem) ||
                      item1?.Auto_Criteria07_007?.Auto_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Lighting?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_PhyFac?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Prolift?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Ramp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Braille?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_RestRoom?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Scribes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_SpecialSkills?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_AnyOther?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Annual?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfIniti1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfIniti2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_DateDuration?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Power?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_TPower?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_ReEnergyS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_ReEnergyG?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_EnergySup?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_PercentageLED?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_PercentageLight?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfParticipants?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfActivities?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_TitlePro?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfParticipants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Auto_Criteria07_007?.Auto_Date1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Auto_Criteria07_007?.Auto_Date2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Auto_Criteria07_007?.Auto_Male?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Female?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Yea?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_PowRe?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_InitiName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_DurInitiative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Desc29?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Auto_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Auto_Criteria07_007?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Lighting}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Budget}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_PhyFac}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Prolift}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Ramp}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Braille}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_RestRoom}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Scribes}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_SpecialSkills}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_AnyOther}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Annual}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_NoOfIniti1}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_NoOfIniti2}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_DateDuration}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Power}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_TPower}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_ReEnergyS}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_ReEnergyG}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_EnergySup}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_PercentageLED}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_PercentageLight}</td>
                      <td>
                        {item?.Auto_Criteria07_007?.Auto_NoOfParticipants}
                      </td>
                      <td>{item?.Auto_Criteria07_007?.Auto_NoOfActivities}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_TitlePro}</td>
                      <td>
                        {item?.Auto_Criteria07_007?.Auto_NoOfParticipants1}
                      </td>
                      <td>
                        {moment(item?.Auto_Criteria07_007?.Auto_Date1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Auto_Criteria07_007?.Auto_Date2).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Male}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Female}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Yea}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_Total}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_PowRe}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_InitiName}</td>
                      <td>{item?.Auto_Criteria07_007?.Auto_DurInitiative}</td>
                      <td>
                        {item?.Auto_Criteria07_007?.Auto_Desc29?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 7 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Criteria07
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Lighting requirements
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Lighting}
                      onChange={(e) => {
                        setAuto_Lighting(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Lighting}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Budget allocated for green initiatives:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Budget}
                      onChange={(e) => {
                        setAuto_Budget(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Budget}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Status of implementation of CBCS / Elective Course System (ECS):
              </h6>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Physical Facilities:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      placeholder={Value?.Auto_Criteria07_007?.Auto_PhyFac}
                      onChange={(e) => {
                        setAuto_PhyFac(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_PhyFac}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Provision for Lift:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Prolift}
                      onChange={(e) => {
                        setAuto_Prolift(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Prolift}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Ramp/ Rails:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Ramp}
                      onChange={(e) => {
                        setAuto_Ramp(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Ramp}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Braille Software/Facilities:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Braille}
                      onChange={(e) => {
                        setAuto_Braille(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Braille}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Rest Rooms:{" "}
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      placeholder={Value?.Auto_Criteria07_007?.Auto_RestRoom}
                      onChange={(e) => {
                        setAuto_RestRoom(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_RestRoom}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Scribes for Examination
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Scribes}
                      onChange={(e) => {
                        setAuto_Scribes(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Scribes}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Special skill Development for Differently Abled Students:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "435px" }}
                      placeholder={
                        Value?.Auto_Criteria07_007?.Auto_SpecialSkills
                      }
                      onChange={(e) => {
                        setAuto_SpecialSkills(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_SpecialSkills}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Any other Similar Facility:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      placeholder={Value?.Auto_Criteria07_007?.Auto_AnyOther}
                      onChange={(e) => {
                        setAuto_AnyOther(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_AnyOther}</>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Annual expenditure excluding salary component of the
                  institution
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_Annual}
                    onChange={(e) => {
                      setAuto_Annual(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_Annual}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives to address locational Advantages and
                  Disadvantages
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_NoOfIniti1}
                    onChange={(e) => {
                      setAuto_NoOfIniti1(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_NoOfIniti1}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives taken to Engage with and contribute to
                  Local Community
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_NoOfIniti2}
                    onChange={(e) => {
                      setAuto_NoOfIniti2(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_NoOfIniti2}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Date and Duration of the Initiative Name of the Initiative
                  Issues Addressed
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_DateDuration}
                    onChange={(e) => {
                      setAuto_DateDuration(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_DateDuration}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Power Requirement met by Renewable Energy Sources
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_Power}
                    onChange={(e) => {
                      setAuto_Power(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_Power}</>
                )}
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Power requirements
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_TPower}
                      onChange={(e) => {
                        setAuto_TPower(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_TPower}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Source:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_ReEnergyS}
                      onChange={(e) => {
                        setAuto_ReEnergyS(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_ReEnergyS}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Generated and Used :
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_ReEnergyG}
                      onChange={(e) => {
                        setAuto_ReEnergyG(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_ReEnergyG}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Energy Supplied to the Grid:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_EnergySup}
                      onChange={(e) => {
                        setAuto_EnergySup(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_EnergySup}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Percentage Lighting through other sources
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={
                        Value?.Auto_Criteria07_007?.Auto_PercentageLight
                      }
                      onChange={(e) => {
                        setAuto_PercentageLight(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_PercentageLight}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participating Students and Staff:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={
                        Value?.Auto_Criteria07_007?.Auto_NoOfParticipants
                      }
                      onChange={(e) => {
                        setAuto_NoOfParticipants(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_NoOfParticipants}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Percentage Lighting through LED bulbs:
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria07_007?.Auto_PercentageLED
                        }
                        onChange={(e) => {
                          setAuto_PercentageLED(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Auto_Criteria07_007?.Auto_PercentageLED}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      No. of Activities:
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria07_007?.Auto_NoOfActivities
                        }
                        onChange={(e) => {
                          setAuto_NoOfActivities(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Auto_Criteria07_007?.Auto_NoOfActivities}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TTitle of the Program/Activity:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_TitlePro}
                      onChange={(e) => {
                        setAuto_TitlePro(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_TitlePro}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participants:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={
                        Value?.Auto_Criteria07_007?.Auto_NoOfParticipants1
                      }
                      onChange={(e) => {
                        setAuto_NoOfParticipants1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_NoOfParticipants1}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Date and Duration:
              </h6>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    From:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Date1}
                      onChange={(e) => {
                        setAuto_Date1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Date1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    To:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Date2}
                      onChange={(e) => {
                        setAuto_Date2(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Date2}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Number of participants by Gender:
              </h6>
              <div className="row">
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    MALE:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Male}
                      onChange={(e) => {
                        setAuto_Male(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Male}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    FEMALE:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Female}
                      onChange={(e) => {
                        setAuto_Female(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Female}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Yea}
                      onChange={(e) => {
                        setAuto_Yea(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Yea}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_Total}
                      onChange={(e) => {
                        setAuto_Total(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_Total}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement Renewable
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_PowRe}
                      onChange={(e) => {
                        setAuto_PowRe(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_PowRe}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Initiative names:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Auto_Criteria07_007?.Auto_InitiName}
                      onChange={(e) => {
                        setAuto_InitiName(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria07_007?.Auto_InitiName}</>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Duration_of_the_Initiative
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_DurInitiative}
                    onChange={(e) => {
                      setAuto_DurInitiative(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_DurInitiative}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Description
                </label>

                {edit1 ? (
                  <textarea
                    className="vi_0"
                    rows={3}
                    type="text"
                    name="email"
                    id="name"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_Desc29}
                    onChange={(e) => {
                      setAuto_Desc29(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_Desc29}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Document 1
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload1"
                    accept="image/*"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_Link29}
                    onChange={(e) => {
                      setAuto_Link29(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_Link29}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload2">
                  {" "}
                  Document 2
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload2"
                    accept="image/*"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_Link30}
                    onChange={(e) => {
                      setAuto_Link30(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_Link30}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Document 3
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload3"
                    accept="image/*"
                    placeholder={Value?.Auto_Criteria07_007?.Auto_Link31}
                    onChange={(e) => {
                      setAuto_Link31(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria07_007?.Auto_Link31}</>
                )}
              </div>
              {Value?.approve == "true" ? (
                <></>
              ) : (
                <div className="row mt-3">
                  <div
                    className="d-flex "
                    style={{
                      float: "right",
                      gap: "10px",
                      marginLeft: "35rem",
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => {
                        setedit1(true);
                      }}
                    >
                      Edit
                    </button>

                    <button
                      className="btn btn-success"
                      style={{ color: "white" }}
                      onClick={(e) => {
                        editAuto_Criteria07_007(e, Value?._id);
                        handleClose();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria07_007?.Auto_Desc29}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria07/${View?.Auto_Criteria07_007?.Auto_Link29}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria07/${View?.Auto_Criteria07_007?.Auto_Link30}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria07/${View?.Auto_Criteria07_007?.Auto_Link31}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Auto_Criteria07;
