import React from "react";

const UG3321aqar = React.memo(function ({ item }) {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Title of paper</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria03_321?.TPapaer}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the author/s</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_321?.AName}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Dept of the teacher</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_321?.TDept}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of journal</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_321?.JName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of publication</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_321?.PubliYear}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>ISSN number</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_321?.IISSN}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Is it listed in UGC Care list/Scopus/Web of Science/other, mention
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_321?.UUGC}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria03_321?.Desc14}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document 1</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria03/${item?.Criteria03_321?.Link14}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document 2</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria03/${item?.Criteria03_321?.Link15}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG3321aqar;
