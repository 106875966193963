import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

function AdminClgAUTO06_633() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  const [content, setContent] = useState([]);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Autonomous-6.3.3");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          params?.id === item1?.addedByPri &&
          item1?.approve === "true" &&
          selectedYear === item1?.Auto_Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          Year: item?.Auto_Year,
          "Date (from)": moment(
            item?.Auto_Criteria06_633?.Auto_Startdate
          ).format("DD-MM-YYYY"),
          "Date (to)": moment(item?.Auto_Criteria06_633?.Auto_Enddate).format(
            "DD-MM-YYYY"
          ),
          "Title of the professional development/ administrative training programs organised for teaching staff (Professional development/ administrative training programs)":
            item?.Auto_Criteria06_633?.Auto_title,
          "No. of participants": item?.Auto_Criteria06_633?.Auto_participants,
          Description: item?.Auto_Criteria06_633?.Auto_desc3,
          "Relevant document": item?.Auto_Criteria06_633?.Auto_doc6,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/PrinipalAdmin/Autocri06_633"
      );
      if (res.status === 200) {
        setContent(res.data.getAuto_C633);
        setdata1(res.data.getAuto_C633);
      }
    } catch (error) {
      console.log(error);
      setContent(error.response.data.getAuto_C633);
    }
  };
  console.log("content", content);

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getContent();
      getAllYears();
    }
  }, []);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-12">
              <h4 className="mt-4"> Autonomous Criteria 6 6.3.3</h4>
              <div className="ad-b mt-4 row align-items-center">
                <div className=" col-lg-3 do-sear mt-4 d-flex ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="vi_0"
                    onChange={(e) => setSearchItem(e.target.value)}
                  />
                </div>

                <div className=" col-lg-2 lab">
                  <label>From</label>
                  <br />
                  <DatePicker
                    onChange={onChangeFrom}
                    picker="year"
                    className="vi_0"
                  />
                </div>
                <div className=" col-lg-2 lab">
                  <label>To</label>
                  <br />
                  <DatePicker
                    onChange={onChangeTo}
                    picker="year"
                    className="vi_0"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ad-b  row align-items-center">
            <div className=" col-lg-4 do-sear  d-flex align-items-center   ">
              <label style={{ width: "130px" }}>Select Year</label>
              <select
                className="criteriaform"
                style={{ width: "150px" }}
                onChange={(e) => setselectedYear(e.target.value)}
              >
                <option>Select Year</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-lg-6">
              <Nav defaultActiveKey="/autocriteria06">
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-1"
                    onClick={() =>
                      navigate(`/adminclgautocri6623/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.2.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    onClick={() =>
                      navigate(`/adminclgautocri6632/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.3.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgautocri6633/${params?.id}`)
                    }
                  >
                    <Button
                      variant="warning"
                      style={{ backgroundColor: "#ffc107" }}
                      className="criteriabtn-0"
                    >
                      6.3.3
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgautocri6634/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.3.4</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgautocri6642/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.4.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgautocri6653/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.5.3</Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className=" col-lg-2 text-end ">
              <Button onClick={ExportToExcel}>Export Excel</Button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>
                    Title of the professional development/ administrative
                    training programs organised for teaching staff (Professional
                    development/ administrative training programs)
                  </th>
                  <th>No. of participants</th>
                  <th>Description</th>
                  <th>Relevant document</th>
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Auto_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      params?.id === item1?.addedByPri &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Auto_Year &&
                      (SearchItem === "" ||
                        item1?.Auto_Year?.includes(SearchItem) ||
                        moment(item1?.Auto_Criteria06_633?.Auto_Startdate)
                          .format("DD-MM-YYYY")
                          ?.toString()
                          ?.includes(SearchItem) ||
                        moment(item1?.Auto_Criteria06_633?.Auto_Enddate)
                          .format("DD-MM-YYYY")
                          ?.toString()
                          ?.includes(SearchItem) ||
                        item1?.Auto_Criteria06_633?.Auto_title?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Auto_Criteria06_633?.Auto_participants?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria06_633?.Auto_desc3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?.Auto_Year}</td>

                        <td>
                          {moment(
                            item?.Auto_Criteria06_633?.Auto_Startdate
                          ).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          {moment(
                            item?.Auto_Criteria06_633?.Auto_Enddate
                          ).format("DD-MM-YYYY")}
                        </td>
                        <td>{item?.Auto_Criteria06_633?.Auto_title}</td>
                        <td>{item?.Auto_Criteria06_633?.Auto_participants}</td>
                        <td>{item?.Auto_Criteria06_633?.Auto_desc3}</td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            fontSize={20}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_633?.Auto_doc6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AdminClgAUTO06_633;
