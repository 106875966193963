import React, { useEffect, useState } from "react";
import "./Styles/form.css";
import axios from "axios";
import { State, City } from "country-state-city";
const RegisterForm = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const stateData = State.getStatesOfCountry("IN");
  console.log("lilili1:", stateData);
  console.log("lilili2:", City.getCitiesOfState("IN", "CT"));
  // console.log("kiki: ", Country);
  // console.log(
  //   "lilili3:",
  //   State?.getStatesOfCountry(selectedCountry?.isoCode)
  // );
  const [Heiname, setHeiname] = useState("");
  const [HeiCode, setHeiCode] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Registered_By, setRegistered_By] = useState("Principal / IQAC");
  const [PhoneNo, setPhoneNo] = useState();
  const [LandNo, setLandNo] = useState();
  const [HEI, setHEI] = useState();
  const [WebsiteLink, setWebsiteLink] = useState("");
  const [PrincipalName, setPrincipalName] = useState("");
  const [Address, setAddress] = useState("");
  const [Country, setCountry] = useState("India");
  const [State1, setState1] = useState("");
  const [City1, setCity1] = useState("");
  const [Pincode, setPincode] = useState("");
  const [CImage, setCImage] = useState("");
  const [Clogo, setClogo] = useState("");

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;
  var HEI_Name = /^[a-zA-Z][a-zA-Z]/;
  let passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,10}$/;
  const register = async (e) => {
    e.preventDefault();
    if (!Heiname) {
      return alert("Enter Your name");
    }
    if (!Heiname.match(HEI_Name)) {
      return alert("Please Enter Valid Name");
    }

    if (!HeiCode) {
      return alert("Enter Hei Code");
    }

    if (!Email) {
      return alert("Enter Your Email");
    }

    if (!Email.match(validRegex)) {
      return alert("Please Enter Valid Email-Id");
    }
    if (!Password) {
      return alert("Enter Your Password");
    }
    // if (!Password.match(passwordRegex)) {
    //   return alert(
    //     "Please Enter Valid Password! Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    //   );
    // }
    if (!PhoneNo) {
      return alert("Enter Contact Number");
    }
    if (PhoneNo.length !== 10) {
      return alert("Enter Contact Number should be 10 digits");
    }

    if (!PhoneNo.match(mobilevalid)) {
      return alert("Enter Valid Contact Number");
    }

    if (!LandNo) {
      return alert("Enter Contact Number");
    }
    if (LandNo.length !== 10) {
      return alert("Enter Contact Number should be 10 digits");
    }
    if (!LandNo.match(mobilevalid)) {
      return alert("Enter Valid Contact Number");
    }
    if (!Registered_By) {
      return alert("Enter Your Spefication");
    }

    if (!HEI) {
      return alert("Select Your HEI");
    }
    if (!WebsiteLink) {
      return alert("Copy & Paste Your Website link ");
    }
    if (!PrincipalName) {
      return alert("Enter Principal Name");
    }
    if (!Address) {
      return alert("Enter Address");
    }

    if (!Country) {
      return alert("Enter Your Country ");
    }
    if (!State1) {
      return alert("Enter State ");
    }

    if (!City1) {
      return alert("Enter Your City");
    }
    if (!Pincode) {
      return alert("Enter Your Pincode");
    }
    if (!CImage) {
      return alert("Upload College Image");
    }
    if (!Clogo) {
      return alert("Upload College Logo");
    }
    try {
      const config = {
        url: "/pri/userRegister",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          HeiName: Heiname,
          HeiCode: HeiCode,
          Email: Email,
          password: Password,
          Registered_By: Registered_By,
          PNumber: PhoneNo,
          HeiLandNumber: LandNo,
          HEI: HEI,
          WebsiteLink: WebsiteLink,
          PrincipalName: PrincipalName,
          Address: Address,
          Country: Country,
          State: State1?.name,
          City: City1?.name,
          Pincode: Pincode,
          CollegeImg: CImage,
          CollegeLogo: Clogo,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          window.location.assign("/success");
          setHeiname("");
          setHeiCode("");
          setEmail("");
          setPassword("");
          setRegistered_By("Principal / IQAC");
          setPhoneNo("");
          setLandNo("");
          setHEI("");
          setWebsiteLink("");
          setPrincipalName("");
          setAddress("");
          setCountry("");
          setState1("");
          setCity1("");
          setPincode("");
          setCImage("");
          setClogo("");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }

    console.log(register);
  };
  return (
    <div className="rigesterpage">
      <div className="container">
        <div className="row  align-items-center justify-content-center">
          <div
            className="col-lg-8 border mb-5 mt-5"
            style={{
              background: "white",
            }}
          >
            <p className="p-3 border-bottom text-center contact-form-text">
              <h4> Registration Form</h4>
            </p>
            <div className="m-auto">
              <form className="p-4">
                <div className="row">
                  <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      HEI Name
                    </label>
                    <input
                      type="text"
                      class="form-control border"
                      placeholder=" HEI Name"
                      onChange={(e) => setHeiname(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      HEI Code :
                    </label>
                    <input
                      type="number"
                      class="form-control border"
                      placeholder="Enter HEI Code"
                      onChange={(e) => setHeiCode(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      class="form-control border"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      password
                    </label>
                    <input
                      type="password"
                      class="form-control border"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      Registered By
                    </label>

                    <input
                      type="text"
                      class="form-control border"
                      placeholder="Principal / IQAC"
                      // value="Principal"
                      disabled
                      onChange={(e) => setRegistered_By("Principal / IQAC")}
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      Phone No
                    </label>
                    <input
                      type="number"
                      class="form-control border"
                      placeholder=" Phone No"
                      onChange={(e) => setPhoneNo(e.target.value)}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      HEI Land Line :
                    </label>
                    <input
                      type="number"
                      class="form-control border"
                      placeholder="Land Line"
                      onChange={(e) => setLandNo(e.target.value)}
                    />
                  </div>
                </div>

                <div class="  col-2">
                  <label for="exampleFormControlInput1" className="form-label">
                    HEI :
                  </label>
                </div>

                <div className="row mt-2 mb-3">
                  <div className="col-2" id="Rtype">
                    <input
                      name="type"
                      type="radio"
                      id="exampleFormControlInput1"
                      onChange={(e) => setHEI(e.target.value)}
                      value={"UG&PG"}
                    />{" "}
                    <label class="form-label">UG & PG</label>
                  </div>

                  <div className="col-3" id="Rtype">
                    <input
                      name="type"
                      type="radio"
                      class=" "
                      id="exampleFormControlInput1"
                      onChange={(e) => setHEI(e.target.value)}
                      value={"Autonomous"}
                    />{" "}
                    <label class="form-label">Autonomous</label>
                  </div>

                  <div className="col-3" id="Rtype">
                    <input
                      name="type"
                      type="radio"
                      class=" "
                      onChange={(e) => setHEI(e.target.value)}
                      value={"University"}
                    />{" "}
                    <label class="form-label">University</label>
                  </div>
                  <div className="col-2" id="Rtype">
                    <input
                      name="type"
                      type="radio"
                      class=" "
                      id="exampleFormControlInput1"
                      onChange={(e) => setHEI(e.target.value)}
                      value={"UG"}
                    />{" "}
                    <label class="form-label">UG</label>
                  </div>

                  <div className="col-2" id="Rtype">
                    <input
                      name="type"
                      type="radio"
                      class=" "
                      onChange={(e) => setHEI(e.target.value)}
                      value={"PG"}
                    />{" "}
                    <label class="form-label">PG</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 mt-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      HEI Website Link
                    </label>

                    <input
                      type="text"
                      class="form-control border"
                      placeholder="Copy & Paste Website Link"
                      onChange={(e) => setWebsiteLink(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Principal Name
                    </label>

                    <input
                      type="text"
                      class="form-control border"
                      placeholder="Enter Principal Name"
                      onChange={(e) => setPrincipalName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Address :
                    </label>
                    <input
                      type="text"
                      class="form-control border"
                      placeholder="Enter Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  {/* <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      Country
                    </label>
                    <select
                      class="form-control border"
                      placeholder="Principal / IQAC"
                      // value="Principal"

                      onChange={(e) => {
                        setSelectedCountry(JSON.parse(e.target.value));
                        setCountry(e.target.value);
                      }}
                    >
                      <option>Choose Country</option>
                      {State.getStatesOfCountry("IN")?.map((countryDetails) => {
                        return (
                          <option value={JSON.stringify(countryDetails)}>
                            India
                          </option>
                        );
                      })}
                    </select>
                  </div> */}

                  <div class="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      State
                    </label>

                    <select
                      class="form-control border"
                      placeholder="Principal / IQAC"
                      // value="Principal"

                      onChange={(e) => setState1(JSON.parse(e.target.value))}
                    >
                      {State.getStatesOfCountry("IN")?.map((stateDetails) => {
                        return (
                          <option value={JSON.stringify(stateDetails)}>
                            {stateDetails?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      City
                    </label>
                    <select
                      class="form-control border"
                      placeholder=""
                      // value="Principal"

                      onChange={(e) => setCity1(JSON.parse(e.target.value))}
                    >
                      {City.getCitiesOfState("IN", State1?.isoCode)?.map(
                        (cityDetails) => {
                          return (
                            <option value={JSON.stringify(cityDetails)}>
                              {cityDetails?.name}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      Pincode
                    </label>

                    <input
                      type="number"
                      class="form-control border"
                      placeholder="Enter Pincode"
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                      htmlFor="upload1"
                    >
                      Upload HEI Image
                    </label>

                    <input
                      type="file"
                      class="form-control border"
                      id="upload1"
                      onChange={(e) => setCImage(e.target.files[0])}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                      htmlFor="upload2"
                    >
                      Upload HEI Logo
                    </label>

                    <input
                      type="file"
                      className="form-control border"
                      id="upload2"
                      onChange={(e) => setClogo(e.target.files[0])}
                    />
                  </div>
                </div>

                <div className="mt-3 text-center">
                  <button className="btn-submit " onClick={(e) => register(e)}>
                    {" "}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
