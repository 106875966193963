import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

const Auto_Criteria05 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);
  const [edit8, setedit8] = useState(false);

  const [Auto_Scheme, setAuto_Scheme] = useState("");
  const [Auto_Std, setAuto_Std] = useState("");
  const [Auto_Amt, setAuto_Amt] = useState("");
  const [Auto_Std1, setAuto_Std1] = useState("");
  const [Auto_Amt1, setAuto_Amt1] = useState("");
  const [Auto_Std2, setAuto_Std2] = useState("");
  const [Auto_Amt2, setAuto_Amt2] = useState("");
  const [Auto_NGO, setAuto_NGO] = useState("");
  const [Auto_Desc18, setAuto_Desc18] = useState("");
  const [Auto_Link18, setAuto_Link18] = useState("");

  const [Auto_Capabality, setAuto_Capabality] = useState("");
  const [Auto_Date, setAuto_Date] = useState("");
  const [Auto_Enrolled, setAuto_Enrolled] = useState("");
  const [Auto_Agencies, setAuto_Agencies] = useState("");
  const [Auto_Desc19, setAuto_Desc19] = useState("");
  const [Auto_Link19, setAuto_Link19] = useState("");

  const [Auto_Activity, setAuto_Activity] = useState("");
  const [Auto_Attended, setAuto_Attended] = useState("");
  const [Auto_Placed, setAuto_Placed] = useState("");
  const [Auto_Desc20, setAuto_Desc20] = useState("");
  const [Auto_Link20, setAuto_Link20] = useState("");

  const [Auto_Placed1, setAuto_Placed1] = useState("");
  const [Auto_Graduate, setAuto_Graduate] = useState("");
  const [Auto_employer, setAuto_employer] = useState("");
  const [Auto_Package, setAuto_Package] = useState("");
  const [Auto_Desc21, setAuto_Desc21] = useState("");
  const [Auto_Link21, setAuto_Link21] = useState("");

  const [Auto_Higher, setAuto_Higher] = useState("");
  const [Auto_Graduat1, setAuto_Graduat1] = useState("");
  const [Auto_Institute, setAuto_Institute] = useState("");
  const [Auto_Admit, setAuto_Admit] = useState("");
  const [Auto_Desc22, setAuto_Desc22] = useState("");
  const [Auto_Link22, setAuto_Link22] = useState("");

  const [Auto_No, setAuto_No] = useState("");
  const [Auto_RollNo, setAuto_RollNo] = useState("");
  const [Auto_StdSelected, setAuto_StdSelected] = useState("");
  const [Auto_NET, setAuto_NET] = useState("");
  const [Auto_SLET, setAuto_SLET] = useState("");
  const [Auto_GATE, setAuto_GATE] = useState("");
  const [Auto_GMAT, setAuto_GMAT] = useState("");
  const [Auto_CAT, setAuto_CAT] = useState("");
  const [Auto_GRE, setAuto_GRE] = useState("");
  const [Auto_JAM, setAuto_JAM] = useState("");
  const [Auto_IELTS, setAuto_IELTS] = useState("");
  const [Auto_TOEFL, setAuto_TOEFL] = useState("");
  const [Auto_Civil, setAuto_Civil] = useState("");
  const [Auto_State, setAuto_State] = useState("");
  const [Auto_OtherExam, setAuto_OtherExam] = useState("");
  const [Auto_Total, setAuto_Total] = useState("");
  const [Auto_GTotal, setAuto_GTotal] = useState("");
  const [Auto_Desc23, setAuto_Desc23] = useState("");
  const [Auto_Link23, setAuto_Link23] = useState("");

  const [Auto_Medal, setAuto_Medal] = useState("");
  const [Auto_Individual, setAuto_Individual] = useState("");
  const [Auto_Inter, setAuto_Inter] = useState("");
  const [Auto_Event, setAuto_Event] = useState("");
  const [Auto_Student, setAuto_Student] = useState("");
  const [Auto_Desc24, setAuto_Desc24] = useState("");
  const [Auto_Link24, setAuto_Link24] = useState("");

  const [Auto_Event1, setAuto_Event1] = useState("");
  const [Auto_Event2, setAuto_Event2] = useState("");
  const [Auto_Desc25, setAuto_Desc25] = useState("");
  const [Auto_Link25, setAuto_Link25] = useState("");

  //integrating get method
  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);
  const [Auto_Criteria05, setAuto_Criteria05] = useState([]);
  const [Value, setValue] = useState({});

  //   ===============================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Autonomous_Criteria-05");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let table1 = `
          <table>
            <thead>
              <th>Number of students</th>
              <th>Amount</th>
            </thead>
            <tbody>
              <tr>
                <td>${item?.Auto_Criteria05_511?.Auto_Std}</td>
                <td>${item?.Auto_Criteria05_511?.Auto_Amt}</td>
              </tr>
            </tbody>
          </table>
        `;
        let table2 = `
          <table>
            <thead>
              <th>Number of students</th>
              <th>Amount</th>
            </thead>
            <tbody>
              <tr>
                <td>${item?.Auto_Criteria05_511?.Auto_Std1}</td>
                <td>${item?.Auto_Criteria05_511?.Auto_Amt1}</td>
              </tr>
            </tbody>
          </table>
        `;
        let table3 = `
          <table>
            <thead>
              <th>Number of students</th>
              <th>Amount</th>
            </thead>
            <tbody>
              <tr>
                <td>${item?.Auto_Criteria05_511?.Auto_Std2}</td>
                <td>${item?.Auto_Criteria05_511?.Auto_Amt2}</td>
              </tr>
            </tbody>
          </table>
        `;
        let obj = {
          //  Criteria 5_511

          Year: item?.Auto_Year,
          "Criteria_5.1.1": item?.Auto_Criteria05_511?.Auto_criteria,
          "Name of the scheme": item?.Auto_Criteria05_511?.Auto_Scheme,
          "Number of students benefited by government scheme and amount":
            table1,
          "Number of students benefited by the institution's schemes and amount":
            table2,
          "Number of students benefited by the non-government agencies (NGOs) and amount":
            table3,
          "Name of the NGO/agency": item?.Auto_Criteria05_511?.Auto_NGO,
          "Description (min 500 words)(5.1.1)":
            item?.Auto_Criteria05_511?.Auto_Desc18,
          "Upload the relevant document(5.1.1)":
            item?.Auto_Criteria05_511?.Auto_Link18,

          // Criteria 5_513

          "Criteria_5.1.3": item?.Auto_Criteria05_513?.Auto_criteria,
          "Name of the capability enhancement program":
            item?.Auto_Criteria05_513?.Auto_Capabality,
          "Date of implementation (DD-MM-YYYY)": moment(
            item?.Auto_Criteria05_513?.Auto_Date
          ).format("DD-MM-YYYY"),
          "Number of students enrolled":
            item?.Auto_Criteria05_513?.Auto_Enrolled,
          "Name of the agencies/consultants involved with contact details (if any)":
            item?.Auto_Criteria05_513?.Auto_Agencies,
          "Description (min 500 words)(5.1.3)":
            item?.Auto_Criteria05_513?.Auto_Desc19,
          "Upload the relevant document(5.1.3)":
            item?.Auto_Criteria05_513?.Auto_Link19,

          // Criteria 5_514
          "Criteria_5.1.4": item?.Auto_Criteria05_514?.Auto_criteria,
          "Name of the Activity conducted by the HEI to offer guidance for competitive examinations/ career counselling offered by the institution during the last five years":
            item?.Auto_Criteria05_514?.Auto_Activity,
          "Number of students attended / participated":
            item?.Auto_Criteria05_514?.Auto_Attended,
          "Number of students placed through campus placement":
            item?.Auto_Criteria05_514?.Auto_Placed,
          "Description (min 500 words)(5.1.4)":
            item?.Auto_Criteria05_514?.Auto_Desc20,
          "Upload the relevant document(5.1.4)":
            item?.Auto_Criteria05_514?.Auto_Link20,

          // Criteria 5_521
          "Criteria_5.2.1": item?.Auto_Criteria05_521?.Auto_criteria,
          "Name of student placed": item?.Auto_Criteria05_521?.Auto_Placed1,
          "Program-graduated-from": item?.Auto_Criteria05_521?.Auto_Graduate,
          "Name of the employer": item?.Auto_Criteria05_521?.Auto_employer,
          "Pay package at appointment": item?.Auto_Criteria05_521?.Auto_Package,
          "Description (min 500 words)(5.2.1)":
            item?.Auto_Criteria05_521?.Auto_Desc21,
          "Upload the relevant document(5.2.1)":
            item?.Auto_Criteria05_521?.Auto_Link21,

          // Criteria 5_522
          "Criteria_5.2.2": item?.Auto_Criteria05_522?.Auto_criteria,
          "Name of student enrolling into higher education":
            item?.Auto_Criteria05_522?.Auto_Higher,
          Program_graduated_from: item?.Auto_Criteria05_522?.Auto_Graduat1,
          "Name of institution joined":
            item?.Auto_Criteria05_522?.Auto_Institute,
          "Name of programme admitted to":
            item?.Auto_Criteria05_522?.Auto_Admit,
          "Description min 500 words(5.2.2)":
            item?.Auto_Criteria05_522?.Auto_Desc22,
          "Link to website of the Journal(5.2.2)":
            item?.Auto_Criteria05_522?.Auto_Link22,

          // Criteria 5_523
          "Criteria_5.2.3": item?.Auto_Criteria05_523?.Auto_criteria,
          "Sl.no.": item?.Auto_Criteria05_523?.Auto_No,
          "Registration number/roll number for the exam":
            item?.Auto_Criteria05_523?.Auto_RollNo,
          "Names of students selected/ qualified":
            item?.Auto_Criteria05_523?.Auto_StdSelected,
          NET: item?.Auto_Criteria05_523?.Auto_NET,
          SLET: item?.Auto_Criteria05_523?.Auto_SLET,
          GATE: item?.Auto_Criteria05_523?.Auto_GATE,
          GMAT: item?.Auto_Criteria05_523?.Auto_GMAT,
          CAT: item?.Auto_Criteria05_523?.Auto_CAT,
          GRE: item?.Auto_Criteria05_523?.Auto_GRE,
          JAM: item?.Auto_Criteria05_523?.Auto_JAM,
          IELTS: item?.Auto_Criteria05_523?.Auto_IELTS,
          TOEFL: item?.Auto_Criteria05_523?.Auto_TOEFL,
          "Civil Services": item?.Auto_Criteria05_523?.Auto_Civil,
          "State government examinations":
            item?.Auto_Criteria05_523?.Auto_State,
          "Other examinations conducted by the State / Central Government Agencies (Specify)":
            item?.Auto_Criteria05_523?.Auto_OtherExam,
          Total: item?.Auto_Criteria05_523?.Auto_Total,
          "Grand Total": item?.Auto_Criteria05_523?.Auto_GTotal,
          "Description (min 500 words)(5.2.3)":
            item?.Auto_Criteria05_523?.Auto_Desc23,
          "Upload the relevant document(5.2.3)":
            item?.Auto_Criteria05_523?.Auto_Link23,

          // Criteria 5_531
          "Criteria_5.3.1": item?.Auto_Criteria05_531?.Auto_criteria,
          "Name of the award/ medal ": item?.Auto_Criteria05_531?.Auto_Medal,
          "Team / Individual": item?.Auto_Criteria05_531?.Auto_Individual,
          "inter-university / state / National/ International":
            item?.Auto_Criteria05_531?.Auto_Inter,
          "Name of the event": item?.Auto_Criteria05_531?.Auto_Event,
          "Name of the student": item?.Auto_Criteria05_531?.Auto_Student,
          "Description min 500 words(5.3.1)":
            item?.Auto_Criteria05_531?.Auto_Desc24,
          "Link to relevnt document(5.3.1)":
            item?.Auto_Criteria05_531?.Auto_Link24,

          // Criteria 5_533
          "Criteria_5.3.3": item?.Auto_Criteria05_533?.Auto_criteria,
          "Date of event/competition (DD-MM-YYYY) ":
            item?.Auto_Criteria05_533?.Auto_Event1,
          "Name of the event/competition":
            item?.Auto_Criteria05_533?.Auto_Event2,
          "Description min 500 words(5.3.3)":
            item?.Auto_Criteria05_533?.Auto_Desc25,
          "Link to relevnt document(5.3.3)":
            item?.Auto_Criteria05_533?.Auto_Link25,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editAuto_Criteria05_511 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc18) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editAuto_Criteria05_511/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Scheme: Auto_Scheme,
          Auto_Std: Auto_Std,
          Auto_Amt: Auto_Amt,
          Auto_Std1: Auto_Std1,
          Auto_Amt1: Auto_Amt1,
          Auto_Std2: Auto_Std2,
          Auto_Amt2: Auto_Amt2,
          Auto_NGO: Auto_NGO,
          Auto_Desc18: Auto_Desc18,
          Auto_Link18: Auto_Link18,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri05details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria05_513 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc19) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria05_513/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Capabality: Auto_Capabality,
          Auto_Date: Auto_Date,
          Auto_Enrolled: Auto_Enrolled,
          Auto_Agencies: Auto_Agencies,
          Auto_Desc19: Auto_Desc19,
          Auto_Link19: Auto_Link19,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri05details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria05_514 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc20;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc20) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria05_514/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Activity: Auto_Activity,
          Auto_Attended: Auto_Attended,
          Auto_Placed: Auto_Placed,
          Auto_Desc20: Auto_Desc20,
          Auto_Link20: Auto_Link20,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri05details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria05_521 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc21) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria05_521/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Placed1: Auto_Placed1,
          Auto_Graduate: Auto_Graduate,
          Auto_employer: Auto_employer,
          Auto_Package: Auto_Package,
          Auto_Desc21: Auto_Desc21,
          Auto_Link21: Auto_Link21,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri05details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editAuto_Criteria05_522 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc22;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc22) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria05_522/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Higher: Auto_Higher,
          Auto_Graduat1: Auto_Graduat1,
          Auto_Institute: Auto_Institute,
          Auto_Admit: Auto_Admit,
          Auto_Desc22: Auto_Desc22,
          Auto_Link22: Auto_Link22,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri05details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria05_523 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc23) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria05_523/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_No: Auto_No,
          Auto_RollNo: Auto_RollNo,
          Auto_StdSelected: Auto_StdSelected,
          Auto_NET: Auto_NET,
          Auto_SLET: Auto_SLET,
          Auto_GATE: Auto_GATE,
          Auto_GMAT: Auto_GMAT,
          Auto_CAT: Auto_CAT,
          Auto_GRE: Auto_GRE,
          Auto_JAM: Auto_JAM,

          Auto_IELTS: Auto_IELTS,
          Auto_TOEFL: Auto_TOEFL,
          Auto_Civil: Auto_Civil,
          Auto_State: Auto_State,
          Auto_OtherExam: Auto_OtherExam,
          Auto_Total: Auto_Total,
          Auto_GTotal: Auto_GTotal,
          Auto_Desc23: Auto_Desc23,
          Auto_Link23: Auto_Link23,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri05details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria05_531 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc24;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc24) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria05_531/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Medal: Auto_Medal,
          Auto_Individual: Auto_Individual,
          Auto_Inter: Auto_Inter,
          Auto_Event: Auto_Event,
          Auto_Student: Auto_Student,
          Auto_Desc24: Auto_Desc24,
          Auto_Link24: Auto_Link24,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri05details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria05_533 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc25;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc25) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria05_533/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Event1: Auto_Event1,
          Auto_Event2: Auto_Event2,
          Auto_Desc25: Auto_Desc25,
          Auto_Link25: Auto_Link25,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri05details();
          setedit8(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getautocri05details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getautocri05details"
      );
      if (res.status == 200) {
        setAllDetails(res.data.autocriteria05);
        setAuto_Criteria05(res.data.autocriteria05);
        setdata1(res.data.autocriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria05);
    }
  };

  useEffect(() => {
    getautocri05details();
  }, []);
  console.log(Auto_Criteria05);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  // const autoCriteria01Details = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria1"
  //     );
  //     if (res.status === 200) {
  //       setAllDetails(res.data.pgcriteria01);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAllDetails(error.response.data.pgcriteria01);
  //   }
  // };

  return (
    <div>
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-05
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/autocriteria005">
                <button className="btn btn-success ">
                  Add Criteria 5
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Name of the scheme</th>
                  <th>
                    Number of students benefited by government scheme and amount
                  </th>
                  <th>
                    Number of students benefited by the institution's schemes
                    and amount
                  </th>
                  <th>
                    Number of students benefited by the non-government agencies
                    (NGOs) and amount
                  </th>
                  <th>Name of the NGO/agency</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the capability enhancement program</th>
                  <th>Date of implementation (DD-MM-YYYY)</th>
                  <th>Number of students enrolled</th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counselling offered by
                    the institution during the last five years
                  </th>
                  <th>Number of students attended / participated</th>
                  <th>Number of students placed through campus placement</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student placed</th>
                  <th>Program graduated from</th>
                  <th>Name of the employer</th>
                  <th>Pay package at appointment</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student enrolling into higher education</th>
                  <th>Program graduated from</th>
                  <th>Name of institution joined</th>
                  <th>Name of programme admitted to</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>criteria</th>
                  <th>Sl.no.</th>
                  <th>Registration number/roll number for the exam</th>
                  <th>Names of students selected/ qualified</th>
                  <th>NET</th>
                  <th>SLET</th>
                  <th>GATE</th>
                  <th>GMAT</th>
                  <th>CAT</th>
                  <th>GRE</th>
                  <th>JAM</th>
                  <th>IELTS</th>
                  <th>TOEFL</th>
                  <th>Civil Services</th>
                  <th>State government examinations</th>
                  <th>
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </th>
                  <th>Total</th>
                  <th>Grand Total</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>inter-university / state / National/ International</th>
                  <th>Name of the event</th>
                  <th>Name of the student</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Date of event/competition (DD-MM-YYYY)</th>
                  <th>Name of the event/competition</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Auto_Criteria05?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Auto_Criteria05_511?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_511?.Auto_Scheme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_511?.Auto_Std?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_511?.Auto_Amt?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_511?.Auto_Std1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_511?.Auto_Amt1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_511?.Auto_Std2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_511?.Auto_Amt2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_511?.Auto_NGO?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_511?.Auto_Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_513?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_513?.Auto_Capabality?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Auto_Criteria05_513?.Auto_Date)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Auto_Criteria05_513?.Auto_Enrolled?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_513?.Auto_Agencies?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_513?.Auto_Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_514?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_514?.Auto_Activity?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_514?.Auto_Attended?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_514?.Auto_Placed?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_514?.Auto_Desc20?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_521?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_521?.Auto_Placed1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_521?.Auto_Graduate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_521?.Auto_employer?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_521?.Auto_Package?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_521?.Auto_Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_522?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_522?.Auto_Higher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_522?.Auto_Graduat1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_522?.Auto_Institute?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_522?.Auto_Admit?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_522?.Auto_Desc22?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_No?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_RollNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_StdSelected?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_NET?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_SLET?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_GATE?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_GMAT?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_CAT?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_GRE?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_JAM?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_IELTS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_TOEFL?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_Civil?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_State?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_OtherExam?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_GTotal?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_523?.Auto_Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_531?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria05_531?.Auto_Medal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_531?.Auto_Individual?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_531?.Auto_Inter?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_531?.Auto_Event?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_531?.Auto_Student?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria05_531?.Auto_Desc24?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteroa05_533?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Auto_Criteroa05_533?.Auto_Event1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Auto_Criteroa05_533?.Auto_Event2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteroa05_533?.Auto_Desc25?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Auto_Year}</td>
                      <td>{Facultydetails?.FName}</td>
                      <td>{item?.Auto_Criteria05_511?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria05_511?.Auto_Scheme}</td>
                      <td>
                        <Table>
                          <thead>
                            <th>Number of students</th>
                            <th>Amount</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Auto_Criteria05_511?.Auto_Std}</td>
                              <td>{item?.Auto_Criteria05_511?.Auto_Amt}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <th>Number of students</th>
                            <th>Amount</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Auto_Criteria05_511?.Auto_Std1}</td>
                              <td>{item?.Auto_Criteria05_511?.Auto_Amt1}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <th>Number of students</th>
                            <th>Amount</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Auto_Criteria05_511?.Auto_Std2}</td>
                              <td>{item?.Auto_Criteria05_511?.Auto_Amt2}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>{" "}
                      <td>{item?.Auto_Criteria05_511?.Auto_NGO}</td>
                      <td>
                        {item?.Auto_Criteria05_511?.Auto_Desc18?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria05_513?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria05_513?.Auto_Capabality}</td>
                      <td>
                        {moment(item?.Auto_Criteria05_513?.Auto_Date).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Auto_Criteria05_513?.Auto_Enrolled}</td>
                      <td>{item?.Auto_Criteria05_513?.Auto_Agencies}</td>
                      <td>
                        {item?.Auto_Criteria05_513?.Auto_Desc19?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria05_514?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria05_514?.Auto_Activity}</td>
                      <td>{item?.Auto_Criteria05_514?.Auto_Attended}</td>
                      <td>{item?.Auto_Criteria05_514?.Auto_Placed}</td>
                      <td>
                        {item?.Auto_Criteria05_514?.Auto_Desc20?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria05_521?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria05_521?.Auto_Placed1}</td>
                      <td>{item?.Auto_Criteria05_521?.Auto_Graduate}</td>
                      <td>{item?.Auto_Criteria05_521?.Auto_employer}</td>
                      <td>{item?.Auto_Criteria05_521?.Auto_Package}</td>
                      <td>
                        {item?.Auto_Criteria05_521?.Auto_Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria05_522?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria05_522?.Auto_Higher}</td>
                      <td>{item?.Auto_Criteria05_522?.Auto_Graduat1}</td>
                      <td>{item?.Auto_Criteria05_522?.Auto_Institute}</td>
                      <td>{item?.Auto_Criteria05_522?.Auto_Admit}</td>
                      <td>
                        {item?.Auto_Criteria05_522?.Auto_Desc22?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria05_523?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_No}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_RollNo}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_StdSelected}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_NET}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_SLET}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_GATE}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_GMAT}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_CAT}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_GRE}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_JAM}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_IELTS}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_TOEFL}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_Civil}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_State}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_OtherExam}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_Total}</td>
                      <td>{item?.Auto_Criteria05_523?.Auto_GTotal}</td>
                      <td>
                        {item?.Auto_Criteria05_523?.Auto_Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria05_531?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria05_531?.Auto_Medal}</td>
                      <td>{item?.Auto_Criteria05_531?.Auto_Individual}</td>
                      <td>{item?.Auto_Criteria05_531?.Auto_Inter}</td>
                      <td>{item?.Auto_Criteria05_531?.Auto_Event}</td>
                      <td>{item?.Auto_Criteria05_531?.Auto_Student}</td>
                      <td>
                        {item?.Auto_Criteria05_531?.Auto_Desc24?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria05_533?.Auto_criteria}</td>
                      <td>
                        {moment(item?.Auto_Criteria05_533?.Auto_Event1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Auto_Criteria05_533?.Auto_Event2}</td>
                      <td>
                        {item?.Auto_Criteria05_533?.Auto_Desc25?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 5 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------5.1.1------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the scheme
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_Scheme}
                        onChange={(e) => setAuto_Scheme(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_Scheme}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students benefited by government scheme and amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_Std}
                        onChange={(e) => setAuto_Std(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_Std}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_Amt}
                        onChange={(e) => setAuto_Amt(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_Amt}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students benefited by the institution's schemes and
                  amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_Std1}
                        onChange={(e) => setAuto_Std1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_Std1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_Amt1}
                        onChange={(e) => setAuto_Amt1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_Amt1}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students benefited by the non-government agencies
                  (NGOs) and amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_Std2}
                        onChange={(e) => setAuto_Std2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_Std2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_Amt2}
                        onChange={(e) => setAuto_Amt2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_Amt2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the NGO/agency
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_NGO}
                        onChange={(e) => setAuto_NGO(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_NGO}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_Desc18}
                        onChange={(e) => setAuto_Desc18(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_Desc18}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria05_511?.Auto_Link18}
                        onChange={(e) => setAuto_Link18(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_511?.Auto_Link18}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit1(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria05_511(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.1.3-------- */}
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the capability enhancement program
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria05_513?.Auto_Capabality
                        }
                        onChange={(e) => {
                          setAuto_Capabality(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_513?.Auto_Capabality}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of implementation (DD-MM-YYYY)
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_513?.Auto_Date}
                        onChange={(e) => {
                          setAuto_Date(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_513?.Auto_Date}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students enrolled
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_513?.Auto_Enrolled}
                        onChange={(e) => setAuto_Enrolled(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_513?.Auto_Enrolled}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_513?.Auto_Agencies}
                        onChange={(e) => setAuto_Agencies(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_513?.Auto_Agencies}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_513?.Auto_Desc19}
                        onChange={(e) => setAuto_Desc19(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_513?.Auto_Desc19}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        onChange={(e) => setAuto_Link19(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_513?.Auto_Link19}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit2(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria05_513(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.1.4-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.4
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counselling offered by
                    the institution during the last five years
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_514?.Auto_Activity}
                        onChange={(e) => {
                          setAuto_Activity(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_514?.Auto_Activity}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students attended / participated
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_514?.Auto_Attended}
                        onChange={(e) => {
                          setAuto_Attended(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_514?.Auto_Attended}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students placed through campus placement
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_514?.Auto_Placed}
                        onChange={(e) => {
                          setAuto_Placed(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_514?.Auto_Placed}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_514?.Auto_Desc20}
                        onChange={(e) => setAuto_Desc20(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_514?.Auto_Desc20}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3 "
                        accept="image?*"
                        placeholder={Value?.Auto_Criteria05_514?.Auto_Link20}
                        onChange={(e) => setAuto_Link20(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_514?.Auto_Link20}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit3(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria05_514(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.2.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student placed
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_521?.Auto_Placed1}
                        onChange={(e) => {
                          setAuto_Placed1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_521?.Auto_Placed1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_521?.Auto_Graduate}
                        onChange={(e) => {
                          setAuto_Graduate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_521?.Auto_Graduate}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the employer
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_521?.Auto_employer}
                        onChange={(e) => {
                          setAuto_employer(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_521?.Auto_employer}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Pay package at appointment
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_521?.Auto_Package}
                        onChange={(e) => {
                          setAuto_Package(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_521?.Auto_Package}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        rows={3}
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_521?.Auto_Desc21}
                        onChange={(e) => setAuto_Desc21(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_521?.Auto_Desc21}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria05_521?.Auto_Link21}
                        onChange={(e) => setAuto_Link21(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_521?.Auto_Link21}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit4(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria05_521(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.2.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student enrolling into higher education
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_522?.Auto_Higher}
                        onChange={(e) => {
                          setAuto_Higher(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_522?.Auto_Higher}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_522?.Auto_Graduat1}
                        onChange={(e) => {
                          setAuto_Graduat1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_522?.Auto_Graduat1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of institution joined
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_522?.Auto_Institute}
                        onChange={(e) => {
                          setAuto_Institute(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_522?.Auto_Institute}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of programme admitted to
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_522?.Auto_Admit}
                        onChange={(e) => {
                          setAuto_Admit(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_522?.Auto_Admit}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        rows={3}
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_522?.Auto_Desc22}
                        onChange={(e) => setAuto_Desc22(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_522?.Auto_Desc22}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload5">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload5"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria05_522?.Auto_Link22}
                        onChange={(e) => setAuto_Link22(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_522?.Auto_Link22}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit5(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria05_522(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.2.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Sl.no.
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_No}
                        onChange={(e) => {
                          setAuto_No(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_No}</>
                  )}
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Registration number/roll number for the exam
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_RollNo}
                        onChange={(e) => {
                          setAuto_RollNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_RollNo}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Auto_NameS of students selected/ qualified
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria05_523?.Auto_StdSelected
                        }
                        onChange={(e) => {
                          setAuto_StdSelected(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_StdSelected}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    NET
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_NET}
                        onChange={(e) => {
                          setAuto_NET(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_NET}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    SLET
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_SLET}
                        onChange={(e) => {
                          setAuto_SLET(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_SLET}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GATE
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_GATE}
                        onChange={(e) => {
                          setAuto_GATE(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_GATE}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GMAT
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_GMAT}
                        onChange={(e) => {
                          setAuto_GMAT(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_GMAT}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    CAT
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_CAT}
                        onChange={(e) => {
                          setAuto_CAT(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_CAT}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GRE
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_GRE}
                        onChange={(e) => {
                          setAuto_GRE(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_GRE}</>
                  )}
                </div>

                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    JAM
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_JAM}
                        onChange={(e) => {
                          setAuto_JAM(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_JAM}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    IELTS
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_IELTS}
                        onChange={(e) => {
                          setAuto_IELTS(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_IELTS}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TOEFL
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_TOEFL}
                        onChange={(e) => {
                          setAuto_TOEFL(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_TOEFL}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Civil Services
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_Civil}
                        onChange={(e) => {
                          setAuto_Civil(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_Civil}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State government examinations
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_State}
                        onChange={(e) => {
                          setAuto_State(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_State}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_OtherExam}
                        onChange={(e) => {
                          setAuto_OtherExam(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_OtherExam}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_Total}
                        onChange={(e) => {
                          setAuto_Total(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_Total}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Grand Total
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_GTotal}
                        onChange={(e) => {
                          setAuto_GTotal(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_GTotal}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_Desc23}
                        onChange={(e) => setAuto_Desc23(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_Desc23}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload6"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria05_523?.Auto_Link23}
                        onChange={(e) => {
                          setAuto_Link23(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_523?.Auto_Link23}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit6(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria05_523(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.3.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/ medal
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_531?.Auto_Medal}
                        onChange={(e) => {
                          setAuto_Medal(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_531?.Auto_Medal}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Team / Individual
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria05_531?.Auto_Individual
                        }
                        onChange={(e) => {
                          setAuto_Individual(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_531?.Auto_Individual}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    inter-university / state / National/ International
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_531?.Auto_Inter}
                        onChange={(e) => {
                          setAuto_Inter(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_531?.Auto_Inter}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the event
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_531?.Auto_Event}
                        onChange={(e) => {
                          setAuto_Event(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_531?.Auto_Event}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the student
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_531?.Auto_Student}
                        onChange={(e) => {
                          setAuto_Student(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_531?.Auto_Student}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_531?.Auto_Desc24}
                        onChange={(e) => setAuto_Desc24(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_531?.Auto_Desc24}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload7"
                        id="upload7"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria05_531?.Auto_Link24}
                        onChange={(e) => setAuto_Link24(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_531?.Auto_Link24}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit7(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria05_531(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.3.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of event/competition (DD-MM-YYYY)
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_533?.Auto_Event1}
                        onChange={(e) => {
                          setAuto_Event1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_533?.Auto_Event1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the event/competition
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_533?.Auto_Event2}
                        onChange={(e) => {
                          setAuto_Event2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_533?.Auto_Event2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit8 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria05_533?.Auto_Desc25}
                        onChange={(e) => setAuto_Desc25(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_533?.Auto_Desc25}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload8"
                        id="upload8"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria05_533?.Auto_Link25}
                        onChange={(e) => setAuto_Link25(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria05_533?.Auto_Link25}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit8(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria05_533(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria05_511?.Auto_Desc18}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria05_513?.Auto_Desc19}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria05_514?.Auto_Desc20}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria05_521?.Auto_Desc21}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria05_522?.Auto_Desc22}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria05_523?.Auto_Desc23}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria05_531?.Auto_Desc24}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_8 */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria05_533?.Auto_Desc25}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria05/${View?.Auto_Criteria05_511?.Auto_Link18}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria05/${View?.Auto_Criteria05_513?.Auto_Link19}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria05/${View?.Auto_Criteria05_514?.Auto_Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria05/${View?.Auto_Criteria05_521?.Auto_Link21}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria05/${View?.Auto_Criteria05_522?.Auto_Link22}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria05/${View?.Auto_Criteria05_523?.Auto_Link23}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria05/${View?.Auto_Criteria05_531?.Auto_Link24}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria05/${View?.Auto_Criteria05_533?.Auto_Link25}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Auto_Criteria05;
