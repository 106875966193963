import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";
// import Faq from './Faq';

const CourseSingle = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(location?.state)?.length == 0) {
      window.location.assign("/courses");
    }
  }, []);

  return (
    <div>
      <h4
        className="mt-4 mb-4"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {location?.state?.courseName}
      </h4>
      <div className="container">
        <div className="row">
          <div className="col-md-1">
            <img
              src={`https://brightnaac.co.in/Course/${location?.state?.instructorImg}`}
              alt=""
              width={50}
              height={50}
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="col-md-4">
            <div>
              <h6>
                Instructor &nbsp;&nbsp;:&nbsp;&nbsp;
                {location?.state?.instructorName}
              </h6>
              <h6>
                Categories&nbsp;&nbsp;:&nbsp;&nbsp;
                {location?.state?.courseCategory}{" "}
              </h6>
            </div>
          </div>
          <div className="col-md-1" style={{ padding: "10px" }}>
            <h6 style={{ float: "right" }}>{location?.state?.coursePrice}</h6>
          </div>
          <div className="col-md-2">
            <button
              type="submit"
              className="btn btn-success"
              style={{ float: "right" }}
            >
              BuyCourse
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        {" "}
        <div className="row">
          <div className="col-md-8">
            <img
              src={`https://brightnaac.co.in/Course/${location?.state?.courseImg}`}
              alt=""
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-md-4">
            <h5>Course Features</h5>
            <ul class="list-group list-group-flush p-2">
              <li class="list-group-item">
                {location?.state?.courseFeatures?.map((item, i) => {
                  return (
                    <div className="row p-2">
                      <div className="col-md-2">
                        {++i}.
                        {/* <img src="./Image/time.png" alt="" width={25} /> */}
                      </div>
                      <div className="col-md-6">{item?.courseFeat}</div>
                      <div className="col-md-4">{item?.courseData}</div>
                    </div>
                  );
                })}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <Navbar
              bg="light"
              variant="light"
              style={{ border: "1px solid black" }}
            >
              <Container>
                <Nav className="me-auto ">
                  <Nav.Link href="#homealignItems">
                    <img
                      src="./Image/search.png"
                      alt=""
                      width={30}
                      height={30}
                      style={{ padding: "5px" }}
                    />{" "}
                    Overview
                  </Nav.Link>
                  <Nav.Link href="#features">
                    <i
                      class="fa-solid fa-bars "
                      style={{ Color: "#000000", padding: "5px" }}
                    ></i>
                    Curiculum
                  </Nav.Link>
                  <Nav.Link href="#pricing">
                    <img
                      src="./Image/star.png"
                      alt=""
                      width={30}
                      height={30}
                      style={{ padding: "5px" }}
                    />
                    Reviews
                  </Nav.Link>
                  <Nav.Link href="#pricing">
                    <img
                      src="./Image/chat.png"
                      alt=""
                      width={28}
                      height={28}
                      style={{ padding: "5px" }}
                    />
                    Forum
                  </Nav.Link>
                </Nav>
              </Container>
            </Navbar>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <h4 className="mt-4 mb-4">About This Course</h4>

            <p
              style={{
                fontSize: 18,
                padding: "10px 10px ",
                textAlign: "justify",
              }}
            >
              {location?.state?.courseDescription}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSingle;
