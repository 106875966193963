import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
const PgCriteria2 = () => {
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  let principalDetails = JSON.parse(sessionStorage.getItem("Puser"));

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  //  ======================================================

  const [content, setContent] = useState([]);
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcri02details"
      );
      if (res.status === 200) {
        setContent(res.data.pgcriteria02);
        setdata1(res.data.pgcriteria02);
      }
    } catch (error) {
      console.log(error);
      setContent(error.response.data.pgcriteria02);
    }
  };

  console.log("content", content);

  // =================================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaPG02/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getContent();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ==================================Export to excel=========================================

  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Post_Graduate_02");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.PG_Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
          <table>
            <tr>
              <td>SC</td>
              <td>${item?.PG_Criteria02_212?.PG_SC}</td>
            </tr>
            <tr>
              <td>ST</td>
              <td>${item?.PG_Criteria02_212?.PG_ST}</td>
            </tr>
            <tr>
              <td>OBC</td>
              <td>${item?.PG_Criteria02_212?.PG_OBC}</td>
            </tr>
            <tr>
              <td>General</td>
              <td>${item?.PG_Criteria02_212?.PG_Gen}</td>
            </tr>
            <tr>
              <td>Divyangjan</td>
              <td>${item?.PG_Criteria02_212?.PG_Divyangjan}</td>
            </tr>
            <tr>
              <td>Others</td>
              <td>${item?.PG_Criteria02_212?.PG_Others}</td>
            </tr>
          </table>
        `;

        let tableMarkup2 = `
          <table>
            <tr>
              <td>SC</td>
              <td>${item?.PG_Criteria02_212?.PG_SSC}</td>
            </tr>
            <tr>
              <td>ST</td>
              <td>${item?.PG_Criteria02_212?.PG_SST}</td>
            </tr>
            <tr>
              <td>OBC</td>
              <td>${item?.PG_Criteria02_212?.PG_OOBC}</td>
            </tr>
            <tr>
              <td>General</td>
              <td>${item?.PG_Criteria02_212?.PG_GGen}</td>
            </tr>
            <tr>
              <td>Divyangjan</td>
              <td>${item?.PG_Criteria02_212?.PG_DDivyangjan}</td>
            </tr>
            <tr>
              <td>Others</td>
              <td>${item?.PG_Criteria02_212?.PG_OOthers}</td>
            </tr>
          </table>
        `;

        let obj = {
          // 211

          Year: item?.PG_Year,
          "Program Name": item?.PG_Criteria02_211?.PG_PrgName1,
          "Program Code": item?.PG_Criteria02_211?.PG_PrgCode,
          "Number of seats sanctioned":
            item?.PG_Criteria02_211?.PG_NoSeatSanctioned,
          "Number of Students admitted": item?.PG_Criteria02_211?.PG_NoStdAdmit,
          Description: item?.PG_Criteria02_211?.PG_Desc6,
          "Relevant document": item?.PG_Criteria02_211?.PG_Link6,

          // 212

          "Number of seats earmarked for reserved category as per GOI or State Government rule":
            tableMarkup1,
          "Number of students admitted from the reserved category":
            tableMarkup2,
          Description: item?.PG_Criteria02_212?.PG_Desc7,
          "Relevant document": item?.PG_Criteria02_212?.PG_Link7,

          // 241

          "Name of the Full-time teacher": item?.PG_Criteria02_241?.PG_TName,
          PAN: item?.PG_Criteria02_241?.PG_PAN,
          Designation: item?.PG_Criteria02_241?.PG_Designation,
          "Year of appointment": item?.PG_Criteria02_241?.PG_YOfAppoint,
          "Nature of appointment (Against Sanctioned post, temporary, permanent)":
            item?.PG_Criteria02_241?.PG_NoOfAppoint,
          "Name of the Department": item?.PG_Criteria02_241?.PG_DeptName,
          "Total years of Experience in the same institution":
            item?.PG_Criteria02_241?.PG_TYofExp,
          "Is the teacher still serving the institution/If not last year of the service of Faculty to the Institution":
            item?.PG_Criteria02_241?.PG_TService,
          Description: item?.PG_Criteria02_241?.PG_Desc8,
          "Relevant document": item?.PG_Criteria02_241?.PG_Link8,

          // 242

          "Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.":
            item?.PG_Criteria02_242?.PG_SerTeacher,
          "Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.)":
            item?.PG_Criteria02_242?.PG_Qualify,
          "Year of obtaining the qualification":
            item?.PG_Criteria02_242?.PG_YOfQuali,
          "Year of Recognition as a Research Guide":
            item?.PG_Criteria02_242?.PG_Whether,
          "Whether recognised as a Research Guide for Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit":
            item?.PG_Criteria02_242?.PG_YOfRecog,
          "Is the teacher still serving the institution/If not last year of the service of Faculty to the Institution":
            item?.PG_Criteria02_242?.PG_IsThereTeacher,
          "Name of the scholar": item?.PG_Criteria02_242?.PG_NameScholar,
          "Year of registration of the scholar":
            item?.PG_Criteria02_242?.PG_YearScholar,
          "Title of the thesis of the for scholar":
            item?.PG_Criteria02_242?.PG_Thesis,
          Description: item?.PG_Criteria02_242?.PG_Desc9,
          "Relevant document": item?.PG_Criteria02_242?.PG_Link9,

          // 263

          "Program Name": item?.PG_Criteria02_263?.PG_PrgName2,
          "Program Code": item?.PG_Criteria02_263?.PG_pCode,
          "Number of students appeared in the final year examination":
            item?.PG_Criteria02_263?.PG_NStdAppeared,
          "Number of students passed in final year examination":
            item?.PG_Criteria02_263?.PG_NStdPassed,
          Description: item?.PG_Criteria02_263?.PG_Desc10,
          "Relevant document": item?.PG_Criteria02_263?.PG_Link10,

          // 271

          "Program Name": item?.PG_Criteria02_271?.PG_PrgName3,
          "Name of the student": item?.PG_Criteria02_271?.PG_NStd,
          Gender: item?.PG_Criteria02_271?.PG_Gender,
          Category: item?.PG_Criteria02_271?.PG_Category,
          "State of Domicile": item?.PG_Criteria02_271?.PG_SDomicile,
          "Nationality if othern than Indian":
            item?.PG_Criteria02_271?.PG_NIndian,
          "Email-ID": item?.PG_Criteria02_271?.PG_Email,
          "Mobile Number": item?.PG_Criteria02_271?.PG_MNo,
          "Year of joining": item?.PG_Criteria02_271?.PG_YJoining,
          "Unique Enrolment ID / College ID/ University enrolment number":
            item?.PG_Criteria02_271?.PG_UID,
          Description: item?.PG_Criteria02_271?.PG_Desc11,
          "Relevant document": item?.PG_Criteria02_271?.PG_Link11,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  // ============================================================

  useEffect(() => {
    principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);

  console.log("fjfjfjfjf", content);
  console.log("principal", principalDetails?._id);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-02
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div className="">
            <Nav defaultActiveKey="/ugcriteria04">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/PG_02_211")}
                >
                  <Button
                    variant="warning"
                    // style={{ backgroundColor: "#ffc107" }}
                    className="criteriabtn-0 btn-sub1"
                  >
                    2.1.1
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/PG_02_212")}
                >
                  <Button className="criteriabtn btn-sub">2.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_02_241")}
                >
                  <Button className="criteriabtn btn-sub">2.4.1 & 243</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_02_242")}
                >
                  <Button className="criteriabtn btn-sub">2.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_02_263")}
                >
                  <Button className="criteriabtn btn-sub">2.6.3</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_02_271")}
                >
                  <Button className="criteriabtn btn-sub">2.7.1</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-3">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>

                  <th>ID</th>
                  <th>Year</th>
                  {/* 2.1.1 */}
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of Students admitted</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 2.1.2 */}
                  <th>Criteria</th>
                  <th>
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </th>
                  <th>
                    Number of students admitted from the reserved category
                  </th>
                  <th>Description</th>
                  <th> Document</th>

                  {/* 2.4.1 & 2.4.3 */}
                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>
                    Nature of appointment (Against Sanctioned post,
                    temporary,permanent)
                  </th>
                  <th>Name of the Department</th>
                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution.
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 2.4.2 */}
                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Year of Recognition as Research Guide</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th>Name of the scholar</th>
                  <th>Year of registration of the scholar</th>
                  <th>Title of the thesis of the project for scholar</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 2.6.3 */}
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Document</th>
                  {/* 2.7.1 */}
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if othern than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.PG_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      principalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "Pending" &&
                      (SearchItem === "" ||
                        item1?.PG_Criteria02_211?.PG_criteria?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Year?.includes(SearchItem) ||
                        item1?.PG_Criteria02_211?.PG_PrgName1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_211?.PG_PrgCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_211?.PG_NoSeatSanctioned?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_211?.PG_NoStdAdmit?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_211?.PG_Desc6?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Year?.includes(SearchItem) ||
                        item1?.PG_Criteria02_212?.PG_SC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_ST?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_OBC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_Gen?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_Divyangjan?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_Others?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_SSC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_SST?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_OOBC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_GGen?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_DDivyangjan?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_OOthers?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_212?.PG_Desc7?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_241?.PG_TName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_241?.PG_PAN?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_241?.PG_Designation?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_241?.PG_YOfAppoint?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_241?.PG_NoOfAppoint?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_241?.PG_DeptName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_241?.PG_TYofExp?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_241?.PG_TService?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_241?.PG_Desc8?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_242?.PG_SerTeacher?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_242?.PG_Qualify?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_242?.PG_YOfQuali?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_242?.PG_Whether?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_242?.PG_YOfRecog?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_242?.PG_IsThereTeacher?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_242?.PG_NameScholar?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_242?.PG_YearScholar?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_242?.PG_Thesis?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_242?.PG_Desc9?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_263?.PG_PrgName2?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria02_263?.PG_pCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_263?.PG_NStdAppeared?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_263?.PG_NStdPassed?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria02_263?.PG_Desc10?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>

                        <td>{item?._id}</td>
                        <td>{item?.PG_Year}</td>
                        <td>{item?.PG_Criteria02_211?.PG_criteria}</td>
                        <td>{item?.PG_Criteria02_211?.PG_PrgName1}</td>
                        <td>{item?.PG_Criteria02_211?.PG_PrgCode}</td>
                        <td>{item?.PG_Criteria02_211?.PG_NoSeatSanctioned}</td>
                        <td>{item?.PG_Criteria02_211?.PG_NoStdAdmit}</td>
                        <td>
                          {item?.PG_Criteria02_211?.PG_Desc6?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow6();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>

                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>
                        <td>{item?.PG_Criteria02_212?.PG_criteria}</td>
                        <td>
                          <Table responsive>
                            <thead>
                              <th>SC</th>
                              <th>ST</th>
                              <th>OBC</th>
                              <th>General</th>
                              <th>Divyangjan</th>
                              <th>Others</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{item?.PG_Criteria02_212?.PG_SC}</td>
                                <td>{item?.PG_Criteria02_212?.PG_ST}</td>
                                <td>{item?.PG_Criteria02_212?.PG_OBC}</td>
                                <td>{item?.PG_Criteria02_212?.PG_Gen}</td>
                                <td>
                                  {item?.PG_Criteria02_212?.PG_Divyangjan}
                                </td>
                                <td>{item?.PG_Criteria02_212?.PG_Others}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          <Table responsive>
                            <thead>
                              <th>SC</th>
                              <th>ST</th>
                              <th>OBC</th>
                              <th>General</th>
                              <th>Divyangjan</th>
                              <th>Others</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{item?.PG_Criteria02_212?.PG_SSC}</td>
                                <td>{item?.PG_Criteria02_212?.PG_SST}</td>
                                <td>{item?.PG_Criteria02_212?.PG_OOBC}</td>
                                <td>{item?.PG_Criteria02_212?.PG_GGen}</td>
                                <td>
                                  {item?.PG_Criteria02_212?.PG_DDivyangjan}
                                </td>
                                <td>{item?.PG_Criteria02_212?.PG_OOthers}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          {item?.PG_Criteria02_212?.PG_Desc7?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow7();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>

                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow2();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>
                        <td>{item?.PG_Criteria02_241?.PG_criteria}</td>
                        <td>{item?.PG_Criteria02_241?.PG_TName}</td>
                        <td>{item?.PG_Criteria02_241?.PG_PAN}</td>
                        <td>{item?.PG_Criteria02_241?.PG_Designation}</td>
                        <td>{item?.PG_Criteria02_241?.PG_YOfAppoint}</td>
                        <td>{item?.PG_Criteria02_241?.PG_NoOfAppoint}</td>
                        <td>{item?.PG_Criteria02_241?.PG_DeptName}</td>
                        <td>{item?.PG_Criteria02_241?.PG_TYofExp}</td>
                        <td>{item?.PG_Criteria02_241?.PG_TService}</td>

                        <td>
                          {item?.PG_Criteria02_241?.PG_Desc8?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow8();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>

                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow3();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>
                        <td>{item?.PG_Criteria02_242?.PG_criteria}</td>
                        <td>{item?.PG_Criteria02_242?.PG_SerTeacher}</td>
                        <td>{item?.PG_Criteria02_242?.PG_Qualify}</td>
                        <td>{item?.PG_Criteria02_242?.PG_YOfQuali}</td>
                        <td>{item?.PG_Criteria02_242?.PG_YOfRecog}</td>
                        <td>{item?.PG_Criteria02_242?.PG_Whether}</td>
                        <td>{item?.PG_Criteria02_242?.PG_IsThereTeacher}</td>
                        <td>{item?.PG_Criteria02_242?.PG_NameScholar}</td>
                        <td>{item?.PG_Criteria02_242?.PG_YearScholar}</td>
                        <td>{item?.PG_Criteria02_242?.PG_Thesis}</td>
                        <td>
                          {item?.PG_Criteria02_242?.PG_Desc9?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow9();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>

                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow4();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>
                        <td>{item?.PG_Criteria02_263?.PG_criteria}</td>
                        <td>{item?.PG_Criteria02_263?.PG_PrgName2}</td>
                        <td>{item?.PG_Criteria02_263?.PG_pCode}</td>
                        <td>{item?.PG_Criteria02_263?.PG_NStdAppeared}</td>
                        <td>{item?.PG_Criteria02_263?.PG_NStdPassed}</td>

                        <td>
                          {item?.PG_Criteria02_263?.PG_Desc10?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow10();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>

                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow5();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>
                        <td>{item?.PG_Criteria02_271?.PG_criteria}</td>
                        <td>{item?.PG_Criteria02_271?.PG_PrgName3}</td>
                        <td>{item?.PG_Criteria02_271?.PG_NStd}</td>
                        <td>{item?.PG_Criteria02_271?.PG_Gender}</td>
                        <td>{item?.PG_Criteria02_271?.PG_Category}</td>
                        <td>{item?.PG_Criteria02_271?.PG_SDomicile}</td>
                        <td>{item?.PG_Criteria02_271?.PG_NIndian}</td>
                        <td>{item?.PG_Criteria02_271?.PG_Email}</td>
                        <td>{item?.PG_Criteria02_271?.PG_MNo}</td>
                        <td>{item?.PG_Criteria02_271?.PG_YJoining}</td>
                        <td>{item?.PG_Criteria02_271?.PG_UID}</td>

                        <td>
                          {item?.PG_Criteria02_271?.PG_Desc11?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow11();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>

                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow12();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>

                        <td style={{ border: "" }}>
                          <div className="d-flex justify-content-evenly align-items-center">
                            {/* <i class="fa-solid fa-check fa-lg" style={{color: "#26a269"}}></i> */}
                            <i
                              class="fa-solid fa-eye fa-lg"
                              onClick={() => {
                                setValue(item);
                                handleShow();
                              }}
                              style={{ color: "#9a9996" }}
                            ></i>
                          </div>
                        </td>
                        <td style={{ border: "" }}>
                          {item?.approve === "true" ? (
                            <div style={{ color: "green" }}>Approved</div>
                          ) : (
                            <>
                              {item?.approve === "false" ? (
                                <div style={{ color: "red" }}>
                                  {" "}
                                  Not Approved
                                </div>
                              ) : (
                                <div> Pending</div>
                              )}
                            </>
                          )}
                        </td>
                        <td style={{ border: "" }}>{item?.remark}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_211?.PG_Link6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_212?.PG_Link7}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_241?.PG_Link8}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_242?.PG_Link9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_263?.PG_Link10}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 12 model */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_271?.PG_Link11}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.PG_Criteria02_211?.PG_Desc6}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose6}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.PG_Criteria02_212?.PG_Desc7}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria02_241?.PG_Desc8}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose8}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria02_242?.PG_Desc9}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 10 model */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria02_263?.PG_Desc10}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 11 model */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.PG_Criteria02_271?.PG_Desc11}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span>PostGraduate_Criteria-02</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme name
                  </label>

                  <>{Value?.PG_Criteria02_211?.PG_PrgName1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme code
                  </label>

                  <>{Value?.PG_Criteria02_211?.PG_PrgCode}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of seats sanctioned
                  </label>

                  <>{Value?.PG_Criteria02_211?.PG_NoSeatSanctioned}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students admitted
                  </label>

                  <>
                    <>{Value?.PG_Criteria02_211?.PG_NoStdAdmit}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria02_211?.PG_Desc6}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria02_211?.PG_Link6}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.1.2------ */}
                <h6 className="text-center text-decoration-underline">
                  Number of seats earmarked for reserved category as per GOI or
                  State Government rule
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_SC}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST{" "}
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_ST}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_OBC}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_Gen}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_Divyangjan}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_Others}</>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students admitted from the reserved category
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_SSC}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_SST}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_OOBC}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_GGen}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_DDivyangjan}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_OOthers}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_Desc7}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria02_212?.PG_Link7}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.1 & 2.4.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.1 & 2.4.3------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Full-time teacher
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_TName}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    PAN
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_PAN}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Designation
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_Designation}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of appointment
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_YOfAppoint}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_NoOfAppoint}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_DeptName}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total years of Experience in the same institution
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_TYofExp}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_TService}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_Desc8}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria02_241?.PG_Link8}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.2------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_SerTeacher}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_Qualify}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of obtaining the qualification
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_YOfQuali}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Recognition as a Research Guide
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_YOfRecog}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./DLit.
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_Whether}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_IsThereTeacher}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scholar
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_NameScholar}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of registration of the scholar
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_YearScholar}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the thesis of the for scholar
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_Thesis}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_Desc9}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria02_242?.PG_Link9}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.6.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.6.3------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  <>{Value?.PG_Criteria02_263?.PG_PrgName2}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  <>{Value?.PG_Criteria02_263?.PG_pCode}</>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students appeared in the final year examination
                    </label>

                    <>{Value?.PG_Criteria02_263?.PG_NStdAppeared}</>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students passed in final year examination
                  </label>

                  <>{Value?.PG_Criteria02_263?.PG_NStdPassed}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria02_263?.PG_Desc10}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria02_263?.PG_Link10}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_PrgName3}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Student Name
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_NStd}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gender
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_Gender}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_Category}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State of Domicile
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_SDomicile}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Nationality if othern than Indian
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_NIndian}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Email-ID
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_Email}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Mobile Number
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_MNo}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of joining
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_YJoining}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_UID}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_Desc11}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    Document
                  </label>

                  <>{Value?.PG_Criteria02_271?.PG_Link11}</>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  className="btn btn-success"
                  onClick={(e) => {
                    approveCriteria(e, true, Value?._id);
                    handleClose();
                  }}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => handleClosesm()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              onChange={(e) => setremark(e.target.value)}
              style={{ height: "200px" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                approveCriteria(e, false, Value?._id);
                handleClose();
                handleClosesm();
              }}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default PgCriteria2;
