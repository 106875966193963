import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export default function AQARList() {
  const navigate = useNavigate();
  const [aqarlist, setaqarlist] = useState([]);
  const getAQARList = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/pri/aqarDetails"
      );
      if (res.status === 200) {
        setaqarlist(res.data.reports);
      }
    } catch (error) {
      console.log(error);
      return alert(error.response.data.msg);
    }
  };
  useEffect(() => {
    getAQARList();
  }, []);
  return (
    <div style={{ padding: "20px" }}>
      <Button onClick={() => navigate("/aqar-details")}>
        Generate AQAR Report
      </Button>
      <div style={{ textAlign: "center" }}>
        <Table>
          <thead>
            <th>Year</th>
            <th>View</th>
          </thead>
          <tbody>
            {aqarlist.map((item) => {
              return (
                <tr>
                  <td>{`${item.startDate}-${item.endDate}`}</td>
                  <td>
                    <FaEye
                      style={{ fontSize: "25px" }}
                      onClick={() => {
                        navigate("/generate-aqar-report", {
                          state: {
                            startYearReport: item.startDate,
                            endYearReport: item.endDate,
                            itemDetails: item,
                          },
                        });
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
