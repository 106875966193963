import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment/moment";
import { AiOutlineEye } from "react-icons/ai";

const Auto_Criteria02 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);

  const [Auto_PrgCode, setAuto_PrgCode] = useState("");
  const [Auto_NoSeatSanctioned, setAuto_NoSeatSanctioned] = useState("");
  const [Auto_NoStdAdmit, setAuto_NoStdAdmit] = useState("");
  const [Auto_PrgName1, setAuto_PrgName1] = useState("");
  const [Auto_Desc32, setAuto_Desc32] = useState("");
  const [Auto_Link32, setAuto_Link32] = useState("");

  const [Auto_SC, setAuto_SC] = useState("");
  const [Auto_ST, setAuto_ST] = useState("");
  const [Auto_OBC, setAuto_OBC] = useState("");
  const [Auto_Gen, setAuto_Gen] = useState("");
  const [Auto_Divyangjan, setAuto_Divyangjan] = useState("");
  const [Auto_Others, setAuto_Others] = useState("");
  const [Auto_SSC, setAuto_SSC] = useState("");
  const [Auto_SST, setAuto_SST] = useState("");
  const [Auto_OOBC, setAuto_OOBC] = useState("");
  const [Auto_GGen, setAuto_GGen] = useState("");
  const [Auto_DDivyangjan, setAuto_DDivyangjan] = useState("");
  const [Auto_OOthers, setAuto_OOthers] = useState("");
  const [Auto_Desc33, setAuto_Desc33] = useState("");
  const [Auto_Link33, setAuto_Link33] = useState("");

  const [Auto_TName, setAuto_TName] = useState("");
  const [Auto_PAN, setAuto_PAN] = useState("");
  const [Auto_Designation, setAuto_Designation] = useState("");
  const [Auto_YOfAppoint, setAuto_YOfAppoint] = useState("");
  const [Auto_NoOfAppoint, setAuto_NoOfAppoint] = useState("");
  const [Auto_DeptName, setAuto_DeptName] = useState("");
  const [Auto_TYofExp, setAuto_TYofExp] = useState("");
  const [Auto_TService, setAuto_TService] = useState("");
  const [Auto_Desc34, setAuto_Desc34] = useState("");
  const [Auto_Link34, setAuto_Link34] = useState("");

  const [Auto_SerTeacher, setAuto_SerTeacher] = useState("");
  const [Auto_Qualify, setAuto_Qualify] = useState("");
  const [Auto_YOfQuali, setAuto_YOfQuali] = useState("");
  const [Auto_YOfRecog, setAuto_YOfRecog] = useState("");
  const [Auto_Whether, setAuto_Whether] = useState("");
  const [Auto_IsThereTeacher, setAuto_IsThereTeacher] = useState("");
  const [Auto_NameScholar, setAuto_NameScholar] = useState("");
  const [Auto_YearScholar, setAuto_YearScholar] = useState("");
  const [Auto_Desc35, setAuto_Desc35] = useState("");
  const [Auto_Link35, setAuto_Link35] = useState("");

  const [Auto_pname1, setAuto_pname1] = useState("");
  const [Auto_pcode1, setAuto_pcode1] = useState("");
  const [Auto_semester, setAuto_semester] = useState("");
  const [Auto_last, setAuto_last] = useState("");
  const [Auto_Date, setAuto_Date] = useState("");
  const [Auto_Desc36, setAuto_Desc36] = useState("");
  const [Auto_Link36, setAuto_Link36] = useState("");

  const [Auto_pCode, setAuto_pCode] = useState("");
  const [Auto_PrgName2, setAuto_PrgName2] = useState("");
  const [Auto_NStdAppeared, setAuto_NStdAppeared] = useState("");
  const [Auto_NStdPassed, setAuto_NStdPassed] = useState("");
  const [Auto_Desc37, setAuto_Desc37] = useState("");
  const [Auto_Link37, setAuto_Link37] = useState("");

  const [Auto_PrgName3, setAuto_PrgName3] = useState("");
  const [Auto_NStd, setAuto_NStd] = useState("");
  const [Auto_Gender, setAuto_Gender] = useState("");
  const [Auto_Category, setAuto_Category] = useState("");
  const [Auto_SDomicile, setAuto_SDomicile] = useState("");
  const [Auto_NIndian, setAuto_NIndian] = useState("");
  const [Auto_Email, setAuto_Email] = useState("");
  const [Auto_MNo, setAuto_MNo] = useState("");
  const [Auto_YJoining, setAuto_YJoining] = useState("");
  const [Auto_UID, setAuto_UID] = useState("");
  const [Auto_Desc38, setAuto_Desc38] = useState("");
  const [Auto_Link38, setAuto_Link38] = useState("");

  //integrating get method
  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [Auto_Criteria02, setAuto_Criteria02] = useState([]);
  const [Value, setValue] = useState({});

  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Autonomous_Criteria-02");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
        <table>
          <tr>
            <td>SC</td>
            <td>${item?.Auto_Criteria02_212?.Auto_SC}</td>
          </tr>
          <tr>
            <td>ST</td>
            <td>${item?.Auto_Criteria02_212?.Auto_ST}</td>
          </tr>
          <tr>
            <td>OBC</td>
            <td>${item?.Auto_Criteria02_212?.Auto_OBC}</td>
          </tr>
          <tr>
            <td>General</td>
            <td>${item?.Auto_Criteria02_212?.Auto_Gen}</td>
          </tr>
          <tr>
            <td>Divyangjan</td>
            <td>${item?.Auto_Criteria02_212?.Auto_Divyangjan}</td>
          </tr>
          <tr>
            <td>Others</td>
            <td>${item?.Auto_Criteria02_212?.Auto_Others}</td>
          </tr>
        </table>
      `;

        let tableMarkup2 = `
        <table>
          <tr>
            <td>SC</td>
            <td>${item?.Auto_Criteria02_212?.Auto_SSC}</td>
          </tr>
          <tr>
            <td>ST</td>
            <td>${item?.Auto_Criteria02_212?.Auto_SST}</td>
          </tr>
          <tr>
            <td>OBC</td>
            <td>${item?.Auto_Criteria02_212?.Auto_OOBC}</td>
          </tr>
          <tr>
            <td>General</td>
            <td>${item?.Auto_Criteria02_212?.Auto_GGen}</td>
          </tr>
          <tr>
            <td>Divyangjan</td>
            <td>${item?.Auto_Criteria02_212?.Auto_DDivyangjan}</td>
          </tr>
          <tr>
            <td>Others</td>
            <td>${item?.Auto_Criteria02_212?.Auto_OOthers}</td>
          </tr>
        </table>
      `;
        let obj = {
          //  Criteria 2_211

          Year: item?.Auto_Year,
          "Criteria_2.1.1": item?.Auto_Criteria02_211?.Auto_criteria,
          ProgramName: item?.Auto_Criteria02_211?.Auto_PrgName1,
          ProgramCode: item?.Auto_Criteria02_211?.Auto_PrgCode,
          "Number of seats sanctioned":
            item?.Auto_Criteria02_211?.Auto_NoSeatSanctioned,
          "Number of Students admitted":
            item?.Auto_Criteria02_211?.Auto_NoStdAdmit,
          "Description(min 500 words)(2.1.1)":
            item?.Auto_Criteria02_211?.Auto_Desc32,
          "Upload the relevant document(2.1.1)":
            item?.Auto_Criteria02_211?.Auto_Link32,

          // Criteria 2_212

          "Criteria_2.1.2": item?.Auto_Criteria02_212?.Auto_criteria,
          "Number of seats earmarked for reserved category as per GOI or State Government rule":
            tableMarkup1,
          "Number of students admitted from the reserved category":
            tableMarkup2,
          "Description(min 50 words)(2.1.2)":
            item?.Auto_Criteria02_212?.Auto_Desc33,
          "Upload the relevant document(2.1.2)":
            item?.Auto_Criteria02_212?.Auto_Link33,

          // Criteria 2_241
          "Criteria_2.4.1": item?.Auto_Criteria02_241?.Auto_criteria,
          "Name of the Full-time teacher":
            item?.Auto_Criteria02_241?.Auto_TName,
          PAN: item?.Auto_Criteria02_241?.Auto_PAN,
          Designation: item?.Auto_Criteria02_241?.Auto_Designation,
          "Year of appointment": item?.Auto_Criteria02_241?.Auto_YOfAppoint,
          "Nature of appointment (Against Sanctioned post,temporary,permanent)":
            item?.Auto_Criteria02_241?.Auto_NoOfAppoint,
          "Name of the Department": item?.Auto_Criteria02_241?.Auto_DeptName,
          "Total years of Experience in the same institution":
            item?.Auto_Criteria02_241?.Auto_TYofExp,
          "Is the teacher still serving the institution/If not last  year of the service of Faculty to the Institution.":
            item?.Auto_Criteria02_241?.Auto_TService,
          "Description(min 500 words)(2.4.1)":
            item?.Auto_Criteria02_241?.Auto_Desc34,
          "Upload the relevant document(2.4.1)":
            item?.Auto_Criteria02_241?.Auto_Link34,

          // Criteria 2_242

          "Criteria_2.4.2": item?.Auto_Criteria02_242?.Auto_criteria,
          " Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.":
            item?.Auto_Criteria02_242?.Auto_SerTeacher,
          " Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./ D.Litt.)":
            item?.Auto_Criteria02_242?.Auto_Qualify,
          "Year of obtaining the qualification":
            item?.Auto_Criteria02_242?.Auto_YOfQuali,
          "Year of Recognition as a Research Guide":
            item?.Auto_Criteria02_242?.Auto_YOfRecog,
          " Whether recognised as a Research Guide for  Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D'Lit.":
            item?.Auto_Criteria02_242?.Auto_Whether,
          " Is the teacher still serving the institution/If not last year of the service of Faculty to the Institution":
            item?.Auto_Criteria02_242?.Auto_IsThereTeacher,
          "Name of the scholar": item?.Auto_Criteria02_242?.Auto_NameScholar,
          "Year of registration of the scholar":
            item?.Auto_Criteria02_242?.Auto_YearScholar,
          "Description(min 50 characters)(2.4.2)":
            item?.Auto_Criteria02_242?.Auto_Desc35,
          "Upload the relevant document(2.4.2)":
            item?.Auto_Criteria02_242?.Auto_Link35,

          // Criteria 2_251
          "Criteria_2.5.1": item?.Auto_Criteria02_251?.Auto_criteria,
          " Programme Name.": item?.Auto_Criteria02_251?.Auto_pname1,
          "Semester/ year": item?.Auto_Criteria02_251?.Auto_semester,
          "Programme Code": item?.Auto_Criteria02_251?.Auto_pcode1,
          "Last date of the last semester-end/ year- end examination":
            item?.Auto_Criteria02_251?.Auto_last,
          " Date of declaration of results of semester-end/ year- end examination":
            item?.Auto_Criteria02_251?.Auto_Date,
          "Description(min 500 words)(2.5.1)":
            item?.Auto_Criteria02_251?.Auto_Desc36,
          "Upload the relevant document(2.5.1)":
            item?.Auto_Criteria02_251?.Auto_Link36,

          // Criteria 2_263
          "Criteria_2.6.3": item?.Auto_Criteria02_263?.Auto_criteria,
          Program_Name: item?.Auto_Criteria02_263?.Auto_PrgName2,
          Program_Code: item?.Auto_Criteria02_263?.Auto_pCode,
          " Number of students appeared in the final year examination":
            item?.Auto_Criteria02_263?.Auto_NStdAppeared,
          " Number of students passed in final year examination":
            item?.Auto_Criteria02_263?.Auto_NStdPassed,
          "Description(min 500 words)(2.6.3)":
            item?.Auto_Criteria02_263?.Auto_Desc37,
          " Upload the relevant document(2.6.3)":
            item?.Auto_Criteria02_263?.Auto_Link37,

          //Criteria 2_271
          "Criteria_2.7.1": item?.Auto_Criteria02_271?.Auto_criteria,
          "Program-Name": item?.Auto_Criteria02_271?.Auto_PrgName3,
          "Name of the student": item?.Auto_Criteria02_271?.Auto_NStd,
          Gender: item?.Auto_Criteria02_271?.Auto_Gender,
          Category: item?.Auto_Criteria02_271?.Auto_Category,
          "State of Domicile": item?.Auto_Criteria02_271?.Auto_SDomicile,
          "Nationality if othern than Indian":
            item?.Auto_Criteria02_271?.Auto_NIndian,
          "Email-ID": item?.Auto_Criteria02_271?.Auto_Email,
          "Mobile Number": item?.Auto_Criteria02_271?.Auto_MNo,
          "Year of joining": item?.Auto_Criteria02_271?.Auto_YJoining,
          "Unique Enrolment ID / College ID/ University enrollment number":
            item?.Auto_Criteria02_271?.Auto_UID,
          "Description(min 500 words)(2.7.1)":
            item?.Auto_Criteria02_271?.Auto_Desc38,
          "Upload the relevant document(2.7.1)":
            item?.Auto_Criteria02_271?.Auto_Link38,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editAuto_Criteria02_211 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc32;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc32) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editAuto_Criteria02_211/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_PrgName1: Auto_PrgName1,
          Auto_PrgCode: Auto_PrgCode,
          Auto_NoSeatSanctioned: Auto_NoSeatSanctioned,
          Auto_NoStdAdmit: Auto_NoStdAdmit,
          Auto_Desc32: Auto_Desc32,
          Auto_Link32: Auto_Link32,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri02details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria02_212 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc33;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Auto_Desc33) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editAuto_Criteria02_212/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_SC: Auto_SC,
          Auto_ST: Auto_ST,
          Auto_OBC: Auto_OBC,
          Auto_Gen: Auto_Gen,
          Auto_Divyangjan: Auto_Divyangjan,
          Auto_Others: Auto_Others,
          Auto_SSC: Auto_SSC,
          Auto_SST: Auto_SST,
          Auto_OOBC: Auto_OOBC,
          Auto_GGen: Auto_GGen,
          Auto_DDivyangjan: Auto_DDivyangjan,
          Auto_OOthers: Auto_OOthers,
          Auto_Desc33: Auto_Desc33,
          Auto_Link33: Auto_Link33,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri02details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria02_241 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc34;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc34) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria02_241/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_TName: Auto_TName,
          Auto_PAN: Auto_PAN,
          Auto_Designation: Auto_Designation,
          Auto_YOfAppoint: Auto_YOfAppoint,
          Auto_NoOfAppoint: Auto_NoOfAppoint,
          Auto_DeptName: Auto_DeptName,
          Auto_TYofExp: Auto_TYofExp,
          Auto_TService: Auto_TService,
          Auto_Desc34: Auto_Desc34,
          Auto_Link34: Auto_Link34,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri02details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria02_251 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc36;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Auto_Desc36) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria02_251/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_TName: Auto_TName,
          Auto_pname1: Auto_pname1,
          Auto_pcode1: Auto_pcode1,
          Auto_semester: Auto_semester,
          Auto_last: Auto_last,
          Auto_Date: Auto_Date,
          Auto_Desc36: Auto_Desc36,
          Auto_Link36: Auto_Link36,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri02details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria02_242 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc35;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc35) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria02_242/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_SerTeacher: Auto_SerTeacher,
          Auto_Qualify: Auto_Qualify,
          Auto_YOfQuali: Auto_YOfQuali,
          Auto_YOfRecog: Auto_YOfRecog,
          Auto_Whether: Auto_Whether,
          Auto_YOfRecog: Auto_YOfRecog,
          Auto_IsThereTeacher: Auto_IsThereTeacher,
          Auto_NameScholar: Auto_NameScholar,
          Auto_YearScholar: Auto_YearScholar,
          Auto_Desc35: Auto_Desc35,
          Auto_Link35: Auto_Link35,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri02details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria02_263 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc37;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc37) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria02_263/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_pCode: Auto_pCode,
          Auto_PrgName2: Auto_PrgName2,
          Auto_NStdAppeared: Auto_NStdAppeared,
          Auto_NStdPassed: Auto_NStdPassed,
          Auto_Desc37: Auto_Desc37,
          Auto_Link37: Auto_Link37,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri02details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria02_271 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc38;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc38) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editAuto_Criteria02_271/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_PrgName3: Auto_PrgName3,
          Auto_NStd: Auto_NStd,
          Auto_Gender: Auto_Gender,
          Auto_Category: Auto_Category,
          Auto_SDomicile: Auto_SDomicile,
          Auto_NIndian: Auto_NIndian,
          Auto_Email: Auto_Email,
          Auto_MNo: Auto_MNo,
          Auto_YJoining: Auto_YJoining,
          Auto_UID: Auto_UID,
          Auto_Desc38: Auto_Desc38,
          Auto_Link38: Auto_Link38,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri02details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getautocri02details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getautocri02details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.autocriteria02);
        setAuto_Criteria02(res.data.autocriteria02);
        setdata1(res.data.autocriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria02);
    }
  };

  useEffect(() => {
    getautocri02details();
  }, []);
  console.log(Auto_Criteria02);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  // const autoCriteria02Details = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://brightnaac.co.in/api/FacultyAdmin/getautocriteria2"
  //     );
  //     if (res.status === 200) {
  //       setAllDetails(res.data.autocriteria02);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAllDetails(error.response.data.autocriteria02);
  //   }
  // };

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-02
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/autocriteria002">
                <button className="btn btn-success">
                  Add Criteria 2{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of Students admitted</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>SC</th>
                  <th>ST</th>
                  <th>OBC</th>
                  <th>Gen</th>
                  <th>Divyangjan</th>
                  <th>Others</th>
                  <th>SSC</th>
                  <th>SST</th>
                  <th>OOBC</th>
                  <th>GGen</th>
                  <th>DDivyangjan</th>
                  <th>OOthers</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>
                    Nature of appointment (Against Sanctioned post,
                    temporary,permanent)
                  </th>
                  <th>Name of the Department</th>
                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution.
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th>Name of the scholar</th>
                  <th>Year of registration of the scholar</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Semester/ year</th>
                  <th>Program Code</th>
                  <th>
                    Last date of the last semester-end/ year- end examination
                  </th>
                  <th>
                    Date of declaration of results of semester-end/ year- end
                    examination
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if othern than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Auto_Criteria02?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Auto_Criteria02_211?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_211?.Auto_PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_211?.Auto_PrgCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_211?.Auto_NoSeatSanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_211?.Auto_NoStdAdmit?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_211?.Auto_Desc32?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_SC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_ST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_OBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_Gen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_Divyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_Others?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_SSC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_SST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_OOBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_GGen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_DDivyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_OOthers?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_212?.Auto_Desc33?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_TName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_PAN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_Designation?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_YOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_NoOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_DeptName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_TYofExp?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_TService?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_241?.Auto_Desc34?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_SerTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_YOfQuali?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_YOfRecog?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_IsThereTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_NameScholar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_YearScholar?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_Desc35?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_251?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_251?.Auto_pname1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_251?.Auto_pcode1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_251?.Auto_semester?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Auto_Criteria02_251?.Auto_last)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Auto_Criteria02_251?.Auto_YOfRecog)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Auto_Criteria02_251?.Auto_Desc36?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_263?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_263?.Auto_PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_263?.Auto_pCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_263?.Auto_NStdAppeared?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_263?.Auto_NStdPassed?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_263?.Auto_Desc37?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_NStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_Gender?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_SDomicile?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_NIndian?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_Email?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_MNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_YJoining?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_UID?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_271?.Auto_Desc38?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Auto_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Auto_Criteria02_211?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_211?.Auto_PrgName1}</td>
                      <td>{item?.Auto_Criteria02_211?.Auto_PrgCode}</td>
                      <td>
                        {item?.Auto_Criteria02_211?.Auto_NoSeatSanctioned}
                      </td>
                      <td>{item?.Auto_Criteria02_211?.Auto_NoStdAdmit}</td>
                      <td>
                        {item?.Auto_Criteria02_211?.Auto_Desc32?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_212?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_SC}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_ST}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_OBC}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_Gen}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_Divyangjan}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_Others}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_SSC}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_SST}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_OOBC}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_GGen}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_DDivyangjan}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_OOthers}</td>
                      <td>
                        {item?.Auto_Criteria02_212?.Auto_Desc33?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_241?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_TName}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_PAN}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_Designation}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_YOfAppoint}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_NoOfAppoint}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_DeptName}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_TYofExp}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_TService}</td>
                      <td>
                        {item?.Auto_Criteria02_241?.Auto_Desc34?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_242?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_SerTeacher}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_Qualify}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_YOfQuali}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_YOfRecog}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_Whether}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_IsThereTeacher}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_NameScholar}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_YearScholar}</td>
                      <td>
                        {item?.Auto_Criteria02_242?.Auto_Desc35?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_251?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_pname1}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_semester}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_pcode1}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_last}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_Date}</td>
                      <td>
                        {item?.Auto_Criteria02_251?.Auto_Desc36?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_263?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_263?.Auto_PrgName2}</td>
                      <td>{item?.Auto_Criteria02_263?.Auto_pCode}</td>
                      <td>{item?.Auto_Criteria02_263?.Auto_NStdAppeared}</td>
                      <td>{item?.Auto_Criteria02_263?.Auto_NStdPassed}</td>
                      <td>
                        {item?.Auto_Criteria02_263?.Auto_Desc37?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_271?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_PrgName3}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_NStd}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_Gender}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_Category}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_SDomicile}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_NIndian}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_Email}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_MNo}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_YJoining}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_UID}</td>
                      <td>
                        {item?.Auto_Criteria02_271?.Auto_Desc38?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                Autonomous_Criteria-02
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme name
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Auto_Criteria02_211?.Auto_PrgName1}
                        onChange={(e) => {
                          setAuto_PrgName1(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BBA">BBA</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_211?.Auto_PrgName1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme code
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_211?.Auto_PrgCode}
                        onChange={(e) => {
                          setAuto_PrgCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_211?.Auto_PrgCode}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of seats sanctioned
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_211?.Auto_NoSeatSanctioned
                        }
                        onChange={(e) => {
                          setAuto_NoSeatSanctioned(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_211?.Auto_NoSeatSanctioned}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students admitted
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_211?.Auto_NoStdAdmit
                        }
                        onChange={(e) => setAuto_NoStdAdmit(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <td>{Value?.Auto_Criteria02_211?.Auto_NoStdAdmit}</td>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_211?.Auto_Desc32}
                        onChange={(e) => setAuto_Desc32(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_211?.Auto_Desc32}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload1"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria02_211?.Auto_Link32}
                        onChange={(e) => setAuto_Link32(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_211?.Auto_Link32}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit1(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria02_211(e, Value?._id);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.1.2------ */}
                <h6 className="text-center text-decoration-underline">
                  Number of seats earmarked for reserved category as per GOI or
                  State Government rule
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_SC}
                        onChange={(e) => {
                          setAuto_SC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_SC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST{" "}
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_ST}
                        onChange={(e) => {
                          setAuto_ST(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_ST}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_OBC}
                        onChange={(e) => {
                          setAuto_OBC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_OBC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_Gen}
                        onChange={(e) => {
                          setAuto_Gen(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_Gen}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_212?.Auto_Divyangjan
                        }
                        onChange={(e) => {
                          setAuto_Divyangjan(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_Divyangjan}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_Others}
                        onChange={(e) => {
                          setAuto_Others(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_Others}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students admitted from the reserved category
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_SSC}
                        onChange={(e) => {
                          setAuto_SSC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_SSC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_SST}
                        onChange={(e) => {
                          setAuto_SST(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_SST}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_OOBC}
                        onChange={(e) => {
                          setAuto_OOBC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_OOBC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_GGen}
                        onChange={(e) => {
                          setAuto_GGen(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_GGen}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_212?.Auto_DDivyangjan
                        }
                        onChange={(e) => {
                          setAuto_DDivyangjan(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_DDivyangjan}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_OOthers}
                        onChange={(e) => {
                          setAuto_OOthers(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_OOthers}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_Desc33}
                        onChange={(e) => setAuto_Desc33(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_Desc33}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria02_212?.Auto_Link33}
                        onChange={(e) => setAuto_Link33(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_212?.Auto_Link33}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit2(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria02_212(e, Value?._id);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.1 & 2.4.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.1 & 2.4.3------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Full-time teacher
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_241?.Auto_TName}
                        onChange={(e) => {
                          setAuto_TName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_TName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    PAN
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_241?.Auto_PAN}
                        onChange={(e) => {
                          setAuto_PAN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_PAN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Designation
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_241?.Auto_Designation
                        }
                        onChange={(e) => {
                          setAuto_Designation(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_Designation}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of appointment
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_241?.Auto_YOfAppoint
                        }
                        onChange={(e) => {
                          setAuto_YOfAppoint(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_YOfAppoint}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_241?.Auto_NoOfAppoint
                        }
                        onChange={(e) => {
                          setAuto_NoOfAppoint(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_NoOfAppoint}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_241?.Auto_DeptName}
                        onChange={(e) => {
                          setAuto_DeptName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_DeptName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total years of Experience in the same institution
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_241?.Auto_TYofExp}
                        onChange={(e) => {
                          setAuto_TYofExp(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_TYofExp}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_241?.Auto_TService}
                        onChange={(e) => {
                          setAuto_TService(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_TService}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_241?.Auto_Desc34}
                        onChange={(e) => setAuto_Desc34(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_Desc34}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria02_241?.Auto_Link34}
                        onChange={(e) => setAuto_Link34(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_241?.Auto_Link34}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit3(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria02_241(e, Value?._id);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.2------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_242?.Auto_SerTeacher
                        }
                        onChange={(e) => {
                          setAuto_SerTeacher(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_SerTeacher}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_242?.Auto_Qualify}
                        onChange={(e) => {
                          setAuto_Qualify(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_Qualify}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of obtaining the qualification
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_242?.Auto_YOfQuali}
                        onChange={(e) => {
                          setAuto_YOfQuali(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_YOfQuali}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Recognition as a Research Guide
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_242?.Auto_YOfRecog}
                        onChange={(e) => {
                          setAuto_YOfRecog(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_YOfRecog}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </label>

                  {edit4 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "270px" }}
                        placeholder={Value?.Auto_Criteria02_242?.Auto_Whether}
                        onChange={(e) => setAuto_Whether(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_Whether}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_242?.Auto_IsThereTeacher
                        }
                        onChange={(e) => {
                          setAuto_IsThereTeacher(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_IsThereTeacher}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scholar
                  </label>
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_242?.Auto_NameScholar
                        }
                        onChange={(e) => {
                          setAuto_NameScholar(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_NameScholar}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of registration of the scholar
                  </label>
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_242?.Auto_YearScholar
                        }
                        onChange={(e) => {
                          setAuto_YearScholar(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_YearScholar}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_242?.Auto_Desc35}
                        onChange={(e) => setAuto_Desc35(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_Desc35}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria02_242?.Auto_Link35}
                        onChange={(e) => {
                          setAuto_Link35(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_242?.Auto_Link35}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit4(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria02_242(e, Value?._id);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.5.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.5.1------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme name
                  </label>

                  {edit7 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Auto_Criteria02_251?.Auto_pname1}
                        onChange={(e) => {
                          setAuto_pname1(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_251?.Auto_pname1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Semester/ year
                  </label>

                  {edit7 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Auto_Criteria02_251?.Auto_semester}
                        onChange={(e) => {
                          setAuto_semester(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Semester-01">Semester-01</option>
                        <option value="Semester-02">Semester-02</option>
                        <option value="Semester-03">Semester-03</option>
                        <option value="Semester-04">Semester-04</option>
                        <option value="Semester-05">Semester-05</option>
                        <option value="Semester-06">Semester-06</option>
                        <option value="Semester-07">Semester-07</option>
                        <option value="Semester-08">Semester-08</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_251?.Auto_semester}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme code
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_251?.Auto_pcode1}
                        onChange={(e) => {
                          setAuto_pcode1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_251?.Auto_pcode1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Last date of the last semester-end/ year- end examination
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_251?.Auto_last}
                        onChange={(e) => {
                          setAuto_last(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_251?.Auto_last}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of declaration of results of semester-end/ year- end
                    examination
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_251?.Auto_Date}
                        onChange={(e) => {
                          setAuto_Date(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_251?.Auto_Date}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_251?.Auto_Desc36}
                        onChange={(e) => setAuto_Desc36(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_251?.Auto_Desc36}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload7"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria02_251?.Auto_Link36}
                        onChange={(e) => setAuto_Link36(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_251?.Auto_Link36}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit7(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria02_251(e, Value?._id);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.6.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.6.3------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit5 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Auto_Criteria02_263?.Auto_PrgName2}
                        onChange={(e) => setAuto_PrgName2(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_263?.Auto_PrgName2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_263?.Auto_pCode}
                        onChange={(e) => setAuto_pCode(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_263?.Auto_pCode}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students appeared in the final year examination
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={
                            Value?.Auto_Criteria02_263?.Auto_NStdAppeared
                          }
                          onChange={(e) => setAuto_NStdAppeared(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria02_263?.Auto_NStdAppeared}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students passed in final year examination
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria02_263?.Auto_NStdPassed
                        }
                        onChange={(e) => setAuto_NStdPassed(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_263?.Auto_NStdPassed}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_263?.Auto_Desc37}
                        onChange={(e) => setAuto_Desc37(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_263?.Auto_Desc37}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="name"
                        type="file"
                        name="email"
                        id="upload5"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria02_263?.Auto_Link37}
                        onChange={(e) => {
                          setAuto_Link37(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_263?.Auto_Link37}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit5(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria02_263(e, Value?._id);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit6 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Auto_Criteria02_271?.Auto_PrgName3}
                        onChange={(e) => {
                          setAuto_PrgName3(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_PrgName3}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Student Name
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_NStd}
                        onChange={(e) => {
                          setAuto_NStd(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_NStd}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gender
                  </label>
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_Gender}
                        onChange={(e) => {
                          setAuto_Gender(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_Gender}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_Category}
                        onChange={(e) => {
                          setAuto_Category(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_Category}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State of Domicile
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_SDomicile}
                        onChange={(e) => {
                          setAuto_SDomicile(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_SDomicile}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Nationality if othern than Indian
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_NIndian}
                        onChange={(e) => {
                          setAuto_NIndian(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_NIndian}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Email-ID
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_Email}
                        onChange={(e) => {
                          setAuto_Email(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_Email}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Mobile Number
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_MNo}
                        onChange={(e) => {
                          setAuto_MNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_MNo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of joining
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_YJoining}
                        onChange={(e) => {
                          setAuto_YJoining(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_YJoining}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_UID}
                        onChange={(e) => {
                          setAuto_UID(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_UID}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_Desc38}
                        onChange={(e) => setAuto_Desc38(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_Desc38}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="name"
                        type="file"
                        name="email"
                        id="upload6"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria02_271?.Auto_Link38}
                        onChange={(e) => {
                          setAuto_Link38(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria02_271?.Auto_Link38}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit6(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria02_271(e, Value?._id);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve ? (
              <></>
            ) : (
              <>
                <Button
                  variant="warning"
                  onClick={() => {
                    handleClose();
                  }}
                  className="me-2"
                >
                  Close
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_211?.Auto_Desc32}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_212?.Auto_Desc33}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_241?.Auto_Desc34}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_242?.Auto_Desc35}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_251?.Auto_Desc36}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_263?.Auto_Desc37}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_271?.Auto_Desc38}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documnet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_211?.Auto_Link32}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documnet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_212?.Auto_Link33}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documnet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_241?.Auto_Link34}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documnet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_242?.Auto_Link35}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documnet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_251?.Auto_Link36}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documnet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_263?.Auto_Link37}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documnet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_271?.Auto_Link38}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Auto_Criteria02;
