import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

function PDFprintUG5_523() {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }
  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    console.log("ddkd1", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("ddkd2", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("ddkd3", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("ddkd4", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

    // const input = document.getElementById("pdf");
    // const options = { scrollY: -window.scrollY, useCORS: true };
    // const canvas = await html2canvas(input, options);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    // pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    pdf.save("5.2.3.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);
  if (location?.state == null) {
    navigate("/UG_05_523");
  }
  return (
    <div>
      <div className="m-auto ">
        <div id="pdf">
          <div className="p-2">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className=" pdfimg pt-2 "
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                year:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.Year}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state[location?.state?.length - 1]?.criteria}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]["Criteria05_523"]["criteria"]}</>
                )}
              </div>
            </div>

            {finalArr?.map((item, i) => {
              return (
                <div
                  className="container-fluid report-pdf mt-5"
                  style={{ padding: "0px 100px" }}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Registration number/roll number for the exam</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.RollNo1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Names of students selected/ qualified</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.StdSelected1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>NET</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.NET1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>SLET</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.SLET1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>GATE</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.GATE1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>GMAT</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.GMAT1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>CAT</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.CAT1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>GRE</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.GRE1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>JAM</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.JAM1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>IELTS</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.IELTS1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>TOEFL</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.TOEFL1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Civil Services</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.Civil1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>State government examinations</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.SGExam1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>
                        Other examinations conducted by the State / Central
                        Government Agencies (Specify)
                      </span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.OtherExam1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Total</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.Total1}</span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Grand Total</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_523?.GTotal1}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Description</span>
                    </div>
                    <div className="col-lg-12 report-text  mb-3">
                      <span> {item?.Criteria05_523?.Desc26}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>
                        <a href={`${item?.Criteria05_523?.Link26}`}>
                          {item?.Criteria05_523?.Link26}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="text-center">
          <button className="ad" style={{}} onClick={createPDF}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default PDFprintUG5_523;
