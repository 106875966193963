import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

function UniversityCri3() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  // ================Show doc17 modal======================
  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  // ================Show doc18 modal======================
  const [show18, setShow18] = useState(false);
  const handleClose18 = () => setShow18(false);
  const handleShow18 = () => setShow18(true);

  const [show19, setShow19] = useState(false);
  const handleClose19 = () => setShow19(false);
  const handleShow19 = () => setShow19(true);

  const [show20, setShow20] = useState(false);
  const handleClose20 = () => setShow20(false);
  const handleShow20 = () => setShow20(true);

  const [show21, setShow21] = useState(false);
  const handleClose21 = () => setShow21(false);
  const handleShow21 = () => setShow21(true);

  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);

  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);
  const handleShow23 = () => setShow23(true);

  const [show24, setShow24] = useState(false);
  const handleClose24 = () => setShow24(false);
  const handleShow24 = () => setShow24(true);

  const [show25, setShow25] = useState(false);
  const handleClose25 = () => setShow25(false);
  const handleShow25 = () => setShow25(true);

  const [show26, setShow26] = useState(false);
  const handleClose26 = () => setShow26(false);
  const handleShow26 = () => setShow26(true);

  const [show27, setShow27] = useState(false);
  const handleClose27 = () => setShow27(false);
  const handleShow27 = () => setShow27(true);

  const [show28, setShow28] = useState(false);
  const handleClose28 = () => setShow28(false);
  const handleShow28 = () => setShow28(true);

  const [show29, setShow29] = useState(false);
  const handleClose29 = () => setShow29(false);
  const handleShow29 = () => setShow29(true);

  const [show30, setShow30] = useState(false);
  const handleClose30 = () => setShow30(false);
  const handleShow30 = () => setShow30(true);

  const [show31, setShow31] = useState(false);
  const handleClose31 = () => setShow31(false);
  const handleShow31 = () => setShow31(true);

  const [show32, setShow32] = useState(false);
  const handleClose32 = () => setShow32(false);
  const handleShow32 = () => setShow32(true);

  const [show33, setShow33] = useState(false);
  const handleClose33 = () => setShow33(false);
  const handleShow33 = () => setShow33(true);

  const [show34, setShow34] = useState(false);
  const handleClose34 = () => setShow34(false);
  const handleShow34 = () => setShow34(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const UniCriteria03Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getunicri03details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.unicriteria03);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria03);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatinguni3`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Uni_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          UniCriteria03Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      UniCriteria03Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center mt-4">
            <div className="col-lg-6 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-03
              </span>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <div className=" col-lg-6 text-center">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Uni_Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div className=" mb-2">
                        Current Rating :
                        <span className="m-1">{item?.rating}</span>
                        <span className="m-1">
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0 mt-4">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            {/* <div className="col-lg-2 text-end">
              <Button
                type="Submit"
                className="btn btn-warning"
                style={{ color: "white" }}
                onClick={() => {
                  // setValue(item);
                  handleShow();
                }}
              >
                Add Rating
              </Button>
            </div> */}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 w-100"></div>
        </div>

        <div className="row m-0 mt-4">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform "
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              {allYears
                ?.sort((a, b) => {
                  return Number(b?.from) - Number(a?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/unicriteria03">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  onClick={() => navigate(`/adminclgunicri3312/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-2"
                  onClick={() => navigate(`/adminclgunicri3313/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3314/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.1.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3316/${params?.id}`)}
                >
                  <Button className="criteriabtn">
                    3.1.6 & 3.2.1 & 3.2.2 & 3.2.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3332/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3333/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3343/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.4.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3344/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.4.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3345/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.4.5</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3346/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.4.6</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3347/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.4.7</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3352/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.5.2 & 3.5.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3362/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.6.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3363/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.6.3 & 3.6.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3371/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.7.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri3372/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.7.2</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 3.1.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher provided with seed money</th>
                  <th>The amount of seed money (INR in Lakhs)</th>
                  <th>Year of receiving</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.1.3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </th>
                  <th>Name of the award/fellowship</th>
                  <th>Year of Award</th>
                  <th>Awarding Agency</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.1.4 */}
                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Name of the Research fellow</th>
                  <th>Year of enrollment</th>
                  <th>Duration of fellowship</th>
                  <th>Type of the fellowship</th>
                  <th>Granting agency</th>
                  <th>Qualifying exam if any (NET, GATE, etc.)</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.1.6 & 3.2.1 & 3.2.2 & 3.2.4 */}
                  <th>Criteria</th>
                  <th>Name of the Scheme/Project/ Endowments/ Chairs</th>
                  <th>
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </th>
                  <th>Name of the Funding agency</th>
                  <th>Type (Government/Non-Government)</th>
                  <th>Department of Principal Investigator/ Co Investigator</th>
                  <th>Year of Award</th>
                  <th>Funds provided (INR in lakhs)</th>
                  <th>Duration of the project</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.3.2 */}
                  <th>Criteria</th>
                  <th>Name of the workshop/ seminar</th>
                  <th>Name of the students</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.3.3 */}
                  <th>Criteria</th>
                  <th>Year of Award</th>
                  <th>Title of the innovation</th>
                  <th>Name of the Awardee</th>
                  <th>Name of the Awarding Agency with contact details</th>
                  <th>
                    Category- institution/teacher/research scholar/student
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.3 */}
                  <th>Criteria</th>
                  <th>Name of the Teacher</th>
                  <th>Patent Number</th>
                  <th>Title of the patent</th>
                  <th>Year of Award / publish of patent</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.4 */}
                  <th>Criteria</th>
                  <th>Name of the PhD scholar</th>
                  <th>Name of the Department</th>
                  <th>Name of the guide/s</th>
                  <th>Title of the thesis</th>
                  <th>Year of registration of the scholar</th>
                  <th>Year of PhD awarded</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit (YES/NO)
                  </th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.5 */}
                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Department of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>Document</th>

                  {/* 3.4.6 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.7 */}
                  <th>Criteria</th>
                  <th>Name of the teacher</th>
                  <th>Name of the module developed</th>
                  <th>Platform on which module is developed</th>
                  <th>Date of launching e content</th>
                  <th>List of the e-content development facility available</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>Document</th>

                  {/* 3.5.2 & 3.5.2.1 */}
                  <th>Criteria</th>
                  <th>
                    Revenue generated from consultancy during the last five
                    years
                  </th>
                  <th>
                    Revenue generated from corporate training during the last
                    five years
                  </th>
                  <th>Number of trainees</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.6.2 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Name of the Award/ recognition</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Year of award</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.6.3 & 3.6.4 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of award</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.7.1 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the collaborating agency with contact details</th>
                  <th>Title of the collaborative activity</th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Nature of the activity</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.7.2 */}
                  <th>Criteria</th>
                  <th>Organisation with which MoU is signed</th>
                  <th>Name of the institution/ industry/ corporate house</th>
                  <th>Year of signing MoU</th>
                  <th>Duration</th>
                  <th>List the actual activities under each MOU year-wise</th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Uni_Year &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria03_312?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_Seed?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_SeedMoney?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_Receiving?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_FellowShip?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_AFellowship?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_Award?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_Agency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Sno?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Research?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Enroll?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Fellow?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Fellow1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Grant?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Endowments?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Invest?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_FAgency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Dep?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Award1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Funds?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Project?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_Seminar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_Parti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_DFrom?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_DTo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_YAw?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Innova?.toLowerCase()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Awardee?.toLowerCase()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Details?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Desc17?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Teacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Patent?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Title?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_YOAw?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Phd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Depar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Guide?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Thesis1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Scholar?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Phd1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Whether1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Recog?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Paper?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Author?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Dept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Journal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Pub?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_ISSN?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_ISIT?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Desc9?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_SLno?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Name?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Book?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Paper1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Title4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Year2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_ISBN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Name1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Teacher1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Module?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Platform?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Uni_Criteria03_347?.Uni_Launch)
                        ?.format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria03_347?.Uni_List1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Desc20?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Consult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Project1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Agency1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Revenue?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Consult2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Title1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Agency3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Revenue1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Trainee?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Name2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Name3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Name4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Year4?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Name5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_org?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Scheme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Year5?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Participants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_SLNO1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Colloborative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Agency2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Participants3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Collo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Durat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Activity?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_IIC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Signing?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Durat1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Uni_Year}</td>

                      {/* 3.1.2 */}
                      <td>{item?.Uni_Criteria03_312?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_312?.Uni_Seed}</td>
                      <td>{item?.Uni_Criteria03_312?.Uni_SeedMoney}</td>
                      <td>{item?.Uni_Criteria03_312?.Uni_Receiving}</td>
                      <td>
                        {item?.Uni_Criteria03_312?.Uni_Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow19();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.3 */}
                      <td>{item?.Uni_Criteria03_313?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_FellowShip}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_AFellowship}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_Award}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_Agency}</td>
                      <td>
                        {item?.Uni_Criteria03_313?.Uni_Desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow20();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.4 */}
                      <td>{item?.Uni_Criteria03_314?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Sno}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Research}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Enroll}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Fellow}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Fellow1}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Grant}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Qualify}</td>
                      <td>
                        {item?.Uni_Criteria03_314?.Uni_Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow21();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.6 & 3.2.1 & 3.2.2 & 3.2.4 */}
                      <td>{item?.Uni_Criteria03_316?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Endowments}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Invest}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_FAgency}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Type}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Dep}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Award1}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Funds}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Project}</td>
                      <td>
                        {item?.Uni_Criteria03_316?.Uni_Desc7?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow22();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.3.2 */}
                      <td>{item?.Uni_Criteria03_332?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_332?.Uni_Seminar}</td>
                      <td>{item?.Uni_Criteria03_332?.Uni_Parti}</td>
                      <td>
                        {moment(item?.Uni_Criteria03_332?.Uni_DFrom)?.format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Uni_Criteria03_332?.Uni_DTo)?.format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {item?.Uni_Criteria03_332?.Uni_Desc8?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow23();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.3.3 */}
                      <td>{item?.Uni_Criteria03_333?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_YAw}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Innova}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Awardee}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Details}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Category}</td>
                      <td>
                        {item?.Uni_Criteria03_333?.Uni_Desc17?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow24();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.3 */}
                      <td>{item?.Uni_Criteria03_343?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_Teacher}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_Patent}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_Title}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_YOAw}</td>
                      <td>
                        {item?.Uni_Criteria03_343?.Uni_Desc18?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow25();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.4 */}
                      <td>{item?.Uni_Criteria03_344?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Phd}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Depar}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Guide}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Thesis1}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Scholar}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Phd1}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Whether1}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Recog}</td>
                      <td>
                        {item?.Uni_Criteria03_344?.Uni_Desc19?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow26();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.5 */}
                      <td>{item?.Uni_Criteria03_345?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Paper}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Author}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Dept}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Journal}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Pub}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_ISSN}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_ISIT}</td>
                      <td>
                        {item?.Uni_Criteria03_345?.Uni_Desc9?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow27();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.6 */}
                      <td>{item?.Uni_Criteria03_346?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_SLno}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Name}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Book}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Paper1}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Title4}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Year2}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_ISBN}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Name1}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Whether}</td>
                      <td>
                        {item?.Uni_Criteria03_346?.Uni_Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow28();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.7 */}
                      <td>{item?.Uni_Criteria03_347?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Teacher1}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Module}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Platform}</td>
                      <td>
                        {moment(item?.Uni_Criteria03_347?.Uni_Launch)?.format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria03_347?.Uni_List1}</td>
                      <td>
                        {item?.Uni_Criteria03_347?.Uni_Desc20?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow29();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.5.2 & 3.5.2.1 */}
                      <td>{item?.Uni_Criteria03_352?.Uni_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Name of the teacher consultant</th>
                            <th>Name of consultancy project</th>
                            <th>
                              Consulting/Sponsoring agency with contact details
                            </th>
                            <th>Revenue generated (INR in Lakhs)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria03_352?.Uni_Consult}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Project1}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Agency1}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Revenue}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>
                              Names of the teacher-consultants/corporate
                              trainers
                            </th>
                            <th>Title of the corporate training program</th>
                            <th>
                              Agency seeking training with contact details
                            </th>
                            <th>Revenue generated (amount in rupees)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria03_352?.Uni_Consult2}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Title1}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Agency3}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Revenue1}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Trainee}</td>
                      <td>
                        {item?.Uni_Criteria03_352?.Uni_Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow30();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.2 */}
                      <td>{item?.Uni_Criteria03_362?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Name2}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Name3}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Name4}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Year4}</td>
                      <td>
                        {item?.Uni_Criteria03_362?.Uni_Desc12?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow31();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.3 & 3.6.4 */}
                      <td>{item?.Uni_Criteria03_363?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Name5}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_org}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Scheme}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Year5}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Participants1}</td>
                      <td>
                        {item?.Uni_Criteria03_363?.Uni_Desc13?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow32();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.1 */}
                      <td>{item?.Uni_Criteria03_371?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_SLNO1}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Colloborative}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Agency2}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Participants3}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Collo}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Durat}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Activity}</td>
                      <td>
                        {item?.Uni_Criteria03_371?.Uni_Desc14?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow33();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow17();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.2 */}
                      <td>{item?.Uni_Criteria03_372?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_Organ}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_IIC}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_Signing}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_Durat1}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_List}</td>
                      <td>
                        {item?.Uni_Criteria03_372?.Uni_Desc15?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow34();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow18();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_312?.Uni_Link5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_313?.Uni_Link6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_314?.Uni_Link23}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_316?.Uni_Link7}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_332?.Uni_Link8}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_333?.Uni_Link17}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_343?.Uni_Link18}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_344?.Uni_Link19}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_345?.Uni_Link9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 10 model */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_345?.Uni_Link10}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 11 model */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_346?.Uni_Link11}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 12 model */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_347?.Uni_Link20}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 13 model */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_347?.Uni_Link21}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 14 model */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_352?.Uni_Link22}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 15 model */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_362?.Uni_Link13}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 16 model */}
          <Modal show={show16} onHide={handleClose16}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_363?.Uni_Link14}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 17 model */}
          <Modal show={show17} onHide={handleClose17}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_371?.Uni_Link15}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 18 model */}
          <Modal show={show18} onHide={handleClose18}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_372?.Uni_Link16}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal_19 */}
          <Modal show={show19} onHide={handleClose19}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_312?.Uni_Desc5}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose19}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_20 */}
          <Modal show={show20} onHide={handleClose20}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_313?.Uni_Desc6}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose20}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_21 */}
          <Modal show={show21} onHide={handleClose21}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_314?.Uni_Desc23}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose21}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_22 */}
          <Modal show={show22} onHide={handleClose22}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Uni_Criteria03_316?.Uni_Desc7}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose22}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_23 */}
          <Modal show={show23} onHide={handleClose23}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {View?.Uni_Criteria03_332?.Uni_Desc8}
                <p> {View?.Uni_Criteria03_333?.Uni_Desc17}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose23}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_24 */}
          <Modal show={show24} onHide={handleClose24}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_333?.Uni_Desc17}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose24}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_25 */}
          <Modal show={show25} onHide={handleClose25}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_343?.Uni_Desc18}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose25}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_26 */}
          <Modal show={show26} onHide={handleClose26}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_344?.Uni_Desc19}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose26}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_27 */}
          <Modal show={show27} onHide={handleClose27}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Uni_Criteria03_345?.Uni_Desc9}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose27}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_28 */}
          <Modal show={show28} onHide={handleClose28}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_346?.Uni_Desc10}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose28}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_29 */}
          <Modal show={show29} onHide={handleClose29}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Uni_Criteria03_347?.Uni_Desc20}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose29}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_30 */}
          <Modal show={show30} onHide={handleClose30}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_352?.Uni_Desc21}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose30}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_31 */}
          <Modal show={show31} onHide={handleClose31}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_362?.Uni_Desc12}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose31}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_32 */}
          <Modal show={show32} onHide={handleClose32}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Uni_Criteria03_363?.Uni_Desc13}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose32}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_33 */}
          <Modal show={show33} onHide={handleClose33}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_371?.Uni_Desc14}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose33}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_34 */}
          <Modal show={show34} onHide={handleClose34}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria03_372?.Uni_Desc15}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose34}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UniversityCri3;
