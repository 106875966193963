import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Image, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
function Graphic() {
  // ================ add graphic modal===============================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================ delete graphic modal===============================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================ edit graphic modal===============================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // =================== calling api for adding the slider content======================
  let formdata = new FormData();
  const [Simage, setSimage] = useState("");
  const [Topic, setTopic] = useState("");
  const [Body, setBody] = useState("");
  const [View, setView] = useState({});

  const AddSilderImage = async (e) => {
    e.preventDefault();
    if (!Simage || !Topic || !Body) {
      return alert("Please fill all the fields!!!");
    }
    formdata.set("Image", Simage);
    formdata.set("topic", Topic);
    formdata.set("body", Body);
    try {
      const config = {
        url: "/admin/addsliderimage",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formdata,
      };
      await axios(config).then((res) => {
        alert("Added Successfully");
        setSimage("");
        setTopic("");
        setBody("");
        getallsliderimage();
        handleClose();
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the slider content======================
  const [SliderImage, setSliderImage] = useState([]);
  const getallsliderimage = async () => {
    let res = await axios.get(
      "https://brightnaac.co.in/api/admin/getallsliderimage"
    );
    if (res.status === 200) {
      setSliderImage(res.data.Simage);
    }
  };

  // =================== calling api for deleting the slider content======================
  const deleteHomeslider = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/deletesliderimage/" + id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setSimage("");
          setTopic("");
          setBody("");
          getallsliderimage();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };

  const editSliderContent = async (e, id) => {
    e.preventDefault();

    if (!Simage && !Topic && !Body) {
      return alert("Please Provide atleast one detail to edit!!!");
    }

    formdata.set("Image", Simage);
    formdata.set("topic", Topic);
    formdata.set("body", Body);

    try {
      const config = {
        url: "/admin/editslider/" + id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formdata,
      };
      await axios(config).then((res) => {
        alert(res.data.success);
        setSimage("");
        setTopic("");
        setBody("");
        getallsliderimage();
        handleClose2();
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  useEffect(() => {
    getallsliderimage();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="row justify-content-bteween align-items-center  mt-4 mb-4 p-0 m-0">
            <div className=" col-lg-6 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Graphic Page
              </span>
            </div>

            <div className="header-02  col-lg-6 text-end">
              <Button className="" onClick={handleShow}>
                Add
              </Button>
            </div>
          </div>

          <Table responsive striped bordered>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "25px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Image</th>
                <th>Topic</th>
                <th>Body</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {SliderImage?.map((item, i) => {
                return (
                  <tr
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <td>{i + 1}</td>

                    <td>
                      <AiOutlineEye
                        onClick={() => {
                          setView(item);
                          handleShow7();
                        }}
                        fontSize={20}
                      />
                    </td>

                    <td>{item?.topic}</td>

                    <td>
                      {item?.body?.slice(0, 70)}
                      ...
                      <Link
                        onClick={() => {
                          handleShow8();
                          setView(item);
                        }}
                      >
                        Read More...
                      </Link>
                    </td>
                    <td>
                      <BiEdit
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          margin: "10px",
                        }}
                        size="20px"
                        onClick={() => {
                          setView(item);
                          handleShow2();
                        }}
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          color: "red",
                          margin: "10px",
                        }}
                        onClick={() => {
                          setView(item);
                          handleShow1();
                        }}
                      >
                        <AiFillDelete size="20px" />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Show Body */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Body</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.body}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* =======================add slider======================= */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Home Slider Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label htmlFor="upload1">Slider Image</Form.Label>
                <Form.Control
                  className="vi_0"
                  type="file"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setSimage(e.target.files[0])}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Topic</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setTopic(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Body</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  onChange={(e) => setBody(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddSilderImage(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* =======================delete slider======================= */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span
              className="header-03"
              onClick={(e) => deleteHomeslider(e, View?._id)}
            >
              <Button>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>

        {/* =======================edit slider======================= */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Edit slider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label htmlFor="upload1">Slider Image</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setSimage(e.target.files[0])}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Topic</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={View?.topic}
                  onChange={(e) => setTopic(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Body</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  placeholder={View?.body}
                  onChange={(e) => setBody(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(e) => editSliderContent(e, View?._id)}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show Image */}

        {/* show 7 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Homeslider/${View?.Image}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Graphic;
