import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
const PgCriteria1 = () => {
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  let principalDetails = JSON.parse(sessionStorage.getItem("Puser"));

  // ================Show doc1 modal======================

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // ============================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaPG01/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          PG_approve: val,
          PG_remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getContent();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [content, setContent] = useState([]);
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcri01details"
      );
      if (res.status === 200) {
        setContent(res.data.pgcriteria01);
        // setdata1(res.data.pgcriteria01);
      }
    } catch (error) {
      console.log(error);
      setContent(error.response.data.pgcriteria01);
    }
  };

  console.log("content", content);

  // ============================================================

  useEffect(() => {
    principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);

  console.log("fjfjfjfjf", content);
  console.log("principal", principalDetails?._id);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-01
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div className="">
            <Nav defaultActiveKey="/pg-Criteria1">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/PG_01_113")}
                >
                  <Button className="criteriabtn  btn-sub1"> 1.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/PG_01_121")}
                >
                  <Button className="criteriabtn  btn-sub">1.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_01_122")}
                >
                  <Button className="criteriabtn  btn-sub">1.2.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_01_132")}
                >
                  <Button className="criteriabtn  btn-sub">1.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_01_133")}
                >
                  <Button className="criteriabtn btn-sub">1.3.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", minWidth: "10000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>ID</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Name of the teacher participated</th>
                  <th>
                    Name of the body in which full time teacher participated
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Code</th>
                  <th>Program Name</th>
                  <th>Year Of Introduction</th>
                  <th>Status of implementation of CBCS/elective course</th>
                  <th>Year of implementation of CBCS/elective course</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of Add on /Certificate programs offered</th>
                  <th>Course Code (if any)</th>
                  <th>Year of offering</th>
                  <th>No. of times offered during the same year</th>
                  <th>Duration of course</th>
                  <th>Number of students enrolled in the year</th>
                  <th>Number of Students completing the course in the year</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Year of offering</th>
                  <th>Course Code</th>
                  <th>
                    Name of the student studied course on experiential learning
                    through project work/field work/internship
                  </th>
                  <th>
                    Name of the Course that include experiential learning
                    through project work/field work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    List of students undertaking project work/field
                    work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.PG_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      principalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      (item1?.PG_approve === "false" ||
                        item1?.PG_approve === "Pending") &&
                      (SearchItem === "" ||
                        item1?.PG_Criteria01_113?.PG_criteria?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Year?.includes(SearchItem) ||
                        item1?.PG_Criteria01_113?.PG_TeacherPar?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_113?.PG_BodyName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_113?.PG_Desc1?.toLowerCase()?.includes() ||
                        item1?.PG_Criteria01_121?.PG_PrgmCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_121?.PG_PrgName1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_121?.PG_YOfIntro?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_121?.PG_Status?.toLowerCase()?.includes() ||
                        item1?.PG_Criteria01_121?.PG_YOfCBCS?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_121?.PG_Desc2?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_121?.PG_Link2?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_122?.PG_CourseCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_CertiName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_122?.PG_YOfOffering?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_NoOfTimes?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_CourseDuration?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_NoOfStudents?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_CourseCompletion?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_Desc3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_122?.PG_Link3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_122?.PG_CourseCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_CertiName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_122?.PG_YOfOffering?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_NoOfTimes?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_CourseDuration?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_NoOfStudents?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_CourseCompletion?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_122?.PG_Desc3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_122?.PG_Link3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_133?.PG_PrgName3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_133?.PG_PPPrgmCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_133?.PG_StdList?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_133?.PG_Desc5?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria01_133?.PG_Link5?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?._id}</td>
                        <td>{item?.PG_Year}</td>
                        <td>{item?.PG_Criteria01_113?.PG_criteria}</td>
                        <td>{item?.PG_Criteria01_113?.PG_TeacherPar}</td>
                        <td>{item?.PG_Criteria01_113?.PG_BodyName}</td>

                        <td>
                          {item?.PG_Criteria01_113?.PG_Desc1?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow6();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>

                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>

                        <td>{item?.PG_Criteria01_121?.PG_criteria}</td>
                        <td>{item?.PG_Criteria01_121?.PG_PrgmCode}</td>
                        <td>{item?.PG_Criteria01_121?.PG_PrgName1}</td>
                        <td>{item?.PG_Criteria01_121?.PG_YOfIntro}</td>
                        <td>{item?.PG_Criteria01_121?.PG_Status}</td>
                        <td>{item?.PG_Criteria01_121?.PG_YOfCBCS}</td>

                        <td>
                          {item?.PG_Criteria01_121?.PG_Desc2?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow7();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>

                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow2();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>

                        <td>{item?.PG_Criteria01_122?.PG_criteria}</td>
                        <td>{item?.PG_Criteria01_122?.PG_CertiName}</td>
                        <td>{item?.PG_Criteria01_122?.PG_CourseCode}</td>
                        <td>{item?.PG_Criteria01_122?.PG_YOfOffering}</td>
                        <td>{item?.PG_Criteria01_122?.PG_NoOfTimes}</td>
                        <td>{item?.PG_Criteria01_122?.PG_CourseDuration}</td>
                        <td>{item?.PG_Criteria01_122?.PG_NoOfStudents}</td>
                        <td>{item?.PG_Criteria01_122?.PG_CourseCompletion}</td>

                        <td>
                          {item?.PG_Criteria01_122?.PG_Desc3?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow8();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow3();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>

                        <td>{item?.PG_Criteria01_132?.PG_criteria}</td>
                        <td>{item?.PG_Criteria01_132?.PG_PrgName2}</td>
                        <td>{item?.PG_Criteria01_132?.PG_PPrgmCode}</td>
                        <td>{item?.PG_Criteria01_132?.PG_YYOfOffering}</td>
                        <td>{item?.PG_Criteria01_132?.PG_Course_code}</td>
                        <td>{item?.PG_Criteria01_132?.PG_StdCourseStudied}</td>
                        <td>{item?.PG_Criteria01_132?.PG_ExpCourse}</td>
                        <td>
                          {item?.PG_Criteria01_132?.PG_Desc4?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow9();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow4();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>

                        <td>{item?.PG_Criteria01_133?.PG_criteria}</td>
                        <td>{item?.PG_Criteria01_133?.PG_PrgName3}</td>
                        <td>{item?.PG_Criteria01_133?.PG_PPPrgmCode}</td>
                        <td>{item?.PG_Criteria01_133?.PG_StdList}</td>

                        <td>
                          {item?.PG_Criteria01_133?.PG_Desc5?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow10();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow5();
                            }}
                            fontSize={20}
                          />{" "}
                        </td>

                        <td className="tdata">
                          <div className="d-flex justify-content-evenly align-items-center">
                            <i
                              class="fa-solid fa-eye fa-lg"
                              onClick={() => {
                                setValue(item);
                                handleShow();
                              }}
                              style={{ color: "#9a9996" }}
                            ></i>
                          </div>
                        </td>
                        <td className="tdata">
                          {item?.PG_approve === "true" ? (
                            <div style={{ color: "green" }}>Approved</div>
                          ) : (
                            <>
                              {item?.PG_approve === "false" ? (
                                <div style={{ color: "red" }}>
                                  {" "}
                                  Not Approved
                                </div>
                              ) : (
                                <div> Pending</div>
                              )}
                            </>
                          )}
                        </td>
                        <td className="tdata">{item?.remark}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.PG_Criteria01_113?.PG_Desc1}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose6}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria01_121?.PG_Desc2}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria01_122?.PG_Desc3}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose8}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria01_132?.PG_Desc4}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 10 model */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.PG_Criteria01_133?.PG_Desc5}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Dcument</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_133?.PG_Link1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Dcument</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_121?.PG_Link2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Dcument</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria07/${View?.PG_Criteria01_122?.PG_Link3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Dcument</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_132?.PG_Link4}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Dcument</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_133?.PG_Link5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 1 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------1.1.3------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher participated
                  </label>

                  <>{Value?.PG_Criteria01_113?.PG_TeacherPar}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the body in which full time teacher participated
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_113?.PG_BodyName}</>
                  </>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria01_113?.PG_Desc1}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria01_113?.PG_Link1}</>
                </div>
              </div>
            </div>
            {/* ------1.2.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_121?.PG_PrgmCode}</>
                  </>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_121?.PG_PrgName1}</>
                  </>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year Of Introduction
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_121?.PG_YOfIntro}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Status of implementation of CBCS/elective course
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_121?.PG_Status}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of implementation of CBCS/elective course
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_121?.PG_YOfCBCS}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria01_121?.PG_Desc2}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria01_121?.PG_Link2}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.2.2 & 1.2.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of Add on /Certificate programs offered
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_122?.PG_CertiName}</>
                  </>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code (if any)
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_122?.PG_CourseCode}</>
                  </>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of offering
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_122?.PG_YOfOffering}</>
                  </>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    No. of times offered during the same year
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_122?.PG_NoOfTimes}</>
                  </>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of course
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_122?.PG_CourseDuration}</>
                  </>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students enrolled in the year
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_122?.PG_NoOfStudents}</>
                  </>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students completing the course in the year
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_122?.PG_CourseCompletion}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria01_122?.PG_Desc3}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria01_122?.PG_Link3}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    Program Name
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_132?.PG_PrgName2}</>
                  </>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_132?.PG_PPrgmCode}</>
                  </>
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of offering
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_132?.PG_YYOfOffering}</>
                  </>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Course Code
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_132?.PG_Course_code}</>
                  </>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student studied course on experiential learning
                    through project work/field work/internship
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_132?.PG_StdCourseStudied}</>
                  </>
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the Course that include experiential learning
                    through project work/field work/internship
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_132?.PG_ExpCourse}</>
                  </>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria01_132?.PG_Desc4}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria01_132?.PG_Link4}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_133?.PG_PrgName3}</>
                  </>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_133?.PG_PPPrgmCode}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    List of students undertaking project work/field
                    work/internship
                  </label>

                  <>
                    <>{Value?.PG_Criteria01_133?.PG_StdList}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.PG_Criteria01_133?.PG_Desc5}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.PG_Criteria01_133?.PG_Link5}</>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.PG_approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  className="btn-success"
                  onClick={(e) => {
                    approveCriteria(e, true, Value?._id);
                    handleClose();
                  }}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => handleClosesm()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              onChange={(e) => setremark(e.target.value)}
              style={{ height: "200px" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                approveCriteria(e, false, Value?._id);
                handleClose();
                handleClosesm();
              }}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default PgCriteria1;
