import React from "react";
import { Col, Container } from "react-bootstrap";
import "../Component/Styles/Footer.css";
import { BsWhatsapp } from "react-icons/bs";
import { CgMail } from "react-icons/cg";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <div
        className="footer"
        style={{
          backgroundImage: "url('../Image/footerimg.avif')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "350px",
        }}
      >
        {/* <div class="ocean">
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
        </div> */}
        <Container
          fluid
          style={{
            padding: "0px 50px",
          }}
        >
          <div className="footer-0">
            <div className="row  justify content-around">
              <Col md={3}>
                <div className="provid mb-3">
                  <img
                    src="NME-Logo.png"
                    style={{
                      maxHeight: "150px",
                    }}
                  />
                </div>
              </Col>

              <Col md={3}>
                <div className="provid mb-3">
                  <h5>NAAC</h5>
                  <p>
                    Providing Life Changing Experiences Through Education. Class
                    That Fit Your Busy Life.
                  </p>
                </div>
              </Col>
              <Col md={3}>
                <div className="provid mb-3">
                  <h5>Useful Links</h5>

                  <ul className="pro-links">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About</Link>
                    </li>
                    <li>
                      <Link to="/events">Event</Link>
                    </li>
                    <li>
                      <Link to="/courses">Courses</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3}>
                <div className="provid mb-3 ">
                  <h5 className="">Address</h5>

                  <div>
                    HEMA ENCLAVE, H.NO: 1-11-66, Hema Enclave, Lingasugur Road,
                    Opp Agriculture University, Raichur 584101
                  </div>
                </div>
                <div>
                  <CgMail /> <span> Email : help.contact@gmail.com</span>{" "}
                </div>
                <BsWhatsapp /> Phone : 8277251111/8277242222
              </Col>
            </div>
          </div>
        </Container>
        <div className="d-flex justify-content-center">
          {/* 2024 © copyright <br />
                    All rights reserved. */}
          Copyright © 2024 Bright beginning education PVT LTD. All Rights
          Reserved.
        </div>
      </div>
    </>
  );
}

export default Footer;
