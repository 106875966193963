import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";

const PgCriteria4 = () => {
  const navigate = useNavigate();
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));

  // =====================================================
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // ===========================================================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/LibraryAdmin/approveFacultyCriteriaPG04/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria04Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const pgCriteria04Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getpgcriteria04"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria04);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria04);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      pgCriteria04Details();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-04
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div className="">
            <Nav defaultActiveKey="/pgcriteria04">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/PG_04_413")}
                >
                  <Button className="criteriabtn btn-sub1">4.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/PG_04_414")}
                >
                  <Button className="criteriabtn btn-sub">4.1.4 & 4.4.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_04_422")}
                >
                  <Button className="criteriabtn btn-sub">4.2.2 & 4.2.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 4.1.3 */}
                  <th>Criteria</th>
                  <th>
                    Room number or Name of Classrooms and Seminar halls with
                    LCD/wifi/LAN facilities
                  </th>
                  <th>Type of ICT facility</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 4.1.4 & 4.4.1 */}
                  <th>Criteria</th>
                  <th>
                    Budget allocated for infrastructure augmentation (INR in
                    Lakh)
                  </th>
                  <th>
                    Expenditure for infrastructure augmentation (INR in Lakh)
                  </th>
                  <th>Total expenditure excluding Salary (INR in Lakh)</th>
                  <th>
                    Expenditure on maintenance of academic facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </th>
                  <th>
                    Expenditure on maintenance of physical facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 4.2.2 & 4.2.3 */}
                  <th>Criteria</th>
                  <th>Library Resources</th>
                  <th>If yes, details of memberships/subscriptions</th>
                  <th>
                    Expenditure on subscription to e-journals, e-books (INR in
                    lakhs)
                  </th>
                  <th>
                    Expenditure on subscription to other e-resources (INR in
                    lakhs)
                  </th>
                  <th>Total Library Expenditure</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View Details</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "Pending" &&
                    (SearchItem === "" ||
                      item1?.PG_Criteria04_413?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_413?.PG_Room?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_413?.PG_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria04_413?.PG_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria04_414?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_414?.PG_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_414?.PG_Expenditure?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_414?.PG_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_414?.PG_Expend1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_414?.PG_Expend2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_414?.PG_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria04_422?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_422?.PG_Library?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria04_422?.PG_IfYes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria04_422?.PG_Expend3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_422?.PG_Other?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_422?.PG_Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria04_422?.PG_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.PG_Year}</td>
                      {/* 413 */}
                      <td>{item?.PG_Criteria04_413?.PG_criteria}</td>
                      <td>{item?.PG_Criteria04_413?.PG_Room}</td>
                      <td>{item?.PG_Criteria04_413?.PG_Type}</td>

                      <td>
                        {item?.PG_Criteria04_413?.PG_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 414 */}
                      <td>{item?.PG_Criteria04_414?.PG_criteria}</td>
                      <td>{item?.PG_Criteria04_414?.PG_Budget}</td>
                      <td>{item?.PG_Criteria04_414?.PG_Expenditure}</td>
                      <td>{item?.PG_Criteria04_414?.PG_Total}</td>
                      <td>{item?.PG_Criteria04_414?.PG_Expend1}</td>
                      <td>{item?.PG_Criteria04_414?.PG_Expend2}</td>

                      <td>
                        {item?.PG_Criteria04_414?.PG_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 332 */}
                      <td>{item?.PG_Criteria04_422?.PG_criteria}</td>
                      <td>{item?.PG_Criteria04_422?.PG_Library}</td>
                      <td>{item?.PG_Criteria04_422?.PG_IfYes}</td>
                      <td>{item?.PG_Criteria04_422?.PG_Expend3}</td>
                      <td>{item?.PG_Criteria04_422?.PG_Other}</td>
                      <td>{item?.PG_Criteria04_422?.PG_Total1}</td>

                      <td>
                        {item?.PG_Criteria04_422?.PG_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setValue(item);
                            handleShow();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.PG_Criteria04_413?.PG_Desc1}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose4}>
                close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.PG_Criteria04_414?.PG_Desc2}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose5}>
                close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.PG_Criteria04_422?.PG_Desc3}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose6}>
                close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria4/${View?.PG_Criteria04_413?.PG_Link1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria4/${View?.PG_Criteria04_414?.PG_Link2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria4/${View?.PG_Criteria04_422?.PG_Link3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Add Model */}
          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#" }}>Criteria 4 </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row-fluid mt-3">
                  <div
                    className="row border"
                    style={{
                      padding: "30px",
                      borderRadius: "5px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <h6>Criteria04--(4.1.3)</h6>
                    <div className="row">
                      <div className="col-md-8 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Room number or Name of Classrooms and Seminar halls
                          with ICT-enabled facilities
                        </label>
                        <br />

                        <>{Value?.PG_Criteria04_413?.PG_Room}</>
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Type of ICT facility
                        </label>
                        <br />

                        <>{Value?.PG_Criteria04_413?.PG_Type}</>
                      </div>

                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Description(min 500 characters)
                        </label>
                        <br />

                        <>{Value?.PG_Criteria04_413?.PG_Desc1}</>
                      </div>
                      <div className="col-md-12 mt-3 ">
                        <label className="content1">
                          {" "}
                          Upload the relevant document
                        </label>
                        <br />

                        <>{Value?.PG_Criteria04_413?.PG_Link1}</>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ------4.1.4 & 4.4.1-------- */}

              <div className="container">
                <div className="row-fluid mt-3">
                  <div
                    className="row border"
                    style={{
                      padding: "30px",
                      borderRadius: "5px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <h6>Criteria04--(4.1.4 & 4.4.1)</h6>

                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Budget allocated for infrastructure apgmentation (INR
                          in Lakh)
                        </label>
                        <br />

                        <>{Value?.PG_Criteria04_414?.PG_Budget}</>
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Expenditure for infrastructure apgmentation (INR in
                          Lakh)
                        </label>
                        <br />

                        <>{Value?.PG_Criteria04_414?.PG_Expenditure}</>
                      </div>
                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Total expenditure excluding Salary (INR in Lakh)
                        </label>
                        <br />

                        <>{Value?.PG_Criteria04_414?.PG_Total}</>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Expenditure on maintenance of academic facilities
                        (excluding salary for human resources) (INR in Lakh)
                      </label>
                      <br />

                      <>{Value?.PG_Criteria04_414?.PG_Expend1}</>
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Expenditure on maintenance of physical facilities
                        (excluding salary for human resources) (INR in Lakh)
                      </label>
                      <br />

                      <>{Value?.PG_Criteria04_414?.PG_Expend2}</>
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 characters)
                      </label>
                      <br />

                      <>{Value?.PG_Criteria04_414?.PG_Desc2}</>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />

                      <> {Value?.PG_Criteria04_414?.PG_Link2}</>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row-fluid mt-3">
                  <div
                    className="row border "
                    style={{
                      padding: "50px",
                      borderRadius: "5px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <div className="row">
                      <h6>Criteria04--(4.2.2)</h6>
                      <div className="row">
                        <div className="row">
                          <div className="col-md-6 mt-3">
                            <label className="content1" htmlFor="name">
                              {" "}
                              Library resources
                            </label>
                            <br />

                            <>{Value?.PG_Criteria04_422?.PG_Library}</>
                          </div>
                          <div className="col-md-12 mt-3">
                            <label className="content1" htmlFor="name">
                              {" "}
                              If yes, details of memberships/subscriptions
                            </label>
                            <br />

                            <>{Value?.PG_Criteria04_422?.PG_IfYes}</>
                          </div>
                          <div className="col-md-12 mt-3">
                            <label className="content1" htmlFor="name">
                              {" "}
                              Expenditure on subscription to e-journals, e-books
                              (INR in lakhs)
                            </label>
                            <br />

                            <>{Value?.PG_Criteria04_422?.PG_Expend3}</>
                          </div>
                          <div className="col-md-12 mt-3">
                            <label className="content1" htmlFor="name">
                              {" "}
                              Expenditure on subscription to other e-resources
                              (INR in lakhs)
                            </label>
                            <br />

                            <>{Value?.PG_Criteria04_422?.PG_Other}</>
                          </div>
                          <div className="col-md-12 mt-3">
                            <label className="content1" htmlFor="name">
                              {" "}
                              Total Library Expenditure
                            </label>

                            <>{Value?.PG_Criteria04_422?.PG_Total1}</>
                          </div>
                          <div className="col-md-12 mt-3 ">
                            <label className="content1" htmlFor="email">
                              {" "}
                              Description(min 500 characters)
                            </label>
                            <br />

                            <>{Value?.PG_Criteria04_422?.PG_Desc3}</>
                          </div>
                          <div className="col-md-12 mt-3 ">
                            <label className="content1" htmlFor="email">
                              {" "}
                              Upload the relevant document
                            </label>
                            <br />

                            <>{Value?.PG_Criteria04_422?.PG_Link3}</>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {Value?.approve === "false" ? (
                <></>
              ) : (
                <div>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      approveCriteria(e, true, Value?._id);
                      handleClose();
                    }}
                  >
                    Approve{" "}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => setSmShow(true)}
                    className="me-2"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal>

          <Modal
            // size="sm"
            show={smShow}
            onHide={() => handleClosesm()}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Tell me the reason?..
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                onChange={(e) => setremark(e.target.value)}
                style={{ height: "200px" }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  approveCriteria(e, false, Value?._id);
                  handleClose();
                  handleClosesm();
                }}
              >
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PgCriteria4;
