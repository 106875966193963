import moment from "moment";
import React from "react";

const PG6653aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (from)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {" "}
            {moment(item?.PG_Criteria06_653?.PG_Sdate2).format("DD-MM-YYYY")}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (to)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {" "}
            {moment(item?.PG_Criteria06_653?.PG_Edate2).format("DD-MM-YYYY")}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Participation in NIRF along with Status.</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria06_653?.PG_nirfs}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Academic Administrative Audit (AAA) and follow up action</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria06_653?.PG_aaa}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> ISO Certification. and nature and validity period</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria06_653?.PG_isoc}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            NBA or any other certification received with program specifications
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria06_653?.PG_nba}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Conferences, Seminars, Workshops on quality conducted</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria06_653?.PG_cswq}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Collaborative quality initiatives with other institution(s) (Provide
            name of the institution and activity)
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria06_653?.PG_iaa}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria06_653?.PG_desc6}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {/* <a href={`${item?.PG_Criteria06_653?.PG_doc9}`}> */}

            {/* </a> */}

            <a
              href={`https://brightnaac.co.in/PG_Criteria06/${item?.PG_Criteria06_653?.PG_doc9}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG6653aqar;
