import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";

const UnderGraduateCri2 = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [View, setView] = useState({});
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // show description model
  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const ugCriteria02Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria02"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria02);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  // =================================================================

  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatingug2`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria02Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      ugCriteria02Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", params?.id);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-6 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-02
              </span>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="">Current Rating: </h6>
                <div className="mt-4">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            {/* <div className="col-lg-2 text-end">
              <Button
                type="Submit"
                className="btn btn-warning"
                style={{ color: "white" }}
                onClick={() => {
                  // setValue(item);
                  handleShow();
                }}
              >
                Add Rating
              </Button>
            </div> */}
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0 "
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              {allYears
                ?.sort((a, b) => {
                  return Number(b?.from) - Number(a?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/ugcriteria02">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  onClick={() => navigate(`/adminclgugcri211/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 2.1.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-2"
                  onClick={() => navigate(`/adminclgugcri212/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 2.1.2 </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri241/${params?.id}`)}
                >
                  <Button className="criteriabtn">2.4.1 & 2.4.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-4"
                  onClick={() => navigate(`/adminclgugcri242/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 2.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-5"
                  onClick={() => navigate(`/adminclgugcri263/${params?.id}`)}
                >
                  <Button style={{}} className="criteriabtn">
                    {" "}
                    2.6.3
                  </Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="link-5"
                  onClick={() => navigate(`/adminclgugcri271/${params?.id}`)}
                >
                  <Button style={{}} className="criteriabtn">
                    {" "}
                    2.7.1
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  <th>Criteria</th>
                  <th>Programme name</th>
                  <th>Programme code</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of Students admitted</th>
                  <th>Description </th>
                  <th>Document </th>

                  <th>Criteria</th>
                  <th>
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </th>
                  {/* <th>SC</th>
                  <th>ST</th>
                  <th>OBC</th>
                  <th>Gen</th>
                  <th>Divyangjan</th>
                  <th>Others</th> */}
                  <th>
                    Number of students admitted from the reserved category
                  </th>
                  {/* <th>SC</th>
                  <th>ST</th>
                  <th>OBC</th>
                  <th>Gen</th>
                  <th>Divyangjan</th>
                  <th>Others</th> */}
                  <th>Description</th>
                  <th>Document </th>

                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </th>
                  <th>Name of the Department</th>
                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th>Description</th>
                  <th>Document </th>

                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </th>
                  <th>Description</th>
                  <th>Document </th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Document </th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if othern than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </th>
                  <th>Description</th>
                  <th>Document </th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Year &&
                    (SearchItem === "" ||
                      item1?.Criteria02_211?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria02_211?.PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_211?.PrgCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_211?.NoSeatSanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_211?.NoStdAdmit?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_211?.Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.SC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.ST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.OBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.Gen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.Divyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.Others?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.SSC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.SST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.OOBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.GGen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.DDivyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.OOthers?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.TName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.PAN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_241?.Designation?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.YOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_241?.NoOfAppoint?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.DeptName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.TYofExp?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_241?.TService?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.SerTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.YOfQuali?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_242?.Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.YOfRecog?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_242?.Desc9?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_263?.PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_263?.pCode
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria02_263?.NStdAppeared?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_263?.NStdPassed?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_263?.Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.NStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Gender?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.SDomicile?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.NIndian?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Email?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.MNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_271?.YJoining?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_271?.UID?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Desc11?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Year}</td>

                      <td>{item?.Criteria02_211?.criteria}</td>
                      <td>{item?.Criteria02_211?.PrgName1}</td>
                      <td>{item?.Criteria02_211?.PrgCode}</td>
                      <td>{item?.Criteria02_211?.NoSeatSanctioned}</td>
                      <td>{item?.Criteria02_211?.NoStdAdmit}</td>
                      <td>
                        {item?.Criteria02_211?.Desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            fontSize={20}
                          />
                        </td>
                      </td>

                      <td>{item?.Criteria02_212?.criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Criteria02_212?.SC}</td>
                              <td>{item?.Criteria02_212?.ST}</td>
                              <td>{item?.Criteria02_212?.OBC}</td>
                              <td>{item?.Criteria02_212?.Gen}</td>
                              <td>{item?.Criteria02_212?.Divyangjan}</td>
                              <td>{item?.Criteria02_212?.Others}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Criteria02_212?.SSC}</td>
                              <td>{item?.Criteria02_212?.SST}</td>
                              <td>{item?.Criteria02_212?.OOBC}</td>
                              <td>{item?.Criteria02_212?.GGen}</td>
                              <td>{item?.Criteria02_212?.DDivyangjan}</td>
                              <td>{item?.Criteria02_212?.OOthers}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        {item?.Criteria02_212?.Desc7?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria02_212?.Link7} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_241?.criteria}</td>
                      <td>{item?.Criteria02_241?.TName}</td>
                      <td>{item?.Criteria02_241?.PAN}</td>
                      <td>{item?.Criteria02_241?.Designation}</td>
                      <td>{item?.Criteria02_241?.YOfAppoint}</td>
                      <td>{item?.Criteria02_241?.NoOfAppoint}</td>
                      <td>{item?.Criteria02_241?.DeptName}</td>
                      <td>{item?.Criteria02_241?.TYofExp}</td>
                      <td>{item?.Criteria02_241?.TService}</td>
                      <td>
                        {item?.Criteria02_241?.Desc8?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria02_241?.Link8} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_242?.criteria}</td>
                      <td>{item?.Criteria02_242?.SerTeacher}</td>
                      <td>{item?.Criteria02_242?.Qualify}</td>
                      <td>{item?.Criteria02_242?.YOfQuali}</td>
                      <td>{item?.Criteria02_242?.Whether}</td>
                      <td>{item?.Criteria02_242?.YOfRecog}</td>
                      <td>
                        {item?.Criteria02_242?.Desc9?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria02_242?.Link9} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_263?.criteria}</td>
                      <td>{item?.Criteria02_263?.PrgName2}</td>
                      <td>{item?.Criteria02_263?.pCode}</td>
                      <td>{item?.Criteria02_263?.NStdAppeared}</td>
                      <td>{item?.Criteria02_263?.NStdPassed}</td>
                      <td>
                        {item?.Criteria02_263?.Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria02_263?.Link10} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_271?.criteria}</td>
                      <td>{item?.Criteria02_271?.PrgName3}</td>
                      <td>{item?.Criteria02_271?.NStd}</td>
                      <td>{item?.Criteria02_271?.Gender}</td>
                      <td>{item?.Criteria02_271?.Category}</td>
                      <td>{item?.Criteria02_271?.SDomicile}</td>
                      <td>{item?.Criteria02_271?.NIndian}</td>
                      <td>{item?.Criteria02_271?.Email}</td>
                      <td>{item?.Criteria02_271?.MNo}</td>
                      <td>{item?.Criteria02_271?.YJoining}</td>
                      <td>{item?.Criteria02_271?.UID}</td>
                      <td>
                        {item?.Criteria02_271?.Desc11?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria02_271?.Link11} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <Modal size="sm" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Rating</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={50}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
            ,
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(e) => AddRating(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_211?.Link6}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_212?.Link7}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_241?.Link8}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_242?.Link9}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_263?.Link10}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 6 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_271?.Link11}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Show description modal */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria02_211?.Desc6}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria02_212?.Desc7}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria02_241?.Desc8}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria02_242?.Desc9}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria02_263?.Desc10}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria02_271?.Desc11}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose12}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UnderGraduateCri2;
