import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
const PostGcriteria6 = () => {
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
  const navigate = useNavigate();
  const Details = JSON.parse(sessionStorage.getItem("Puser"));
  const [View, setView] = useState({});

  // const [Value, setValue] = useState({});
  // ===========================================================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  // ===========================================================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  // ===========================================================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  // ===========================================================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  // ===========================================================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  // ===========================================================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  // ===========================================================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  // ===========================================================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  // ===========================================================

  //611
  const [VisionMission, setVisionMission] = useState("");
  const [VisionMissionStatement, setVisionMissionStatement] = useState("");
  const [VisionMissionyesr, setVisionMissionyear] = useState("");

  // 612
  const [ParticipativeMng, setParticipativeMng] = useState("");
  const [AcadamicMont, setAcadamicMont] = useState("");
  const [OficeOrder, setOficeOrder] = useState("");
  const [Decentralisation1, setDecentralisation1] = useState("");
  const [Decentralisation2, setDecentralisation2] = useState("");
  const [Decentralisation3, setDecentralisation3] = useState("");
  const [AcadamicMont1, setAcadamicMont1] = useState("");

  // 623

  const [Year, setYear] = useState("");
  const [PG_yoi1, setPG_yoi1] = useState("");
  const [PG_yoi2, setPG_yoi2] = useState("");
  const [PG_yoi3, setPG_yoi3] = useState("");
  const [PG_yoi4, setPG_yoi4] = useState("");
  const [PG_doc1, setPG_doc1] = useState("");
  const [PG_doc2, setPG_doc2] = useState("");
  const [PG_doc3, setPG_doc3] = useState("");
  const [PG_doc4, setPG_doc4] = useState("");
  const [PG_desc1, setPG_desc1] = useState("");

  // 632
  const [PG_Not, setPG_Not] = useState("");
  const [PG_Asr, setPG_Asr] = useState("");
  const [PG_NOC, setPG_NOC] = useState("");
  const [PG_Nop, setPG_Nop] = useState("");
  const [PG_desc2, setPG_desc2] = useState("");
  const [PG_doc5, setPG_doc5] = useState("");

  //============= criteria 633 =============== //

  const [PG_Sdate, setPG_Sdate] = useState("");
  const [PG_Edate, setPG_Edate] = useState("");
  const [PG_titlep, setPG_titlep] = useState("");
  const [PG_Nfp, setPG_Nfp] = useState("");
  const [PG_doc6, setPG_doc6] = useState("");
  const [PG_desc3, setPG_desc3] = useState("");

  //============= criteria 634 =============== //

  const [PG_Sdate1, setPG_Sdate1] = useState("");
  const [PG_Edate1, setPG_Edate1] = useState("");
  const [PG_namet, setPG_namet] = useState("");
  const [PG_prgm50, setPG_prgm50] = useState("");
  const [PG_doc7, setPG_doc7] = useState("");
  const [PG_desc4, setPG_desc4] = useState("");

  //============= criteria 642 =============== //

  const [PG_pgrant, setPG_pgrant] = useState("");
  const [PG_Fundg, setPG_Fundg] = useState("");
  const [PG_namefgov, setPG_namefgov] = useState("");
  const [PG_doc8, setPG_doc8] = useState("");
  const [PG_desc5, setPG_desc5] = useState("");

  //============= criteria 653 =============== //

  const [PG_Sdate2, setPG_Sdate2] = useState("");
  const [PG_Edate2, setPG_Edate2] = useState("");
  const [PG_nirfs, setPG_nirfs] = useState("");
  const [PG_aaa, setPG_aaa] = useState("");
  const [PG_nba, setPG_nba] = useState("");
  const [PG_cswq, setPG_cswq] = useState("");
  const [PG_iaa, setPG_iaa] = useState("");
  const [PG_doc9, setPG_doc9] = useState("");
  const [PG_desc6, setPG_desc6] = useState("");

  // ========

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});

  console.log("obj7", obj7);
  console.log("obj7", obj8);

  // =============================================
  const [add1, setAdd1] = useState(true);
  const [add2, setAdd2] = useState(true);
  const [add3, setAdd3] = useState(true);
  const [add4, setAdd4] = useState(true);
  const [add5, setAdd5] = useState(true);
  const [add6, setAdd6] = useState(true);

  const [edit1, setedit1] = useState();
  const [edit2, setedit2] = useState();
  const [edit3, setedit3] = useState();
  const [edit4, setedit4] = useState();
  const [edit5, setedit5] = useState();
  const [edit6, setedit6] = useState();

  // ==========================================================

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==========================================================

  const getpgcriteria6 = async () => {
    try {
      let am1 = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am1));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));
      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
      let am7 = sessionStorage.getItem("cat61");
      setobj7(JSON.parse(am7));
      let am8 = sessionStorage.getItem("cat612");
      setobj8(JSON.parse(am8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpgcriteria6();
  }, []);

  const pgcriteria6 = async () => {
    try {
      const config = {
        url: "/pgadmin/detailspgcri6",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Year: Year,
          PG_Criteria06_611: obj7,
          PG_Criteria06_612: obj8,
          PG_Criteria06_623: obj1,
          PG_Criteria06_632: obj2,
          PG_Criteria06_633: obj3,
          PG_Criteria06_634: obj4,
          PG_Criteria06_642: obj5,
          PG_Criteria06_653: obj6,
          PG_doc1: PG_doc1,
          PG_doc2: PG_doc2,
          PG_doc3: PG_doc3,
          PG_doc4: PG_doc4,
          PG_doc5: PG_doc5,
          PG_doc6: PG_doc6,
          PG_doc7: PG_doc7,
          PG_doc8: PG_doc8,
          PG_doc9: PG_doc9,
          VisionMission: VisionMission,
          VisionMissionStatement: VisionMissionStatement,
          VisionMissionyesr: VisionMissionyesr,

          ParticipativeMng: ParticipativeMng,
          AcadamicMont: AcadamicMont,
          OficeOrder: OficeOrder,
          Decentralisation1: Decentralisation1,
          Decentralisation2: Decentralisation2,
          Decentralisation3: Decentralisation3,
          AcadamicMont1: AcadamicMont1,
          addedByPri: Details?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");
          sessionStorage.removeItem("cat2");
          sessionStorage.removeItem("cat3");
          sessionStorage.removeItem("cat4");
          sessionStorage.removeItem("cat5");
          sessionStorage.removeItem("cat6");
          navigate("/pg-Criteria6");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  //get method

  // Criteria 6.1.1

  const cat61 = () => {
    try {
      let obj = {
        criteria: "6.1.1",
        VisionMission: VisionMission,
        VisionMissionStatement: VisionMissionStatement,
        VisionMissionyesr: VisionMissionyesr,
      };
      sessionStorage.setItem("cat61", JSON.stringify(obj));
      alert("Successfully added cat 6.1.1");
      getpgcriteria6();
    } catch (error) {
      console.log(error);
    }
  };
  const cat612 = () => {
    try {
      let obj = {
        criteria: "6.1.2",
        ParticipativeMng: ParticipativeMng,
        AcadamicMont: AcadamicMont,
        OficeOrder: OficeOrder,
        Decentralisation1: Decentralisation1,
        Decentralisation2: Decentralisation2,
        Decentralisation3: Decentralisation3,
        AcadamicMont1: AcadamicMont1,
      };
      sessionStorage.setItem("cat612", JSON.stringify(obj));
      alert("Successfully added cat 6.1.2");
      getpgcriteria6();
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.2.3
  const cat1 = () => {
    try {
      let obj = {
        PG_criteria: "6.2.3",
        PG_yoi1: PG_yoi1,
        PG_yoi2: PG_yoi2,
        PG_yoi3: PG_yoi3,
        PG_yoi4: PG_yoi4,
        PG_doc1: PG_doc1,
        PG_doc2: PG_doc2,
        PG_doc3: PG_doc3,
        PG_doc4: PG_doc4,
        PG_desc1: PG_desc1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getpgcriteria6();
    } catch (error) {
      console.log(error);
    }
  };

  const cat2 = () => {
    try {
      let obj = {
        PG_criteria: "6.3.2",
        PG_Not: PG_Not,
        PG_Asr: PG_Asr,
        PG_NOC: PG_NOC,
        PG_Nop: PG_Nop,
        PG_desc2: PG_desc2,
        PG_doc5: PG_doc5,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));
      alert("Successfully added cat2");
      getpgcriteria6();
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      let obj = {
        PG_criteria: "6.3.3",
        PG_Sdate: PG_Sdate,
        PG_Edate: PG_Edate,
        PG_titlep: PG_titlep,
        PG_Nfp: PG_Nfp,
        PG_doc6: PG_doc6,
        PG_desc3: PG_desc3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));
      alert("Successfully added cat3");
      getpgcriteria6();
    } catch (error) {
      console.log(error);
    }
  };

  const cat4 = () => {
    try {
      let obj = {
        PG_criteria: "6.3.4",
        PG_Sdate1: PG_Sdate1,
        PG_Edate1: PG_Edate1,
        PG_namet: PG_namet,
        PG_prgm50: PG_prgm50,
        PG_doc7: PG_doc7,
        PG_desc4: PG_desc4,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getpgcriteria6();
    } catch (error) {
      console.log(error);
    }
  };

  const cat5 = () => {
    try {
      let obj = {
        PG_criteria: "6.4.2",
        PG_pgrant: PG_pgrant,
        PG_Fundg: PG_Fundg,
        PG_namefgov: PG_namefgov,
        PG_doc8: PG_doc8,
        PG_desc5: PG_desc5,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getpgcriteria6();
    } catch (error) {
      console.log(error);
    }
  };

  const cat6 = () => {
    try {
      let obj = {
        PG_criteria: "6.5.3",
        PG_Sdate2: PG_Sdate2,
        PG_Edate2: PG_Edate2,
        PG_nirfs: PG_nirfs,
        PG_aaa: PG_aaa,
        PG_nba: PG_nba,
        PG_cswq: PG_cswq,
        PG_iaa: PG_iaa,
        PG_doc9: PG_doc9,
        PG_desc6: PG_desc6,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getpgcriteria6();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div className="container">
      <div className="pt-4   " style={{ padding: "10px" }}>
        <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
          PostGraduation_Criteria-01
        </span>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid border bs"
          style={{
            padding: "30px",
          }}
        >
          <div class=" col-md-6">
            <label for="" class="form-label">
              Select Year
            </label>
            {obj1?.Year ? (
              <p>{obj1?.Year}</p>
            ) : (
              <select
                className="form-select form-select-sm vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setYear(e.target.value)}
              >
                <option>Select Year</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>
      </div>

      <div className="container  ">
        <div className="row-fluid border p-4 bs mt-5 ">
          <h3>Criteria 6.1.1</h3>
          <h5>Institutional Vision and Leadership</h5>
          <div className="row  ">
            <h6>
              The governance of the institution is reflective of an effective
              leadership in tune with the vision and mission of the institution
            </h6>
            <div className="col-lg-6">
              <div className="lab">
                <label>Vision_Mission:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab">
                <input
                  type="file"
                  id="upload1"
                  className="vi_0"
                  onChange={(e) => setVisionMission(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3 ">
            <div className="col-lg-6">
              <div className="lab">
                <label>Vision_Mission_Statement:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab">
                <input
                  type="file"
                  id="upload2"
                  className="vi_0"
                  onChange={(e) => setVisionMissionStatement(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-6">
              <div className="lab">
                <label>Vision_Mission_2022-23:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab mb-4">
                <input
                  type="file"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setVisionMissionyear(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="text-end mt-4">
            <Button
              className="btn-success"
              type="submit"
              onClick={() => {
                cat61();
              }}
            >
              ADD
            </Button>
          </div>
        </div>
      </div>
      <div className="container  ">
        <div className="row-fluid border p-4 bs mt-5 ">
          <h3>Criteria 6.1.2</h3>
          <h5>
            The institution practices decentralization and participative
            management
          </h5>
          <div className="row  ">
            <div className="col-lg-6">
              <div className="lab">
                <label>Participative management:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab">
                <input
                  type="file"
                  id="upload1"
                  className="vi_0"
                  onChange={(e) => setParticipativeMng(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3 ">
            <div className="col-lg-6">
              <div className="lab">
                <label>Acadamic monitoring committee:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab">
                <input
                  type="file"
                  id="upload2"
                  className="vi_0"
                  onChange={(e) => setAcadamicMont(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-6">
              <div className="lab">
                <label>Office Order:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab mb-4">
                <input
                  type="file"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setOficeOrder(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <div className="lab">
                <label>Decentralisation_20-21:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab mb-4">
                <input
                  type="file"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setDecentralisation1(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <div className="lab">
                <label>Decentralisation_21-22:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab mb-4">
                <input
                  type="file"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setDecentralisation2(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <div className="lab">
                <label>Decentralisation_2022-23:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab mb-4">
                <input
                  type="file"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setDecentralisation3(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <div className="lab">
                <label>Academic Monitoring Committee_2022-23:</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="lab mb-4">
                <input
                  type="file"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAcadamicMont1(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="text-end mt-4">
            <Button
              className="btn-success"
              type="submit"
              onClick={() => {
                cat612();
              }}
            >
              ADD
            </Button>
          </div>
        </div>
      </div>

      <div className="container bs mt-5 p-5">
        <h3>Criteria 6.2.3</h3>
        <h5>Areas of E_Governance</h5>

        <div className="row ">
          <h6>Administration</h6>
          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Year of implementation:</label>

              {obj1?.PG_yoi1 ? (
                <p>{obj1?.PG_yoi1}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setPG_yoi1(e.target.value)}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Upload the relevant document</label>

              <input
                type="file"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_doc1(e.target.files[0])}
              />
            </div>
          </div>
        </div>

        <div className="row ">
          <h6>Finance and Accounts</h6>

          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Year of implementation:</label>

              {obj1?.PG_yoi2 ? (
                <p>{obj1?.PG_yoi2}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setPG_yoi2(e.target.value)}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Upload the relevant document</label>

              <input
                type="file"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_doc2(e.target.files[0])}
              />
            </div>
          </div>
        </div>

        <div className="row ">
          <h6>Student Admissions and Support</h6>

          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Year of implementation:</label>

              {obj1?.PG_yoi3 ? (
                <p>{obj1?.PG_yoi3}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setPG_yoi3(e.target.value)}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Upload the relevant document</label>

              <input
                type="file"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_doc3(e.target.files[0])}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <h6>Examinations</h6>
          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Year of implementation:</label>

              {obj1?.PG_yoi4 ? (
                <p>{obj1?.PG_yoi4}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setPG_yoi4(e.target.value)}
                />
              )}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Upload the relevant document</label>

              <input
                type="file"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_doc4(e.target.files[0])}
              />
            </div>
          </div>
          <div className="col-md-12 mb-3 ">
            <label className="content1" htmlFor="name">
              Description with minimum 500 Characters
            </label>
            {obj1?.PG_desc1 ? (
              <p>{obj1?.PG_desc1}</p>
            ) : (
              <textarea
                className="vi_0"
                type="text"
                id="name"
                onChange={(e) => setPG_desc1(e.target.value)}
              />
            )}
          </div>

          <div className="text-end mt-4">
            {add1 ? (
              <button
                type="submit"
                className="btn btn-success  "
                onClick={() => {
                  cat1();
                  setAdd1(false);
                }}
              >
                ADD
              </button>
            ) : (
              <>
                <Button
                  type="submit"
                  onClick={() => {
                    handleShow1();
                  }}
                  className="m-2"
                  variant="warning "
                  style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                >
                  Preview
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* 06-623 */}

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Add Grade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row ">
              <h6>Administration</h6>
              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {edit1 ? (
                    <>
                      <input
                        type="text"
                        placeholder={PG_yoi1}
                        className="vi_0"
                        onChange={(e) => {
                          setPG_yoi1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{PG_yoi1}</>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>

                  {edit1 ? (
                    <>
                      <input
                        type="file"
                        id="upload1"
                        className="vi_0"
                        placeholder={PG_doc1}
                        onChange={(e) => setPG_doc1(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{PG_doc1?.name}</>
                  )}
                </div>
              </div>
            </div>
            <div className="row ">
              <h6>Finance and Accounts</h6>

              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {edit1 ? (
                    <>
                      <input
                        type="text"
                        placeholder={PG_yoi2}
                        className="vi_0"
                        onChange={(e) => setPG_yoi2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{PG_yoi2}</>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>

                  {edit1 ? (
                    <>
                      <input
                        type="file"
                        id="upload2"
                        className="vi_0"
                        placeholder={PG_doc2}
                        onChange={(e) => setPG_doc2(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{PG_doc2?.name}</>
                  )}
                </div>
              </div>
            </div>
            <div className="row ">
              <h6>Student Admissions and Support</h6>

              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {edit1 ? (
                    <>
                      <input
                        type="text"
                        placeholder={PG_yoi3}
                        className="vi_0"
                        onChange={(e) => setPG_yoi3(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{PG_yoi3}</>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>

                  {edit1 ? (
                    <>
                      <input
                        type="file"
                        id="upload3"
                        className="vi_0"
                        placeholder={PG_doc3}
                        onChange={(e) => setPG_doc3(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{PG_doc3?.name}</>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <h6>Examinations</h6>
              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {edit1 ? (
                    <>
                      <input
                        type="text"
                        placeholder={PG_yoi4}
                        className="vi_0"
                        onChange={(e) => setPG_yoi4(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{PG_yoi4}</>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>

                  {edit1 ? (
                    <>
                      <input
                        type="file"
                        id="upload4"
                        className="vi_0"
                        placeholder={PG_doc4}
                        onChange={(e) => setPG_doc4(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{PG_doc4?.name}</>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-3 ">
              <label className="content1" htmlFor="name">
                {" "}
                Description with minimum 500 Characters
              </label>
              {edit1 ? (
                <>
                  <textarea
                    className="vi_0"
                    type="text"
                    placeholder={PG_desc1}
                    onChange={(e) => setPG_desc1(e.target.value)}
                  />
                </>
              ) : (
                <>
                  {PG_desc1?.slice(0, 70)}
                  ...
                  <Link
                    onClick={() => {
                      handleShow7();
                      setView(PG_desc1);
                    }}
                  >
                    Read More...
                  </Link>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-success"
              onClick={() => setedit1(true)}
            >
              Edit
            </button>
            <Button
              variant="warning"
              style={{ color: "black" }}
              onClick={() => {
                cat1();
                handleClose1();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal.Footer>
      </Modal>

      <div className="container bs mt-5 p-5">
        <h3>Criteria 6.3.2</h3>
        <h6 className="mb-5">
          Average percentage of teachers provided with financial support to
          attend conferences / workshops and towards membership fee of
          professional bodies during the last five years (8)
        </h6>
        <div className="row ">
          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Name of the teacher</label>

              {obj2?.PG_Not ? (
                <p>{obj2?.PG_Not}</p>
              ) : (
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => setPG_Not(e.target.value)}
                />
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="lab mb-4">
              <label>Amount of support received (in INR)</label>

              {obj2?.PG_Asr ? (
                <p>{obj2?.PG_Asr}</p>
              ) : (
                <input
                  type="text"
                  placeholder=""
                  className="vi_0"
                  onChange={(e) => setPG_Asr(e.target.value)}
                />
              )}
            </div>
          </div>
        </div>

        <div className="lab mb-4">
          <label>
            Name of conference/ workshop attended for which financial support
            provided
          </label>

          {obj2?.PG_NOC ? (
            <p>{obj2?.PG_NOC}</p>
          ) : (
            <input
              type="text"
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_NOC(e.target.value)}
            />
          )}
        </div>

        <div className="lab mb-4">
          <label>
            Name of the professional body for which membership fee is provided
          </label>

          {obj2?.PG_Nop ? (
            <p>{obj2?.PG_Nop}</p>
          ) : (
            <input
              type="text"
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_Nop(e.target.value)}
            />
          )}
        </div>

        <div className="col-md-12 mb-3 ">
          <label className="content1" htmlFor="name">
            {" "}
            Description with minimum 500 Characters
          </label>
          {obj2?.PG_desc2 ? (
            <p>{obj2?.PG_desc2}</p>
          ) : (
            <textarea
              className="vi_0"
              type="text"
              id="name"
              // placeholder={ugdesc1}
              onChange={(e) => setPG_desc2(e.target.value)}
            />
          )}
        </div>

        <div className="col-12">
          <div className="lab mb-4">
            <label>Upload the relevant document</label>

            <input
              type="file"
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_doc5(e.target.files[0])}
            />
          </div>
        </div>
        <div className="text-end mt-4">
          {add2 ? (
            <button
              type="submit"
              className="btn btn-success  "
              onClick={() => {
                cat2();
                setAdd2(false);
              }}
            >
              ADD
            </button>
          ) : (
            <>
              {" "}
              <Button
                type="submit"
                onClick={handleShow2}
                className="m-2 "
                variant="warning "
                style={{ fontSize: "16px", color: "", fontWeight: "600" }}
              >
                Preview
              </Button>
            </>
          )}
        </div>
      </div>

      {/* 06_632 */}

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Add Grade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row ">
              <div className="col-lg-12">
                <div className="lab mb-3">
                  <label>Name of the teacher</label>

                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={PG_Not}
                        className="vi_0"
                        onChange={(e) => setPG_Not(e.target.value)}
                      />{" "}
                    </>
                  ) : (
                    <>{PG_Not}</>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="lab mb-3">
                  <label>Amount of support received (in INR)</label>

                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={PG_Asr}
                        className="vi_0"
                        onChange={(e) => setPG_Asr(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{PG_Asr}</>
                  )}
                </div>
              </div>
            </div>

            <div className="lab mb-4">
              <label>
                Name of conference/ workshop attended for which financial
                support provided
              </label>

              {edit2 ? (
                <>
                  <input
                    type="text"
                    placeholder={PG_NOC}
                    className="vi_0"
                    onChange={(e) => setPG_NOC(e.target.value)}
                  />
                </>
              ) : (
                <>{PG_NOC}</>
              )}
            </div>

            <div className="lab mb-4">
              <label>
                Name of the professional body for which membership fee is
                provided
              </label>

              {edit2 ? (
                <>
                  <input
                    type="text"
                    placeholder={PG_Nop}
                    className="vi_0"
                    onChange={(e) => setPG_Nop(e.target.value)}
                  />{" "}
                </>
              ) : (
                <>{PG_Nop}</>
              )}
            </div>

            <div className="col-md-12 mb-3 ">
              <label className="content1" htmlFor="name">
                {" "}
                Description with minimum 500 Characters
              </label>
              {edit2 ? (
                <>
                  <textarea
                    type="text"
                    id="name"
                    placeholder={PG_desc2}
                    onChange={(e) => {
                      setPG_desc2(e.target.value);
                    }}
                  />
                </>
              ) : (
                <>
                  {PG_desc2?.slice(0, 70)}
                  ...
                  <Link
                    onClick={() => {
                      handleShow8();
                      setView(PG_desc2);
                    }}
                  >
                    Read More...
                  </Link>
                </>
              )}
            </div>

            <div className="col-lg-12">
              <div className="lab mb-4">
                <label>Upload the relevant document</label>

                {edit2 ? (
                  <input
                    type="file"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setPG_doc5(e.target.files[0])}
                  />
                ) : (
                  <>{PG_doc5?.name}</>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-success"
              onClick={() => setedit2(true)}
            >
              Edit
            </button>
            <Button
              variant="warning"
              style={{ color: "black" }}
              onClick={() => {
                cat2();
                handleClose2();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal.Footer>
      </Modal>

      <div className="container bs mt-5 p-5">
        <h3>Criteria 6.3.3</h3>
        <h6 className="mb-5">
          {" "}
          Average number of professional development / administrative training
          programs organized by the institution for teaching and non teaching
          staff during the last five years (8)
        </h6>
        <div className="row">
          <div className=" col-lg-6 lab mb-4">
            <label>Date (from) (DD-MM-YYYY)</label>

            {obj3?.PG_Sdate ? (
              <p>{obj3.PG_Sdate}</p>
            ) : (
              <input
                type="date"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_Sdate(e.target.value)}
              />
            )}
          </div>
          <div className=" col-lg-6 lab mb-4">
            <label>Date (to) (DD-MM-YYYY)</label>

            {obj3?.PG_Edate ? (
              <p>{obj3?.PG_Edate}</p>
            ) : (
              <input
                type="date"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_Edate(e.target.value)}
              />
            )}
          </div>
          <div className=" col-lg-12 lab mb-4">
            <label>No. of participants</label>

            {obj3?.PG_Nfp ? (
              <p>{obj3?.PG_Nfp}</p>
            ) : (
              <input
                type="text"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_Nfp(e.target.value)}
              />
            )}
          </div>
        </div>

        <div className="lab mb-4">
          <label>
            {" "}
            Title of the professional development/ administrative training
            programs organised for teaching staff (Professional development/
            administrative training programs)
          </label>

          {obj3?.PG_titlep ? (
            <p>{obj3?.PG_titlep}</p>
          ) : (
            <input
              type="text"
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_titlep(e.target.value)}
            />
          )}
        </div>

        <div className="col-md-12 mb-3 ">
          <label className="content1" htmlFor="name">
            {" "}
            Description with minimum 500 Characters
          </label>
          {obj3?.PG_desc3 ? (
            <p>{obj3?.PG_desc3}</p>
          ) : (
            <textarea
              className="vi_0"
              type="text"
              id="name"
              // placeholder={ugdesc1}
              onChange={(e) => setPG_desc3(e.target.value)}
            />
          )}
        </div>

        <div className="col-12">
          <div className="lab mb-4">
            <label>Upload the relevant document</label>

            <input
              type="file"
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_doc6(e.target.files[0])}
            />
          </div>
        </div>
        <div className="text-end mt-4">
          {add3 ? (
            <button
              type="submit"
              className="btn btn-success m-2 mt-3 "
              onClick={() => {
                cat3();
                setAdd3(false);
              }}
            >
              ADD
            </button>
          ) : (
            <>
              {" "}
              <Button
                type="submit"
                onClick={handleShow3}
                className=" m-2 mt-3"
                variant="warning"
                style={{ fontSize: "16px", color: "", fontWeight: "600" }}
              >
                Preview
              </Button>
            </>
          )}
        </div>
      </div>

      {/* 06_633 */}

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className=" col-lg-12 lab mb-4">
                <label>Date (from) (DD-MM-YYYY)</label>

                {edit3 ? (
                  <input
                    type="date"
                    className="vi_0"
                    placeholder={PG_Sdate}
                    classNameeditCriteria06_633="vi_0"
                    onChange={(e) => setPG_Sdate(e.target.value)}
                  />
                ) : (
                  <>{PG_Sdate}</>
                )}
              </div>
              <div className=" col-lg-12 lab mb-4">
                <label>Date (to) (DD-MM-YYYY)</label>

                {edit3 ? (
                  <input
                    type="date"
                    placeholder={setPG_Edate}
                    className="vi_0"
                    onChange={(e) => setPG_Edate(e.target.value)}
                  />
                ) : (
                  <>{setPG_Edate}</>
                )}
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label>
                  {" "}
                  Title of the professional development/ administrative training
                  programs organised for teaching staff (Professional
                  development/ administrative training programs)
                </label>

                {edit3 ? (
                  <input
                    type="text"
                    placeholder={PG_titlep}
                    className="vi_0"
                    onChange={(e) => setPG_titlep(e.target.value)}
                  />
                ) : (
                  <>{PG_titlep}</>
                )}
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label>No. of participants</label>

                {edit3 ? (
                  <input
                    type="text"
                    placeholder={PG_Nfp}
                    className="vi_0"
                    onChange={(e) => setPG_Nfp(e.target.value)}
                  />
                ) : (
                  <>{PG_Nfp}</>
                )}
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label>Upload the relevant document</label>

                {edit3 ? (
                  <input
                    type="file"
                    placeholder={PG_doc6}
                    className="vi_0"
                    id="upload6"
                    onChange={(e) => setPG_doc6(e.target.files[0])}
                  />
                ) : (
                  <>{PG_doc6?.name}</>
                )}
              </div>
              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description with minimum 500 Characters
                </label>
                {edit3 ? (
                  <textarea
                    className="vi_0"
                    type="text"
                    placeholder={PG_desc3}
                    onChange={(e) => {
                      setPG_desc3(e.target.value);
                    }}
                  />
                ) : (
                  <>{PG_desc3}</>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-success"
              onClick={() => setedit3(true)}
            >
              Edit
            </button>
            <Button
              variant="warning"
              style={{ color: "black" }}
              onClick={() => {
                cat3();
                handleClose3();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal.Footer>
      </Modal>

      <div className="container bs mt-5 p-5">
        <h3>Criteria 6.3.4</h3>
        <h6 className="mb-5">
          {" "}
          Average percentage of teachers undergoing online/ face-to-face Faculty
          Development Programmes during the last five years{" "}
        </h6>
        <div className="row">
          <div className=" col-lg-6 lab mb-4">
            <label>Date (from) (DD-MM-YYYY)</label>

            {obj4?.PG_Sdate1 ? (
              <p>{obj4?.PG_Sdate1}</p>
            ) : (
              <input
                type="date"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_Sdate1(e.target.value)}
              />
            )}
          </div>
          <div className=" col-lg-6 lab mb-4">
            <label>Date (to) (DD-MM-YYYY)</label>

            {obj4?.PG_Edate1 ? (
              <p>{obj4?.PG_Edate1}</p>
            ) : (
              <input
                type="date"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_Edate1(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className=" col-lg-6 lab mb-4">
            <label>Name of teacher who attended</label>

            {obj4?.PG_namet ? (
              <p>{obj4?.PG_namet}</p>
            ) : (
              <input
                type="text"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_namet(e.target.value)}
              />
            )}
          </div>
          <div className=" col-lg-6 lab mb-4">
            <label>Title of the program</label>

            {obj4?.PG_prgm50 ? (
              <p>{obj4?.PG_prgm50}</p>
            ) : (
              <input
                type="text"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_prgm50(e.target.value)}
              />
            )}
          </div>

          <div className="col-md-12 mb-3 ">
            <label className="content1" htmlFor="name">
              {" "}
              Description with minimum 500 Characters
            </label>
            {obj4?.PG_desc4 ? (
              <p>{obj4?.PG_desc4}</p>
            ) : (
              <textarea
                className="vi_0"
                type="text"
                id="name"
                // placeholder={ugdesc1}
                onChange={(e) => setPG_desc4(e.target.value)}
              />
            )}
          </div>

          <div className="col-6">
            <div className="lab mb-4">
              <label>Upload the relevant document</label>

              <input
                type="file"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_doc7(e.target.files[0])}
              />
            </div>
          </div>
        </div>
        <div className="text-end mt-4">
          {add4 ? (
            <button
              type="submit"
              className="btn btn-success m-2 mt-3 "
              onClick={() => {
                cat4();
                setAdd4(false);
              }}
            >
              ADD
            </button>
          ) : (
            <>
              {" "}
              <Button
                type="submit"
                onClick={handleShow4}
                className=" m-2 mt-3"
                variant="warning"
                style={{ fontSize: "16px", color: "", fontWeight: "600" }}
              >
                Preview
              </Button>
            </>
          )}
        </div>
      </div>

      {/* 06_634*/}

      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className=" col-lg-12 lab mb-4">
                <label>Date (from) (DD-MM-YYYY)</label>

                {edit4 ? (
                  <input
                    type="date"
                    className="vi_0"
                    placeholder={PG_Sdate1}
                    onChange={(e) => setPG_Sdate1(e.target.value)}
                  />
                ) : (
                  <>{PG_Sdate1}</>
                )}
              </div>
              <div className=" col-lg-12 lab mb-4">
                <label>Date (to) (DD-MM-YYYY)</label>

                {edit4 ? (
                  <input
                    type="date"
                    placeholder={PG_Edate1}
                    className="vi_0"
                    onChange={(e) => setPG_Edate1(e.target.value)}
                  />
                ) : (
                  <>{PG_Edate1}</>
                )}
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-12 lab mb-4">
                <label>Name of teacher who attended</label>

                {edit4 ? (
                  <input
                    type="text"
                    placeholder={PG_namet}
                    className="vi_0"
                    onChange={(e) => setPG_namet(e.target.value)}
                  />
                ) : (
                  <>{PG_namet}</>
                )}
              </div>
              <div className=" col-lg-12 lab mb-4">
                <label>Title of the program</label>

                {edit4 ? (
                  <input
                    type="text"
                    placeholder={PG_prgm50}
                    className="vi_0"
                    onChange={(e) => setPG_prgm50(e.target.value)}
                  />
                ) : (
                  <>{PG_prgm50}</>
                )}
              </div>
            </div>

            <div className="col-md-12 mb-3 ">
              <label className="content1" htmlFor="name">
                Description with minimum 500 Characters
              </label>
              {edit4 ? (
                <textarea
                  className="vi_0"
                  type="text"
                  id="name"
                  placeholder={PG_desc4}
                  onChange={(e) => {
                    setPG_desc4(e.target.value);
                  }}
                />
              ) : (
                <>{PG_desc4}</>
              )}
            </div>

            <div className=" col-lg-12 lab mb-4">
              <label>Upload the relevant document</label>

              {edit4 ? (
                <input
                  type="file"
                  className="vi_0"
                  placeholder={PG_doc7}
                  onChange={(e) => setPG_doc7(e.target.files[0])}
                />
              ) : (
                <>{PG_doc7.name}</>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-success"
              onClick={() => setedit4(true)}
            >
              Edit
            </button>
            <Button
              variant="warning"
              style={{ color: "black" }}
              onClick={() => {
                cat4();
                handleClose4();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal.Footer>
      </Modal>

      <div className="container bs mt-5 p-5">
        <h3>Criteria 6.4.2</h3>
        <h6 className="mb-5">
          {" "}
          Funds / Grants received from non-government bodies, individuals,
          philanthropists during the last five years (not covered in Criterion
          III and V) (INR in Lakhs) (10)
        </h6>
        <div className="row">
          <div className=" col-lg-6 lab mb-4">
            <label>Purpose of the Grant</label>

            {obj5?.PG_pgrant ? (
              <p>{obj5?.PG_pgrant}</p>
            ) : (
              <input
                type="text"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_pgrant(e.target.value)}
              />
            )}
          </div>
          <div className=" col-lg-6 lab mb-4">
            <label> Funds/ Grants received (INR in lakhs)</label>

            {obj5?.PG_Fundg ? (
              <p>{obj5?.PG_Fundg}</p>
            ) : (
              <input
                type="text"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_Fundg(e.target.value)}
              />
            )}
          </div>
        </div>

        <div className="  lab mb-4">
          <label>
            {" "}
            Name of the non government funding agencies/ individuals
          </label>

          {obj5?.PG_namefgov ? (
            <p>{obj5?.PG_namefgov}</p>
          ) : (
            <input
              type="text"
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_namefgov(e.target.value)}
            />
          )}
        </div>

        <div className="col-md-12 mb-3 ">
          <label className="content1" htmlFor="name">
            {" "}
            Description with minimum 500 Characters
          </label>
          {obj5?.PG_desc5 ? (
            <p>{obj5?.PG_desc5}</p>
          ) : (
            <textarea
              className="vi_0"
              type="text"
              id="name"
              // placeholder={ugdesc1}
              onChange={(e) => setPG_desc5(e.target.value)}
            />
          )}
        </div>

        <div className="lab mb-4">
          <label> Upload the relevant doument </label>

          <input
            type="file"
            placeholder=" "
            className="vi_0"
            onChange={(e) => setPG_doc8(e.target.files[0])}
          />
        </div>

        <div className="text-end mt-4">
          <Button
            type="submit"
            onClick={handleShow5}
            className=" m-2 mt-3"
            variant="warning"
            style={{ fontSize: "16px", color: "", fontWeight: "600" }}
          >
            Preview
          </Button>
          {add5 ? (
            <button
              type="submit"
              className="btn btn-success m-2 mt-3 "
              onClick={() => {
                cat5();
                setAdd5(false);
              }}
            >
              ADD
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className=" col-lg-6 lab mb-4">
                <label>Purpose of the Grant</label>

                {edit5 ? (
                  <input
                    type="text"
                    placeholder={PG_pgrant}
                    className="vi_0"
                    onChange={(e) => setPG_pgrant(e.target.value)}
                  />
                ) : (
                  <>{PG_pgrant}</>
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label> Funds/ Grants received (INR in lakhs)</label>

                {edit5 ? (
                  <input
                    type="text"
                    placeholder={PG_Fundg}
                    className="vi_0"
                    onChange={(e) => setPG_Fundg(e.target.value)}
                  />
                ) : (
                  <>{PG_Fundg}</>
                )}
              </div>
            </div>

            <div className="  lab mb-4">
              <label>
                {" "}
                Name of the non government funding agencies/ individuals
              </label>

              {edit5 ? (
                <input
                  type="text"
                  placeholder={PG_namefgov}
                  className="vi_0"
                  onChange={(e) => setPG_namefgov(e.target.value)}
                />
              ) : (
                <>{PG_namefgov}</>
              )}
            </div>

            <div className="col-md-12 mb-3 ">
              <label className="content1" htmlFor="name">
                Description with minimum 500 Characters
              </label>
              {edit5 ? (
                <textarea
                  className="vi_0"
                  type="text"
                  id="name"
                  placeholder={PG_desc5}
                  onChange={(e) => {
                    setPG_desc5(e.target.value);
                  }}
                />
              ) : (
                <>{PG_desc5}</>
              )}
            </div>

            <div className="lab mb-4">
              <label>
                Upload the Audited Statement of Accounts reflecting the receipts
              </label>

              {edit5 ? (
                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => setPG_doc8(e.target.files[0])}
                />
              ) : (
                <>{PG_doc8.name}</>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-success"
              onClick={() => setedit5(true)}
            >
              Edit
            </button>
            <Button
              variant="warning"
              style={{ color: "black" }}
              onClick={() => {
                cat5();
                handleClose5();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal.Footer>
      </Modal>

      <div className="container bs mt-5 p-5">
        <h3>Criteria 6.5.2</h3>
        <h6 className="mb-5 ">
          {" "}
          Quality assurance initiatives of the institution include: (10) 1.
          Regular meeting of Internal Quality Assurance Cell (IQAC); Feedback
          collected, analysed and used for improvements 2. Collaborative quality
          intitiatives with other institution(s) 3. Participation in NIRF 4.Any
          other quality audit recognized by state, national or international
          agencies (ISO Certification)
        </h6>{" "}
        <div className="row">
          <div className=" col-lg-12 lab mb-4">
            <label> Participation in NIRF along with Status.</label>

            {obj6?.PG_nirfs ? (
              <p>{obj6?.PG_nirfs}</p>
            ) : (
              <input
                type="text"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_nirfs(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className="lab">
          <label>
            Orientation programme on quality issues for teachers and students,{" "}
          </label>
        </div>
        <div className="row">
          <div className=" col-lg-6 lab mb-4">
            <label>Date (from) (DD-MM-YYYY)</label>

            {obj6?.PG_Sdate2 ? (
              <p>{obj6?.PG_Sdate2}</p>
            ) : (
              <input
                type="date"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_Sdate2(e.target.value)}
              />
            )}
          </div>
          <div className=" col-lg-6 lab mb-4">
            <label>Date (to) (DD-MM-YYYY)</label>

            {obj6?.PG_Edate2 ? (
              <p>{obj6?.PG_Edate2}</p>
            ) : (
              <input
                type="date"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setPG_Edate2(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className="lab mb-4">
          <label>
            {" "}
            Academic Administrative Audit (AAA) and follow up action{" "}
          </label>

          {obj6?.PG_aaa ? (
            <p>{obj6?.PG_aaa}</p>
          ) : (
            <input
              type=""
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_aaa(e.target.value)}
            />
          )}
        </div>
        <div className="lab mb-4">
          <label>
            {" "}
            NBA or any other certification received with program specifications.{" "}
          </label>

          {obj6?.PG_nba ? (
            <p>{obj6?.PG_nba}</p>
          ) : (
            <input
              type="text"
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_nba(e.target.value)}
            />
          )}
        </div>
        <div className="  lab mb-4">
          <label> Conferences, Seminars, Workshops on quality conducted </label>

          {obj6?.PG_cswq ? (
            <p>{obj6?.PG_cswq}</p>
          ) : (
            <input
              type="text"
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_cswq(e.target.value)}
            />
          )}
        </div>
        <div className="lab mb-4">
          <label>
            {" "}
            Collaborative quality initiatives with other institution(s) (Provide
            name of the institution and activity)
          </label>

          {obj6?.PG_iaa ? (
            <p>{obj6?.PG_iaa}</p>
          ) : (
            <input
              type="text"
              placeholder=" "
              className="vi_0"
              onChange={(e) => setPG_iaa(e.target.value)}
            />
          )}
        </div>
        <div className="col-md-12 mb-3 ">
          <label className="content1" htmlFor="name">
            {" "}
            Description with minimum 500 Characters
          </label>
          {obj6?.PG_desc6 ? (
            <p>{obj6?.PG_desc6}</p>
          ) : (
            <textarea
              className="vi_0"
              type="text"
              id="name"
              // placeholder={ugdesc1}
              onChange={(e) => setPG_desc6(e.target.value)}
            />
          )}
        </div>
        <div className="lab mb-4">
          <label> Upload the relevant doument </label>

          <input
            type="file"
            placeholder=" "
            className="vi_0"
            onChange={(e) => setPG_doc9(e.target.files[0])}
          />
        </div>
        <div className="text-end mt-4">
          <Button
            type="submit"
            onClick={handleShow6}
            className=" m-2 mt-3"
            variant="warning"
            style={{ fontSize: "16px", color: "", fontWeight: "600" }}
          >
            Preview
          </Button>
          {add6 ? (
            <button
              type="submit"
              className="btn btn-success m-2 mt-3 "
              onClick={() => {
                cat6();
                setAdd6(false);
              }}
            >
              ADD
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="lab">
              <label>
                Orientation programme on quality issues for teachers and
                students,{" "}
              </label>
            </div>

            <div className="row">
              <div className=" col-lg-12 lab mb-4">
                <label>Date (from) (DD-MM-YYYY)</label>

                {edit6 ? (
                  <input
                    type="date"
                    placeholder={PG_Sdate2}
                    className="vi_0"
                    onChange={(e) => setPG_Sdate2(e.target.value)}
                  />
                ) : (
                  <>{PG_Sdate2}</>
                )}
              </div>
              <div className=" col-lg-12 lab mb-4">
                <label>Date (to) (DD-MM-YYYY)</label>

                {edit6 ? (
                  <input
                    type="date"
                    placeholder={PG_Edate2}
                    className="vi_0"
                    onChange={(e) => setPG_Edate2(e.target.value)}
                  />
                ) : (
                  <>{PG_Edate2}</>
                )}
              </div>
            </div>

            <div className=" col-lg-12 lab mb-4">
              <label> Participation in NIRF along with Status.</label>

              {edit6 ? (
                <input
                  type="text"
                  placeholder={PG_nirfs}
                  className="vi_0"
                  onChange={(e) => setPG_nirfs(e.target.value)}
                />
              ) : (
                <>{PG_nirfs}</>
              )}
            </div>

            <div className="lab mb-4">
              <label>Academic Administrative Audit (AAA)</label>

              {edit6 ? (
                <input
                  type="text"
                  placeholder={PG_aaa}
                  className="vi_0"
                  onChange={(e) => setPG_aaa(e.target.value)}
                />
              ) : (
                <>{PG_aaa}</>
              )}
            </div>

            <div className="lab mb-4">
              <label>
                {" "}
                NBA or any other certification received with program
                specifications.{" "}
              </label>

              {edit6 ? (
                <input
                  type="text"
                  placeholder={PG_nba}
                  className="vi_0"
                  onChange={(e) => setPG_nba(e.target.value)}
                />
              ) : (
                <>{PG_nba}</>
              )}
            </div>

            <div className="  lab mb-4">
              <label>
                {" "}
                Conferences, Seminars, Workshops on quality conducted{" "}
              </label>

              {edit6 ? (
                <input
                  type="text"
                  placeholder={PG_cswq}
                  className="vi_0"
                  onChange={(e) => setPG_cswq(e.target.value)}
                />
              ) : (
                <>{PG_cswq}</>
              )}
            </div>

            <div className="lab mb-4">
              <label>
                {" "}
                Collaborative quality initiatives with other institution(s)
                (Provide name of the institution and activity)
              </label>

              {edit6 ? (
                <input
                  type="text"
                  placeholder={PG_iaa}
                  className="vi_0"
                  onChange={(e) => setPG_iaa(e.target.value)}
                />
              ) : (
                <>{PG_iaa}</>
              )}
            </div>

            <div className="col-md-12 mb-3 ">
              <label className="content1" htmlFor="name">
                Description with minimum 500 Characters
              </label>
              {edit6 ? (
                <textarea
                  className="vi_0"
                  type="text"
                  id="name"
                  onChange={(e) => {
                    setPG_desc6(e.target.value);
                  }}
                />
              ) : (
                <>{PG_desc6}</>
              )}
            </div>

            <div className="lab mb-4">
              <label> Upload the relevant document</label>

              {edit6 ? (
                <>
                  <input
                    type="file"
                    className="vi_0"
                    placeholder={PG_doc9}
                    onChange={(e) => setPG_doc9(e.target.files[0])}
                  />
                </>
              ) : (
                <>{PG_doc9.name}</>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-success"
              onClick={() => setedit6(true)}
            >
              Edit
            </button>
            <Button
              variant="warning"
              style={{ color: "black" }}
              onClick={() => {
                cat6();
                handleClose6();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal.Footer>
      </Modal>

      <div className="container mt-5 bs">
        {" "}
        <div className="row">
          <div className=" col-lg-6 ad-b  mt-5 text-end">
            <Button className="mb-5" onClick={pgcriteria6}>
              Submit{" "}
            </Button>
          </div>
        </div>
      </div>

      {/* Show description modal */}
      <Modal show={show7} onHide={handleClose7}>
        <Modal.Body>
          <div>{View?.PG_desc1}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose7}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Show description modal */}
      <Modal show={show8} onHide={handleClose8}>
        <Modal.Body>
          <div>{View?.PG_desc2}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose8}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PostGcriteria6;
