import React from "react";

const UG2242aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B Super
            speciality/D.Sc./D.Litt.
          </span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria02_242?.SerTeacher}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
            D.Litt.)
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria02_242?.Qualify}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of obtaining the qualification</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria02_242?.YOfQuali}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of Recognition as a Research Guide</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_242?.YOfRecog}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Whether recognised as a Research Guide for Ph.D./D.M/M.Ch./D.N.B
            Super speciality/D.Sc./D’Lit.
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_242?.Whether}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria02_242?.Desc9}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria02/${item?.Criteria02_241?.Link8}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG2242aqar;
