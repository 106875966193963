import React from "react";

const UG5533aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the student participated</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_533?.NStdParti}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date of Event/Activity (DD-MM-YYYY)</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_533?.EDate}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the Event/Activity</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_533?.EName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria05_533?.Desc28}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria05/${item?.Criteria05_511?.Link21}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG5533aqar;
