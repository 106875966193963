import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PG_Criteria001 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  //post method for pg_criteria_01
  const [PG_MainYear1, setPG_MainYear1] = useState("");

  const [PG_TeacherPar, setPG_TeacherPar] = useState("");
  const [PG_BodyName, setPG_BodyName] = useState("");
  const [PG_Desc1, setPG_Desc1] = useState("");
  const [PG_Link1, setPG_Link1] = useState("");

  const [PG_PrgmCode, setPG_PrgmCode] = useState("");
  const [PG_YOfIntro, setPG_YOfIntro] = useState("");
  const [PG_PrgName1, setPG_PrgName1] = useState("");
  const [PG_Status, setPG_Status] = useState("");
  const [PG_YOfCBCS, setPG_YOfCBCS] = useState("");

  const [PG_Link2, setPG_Link2] = useState("");
  const [PG_Desc2, setPG_Desc2] = useState("");

  const [PG_CertiName, setPG_CertiName] = useState("");
  const [PG_CourseCode, setPG_CourseCode] = useState("");
  const [PG_YOfOffering, setPG_YOfOffering] = useState("");
  const [PG_NoOfTimes, setPG_NoOfTimes] = useState("");
  const [PG_CourseDuration, setPG_CourseDuration] = useState("");
  const [PG_NoOfStudents, setPG_NoOfStudents] = useState("");
  const [PG_CourseCompletion, setPG_CourseCompletion] = useState("");
  const [PG_Link3, setPG_Link3] = useState("");
  const [PG_Desc3, setPG_Desc3] = useState("");

  const [PG_PPrgmCode, setPG_PPrgmCode] = useState("");
  const [PG_PrgName2, setPG_PrgName2] = useState("");
  const [PG_YYOfOffering, setPG_YYOfOffering] = useState("");
  const [course_code, setCourse_Code] = useState("");
  const [PG_StdCourseStudied, setPG_StdCourseStudied] = useState("");
  const [PG_ExpCourse, setPG_ExpCourse] = useState("");
  const [PG_Link4, setPG_Link4] = useState("");
  const [PG_Desc4, setPG_Desc4] = useState("");

  const [PG_PPPrgmCode, setPG_PPPrgmCode] = useState("");
  const [PG_PrgName3, setPG_PrgName3] = useState("");
  const [PG_StdList, setPG_StdList] = useState("");
  const [PG_Link5, setPG_Link5] = useState("");
  const [PG_Desc5, setPG_Desc5] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getpgcriteria1 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpgcriteria1();
  }, []);

  const pgcriteria1 = async () => {
    if (!PG_MainYear1) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/pgcriteria01",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Year: PG_MainYear1,

          PG_Criteria01_113: obj1,
          PG_Link1: PG_Link1,

          PG_Criteria01_121: obj2,
          PG_Link2: PG_Link2,

          PG_Criteria01_122: obj3,
          PG_Link3: PG_Link3,

          PG_Criteria01_132: obj4,
          PG_Link4: PG_Link4,

          PG_Criteria01_133: obj5,
          PG_Link5: PG_Link5,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          navigate("/pgcriteria01");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (!PG_TeacherPar || !PG_BodyName || !PG_Link1 || !PG_Desc1) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", PG_Link1);
      let obj = {
        PG_criteria: "1.1.3",
        PG_TeacherPar: PG_TeacherPar,
        PG_BodyName: PG_BodyName,
        PG_Link1: PG_Link1,
        PG_Desc1: PG_Desc1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getpgcriteria1();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !PG_PrgName1 ||
          !PG_PrgmCode ||
          !PG_Status ||
          !PG_YOfIntro ||
          !PG_YOfCBCS ||
          !PG_Link2 ||
          !PG_Desc2
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", PG_Link2);
      let obj = {
        PG_criteria: "1.2.1",
        PG_PrgName1: PG_PrgName1,
        PG_PrgmCode: PG_PrgmCode,
        PG_Status: PG_Status,
        PG_YOfIntro: PG_YOfIntro,
        PG_YOfCBCS: PG_YOfCBCS,
        PG_Link2: PG_Link2,
        PG_Desc2: PG_Desc2,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getpgcriteria1();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !PG_CertiName ||
          !PG_CourseCode ||
          !PG_YOfOffering ||
          !PG_NoOfTimes ||
          !PG_CourseDuration ||
          !PG_NoOfStudents ||
          !PG_CourseCompletion ||
          !PG_Link3 ||
          !PG_Desc3
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", PG_Link3);
      let obj = {
        PG_criteria: "1.2.2 & 1.2.3",
        PG_CertiName: PG_CertiName,
        PG_CourseCode: PG_CourseCode,
        PG_YOfOffering: PG_YOfOffering,
        PG_NoOfTimes: PG_NoOfTimes,
        PG_CourseDuration: PG_CourseDuration,
        PG_NoOfStudents: PG_NoOfStudents,
        PG_CourseCompletion: PG_CourseCompletion,
        PG_Link3: PG_Link3,
        PG_Desc3: PG_Desc3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getpgcriteria1();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !PG_PrgName2 ||
          !PG_PPrgmCode ||
          !PG_YYOfOffering ||
          !course_code ||
          !PG_StdCourseStudied ||
          !PG_ExpCourse ||
          !PG_Desc4 ||
          !PG_Link4
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", PG_Link4);
      let obj = {
        PG_criteria: "1.3.2",
        PG_PrgName2: PG_PrgName2,
        PG_PPrgmCode: PG_PPrgmCode,
        PG_YYOfOffering: PG_YYOfOffering,
        PG_Course_code: course_code,
        PG_StdCourseStudied: PG_StdCourseStudied,
        PG_ExpCourse: PG_ExpCourse,
        PG_Desc4: PG_Desc4,
        PG_Link4: PG_Link4,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getpgcriteria1();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !PG_PrgName3 ||
          !PG_PPPrgmCode ||
          !PG_StdList ||
          !PG_Link5 ||
          !PG_Desc5
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("qqqq,", PG_Link5);
      let obj = {
        PG_criteria: "1.3.3",
        PG_PrgName3: PG_PrgName3,
        PG_PPPrgmCode: PG_PPPrgmCode,
        PG_StdList: PG_StdList,
        PG_Link5: PG_Link5,
        PG_Desc5: PG_Desc5,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getpgcriteria1();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            PostGraduation_Criteria-01
          </span>
        </div>

        <div className="container">
          <div className="row-fluid bs border ">
            <div class=" col-md-6" style={{ padding: "20px" }}>
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.PG_MainYear1 ? (
                <p>{obj1?.PG_MainYear1}</p>
              ) : (
                <select
                  class="form-select form-select-sm vi_0"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setPG_MainYear1(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border bs "
            style={{
              padding: "50px",
              borderRadius: "5px",
            }}
          >
            <h5>Criteria_1 (1.1.3)</h5>

            <div className="row">
              <div className="col-md-5 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the teacher participated
                </label>
                {obj1?.PG_TeacherPar ? (
                  <p>{obj1?.PG_TeacherPar}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_TeacherPar(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-7 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the body in which full time teacher participated
                </label>
                {obj1?.PG_BodyName ? (
                  <p>{obj1?.PG_BodyName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setPG_BodyName(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.PG_Desc1 ? (
                  <p>{obj1?.PG_Desc1}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc1(e.target.value);
                      // WordLimitTextarea(e);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link1(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="pt-2 text-end mt-3 ">
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>PG_criteria01_113</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row m-0">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher participated
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_TeacherPar}
                          onChange={(e) => {
                            setPG_TeacherPar(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_TeacherPar}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the body in which full time teacher participated
                    </label>
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_BodyName}
                          onChange={(e) => setPG_BodyName(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_BodyName}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit1 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc1}
                          onChange={(e) => setPG_Desc1(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload1"
                          accept="image/*"
                          onChange={(e) => setPG_Link1(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link1?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat1();
                    handleClose();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_1 (1.2.1)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Code
                </label>
                {obj2?.PG_PrgmCode ? (
                  <p>{obj2?.PG_PrgmCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_PrgmCode(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Name
                </label>
                {obj2?.PG_PrgName1 ? (
                  <p>{obj2?.PG_PrgName1}</p>
                ) : (
                  <select
                    class="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setPG_PrgName1(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="MCA">MCA</option>
                    <option value="MSC">MSC</option>
                    <option value="MBA">MBA</option>
                    <option value="M.Com">M.Com</option>
                  </select>
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year Of Introduction
                </label>
                {obj2?.PG_YOfIntro ? (
                  <p>{obj2?.PG_YOfIntro}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_YOfIntro(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Status of implementation of CBCS/elective course
                </label>
                {obj2?.PG_Status ? (
                  <p>{obj2?.PG_Status}</p>
                ) : (
                  <select
                    class="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setPG_Status(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of implementation of CBCS/elective course
                </label>
                {obj2?.PG_YOfCBCS ? (
                  <p>{obj2?.PG_YOfCBCS}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_YOfCBCS(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj2?.PG_Desc2 ? (
                <p>{obj2?.PG_Desc2}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setPG_Desc2(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="upload2"
                id="upload2"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link2(e.target.files[0]);
                }}
              />
            </div>

            <div className="pt-2 text-end mt-3 ">
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success m-3"
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning mt-3 mb-3"
                    onClick={handleShow1}
                    style={{ color: "black" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="" show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>PG_Criteria01_121</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_PrgmCode}
                          onChange={(e) => {
                            setPG_PrgmCode(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PrgmCode}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    {edit2 ? (
                      <>
                        <select
                          class="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={PG_PrgName1}
                          onChange={(e) => setPG_PrgName1(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="MCA">MCA</option>
                          <option value="MSC">MSC</option>
                          <option value="MBA">MBA</option>
                          <option value="M.Com">M.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{PG_PrgName1}</>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year Of Introduction
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_YOfIntro}
                          onChange={(e) => setPG_YOfIntro(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_YOfIntro}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Status of implementation of CBCS/elective course
                    </label>

                    {edit2 ? (
                      <>
                        <select
                          class="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={PG_Status}
                          onChange={(e) => setPG_Status(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </>
                    ) : (
                      <>{PG_Status}</>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of implementation of CBCS/elective course
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_YOfCBCS}
                          onChange={(e) => setPG_YOfCBCS(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_YOfCBCS}</>
                    )}
                  </div>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={PG_Desc2}
                        onChange={(e) => setPG_Desc2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{PG_Desc2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        onChange={(e) => setPG_Link2(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{PG_Link2?.name}</>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit2(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat2();
                    handleClose1();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_1 (1.2.2 & 1.2.3)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of Add on /Certificate programs offered
                </label>

                {obj3?.PG_CertiName ? (
                  <p>{obj3?.PG_CertiName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_CertiName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Course Code (if any)
                </label>
                {obj3?.PG_CourseCode ? (
                  <p>{obj3?.PG_CourseCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_CourseCode(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Year of offering</label>
                {obj3?.PG_YOfOffering ? (
                  <p>{obj3?.PG_YOfOffering}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_YOfOffering(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  No. of times offered during the same year
                </label>
                {obj3?.PG_NoOfTimes ? (
                  <p>{obj3?.PG_NoOfTimes}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_NoOfTimes(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Duration of course
                </label>
                {obj3?.PG_CourseDuration ? (
                  <p>{obj3?.PG_CourseDuration}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_CourseDuration(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of students enrolled in the year
                </label>
                {obj3?.PG_NoOfStudents ? (
                  <p>{obj3?.PG_NoOfStudents}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_NoOfStudents(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of Students completing the course in the year
                </label>
                {obj3?.PG_CourseCompletion ? (
                  <p>{obj3?.PG_CourseCompletion}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_CourseCompletion(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj3?.PG_Desc3 ? (
                  <p>{obj3?.PG_Desc3}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc3(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="upload3"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link3(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="pt-2 text-end mt-3 ">
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow2}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>PG_Criteria01_122 & 123</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of Add on /Certificate programs offered
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_CertiName}
                          onChange={(e) => {
                            setPG_CertiName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_CertiName}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Course Code (if any)
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_CourseCode}
                          onChange={(e) => {
                            setPG_CourseCode(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_CourseCode}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of offering
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_YOfOffering}
                          onChange={(e) => {
                            setPG_YOfOffering(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_YOfOffering}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      No. of times offered during the same year
                    </label>
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NoOfTimes}
                          onChange={(e) => {
                            setPG_NoOfTimes(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NoOfTimes}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Duration of course
                    </label>
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_CourseDuration}
                          onChange={(e) => {
                            setPG_CourseDuration(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_CourseDuration}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of students enrolled in the year
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NoOfStudents}
                          onChange={(e) => {
                            setPG_NoOfStudents(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NoOfStudents}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of Students completing the course in the year
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_CourseCompletion}
                          onChange={(e) => {
                            setPG_CourseCompletion(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_CourseCompletion}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit3 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc3}
                          onChange={(e) => setPG_Desc3(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc3}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="upload3 "
                          onChange={(e) => setPG_Link3(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link3?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit3(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat3();
                    handleClose2();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_1 (1.3.2)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Name
                </label>
                {obj4?.PG_PrgName2 ? (
                  <p>{obj4?.PG_PrgName2}</p>
                ) : (
                  <select
                    class="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setPG_PrgName2(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="MCA">MCA</option>
                    <option value="MSC">MSC</option>
                    <option value="MBA">MBA</option>
                    <option value="M.Com">M.Com</option>
                  </select>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Code
                </label>
                {obj4?.PG_PPrgmCode ? (
                  <p>{obj4?.PG_PPrgmCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setPG_PPrgmCode(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Course code
                </label>
                {obj4?.PG_Course_code ? (
                  <p>{obj4?.PG_Course_code}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setCourse_Code(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of offering
                </label>
                {obj4?.PG_YYOfOffering ? (
                  <p>{obj4?.PG_YYOfOffering}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setPG_YYOfOffering(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the student studied course on experiential learning
                  through project work/field work/internship
                </label>
                {obj4?.PG_StdCourseStudied ? (
                  <p>{obj4?.PG_StdCourseStudied}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setPG_StdCourseStudied(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the Course that include experiential learning through
                  project work/field work/internship
                </label>
                {obj4?.PG_ExpCourse ? (
                  <p>{obj4?.PG_ExpCourse}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setPG_ExpCourse(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj4?.PG_Desc4 ? (
                <p>{obj4?.PG_Desc4}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setPG_Desc4(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload4">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload4"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link4(e.target.files[0]);
                }}
              />
            </div>

            <div className="pt-2 text-end mt-3 ">
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow3}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria01_132</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    {edit4 ? (
                      <>
                        <select
                          class="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={PG_PrgName2}
                          onChange={(e) => {
                            setPG_PrgName2(e.target.value);
                          }}
                        >
                          <option selected>--Select--</option>
                          <option value="MCA">MCA</option>
                          <option value="MSC">MSC</option>
                          <option value="MBA">MBA</option>
                          <option value="M.Com">M.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{PG_PrgName2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_PPrgmCode}
                          onChange={(e) => {
                            setPG_PPrgmCode(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PPrgmCode}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Course Code
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={course_code}
                          onChange={(e) => {
                            setCourse_Code(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{course_code}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of offering
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_YYOfOffering}
                          onChange={(e) => {
                            setPG_YYOfOffering(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_YYOfOffering}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the student studied course on experiential
                      learning through project work/field work/internship
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_StdCourseStudied}
                          onChange={(e) => {
                            setPG_StdCourseStudied(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_StdCourseStudied}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the Course that include experiential learning
                      through project work/field work/internship
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_ExpCourse}
                          onChange={(e) => {
                            setPG_ExpCourse(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_ExpCourse}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit4 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc4}
                          onChange={(e) => setPG_Desc4(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc4}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Document
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload4"
                          onChange={(e) => setPG_Link4(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link4?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit4(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat4();
                    handleClose3();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_1 (1.3.3)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Name
                </label>
                {obj5?.PG_PrgName3 ? (
                  <p>{obj5?.PG_PrgName3}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setPG_PrgName3(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="MCA">MCA</option>
                    <option value="MSC">MSC</option>
                    <option value="MBA">MBA</option>
                    <option value="M.Com">M.Com</option>
                  </select>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Code
                </label>
                {obj5?.PG_PPPrgmCode ? (
                  <p>{obj5?.PG_PPPrgmCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_PPPrgmCode(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  List of students undertaking project work/field
                  work/internship
                </label>
                {obj5?.PG_StdList ? (
                  <p>{obj5?.PG_StdList}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_StdList(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj5?.PG_Desc5 ? (
                <p>{obj5?.PG_Desc5}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setPG_Desc5(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload5">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="email"
                id="upload5"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link5(e.target.files[0]);
                }}
              />
            </div>

            <div className="pt-2 text-end mt-3 ">
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat5();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow4}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show4} onHide={handleClose4}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>criteria01_133</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    {edit5 ? (
                      <>
                        <select
                          class="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={PG_PrgName3}
                          onChange={(e) => {
                            setPG_PrgName3(e.target.value);
                          }}
                        >
                          <option selected>--Select--</option>
                          <option value="MCA">MCA</option>
                          <option value="MSC">MSC</option>
                          <option value="MBA">MBA</option>
                          <option value="M.Com">M.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{PG_PrgName3}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_PPPrgmCode}
                          onChange={(e) => {
                            setPG_PPPrgmCode(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PPPrgmCode}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      List of students undertaking project work/field
                      work/internship
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_StdList}
                          onChange={(e) => {
                            setPG_StdList(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_StdList}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit5 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc5}
                          onChange={(e) => setPG_Desc5(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc5}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload5"
                          accept="image/*"
                          onChange={(e) => {
                            setPG_Link5(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Link5?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit5(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat5();
                    handleClose4();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => pgcriteria1()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PG_Criteria001;
