import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Image, Modal, Table } from "react-bootstrap";
import { AiFillDelete, AiFillLock, AiFillUnlock } from "react-icons/ai";

function RegisterUser() {
  let registerDetails = JSON.parse(sessionStorage.getItem("Admin"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [View, setView] = useState({});
  const [Registeruser, setRegisteruser] = useState([]);
  const getallregisteruser = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/app/notapprovedlist"
      );
      if (res.status === 200) {
        setRegisteruser(res.data.Register);
      }
    } catch (error) {
      console.log(error);
      setRegisteruser(error.response.data.Register);
    }
  };
  // ==========================================================
  useEffect(() => {
    registerDetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!registerDetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getallregisteruser();
    }
  }, []);
  //=================================================

  const Approve = async (e, principalid) => {
    e.preventDefault();
    try {
      const config = {
        url: "/app/approval/" + principalid,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getallregisteruser();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getallregisteruser();
  }, []);
  console.log(Registeruser, "hkjhl.");
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-12">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Register User
            </span>
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table
            responsive
            striped
            bordered
            hover
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              width: "3000px",
            }}
          >
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>HEI Code</th>
                <th>HEI Name</th>
                <th>Email Id</th>
                <th>Specification</th>
                <th>Phone No.</th>
                <th>Land No.</th>
                <th>HEI</th>
                <th>WebsiteLink</th>
                <th>PrincipalName</th>
                <th>Address</th>
                <th>Country</th>
                <th>State</th>
                <th>City</th>
                <th>Pincode</th>
                <th>College Image</th>
                <th>College Logo</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Registeruser?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{i + 1}</td>
                    <td>{item?.HeiName}</td>
                    <td>{item?.HeiCode}</td>
                    <td>{item?.Email}</td>
                    <td>{item?.Registered_By}</td>

                    <td>{item?.PNumber}</td>
                    <td>{item?.HeiLandNumber}</td>
                    <td>{item?.HEI}</td>
                    <td>{item?.WebsiteLink}</td>
                    <td>{item?.PrincipalName}</td>
                    <td>{item?.Address}</td>
                    <td>{item?.Country}</td>
                    <td>{item?.State}</td>
                    <td>{item?.City}</td>
                    <td>{item?.Pincode}</td>

                    <td>
                      <Image
                        src={`https://brightnaac.co.in/register/${item?.CollegeImg}`}
                        alt="pic"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>
                      <Image
                        src={`https://brightnaac.co.in/register/${item?.CollegeLogo}`}
                        alt="pic"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>

                    <td>pending</td>

                    <td>
                      <div>
                        <button onClick={(e) => Approve(e, item?._id)}>
                          Approve
                        </button>
                      </div>
                    </td>

                    {/*<td>
                      {item?.isBlocked == false ? (
                        <>
                          <span
                            style={{
                              cursor: "pointer",
                              color: "green",
                              fontSize: "31px",
                            }}
                            onClick={() => {
                              block(item._id);
                            }}
                          >
                            <AiFillUnlock />
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            style={{
                              cursor: "pointer",
                              color: "red",
                              fontSize: "31px",
                            }}
                            onClick={() => {
                              block(item._id);
                            }}
                          >
                            <AiFillLock />
                          </span>
                        </>
                      )}
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Submit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default RegisterUser;
