import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { DatePicker, Space } from "antd";
import axios from "axios";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";
import exportFromJSON from "export-from-json";

const Uni_Criteria04 = () => {
  const navigate = useNavigate();
  let librarianDetails = JSON.parse(sessionStorage.getItem("Luser"));
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);

  const [app1, setapp1] = useState(true);
  const [Uni_Room, setUni_Room] = useState("");
  const [Uni_Type, setUni_Type] = useState("");
  const [Uni_Desc1, setUni_Desc1] = useState("");
  const [Uni_Link1, setUni_Link1] = useState("");

  const [Uni_Budget, setUni_Budget] = useState("");
  const [Uni_Expenditure, setUni_Expenditure] = useState("");
  const [Uni_Total, setUni_Total] = useState("");
  const [Uni_Expend1, setUni_Expend1] = useState("");
  const [Uni_Expend2, setUni_Expend2] = useState("");
  const [Uni_Desc2, setUni_Desc2] = useState("");
  const [Uni_Link2, setUni_Link2] = useState("");

  const [Uni_Library, setUni_Library] = useState("");
  const [Uni_IfYes, setUni_IfYes] = useState("");
  const [Uni_Expend3, setUni_Expend3] = useState("");
  const [Uni_Total1, setUni_Total1] = useState("");
  const [Uni_Desc3, setUni_Desc3] = useState("");
  const [Uni_Link3, setUni_Link3] = useState("");

  //integrating get method
  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [Uni_Criteria04, setUni_Criteria04] = useState([]);
  const [Value, setValue] = useState({});

  const editUni_Criteria04_431 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc1) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/LibraryAdmin/editUni_Criteria04_431/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Room: Uni_Room,
          Uni_Type: Uni_Type,
          Uni_Desc1: Uni_Desc1,
          Uni_Link1: Uni_Link1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri04details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria04_414 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc2) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/LibraryAdmin/editUni_Criteria0a4_414/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Budget: Uni_Budget,
          Uni_Expenditure: Uni_Expenditure,
          Uni_Total: Uni_Total,
          Uni_Expend1: Uni_Expend1,
          Uni_Expend2: Uni_Expend2,
          Uni_Desc2: Uni_Desc2,
          Uni_Link2: Uni_Link2,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri04details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria04_422 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc3) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/LibraryAdmin/editUni_Criteria04_422/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Library: Uni_Library,
          Uni_IfYes: Uni_IfYes,
          Uni_Expend3: Uni_Expend3,
          Uni_Total1: Uni_Total1,
          Uni_Desc3: Uni_Desc3,
          Uni_Link3: Uni_Link3,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri04details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Uni_Criteria_4.0 ");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          // Criteria 4_414

          "Criteria_(4.1.4 & 4.4.1)": item?.Uni_Criteria04_414?.Uni_criteria,
          "Budget Allocated for Infrastructure augmentation (INR inLakh)":
            item?.Uni_Criteria04_414?.Uni_Budget,
          "Expenditure for Infrastructure Augmentation (INR in Lakh)":
            item?.Uni_Criteria04_414?.Uni_Expenditure,
          "Total Expenditure Excluding Salary (INR in Lakh)":
            item?.Uni_Criteria04_414?.Uni_Total,
          "Expenditure on Maintenance of Academic Facilities (Excluding salary for Human Resources) (INR in Lakh)":
            item?.Uni_Criteria04_414?.Uni_Expend1,
          "Expenditure on Maintenance of Physical Facilities (Excluding salary for Human Resources) (INR in Lakh)":
            item?.Uni_Criteria04_414?.Uni_Expend2,
          "Description(min 500 words)2": item?.Uni_Criteria04_414?.Uni_Desc2,
          "Upload the Relevant Document2": item?.Uni_Criteria04_414?.Uni_Link2,

          // Criteria 4_422

          "Criteria_(4.2.2  & 4.2.3)": item?.Uni_Criteria04_422?.Uni_criteria,
          "Library Resources	": item?.Uni_Criteria04_422?.Uni_Library,
          "If yes, details of Memberships/Subscriptions":
            item?.Uni_Criteria04_422?.Uni_IfYes,
          "Expenditure on Subscription for the resources (INR in lakhs)":
            item?.Uni_Criteria04_422?.Uni_Expend3,
          "Total Library Expenditure": item?.Uni_Criteria04_422?.Uni_Total1,
          "Description(min 500 words)3": item?.Uni_Criteria04_422?.Uni_Desc3,
          "Upload the relevant document3": item?.Uni_Criteria04_422?.Uni_Link3,

          //  Criteria 4_431

          //Uni_Year: item?.Uni_Year,
          "Criteria_(4.3.1)": item?.Uni_Criteria04_431?.Uni_criteria,
          "Room number or Name of Classrooms and Seminar Halls with LCD/wifi/LAN facilities":
            item?.Uni_Criteria04_431?.Uni_Room,
          "Type of ICT facility": item?.Uni_Criteria04_431?.Uni_Type,
          "Description(min 500 words)1": item?.Uni_Criteria04_431?.Uni_Desc1,
          "Upload the relevant document1": item?.Uni_Criteria04_431?.Uni_Link1,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const getunicri04details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getunicriteria04"
      );
      if (res.status === 200) {
        setUni_Criteria04(res.data.unicriteria04);
        setdata1(res.data.unicriteria04);
      }
    } catch (error) {
      console.log(error);
      setUni_Criteria04(error.response.unicriteria04);
    }
  };

  useEffect(() => {
    librarianDetails = JSON.parse(sessionStorage.getItem("Luser"));
    if (!librarianDetails) {
      alert("Please login first");
      window.location.assign("/llogin");
    } else {
      getunicri04details();
    }
  }, []);
  console.log(Value);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-04
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/unicriteria004">
                <button className="btn btn-success">
                  Add Criteria 4{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0 ">
            <div className=" col-lg-3 do-sear d-flex p-0  ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeFrom}
                picker="year"
                placeholder="Select Year (from)"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                picker="year"
                placeholder="Select Year (to)"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>
                    Budget Allocated for Infrastructure Augmentation (INR in
                    Lakhs)
                  </th>
                  <th>
                    Expenditure for Infrastructure Augmentation (INR in Lakhs)
                  </th>
                  <th>Total Expenditure Excluding Salary (INR in Lakhs)</th>
                  <th>
                    Expenditure on Maintenance of Academic Facilities (excluding
                    salary for Human Resources) (INR in Lakhs)
                  </th>
                  <th>
                    Expenditure on Maintenance of Physical Facilities (excluding
                    salary for Human Resources) (INR in Lakh)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Library Resources</th>
                  <th>If yes, Details of Memberships/Subscriptions</th>
                  <th>
                    Expenditure on Subscription for the Resources (INR in lakhs)
                  </th>
                  <th>Total Library Expenditure</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Room number or Name of Classrooms and Seminar Halls with
                    LCD/wifi/LAN facilities
                  </th>
                  <th>Type of ICT facility</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {Uni_Criteria04?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    librarianDetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria04_431?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_431?.Uni_Room?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_431?.Uni_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_431?.Uni_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Expenditure?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Expend1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Expend2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_Library?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_IfYes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_Expend3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>
                      <td> {librarianDetails?.FName}</td>

                      {/* ====== */}
                      <td>{item?.Uni_Criteria04_414?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Budget}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Expenditure}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Total}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Expend1}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Expend2}</td>
                      <td>
                        {item?.Uni_Criteria04_414?.Uni_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* ====== */}
                      <td>{item?.Uni_Criteria04_422?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria04_422?.Uni_Library}</td>
                      <td>{item?.Uni_Criteria04_422?.Uni_IfYes}</td>
                      <td>{item?.Uni_Criteria04_422?.Uni_Expend3}</td>
                      <td>{item?.Uni_Criteria04_422?.Uni_Total1}</td>
                      <td>
                        {item?.Uni_Criteria04_422?.Uni_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>
                      {/* ====== */}
                      <td>{item?.Uni_Criteria04_431?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria04_431?.Uni_Room}</td>
                      <td>{item?.Uni_Criteria04_431?.Uni_Type}</td>
                      <td>
                        {item?.Uni_Criteria04_431?.Uni_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green " }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={() => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              {" "}
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold", color: "white" }}
              >
                University_Criteria-04
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------4.1.4 & 4.4.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-413
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Budget allocated for infrastructure augmentation (INR in
                    Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_414?.Uni_Budget}
                        onChange={(e) => {
                          setUni_Budget(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_414?.Uni_Budget}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Expenditure for infrastructure augmentation (INR in Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_414?.Uni_Expenditure}
                        onChange={(e) => {
                          setUni_Expenditure(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_414?.Uni_Expenditure}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total expenditure excluding Salary (INR in Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_414?.Uni_Total}
                        onChange={(e) => setUni_Total(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_414?.Uni_Total}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Expenditure on maintenance of academic facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_414?.Uni_Expend1}
                        onChange={(e) => setUni_Expend1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_414?.Uni_Expend1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Expenditure on maintenance of physical facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_414?.Uni_Expend2}
                        onChange={(e) => setUni_Expend2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_414?.Uni_Expend2}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_414?.Uni_Desc2}
                        onChange={(e) => setUni_Desc2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_414?.Uni_Desc2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        // accept="image/*"
                        placeholder={Value?.Uni_Criteria04_414?.Uni_Link2}
                        onChange={(e) => setUni_Link2(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_414?.Uni_Link2}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row">
                    <div className="text-end mt-3 ">
                      {edit2 ? (
                        <>
                          {" "}
                          <Button
                            className="btn btn-success"
                            onClick={(e) => {
                              editUni_Criteria04_414(e, Value?._id);
                              setedit2(false);
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-422{" "}
                </span>
              </div>

              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Library resources
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Uni_Criteria04_422?.Uni_Library}
                        onChange={(e) => setUni_Library(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Books">Books</option>
                        <option value="Journals ">Journals </option>
                        <option value="e – journals ">e – journals </option>
                        <option value="e-books">e-books</option>
                        <option value="e-ShodhSindhu">e-ShodhSindhu</option>
                        <option value="Shodhganga">Shodhganga</option>
                        <option value="Databases">Databases</option>
                        <option value="Local and / or Remote access to e- resources (Specify)">
                          e-ShodhSindhu
                        </option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_422?.Uni_Library}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    If yes, details of memberships/subscriptions
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_422?.Uni_IfYes}
                        onChange={(e) => {
                          setUni_IfYes(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_422?.Uni_IfYes}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Expenditure on subscription for the recourses (INR in Lakhs)
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_422?.Uni_Expend3}
                        onChange={(e) => {
                          setUni_Expend3(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_422?.Uni_Expend3}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total Library Expenditure
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_422?.Uni_Total1}
                        onChange={(e) => {
                          setUni_Total1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_422?.Uni_Total1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_422?.Uni_Desc3}
                        onChange={(e) => setUni_Desc3(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_422?.Uni_Desc3}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3 "
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria04_422?.Uni_Link3}
                        onChange={(e) => setUni_Link3(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_422?.Uni_Link3}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row">
                    <div className="text-end mt-3 ">
                      {edit3 ? (
                        <>
                          {" "}
                          <Button
                            className="btn btn-success"
                            onClick={(e) => {
                              editUni_Criteria04_422(e, Value?._id);
                              setedit3(false);
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-431
                </span>
              </div>

              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Room number or Name of Classrooms and Seminar halls with
                    ICT-enabled facilities
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_431?.Uni_Room}
                        onChange={(e) => setUni_Room(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_431?.Uni_Room}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Type of ICT facility
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_431?.Uni_Type}
                        onChange={(e) => setUni_Type(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_431?.Uni_Type}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria04_431?.Uni_Desc1}
                        onChange={(e) => setUni_Desc1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_431?.Uni_Desc1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1"> Document</label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        placeholder={Uni_Link1}
                        onChange={(e) => setUni_Link1(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria04_431?.Uni_Link1}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row">
                    <div className="text-end mt-3 ">
                      {edit1 ? (
                        <>
                          {" "}
                          <Button
                            className=" btn btn-success "
                            onClick={(e) => {
                              editUni_Criteria04_431(e, Value?._id);
                              setedit1(false);
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
                setedit1(false);
                setedit2(false);
                setedit3(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria04_414?.Uni_Desc2}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria04_422?.Uni_Desc3}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria04_431?.Uni_Desc1}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria4/${View?.Uni_Criteria04_414?.Uni_Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria4/${View?.Uni_Criteria04_422?.Uni_Link3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria4/${View?.Uni_Criteria04_431?.Uni_Link1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Uni_Criteria04;
