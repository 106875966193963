import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

function PDFprintUG5_511() {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }
  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    console.log("ddkd1", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("ddkd2", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("ddkd3", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("ddkd4", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

    // const input = document.getElementById("pdf");
    // const options = { scrollY: -window.scrollY, useCORS: true };
    // const canvas = await html2canvas(input, options);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    // pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    pdf.save("5.1.1.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);
  if (location?.state == null) {
    navigate("/UG_05_511");
  }
  return (
    <div>
      <div className="m-auto">
        <div id="pdf">
          <div className="p-2">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className=" pdfimg pt-2 "
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                Year:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.Year}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state[location?.state?.length - 1]?.criteria}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]["Criteria05_511"]["criteria"]}</>
                )}
              </div>
            </div>

            {finalArr?.map((item, i) => {
              return (
                <div
                  className="container-fluid report-pdf mt-5"
                  style={{ padding: "0px 100px" }}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Name of the scheme</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria05_511?.NameS}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 report-head  mb-3">
                      <span>
                        Number of students benefited by government scheme and
                        amount
                      </span>
                      <div className="row">
                        <div className="col-lg-6 report-head  mt-2">
                          <div>
                            <span> Number of students</span>
                          </div>
                          <div>
                            <span>Amount</span>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-2">
                          <div className="report-text">
                            <span>{item?.Criteria05_511?.SName}</span>
                          </div>
                          <div className="report-text">
                            <span> {item?.Criteria05_511?.Amount}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 report-head  mb-3">
                      <span>
                        Number of students benefited by the institution's
                        schemes and amount
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 report-head mt-2">
                        <div>
                          <span> Number of students</span>
                        </div>
                        <div>
                          <span>Amount</span>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-2">
                        <div className="report-text">
                          <span>{item?.Criteria05_511?.SName1}</span>
                        </div>
                        <div className="report-text">
                          <span> {item?.Criteria05_511?.Amount1}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Description</span>
                    </div>
                    <div className="col-lg-12 report-text  mb-3">
                      <span> {item?.Criteria05_511?.Desc21}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>
                        <a href={`${item?.Criteria05_511?.Link21}`}>
                          {item?.Criteria05_511?.Link21}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                //   <tr>
                //   <td>{++i}.</td>
                //   <td>{item?.Year}</td>

                //   <td>{item?.Criteria05_511?.NameS}</td>
                // <td>
                //   <Table responsive>
                //     <thead>
                //       <th style={{ border: "1px solid black" }}>
                //         Number of students
                //       </th>
                //       <th style={{ border: "1px solid black" }}>
                //         Amount
                //       </th>
                //     </thead>
                //     <tbody>
                //       <tr>
                //         <td style={{ border: "1px solid black" }}>
                //           {item?.Criteria05_511?.SName}
                //         </td>
                //         <td style={{ border: "1px solid black" }}>
                //           {item?.Criteria05_511?.Amount}
                //         </td>
                //       </tr>
                //     </tbody>
                //   </Table>
                // </td>
                // <td>
                //   <Table responsive>
                //     <thead>
                //       <th style={{ border: "1px solid black" }}>
                //         Number of students
                //       </th>
                //       <th style={{ border: "1px solid black" }}>
                //         Amount
                //       </th>
                //     </thead>
                //     <tbody>
                //       <tr>
                //         <td style={{ border: "1px solid black" }}>
                //           {item?.Criteria05_511?.SName1}
                //         </td>
                //         <td style={{ border: "1px solid black" }}>
                //           {item?.Criteria05_511?.Amount1}
                //         </td>
                //       </tr>
                //     </tbody>
                //   </Table>
                // </td>
                // <td>{item?.Criteria05_511?.Desc21}</td>

                // <img
                //       src={`https://brightnaac.co.in/UG_Criteria05/${item?.Criteria05_511?.Link21}`}
                //       alt="img"
                //       width={50}
                //     />

                // </tr>
              );
            })}
          </div>
        </div>

        <div className="text-center">
          <button className="ad" style={{}} onClick={createPDF}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default PDFprintUG5_511;
