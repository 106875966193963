import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function IIQA() {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [allYears, setallYears] = useState([]);
  const [MainYear1, setMainYear1] = useState("");
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  useEffect(() => {
    if (!pricipalDetails) {
      alert("Please Login first");
      window.location.assign("/login");
    } else {
      getAllYears();
    }
  }, []);

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  const [selectedyear, setselectedyear] = useState("");
  const [Dateofsubmission, setDateofsubmission] = useState("");
  const [AISHEID, setAISHEID] = useState("");
  const [InstitutionTrackID, setInstitutionTrackID] = useState("");
  const [ApplicationFor, setApplicationFor] = useState("");
  const [Cyclename, setCyclename] = useState("");
  const [CycleNumber, setCycleNumber] = useState("");
  const [Cycledate, setCycledate] = useState("");
  const [Cyclegrade, setCyclegrade] = useState("");
  const [CollegeName, setCollegeName] = useState("");
  const [Collegeestablishmentdate, setCollegeestablishmentdate] = useState("");
  const [Collegeheadname, setCollegeheadname] = useState("");
  const [Designation, setDesignation] = useState("");
  const [OwnCampus, setOwnCampus] = useState("");
  const [Collegeaddress, setCollegeaddress] = useState("");
  const [CollegeState, setCollegeState] = useState("");
  const [CollegeDistrict, setCollegeDistrict] = useState("");
  const [CollegeCity, setCollegeCity] = useState("");
  const [CollegePin, setCollegePin] = useState("");
  const [CollegePhoneNo, setCollegePhoneNo] = useState("");
  const [CollegeFaxNo, setCollegeFaxNo] = useState("");
  const [CollegeMobileNo, setCollegeMobileNo] = useState("");
  const [CollegeRegisteredEmail, setCollegeRegisteredEmail] = useState("");
  const [CollegeAlternateEmail, setCollegeAlternateEmail] = useState("");
  const [AlternateFaculty, setAlternateFaculty] = useState("");
  const [ADesignation, setADesignation] = useState("");
  const [ACollegeaddress, setACollegeaddress] = useState("");
  const [ACollegeState, setACollegeState] = useState("");
  const [ACollegeCity, setACollegeCity] = useState("");
  const [ACollegePin, setACollegePin] = useState("");
  const [ACollegePhoneNo, setACollegePhoneNo] = useState("");
  const [ACollegeFaxNo, setACollegeFaxNo] = useState("");
  const [ACollegeMobileNo, setACollegeMobileNo] = useState("");
  const [ACollegeRegisteredEmail, setACollegeRegisteredEmail] = useState("");
  const [ACollegeAlternateEmail, setACollegeAlternateEmail] = useState("");
  const [Website, setWebsite] = useState("");
  const [Natureofthecollege, setNatureofthecollege] = useState("");
  const [CollegeAffiliation, setCollegeAffiliation] = useState("");
  const [Instc6yoexistence, setInstc6yoexistence] = useState("");
  const [graduationy1, setgraduationy1] = useState("");
  const [graduationy2, setgraduationy2] = useState("");
  const [affiliating_Uni_State, setaffiliating_Uni_State] = useState("");
  const [affiliating_Uni_Name, setaffiliating_Uni_Name] = useState("");
  const [affiliating_Uni_Doc, setaffiliating_Uni_Doc] = useState("");
  const [section2UGCAct, setsection2UGCAct] = useState("");
  const [section2UGCAct_Doc, setsection2UGCAct_Doc] = useState("");
  const [section12BUGCAct, setsection12BUGCAct] = useState("");
  const [section12BUGCAct_Doc, setsection12BUGCAct_Doc] = useState("");
  const [recognised_Aut_Clg, setrecognised_Aut_Clg] = useState("");
  const [CPE, setCPE] = useState("");
  const [College_Excellence, setCollege_Excellence] = useState("");
  const [recognised_SRA, setrecognised_SRA] = useState("");
  const [SRA_AIU, setSRA_AIU] = useState("");
  const [SRA_program, setSRA_program] = useState("");
  const [SRA_Document, setSRA_Document] = useState("");
  const [UG, setUG] = useState();
  const [PG, setPG] = useState();
  const [Ayurveda, setAyurveda] = useState();
  const [M_Phil, setM_Phil] = useState();
  const [PhD, setPhD] = useState();
  const [LLD, setLLD] = useState();
  const [PG_Diploma, setPG_Diploma] = useState();
  const [Diploma, setDiploma] = useState();
  const [Certificate, setCertificate] = useState();
  const [Programme_Array, setProgramme_Array] = useState([]);
  const [Programme_Doc, setProgramme_Doc] = useState("");
  const [Teaching_P_Male, setTeaching_P_Male] = useState(0);
  const [Teaching_P_Female, setTeaching_P_Female] = useState(0);
  const [Teaching_P_Transgender, setTeaching_P_Transgender] = useState(0);
  const [Teaching_T_Male, setTeaching_T_Male] = useState(0);
  const [Teaching_T_Female, setTeaching_T_Female] = useState(0);
  const [Teaching_T_Transgender, setTeaching_T_Transgender] = useState(0);
  const [N_Teaching_P_Male, setN_Teaching_P_Male] = useState(0);
  const [N_Teaching_P_Female, setN_Teaching_P_Female] = useState(0);
  const [N_Teaching_P_Transgender, setN_Teaching_P_Transgender] = useState(0);
  const [N_Teaching_T_Male, setN_Teaching_T_Male] = useState(0);
  const [N_Teaching_T_Female, setN_Teaching_T_Female] = useState(0);
  const [N_Teaching_T_Transgender, setN_Teaching_T_Transgender] = useState(0);
  const [Students_M, setStudents_M] = useState(0);
  const [Students_F, setStudents_F] = useState(0);
  const [Students_T, setStudents_T] = useState(0);
  const [committees, setcommittees] = useState();
  const [Date_establishment_IQAC, setDate_establishment_IQAC] = useState("");
  const [IQAC_meeting_Date, setIQAC_meeting_Date] = useState("");
  const [IQAC_meeting_Doc, setIQAC_meeting_Doc] = useState("");
  const [Date_submission_AQARs1, setDate_submission_AQARs1] = useState("");
  const [Date_submission_AQARs_Doc1, setDate_submission_AQARs_Doc1] =
    useState("");
  const [Date_submission_AQARs2, setDate_submission_AQARs2] = useState("");
  const [Date_submission_AQARs_Doc2, setDate_submission_AQARs_Doc2] =
    useState("");
  const [Date_submission_AQARs3, setDate_submission_AQARs3] = useState("");
  const [Date_submission_AQARs_Doc3, setDate_submission_AQARs_Doc3] =
    useState("");
  const [Date_submission_AQARs4, setDate_submission_AQARs4] = useState("");
  const [Date_submission_AQARs_Doc4, setDate_submission_AQARs_Doc4] =
    useState("");
  const [RTI_Act_2005, setRTI_Act_2005] = useState("");
  const [RTI_Act_2005_Doc, setRTI_Act_2005_Doc] = useState("");
  const [MoU_foreign, setMoU_foreign] = useState("");
  const [MHRD_Date, setMHRD_Date] = useState("");
  const [MHRD_Doc, setMHRD_Doc] = useState("");
  const [AttachCertification, setAttachCertification] = useState("");
  const [Registration_Fee, setRegistration_Fee] = useState();

  const [Program_Name, setProgram_Name] = useState("");
  const [Program_Department, setProgram_Department] = useState("");
  const [Program_University, setProgram_University] = useState("");
  const [Program_SRA, setProgram_SRA] = useState("");
  const [Program_Affiliation, setProgram_Affiliation] = useState("");

  const addPrograms = () => {
    if (
      !Program_Name ||
      !Program_Department ||
      !Program_University ||
      !Program_SRA ||
      !Program_Affiliation
    ) {
      alert("Please fill all the fields");
    } else {
      let obj = {
        Program_Name: Program_Name,
        Program_Department: Program_Department,
        Program_University: Program_University,
        Program_SRA: Program_SRA,
        Program_Affiliation: Program_Affiliation,
      };
      Programme_Array.push(obj);
      setProgram_Name("");
      setProgram_Department("");
      setProgram_University("");
      setProgram_SRA("");
      setProgram_Affiliation("");
    }
  };

  const removeItem = (val) => {
    const data = Programme_Array.filter((item, index) => index !== val);
    setProgramme_Array(data);
  };

  const handleMainBody = (e, editor) => {
    const data = editor.getData();
    setcommittees(data);
  };

  const handleMainBody1 = (e, editor) => {
    const data = editor.getData();
    setRegistration_Fee(data);
  };

  const addiiqa = async () => {
    if (
      !selectedyear &&
      !Dateofsubmission &&
      !AISHEID &&
      !InstitutionTrackID &&
      !ApplicationFor &&
      !Cyclename &&
      !CycleNumber &&
      !Cycledate &&
      !Cyclegrade &&
      !CollegeName &&
      !Collegeestablishmentdate &&
      !Collegeheadname &&
      !Designation &&
      !OwnCampus &&
      !Collegeaddress &&
      !CollegeState &&
      !CollegeDistrict &&
      !CollegeCity &&
      !CollegePin &&
      !CollegePhoneNo &&
      !CollegeFaxNo &&
      !CollegeMobileNo &&
      !CollegeRegisteredEmail &&
      !CollegeAlternateEmail &&
      !AlternateFaculty &&
      !ACollegeaddress &&
      !ACollegeState &&
      !ACollegeCity &&
      !ACollegePin &&
      !ACollegePhoneNo &&
      !ACollegeFaxNo &&
      !ACollegeMobileNo &&
      !ACollegeRegisteredEmail &&
      !ACollegeAlternateEmail &&
      !Website &&
      !Natureofthecollege &&
      !CollegeAffiliation &&
      !Instc6yoexistence &&
      !graduationy1 &&
      !graduationy2 &&
      !affiliating_Uni_State &&
      !affiliating_Uni_Name &&
      !affiliating_Uni_Doc &&
      !section2UGCAct &&
      !section2UGCAct_Doc &&
      !section12BUGCAct &&
      !section12BUGCAct_Doc &&
      !recognised_Aut_Clg &&
      !CPE &&
      !College_Excellence &&
      !recognised_SRA &&
      !SRA_AIU &&
      !UG &&
      !PG &&
      !Ayurveda &&
      !M_Phil &&
      !PhD &&
      !LLD &&
      !PG_Diploma &&
      !Diploma &&
      !Certificate &&
      !Programme_Doc &&
      !Teaching_P_Male &&
      !Teaching_P_Female &&
      !Teaching_P_Transgender &&
      !Teaching_T_Male &&
      !Teaching_T_Female &&
      !Teaching_T_Transgender &&
      !N_Teaching_P_Male &&
      !N_Teaching_P_Female &&
      !N_Teaching_P_Transgender &&
      !N_Teaching_T_Male &&
      !N_Teaching_T_Female &&
      !N_Teaching_T_Transgender &&
      !Students_M &&
      !Students_F &&
      !Students_T &&
      !committees &&
      !Date_establishment_IQAC &&
      !IQAC_meeting_Date &&
      !IQAC_meeting_Doc &&
      !Date_submission_AQARs1 &&
      !Date_submission_AQARs_Doc1 &&
      !Date_submission_AQARs2 &&
      !Date_submission_AQARs_Doc2 &&
      !Date_submission_AQARs3 &&
      !Date_submission_AQARs_Doc3 &&
      !Date_submission_AQARs4 &&
      !Date_submission_AQARs_Doc4 &&
      !RTI_Act_2005 &&
      !RTI_Act_2005_Doc &&
      !MoU_foreign &&
      !MHRD_Date &&
      !MHRD_Doc &&
      !AttachCertification &&
      !Registration_Fee
    )
      alert("Please fill all the fields");
    else if (Programme_Array?.length === 0) {
      alert("Please add programs");
    } else
      try {
        if (
          ValidateEmail(CollegeRegisteredEmail) &&
          ValidateEmail(CollegeAlternateEmail) &&
          ValidateEmail(ACollegeRegisteredEmail) &&
          ValidateEmail(ACollegeAlternateEmail) &&
          phonenumber(CollegeMobileNo) &&
          phonenumber(ACollegeMobileNo)
        ) {
          const config = {
            url: "/pri/addIIQA",
            method: "post",
            baseURL: "https://brightnaac.co.in/api",
            headers: { "content-type": "multipart/form-data" },
            data: {
              CollegeId: pricipalDetails?._id,
              selectedyear: selectedyear,
              Dateofsubmission: Dateofsubmission,
              AISHEID: AISHEID,
              InstitutionTrackID: InstitutionTrackID,
              ApplicationFor: ApplicationFor,
              Cyclename: Cyclename,
              CycleNumber: CycleNumber,
              Cycledate: Cycledate,
              Cyclegrade: Cyclegrade,
              CollegeName: CollegeName,
              Collegeestablishmentdate: Collegeestablishmentdate,
              Collegeheadname: Collegeheadname,
              Designation: Designation,
              OwnCampus: OwnCampus,
              Collegeaddress: Collegeaddress,
              CollegeState: CollegeState,
              CollegeDistrict: CollegeDistrict,
              CollegeCity: CollegeCity,
              CollegePin: CollegePin,
              CollegePhoneNo: CollegePhoneNo,
              CollegeFaxNo: CollegeFaxNo,
              CollegeMobileNo: CollegeMobileNo,
              CollegeRegisteredEmail: CollegeRegisteredEmail,
              CollegeAlternateEmail: CollegeAlternateEmail,
              AlternateFaculty: AlternateFaculty,
              ADesignation: ADesignation,
              ACollegeaddress: ACollegeaddress,
              ACollegeState: ACollegeState,
              ACollegeCity: ACollegeCity,
              ACollegePin: ACollegePin,
              ACollegePhoneNo: ACollegePhoneNo,
              ACollegeFaxNo: ACollegeFaxNo,
              ACollegeMobileNo: ACollegeMobileNo,
              ACollegeRegisteredEmail: ACollegeRegisteredEmail,
              ACollegeAlternateEmail: ACollegeAlternateEmail,
              Website: Website,
              Natureofthecollege: Natureofthecollege,
              CollegeAffiliation: CollegeAffiliation,
              Instc6yoexistence: Instc6yoexistence,
              graduationy1: graduationy1,
              graduationy2: graduationy2,
              affiliating_Uni_State: affiliating_Uni_State,
              affiliating_Uni_Name: affiliating_Uni_Name,
              affiliating_Uni_Doc: affiliating_Uni_Doc,
              section2UGCAct: section2UGCAct,
              section2UGCAct_Doc: section2UGCAct_Doc,
              section12BUGCAct: section12BUGCAct,
              section12BUGCAct_Doc: section12BUGCAct_Doc,
              recognised_Aut_Clg: recognised_Aut_Clg,
              CPE: CPE,
              College_Excellence: College_Excellence,
              recognised_SRA: recognised_SRA,
              SRA_AIU: SRA_AIU,
              SRA_program: SRA_program,
              SRA_Document: SRA_Document,
              UG: UG,
              PG: PG,
              Ayurveda: Ayurveda,
              M_Phil: M_Phil,
              PhD: PhD,
              LLD: LLD,
              PG_Diploma: PG_Diploma,
              Diploma: Diploma,
              Certificate: Certificate,
              Programme_Doc: Programme_Doc,
              Teaching_P_Male: Teaching_P_Male,
              Teaching_P_Female: Teaching_P_Female,
              Teaching_P_Transgender: Teaching_P_Transgender,
              Teaching_T_Male: Teaching_T_Male,
              Teaching_T_Female: Teaching_T_Female,
              Teaching_T_Transgender: Teaching_T_Transgender,
              N_Teaching_P_Male: N_Teaching_P_Male,
              N_Teaching_P_Female: N_Teaching_P_Female,
              N_Teaching_P_Transgender: N_Teaching_P_Transgender,
              N_Teaching_T_Male: N_Teaching_T_Male,
              N_Teaching_T_Female: N_Teaching_T_Female,
              N_Teaching_T_Transgender: N_Teaching_T_Transgender,
              Students_M: Students_M,
              Students_F: Students_F,
              Students_T: Students_T,
              committees: committees,
              Date_establishment_IQAC: Date_establishment_IQAC,
              IQAC_meeting_Date: IQAC_meeting_Date,
              IQAC_meeting_Doc: IQAC_meeting_Doc,
              Date_submission_AQARs1: Date_submission_AQARs1,
              Date_submission_AQARs_Doc1: Date_submission_AQARs_Doc1,
              Date_submission_AQARs2: Date_submission_AQARs2,
              Date_submission_AQARs_Doc2: Date_submission_AQARs_Doc2,
              Date_submission_AQARs3: Date_submission_AQARs3,
              Date_submission_AQARs_Doc3: Date_submission_AQARs_Doc3,
              Date_submission_AQARs4: Date_submission_AQARs4,
              Date_submission_AQARs_Doc4: Date_submission_AQARs_Doc4,
              RTI_Act_2005: RTI_Act_2005,
              RTI_Act_2005_Doc: RTI_Act_2005_Doc,
              MoU_foreign: MoU_foreign,
              MHRD_Date: MHRD_Date,
              MHRD_Doc: MHRD_Doc,
              AttachCertification: AttachCertification,
              Registration_Fee: Registration_Fee,
              Programme_Array: Programme_Array,
            },
          };
          let res = await axios(config);
          if (res.status === 200) {
            alert("IIQA added succesfully");
            window.location.assign("/IiqaMain");
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert(error.response.data.error);
        }
      }
  };

  console.log("Programme_Array", Programme_Array);

  return (
    <div style={{ overflow: "hidden", height: "700px", overflowY: "scroll" }}>
      <div className="text-center">
        <img
          src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
          alt="img"
          className=" pdfimg pt-2 "
        />
        <h3>Institutional Information for Quality Assessment(IIQA)</h3>
        <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
      </div>
      <div>
        <div
          class=" col-md-12-fluid border"
          style={{
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <div>
            <label>Select Year</label>

            <select
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              onChange={(e) => setselectedyear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}{" "}
            </select>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Date of submission</label>
              <input
                type="date"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setDateofsubmission(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>AISHE ID</label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setAISHEID(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Institution Track ID</label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setInstitutionTrackID(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          class=" col-md-12-fluid border mt-5"
          style={{
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <label>Application For</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setApplicationFor(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Cycle of Accreditation </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCyclename(e.target.value)}
              />
              <Table responsive>
                <thead>
                  <th>Cycle</th>
                  <th>Date</th>
                  <th>Grade</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "120px" }}
                        onChange={(e) => setCycleNumber(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "120px" }}
                        onChange={(e) => setCycledate(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "120px" }}
                        onChange={(e) => setCyclegrade(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Name of the College</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeName(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Date of establishment of the Institution </label>
              <input
                type="date"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeestablishmentdate(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label> Name of the Head of the Institution </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeheadname(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Designation </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label> Does the college function from Own Campus </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setOwnCampus(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Address of the College </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeaddress(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> State/UT </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeState(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> District </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeDistrict(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> City </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeCity(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Pin </label>
              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={6}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegePin(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Phone No </label>

              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                // maxLength={10}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegePhoneNo(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Fax No </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeFaxNo(e.target.value)}
              />
            </div>

            <div className="col-md-4">
              <label> Mobile No </label>

              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={10}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeMobileNo(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Registered Email </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeRegisteredEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label> Alternate Email </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeAlternateEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Alternate Faculty Contact Details </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setAlternateFaculty(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Designation</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setADesignation(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Address</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setACollegeaddress(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> State/UT </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setACollegeState(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> City</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setACollegeCity(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Pin </label>
              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={6}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setACollegePin(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Phone No</label>

              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setACollegePhoneNo(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Fax No </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setACollegeFaxNo(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Mobile No</label>

              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={10}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setACollegeMobileNo(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Email </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setACollegeRegisteredEmail(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Alternate Email</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setACollegeAlternateEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label> Website</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Nature of the college</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setNatureofthecollege(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> College Affiliation</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setCollegeAffiliation(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Has the Institution completed 6 years of existence</label>
              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setInstc6yoexistence(e.target.value)}
              >
                <option>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                <div>
             <label>Yes</label>
              <input
                type="checkbox"
              />
             </div>
             <div>
               <label>No</label>
               <input
                type="checkbox"
              
              /></div>
              </div> */}
            </div>
            <div className="col-md-6">
              <label>Years of graduation of last two batches</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div>
                  <label>Year1</label>
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={4}
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "100px" }}
                    onChange={(e) => setgraduationy1(e.target.value)}
                  />
                </div>
                <div>
                  <label>Year2</label>
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={4}
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "100px" }}
                    onChange={(e) => setgraduationy2(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <label>
              Name of the affiliating University(ies) and the state(s) in which
              the University(ies) is located
            </label>

            <Table responsive>
              <thead>
                <th>State</th>
                <th>University Name</th>
                <th>Documents</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setaffiliating_Uni_State(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setaffiliating_Uni_Name(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setaffiliating_Uni_Doc(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="col-md-12">
            <label>
              Is the Institution recognized under section 2(f) of the UGC Act?
            </label>
            <div className="row">
              <div className="col-md-6">
                <textarea
                  rows={5}
                  className="vi_0"
                  aria-label="Default select example"
                  //   style={{ width: "270px" }}
                  onChange={(e) => setsection2UGCAct(e.target.value)}
                ></textarea>
              </div>
              <div className="col-md-6">
                <input
                  type="file"
                  className="vi_0"
                  aria-label="Default select example"
                  //   style={{ width: "270px" }}
                  onChange={(e) => setsection2UGCAct_Doc(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <label>
              Is the Institution recognized under section 12B of the UGC Act? If
              yes, date of recognition by UGC under section 12B along with
              latest Plan General Development Grant release letter
            </label>
            <div className="row">
              <div className="col-md-6">
                <textarea
                  rows={5}
                  className="vi_0"
                  aria-label="Default select example"
                  //   style={{ width: "270px" }}
                  onChange={(e) => setsection12BUGCAct(e.target.value)}
                ></textarea>
              </div>
              <div className="col-md-6">
                <input
                  type="file"
                  className="vi_0"
                  aria-label="Default select example"
                  //   style={{ width: "270px" }}
                  onChange={(e) => setsection12BUGCAct_Doc(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>
                Is the institution recognised as an Autonomous College by the
                UGC?
              </label>

              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setrecognised_Aut_Clg(e.target.value)}
              >
                <option>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-md-6">
              <label>
                Is the institution recognised as a ‘College with Potential for
                Excellence (CPE)’ by the UGC?
              </label>

              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setCPE(e.target.value)}
              >
                <option>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>
                Is the institution recognised as a ‘College of Excellence’ by
                the UGC?
              </label>

              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setCollege_Excellence(e.target.value)}
              >
                <option>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-md-6">
              <label>
                Is the College offering any programmes recognised by any
                Statutory Regulatory Authority (SRA)
              </label>

              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setrecognised_SRA(e.target.value)}
              >
                <option>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>
                If the institution is not affiliated to a university and is
                offering programmes recognized by any Statutory Regulatory
                Authorities (SRA), are the programmes recognized by Association
                of Indian Universities(AIU) or other appropriate Government
                authorities as equivalent to UG / PG Programmes of a University
              </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setSRA_AIU(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label> Statutory Regulatory Authorities</label>

              <Table responsive>
                <thead>
                  <th>SRA program</th>
                  <th>Document</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        onChange={(e) => setSRA_program(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="file"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        onChange={(e) => setSRA_Document(e.target.files[0])}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div className="col-md-12">
            <label> Number of programmes offered</label>
            <Table responsive>
              <thead>
                <th>Programmes</th>
                <th>Number</th>
              </thead>
              <tbody>
                <tr>
                  <td>UG</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setUG(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>PG</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setPG(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Post Master's (DM, Ayurveda Vachaspathi, M.ch)</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setAyurveda(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pre Doctoral (M.Phil)</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setM_Phil(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Doctoral (Ph.D)</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setPhD(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Post Doctoral (D.Sc, D.Litt, LLD)</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setLLD(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    PG Diploma recognised by statutory authority including
                    university
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setPG_Diploma(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Diploma</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setDiploma(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Certificate / Awareness</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setCertificate(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="col-md-12">
            <label> Programme Details</label>
            <Table responsive>
              <thead>
                <th>Program</th>
                <th>Department</th>
                <th>University Affiliation</th>
                <th> SRA Recognition</th>
                <th> Affiliation Status</th>
                <th>Action</th>
              </thead>
              <tbody>
                {Programme_Array?.length > 0
                  ? Programme_Array?.map((item, i) => (
                      <tr>
                        <td>{item?.Program_Name}</td>
                        <td>{item?.Program_Department}</td>
                        <td>{item?.Program_University}</td>
                        <td>{item?.Program_SRA}</td>
                        <td>{item?.Program_Affiliation}</td>
                        <td>
                          <button
                            style={{ right: 10, backgroundColor: "#dc3545" }}
                            onClick={() => removeItem(i)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  : ""}
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={Program_Name}
                      onChange={(e) => setProgram_Name(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={Program_Department}
                      onChange={(e) => setProgram_Department(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={Program_University}
                      onChange={(e) => setProgram_University(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={Program_SRA}
                      onChange={(e) => setProgram_SRA(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={Program_Affiliation}
                      onChange={(e) => setProgram_Affiliation(e.target.value)}
                    />
                  </td>
                  <td>
                    <button style={{ right: 10 }} onClick={addPrograms}>
                      Add
                    </button>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                    />
                  </td>
                </tr> */}
              </tbody>
            </Table>
            <input
              type="file"
              className="vi_0"
              aria-label="Default select example"
              onChange={(e) => setProgramme_Doc(e.target.files[0])}
            />
          </div>

          <div className="col-md-12">
            <label>
              Number of Teaching Staff by employment status (permanent /
              temporary) and by gender
            </label>
            <Table responsive>
              <thead>
                <th>Type</th>
                <th>Male</th>
                <th>Female</th>
                <th>Transgender</th>
                <th> Total</th>
              </thead>
              <tbody>
                <tr>
                  <td>Permanent</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setTeaching_P_Male(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setTeaching_P_Female(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setTeaching_P_Transgender(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        Teaching_P_Male &&
                        Teaching_P_Female &&
                        Teaching_P_Transgender
                          ? parseInt(Teaching_P_Male) +
                            parseInt(Teaching_P_Female) +
                            parseInt(Teaching_P_Transgender)
                          : ""
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Temporary</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setTeaching_T_Male(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setTeaching_T_Female(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setTeaching_T_Transgender(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        Teaching_T_Male &&
                        Teaching_T_Female &&
                        Teaching_T_Transgender
                          ? parseInt(Teaching_T_Male) +
                            parseInt(Teaching_T_Female) +
                            parseInt(Teaching_T_Transgender)
                          : ""
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="col-md-12">
            <label>
              Number of Non-Teaching Staff by employment status (permanent /
              temporary) and by gender
            </label>
            <Table responsive>
              <thead>
                <th>Type</th>
                <th>Male</th>
                <th>Female</th>
                <th>Transgender</th>
                <th> Total</th>
              </thead>
              <tbody>
                <tr>
                  <td>Permanent</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setN_Teaching_P_Male(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setN_Teaching_P_Female(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setN_Teaching_P_Transgender(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        N_Teaching_P_Male &&
                        N_Teaching_P_Female &&
                        N_Teaching_P_Transgender
                          ? parseInt(N_Teaching_P_Male) +
                            parseInt(N_Teaching_P_Female) +
                            parseInt(N_Teaching_P_Transgender)
                          : ""
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Temporary</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setN_Teaching_T_Male(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setN_Teaching_T_Female(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setN_Teaching_T_Transgender(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        N_Teaching_T_Male &&
                        N_Teaching_T_Female &&
                        N_Teaching_T_Transgender
                          ? parseInt(N_Teaching_T_Male) +
                            parseInt(N_Teaching_T_Female) +
                            parseInt(N_Teaching_T_Transgender)
                          : ""
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="col-md-12">
            <label> Number of Students on roll by gender</label>
            <Table responsive>
              <thead>
                <th>Male</th>
                <th>Female</th>
                <th>Transgender</th>
                <th> Total</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setStudents_M(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setStudents_F(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setStudents_T(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        Students_M && Students_F && Students_T
                          ? parseInt(Students_M) +
                            parseInt(Students_F) +
                            parseInt(Students_T)
                          : ""
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label>
                Does the institution have statutory cells / committees{" "}
              </label>

              <div className="">
                <CKEditor
                  editor={ClassicEditor}
                  data={committees}
                  onChange={handleMainBody}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label> Date of establishment of IQAC</label>

              <input
                type="date"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setDate_establishment_IQAC(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>
                The minutes of IQAC meeting and Action Taken Report should be
                uploaded on the institutional website.
              </label>

              <Table responsive>
                <thead>
                  <th>Date</th>
                  <th>View Document</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="date"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "270px" }}
                        onChange={(e) => setIQAC_meeting_Date(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="file"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "270px" }}
                        onChange={(e) => setIQAC_meeting_Doc(e.target.files[0])}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div className="col-md-12">
            <label> Date of submission of AQARs of last 4 years to NAAC</label>
            <Table responsive>
              <thead>
                <th>Date</th>
                <th>View Document</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setDate_submission_AQARs1(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setDate_submission_AQARs_Doc1(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setDate_submission_AQARs2(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setDate_submission_AQARs_Doc2(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setDate_submission_AQARs3(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setDate_submission_AQARs_Doc3(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setDate_submission_AQARs4(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) =>
                        setDate_submission_AQARs_Doc4(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="col-md-12">
            <label>
              {" "}
              Has the institution made statutory declaration on the institution
              website under Section 4 (1) (b) of the RTI Act 2005 as issued and
              amended from time to time.
            </label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px", marginRight: "10px" }}
              onChange={(e) => setRTI_Act_2005(e.target.value)}
            />
            <input
              type="file"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              onChange={(e) => setRTI_Act_2005_Doc(e.target.files[0])}
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>
                Does the college have an academic MoU with any foreign
                institution{" "}
              </label>

              {/* <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setMoU_foreign(e.target.value)}
              /> */}
              <select
                className="vi_0"
                style={{ width: "270px" }}
                onChange={(e) => setMoU_foreign(e.target.value)}
              >
                <option>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-md-6">
              <label>
                {" "}
                Date of uploading data on MHRD website for All India Survey on
                Higher Education (AISHE).
              </label>
              <input
                type="date"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px", marginRight: "10px" }}
                onChange={(e) => setMHRD_Date(e.target.value)}
              />
              <input
                type="file"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setMHRD_Doc(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>
                {" "}
                Attach Certification by the Head of the Institution for having
                complied with Rules & Regulations of Central Government, UGC and
                other Statutory Bodies, State Government and Affiliating
                University in the prescribed format enclosed herewith.
              </label>

              <input
                type="file"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setAttachCertification(e.target.files[0])}
              />
            </div>
            <div className="col-md-6">
              <label> Registration Fee paid details.</label>
              <div className="">
                <CKEditor
                  editor={ClassicEditor}
                  data={Registration_Fee}
                  onChange={handleMainBody1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <button onClick={addiiqa}>Save</button>
      </div>
    </div>
  );
}
