import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";

function AdminClgUNI05_531() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("University-5.3.1");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
          item1?.approve === "true" &&
          selectedYear === item1?.Uni_Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          Year: item?.Uni_Year,
          "Name of the award/ medal": item?.Uni_Criteria05_531?.Uni_Medal,
          "Team / Individual": item?.Uni_Criteria05_531?.Uni_Individual,
          "inter-university / state / National/ International":
            item?.Uni_Criteria05_531?.Uni_Inter,
          "Name of the event": item?.Uni_Criteria05_531?.Uni_Event,
          "Name of the student": item?.Uni_Criteria05_531?.Uni_Student,
          Description: item?.Uni_Criteria05_531?.Uni_Desc24,
          "Link to website of the Journal":
            item?.Uni_Criteria05_531?.Uni_Link24,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/unicri05_531"
      );
      if (res.status === 200) {
        setAllDetails(res.data.getC531);
        setdata1(res.data.getC531);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.getC531);
    }
  };
  console.log("allContent", AllDetails);

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      // approveCriteria();
      getContent();
      getAllYears();
    }
  }, []);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <h4 className="mt-4"> University Criteria 5 5.3.1</h4>
              <div className="ad-b mt-4 row align-items-center">
                <div className=" col-lg-4 do-sear mt-4 d-flex ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="vi_0"
                    onChange={(e) => setSearchItem(e.target.value)}
                  />
                </div>

                <div className=" col-lg-2 lab">
                  <label>From</label>
                  <br />
                  <DatePicker
                    onChange={onChangeFrom}
                    picker="year"
                    className="vi_0"
                  />
                </div>
                <div className=" col-lg-2 lab">
                  <label>To</label>
                  <br />
                  <DatePicker
                    onChange={onChangeTo}
                    picker="year"
                    className="vi_0"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ad-b  row align-items-center">
            <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
              <label style={{ width: "130px" }}>Select Year</label>
              <select
                className="criteriaform"
                style={{ width: "150px" }}
                onChange={(e) => setselectedYear(e.target.value)}
              >
                <option>Select Year</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-lg-5">
              <Nav defaultActiveKey="/unicriteria05">
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-1"
                    onClick={() =>
                      navigate(`/adminclgunicri5511/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">5.1.1</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    onClick={() =>
                      navigate(`/adminclgunicri5512/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">5.1.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri5513/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">5.1.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri5521/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">5.2.1</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri5522/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">5.2.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri5523/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">5.2.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri5531/${params?.id}`)
                    }
                  >
                    <Button
                      variant="warning"
                      style={{ backgroundColor: "#ffc107" }}
                      className="criteriabtn-0"
                    >
                      5.3.1
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri5533/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">5.3.3</Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <div className=" col-lg-2 text-end ">
              <Button onClick={ExportToExcel}>Export Excel</Button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>inter-university / state / National/ International</th>
                  <th>Name of the event</th>
                  <th>Name of the student</th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Uni_Year &&
                    (SearchItem === "" ||
                      item1?.Uni_Year?.includes(SearchItem) ||
                      item1?.Uni_Criteria05_531?.Uni_Medal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Individual?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Inter?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Event?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Student?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Desc24?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>

                      <td>{item?.Uni_Criteria05_531?.Uni_Medal}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Individual}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Inter}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Event}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Student}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Desc24}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_531?.Uni_Link24}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AdminClgUNI05_531;
