import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Container, Table } from "react-bootstrap";
import UGC1aqar from "./UGC1aqar";
import UGC2aqar from "./UGC2aqar";
import UGC3aqar from "./UGC3aqar";
import UGC4aqar from "./UGC4aqar";
import UGC5aqar from "./UGC5aqar";
import UGC6aqar from "./UGC6aqar";
import UGC7aqar from "./UGC7aqar";
import { useLocation } from "react-router-dom";
import PGC1aqar from "./PGC1aqar";
import PGC2aqar from "./PGC2aqar";
import PGC3aqar from "./PGC3aqar";
import PGC4aqar from "./PGC4aqar";
import PGC5aqar from "./PGC5aqar";
import PGC6aqar from "./PGC6aqar";
import PGC7aqar from "./PGC7aqar";

import "./SSRViewReport.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
function SSRViewReport() {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const location = useLocation();
  const startYeardata = location?.state?.startYearReport;
  const endYeardata = location?.state?.endYearReport;
  const reportDetails = location?.state?.itemDetails;
  let arrOfYears = [];
  for (let i = startYeardata; i < endYeardata; i++) {
    arrOfYears.push(Number(i));
  }
  // Call the function to generate and download the PDF
  const dataToPrintRef = useRef(null);

  const handlePrint = () => {
    if (dataToPrintRef.current) {
      const content = dataToPrintRef.current.innerHTML;
      const originalDocument = document.body.innerHTML;

      document.body.innerHTML = content;
      window.print();

      document.body.innerHTML = originalDocument;
    }
  };

  const parseHTML = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };
  //  {parseHTML(`<div>${item?.ANXDesc}</div>`)}
  return (
    <>
      <div
        // id="content"
        id="pdf"
        ref={dataToPrintRef}
        style={{
          overflow: "hidden",
          height: "700px",
          overflowY: "scroll",
        }}
      >
        <div style={{ backgroundColor: "#ced3be" }}>
          <Container style={{ backgroundColor: "white" }}>
            <div className=" mt-3 d-flex justify-content-center">
              <img src="./header.png" alt="" />
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h4>
                <b>SELF STUDY REPORT</b>
              </h4>
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h2>
                <b>FOR</b>
              </h2>
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h2>
                <b>{reportDetails?.prefaceFor}</b>
              </h2>
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h2>
                <b>{pricipalDetails?.HeiName}</b>
              </h2>
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h6>
                {pricipalDetails?.Address}, {pricipalDetails?.City},{" "}
                {pricipalDetails?.State}
              </h6>
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h6>{pricipalDetails?.Pincode}</h6>
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h6>
                <a href={reportDetails?.prefaceWebsiteURI} target="_blank">
                  {reportDetails?.prefaceWebsiteURI}
                </a>
              </h6>
            </div>
            <div className=" mt-5 d-flex justify-content-center">
              <h6>Submitted To</h6>
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h5>NATIONAL ASSESSMENT AND ACCREDITATION COUNCIL</h5>
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h5>BANGALORE</h5>
            </div>
            <div className=" mt-3 d-flex justify-content-center">
              <h5>{reportDetails?.prefaceDate}</h5>
            </div>
            <br />
            <br />
          </Container>

          <Container style={{ backgroundColor: "white" }} className="mt-3">
            <div>
              <div
                className=" mt-3 d-flex justify-content-start"
                style={{ borderBottom: "1px solid" }}
              >
                <h5 className="mt-4">
                  <b>1. EXECUTIVE SUMMARY</b>
                </h5>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>1.1 INTRODUCTION</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.exeSum1Intro}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Vision</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.exeSum1Vision}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Mission</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.exeSum1Mission}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h5 className="mt-4">
                  <b>
                    1.2 Strength, Weakness, Opportunity and Challenges(SWOC)
                  </b>
                </h5>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Institutional Strength</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.swoc12InstStrength}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Institutional Weakness</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.swoc12InstWeakness}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Institutional Opportunity</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.swoc12InstOppo}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Institutional Challenge</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(
                    `<div>${reportDetails?.swoc12InstChallenge}</div>`
                  )}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h5 className="mt-4">
                  <b>1.3 CRITERIA WISE SUMMARY</b>
                </h5>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Curricular Aspects</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.criSum13CurrAsp}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Teaching-learning and Evaluation</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.criSum13TeachLearn}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Research, Innovations and Extension</b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.criSum13Research}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Infrastructure and Learning Resources </b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(
                    `<div>${reportDetails?.criSum13Infrastructure}</div>`
                  )}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Student Support and Progression </b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.criSum13StuSupport}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Governance, Leadership and Management </b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.criSum13Gov}</div>`)}
                </p>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>Institutional Values and Best Practices </b>
                </h6>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <p className="">
                  {parseHTML(`<div>${reportDetails?.criSum13InstValue}</div>`)}
                </p>
              </div>
              <div
                className=" mt-3 d-flex justify-content-start"
                style={{ borderBottom: "1px solid" }}
              >
                <h5 className="mt-4">
                  <b>2. PROFILE </b>
                </h5>
              </div>
              <div className=" mt-3 d-flex justify-content-start">
                <h6 className="mt-4">
                  <b>2.1 BASIC INFORMATION </b>
                </h6>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      Name and Address of the College
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{reportDetails?.profile21Name}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{reportDetails?.profile21Address}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>{reportDetails?.profile21City}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td>{reportDetails?.profile21State}</td>
                    </tr>
                    <tr>
                      <td>Pin</td>
                      <td>{reportDetails?.profile21Pin}</td>
                    </tr>
                    <tr>
                      <td>Website</td>
                      <td>{reportDetails?.profile21Website}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="7"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Contacts for Communication
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Designation
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Name
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Telephone with STD Code
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Mobile
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Fax
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Email
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportDetails?.ContractCom?.map((val1) => {
                      return (
                        <tr>
                          <td>{val1?.Designation}</td>
                          <td>{val1?.Name}</td>
                          <td>{val1?.Telephonecode}</td>
                          <td>{val1?.Mobile}</td>
                          <td>{val1?.Fax}</td>
                          <td>{val1?.Email}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="2"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        {" "}
                        Status of the Institution
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        <div>Institution Status</div>
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.inst21Status}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="2"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Type of Institution
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        <div>By Gender</div>
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.typeOfInst21Gender}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        <div>By Shift</div>
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.typeOfInst21Shift}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="2"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Recognized Minority institution
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        <div>If it is a recognized minroity institution</div>
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.regMinInst21}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="2"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Establishment Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        <div>Date of establishment of the college</div>
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.dateOFEstOFCollege21}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="3"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        University to which the college is affiliated/ or which
                        governs the college (if it is a constituent college)
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>State</th>
                      <th>University name</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.collegeAff21State}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.collegeAff21UniName}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        <a
                          href={`https://brightnaac.co.in/SSR/${reportDetails?.collegeAff21Doc}`}
                          target="blank_"
                        >
                          {" "}
                          View Document
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="3"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Details of UGC recognition
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Under Section</th>
                      <th>Date</th>
                      <th>View Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportDetails?.UGCRecgArr?.map((val2) => {
                      return (
                        <tr>
                          <td
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            {val2?.underSecUGC21}
                          </td>
                          <td
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            {val2?.DateUGC21}
                          </td>
                          <td
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            <a
                              href={`https://brightnaac.co.in/SSR/${val2?.docUGC21}`}
                              target="blank_"
                            >
                              {" "}
                              View Document
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="6"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Details of recognition/approval by stationary/regulatory
                        bodies like AICTE,NCTE,MCI,DCI,PCI,RCI etc(other than
                        UGC)
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Statutory Regulatory Authority</th>
                      <th>
                        Recognition/App roval details Inst itution/Departme nt
                        programme
                      </th>
                      <th>Day,Month and year(dd-mm-yyyy)</th>
                      <th>Validity in months</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportDetails?.Approve?.map((val3) => {
                      return (
                        <tr>
                          <td
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            {val3?.Statutory}
                          </td>
                          <td
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            {val3?.Recognition}
                          </td>
                          <td
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            {val3?.DMY}
                          </td>
                          <td
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            {val3?.ValidMonth}
                          </td>
                          <td
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            {val3?.Remark}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="2"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Details of autonomy
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        <div>
                          Does the affiliating university Act provide for
                          conferment of autonomy (as recognized by the UGC), on
                          its affiliated colleges?
                        </div>
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.detailsAutonomy21}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="2"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Recognitions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        <div>
                          Is the College recognized by UGC as a College with
                          Potential for Excellence(CPE)?
                        </div>
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.recog211}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        <div>
                          Is the College recognized for its performance by any
                          other governmental agency?
                        </div>
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.recog212}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="5"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Location and Area of Campus
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <th>Campus Type</th>
                    <th>Address</th>
                    <th>Location*</th>
                    <th>Campus Area in Acres</th>
                    <th>Built up Area in sq.mts.</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.campType21}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.campAddress21}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.campLoc21}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.campAreaInAcre21}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.campBuildUp21}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>2.2 ACADEMIC INFORMATION</b>
                </h5>
              </div>

              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="8"
                        style={{
                          textAlign: "start",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Details of Programmes Offered by the College (Give Data
                        for Current Academic year)
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Programme Level
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Name of Programme/Course
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Duration in Months
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Entry Qualification
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Medium of Instruction
                      </th>
                      <th style={{ backgroundColor: "white", color: "black" }}>
                        Sanctioned Strength
                      </th>
                      <th>No.of Students Admitted</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportDetails?.AcademinInfo?.map((val4) => {
                      return (
                        <tr>
                          <td>{val4?.ProgramLevel}</td>
                          <td> {val4?.Programme}</td>
                          <td> {val4?.DUration}</td>
                          <td> {val4?.Qualification}</td>
                          <td>{val4?.Instruction}</td>
                          <td>{val4?.Strength}</td>
                          <td>{val4?.Admitted}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="mt-2">
                <h6>Position Details of Faculty & Staff in the College</h6>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped border>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="4"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        {" "}
                        Teaching Faculty
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th></th>
                      <th colSpan="4">Professor</th>
                      <th colSpan="4">Associate Professor</th>
                      <th colSpan="4">Assistant Professor</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th></th>
                      <th>male</th>
                      <th>female</th>
                      <th>other</th>
                      <th>Total</th>
                      <th>male</th>
                      <th>female</th>
                      <th>other</th>
                      <th>Total</th>
                      <th>male</th>
                      <th>female</th>
                      <th>other</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Sanctioned by the UGC /University State Government
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {Number(reportDetails?.professorMale1) +
                          Number(reportDetails?.professorFemale1) +
                          Number(reportDetails?.professorOther1) +
                          Number(reportDetails?.professorYTR1)}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {Number(reportDetails?.AssoProfessorMale1) +
                          Number(reportDetails?.AssoProfessorFemale1) +
                          Number(reportDetails?.AssoProfessorOther1) +
                          Number(reportDetails?.AssoProfessorYTR1)}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {Number(reportDetails?.AssisProfessorMale1) +
                          Number(reportDetails?.AssisProfessorFemale1) +
                          Number(reportDetails?.AssisProfessorOther1) +
                          Number(reportDetails?.AssisProfessorYTR1)}
                      </td>
                    </tr>
                    <tr>
                      <td>Recruited</td>
                      <td>{reportDetails?.professorMale1}</td>
                      <td>{reportDetails?.professorFemale1}</td>
                      <td>{reportDetails?.professorOther1}</td>
                      <td>
                        {Number(reportDetails?.professorMale1) +
                          Number(reportDetails?.professorFemale1) +
                          Number(reportDetails?.professorOther1) +
                          Number(reportDetails?.professorYTR1)}
                      </td>
                      <td>{reportDetails?.AssoProfessorMale1}</td>
                      <td>{reportDetails?.AssoProfessorFemale1}</td>
                      <td>{reportDetails?.AssoProfessorOther1}</td>
                      <td>
                        {Number(reportDetails?.AssoProfessorMale1) +
                          Number(reportDetails?.AssoProfessorFemale1) +
                          Number(reportDetails?.AssoProfessorOther1) +
                          Number(reportDetails?.AssoProfessorYTR1)}
                      </td>
                      <td>{reportDetails?.AssisProfessorMale1}</td>
                      <td>{reportDetails?.AssisProfessorFemale1}</td>
                      <td>{reportDetails?.AssisProfessorOther1}</td>
                      <td>
                        {Number(reportDetails?.AssisProfessorMale1) +
                          Number(reportDetails?.AssisProfessorFemale1) +
                          Number(reportDetails?.AssisProfessorOther1) +
                          Number(reportDetails?.AssisProfessorYTR1)}
                      </td>
                    </tr>
                    <tr>
                      <td>Yet to Recruit</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.professorYTR1}</td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.AssoProfessorYTR1}</td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.AssisProfessorYTR1}</td>
                    </tr>
                    <tr>
                      <td>
                        Sanctioned by the Management/Society or Other Authorized
                        Bodies
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>32</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>32</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>32</td>
                    </tr>
                    <tr>
                      <td>Recruited</td>
                      <td>{reportDetails?.professorMale2}</td>
                      <td>{reportDetails?.professorFemale2}</td>
                      <td>{reportDetails?.professorOther2}</td>
                      <td>
                        {" "}
                        {Number(reportDetails?.professorMale2) +
                          Number(reportDetails?.professorFemale2) +
                          Number(reportDetails?.professorOther2) +
                          Number(reportDetails?.professorYTR2)}
                      </td>
                      <td>{reportDetails?.AssoProfessorMale2}</td>
                      <td>{reportDetails?.AssoProfessorFemale2}</td>
                      <td>{reportDetails?.AssoProfessorOther2}</td>
                      <td>
                        {Number(reportDetails?.AssoProfessorMale2) +
                          Number(reportDetails?.AssoProfessorFemale2) +
                          Number(reportDetails?.AssoProfessorOther2) +
                          Number(reportDetails?.AssoProfessorYTR2)}
                      </td>
                      <td>{reportDetails?.AssisProfessorMale2}</td>
                      <td>{reportDetails?.AssisProfessorFemale2}</td>
                      <td>{reportDetails?.AssisProfessorOther2}</td>
                      <td>
                        {Number(reportDetails?.AssisProfessorMale2) +
                          Number(reportDetails?.AssisProfessorFemale2) +
                          Number(reportDetails?.AssisProfessorOther2) +
                          Number(reportDetails?.AssisProfessorYTR2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Yet to Recruit</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.professorYTR2}</td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.AssoProfessorYTR2}</td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.AssisProfessorYTR2}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped border>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="5"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Non-Teaching Staff
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <th></th>
                    <th>Male</th>
                    <th>Female</th>
                    <th>Others</th>
                    <th>Total</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Sanctioned by the UGC /University State Government
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {Number(reportDetails?.NTSMale1) +
                          Number(reportDetails?.NTSFemale1) +
                          Number(reportDetails?.NTSOther1) +
                          Number(reportDetails?.NTSYTR1)}
                      </td>
                    </tr>
                    <tr>
                      <td>Recruited </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.NTSMale1}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.NTSFemale1}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.NTSOther1}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {Number(reportDetails?.NTSMale1) +
                          Number(reportDetails?.NTSFemale1) +
                          Number(reportDetails?.NTSOther1)}
                      </td>
                    </tr>
                    <tr>
                      <td>Yet to Recruit </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.NTSYTR1}</td>
                    </tr>

                    <tr>
                      <td>
                        Sanctioned by the Management/Society or Other Authorized
                        Bodies
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {Number(reportDetails?.NTSMale2) +
                          Number(reportDetails?.NTSFemale2) +
                          Number(reportDetails?.NTSOther2) +
                          Number(reportDetails?.NTSYTR2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Recruited </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.NTSMale2}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.NTSFemale2}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.NTSOther2}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {Number(reportDetails?.NTSMale2) +
                          Number(reportDetails?.NTSFemale2) +
                          Number(reportDetails?.NTSOther2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Yet to Recruit </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.NTSYTR2}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="5"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Technical Staff
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <th></th>
                    <th>Male</th>
                    <th>Female</th>
                    <th>Others</th>
                    <th>Total</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Sanctioned by the UGC /University State Government
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {Number(reportDetails?.TSMale1) +
                          Number(reportDetails?.TSFemale1) +
                          Number(reportDetails?.TSOther1) +
                          Number(reportDetails?.TSYTR1)}
                      </td>
                    </tr>
                    <tr>
                      <td>Recruited </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.TSMale1}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.TSFemale1}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.TSOther1}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {Number(reportDetails?.TSMale1) +
                          Number(reportDetails?.TSFemale1) +
                          Number(reportDetails?.TSOther1)}
                      </td>
                    </tr>
                    <tr>
                      <td>Yet to Recruit </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.TSYTR1}</td>
                    </tr>

                    <tr>
                      <td>
                        Sanctioned by the Management/Society or Other Authorized
                        Bodies
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {Number(reportDetails?.TSMale2) +
                          Number(reportDetails?.TSFemale2) +
                          Number(reportDetails?.TSOther2) +
                          Number(reportDetails?.TSYTR2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Recruited </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.TSMale2}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.TSFemale2}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {reportDetails?.TSOther2}
                      </td>
                      <td style={{ backgroundColor: "white", color: "black" }}>
                        {Number(reportDetails?.TSMale2) +
                          Number(reportDetails?.TSFemale2) +
                          Number(reportDetails?.TSOther2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Yet to Recruit </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{reportDetails?.TSYTR2}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="mt-2">
                <h6>Qualification Details of the Teaching Staff</h6>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="6"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Permanent Teachers
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <th>Highest Qualification</th>
                    <th colSpan="3">Professor</th>
                    <th colSpan="3">Associate Professor</th>
                    <th colSpan="3">Assistant Professor</th>
                  </thead>
                  <thead>
                    <th></th>
                    <th>male</th>
                    <th>female</th>
                    <th>other</th>
                    <th>male</th>
                    <th>female</th>
                    <th>other</th>
                    <th>male</th>
                    <th>female</th>
                    <th>other</th>
                    <th>Toatal</th>
                  </thead>
                  <tbody>
                    {reportDetails?.PermannentTech?.map((val5) => {
                      return (
                        <tr>
                          <td>{val5?.PhightestQ}</td>
                          <td>{val5?.Pmale1}</td>
                          <td>{val5?.Pfemale1}</td>
                          <td>{val5?.Pother1}</td>
                          <td>{val5?.Pmale2}</td>
                          <td>{val5?.Pfemale2}</td>
                          <td>{val5?.Pother2}</td>
                          <td>{val5?.Pmale3}</td>
                          <td>{val5?.Pfemale3}</td>
                          <td>{val5?.Pother3}</td>

                          <td>
                            {Number(val5?.Pmale1) +
                              Number(val5?.Pfemale1) +
                              Number(val5?.Pother1) +
                              Number(val5?.Pmale2) +
                              Number(val5?.Pfemale2) +
                              Number(val5?.Pother2) +
                              Number(val5?.Pmale3) +
                              Number(val5?.Pfemale3) +
                              Number(val5?.Pother3)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="6"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Temporary Teachers
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <th>Highest Qualification</th>
                    <th colSpan="3">Professor</th>
                    <th colSpan="3">Associate Professor</th>
                    <th colSpan="3">Assistant Professor</th>
                  </thead>
                  <thead>
                    <th></th>
                    <th>male</th>
                    <th>female</th>
                    <th>other</th>
                    <th>male</th>
                    <th>female</th>
                    <th>other</th>
                    <th>male</th>
                    <th>female</th>
                    <th>other</th>
                    <th>Total</th>
                  </thead>
                  <tbody>
                    {reportDetails?.TemporaryTech?.map((val6) => {
                      return (
                        <tr>
                          <td>{val6?.ThightestQ}</td>
                          <td>{val6?.Tmale1}</td>
                          <td>{val6?.Tfemale1}</td>
                          <td>{val6?.Tother1}</td>
                          <td>{val6?.Tmale2}</td>
                          <td>{val6?.TPfemale2}</td>
                          <td>{val6?.Tother2}</td>
                          <td>{val6?.Tmale3}</td>
                          <td>{val6?.Tfemale3}</td>
                          <td>{val6?.Tother3}</td>

                          <td>
                            {Number(val6?.Tmale1) +
                              Number(val6?.Tfemale1) +
                              Number(val6?.Tother1) +
                              Number(val6?.Tmale2) +
                              Number(val6?.TPfemale2) +
                              Number(val6?.Tother2) +
                              Number(val6?.Tmale3) +
                              Number(val6?.Tfemale3) +
                              Number(val6?.Tother3)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="6"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Part Time Teachers
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <th>Highest Qualification</th>
                    <th colSpan="3">Professor</th>
                    <th colSpan="3">Associate Professor</th>
                    <th colSpan="3">Assistant Professor</th>
                  </thead>
                  <thead>
                    <th></th>
                    <th>male</th>
                    <th>female</th>
                    <th>other</th>
                    <th>male</th>
                    <th>female</th>
                    <th>other</th>
                    <th>male</th>
                    <th>female</th>
                    <th>other</th>
                    <th>Toatal</th>
                  </thead>
                  <tbody>
                    {reportDetails?.PartTimeTech?.map((val7) => {
                      return (
                        <tr>
                          <td>{val7?.PThightestQ}</td>
                          <td>{val7?.PTmale1}</td>
                          <td>{val7?.PTfemale1}</td>
                          <td>{val7?.PTother1}</td>
                          <td>{val7?.PTmale2}</td>
                          <td>{val7?.PTfemale2}</td>
                          <td>{val7?.PTother2}</td>
                          <td>{val7?.PTmale3}</td>
                          <td>{val7?.PTfemale3}</td>
                          <td>{val7?.PTother3}</td>

                          <td>
                            {Number(val7?.PTmale1) +
                              Number(val7?.PTfemale1) +
                              Number(val7?.PTother1) +
                              Number(val7?.PTmale2) +
                              Number(val7?.PTfemale2) +
                              Number(val7?.PTother2) +
                              Number(val7?.PTmale3) +
                              Number(val7?.PTfemale3) +
                              Number(val7?.PTother3)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th
                        colspan="5"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        Details of Visting/Guest Faculties
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Number of Visiting/Guest Faculty engaged with the
                        college?
                      </td>
                      <td colSpan="1">Male</td>
                      <td colSpan="1">Female</td>
                      <td colSpan="1">Others</td>
                      <td colSpan="1">Total</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>{reportDetails?.detailsOfVisitingMale}</td>
                      <td>{reportDetails?.detailsOfVisitingFemale}</td>
                      <td>{reportDetails?.detailsOfVisitingOther}</td>
                      <td>
                        {Number(reportDetails?.detailsOfVisitingMale) +
                          Number(reportDetails?.detailsOfVisitingFemale) +
                          Number(reportDetails?.detailsOfVisitingOther)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="mt-2">
                <h6>
                  Provide the Following Details of Students Enrolled in the
                  College During the Current Academic Year
                </h6>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th colspan="2">Programme</th>
                      <th>From the State Where College is Located</th>
                      <th>From Other States of India</th>
                      <th>NRI Students</th>
                      <th>Foreign Students</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  {reportDetails?.StudentsEnrolled?.map((val11) => {
                    return (
                      <tbody style={{ border: "2px solid" }}>
                        <tr>
                          <td rowspan="3">{val11?.Course}</td>

                          <td> Male </td>
                          <td>{val11?.CollegeStateM}</td>
                          <td>{val11?.OtherStateM}</td>
                          <td>{val11?.NRIstudentM}</td>
                          <td>{val11?.ForeignStudentM}</td>
                          <td>
                            {Number(val11?.CollegeStateM) +
                              Number(val11?.OtherStateM) +
                              Number(val11?.NRIstudentM) +
                              Number(val11?.ForeignStudentM)}
                          </td>
                        </tr>
                        <tr>
                          <td> Female </td>
                          <td>{val11?.CollegeStateF}</td>
                          <td>{val11?.OtherStateF}</td>
                          <td>{val11?.NRIstudentF}</td>
                          <td>{val11?.ForeignStudentF}</td>
                          <td>
                            {" "}
                            {Number(val11?.CollegeStateF) +
                              Number(val11?.OtherStateF) +
                              Number(val11?.NRIstudentF) +
                              Number(val11?.ForeignStudentF)}
                          </td>
                        </tr>
                        <tr>
                          <td> Other </td>
                          <td>{val11?.CollegeStateO}</td>
                          <td>{val11?.OtherStateO}</td>
                          <td>{val11?.NRIstudentO}</td>
                          <td>{val11?.ForeignStudentO}</td>
                          <td>
                            {" "}
                            {Number(val11?.CollegeStateO) +
                              Number(val11?.OtherStateO) +
                              Number(val11?.NRIstudentO) +
                              Number(val11?.ForeignStudentO)}
                          </td>
                        </tr>
                        {/* </tbody>
                        <tbody style={{ border: "2px solid" }}> */}
                        <tr>
                          <td rowspan="3">Certificate / Awareness</td>

                          <td> Male </td>
                          <td>{val11?.CFCollegeStateM}</td>
                          <td>{val11?.CFOtherStateM}</td>
                          <td>{val11?.CFNRIstudentM}</td>
                          <td>{val11?.CFForeignStudentM}</td>
                          <td>
                            {" "}
                            {Number(val11?.CFCollegeStateM) +
                              Number(val11?.CFOtherStateM) +
                              Number(val11?.CFNRIstudentM) +
                              Number(val11?.CFForeignStudentM)}
                          </td>
                        </tr>
                        <tr>
                          <td> Female </td>
                          <td>{val11?.CFCollegeStateF}</td>
                          <td>{val11?.CFOtherStateF}</td>
                          <td>{val11?.CFNRIstudentF}</td>
                          <td>{val11?.CFForeignStudentF}</td>
                          <td>
                            {" "}
                            {Number(val11?.CFCollegeStateF) +
                              Number(val11?.CFOtherStateF) +
                              Number(val11?.CFNRIstudentF) +
                              Number(val11?.CFForeignStudentF)}
                          </td>
                        </tr>
                        <tr>
                          <td> Other </td>
                          <td>{val11?.CFCollegeStateO}</td>
                          <td>{val11?.CFOtherStateO}</td>
                          <td>{val11?.CFNRIstudentO}</td>
                          <td>{val11?.CFForeignStudentO}</td>
                          <td>
                            {" "}
                            {Number(val11?.CFCollegeStateO) +
                              Number(val11?.CFOtherStateO) +
                              Number(val11?.CFNRIstudentO) +
                              Number(val11?.CFForeignStudentO)}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
              </div>
              <div
                className=" mt-2 d-flex"
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Table striped>
                  <thead>
                    <th colspan="6">
                      Provide the Following Details of Students admitted to the
                      College During the last four Academic Years
                    </th>
                  </thead>
                  <thead>
                    <tr style={{ backgroundColor: "white", color: "black" }}>
                      <th colspan="2">Programme</th>
                      <th>Year 1 </th>
                      <th>Year 2</th>
                      <th>Year 3</th>
                      <th>Year 4</th>
                    </tr>
                  </thead>
                  <tbody className="mt-1" style={{ border: "2px solid" }}>
                    <tr>
                      <td rowspan="3">SC</td>

                      <td> Male </td>
                      <td>{reportDetails?.scMaleYear1}</td>
                      <td>{reportDetails?.scMaleYear2}</td>
                      <td>{reportDetails?.scMaleYear3}</td>
                      <td>{reportDetails?.scMaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Female </td>
                      <td>{reportDetails?.scFemaleYear1}</td>
                      <td>{reportDetails?.scFemaleYear2}</td>
                      <td>{reportDetails?.scFemaleYear3}</td>
                      <td>{reportDetails?.scFemaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Other </td>
                      <td>{reportDetails?.scOtherYear1}</td>
                      <td>{reportDetails?.scOtherYear2}</td>
                      <td>{reportDetails?.scOtherYear3}</td>
                      <td>{reportDetails?.scOtherYear4}</td>
                    </tr>
                  </tbody>
                  <tbody className="mt-1" style={{ border: "2px solid" }}>
                    <tr>
                      <td rowspan="3">ST</td>

                      <td> Male </td>
                      <td>{reportDetails?.stMaleYear1}</td>
                      <td>{reportDetails?.stMaleYear2}</td>
                      <td>{reportDetails?.stMaleYear3}</td>
                      <td>{reportDetails?.stMaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Female </td>
                      <td>{reportDetails?.stFemaleYear1}</td>
                      <td>{reportDetails?.stFemaleYear2}</td>
                      <td>{reportDetails?.stFemaleYear3}</td>
                      <td>{reportDetails?.stFemaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Other </td>
                      <td>{reportDetails?.stOtherYear1}</td>
                      <td>{reportDetails?.stOtherYear2}</td>
                      <td>{reportDetails?.stOtherYear3}</td>
                      <td>{reportDetails?.stOtherYear4}</td>
                    </tr>
                  </tbody>
                  <tbody className="mt-1" style={{ border: "2px solid" }}>
                    <tr>
                      <td rowspan="3">OBC</td>

                      <td> Male </td>
                      <td>{reportDetails?.obcMaleYear1}</td>
                      <td>{reportDetails?.obcMaleYear2}</td>
                      <td>{reportDetails?.obcMaleYear3}</td>
                      <td>{reportDetails?.obcMaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Female </td>
                      <td>{reportDetails?.obcFemaleYear1}</td>
                      <td>{reportDetails?.obcFemaleYear2}</td>
                      <td>{reportDetails?.obcFemaleYear3}</td>
                      <td>{reportDetails?.obcFemaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Other </td>
                      <td>{reportDetails?.obcOtherYear1}</td>
                      <td>{reportDetails?.obcOtherYear2}</td>
                      <td>{reportDetails?.obcOtherYear3}</td>
                      <td>{reportDetails?.obcOtherYear4}</td>
                    </tr>
                  </tbody>
                  <tbody className="mt-1" style={{ border: "2px solid" }}>
                    <tr>
                      <td rowspan="3">General</td>

                      <td> Male </td>
                      <td> {reportDetails?.genMaleYear1}</td>
                      <td>{reportDetails?.genMaleYear2}</td>
                      <td>{reportDetails?.genMaleYear3}</td>
                      <td>{reportDetails?.genMaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Female </td>
                      <td>{reportDetails?.genFemaleYear1}</td>
                      <td>{reportDetails?.genFemaleYear2}</td>
                      <td>{reportDetails?.genFemaleYear3}</td>
                      <td>{reportDetails?.genFemaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Other </td>
                      <td>{reportDetails?.genOtherYear1}</td>
                      <td>{reportDetails?.genOtherYear2}</td>
                      <td>{reportDetails?.genOtherYear3}</td>
                      <td>{reportDetails?.genOtherYear}</td>
                    </tr>
                  </tbody>
                  <tbody className="mt-1" style={{ border: "2px solid" }}>
                    <tr>
                      <td rowspan="3">Other</td>

                      <td> Male </td>
                      <td> {reportDetails?.otherMaleYear1}</td>
                      <td>{reportDetails?.otherMaleYear2}</td>
                      <td>{reportDetails?.otherMaleYear3}</td>
                      <td>{reportDetails?.otherMaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Female </td>
                      <td>{reportDetails?.otherFemaleYear1}</td>
                      <td>{reportDetails?.otherFemaleYear2}</td>
                      <td>{reportDetails?.otherFemaleYear3}</td>
                      <td>{reportDetails?.otherFemaleYear4}</td>
                    </tr>
                    <tr>
                      <td> Other </td>
                      <td>{reportDetails?.otherOtherYear1}</td>
                      <td>{reportDetails?.otherOtherYear1}</td>
                      <td>{reportDetails?.otherOtherYear1}</td>
                      <td>{reportDetails?.otherOtherYear1}</td>
                    </tr>
                  </tbody>
                  <tbody className="mt-1" style={{ border: "2px solid" }}>
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td> 12 </td>
                      <td>12</td>
                      <td>12</td>
                      <td>23</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div
                className=" mt-3 d-flex justify-content-start"
                style={{ borderBottom: "1px solid" }}
              >
                <h5 className="mt-4">
                  <b>Extended Profile</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>1 Program</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>1.1</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>
                    Number of courses offered by the Institution across all
                    programs during the last five years
                  </b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <tbody style={{ border: "2px solid black" }}>
                    <tr>
                      <td>{reportDetails?.ep1Year1}</td>
                      <td>{reportDetails?.ep1Year2}</td>
                      <td>{reportDetails?.ep1Year3}</td>
                      <td>{reportDetails?.ep1Year4}</td>
                      <td>{reportDetails?.ep1Year5}</td>
                    </tr>
                    <tr>
                      <td>{reportDetails?.ep1Year1Val}</td>
                      <td>{reportDetails?.ep1Year2Val}</td>
                      <td>{reportDetails?.ep1Year3Val}</td>
                      <td>{reportDetails?.ep1Year4Val}</td>
                      <td>{reportDetails?.ep1Year5Val}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <thead>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{reportDetails?.fileDesc11}</td>
                      <td>
                        <a
                          href={`https://brightnaac.co.in/SSR/${reportDetails?.Doc11}`}
                          target="blank_"
                        >
                          View Document
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>1.2</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>
                    Number of programs offered year-wise for last five years
                  </b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <tbody style={{ border: "2px solid black" }}>
                    <tr>
                      <td>{reportDetails?.ep12Year1}</td>
                      <td>{reportDetails?.ep12Year2}</td>
                      <td>{reportDetails?.ep12Year3}</td>
                      <td>{reportDetails?.ep12Year4}</td>
                      <td>{reportDetails?.ep12Year5}</td>
                    </tr>
                    <tr>
                      <td>{reportDetails?.ep12Year1Val}</td>
                      <td>{reportDetails?.ep12Year2Val}</td>
                      <td>{reportDetails?.ep12Year3Val}</td>
                      <td>{reportDetails?.ep12Year4Val}</td>
                      <td>{reportDetails?.ep12Year5Val}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>2 Students</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>2.1</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>Number of students year-wise during last five years</b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <tbody style={{ border: "2px solid black" }}>
                    <tr>
                      <td> {reportDetails?.ep21Year1}</td>
                      <td> {reportDetails?.ep21Year2}</td>
                      <td> {reportDetails?.ep21Year3}</td>
                      <td> {reportDetails?.ep21Year4}</td>
                      <td> {reportDetails?.ep21Year5}</td>
                    </tr>
                    <tr>
                      <td>{reportDetails?.ep21Year1Val}</td>
                      <td>{reportDetails?.ep21Year2Val}</td>
                      <td>{reportDetails?.ep21Year3Val}</td>
                      <td>{reportDetails?.ep21Year4Val}</td>
                      <td>{reportDetails?.ep21Year5Val}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <thead>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{reportDetails?.fileDesc21}</td>
                      <td>
                        <a
                          href={`https://brightnaac.co.in/SSR/${reportDetails?.Doc21}`}
                          target="blank_"
                        >
                          View Document
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>2.2</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>
                    Number of seats earmarked for reserved category as per
                    GOI/State Govt rule year-wise during last five years
                  </b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <tbody style={{ border: "2px solid black" }}>
                    <tr>
                      <td>{reportDetails?.ep22Year1}</td>
                      <td>{reportDetails?.ep22Year2}</td>
                      <td>{reportDetails?.ep22Year3}</td>
                      <td>{reportDetails?.ep22Year4}</td>
                      <td>{reportDetails?.ep22Year5}</td>
                    </tr>
                    <tr>
                      <td>{reportDetails?.ep22Year1Val}</td>
                      <td>{reportDetails?.ep22Year2Val}</td>
                      <td>{reportDetails?.ep22Year3Val}</td>
                      <td>{reportDetails?.ep22Year4Val}</td>
                      <td>{reportDetails?.ep22Year5Val}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <thead>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{reportDetails?.fileDesc22}</td>
                      <td>
                        <a
                          href={`https://brightnaac.co.in/SSR/${reportDetails?.Doc22}`}
                          target="blank_"
                        >
                          View Document
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>2.3</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>
                    Number of outgoing / final year students year-wise during
                    last five years
                  </b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <tbody style={{ border: "2px solid black" }}>
                    <tr>
                      <td>{reportDetails?.ep23Year1}</td>
                      <td>{reportDetails?.ep23Year2}</td>
                      <td>{reportDetails?.ep23Year3}</td>
                      <td>{reportDetails?.ep23Year4}</td>
                      <td>{reportDetails?.ep23Year5}</td>
                    </tr>
                    <tr>
                      <td>{reportDetails?.ep23Year1Val}</td>
                      <td>{reportDetails?.ep23Year2Val}</td>
                      <td>{reportDetails?.ep23Year3Val}</td>
                      <td>{reportDetails?.ep23Year4Val}</td>
                      <td>{reportDetails?.ep23Year5Val}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <thead>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{reportDetails?.fileDesc23}</td>
                      <td>
                        <a
                          href={`https://brightnaac.co.in/SSR/${reportDetails?.Doc23}`}
                          target="blank_"
                        >
                          View Document
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>3 Teachers</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>3.1</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>
                    Number of full time teachers year-wise during the last five
                    years
                  </b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <tbody style={{ border: "2px solid black" }}>
                    <tr>
                      <td>{reportDetails?.ep31Year1}</td>
                      <td>{reportDetails?.ep31Year2}</td>
                      <td>{reportDetails?.ep31Year3}</td>
                      <td>{reportDetails?.ep31Year4}</td>
                      <td>{reportDetails?.ep31Year5}</td>
                    </tr>
                    <tr>
                      <td>{reportDetails?.ep31Year1Val}</td>
                      <td>{reportDetails?.ep31Year2Val}</td>
                      <td>{reportDetails?.ep31Year3Val}</td>
                      <td>{reportDetails?.ep31Year4Val}</td>
                      <td>{reportDetails?.ep31Year5Val}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <thead>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{reportDetails?.fileDesc31}</td>
                      <td>
                        <a
                          href={`https://brightnaac.co.in/SSR/${reportDetails?.Doc31}`}
                          target="blank_"
                        >
                          View Document
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>3.2</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>
                    Number of sanctioned posts year-wise during last five years
                  </b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <tbody style={{ border: "2px solid black" }}>
                    <tr>
                      <td>{reportDetails?.ep32Year1}</td>
                      <td>{reportDetails?.ep32Year2}</td>
                      <td>{reportDetails?.ep32Year3}</td>
                      <td>{reportDetails?.ep32Year4}</td>
                      <td>{reportDetails?.ep32Year5}</td>
                    </tr>
                    <tr>
                      <td>{reportDetails?.ep32Year1Val}</td>
                      <td>{reportDetails?.ep32Year2Val}</td>
                      <td>{reportDetails?.ep32Year3Val}</td>
                      <td>{reportDetails?.ep32Year4Val}</td>
                      <td>{reportDetails?.ep32Year5Val}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <thead>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{reportDetails?.fileDesc32}</td>
                      <td>
                        <a
                          href={`https://brightnaac.co.in/SSR/${reportDetails?.Doc32}`}
                          target="blank_"
                        >
                          View Document
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>4 Institution</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>4.1</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>Total number of classrooms and seminar halls</b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>Response: {reportDetails?.res41}</b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>4.2</b>
                </h6>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h6>
                  <b>
                    Total Expenditure excluding salary year-wise during last
                    five years ( INR in Lakhs)
                  </b>
                </h6>
              </div>

              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <tbody style={{ border: "2px solid black" }}>
                    <tr>
                      <td>{reportDetails?.ep42Year1}</td>
                      <td>{reportDetails?.ep42Year2}</td>
                      <td>{reportDetails?.ep42Year3}</td>
                      <td>{reportDetails?.ep42Year4}</td>
                      <td>{reportDetails?.ep42Year5}</td>
                    </tr>
                    <tr>
                      <td>{reportDetails?.ep42Year1Val}</td>
                      <td>{reportDetails?.ep42Year2Val}</td>
                      <td>{reportDetails?.ep42Year3Val}</td>
                      <td>{reportDetails?.ep42Year4Val}</td>
                      <td>{reportDetails?.ep42Year5Val}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>4.3</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>Number of Computers</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>Response: {reportDetails?.res43}</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>4.4</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>
                    Total number of computers in the campus for academic purpose
                  </b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>Response: {reportDetails?.res44}</b>
                </h5>
              </div>

              <div
                className=" mt-3 d-flex justify-content-start"
                style={{ borderBottom: "1px solid" }}
              >
                <h5 className="mt-4">
                  <b>4. Quality Indicator Framework(QIF)</b>
                </h5>
              </div>
              <div className="" style={{ textAlign: "justify" }}>
                <div className="row align-items-center"></div>
                {arrOfYears?.map((val, i) => {
                  return (
                    <div key={i}>
                      <div>
                        <div
                          style={{
                            color: "red",
                            textAlign: "center",
                            margin: "5px",
                          }}
                        >
                          <b>
                            UG {Number(val)}-{Number(val) + 1}{" "}
                          </b>
                        </div>
                        <UGC1aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                        <UGC2aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                        <UGC3aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                        <UGC4aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                        <UGC5aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                        <UGC6aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                        <UGC7aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                      </div>
                      <div>
                        <div
                          style={{
                            color: "red",
                            textAlign: "center",
                            margin: "5px",
                          }}
                        >
                          <b>
                            PG {Number(val)}-{Number(val) + 1}
                          </b>
                        </div>
                        <PGC1aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                        <PGC2aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                        <PGC3aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                        <PGC4aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />{" "}
                        <PGC5aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />{" "}
                        <PGC6aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />{" "}
                        <PGC7aqar
                          startYeardata={Number(val)}
                          endYeardata={Number(val) + 1}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className=" mt-3 d-flex justify-content-start"
                style={{ borderBottom: "1px solid" }}
              >
                <h5 className="mt-4">
                  <b>5. CONCLUSION</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>Additional Information :</b>
                </h5>
              </div>

              <div className=" d-flex ">
                <p>{parseHTML(`<div>${reportDetails?.AddInfoCon5}</div>`)}</p>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>Concluding Remarks :</b>
                </h5>
              </div>

              <div className="  d-flex ">
                <p>{parseHTML(`<div>${reportDetails?.remarkCon5}</div>`)}</p>
              </div>

              <div
                className=" mt-3 d-flex justify-content-start"
                style={{ borderBottom: "1px solid" }}
              >
                <h5 className="mt-4">
                  <b>6.ANNEXURE</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <h5>
                  <b>1.Metrics Level Deviations</b>
                </h5>
              </div>
              <div className=" mt-2 d-flex justify-content-start">
                <Table>
                  <thead>
                    <tr>
                      <th>Metric ID</th>
                      <th>
                        Sub Questions and Answers before and after DVV
                        Verification
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportDetails?.AnnuxtureArr?.map((val22) => {
                      return (
                        <tr>
                          <td>{val22?.IndexId}</td>
                          <td>
                            <div className=" mt-3 d-flex ">
                              <p>{parseHTML(`<div>${val22?.ANXDesc}</div>`)}</p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="text-center">
        <button className="ad" style={{}} onClick={() => handlePrint()}>
          Download
        </button>
      </div>
    </>
  );
}

export default SSRViewReport;
