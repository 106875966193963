import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Uni_Criteria003 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);
  const [edit8, setedit8] = useState(false);
  const [add8, setadd8] = useState(true);
  const [edit9, setedit9] = useState(false);
  const [add9, setadd9] = useState(true);
  const [edit10, setedit10] = useState(false);
  const [add10, setadd10] = useState(true);
  const [edit11, setedit11] = useState(false);
  const [add11, setadd11] = useState(true);
  const [edit12, setedit12] = useState(false);
  const [add12, setadd12] = useState(true);
  const [edit13, setedit13] = useState(false);
  const [add13, setadd13] = useState(true);
  const [edit14, setedit14] = useState(false);
  const [add14, setadd14] = useState(true);
  const [edit15, setedit15] = useState(false);
  const [add15, setadd15] = useState(true);
  const [edit16, setedit16] = useState(false);
  const [add16, setadd16] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  const [show11, setShow11] = useState(false);
  const [show12, setShow12] = useState(false);
  const [show13, setShow13] = useState(false);
  const [show14, setShow14] = useState(false);
  const [show15, setShow15] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  //post method for Uni_criteria_03
  const [Uni_Year, setUni_Year] = useState("");

  const [Uni_Seed, setUni_Seed] = useState("");
  const [Uni_SeedMoney, setUni_SeedMoney] = useState("");
  const [Uni_Receiving, setUni_Receiving] = useState(""); // 3.1.2
  const [Uni_Desc5, setUni_Desc5] = useState("");
  const [Uni_Link5, setUni_Link5] = useState("");

  const [Uni_FellowShip, setUni_FellowShip] = useState("");
  const [Uni_AFellowship, setUni_AFellowship] = useState("");
  const [Uni_Award, setUni_Award] = useState(""); // 3.1.3
  const [Uni_Agency, setUni_Agency] = useState("");
  const [Uni_Desc6, setUni_Desc6] = useState("");
  const [Uni_Link6, setUni_Link6] = useState("");

  const [Uni_Sno, setUni_Sno] = useState("");
  const [Uni_Research, setUni_Research] = useState("");
  const [Uni_Enroll, setUni_Enroll] = useState(""); // 3.1.4
  const [Uni_Fellow, setUni_Fellow] = useState("");
  const [Uni_Fellow1, setUni_Fellow1] = useState("");
  const [Uni_Grant, setUni_Grant] = useState("");
  const [Uni_Qualify, setUni_Qualify] = useState("");
  const [Uni_Desc23, setUni_Desc23] = useState("");
  const [Uni_Link23, setUni_Link23] = useState("");

  const [Uni_Endowments, setUni_Endowments] = useState("");
  const [Uni_Invest, setUni_Invest] = useState("");
  const [Uni_FAgency, setUni_FAgency] = useState(""); //  3.1.6
  const [Uni_Type, setUni_Type] = useState("");
  const [Uni_Dep, setUni_Dep] = useState("");
  const [Uni_Award1, setUni_Award1] = useState("");
  const [Uni_Funds, setUni_Funds] = useState("");
  const [Uni_Project, setUni_Project] = useState("");
  const [Uni_Desc7, setUni_Desc7] = useState("");
  const [Uni_Link7, setUni_Link7] = useState("");

  const [Uni_Seminar, setUni_Seminar] = useState("");
  const [Uni_Parti, setUni_Parti] = useState("");
  const [Uni_DFrom, setUni_DFrom] = useState("");
  const [Uni_DTo, setUni_DTo] = useState(""); //  3.3.2
  const [Uni_Desc8, setUni_Desc8] = useState("");
  const [Uni_Link8, setUni_Link8] = useState("");

  const [Uni_YAw, setUni_YAw] = useState("");
  const [Uni_Innova, setUni_Innova] = useState("");
  const [Uni_Awardee, setUni_Awardee] = useState("");
  const [Uni_Details, setUni_Details] = useState("");
  const [Uni_Category, setUni_Category] = useState("");
  const [Uni_Desc17, setUni_Desc17] = useState(""); //  3.3.3
  const [Uni_Link17, setUni_Link17] = useState("");

  const [Uni_Teacher, setUni_Teacher] = useState("");
  const [Uni_Patent, setUni_Patent] = useState("");
  const [Uni_Title, setUni_Title] = useState("");
  const [Uni_YOAw, setUni_YOAw] = useState(""); //  3.4.3
  const [Uni_Desc18, setUni_Desc18] = useState("");
  const [Uni_Link18, setUni_Link18] = useState("");

  const [Uni_Phd, setUni_Phd] = useState("");
  const [Uni_Depar, setUni_Depar] = useState("");
  const [Uni_Guide, setUni_Guide] = useState(""); //  3.4.4
  const [Uni_Thesis1, setUni_Thesis1] = useState("");
  const [Uni_Scholar, setUni_Scholar] = useState("");
  const [Uni_Phd1, setUni_Phd1] = useState("");
  const [Uni_Whether1, setUni_Whether1] = useState("");
  const [Uni_Recog, setUni_Recog] = useState("");
  const [Uni_Desc19, setUni_Desc19] = useState("");
  const [Uni_Link19, setUni_Link19] = useState("");

  const [Uni_Paper, setUni_Paper] = useState("");
  const [Uni_Author, setUni_Author] = useState("");
  const [Uni_Dept, setUni_Dept] = useState("");
  const [Uni_Journal, setUni_Journal] = useState("");
  const [Uni_Pub, setUni_Pub] = useState("");
  const [Uni_ISSN, setUni_ISSN] = useState(""); //  3.4.5
  const [Uni_ISIT, setUni_ISIT] = useState("");
  const [Uni_Desc9, setUni_Desc9] = useState("");
  const [Uni_Link9, setUni_Link9] = useState("");
  const [Uni_Link10, setUni_Link10] = useState("");

  const [Uni_SLno, setUni_SLno] = useState("");
  const [Uni_Name, setUni_Name] = useState("");
  const [Uni_Book, setUni_Book] = useState("");
  const [Uni_Paper1, setUni_Paper1] = useState("");
  const [Uni_Title4, setUni_Title4] = useState("");
  const [Uni_Year2, setUni_Year2] = useState(""); //3.4.6
  const [Uni_ISBN, setUni_ISBN] = useState("");
  const [Uni_Whether, setUni_Whether] = useState("");
  const [Uni_Name1, setUni_Name1] = useState("");
  const [Uni_Desc10, setUni_Desc10] = useState("");
  const [Uni_Link11, setUni_Link11] = useState("");

  const [Uni_Teacher1, setUni_Teacher1] = useState("");
  const [Uni_Module, setUni_Module] = useState("");
  const [Uni_Platform, setUni_Platform] = useState("");
  const [Uni_Launch, setUni_Launch] = useState(""); //3.4.7
  const [Uni_List1, setUni_List1] = useState("");
  const [Uni_Desc20, setUni_Desc20] = useState("");
  const [Uni_Link20, setUni_Link20] = useState("");
  const [Uni_Link21, setUni_Link21] = useState("");

  const [Uni_Consult, setUni_Consult] = useState("");
  const [Uni_Project1, setUni_Project1] = useState("");
  const [Uni_Agency1, setUni_Agency1] = useState("");
  const [Uni_Revenue, setUni_Revenue] = useState("");
  const [Uni_Consult2, setUni_Consult2] = useState("");
  const [Uni_Title1, setUni_Title1] = useState("");
  const [Uni_Revenue1, setUni_Revenue1] = useState(""); // 3.5.1
  const [Uni_Agency3, setUni_Agency3] = useState("");
  const [Uni_Trainee, setUni_Trainee] = useState("");
  const [Uni_Desc21, setUni_Desc21] = useState("");
  const [Uni_Link22, setUni_Link22] = useState("");

  const [Uni_Name2, setUni_Name2] = useState("");
  const [Uni_Name3, setUni_Name3] = useState("");
  const [Uni_Name4, setUni_Name4] = useState(""); //  3.6.2
  const [Uni_Year4, setUni_Year4] = useState("");
  const [Uni_Desc12, setUni_Desc12] = useState("");
  const [Uni_Link13, setUni_Link13] = useState("");

  const [Uni_Name5, setUni_Name5] = useState("");
  const [Uni_org, setUni_org] = useState("");
  const [Uni_Scheme, setUni_Scheme] = useState("");
  const [Uni_Year5, setUni_Year5] = useState("");
  const [Uni_Participants1, setUni_Participants1] = useState("");
  const [Uni_Desc13, setUni_Desc13] = useState(""); // 3.6.3
  const [Uni_Link14, setUni_Link14] = useState("");

  const [Uni_SLNO1, setUni_SLNO1] = useState("");
  const [Uni_Colloborative, setUni_Colloborative] = useState("");
  const [Uni_Agency2, setUni_Agency2] = useState("");
  const [Uni_Participants3, setUni_Participants3] = useState("");
  const [Uni_Collo, setUni_Collo] = useState("");
  const [Uni_Durat, setUni_Durat] = useState(""); // 3.7.1
  const [Uni_Activity, setUni_Activity] = useState("");
  const [Uni_Desc14, setUni_Desc14] = useState("");
  const [Uni_Link15, setUni_Link15] = useState("");

  const [Uni_Organ, setUni_Organ] = useState("");
  const [Uni_IIC, setUni_IIC] = useState("");
  const [Uni_Signing, setUni_Signing] = useState(""); // 3.7.2
  const [Uni_Durat1, setUni_Durat1] = useState("");
  const [Uni_List, setUni_List] = useState("");
  const [Uni_Desc15, setUni_Desc15] = useState("");
  const [Uni_Link16, setUni_Link16] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});
  const [obj9, setobj9] = useState({});
  const [obj10, setobj10] = useState({});
  const [obj11, setobj11] = useState({});
  const [obj12, setobj12] = useState({});
  const [obj13, setobj13] = useState({});
  const [obj14, setobj14] = useState({});
  const [obj15, setobj15] = useState({});
  const [obj16, setobj16] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getunicriteria3 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));

      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));

      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));

      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));

      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));

      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));

      let am8 = sessionStorage.getItem("cat8");
      setobj8(JSON.parse(am8));

      let am9 = sessionStorage.getItem("cat9");
      setobj9(JSON.parse(am9));

      let am10 = sessionStorage.getItem("cat10");
      setobj10(JSON.parse(am10));

      let am11 = sessionStorage.getItem("cat11");
      setobj11(JSON.parse(am11));

      let am12 = sessionStorage.getItem("cat12");
      setobj12(JSON.parse(am12));

      let am13 = sessionStorage.getItem("cat13");
      setobj13(JSON.parse(am13));

      let am14 = sessionStorage.getItem("cat14");
      setobj14(JSON.parse(am14));

      let am15 = sessionStorage.getItem("cat15");
      setobj15(JSON.parse(am15));

      let am16 = sessionStorage.getItem("cat16");
      setobj16(JSON.parse(am16));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getunicriteria3();
  }, []);

  const unicriteria3 = async () => {
    if (!Uni_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/uniadddetails03",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Year: Uni_Year,

          Uni_Criteria03_312: obj1,
          Uni_Link5: Uni_Link5,

          Uni_Criteria03_313: obj2,
          Uni_Link6: Uni_Link6,

          Uni_Criteria03_314: obj3,
          Uni_Link23: Uni_Link23,

          Uni_Criteria03_316: obj4,
          Uni_Link7: Uni_Link7,

          Uni_Criteria03_332: obj5,
          Uni_Link8: Uni_Link8,

          Uni_Criteria03_333: obj6,
          Uni_Link17: Uni_Link17,

          Uni_Criteria03_343: obj7,
          Uni_Link18: Uni_Link18,

          Uni_Criteria03_344: obj8,
          Uni_Link19: Uni_Link19,

          Uni_Criteria03_345: obj9,
          Uni_Link9: Uni_Link9,
          Uni_Link10: Uni_Link10,

          Uni_Criteria03_346: obj10,
          Uni_Link11: Uni_Link11,

          Uni_Criteria03_347: obj11,
          Uni_Link20: Uni_Link20,
          Uni_Link21: Uni_Link21,

          Uni_Criteria03_352: obj12,
          Uni_Link22: Uni_Link22,

          Uni_Criteria03_362: obj13,
          Uni_Link13: Uni_Link13,

          Uni_Criteria03_363: obj14,
          Uni_Link14: Uni_Link14,

          Uni_Criteria03_371: obj15,
          Uni_Link15: Uni_Link15,

          Uni_Criteria03_372: obj16,
          Uni_Link16: Uni_Link16,

          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          sessionStorage.removeItem("cat8");

          sessionStorage.removeItem("cat9");

          sessionStorage.removeItem("cat10");

          sessionStorage.removeItem("cat11");

          sessionStorage.removeItem("cat12");

          sessionStorage.removeItem("cat13");

          sessionStorage.removeItem("cat14");

          sessionStorage.removeItem("cat15");

          navigate("/unicriteria03");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Uni_Seed ||
          !Uni_SeedMoney ||
          !Uni_Receiving ||
          !Uni_Desc5 ||
          !Uni_Link5
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("aaaa,", Uni_Link5);
      let obj = {
        Uni_criteria: "3.1.2",
        Uni_Seed: Uni_Seed,
        Uni_SeedMoney: Uni_SeedMoney,
        Uni_Receiving: Uni_Receiving,
        Uni_Desc5: Uni_Desc5,
        Uni_Link5: Uni_Link5,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getunicriteria3();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !Uni_FellowShip ||
          !Uni_AFellowship ||
          !Uni_Award ||
          !Uni_Agency ||
          !Uni_Desc6 ||
          !Uni_Link6
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("bbb,", Uni_Link6);
      let obj = {
        Uni_criteria: "3.1.3",
        Uni_FellowShip: Uni_FellowShip,
        Uni_AFellowship: Uni_AFellowship,
        Uni_Award: Uni_Award,
        Uni_Agency: Uni_Agency,
        Uni_Desc6: Uni_Desc6,
        Uni_Link6: Uni_Link6,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getunicriteria3();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !Uni_Sno ||
          !Uni_Research ||
          !Uni_Enroll ||
          !Uni_Fellow ||
          !Uni_Fellow1 ||
          !Uni_Grant ||
          !Uni_Qualify ||
          !Uni_Desc23 ||
          !Uni_Link23
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("cccc,", Uni_Link23);
      let obj = {
        Uni_criteria: "3.1.4",
        Uni_Sno: Uni_Sno,
        Uni_Research: Uni_Research,
        Uni_Enroll: Uni_Enroll,
        Uni_Fellow: Uni_Fellow,
        Uni_Fellow1: Uni_Fellow1,
        Uni_Grant: Uni_Grant,
        Uni_Qualify: Uni_Qualify,
        Uni_Desc23: Uni_Desc23,
        Uni_Link23: Uni_Link23,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getunicriteria3();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc7;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Uni_Endowments ||
          !Uni_Invest ||
          !Uni_FAgency ||
          !Uni_Type ||
          !Uni_Dep ||
          !Uni_Award1 ||
          !Uni_Funds ||
          !Uni_Project ||
          !Uni_Desc7 ||
          !Uni_Link7
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("dddd,", Uni_Link7);
      let obj = {
        Uni_criteria: "3.1.6 & 3.2.1 & 3.2.2 & 3.2.4",
        Uni_Endowments: Uni_Endowments,
        Uni_Invest: Uni_Invest,
        Uni_FAgency: Uni_FAgency,
        Uni_Type: Uni_Type,
        Uni_Dep: Uni_Dep,
        Uni_Award1: Uni_Award1,
        Uni_Funds: Uni_Funds,
        Uni_Project: Uni_Project,
        Uni_Desc7: Uni_Desc7,
        Uni_Link7: Uni_Link7,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));

      alert("Successfully added cat4");
      getunicriteria3();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc8;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !Uni_Seminar ||
          !Uni_Parti ||
          !Uni_DFrom ||
          !Uni_DTo ||
          !Uni_Desc8 ||
          !Uni_Link8
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("eee,", Uni_Link8);
      let obj = {
        Uni_criteria: "3.3.2",
        Uni_Seminar: Uni_Seminar,
        Uni_Parti: Uni_Parti,
        Uni_DFrom: Uni_DFrom,
        Uni_DTo: Uni_DTo,
        Uni_Desc8: Uni_Desc8,
        Uni_Link8: Uni_Link8,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getunicriteria3();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc17;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !Uni_YAw ||
          !Uni_Innova ||
          !Uni_Awardee ||
          !Uni_Details ||
          !Uni_Category ||
          !Uni_Desc17 ||
          !Uni_Link17
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("fffff,", Uni_Link17);
      let obj = {
        Uni_criteria: "3.3.3",
        Uni_YAw: Uni_YAw,
        Uni_Innova: Uni_Innova,
        Uni_Awardee: Uni_Awardee,
        Uni_Details: Uni_Details,
        Uni_Category: Uni_Category,
        Uni_Desc17: Uni_Desc17,
        Uni_Link17: Uni_Link17,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getunicriteria3();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !Uni_Teacher ||
          !Uni_Patent ||
          !Uni_Title ||
          !Uni_YOAw ||
          !Uni_Desc18 ||
          !Uni_Link18
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd7(false);
        }
      }

      console.log("ggggg,", Uni_Link18);
      let obj = {
        Uni_criteria: "3.4.3",
        Uni_Teacher: Uni_Teacher,
        Uni_Patent: Uni_Patent,
        Uni_Title: Uni_Title,
        Uni_YOAw: Uni_YOAw,
        Uni_Desc18: Uni_Desc18,
        Uni_Link18: Uni_Link18,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getunicriteria3();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat8 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add8) {
        if (
          !Uni_Phd ||
          !Uni_Depar ||
          !Uni_Guide ||
          !Uni_Thesis1 ||
          !Uni_Scholar ||
          !Uni_Phd1 ||
          !Uni_Whether1 ||
          !Uni_Recog ||
          !Uni_Desc19 ||
          !Uni_Link19
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd8(false);
        }
      }

      console.log("hhhhh,", Uni_Link19);
      let obj = {
        Uni_criteria: "3.4.4",
        Uni_Phd: Uni_Phd,
        Uni_Depar: Uni_Depar,
        Uni_Guide: Uni_Guide,
        Uni_Thesis1: Uni_Thesis1,
        Uni_Scholar: Uni_Scholar,
        Uni_Phd1: Uni_Phd1,
        Uni_Whether1: Uni_Whether1,
        Uni_Recog: Uni_Recog,
        Uni_Desc19: Uni_Desc19,
        Uni_Link19: Uni_Link19,
      };
      sessionStorage.setItem("cat8", JSON.stringify(obj));
      alert("Successfully added cat8");
      getunicriteria3();
      setedit8(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat9 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc9;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add9) {
        if (
          !Uni_Paper ||
          !Uni_Author ||
          !Uni_Dept ||
          !Uni_Journal ||
          !Uni_Pub ||
          !Uni_ISSN ||
          !Uni_ISIT ||
          !Uni_Desc9 ||
          !Uni_Link9 ||
          !Uni_Link10
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd9(false);
        }
      }

      console.log("iiii,", Uni_Link9);
      console.log("jjjj,", Uni_Link10);
      let obj = {
        Uni_criteria: "3.4.5",
        Uni_Paper: Uni_Paper,
        Uni_Author: Uni_Author,
        Uni_Dept: Uni_Dept,
        Uni_Journal: Uni_Journal,
        Uni_Pub: Uni_Pub,
        Uni_ISSN: Uni_ISSN,
        Uni_ISIT: Uni_ISIT,
        Uni_Desc9: Uni_Desc9,
        Uni_Link9: Uni_Link9,
        Uni_Link10: Uni_Link10,
      };
      sessionStorage.setItem("cat9", JSON.stringify(obj));
      alert("Successfully added cat9");
      getunicriteria3();
      setedit9(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat10 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc10;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add10) {
        if (
          !Uni_SLno ||
          !Uni_Name ||
          !Uni_Book ||
          !Uni_Paper1 ||
          !Uni_Title4 ||
          !Uni_Year2 ||
          !Uni_ISBN ||
          !Uni_Whether ||
          !Uni_Name1 ||
          !Uni_Desc10 ||
          !Uni_Link11
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd10(false);
        }
      }

      console.log("kkkk,", Uni_Link11);
      let obj = {
        Uni_criteria: "3.4.6",
        Uni_SLno: Uni_SLno,
        Uni_Name: Uni_Name,
        Uni_Book: Uni_Book,
        Uni_Paper1: Uni_Paper1,
        Uni_Title4: Uni_Title4,
        Uni_Year2: Uni_Year2,
        Uni_ISBN: Uni_ISBN,
        Uni_Whether: Uni_Whether,
        Uni_Name1: Uni_Name1,
        Uni_Desc10: Uni_Desc10,
        Uni_Link11: Uni_Link11,
      };
      sessionStorage.setItem("cat10", JSON.stringify(obj));
      alert("Successfully added cat10");
      getunicriteria3();
      setedit10(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat11 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc20;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add11) {
        if (
          !Uni_Teacher1 ||
          !Uni_Module ||
          !Uni_Platform ||
          !Uni_Launch ||
          !Uni_List1 ||
          !Uni_Desc20 ||
          !Uni_Link20 ||
          !Uni_Link21
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd11(false);
        }
      }

      console.log("mmmm,", Uni_Link21);
      console.log("xxxx,", Uni_Link20);
      let obj = {
        Uni_criteria: "3.4.7",
        Uni_Teacher1: Uni_Teacher1,
        Uni_Module: Uni_Module,
        Uni_Platform: Uni_Platform,
        Uni_Launch: Uni_Launch,
        Uni_List1: Uni_List1,
        Uni_Desc20: Uni_Desc20,
        Uni_Link20: Uni_Link20,
        Uni_Link21: Uni_Link21,
      };
      sessionStorage.setItem("cat11", JSON.stringify(obj));
      alert("Successfully added cat11");
      getunicriteria3();
      setedit11(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat12 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add12) {
        if (
          !Uni_Consult ||
          !Uni_Project1 ||
          !Uni_Agency1 ||
          !Uni_Revenue ||
          !Uni_Consult2 ||
          !Uni_Title1 ||
          !Uni_Agency3 ||
          !Uni_Revenue1 ||
          !Uni_Trainee ||
          !Uni_Desc21 ||
          !Uni_Link22
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd12(false);
        }
      }

      console.log("nnnnn,", Uni_Link22);
      let obj = {
        Uni_criteria: "3.5.2 & 3.5.2.1",
        Uni_Consult: Uni_Consult,
        Uni_Project1: Uni_Project1,
        Uni_Agency1: Uni_Agency1,
        Uni_Revenue: Uni_Revenue,
        Uni_Consult2: Uni_Consult2,
        Uni_Title1: Uni_Title1,
        Uni_Agency3: Uni_Agency3,
        Uni_Revenue1: Uni_Revenue1,
        Uni_Trainee: Uni_Trainee,
        Uni_Desc21: Uni_Desc21,
        Uni_Link22: Uni_Link22,
      };
      sessionStorage.setItem("cat12", JSON.stringify(obj));
      alert("Successfully added cat12");
      getunicriteria3();
      setedit12(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat13 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc12;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add13) {
        if (
          !Uni_Name2 ||
          !Uni_Name3 ||
          !Uni_Name4 ||
          !Uni_Year4 ||
          !Uni_Desc12 ||
          !Uni_Link13
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd13(false);
        }
      }

      console.log("ooo,", Uni_Link13);
      let obj = {
        Uni_criteria: "3.6.2",
        Uni_Name2: Uni_Name2,
        Uni_Name3: Uni_Name3,
        Uni_Name4: Uni_Name4,
        Uni_Year4: Uni_Year4,
        Uni_Desc12: Uni_Desc12,
        Uni_Link13: Uni_Link13,
      };
      sessionStorage.setItem("cat13", JSON.stringify(obj));
      alert("Successfully added cat13");
      getunicriteria3();
      setedit13(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat14 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc13;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add14) {
        if (
          !Uni_Name5 ||
          !Uni_org ||
          !Uni_Scheme ||
          !Uni_Year5 ||
          !Uni_Participants1 ||
          !Uni_Desc13 ||
          !Uni_Link14
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd14(false);
        }
      }

      console.log("pppp,", Uni_Link14);
      let obj = {
        Uni_criteria: "3.6.3 & 3.6.4",
        Uni_Name5: Uni_Name5,
        Uni_org: Uni_org,
        Uni_Scheme: Uni_Scheme,
        Uni_Year5: Uni_Year5,
        Uni_Participants1: Uni_Participants1,
        Uni_Desc13: Uni_Desc13,
        Uni_Link14: Uni_Link14,
      };
      sessionStorage.setItem("cat14", JSON.stringify(obj));
      alert("Successfully added cat14");
      getunicriteria3();
      setedit14(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat15 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc14;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add15) {
        if (
          !Uni_SLNO1 ||
          !Uni_Colloborative ||
          !Uni_Agency2 ||
          !Uni_Participants3 ||
          !Uni_Collo ||
          !Uni_Durat ||
          !Uni_Activity ||
          !Uni_Desc14 ||
          !Uni_Link15
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd15(false);
        }
      }

      console.log("qqqqq,", Uni_Link15);
      let obj = {
        Uni_criteria: "3.7.1",
        Uni_SLNO1: Uni_SLNO1,
        Uni_Colloborative: Uni_Colloborative,
        Uni_Agency2: Uni_Agency2,
        Uni_Participants3: Uni_Participants3,
        Uni_Collo: Uni_Collo,
        Uni_Durat: Uni_Durat,
        Uni_Activity: Uni_Activity,
        Uni_Desc14: Uni_Desc14,
        Uni_Link15: Uni_Link15,
      };
      sessionStorage.setItem("cat15", JSON.stringify(obj));
      alert("Successfully added cat15");
      getunicriteria3();
      setedit15(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat16 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc15;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add15) {
        if (
          !Uni_Organ ||
          !Uni_IIC ||
          !Uni_Signing ||
          !Uni_Durat1 ||
          !Uni_List ||
          !Uni_Desc15 ||
          !Uni_Link16
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd16(false);
        }
      }

      console.log("rrrr,", Uni_Link16);
      let obj = {
        Uni_criteria: "3.7.2",
        Uni_Organ: Uni_Organ,
        Uni_IIC: Uni_IIC,
        Uni_Signing: Uni_Signing,
        Uni_Durat1: Uni_Durat1,
        Uni_List: Uni_List,
        Uni_Desc15: Uni_Desc15,
        Uni_Link16: Uni_Link16,
      };
      sessionStorage.setItem("cat16", JSON.stringify(obj));
      alert("Successfully added cat16");
      getunicriteria3();
      setedit15(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <h2
        className="text-center mt-5"
        style={{ fontWeight: "700", color: "#E42127" }}
      >
        University Criteria 03 Form
      </h2>
      <h6 className="text-center" style={{ color: "#E42127" }}>
        (Please fill all the details)
      </h6>
      <br />

      <div className="container">
        <div
          className="row-fluid border mb-3"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
          <div
            className="row-fluid border"
            style={{
              padding: "30px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <div class=" col-md-6">
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Uni_Year ? (
                <p>{obj1?.Uni_Year}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setUni_Year(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.1.2)</h5>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the teacher provided with seed money
                  </label>
                  {obj1?.Uni_Seed ? (
                    <p>{obj1?.Uni_Seed}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_Seed(e.target.value)}
                    />
                  )}
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    The amount of seed money (INR in Lakhs)
                  </label>
                  {obj1?.Uni_SeedMoney ? (
                    <p>{obj1?.Uni_SeedMoney}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_SeedMoney(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of receiving
                  </label>
                  {obj1?.Uni_Receiving ? (
                    <p>{obj1?.Uni_Receiving}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_Receiving(e.target.value)}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj1?.Uni_Desc5 ? (
                    <p>{obj1?.Uni_Desc5}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      // cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc5(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link5(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add1 ? (
                  <button
                    type="submit"
                    className="btn btn-success  mt-3 "
                    onClick={() => {
                      cat1();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_3 (3.1.2)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the teacher provided with seed money
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Seed}
                            onChange={(e) => setUni_Seed(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Seed}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        The amount of seed money (INR in Lakhs)
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_SeedMoney}
                            onChange={(e) => setUni_SeedMoney(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_SeedMoney}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of receiving
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Receiving}
                            onChange={(e) => setUni_Receiving(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Receiving}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc5}
                            onChange={(e) => setUni_Desc5(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc5}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="upload1">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="Year"
                            id="upload1"
                            accept="image/*"
                            onChange={(e) => setUni_Link5(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link5?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit1(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat1();
                      handleClose();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.1.3)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </label>
                  {obj2?.Uni_FellowShip ? (
                    <p>{obj2?.Uni_FellowShip}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_FellowShip(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/fellowship
                  </label>
                  {obj2?.Uni_AFellowship ? (
                    <p>{obj2?.Uni_AFellowship}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_AFellowship(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Award
                  </label>
                  {obj2?.Uni_Award ? (
                    <p>{obj2?.Uni_Award}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Award(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Awarding Agency
                  </label>
                  {obj2?.Uni_Agency ? (
                    <p>{obj2?.Uni_Agency}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Agency(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj2?.Uni_Desc6 ? (
                  <p>{obj2?.Uni_Desc6}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc6(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload2">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  name="upload2"
                  id="upload2"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link6(e.target.files[0]);
                  }}
                />
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add2 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat2();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow1}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria03_[3.1.3]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the teacher awarded national/ international
                        fellowship/financial support
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_FellowShip}
                            onChange={(e) => {
                              setUni_FellowShip(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_FellowShip}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the award/fellowship
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_AFellowship}
                            onChange={(e) => {
                              setUni_AFellowship(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_AFellowship}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of Award
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Award}
                            onChange={(e) => setUni_Award(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Award}</>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Awarding Agency
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_Agency}
                          onChange={(e) => setUni_Agency(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_Agency}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_Desc6}
                          onChange={(e) => setUni_Desc6(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_Desc6}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload2"
                          id="name"
                          onChange={(e) => setUni_Link6(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_Link6?.name}</>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit2(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat2();
                      handleClose1();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.1.4)</h5>
              <div className="row">
                <div className="col-md-2 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl.no.
                  </label>

                  {obj3?.Uni_Sno ? (
                    <p>{obj3?.Uni_Sno}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Sno(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-5 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Research fellow
                  </label>
                  {obj3?.Uni_Research ? (
                    <p>{obj3?.Uni_Research}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Research(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-5 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of enrolment
                  </label>
                  {obj3?.Uni_Enroll ? (
                    <p>{obj3?.Uni_Enroll}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Enroll(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Duration of fellowship</label>
                  {obj3?.Uni_Fellow ? (
                    <p>{obj3?.Uni_Fellow}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Fellow(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Type of the fellowship
                  </label>
                  {obj3?.Uni_Fellow1 ? (
                    <p>{obj3?.Uni_Fellow1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Fellow1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Granting agency
                  </label>
                  {obj3?.Uni_Grant ? (
                    <p>{obj3?.Uni_Grant}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Grant(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualifying exam if any (NET, GATE, etc.)
                  </label>
                  {obj3?.Uni_Qualify ? (
                    <p>{obj3?.Uni_Qualify}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Qualify(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj3?.Uni_Desc23 ? (
                    <p>{obj3?.Uni_Desc23}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc23(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload3"
                    id="upload3"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link23(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add3 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat3();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow2}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.1.4]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-2 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Sl.no.
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Sno}
                            onChange={(e) => {
                              setUni_Sno(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Sno}</>
                      )}
                    </div>
                    <div className="col-md-5 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Research fellow
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Research}
                            onChange={(e) => {
                              setUni_Research(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Research}</>
                      )}
                    </div>
                    <div className="col-md-5 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of enrolment
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Enroll}
                            onChange={(e) => {
                              setUni_Enroll(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Enroll}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Duration of fellowship
                      </label>
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Fellow}
                            onChange={(e) => {
                              setUni_Fellow(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Fellow}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Type of the fellowship
                      </label>
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Fellow1}
                            onChange={(e) => {
                              setUni_Fellow1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Fellow1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Granting agency
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Grant}
                            onChange={(e) => {
                              setUni_Grant(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Grant}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Qualifying exam if any (NET, GATE, etc.)
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Qualify}
                            onChange={(e) => {
                              setUni_Qualify(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Qualify}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc23}
                            onChange={(e) => setUni_Desc23(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc23}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload3"
                            id="upload3 "
                            onChange={(e) => setUni_Link23(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link23.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit3(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat3();
                      handleClose2();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.1.6 & 3.2.1 & 3.2.2 & 3.2.4)</h5>
              <h6 className="text-center text-decoration-underline">
                Average enrolement percentage(Average of last five years)
              </h6>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Scheme/Project/ Endowments/ Chairs
                  </label>

                  {obj4?.Uni_Endowments ? (
                    <p>{obj4?.Uni_Endowments}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Endowments(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </label>
                  {obj4?.Uni_Invest ? (
                    <p>{obj4?.Uni_Invest}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Invest(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Funding agency
                  </label>
                  {obj4?.Uni_FAgency ? (
                    <p>{obj4?.Uni_FAgency}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_FAgency(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1">
                    {" "}
                    Type (Government/Non-Government)
                  </label>
                  {obj4?.Uni_Type ? (
                    <p>{obj4?.Uni_Type}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Type(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of Principal Investigator/ Co Investigator
                  </label>
                  {obj4?.Uni_Dep ? (
                    <p>{obj4?.Uni_Dep}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Dep(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award
                  </label>
                  {obj4?.Uni_Award1 ? (
                    <p>{obj4?.Uni_Award1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Award1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Funds provided (INR in lakhs)
                  </label>
                  {obj4?.Uni_Funds ? (
                    <p>{obj4?.Uni_Funds}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Funds(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of the project
                  </label>
                  {obj4?.Uni_Project ? (
                    <p>{obj4?.Uni_Project}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Project(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj4?.Uni_Desc7 ? (
                    <p>{obj4?.Uni_Desc7}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc7(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload4"
                    id="upload4"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link7(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add4 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat4();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow3}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.1.6 & 3.2.1 & 3.2.2 & 3.2.4]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Scheme/Project/ Endowments/ Chairs
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Endowments}
                            onChange={(e) => {
                              setUni_Endowments(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Endowments}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Principal Investigator/ Co Investigator (if
                        applicable)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Invest}
                            onChange={(e) => {
                              setUni_Invest(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Invest}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Funding agency
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_FAgency}
                            onChange={(e) => {
                              setUni_FAgency(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_FAgency}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Type (Government/Non-Government)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Type}
                            onChange={(e) => {
                              setUni_Type(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Type}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Department of Principal Investigator/ Co Investigator
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Dep}
                            onChange={(e) => {
                              setUni_Dep(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Dep}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of Award
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Award1}
                            onChange={(e) => {
                              setUni_Award1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Award1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Funds provided (INR in lakhs)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Funds}
                            onChange={(e) => {
                              setUni_Funds(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Funds}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Duration of the project
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Project}
                            onChange={(e) => {
                              setUni_Project(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Project}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc7}
                            onChange={(e) => setUni_Desc7(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc7}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload4"
                            id="upload4 "
                            onChange={(e) => setUni_Link7(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link7.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit4(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat4();
                      handleClose3();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.3.2)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the workshop/ seminar
                  </label>
                  {obj5?.Uni_Seminar ? (
                    <p>{obj5?.Uni_Seminar}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_Seminar(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the students
                  </label>
                  {obj5?.Uni_Parti ? (
                    <p>{obj5?.Uni_Parti}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_Parti(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date (From)
                  </label>
                  {obj5?.Uni_DFrom ? (
                    <p>{obj5?.Uni_DFrom}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="date"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_DFrom(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date (To)
                  </label>
                  {obj5?.Uni_DTo ? (
                    <p>{obj5?.Uni_DTo}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="date"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_DTo(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj5?.Uni_Desc8 ? (
                  <p>{obj5?.Uni_Desc8}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc8(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload5">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload5"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link8(e.target.files[0]);
                  }}
                />
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add5 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat5();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow4}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show4} onHide={handleClose4}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.3.2]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the workshop/ seminar
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Seminar}
                            onChange={(e) => {
                              setUni_Seminar(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Seminar}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the students
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Parti}
                            onChange={(e) => {
                              setUni_Parti(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Parti}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Date (From)
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="date"
                            name="email"
                            id="name"
                            placeholder={Uni_DFrom}
                            onChange={(e) => {
                              setUni_DFrom(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_DFrom}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Date (To)
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="date"
                            name="email"
                            id="name"
                            placeholder={Uni_DTo}
                            onChange={(e) => {
                              setUni_DTo(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_DTo}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <textarea
                            rows={3}
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc8}
                            onChange={(e) => setUni_Desc8(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc8}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload5">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="email"
                            id="upload5"
                            onChange={(e) => setUni_Link8(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link8.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit5(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat5();
                      handleClose4();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.3.3)</h5>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award
                  </label>

                  {obj6?.Uni_YAw ? (
                    <p>{obj6?.Uni_YAw}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_YAw(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the innovation
                  </label>
                  {obj6?.Uni_Innova ? (
                    <p>{obj6?.Uni_Innova}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Innova(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awardee
                  </label>
                  {obj6?.Uni_Awardee ? (
                    <p>{obj6?.Uni_Awardee}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Awardee(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1">
                    {" "}
                    Name of the Awarding Agency with contact details
                  </label>
                  {obj6?.Uni_Details ? (
                    <p>{obj6?.Uni_Details}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Details(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category- institution/teacher/research scholar/student
                  </label>
                  {obj6?.Uni_Category ? (
                    <p>{obj6?.Uni_Category}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Category(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj6?.Uni_Desc17 ? (
                    <p>{obj6?.Uni_Desc17}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc17(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload6"
                    id="upload6"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link17(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add6 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat6();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow5}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show5} onHide={handleClose5}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.3.3]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of Award
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_YAw}
                            onChange={(e) => {
                              setUni_YAw(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_YAw}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the innovation
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Innova}
                            onChange={(e) => {
                              setUni_Innova(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Innova}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Awardee
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Awardee}
                            onChange={(e) => {
                              setUni_Awardee(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Awardee}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Awarding Agency with contact details
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Details}
                            onChange={(e) => {
                              setUni_Details(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Details}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Category- institution/teacher/research scholar/student
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Category}
                            onChange={(e) => {
                              setUni_Category(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Category}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc17}
                            onChange={(e) => setUni_Desc17(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc17}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload6"
                            id="upload6 "
                            onChange={(e) => setUni_Link17(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link17.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit6(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat6();
                      handleClose5();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.4.3)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Teacher
                  </label>

                  {obj7?.Uni_Teacher ? (
                    <p>{obj7?.Uni_Teacher}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Teacher(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Patent Number
                  </label>
                  {obj7?.Uni_Patent ? (
                    <p>{obj7?.Uni_Patent}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Patent(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the patent
                  </label>
                  {obj7?.Uni_Title ? (
                    <p>{obj7?.Uni_Title}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Title(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1">
                    {" "}
                    Year of Award / publish of patent
                  </label>
                  {obj7?.Uni_YOAw ? (
                    <p>{obj7?.Uni_YOAw}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_YOAw(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj7?.Uni_Desc18 ? (
                    <p>{obj7?.Uni_Desc18}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc18(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload7">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload7"
                    id="upload7"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link18(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add7 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat7();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow6}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show6} onHide={handleClose6}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.4.3]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Teacher
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Teacher}
                            onChange={(e) => {
                              setUni_Teacher(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Teacher}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Patent Number
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Patent}
                            onChange={(e) => {
                              setUni_Patent(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Patent}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the patent
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Title}
                            onChange={(e) => {
                              setUni_Title(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Title}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of Award / publish of patent
                      </label>
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_YOAw}
                            onChange={(e) => {
                              setUni_YOAw(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_YOAw}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc18}
                            onChange={(e) => setUni_Desc18(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc18}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload7"
                            id="upload7 "
                            onChange={(e) => setUni_Link18(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link18.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit7(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat7();
                      handleClose6();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.4.4)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the PhD scholar
                  </label>

                  {obj8?.Uni_Phd ? (
                    <p>{obj8?.Uni_Phd}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Phd(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>
                  {obj8?.Uni_Depar ? (
                    <p>{obj8?.Uni_Depar}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Depar(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the guide/s
                  </label>
                  {obj8?.Uni_Guide ? (
                    <p>{obj8?.Uni_Guide}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Guide(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1">Title of the thesis</label>
                  {obj8?.Uni_Thesis1 ? (
                    <p>{obj8?.Uni_Thesis1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Thesis1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of registration of the scholar
                  </label>
                  {obj8?.Uni_Scholar ? (
                    <p>{obj8?.Uni_Scholar}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Scholar(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of PhD awarded
                  </label>
                  {obj8?.Uni_Phd1 ? (
                    <p>{obj8?.Uni_Phd1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Phd1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit (YES/NO)
                  </label>
                  {obj8?.Uni_Whether1 ? (
                    <p>{obj8?.Uni_Whether1}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setUni_Whether1(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Recognition as a Research Guide
                  </label>
                  {obj8?.Uni_Recog ? (
                    <p>{obj8?.Uni_Recog}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Recog(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj8?.Uni_Desc19 ? (
                    <p>{obj8?.Uni_Desc19}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc19(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload5">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload5"
                    id="upload5"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link19(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add8 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat8();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn  btn-warning"
                      onClick={handleShow7}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show7} onHide={handleClose7}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.4.4]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the PhD scholar
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Phd}
                            onChange={(e) => {
                              setUni_Phd(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Phd}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Department
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Depar}
                            onChange={(e) => {
                              setUni_Depar(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Depar}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the guide/s
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Guide}
                            onChange={(e) => {
                              setUni_Guide(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Guide}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the thesis
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Thesis1}
                            onChange={(e) => {
                              setUni_Thesis1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Thesis1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of registration of the scholar
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Scholar}
                            onChange={(e) => {
                              setUni_Scholar(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Scholar}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of PhD awarded
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Phd1}
                            onChange={(e) => {
                              setUni_Phd1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Phd1}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Whether recognised as a Research Guide for
                        Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit
                        (YES/NO)
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            onChange={(e) => setUni_Whether1(e.target.value)}
                          >
                            <option selected>--Select--</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </>
                      ) : (
                        <>{Uni_Whether1}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of PhD awarded
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Recog}
                            onChange={(e) => {
                              setUni_Recog(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Recog}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc7}
                            onChange={(e) => setUni_Desc9(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc9}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload8"
                            id="upload8 "
                            onChange={(e) => setUni_Link9(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link19.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit8(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat8();
                      handleClose7();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.4.5)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of paper
                  </label>

                  {obj9?.Uni_Paper ? (
                    <p>{obj9?.Uni_Paper}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Paper(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the author/s
                  </label>
                  {obj9?.Uni_Author ? (
                    <p>{obj9?.Uni_Author}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Author(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of the teacher
                  </label>
                  {obj9?.Uni_Dept ? (
                    <p>{obj9?.Uni_Dept}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Dept(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Name of journal</label>
                  {obj9?.Uni_Journal ? (
                    <p>{obj9?.Uni_Journal}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Journal(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of publication
                  </label>
                  {obj9?.Uni_Pub ? (
                    <p>{obj9?.Uni_Pub}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Pub(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ISSN number
                  </label>
                  {obj9?.Uni_ISSN ? (
                    <p>{obj9?.Uni_ISSN}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_ISSN(e.target.value);
                      }}
                    />
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  Link to the recognition in UGC enlistment of the Journal{" "}
                </h6>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </label>
                  {obj9?.Uni_ISIT ? (
                    <p>{obj9?.Uni_ISIT}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_ISIT(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj9?.Uni_Desc9 ? (
                    <p>{obj9?.Uni_Desc9}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc9(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload9">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload9"
                    id="upload9"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link9(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload10">
                    {" "}
                    Link to article/paper/abstract of the article
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload10"
                    id="upload10"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link10(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add9 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat9();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow8}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show8} onHide={handleClose8}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.4.5]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of paper
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Paper}
                            onChange={(e) => {
                              setUni_Paper(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Paper}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the author/s
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Author}
                            onChange={(e) => {
                              setUni_Author(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Author}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Department of the teacher
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Dept}
                            onChange={(e) => {
                              setUni_Dept(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Dept}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of journal
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Journal}
                            onChange={(e) => {
                              setUni_Journal(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Journal}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of publication
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Pub}
                            onChange={(e) => {
                              setUni_Pub(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Pub}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        ISSN number
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_ISSN}
                            onChange={(e) => {
                              setUni_ISSN(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_ISSN}</>
                      )}
                    </div>
                    <h6 className="text-center text-decoration-underline">
                      Link to the recognition in UGC enlistment of the Journal{" "}
                    </h6>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Is it listed in UGC Care list/Scopus/Web of
                        Science/other, mention
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_ISIT}
                            onChange={(e) => {
                              setUni_ISIT(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_ISIT}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc7}
                            onChange={(e) => setUni_Desc9(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc9}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload9"
                            id="upload9 "
                            onChange={(e) => setUni_Link9(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link9.name}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to article/paper/abstract of the article
                      </label>
                      <br />
                      {edit9 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload10"
                            id="upload10 "
                            onChange={(e) => setUni_Link10(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link10?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit9(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat9();
                      handleClose8();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.4.6)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {obj10?.Uni_SLno ? (
                    <p>{obj10?.Uni_SLno}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_SLno(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher
                  </label>
                  {obj10?.Uni_Name ? (
                    <p>{obj10?.Uni_Name}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Name(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the book/chapters published
                  </label>
                  {obj10?.Uni_Book ? (
                    <p>{obj10?.Uni_Book}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Book(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Title of the paper</label>
                  {obj10?.Uni_Paper1 ? (
                    <p>{obj10?.Uni_Paper1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Paper1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the proceedings of the conference
                  </label>
                  {obj10?.Uni_Title4 ? (
                    <p>{obj10?.Uni_Title4}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Title4(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of publication
                  </label>
                  {obj10?.Uni_Year2 ? (
                    <p>{obj10?.Uni_Year2}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Year2(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ISBN/ISSN number of the proceeding
                  </label>
                  {obj10?.Uni_ISBN ? (
                    <p>{obj10?.Uni_ISBN}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_ISBN(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the publisher
                  </label>
                  {obj10?.Uni_Whether ? (
                    <p>{obj10?.Uni_Whether}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Whether(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </label>
                  {obj10?.Uni_Name1 ? (
                    <p>{obj10?.Uni_Name1}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "100%" }}
                      onChange={(e) => setUni_Name1(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj10?.Uni_Desc10 ? (
                    <p>{obj10?.Uni_Desc10}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc10(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload11">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload11"
                    id="upload11"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link11(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add10 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat10();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow9}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show9} onHide={handleClose9}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.4.6]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Sl. No.
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_SLno}
                            onChange={(e) => {
                              setUni_SLno(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_SLno}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the teacher
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Name}
                            onChange={(e) => {
                              setUni_Name(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Name}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the book/chapters published
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Book}
                            onChange={(e) => {
                              setUni_Book(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Book}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the paper
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Paper1}
                            onChange={(e) => {
                              setUni_Paper1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Paper1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the proceedings of the conference
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Title4}
                            onChange={(e) => {
                              setUni_Title4(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Title4}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of publication
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Year2}
                            onChange={(e) => {
                              setUni_Year2(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Year2}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        ISBN/ISSN number of the proceeding
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_ISBN}
                            onChange={(e) => {
                              setUni_ISBN(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_ISBN}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the publisher
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Name1}
                            onChange={(e) => {
                              setUni_Name1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Name1}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Whether at the time of publication Affiliating
                        Institution Was same
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "100%" }}
                            onChange={(e) => setUni_Whether(e.target.value)}
                          >
                            <option selected>--Select--</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </>
                      ) : (
                        <>{Uni_Whether}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc10}
                            onChange={(e) => setUni_Desc10(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc10}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit10 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload7"
                            id="upload7 "
                            onChange={(e) => setUni_Link11(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link11.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit10(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat10();
                      handleClose9();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.4.7)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher
                  </label>

                  {obj11?.Uni_Teacher1 ? (
                    <p>{obj11?.Uni_Teacher1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Teacher1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the module developed
                  </label>
                  {obj11?.Uni_Module ? (
                    <p>{obj11?.Uni_Module}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Module(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Platform on which module is developed
                  </label>
                  {obj11?.Uni_Platform ? (
                    <p>{obj11?.Uni_Platform}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Platform(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1">
                    {" "}
                    Date of launching e content
                  </label>
                  {obj11?.Uni_Launch ? (
                    <p>{obj11?.Uni_Launch}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="date"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Launch(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    List of the e-content development facility available
                  </label>
                  {obj11?.Uni_List1 ? (
                    <p>{obj11?.Uni_List1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_List1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj11?.Uni_Desc20 ? (
                    <p>{obj11?.Uni_Desc20}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc20(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload11">
                    {" "}
                    Link to the relevant document and facility available in the
                    institution
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload11"
                    id="upload11"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link20(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload11">
                    {" "}
                    Provide link to videos of the media centre and recording
                    facility
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload12"
                    id="upload12"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link21(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add11 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat11();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow10}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show10} onHide={handleClose10}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.4.7]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the teacher
                      </label>
                      <br />
                      {edit11 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Teacher1}
                            onChange={(e) => {
                              setUni_Teacher1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Teacher1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the module developed
                      </label>
                      <br />
                      {edit11 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Module}
                            onChange={(e) => {
                              setUni_Module(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Module}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Platform on which module is developed
                      </label>
                      <br />
                      {edit11 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Platform}
                            onChange={(e) => {
                              setUni_Platform(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Platform}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Date of launching e content
                      </label>
                      <br />
                      {edit11 ? (
                        <>
                          <input
                            className="vi_0"
                            type="date"
                            name="fname"
                            id="name"
                            placeholder={Uni_Launch}
                            onChange={(e) => {
                              setUni_Launch(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Launch}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        List of the e-content development facility available
                      </label>
                      <br />
                      {edit11 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_List1}
                            onChange={(e) => {
                              setUni_List1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_List1}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc20}
                            onChange={(e) => setUni_Desc20(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc20}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to the relevant document and facility available in
                        the institution
                      </label>
                      <br />
                      {edit11 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload13"
                            id="upload13"
                            onChange={(e) => setUni_Link20(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link20.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit11(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat11();
                      handleClose10();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.5.2 & 3.5.2.1)</h5>
              <div className="row">
                <h6 className="text-center text-decoration-underline">
                  Revenue generated from consultancy during the last five years
                </h6>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher consultant
                  </label>

                  {obj12?.Uni_Consult ? (
                    <p>{obj12?.Uni_Consult}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Consult(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of consultancy project
                  </label>
                  {obj12?.Uni_Project1 ? (
                    <p>{obj12?.Uni_Project1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Project1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Consulting/Sponsoring agency with contact details
                  </label>
                  {obj12?.Uni_Agency1 ? (
                    <p>{obj12?.Uni_Agency1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Agency1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1">
                    {" "}
                    Revenue generated (INR in Lakhs)
                  </label>
                  {obj12?.Uni_Revenue ? (
                    <p>{obj12?.Uni_Revenue}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Revenue(e.target.value);
                      }}
                    />
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  Revenue generated from corporate training during the last five
                  years
                </h6>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Names of the teacher-consultants/corporate trainers
                  </label>
                  {obj12?.Uni_Consult2 ? (
                    <p>{obj12?.Uni_Consult2}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Consult2(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the corporate training program
                  </label>

                  {obj12?.Uni_Title1 ? (
                    <p>{obj12?.Uni_Title1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Title1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Agency seeking training with contact details
                  </label>
                  {obj12?.Uni_Agency3 ? (
                    <p>{obj12?.Uni_Agency3}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Agency3(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Revenue generated (amount in rupees)
                  </label>
                  {obj12?.Uni_Revenue1 ? (
                    <p>{obj12?.Uni_Revenue1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Revenue1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1">Number of trainees</label>
                  {obj12?.Uni_Trainee ? (
                    <p>{obj12?.Uni_Trainee}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Trainee(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj12?.Uni_Desc21 ? (
                    <p>{obj12?.Uni_Desc21}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc21(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload14">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload14"
                    id="upload14"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link22(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add12 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat12();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow11}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show11} onHide={handleClose11}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.5.2 & 3.5.2.1]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <h6 className="text-center text-decoration-underline">
                      Revenue generated from consultancy during the last five
                      years
                    </h6>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the teacher consultant
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Consult}
                            onChange={(e) => {
                              setUni_Consult(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Consult}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of consultancy project
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Project1}
                            onChange={(e) => {
                              setUni_Project1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Project1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Consulting/Sponsoring agency with contact details
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Agency1}
                            onChange={(e) => {
                              setUni_Agency1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Agency1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Revenue generated (INR in Lakhs)
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Revenue}
                            onChange={(e) => {
                              setUni_Revenue(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Revenue}</>
                      )}
                    </div>
                    <h6 className="text-center text-decoration-underline">
                      Revenue generated from corporate training during the last
                      five years
                    </h6>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Names of the teacher-consultants/corporate trainers
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Consult2}
                            onChange={(e) => {
                              setUni_Consult2(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Consult2}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the corporate training program
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Title1}
                            onChange={(e) => {
                              setUni_Title1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Title1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Agency seeking training with contact details
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Agency3}
                            onChange={(e) => {
                              setUni_Agency3(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Agency3}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Revenue generated (amount in rupees)
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Revenue1}
                            onChange={(e) => {
                              setUni_Revenue1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Revenue1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Number of trainees
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Trainee}
                            onChange={(e) => {
                              setUni_Trainee(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Trainee}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc21}
                            onChange={(e) => setUni_Desc21(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc21}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit12 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload14"
                            id="upload14"
                            onChange={(e) => setUni_Link22(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link22.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit12(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat12();
                      handleClose11();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.6.2)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  {obj13?.Uni_Name2 ? (
                    <p>{obj13?.Uni_Name2}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Name2(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Award/ recognition
                  </label>
                  {obj13?.Uni_Name3 ? (
                    <p>{obj13?.Uni_Name3}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Name3(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awarding government/ government recognised
                    bodies
                  </label>
                  {obj13?.Uni_Name4 ? (
                    <p>{obj13?.Uni_Name4}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Name4(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1"> Year of award</label>
                  {obj13?.Uni_Year4 ? (
                    <p>{obj13?.Uni_Year4}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Year4(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj13?.Uni_Desc12 ? (
                    <p>{obj13?.Uni_Desc12}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc12(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload15">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload15"
                    id="upload15"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link13(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add13 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat13();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow12}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show12} onHide={handleClose12}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.6.2]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the activity
                      </label>
                      <br />
                      {edit13 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Name2}
                            onChange={(e) => {
                              setUni_Name2(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Name2}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Award/ recognition
                      </label>
                      <br />
                      {edit13 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Name3}
                            onChange={(e) => {
                              setUni_Name3(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Name3}</>
                      )}
                    </div>
                    <div className="col-md-8 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Awarding government/ government recognised
                        bodies
                      </label>
                      <br />
                      {edit13 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Name4}
                            onChange={(e) => {
                              setUni_Name4(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Name4}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of award
                      </label>
                      <br />
                      {edit13 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Year4}
                            onChange={(e) => {
                              setUni_Year4(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Year4}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit13 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc12}
                            onChange={(e) => setUni_Desc12(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc12}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit13 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload15"
                            id="upload15"
                            onChange={(e) => setUni_Link13(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link13.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit13(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat13();
                      handleClose12();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.6.3 & 3.6.4)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  {obj14?.Uni_Name5 ? (
                    <p>{obj14?.Uni_Name5}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Name5(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Organising unit/ agency/ collaborating agency
                  </label>
                  {obj14?.Uni_org ? (
                    <p>{obj14?.Uni_org}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_org(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scheme
                  </label>
                  {obj14?.Uni_Scheme ? (
                    <p>{obj14?.Uni_Scheme}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Scheme(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Year of award</label>
                  {obj14?.Uni_Year5 ? (
                    <p>{obj14?.Uni_Year5}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Year5(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1">
                    {" "}
                    Number of students participated in such activities
                  </label>
                  {obj14?.Uni_Participants1 ? (
                    <p>{obj14?.Uni_Participants1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Participants1(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj14?.Uni_Desc13 ? (
                    <p>{obj14?.Uni_Desc13}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc13(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload16">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload16"
                    id="upload16"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link14(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add14 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat14();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow13}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show13} onHide={handleClose13}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.6.3 & 3.6.4]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the activity
                      </label>
                      <br />
                      {edit14 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Name5}
                            onChange={(e) => {
                              setUni_Name5(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Name5}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Organising unit/ agency/ collaborating agency
                      </label>
                      <br />
                      {edit14 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_org}
                            onChange={(e) => {
                              setUni_org(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_org}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the scheme
                      </label>
                      <br />
                      {edit14 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Scheme}
                            onChange={(e) => {
                              setUni_Scheme(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Scheme}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of the activity
                      </label>
                      <br />
                      {edit14 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Year5}
                            onChange={(e) => {
                              setUni_Year5(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Year5}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Number of students participated in such activities
                      </label>
                      <br />
                      {edit14 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Participants1}
                            onChange={(e) => {
                              setUni_Participants1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Participants1}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit14 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc13}
                            onChange={(e) => setUni_Desc13(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc13}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit14 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload15"
                            id="upload15"
                            onChange={(e) => setUni_Link14(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link14?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit14(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat14();
                      handleClose13();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.7.1)</h5>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {obj15?.Uni_SLNO1 ? (
                    <p>{obj15?.Uni_SLNO1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_SLNO1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the collaborating agency with contact details
                  </label>
                  {obj15?.Uni_Agency2 ? (
                    <p>{obj15?.Uni_Agency2}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Agency2(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the collaborative activity
                  </label>
                  {obj15?.Uni_Colloborative ? (
                    <p>{obj15?.Uni_Colloborative}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Colloborative(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Name of the participant</label>
                  {obj15?.Uni_Participants3 ? (
                    <p>{obj15?.Uni_Participants3}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Participants3(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Year of collaboration</label>
                  {obj15?.Uni_Collo ? (
                    <p>{obj15?.Uni_Collo}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Collo(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Duration</label>
                  {obj15?.Uni_Durat ? (
                    <p>{obj15?.Uni_Durat}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Durat(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Nature of the activity</label>
                  {obj15?.Uni_Activity ? (
                    <p>{obj15?.Uni_Activity}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Activity(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj15?.Uni_Desc14 ? (
                    <p>{obj15?.Uni_Desc14}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc14(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload17">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload17"
                    id="upload17"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link15(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add15 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat15();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow14}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show14} onHide={handleClose14}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.7.1]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Sl. No.
                      </label>
                      <br />
                      {edit15 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_SLNO1}
                            onChange={(e) => {
                              setUni_SLNO1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_SLNO1}</>
                      )}
                    </div>
                    <div className="col-md-8 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the collaborating agency with contact details
                      </label>
                      <br />
                      {edit15 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Agency2}
                            onChange={(e) => {
                              setUni_Agency2(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Agency2}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the collaborative activity
                      </label>
                      <br />
                      {edit15 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Colloborative}
                            onChange={(e) => {
                              setUni_Colloborative(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Colloborative}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the participant
                      </label>
                      <br />
                      {edit15 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Participants3}
                            onChange={(e) => {
                              setUni_Participants3(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Participants3}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of collaboration
                      </label>
                      <br />
                      {edit15 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Collo}
                            onChange={(e) => {
                              setUni_Collo(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Collo}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Duration
                      </label>
                      <br />
                      {edit15 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Durat}
                            onChange={(e) => {
                              setUni_Durat(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Durat}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Nature of the activity
                      </label>
                      <br />
                      {edit15 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Activity}
                            onChange={(e) => {
                              setUni_Activity(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Activity}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit15 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc14}
                            onChange={(e) => setUni_Desc14(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc14}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit15 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload11"
                            id="upload11"
                            onChange={(e) => setUni_Link15(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link15?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit15(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat15();
                      handleClose14();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.7.2)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Organisation with which MoU is signed
                  </label>

                  {obj16?.Uni_Organ ? (
                    <p>{obj16?.Uni_Organ}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Organ(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the institution/ industry/ corporate house
                  </label>
                  {obj16?.Uni_IIC ? (
                    <p>{obj16?.Uni_IIC}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_IIC(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of signing MoU
                  </label>
                  {obj16?.Uni_Signing ? (
                    <p>{obj16?.Uni_Signing}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Signing(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Duration</label>
                  {obj16?.Uni_Durat1 ? (
                    <p>{obj16?.Uni_Durat1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Durat1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1">
                    {" "}
                    List the actual activities under each MOU year-wise
                  </label>
                  {obj16?.Uni_List ? (
                    <p>{obj16?.Uni_List}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_List(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj16?.Uni_Desc15 ? (
                    <p>{obj16?.Uni_Desc15}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc15(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload18">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload18"
                    id="upload18"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link16(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add16 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat16();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow15}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show15} onHide={handleClose15}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[3.7.2]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Organisation with which MoU is signed
                      </label>
                      <br />
                      {edit16 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Organ}
                            onChange={(e) => {
                              setUni_Organ(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Organ}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the institution/ industry/ corporate house
                      </label>
                      <br />
                      {edit16 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_IIC}
                            onChange={(e) => {
                              setUni_IIC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_IIC}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of signing MoU
                      </label>
                      <br />
                      {edit16 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Signing}
                            onChange={(e) => {
                              setUni_Signing(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Signing}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Duration
                      </label>
                      <br />
                      {edit16 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Durat1}
                            onChange={(e) => {
                              setUni_Durat1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Durat1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        List the actual activities under each MOU year-wise
                      </label>
                      <br />
                      {edit16 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_List}
                            onChange={(e) => {
                              setUni_List(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_List}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit16 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc15}
                            onChange={(e) => setUni_Desc15(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc15}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit16 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload18"
                            id="upload18"
                            onChange={(e) => setUni_Link16(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link16.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit16(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat16();
                      handleClose15();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="text-center mt-3 mb-3">
            <button
              type="submit"
              className="btn btn-success "
              onClick={() => unicriteria3()}
              style={{ color: "white", marginTop: "15px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uni_Criteria003;
