import React from 'react'
import './Styles/Successpage.css'
import { Link } from 'react-router-dom'
const Successpage = () => {
  return (
    <div className='success'>
   
  
        <div class="">
        <div style={{borderRadius:"200px", height:"200px", width:"200px", background: "#F8FAF5", margin: "0 auto"}}>
          <i class="checkmark " id="curiculum">✓</i>
        </div>
          <h2 className='success-meg'>Register Successfully</h2> <br/>
          <Link to="/">Home</Link>
        </div>
     
  </div>
  )
}

export default Successpage