import React from "react";

const UG6623aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-12 report-head  mb-3">
          <span>Administration</span>
          <div className="row">
            <div className="col-lg-6 report-head  mt-2">
              <div>
                <span>Year of implementation</span>
              </div>
              <div>
                <span>Document</span>
              </div>
            </div>
            <div className="col-lg-6 mt-2">
              <div className="report-text">
                <span>{item?.UGCriteria06_623?.yoi1}</span>
              </div>
              <div className="report-text">
                <span>
                  <a
                    href={`https://brightnaac.co.in/UG_Criteria06/${item?.UGCriteria06_623?.doc1}`}
                    target="blank_"
                  >
                    View Document
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 report-head  mb-3">
          <span>Finance and Accounts</span>
          <div className="row">
            <div className="col-lg-6 report-head  mt-2">
              <div>
                <span>Year of implementation</span>
              </div>
              <div>
                <span>Document</span>
              </div>
            </div>
            <div className="col-lg-6 mt-2">
              <div className="report-text">
                <span>{item?.UGCriteria06_623?.yoi2}</span>
              </div>
              <div className="report-text">
                <span>
                  <a
                    href={`https://brightnaac.co.in/UG_Criteria06/${item?.UGCriteria06_623?.doc2}`}
                    target="blank_"
                  >
                    View Document
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 report-head  mb-3">
          <span>Student Admissions and Support</span>
        </div>
        <div className="row">
          <div className="col-lg-6 report-head mt-2">
            <div>
              <span> Year of implementation </span>
            </div>
            <div>
              <span>Document</span>
            </div>
          </div>
          <div className="col-lg-6 mt-2">
            <div className="report-text">
              <span>{item?.UGCriteria06_623?.yoi3}</span>
            </div>
            <div className="report-text">
              <span>
                <a
                  href={`https://brightnaac.co.in/UG_Criteria06/${item?.UGCriteria06_623?.doc3}`}
                  target="blank_"
                >
                  View Document
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 report-head  mb-3">
          <span>Examinations</span>
        </div>
        <div className="row">
          <div className="col-lg-6 report-head mt-2">
            <div>
              <span> Year of implementation </span>
            </div>
            <div>
              <span>Document</span>
            </div>
          </div>
          <div className="col-lg-6 mt-2">
            <div className="report-text">
              <span>{item?.UGCriteria06_623?.yoi4}</span>
            </div>
            <div className="report-text">
              <span>
                <a
                  href={`https://brightnaac.co.in/UG_Criteria06/${item?.UGCriteria06_623?.doc4}`}
                  target="blank_"
                >
                  View Document
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.UGCriteria06_623?.ugdesc1}</span>
        </div>
      </div>
    </div>
  );
});

export default UG6623aqar;
