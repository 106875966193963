import React, { useState } from "react";
import "./Css/adminpanel.css";
import { Dropdown } from "react-bootstrap";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { AiFillAppstore, AiFillDashboard } from "react-icons/ai";
import { AiFillHome, AiFillProfile } from "react-icons/ai";
import { HiPhotograph } from "react-icons/hi";
import { GrGraphQl } from "react-icons/gr";
import { MdLibraryBooks, MdAccessTimeFilled } from "react-icons/md";
import { MdEmojiEvents, MdFeaturedPlayList } from "react-icons/md";
import { FaBlog, FaChalkboardTeacher, FaListAlt } from "react-icons/fa";
import { SiHelpdesk } from "react-icons/si";
import {
  BsFillImageFill,
  BsBuildingsFill,
  BsFillFilePersonFill,
} from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

function AdminPanel(props) {
  const [collapsed, setCollapsed] = React.useState(false);

  const PriLogin = JSON.parse(sessionStorage.getItem("Admin"));
  const doUserLogOut = () => {
    sessionStorage.removeItem("Admin");
    sessionStorage.removeItem("collegeDetails");
    window.location.assign("/admin");
  };

  return (
    <>
      <div style={{ display: "flex", height: "100%", minHeight: "100vh" }}>
        <Sidebar
          collapsed={collapsed}
          // breakPoint={breakpoint? "sm" : ""}
          height="100vh"
          collapsedWidth="4.5rem"
          backgroundColor="#161632"
          style={{
            position: "sticky",
            top: "auto",
            padding: "0rem",
            margin: "0rem",
          }}
        >
          <div className="dash-logo text-center pt-3 ">
            <h4 onClick={() => setCollapsed(!collapsed)}>NAAC</h4>
          </div>
          <Menu
            transitionDuration={900}
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: disabled ? "blue" : "white",
                    backgroundColor: active ? "red" : undefined,
                  };
              },
            }}
            rootStyles={{
              // backgroundColor: '#e1e1e1',
              color: "#344cff",
            }}
          >
            <MenuItem
              component={<Link to="/dashboard" />}
              icon={<MdOutlineDashboardCustomize />}
            >
              Dashboard
            </MenuItem>

            <SubMenu icon={<AiFillAppstore />} label=" Manage Website">
              <SubMenu icon={<AiFillHome />} label="Home">
                <MenuItem
                  component={<Link to="/graphic" />}
                  icon={<BsFillImageFill />}
                >
                  {" "}
                  Slider
                </MenuItem>
                <MenuItem
                  component={<Link to="/description" />}
                  icon={<AiFillProfile />}
                >
                  Description
                </MenuItem>
                <MenuItem
                  component={<Link to="/announcement" />}
                  icon={<AiFillProfile />}
                >
                  {" "}
                  Announcement
                </MenuItem>
                <MenuItem
                  component={<Link to="/notification" />}
                  icon={<AiFillProfile />}
                >
                  Notification
                </MenuItem>
                <MenuItem
                  component={<Link to="/addVideos" />}
                  icon={<AiFillProfile />}
                >
                  {" "}
                  Latest Videos
                </MenuItem>
                <MenuItem
                  component={<Link to="/addlatestnews" />}
                  icon={<AiFillProfile />}
                >
                  Latest News
                </MenuItem>
                <MenuItem
                  component={<Link to="/addeducating" />}
                  icon={<AiFillProfile />}
                >
                  Educating
                </MenuItem>
              </SubMenu>

              <SubMenu icon={<BsFillFilePersonFill />} label="About">
                <MenuItem
                  component={<Link to="/addaboutus" />}
                  icon={<AiFillProfile />}
                >
                  {" "}
                  About Us
                </MenuItem>
                <MenuItem
                  component={<Link to="/addvalueframework" />}
                  icon={<AiFillProfile />}
                >
                  Value FrameWork
                </MenuItem>
                <MenuItem
                  component={<Link to="/addleadership" />}
                  icon={<AiFillProfile />}
                >
                  {" "}
                  Leadership
                </MenuItem>
                <MenuItem
                  component={<Link to="/addcampus" />}
                  icon={<AiFillProfile />}
                >
                  Campus
                </MenuItem>
                {/* <MenuItem
                  component={<Link to="/addnaccoffice" />}
                  icon={<AiFillProfile />}
                >
                  {" "}
                  NAAC Office
                </MenuItem> */}
              </SubMenu>

              <SubMenu icon={<AiFillHome />} label="Assessment">
                <MenuItem
                  component={<Link to="/accrediationAndAssessment" />}
                  icon={<BsFillImageFill />}
                >
                  {" "}
                  Accrediation & Assessment
                </MenuItem>
              </SubMenu>

              <SubMenu icon={<AiFillHome />} label="Seminar">
                <MenuItem
                  component={<Link to="/seminar" />}
                  icon={<BsFillImageFill />}
                >
                  {" "}
                  Sponsered Seminars
                </MenuItem>
              </SubMenu>

              <SubMenu icon={<AiFillHome />} label="Resources">
                <MenuItem
                  component={<Link to="/adminLibrary" />}
                  icon={<AiFillProfile />}
                >
                  {" "}
                  Library{" "}
                </MenuItem>
                <MenuItem
                  component={<Link to="/adminPublications" />}
                  icon={<BsFillImageFill />}
                >
                  {" "}
                  Publications{" "}
                </MenuItem>
              </SubMenu>

              <MenuItem
                component={<Link to="/agallery" />}
                icon={<HiPhotograph />}
              >
                {" "}
                Gallery
              </MenuItem>
              <MenuItem
                component={<Link to="/course" />}
                icon={<MdLibraryBooks />}
              >
                {" "}
                Course
              </MenuItem>

              <MenuItem
                component={<Link to="/event" />}
                icon={<MdEmojiEvents />}
              >
                {" "}
                Event
              </MenuItem>
              <MenuItem
                component={<Link to="/admincontactus" />}
                icon={<FaBlog />}
              >
                {" "}
                Contact us
              </MenuItem>

              <MenuItem
                component={<Link to="/adminhelpuser" />}
                icon={<SiHelpdesk />}
              >
                {" "}
                Help Users
              </MenuItem>
              <MenuItem
                component={<Link to="/adminfaq" />}
                icon={<FaChalkboardTeacher />}
              >
                {" "}
                FAQ
              </MenuItem>
            </SubMenu>

            <MenuItem
              component={<Link to="/addYears" />}
              icon={<MdAccessTimeFilled />}
            >
              Academic Year
            </MenuItem>

            <MenuItem
              component={<Link to="/manageclg" />}
              icon={<BsBuildingsFill />}
            >
              Manage College
            </MenuItem>

            <SubMenu label="  College List" icon={<MdFeaturedPlayList />}>
              <MenuItem
                component={<Link to="/registeruser" />}
                icon={<GrGraphQl />}
              >
                Newly Registered
              </MenuItem>
              <MenuItem
                component={<Link to="/approvedclg" />}
                icon={<GrGraphQl />}
              >
                Approved Colleges
              </MenuItem>
            </SubMenu>

            <MenuItem
                component={<Link to="/feedbacklistforadmin" />}
                icon={<GrGraphQl />}
              >
                Feedback List
              </MenuItem>
          </Menu>
        </Sidebar>

        <main style={{}}>
          <div className="row admin-header m-0">
            <div className="col-sm-6">
              <div className="admin-header">
                <h3> Welcome to AdminPanel</h3>
              </div>
            </div>
            <div className="col-sm-6">
              <Dropdown className="dropdown-adminpro">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Mr. {PriLogin?.AName}
                </Dropdown.Toggle>

                <Dropdown.Menu className="profile-show">
                  <Dropdown.Item onClick={() => doUserLogOut()}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div>{props.children}</div>
        </main>
      </div>
    </>
  );
}

export default AdminPanel;
