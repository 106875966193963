import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PG_Criteria003 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);
  const [edit8, setedit8] = useState(false);
  const [add8, setadd8] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  //post method for criteria_03
  const [PG_Year, setPG_Year] = useState("");

  const [PG_ProName, setPG_ProName] = useState("");
  const [PG_PrinciName, setPG_PrinciName] = useState("");
  const [PG_PrinciDept, setPG_PrinciDept] = useState("");
  const [PG_YOfAw, setPG_YOfAw] = useState("");
  const [PG_ASanctioned, setPG_ASanctioned] = useState("");
  const [PG_DProject, setPG_DProject] = useState("");
  const [PG_NFunding, setPG_NFunding] = useState("");
  const [PG_Type, setPG_Type] = useState("");
  const [PG_Desc12, setPG_Desc12] = useState("");
  const [PG_Link12, setPG_Link12] = useState("");

  const [PG_NPati, setPG_NPati] = useState("");
  const [PG_FDate, setPG_FDate] = useState("");
  const [PG_TDate, setPG_TDate] = useState("");
  const [PG_NWorkshop, setPG_NWorkshop] = useState("");
  const [PG_Desc13, setPG_Desc13] = useState("");
  const [PG_Link13, setPG_Link13] = useState("");

  const [PG_TPapaer, setPG_TPapaer] = useState("");
  const [PG_AName, setPG_AName] = useState("");
  const [PG_TDept, setPG_TDept] = useState("");
  const [PG_JName, setPG_JName] = useState("");
  const [PG_PubliYear, setPG_PubliYear] = useState("");
  const [PG_IISSN, setPG_IISSN] = useState("");
  const [PG_UUGC, setPG_UUGC] = useState("");
  const [PG_Desc14, setPG_Desc14] = useState("");
  const [PG_Link14, setPG_Link14] = useState("");
  const [PG_Link15, setPG_Link15] = useState("");

  const [PG_SNo, setPG_SNo] = useState("");
  const [PG_NTea, setPG_NTea] = useState("");
  const [PG_Title1, setPG_Title1] = useState("");
  const [PG_Title2, setPG_Title2] = useState("");
  const [PG_Title3, setPG_Title3] = useState("");
  const [PG_YPub, setPG_YPub] = useState("");
  const [PG_IIISBN, setPG_IIISBN] = useState("");
  const [PG_NPub, setPG_NPub] = useState("");
  const [PG_ConName, setPG_ConName] = useState("");
  const [PG_NatInt, setPG_NatInt] = useState("");
  const [PG_Affi, setPG_Affi] = useState("");
  const [PG_Desc15, setPG_Desc15] = useState("");
  const [PG_Link16, setPG_Link16] = useState("");

  const [PG_ActiN, setPG_ActiN] = useState("");
  const [PG_YearA, setPG_YearA] = useState("");
  const [PG_INAme, setPG_INAme] = useState("");
  const [PG_Bodies, setPG_Bodies] = useState("");
  const [PG_Desc16, setPG_Desc16] = useState("");
  const [PG_Link17, setPG_Link17] = useState("");

  const [PG_NaActi, setPG_NaActi] = useState("");
  const [PG_Organ, setPG_Organ] = useState("");
  const [PG_SchName, setPG_SchName] = useState("");
  const [PG_ActiYear, setPG_ActiYear] = useState("");
  const [PG_NStudents, setPG_NStudents] = useState("");
  const [PG_Desc17, setPG_Desc17] = useState("");
  const [PG_Link18, setPG_Link18] = useState("");

  const [PG_SlNo, setPG_SlNo] = useState("");
  const [PG_Collo, setPG_Collo] = useState("");
  const [PG_NCollo, setPG_NCollo] = useState("");
  const [PG_NameParti, setPG_NameParti] = useState("");
  const [PG_ColloYear, setPG_ColloYear] = useState("");
  const [PG_Dur, setPG_Dur] = useState("");
  const [PG_Desc18, setPG_Desc18] = useState("");
  const [PG_Link19, setPG_Link19] = useState("");

  const [PG_NMou, setPG_NMou] = useState("");
  const [PG_Mou, setPG_Mou] = useState("");
  const [PG_Durat, setPG_Durat] = useState("");
  const [PG_List, setPG_List] = useState("");
  const [PG_Desc19, setPG_Desc19] = useState("");
  const [PG_Link20, setPG_Link20] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getpgcriteria3 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));

      let am8 = sessionStorage.getItem("cat8");
      setobj8(JSON.parse(am8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpgcriteria3();
  }, []);

  const pgcriteria3 = async () => {
    if (!PG_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/pgadddetails03",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Year: PG_Year,

          PG_Criteria03_311: obj1,
          PG_Link12: PG_Link12,

          PG_Criteria03_322: obj2,
          PG_Link13: PG_Link13,

          PG_Criteria03_332: obj3,
          PG_Link14: PG_Link14,
          PG_Link15: PG_Link15,

          PG_Criteria03_333: obj4,
          PG_Link16: PG_Link16,

          PG_Criteria03_342: obj5,
          PG_Link17: PG_Link17,

          PG_Criteria03_343: obj6,
          PG_Link18: PG_Link18,

          PG_Criteria03_351: obj7,
          PG_Link19: PG_Link19,

          PG_Criteria03_352: obj8,
          PG_Link20: PG_Link20,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          sessionStorage.removeItem("cat8");

          navigate("/pgcriteria03");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc12;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !PG_ProName ||
          !PG_PrinciName ||
          !PG_PrinciDept ||
          !PG_YOfAw ||
          !PG_ASanctioned ||
          !PG_DProject ||
          !PG_NFunding ||
          !PG_Type ||
          !PG_Desc12 ||
          !PG_Link12
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", PG_Link12);
      let obj = {
        PG_criteria: "3.1.1 & 3.1.3",
        PG_ProName: PG_ProName,
        PG_PrinciName: PG_PrinciName,
        PG_PrinciDept: PG_PrinciDept,
        PG_YOfAw: PG_YOfAw,
        PG_ASanctioned: PG_ASanctioned,
        PG_DProject: PG_DProject,
        PG_NFunding: PG_NFunding,
        PG_Type: PG_Type,
        PG_Desc12: PG_Desc12,
        PG_Link12: PG_Link12,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getpgcriteria3();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc13;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !PG_NPati ||
          !PG_FDate ||
          !PG_TDate ||
          !PG_NWorkshop ||
          !PG_Desc13 ||
          !PG_Link13
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", PG_Link13);
      let obj = {
        PG_criteria: "3.2.2",
        PG_NPati: PG_NPati,
        PG_FDate: PG_FDate,
        PG_TDate: PG_TDate,
        PG_NWorkshop: PG_NWorkshop,
        PG_Desc13: PG_Desc13,
        PG_Link13: PG_Link13,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getpgcriteria3();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc14;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !PG_TPapaer ||
          !PG_AName ||
          !PG_PubliYear ||
          !PG_TDept ||
          !PG_JName ||
          !PG_IISSN ||
          !PG_UUGC ||
          !PG_Desc14 ||
          !PG_Link14 ||
          !PG_Link15
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", PG_Link14);
      console.log("pppp,", PG_Link15);
      let obj = {
        PG_criteria: "3.3.2",
        PG_TPapaer: PG_TPapaer,
        PG_AName: PG_AName,
        PG_TDept: PG_TDept,
        PG_JName: PG_JName,
        PG_PubliYear: PG_PubliYear,
        PG_IISSN: PG_IISSN,
        PG_UUGC: PG_UUGC,
        PG_Desc14: PG_Desc14,
        PG_Link14: PG_Link14,
        PG_Link15: PG_Link15,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getpgcriteria3();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc15;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !PG_SNo ||
          !PG_NTea ||
          !PG_Title1 ||
          !PG_Title2 ||
          !PG_Title3 ||
          !PG_YPub ||
          !PG_IIISBN ||
          !PG_NPub ||
          !PG_ConName ||
          !PG_NatInt ||
          !PG_Affi ||
          !PG_Desc15 ||
          !PG_Link16
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("qqqq,", PG_Link16);
      let obj = {
        PG_criteria: "3.3.3",
        PG_SNo: PG_SNo,
        PG_NTea: PG_NTea,
        PG_Title1: PG_Title1,
        PG_Title2: PG_Title2,
        PG_Title3: PG_Title3,
        PG_YPub: PG_YPub,
        PG_IIISBN: PG_IIISBN,
        PG_NPub: PG_NPub,
        PG_ConName: PG_ConName,
        PG_NatInt: PG_NatInt,
        PG_Affi: PG_Affi,
        PG_Desc15: PG_Desc15,
        PG_Link16: PG_Link16,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getpgcriteria3();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc16;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !PG_ActiN ||
          !PG_YearA ||
          !PG_INAme ||
          !PG_Bodies ||
          !PG_Desc16 ||
          !PG_Link17
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("rrr,", PG_Link17);
      let obj = {
        PG_criteria: "3.4.2",
        PG_ActiN: PG_ActiN,
        PG_YearA: PG_YearA,
        PG_INAme: PG_INAme,
        PG_Bodies: PG_Bodies,
        PG_Desc16: PG_Desc16,
        PG_Link17: PG_Link17,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getpgcriteria3();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc17;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !PG_NaActi ||
          !PG_Organ ||
          !PG_SchName ||
          !PG_ActiYear ||
          !PG_NStudents ||
          !PG_Desc17 ||
          !PG_Link18
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("sss,", PG_Link18);
      let obj = {
        PG_criteria: "3.4.3",
        PG_NaActi: PG_NaActi,
        PG_Organ: PG_Organ,
        PG_SchName: PG_SchName,
        PG_ActiYear: PG_ActiYear,
        PG_NStudents: PG_NStudents,
        PG_Desc17: PG_Desc17,
        PG_Link18: PG_Link18,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getpgcriteria3();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !PG_SlNo ||
          !PG_Collo ||
          !PG_NCollo ||
          !PG_NameParti ||
          !PG_ColloYear ||
          !PG_Dur ||
          !PG_Desc18 ||
          !PG_Link19
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd7(false);
        }
      }

      console.log("ttt,", PG_Link19);
      let obj = {
        PG_criteria: "3.5.1",
        PG_SlNo: PG_SlNo,
        PG_Collo: PG_Collo,
        PG_NCollo: PG_NCollo,
        PG_NameParti: PG_NameParti,
        PG_ColloYear: PG_ColloYear,
        PG_Dur: PG_Dur,
        PG_Desc18: PG_Desc18,
        PG_Link19: PG_Link19,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getpgcriteria3();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat8 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add8) {
        if (
          !PG_NMou ||
          !PG_Mou ||
          !PG_Durat ||
          !PG_List ||
          !PG_Desc19 ||
          !PG_Link20
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd8(false);
        }
      }

      console.log("uuu,", PG_Link20);
      let obj = {
        PG_criteria: "3.5.2",
        PG_NMou: PG_NMou,
        PG_Mou: PG_Mou,
        PG_Durat: PG_Durat,
        PG_List: PG_List,
        PG_Desc19: PG_Desc19,
        PG_Link20: PG_Link20,
      };
      sessionStorage.setItem("cat8", JSON.stringify(obj));
      alert("Successfully added cat8");
      getpgcriteria3();
      setedit8(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            PostGraduation_Criteria-03
          </span>
        </div>

        <div className="container">
          <div className="row-fluid border bs">
            <div class=" col-md-6" style={{ padding: "20px" }}>
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.PG_Year ? (
                <p>{obj1?.PG_Year}</p>
              ) : (
                <select
                  class="form-select form-select-sm vi_0"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setPG_Year(e.target.value)}
                >
                  <option selected>--select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>PG_Criteria_3 (3.1.2 & 3.1.3)</h5>

            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Principal Investigator/Co-Investigator
                </label>
                {obj1?.PG_PrinciName ? (
                  <p>{obj1?.PG_PrinciName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_PrinciName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Project/ Endowments, Chairs
                </label>
                {obj1?.PG_ProName ? (
                  <p>{obj1?.PG_ProName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_ProName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Department of Principal Investigator
                </label>
                {obj1?.PG_PrinciDept ? (
                  <p>{obj1?.PG_PrinciDept}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_PrinciDept(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of Award
                </label>
                {obj1?.PG_YOfAw ? (
                  <p>{obj1?.PG_YOfAw}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_YOfAw(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Amount Sanctioned
                </label>
                {obj1?.PG_ASanctioned ? (
                  <p>{obj1?.PG_ASanctioned}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_ASanctioned(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Duration of the project
                </label>
                {obj1?.PG_DProject ? (
                  <p>{obj1?.PG_DProject}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_DProject(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the Funding Agency
                </label>
                {obj1?.PG_NFunding ? (
                  <p>{obj1?.PG_NFunding}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NFunding(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Type (Government/non-Government)
                </label>
                {obj1?.PG_Type ? (
                  <p>{obj1?.PG_Type}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Type(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.PG_Desc12 ? (
                  <p>{obj1?.PG_Desc12}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => setPG_Desc12(e.target.value)}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link12(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end">
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>PG_Criteria03__(3.1.2 & 3.1.3)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Project/ Endowments, Chairs
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_ProName}
                          onChange={(e) => {
                            setPG_ProName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_ProName}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Principal Investigator/Co-Investigator
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_PrinciName}
                          onChange={(e) => {
                            setPG_PrinciName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PrinciName}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Department of Principal Investigator
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_PrinciDept}
                          onChange={(e) => {
                            setPG_PrinciDept(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PrinciDept}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of Award
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_YOfAw}
                          onChange={(e) => {
                            setPG_YOfAw(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_YOfAw}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Amount Sanctioned
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_ASanctioned}
                          onChange={(e) => {
                            setPG_ASanctioned(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_ASanctioned}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Duration of the project
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_DProject}
                          onChange={(e) => {
                            setPG_DProject(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_DProject}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of Funding Agency
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_NFunding}
                          onChange={(e) => {
                            setPG_NFunding(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NFunding}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Type (Government/non-Government)
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Type}
                          onChange={(e) => {
                            setPG_Type(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Type}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit1 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc12}
                          onChange={(e) => setPG_Desc12(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc12}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className=" name"
                          type="file"
                          name="Year"
                          id="upload1"
                          accept="image/*"
                          onChange={(e) => setPG_Link12(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link12?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat1();
                    handleClose();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.2.2)</h5>
            <div className="row">
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of Participants
                </label>
                {obj2?.PG_NPati ? (
                  <p>{obj2?.PG_NPati}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_NPati(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Date (From)
                </label>
                {obj2?.PG_FDate ? (
                  <p>{obj2?.PG_FDate}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_FDate(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Date (To)
                </label>
                {obj2?.PG_TDate ? (
                  <p>{obj2?.PG_TDate}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_TDate(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the workshop/ seminar/ conference
                </label>
                {obj2?.PG_NWorkshop ? (
                  <p>{obj2?.PG_NWorkshop}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NWorkshop(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj2?.PG_Desc13 ? (
                  <p>{obj2?.PG_Desc13}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc13(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload2">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload2"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link13(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end">
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow1}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_3 (3.2.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of Participants
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NPati}
                          onChange={(e) => {
                            setPG_NPati(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NPati}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Date (From)
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="date"
                          name="fname"
                          id="name"
                          placeholder={PG_FDate}
                          onChange={(e) => {
                            setPG_FDate(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_FDate}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Date (To)
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="date"
                          name="fname"
                          id="name"
                          placeholder={PG_TDate}
                          onChange={(e) => {
                            setPG_TDate(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_TDate}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of workshop/seminar/conference
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_NWorkshop}
                          onChange={(e) => {
                            setPG_NWorkshop(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NWorkshop}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit2 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc13}
                          onChange={(e) => setPG_Desc13(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc13}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload2">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload2"
                          id="name"
                          onChange={(e) => setPG_Link13(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link13?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit2(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat2();
                    handleClose1();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.3.2)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Title of paper
                </label>
                {obj3?.PG_TPapaer ? (
                  <p>{obj3?.PG_TPapaer}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_TPapaer(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the author/s
                </label>
                {obj3?.PG_AName ? (
                  <p>{obj3?.PG_AName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_AName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Department of the teacher
                </label>
                {obj3?.PG_TDept ? (
                  <p>{obj3?.PG_TDept}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_TDept(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of journal
                </label>
                {obj3?.PG_JName ? (
                  <p>{obj3?.PG_JName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_JName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of publication
                </label>
                {obj3?.PG_PubliYear ? (
                  <p>{obj3?.PG_PubliYear}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_PubliYear(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  ISSN number
                </label>
                {obj3?.PG_IISSN ? (
                  <p>{obj3?.PG_IISSN}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_IISSN(e.target.value);
                    }}
                  />
                )}
              </div>
              <h6 className="text-center text-decoration-underline">
                Link to the recognition in UGC enlistment of the Journal
                /Digital Object Identifier (doi) number
              </h6>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Is it listed in UGC Care list/Scopus/Web of Science/other,
                  mention
                </label>
                {obj3?.PG_UUGC ? (
                  <p>{obj3?.PG_UUGC}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_UUGC(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj3?.PG_Desc14 ? (
                  <p>{obj3?.PG_Desc14}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc14(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link14(e.target.files[0]);
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload4">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload4"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link15(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end">
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow2}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria03_(3.3.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of paper
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_TPapaer}
                          onChange={(e) => {
                            setPG_TPapaer(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_TPapaer}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the author/s
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_AName}
                          onChange={(e) => {
                            setPG_AName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_AName}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Dept of the teacher
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_TDept}
                          onChange={(e) => {
                            setPG_TDept(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_TDept}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of journal
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_JName}
                          onChange={(e) => {
                            setPG_JName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_JName}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of publication
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_PubliYear}
                          onChange={(e) => {
                            setPG_PubliYear(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PubliYear}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ISSN number
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_IISSN}
                          onChange={(e) => {
                            setPG_IISSN(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_IISSN}</>
                    )}
                  </div>
                  <h6 className="text-center text-decoration-underline">
                    Link to the recognition in UGC enlistment of the Journal
                    /Digital Object Identifier (doi) number
                  </h6>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Is it listed in UGC Care list/Scopus/Web of Science/other,
                      mention
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_UUGC}
                          onChange={(e) => {
                            setPG_UUGC(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_UUGC}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description (min 500 words)
                    </label>

                    {edit3 ? (
                      <>
                        <textarea
                          className="vi_0"
                          id="name"
                          cols={57}
                          rows={3}
                          placeholder={PG_Desc14}
                          onChange={(e) => {
                            setPG_Desc14(e.target.value);
                          }}
                        ></textarea>
                      </>
                    ) : (
                      <>{PG_Desc14}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload3">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload3"
                          accept="image/*"
                          onChange={(e) => {
                            setPG_Link14(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Link14?.name}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload4"
                          accept="image/*"
                          onChange={(e) => {
                            setPG_Link15(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Link15?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit3(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat3();
                    handleClose2();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.3.3)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Sl. No.
                </label>
                {obj4?.PG_SNo ? (
                  <p>{obj4?.PG_SNo}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_SNo(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the teacher
                </label>
                {obj4?.PG_NTea ? (
                  <p>{obj4?.PG_NTea}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_NTea(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Title of the book/chapters published
                </label>
                {obj4?.PG_Title1 ? (
                  <p>{obj4?.PG_Title1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Title1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Title of the paper
                </label>
                {obj4?.PG_Title2 ? (
                  <p>{obj4?.PG_Title2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Title2(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Title of the proceedings of the conference
                </label>

                {obj4?.PG_Title3 ? (
                  <p>{obj4?.PG_Title3}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Title3(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of publication
                </label>
                {obj4?.PG_YPub ? (
                  <p>{obj4?.PG_YPub}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_YPub(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  ISBN/ISSN number of the proceeding
                </label>
                {obj4?.PG_IIISBN ? (
                  <p>{obj4?.PG_IIISBN}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_IIISBN(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the publisher
                </label>
                {obj4?.PG_NPub ? (
                  <p>{obj4?.PG_NPub}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NPub(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the conference
                </label>
                {obj4?.PG_ConName ? (
                  <p>{obj4?.PG_ConName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_ConName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  National / International
                </label>
                {obj4?.PG_NatInt ? (
                  <p>{obj4?.PG_NatInt}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NatInt(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Affiliating Institute at the time of publication
                </label>
                {obj4?.PG_Affi ? (
                  <p>{obj4?.PG_Affi}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Affi(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj4?.PG_Desc15 ? (
                  <p>{obj4?.PG_Desc15}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc15(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload5">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload5"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link16(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end">
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow3}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_3 (3.3.3)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Sl. No.
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_SNo}
                          onChange={(e) => {
                            setPG_SNo(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SNo}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NTea}
                          onChange={(e) => {
                            setPG_NTea(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NTea}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the book/chapters published
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Title1}
                          onChange={(e) => {
                            setPG_Title1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Title1}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Title of the proceedings of conference
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Title2}
                          onChange={(e) => {
                            setPG_Title2(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Title2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the paper
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Title3}
                          onChange={(e) => {
                            setPG_Title3(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Title3}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of publication
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_YPub}
                          onChange={(e) => {
                            setPG_YPub(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_YPub}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      ISBN/ISSN number of the proceeding
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_IIISBN}
                          onChange={(e) => {
                            setPG_IIISBN(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_IIISBN}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the publisher
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_NPub}
                          onChange={(e) => {
                            setPG_NPub(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NPub}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the conference
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_ConName}
                          onChange={(e) => {
                            setPG_ConName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_ConName}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      National / International
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_NatInt}
                          onChange={(e) => {
                            setPG_NatInt(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NatInt}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Affiliating Institute at the time of publication
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Affi}
                          onChange={(e) => {
                            setPG_Affi(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Affi}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit4 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc15}
                          onChange={(e) => setPG_Desc15(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc15}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload5">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload5"
                          accept="image/*"
                          onChange={(e) => {
                            setPG_Link16(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Link16?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit4(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat4();
                    handleClose3();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.4.2)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the activity
                </label>
                {obj5?.PG_ActiN ? (
                  <p>{obj5?.PG_ActiN}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_ActiN(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of award
                </label>
                {obj5?.PG_YearA ? (
                  <p>{obj5?.PG_YearA}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_YearA(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Award/ recognition for Institution
                </label>
                {obj5?.PG_INAme ? (
                  <p>{obj5?.PG_INAme}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_INAme(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Awarding government/ government recognised bodies
                </label>
                {obj5?.PG_Bodies ? (
                  <p>{obj5?.PG_Bodies}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Bodies(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj5?.PG_Desc16 ? (
                  <p>{obj5?.PG_Desc16}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc16(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload6">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload6"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link17(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end">
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat5();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow4}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show4} onHide={handleClose4}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_3 (3.4.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the activity
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_ActiN}
                          onChange={(e) => {
                            setPG_ActiN(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_ActiN}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of award
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_YearA}
                          onChange={(e) => {
                            setPG_YearA(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_YearA}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Award/ recognition for Institution
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_INAme}
                          onChange={(e) => {
                            setPG_INAme(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_INAme}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Awarding government/ government recognised
                      bodies
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Bodies}
                          onChange={(e) => {
                            setPG_Bodies(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Bodies}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit5 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc16}
                          onChange={(e) => setPG_Desc16(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc16}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload6">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload6"
                          accept="image/*"
                          onChange={(e) => {
                            setPG_Link17(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Link17?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit5(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat5();
                    handleClose4();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.4.3)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the activity
                </label>
                {obj6?.PG_NaActi ? (
                  <p>{obj6?.PG_NaActi}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_NaActi(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Organising unit/ agency/ collaborating agency
                </label>
                {obj6?.PG_Organ ? (
                  <p>{obj6?.PG_Organ}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Organ(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the scheme
                </label>
                {obj6?.PG_SchName ? (
                  <p>{obj6?.PG_SchName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_SchName(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-3 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of the activity
                </label>
                {obj6?.PG_ActiYear ? (
                  <p>{obj6?.PG_ActiYear}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_ActiYear(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of students participated in such activities
                </label>
                {obj6?.PG_NStudents ? (
                  <p>{obj6?.PG_NStudents}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_NStudents(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj6?.PG_Desc17 ? (
                  <p>{obj6?.PG_Desc17}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc17(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload7">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload7"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link18(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end">
              {add6 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat6();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow5}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show5} onHide={handleClose5}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_3 (3.4.3)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the activity
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_NaActi}
                          onChange={(e) => {
                            setPG_NaActi(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NaActi}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Organising unit/agency/collaborating agency
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Organ}
                          onChange={(e) => {
                            setPG_Organ(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Organ}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the scheme
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_SchName}
                          onChange={(e) => {
                            setPG_SchName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SchName}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of the activity
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_ActiYear}
                          onChange={(e) => {
                            setPG_ActiYear(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_ActiYear}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of students participated in such activities
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NStudents}
                          onChange={(e) => {
                            setPG_NStudents(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NStudents}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit6 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc17}
                          onChange={(e) => setPG_Desc17(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc17}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload7">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload7"
                          accept="image/*"
                          onChange={(e) => setPG_Link18(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link18?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit6(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat6();
                    handleClose5();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.5.1)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Sl. No.
                </label>
                {obj7?.PG_SlNo ? (
                  <p>{obj7?.PG_SlNo}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_SlNo(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Title of the collaborative activity
                </label>
                {obj7?.PG_Collo ? (
                  <p>{obj7?.PG_Collo}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Collo(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the collaborating / linking agency with contact
                  details
                </label>
                {obj7?.PG_NCollo ? (
                  <p>{obj7?.PG_NCollo}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_NCollo(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the participant
                </label>
                {obj7?.PG_NameParti ? (
                  <p>{obj7?.PG_NameParti}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_NameParti(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of collaboration
                </label>
                {obj7?.PG_ColloYear ? (
                  <p>{obj7?.PG_ColloYear}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_ColloYear(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Duration
                </label>
                {obj7?.PG_Dur ? (
                  <p>{obj7?.PG_Dur}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Dur(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj7?.PG_Desc18 ? (
                  <p>{obj7?.PG_Desc18}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc18(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload8">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload8"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link19(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end">
              {add7 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat7();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow6}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show6} onHide={handleClose6}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_3 (3.5.1)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Sl. No.
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_SlNo}
                          onChange={(e) => {
                            setPG_SlNo(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SlNo}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the collaborative activity
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Collo}
                          onChange={(e) => {
                            setPG_Collo(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Collo}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the collaborating / linking agency with contact
                      details
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NCollo}
                          onChange={(e) => {
                            setPG_NCollo(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NCollo}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the participant
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NameParti}
                          onChange={(e) => {
                            setPG_NameParti(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NameParti}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of collaboration
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_ColloYear}
                          onChange={(e) => {
                            setPG_ColloYear(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_ColloYear}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Duration
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Dur}
                          onChange={(e) => {
                            setPG_Dur(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Dur}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit7 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc18}
                          onChange={(e) => setPG_Desc18(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc18}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload8">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload8"
                          accept="image/*"
                          onChange={(e) => setPG_Link19(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link19?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit7(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat7();
                    handleClose6();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.5.2)</h5>
            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the institution/ industry/ corporate house with whom
                  MoU is signed
                </label>
                {obj8?.PG_NMou ? (
                  <p>{obj8?.PG_NMou}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_NMou(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of signing MOU
                </label>
                {obj8?.PG_Mou ? (
                  <p>{obj8?.PG_Mou}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Mou(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Duration
                </label>
                {obj8?.PG_Durat ? (
                  <p>{obj8?.PG_Durat}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Durat(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  List the actual activities under each MOU and web links
                  year-wise
                </label>
                {obj8?.PG_List ? (
                  <p>{obj8?.PG_List}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_List(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj8?.PG_Desc19 ? (
                  <p>{obj8?.PG_Desc19}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc19(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload9">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload9"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link20(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end">
              {add8 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat8();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow7}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show7} onHide={handleClose7}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_3 (3.5.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the institution/ industry/ corporate house with
                      whom MoU is signed
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_NMou}
                          onChange={(e) => {
                            setPG_NMou(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NMou}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of signing MOU
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Mou}
                          onChange={(e) => {
                            setPG_Mou(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Mou}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Duration
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Durat}
                          onChange={(e) => {
                            setPG_Durat(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Durat}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      List the actual activities under each MOU and web links
                      year-wise
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_List}
                          onChange={(e) => {
                            setPG_List(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_List}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit8 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc19}
                          onChange={(e) => setPG_Desc19(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc19}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload9">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload9"
                          accept="image/*"
                          onChange={(e) => setPG_Link20(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link20?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit8(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat8();
                    handleClose7();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => pgcriteria3()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PG_Criteria003;
