import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, ModalTitle } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

import exportFromJSON from "export-from-json";
const UG_Criteria05 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);
  const [edit8, setedit8] = useState(false);

  const [NameS, setNameS] = useState("");
  const [SName, setSName] = useState("");
  const [Amount, setAmount] = useState("");
  const [SName1, setSName1] = useState("");
  const [Amount1, setAmount1] = useState("");
  const [Desc21, setDesc21] = useState("");
  const [Link21, setLink21] = useState("");

  const [NamCap, setNamCap] = useState("");
  const [IDate, setIDate] = useState("");
  const [StdEnr, setStdEnr] = useState("");
  const [AgeName, setAgeName] = useState("");
  const [Desc22, setDesc22] = useState("");
  const [Link22, setLink22] = useState("");

  const [CampName, setCampName] = useState("");
  const [Nameact, setNameact] = useState("");
  const [NamePart, setNamePart] = useState("");
  const [Desc23, setDesc23] = useState("");
  const [Link23, setLink23] = useState("");

  const [PlaName, setPlaName] = useState("");
  const [Graduate, setGraduate] = useState("");
  const [ConDetails, setConDetails] = useState("");
  const [Pay, setPay] = useState("");
  const [Desc24, setDesc24] = useState("");
  const [Link24, setLink24] = useState("");

  const [HighName, setHighName] = useState("");
  const [PGrad, setPGrad] = useState("");
  const [Institute, setInstitute] = useState("");
  const [Admitted, setAdmitted] = useState("");
  const [Desc25, setDesc25] = useState("");
  const [Link25, setLink25] = useState("");

  const [No1, setNo1] = useState("");
  const [RollNo1, setRollNo1] = useState("");
  const [StdSelected1, setStdSelected1] = useState("");
  const [NET1, setNET1] = useState("");
  const [SLET1, setSLET1] = useState("");
  const [GATE1, setGATE1] = useState("");
  const [GMAT1, setGMAT1] = useState("");
  const [CAT1, setCAT1] = useState("");
  const [GRE1, setGRE1] = useState("");
  const [JAM1, setJAM1] = useState("");
  const [IELTS1, setIELTS1] = useState("");
  const [TOEFL1, setTOEFL1] = useState("");
  const [Civil1, setCivil1] = useState("");
  const [SGExam1, setSGExam1] = useState("");
  const [OtherExam1, setOtherExam1] = useState("");
  const [Total1, setTotal1] = useState("");
  const [GTotal1, setGTotal1] = useState("");
  const [Desc26, setDesc26] = useState("");
  const [Link26, setLink26] = useState("");

  const [Meda, setMeda] = useState("");
  const [TIndi, setTIndi] = useState("");
  const [USNI1, setUSNI1] = useState("");
  const [SCult, setSCult] = useState("");
  const [NaStd, setNaStd] = useState("");
  const [Desc27, setDesc27] = useState("");
  const [Link27, setLink27] = useState("");

  const [NStdParti, setNStdParti] = useState("");
  const [EDate, setEDate] = useState("");
  const [EName, setEName] = useState("");
  const [Desc28, setDesc28] = useState("");
  const [Link28, setLink28] = useState("");

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [UG_Criteria05, setUG_Criteria05] = useState([]);
  const [Value, setValue] = useState({});

  const [View, setView] = useState({});

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Cri5.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
          <table>
            <tr>
              <td>Number of students</td>
              <td>${item?.Criteria05_511?.SName}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>${item?.Criteria05_511?.Amount}</td>
            </tr>
          </table>
        `;
        let tableMarkup2 = `
          <table>
            <tr>
              <td>Number of students</td>
              <td>${item?.Criteria05_511?.SName1}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>${item?.Criteria05_511?.Amount1}</td>
            </tr>
          </table>
        `;
        let tableMarkup3 = `
        <table>
          <tr>
            <td>Name of the Activity</td>
            <td>${item?.Criteria05_514?.Nameact}</td>
          </tr>
          <tr>
            <td>Number of students attended / participated</td>
            <td>${item?.Criteria05_514?.NamePart}</td>
          </tr>
        </table>
      `;
        let obj = {
          //  Criteria 5_511

          Year: item?.Year,

          "Criteria_(5.1.1)": item?.Criteria05_511?.criteria,
          "Name of the scheme": item?.Criteria05_511?.NameS,
          "Number of students benefited by government scheme and amount":
            tableMarkup1,
          "Number of students benefited by the institution's schemes and amount":
            tableMarkup2,
          "Description(min 500 words)": item?.Criteria05_511?.Desc21,
          "Upload the relevant document": item?.Criteria05_511?.Link21,

          // Criteria 5_513

          "Criteria_5.1.3": item?.Criteria05_513?.criteria,
          "Name of the capacity development and skills enhancement program":
            item?.Criteria05_513?.NamCap,
          "Date of implementation": moment(item?.Criteria05_513?.IDate).format(
            "DD-MM-YYYY"
          ),
          "Number of students enrolled": item?.Criteria05_513?.StdEnr,
          "Name of the agencies/consultants involved with contact details (if any)":
            item?.Criteria05_513?.AgeName,
          "Description(min 500 words)": item?.Criteria05_513?.Desc22,
          "Upload the relevant document": item?.Criteria05_513?.Link22,

          // Criteria 5_514
          "Criteria_5.1.4": item?.Criteria05_514?.criteria,
          "Number of students placed through campus placement":
            item?.Criteria05_514?.CampName,
          "Name of the Activity conducted by the HEI to offer guidance for competitive examinations/ career counseling offered by the institution during the last five years":
            tableMarkup3,

          "Description (min 500 words)(5.1.4)": item?.Criteria05_514?.Desc23,
          "Upload the relevant document(5.1.4)": item?.Criteria05_514?.Link23,

          // Criteria 5_521
          "Criteria_5.2.1": item?.Criteria05_521?.criteria,
          "Name of student placed and contact details":
            item?.Criteria05_521?.PlaName,
          "Program graduated from": item?.Criteria05_521?.Graduate,
          "Name of the employer with contact details":
            item?.Criteria05_521?.ConDetails,
          "Pay package at appointment (In INR per annum)":
            item?.Criteria05_521?.Pay,
          "Description (min 500 words)(5.2.1)": item?.Criteria05_521?.Desc24,
          "Upload the relevant document(5.2.1)": item?.Criteria05_521?.Link24,

          // Criteria 5_522
          "Criteria_5.2.2": item?.Criteria05_522?.criteria,
          "Name of student enrolling into higher education":
            item?.Criteria05_522?.HighName,
          "Program Graduated from": item?.Criteria05_522?.PGrad,
          "Name of institution joined": item?.Criteria05_522?.Institute,
          "Name of programme admitted to": item?.Criteria05_522?.Admitted,
          "Description (min 500 words)(5.2.2)": item?.Criteria05_522?.Desc25,
          "Upload the relevant document(5.2.2)": item?.Criteria05_522?.Link25,

          // Criteria 5_523
          "Criteria_5.2.3": item?.Criteria05_523?.criteria,
          "Sl.no.": item?.Criteria05_523?.No1,
          "Registration number/roll number for the exam":
            item?.Criteria05_523?.RollNo1,
          "Names of students selected/ qualified":
            item?.Criteria05_523?.StdSelected1,
          NET: item?.Criteria05_523?.NET1,
          SLET: item?.Criteria05_523?.SLET1,
          GATE: item?.Criteria05_523?.GATE1,
          GMAT: item?.Criteria05_523?.GMAT1,
          CAT: item?.Criteria05_523?.CAT1,
          GRE: item?.Criteria05_523?.GRE1,
          JAM: item?.Criteria05_523?.JAM1,
          IELTS: item?.Criteria05_523?.IELTS1,
          TOEFL: item?.Criteria05_523?.TOEFL1,
          "Civil Services": item?.Criteria05_523?.Civil1,
          "State government examinations": item?.Criteria05_523?.SGExam1,
          "Other examinations conducted by the State / Central Government Agencies (Specify)":
            item?.Criteria05_523?.OtherExam1,
          Total: item?.Criteria05_523?.Total1,
          "Grand Total": item?.Criteria05_523?.GTotal1,
          "Description (min 500 words)(5.2.3)": item?.Criteria05_523?.Desc26,
          "Upload the relevant document(5.2.3)": item?.Criteria05_523?.Link26,

          // Criteria 5_531
          "Criteria_5.3.1": item?.Criteria05_531?.criteria,
          "Name of the award/ medal": item?.Criteria05_531?.Meda,
          "Team / Individual": item?.Criteria05_531?.TIndi,
          "University/State/National/ International1":
            item?.Criteria05_531?.USNI1,
          "Sports/ Cultural": item?.Criteria05_531?.SCult,
          "Name of the student": item?.Criteria05_531?.NaStd,
          "Description (min 500 words)(5.3.1)": item?.Criteria05_531?.Desc27,
          "Upload the relevant document(5.3.1)": item?.Criteria05_531?.Link27,

          // Criteria 5_533
          "Criteria_5.3.3": item?.Criteria05_533?.criteria,
          "Name of the student participated": item?.Criteria05_533?.NStdParti,
          "Date of event/activity (DD-MM-YYYY)": moment(
            item?.Criteria05_533?.EDate
          ).format("DD-MM-YYYY"),
          "Name of the event/activity": item?.Criteria05_533?.EName,
          "Description (min 500 words)(5.3.3)": item?.Criteria05_533?.Desc28,
          "Upload the relevant document(5.3.3)": item?.Criteria05_533?.Link28,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editCriteria05_511 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc21) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria05_511/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          NameS: NameS,
          SName: SName,
          Amount: Amount,
          SName1: SName1,
          Amount1: Amount1,
          Desc21: Desc21,
          Link21: Link21,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri05details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria05_513 = async (e, id) => {
    e.preventDefault();

    try {
      const minwords = 500;
      const newText = Desc22;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc22) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria05_513/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          NamCap: NamCap,
          IDate: IDate,
          StdEnr: StdEnr,
          AgeName: AgeName,
          Desc22: Desc22,
          Link22: Link22,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri05details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria05_514 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc23) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria05_514/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          CampName: CampName,
          Nameact: Nameact,
          NamePart: NamePart,
          Desc23: Desc23,
          Link23: Link23,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri05details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria05_521 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc24;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc24) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria05_521/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PlaName: PlaName,
          Graduate: Graduate,
          ConDetails: ConDetails,
          Pay: Pay,
          Desc24: Desc24,
          Link24: Link24,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri05details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria05_522 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc25;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc25) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria05_522/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          HighName: HighName,
          PGrad: PGrad,
          Institute: Institute,
          Admitted: Admitted,
          Desc25: Desc25,
          Link25: Link25,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri05details();
          handleClose();
          setedit5(false);
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria05_523 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc26;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc26) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria05_523/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          No1: No1,
          RollNo1: RollNo1,
          StdSelected1: StdSelected1,
          NET1: NET1,
          SLET1: SLET1,
          GATE1: GATE1,
          GMAT1: GMAT1,
          CAT1: CAT1,
          GR1E: GRE1,
          JAM1: JAM1,

          IELTS1: IELTS1,
          TOEFL1: TOEFL1,
          Civil1: Civil1,
          SGExam1: SGExam1,
          OtherExam1: OtherExam1,
          Total1: Total1,
          GTotal1: GTotal1,
          Desc26: Desc26,
          Link26: Link26,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri05details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria05_531 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc27;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc27) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria05_531/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Meda: Meda,
          TIndi: TIndi,
          USNI1: USNI1,
          SCult: SCult,
          NaStd: NaStd,
          Desc27: Desc27,
          Link27: Link27,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri05details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria05_533 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc28;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc28) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria05_533/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          NStdParti: NStdParti,
          EDate: EDate,
          EName: EName,
          Desc28: Desc28,
          Link28: Link28,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri05details();
          setedit8(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const [AllDetails, setAllDetails] = useState([]);
  const getcri05details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getcri05details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria05);
        setUG_Criteria05(res.data.ugcriteria05);
        setdata1(res.data.ugcriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria05);
    }
  };

  useEffect(() => {
    getcri05details();
  }, []);
  console.log(Value);

  // =================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  return (
    <div>
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                UnderGraduate_Criteria-05
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/ugcriteria005">
                <button className="btn btn-success">
                  Add Criteria 5{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Name of the scheme</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the capacity development and skills enhancement
                    program
                  </th>
                  <th>Date of implementation</th>
                  <th>Number of students enrolled</th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Number of students placed through campus placement</th>
                  <th>Name of the Activity</th>
                  <th>Number of students attended / participated</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student placed and contact details</th>
                  <th>Program graduated from</th>
                  <th>Name of the employer with contact details</th>
                  <th>Pay package at appointment (In INR per annum)</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student enrolling into higher education</th>
                  <th>Program graduated from</th>
                  <th>Name of institution joined</th>
                  <th>Name of programme admitted to</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Registration number/roll number for the exam</th>
                  <th>Names of students selected/ qualified</th>
                  <th>NET</th>
                  <th>SLET</th>
                  <th>GATE</th>
                  <th>GMAT</th>
                  <th>CAT</th>
                  <th>GRE</th>
                  <th>JAM</th>
                  <th>IELTS</th>
                  <th>TOEFL</th>
                  <th>Civil Services</th>
                  <th>State government examinations</th>
                  <th>
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </th>
                  <th>Total</th>
                  <th>Grand Total</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>University/State/National/ International</th>
                  <th>Sports/ Cultural</th>
                  <th>Name of the student</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the student participated</th>
                  <th>Date of event/activity (DD-MM-YYYY)</th>
                  <th>Name of the event/activity</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {UG_Criteria05?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Criteria05_511?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_511?.NameS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_511?.SName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.Amount?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.SName1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.Amount1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_513?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_513?.NamCap?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Criteria05_513?.IDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_513?.StdEnr?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_513?.AgeName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_513?.Desc22?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_514?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_514?.CampName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_514?.Nameact?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_514?.NamePart?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_514?.Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_521?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_521?.PlaName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_521?.Graduate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_521?.ConDetails?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_521?.Pay?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_521?.Desc24?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_522?.HighName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.PGrad?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.Institute?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.Admitted?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.Desc25?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_523?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_523?.No1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.RollNo1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.StdSelected1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_523?.NET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.SLET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GATE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GMAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.CAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GRE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.JAM1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.IELTS1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.TOEFL1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.Civil1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.SGExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.OtherExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GTotal1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.Desc26?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_531?.Meda?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.TIndi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.USNI1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.SCult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.NaStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.Desc27?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_533?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_533?.NStdParti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Criteria05_533?.EDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_533?.EName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_533?.Desc28?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Criteria05_511?.criteria}</td>
                      <td>{item?.Criteria05_511?.NameS}</td>
                      <td>{item?.Criteria05_511?.SName}</td>
                      <td>{item?.Criteria05_511?.Amount}</td>
                      <td>{item?.Criteria05_511?.SName1}</td>
                      <td>{item?.Criteria05_511?.Amount1}</td>
                      <td>
                        {item?.Criteria05_511?.Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_513?.criteria}</td>
                      <td>{item?.Criteria05_513?.NamCap}</td>
                      <td>
                        {moment(item?.Criteria05_513?.IDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Criteria05_513?.StdEnr}</td>
                      <td>{item?.Criteria05_513?.AgeName}</td>
                      <td>
                        {item?.Criteria05_513?.Desc22?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_514?.criteria}</td>
                      <td>{item?.Criteria05_514?.CampName}</td>
                      <td>{item?.Criteria05_514?.Nameact}</td>
                      <td>{item?.Criteria05_514?.NamePart}</td>
                      <td>
                        {item?.Criteria05_514?.Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_521?.criteria}</td>
                      <td>{item?.Criteria05_521?.PlaName}</td>
                      <td>{item?.Criteria05_521?.Graduate}</td>
                      <td>{item?.Criteria05_521?.ConDetails}</td>
                      <td>{item?.Criteria05_521?.Pay}</td>
                      <td>
                        {item?.Criteria05_521?.Desc24?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_522?.criteria}</td>
                      <td>{item?.Criteria05_522?.HighName}</td>
                      <td>{item?.Criteria05_522?.PGrad}</td>
                      <td>{item?.Criteria05_522?.Institute}</td>
                      <td>{item?.Criteria05_522?.Admitted}</td>
                      <td>
                        {item?.Criteria05_522?.Desc25?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_523?.criteria}</td>
                      <td>{item?.Criteria05_523?.No1}</td>
                      <td>{item?.Criteria05_523?.RollNo1}</td>
                      <td>{item?.Criteria05_523?.StdSelected1}</td>
                      <td>{item?.Criteria05_523?.NET1}</td>
                      <td>{item?.Criteria05_523?.SLET1}</td>
                      <td>{item?.Criteria05_523?.GATE1}</td>
                      <td>{item?.Criteria05_523?.GMAT1}</td>
                      <td>{item?.Criteria05_523?.CAT1}</td>
                      <td>{item?.Criteria05_523?.GRE1}</td>
                      <td>{item?.Criteria05_523?.JAM1}</td>
                      <td>{item?.Criteria05_523?.IELTS1}</td>
                      <td>{item?.Criteria05_523?.TOEFL1}</td>
                      <td>{item?.Criteria05_523?.Civil1}</td>
                      <td>{item?.Criteria05_523?.SGExam1}</td>
                      <td>{item?.Criteria05_523?.OtherExam1}</td>
                      <td>{item?.Criteria05_523?.Total1}</td>
                      <td>{item?.Criteria05_523?.GTotal1}</td>
                      <td>
                        {item?.Criteria05_523?.Desc26?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_531?.criteria}</td>
                      <td>{item?.Criteria05_531?.Meda}</td>
                      <td>{item?.Criteria05_531?.TIndi}</td>
                      <td>{item?.Criteria05_531?.USNI1}</td>
                      <td>{item?.Criteria05_531?.SCult}</td>
                      <td>{item?.Criteria05_531?.NaStd}</td>
                      <td>
                        {item?.Criteria05_531?.Desc27?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_533?.criteria}</td>
                      <td>{item?.Criteria05_533?.NStdParti}</td>
                      <td>
                        {moment(item?.Criteria05_533?.EDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Criteria05_533?.EName}</td>
                      <td>
                        {item?.Criteria05_533?.Desc28?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={(e) => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            setedit6(false);
            setedit7(false);
            setedit8(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              {" "}
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                UnderGraduate_Criteria-05
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 m-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scheme
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_511?.NameS}
                        onChange={(e) => {
                          setNameS(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_511?.NameS}</>
                  )}
                </div>

                <h6 className="text-center text-decoration-underline">
                  (Number of students benefited by government scheme and amount){" "}
                </h6>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_511?.SName}
                        onChange={(e) => {
                          setSName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_511?.SName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_511?.Amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_511?.Amount}</>
                  )}
                </div>

                <h6 className="text-center text-decoration-underline">
                  (Number of students benefited by the institution's schemes and
                  amount){" "}
                </h6>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_511?.SName1}
                        onChange={(e) => {
                          setSName1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_511?.SName1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_511?.Amount1}
                        onChange={(e) => {
                          setAmount1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_511?.Amount1}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_511?.Desc21}
                        onChange={(e) => setDesc21(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_511?.Desc21}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        placeholder={Value?.Criteria05_511?.Link21}
                        onChange={(e) => setLink21(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_511?.Link21}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria05_511(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    Name of the capacity development and skills enhancement
                    program
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_513?.NamCap}
                        onChange={(e) => {
                          setNamCap(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_513?.NamCap}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of implementation
                  </label>{" "}
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_513?.IDate}
                        onChange={(e) => {
                          setIDate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_513?.IDate}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students enrolled
                  </label>{" "}
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_513?.StdEnr}
                        onChange={(e) => {
                          setStdEnr(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_513?.StdEnr}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </label>{" "}
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_513?.AgeName}
                        onChange={(e) => {
                          setAgeName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_513?.AgeName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_513?.Desc22}
                        onChange={(e) => setDesc22(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_513?.Desc22}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        placeholder={Value?.Criteria05_513?.Link22}
                        onChange={(e) => setLink22(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_513?.Link22}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit2 ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={(e) => {
                              editCriteria05_513(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.4
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    Number of students placed through campus placement
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_514?.CampName}
                        onChange={(e) => {
                          setCampName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_514?.CampName}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  (Name of the Activity conducted by the HEI to offer guidance
                  for competitive examinations/ career counseling offered by the
                  institution during the last five years)
                </h6>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the Activity
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_514?.Nameact}
                        onChange={(e) => {
                          setNameact(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_514?.Nameact}</>
                  )}
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students attended / participated
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_514?.NamePart}
                        onChange={(e) => {
                          setNamePart(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_514?.NamePart}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_514?.Desc23}
                        onChange={(e) => setDesc23(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_514?.Desc23}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3 "
                        placeholder={Value?.Criteria05_514?.Link23}
                        onChange={(e) => setLink23(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_514?.Link23}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit3 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria05_514(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student placed and contact details
                  </label>{" "}
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_521?.PlaName}
                        onChange={(e) => {
                          setPlaName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_521?.PlaName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the employer with contact details
                  </label>{" "}
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_521?.Graduate}
                        onChange={(e) => {
                          setGraduate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_521?.Graduate}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>{" "}
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_521?.ConDetails}
                        onChange={(e) => {
                          setConDetails(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_521?.ConDetails}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Pay package at appointment (In INR per annum)
                  </label>{" "}
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_521?.Pay}
                        onChange={(e) => {
                          setPay(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_521?.Pay}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_521?.Desc24}
                        onChange={(e) => setDesc24(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_521?.Desc24}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        placeholder={Value?.Criteria05_521?.Link24}
                        onChange={(e) => setLink24(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_521?.Link24}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit4 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria05_521(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student enrolling into higher education
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_522?.HighName}
                        onChange={(e) => {
                          setHighName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_522?.HighName}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>{" "}
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_522?.PGrad}
                        onChange={(e) => {
                          setPGrad(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_522?.PGrad}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of institution joined
                  </label>{" "}
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_522?.Institute}
                        onChange={(e) => {
                          setInstitute(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_522?.Institute}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of programme admitted to
                  </label>{" "}
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_522?.Admitted}
                        onChange={(e) => {
                          setAdmitted(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_522?.Admitted}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_522?.Desc25}
                        onChange={(e) => setDesc25(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_522?.Desc25}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload5"
                        accept="image/*"
                        placeholder={Value?.Criteria05_522?.Link25}
                        onChange={(e) => {
                          setLink25(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_522?.Link25}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit5 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria05_522(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Sl.no.
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.No1}
                        onChange={(e) => {
                          setNo1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.No1}</>
                  )}
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Registration number/roll number for the exam
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.RollNo1}
                        onChange={(e) => {
                          setRollNo1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.RollNo1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Names of students selected/ qualified
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.StdSelected1}
                        onChange={(e) => {
                          setStdSelected1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.StdSelected1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    NET
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.NET1}
                        onChange={(e) => {
                          setNET1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.NET1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    SLET
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.SLET1}
                        onChange={(e) => {
                          setSLET1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.SLET1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GATE
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.GATE1}
                        onChange={(e) => {
                          setGATE1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.GATE1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GMAT
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.GMAT1}
                        onChange={(e) => {
                          setGMAT1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.GMAT1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    CAT
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.CAT1}
                        onChange={(e) => {
                          setCAT1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.CAT1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GRE
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.GRE1}
                        onChange={(e) => {
                          setGRE1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.GRE1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    JAM
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.JAM1}
                        onChange={(e) => {
                          setJAM1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.JAM1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    IELTS
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.IELTS1}
                        onChange={(e) => {
                          setIELTS1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.IELTS1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TOEFL
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.TOEFL1}
                        onChange={(e) => {
                          setTOEFL1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.TOEFL1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Civil Services
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.Civil1}
                        onChange={(e) => {
                          setCivil1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.Civil1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State government examinations
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.SGExam1}
                        onChange={(e) => {
                          setSGExam1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.SGExam1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.OtherExam1}
                        onChange={(e) => {
                          setOtherExam1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.OtherExam1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.Total1}
                        onChange={(e) => {
                          setTotal1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.Total1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Grand Total
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_523?.GTotal1}
                        onChange={(e) => {
                          setGTotal1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.GTotal1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_523?.Desc26}
                        onChange={(e) => setDesc26(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.Desc26}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload6"
                        accept="image/*"
                        placeholder={Value?.Criteria05_523?.Link26}
                        onChange={(e) => {
                          setLink26(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_523?.Link26}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit6 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria05_523(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit6(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/ medal
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_531?.Meda}
                        onChange={(e) => {
                          setMeda(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_531?.Meda}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Team / Individual
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_531?.TIndi}
                        onChange={(e) => {
                          setTIndi(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_531?.TIndi}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    University/State/National/ International
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_531?.USNI1}
                        onChange={(e) => {
                          setUSNI1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_531?.USNI1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Sports/ Cultural
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_531?.SCult}
                        onChange={(e) => {
                          setSCult(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_531?.SCult}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria05_531?.NaStd}
                        onChange={(e) => {
                          setNaStd(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_531?.NaStd}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_531?.Desc27}
                        onChange={(e) => setDesc27(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_531?.Desc27}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload7"
                        accept="image/*"
                        placeholder={Value?.Criteria05_531?.Link27}
                        onChange={(e) => {
                          setLink27(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_531?.Link27}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit7 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria05_531(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit7(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student participated
                  </label>{" "}
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_533?.NStdParti}
                        onChange={(e) => setNStdParti(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_533?.NStdParti}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date of event/competition (DD-MM-YYYY)
                  </label>{" "}
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_533?.EDate}
                        onChange={(e) => setEDate(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.Criteria05_533?.EDate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the event/competition
                  </label>{" "}
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_533?.EName}
                        onChange={(e) => setEName(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_533?.EName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit8 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria05_533?.Desc28}
                        onChange={(e) => setDesc28(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_533?.Desc28}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload8"
                        accept="image/*"
                        placeholder={Value?.Criteria05_533?.Link28}
                        onChange={(e) => {
                          setLink28(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria05_533?.Link28}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit8 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            onClick={(e) => {
                              editCriteria05_533(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit8(true)}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={(e) => {
                handleClose();
                setedit1(false);
                setedit2(false);
                setedit3(false);
                setedit4(false);
                setedit5(false);
                setedit6(false);
                setedit7(false);
                setedit8(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria05_511?.Desc21}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria05_513?.Desc22}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria05_514?.Desc23}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close6
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria05_521?.Desc24}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria05_522?.Desc25}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria05_523?.Desc26}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria05_531?.Desc27}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_8 */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria05_533?.Desc28}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_511?.Link21}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_513?.Link22}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_514?.Link23}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_521?.Link24}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_522?.Link25}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_523?.Link26}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_531?.Link27}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_533?.Link28}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default UG_Criteria05;
