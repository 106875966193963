import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PG_Criteria004 = () => {
  let librarianDetails = JSON.parse(sessionStorage.getItem("Luser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  //post method for PG_criteria_04
  const [PG_Year, setPG_Year] = useState("");

  const [PG_Room, setPG_Room] = useState("");
  const [PG_Type, setPG_Type] = useState("");
  const [PG_Desc1, setPG_Desc1] = useState("");
  const [PG_Link1, setPG_Link1] = useState("");

  const [PG_Budget, setPG_Budget] = useState("");
  const [PG_Expenditure, setPG_Expenditure] = useState("");
  const [PG_Total, setPG_Total] = useState("");
  const [PG_Expend1, setPG_Expend1] = useState("");
  const [PG_Expend2, setPG_Expend2] = useState("");
  const [PG_Desc2, setPG_Desc2] = useState("");
  const [PG_Link2, setPG_Link2] = useState("");

  const [PG_Library, setPG_Library] = useState("");
  const [PG_IfYes, setPG_IfYes] = useState("");
  const [PG_Expend3, setPG_Expend3] = useState("");
  const [PG_Total1, setPG_Total1] = useState("");
  const [PG_Other, setPG_Other] = useState("");
  const [PG_Desc3, setPG_Desc3] = useState("");
  const [PG_Link3, setPG_Link3] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getpgcriteria4 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpgcriteria4();
  }, []);

  const pgcriteria4 = async () => {
    if (!PG_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/LibraryAdmin/pgadddetails04",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Year: PG_Year,

          PG_Criteria04_413: obj1,
          PG_Link1: PG_Link1,

          PG_Criteria04_414: obj2,
          PG_Link2: PG_Link2,

          PG_Criteria04_422: obj3,
          PG_Link3: PG_Link3,

          addedByFac: librarianDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          navigate("/pgcriteria04");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add1) {
        if (!PG_Room || !PG_Type || !PG_Desc1 || !PG_Link1) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", PG_Link1);
      let obj = {
        PG_criteria: "4.1.3",
        PG_Room: PG_Room,
        PG_Type: PG_Type,
        PG_Desc1: PG_Desc1,
        PG_Link1: PG_Link1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getpgcriteria4();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add2) {
        if (
          !PG_Budget ||
          !PG_Expenditure ||
          !PG_Total ||
          !PG_Expend1 ||
          !PG_Expend2 ||
          !PG_Desc2 ||
          !PG_Link2
        ) {
          return alert("Please fill all the details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", PG_Link2);
      let obj = {
        PG_criteria: "4.1.4 & 4.4.1",
        PG_Budget: PG_Budget,
        PG_Expenditure: PG_Expenditure,
        PG_Total: PG_Total,
        PG_Expend1: PG_Expend1,
        PG_Expend2: PG_Expend2,
        PG_Desc2: PG_Desc2,
        PG_Link2: PG_Link2,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getpgcriteria4();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add3) {
        if (
          !PG_Library ||
          !PG_IfYes ||
          !PG_Expend3 ||
          !PG_Total1 ||
          !PG_Total1 ||
          !PG_Desc3 ||
          !PG_Link3
        ) {
          return alert("Please fill all the details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", PG_Link3);
      let obj = {
        PG_criteria: "4.2.2 & 4.2.3",
        PG_Library: PG_Library,
        PG_IfYes: PG_IfYes,
        PG_Expend3: PG_Expend3,
        PG_Total1: PG_Total1,
        PG_Other: PG_Other,
        PG_Desc3: PG_Desc3,
        PG_Link3: PG_Link3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getpgcriteria4();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    librarianDetails = JSON.parse(sessionStorage.getItem("Luser"));
    if (!librarianDetails) {
      alert("Please Login first");
      window.location.assign("/llogin");
    } else {
      getpgcriteria4();
      getAllYears();
    }
  }, []);
  return (
    <div>
      <div className="mt-4" style={{ padding: "10px" }}>
        <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
          PostGraduate_Criteria-04
        </span>
      </div>

      <div className="container mt-4">
        <div
          className="row-fluid border bs"
          style={{
            padding: "30px",
          }}
        >
          <div class=" col-md-6">
            <label for="" class="form-label">
              Select Year
            </label>
            {obj1?.PG_Year ? (
              <p>{obj1?.PG_Year}</p>
            ) : (
              <select
                class="form-select form-select-sm vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setPG_Year(e.target.value)}
              >
                <option selected>--Select Year--</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "30px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>PG_Criteria04-(4.1.3)</h5>

            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Room number or Name of Classrooms and Seminar halls with
                  ICT-enabled facilities
                </label>
                {obj1?.PG_Room ? (
                  <p>{obj1?.PG_Room}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setPG_Room(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Type of ICT facility
                </label>
                {obj1?.PG_Type ? (
                  <p>{obj1?.PG_Type}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setPG_Type(e.target.value)}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description
                </label>
                {obj1?.PG_Desc1 ? (
                  <p>{obj1?.PG_Desc1}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc1(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link1(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="mt-3 text-end">
              {add1 ? (
                <Button
                  className="btn btn-success"
                  type="submit"
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </Button>
              ) : (
                <>
                  <Button className="btn btn-warning" onClick={handleShow}>
                    Preview
                  </Button>
                </>
              )}
            </div>
            <Modal
              size=""
              show={show}
              onHide={() => {
                handleClose();
                setedit1(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>PG_Criteria04_413</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Room number or Name of Classrooms and Seminar halls with
                      ICT-enabled facilities
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Room}
                          onChange={(e) => setPG_Room(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Room}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Type of ICT facility
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Type}
                          onChange={(e) => setPG_Type(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Type}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc1}
                          onChange={(e) => setPG_Desc1(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload1"
                          accept="image/*"
                          onChange={(e) => setPG_Link1(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link1.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit1 ? (
                  <>
                    <Button
                      className="btn btn-success"
                      onClick={() => {
                        cat1();
                        handleClose();
                        setedit1(false);
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit1(true)}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border bs "
            style={{
              padding: "30px",
              borderRadius: "5px",
            }}
          >
            <h5>PG_Criteria04-(4.1.4 & 4.4.1)</h5>
            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Budget allocated for infrastructure augmentation (INR in Lakh)
                </label>
                {obj2?.PG_Budget ? (
                  <p>{obj2?.PG_Budget}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_Budget(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Expenditure for infrastructure augmentation (INR in Lakh)
                </label>
                {obj2?.PG_Expenditure ? (
                  <p>{obj2?.PG_Expenditure}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_Expenditure(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total expenditure excluding Salary (INR in Lakh)
                </label>
                {obj2?.PG_Total ? (
                  <p>{obj2?.PG_Total}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Total(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Expenditure on maintenance of academic facilities (excluding
                  salary for human resources) (INR in Lakh)
                </label>
                {obj2?.PG_Expend1 ? (
                  <p>{obj2?.PG_Expend1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Expend1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Expenditure on maintenance of physical facilities (excluding
                  salary for human resources) (INR in Lakh)
                </label>
                {obj2?.PG_Expend2 ? (
                  <p>{obj2?.PG_Expend2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Expend2(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description
              </label>
              {obj2?.PG_Desc2 ? (
                <p>{obj2?.PG_Desc2}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setPG_Desc2(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Document
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="upload2"
                id="upload2"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link2(e.target.files[0]);
                }}
              />
            </div>

            <div className="mt-3 text-end">
              {add2 ? (
                <Button
                  type="submit"
                  className="btn btn-success mt-3"
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </Button>
              ) : (
                <>
                  <Button className="btn btn-warning" onClick={handleShow1}>
                    Preview
                  </Button>
                </>
              )}
            </div>
            <Modal
              size=""
              show={show1}
              onHide={() => {
                handleClose1();
                setedit2(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>PG_Criteria04_414 & 441</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Budget allocated for infrastructure augmentation (INR in
                      Lakh)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_Budget}
                          onChange={(e) => {
                            setPG_Budget(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Budget}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Expenditure for infrastructure augmentation (INR in Lakh)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_Expenditure}
                          onChange={(e) => {
                            setPG_Expenditure(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Expenditure}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total expenditure excluding Salary (INR in Lakh)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Total}
                          onChange={(e) => setPG_Total(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Total}</>
                    )}
                  </div>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Expenditure on maintenance of academic facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={PG_Expend1}
                        onChange={(e) => setPG_Expend1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{PG_Expend1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Expenditure on maintenance of physical facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={PG_Expend2}
                        onChange={(e) => setPG_Expend2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{PG_Expend2}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    Description
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={PG_Desc2}
                        onChange={(e) => setPG_Desc2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{PG_Desc2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        onChange={(e) => setPG_Link2(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{PG_Link2?.name}</>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit2 ? (
                  <>
                    <Button
                      className="btn btn-success"
                      onClick={() => {
                        cat2();
                        handleClose1();
                        setedit2(false);
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit2(true)}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "30px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>PG_Criteria04-(4.2.2 & 4.2.3)</h5>
            <h6>Average enrollement percentage(Average of last five years)</h6>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Library resources
                </label>

                {obj3?.PG_Library ? (
                  <p>{obj3?.PG_Library}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setPG_Library(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="Books">Books</option>
                    <option value="Journals ">Journals </option>
                    <option value="e–journals ">e–journals </option>
                    <option value="e-books">e-books</option>
                    <option value="e-ShodhSindhu">e-ShodhSindhu</option>
                    <option value="Shodhganga">Shodhganga</option>
                    <option value="Databases">Databases</option>
                    <option value="Local_and_Remote_access_to_e-_resources_(Specify)">
                      Local_and_Remote_access_to_e-_resources_(Specify)
                    </option>
                  </select>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  If yes, details of Memberships/Subscriptions
                </label>
                {obj3?.PG_IfYes ? (
                  <p>{obj3?.PG_IfYes}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_IfYes(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Expenditure on subscription to e-journals, e-books (INR in
                  lakhs)
                </label>
                {obj3?.PG_Expend3 ? (
                  <p>{obj3?.PG_Expend3}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_Expend3(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Expenditure on subscription to other e-resources (INR in
                  lakhs)
                </label>
                {obj3?.PG_Other ? (
                  <p>{obj3?.PG_Other}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_Other(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1"> Total Library Expenditure</label>
                {obj3?.PG_Total1 ? (
                  <p>{obj3?.PG_Total1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_Total1(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description
                </label>
                {obj3?.PG_Desc3 ? (
                  <p>{obj3?.PG_Desc3}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc3(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Document
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload3"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link3(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="text-end mt-3">
              {add3 ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </Button>
              ) : (
                <>
                  {" "}
                  <Button className="btn btn-warning" onClick={handleShow2}>
                    Preview
                  </Button>
                </>
              )}
            </div>
            <Modal
              size=""
              show={show2}
              onHide={() => {
                handleClose2();
                setedit3(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>PG_Criteria04_422 & 423</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Library resources
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={PG_Library}
                          onChange={(e) => setPG_Library(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="Books">Books</option>
                          <option value="Journals ">Journals </option>
                          <option value="e – journals ">e – journals </option>
                          <option value="e-books">e-books</option>
                          <option value="e-ShodhSindhu">e-ShodhSindhu</option>
                          <option value="Shodhganga">Shodhganga</option>
                          <option value="Databases">Databases</option>
                          <option value="Local and / or Remote access to e- resources (Specify)">
                            e-ShodhSindhu
                          </option>
                        </select>
                      </>
                    ) : (
                      <>{PG_Library}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      If yes, details of Memberships/Subscriptions
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_IfYes}
                          onChange={(e) => {
                            setPG_IfYes(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_IfYes}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Expenditure on subscription to e-journals, e-books (INR in
                      lakhs)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Expend3}
                          onChange={(e) => {
                            setPG_Expend3(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Expend3}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Expenditure on subscription to other e-resources (INR in
                      lakhs)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Other}
                          onChange={(e) => {
                            setPG_Other(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Other}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Total Library Expenditure
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Total1}
                          onChange={(e) => {
                            setPG_Total1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Total1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc3}
                          onChange={(e) => setPG_Desc3(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc3}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="upload3 "
                          onChange={(e) => setPG_Link3(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link3?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit3 ? (
                  <>
                    <Button
                      className=" btn btn-success"
                      style={{ color: "black" }}
                      onClick={() => {
                        cat3();
                        handleClose2();
                        setedit3(false);
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit3(true)}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <Button
            type="submit"
            className="btn btn-success"
            onClick={() => pgcriteria4()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PG_Criteria004;
