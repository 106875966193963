import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete, AiOutlineDelete, AiOutlineSave } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsImages } from "react-icons/bs";
import { Link } from "react-router-dom";

function AdminLibrary() {
  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================ add campus details modal===============================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================ delete campus details modal===============================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================ edit campus details modal===============================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================ show image modal===============================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================ show specific image modal===============================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================ update specific image modal===============================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================ update specific image modal===============================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================ add specific image modal===============================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [SpecificImg, setSpecificImg] = useState({});
  //   const [SpecificImgId, setSpecificImgId] = useState("");
  // =================== calling api for adding the campus content======================
  let formdata = new FormData();
  const [imgs, setimgs] = useState("");
  const [speciImg, setspeciImg] = useState("");
  const [description, setdescription] = useState("");
  const [heading, setheading] = useState("");
  const [imgid, setimgid] = useState("");

  const [View, setView] = useState({});

  // function addLinks() {
  //   try {
  //     if (!imgid || !link) {
  //       return alert("please add link name and their respective link.");
  //     }

  //     setlinksAvail((curr) => [
  //       ...curr,
  //       {
  //         imgid: imgid,
  //         link: link,
  //       },
  //     ]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const AddLibrDetails = async (e) => {
    e.preventDefault();
    if (!imgs || !heading || !description) {
      alert("Please fill all fields");
    }
    let obj = {
      imgs: imgs,
      heading: heading,
      description: description,
    };

    try {
      const config = {
        url: "/admin/addAdminLibr",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          getLibrList();
          setimgs("");
          setdescription("");
          setheading("");
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the Leadership content======================
  const [libList, setlibList] = useState([]);
  const getLibrList = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getAdminLibr"
      );
      if (res.status === 200) {
        setlibList(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setlibList(error.response.data.allContent);
    }
  };

  // =================== calling api for getting the specific campus content======================
  const getLibrListById = async (id) => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getAdminLibrById/" + id
      );
      if (res.status === 200) {
        setView(res.data.Content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================== calling api for deleting the Leadership content======================
  const deleteContent = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/deleteAdminLibr/" + View?._id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setimgs("");
          setdescription("");
          setheading("");
          getLibrList();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };

  // =================== calling api for editing the Leadership content======================
  const editContent = async (e) => {
    e.preventDefault();
    if (!description && !heading) {
      return alert("Please Provide atleast one detail to edit!!!");
    }
    try {
      const config = {
        url: "/admin/editAdminLibr/" + View?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          description: description,
          heading: heading,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getLibrList();
          setimgs("");
          setdescription("");
          setheading("");
          handleClose2();
          handleClose6();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  // ======================calling api for editing=================
  const EditImgs = async (e, state, id) => {
    e.preventDefault();
    if (!speciImg) {
      return alert("Please add an Image !!!");
    }
    let obj = {
      imgid: id,
      imgs: speciImg,
      state: state,
    };
    try {
      const config = {
        url: "/admin/editImgs/" + View?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getLibrListById(View?._id);
          getLibrList();
          setimgid("");
          setimgs("");
          handleClose4();
          handleClose5();
          handleClose6();
          handleClose7();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getLibrList();
  }, []);
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Library
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            {libList?.length > 0 ? (
              <></>
            ) : (
              <Button onClick={handleShow}>Add</Button>
            )}
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Heading</th>
                <th>Description</th>
                <th>Images</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {libList?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>{item?.heading}</td>
                    <td>
                      {item?.description?.slice(0, 70)}
                      ...
                      <Link
                        onClick={() => {
                          handleShow10();
                          setView(item);
                        }}
                      >
                        Read More...
                      </Link>
                    </td>

                    <td>
                      <BsImages
                        size={20}
                        onClick={() => {
                          setView(item);
                          handleShow3();
                        }}
                      />
                    </td>

                    <td>
                      <div className="d-flex align-items-center justify-content-evenly">
                        <div className="">
                          <span
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              margin: "10px",
                            }}
                          >
                            <BiEdit
                              size={20}
                              onClick={() => {
                                setView(item);
                                //   getLibrListById(item?._id);
                                handleShow2();
                              }}
                            />
                          </span>
                        </div>
                        <div className="">
                          <span
                            onClick={() => {
                              setView(item);
                              // getLibrListById(item?._id);
                              handleShow1();
                            }}
                            style={{
                              cursor: "pointer",
                              color: "red",
                              margin: "10px",
                            }}
                          >
                            <AiFillDelete size={20} />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Show Body */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Body</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.description}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add library details */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Library details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label htmlFor="upload1">
                Images<span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="upload1"
                  name="ImageStyle"
                  className="vi_0"
                  accept="image/*"
                  multiple
                  onChange={(e) => setimgs(e.target.files)}
                />
              </div>
            </div>
            <div className="mb-1">
              <label>
                Heading <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setheading(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-1">
              <label>
                Description <span className="font-css top">*</span>
              </label>
              <div className="">
                <textarea
                  className="vi_0"
                  id="w3review"
                  name="w3review"
                  value={description}
                  rows="4"
                  cols="50"
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddLibrDetails(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Library Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>
                Heading <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.heading}
                  onChange={(e) => setheading(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-1">
              <label>
                Description <span className="font-css top">*</span>
              </label>
              <div className="">
                <textarea
                  className="vi_0"
                  id="w3review"
                  name="w3review"
                  rows="4"
                  cols="50"
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                >
                  {View?.description}
                </textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => editContent(e)}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteContent(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>

        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Images</Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <Table responsive bordered striped>
              <thead
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  height: "40px",
                }}
              >
                <th>S.no.</th>
                <th>Image</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {View?.imgs?.map((item, i) => {
                  return (
                    <tr
                      style={{
                        textAlign: "center",
                        verticalAlign: "center",
                      }}
                    >
                      <td>{++i}</td>
                      <td>
                        <div
                          onClick={() => {
                            setSpecificImg(item);
                            handleShow4();
                          }}
                        >
                          <img
                            src={`https://brightnaac.co.in/AdminLibr/${item?.imgName}`}
                            style={{ width: "30%" }}
                            alt="no-image"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <div>
                            <BiEdit
                              size={20}
                              style={{ margin: "10px", color: "blue" }}
                              onClick={() => {
                                setSpecificImg(item);
                                handleShow5();
                              }}
                            />
                          </div>
                          <div>
                            <AiFillDelete
                              size={20}
                              style={{ margin: "10px", color: "red" }}
                              onClick={() => {
                                setSpecificImg(item);
                                handleShow6();
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end mb-3 p-2">
              <Button
                onClick={(e) => {
                  handleShow7();
                }}
              >
                ADD Image
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
            {/* <span className="header-03">
              <Button onClick={(e) => deleteContent(e)}>Delete</Button>
            </span> */}
          </Modal.Footer>
        </Modal>

        <Modal show={show4} onHide={handleClose4}>
          <Modal.Body>
            <div>
              <img
                src={`https://brightnaac.co.in/AdminLibr/${SpecificImg?.imgName}`}
                style={{ width: "100%" }}
                alt="no-image"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Update Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label htmlFor="upload6">Upload Image</label>
              <input
                type="file"
                name="file"
                id="upload6"
                accept="image/*"
                onChange={(e) => setspeciImg(e.target.files[0])}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => EditImgs(e, "EDIT", SpecificImg?._id)}>
              Edit
            </Button>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Add Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label htmlFor="upload7">Upload Image</label>
              <input
                type="file"
                name="file"
                id="upload7"
                accept="image/*"
                multiple
                onChange={(e) => setspeciImg(e.target.files)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => EditImgs(e, "ADD")}>ADD</Button>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
            <span className="header-03">
              <Button onClick={(e) => EditImgs(e, "DELETE", SpecificImg?._id)}>
                Delete
              </Button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AdminLibrary;
