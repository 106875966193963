import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const PG_Criteria02 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);

  const [PG_PrgCode, setPG_PrgCode] = useState("");
  const [PG_NoSeatSanctioned, setPG_NoSeatSanctioned] = useState("");
  const [PG_NoStdAdmit, setPG_NoStdAdmit] = useState("");
  const [PG_PrgName1, setPG_PrgName1] = useState("");
  const [PG_Desc6, setPG_Desc6] = useState("");
  const [PG_Link6, setPG_Link6] = useState("");

  const [PG_SC, setPG_SC] = useState("");
  const [PG_ST, setPG_ST] = useState("");
  const [PG_OBC, setPG_OBC] = useState("");
  const [PG_Gen, setPG_Gen] = useState("");
  const [PG_Divyangjan, setPG_Divyangjan] = useState("");
  const [PG_Others, setPG_Others] = useState("");
  const [PG_SSC, setPG_SSC] = useState("");
  const [PG_SST, setPG_SST] = useState("");
  const [PG_OOBC, setPG_OOBC] = useState("");
  const [PG_GGen, setPG_GGen] = useState("");
  const [PG_DDivyangjan, setPG_DDivyangjan] = useState("");
  const [PG_OOthers, setPG_OOthers] = useState("");
  const [PG_Desc7, setPG_Desc7] = useState("");
  const [PG_Link7, setPG_Link7] = useState("");

  const [PG_TName, setPG_TName] = useState("");
  const [PG_PAN, setPG_PAN] = useState("");
  const [PG_Designation, setPG_Designation] = useState("");
  const [PG_YOfAppoint, setPG_YOfAppoint] = useState("");
  const [PG_NoOfAppoint, setPG_NoOfAppoint] = useState("");
  const [PG_DeptName, setPG_DeptName] = useState("");
  const [PG_TYofExp, setPG_TYofExp] = useState("");
  const [PG_TService, setPG_TService] = useState("");
  const [PG_Desc8, setPG_Desc8] = useState("");
  const [PG_Link8, setPG_Link8] = useState("");

  const [PG_SerTeacher, setPG_SerTeacher] = useState("");
  const [PG_Qualify, setPG_Qualify] = useState("");
  const [PG_YOfQuali, setPG_YOfQuali] = useState("");
  const [PG_YOfRecog, setPG_YOfRecog] = useState("");
  const [PG_Whether, setPG_Whether] = useState("");
  const [PG_IsThereTeacher, setPG_IsThereTeacher] = useState("");
  const [PG_NameScholar, setPG_NameScholar] = useState("");
  const [PG_YearScholar, setPG_YearScholar] = useState("");
  const [PG_Thesis, setPG_Thesis] = useState("");
  const [PG_Desc9, setPG_Desc9] = useState("");
  const [PG_Link9, setPG_Link9] = useState("");

  const [PG_pCode, setPG_pCode] = useState("");
  const [PG_PrgName2, setPG_PrgName2] = useState("");
  const [PG_NStdAppeared, setPG_NStdAppeared] = useState("");
  const [PG_NStdPassed, setPG_NStdPassed] = useState("");
  const [PG_Desc10, setPG_Desc10] = useState("");
  const [PG_Link10, setPG_Link10] = useState("");

  const [PG_PrgName3, setPG_PrgName3] = useState("");
  const [PG_NStd, setPG_NStd] = useState("");
  const [PG_Gender, setPG_Gender] = useState("");
  const [PG_Category, setPG_Category] = useState("");
  const [PG_SDomicile, setPG_SDomicile] = useState("");
  const [PG_NIndian, setPG_NIndian] = useState("");
  const [PG_Email, setPG_Email] = useState("");
  const [PG_MNo, setPG_MNo] = useState("");
  const [PG_YJoining, setPG_YJoining] = useState("");
  const [PG_UID, setPG_UID] = useState("");
  const [PG_Desc11, setPG_Desc11] = useState("");
  const [PG_Link11, setPG_Link11] = useState("");

  //integrating get method
  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [PG_Criteria02, setPG_Criteria02] = useState([]);
  const [Value, setValue] = useState({});

  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("PG_Cri2.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
        <table>
          <tr>
            <td>SC</td>
            <td>${item?.PG_Criteria02_212?.PG_SC}</td>
          </tr>
          <tr>
            <td>ST</td>
            <td>${item?.PG_Criteria02_212?.PG_ST}</td>
          </tr>
          <tr>
            <td>OBC</td>
            <td>${item?.PG_Criteria02_212?.PG_OBC}</td>
          </tr>
          <tr>
            <td>General</td>
            <td>${item?.PG_Criteria02_212?.PG_Gen}</td>
          </tr>
          <tr>
            <td>Divyangjan</td>
            <td>${item?.PG_Criteria02_212?.PG_Divyangjan}</td>
          </tr>
          <tr>
            <td>Others</td>
            <td>${item?.PG_Criteria02_212?.PG_Others}</td>
          </tr>
        </table>
      `;

        let tableMarkup2 = `
        <table>
          <tr>
            <td>SC</td>
            <td>${item?.PG_Criteria02_212?.PG_SSC}</td>
          </tr>
          <tr>
            <td>ST</td>
            <td>${item?.PG_Criteria02_212?.PG_SST}</td>
          </tr>
          <tr>
            <td>OBC</td>
            <td>${item?.PG_Criteria02_212?.PG_OOBC}</td>
          </tr>
          <tr>
            <td>General</td>
            <td>${item?.PG_Criteria02_212?.PG_GGen}</td>
          </tr>
          <tr>
            <td>Divyangjan</td>
            <td>${item?.PG_Criteria02_212?.PG_DDivyangjan}</td>
          </tr>
          <tr>
            <td>Others</td>
            <td>${item?.PG_Criteria02_212?.PG_OOthers}</td>
          </tr>
        </table>
      `;
        let obj = {
          //  Criteria 2_211

          PG_Year: item?.PG_Year,
          "Criteria_2.1.1": item?.PG_Criteria02_211?.PG_criteria,
          "Program Name": item?.PG_Criteria02_211?.PG_PrgName1,
          "Program Code": item?.PG_Criteria02_211?.PG_PrgCode,
          "Number of seats sanctioned":
            item?.PG_Criteria02_211?.PG_NoSeatSanctioned,
          "Number of Students admitted": item?.PG_Criteria02_211?.PG_NoStdAdmit,
          "Description(min 500 words)(2.1.1)":
            item?.PG_Criteria02_211?.PG_Desc6,
          "Upload the relevant document(2.1.1)":
            item?.PG_Criteria02_211?.PG_Link6,

          // Criteria 2_212

          "Criteria_2.1.2": item?.PG_Criteria02_212?.PG_criteria,
          "Number of seats earmarked for reserved category as per GOI or State Government rule":
            tableMarkup1,
          "Number of students admitted from the reserved category":
            tableMarkup2,
          Description: item?.PG_Criteria02_212?.PG_Desc7,
          "Relevant document": item?.PG_Criteria02_212?.PG_Link7,

          // Criteria 2_241
          "Criteria_2.4.1": item?.PG_Criteria02_241?.PG_criteria,
          "Name of the Full-time teacher": item?.PG_Criteria02_241?.PG_TName,
          PAN: item?.PG_Criteria02_241?.PG_PAN,
          Designation: item?.PG_Criteria02_241?.PG_Designation,
          "Year of appointment": item?.PG_Criteria02_241?.PG_YOfAppoint,
          "Nature of appointment (Against Sanctioned post,temporary,permanent)":
            item?.PG_Criteria02_241?.PG_NoOfAppoint,
          "Name of the Department": item?.PG_Criteria02_241?.PG_DeptName,
          "Total years of Experience in the same institution":
            item?.PG_Criteria02_241?.PG_TYofExp,
          "Is the teacher still serving the institution/If not last  year of the service of Faculty to the Institution.":
            item?.PG_Criteria02_241?.PG_TService,
          "Description(min 500 words)(2.4.1)":
            item?.PG_Criteria02_241?.PG_Desc8,
          "Upload the relevant document(2.4.1)":
            item?.PG_Criteria02_241?.PG_Link8,

          // Criteria 2_242
          "Criteria_2.4.2": item?.PG_Criteria02_242?.PG_criteria,
          " Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.":
            item?.PG_Criteria02_242?.PG_SerTeacher,
          " Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./ D.Litt.)":
            item?.PG_Criteria02_242?.PG_Qualify,
          "Year of obtaining the qualification":
            item?.PG_Criteria02_242?.PG_YOfQuali,
          "Year of Recognition as a Research Guide":
            item?.PG_Criteria02_242?.PG_YOfRecog,
          " Whether recognised as a Research Guide for  Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.":
            item?.PG_Criteria02_242?.PG_Whether,
          "Is the teacher still serving the institution/If not last year of the service of Faculty to the Institution":
            item?.PG_Criteria02_242?.PG_IsThereTeacher,
          "Name of the Scholar": item?.PG_Criteria02_242?.PG_NameScholar,
          "Year of Registration of the Scholar":
            item?.PG_Criteria02_242?.PG_YearScholar,
          "Title of the thesis of the for scholar":
            item?.PG_Criteria02_242?.PG_Thesis,
          "Description(min 500 words)(2.4.2)":
            item?.PG_Criteria02_242?.PG_Desc9,
          "Upload the relevant document(2.4.2)":
            item?.PG_Criteria02_242?.PG_Link9,

          // Criteria 2_263
          "Criteria_2.6.3": item?.PG_Criteria02_263?.PG_criteria,
          "Program name": item?.PG_Criteria02_263?.PG_PrgName2,
          "Program code": item?.PG_Criteria02_263?.PG_pCode,
          " Number of students appeared in the final year examination":
            item?.PG_Criteria02_263?.PG_NStdAppeared,
          " Number of students passed in final year examination":
            item?.PG_Criteria02_263?.PG_NStdPassed,
          "Description(min 500 words)(2.6.3)":
            item?.PG_Criteria02_263?.PG_Desc10,
          " Upload the relevant document(2.6.3)":
            item?.PG_Criteria02_263?.PG_Link10,

          //Criteria 2_271
          "Criteria_2.7.1": item?.PG_Criteria02_271?.PG_criteria,
          "Program Name1": item?.PG_Criteria02_271?.PG_PrgName3,
          "Name of the student": item?.PG_Criteria02_271?.PG_NStd,
          Gender: item?.PG_Criteria02_271?.PG_Gender,
          Category: item?.PG_Criteria02_271?.PG_Category,
          "State of Domicile": item?.PG_Criteria02_271?.PG_SDomicile,
          "Nationality if othern than Indian":
            item?.PG_Criteria02_271?.PG_NIndian,
          "Email-ID": item?.PG_Criteria02_271?.PG_Email,
          "Mobile Number": item?.PG_Criteria02_271?.PG_MNo,
          "Year of joining": item?.PG_Criteria02_271?.PG_YJoining,
          "Unique Enrolment ID / College ID/ University enrolment number":
            item?.PG_Criteria02_271?.PG_UID,
          "Description(min 500 words)(2.7.1)":
            item?.PG_Criteria02_271?.PG_Desc11,
          "Upload the relevant document(2.7.1)":
            item?.PG_Criteria02_271?.PG_Link11,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editPG_Criteria02_211 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc6) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria02_211/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_PrgName1: PG_PrgName1,
          PG_PrgCode: PG_PrgCode,
          PG_NoSeatSanctioned: PG_NoSeatSanctioned,
          PG_NoStdAdmit: PG_NoStdAdmit,
          PG_Desc6: PG_Desc6,
          PG_Link6: PG_Link6,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri02details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria02_212 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc7;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc7) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria02_212/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_SC: PG_SC,
          PG_ST: PG_ST,
          PG_OBC: PG_OBC,
          PG_Gen: PG_Gen,
          PG_Divyangjan: PG_Divyangjan,
          PG_Others: PG_Others,
          PG_SSC: PG_SSC,
          PG_SST: PG_SST,
          PG_OOBC: PG_OOBC,
          PG_GGen: PG_GGen,
          PG_DDivyangjan: PG_DDivyangjan,
          PG_OOthers: PG_OOthers,
          PG_Desc7: PG_Desc7,
          PG_Link7: PG_Link7,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri02details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria02_241 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc8;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc8) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria02_241/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_TName: PG_TName,
          PG_PAN: PG_PAN,
          PG_Designation: PG_Designation,
          PG_YOfAppoint: PG_YOfAppoint,
          PG_NoOfAppoint: PG_NoOfAppoint,
          PG_DeptName: PG_DeptName,
          PG_TYofExp: PG_TYofExp,
          PG_TService: PG_TService,
          PG_Desc8: PG_Desc8,
          PG_Link8: PG_Link8,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri02details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria02_242 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc9;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc9) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria02_242/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_SerTeacher: PG_SerTeacher,
          PG_Qualify: PG_Qualify,
          PG_YOfQuali: PG_YOfQuali,
          PG_YOfRecog: PG_YOfRecog,
          PG_Whether: PG_Whether,
          PG_YOfRecog: PG_YOfRecog,
          PG_IsThereTeacher: PG_IsThereTeacher,
          PG_NameScholar: PG_NameScholar,
          PG_YearScholar: PG_YearScholar,
          PG_Thesis: PG_Thesis,
          PG_Desc9: PG_Desc9,
          PG_Link9: PG_Link9,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri02details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria02_263 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc10;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc10) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria02_263/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_pCode: PG_pCode,
          PG_PrgName2: PG_PrgName2,
          PG_NStdAppeared: PG_NStdAppeared,
          PG_NStdPassed: PG_NStdPassed,
          PG_Desc10: PG_Desc10,
          PG_Link10: PG_Link10,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri02details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria02_271 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc11;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc11) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editPG_Criteria02_271/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_PrgName3: PG_PrgName3,
          PG_NStd: PG_NStd,
          PG_Gender: PG_Gender,
          PG_Category: PG_Category,
          PG_SDomicile: PG_SDomicile,
          PG_NIndian: PG_NIndian,
          PG_Email: PG_Email,
          PG_MNo: PG_MNo,
          PG_YJoining: PG_YJoining,
          PG_UID: PG_UID,
          PG_Desc11: PG_Desc11,
          PG_Link11: PG_Link11,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri02details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getpgcri02details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcri02details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria02);
        setPG_Criteria02(res.data.pgcriteria02);
        setdata1(res.data.pgcriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria02);
    }
  };

  useEffect(() => {
    getpgcri02details();
  }, []);
  console.log(PG_Criteria02);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  // const pgCriteria02Details = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria2"
  //     );
  //     if (res.status === 200) {
  //       setAllDetails(res.data.pgcriteria02);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAllDetails(error.response.data.pgcriteria02);
  //   }
  // };

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-02
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/pgcriteria002">
                <button className="btn btn-success ">
                  Add Criteria 2{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of Students admitted</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </th>
                  <th>
                    Number of students admitted from the reserved category
                  </th>
                  <th>Description</th>
                  <th> Document</th>

                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>
                    Nature of appointment (Against Sanctioned post,
                    temporary,permanent)
                  </th>
                  <th>Name of the Department</th>
                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution.
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Year of Recognition as Research Guide</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th>Name of the scholar</th>
                  <th>Year of registration of the scholar</th>
                  <th>Title of the thesis of the project for scholar</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if othern than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {PG_Criteria02?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.PG_Criteria02_211?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_211?.PG_PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_211?.PG_PrgCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_211?.PG_NoSeatSanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_211?.PG_NoStdAdmit?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_211?.PG_Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_212?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_SC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_ST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_OBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_Gen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_Divyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_Others?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_SSC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_SST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_OOBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_GGen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_DDivyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_OOthers?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_212?.PG_Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_241?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_TName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_241?.PG_PAN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_Designation?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_241?.PG_YOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_NoOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_DeptName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_241?.PG_TYofExp?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_TService?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_241?.PG_Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_242?.PG_SerTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_YOfQuali?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_242?.PG_Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_YOfRecog?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_242?.PG_IsThereTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_NameScholar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_YearScholar?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_242?.PG_Thesis?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_242?.PG_Desc9?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_263?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_263?.PG_PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_263?.PG_pCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_263?.PG_NStdAppeared?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_263?.PG_NStdPassed?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_263?.PG_Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_271?.PG_PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_NStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_Gender?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_SDomicile?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_NIndian?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_Email?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria02_271?.PG_MNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_271?.PG_YJoining?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_271?.PG_UID?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria02_271?.PG_Desc11?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.PG_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.PG_Criteria02_211?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_211?.PG_PrgName1}</td>
                      <td>{item?.PG_Criteria02_211?.PG_PrgCode}</td>
                      <td>{item?.PG_Criteria02_211?.PG_NoSeatSanctioned}</td>
                      <td>{item?.PG_Criteria02_211?.PG_NoStdAdmit}</td>
                      <td>
                        {item?.PG_Criteria02_211?.PG_Desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria02_212?.PG_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.PG_Criteria02_212?.PG_SC}</td>
                              <td>{item?.PG_Criteria02_212?.PG_ST}</td>
                              <td>{item?.PG_Criteria02_212?.PG_OBC}</td>
                              <td>{item?.PG_Criteria02_212?.PG_Gen}</td>
                              <td>{item?.PG_Criteria02_212?.PG_Divyangjan}</td>
                              <td>{item?.PG_Criteria02_212?.PG_Others}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.PG_Criteria02_212?.PG_SSC}</td>
                              <td>{item?.PG_Criteria02_212?.PG_SST}</td>
                              <td>{item?.PG_Criteria02_212?.PG_OOBC}</td>
                              <td>{item?.PG_Criteria02_212?.PG_GGen}</td>
                              <td>{item?.PG_Criteria02_212?.PG_DDivyangjan}</td>
                              <td>{item?.PG_Criteria02_212?.PG_OOthers}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        {item?.PG_Criteria02_212?.PG_Desc7?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria02_241?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_241?.PG_TName}</td>
                      <td>{item?.PG_Criteria02_241?.PG_PAN}</td>
                      <td>{item?.PG_Criteria02_241?.PG_Designation}</td>
                      <td>{item?.PG_Criteria02_241?.PG_YOfAppoint}</td>
                      <td>{item?.PG_Criteria02_241?.PG_NoOfAppoint}</td>
                      <td>{item?.PG_Criteria02_241?.PG_DeptName}</td>
                      <td>{item?.PG_Criteria02_241?.PG_TYofExp}</td>
                      <td>{item?.PG_Criteria02_241?.PG_TService}</td>
                      <td>
                        {item?.PG_Criteria02_241?.PG_Desc8?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria02_242?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_242?.PG_SerTeacher}</td>
                      <td>{item?.PG_Criteria02_242?.PG_Qualify}</td>
                      <td>{item?.PG_Criteria02_242?.PG_YOfQuali}</td>
                      <td>{item?.PG_Criteria02_242?.PG_YOfRecog}</td>
                      <td>{item?.PG_Criteria02_242?.PG_Whether}</td>
                      <td>{item?.PG_Criteria02_242?.PG_IsThereTeacher}</td>
                      <td>{item?.PG_Criteria02_242?.PG_NameScholar}</td>
                      <td>{item?.PG_Criteria02_242?.PG_YearScholar}</td>
                      <td>{item?.PG_Criteria02_242?.PG_Thesis}</td>
                      <td>
                        {item?.PG_Criteria02_242?.PG_Desc9?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria02_263?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_263?.PG_PrgName2}</td>
                      <td>{item?.PG_Criteria02_263?.PG_pCode}</td>
                      <td>{item?.PG_Criteria02_263?.PG_NStdAppeared}</td>
                      <td>{item?.PG_Criteria02_263?.PG_NStdPassed}</td>
                      <td>
                        {item?.PG_Criteria02_263?.PG_Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria02_271?.PG_criteria}</td>
                      <td>{item?.PG_Criteria02_271?.PG_PrgName3}</td>
                      <td>{item?.PG_Criteria02_271?.PG_NStd}</td>
                      <td>{item?.PG_Criteria02_271?.PG_Gender}</td>
                      <td>{item?.PG_Criteria02_271?.PG_Category}</td>
                      <td>{item?.PG_Criteria02_271?.PG_SDomicile}</td>
                      <td>{item?.PG_Criteria02_271?.PG_NIndian}</td>
                      <td>{item?.PG_Criteria02_271?.PG_Email}</td>
                      <td>{item?.PG_Criteria02_271?.PG_MNo}</td>
                      <td>{item?.PG_Criteria02_271?.PG_YJoining}</td>
                      <td>{item?.PG_Criteria02_271?.PG_UID}</td>
                      <td>
                        {item?.PG_Criteria02_263?.PG_Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 2 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 m-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    Programme name
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.PG_Criteria02_211?.PG_PrgName1}
                        onChange={(e) => {
                          setPG_PrgName1(e.target.value);
                        }}
                      >
                        <option selected>--select--</option>
                        <option value="MCA">MCA</option>
                        <option value="MSC">MSC</option>
                        <option value="MBA">MBA</option>
                        <option value="M.Com">M.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_211?.PG_PrgName1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme code
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_211?.PG_PrgCode}
                        onChange={(e) => {
                          setPG_PrgCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_211?.PG_PrgCode}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of seats sanctioned
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.PG_Criteria02_211?.PG_NoSeatSanctioned
                        }
                        onChange={(e) => {
                          setPG_NoSeatSanctioned(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_211?.PG_NoSeatSanctioned}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students admitted
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_211?.PG_NoStdAdmit}
                        onChange={(e) => setPG_NoStdAdmit(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria02_211?.PG_NoStdAdmit}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_211?.PG_Desc6}
                        onChange={(e) => setPG_Desc6(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_211?.PG_Desc6}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="file"
                        name="upload1"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria02_211?.PG_Link6}
                        onChange={(e) => setPG_Link6(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_211?.PG_Link6}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria02_211(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                {/* -------2.1.2------ */}
                <h6 className="text-center text-decoration-underline">
                  Number of seats earmarked for reserved category as per GOI or
                  State Government rule
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_SC}
                        onChange={(e) => {
                          setPG_SC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_SC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    ST
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_ST}
                        onChange={(e) => {
                          setPG_ST(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_ST}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_OBC}
                        onChange={(e) => {
                          setPG_OBC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_OBC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_Gen}
                        onChange={(e) => {
                          setPG_Gen(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_Gen}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_Divyangjan}
                        onChange={(e) => {
                          setPG_Divyangjan(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_Divyangjan}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_Others}
                        onChange={(e) => {
                          setPG_Others(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_Others}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students admitted from the reserved category
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_SSC}
                        onChange={(e) => {
                          setPG_SSC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_SSC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_SST}
                        onChange={(e) => {
                          setPG_SST(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_SST}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_OOBC}
                        onChange={(e) => {
                          setPG_OOBC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_OOBC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        placeholder={Value?.PG_Criteria02_212?.PG_GGen}
                        onChange={(e) => {
                          setPG_GGen(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_GGen}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_DDivyangjan}
                        onChange={(e) => {
                          setPG_DDivyangjan(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_DDivyangjan}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_OOthers}
                        onChange={(e) => {
                          setPG_OOthers(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_OOthers}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_212?.PG_Desc7}
                        onChange={(e) => setPG_Desc7(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_Desc7}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria02_212?.PG_Link7}
                        onChange={(e) => setPG_Link7(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_212?.PG_Link7}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit2 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria02_212(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.1 & 2.4.3------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Full-time teacher
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_241?.PG_TName}
                        onChange={(e) => {
                          setPG_TName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_TName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    PAN
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_241?.PG_PAN}
                        onChange={(e) => {
                          setPG_PAN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_PAN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Designation
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_241?.PG_Designation}
                        onChange={(e) => {
                          setPG_Designation(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_Designation}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of appointment
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_241?.PG_YOfAppoint}
                        onChange={(e) => {
                          setPG_YOfAppoint(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_YOfAppoint}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_241?.PG_NoOfAppoint}
                        onChange={(e) => {
                          setPG_NoOfAppoint(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_NoOfAppoint}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_241?.PG_DeptName}
                        onChange={(e) => {
                          setPG_DeptName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_DeptName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total years of Experience in the same institution
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_241?.PG_TYofExp}
                        onChange={(e) => {
                          setPG_TYofExp(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_TYofExp}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_241?.PG_TService}
                        onChange={(e) => {
                          setPG_TService(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_TService}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_241?.PG_Desc8}
                        onChange={(e) => setPG_Desc8(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_Desc8}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="file"
                        name="upload3"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria02_241?.PG_Link8}
                        onChange={(e) => setPG_Link8(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_241?.PG_Link8}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit3 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria02_241(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.2------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_242?.PG_SerTeacher}
                        onChange={(e) => {
                          setPG_SerTeacher(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_SerTeacher}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_242?.PG_Qualify}
                        onChange={(e) => {
                          setPG_Qualify(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_Qualify}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of obtaining the qualification
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_242?.YOfQuali}
                        onChange={(e) => {
                          setPG_YOfQuali(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_242?.YOfQuali}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Recognition as a Research Guide
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_242?.PG_YOfRecog}
                        onChange={(e) => {
                          setPG_YOfRecog(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_YOfRecog}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </label>

                  {edit4 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "270px" }}
                        placeholder={Value?.PG_Criteria02_242?.PG_Whether}
                        onChange={(e) => setPG_Whether(e.target.value)}
                      >
                        <option selected>--select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_Whether}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.PG_Criteria02_242?.PG_IsThereTeacher
                        }
                        onChange={(e) => {
                          setPG_IsThereTeacher(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_IsThereTeacher}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scholar
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_242?.PG_NameScholar}
                        onChange={(e) => {
                          setPG_NameScholar(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_NameScholar}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of registration of the scholar
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_242?.PG_YearScholar}
                        onChange={(e) => {
                          setPG_YearScholar(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_YearScholar}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the thesis of the for scholar
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_242?.PG_Thesis}
                        onChange={(e) => {
                          setPG_Thesis(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_Thesis}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_242?.PG_Desc9}
                        onChange={(e) => setPG_Desc9(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_Desc9}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria02_242?.PG_Link9}
                        onChange={(e) => {
                          setPG_Link9(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_242?.PG_Link9}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="d-flex ">
                      {edit4 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria02_242(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.6.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.6.3------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit5 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.PG_Criteria02_263?.PG_PrgName2}
                        onChange={(e) => setPG_PrgName2(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="MCA">MCA</option>
                        <option value="MSC">MSC</option>
                        <option value="MBA">MBA</option>
                        <option value="M.Com">M.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_263?.PG_PrgName2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_263?.PG_pCode}
                        onChange={(e) => setPG_pCode(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_263?.PG_pCode}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students appeared in the final year examination
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className=" vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={
                            Value?.PG_Criteria02_263?.PG_NStdAppeared
                          }
                          onChange={(e) => setPG_NStdAppeared(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria02_263?.PG_NStdAppeared}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students passed in final year examination
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_263?.PG_NStdPassed}
                        onChange={(e) => setPG_NStdPassed(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_263?.PG_NStdPassed}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_263?.PG_Desc10}
                        onChange={(e) => setPG_Desc10(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_263?.PG_Desc10}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload5"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria02_263?.PG_Link10}
                        onChange={(e) => {
                          setPG_Link10(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_263?.PG_Link10}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit5 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria02_263(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <h6>Criteria02--(2.7.1)</h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit6 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.PG_Criteria02_271?.PG_PrgName3}
                        onChange={(e) => {
                          setPG_PrgName3(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="MCA">MCA</option>
                        <option value="MSC">MSC</option>
                        <option value="MBA">MBA</option>
                        <option value="M.Com">M.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_PrgName3}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Student Name
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_NStd}
                        onChange={(e) => {
                          setPG_NStd(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_NStd}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gender
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_Gender}
                        onChange={(e) => {
                          setPG_Gender(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_Gender}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_Category}
                        onChange={(e) => {
                          setPG_Category(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_Category}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State of Domicile
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_SDomicile}
                        onChange={(e) => {
                          setPG_SDomicile(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_SDomicile}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Nationality if othern than Indian
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_NIndian}
                        onChange={(e) => {
                          setPG_NIndian(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_NIndian}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Email-ID
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_Email}
                        onChange={(e) => {
                          setPG_Email(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_Email}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Mobile Number
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_MNo}
                        onChange={(e) => {
                          setPG_MNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_MNo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of joining
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_YJoining}
                        onChange={(e) => {
                          setPG_YJoining(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_YJoining}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_UID}
                        onChange={(e) => {
                          setPG_UID(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_UID}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria02_271?.PG_Desc11}
                        onChange={(e) => setPG_Desc11(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_Desc11}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload6"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria02_271?.PG_Link11}
                        onChange={(e) => {
                          setPG_Link11(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria02_271?.PG_Link11}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit6 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria02_271(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit6(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria02_211?.PG_Desc6}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria02_212?.PG_Desc7}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria02_241?.PG_Desc8}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria02_242?.PG_Desc9}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria02_263?.PG_Desc10}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria02_271?.PG_Desc11}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_211?.PG_Link6}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_212?.PG_Link7}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_241?.PG_Link8}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_242?.PG_Link9}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_263?.PG_Link10}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria02/${View?.PG_Criteria02_271?.PG_Link11}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default PG_Criteria02;
