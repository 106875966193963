import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./Styles/Assessment.css";
import axios from "axios";
import parse from "html-react-parser";
const Assessment = () => {
  const location = useLocation();
  const { id } = location?.state;

  // =================== calling api for getting the Accrediation content======================
  const [AccredAssessmentDetails, setAccredAssessmentDetails] = useState([]);
  const getAccredAssessmentContent = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getaccredAndAssessmentDetails"
      );
      if (res.status === 200) {
        setAccredAssessmentDetails(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccredAssessmentContent();
  }, []);

  useEffect(() => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [id]);
  return (
    <div className="assessment">
      <div
        className="ab-0"
        style={{
          backgroundImage: `url(https://brightnaac.co.in/AccrediationAndAssessment/${AccredAssessmentDetails[0]?.img})`,
          height: "250px",
          position: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="edg container">
          <div className="edgt-has-inline-style">
            Assessment & Accreditation
          </div>
          <div class="edgt-breadcrumbs-info">
            <div
              itemProp="breadcrumb"
              classname="edgt-breadcrumbs "
              style={{ color: "#e1e0e0" }}
            >
              <Link
                itemProp="url"
                to="/"
                style={{ color: "#e1e0e0", textDecoration: "none" }}
              >
                Home
              </Link>
              <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
              <span classname="edgt-current">Assessment & Accreditation</span>
            </div>
          </div>
        </div>
      </div>
      <img
        src="../gallery/logob.png"
        class="img-logo"
        style={{ color: "white" }}
      />
      {/* 
      <img
        src="../gallery/logob.png"
        class="img-logo"
        style={{ color: "white" }}
      /> */}

      <section id="demo">
        <Container>
          <div className="content">
            <h4 className="text-center">ASSESSMENT & ACCREDITATION</h4>
            <p className="educat">
              {parse(`<div>${AccredAssessmentDetails[0]?.body}</div>`)}
            </p>
          </div>
        </Container>
      </section>

      <section id="AC1">
        <div className="ab-sec" data-aos="fade-up" data-aos-duration="2000">
          <Container>
            <div className="content">
              <h4 className="text-center mb-3">ACCREDITATION</h4>
              <div className="row mb-5 ">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                  <div className="edgt-eh-item-inner">
                    <div className="edgt-eh-custom-2737">
                      <div className="edgt-st-inner">
                        <h5 className="text-center mb-2">
                          {AccredAssessmentDetails[0]?.accredTopic}
                        </h5>

                        <p className="p1">
                          {parse(
                            `<div>${AccredAssessmentDetails[0]?.accredbody}</div>`
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="committed-group">
                    <img
                      src={`https://brightnaac.co.in/AccrediationAndAssessment/${AccredAssessmentDetails[0]?.accredImg}`}
                      alt=""
                      className="img-fluid p-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section id="EC1">
        <div className="ab-sec" data-aos="fade-up" data-aos-duration="2000">
          <Container
          // id="EC1"
          >
            <div className="content">
              <h4 className="text-center ">ELIGIBILITY CRITERIA</h4>
              <p className="educat mb-5">
                {parse(
                  `<div>${AccredAssessmentDetails[0]?.elegibleCriMainBody}</div>`
                )}
              </p>
            </div>
          </Container>
        </div>
      </section>

      <section id="UA1">
        <div
          className="container "
          style={{
            background: "#CCE7D4",
          }}
        >
          <div className="row ">
            <h4 className=" mt-5  text-center "> UNITS OF ASSESSMENT</h4>

            <div
            //  className="col-lg-6 col-md-6 col-sm-12 col-12 "
            >
              <div className="edgt-eh-item-inner">
                <div className="edgt-eh-custom-2737">
                  <div className="edgt-st-inner">
                    <p
                      className="p1 "
                      style={{
                        padding: "20px",
                      }}
                    >
                      {parse(
                        `<div>${AccredAssessmentDetails[0]?.unitOfAssessment}</div>`
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="P1">
        <div className="container">
          <h4 className="text-center mb-3 ">PROCESS</h4>
          <div className="fs-3 text-center border rounded p-3 mb-5 link-sec">
            <Link
              to={AccredAssessmentDetails[0]?.processLink}
              class="click-link"
            >
              {AccredAssessmentDetails[0]?.processData}
            </Link>
          </div>
        </div>
      </section>

      <section className="" id="CW1">
        <div className="container ">
          <h4 className="text-center ">CRITERIA & WEIGHTAGES</h4>
          <div className="row">
            <div className="col-md-4 ">
              <p className="" style={{ textAlign: "justify", padding: "0px" }}>
                {parse(
                  `<div>${AccredAssessmentDetails[0]?.criteriaAndWeightagesBody}</div>`
                )}
              </p>
            </div>

            <div className="col-md-8">
              <table class="table sec-back1 ">
                <tbody>
                  {AccredAssessmentDetails[0]?.criteriaWeightagesDescription?.map(
                    (item2) => {
                      return (
                        <tr>
                          <td>{item2?.criWeiTopic}</td>
                          <td>{item2?.section1}</td>
                          <td> {item2?.section2}</td>
                          <td>{item2?.section3}</td>
                          <td>{item2?.section4}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <section id="G1">
        <div className="container">
          <h4 className=" mb-3 ">GRADING</h4>
          <div className="row">
            <div className="col-md-12">
              <p className="p1">
                {" "}
                {parse(`<div>${AccredAssessmentDetails[0]?.grading}</div>`)}
              </p>
            </div>

            {/* <div className="col-md-8">
              <h5>Advantages of CGPA</h5>
              <ul>
                <li>
                  <p>
                    Letter grades converted to Numerical Grade Points (overall
                    score in Cumulative Grade Point Average)
                  </p>
                </li>
                <li>
                  <p>Qualitative measurements converted to grade points</p>
                </li>
                <li>
                  <p>Wider scope for normalizing the scores</p>
                </li>
                <li>
                  <p>Extreme biases (if any) could be minimized</p>
                </li>
                <li>
                  <p>
                    A one point difference between two letter grades, with 50 or
                    100 points assigned between two successive letter grades
                    results in appreciable finetuning of the process
                  </p>
                </li>
                <li>
                  <p>
                    Relative evaluation would be more exact, due to a reduction
                    in variations and standard deviations
                  </p>
                </li>
                <li>
                  <p>Inter-Peer Team variations are substantially reduced</p>
                </li>
                <li>
                  <p>
                    With scare scope for adjustment at any stage, the peer team
                    judgment would be more accurate
                  </p>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </section>

      <section id="GR1">
        <div className="container">
          <h4 className=" text-center mb-3 ">GRIEVANCE REDRESSAL</h4>

          <div className="row">
            <div className="col">
              <p className="p1">
                {parse(
                  `<div>${AccredAssessmentDetails[0]?.grievanceRedressal}</div>`
                )}
              </p>

              {/* <p className="p1 mt-3">
                {" "}
                On announcement of the A&A outcome, the institutions not
                satisfied with the accreditation status may submit:
              </p>

              <p className="p1 mt-3">
                {" "}
                1. Appeal Intent Online through the HEI portal within Fifteen
                (15) Days from the date of declaration of the A&A result on the
                HEI portal.The grade obtained by the institution will be Kept in
                Abeyance(not be published)on the NAAC website upon receiving the
                Appeal Intent within the stipulated period from the HEI.
              </p>

              <p className="p1 mt-3">
                2.Once an institution has submitted Appeal Intent,the
                institution may fill and submit an appeal online through the HEI
                portal in prescribed proforma as given at Annexure-1 /
                Annexure-2 within Forty-Five (45)days from the date of
                declaration of the A&A result on the HEI portal along with a
                non-refundable fee of Rs.50,000/-(Rupees Fifty Thousand Only) +
                GST as applicable through online payment option available on the
                HEI portal. In any case date of submission of Appeal shall not
                be beyond 45 days from the date of declaration of results on the
                NAAC website.
              </p>

              <p className="p1 mt-3">
                No correspondence (including phone calls) will be entertained on
                the matter till the appeal is disposed of by the "Appeals
                Committee/EC of NAAC. An Appeals Committee constituted for the
                purpose will consider the appeal and make recommendations to the
                Executive Committee (EC). The decision of the EC shall be
                binding on the institution.
              </p>

              <p className=" h4 mt-4 fw-medium mb-4">
                Scope of Appeals Committee Extended
              </p>

              <p className="p1 ">
                The Executive Committee (EC) reiterated during 53rd meeting on
                September, 4th 2010, that Appeals Committee is meant to consider
                not only the appeals from the Institutions but also to consider
                cases referred to it by the EC, in case of any deviation from
                the process of Assessment and Accreditation, violations,
                complaints, etc.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      <section id="RA1">
        <div className="container">
          <h4 className=" text-center mb-3 ">RE-ASSESSMENT</h4>
          <p className="p1 ">
            {parse(`<div>${AccredAssessmentDetails[0]?.reAssessment}</div>`)}
          </p>
        </div>
      </section>

      <section id="CA1">
        <div className="container">
          <h4 className=" text-center mb-3 ">CYCLES OF ACCREDITATION</h4>
          <p className="p1 ">
            {parse(`<div>${AccredAssessmentDetails[0]?.cyclesOfAccred}</div>`)}
          </p>

          {/* <p className="p1 mb-4">
            When an institution undergoes the accreditation process for the
            first time it is referred to as Cycle 1 and the consecutive five
            year periods as Cycles 2, 3, etc.
          </p>

          <p className="p1 mb-2">
            For Cycle 1, please refer Process of accreditation
          </p>

          <p className="p1 mb-4">
            For Cycles 2, 3, etc. the following are essential:
          </p>

          <ul>
            <li>
              <p>IQAC to be functional</p>
            </li>
            <li>
              <p>Timely submission of AQARs annually</p>
            </li>
            <li>
              <p>
                Institutions to submit IIQA, during the last 6 months of
                validity period subject to the fulfilment of other conditions
                specified by NAAC from time to time for the purpose.
              </p>
            </li>
            <li>
              <p>Other steps remain the same as first cycle</p>
            </li>
          </ul> */}
        </div>
      </section>

      <section id="AO1">
        <div className="container">
          <h4 className=" text-center mb-3 ">ASSESSMENT OUTCOME</h4>
          {/* <p className="p1 mb-5 ">
            The final result of the Assessment and Accreditation exercise will
            be an ICT based score, which is a combination of evaluation of
            qualitative and quantitative metrics. This will be compiled as a
            document comprising three parts.
          </p>

          <div className="row mb-5">
            <div className="col-md-6">
              <p>1. Peer Team Report</p>
              <ul>
                <li>
                  <p>
                    Section 1: Gives the General Information of the institution
                    and its context.
                  </p>
                </li>
                <li>
                  <p>
                    Section 2: Gives Criterion wise analysis based on peer
                    evaluation of qualitative indicators. Instead of reporting
                    with bullet points, this will be a qualitative, descriptive
                    assessment report based on the Peer Team’s critical analysis
                    presenting strengths and weaknesses of HEI under each
                    Criterion
                  </p>
                </li>
                <li>
                  <p>
                    Section 3: Presents an <b>Overall Analysis</b> which
                    includes Institutional Strengths, Weaknesses, Opportunities
                    and Challenges.
                  </p>
                </li>

                <li>
                  <p>
                    Section 4: Records Recommendations for Quality Enhancement
                    of the Institution (not more than 10 major ones).
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <p>
                2. Graphical representation based on Quantitative Metrics (QnM)
                This part will be a System Generated Quality Profile of the HEI
                based on statistical analysis of quantitative indicators in the
                NAAC’s QIF (quality indicator framework). Graphical presentation
                of institutional features would be reflected through synthesis
                of quantifiable indicators.
              </p>

              <p>
                3. Institutional Grade Sheet Contains the{" "}
                <b>Institutional Grade Sheet </b>which is based on qualitative
                indicators, quantitative indicators and student satisfaction
                survey using existing calculation methods but it will be
                generated by a software.
              </p>
            </div>
          </div>

          <p className="mb-5">
            The above three parts will together form “NAAC Accreditation
            Outcome” document. It is mandatory for the HEIs to display it on
            their institutional website apart from NAAC hosting it on its
            website
          </p> */}

          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  Range of institutional Cumulative Grade Point Average (CGPA)
                </th>
                <th scope="col"> Letter Grade</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {AccredAssessmentDetails[0]?.assessmentOutcomeTable?.map(
                (item3) => {
                  return (
                    <tr>
                      <th className="text-center" scope="row">
                        {item3?.CGPA}
                      </th>
                      <td>{item3?.letterGrade}</td>
                      <td>{item3?.status}</td>
                    </tr>
                  );
                }
              )}
              {/* <tr>
                <th className="text-center" scope="row">
                  3.26 - 3.50
                </th>
                <td>A+</td>
                <td>Accredited</td>
              </tr>
              <tr>
                <th className="text-center" scope="row">
                  3.01 - 3.25
                </th>
                <td>A</td>
                <td>Accredited</td>
              </tr>

              <tr>
                <th className="text-center" scope="row">
                  2.76 - 3.00
                </th>
                <td>B++</td>
                <td>Accredited</td>
              </tr>

              <tr>
                <th className="text-center" scope="row">
                  2.51 - 2.75
                </th>
                <td>B+</td>
                <td>Accredited</td>
              </tr>

              <tr>
                <th className="text-center" scope="row">
                  2.01 - 2.50
                </th>
                <td>B</td>
                <td>Accredited</td>
              </tr>

              <tr>
                <th className="text-center" scope="row">
                  1.51 - 2.00
                </th>
                <td>C</td>
                <td>Accredited</td>
              </tr>

              <tr>
                <th className="text-center" scope="row">
                  {" "}
                  = 1.50
                </th>
                <td>D</td>
                <td>Not accredited</td>
              </tr> */}
            </tbody>
          </table>

          {/* <p className="text-center text-black mb-3 mt-2">
            With Effect From July 2017
          </p> */}

          <div>
            {/* <h5>Note</h5> */}
            <div>
              {parse(
                `<div>${AccredAssessmentDetails[0]?.assessmentOutcomeNote}</div>`
              )}
            </div>
            {/* <ul>
              <li>
                <p>Final CGPA value is rounded off to two decimal points</p>
              </li>
              <li>
                <p>
                  The Grading pattern has been prepared as inclusive class
                  intervals i.e., both the upper limit and lower limit are
                  included in the respective class interval
                </p>
              </li>

              <li>
                <p>
                  For example 3.01 - 3.25 class interval is assigned ‘A’ Grade
                  i.e., any value falling between class intervals; and both 3.01
                  and 3.25 are included in the same class interval;
                </p>
              </li>

              <li>
                <p>
                  But the values below 3.01 or above 3.25 are not included in
                  the same class interval.
                </p>
              </li>

              <li>
                <p>
                  If the third decimal point is more than or equal to 5, then
                  the second decimal point will be increased by one; For
                  example, CGPA of 3.005, 3.006, 3.007, 3.008 and 3.009 are
                  rounded off to CGPA 3.01.
                </p>
              </li>

              <li>
                <p>
                  If the third decimal point is less than or equal to 4, then
                  second decimal point will be retained as it is; For example,
                  CGPA of 3.000, 3.001, 3.002, 3.003and 3.004 are rounded off to
                  CGPA 3.00
                </p>
              </li>

              <li>
                <p>
                  This analogy is applicable to all scores and grades adopted in
                  the Grading pattern and in CGPA scoring pattern.
                </p>
              </li>
            </ul> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Assessment;
