import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function CollegeAdminHeader() {
  const principalDetails = JSON.parse(sessionStorage.getItem("Puser"));

  return (
    <>
      <div
        className="header"
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          background: "#393186",
        }}
      >
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              <h3 className="admin-heading">{principalDetails?.HeiName}</h3>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
                <div className=" d-flex align-items-center justify-content-center">
                  <div class="dropdown ">
                    <img
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      src={`https://brightnaac.co.in/register/${principalDetails?.CollegeLogo}`}
                      alt="logo"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        border: "2px solid black",
                        borderColor: "#393186",
                        background: "white",
                      }}
                    />
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="/PrincipalProfile">
                          Profile
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="/login"
                          onClick={() => {
                            sessionStorage.removeItem("Puser");
                          }}
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div
                    className="m-3"
                    style={{
                      fontSize: "12px",
                      color: "white",
                    }}
                  >
                    {principalDetails?.PrincipalName}
                    <div className="d-block" style={{ fontSize: "12px" }}>
                      ID:{principalDetails?._id}
                    </div>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default CollegeAdminHeader;
