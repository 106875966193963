import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

function UniversityCri5() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const uniCriteria05Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getunicri05details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.unicriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria05);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatinguni5`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Uni_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          uniCriteria05Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      uniCriteria05Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="mt-4"> University Criteria5 </h4>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="mt-4">Current Rating: </h6>
                <div className="mt-4">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Uni_Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center">
            <div className="ad-b mt-4 row align-items-center">
              <div className=" col-lg-4 do-sear mt-4 d-flex ">
                <input
                  type="text"
                  placeholder="Search"
                  className="vi_0"
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {/* <button>Search</button> */}
              </div>

              <div className=" col-lg-2 lab">
                <label>From</label>
                <br />
                <DatePicker
                  onChange={onChangeFrom}
                  picker="year"
                  className="vi_0"
                />
              </div>
              <div className=" col-lg-2 lab">
                <label>To</label>
                <br />
                <DatePicker
                  onChange={onChangeTo}
                  picker="year"
                  className="vi_0"
                />
              </div>

              {/* <div className=" col-lg-2 lab mt-4">
                  <Button>Submit</Button>
                </div> */}
              {/* <div className=" col-lg-2 text-end mt-4">
                  <Button>Export Excel</Button>
                </div> */}
            </div>
            <div className="row">
              <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
                <label style={{ width: "130px" }}>Select Year</label>
                <select
                  className="criteriaform "
                  style={{ width: "150px" }}
                  onChange={(e) => setselectedYear(e.target.value)}
                >
                  {allYears
                    ?.sort((a, b) => {
                      return Number(b?.from) - Number(a?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg-5">
                <Nav defaultActiveKey="/unicriteria05">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-1"
                      onClick={() =>
                        navigate(`/adminclgunicri5511/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.1.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-2"
                      onClick={() =>
                        navigate(`/adminclgunicri5512/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.1.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgunicri5513/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.1.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgunicri5521/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.2.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgunicri5522/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.2.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgunicri5523/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.2.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgunicri5531/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.3.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgunicri5533/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.3.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              {/* <div className="col-lg-2">
                <Button
                  type="Submit"
                  className="btn btn-warning"
                  style={{ color: "white" }}
                  onClick={() => {
                    // setValue(item);
                    handleShow();
                  }}
                >
                  Add Rating
                </Button>
              </div> */}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 w-100"></div>
        </div>
        <div className="container">
          <div className="mo-gra mt-2">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 5.1.1 */}
                  <th>Criteria</th>
                  <th>Name of the scheme</th>
                  <th>
                    Number of students benefited by government scheme and amount
                  </th>
                  <th>
                    Number of students benefited by the institution's schemes
                    and amount
                  </th>
                  <th>
                    Number of students benefited by the non-government agencies
                    (NGOs) and amount
                  </th>
                  <th>Name of the NGO/agency</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 5.1.2 */}
                  <th>Criteria</th>
                  <th>
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counselling offered by
                    the institution during the last five years
                  </th>
                  <th>Number of students attended / participated</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 5.1.3 */}
                  <th>Criteria</th>
                  <th>Name of the capability enhancement program</th>
                  <th>Date of implementation (DD-MM-YYYY)</th>
                  <th>Number of students enrolled</th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 5.2.1 */}
                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Registration number/roll number for the exam</th>
                  <th>Name of the student /qualified</th>
                  <th>NET</th>
                  <th>SLET</th>
                  <th>GATE</th>
                  <th>GMAT</th>
                  <th>CAT</th>
                  <th>GRE</th>
                  <th>JAM</th>
                  <th>IELTS</th>
                  <th>TOEFL</th>
                  <th>Civil Services</th>
                  <th>State government examinations</th>
                  <th>
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </th>
                  <th>Total</th>
                  <th>Grand Total</th>
                  <th>Description</th>
                  <th>Link to relevant document</th>

                  {/* 5.2.2 */}
                  <th>Criteria</th>
                  <th>Name of student placed</th>
                  <th>Program graduated from</th>
                  <th>Name of the employer</th>
                  <th>Pay package at appointment</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  {/* 5.2.3 */}
                  <th>Criteria</th>
                  <th>Name of student enrolling into higher education</th>
                  <th>Program graduated from</th>
                  <th>Name of institution joined</th>
                  <th>Name of programme admitted to</th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>

                  {/* 5.3.1 */}
                  <th>Criteria</th>
                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>inter-university / state / National/ International</th>
                  <th>Name of the event</th>
                  <th>Name of the student</th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>

                  {/* 5.3.3 */}
                  <th>Criteria</th>
                  <th>Date of event/competition (DD-MM-YYYY)</th>
                  <th>Name of the event/competition</th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Uni_Year &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria05_511?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Scheme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Std?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Amt?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Std1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Amt1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Std2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Amt2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_NGO?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_512?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_512?.Uni_HEI?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_512?.Uni_Number?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_512?.Uni_Desc20?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_513?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_513?.Uni_Capabality?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Uni_Criteria05_513?.Uni_Date)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria05_513?.Uni_Enrolled?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_513?.Uni_Agencies?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_513?.Uni_Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_No?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_RollNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_StdSelected?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_NET?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_SLET?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_GATE?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_GMAT?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_CAT?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_GRE?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_JAM?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_IELTS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_TOEFL?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_Civil?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_State?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_OtherExam?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_GTotal?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_Placed1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_Graduate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_employer?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_Package?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Higher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Graduat1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Institute?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Admit?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Desc22?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Medal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Individual?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Inter?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Event?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Student?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Desc24?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_533?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Uni_Criteria05_533?.Uni_Event1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria05_533?.Uni_Event2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_533?.Uni_Desc25?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Uni_Year}</td>

                      {/* 5.1.1 */}
                      <td>{item?.Uni_Criteria05_511?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Scheme}</td>
                      <td>
                        <Table>
                          <thead>
                            <th>Number of students</th>
                            <th>Amount</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria05_511?.Uni_Std}</td>
                              <td>{item?.Uni_Criteria05_511?.Uni_Amt}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <th>Number of students</th>
                            <th>Amount</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria05_511?.Uni_Std1}</td>
                              <td>{item?.Uni_Criteria05_511?.Uni_Amt1}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <th>Number of students</th>
                            <th>Amount</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria05_511?.Uni_Std2}</td>
                              <td>{item?.Uni_Criteria05_511?.Uni_Amt2}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.Uni_Criteria05_511?.Uni_NGO}</td>
                      <td>
                        {item?.Uni_Criteria05_511?.Uni_Desc18?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 5.1.2 */}
                      <td>{item?.Uni_Criteria05_512?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_512?.Uni_HEI}</td>
                      <td>{item?.Uni_Criteria05_512?.Uni_Number}</td>
                      <td>
                        {item?.Uni_Criteria05_512?.Uni_Desc20?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 5.1.3 */}
                      <td>{item?.Uni_Criteria05_513?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_513?.Uni_Capabality}</td>
                      <td>
                        {moment(item?.Uni_Criteria05_513?.Uni_Date).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria05_513?.Uni_Enrolled}</td>
                      <td>{item?.Uni_Criteria05_513?.Uni_Agencies}</td>
                      <td>
                        {item?.Uni_Criteria05_513?.Uni_Desc19?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 5.2.1 */}
                      <td>{item?.Uni_Criteria05_521?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_No}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_RollNo}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_StdSelected}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_NET}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_SLET}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GATE}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GMAT}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_CAT}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GRE}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_JAM}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_IELTS}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_TOEFL}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_Civil}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_State}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_OtherExam}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_Total}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GTotal}</td>
                      <td>
                        {item?.Uni_Criteria05_521?.Uni_Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 5.2.2 */}
                      <td>{item?.Uni_Criteria05_522?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_Placed1}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_Graduate}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_employer}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_Package}</td>
                      <td>
                        {item?.Uni_Criteria05_522?.Uni_Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 5.2.3 */}
                      <td>{item?.Uni_Criteria05_523?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Higher}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Graduat1}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Institute}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Admit}</td>
                      <td>
                        {item?.Uni_Criteria05_523?.Uni_Desc22?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 5.3.1 */}
                      <td>{item?.Uni_Criteria05_531?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Medal}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Individual}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Inter}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Event}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Student}</td>
                      <td>
                        {item?.Uni_Criteria05_531?.Uni_Desc24?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 5.3.3 */}
                      <td>{item?.Uni_Criteria05_533?.Uni_criteria}</td>
                      <td>
                        {moment(item?.Uni_Criteria05_533?.Uni_Event1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria05_533?.Uni_Event2}</td>
                      <td>
                        {item?.Uni_Criteria05_533?.Uni_Desc25?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_511?.Uni_Link18}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_512?.Uni_Link20}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_513?.Uni_Link19}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_521?.Uni_Link23}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_522?.Uni_Link21}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link22}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_531?.Uni_Link24}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_533?.Uni_Link25}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal_9 */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Body>
              <div>{View?.Uni_Criteria05_511?.Uni_Desc18}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_10 */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Body>
              <div>{View?.Uni_Criteria05_512?.Uni_Desc20}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_11 */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Body>
              <div>{View?.Uni_Criteria05_513?.Uni_Desc19}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_12 */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Body>
              <div>{View?.Uni_Criteria05_521?.Uni_Desc23}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose12}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_13 */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Body>
              <div>{View?.Uni_Criteria05_522?.Uni_Desc21}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_14 */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Body>
              <div>{View?.Uni_Criteria05_523?.Uni_Desc22}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose14}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_15 */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Body>
              <div>{View?.Uni_Criteria05_531?.Uni_Desc24}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_16 */}
          <Modal show={show16} onHide={handleClose16}>
            <Modal.Body>
              <div>{View?.Uni_Criteria05_533?.Uni_Desc25}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose16}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UniversityCri5;
