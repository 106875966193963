import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const UG_Criteria003 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);
  const [edit8, setedit8] = useState(false);
  const [add8, setadd8] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  //post method for criteria_03
  const [MainYear3, setMainYear3] = useState("");

  const [ProName, setProName] = useState("");
  const [PrinciName, setPrinciName] = useState("");
  const [PrinciDept, setPrinciDept] = useState("");
  const [YOfAw, setYOfAw] = useState("");
  const [ASanctioned, setASanctioned] = useState("");
  const [DProject, setDProject] = useState("");
  const [NFunding, setNFunding] = useState("");
  const [Type, setType] = useState("");
  const [Desc12, setDesc12] = useState("");
  const [Link12, setLink12] = useState("");

  const [NPati, setNPati] = useState("");
  const [FDate, setFDate] = useState("");
  const [TDate, setTDate] = useState("");
  const [NWorkshop, setNWorkshop] = useState("");
  const [Desc13, setDesc13] = useState("");
  const [Link13, setLink13] = useState("");

  const [TPapaer, setTPapaer] = useState("");
  const [AName, setAName] = useState("");
  const [TDept, setTDept] = useState("");
  const [JName, setJName] = useState("");
  const [PubliYear, setPubliYear] = useState("");
  const [IISSN, setIISSN] = useState("");
  const [UUGC, setUUGC] = useState("");
  const [Desc14, setDesc14] = useState("");
  const [Link14, setLink14] = useState("");
  const [Link15, setLink15] = useState("");

  const [SNo, setSNo] = useState("");
  const [NTea, setNTea] = useState("");
  const [Title1, setTitle1] = useState("");
  const [Title2, setTitle2] = useState("");
  const [Title3, setTitle3] = useState("");
  const [YPub, setYPub] = useState("");
  const [IIISBN, setIIISBN] = useState("");
  const [NPub, setNPub] = useState("");
  const [TimeWhether, setTimeWhether] = useState("");
  const [Desc15, setDesc15] = useState("");
  const [Link16, setLink16] = useState("");

  const [ActiN, setActiN] = useState("");
  const [YearA, setYearA] = useState("");
  const [INAme, setINAme] = useState("");
  const [Bodies, setBodies] = useState("");
  const [Desc16, setDesc16] = useState("");
  const [Link17, setLink17] = useState("");

  const [NaActi, setNaActi] = useState("");
  const [Organ, setOrgan] = useState("");
  const [SchName, setSchName] = useState("");
  const [ActiYear, setActiYear] = useState("");
  const [NStudents, setNStudents] = useState("");
  const [Desc17, setDesc17] = useState("");
  const [Link18, setLink18] = useState("");

  const [SlNo, setSlNo] = useState("");
  const [Collo, setCollo] = useState("");
  const [NCollo, setNCollo] = useState("");
  const [NameParti, setNameParti] = useState("");
  const [ColloYear, setColloYear] = useState("");
  const [Dur, setDur] = useState("");
  const [Desc18, setDesc18] = useState("");
  const [Link19, setLink19] = useState("");

  const [NMou, setNMou] = useState("");
  const [Mou, setMou] = useState("");
  const [Durat, setDurat] = useState("");
  const [List, setList] = useState("");
  const [Desc19, setDesc19] = useState("");
  const [Link20, setLink20] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getugcriteria3 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));

      let am8 = sessionStorage.getItem("cat8");
      setobj8(JSON.parse(am8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getugcriteria3();
  }, []);

  const ugcriteria3 = async () => {
    if (!MainYear3) {
      return alert("Please select the Year");
    }
    console.log("Hello friends: ", {
      Year: MainYear3,

      Criteria03_312: obj1,
      Link12: Link12,

      Criteria03_313: obj2,
      Link13: Link13,

      Criteria03_321: obj3,
      Link14: Link14,
      Link15: Link15,

      Criteria03_322: obj4,
      Link16: Link16,

      Criteria03_332: obj5,
      Link17: Link17,

      Criteria03_333: obj6,
      Link18: Link18,

      Criteria03_341: obj7,
      Link19: Link19,

      Criteria03_342: obj8,
      Link20: Link20,
      addedByFac: facultyDetails?._id,
    });
    try {
      const config = {
        url: "/FacultyAdmin/adddetails03",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Year: MainYear3,

          Criteria03_312: obj1,
          Link12: Link12,

          Criteria03_313: obj2,
          Link13: Link13,

          Criteria03_321: obj3,
          Link14: Link14,
          Link15: Link15,

          Criteria03_322: obj4,
          Link16: Link16,

          Criteria03_332: obj5,
          Link17: Link17,

          Criteria03_333: obj6,
          Link18: Link18,

          Criteria03_341: obj7,
          Link19: Link19,

          Criteria03_342: obj8,
          Link20: Link20,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          sessionStorage.removeItem("cat8");

          navigate("/ugcriteria03");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Desc12;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !ProName ||
          !PrinciName ||
          !PrinciDept ||
          !YOfAw ||
          !ASanctioned ||
          !DProject ||
          !NFunding ||
          !Type ||
          !Desc12 ||
          !Link12
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Link12);
      let obj = {
        criteria: "3.1.1 & 3.1.2",
        ProName: ProName,
        PrinciName: PrinciName,
        PrinciDept: PrinciDept,
        YOfAw: YOfAw,
        ASanctioned: ASanctioned,
        DProject: DProject,
        NFunding: NFunding,
        Type: Type,
        Desc12: Desc12,
        Link12: Link12,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getugcriteria3();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Desc13;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (!NPati || !FDate || !TDate || !NWorkshop || !Desc13 || !Link13) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Link13);
      let obj = {
        criteria: "3.1.3",
        NPati: NPati,
        FDate: FDate,
        TDate: TDate,
        NWorkshop: NWorkshop,
        Desc13: Desc13,
        Link13: Link13,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getugcriteria3();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Desc14;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !TPapaer ||
          !AName ||
          !TDept ||
          !JName ||
          !PubliYear ||
          !IISSN ||
          !UUGC ||
          !Desc14 ||
          !Link14 ||
          !Link15
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Link14);
      console.log("pppp,", Link15);
      let obj = {
        criteria: "3.2.1",
        TPapaer: TPapaer,
        AName: AName,
        TDept: TDept,
        JName: JName,
        PubliYear: PubliYear,
        IISSN: IISSN,
        UUGC: UUGC,
        Desc14: Desc14,
        Link14: Link14,
        Link15: Link15,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getugcriteria3();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Desc15;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !SNo ||
          !NTea ||
          !Title1 ||
          !Title2 ||
          !Title3 ||
          !YPub ||
          !IIISBN ||
          !NPub ||
          !TimeWhether ||
          !Desc15 ||
          !Link16
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }
      console.log("qqqq,", Link16);
      let obj = {
        criteria: "3.2.2",
        SNo: SNo,
        NTea: NTea,
        Title1: Title1,
        Title2: Title2,
        Title3: Title3,
        YPub: YPub,
        IIISBN: IIISBN,
        NPub: NPub,
        TimeWhether: TimeWhether,
        Desc15: Desc15,
        Link16: Link16,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getugcriteria3();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Desc16;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (!ActiN || !YearA || !INAme || !Bodies || !Desc16 || !Link17) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("rrr,", Link17);
      let obj = {
        criteria: "3.3.2",
        ActiN: ActiN,
        YearA: YearA,
        INAme: INAme,
        Bodies: Bodies,
        Desc16: Desc16,
        Link17: Link17,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getugcriteria3();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Desc17;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !NaActi ||
          !Organ ||
          !SchName ||
          !ActiYear ||
          !NStudents ||
          !Desc17 ||
          !Link18
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
        {
          setadd6(false);
        }
      }

      console.log("sss,", Link18);
      let obj = {
        criteria: "3.3.3 & 3.3.4",
        NaActi: NaActi,
        Organ: Organ,
        SchName: SchName,
        ActiYear: ActiYear,
        NStudents: NStudents,
        Desc17: Desc17,
        Link18: Link18,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getugcriteria3();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !SlNo ||
          !Collo ||
          !NCollo ||
          !NameParti ||
          !ColloYear ||
          !Dur ||
          !Desc18 ||
          !Link19
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
        {
          setadd7(false);
        }
      }

      console.log("ttt,", Link19);
      let obj = {
        criteria: "3.4.1",
        SlNo: SlNo,
        Collo: Collo,
        NCollo: NCollo,
        NameParti: NameParti,
        ColloYear: ColloYear,
        Dur: Dur,
        Desc18: Desc18,
        Link19: Link19,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getugcriteria3();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat8 = () => {
    try {
      const minwords = 500;
      const newText = Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add8) {
        if (!NMou || !Mou || !Durat || !List || !Desc19 || !Link20) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd8(false);
        }
      }

      console.log("uuu,", Link20);
      let obj = {
        criteria: "3.4.2",
        NMou: NMou,
        Mou: Mou,
        Durat: Durat,
        List: List,
        Desc19: Desc19,
        Link20: Link20,
      };
      sessionStorage.setItem("cat8", JSON.stringify(obj));
      alert("Successfully added cat8");
      getugcriteria3();
      setedit8(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div className="container">
      <div className="pt-4   " style={{ padding: "10px" }}>
        <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
          {" "}
          UnderGraduate_Criteria-03
        </span>
      </div>

      <div className="container mt-3">
        <div className="row-fluid">
          <div
            class=" col-md-6-fluid border"
            style={{
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <label for="" class="form-label">
              Select Year
            </label>
            {obj1?.MainYear3 ? (
              <p>{obj1?.MainYear3}</p>
            ) : (
              <select
                class="form-select form-select-sm vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setMainYear3(e.target.value)}
              >
                <option selected>--Select Year--</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>

          <div className="container ">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 3.1.2 & 3.1.1</h3>

              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Principal Investigator/Co-Investigator
                  </label>
                  {obj1?.PrinciName ? (
                    <p>{obj1?.PrinciName}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setPrinciName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Project/ Endowments, Chairs
                  </label>
                  {obj1?.ProName ? (
                    <p>{obj1?.ProName}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setProName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Department of Principal Investigator
                  </label>
                  {obj1?.PrinciDept ? (
                    <p>{obj1?.PrinciDept}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPrinciDept(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Award
                  </label>
                  {obj1?.YOfAw ? (
                    <p>{obj1?.YOfAw}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setYOfAw(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount Sanctioned
                  </label>
                  {obj1?.ASanctioned ? (
                    <p>{obj1?.ASanctioned}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setASanctioned(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration of the project
                  </label>
                  {obj1?.DProject ? (
                    <p>{obj1?.DProject}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setDProject(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the Funding Agency
                  </label>
                  {obj1?.NFunding ? (
                    <p>{obj1?.NFunding}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setNFunding(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Type (Government/non-Government)
                  </label>
                  {obj1?.Type ? (
                    <p>{obj1?.Type}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj1?.Desc12 ? (
                    <p>{obj1?.Desc12}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      // cols={57}
                      rows={3}
                      onChange={(e) => setDesc12(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setLink12(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="pt-2 text-end mt-3 ">
                {add1 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat1();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria03_(3.1.2 & 3.1.1)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Project/ Endowments, Chairs
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={ProName}
                            onChange={(e) => {
                              setProName(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{ProName}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Principal Investigator/Co-Investigator
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={PrinciName}
                            onChange={(e) => {
                              setPrinciName(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PrinciName}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Department of Principal Investigator
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={PrinciDept}
                            onChange={(e) => {
                              setPrinciDept(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PrinciDept}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of Award
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={YOfAw}
                            onChange={(e) => {
                              setYOfAw(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{YOfAw}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Amount Sanctioned
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={ASanctioned}
                            onChange={(e) => {
                              setASanctioned(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{ASanctioned}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Duration of the project
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={DProject}
                            onChange={(e) => {
                              setDProject(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{DProject}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of Funding Agency
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={NFunding}
                            onChange={(e) => {
                              setNFunding(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NFunding}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Type (Government/non-Government)
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Type}
                            onChange={(e) => {
                              setType(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Type}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc12}
                            onChange={(e) => setDesc12(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc12}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload1">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className=" name"
                            type="file"
                            name="Year"
                            id="upload1"
                            accept="image/*"
                            onChange={(e) => setLink12(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link12?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit1(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat1();
                      handleClose();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.1.3)</h5>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Participants
                  </label>
                  {obj2?.NPati ? (
                    <p>{obj2?.NPati}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setNPati(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date (From)
                  </label>
                  {obj2?.FDate ? (
                    <p>{obj2?.FDate}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="date"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setFDate(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date (To)
                  </label>
                  {obj2?.TDate ? (
                    <p>{obj2?.TDate}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="date"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setTDate(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the workshop/ seminar/ conference
                  </label>
                  {obj2?.NWorkshop ? (
                    <p>{obj2?.NWorkshop}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setNWorkshop(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj2?.Desc13 ? (
                    <p>{obj2?.Desc13}</p>
                  ) : (
                    <textarea
                      className=" vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc13(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="name"
                    type="file"
                    name="Year"
                    id="upload2"
                    accept="image/*"
                    onChange={(e) => {
                      setLink13(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add2 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat2();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow1}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_3(3.1.3)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Number of Participants
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={NPati}
                            onChange={(e) => {
                              setNPati(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NPati}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Date (From)
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="date"
                            name="fname"
                            id="name"
                            placeholder={FDate}
                            onChange={(e) => {
                              setFDate(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{FDate}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Date (To)
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="date"
                            name="fname"
                            id="name"
                            placeholder={TDate}
                            onChange={(e) => {
                              setTDate(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{TDate}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of workshop/seminar/conference
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={NWorkshop}
                            onChange={(e) => {
                              setNWorkshop(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NWorkshop}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc13}
                            onChange={(e) => setDesc13(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc13}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload2">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="file"
                            name="upload2"
                            id="name"
                            onChange={(e) => setLink13(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link13?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit2(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn-success"
                    onClick={() => {
                      cat2();
                      handleClose1();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3(3.2.1)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of paper
                  </label>
                  {obj3?.TPapaer ? (
                    <p>{obj3?.TPapaer}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setTPapaer(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the author/s
                  </label>
                  {obj3?.AName ? (
                    <p>{obj3?.AName}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setAName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of the teacher
                  </label>
                  {obj3?.TDept ? (
                    <p>{obj3?.TDept}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setTDept(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of Journal
                  </label>
                  {obj3?.JName ? (
                    <p>{obj3?.JName}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setJName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of publication
                  </label>
                  {obj3?.PubliYear ? (
                    <p>{obj3?.PubliYear}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setPubliYear(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ISSN number
                  </label>
                  {obj3?.IISSN ? (
                    <p>{obj3?.IISSN}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setIISSN(e.target.value);
                      }}
                    />
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  Link to the recognition in UGC enlistment of the Journal
                  /Digital Object Identifier (doi) number
                </h6>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </label>
                  {obj3?.UUGC ? (
                    <p>{obj3?.UUGC}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setUUGC(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj3?.Desc14 ? (
                    <p>{obj3?.Desc14}</p>
                  ) : (
                    <textarea
                      className=" vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc14(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload3"
                    accept="image/*"
                    onChange={(e) => {
                      setLink14(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="name"
                    type="file"
                    name="Year"
                    id="upload4"
                    accept="image/*"
                    onChange={(e) => {
                      setLink15(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add3 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat3();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow2}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria03_(3.2.1)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of paper
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={TPapaer}
                            onChange={(e) => {
                              setTPapaer(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{TPapaer}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the author/s
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={AName}
                            onChange={(e) => {
                              setAName(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{AName}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Department of the teacher
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={TDept}
                            onChange={(e) => {
                              setTDept(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{TDept}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of Journal
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" forminput"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={JName}
                            onChange={(e) => {
                              setJName(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{JName}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of publication
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={PubliYear}
                            onChange={(e) => {
                              setPubliYear(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PubliYear}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        ISSN number
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={IISSN}
                            onChange={(e) => {
                              setIISSN(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{IISSN}</>
                      )}
                    </div>
                    <h6 className="text-center text-decoration-underline">
                      Link to the recognition in UGC enlistment of the Journal
                      /Digital Object Identifier (doi) number
                    </h6>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Is it listed in UGC Care list/Scopus/Web of
                        Science/other, mention
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={UUGC}
                            onChange={(e) => {
                              setUUGC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{UUGC}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Description (min 500 words)
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <textarea
                            className=" vi_0"
                            id="name"
                            cols={57}
                            rows={3}
                            placeholder={Desc14}
                            onChange={(e) => {
                              setDesc14(e.target.value);
                            }}
                          ></textarea>
                        </>
                      ) : (
                        <>{Desc14}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload3">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="name"
                            type="file"
                            name="Year"
                            id="upload3"
                            accept="image/*"
                            onChange={(e) => {
                              setLink14(e.target.files[0]);
                            }}
                          />
                        </>
                      ) : (
                        <>{Link14?.name}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload4">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="name"
                            type="file"
                            name="Year"
                            id="upload4"
                            accept="image/*"
                            onChange={(e) => {
                              setLink15(e.target.files[0]);
                            }}
                          />
                        </>
                      ) : (
                        <>{Link15?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit3(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat3();
                      handleClose2();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.2.2)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>
                  {obj4?.SNo ? (
                    <p>{obj4?.SNo}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setSNo(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher
                  </label>
                  {obj4?.NTea ? (
                    <p>{obj4?.NTea}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setNTea(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the book/chapters published
                  </label>
                  {obj4?.Title1 ? (
                    <p>{obj4?.Title1}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setTitle1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the paper
                  </label>
                  {obj4?.Title2 ? (
                    <p>{obj4?.Title2}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setTitle2(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Title of the proceedings of the conference
                  </label>

                  {obj4?.Title3 ? (
                    <p>{obj4?.Title3}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setTitle3(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of publication
                  </label>
                  {obj4?.YPub ? (
                    <p>{obj4?.YPub}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setYPub(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    ISBN/ISSN number of the proceeding
                  </label>
                  {obj4?.IIISBN ? (
                    <p>{obj4?.IIISBN}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setIIISBN(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the publisher
                  </label>
                  {obj4?.NPub ? (
                    <p>{obj4?.NPub}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setNPub(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </label>
                  {obj4?.TimeWhether ? (
                    <p>{obj4?.TimeWhether}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setTimeWhether(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj4?.Desc15 ? (
                    <p>{obj4?.Desc15}</p>
                  ) : (
                    <textarea
                      className=" vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc15(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload5">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="name"
                    type="file"
                    name="Year"
                    id="upload5"
                    accept="image/*"
                    onChange={(e) => {
                      setLink16(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add4 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat4();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow3}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_3.2.2</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Sl. No.
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={SNo}
                            onChange={(e) => {
                              setSNo(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{SNo}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the teacher
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={NTea}
                            onChange={(e) => {
                              setNTea(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NTea}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the book/chapters published
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Title1}
                            onChange={(e) => {
                              setTitle1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Title1}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Title of the proceedings of conference
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Title2}
                            onChange={(e) => {
                              setTitle2(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Title2}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the paper
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Title3}
                            onChange={(e) => {
                              setTitle3(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Title3}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of publication
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={YPub}
                            onChange={(e) => {
                              setYPub(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{YPub}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        ISBN/ISSN number of the proceeding
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={IIISBN}
                            onChange={(e) => {
                              setIIISBN(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{IIISBN}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the publisher
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={NPub}
                            onChange={(e) => {
                              setNPub(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NPub}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Whether at the time of publication Affiliating Was same
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "270px" }}
                            placeholder={TimeWhether}
                            onChange={(e) => {
                              setTimeWhether(e.target.value);
                            }}
                          >
                            <option selected>--Select--</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </>
                      ) : (
                        <>{TimeWhether}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc15}
                            onChange={(e) => setDesc15(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc15}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload5">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="name"
                            type="file"
                            name="Year"
                            id="upload5"
                            accept="image/*"
                            onChange={(e) => {
                              setLink16(e.target.files[0]);
                            }}
                          />
                        </>
                      ) : (
                        <>{Link16?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit4(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat4();
                      handleClose3();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3(3.3.2)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>
                  {obj5?.ActiN ? (
                    <p>{obj5?.ActiN}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setActiN(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of award
                  </label>
                  {obj5?.YearA ? (
                    <p>{obj5?.YearA}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setYearA(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Award/ recognition for Institution
                  </label>
                  {obj5?.INAme ? (
                    <p>{obj5?.INAme}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setINAme(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awarding government/ government recognised
                    bodies
                  </label>
                  {obj5?.Bodies ? (
                    <p>{obj5?.Bodies}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setBodies(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj5?.Desc16 ? (
                    <p>{obj5?.Desc16}</p>
                  ) : (
                    <textarea
                      className=" vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc16(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="name"
                    type="file"
                    name="Year"
                    id="upload6"
                    accept="image/*"
                    onChange={(e) => {
                      setLink17(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add5 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat5();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning "
                      onClick={handleShow4}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show4} onHide={handleClose4}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_3.3.2</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the activity
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={ActiN}
                            onChange={(e) => {
                              setActiN(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{ActiN}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of award
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={YearA}
                            onChange={(e) => {
                              setYearA(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{YearA}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Award/ recognition for Institution
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={INAme}
                            onChange={(e) => {
                              setINAme(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{INAme}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Awarding government/ government recognised
                        bodies
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Bodies}
                            onChange={(e) => {
                              setBodies(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Bodies}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc16}
                            onChange={(e) => setDesc16(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc16}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload6">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="name"
                            type="file"
                            name="Year"
                            id="upload6"
                            accept="image/*"
                            onChange={(e) => {
                              setLink17(e.target.files[0]);
                            }}
                          />
                        </>
                      ) : (
                        <>{Link17?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit5(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat5();
                      handleClose4();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.3.3 & 3.3.4)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>
                  {obj6?.NaActi ? (
                    <p>{obj6?.NaActi}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setNaActi(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Organising unit/ agency/ collaborating agency
                  </label>
                  {obj6?.Organ ? (
                    <p>{obj6?.Organ}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setOrgan(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scheme
                  </label>
                  {obj6?.SchName ? (
                    <p>{obj6?.SchName}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setSchName(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-3 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of the activity
                  </label>
                  {obj6?.ActiYear ? (
                    <p>{obj6?.ActiYear}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setActiYear(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students participated in such activities
                  </label>
                  {obj6?.NStudents ? (
                    <p>{obj6?.NStudents}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setNStudents(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj6?.Desc17 ? (
                    <p>{obj6?.Desc17}</p>
                  ) : (
                    <textarea
                      className=" vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc17(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload7">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="name"
                    type="file"
                    name="Year"
                    id="upload7"
                    accept="image/*"
                    onChange={(e) => {
                      setLink18(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add6 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat6();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow5}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}{" "}
              </div>
              <Modal size="lg" show={show5} onHide={handleClose5}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_3(3.3.3 & 3.3.4)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the activity
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={NaActi}
                            onChange={(e) => {
                              setNaActi(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NaActi}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Organising unit/agency/collaborating agency
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Organ}
                            onChange={(e) => {
                              setOrgan(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Organ}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the scheme
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={SchName}
                            onChange={(e) => {
                              setSchName(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{SchName}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of the activity
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={ActiYear}
                            onChange={(e) => {
                              setActiYear(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{ActiYear}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Number of students participated in such activities
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={NStudents}
                            onChange={(e) => {
                              setNStudents(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NStudents}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc17}
                            onChange={(e) => setDesc17(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc17}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload7">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="file"
                            name="Year"
                            id="upload7"
                            accept="image/*"
                            onChange={(e) => setLink18(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link18?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit6(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat6();
                      handleClose5();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.4.1)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>
                  {obj7?.SlNo ? (
                    <p>{obj7?.SlNo}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setSlNo(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the collaborative activity
                  </label>
                  {obj7?.Collo ? (
                    <p>{obj7?.Collo}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setCollo(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the collaborating / linking agency with contact
                    details
                  </label>
                  {obj7?.NCollo ? (
                    <p>{obj7?.NCollo}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setNCollo(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the participant
                  </label>
                  {obj7?.NameParti ? (
                    <p>{obj7?.NameParti}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setNameParti(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of collaboration
                  </label>
                  {obj7?.ColloYear ? (
                    <p>{obj7?.ColloYear}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setColloYear(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>
                  {obj7?.Dur ? (
                    <p>{obj7?.Dur}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setDur(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj7?.Desc18 ? (
                    <p>{obj7?.Desc18}</p>
                  ) : (
                    <textarea
                      className=" vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc18(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload8">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="name"
                    type="file"
                    name="Year"
                    id="upload8"
                    accept="image/*"
                    onChange={(e) => {
                      setLink19(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add7 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat7();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow6}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}{" "}
              </div>
              <Modal size="lg" show={show6} onHide={handleClose6}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_3 (3.4.1)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Sl. No.
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={SlNo}
                            onChange={(e) => {
                              setSlNo(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{SlNo}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the collaborative activity
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Collo}
                            onChange={(e) => {
                              setCollo(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Collo}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the collaborating / linking agency with contact
                        details
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={NCollo}
                            onChange={(e) => {
                              setNCollo(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NCollo}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the participant
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={NameParti}
                            onChange={(e) => {
                              setNameParti(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NameParti}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of collaboration
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={ColloYear}
                            onChange={(e) => {
                              setColloYear(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{ColloYear}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Duration
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Dur}
                            onChange={(e) => {
                              setDur(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Dur}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc18}
                            onChange={(e) => setDesc18(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc18}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload8">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="file"
                            name="Year"
                            id="upload8"
                            accept="image/*"
                            onChange={(e) => setLink19(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link19?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit7(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat7();
                      handleClose6();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_3 (3.4.2)</h5>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the institution/ industry/ corporate house with whom
                    MoU is signed
                  </label>
                  {obj8?.NMou ? (
                    <p>{obj8?.NMou}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setNMou(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of signing MOU
                  </label>
                  {obj8?.Mou ? (
                    <p>{obj8?.Mou}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setMou(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>
                  {obj8?.Durat ? (
                    <p>{obj8?.Durat}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setDurat(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    List the actual activities under each MOU and web links
                    year-wise
                  </label>
                  {obj8?.List ? (
                    <p>{obj8?.List}</p>
                  ) : (
                    <input
                      className=" vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setList(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj8?.Desc19 ? (
                    <p>{obj8?.Desc19}</p>
                  ) : (
                    <textarea
                      className=" vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc19(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload9">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="name"
                    type="file"
                    name="Year"
                    id="upload9"
                    accept="image/*"
                    onChange={(e) => {
                      setLink20(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add8 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat8();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow7}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}{" "}
              </div>
              <Modal size="lg" show={show7} onHide={handleClose7}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_3 (3.4.2)</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the institution/ industry/ corporate house with
                        whom MoU is signed
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={NMou}
                            onChange={(e) => {
                              setNMou(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NMou}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of signing MOU
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Mou}
                            onChange={(e) => {
                              setMou(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Mou}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Duration
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Durat}
                            onChange={(e) => {
                              setDurat(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Durat}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        List the actual activities under each MOU and web links
                        year-wise
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={List}
                            onChange={(e) => {
                              setList(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{List}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <textarea
                            className=" vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc19}
                            onChange={(e) => setDesc19(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc19}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload9">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className=" vi_0"
                            type="file"
                            name="Year"
                            id="upload9"
                            accept="image/*"
                            onChange={(e) => setLink20(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link20?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit8(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      cat8();
                      handleClose7();
                    }}
                    style={{ color: "white" }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="text-center mt-3 mb-3">
            <button
              type="submit"
              className="btn btn-success "
              onClick={() => ugcriteria3()}
              style={{ color: "white", marginTop: "15px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UG_Criteria003;
