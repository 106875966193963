import axios from "axios";
import React, { useEffect, useState } from "react";
import UG3332aqar from "./UG3332aqar";
import UG3322aqar from "./UG3322aqar";
import UG3321aqar from "./UG3321aqar";
import UG3313aqar from "./UG3313aqar";
import UG3312aqar from "./UG3312aqar";
import UG3333aqar from "./UG3333aqar";
import UG3341aqar from "./UG3341aqar";
import UG3342aqar from "./UG3342aqar";
import { useNavigate } from "react-router-dom";
const UGC3aqar = ({ startYeardata, endYeardata }) => {
  const [ugcriteria03, setugcriteria03] = useState([]);
  const navigate = useNavigate();
  const ugCriteria03Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria03"
      );
      if (res.status === 200) {
        setugcriteria03(
          res.data.ugcriteria03?.filter(
            (item) =>
              item?.Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.ugcriteria03?.filter(
          (item) =>
            item?.Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setugcriteria03(error.response.data.ugcriteria03);
    }
  };
  useEffect(() => {
    ugCriteria03Details();
  }, [startYeardata, endYeardata]);
  return (
    <div>
      <div className="text-center">
        <b>Criteria 3</b>
      </div>
      {ugcriteria03?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.1.2)
            </div>
            <UG3312aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.1.3)
            </div>
            <UG3313aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.2.1)
            </div>
            <UG3321aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.2.2)
            </div>
            <UG3322aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.3.2)
            </div>
            <UG3332aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.3.3)
            </div>
            <UG3333aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.4.1)
            </div>
            <UG3341aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.4.2)
            </div>
            <UG3342aqar item={item} />
          </div>
        );
      })}
    </div>
  );
};

export default UGC3aqar;
