import moment from "moment";
import React from "react";

const UG7777aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Total Lighting requirements</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria07_007?.Lighting}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Budget allocated for green initiatives</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria07_007?.Budget}</span>
        </div>
      </div>

      <div>
        <b>Status of implementation of CBCS / Elective Course System (ECS)</b>
      </div>
      <div style={{ marginLeft: "20px" }}>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Physical Facilities</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.PhyFac}</span>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Provision for Lift</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.Prolift}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Ramp/ Rails</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.Ramp}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Braille Software/Facilities</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span>{item?.Criteria07_007?.Braille}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Rest Rooms</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.RestRoom}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Scribes for Examination</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.Scribes}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>
              Special skill Development for Differently Abled Students
            </span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span>{item?.Criteria07_007?.SpecialSkills}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Any other Similar Facility</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.AnyOther}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>
              Annual expenditure excluding salary component of the institution
            </span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.Annual}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>
              Number of initiatives to address locational Advantages and
              Disadvantages
            </span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.NoOfIniti1}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>
              Number of initiatives taken to Engage with and contribute to Local
              Community
            </span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.NoOfIniti2}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>
              Date and Duration of the Initiative Name of the Initiative Issues
              Addressed
            </span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.DateDuration}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Power Requirement met by Renewable Energy Sources</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.Power}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Total Power requirements</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.TPower}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Renewable Energy Source</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.ReEnergyS}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Renewable Energy Generated and Used</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.ReEnergyG}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Energy Supplied to the Grid</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.EnergySup}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Percentage Lighting through LED bulbs</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.PercentageLED}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Percentage Lighting through other sources</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.PercentageLight}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Number of Participating Students and Staff</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.NoOfParticipants}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>No. of Activities</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.NoOfActivities}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>TTitle of the Program/Activity</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.TitlePro}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 report-head  mb-3">
            <span>Number of Participants</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.NoOfParticipants1}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date and Duration</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {" "}
            {moment(item?.Criteria07_007?.Date1).format("DD/MM/YYYY")}
            {" - "}
            {moment(item?.Criteria07_007?.Date2).format("DD-MM-YYYY")}
          </span>
        </div>
      </div>

      <div>
        <b>Number of participants by Gender</b>
      </div>
      <div style={{ marginLeft: "20px" }}>
        <div className="row">
          <div className="col-lg-6 report-head  mb-3">
            <span>Male</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.Male}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 report-head  mb-3">
            <span>Female</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.Female}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 report-head  mb-3">
            <span>Year</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.Yea}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 report-head  mb-3">
            <span>Total</span>
          </div>
          <div className="col-lg-6 report-text  mb-3">
            <span> {item?.Criteria07_007?.Total}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Power Requirement Renewable</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria07_007?.PowRe}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Initiative names</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria07_007?.InitiName}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Duration of the Initiative</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria07_007?.DurInitiative}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria07_007?.Desc29}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria07/${item?.Criteria07_007?.Link29}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria07/${item?.Criteria07_007?.Link30}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria07/${item?.Criteria07_007?.Link31}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG7777aqar;
