import React from "react";

const PG5522aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of student enrolling into higher education</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_522?.PG_HighName}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Program graduated from</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_522?.PG_PGrad}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of institution joined</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_522?.PG_Institute}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of programme admitted to</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_522?.PG_Admitted}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria05_522?.PG_Desc25}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {/* <a href={`${item?.PG_Criteria05_522?.PG_Link25}`}> */}

            {/* </a> */}
            <a
              href={`https://brightnaac.co.in/PG_Criteria05/${item?.PG_Criteria05_522?.PG_Link25}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG5522aqar;
