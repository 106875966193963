import React, { useEffect, useState } from "react";
import "../Component/Styles/Announment.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

function PopularCourse() {
  const navigate = useNavigate();

  // =================== calling api for getting the Course content======================
  const [CoursesList, setCoursesList] = useState([]);
  const getCourseList = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getCourseDetails"
      );
      if (res.status === 200) {
        setCoursesList(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getCourseList();
  }, []);
  // useEffect(() => {
  //   getCourseList();
  // }, []);

  return (
    <>
      <div
        className="ab-0"
        style={{
          backgroundImage: "url('../Image/about-us.jpg')",
          height: "250px",
          position: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="edg container">
          <div className="edgt-has-inline-style">Courses</div>
          <div class="edgt-breadcrumbs-info">
            <div
              col-md-4Prop="breadcrumb"
              classname="edgt-breadcrumbs "
              style={{ color: "#e1e0e0" }}
            >
              <Link
                col-md-4Prop="url"
                to="/"
                style={{ color: "#e1e0e0", textDecoration: "none" }}
              >
                Home
              </Link>
              <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
              <span classname="edgt-current">Courses</span>
            </div>
          </div>
        </div>
      </div>

      {/* <section>
        <div className="pap-0" data-aos="fade-up" data-aos-duration="2000">
          <div className="container">
            <div className="popular">
              <h4 style={{ color: "black" }}> Courses</h4>
              <div className="car-popular mt-3">
                <div className="row">
                  {CoursesList?.map((item) => {
                    return (
                      <div
                        class=" col-lg-4 col-md-12 col-sm-12 mb-3"
                        onClick={() =>
                          navigate("/coursesingle", { state: item })
                        }
                      >
                        <div className="max">
                          <div className="sd-max mb-2">
                            <img
                              src={`https://brightnaac.co.in/Course/${item?.courseImg}`}
                              alt="course1"
                              className="img-max"
                            />
                          </div>
                          <div className="course-name">
                            <Link
                              className="course-0 mb-2"
                              // to={"/coursesingle"}
                            >
                              {item?.courseName}
                            </Link>
                            <p>{item?.courseDescription?.slice(0, 150)}...</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className="container">
        <h4 style={{ color: "black" }}> Courses</h4>

        <div className="row my-3" data-aos="fade-up" data-aos-duration="2000">
          {CoursesList?.map((item) => {
            return (
              <div
                className="col-md-4"
                onClick={() => navigate("/coursesingle", { state: item })}
              >
                <Card style={{ width: "21rem", height: "425px" }}>
                  <Card.Img
                    variant="top"
                    src={`https://brightnaac.co.in/Course/${item?.courseImg}`}
                  />
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      {item?.courseName?.slice(0, 21)}...
                    </Card.Title>
                    <Card.Text>
                      {item?.courseDescription?.slice(0, 125)}...
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </div>

      {/* <section class="wrapper">
    <div class="container-fostrap">
        <div>
            <img src="https://4.bp.blogspot.com/-7OHSFmygfYQ/VtLSb1xe8kI/AAAAAAAABjI/FxaRp5xW2JQ/s320/logo.png" class="fostrap-logo"/>
            <h1 class="heading">
                Bootstrap Card Responsive 
            </h1>
        </div>
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-4">
                        <div class="card">
                            <a class="img-card" href="http://www.fostrap.com/2016/03/bootstrap-3-carousel-fade-effect.html">
                            <img src="https://1.bp.blogspot.com/-Bii3S69BdjQ/VtdOpIi4aoI/AAAAAAAABlk/F0z23Yr59f0/s640/cover.jpg" />
                          </a>
                            <div class="card-content">
                                <h4 class="card-title">
                                    <a href="http://www.fostrap.com/2016/03/bootstrap-3-carousel-fade-effect.html"> Bootstrap 3 Carousel FadeIn Out Effect
                                  </a>
                                </h4>
                                <p class="">
                                    Tutorial to make a carousel bootstrap by adding more wonderful effect fadein ...
                                </p>
                            </div>
                            <div class="card-read-more">
                                <a href="http://www.fostrap.com/2016/03/bootstrap-3-carousel-fade-effect.html" class="btn btn-link btn-block">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4">
                        <div class="card">
                            <a class="img-card" href="http://www.fostrap.com/2016/03/5-button-hover-animation-effects-css3.html">
                            <img src="https://3.bp.blogspot.com/-bAsTyYC8U80/VtLZRKN6OlI/AAAAAAAABjY/kAoljiMALkQ/s400/material%2Bnavbar.jpg" />
                          </a>
                            <div class="card-content">
                                <h4 class="card-title">
                                    <a href="http://www.fostrap.com/2016/02/awesome-material-design-responsive-menu.html"> Material Design Responsive Menu
                                  </a>
                                </h4>
                                <p class="">
                                    Material Design is a visual programming language made by Google. Language programming...
                                </p>
                            </div>
                            <div class="card-read-more">
                                <a href="https://codepen.io/wisnust10/full/ZWERZK/" class="btn btn-link btn-block">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4">
                        <div class="card">
                            <a class="img-card" href="http://www.fostrap.com/2016/03/5-button-hover-animation-effects-css3.html">
                            <img src="https://4.bp.blogspot.com/-TDIJ17DfCco/Vtneyc-0t4I/AAAAAAAABmk/aa4AjmCvRck/s1600/cover.jpg" />
                          </a>
                            <div class="card-content">
                                <h4 class="card-title">
                                    <a href="http://www.fostrap.com/2016/03/5-button-hover-animation-effects-css3.html">5  Button Hover Animation Effects
                                  </a>
                                </h4>
                                <p class="">
                                    tutorials button hover animation, although very much a hover button is very beauti...
                                </p>
                            </div>
                            <div class="card-read-more">
                                <a href="http://www.fostrap.com/2016/03/5-button-hover-animation-effects-css3.html" class="btn btn-link btn-block">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}
    </>
  );
}

export default PopularCourse;
