import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Nav from "react-bootstrap/Nav";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
const Ucriteria5 = () => {
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
  console.log("approvedetails", approvedetails);
  const [selectedYear, setselectedYear] = useState("");
  const [AllDetails, setAllDetails] = useState([]);
  const navigate = useNavigate();

  const [Uni_Criteria05, setUni_Criteria05] = useState([]);
  const [Value, setValue] = useState({});
  const [View, setView] = useState({});

  const [remark, setremark] = useState("");
  // =====================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  //====================================================

  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Uni_Cri5.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          //  Criteria 5_511

          Uni_Year: item?.Uni_Year,
          "Criteria_5.1.1": item?.Uni_Criteria05_511?.Uni_criteria,
          "Name of the scheme": item?.Uni_Criteria05_511?.Uni_Scheme,
          "Number of students": item?.Uni_Criteria05_511?.Uni_Std,
          Amount: item?.Uni_Criteria05_511?.Uni_Amt,
          "Number of students": item?.Uni_Criteria05_511?.Uni_Std1,
          Amount: item?.Uni_Criteria05_511?.Uni_Amt1,
          "Number of students": item?.Uni_Criteria05_511?.Uni_Std2,
          Amount: item?.Uni_Criteria05_511?.Uni_Amt2,
          "Name of the NGO/agency": item?.Uni_Criteria05_511?.Uni_NGO,
          "Description (min 500 characters)(5.1.1)":
            item?.Uni_Criteria05_511?.Uni_Desc18,
          "Document(5.1.1)": item?.Uni_Criteria05_511?.Uni_Link18,

          // Criteria 5_512
          "Criteria_5.1.2": item?.Uni_Criteria05_512?.Uni_criteria,
          "Name of the Activity conducted by the HEI to offer guidance for competitive examinations/ career counselling offered by the institution during the last five years":
            item?.Uni_Criteria05_512?.Uni_HEI,
          "Number of students attended / participated":
            item?.Uni_Criteria05_512?.Uni_Number,
          "Description (min 500 characters)(5.1.2)":
            item?.Uni_Criteria05_512?.Uni_Desc20,
          "Document(5.1.2)": item?.Uni_Criteria05_512?.Uni_Link20,

          // Criteria 5_513

          "Criteria_5.1.3": item?.Uni_Criteria05_513?.Uni_criteria,
          "Name of the capability enhancement program":
            item?.Uni_Criteria05_513?.Uni_Capabality,
          "Date of implementation (DD-MM-YYYY)":
            item?.Uni_Criteria05_513?.Uni_Date,
          "Number of students enrolled": item?.Uni_Criteria05_513?.Uni_Enrolled,
          "Name of the agencies/consultants involved with contact details (if any)":
            item?.Uni_Criteria05_513?.Uni_Agencies,
          "Description (min 500 characters)(5.1.3)":
            item?.Uni_Criteria05_513?.Uni_Desc19,
          "Document(5.1.3)": item?.Uni_Criteria05_513?.Uni_Link19,

          // Criteria 5_521
          "Criteria_5.2.1": item?.Uni_Criteria05_521?.Uni_criteria,
          "Sl.no.": item?.Uni_Criteria05_521?.Uni_No,
          "Registration number/roll number for the exam":
            item?.Uni_Criteria05_521?.Uni_RollNo,
          "Names of students selected/ qualified":
            item?.Uni_Criteria05_521?.Uni_StdSelected,
          NET: item?.Uni_Criteria05_521?.Uni_NET,
          SLET: item?.Uni_Criteria05_521?.Uni_SLET,
          GATE: item?.Uni_Criteria05_521?.Uni_GATE,
          GMAT: item?.Uni_Criteria05_521?.Uni_GMAT,
          CAT: item?.Uni_Criteria05_521?.Uni_CAT,
          GRE: item?.Uni_Criteria05_521?.Uni_GRE,
          JAM: item?.Uni_Criteria05_521?.Uni_JAM,
          IELTS: item?.Uni_Criteria05_521?.Uni_IELTS,
          TOEFL: item?.Uni_Criteria05_521?.Uni_TOEFL,
          "Civil Services": item?.Uni_Criteria05_521?.Uni_Civil,
          "State government examinations": item?.Uni_Criteria05_521?.Uni_State,
          "Other examinations conducted by the State / Central Government Agencies (Specify)":
            item?.Uni_Criteria05_521?.Uni_OtherExam,
          Total: item?.Uni_Criteria05_521?.Uni_Total,
          "Grand Total": item?.Uni_Criteria05_521?.Uni_GTotal,
          "Description (min 500 characters)(5.2.1)":
            item?.Uni_Criteria05_521?.Uni_Desc23,
          "Document(5.2.1)": item?.Uni_Criteria05_521?.Uni_Link23,

          // Criteria 5_522
          "Criteria_5.2.2": item?.Uni_Criteria05_522?.Uni_criteria,
          "Name of student placed": item?.Uni_Criteria05_522?.Uni_Placed1,
          "Program graduated from": item?.Uni_Criteria05_522?.Uni_Graduate,
          "Name of the employer": item?.Uni_Criteria05_522?.Uni_employer,
          "Pay package at appointment": item?.Uni_Criteria05_522?.Uni_Package,
          "Description (min 500 characters)(5.2.2)":
            item?.Uni_Criteria05_522?.Uni_Desc21,
          "Document(5.2.2)": item?.Uni_Criteria05_522?.Uni_Link21,

          // Criteria 5_523
          "Criteria_5.2.3": item?.Uni_Criteria05_523?.Uni_criteria,
          "Name of student enrolling into higher education":
            item?.Uni_Criteria05_523?.Uni_Higher,
          "Program graduated from": item?.Uni_Criteria05_523?.Uni_Graduat1,
          "Name of institution joined": item?.Uni_Criteria05_523?.Uni_Institute,
          "Name of program admitted to": item?.Uni_Criteria05_523?.Uni_Admit,
          "Description min 500 characters(5.2.3)":
            item?.Uni_Criteria05_523?.Uni_Desc22,
          "Link to website of the Journal(5.2.3)":
            item?.Uni_Criteria05_523?.Uni_Link22,

          // Criteria 5_531
          "Criteria_5.3.1": item?.Uni_Criteria05_531?.Uni_criteria,
          "Name of the award/ medal ": item?.Uni_Criteria05_531?.Uni_Medal,
          "Team / Individual": item?.Uni_Criteria05_531?.Uni_Individual,
          "Inter-university / state / National/ International":
            item?.Uni_Criteria05_531?.Uni_Inter,
          "Name of the event": item?.Uni_Criteria05_531?.Uni_Event,
          "Name of the student": item?.Uni_Criteria05_531?.Uni_Student,
          "Description min 500 characters(5.3.1)":
            item?.Uni_Criteria05_531?.Uni_Desc24,
          "Link to relevnt document(5.3.1)":
            item?.Uni_Criteria05_531?.Uni_Link24,

          // Criteria 5_533
          "Criteria_5.3.3": item?.Uni_Criteria05_533?.Uni_criteria,
          "Date of event/competition (DD-MM-YYYY) ":
            item?.Uni_Criteria05_533?.Uni_Event1,
          "Name of the event/competition": item?.Uni_Criteria05_533?.Uni_Event2,
          "Description min 500 characters(5.3.3)":
            item?.Uni_Criteria05_533?.Uni_Desc25,
          "Link to relevnt document(5.3.3)":
            item?.Uni_Criteria05_533?.Uni_Link25,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  // ==================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaU5/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri05details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //====================================================

  const getunicri05details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getunicri05details"
      );
      if (res.status == 200) {
        setAllDetails(res.data.unicriteria05);
        setUni_Criteria05(res.data.unicriteria05);
        setdata1(res.data.unicriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria05);
    }
  };

  useEffect(() => {
    getunicri05details();
  }, []);
  console.log(Uni_Criteria05);

  // ===================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-8 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-05
              </span>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-4 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div>
            <Nav defaultActiveKey="/unicriteria05">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/U_05_511")}
                >
                  <Button className="criteriabtn  btn-sub1">5.1.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/U_05_512")}
                >
                  <Button className="criteriabtn  btn-sub">5.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_05_513")}
                >
                  <Button className="criteriabtn  btn-sub">5.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_05_521")}
                >
                  <Button className="criteriabtn  btn-sub">5.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_05_522")}
                >
                  <Button className="criteriabtn  btn-sub">5.2.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_05_523")}
                >
                  <Button className="criteriabtn  btn-sub">5.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_05_531")}
                >
                  <Button className="criteriabtn  btn-sub">5.3.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_05_533")}
                >
                  <Button className="criteriabtn  btn-sub">5.3.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "20000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>Criteria</th>
                  <th>Name of the scheme</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Name of the NGO/agency</th>
                  <th>Description </th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counselling offered by
                    the institution during the last five years
                  </th>
                  <th>Number of students attended / participated</th>
                  <th>Description </th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the capability enhancement program</th>
                  <th>Date of implementation (DD-MM-YYYY)</th>
                  <th>Number of students enrolled</th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description </th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Registration number/roll number for the exam</th>
                  <th>Names of students selected/ qualified</th>
                  <th>NET</th>
                  <th>SLET</th>
                  <th>GATE</th>
                  <th>GMAT</th>
                  <th>CAT</th>
                  <th>GRE</th>

                  <th>JAM</th>
                  <th>IELTS</th>
                  <th>TOEFL</th>
                  <th>Civil Services</th>
                  <th>State government examinations</th>
                  <th>
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </th>
                  <th>Total</th>
                  <th>Grand Total</th>
                  <th>Description </th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student placed</th>
                  <th>Program graduated from</th>
                  <th>Name of the employer</th>
                  <th>Pay package at appointment</th>
                  <th>Description </th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student enrolling into higher education</th>
                  <th>Program graduated from</th>
                  <th>Name of institution joined</th>
                  <th>Name of program admitted to</th>
                  <th>Description </th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>Inter-university / state / National/ International</th>
                  <th>Name of the event</th>
                  <th>Name of the student</th>
                  <th>Description </th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Date of event/competition (DD-MM-YYYY)</th>
                  <th>Name of the event/competition</th>
                  <th>Description </th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {Uni_Criteria05?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>
                      {/* <td>{approvedetails?.FName}</td> */}

                      <td>{item?.Uni_Criteria05_511?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Scheme}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Std}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Amt}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Std1}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Amt1}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Std2}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Amt2}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_NGO}</td>
                      <td>
                        {item?.Uni_Criteria05_511?.Uni_Desc18?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_512?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_512?.Uni_HEI}</td>
                      <td>{item?.Uni_Criteria05_512?.Uni_Number}</td>

                      <td>
                        {item?.Uni_Criteria05_512?.Uni_Desc20?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_513?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_513?.Uni_Capabality}</td>
                      <td>
                        {moment(item?.Uni_Criteria05_513?.Uni_Date).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria05_513?.Uni_Enrolled}</td>
                      <td>{item?.Uni_Criteria05_513?.Uni_Agencies}</td>
                      <td>
                        {item?.Uni_Criteria05_513?.Uni_Desc19?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_521?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_No}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_RollNo}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_StdSelected}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_NET}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_SLET}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GATE}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GMAT}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_CAT}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GRE}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_JAM}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_IELTS}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_TOEFL}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_Civil}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_State}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_OtherExam}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_Total}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GTotal}</td>
                      <td>
                        {item?.Uni_Criteria05_521?.Uni_Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_522?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_Placed1}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_Graduate}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_employer}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_Package}</td>

                      <td>
                        {item?.Uni_Criteria05_522?.Uni_Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_523?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Higher}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Graduat1}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Institute}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Admit}</td>

                      <td>
                        {item?.Uni_Criteria05_523?.Uni_Desc22?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_531?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Medal}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Individual}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Inter}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Event}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Student}</td>
                      <td>
                        {item?.Uni_Criteria05_531?.Uni_Desc24?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_533?.Uni_criteria}</td>
                      <td>
                        {moment(item?.Uni_Criteria05_533?.Uni_Event1).format(
                          "MM-DD-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria05_533?.Uni_Event2}</td>

                      <td>
                        {item?.Uni_Criteria05_533?.Uni_Desc25?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Show description modal */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_511?.Uni_Desc18}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_512?.Uni_Desc20}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_513?.Uni_Desc19}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_521?.Uni_Desc23}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose12}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_522?.Uni_Desc21}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose13}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_523?.Uni_Desc22}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose14}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_531?.Uni_Desc24}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose15}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_533?.Uni_Desc25}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose16}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link18}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link19}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link21}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 6 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link22}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 7 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link23}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 8 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link24}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              {" "}
              <span className="modal-head-text">
                University_Criteria-05
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------5.1.1------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the scheme
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_Scheme}</>
                </div>

                <h6 className="text-center text-decoration-underline">
                  Number of students benefited by government scheme and amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_Std}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_Amt}</>
                </div>

                <h6 className="text-center text-decoration-underline">
                  Number of students benefited by the institution's schemes and
                  amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_Std1}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_Amt1}</>
                </div>

                <h6 className="text-center text-decoration-underline">
                  Number of students benefited by the non-government agencies
                  (NGOs) and amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_Std2}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_Amt2}</>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the NGO/agency
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_NGO}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_Desc18}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria05_511?.Uni_Link18}</>
                </div>
              </div>
            </div>

            {/* ------5.1.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counselling offered by
                    the institution during the last five years
                  </label>

                  <>{Value?.Uni_Criteria05_512?.Uni_HEI}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students attended / participated
                  </label>

                  <>{Value?.Uni_Criteria05_512?.Uni_Number}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria05_512?.Uni_Desc20}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria05_512?.Uni_Link20}</>
                </div>
              </div>
            </div>

            {/* ------5.1.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the capability enhancement program
                  </label>

                  <>{Value?.Uni_Criteria05_513?.Uni_Capabality}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of implementation (DD-MM-YYYY)
                  </label>

                  <>
                    {moment(Value?.Uni_Criteria05_513?.Uni_Date).format(
                      "DD-MM-YYYY"
                    )}
                  </>
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students enrolled
                  </label>

                  <>{Value?.Uni_Criteria05_513?.Uni_Enrolled}</>
                </div>
                <div className="col-md-8 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </label>

                  <>{Value?.Uni_Criteria05_513?.Uni_Agencies}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria05_513?.Uni_Desc19}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria05_513?.Uni_Link19}</>
                </div>
              </div>
            </div>

            {/* ------5.2.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-4 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Sl.no.
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_No}</>
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Registration number/roll number for the exam
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_RollNo}</>
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Uni_NameS of students selected/ qualified
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_StdSelected}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    NET
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_NET}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    SLET
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_SLET}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GATE
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_GATE}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GMAT
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_GMAT}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    CAT
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_CAT}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GRE
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_GRE}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    JAM
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_JAM}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    IELTS
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_IELTS}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TOEFL
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_TOEFL}</>
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Civil Services
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_Civil}</>
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State government examinations
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_State}</>
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_OtherExam}</>
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_Total}</>
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Grand Total
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_521?.Uni_GTotal}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria05_521?.Uni_Desc23}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria05_521?.Uni_Link23}</>
                </div>
              </div>
            </div>

            {/* ------5.2.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student placed
                  </label>

                  <>{Value?.Uni_Criteria05_522?.Uni_Placed1}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>

                  <>{Value?.Uni_Criteria05_522?.Uni_Graduate}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the employer
                  </label>

                  <>{Value?.Uni_Criteria05_522?.Uni_employer}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Pay package at appointment
                  </label>

                  <>{Value?.Uni_Criteria05_522?.Uni_Package}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria05_522?.Uni_Desc21}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria05_522?.Uni_Link21}</>
                </div>
              </div>
            </div>

            {/* ------5.2.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student enrolling into higher education
                  </label>

                  <>{Value?.Uni_Criteria05_523?.Uni_Higher}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>

                  <>{Value?.Uni_Criteria05_523?.Uni_Graduat1}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of institution joined
                  </label>

                  <>{Value?.Uni_Criteria05_523?.Uni_Institute}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of programme admitted to
                  </label>

                  <>{Value?.Uni_Criteria05_523?.Uni_Admit}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria05_523?.Uni_Desc22}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload5">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria05_523?.Uni_Link22}</>
                </div>
              </div>
            </div>

            {/* ------5.3.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/ medal
                  </label>

                  <>{Value?.Uni_Criteria05_531?.Uni_Medal}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Team / Individual
                  </label>

                  <>{Value?.Uni_Criteria05_531?.Uni_Individual}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    inter-university / state / National/ International
                  </label>

                  <>{Value?.Uni_Criteria05_531?.Uni_Inter}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the event
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_531?.Uni_Event}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the student
                  </label>{" "}
                  <>{Value?.Uni_Criteria05_531?.Uni_Student}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria05_531?.Uni_Desc24}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to website of the Journal
                  </label>

                  <>{Value?.Uni_Criteria05_531?.Uni_Link24}</>
                </div>
              </div>
            </div>

            {/* ------5.3.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of event/competition (DD-MM-YYYY)
                  </label>

                  <>
                    {moment(Value?.Uni_Criteria05_533?.Uni_Event1).format(
                      "DD-MM-YYYY"
                    )}
                  </>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the event/competition
                  </label>

                  <>{Value?.Uni_Criteria05_533?.Uni_Event2}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria05_533?.Uni_Desc25}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document of the Journal
                  </label>

                  <>{Value?.Uni_Criteria05_533?.Uni_Link25}</>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    approveCriteria(e, true, Value?._id);
                    handleClose();
                  }}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => handleClosesm()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              onChange={(e) => setremark(e.target.value)}
              style={{ height: "200px" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                approveCriteria(e, false, Value?._id);
                handleClose();
                handleClosesm();
              }}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Ucriteria5;
