import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Image, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
function Description() {
  // ================ add description modal===============================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================ delete description modal===============================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================ edit description modal===============================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // =================== calling api for adding the slider content======================
  let formdata = new FormData();
  const [Topic, setTopic] = useState("");
  const [Body, setBody] = useState("");
  const [View, setView] = useState({});

  const AddDescription = async (e) => {
    e.preventDefault();

    if (!Topic || !Body) {
      return alert("Please fill all the fields!!!");
    } else {
      try {
        const config = {
          url: "/admin/addDescription",
          method: "post",
          baseURL: "https://brightnaac.co.in/api",
          headers: {
            headers: { "content-type": "application/json" },
          },
          data: {
            topic: Topic,
            body: Body,
          },
        };
        await axios(config).then((res) => {
          alert("Added Successfully");
          setBody("");
          setTopic("");
          getDescData();
          handleClose();
        });
      } catch (error) {
        console.log(error);
        handleClose();
      }
    }
  };

  // =================== calling api for getting the slider content======================
  const [Descp, setDescp] = useState([]);

  const getDescData = async () => {
    let res = await axios.get("https://brightnaac.co.in/api/admin/getdescp");
    if (res.status === 200) {
      setDescp(res.data.allData);
    }
  };

  // =================== calling api for deleting the slider content======================
  const deleteDescription = async (e, id) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/deletedescp/" + id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setBody("");
          setTopic("");
          getDescData();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };

  const editDescription = async (e, id) => {
    e.preventDefault();

    if (!Topic && !Body) {
      return alert("Please Provide atleast one detail to edit");
    } else {
      try {
        const config = {
          url: "/admin/editdescp/" + id,
          method: "put",
          baseURL: "https://brightnaac.co.in/api",
          headers: {
            headers: { "content-type": "application/json" },
          },
          data: {
            topic: Topic,
            body: Body,
          },
        };
        await axios(config).then((res) => {
          alert(res.data.success);
          setBody("");
          setTopic("");
          getDescData();
          handleClose2();
        });
      } catch (error) {
        console.log(error);
        handleClose2();
      }
    }
  };

  useEffect(() => {
    getDescData();
  }, []);

  return (
    <>
      <div className="conrainer-fluid">
        <div className="row p-0 m-0">
          <div className="row justify-content-bteween align-items-center m-0 p-0  mt-4 mb-4">
            <div className="col-lg-6">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Description
              </span>
            </div>

            <div className="header-02 col-lg-6 text-end">
              {Descp?.length > 0 ? (
                <></>
              ) : (
                <Button onClick={handleShow}>Add</Button>
              )}
            </div>
          </div>

          <Table responsive bordered striped className="mt-4">
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "25px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Topic</th>
                <th>Body</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Descp?.map((item, i) => {
                return (
                  <tr
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <td>{i + 1}</td>

                    <td>{item?.topic}</td>

                    <td>
                      {item?.body?.slice(0, 70)}
                      ...
                      <Link
                        onClick={() => {
                          handleShow7();
                          setView(item);
                        }}
                      >
                        Read More...
                      </Link>
                    </td>

                    <td>
                      <BiEdit
                        size={20}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          margin: "10px",
                        }}
                        onClick={() => {
                          setView(item);
                          handleShow2();
                        }}
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          color: "red",
                          margin: "10px",
                        }}
                        onClick={() => {
                          setView(item);
                          handleShow1();
                        }}
                      >
                        <AiFillDelete size={20} />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Show Description */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.body}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* =======================add description======================= */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Topic</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setTopic(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Body</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  onChange={(e) => setBody(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddDescription(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* =======================delete description======================= */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span
              className="header-03"
              onClick={(e) => deleteDescription(e, View?._id)}
            >
              <Button>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>

        {/* =======================edit descrtipion======================= */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Edit slider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Topic</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={View?.topic}
                  onChange={(e) => setTopic(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Body</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  placeholder={View?.body}
                  onChange={(e) => setBody(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(e) => editDescription(e, View?._id)}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Description;
