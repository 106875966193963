import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import exportFromJSON from "export-from-json";
const PgCriteria5 = () => {
  const navigate = useNavigate();

  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [selectedYear, setselectedYear] = useState("");
  const [remark, setremark] = useState("");
  const [Value, setValue] = useState("");

  // =====================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // ===========================================================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaPG05/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria05Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const pgCriteria05Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getPG_cri05details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria05);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      pgCriteria05Details();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-05
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          {" "}
          <div className="">
            <Nav defaultActiveKey="/pgcriteria05">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/PG_05_511")}
                >
                  <Button className="criteriabtn btn-sub1">
                    5.1.1 & 5.1.2
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/PG_05_513")}
                >
                  <Button className="criteriabtn btn-sub">5.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_05_514")}
                >
                  <Button className="criteriabtn btn-sub">5.1.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_05_521")}
                >
                  <Button className="criteriabtn btn-sub">5.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_05_522")}
                >
                  <Button className="criteriabtn btn-sub">5.2.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_05_523")}
                >
                  <Button className="criteriabtn btn-sub">5.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_05_531")}
                >
                  <Button className="criteriabtn btn-sub">5.3.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_05_533")}
                >
                  <Button className="criteriabtn btn-sub">5.3.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", minWidth: "10000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 5.1.1 & 5.1.2 */}
                  <th>Criteria</th>
                  <th>Name of the Scheme</th>
                  <th>
                    Number of students benefited by government scheme and amount
                  </th>
                  <th>
                    Number of students benefited by the institution's schemes
                    and amount
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 5.1.3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the capacity development and skills enhancement
                    program
                  </th>
                  <th>Date of implementation</th>
                  <th>Number of students enrolled</th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 5.1.4 */}
                  <th>Criteria</th>
                  <th>Number of students placed through campus placement</th>
                  <th>
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counseling offered by
                    the institution during the last five years
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 5.2.1 */}
                  <th>Criteria</th>
                  <th>Name of student placed and contact details</th>
                  <th>Program graduated from</th>
                  <th>Name of the employer with contact details</th>
                  <th>Pay package at appointment (In INR per annum)</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 5.2.2 */}
                  <th>Criteria</th>
                  <th>Name of student enrolling into higher education</th>
                  <th>Program graduated from</th>
                  <th>Name of institution joined</th>
                  <th>Name of program admitted to</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 5.2.3 */}
                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Registration number/roll number for the exam</th>
                  <th>Names of students selected/ qualified</th>
                  <th>NET</th>
                  <th>SLET</th>
                  <th>GATE</th>
                  <th>GMAT</th>
                  <th>CAT</th>
                  <th>GRE</th>
                  <th>JAM</th>
                  <th>IELTS</th>
                  <th>TOEFL</th>
                  <th>Civil Services</th>
                  <th>State government examinations</th>
                  <th>
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </th>
                  <th>Total</th>
                  <th>Grand Total</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 5.3.1 */}
                  <th>Criteria</th>
                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>University/State/National/ International</th>
                  <th>Sports/ Cultural</th>
                  <th>Name of the student</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 5.3.3 */}
                  <th>Criteria</th>
                  <th>Name of the student participated</th>
                  <th>Date of event/activity (DD-MM-YYYY)</th>
                  <th>Name of the event/activity</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    (item1?.approve === "false" ||
                      item1?.approve === "Pending") &&
                    (SearchItem === "" ||
                      item1?.PG_Criteria05_511?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_NameS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_511?.PG_SName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_Amount?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_SName1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_Amount1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_513?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_513?.PG_NamCap?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.PG_Criteria05_513?.PG_IDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria05_513?.PG_StdEnr?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_513?.PG_AgeName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_513?.PG_Desc22?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_514?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_514?.PG_CampName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_514?.PG_Nameact?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_514?.PG_NamePart?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_514?.PG_Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_521?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_521?.PG_PlaName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_521?.PG_Graduate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_521?.PG_ConDetails?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_521?.PG_Pay?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_521?.PG_Desc24?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_522?.PG_HighName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_PGrad?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_Institute?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_Admitted?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_Desc25?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_523?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_No1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_RollNo1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_StdSelected1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_523?.PG_NET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_SLET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_GATE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_GMAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_CAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_GRE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_JAM1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_IELTS1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_TOEFL1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_Civil1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_SGExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_OtherExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_GTotal1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_Desc26?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_531?.PG_Meda?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_TIndi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_USNI1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_SCult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_NaStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_Desc27?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_533?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_533?.PG_NStdParti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.PG_Criteria05_533?.PG_EDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria05_533?.PG_EName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_533?.PG_Desc28?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.PG_Year}</td>

                      {/* 5.1.1 */}
                      <td>{item?.PG_Criteria05_511?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_511?.PG_NameS}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Number of students
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Amount
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria05_511?.PG_SName}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria05_511?.PG_Amount}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Number of students
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Amount
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria05_511?.PG_SName1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria05_511?.PG_Amount1}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>

                      <td>
                        {item?.PG_Criteria05_511?.PG_Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_513?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_513?.PG_NamCap}</td>
                      <td>
                        {moment(item?.PG_Criteria05_513?.PG_IDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria05_513?.PG_StdEnr}</td>
                      <td>{item?.PG_Criteria05_513?.PG_AgeName}</td>

                      <td>
                        {item?.PG_Criteria05_513?.PG_Desc22?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_514?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_514?.PG_CampName}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Name of the Activity</th>
                            <th>Number of students attended / participated</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.PG_Criteria05_514?.PG_Nameact}</td>
                              <td>{item?.PG_Criteria05_514?.PG_NamePart}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>

                      <td>
                        {item?.PG_Criteria05_514?.PG_Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_521?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_521?.PG_PlaName}</td>
                      <td>{item?.PG_Criteria05_521?.PG_Graduate}</td>
                      <td>{item?.PG_Criteria05_521?.PG_ConDetails}</td>
                      <td>{item?.PG_Criteria05_521?.PG_Pay}</td>

                      <td>
                        {item?.PG_Criteria05_521?.PG_Desc24?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_522?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_522?.PG_HighName}</td>
                      <td>{item?.PG_Criteria05_522?.PG_PGrad}</td>
                      <td>{item?.PG_Criteria05_522?.PG_Institute}</td>
                      <td>{item?.PG_Criteria05_522?.PG_Admitted}</td>

                      <td>
                        {item?.PG_Criteria05_522?.PG_Desc25?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_523?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_523?.PG_No1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_RollNo1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_StdSelected1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_NET1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_SLET1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_GATE1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_GMAT1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_CAT1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_GRE1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_JAM1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_IELTS1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_TOEFL1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_Civil1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_SGExam1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_OtherExam1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_Total1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_GTotal1}</td>

                      <td>
                        {item?.PG_Criteria05_523?.PG_Desc26?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_531?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_531?.PG_Meda}</td>
                      <td>{item?.PG_Criteria05_531?.PG_TIndi}</td>
                      <td>{item?.PG_Criteria05_531?.PG_USNI1}</td>
                      <td>{item?.PG_Criteria05_531?.PG_SCult}</td>
                      <td>{item?.PG_Criteria05_531?.PG_NaStd}</td>

                      <td>
                        {item?.PG_Criteria05_531?.PG_Desc27?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_533?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_533?.PG_NStdParti}</td>
                      <td>
                        {moment(item?.PG_Criteria05_533?.PG_EDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria05_533?.PG_EName}</td>

                      <td>
                        {item?.PG_Criteria05_533?.PG_Desc28?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria05_511?.PG_Desc21}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 10 model */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.PG_Criteria05_513?.PG_Desc22}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 11 model */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria05_514?.PG_Desc23}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 12 model */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria05_521?.PG_Desc24}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose12}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 13 model */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria05_522?.PG_Desc25}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 14 model */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria05_523?.PG_Desc26}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose14}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 15 model */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.PG_Criteria05_531?.PG_Desc27}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 16 model */}
          <Modal show={show16} onHide={handleClose16}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.PG_Criteria05_533?.PG_Desc28}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose16}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_511?.PG_Link21}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_513?.PG_Link22}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_514?.PG_Link23}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_521?.PG_Link24}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_522?.PG_Link25}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_523?.PG_Link26}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_531?.PG_Link27}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_533?.PG_Link28}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* Add Model */}
          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#" }}>
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Post_Graduaation-05
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 m-0">
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.1.1
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="row">
                    <div className="col-md-12 mt-3 mb-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the scheme
                      </label>

                      <>{Value?.PG_Criteria05_511?.PG_NameS}</>
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline">
                    (Number of students benefited by government scheme and
                    amount){" "}
                  </h6>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students
                      </label>

                      <>{Value?.PG_Criteria05_511?.PG_SName}</>
                    </div>
                    <div className="col-md-6 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Amount
                      </label>

                      <>{Value?.PG_Criteria05_511?.PG_Amount}</>
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline">
                    (Number of students benefited by the institution's schemes
                    and amount){" "}
                  </h6>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students
                      </label>

                      <>{Value?.PG_Criteria05_511?.PG_SName1}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Amount
                      </label>

                      <>{Value?.PG_Criteria05_511?.PG_Amount1}</>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.PG_Criteria05_511?.PG_Desc21}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>

                    <>{Value?.PG_Criteria05_511?.PG_Link21}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.1.3
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the capacity development and skills enhancement
                        program
                      </label>

                      <>{Value?.PG_Criteria05_513?.PG_NamCap}</>
                    </div>
                    <div className="col-md-6 mt-3 mb-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Date of implementation
                      </label>{" "}
                      <>
                        {moment(Value?.PG_Criteria05_513?.PG_IDate).format(
                          "DD-MM-YYYY"
                        )}
                      </>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students enrolled
                      </label>{" "}
                      <>{Value?.PG_Criteria05_513?.PG_StdEnr}</>
                    </div>
                    <div className="col-md-12 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the agencies/consultants involved with contact
                        details (if any)
                      </label>{" "}
                      <>{Value?.PG_Criteria05_513?.PG_AgeName}</>
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description
                      </label>

                      <>{Value?.PG_Criteria05_513?.PG_Desc22}</>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Document
                      </label>

                      <>{Value?.PG_Criteria05_513?.PG_Link22}</>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.1.4
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="row">
                    <div className="col-md-12 mt-3 mb-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Number of students placed through campus placement
                      </label>

                      <>{Value?.PG_Criteria05_514?.PG_CampName}</>
                    </div>
                    <h6 className="text-center text-decoration-underline">
                      (Name of the Activity conducted by the HEI to offer
                      guidance for competitive examinations/ career counseling
                      offered by the institution during the last five years)
                    </h6>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the Activity
                      </label>

                      <>{Value?.PG_Criteria05_514?.PG_Nameact}</>
                    </div>
                    <div className="col-md-8 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students attended / participated
                      </label>

                      <>{Value?.PG_Criteria05_514?.PG_NamePart}</>
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description
                      </label>

                      <>{Value?.PG_Criteria05_514?.PG_Desc23}</>
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Document
                      </label>

                      <>{Value?.PG_Criteria05_514?.PG_Link23}</>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.2.1
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of student placed and contact details
                    </label>{" "}
                    <>{Value?.PG_Criteria05_521?.PG_PlaName}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the employer with contact details
                    </label>{" "}
                    <>{Value?.PG_Criteria05_521?.PG_Graduate}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program graduated from
                    </label>{" "}
                    <>{Value?.PG_Criteria05_521?.PG_ConDetails}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Pay package at appointment (In INR per annum)
                    </label>{" "}
                    <>{Value?.PG_Criteria05_521?.PG_Pay}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.PG_Criteria05_521?.PG_Desc24}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Document
                    </label>

                    <>{Value?.PG_Criteria05_521?.PG_Link24}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.2.2
                  </span>
                </div>

                <div className="row m-0">
                  <div className="col-md-8 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of student enrolling into higher education
                    </label>

                    <>{Value?.PG_Criteria05_522?.PG_HighName}</>
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program graduated from
                    </label>{" "}
                    <>{Value?.PG_Criteria05_522?.PG_PGrad}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of institution joined
                    </label>{" "}
                    <>{Value?.PG_Criteria05_522?.PG_Institute}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of programme admitted to
                    </label>{" "}
                    <>{Value?.PG_Criteria05_522?.PG_Admitted}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.PG_Criteria05_522?.PG_Desc25}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.PG_Criteria05_522?.PG_Link25}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.2.3
                  </span>
                </div>
                <div className="row m-0">
                  <div className="col-md-4 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Sl.no.
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_No1}</>
                  </div>
                  <div className="col-md-8 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Registration number/roll number for the exam
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_RollNo1}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Names of students selected/ qualified
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_StdSelected1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      NET
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_NET1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      SLET
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_SLET1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GATE
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_GATE1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GMAT
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_GMAT1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      CAT
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_CAT1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GRE
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_GRE1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      JAM
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_JAM1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      IELTS
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_IELTS1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      TOEFL
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_TOEFL1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Civil Services
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_Civil1}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      State government examinations
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_SGExam1}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Other examinations conducted by the State / Central
                      Government Agencies (Specify)
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_OtherExam1}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_Total1}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Grand Total
                    </label>{" "}
                    <>{Value?.PG_Criteria05_523?.PG_GTotal1}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.PG_Criteria05_523?.PG_Desc26}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.PG_Criteria05_523?.PG_Link26}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.3.1
                  </span>
                </div>
                <div className="row m-0">
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the award/ medal
                    </label>{" "}
                    <>{Value?.PG_Criteria05_531?.PG_Meda}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Team / Individual
                    </label>{" "}
                    <>{Value?.PG_Criteria05_531?.PG_TIndi}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      University/State/National/ International
                    </label>{" "}
                    <>{Value?.PG_Criteria05_531?.PG_USNI1}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Sports/ Cultural
                    </label>{" "}
                    <>{Value?.PG_Criteria05_531?.PG_SCult}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the student
                    </label>{" "}
                    <>{Value?.PG_Criteria05_531?.PG_NaStd}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.PG_Criteria05_531?.PG_Desc27}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.PG_Criteria05_531?.PG_Link27}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.3.3
                  </span>
                </div>
                <div className="row m-0">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the student participated
                    </label>{" "}
                    <>{Value?.PG_Criteria05_533?.PG_NStdParti}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date of event/competition
                    </label>{" "}
                    <>
                      {moment(Value?.PG_Criteria05_533?.PG_EDate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the event/competition
                    </label>{" "}
                    <>{Value?.PG_Criteria05_533?.PG_EName}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.PG_Criteria05_533?.PG_Desc28}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload8">
                      {" "}
                      Document
                    </label>

                    <>{Value?.PG_Criteria05_533?.PG_Link28}</>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {Value?.approve === "false" ? (
                <></>
              ) : (
                <div>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      approveCriteria(e, true, Value?._id);
                      handleClose();
                    }}
                  >
                    Approve{" "}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => setSmShow(true)}
                    className="me-2"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal>
          {
            // remarks model
          }
          <Modal
            // size="sm"
            show={smShow}
            onHide={() => handleClosesm()}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Tell me the reason?..
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                onChange={(e) => setremark(e.target.value)}
                style={{ height: "200px" }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  approveCriteria(e, true, Value?._id);
                  handleClose();
                  handleClosesm();
                }}
              >
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PgCriteria5;
