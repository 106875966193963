import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import moment from "moment/moment";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";
import { AiOutlineEye } from "react-icons/ai";

const UnderGraduateCri3 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));
  const [View, setView] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // show description model
  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);
  // ================Show doc17 modal======================
  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const ugCriteria03Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria03"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria03);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria03);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  // =================================================================

  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatingug3`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria03Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      ugCriteria03Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", params?.id);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-6 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-03
              </span>
            </div>

            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="">Current Rating: </h6>
                <div className="">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            {/* <div className="col-lg-2 text-end">
              <Button
                type="Submit"
                className="btn btn-warning"
                style={{ color: "white" }}
                onClick={() => {
                  // setValue(item);
                  handleShow();
                }}
              >
                Add Rating
              </Button>
            </div> */}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 w-100"></div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform "
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              {allYears
                ?.sort((a, b) => {
                  return Number(b?.from) - Number(a?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/ugcriteria03">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  onClick={() => navigate(`/adminclgugcri3312/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.1.1 & 3.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-2"
                  onClick={() => navigate(`/adminclgugcri3313/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri3321/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-4"
                  onClick={() => navigate(`/adminclgugcri3322/${params?.id}`)}
                >
                  <Button className="criteriabtn">3.2.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-5"
                  onClick={() => navigate(`/adminclgugcri3332/${params?.id}`)}
                >
                  <Button style={{}} className="criteriabtn">
                    3.3.2
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-5"
                  onClick={() => navigate(`/adminclgugcri3333/${params?.id}`)}
                >
                  <Button style={{}} className="criteriabtn">
                    3.3.3 & 3.3.4
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-5"
                  onClick={() => navigate(`/adminclgugcri3341/${params?.id}`)}
                >
                  <Button style={{}} className="criteriabtn">
                    3.4.1
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-5"
                  onClick={() => navigate(`/adminclgugcri3342/${params?.id}`)}
                >
                  <Button style={{}} className="criteriabtn">
                    3.4.2
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          {/* <div className="col-lg-2">
            <Button
              type="Submit"
              className="btn btn-warning"
              style={{ color: "white" }}
              onClick={() => {
                // setValue(item);
                handleShow();
              }}
            >
              Add Rating
            </Button>
          </div> */}
        </div>

        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/*  */}
                  <th>Criteria</th>
                  <th>Name of the Principal Investigator/Co-Investigator</th>
                  <th>Name of the Project/ Endowments, Chairs</th>
                  <th>Department of Principal Investigator</th>
                  <th>Year of Award</th>
                  <th>Amount Sanctioned</th>
                  <th>Duration of the project</th>
                  <th>Name of the Funding Agency</th>
                  <th>Type (Government/non-Government)</th>
                  <th>Description </th>
                  <th>Relevant document </th>

                  {/*  */}
                  <th>Criteria</th>
                  <th>Number of Participants</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Name of the workshop/ seminar/ conference</th>
                  <th>Description </th>
                  <th>Relevant document </th>

                  {/*  */}
                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other
                  </th>
                  <th>Description </th>
                  <th>Relevant document </th>
                  <th>Relevant document </th>

                  {/* 3.2.2 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>
                    Whether at the time of publication Affiliating
                    InstitutionWas same
                  </th>
                  <th>Description </th>
                  <th>Relevant document </th>

                  {/* 3.3.2 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Year of award</th>
                  <th>Name of the Award/ recognition for Institution</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Description </th>
                  <th>Relevant document </th>

                  {/* 3.3.3 & 3.3.4 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of the activity</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description </th>
                  <th>Relevant document </th>

                  {/* 3.4.1 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Title of the collaborative activity</th>
                  <th>
                    Name of the collaborating / linking agency with contact
                    details
                  </th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Description </th>
                  <th>Relevant document </th>

                  {/* 3.4.2 */}
                  <th>Criteria</th>
                  <th>
                    Name of the institution/ industry/ corporate house with whom
                    MoU is signed
                  </th>
                  <th>Year of signing MOU</th>
                  <th>Duration</th>
                  <th>
                    List the actual activities under each MOU and web links
                    year-wise
                  </th>
                  <th>Description </th>
                  <th>Relevant document </th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Year &&
                    (SearchItem === "" ||
                      item1?.Criteria03_312?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_312?.ProName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.PrinciName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.PrinciDept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.YOfAw?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_312?.ASanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_312?.DProject?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_312?.NFunding?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_313?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_313?.NPati?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Criteria03_313?.FDate)
                        .format("DD/MM/YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Criteria03_313?.TDate)
                        .format("DD/MM/YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_313?.NWorkshop?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_313?.Desc13?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_321?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_321?.TPapaer?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_321?.AName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_321?.PubliYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_321?.IISSN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_321?.UUGC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_321?.Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_322?.SNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_322?.NTea?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.Title1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.Title2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.Title3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.YPub?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_322?.IIISBN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_322?.NPub?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.TimeWhether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_332?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_332?.ActiN?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_332?.YearA?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_332?.INAme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_332?.Bodies?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_332?.Desc16?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_333?.NaActi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_333?.Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_333?.SchName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_333?.ActiYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_333?.NStudents?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_333?.Desc17?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_341?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_341?.SlNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_341?.Collo?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_341?.NCollo?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_341?.NameParti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_341?.ColloYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_341?.Dur?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_341?.Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_342?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_342?.NMou?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_342?.Mou?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_342?.Durat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_342?.List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_342?.Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Year}</td>

                      <td>{item?.Criteria03_312?.criteria}</td>
                      <td>{item?.Criteria03_312?.ProName}</td>
                      <td>{item?.Criteria03_312?.PrinciName}</td>
                      <td>{item?.Criteria03_312?.PrinciDept}</td>
                      <td>{item?.Criteria03_312?.YOfAw}</td>
                      <td>{item?.Criteria03_312?.ASanctioned}</td>
                      <td>{item?.Criteria03_312?.DProject}</td>
                      <td>{item?.Criteria03_312?.NFunding}</td>
                      <td>{item?.Criteria03_312?.Type}</td>
                      <td>
                        {item?.Criteria03_312?.Desc12?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria03_312?.Link12} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria03_313?.criteria}</td>
                      <td>{item?.Criteria03_313?.NPati}</td>
                      <td>
                        {moment(item?.Criteria03_313?.FDate).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Criteria03_313?.TDate).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>{item?.Criteria03_313?.NWorkshop}</td>
                      <td>
                        {item?.Criteria03_313?.Desc13?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria03_313?.Link13} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria03_321?.criteria}</td>
                      <td>{item?.Criteria03_321?.TPapaer}</td>
                      <td>{item?.Criteria03_321?.AName}</td>
                      <td>{item?.Criteria03_321?.PubliYear}</td>
                      <td>{item?.Criteria03_321?.IISSN}</td>
                      <td>{item?.Criteria03_321?.UUGC}</td>
                      <td>
                        {item?.Criteria03_321?.Desc14?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria03_321?.Link14} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        {/* {item?.Criteria03_321?.Link15} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria03_322?.criteria}</td>
                      <td>{item?.Criteria03_322?.SNo}</td>
                      <td>{item?.Criteria03_322?.NTea}</td>
                      <td>{item?.Criteria03_322?.Title1}</td>
                      <td>{item?.Criteria03_322?.Title2}</td>
                      <td>{item?.Criteria03_322?.Title3}</td>
                      <td>{item?.Criteria03_322?.YPub}</td>
                      <td>{item?.Criteria03_322?.IIISBN}</td>
                      <td>{item?.Criteria03_322?.NPub}</td>
                      <td>{item?.Criteria03_322?.TimeWhether}</td>
                      <td>
                        {item?.Criteria03_322?.Desc15?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria03_322?.Link16} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria03_332?.criteria}</td>
                      <td>{item?.Criteria03_332?.ActiN}</td>
                      <td>{item?.Criteria03_332?.YearA}</td>
                      <td>{item?.Criteria03_332?.INAme}</td>
                      <td>{item?.Criteria03_332?.Bodies}</td>
                      <td>
                        {item?.Criteria03_332?.Desc16?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria03_332?.Link17} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria03_333?.NaActi}</td>
                      <td>{item?.Criteria03_333?.Organ}</td>
                      <td>{item?.Criteria03_333?.SchName}</td>
                      <td>{item?.Criteria03_333?.ActiYear}</td>
                      <td>{item?.Criteria03_333?.NStudents}</td>
                      <td>
                        {item?.Criteria03_333?.Desc17?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria03_333?.Link18} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria03_341?.criteria}</td>
                      <td>{item?.Criteria03_341?.SlNo}</td>
                      <td>{item?.Criteria03_341?.Collo}</td>
                      <td>{item?.Criteria03_341?.NCollo}</td>
                      <td>{item?.Criteria03_341?.NameParti}</td>
                      <td>{item?.Criteria03_341?.ColloYear}</td>
                      <td>{item?.Criteria03_341?.Dur}</td>
                      <td>
                        {item?.Criteria03_341?.Desc18?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria03_341?.Link19} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria03_342?.criteria}</td>
                      <td>{item?.Criteria03_342?.NMou}</td>
                      <td>{item?.Criteria03_342?.Mou}</td>
                      <td>{item?.Criteria03_342?.Durat}</td>
                      <td>{item?.Criteria03_342?.List}</td>
                      <td>
                        {item?.Criteria03_342?.Desc19?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow17();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* {item?.Criteria03_342?.Link20} */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <Modal size="sm" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Rating</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={50}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
            ,
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(e) => AddRating(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_312?.Link12}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_313?.Link13}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_321?.Link14}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_321?.Link15}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_322?.Link16}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 6 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_332?.Link17}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 7 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_333?.Link18}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 8 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_341?.Link19}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 9 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_342?.Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Show description modal */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Body>
            <div>{View?.Criteria03_312?.Desc12}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Body>
            <div>{View?.Criteria03_313?.Desc13}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Body>
            <div>{View?.riteria03_321?.Desc14}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose12}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Body>
            <div>{View?.Criteria03_322?.Desc15}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose13}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Body>
            <div>{View?.Criteria03_332?.Desc16}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose14}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Body>
            <div>{View?.Criteria03_333?.Desc17}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose15}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Body>
            <div>{View?.Criteria03_341?.Desc18}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose16}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show17} onHide={handleClose17}>
          <Modal.Body>
            <div>{View?.Criteria03_342?.Desc19}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose17}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UnderGraduateCri3;
