import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import moment from "moment/moment";
import { AiOutlineEye } from "react-icons/ai";
import exportFromJSON from "export-from-json";
const UgCriteria5 = () => {
  const navigate = useNavigate();

  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [remark, setremark] = useState("");
  const [Value, setValue] = useState({});

  // ================Show View modal======================
  const [smShow, setSmShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  // ========================================

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("UG_Criteria 5.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
        <table>
          <tr>
            <td>Number of students</td>
            <td>${item?.Criteria05_511?.SName}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>${item?.Criteria05_511?.Amount}</td>
          </tr>
        </table>
      `;
        let tableMarkup2 = `
        <table>
          <tr>
            <td>Number of students</td>
            <td>${item?.Criteria05_511?.SName1}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>${item?.Criteria05_511?.Amount1}</td>
          </tr>
        </table>
      `;

        let tableMarkup3 = `
        <table>
          <tr>
            <td>Name of the Activity</td>
            <td>${item?.Criteria05_514?.Nameact}</td>
          </tr>
          <tr>
            <td>Number of students attended / participated</td>
            <td>${item?.Criteria05_514?.NamePart}</td>
          </tr>
        </table>
      `;
        let obj = {
          //  Criteria 5_511

          "Criteria_5.1.1": item?.Criteria05_511?.criteria,
          "Name of the scheme": item?.Criteria05_511?.NameS,
          "Number of students benefited by government scheme and amount":
            tableMarkup1,
          "Number of students benefited by the institution's schemes and amount":
            tableMarkup2,
          Description: item?.Criteria05_511?.Desc21,
          "Relevant document": item?.Criteria05_511?.Link21,

          // Criteria 5_513

          "Criteria_5.1.3": item?.Criteria05_513?.criteria,
          "Name of the capacity development and skills enhancement program":
            item?.Criteria05_513?.NamCap,
          "Date of implementation": moment(item?.Criteria05_513?.IDate).format(
            "DD-MM-YYYY"
          ),
          "Number of students enrolled": item?.Criteria05_513?.StdEnr,
          "Name of the agencies/consultants involved with contact details (if any)":
            item?.Criteria05_513?.AgeName,
          Description: item?.Criteria05_513?.Desc22,
          "Relevant document": item?.Criteria05_513?.Link22,

          // Criteria 5_514
          "Criteria_5.1.4": item?.Criteria05_514?.criteria,
          "Number of students placed through campus placement":
            item?.Criteria05_514?.CampName,
          "Name of the Activity conducted by the HEI to offer guidance for competitive examinations/ career counseling offered by the institution during the last five years":
            tableMarkup3,
          Description: item?.Criteria05_514?.Desc23,
          "Relevant document": item?.Criteria05_514?.Link23,

          // Criteria 5_521

          "Criteria_5.2.1": item?.Criteria05_521?.criteria,
          "Name of student placed and contact details":
            item?.Criteria05_521?.PlaName,
          "Program graduated from": item?.Criteria05_521?.Graduate,
          "Name of the employer with contact details":
            item?.Criteria05_521?.ConDetails,
          "Pay package at appointment (In INR per annum)":
            item?.Criteria05_521?.Pay,
          Description: item?.Criteria05_521?.Desc24,
          "Relevant document": item?.Criteria05_521?.Link24,

          // Criteria 5_522

          "Criteria_5.2.2": item?.Criteria05_522?.criteria,
          "Name of student enrolling into higher education":
            item?.Criteria05_522?.HighName,
          "Program graduated from": item?.Criteria05_522?.PGrad,
          "Name of institution joined": item?.Criteria05_522?.Institute,
          "Name of programme admitted to": item?.Criteria05_522?.Admitted,
          Description: item?.Criteria05_522?.Desc25,
          "Relevant document": item?.Criteria05_522?.Link25,
          // Criteria 5_523
          "Criteria_5.2.3": item?.Criteria05_523?.criteria,
          "Sl.no.": item?.Criteria05_523?.No1,
          "Registration number/roll number for the exam":
            item?.Criteria05_523?.RollNo1,
          "Names of students selected/ qualified":
            item?.Criteria05_523?.StdSelected1,
          NET: item?.Criteria05_523?.NET1,
          SLET: item?.Criteria05_523?.SLET1,
          GATE: item?.Criteria05_523?.GATE1,
          GMAT: item?.Criteria05_523?.GMAT1,
          CAT: item?.Criteria05_523?.CAT1,
          GRE: item?.Criteria05_523?.GRE1,
          JAM: item?.Criteria05_523?.JAM1,
          IELTS: item?.Criteria05_523?.IELTS1,
          TOEFL: item?.Criteria05_523?.TOEFL1,
          "Civil Services": item?.Criteria05_523?.Civil1,
          "State government examinations": item?.Criteria05_523?.SGExam1,
          "Other examinations conducted by the State / Central Government Agencies (Specify)":
            item?.Criteria05_523?.OtherExam1,
          Total: item?.Criteria05_523?.Total1,
          "Grand Total": item?.Criteria05_523?.GTotal1,
          Description: item?.Criteria05_523?.Desc26,
          "Relevant document": item?.Criteria05_523?.Link26,

          // Criteria 5_531

          "Criteria_5.3.1": item?.Criteria05_531?.criteria,
          "Name of the award/ medal": item?.Criteria05_531?.Meda,
          "Team / Individual": item?.Criteria05_531?.TIndi,
          "University/State/National/ International1":
            item?.Criteria05_531?.USNI1,
          "Sports/ Cultural": item?.Criteria05_531?.SCult,
          "Name of the student": item?.Criteria05_531?.NaStd,
          Description: item?.Criteria05_531?.Desc27,
          "Relevant document": item?.Criteria05_531?.Link27,

          // Criteria 5_533

          "Criteria_5.3.3": item?.Criteria05_533?.criteria,
          "Name of the student participated": item?.Criteria05_533?.NStdParti,
          "Date of event/competition": moment(
            item?.Criteria05_533?.EDate
          ).format("DD-MM-YYYY"),
          "Name of the event/competition": item?.Criteria05_533?.EName,
          Description: item?.Criteria05_533?.Desc28,
          "Relevant document": item?.Criteria05_533?.Link28,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const ugCriteria05Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getcri05details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria05);
        setdata1(res.data.ugcriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria05);
    }
  };

  // =======================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaUG5/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria05Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =============================================================

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      ugCriteria05Details();
    }
  }, []);

  //===============================================================

  console.log("fjfjfjfjf", AllDetails);
  console.log("principal", pricipalDetails?._id);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-05
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div className="col-lg-12 ">
            <Nav defaultActiveKey="/ugcriteria05">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/UG_05_511")}
                >
                  <Button className="criteriabtn btn-sub1">
                    5.1.1 & 5.1.2
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UG_05_513")}
                >
                  <Button className="criteriabtn btn-sub">5.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UG_05_514")}
                >
                  <Button className="criteriabtn btn-sub">5.1.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UG_05_521")}
                >
                  <Button className="criteriabtn btn-sub">5.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UG_05_522")}
                >
                  <Button className="criteriabtn btn-sub">5.2.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UG_05_523")}
                >
                  <Button className="criteriabtn btn-sub">5.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UG_05_531")}
                >
                  <Button className="criteriabtn btn-sub">5.3.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UG_05_533")}
                >
                  <Button className="criteriabtn btn-sub">5.3.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>ID</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Name of the scheme</th>
                  <th>
                    Number of students benefited by government scheme and amount
                  </th>
                  <th>
                    Number of students benefited by the institution's schemes
                    and amount
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the capacity development and skills enhancement
                    program
                  </th>
                  <th>Date of implementation</th>
                  <th>Number of students enrolled </th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Number of students placed through campus placement</th>
                  <th>
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counseling offered by
                    the institution during the last five years
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student placed and contact details</th>
                  <th>Program graduated from</th>
                  <th>Name of the employer with contact details</th>
                  <th>Pay package at appointment (In INR per annum)</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student enrolling into higher education</th>
                  <th>Program graduated from</th>
                  <th>Name of institution joined</th>
                  <th>Name of programme admitted to</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Registration number/roll number for the exam</th>
                  <th>Names of students selected/ qualified</th>
                  <th>NET</th>
                  <th>SLET</th>
                  <th>GATE</th>
                  <th>GMAT</th>
                  <th>CAT</th>
                  <th>GRE</th>
                  <th>JAM</th>
                  <th>IELTS</th>
                  <th>TOEFL</th>
                  <th>Civil Services</th>
                  <th>State government examinations</th>
                  <th>
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </th>
                  <th>Total</th>
                  <th>Grand Total</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>University/State/National/ International</th>
                  <th>Sports/ Cultural</th>
                  <th>Name of the student</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the student participated</th>
                  <th>Date of event/activity (DD-MM-YYYY)</th>
                  <th>Name of the event/activity</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    pricipalDetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "Pending" &&
                    (SearchItem === "" ||
                      item1?.Criteria05_511?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_511?.NameS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_511?.SName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.Amount?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.SName1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.Amount1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_513?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_513?.NamCap?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Criteria05_513?.IDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_513?.StdEnr?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_513?.AgeName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_513?.Desc22?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_514?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_514?.CampName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_514?.Nameact?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_514?.NamePart?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_514?.Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_521?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_521?.PlaName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_521?.Graduate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_521?.ConDetails?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_521?.Pay?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_521?.Desc24?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_522?.HighName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.PGrad?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.Institute?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.Admitted?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.Desc25?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_523?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_523?.No1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.RollNo1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.StdSelected1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_523?.NET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.SLET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GATE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GMAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.CAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GRE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.JAM1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.IELTS1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.TOEFL1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.Civil1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.SGExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.OtherExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GTotal1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.Desc26?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_531?.Meda?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.TIndi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.USNI1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.SCult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.NaStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.Desc27?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_533?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_533?.NStdParti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Criteria05_533?.EDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_533?.EName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_533?.Desc28?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                }).map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{item?._id}</td>
                      <td>{item?.Year}</td>
                      <td>{item?.Criteria05_511?.criteria}</td>
                      <td>{item?.Criteria05_511?.NameS}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Number of students
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Amount
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Criteria05_511?.SName}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Criteria05_511?.Amount}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Number of students
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Amount
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Criteria05_511?.SName1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Criteria05_511?.Amount1}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>

                      <td>
                        {item?.Criteria05_511?.Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_513?.criteria}</td>
                      <td>{item?.Criteria05_513?.NamCap}</td>
                      <td>
                        {moment(item?.Criteria05_513?.IDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Criteria05_513?.StdEnr}</td>
                      <td>{item?.Criteria05_513?.AgeName}</td>

                      <td>
                        {item?.Criteria05_513?.Desc22?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_514?.criteria}</td>
                      <td>{item?.Criteria05_514?.CampName}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Name of the Activity</th>
                            <th>Number of students attended / participated</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Criteria05_514?.Nameact}</td>
                              <td>{item?.Criteria05_514?.NamePart}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>

                      <td>
                        {item?.Criteria05_514?.Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_521?.criteria}</td>
                      <td>{item?.Criteria05_521?.PlaName}</td>
                      <td>{item?.Criteria05_521?.Graduate}</td>
                      <td>{item?.Criteria05_521?.ConDetails}</td>
                      <td>{item?.Criteria05_521?.Pay}</td>

                      <td>
                        {item?.Criteria05_521?.Desc24?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_522?.criteria}</td>
                      <td>{item?.Criteria05_522?.HighName}</td>
                      <td>{item?.Criteria05_522?.PGrad}</td>
                      <td>{item?.Criteria05_522?.Institute}</td>
                      <td>{item?.Criteria05_522?.Admitted}</td>

                      <td>
                        {item?.Criteria05_522?.Desc25?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_523?.criteria}</td>
                      <td>{item?.Criteria05_523?.No1}</td>
                      <td>{item?.Criteria05_523?.RollNo1}</td>
                      <td>{item?.Criteria05_523?.StdSelected1}</td>
                      <td>{item?.Criteria05_523?.NET1}</td>
                      <td>{item?.Criteria05_523?.SLET1}</td>
                      <td>{item?.Criteria05_523?.GATE1}</td>
                      <td>{item?.Criteria05_523?.GMAT1}</td>
                      <td>{item?.Criteria05_523?.CAT1}</td>
                      <td>{item?.Criteria05_523?.GRE1}</td>
                      <td>{item?.Criteria05_523?.JAM1}</td>
                      <td>{item?.Criteria05_523?.IELTS1}</td>
                      <td>{item?.Criteria05_523?.TOEFL1}</td>
                      <td>{item?.Criteria05_523?.Civil1}</td>
                      <td>{item?.Criteria05_523?.SGExam1}</td>
                      <td>{item?.Criteria05_523?.OtherExam1}</td>
                      <td>{item?.Criteria05_523?.Total1}</td>
                      <td>{item?.Criteria05_523?.GTotal1}</td>

                      <td>
                        {item?.Criteria05_523?.Desc26?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_531?.criteria}</td>
                      <td>{item?.Criteria05_531?.Meda}</td>
                      <td>{item?.Criteria05_531?.TIndi}</td>
                      <td>{item?.Criteria05_531?.USNI1}</td>
                      <td>{item?.Criteria05_531?.SCult}</td>
                      <td>{item?.Criteria05_531?.NaStd}</td>

                      <td>
                        {item?.Criteria05_531?.Desc27?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_533?.criteria}</td>
                      <td>{item?.Criteria05_533?.NStdParti}</td>
                      <td>
                        {moment(item?.Criteria05_533?.EDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Criteria05_533?.EName}</td>

                      <td>
                        {item?.Criteria05_533?.Desc28?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria05_511?.Desc21}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria05_513?.Desc22}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria05_514?.Desc23}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria05_521?.Desc24}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose12}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria05_522?.Desc25}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria05_523?.Desc26}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose14}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria05_531?.Desc27}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show16} onHide={handleClose16}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria05_533?.Desc28}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose16}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_511?.Link21}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_513?.Link22}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_514?.Link23}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_521?.Link24}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_522?.Link25}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_523?.Link26}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_531?.Link27}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_533?.Link28}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton style={{ background: "#393186" }}>
              <Modal.Title>
                <span
                  className=""
                  style={{
                    fontSize: "19px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  UnderGraduate_Criteria-05
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-0 p-0">
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.1.1
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the scheme
                    </label>
                    <br />

                    <>{Value?.Criteria05_511?.NameS}</>
                  </div>

                  <h6 className="text-center text-decoration-underline">
                    (Number of students benefited by government scheme and
                    amount){" "}
                  </h6>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students
                      </label>
                      <br />

                      <>{Value?.Criteria05_511?.SName}</>
                    </div>
                    <div className="col-md-6 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Amount
                      </label>
                      <br />

                      <>{Value?.Criteria05_511?.Amount}</>
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline">
                    (Number of students benefited by the institution's schemes
                    and amount){" "}
                  </h6>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students
                      </label>
                      <br />

                      <>{Value?.Criteria05_511?.SName1}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Amount
                      </label>
                      <br />

                      <>{Value?.Criteria05_511?.Amount1}</>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />

                    <p>{Value?.Criteria05_511?.Desc21}</p>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>
                    <br />
                    <>{Value?.Criteria05_511?.Link21}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.1.3
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the capacity development and skills enhancement
                      program
                    </label>
                    <br />

                    <>{Value?.Criteria05_513?.NamCap}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Date of implementation
                    </label>{" "}
                    <br />
                    <>
                      {moment(Value?.Criteria05_513?.IDate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students enrolled
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_513?.StdEnr}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the agencies/consultants involved with contact
                      details (if any)
                    </label>
                    <br />
                    <>{Value?.Criteria05_513?.AgeName}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />

                    <p>{Value?.Criteria05_513?.Desc22}</p>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <br />

                    <>{Value?.Criteria05_513?.Link22}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.1.4
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of students placed through campus placement
                    </label>
                    <br />

                    <>{Value?.Criteria05_514?.CampName}</>
                  </div>
                  <h6 className="text-center text-decoration-underline">
                    (Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counseling offered by
                    the institution during the last five years)
                  </h6>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the Activity
                    </label>
                    <br />

                    <>{Value?.Criteria05_514?.Nameact}</>
                  </div>
                  <div className="col-md-8 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students attended / participated
                    </label>
                    <br />

                    <>{Value?.Criteria05_514?.NamePart}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />

                    <p>{Value?.Criteria05_514?.Desc23}</p>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <br />

                    <>{Value?.Criteria05_514?.Link23}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.2.1
                  </span>
                </div>

                <div className="row  m-0 ">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of student placed and contact details
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_521?.PlaName}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the employer with contact details
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_521?.Graduate}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program graduated from
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_521?.ConDetails}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Pay package at appointment (In INR per annum)
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_521?.Pay}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />

                    <p>{Value?.Criteria05_521?.Desc24}</p>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Document
                    </label>
                    <br />

                    <>{Value?.Criteria05_521?.Link24}</>
                  </div>
                </div>
              </div>

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.2.2
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of student enrolling into higher education
                    </label>
                    <br />

                    <>{Value?.Criteria05_522?.HighName}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program graduated from
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_522?.PGrad}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of institution joined
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_522?.Institute}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of programme admitted to
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_522?.Admitted}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />

                    <p>{Value?.Criteria05_522?.Desc25}</p>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <br />
                    <>{Value?.Criteria05_522?.Link25}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.2.3
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-4 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Sl.no.
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.No1}</>
                  </div>
                  <div className="col-md-8 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Registration number/roll number for the exam
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.RollNo1}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Names of students selected/ qualified
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.StdSelected1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      NET
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.NET1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      SLET
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.SLET1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GATE
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.GATE1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GMAT
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.GMAT1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      CAT
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.CAT1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GRE
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.GRE1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      JAM
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.JAM1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      IELTS
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.IELTS1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      TOEFL
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.TOEFL1}</>
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Civil Services
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.Civil1}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      State government examinations
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.SGExam1}</>
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Other examinations conducted by the State / Central
                      Government Agencies (Specify)
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.OtherExam1}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.Total1}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Grand Total
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_523?.GTotal1}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />

                    <p>{Value?.Criteria05_523?.Desc26}</p>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <br />

                    <>{Value?.Criteria05_523?.Link26}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.3.1
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the award/ medal
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_531?.Meda}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Team / Individual
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_531?.TIndi}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      University/State/National/ International
                    </label>{" "}
                    <>{Value?.Criteria05_531?.USNI1}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Sports/ Cultural
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_531?.SCult}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the student
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_531?.NaStd}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />

                    <p>{Value?.Criteria05_531?.Desc27}</p>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <br />

                    <>{Value?.Criteria05_531?.Link27}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-5.3.3
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the student participated
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_533?.NStdParti}</>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date of event/competition (DD-MM-YYYY)
                    </label>{" "}
                    <br />
                    <>
                      {moment(Value?.Criteria05_533?.EDate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the event/competition
                    </label>{" "}
                    <br />
                    <>{Value?.Criteria05_533?.EName}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />

                    <p>{Value?.Criteria05_533?.Desc28}</p>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>
                    <br />

                    <>{Value?.Criteria05_533?.Link28}</>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {Value?.approve === "false" ? (
                <></>
              ) : (
                <div>
                  <Button
                    className="btn-success"
                    onClick={(e) => approveCriteria(e, true, Value?._id)}
                  >
                    Approve{" "}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => setSmShow(true)}
                    className="me-2"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal>

          {
            // remarks model
          }

          <Modal
            // size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Tell me the reason?..
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                onChange={(e) => setremark(e.target.value)}
                style={{ height: "200px" }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => approveCriteria(e, false, Value?._id)}
              >
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default UgCriteria5;
