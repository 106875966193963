import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { ImLocation } from "react-icons/im";
import "./Css/dashboard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { BsStarFill } from "react-icons/bs";
function Adminclg() {
  const navigate = useNavigate();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  const [collegeApp, setCollegeApp] = useState([]);
  const [SearchItem, setSearchItem] = useState("");

  const getallAppColleges = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/app/approvedlist"
      );
      if (res.status === 200) {
        setCollegeApp(res.data.Register);
      }
    } catch (error) {
      console.log(error);
      setCollegeApp(error.response.data.Register);
    }
  };

  // ==========================================
  useEffect(() => {
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getallAppColleges();
    }
  }, [approvedetails]);
  //  ===========================================

  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center justify-content-start p-3">
          <div className="col-lg-5 d-flex">
            <input
              type="text"
              placeholder="Search"
              className="vi_0"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
        </div>

        <div className="row align-items-center justify-content-evenly mt-5 ">
          {collegeApp
            ?.filter(
              (item1) =>
                SearchItem === "" ||
                item1?.HeiName?.toLowerCase()?.includes(
                  SearchItem?.toLowerCase()
                )
            )

            ?.filter((val) => val?.isApprove && !val?.isBlocked)
            ?.map((item) => {
              return (
                <Card
                  onClick={() => {
                    sessionStorage.setItem(
                      "collegeDetails",
                      JSON.stringify(item)
                    );
                    navigate("/clgdashboard", { state: item });
                  }}
                  style={{
                    maxWidth: "423px",
                    position: "relative",
                    background: "#fff",
                  }}
                >
                  <img
                    src={`https://brightnaac.co.in/register/${item?.CollegeImg}`}
                    style={{
                      width: "100%",
                      height: "150px",
                      borderRadius: "10px",
                    }}
                    alt="Not-available"
                  />

                  <Card.Title className="" style={{ marginTop: "20px" }}>
                    {item?.HeiName}
                  </Card.Title>

                  <img
                    src={`https://brightnaac.co.in/register/${item?.CollegeLogo}`}
                    style={{
                      position: "absolute",
                      height: "50px ",
                      width: "50px",
                      borderRadius: "50%",
                      top: "50%",
                      left: "80%",
                    }}
                    alt="Not-available"
                  />

                  <Card.Footer className="">
                    <ImLocation />
                    {item?.Address}, {item?.Pincode}
                    <br />
                    <span className="clg-footer p-1">{item?.City}</span>
                    <span className="clg-footer p-1">{item?.State}</span>
                    <span className="clg-footer p-1">{item?.Country}</span>
                    <span className="clg-footer p-1">
                      {item?.HEI === "UG&PG" ? (
                        <div>
                          {(
                            (Number(item?.ugRatingSum ? item?.ugRatingSum : 0) +
                              Number(
                                item?.pgRatingSum ? item?.pgRatingSum : 0
                              )) /
                            14
                          )?.toFixed(1) >= 3.51 ? (
                            <>A++ Accredited</>
                          ) : (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) <= 3.5 &&
                            (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) >= 3.26 ? (
                            <>A+ Accredited</>
                          ) : (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) <= 3.25 &&
                            (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) >= 3.01 ? (
                            <>A Accredited</>
                          ) : (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) <= 3.0 &&
                            (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) >= 2.76 ? (
                            <>B++ Accredited</>
                          ) : (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) <= 2.75 &&
                            (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) >= 2.51 ? (
                            <>B+ Accredited</>
                          ) : (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) <= 2.5 &&
                            (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) >= 2.01 ? (
                            <>B Accredited</>
                          ) : (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) <= 2.0 &&
                            (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) >= 1.51 ? (
                            <>C Accredited</>
                          ) : (
                              (Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) +
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                )) /
                              14
                            )?.toFixed(1) <= 1.5 ? (
                            <>D Not Accredited</>
                          ) : (
                            <></>
                          )}
                          {/* <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span> */}
                        </div>
                      ) : item?.HEI === "UG" ? (
                        <div>
                          <span>
                            {(
                              Number(
                                item?.ugRatingSum ? item?.ugRatingSum : 0
                              ) / 7
                            )?.toFixed(1) >= 3.51 ? (
                              <>A++ Accredited</>
                            ) : (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.5 &&
                              (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 3.26 ? (
                              <>A+ Accredited</>
                            ) : (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.25 &&
                              (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 3.01 ? (
                              <>A Accredited</>
                            ) : (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.0 &&
                              (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.76 ? (
                              <>B++ Accredited</>
                            ) : (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.75 &&
                              (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.51 ? (
                              <>B+ Accredited</>
                            ) : (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.5 &&
                              (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.01 ? (
                              <>B Accredited</>
                            ) : (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.0 &&
                              (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 1.51 ? (
                              <>C Accredited</>
                            ) : (
                                Number(
                                  item?.ugRatingSum ? item?.ugRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 1.5 ? (
                              <>D Not Accredited</>
                            ) : (
                              <></>
                            )}
                          </span>
                          {/* <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span> */}
                        </div>
                      ) : item?.HEI === "PG" ? (
                        <div>
                          <span>
                            {(
                              Number(
                                item?.pgRatingSum ? item?.pgRatingSum : 0
                              ) / 7
                            )?.toFixed(1) >= 3.51 ? (
                              <>A++ Accredited</>
                            ) : (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.5 &&
                              (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 3.26 ? (
                              <>A+ Accredited</>
                            ) : (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.25 &&
                              (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 3.01 ? (
                              <>A Accredited</>
                            ) : (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.0 &&
                              (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.76 ? (
                              <>B++ Accredited</>
                            ) : (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.75 &&
                              (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.51 ? (
                              <>B+ Accredited</>
                            ) : (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.5 &&
                              (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.01 ? (
                              <>B Accredited</>
                            ) : (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.0 &&
                              (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 1.51 ? (
                              <>C Accredited</>
                            ) : (
                                Number(
                                  item?.pgRatingSum ? item?.pgRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 1.5 ? (
                              <>D Not Accredited</>
                            ) : (
                              <></>
                            )}
                          </span>
                          {/* <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span> */}
                        </div>
                      ) : item?.HEI === "University" ? (
                        <div>
                          <span>
                            {(
                              Number(
                                item?.uniRatingSum ? item?.uniRatingSum : 0
                              ) / 7
                            )?.toFixed(1) >= 3.51 ? (
                              <>A++ Accredited</>
                            ) : (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.5 &&
                              (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 3.26 ? (
                              <>A+ Accredited</>
                            ) : (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.25 &&
                              (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 3.01 ? (
                              <>A Accredited</>
                            ) : (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.0 &&
                              (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.76 ? (
                              <>B++ Accredited</>
                            ) : (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.75 &&
                              (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.51 ? (
                              <>B+ Accredited</>
                            ) : (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.5 &&
                              (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.01 ? (
                              <>B Accredited</>
                            ) : (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.0 &&
                              (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 1.51 ? (
                              <>C Accredited</>
                            ) : (
                                Number(
                                  item?.uniRatingSum ? item?.uniRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 1.5 ? (
                              <>D Not Accredited</>
                            ) : (
                              <></>
                            )}
                          </span>
                          {/* <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span> */}
                        </div>
                      ) : item?.HEI === "Autonomous" ? (
                        <div>
                          <span>
                            {(
                              Number(
                                item?.autoRatingSum ? item?.autoRatingSum : 0
                              ) / 7
                            )?.toFixed(1) >= 3.51 ? (
                              <>A++ Accredited</>
                            ) : (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.5 &&
                              (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 3.26 ? (
                              <>A+ Accredited</>
                            ) : (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.25 &&
                              (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 3.01 ? (
                              <>A Accredited</>
                            ) : (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 3.0 &&
                              (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.76 ? (
                              <>B++ Accredited</>
                            ) : (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.75 &&
                              (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.51 ? (
                              <>B+ Accredited</>
                            ) : (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.5 &&
                              (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 2.01 ? (
                              <>B Accredited</>
                            ) : (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 2.0 &&
                              (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) >= 1.51 ? (
                              <>C Accredited</>
                            ) : (
                                Number(
                                  item?.autoRatingSum ? item?.autoRatingSum : 0
                                ) / 7
                              )?.toFixed(1) <= 1.5 ? (
                              <>D Not Accredited</>
                            ) : (
                              <></>
                            )}
                          </span>
                          {/* <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span> */}
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>
                  </Card.Footer>
                </Card>
              );
            })}
          {/* <Card
            onClick={() => navigate("/adminclgugcri1")}
            style={{
              maxWidth: "423px",
              position: "relative",
              background: "#fff",
            }}
          >
            <img
              src="clg1.jpeg"
              style={{ width: "100%", height: "150px", borderRadius: "10px" }}
            />

            <Card.Title className="" style={{ marginTop: "20px" }}>
              SVCE
            </Card.Title>

            <img
              src="col3.png"
              style={{
                position: "absolute",
                height: "50px ",
                width: "50px",
                borderRadius: "50%",
                top: "50%",
                left: "80%",
              }}
            />

            <Card.Footer className="">
              <ImLocation />
              Singapura
              <br />
              <span className="clg-footer p-1">Computer Applications</span>
              <span className="clg-footer p-1">Arts</span>
              <span className="clg-footer p-1">Computer Applications</span>
            </Card.Footer>
          </Card>

          <Card
            onClick={() => navigate("/adminclgugcri1")}
            style={{
              maxWidth: "423px",
              position: "relative",
              background: "#fff",
            }}
          >
            <img
              src="clg1.jpeg"
              style={{ width: "100%", height: "150px", borderRadius: "10px" }}
            />

            <Card.Title className="" style={{ marginTop: "20px" }}>
              SVCE
            </Card.Title>

            <img
              src="col3.png"
              style={{
                position: "absolute",
                height: "50px ",
                width: "50px",
                borderRadius: "50%",
                top: "50%",
                left: "80%",
              }}
            />

            <Card.Footer className="">
              <ImLocation />
              Singapura
              <br />
              <span className="clg-footer p-1">Computer Applications</span>
              <span className="clg-footer p-1">Arts</span>
              <span className="clg-footer p-1">Computer Applications</span>
            </Card.Footer>
          </Card>

          <Card
            onClick={() => navigate("/adminclgugcri1")}
            style={{
              maxWidth: "423px",
              position: "relative",
              background: "#fff",
            }}
          >
            <img
              src="clg1.jpeg"
              style={{ width: "100%", height: "150px", borderRadius: "10px" }}
            />

            <Card.Title className="" style={{ marginTop: "20px" }}>
              SVCE
            </Card.Title>

            <img
              src="col3.png"
              style={{
                position: "absolute",
                height: "50px ",
                width: "50px",
                borderRadius: "50%",
                top: "50%",
                left: "80%",
              }}
            />

            <Card.Footer className="">
              <ImLocation />
              Singapura
              <br />
              <span className="clg-footer p-1">Computer Applications</span>
              <span className="clg-footer p-1">Arts</span>
              <span className="clg-footer p-1">Computer Applications</span>
            </Card.Footer>
          </Card> */}
        </div>
      </div>
    </>
  );
}

export default Adminclg;
