import axios from "axios";
import React, { useEffect, useState } from "react";
import UG2271aqar from "./UG2271aqar";
import UG2263aqar from "./UG2263aqar";
import UG2242aqar from "./UG2242aqar";
import UG2241aqar from "./UG2241aqar";
import UG2212aqar from "./UG2212aqar";
import UG2211aqar from "./UG2211aqar";
import { useNavigate } from "react-router-dom";

const UGC2aqar = ({ startYeardata, endYeardata }) => {
  const navigate = useNavigate();
  const [UG_Criteria02, setUG_Criteria02] = useState([]);
  const ugCriteria02Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria02"
      );
      if (res.status === 200) {
        setUG_Criteria02(
          res.data.ugcriteria02?.filter(
            (item) =>
              item?.Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.ugcriteria02?.filter(
          (item) =>
            item?.Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setUG_Criteria02(error.response.data.ugcriteria02);
    }
  };
  useEffect(() => {
    ugCriteria02Details();
  }, [startYeardata, endYeardata]);
  return (
    <div>
      <div className="text-center">
        <b>Criteria 2</b>
      </div>
      {UG_Criteria02?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.1.1)
            </div>
            <UG2211aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.1.2)
            </div>
            <UG2212aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.4.1 & 2.4.3)
            </div>
            <UG2241aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.4.2)
            </div>
            <UG2242aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.6.3)
            </div>
            <UG2263aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.7.1)
            </div>
            <UG2271aqar item={item} />
          </div>
        );
      })}
    </div>
  );
};

export default UGC2aqar;
