import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

function LibraryHeader() {
  const libDetails = sessionStorage.getItem("Luser");
  useEffect(() => {
    if (!libDetails) {
      window.location.assign("/llogin");
      return alert("please login first!!!");
    }
  }, [libDetails]);
  return (
    <>
      <div
        className="header"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <Navbar expand="lg" style={{ backgroundColor: "#2A2A72" }}>
          <Container fluid>
            <Navbar.Brand href="#">
              {/* <p className=""></p> */}
              <h3 className="admin-heading">
                {JSON.parse(libDetails)?.facultyAddedBy?.HeiName}
              </h3>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
                <div className=" d-flex align-items-center justify-content-center">
                  <div class="dropdown ">
                    <img
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      src={`https://brightnaac.co.in/register/${
                        JSON.parse(libDetails)?.facultyAddedBy?.CollegeLogo
                      }`}
                      alt="logo"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        border: "2px solid black",
                        borderColor: "#393186",
                      }}
                    />
                    <ul class="dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/libraryprofile">
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="dropdown-item"
                          to="/llogin"
                          onClick={() => {
                            sessionStorage.removeItem("Luser");
                          }}
                        >
                          {" "}
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="m-3"
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "#ffffff",
                    }}
                  >
                    {JSON.parse(libDetails)?.FName}{" "}
                    <div
                      className="d-block"
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#ffffff",
                      }}
                    >
                      ID:{JSON.parse(libDetails)?._id}
                    </div>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default LibraryHeader;
