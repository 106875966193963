import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import exportFromJSON from "export-from-json";
import { DatePicker, Space } from "antd";
import { AiOutlineEye } from "react-icons/ai";

const UGCri03322 = () => {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [View, setView] = useState([]);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  // ================date filter=====================

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  // ================date filter=====================

  const navigate = useNavigate();

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("3.2.2");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          Year: item?.Year,
          "Sl. No.": item?.Criteria03_322?.SNo,
          "Name of the teacher": item?.Criteria03_322?.NTea,
          "Title of the book/chapters published": item?.Criteria03_322?.Title1,
          "Title of the paper": item?.Criteria03_322?.Title2,
          "Title of the proceedings of the conference":
            item?.Criteria03_322?.Title3,
          "Year of publication": item?.Criteria03_322?.YPub,
          "ISBN/ISSN number of the proceeding": item?.Criteria03_322?.IIISBN,
          "Name of the publisher": item?.Criteria03_322?.NPub,
          "Whether at the time of publication Affiliating Institution was same":
            item?.Criteria03_322?.TimeWhether,
          "Description (min 500 characters)": item?.Criteria03_322?.Desc15,
          Document: item?.Criteria03_322?.Link16,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================
  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        pricipalDetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
        item1?.approve === "true" &&
        selectedYear === item1?.Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 3 });
  };

  //  ===========================================================================================

  const [content, setContent] = useState([]);
  const [selectedYear, setselectedYear] = useState("");
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/ugcri3_322"
      );
      if (res.status === 200) {
        setContent(res.data.getC322);
        setdata1(res.data.getC322);
        setfilterPdf(res.data.getC322);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);
  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);

  console.log("reiewyuwefe", content);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-03-322
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>
            <div className=" col-lg-2 text-end lab">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = content?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year &&
                      (SearchItem === "" ||
                        item1?.Criteria03_322?.criteria?.includes(SearchItem) ||
                        item1?.Year?.includes(SearchItem) ||
                        item1?.Criteria03_322?.SNo?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.NTea?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Title1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Title2?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Title3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.YPub?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.IIISBN?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.NPub?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.TimeWhether?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Desc15?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Link16?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });
                  if (arr1?.length > 0) {
                    navigate("/PDFprintUG3_322", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4 align-items-center">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="col-lg-9">
            <div>
              <Nav defaultActiveKey="">
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-1"
                    onClick={() => navigate("/UGCri03312")}
                  >
                    <Button className="criteriabtn btn-sub1">
                      3.1.1 & 3.1.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-2"
                    onClick={() => navigate("/UGCri03313")}
                  >
                    <Button className="criteriabtn  btn-sub">3.1.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-3"
                    onClick={() => navigate("/UGCri03321")}
                  >
                    <Button className="criteriabtn  btn-sub">3.2.1</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-4"
                    onClick={() => navigate("/UGCri03322")}
                  >
                    <Button
                      variant="warning"
                      style={{ backgroundColor: "#ffc107" }}
                      className="criteriabtn-0  btn-sub"
                    >
                      3.2.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-5"
                    onClick={() => navigate("/UGCri03332")}
                  >
                    <Button style={{}} className="criteriabtn  btn-sub">
                      {" "}
                      3.3.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-5"
                    onClick={() => navigate("/UGCri03333")}
                  >
                    <Button style={{}} className="criteriabtn btn-sub">
                      {" "}
                      3.3.3
                    </Button>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-5"
                    onClick={() => navigate("/UGCri03341")}
                  >
                    <Button style={{}} className="criteriabtn  btn-sub">
                      {" "}
                      3.4.1
                    </Button>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-5"
                    onClick={() => navigate("/UGCri03342")}
                  >
                    <Button style={{}} className="criteriabtn  btn-sub">
                      {" "}
                      3.4.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Sl. No. </th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>
                    Whether at the time of publication Affiliating Institution
                    was same
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year &&
                      (SearchItem === "" ||
                        item1?.Criteria03_322?.criteria?.includes(SearchItem) ||
                        item1?.Year?.includes(SearchItem) ||
                        item1?.Criteria03_322?.SNo?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.NTea?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Title1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Title2?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Title3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.YPub?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.IIISBN?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.NPub?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.TimeWhether?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Desc15?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Link16?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?.Year}</td>
                        <td>{item?.Criteria03_322?.criteria}</td>
                        <td>{item?.Criteria03_322?.SNo}</td>
                        <td>{item?.Criteria03_322?.NTea}</td>
                        <td>{item?.Criteria03_322?.Title1}</td>
                        <td>{item?.Criteria03_322?.Title2}</td>
                        <td>{item?.Criteria03_322?.Title3}</td>
                        <td>{item?.Criteria03_322?.YPub}</td>
                        <td>{item?.Criteria03_322?.IIISBN}</td>
                        <td>{item?.Criteria03_322?.NPub}</td>
                        <td>{item?.Criteria03_322?.TimeWhether}</td>
                        <td>
                          {item?.Criteria03_322?.Desc4?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow12();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow5();
                            }}
                            fontSize={20}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_322?.Link16}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>{" "}
          {/* Show description modal */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria03_322?.Desc15}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose12}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default UGCri03322;
