import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "animate.css";
import "./App.css";

//  Common Pages
import Home from "./Component/Home";
import Header from "./Component/Header";
import SubHeader from "./Component/SubHeader";
import Footer from "./Component/Footer";
import About_us from "./Component/About_us";
import PopularCourse from "./Component/PopularCourse";
import SingalCourse from "./Component/SingalCourse";
import CourseSingle from "./Component/CourseSingle";
import Gallery from "./Component/Gallery";
import Gallerypages from "./Component/Gallerypages";
import Contactus from "./Component/Contactus";
import Events from "./Component/Events";
import Eventspage from "./Component/Eventspage";
import Pagenotfound from "./Component/Pagenotfound";
import Blog from "./Component/Blog";
import StudentLogin from "./Component/StudentLogin";
import StudentloginR from "./Component/StudentloginR";
import RegisterForm from "./Component/RegisterForm";
import Successpage from "./Component/Successpage";
import Resources from "./Component/Resources";
import Login from "./Component/Login";
// import Login from "./Component/Stu";
import Assessment from "./Component/Assessment";
import Rigester from "./Component/Rigester";
import Faq from "./Component/Faq";
import Flogin from "./Component/FacultyPanel/Flogin";
import Llogin from "./Component/Llogin";
import Information from "./Component/Information";
import Aqar from "./Component/Aqar";

// College list
import Collegeslist from "./Component/Collegeslist";

//    ADMIN PANEL ROUTES
import Admin from "./Component/AdminPanel/Admin";
import AdminPanel from "./Component/AdminPanel/AdminPanel";
import Dashboard from "./Component/AdminPanel/Dashboard";
import AHome from "./Component/AdminPanel/AHome";
import Graphic from "./Component/AdminPanel/Graphic";
import Description from "./Component/AdminPanel/Description";
import Announcement from "./Component/AdminPanel/Announcement";
import Notification from "./Component/AdminPanel/Notification";
import AGallery from "./Component/AdminPanel/Gallery";
import Course from "./Component/AdminPanel/Course";
import Event from "./Component/AdminPanel/Event";
import Admincontactus from "./Component/AdminPanel/Admincontactus";
import Adminhelpuser from "./Component/AdminPanel/Adminhelpuser";
import FAQ from "./Component/AdminPanel/FAQ";
import Seminar from "./Component/AdminPanel/Seminar";
import RegisterUser from "./Component/AdminPanel/RegisterUser";
import AddYears from "./Component/AdminPanel/AddYears";
import Newlyreg from "./Component/AdminPanel/Newlyreg";
import Approvedclg from "./Component/AdminPanel/Approvedclg";
import AddSlider from "./Component/AdminPanel/AddSlider";
import Addannouncement from "./Component/AdminPanel/Addanouncement";
import AddVideos from "./Component/AdminPanel/AddVideos";
import AddLatestNews from "./Component/AdminPanel/AddLatestnews";
import AddCommitedtoeducating from "./Component/AdminPanel/AddCommitedtoeducating";
import Addvalueframework from "./Component/AdminPanel/Addvalueframework";
import AddAboutus from "./Component/AdminPanel/AddAboutus";
import AddLeadership from "./Component/AdminPanel/AddLeadership";
import AddAccrediation from "./Component/AdminPanel/AddAccrediation";
import AccrediationAndAssessment from "./Component/AdminPanel/AccrediationAndAssessment";
import AddCampus from "./Component/AdminPanel/AddCampus";
import AddNaccoffice from "./Component/AdminPanel/AddNaccoffice";
import AdminLibrary from "./Component/AdminPanel/AdminLibrary";
import AdminPublication from "./Component/AdminPanel/AdminPublication";

// ADMIN COLLEGE ROUTES

import Adminfinalreports from "./Component/AdminClg/Adminfinalreports";
import Adminclg from "./Component/AdminPanel/Adminclg";

// Admin Clg Under Graduate

import AdminclgDashboard from "./Component/AdminClg/AdminclgDashboard";
import DisplayFaculty from "./Component/AdminClg/DisplayFaculty";
import UnderGraduateCri1 from "./Component/AdminClg/UnderGraduate/UnderGraduateCri1";
import UnderGraduateCri2 from "./Component/AdminClg/UnderGraduate/UnderGraduateCri2";
import UnderGraduateCri3 from "./Component/AdminClg/UnderGraduate/UnderGraduateCri3";
import UnderGraduateCri4 from "./Component/AdminClg/UnderGraduate/UnderGraduateCri4";
import UnderGraduateCri5 from "./Component/AdminClg/UnderGraduate/UnderGraduateCri5";
import UnderGraduateCri6 from "./Component/AdminClg/UnderGraduate/UnderGraduateCri6";
import UnderGraduateCri7 from "./Component/AdminClg/UnderGraduate/UnderGraduateCri7";

// Admin Clg Post Graduate

import AdminClgPG01_113 from "./Component/AdminClg/PostGraduate/AdminClgPG01_113";
import AdminClgPG01_121 from "./Component/AdminClg/PostGraduate/AdminClgPG01_121";
import AdminClgPG01_122 from "./Component/AdminClg/PostGraduate/AdminClgPG01_122";
import AdminClgPG01_132 from "./Component/AdminClg/PostGraduate/AdminClgPG01_132";
import AdminClgPG01_133 from "./Component/AdminClg/PostGraduate/AdminClgPG01_133";

import AdminClgPG02_211 from "./Component/AdminClg/PostGraduate/AdminClgPG02_211";
import AdminClgPG02_212 from "./Component/AdminClg/PostGraduate/AdminClgPG02_212";
import AdminClgPG02_241 from "./Component/AdminClg/PostGraduate/AdminClgPG02_241";
import AdminClgPG02_242 from "./Component/AdminClg/PostGraduate/AdminClgPG02_242";
import AdminClgPG02_263 from "./Component/AdminClg/PostGraduate/AdminClgPG02_263";
import AdminClgPG02_271 from "./Component/AdminClg/PostGraduate/AdminClgPG02_271";

import AdminClgPG03_312 from "./Component/AdminClg/PostGraduate/AdminClgPG03_312";
import AdminClgPG03_322 from "./Component/AdminClg/PostGraduate/AdminClgPG03_322";
import AdminClgPG03_332 from "./Component/AdminClg/PostGraduate/AdminClgPG03_332";
import AdminClgPG03_333 from "./Component/AdminClg/PostGraduate/AdminClgPG03_333";
import AdminClgPG03_342 from "./Component/AdminClg/PostGraduate/AdminClgPG03_342";
import AdminClgPG03_343 from "./Component/AdminClg/PostGraduate/AdminClgPG03_343";
import AdminClgPG03_351 from "./Component/AdminClg/PostGraduate/AdminClgPG03_351";
import AdminClgPG03_352 from "./Component/AdminClg/PostGraduate/AdminClgPG03_352";

import AdminClgPG04_413 from "./Component/AdminClg/PostGraduate/AdminClgPG04_413";
import AdminClgPG04_414 from "./Component/AdminClg/PostGraduate/AdminClgPG04_414";
import AdminClgPG04_422 from "./Component/AdminClg/PostGraduate/AdminClgPG04_422";

import AdminClgPG05_511 from "./Component/AdminClg/PostGraduate/AdminClgPG05_511";
import AdminClgPG05_513 from "./Component/AdminClg/PostGraduate/AdminClgPG05_513";
import AdminClgPG05_514 from "./Component/AdminClg/PostGraduate/AdminClgPG05_514";
import AdminClgPG05_521 from "./Component/AdminClg/PostGraduate/AdminClgPG05_521";
import AdminClgPG05_522 from "./Component/AdminClg/PostGraduate/AdminClgPG05_522";
import AdminClgPG05_523 from "./Component/AdminClg/PostGraduate/AdminClgPG05_523";
import AdminClgPG05_531 from "./Component/AdminClg/PostGraduate/AdminClgPG05_531";
import AdminClgPG05_533 from "./Component/AdminClg/PostGraduate/AdminClgPG05_533";

import AdminClgPG06_623 from "./Component/AdminClg/PostGraduate/AdminClgPG06_623";
import AdminClgPG06_632 from "./Component/AdminClg/PostGraduate/AdminClgPG06_632";
import AdminClgPG06_633 from "./Component/AdminClg/PostGraduate/AdminClgPG06_633";
import AdminClgPG06_634 from "./Component/AdminClg/PostGraduate/AdminClgPG06_634";
import AdminClgPG06_642 from "./Component/AdminClg/PostGraduate/AdminClgPG06_642";
import AdminClgPG06_653 from "./Component/AdminClg/PostGraduate/AdminClgPG06_653";

import PostGraduateCri1 from "./Component/AdminClg/PostGraduate/PostGraduateCri1";
import PostGraduateCri2 from "./Component/AdminClg/PostGraduate/PostGraduateCri2";
import PostGraduateCri3 from "./Component/AdminClg/PostGraduate/PostGraduateCri3";
import PostGraduateCri4 from "./Component/AdminClg/PostGraduate/PostGraduateCri4";
import PostGraduateCri5 from "./Component/AdminClg/PostGraduate/PostGraduateCri5";
import PostGraduateCri6 from "./Component/AdminClg/PostGraduate/PostGraduateCri6";
import PostGraduateCri7 from "./Component/AdminClg/PostGraduate/PostGraduateCri7";

// Admin Clg Autonomus

import AdminClgAUTO01_112 from "./Component/AdminClg/Autonumous/AdminClgAUTO01_112";
import AdminClgAUTO01_113 from "./Component/AdminClg/Autonumous/AdminClgAUTO01_113";
import AdminClgAUTO01_132 from "./Component/AdminClg/Autonumous/AdminClgAUTO01_132";
import AdminClgAUTO01_134 from "./Component/AdminClg/Autonumous/AdminClgAUTO01_134";

import AdminClgAUTO02_211 from "./Component/AdminClg/Autonumous/AdminClgAUTO02_211";
import AdminClgAUTO02_212 from "./Component/AdminClg/Autonumous/AdminClgAUTO02_212";
import AdminClgAUTO02_241 from "./Component/AdminClg/Autonumous/AdminClgAUTO02_241";
import AdminClgAUTO02_242 from "./Component/AdminClg/Autonumous/AdminClgAUTO02_242";
import AdminClgAUTO02_251 from "./Component/AdminClg/Autonumous/AdminClgAUTO02_251";
import AdminClgAUTO02_263 from "./Component/AdminClg/Autonumous/AdminClgAUTO02_263";
import AdminClgAUTO02_271 from "./Component/AdminClg/Autonumous/AdminClgAUTO02_271";

import AdminClgAUTO03_312 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_312";
import AdminClgAUTO03_313 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_313";
import AdminClgAUTO03_321 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_321";
import AdminClgAUTO03_332 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_332";
import AdminClgAUTO03_343 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_343";
import AdminClgAUTO03_344 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_344";
import AdminClgAUTO03_351 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_351";
import AdminClgAUTO03_362 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_362";
import AdminClgAUTO03_363 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_363";
import AdminClgAUTO03_371 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_371";
import AdminClgAUTO03_372 from "./Component/AdminClg/Autonumous/AdminClgAUTO03_372";

import AdminClgAUTO04_413 from "./Component/AdminClg/Autonumous/AdminClgAUTO04_413";
import AdminClgAUTO04_414 from "./Component/AdminClg/Autonumous/AdminClgAUTO04_414";
import AdminClgAUTO04_422 from "./Component/AdminClg/Autonumous/AdminClgAUTO04_422";

import AdminClgAUTO05_511 from "./Component/AdminClg/Autonumous/AdminClgAUTO05_511";
import AdminClgAUTO05_513 from "./Component/AdminClg/Autonumous/AdminClgAUTO05_513";
import AdminClgAUTO05_514 from "./Component/AdminClg/Autonumous/AdminClgAUTO05_514";
import AdminClgAUTO05_521 from "./Component/AdminClg/Autonumous/AdminClgAUTO05_521";
import AdminClgAUTO05_522 from "./Component/AdminClg/Autonumous/AdminClgAUTO05_522";
import AdminClgAUTO05_523 from "./Component/AdminClg/Autonumous/AdminClgAUTO05_523";
import AdminClgAUTO05_531 from "./Component/AdminClg/Autonumous/AdminClgAUTO05_531";
import AdminClgAUTO05_533 from "./Component/AdminClg/Autonumous/AdminClgAUTO05_533";

import AdminClgAUTO06_623 from "./Component/AdminClg/Autonumous/AdminClgAUTO06_623";
import AdminClgAUTO06_632 from "./Component/AdminClg/Autonumous/AdminClgAUTO06_632";
import AdminClgAUTO06_633 from "./Component/AdminClg/Autonumous/AdminClgAUTO06_633";
import AdminClgAUTO06_634 from "./Component/AdminClg/Autonumous/AdminClgAUTO06_634";
import AdminClgAUTO06_642 from "./Component/AdminClg/Autonumous/AdminClgAUTO06_642";
import AdminClgAUTO06_653 from "./Component/AdminClg/Autonumous/AdminClgAUTO06_653";

import AutonomusCri1 from "./Component/AdminClg/Autonumous/AutonomousCri1";
import AutonomusCri2 from "./Component/AdminClg/Autonumous/AutonomousCri2";
import AutonomusCri3 from "./Component/AdminClg/Autonumous/AutonomousCri3";
import AutonomusCri4 from "./Component/AdminClg/Autonumous/AutonomousCri4";
import AutonomusCri5 from "./Component/AdminClg/Autonumous/AutonomousCri5";
import AutonomusCri6 from "./Component/AdminClg/Autonumous/AutonomousCri6";
import AutonomusCri7 from "./Component/AdminClg/Autonumous/AutonomousCri7";

// Admin Clg University - starts

import AdminClgUNI01_112 from "./Component/AdminClg/University/AdminClgUNI01_112";
import AdminClgUNI01_113 from "./Component/AdminClg/University/AdminClgUNI01_113";
import AdminClgUNI01_132 from "./Component/AdminClg/University/AdminClgUNI01_132";
import AdminClgUNI01_134 from "./Component/AdminClg/University/AdminClgUNI01_134";
import AdminClgUNI01_141 from "./Component/AdminClg/University/AdminClgUNI01_141";

import AdminClgUNI02_211 from "./Component/AdminClg/University/AdminClgUNI02_211";
import AdminClgUNI02_212 from "./Component/AdminClg/University/AdminClgUNI02_212";
import AdminClgUNI02_241 from "./Component/AdminClg/University/AdminClgUNI02_241";
import AdminClgUNI02_242 from "./Component/AdminClg/University/AdminClgUNI02_242";
import AdminClgUNI02_244 from "./Component/AdminClg/University/AdminClgUNI02_244";
import AdminClgUNI02_251 from "./Component/AdminClg/University/AdminClgUNI02_251";
import AdminClgUNI02_263 from "./Component/AdminClg/University/AdminClgUNI02_263";
import AdminClgUNI02_271 from "./Component/AdminClg/University/AdminClgUNI02_271";

import AdminClgUNI03_312 from "./Component/AdminClg/University/AdminClgUNI03_312";
import AdminClgUNI03_313 from "./Component/AdminClg/University/AdminClgUNI03_313";
import AdminClgUNI03_314 from "./Component/AdminClg/University/AdminClgUNI03_314";
import AdminClgUNI03_316 from "./Component/AdminClg/University/AdminClgUNI03_316";
import AdminClgUNI03_332 from "./Component/AdminClg/University/AdminClgUNI03_332";
import AdminClgUNI03_333 from "./Component/AdminClg/University/AdminClgUNI03_333";
import AdminClgUNI03_343 from "./Component/AdminClg/University/AdminClgUNI03_343";
import AdminClgUNI03_344 from "./Component/AdminClg/University/AdminClgUNI03_344";
import AdminClgUNI03_345 from "./Component/AdminClg/University/AdminClgUNI03_345";
import AdminClgUNI03_346 from "./Component/AdminClg/University/AdminClgUNI03_346";
import AdminClgUNI03_347 from "./Component/AdminClg/University/AdminClgUNI03_347";
import AdminClgUNI03_352 from "./Component/AdminClg/University/AdminClgUNI03_352";
import AdminClgUNI03_362 from "./Component/AdminClg/University/AdminClgUNI03_362";
import AdminClgUNI03_363 from "./Component/AdminClg/University/AdminClgUNI03_363";
import AdminClgUNI03_371 from "./Component/AdminClg/University/AdminClgUNI03_371";
import AdminClgUNI03_372 from "./Component/AdminClg/University/AdminClgUNI03_372";

import AdminClgUNI04_431 from "./Component/AdminClg/University/AdminClgUNI04_431";
import AdminClgUNI04_414 from "./Component/AdminClg/University/AdminClgUNI04_414";
import AdminClgUNI04_422 from "./Component/AdminClg/University/AdminClgUNI04_422";

import AdminClgUNI05_511 from "./Component/AdminClg/University/AdminClgUNI05_511";
import AdminClgUNI05_512 from "./Component/AdminClg/University/AdminClgUNI05_512";
import AdminClgUNI05_513 from "./Component/AdminClg/University/AdminClgUNI05_513";
import AdminClgUNI05_521 from "./Component/AdminClg/University/AdminClgUNI05_521";
import AdminClgUNI05_522 from "./Component/AdminClg/University/AdminClgUNI05_522";
import AdminClgUNI05_523 from "./Component/AdminClg/University/AdminClgUNI05_523";
import AdminClgUNI05_531 from "./Component/AdminClg/University/AdminClgUNI05_531";
import AdminClgUNI05_533 from "./Component/AdminClg/University/AdminClgUNI05_533";

import AdminClgUNI06_623 from "./Component/AdminClg/University/AdminClgUNI06_623";
import AdminClgUNI06_632 from "./Component/AdminClg/University/AdminClgUNI06_632";
import AdminClgUNI06_633 from "./Component/AdminClg/University/AdminClgUNI06_633";
import AdminClgUNI06_634 from "./Component/AdminClg/University/AdminClgUNI06_634";
import AdminClgUNI06_642 from "./Component/AdminClg/University/AdminClgUNI06_642";
import AdminClgUNI06_652 from "./Component/AdminClg/University/AdminClgUNI06_652";

import UniversityCri1 from "./Component/AdminClg/University/UniversityCri1";
import UniversityCri2 from "./Component/AdminClg/University/UniversityCri2";
import UniversityCri3 from "./Component/AdminClg/University/UniversityCri3";
import UniversityCri4 from "./Component/AdminClg/University/UniversityCri4";
import UniversityCri5 from "./Component/AdminClg/University/UniversityCri5";
import UniversityCri6 from "./Component/AdminClg/University/UniversityCri6";
import UniversityCri7 from "./Component/AdminClg/University/UniversityCri7";

// ==================
import AdminClgUG01_113 from "./Component/AdminClg/UnderGraduate/AdminClgUG01_113";
import AdminClgUG01_121 from "./Component/AdminClg/UnderGraduate/AdminClgUG01_121";
import AdminClgUG01_122 from "./Component/AdminClg/UnderGraduate/AdminClgUG01_122";
import AdminClgUG01_132 from "./Component/AdminClg/UnderGraduate/AdminClgUG01_132";
import AdminClgUG01_133 from "./Component/AdminClg/UnderGraduate/AdminClgUG01_133";
import AdminClgUG02_211 from "./Component/AdminClg/UnderGraduate/AdminClgUG02_211";
import AdminClgUG02_212 from "./Component/AdminClg/UnderGraduate/AdminClgUG02_212";
import AdminClgUG02_241 from "./Component/AdminClg/UnderGraduate/AdminClgUG02_241";
import AdminClgUG02_242 from "./Component/AdminClg/UnderGraduate/AdminClgUG02_242";
import AdminClgUG02_263 from "./Component/AdminClg/UnderGraduate/AdminClgUG02_263";
import AdminClgUG02_271 from "./Component/AdminClg/UnderGraduate/AdminClgUG02_271";

import AdminClgUG03_313 from "./Component/AdminClg/UnderGraduate/AdminClgUG03_313";
import AdminClgUG03_321 from "./Component/AdminClg/UnderGraduate/AdminClgUG03_321";
import AdminClgUG03_322 from "./Component/AdminClg/UnderGraduate/AdminClgUG03_322";
import AdminClgUG03_332 from "./Component/AdminClg/UnderGraduate/AdminClgUG03_332";

// Admin Clg University - ends

import AdminClgUG03_333 from "./Component/AdminClg/UnderGraduate/AdminClgUG03_333";
import AdminClgUG03_341 from "./Component/AdminClg/UnderGraduate/AdminClgUG03_341";
import AdminClgUG03_342 from "./Component/AdminClg/UnderGraduate/AdminClgUG03_342";
import AdminClgUG03_312 from "./Component/AdminClg/UnderGraduate/AdminClgUG03_312";

import AdminClgUG04_413 from "./Component/AdminClg/UnderGraduate/AdminClgUG04_413";
import AdminClgUG04_414 from "./Component/AdminClg/UnderGraduate/AdminClgUG04_414";
import AdminClgUG04_422 from "./Component/AdminClg/UnderGraduate/AdminClgUG04_422";

import AdminClgUG05_511 from "./Component/AdminClg/UnderGraduate/AdminClgUG05_511";
import AdminClgUG05_513 from "./Component/AdminClg/UnderGraduate/AdminClgUG05_513";
import AdminClgUG05_514 from "./Component/AdminClg/UnderGraduate/AdminClgUG05_514";
import AdminClgUG05_521 from "./Component/AdminClg/UnderGraduate/AdminClgUG05_521";
import AdminClgUG05_522 from "./Component/AdminClg/UnderGraduate/AdminClgUG05_522";
import AdminClgUG05_523 from "./Component/AdminClg/UnderGraduate/AdminClgUG05_523";
import AdminClgUG05_531 from "./Component/AdminClg/UnderGraduate/AdminClgUG05_531";
import AdminClgUG05_533 from "./Component/AdminClg/UnderGraduate/AdminClgUG05_533";

// ======= NACC ADMIN LOGIN ==========
import AdminClgLogin from "./Component/AdminClg/AdminClgLogin";
import ClgDashboard from "./Component/AdminClg/ClgDashboard";
import AdminClgUG06_623 from "./Component/AdminClg/UnderGraduate/AdminClgUG06_623";
import AdminClgUG06_632 from "./Component/AdminClg/UnderGraduate/AdminClgUG06_632";
import AdminClgUG06_633 from "./Component/AdminClg/UnderGraduate/AdminClgUG06_633";
import AdminClgUG06_634 from "./Component/AdminClg/UnderGraduate/AdminClgUG06_634";
import AdminClgUG06_642 from "./Component/AdminClg/UnderGraduate/AdminClgUG06_642";
import AdminClgUG06_653 from "./Component/AdminClg/UnderGraduate/AdminClgUG06_653";

// ========== Library panel   ========= //

import LibraryDashboard from "./Component/LibraryPanel/LibraryDashboard";
import LibraryProfile from "./Component/LibraryPanel/LibraryProfile";

import PG_Criteria04 from "./Component/LibraryPanel/PG_Criteria04";
import Uni_Criteria04 from "./Component/LibraryPanel/Uni_Criteria04";
import Auto_Criteria04 from "./Component/LibraryPanel/Auto_Criteria04";
import UG_Criteria04 from "./Component/LibraryPanel/UG_Criteria04";
import UG_Criteria004 from "./Component/LibraryPanel/UG_Criteria004";
import PG_Criteria004 from "./Component/LibraryPanel/PG_Criteria004";
import Uni_Criteria004 from "./Component/LibraryPanel/Uni_Criteria004";
import Auto_Criteria004 from "./Component/LibraryPanel/Auto_Criteria004";

import Librarydashboard1 from "./Component/LibraryPanel/Librarydashboard1";

// ==== FACULTY PANEL ==== //
import FacultyAdmindashboard from "./Component/FacultyPanel/FacultyAdmindashboard";
import FacultyDashboard from "./Component/FacultyPanel/FacultyDashboard";
import FacultyPanelProfile from "./Component/FacultyPanel/FacultyPanelProfile";

// Under Graduate  Main page
import UG_Criteria01 from "./Component/FacultyPanel/UG_Criteria01";
import UG_Criteria02 from "./Component/FacultyPanel/UG_Criteria02";
import UG_Criteria03 from "./Component/FacultyPanel/UG_Criteria03";
import UG_Criteria05 from "./Component/FacultyPanel/UG_Criteria05";
import UG_Criteria07 from "./Component/FacultyPanel/UG_Criteria07";

// Under Graduate Add Criteria
import UG_Criteria001 from "./Component/FacultyPanel/UG_Criteria001";
import UG_Criteria002 from "./Component/FacultyPanel/UG_Criteria002";
import UG_Criteria003 from "./Component/FacultyPanel/UG_Criteria003";
import UG_Criteria005 from "./Component/FacultyPanel/UG_Criteria005";
import UG_Criteria007 from "./Component/FacultyPanel/UG_Criteria007";

// Post Graduate  Main page
import PG_Criteria01 from "./Component/FacultyPanel/PG_Criteria_01";
import PG_Criteria02 from "./Component/FacultyPanel/PG_Criteria02";
import PG_Criteria03 from "./Component/FacultyPanel/PG_Criteria_03";
import PG_Criteria05 from "./Component/FacultyPanel/PG_Criteria_05";
import PG_Criteria07 from "./Component/FacultyPanel/PG_Criteria_07";

// Post Graduate Add Criteria
import PG_Criteria001 from "./Component/FacultyPanel/PG_Criteria001";
import PG_Criteria002 from "./Component/FacultyPanel/PG_Criteria002";
import PG_Criteria003 from "./Component/FacultyPanel/PG_Criteria003";
import PG_Criteria005 from "./Component/FacultyPanel/PG_Criteria005";
import PG_Criteria007 from "./Component/FacultyPanel/PG_Criteria007";

// University  Main page
import Uni_Criteria01 from "./Component/FacultyPanel/Uni_Criteria01";
import Uni_Criteria02 from "./Component/FacultyPanel/Uni_Criteria02";
import Uni_Criteria03 from "./Component/FacultyPanel/Uni_Criteria03";
import Uni_Criteria05 from "./Component/FacultyPanel/Uni_Criteria05";
import Uni_Criteria07 from "./Component/FacultyPanel/Uni_Criteria07";

// University Add Criteria
import Uni_Criteria001 from "./Component/FacultyPanel/Uni_Criteria001";
import Uni_Criteria002 from "./Component/FacultyPanel/Uni_Criteria002";
import Uni_Criteria003 from "./Component/FacultyPanel/Uni_Criteria003";
import Uni_Criteria005 from "./Component/FacultyPanel/Uni_Criteria005";
import Uni_Criteria007 from "./Component/FacultyPanel/Uni_Criteria007";

// Autonomous  Main page
import Auto_Criteria01 from "./Component/FacultyPanel/Auto_Criteria01";
import Auto_Criteria02 from "./Component/FacultyPanel/Auto_Criteria02";
import Auto_Criteria03 from "./Component/FacultyPanel/Auto_Criteria03";
import Auto_Criteria05 from "./Component/FacultyPanel/Auto_Criteria05";
import Auto_Criteria07 from "./Component/FacultyPanel/Auto_Criteria07";

// Autonomous Add Criteria
import Auto_Criteria001 from "./Component/FacultyPanel/Auto_Criteria001";
import Auto_Criteria002 from "./Component/FacultyPanel/Auto_Criteria002";
import Auto_Criteria003 from "./Component/FacultyPanel/Auto_Criteria003";
import Auto_Criteria005 from "./Component/FacultyPanel/Auto_Criteria005";
import Auto_Criteria007 from "./Component/FacultyPanel/Auto_Criteria007";

// // END FACULTY PANEL  ========================

// ===== PRINCIPAL PANEL ===== //

import CollegeAdminLogin from "./Component/PrincipalPanel/CollegeAdminLogin";
import AdminProfile from "./Component/PrincipalPanel/AdminProfile";
import ChangePassword from "./Component/PrincipalPanel/ChangePassword";
import AddFaculty from "./Component/PrincipalPanel/AddFaculty";
import CollegeAdmindashboard from "./Component/PrincipalPanel/CollegeAdmindashboard";
import SideBarPrincipal from "./Component/PrincipalPanel/SideBar";

// File report

import FinalReports from "./Component/PrincipalPanel/FinalReports";
import IIQA from "./Component/PrincipalPanel/IIQA";

import CreateMergedReport from "./Component/PrincipalPanel/CreateMergedReport";
import CreateMergedReportIQAC from "./Component/PrincipalPanel/CreateMergedReportIQAC";

// UnderGraduate Main Pages
import UgCriteria1 from "./Component/PrincipalPanel/UgCriteria1";
import UgCriteria2 from "./Component/PrincipalPanel/UgCriteria2";
import UgCriteria3 from "./Component/PrincipalPanel/UgCriteria3";
import UgCriteria4 from "./Component/PrincipalPanel/UgCriteria4";
import UgCriteria5 from "./Component/PrincipalPanel/UgCriteria5";
import UgCriteria6 from "./Component/PrincipalPanel/UgCriteria6";
import UgCriteria7 from "./Component/PrincipalPanel/UgCriteria7";

// PostGraduate Main Pages
import PgCriteria1 from "./Component/PrincipalPanel/PgCriteria1";
import PgCriteria2 from "./Component/PrincipalPanel/PgCriteria2";
import PgCriteria3 from "./Component/PrincipalPanel/PgCriteria3";
import PgCriteria4 from "./Component/PrincipalPanel/PgCriteria4";
import PgCriteria5 from "./Component/PrincipalPanel/PgCriteria5";
import PgCriteria6 from "./Component/PrincipalPanel/PgCriteria6";
import PgCriteria7 from "./Component/PrincipalPanel/PgCriteria7";

// Autonomous Main pages
import Criteria1 from "./Component/PrincipalPanel/Criteria1";
import Criteria2 from "./Component/PrincipalPanel/Criteria2";
import Criteria3 from "./Component/PrincipalPanel/Criteria3";
import Criteria4 from "./Component/PrincipalPanel/Criteria4";
import Criteria5 from "./Component/PrincipalPanel/Criteria5";
import Criteria6 from "./Component/PrincipalPanel/Criteria6";
import Criteria7 from "./Component/PrincipalPanel/Criteria7";

// University Main Pages
import Ucriteria7 from "./Component/PrincipalPanel/Ucriteria7";
import Ucriteria6 from "./Component/PrincipalPanel/Ucriteria6";
import Ucriteria5 from "./Component/PrincipalPanel/Ucriteria5";
import Ucriteria4 from "./Component/PrincipalPanel/Ucriteria4";
import Ucriteria3 from "./Component/PrincipalPanel/Ucriteria3";
import Ucriteria2 from "./Component/PrincipalPanel/Ucriteria2";
import Ucriteria1 from "./Component/PrincipalPanel/Ucriteria1";

//Principal ADD Criteria-06 Details
import PGcriteria6 from "./Component/PrincipalPanel/PostGcriteria6";
import UGcriteria6 from "./Component/PrincipalPanel/UnderGcriteria6";
import Aucriteria6 from "./Component/PrincipalPanel/AuCriteria6";
import UnCriteria6 from "./Component/PrincipalPanel/UnCriteria6";

// ---------------  PRINCIPAL PANEL UnderGraduate  Sub-Criteria-Pages  ----------------

// Principal Panel  UG Criteria 01 Sub Criteria
import Criteria01_121 from "./Component/PrincipalPanel/Criteria01_121";
import Criteria01_132 from "./Component/PrincipalPanel/Criteria01_132";
import Criteria01_133 from "./Component/PrincipalPanel/Criteria01_133";
import Criteria01_122 from "./Component/PrincipalPanel/Criteria01_122";
import Cri01_113 from "./Component/PrincipalPanel/Cri01_113";

// Principal Panel  UG Criteria 02 Sub Criteria
import CriteriaUG02_211 from "./Component/PrincipalPanel/CriteriaUG02_211";
import CriteriaUG02_212 from "./Component/PrincipalPanel/CriteriaUG02_212";
import CriteriaUG02_241 from "./Component/PrincipalPanel/CriteriaUG02_241";
import CriteriaUG02_242 from "./Component/PrincipalPanel/CriteriaUG02_242";
import CriteriaUG02_263 from "./Component/PrincipalPanel/CriteriaUG02_263";
import CriteriaUG02_271 from "./Component/PrincipalPanel/CriteriaUG02_271";

// Principal Panel  UG Criteria 03 Sub Criteria
import UGCri03313 from "./Component/PrincipalPanel/CriteriaUG03313";
import UGCri03312 from "./Component/PrincipalPanel/CriteriaUG03312";
import UGCri03321 from "./Component/PrincipalPanel/CriteriaUG03321";
import UGCri03322 from "./Component/PrincipalPanel/CriteriaUG03322";
import UGCri03332 from "./Component/PrincipalPanel/CriteriaUG03332";
import UGCri03333 from "./Component/PrincipalPanel/CriteriaUG03333";
import UGCri03341 from "./Component/PrincipalPanel/CriteriaUG03341";
import UGCri03342 from "./Component/PrincipalPanel/CriteriaUG03342";

// Principal Panel  UG Criteria 04 Sub Criteria
import Criteria04_413 from "./Component/PrincipalPanel/Criteria04_413";
import Criteria04_414 from "./Component/PrincipalPanel/Criteria04_414";
import Criteria04_422 from "./Component/PrincipalPanel/Criteria04_422";

// Principal Panel  UG Criteria 05 Sub Criteria
import UG_05_511 from "./Component/PrincipalPanel/UG_05_Sub_Criteria/UG_05_511";
import UG_05_513 from "./Component/PrincipalPanel/UG_05_Sub_Criteria/UG_05_513";
import UG_05_514 from "./Component/PrincipalPanel/UG_05_Sub_Criteria/UG_05_514";
import UG_05_521 from "./Component/PrincipalPanel/UG_05_Sub_Criteria/UG_05_521";
import UG_05_522 from "./Component/PrincipalPanel/UG_05_Sub_Criteria/UG_05_522";
import UG_05_523 from "./Component/PrincipalPanel/UG_05_Sub_Criteria/UG_05_523";
import UG_05_531 from "./Component/PrincipalPanel/UG_05_Sub_Criteria/UG_05_531";
import UG_05_533 from "./Component/PrincipalPanel/UG_05_Sub_Criteria/UG_05_533";

// Principal Panel  UG Criteria 06 Sub Criteria
import UG_06_623 from "./Component/PrincipalPanel/UG_06_Sub_Criteria/UG_06_623";
import UG_06_632 from "./Component/PrincipalPanel/UG_06_Sub_Criteria/UG_06_632";
import UG_06_633 from "./Component/PrincipalPanel/UG_06_Sub_Criteria/UG_06_633";
import UG_06_634 from "./Component/PrincipalPanel/UG_06_Sub_Criteria/UG_06_634";
import UG_06_642 from "./Component/PrincipalPanel/UG_06_Sub_Criteria/UG_06_642";
import UG_06_653 from "./Component/PrincipalPanel/UG_06_Sub_Criteria/UG_06_653";

// ------------------  PRINCIPAL PANEL UnderGradute PDF  --------------------------

//  Principal Panel  UG Criteria 01 Sub Criteria PDF
import PDFprint from "./Component/PrincipalPanel/PDFprint";
import PDFprintUG1_121 from "./Component/PrincipalPanel/PDFprintUG1_121";
import PDFprintUG1_122 from "./Component/PrincipalPanel/PDFprintUG1_122";
import PDFprintUG1_132 from "./Component/PrincipalPanel/PDFprintUG1_132";
import PDFprintUG1_133 from "./Component/PrincipalPanel/PDFprintUG1_133";

//  Principal Panel  UG Criteria 02 Sub Criteria PDF
import PDFprintUG2_211 from "./Component/PrincipalPanel/PDFprintUG2_211";
import PDFprintUG2_212 from "./Component/PrincipalPanel/PDFprintUG2_212";
import PDFprintUG2_241 from "./Component/PrincipalPanel/PDFprintUG2_241";
import PDFprintUG2_242 from "./Component/PrincipalPanel/PDFprintUG2_242";
import PDFprintUG2_263 from "./Component/PrincipalPanel/PDFprintUG2_263";
import PDFprintUG2_271 from "./Component/PrincipalPanel/PDFprintUG2_271";

//  Principal Panel  UG Criteria 03 Sub Criteria PDF
import PDFprintUG3_312 from "./Component/PrincipalPanel/PDFprintUG3_312";
import PDFprintUG3_313 from "./Component/PrincipalPanel/PDFprintUG3_313";
import PDFprintUG3_321 from "./Component/PrincipalPanel/PDFprintUG3_321";
import PDFprintUG3_322 from "./Component/PrincipalPanel/PDFprintUG3_322";
import PDFprintUG3_332 from "./Component/PrincipalPanel/PDFprintUG3_332";
import PDFprintUG3_333 from "./Component/PrincipalPanel/PDFprintUG3_333";
import PDFprintUG3_341 from "./Component/PrincipalPanel/PDFprintUG3_341";
import PDFprintUG3_342 from "./Component/PrincipalPanel/PDFprintUG3_342";

//  Principal Panel  UG Criteria 04 Sub Criteria PDF
import PDFprintUG4_413 from "./Component/PrincipalPanel/PDFprintUG4_413";
import PDFprintUG4_414 from "./Component/PrincipalPanel/PDFprintUG4_414";
import PDFprintUG4_422 from "./Component/PrincipalPanel/PDFprintUG4_422";

//  Principal Panel  UG Criteria 05 Sub Criteria PDF
import PDFprintUG5_511 from "./Component/PrincipalPanel/PDFUG05/PDFprintUG5_511";
import PDFprintUG5_513 from "./Component/PrincipalPanel/PDFUG05/PDFprintUG5_513";
import PDFprintUG5_514 from "./Component/PrincipalPanel/PDFUG05/PDFprintUG5_514";
import PDFprintUG5_521 from "./Component/PrincipalPanel/PDFUG05/PDFprintUG5_521";
import PDFprintUG5_522 from "./Component/PrincipalPanel/PDFUG05/PDFprintUG5_522";
import PDFprintUG5_523 from "./Component/PrincipalPanel/PDFUG05/PDFprintUG5_523";
import PDFprintUG5_531 from "./Component/PrincipalPanel/PDFUG05/PDFprintUG5_531";
import PDFprintUG5_533 from "./Component/PrincipalPanel/PDFUG05/PDFprintUG5_533";

//  Principal Panel  UG Criteria 06 Sub Criteria PDF
import PDFprintUG6_623 from "./Component/PrincipalPanel/PDFUG06/PDFprintUG6_623";
import PDFprintUG6_632 from "./Component/PrincipalPanel/PDFUG06/PDFprintUG6_632";
import PDFprintUG6_633 from "./Component/PrincipalPanel/PDFUG06/PDFprintUG6_633";
import PDFprintUG6_634 from "./Component/PrincipalPanel/PDFUG06/PDFprintUG6_634";
import PDFprintUG6_642 from "./Component/PrincipalPanel/PDFUG06/PDFprintUG6_642";
import PDFprintUG6_653 from "./Component/PrincipalPanel/PDFUG06/PDFprintUG6_653";

// -------------------       PRINCIPAL PANEL PG END       ----------------------

// ---------------  PRINCIPAL PANEL PostGraduate  Sub-Criteria-Pages  ----------------

// Principal Panel  PG Criteria 01 Sub Criteri1
import PG_01_113 from "./Component/PrincipalPanel/PG_01_Sub_Criteria/PG_01_113";
import PG_01_121 from "./Component/PrincipalPanel/PG_01_Sub_Criteria/PG_01_121";
import PG_01_122 from "./Component/PrincipalPanel/PG_01_Sub_Criteria/PG_01_122";
import PG_01_132 from "./Component/PrincipalPanel/PG_01_Sub_Criteria/PG_01_132";
import PG_01_133 from "./Component/PrincipalPanel/PG_01_Sub_Criteria/PG_01_133";

// Principal Panel  PG Criteria 02 Sub Criteri2
import PG_02_211 from "./Component/PrincipalPanel/PG_02_Sub_Criteria/PG_02_211";
import PG_02_212 from "./Component/PrincipalPanel/PG_02_Sub_Criteria/PG_02_212";
import PG_02_242 from "./Component/PrincipalPanel/PG_02_Sub_Criteria/PG_02_242";
import PG_02_241 from "./Component/PrincipalPanel/PG_02_Sub_Criteria/PG_02_241";
import PG_02_263 from "./Component/PrincipalPanel/PG_02_Sub_Criteria/PG_02_263";
import PG_02_271 from "./Component/PrincipalPanel/PG_02_Sub_Criteria/PG_02_271";

// Principal Panel  PG Criteria 03 Sub Criteria 03
import PG_03_311 from "./Component/PrincipalPanel/PG_03_Sub_Criteria/PG_03_311";
import PG_03_322 from "./Component/PrincipalPanel/PG_03_Sub_Criteria/PG_03_322";
import PG_03_332 from "./Component/PrincipalPanel/PG_03_Sub_Criteria/PG_03_332";
import PG_03_333 from "./Component/PrincipalPanel/PG_03_Sub_Criteria/PG_03_333";
import PG_03_342 from "./Component/PrincipalPanel/PG_03_Sub_Criteria/PG_03_342";
import PG_03_343 from "./Component/PrincipalPanel/PG_03_Sub_Criteria/PG_03_343";
import PG_03_351 from "./Component/PrincipalPanel/PG_03_Sub_Criteria/PG_03_351";
import PG_03_352 from "./Component/PrincipalPanel/PG_03_Sub_Criteria/PG_03_352";

// Principal Panel  Post Graduate Criteria 04 Sub Criteria
import PG_04_413 from "./Component/PrincipalPanel/PG_04_Sub_Criteria/PG_04_413";
import PG_04_414 from "./Component/PrincipalPanel/PG_04_Sub_Criteria/PG_04_414";
import PG_04_422 from "./Component/PrincipalPanel/PG_04_Sub_Criteria/PG_04_422";

// Principal Panel  PG Criteria 05 Sub Criteria03
import PG_05_511 from "./Component/PrincipalPanel/PG_05_Sub_Criteria/PG_05_511";
import PG_05_513 from "./Component/PrincipalPanel/PG_05_Sub_Criteria/PG_05_513";
import PG_05_514 from "./Component/PrincipalPanel/PG_05_Sub_Criteria/PG_05_514";
import PG_05_521 from "./Component/PrincipalPanel/PG_05_Sub_Criteria/PG_05_521";
import PG_05_522 from "./Component/PrincipalPanel/PG_05_Sub_Criteria/PG_05_522";
import PG_05_523 from "./Component/PrincipalPanel/PG_05_Sub_Criteria/PG_05_523";
import PG_05_531 from "./Component/PrincipalPanel/PG_05_Sub_Criteria/PG_05_531";
import PG_05_533 from "./Component/PrincipalPanel/PG_05_Sub_Criteria/PG_05_533";

// Principal Panel  PG Criteria 06 Sub Criteria 06
import PG_06_623 from "./Component/PrincipalPanel/PG_06_Sub_Criteria/PG_06_623";
import PG_06_632 from "./Component/PrincipalPanel/PG_06_Sub_Criteria/PG_06_632";
import PG_06_633 from "./Component/PrincipalPanel/PG_06_Sub_Criteria/PG_06_633";
import PG_06_634 from "./Component/PrincipalPanel/PG_06_Sub_Criteria/PG_06_634";
import PG_06_642 from "./Component/PrincipalPanel/PG_06_Sub_Criteria/PG_06_642";
import PG_06_653 from "./Component/PrincipalPanel/PG_06_Sub_Criteria/PG_06_653";

// ---------------  PRINCIPAL PANEL PostGradute PDF  ----------------

//  Principal Panel  PG Criteria 01 Sub Criteria PDF
import PDFprintPG1_121 from "./Component/PrincipalPanel/PDFPG01/PDFprintPG1_121";
import PDFprintPG1_122 from "./Component/PrincipalPanel/PDFPG01/PDFprintPG1_122";
import PDFprintPG1_132 from "./Component/PrincipalPanel/PDFPG01/PDFprintPG1_132";
import PDFprintPG1_113 from "./Component/PrincipalPanel/PDFPG01/PDFprintPG1_113";
import PDFprintPG1_133 from "./Component/PrincipalPanel/PDFPG01/PDFprintPG1_133";

//  Principal Panel  PG Criteria 02 Sub Criteria PDF
import PDFprintPG2_211 from "./Component/PrincipalPanel/PDFPG02/PDFprintPG2_211";
import PDFprintPG2_212 from "./Component/PrincipalPanel/PDFPG02/PDFprintPG2_212";
import PDFprintPG2_241 from "./Component/PrincipalPanel/PDFPG02/PDFprintPG2_241";
import PDFprintPG2_242 from "./Component/PrincipalPanel/PDFPG02/PDFprintPG2_242";
import PDFprintPG2_263 from "./Component/PrincipalPanel/PDFPG02/PDFprintPG2_263";
import PDFprintPG2_271 from "./Component/PrincipalPanel/PDFPG02/PDFprintPG2_271";

//  Principal Panel  PG Criteria 03 Sub Criteria PDF
import PDFprintPG3_311 from "./Component/PrincipalPanel/PDFPG03/PDFprintPG3_311";
import PDFprintPG3_322 from "./Component/PrincipalPanel/PDFPG03/PDFprintPG3_322";
import PDFprintPG3_332 from "./Component/PrincipalPanel/PDFPG03/PDFprintPG3_332";
import PDFprintPG3_333 from "./Component/PrincipalPanel/PDFPG03/PDFprintPG3_333";
import PDFprintPG3_342 from "./Component/PrincipalPanel/PDFPG03/PDFprintPG3_342";
import PDFprintPG3_343 from "./Component/PrincipalPanel/PDFPG03/PDFprintPG3_343";
import PDFprintPG3_351 from "./Component/PrincipalPanel/PDFPG03/PDFprintPG3_351";
import PDFprintPG3_352 from "./Component/PrincipalPanel/PDFPG03/PDFprintPG3_352";

// Principal Panel  Post Graduate Criteria 04 Sub Criteria PDF
import PDFprintPG4_413 from "./Component/PrincipalPanel/PDFPG04/PDFprintPG4_413";
import PDFprintPG4_414 from "./Component/PrincipalPanel/PDFPG04/PDFprintPG4_414";
import PDFprintPG4_422 from "./Component/PrincipalPanel/PDFPG04/PDFprintPG4_422";

//  Principal Panel  PG Criteria 05 Sub Criteria PDF
import PDFprintPG5_511 from "./Component/PrincipalPanel/PDFPG05/PDFprintPG5_511";
import PDFprintPG5_513 from "./Component/PrincipalPanel/PDFPG05/PDFprintPG5_513";
import PDFprintPG5_514 from "./Component/PrincipalPanel/PDFPG05/PDFprintPG5_514";
import PDFprintPG5_521 from "./Component/PrincipalPanel/PDFPG05/PDFprintPG5_521";
import PDFprintPG5_522 from "./Component/PrincipalPanel/PDFPG05/PDFprintPG5_522";
import PDFprintPG5_523 from "./Component/PrincipalPanel/PDFPG05/PDFprintPG5_523";
import PDFprintPG5_531 from "./Component/PrincipalPanel/PDFPG05/PDFprintPG5_531";
import PDFprintPG5_533 from "./Component/PrincipalPanel/PDFPG05/PDFprintPG5_533";

//  Principal Panel  Post graduation Criteria 06 Sub Criteria PDF
import PDFprintPG6_623 from "./Component/PrincipalPanel/PDFPG06/PDFprintPG6_623";
import PDFprintPG6_632 from "./Component/PrincipalPanel/PDFPG06/PDFprintPG6_632";
import PDFprintPG6_633 from "./Component/PrincipalPanel/PDFPG06/PDFprintPG6_633";
import PDFprintPG6_634 from "./Component/PrincipalPanel/PDFPG06/PDFprintPG6_634";
import PDFprintPG6_642 from "./Component/PrincipalPanel/PDFPG06/PDFprintPG6_642";
import PDFprintPG6_653 from "./Component/PrincipalPanel/PDFPG06/PDFprintPG6_653";

// -------------------       PRINCIPAL PANEL PG END       ----------------------

// -------------------  PRINCIPAL PANEL AUTONOMOUS Criteria  ---------------------

// Principal Panel  Auto Criteria 01 Sub Criteri1
import A_01_112 from "./Component/PrincipalPanel/A_01_Sub_Criteria/A_01_112";
import A_01_113 from "./Component/PrincipalPanel/A_01_Sub_Criteria/A_01_113";
import A_01_132 from "./Component/PrincipalPanel/A_01_Sub_Criteria/A_01_132";
import A_01_134 from "./Component/PrincipalPanel/A_01_Sub_Criteria/A_01_134";

// Principal Panel  Auto Criteria 02 Sub Criteri
import A_02_211 from "./Component/PrincipalPanel/A_02_Sub_Criteria/A_02_211";
import A_02_212 from "./Component/PrincipalPanel/A_02_Sub_Criteria/A_02_212";
import A_02_242 from "./Component/PrincipalPanel/A_02_Sub_Criteria/A_02_242";
import A_02_241 from "./Component/PrincipalPanel/A_02_Sub_Criteria/A_02_241";
import A_02_251 from "./Component/PrincipalPanel/A_02_Sub_Criteria/A_02_251";
import A_02_263 from "./Component/PrincipalPanel/A_02_Sub_Criteria/A_02_263";
import A_02_271 from "./Component/PrincipalPanel/A_02_Sub_Criteria/A_02_271";

//  Principal Panel  Auto Criteria 03 Sub Criteria
import A_03_312 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_312";
import A_03_313 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_313";
import A_03_321 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_321";
import A_03_332 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_332";
import A_03_343 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_343";
import A_03_344 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_344";
import A_03_363 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_363";
import A_03_351 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_351";
import A_03_362 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_362";
import A_03_371 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_371";
import A_03_372 from "./Component/PrincipalPanel/A_03_Sub_Criteria/A_03_372";

// Principal Panel  Auto Criteria 04 Sub Criteria
import A_04_413 from "./Component/PrincipalPanel/A_04_Sub_Criteria/A_04_413";
import A_04_414 from "./Component/PrincipalPanel/A_04_Sub_Criteria/A_04_414";
import A_04_422 from "./Component/PrincipalPanel/A_04_Sub_Criteria/A_04_422";

// Principal Panel  Auto Criteria 05 Sub Criteria
import A_05_511 from "./Component/PrincipalPanel/A_05_Sub_Criteria/A_05_511";
import A_05_513 from "./Component/PrincipalPanel/A_05_Sub_Criteria/A_05_513";
import A_05_514 from "./Component/PrincipalPanel/A_05_Sub_Criteria/A_05_514";
import A_05_521 from "./Component/PrincipalPanel/A_05_Sub_Criteria/A_05_521";
import A_05_522 from "./Component/PrincipalPanel/A_05_Sub_Criteria/A_05_522";
import A_05_523 from "./Component/PrincipalPanel/A_05_Sub_Criteria/A_05_523";
import A_05_531 from "./Component/PrincipalPanel/A_05_Sub_Criteria/A_05_531";
import A_05_533 from "./Component/PrincipalPanel/A_05_Sub_Criteria/A_05_533";

// Principal Panel  Auto Criteria 06 Sub Criteria
import A_06_623 from "./Component/PrincipalPanel/A_06_Sub_Criteria/A_06_623";
import A_06_632 from "./Component/PrincipalPanel/A_06_Sub_Criteria/A_06_632";
import A_06_633 from "./Component/PrincipalPanel/A_06_Sub_Criteria/A_06_633";
import A_06_634 from "./Component/PrincipalPanel/A_06_Sub_Criteria/A_06_634";
import A_06_642 from "./Component/PrincipalPanel/A_06_Sub_Criteria/A_06_642";
import A_06_653 from "./Component/PrincipalPanel/A_06_Sub_Criteria/A_06_653";

// -------------------  PRINCIPAL PANEL AUTONOMOUS PDF  ---------------------

// Principal Panel  Auto Criteria 01 PDF
import PDFprintA1_113 from "./Component/PrincipalPanel/PDFA01/PDFprintA1_113";
import PDFprintA1_112 from "./Component/PrincipalPanel/PDFA01/PDFprintA1_112";
import PDFprintA1_132 from "./Component/PrincipalPanel/PDFA01/PDFprintA1_132";
import PDFprintA1_134 from "./Component/PrincipalPanel/PDFA01/PDFprintA1_134";

//  Principal Panel  Autonomous Criteria 02 Sub Criteria PDF
import PDFprintA2_211 from "./Component/PrincipalPanel/PDFA02/PDFprintA2_211";
import PDFprintA2_212 from "./Component/PrincipalPanel/PDFA02/PDFprintA2_212";
import PDFprintA2_241 from "./Component/PrincipalPanel/PDFA02/PDFprintA2_241";
import PDFprintA2_242 from "./Component/PrincipalPanel/PDFA02/PDFprintA2_242";
import PDFprintA2_251 from "./Component/PrincipalPanel/PDFA02/PDFprintA2_251";
import PDFprintA2_263 from "./Component/PrincipalPanel/PDFA02/PDFprintA2_263";
import PDFprintA2_271 from "./Component/PrincipalPanel/PDFA02/PDFprintA2_271";

//  Principal Panel  Auto Criteria 03 Sub Criteria PDF
import PDFprintA3_312 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_312";
import PDFprintA3_313 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_313";
import PDFprintA3_321 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_321";
import PDFprintA3_332 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_332";
import PDFprintA3_343 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_343";
import PDFprintA3_344 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_344";
import PDFprintA3_363 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_363";
import PDFprintA3_351 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_351";
import PDFprintA3_362 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_362";
import PDFprintA3_371 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_371";
import PDFprintA3_372 from "./Component/PrincipalPanel/PDFA03/PDFprintA3_372";

// Principal Panel  Auto Criteria 06 Sub Criteria
import PDFprintA4_413 from "./Component/PrincipalPanel/PDFA04/PDFprintA4_413";
import PDFprintA4_414 from "./Component/PrincipalPanel/PDFA04/PDFprintA4_414";
import PDFprintA4_422 from "./Component/PrincipalPanel/PDFA04/PDFprintA4_422";

//  Principal Panel  Auto Criteria 05 Sub Criteria PDF
import PDFprintA5_511 from "./Component/PrincipalPanel/PDFA05/PDFprintA5_511";
import PDFprintA5_513 from "./Component/PrincipalPanel/PDFA05/PDFprintA5_513";
import PDFprintA5_514 from "./Component/PrincipalPanel/PDFA05/PDFprintA5_514";
import PDFprintA5_521 from "./Component/PrincipalPanel/PDFA05/PDFprintA5_521";
import PDFprintA5_522 from "./Component/PrincipalPanel/PDFA05/PDFprintA5_522";
import PDFprintA5_523 from "./Component/PrincipalPanel/PDFA05/PDFprintA5_523";
import PDFprintA5_531 from "./Component/PrincipalPanel/PDFA05/PDFprintA5_531";
import PDFprintA5_533 from "./Component/PrincipalPanel/PDFA05/PDFprintA5_533";

// Principal Panel  Auto Criteria 06 Sub Criteria PDF
import PDFprintA6_623 from "./Component/PrincipalPanel/PDFA06/PDFprintA6_623";
import PDFprintA6_632 from "./Component/PrincipalPanel/PDFA06/PDFprintA6_632";
import PDFprintA6_633 from "./Component/PrincipalPanel/PDFA06/PDFprintA6_633";
import PDFprintA6_634 from "./Component/PrincipalPanel/PDFA06/PDFprintA6_634";
import PDFprintA6_642 from "./Component/PrincipalPanel/PDFA06/PDFprintA6_642";
import PDFprintA6_653 from "./Component/PrincipalPanel/PDFA06/PDFprintA6_653";

// -------------------       PRINCIPAL PANEL Autonomous END       ----------------------

// Principal Panel  University Criteria 01 Sub Criteri1
import U_01_112 from "./Component/PrincipalPanel/U_01_Sub_Criteria/U_01_112";
import U_01_113 from "./Component/PrincipalPanel/U_01_Sub_Criteria/U_01_113";
import U_01_132 from "./Component/PrincipalPanel/U_01_Sub_Criteria/U_01_132";
import U_01_134 from "./Component/PrincipalPanel/U_01_Sub_Criteria/U_01_134";
import U_01_141 from "./Component/PrincipalPanel/U_01_Sub_Criteria/U_01_141";

// Principal Panel  University Criteria 02 Sub Criteri1
import U_02_211 from "./Component/PrincipalPanel/U_02_Sub_Criteria/U_02_211";
import U_02_212 from "./Component/PrincipalPanel/U_02_Sub_Criteria/U_02_212";
import U_02_242 from "./Component/PrincipalPanel/U_02_Sub_Criteria/U_02_242";
import U_02_241 from "./Component/PrincipalPanel/U_02_Sub_Criteria/U_02_241";
import U_02_244 from "./Component/PrincipalPanel/U_02_Sub_Criteria/U_02_244";
import U_02_251 from "./Component/PrincipalPanel/U_02_Sub_Criteria/U_02_251";
import U_02_263 from "./Component/PrincipalPanel/U_02_Sub_Criteria/U_02_263";
import U_02_271 from "./Component/PrincipalPanel/U_02_Sub_Criteria/U_02_271";

// Principal Panel  University Criteria 03 Sub Criteria
import Uni_03_312 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_312";
import Uni_03_313 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_313";
import Uni_03_314 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_314";
import Uni_03_316 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_316";
import Uni_03_332 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_332";
import Uni_03_333 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_333";
import Uni_03_343 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_343";
import Uni_03_344 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_344";
import Uni_03_345 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_345";
import Uni_03_346 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_346";
import Uni_03_347 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_347";
import Uni_03_352 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_352";
import Uni_03_362 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_362";
import Uni_03_363 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_363";
import Uni_03_371 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_371";
import Uni_03_372 from "./Component/PrincipalPanel/Uni_03_Sub_Criteria/Uni_03_372";

// Principal Panel  University Criteria 05 Sub Criteria
import U_05_511 from "./Component/PrincipalPanel/U_05_Sub_Criteria/U_05_511";
import U_05_513 from "./Component/PrincipalPanel/U_05_Sub_Criteria/U_05_513";
import U_05_512 from "./Component/PrincipalPanel/U_05_Sub_Criteria/U_05_512";
import U_05_521 from "./Component/PrincipalPanel/U_05_Sub_Criteria/U_05_521";
import U_05_522 from "./Component/PrincipalPanel/U_05_Sub_Criteria/U_05_522";
import U_05_523 from "./Component/PrincipalPanel/U_05_Sub_Criteria/U_05_523";
import U_05_531 from "./Component/PrincipalPanel/U_05_Sub_Criteria/U_05_531";
import U_05_533 from "./Component/PrincipalPanel/U_05_Sub_Criteria/U_05_533";

// Principal Panel  University Criteria 06 Sub Criteria
import U_06_623 from "./Component/PrincipalPanel/U_06_Sub_Criteria/U_06_623";
import U_06_632 from "./Component/PrincipalPanel/U_06_Sub_Criteria/U_06_632";
import U_06_633 from "./Component/PrincipalPanel/U_06_Sub_Criteria/U_06_633";
import U_06_634 from "./Component/PrincipalPanel/U_06_Sub_Criteria/U_06_634";
import U_06_642 from "./Component/PrincipalPanel/U_06_Sub_Criteria/U_06_642";
import U_06_652 from "./Component/PrincipalPanel/U_06_Sub_Criteria/U_06_652";

// -------------------  PRINCIPAL PANEL University PDF  ---------------------

// Principal Panel  University Criteria 01 PDF
import PDFprintU1_113 from "./Component/PrincipalPanel/PDFU01/PDFprintU1_113";
import PDFprintU1_112 from "./Component/PrincipalPanel/PDFU01/PDFprintU1_112";
import PDFprintU1_132 from "./Component/PrincipalPanel/PDFU01/PDFprintU1_132";
import PDFprintU1_134 from "./Component/PrincipalPanel/PDFU01/PDFprintU1_134";
import PDFprintU1_141 from "./Component/PrincipalPanel/PDFU01/PDFprintU1_141";

//  Principal Panel  UG Criteria 02 Sub Criteria PDF
import PDFprintU2_211 from "./Component/PrincipalPanel/PDFU02/PDFprintU2_211";
import PDFprintU2_212 from "./Component/PrincipalPanel/PDFU02/PDFprintU2_212";
import PDFprintU2_241 from "./Component/PrincipalPanel/PDFU02/PDFprintU2_241";
import PDFprintU2_242 from "./Component/PrincipalPanel/PDFU02/PDFprintU2_242";
import PDFprintU2_244 from "./Component/PrincipalPanel/PDFU02/PDFprintU2_244";
import PDFprintU2_251 from "./Component/PrincipalPanel/PDFU02/PDFprintU2_251";
import PDFprintU2_263 from "./Component/PrincipalPanel/PDFU02/PDFprintU2_263";
import PDFprintU2_271 from "./Component/PrincipalPanel/PDFU02/PDFprintU2_271";

// Principal Panel  University Criteria 03 Sub Criteria
import PdfPrintUN3_312 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_312";
import PdfPrintUN3_313 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_313";
import PdfPrintUN3_314 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_314";
import PdfPrintUN3_316 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_316";
import PdfPrintUN3_332 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_332";
import PdfPrintUN3_333 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_332";
import PdfPrintUN3_343 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_343";
import PdfPrintUN3_344 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_344";
import PdfPrintUN3_345 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_345";
import PdfPrintUN3_346 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_346";
import PdfPrintUN3_347 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_347";
import PdfPrintUN3_352 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_352";
import PdfPrintUN3_362 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_362";
import PdfPrintUN3_363 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_363";
import PdfPrintUN3_371 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_371";
import PdfPrintUN3_372 from "./Component/PrincipalPanel/PDFUNI03/PdfPrintUN3_372";

//  Principal Panel  University Criteria 05 Sub Criteria PDF
import PDFprintU5_511 from "./Component/PrincipalPanel/PDFU05/PDFprintU5_511";
import PDFprintU5_513 from "./Component/PrincipalPanel/PDFU05/PDFprintU5_513";
import PDFprintU5_512 from "./Component/PrincipalPanel/PDFU05/PDFprintU5_512";
import PDFprintU5_521 from "./Component/PrincipalPanel/PDFU05/PDFprintU5_521";
import PDFprintU5_522 from "./Component/PrincipalPanel/PDFU05/PDFprintU5_522";
import PDFprintU5_523 from "./Component/PrincipalPanel/PDFU05/PDFprintU5_523";
import PDFprintU5_531 from "./Component/PrincipalPanel/PDFU05/PDFprintU5_531";
import PDFprintU5_533 from "./Component/PrincipalPanel/PDFU05/PDFprintU5_533";

// Principal Panel  University Criteria 06 Sub Criteria PDF
import PDFprintU6_623 from "./Component/PrincipalPanel/PDFU06/PDFprintU6_623";
import PDFprintU6_632 from "./Component/PrincipalPanel/PDFU06/PDFprintU6_632";
import PDFprintU6_633 from "./Component/PrincipalPanel/PDFU06/PDFprintU6_633";
import PDFprintU6_634 from "./Component/PrincipalPanel/PDFU06/PDFprintU6_634";
import PDFprintU6_642 from "./Component/PrincipalPanel/PDFU06/PDFprintU6_642";
import PDFprintU6_653 from "./Component/PrincipalPanel/PDFU06/PDFprintU6_653";

// -------------------       PRINCIPAL PANEL University END       ----------------------

// Principal  panel criteria 07
import PDFprintA07 from "./Component/PrincipalPanel/PDFA07/PDFprintA07";
import PDFprintPG07 from "./Component/PrincipalPanel/PDFPG07/PDFprintPG07";
import PDFprintU07 from "./Component/PrincipalPanel/PDFU07/PDFprintU07";
import PDFprintUG07 from "./Component/PrincipalPanel/PDFUG07/PDFprintUG07";
import StudentDashboard from "./Component/StudentPanel/StudentDashboard";
import StudentSidebar from "./Component/StudentPanel/StudentSidebar";
import StudentSatSurvey from "./Component/StudentPanel/StudentSatSurvey";
import SyllabusFeedback from "./Component/StudentPanel/SyllabusFeedback";
import FacultyFeedback from "./Component/StudentPanel/FacultyFeedback";
import TeacherFeedback from "./Component/StudentPanel/TeacherFeedback";
import EmployerFeedback from "./Component/StudentPanel/PrincipalFeedback";
import AlumunsFeedback from "./Component/StudentPanel/AlumunsFeedback";
import StudentRegList from "./Component/FacultyPanel/StudentDetails/StudentRegList";
import StudentRegister from "./Component/FacultyPanel/StudentDetails/StudentRegister";
import StudentSatSurveyList from "./Component/StudentPanel/StudentSatSurveyList";
import SyllubusSurveyList from "./Component/StudentPanel/SyllubusSurveyList";
import TeacherFeedbackList from "./Component/StudentPanel/TeacherFeedbackList";
import AlumniFeefbackList from "./Component/StudentPanel/AlumniFeefbackList";
import FacultyfeedbackList from "./Component/StudentPanel/FacultyfeedbackList";
import PrincipalFeedbackList from "./Component/PrincipalPanel/PrincipalFeedbackList";
import PrincipalFeedback from "./Component/StudentPanel/PrincipalFeedback";
import FacultyFeedbackAdminpanel from "./Component/PrincipalPanel/FacultyFeedbackAdminpanel";
import AlumniFeedbackListAdminpanel from "./Component/PrincipalPanel/AlumniFeedbackListAdminpanel";
import SSSFeedbackListAdmin from "./Component/PrincipalPanel/SSSFeedbackListAdmin";
import SyllabusFeedbackListAdmin from "./Component/PrincipalPanel/SyllabusFeedbackListAdmin";
import TeacherFeedbackListAdmin from "./Component/PrincipalPanel/TeacherFeedbackListAdmin";
import FeedbackListForAdmin from "./Component/AdminPanel/FeedbackListForAdmin";
import UG_06_612 from "./Component/PrincipalPanel/UG_06_Sub_Criteria/UG_06_612";
import UG_06_611 from "./Component/PrincipalPanel/UG_06_Sub_Criteria/UG_06_611";
import PDFprintUG6_611 from "./Component/PrincipalPanel/PDFUG06/PDFprintUG6_611";
import PDFprintUG6_612 from "./Component/PrincipalPanel/PDFUG06/PDFprintUG6_612";
import PG_06_611 from "./Component/PrincipalPanel/PG_06_Sub_Criteria/PG_06_611";
import PG_06_612 from "./Component/PrincipalPanel/PG_06_Sub_Criteria/PG_06_612";
import IiqaMain from "./Component/PrincipalPanel/IiqaMain";
import Editiiqa from "./Component/PrincipalPanel/Editiiqa";
import AQARDetails from "./Component/PrincipalPanel/AQARDetails";
import IQACDetails from "./Component/PrincipalPanel/IQACDetails";
import SSRAddRepoprt from "./Component/PrincipalPanel/SSRAddRepoprt";
import SSRViewReport from "./Component/PrincipalPanel/SSRViewReport";
import AQARList from "./Component/PrincipalPanel/AQARList";
import { IQACList } from "./Component/PrincipalPanel/IQACList";
import SSRreportsList from "./Component/PrincipalPanel/SSRreportsList";
import FacultyFeedbackEdit from "./Component/StudentPanel/FacultyFeedbackEdit";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Google Form */}
          <Route
            path="/studentdashboard"
            exact
            element={
              <StudentSidebar
                children={
                  <>
                    <StudentDashboard />
                  </>
                }
              />
            }
          />
          <Route
            path="/studentsatsurvey"
            element={
              <StudentSidebar
                children={
                  <>
                    <StudentSatSurvey />
                  </>
                }
              />
            }
          />
          <Route
            path="/studentsatsurveylist"
            element={
              <StudentSidebar
                children={
                  <>
                    <StudentSatSurveyList />
                  </>
                }
              />
            }
          />
          <Route
            path="/syllabusfeedback"
            element={
              <StudentSidebar
                children={
                  <>
                    <SyllabusFeedback />
                  </>
                }
              />
            }
          />
          <Route
            path="/syllubussurveylist"
            element={
              <StudentSidebar
                children={
                  <>
                    <SyllubusSurveyList />
                  </>
                }
              />
            }
          />
          <Route
            path="/teacherfeedback"
            element={
              <StudentSidebar
                children={
                  <>
                    <TeacherFeedback />
                  </>
                }
              />
            }
          />
          <Route
            path="/teacherfeedbacklist"
            element={
              <StudentSidebar
                children={
                  <>
                    <TeacherFeedbackList />
                  </>
                }
              />
            }
          />
          <Route
            path="/alumunsfeedback"
            element={
              <StudentSidebar
                children={
                  <>
                    <AlumunsFeedback />
                  </>
                }
              />
            }
          />
          <Route
            path="/alumnifeefbacklist"
            element={
              <StudentSidebar
                children={
                  <>
                    <AlumniFeefbackList />
                  </>
                }
              />
            }
          />
          <Route
            path="/IiqaMain"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <IiqaMain />
                  </>
                }
              />
            }
          />
          <Route
            path="/finalreports"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <FinalReports />
                  </>
                }
              />
            }
          />
          <Route
            path="/iiqareport"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <IIQA />
                  </>
                }
              />
            }
          />
          <Route
            path="/Editiiqa"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Editiiqa />
                  </>
                }
              />
            }
          />
          {/* ADMIN COLLEGE  */}
          <Route path="/NaccadminLogin" element={<AdminClgLogin />} />
          <Route
            path="/adminclgugcri113/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG01_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri121/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG01_121 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri122/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG01_122 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri132/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG01_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri133/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG01_133 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri211/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG02_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri212/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG02_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri241/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG02_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri242/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG02_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri263/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG02_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri271/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG02_271 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminfinalreports"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <Adminfinalreports />
                  </>
                }
              />
            }
          />
          {/* <Route
            path="/finalreports"
            element={
              <CollegeDashboard
                children={
                  <>
                    <FinalReports />
                  </>
                }
              />
            }
          />  */}
          {/* UnderGraduate Criteria 03 */}
          <Route
            path="/adminclgugcri3313/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG03_313 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri3321/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG03_321 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri3322/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG03_322 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri3332/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG03_332 />
                  </>
                }
              />
            }
          />
          {/* ============ */}
          <Route
            path="/adminclgugcri1/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UnderGraduateCri1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri2/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UnderGraduateCri2 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri3/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UnderGraduateCri3 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri4/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UnderGraduateCri4 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgfaculty"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <DisplayFaculty />
                  </>
                }
              />
            }
          />
          <Route
            path="/clgdashboard"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <ClgDashboard />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri5/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UnderGraduateCri5 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri6/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UnderGraduateCri6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri7/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UnderGraduateCri7 />
                  </>
                }
              />
            }
          />
          {/*  Admin clg Post Graduate */}
          <Route
            path="/adminclgpgcri1/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <PostGraduateCri1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri2/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <PostGraduateCri2 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri3/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <PostGraduateCri3 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri4/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <PostGraduateCri4 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri5/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <PostGraduateCri5 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri6/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <PostGraduateCri6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri7/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <PostGraduateCri7 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri1113/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG01_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri1121/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG01_121 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri1122/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG01_122 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri1132/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG01_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri1133/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG01_133 />
                  </>
                }
              />
            }
          />
          {/* subcriteria pg 2  starts*/}
          <Route
            path="/adminclgpgcri2211/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG02_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri2212/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG02_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri2241/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG02_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri2242/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG02_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri2263/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG02_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri2271/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG02_271 />
                  </>
                }
              />
            }
          />
          {/* subcriteria pg 2  ends*/}
          {/* subcriteria pg 3  starts*/}
          <Route
            path="/adminclgpgcri3312/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG03_312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri3322/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG03_322 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri3332/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG03_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri3333/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG03_333 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri3342/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG03_342 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri3343/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG03_343 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri3351/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG03_351 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri3352/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG03_352 />
                  </>
                }
              />
            }
          />
          {/* subcriteria pg 3  ends*/}
          {/* subcriteria pg 4  starts*/}
          <Route
            path="/adminclgpgcri4413/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG04_413 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri4414/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG04_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri4422/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG04_422 />
                  </>
                }
              />
            }
          />
          {/* subcriteria pg 4  ends*/}
          {/* subcriteria pg 5 starts*/}
          <Route
            path="/adminclgpgcri5511/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG05_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri5513/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG05_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri5514/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG05_514 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri5521/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG05_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri5522/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG05_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri5523/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG05_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri5531/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG05_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri5533/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG05_533 />
                  </>
                }
              />
            }
          />
          {/* subcriteria pg 5 ends*/}
          {/* subcriteria 6 pg starts */}
          <Route
            path="/adminclgpgcri6623/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG06_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri6632/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG06_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri6633/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG06_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri6634/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG06_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri6642/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG06_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgpgcri6653/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgPG06_653 />
                  </>
                }
              />
            }
          />
          {/* sub criteria 6 pg ends */}
          {/*  Admin clg Autonomous starts */}
          <Route
            path="/adminclgautocri1/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AutonomusCri1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri2/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AutonomusCri2 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AutonomusCri3 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri4/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AutonomusCri4 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri5/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AutonomusCri5 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri6/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AutonomusCri6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri7/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AutonomusCri7 />
                  </>
                }
              />
            }
          />
          {/* autonomoust subcriteria 1 starts*/}
          <Route
            path="/adminclgautocri1112/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO01_112 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri1113/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO01_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri1132/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO01_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri1134/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO01_134 />
                  </>
                }
              />
            }
          />
          {/* autonomoust subcriteria 1 ends*/}
          {/* autonomoust subcriteria 2 starts*/}
          <Route
            path="/adminclgautocri2211/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO02_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri2212/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO02_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri2241/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO02_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri2242/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO02_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri2251/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO02_251 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri2263/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO02_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri2271/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO02_271 />
                  </>
                }
              />
            }
          />
          {/* autonomoust subcriteria 2 ends*/}
          {/* autonomous subcriteria 3 starts*/}
          <Route
            path="/adminclgautocri3312/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3313/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_313 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3321/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_321 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3332/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3343/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_343 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3344/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_344 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3351/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_351 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3362/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_362 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3363/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_363 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3371/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_371 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri3372/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO03_372 />
                  </>
                }
              />
            }
          />
          {/* autonomous subcriteria 3 ends*/}
          {/* autonomous subcriteria 4 starts*/}
          <Route
            path="/adminclgautocri4413/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO04_413 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri4414/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO04_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri4422/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO04_422 />
                  </>
                }
              />
            }
          />
          {/* autonomous subcriteria 4 ends*/}
          {/* autonomous subcriteria 5 starts*/}
          <Route
            path="/adminclgautocri5511/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO05_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri5513/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO05_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri5514/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO05_514 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri5521/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO05_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri5522/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO05_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri5523/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO05_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri5531/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO05_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri5533/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO05_533 />
                  </>
                }
              />
            }
          />
          {/* autonomous subcriteria 5 ends*/}
          {/* autonomous subcriteria 6 starts*/}
          <Route
            path="/adminclgautocri6623/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO06_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri6632/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO06_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri6633/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO06_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri6634/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO06_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri6642/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO06_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgautocri6653/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgAUTO06_653 />
                  </>
                }
              />
            }
          />
          {/* autonomous subcriteria 6 ends*/}
          {/*  Admin clg Autonomous ends */}
          {/*  Admin clg University starts */}
          <Route
            path="/adminclgunicri1/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UniversityCri1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri2/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UniversityCri2 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UniversityCri3 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri4/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UniversityCri4 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri5/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UniversityCri5 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri6/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UniversityCri6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri7/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <UniversityCri7 />
                  </>
                }
              />
            }
          />
          {/* University subcriteria 1 starts*/}
          <Route
            path="/adminclgunicri1112/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI01_112 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri1113/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI01_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri1132/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI01_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri1134/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI01_134 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri1141/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI01_141 />
                  </>
                }
              />
            }
          />
          {/* University subcriteria 1 ends*/}
          {/* University subcriteria 2 starts*/}
          <Route
            path="/adminclgunicri2211/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI02_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri2212/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI02_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri2241/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI02_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri2242/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI02_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri2244/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI02_244 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri2251/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI02_251 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri2263/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI02_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri2271/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI02_271 />
                  </>
                }
              />
            }
          />
          {/* University subcriteria 2 ends*/}
          {/* University subcriteria 3 starts*/}
          <Route
            path="/adminclgunicri3312/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3313/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_313 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3314/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_314 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3316/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_316 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3332/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3333/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_333 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3343/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_343 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3344/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_344 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3345/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_345 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3346/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_346 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3347/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_347 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3352/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_352 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3362/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_362 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3363/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_363 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3371/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_371 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri3372/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI03_372 />
                  </>
                }
              />
            }
          />
          {/* University subcriteria 3 ends*/}
          {/* University subcriteria 4 starts*/}
          <Route
            path="/adminclgunicri4431/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI04_431 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri4414/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI04_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri4422/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI04_422 />
                  </>
                }
              />
            }
          />
          {/* University subcriteria 4 ends*/}
          {/* University subcriteria 5 starts*/}
          <Route
            path="/adminclgunicri5511/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI05_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri5512/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI05_512 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri5513/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI05_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri5521/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI05_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri5522/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI05_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri5523/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI05_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri5531/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI05_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri5533/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI05_533 />
                  </>
                }
              />
            }
          />
          {/* University subcriteria 5 ends*/}
          {/* University subcriteria 6 starts*/}
          <Route
            path="/adminclgunicri6623/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI06_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri6632/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI06_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri6633/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI06_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri6634/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI06_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri6642/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI06_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgunicri6652/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUNI06_652 />
                  </>
                }
              />
            }
          />
          {/* Under Graduate  Criteria 03  */}
          <Route
            path="/adminclgugcri3312/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG03_312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri3333/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG03_333 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri3341/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG03_341 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri3342/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG03_342 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri4413/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG04_413 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri4414/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG04_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri4422/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG04_422 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri5511/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG05_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri5513/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG05_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri5514/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG05_514 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri5521/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG05_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri5522/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG05_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri5523/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG05_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri5531/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG05_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri5533/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG05_533 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri6623/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG06_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri6632/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG06_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri6633/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG06_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri6634/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG06_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri6642/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG06_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminclgugcri6653/:id"
            element={
              <AdminclgDashboard
                children={
                  <>
                    <AdminClgUG06_653 />
                  </>
                }
              />
            }
          />
          {/* =============================================== */}
          <Route
            path="/"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/about-us"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <About_us />
                <Footer />
              </>
            }
          />
          <Route
            path="/information"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Information />
                <Footer />
              </>
            }
          />
          <Route
            path="/courses"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <PopularCourse />
                <Footer />
              </>
            }
          />
          <Route
            path="/courses/:id"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <SingalCourse />
                <Footer />
              </>
            }
          />
          <Route
            path="/coursesingle"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <CourseSingle />
                <Footer />
              </>
            }
          />
          <Route
            path="/gallery"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Gallery />
                <Footer />
              </>
            }
          />
          <Route
            path="/aqar"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Aqar />
                <Footer />
              </>
            }
          />
          <Route
            path="/success"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Successpage />
              </>
            }
          />
          <Route
            path="/gallerypages"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Gallerypages />
                <Footer />
              </>
            }
          />
          <Route
            path="/contactus"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Contactus />
                <Footer />
              </>
            }
          />
          <Route
            path="/events"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Events />
                <Footer />
              </>
            }
          />
          <Route
            path="/eventspage"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Eventspage />
                <Footer />
              </>
            }
          />
          <Route
            path="/*"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Pagenotfound />
              </>
            }
          />
          <Route
            path="/blog"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Blog />
              </>
            }
          />
          <Route
            path="/studentform"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <StudentLogin />
              </>
            }
          />
          <Route
            path="/student-login"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <StudentloginR />
              </>
            }
          />
          <Route
            path="/faq"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Faq />
                <Footer />
              </>
            }
          />
          <Route
            path="/registerrorm"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <RegisterForm />
              </>
            }
          />
          <Route
            path="/resources"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Resources />
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Login />
              </>
            }
          />
          {/* <Route
            path="/student-login"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Login />
              </>
            }
          /> */}
          <Route
            path="/llogin"
            exact
            element={
              <>
                {/* <Header />
                <SubHeader /> */}
                <Llogin />
              </>
            }
          />
          <Route
            path="/assessment"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Assessment />
                <Footer />
              </>
            }
          />
          <Route
            path="/facultyform"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Rigester />
                <Footer />
              </>
            }
          />
          {/* AdminPanel */}
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/adminpanel" element={<AdminPanel />} />
          <Route
            exact
            path="/dashboard"
            element={<AdminPanel children={<Dashboard />} />}
          />
          <Route
            exact
            path="/addVideos"
            element={<AdminPanel children={<AddVideos />} />}
          />
          <Route
            exact
            path="/addlatestnews"
            element={<AdminPanel children={<AddLatestNews />} />}
          />
          <Route
            exact
            path="/addeducating"
            element={<AdminPanel children={<AddCommitedtoeducating />} />}
          />
          <Route
            exact
            path="/addvalueframework"
            element={<AdminPanel children={<Addvalueframework />} />}
          />
          <Route
            exact
            path="/addaboutus"
            element={<AdminPanel children={<AddAboutus />} />}
          />
          <Route
            exact
            path="/addleadership"
            element={<AdminPanel children={<AddLeadership />} />}
          />
          <Route
            exact
            path="/addcampus"
            element={<AdminPanel children={<AddCampus />} />}
          />
          <Route
            exact
            path="/addnaccoffice"
            element={<AdminPanel children={<AddNaccoffice />} />}
          />
          <Route
            exact
            path="/adminLibrary"
            element={<AdminPanel children={<AdminLibrary />} />}
          />
          <Route
            exact
            path="/adminPublications"
            element={<AdminPanel children={<AdminPublication />} />}
          />
          <Route
            exact
            path="/addaccrediation"
            element={<AdminPanel children={<AddAccrediation />} />}
          />
          <Route
            exact
            path="/accrediationAndAssessment"
            element={<AdminPanel children={<AccrediationAndAssessment />} />}
          />
          <Route
            exact
            path="/addslider"
            element={<AdminPanel children={<AddSlider />} />}
          />
          <Route
            exact
            path="/addannouncement"
            element={<AdminPanel children={<Addannouncement />} />}
          />
          <Route
            exact
            path="/manageclg"
            element={<AdminPanel children={<Adminclg />} />}
          />
          <Route
            exact
            path="/addYears"
            element={<AdminPanel children={<AddYears />} />}
          />
          <Route
            exact
            path="/feedbacklistforadmin"
            element={<AdminPanel children={<FeedbackListForAdmin />} />}
          />
          <Route
            exact
            path="/approvedclg"
            element={<AdminPanel children={<Approvedclg />} />}
          />
          <Route
            exact
            path="/newlyreg"
            element={<AdminPanel children={<Newlyreg />} />}
          />
          <Route
            exact
            path="/home"
            element={<AdminPanel children={<AHome />} />}
          />
          <Route
            exact
            path="/graphic"
            element={<AdminPanel children={<Graphic />} />}
          />
          <Route
            exact
            path="/description"
            element={<AdminPanel children={<Description />} />}
          />
          <Route
            exact
            path="/announcement"
            element={<AdminPanel children={<Announcement />} />}
          />
          <Route
            exact
            path="/notification"
            element={<AdminPanel children={<Notification />} />}
          />
          <Route
            exact
            path="/agallery"
            element={<AdminPanel children={<AGallery />} />}
          />
          <Route
            exact
            path="/course"
            element={<AdminPanel children={<Course />} />}
          />
          <Route
            exact
            path="/event"
            element={<AdminPanel children={<Event />} />}
          />
          <Route
            exact
            path="/admincontactus"
            element={<AdminPanel children={<Admincontactus />} />}
          />
          <Route
            exact
            path="/adminhelpuser"
            element={<AdminPanel children={<Adminhelpuser />} />}
          />
          <Route
            exacts
            path="/adminfaq"
            element={<AdminPanel children={<FAQ />} />}
          />
          <Route
            exact
            path="/seminar"
            element={<AdminPanel children={<Seminar />} />}
          />
          <Route
            exact
            path="/registeruser"
            element={<AdminPanel children={<RegisterUser />} />}
          />
          {/* Library Dashboard */}
          {/* Faculty */}
          {/* Library */}
          <Route
            path="/librarydashboard"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Librarydashboard1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/libraryprofile"
            element={
              <LibraryDashboard
                children={
                  <>
                    <LibraryProfile />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Auto_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <UG_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <UG_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <PG_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <PG_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Uni_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Uni_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Auto_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Auto_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/librarydashboard"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Librarydashboard1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/libraryprofile"
            element={
              <LibraryDashboard
                children={
                  <>
                    <LibraryProfile />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Auto_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <UG_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <PG_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <PG_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Uni_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Uni_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Auto_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Auto_Criteria004 />
                  </>
                }
              />
            }
          />
          {/* Library */}
          {/* Faculty */}
          {/* Library */}
          {/* <Route
            path="/librarydashboard"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Librarydashboard1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/libraryprofile"
            element={
              <LibraryDashboard
                children={
                  <>
                    <LibraryProfile />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Auto_Criteria04 />
                  </>
                }
              />
            }
          /> 
       
         <Route
            path="/ugcriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <UG_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <PG_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <PG_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Uni_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Uni_Criteria004 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria04"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Auto_Criteria04 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria004"
            element={
              <LibraryDashboard
                children={
                  <>
                    <Auto_Criteria004 />
                  </>
                }
              />
            }
          />  */}
          {/* College Admin */}
          {/* University subcriteria 6 ends*/}
          {/*  Admin clg University ends */}
          <Route
            path="/UGCri03312"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UGCri03312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri03313"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UGCri03313 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri03321"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UGCri03321 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri03322"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UGCri03322 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri03332"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UGCri03332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri03333"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UGCri03333 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri03341"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UGCri03341 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri03342"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UGCri03342 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG3_312"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG3_312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG3_313"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG3_313 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG3_321"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG3_321 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG3_322"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG3_322 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG3_332"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG3_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG3_333"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG3_333 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG3_341"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG3_341 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG3_342"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG3_342 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pdfPrintUG4_413"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG4_413 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pdfPrintUG4_414"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG4_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pdfPrintUG4_422"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG4_422 />
                  </>
                }
              />
            }
          />
          <Route
            path="/"
            element={
              <>
                <CollegeAdminLogin />
              </>
            }
          />
          <Route
            path="/collegeadmindashboard"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <CollegeAdmindashboard />
                  </>
                }
              />
            }
          />
          <Route
            path="/principalfeedbacklist"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PrincipalFeedbackList />
                  </>
                }
              />
            }
          />
          <Route
            path="/facultyfeedbackadminpanel"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <FacultyFeedbackAdminpanel />
                  </>
                }
              />
            }
          />
          <Route
            path="/alumnifeedbacklistadminpanel"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <AlumniFeedbackListAdminpanel />
                  </>
                }
              />
            }
          />
          <Route
            path="/sssfeedbacklistadmin"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <SSSFeedbackListAdmin />
                  </>
                }
              />
            }
          />
          <Route
            path="/syllabusfeedbacklistadmin"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <SyllabusFeedbackListAdmin />
                  </>
                }
              />
            }
          />
          <Route
            path="/teacherfeedbacklistadmin"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <TeacherFeedbackListAdmin />
                  </>
                }
              />
            }
          />
          <Route
            path="/principalfeedback"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PrincipalFeedback />
                  </>
                }
              />
            }
          />
          <Route
            path="/PrincipalProfile"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <AdminProfile />
                  </>
                }
              />
            }
          />
          <Route
            path="/change-password"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <ChangePassword />
                  </>
                }
              />
            }
          />
          <Route
            path="/addfaculty"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <AddFaculty />
                  </>
                }
              />
            }
          />
          {/* ======================================== */}
          <Route
            path="/criteria1"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/criteria2"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria2 />
                  </>
                }
              />
            }
          />
          <Route
            path="/criteria3"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria3 />
                  </>
                }
              />
            }
          />
          <Route
            path="/criteria4"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria4 />
                  </>
                }
              />
            }
          />
          <Route
            path="/criteria5"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria5 />
                  </>
                }
              />
            }
          />
          <Route
            path="/criteria6"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/criteria7"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria7 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pg-criteria1"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PgCriteria1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pg-criteria2"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PgCriteria2 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pg-criteria3"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PgCriteria3 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pg-criteria4"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PgCriteria4 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pg-criteria5"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PgCriteria5 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pg-criteria6"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PgCriteria6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pg-criteria7"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PgCriteria7 />
                  </>
                }
              />
            }
          />
          <Route
            path="/generate-aqar-report"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <CreateMergedReport />
                  </>
                }
              />
            }
          />
          <Route
            path="/generate-iqac-report"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <CreateMergedReportIQAC />
                  </>
                }
              />
            }
          />
          <Route
            path="/aqar-details"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <AQARDetails />
                  </>
                }
              />
            }
          />
          <Route
            path="/iqac-details"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <IQACDetails />
                  </>
                }
              />
            }
          />
          <Route
            path="/aqar-List"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <AQARList />
                  </>
                }
              />
            }
          />
          <Route
            path="/iqac-List"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <IQACList />
                  </>
                }
              />
            }
          />
          <Route
            path="/ssr-List"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <SSRreportsList />
                  </>
                }
              />
            }
          />
          <Route
            path="/aaraddrepoprt"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <SSRAddRepoprt />
                  </>
                }
              />
            }
          />
          <Route
            path="/ssrviewreport"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <SSRViewReport />
                  </>
                }
              />
            }
          />
          <Route
            path="/Ug-criteria1"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UgCriteria1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/Ug-criteria2"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UgCriteria2 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ug-criteria3"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UgCriteria3 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ug-criteria4"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UgCriteria4 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ug-criteria5"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UgCriteria5 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ug-criteria6"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UgCriteria6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ug-criteria7"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UgCriteria7 />
                  </>
                }
              />
            }
          />
          {/* university 01 started */}
          <Route
            path="/U-criteria1"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Ucriteria1 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_01_112"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_01_112 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_01_113"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_01_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_01_132"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_01_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_01_134"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_01_134 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_01_141"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_01_141 />
                  </>
                }
              />
            }
          />
          {/* Criteria 01 University PDF */}
          <Route
            path="/PDFprintU1_112"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU1_112 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU1_113"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU1_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU1_132"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU1_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU1_134"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU1_134 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU1_141"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU1_141 />
                  </>
                }
              />
            }
          />
          {/* university 01 finished */}
          <Route
            path="/U-criteria2"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Ucriteria2 />
                  </>
                }
              />
            }
          />
          <Route
            path="/u-criteria3"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Ucriteria3 />
                  </>
                }
              />
            }
          />
          <Route
            path="/u-criteria4"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Ucriteria4 />
                  </>
                }
              />
            }
          />
          <Route
            path="/u-criteria5"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Ucriteria5 />
                  </>
                }
              />
            }
          />
          <Route
            path="/u-criteria6"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Ucriteria6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/u-criteria7"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Ucriteria7 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria6"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PGcriteria6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria6"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UGcriteria6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/aucriteria6"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Aucriteria6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria6"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UnCriteria6 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pdfPrint"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprint />
                  </>
                }
              />
            }
          />
          <Route
            path="/pdfPrintUG1_121"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG1_121 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pdfPrintUG1_122"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG1_122 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pdfPrintUG1_132"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG1_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pdfPrintUG1_133"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG1_133 />
                  </>
                }
              />
            }
          />
          {/*  Criteria 02 UNDER GRADUATE */}
          <Route
            path="/CriteriaUG02_211"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <CriteriaUG02_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/CriteriaUG02_212"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <CriteriaUG02_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/CriteriaUG02_241"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <CriteriaUG02_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/CriteriaUG02_242"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <CriteriaUG02_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/CriteriaUG02_263"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <CriteriaUG02_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/CriteriaUG02_271"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <CriteriaUG02_271 />
                  </>
                }
              />
            }
          />
          {/* PDF */}
          <Route
            path="/PDFprintUG2_211"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG2_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG2_212"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG2_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG2_241"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG2_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG2_242"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG2_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG2_263"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG2_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG2_271"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG2_271 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri01113"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Cri01_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri01121"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria01_121 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri01122"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria01_122 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri01132"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria01_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri01133"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria01_133 />
                  </>
                }
              />
            }
          />
          {/* Library principal panel criteria - 04 */}
          <Route
            path="/UGCri04413"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria04_413 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri04414"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria04_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UGCri04422"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Criteria04_422 />
                  </>
                }
              />
            }
          />
          {/* Principal Panel UG Criteria 06 Sub Criteria  */}
          {/* <Route
            path="/UG_06_623"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_06_623 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_06_632"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_06_632 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_06_633"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_06_633 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_06_634"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_06_634 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_06_642"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_06_642 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_06_653"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_06_653 />
                  </>
                }
              />
            }
          /> */}
          {/* Criteria 06_623 PDF  */}*{" "}
          <Route
            path="/PDFprintUG6_623"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_632"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_633"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_634"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_642"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_653"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_653 />
                  </>
                }
              />
            }
          />
          {/* Principal Panel UG Criteria 05 Sub Criteria  */}
          {/* <Route
            path="/UG_05_511/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_05_511 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_05_513/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_05_513 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_05_514/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_05_514 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_05_521/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_05_521 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_05_522/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_05_522 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_05_523/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_05_523 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_05_531/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_05_531 />
                  </>
                }
              />
            }
          />

          <Route
            path="/UG_05_533/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <UG_05_533 />
                  </>
                }
              />
            }
          /> */}
          {/* Criteria 05 Under Graduate PDF  */}
          {/* <Route
            path="/PDFprintUG5_511"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintUG5_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG5_513"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintUG5_513 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PDFprintUG5_514"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintUG5_514 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PDFprintUG5_521"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintUG5_521 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PDFprintUG5_522"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintUG5_522 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PDFprintUG5_523"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintUG5_523 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PDFprintUG5_531"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintUG5_531 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PDFprintUG5_533"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintUG5_533 />
                  </>
                }
              />
            }
          /> */}
          {/* Principal Panel PG Criteria 01 Sub Criteria  */}
          {/* <Route
            path="/PG_01_113/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_01_113 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PG_01_121/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_01_121 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_01_122/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_01_122 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PG_01_132/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_01_132 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PG_01_133/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_01_133 />
                  </>
                }
              />
            }
          /> */}
          {/* Criteria 01 Post Graduate PDF  */}
          {/* <Route
            path="/PDFprintPG1_113"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintPG1_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG1_121"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintPG1_121 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PDFprintPG1_122"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintPG1_122 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PDFprintPG1_132"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintPG1_132 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PDFprintPG1_133"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PDFprintPG1_133 />
                  </>
                }
              />
            }
          /> */}
          {/* Criteria 02 Post Graduate   */}
          {/* <Route
            path="/PG_02_211/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_02_211 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PG_02_212/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_02_212 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PG_02_241/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_02_241 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PG_02_242/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_02_242 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PG_02_263/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_02_263 />
                  </>
                }
              />
            }
          />

          <Route
            path="/PG_02_271/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <PG_02_271 />
                  </>
                }
              />
            }
          /> */}
          {/* ================= Faculty Panel ===================== */}
          <Route
            path="/flogin"
            exact
            element={
              <>
                <Flogin />
              </>
            }
          />
          <Route
            path="/FacultyDashboard"
            element={
              <FacultyDashboard
                children={
                  <>
                    <FacultyAdmindashboard />
                  </>
                }
              />
            }
          />
          {/* ---StudentDetails---- */}
          <Route
            path="/studentdeglist"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <StudentRegList />
                  </>
                }
              />
            }
          />
          <Route
            path="/facultyfeedbacklist"
            element={
              <FacultyDashboard
                children={
                  <>
                    <FacultyfeedbackList />
                  </>
                }
              />
            }
          />
          <Route
            path="/facultyfeedback"
            element={
              <FacultyDashboard
                children={
                  <>
                    <FacultyFeedback />
                  </>
                }
              />
            }
          />
          <Route
            path="/editfacultyfeedback"
            element={
              <FacultyDashboard
                children={
                  <>
                    <FacultyFeedbackEdit />
                  </>
                }
              />
            }
          />
          <Route
            path="/studentregister"
            element={
              <>
                <Header />
                <SubHeader />
                <StudentRegister />
              </>
            }
          />
          {/* Faculty Profile */}
          <Route
            path="/FacultyProfile"
            element={
              <FacultyDashboard
                children={
                  <>
                    <FacultyPanelProfile />
                  </>
                }
              />
            }
          />
          {/* Under Graduate Main page */}
          <Route
            path="/ugcriteria01"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria01 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria02"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria02 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria03"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria03 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria05"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria05 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria07"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria07 />
                  </>
                }
              />
            }
          />
          {/* Under Graduate Add Criteria */}
          <Route
            path="/ugcriteria001"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria001 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria002"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria002 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria003"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria003 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria005"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria005 />
                  </>
                }
              />
            }
          />
          <Route
            path="/ugcriteria007"
            element={
              <FacultyDashboard
                children={
                  <>
                    <UG_Criteria007 />
                  </>
                }
              />
            }
          />
          {/* Post Graduate  Main page */}
          <Route
            path="/pgcriteria01"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria01 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria02"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria02 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria03"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria03 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria05"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria05 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria07"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria07 />
                  </>
                }
              />
            }
          />
          {/* Post Graduate Add Criteria */}
          <Route
            path="/pgcriteria001"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria001 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria002"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria002 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria003"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria003 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria005"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria005 />
                  </>
                }
              />
            }
          />
          <Route
            path="/pgcriteria007"
            element={
              <FacultyDashboard
                children={
                  <>
                    <PG_Criteria007 />
                  </>
                }
              />
            }
          />
          {/* University  Main page */}
          <Route
            path="/unicriteria01"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria01 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria02"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria02 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria03"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria03 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria05"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria05 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria07"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria07 />
                  </>
                }
              />
            }
          />
          {/*  University Add Criteria */}
          <Route
            path="/unicriteria001"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria001 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria002"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria002 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria003"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria003 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria005"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria005 />
                  </>
                }
              />
            }
          />
          <Route
            path="/unicriteria007"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Uni_Criteria007 />
                  </>
                }
              />
            }
          />
          {/*  Autonomous Main Page */}
          <Route
            path="/autocriteria01"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria01 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria02"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria02 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria03"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria03 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria05"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria05 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria07"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria07 />
                  </>
                }
              />
            }
          />
          {/*  University Add Criteria */}
          <Route
            path="/autocriteria001"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria001 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria002"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria002 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria003"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria003 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria005"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria005 />
                  </>
                }
              />
            }
          />
          <Route
            path="/autocriteria007"
            element={
              <FacultyDashboard
                children={
                  <>
                    <Auto_Criteria007 />
                  </>
                }
              />
            }
          />
          {/* principal panel - 31/8/23 */}
          {/* Principal Panel UG Criteria 06 Sub Criteria */}
          <Route
            path="/UG_06_611"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_06_611 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_06_612"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_06_612 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_06_623"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_06_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_06_632"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_06_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_06_633"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_06_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_06_634"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_06_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_06_642"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_06_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_06_653"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_06_653 />
                  </>
                }
              />
            }
          />
          {/* Criteria 06_623 PDF */}
          <Route
            path="/PDFprintUG6_611"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_611 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_612"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_612 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_623"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_632"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_633"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_634"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_642"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG6_653"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG6_653 />
                  </>
                }
              />
            }
          />
          {/* Principal Panel UG Criteria 05 Sub Criteria */}
          <Route
            path="/UG_05_511"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_05_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_05_513"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_05_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_05_514"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_05_514 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_05_521"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_05_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_05_522"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_05_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_05_523"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_05_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_05_531"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_05_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/UG_05_533"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <UG_05_533 />
                  </>
                }
              />
            }
          />
          {/* Criteria 05 Under Graduate PDF */}
          <Route
            path="/PDFprintUG5_511"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG5_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG5_513"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG5_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG5_514"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG5_514 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG5_521"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG5_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG5_522"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG5_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG5_523"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG5_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG5_531"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG5_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG5_533"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG5_533 />
                  </>
                }
              />
            }
          />
          {/* Principal Panel PG Criteria 01 Sub Criteria */}
          <Route
            path="/PG_01_113"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_01_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_01_121"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_01_121 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_01_122"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_01_122 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_01_132"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_01_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_01_133"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_01_133 />
                  </>
                }
              />
            }
          />
          {/* Criteria 01 Post Graduate PDF */}
          <Route
            path="/PDFprintPG1_113"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG1_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG1_121"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG1_121 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG1_122"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG1_122 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG1_132"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG1_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG1_133"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG1_133 />
                  </>
                }
              />
            }
          />
          {/* Criteria 02 Post Graduate */}
          <Route
            path="/PG_02_211"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_02_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_02_212"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_02_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_02_241"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_02_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_02_242"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_02_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_02_263"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_02_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_02_271"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_02_271 />
                  </>
                }
              />
            }
          />
          {/* Criteria 02 Post Graduate PDF */}
          <Route
            path="/PDFprintPG2_211"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG2_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG2_212"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG2_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG2_241"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG2_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG2_242"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG2_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG2_263"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG2_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG2_271"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG2_271 />
                  </>
                }
              />
            }
          />
          {/* Criteria 06 Post Graduate */}
          <Route
            path="/PG_06_611"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_06_611 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_06_612"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_06_612 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_06_623"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_06_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_06_632"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_06_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_06_633"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_06_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_06_634"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_06_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_06_642"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_06_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_06_653"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_06_653 />
                  </>
                }
              />
            }
          />
          {/* Criteria 03 Post Graduate */}
          <Route
            path="/PG_03_311"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_03_311 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_03_322"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_03_322 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_03_332"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_03_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_03_333"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_03_333 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_03_342"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_03_342 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_03_343"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_03_343 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_03_351"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_03_351 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_03_352"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_03_352 />
                  </>
                }
              />
            }
          />
          {/* Criteria 03 Post Graduate PDF */}
          <Route
            path="/PDFprintPG3_311"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG3_311 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG3_322"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG3_322 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG3_332"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG3_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG3_333"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG3_333 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG3_342"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG3_342 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG3_343"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG3_343 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG3_351"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG3_351 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG3_352"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG3_352 />
                  </>
                }
              />
            }
          />
          {/* Criteria 05 Post Graduate */}
          <Route
            path="/PG_05_511"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_05_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_05_513"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_05_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_05_514"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_05_514 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_05_521"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_05_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_05_522"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_05_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_05_523"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_05_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_05_531"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_05_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_05_533"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_05_533 />
                  </>
                }
              />
            }
          />
          {/* Criteria 05 Post Graduate PDF */}
          <Route
            path="/PDFprintPG5_511"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG5_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG5_513"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG5_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG5_514"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG5_514 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG5_521"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG5_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG5_522"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG5_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG5_523"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG5_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG5_531"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG5_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG5_533"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG5_533 />
                  </>
                }
              />
            }
          />
          {/* Criteria 01 Autonomous */}
          <Route
            path="/A_01_112"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_01_112 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_01_113"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_01_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_01_132"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_01_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_01_134"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_01_134 />
                  </>
                }
              />
            }
          />
          {/* <Route
            path="/A_01_141/:id"
            element={
              <CollegeDashboard
                children={
                  <>
                    <A_01_141 />
                  </>
                }
              />
            }
          /> */}
          {/* Criteria 01 Autonomous PDF */}
          <Route
            path="/PDFprintA1_112"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA1_112 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA1_113"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA1_113 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA1_132"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA1_132 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA1_134"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA1_134 />
                  </>
                }
              />
            }
          />
          {/* Criteria 03 Autonomous */}
          <Route
            path="/A_03_312"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_313"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_313 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_321"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_321 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_332"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_343"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_343 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_344"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_344 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_351"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_351 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_362"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_362 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_363"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_363 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_371"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_371 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_03_372"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_03_372 />
                  </>
                }
              />
            }
          />
          {/* Criteria 03 Autonomous PDF */}
          <Route
            path="/PDFprintA3_312"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_313"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_313 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_321"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_321 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_332"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_343"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_343 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_344"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_344 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_351"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_351 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_362"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_362 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_363"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_363 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_371"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_371 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA3_372"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA3_372 />
                  </>
                }
              />
            }
          />
          {/* Criteria 04 Autonomous */}
          <Route
            path="/A_04_413"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_04_413 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_04_414"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_04_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_04_422"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_04_422 />
                  </>
                }
              />
            }
          />
          {/* Criteria 05 Autonomous */}
          <Route
            path="/A_05_511"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_05_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_05_513"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_05_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_05_514"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_05_514 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_05_521"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_05_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_05_522"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_05_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_05_523"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_05_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_05_531"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_05_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_05_533"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_05_533 />
                  </>
                }
              />
            }
          />
          {/* Criteria 05 Auto PDF */}
          <Route
            path="/PDFprintA5_511"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA5_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA5_513"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA5_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA5_514"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA5_514 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA5_521"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA5_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA5_522"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA5_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA5_523"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA5_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA5_531"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA5_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA5_533"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA5_533 />
                  </>
                }
              />
            }
          />
          {/* Criteria 06 Autonomous */}
          <Route
            path="/A_06_623"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_06_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_06_632"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_06_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_06_633"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_06_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_06_634"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_06_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_06_642"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_06_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_06_653"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_06_653 />
                  </>
                }
              />
            }
          />
          {/* Criteria 06 Auto PDF */}
          <Route
            path="/PDFprintA6_623"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA6_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA6_632"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA6_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA6_633"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA6_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA6_634"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA6_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA6_642"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA6_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA6_653"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA6_653 />
                  </>
                }
              />
            }
          />
          {/* Criteria 06 University PDF */}
          <Route
            path="/PDFprintU6_623"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU6_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU6_632"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU6_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU6_633"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU6_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU6_634"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU6_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU6_642"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU6_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU6_653"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU6_653 />
                  </>
                }
              />
            }
          />
          {/* Criteria 02 Autonomous */}
          <Route
            path="/A_02_211"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_02_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_02_212"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_02_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_02_241"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_02_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_02_242"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_02_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_02_251"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_02_251 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_02_263"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_02_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/A_02_271"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <A_02_271 />
                  </>
                }
              />
            }
          />
          {/* Criteria 02 Post Graduate PDF */}
          <Route
            path="/PDFprintA2_211"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA2_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA2_212"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA2_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA2_241"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA2_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA2_242"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA2_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA2_251"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA2_251 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA2_263"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA2_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA2_271"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA2_271 />
                  </>
                }
              />
            }
          />
          {/* Criteria 05 University */}
          <Route
            path="/U_05_511"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_05_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_05_512"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_05_512 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_05_513"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_05_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_05_521"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_05_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_05_522"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_05_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_05_523"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_05_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_05_531"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_05_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_05_533"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_05_533 />
                  </>
                }
              />
            }
          />
          {/* Criteria 05 University PDF */}
          <Route
            path="/PDFprintU5_511"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU5_511 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU5_512"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU5_512 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU5_513"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU5_513 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU5_521"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU5_521 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU5_522"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU5_522 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU5_523"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU5_523 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU5_531"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU5_531 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU5_533"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU5_533 />
                  </>
                }
              />
            }
          />
          {/* Criteria 02 Autonomous */}
          <Route
            path="/U_02_211"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_02_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_02_212"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_02_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_02_241"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_02_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_02_242"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_02_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_02_244"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_02_244 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_02_251"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_02_251 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_02_263"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_02_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_02_271"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_02_271 />
                  </>
                }
              />
            }
          />
          {/* Criteria 02 University PDF */}
          <Route
            path="/PDFprintU2_211"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU2_211 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU2_212"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU2_212 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU2_241"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU2_241 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU2_242"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU2_242 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU2_244"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU2_244 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU2_251"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU2_251 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU2_263"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU2_263 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU2_271"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU2_271 />
                  </>
                }
              />
            }
          />
          {/* criteria3 */}
          <Route
            path="/Uni_03_312"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Uni_03_312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/Uni_03_313"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Uni_03_313 />
                  </>
                }
              />
            }
          />
          <Route
            path="/Uni_03_314"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Uni_03_314 />
                  </>
                }
              />
            }
          />
          <Route
            path="/Uni_03_316"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Uni_03_316 />
                  </>
                }
              />
            }
          />
          <Route
            path="/Uni_03_332"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Uni_03_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/Uni_03_333"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Uni_03_333 />
                  </>
                }
              />
            }
          />
          <Route
            path="/Uni_03_343"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Uni_03_343 />
                  </>
                }
              />
            }
          />
          <Route
            path="/Uni_03_344"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <Uni_03_344 />
                  </>
                }
              />
            }
          />
          <Route
            path="/Uni_03_345"
            element={<SideBarPrincipal children={<Uni_03_345 />} />}
          />
          <Route
            path="/Uni_03_346"
            element={<SideBarPrincipal children={<Uni_03_346 />} />}
          />
          <Route
            path="/Uni_03_347"
            element={<SideBarPrincipal children={<Uni_03_347 />} />}
          />
          <Route
            path="/Uni_03_352"
            element={<SideBarPrincipal children={<Uni_03_352 />} />}
          />
          <Route
            path="/Uni_03_362"
            element={<SideBarPrincipal children={<Uni_03_362 />} />}
          />
          <Route
            path="/Uni_03_363"
            element={<SideBarPrincipal children={<Uni_03_363 />} />}
          />
          <Route
            path="/Uni_03_371"
            element={<SideBarPrincipal children={<Uni_03_371 />} />}
          />
          <Route
            path="/Uni_03_372"
            element={<SideBarPrincipal children={<Uni_03_372 />} />}
          />
          {/* Criteria 06 University  */}
          <Route
            path="/U_06_623"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_06_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_06_632"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_06_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_06_633"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_06_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_06_634"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_06_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_06_642"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_06_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/U_06_653"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <U_06_652 />
                  </>
                }
              />
            }
          />
          {/* vvvv */}
          {/* Criteria 04 Postgraduate  */}
          <Route
            path="/PG_04_413"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_04_413 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_04_414"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_04_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PG_04_422"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PG_04_422 />
                  </>
                }
              />
            }
          />
          {/* Criteria 04 Post graduate PDF  */}
          <Route
            path="/PDFprintPG4_413"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG4_413 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG4_414"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG4_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG4_422"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG4_422 />
                  </>
                }
              />
            }
          />
          {/* Criteria 04 Autonomous PDF  */}
          <Route
            path="/PDFprintA4_413"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA4_413 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA4_414"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA4_414 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintA4_422"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA4_422 />
                  </>
                }
              />
            }
          />
          {/* Criteria 06 Post Graduate PDF  */}
          <Route
            path="/PDFprintPG6_623"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG6_623 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG6_632"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG6_632 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG6_633"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG6_633 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG6_634"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG6_634 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG6_642"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG6_642 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG6_653"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG6_653 />
                  </>
                }
              />
            }
          />
          {/* Criteria 03 Post Graduate PDF  */}
          <Route
            path="/PdfPrintUN3_312"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_312 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_313"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_313 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_314"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_314 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_316"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_316 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_332"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_332 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_333"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_333 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_343"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_343 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_344"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_344 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_345"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_345 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_346"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_346 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_347"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_347 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_352"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_352 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_362"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_362 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_363"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_363 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_371"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_371 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PdfPrintUN3_372"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PdfPrintUN3_371 />
                  </>
                }
              />
            }
          />
          {/* 07 Criteria Pdf */}
          <Route
            path="/PDFprintA07"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintA07 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintPG07"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintPG07 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintU07"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintU07 />
                  </>
                }
              />
            }
          />
          <Route
            path="/PDFprintUG07"
            element={
              <SideBarPrincipal
                children={
                  <>
                    <PDFprintUG07 />
                  </>
                }
              />
            }
          />
          <Route
            path="/collegelist"
            exact
            element={
              <>
                <Header />
                <SubHeader />
                <Collegeslist />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
