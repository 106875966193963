import React from "react";

const UG2212aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <h6>
        Number of seats earmarked for reserved category as per GOI or State
        Government rule
      </h6>
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>SC</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria02_212?.SC}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>ST</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria02_212?.ST}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>OBC</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria02_212?.OBC}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Gen</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_212?.Gen}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Others</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_212?.Others}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Divyangjan</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_212?.Divyangjan}</span>
        </div>
      </div>

      <h6>Number of students admitted from the reserved category</h6>
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>SC</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria02_212?.SSC}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>ST</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria02_212?.SST}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>OBC</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria02_212?.OOBC}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Gen</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_212?.GGen}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Others</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_212?.OOthers}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Divyangjan</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_212?.DDivyangjan}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria02_212?.Desc7}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria02/${item?.Criteria02_212?.Link7}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG2212aqar;
