import React from "react";

const UG6633aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (from)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_633?.Sdate}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (to)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_633?.Edate}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Title of the professional development program organised for teaching
            staff
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_633?.title_dev}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Title of the administrative training program organised for
            non-teaching staff
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_633?.titlep}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> No. of participants</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_633?.Nfp}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.UgCriteria06_633?.ugdesc3}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.UgCriteria06_633?.doc6}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG6633aqar;
