import React from "react";
// import "./Styles/Blog.css";
import { Link } from "react-router-dom";
const Blog = () => {
  return (
    <div className="blog">
      <div
        className="ab-0"
        style={{
          backgroundImage: "url('../Image/about-us.jpg')",
          height: "250px",
          position: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="edg container">
          <div className="edgt-has-inline-style">Blog</div>
          <div class="edgt-breadcrumbs-info">
            <div
              itemProp="breadcrumb"
              classname="edgt-breadcrumbs "
              style={{ color: "#e1e0e0" }}
            >
              <a
                itemProp="url"
                href="#"
                style={{ color: "#e1e0e0", textDecoration: "none" }}
              >
                Home
              </a>
              <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
              <span classname="edgt-current">Blog</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container ">
        <div className="row mt-5">
          <div className="col-lg-8">
            <img src="../gallery/train1.jpg" className="img-fluid mb-4" />
            <div className="h3 ">Studying to accomplish the greatest goals</div>
            <div>
                <Link> July 17, 2017 18   </Link>
                <Link> 3  </Link>
                <Link> Education  </Link>
               
            </div>

           Lorem Ipsn gravida nibh vel velit
            auctor aliquet.Aenean sollicitudin, lorem quis bibendum auci elit
            consequat ipsutis sem nibh id elit. Duis sed odio sit amet nibh
            vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam
            nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris
            vitae erat consequat auctor eu in elit. Class aptent taciti
            sociosquad litora torquent per conubia nostra, per inceptos
            himenaeos. Mauris in erat justo. Nullam ac urna eu felis dapibus
            condimentum sit amet a augue.
          </div>

          <div className="col-lg-4"></div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
