import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Auto_Criteria002 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  //post method for aut0_criteria_02
  const [Auto_Year, setAuto_Year] = useState("");

  const [Auto_PrgCode, setAuto_PrgCode] = useState("");
  const [Auto_NoSeatSanctioned, setAuto_NoSeatSanctioned] = useState("");
  const [Auto_NoStdAdmit, setAuto_NoStdAdmit] = useState("");
  const [Auto_PrgName1, setAuto_PrgName1] = useState("");
  const [Auto_Desc32, setAuto_Desc32] = useState("");
  const [Auto_Link32, setAuto_Link32] = useState("");

  const [Auto_SC, setAuto_SC] = useState("");
  const [Auto_ST, setAuto_ST] = useState("");
  const [Auto_OBC, setAuto_OBC] = useState("");
  const [Auto_Gen, setAuto_Gen] = useState("");
  const [Auto_Divyangjan, setAuto_Divyangjan] = useState("");
  const [Auto_Others, setAuto_Others] = useState("");
  const [Auto_SSC, setAuto_SSC] = useState("");
  const [Auto_SST, setAuto_SST] = useState("");
  const [Auto_OOBC, setAuto_OOBC] = useState("");
  const [Auto_GGen, setAuto_GGen] = useState("");
  const [Auto_DDivyangjan, setAuto_DDivyangjan] = useState("");
  const [Auto_OOthers, setAuto_OOthers] = useState("");
  const [Auto_Desc33, setAuto_Desc33] = useState("");
  const [Auto_Link33, setAuto_Link33] = useState("");

  const [Auto_TName, setAuto_TName] = useState("");
  const [Auto_PAN, setAuto_PAN] = useState("");
  const [Auto_Designation, setAuto_Designation] = useState("");
  const [Auto_YOfAppoint, setAuto_YOfAppoint] = useState("");
  const [Auto_NoOfAppoint, setAuto_NoOfAppoint] = useState("");
  const [Auto_DeptName, setAuto_DeptName] = useState("");
  const [Auto_TYofExp, setAuto_TYofExp] = useState("");
  const [Auto_TService, setAuto_TService] = useState("");
  const [Auto_Desc34, setAuto_Desc34] = useState("");
  const [Auto_Link34, setAuto_Link34] = useState("");

  const [Auto_SerTeacher, setAuto_SerTeacher] = useState("");
  const [Auto_Qualify, setAuto_Qualify] = useState("");
  const [Auto_YOfQuali, setAuto_YOfQuali] = useState("");
  const [Auto_YOfRecog, setAuto_YOfRecog] = useState("");
  const [Auto_Whether, setAuto_Whether] = useState("");
  const [Auto_IsThereTeacher, setAuto_IsThereTeacher] = useState("");
  const [Auto_NameScholar, setAuto_NameScholar] = useState("");
  const [Auto_YearScholar, setAuto_YearScholar] = useState("");
  const [Auto_Desc35, setAuto_Desc35] = useState("");
  const [Auto_Link35, setAuto_Link35] = useState("");

  const [Auto_pname1, setAuto_pname1] = useState("");
  const [Auto_pcode1, setAuto_pcode1] = useState("");
  const [Auto_semester, setAuto_semester] = useState("");
  const [Auto_last, setAuto_last] = useState("");
  const [Auto_Date, setAuto_Date] = useState("");
  const [Auto_Desc36, setAuto_Desc36] = useState("");
  const [Auto_Link36, setAuto_Link36] = useState("");

  const [Auto_pCode, setAuto_pCode] = useState("");
  const [Auto_PrgName2, setAuto_PrgName2] = useState("");
  const [Auto_NStdAppeared, setAuto_NStdAppeared] = useState("");
  const [Auto_NStdPassed, setAuto_NStdPassed] = useState("");
  const [Auto_Desc37, setAuto_Desc37] = useState("");
  const [Auto_Link37, setAuto_Link37] = useState("");

  const [Auto_PrgName3, setAuto_PrgName3] = useState("");
  const [Auto_NStd, setAuto_NStd] = useState("");
  const [Auto_Gender, setAuto_Gender] = useState("");
  const [Auto_Category, setAuto_Category] = useState("");
  const [Auto_SDomicile, setAuto_SDomicile] = useState("");
  const [Auto_NIndian, setAuto_NIndian] = useState("");
  const [Auto_Email, setAuto_Email] = useState("");
  const [Auto_MNo, setAuto_MNo] = useState("");
  const [Auto_YJoining, setAuto_YJoining] = useState("");
  const [Auto_UID, setAuto_UID] = useState("");
  const [Auto_Desc38, setAuto_Desc38] = useState("");
  const [Auto_Link38, setAuto_Link38] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getautocriteria2 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getautocriteria2();
  }, []);

  const autocriteria2 = async () => {
    if (!Auto_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/autocriteria02",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Year: Auto_Year,

          Auto_Criteria02_211: obj1,
          Auto_Link32: Auto_Link32,

          Auto_Criteria02_212: obj2,
          Auto_Link33: Auto_Link33,

          Auto_Criteria02_241: obj3,
          Auto_Link34: Auto_Link34,

          Auto_Criteria02_242: obj4,
          Auto_Link35: Auto_Link35,

          Auto_Criteria02_263: obj5,
          Auto_Link37: Auto_Link37,

          Auto_Criteria02_271: obj6,
          Auto_Link38: Auto_Link38,

          Auto_Criteria02_251: obj7,
          Auto_Link36: Auto_Link36,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          navigate("/autocriteria02");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc32;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Auto_PrgName1 ||
          !Auto_PrgCode ||
          !Auto_NoSeatSanctioned ||
          !Auto_NoStdAdmit ||
          !Auto_Desc32 ||
          !Auto_Link32
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Auto_Link32);
      let obj = {
        Auto_criteria: "2.1.1",
        Auto_PrgName1: Auto_PrgName1,
        Auto_PrgCode: Auto_PrgCode,
        Auto_NoSeatSanctioned: Auto_NoSeatSanctioned,
        Auto_NoStdAdmit: Auto_NoStdAdmit,
        Auto_Desc32: Auto_Desc32,
        Auto_Link32: Auto_Link32,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getautocriteria2();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc33;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !Auto_SC ||
          !Auto_ST ||
          !Auto_OBC ||
          !Auto_Gen ||
          !Auto_Divyangjan ||
          !Auto_Others ||
          !Auto_SSC ||
          !Auto_SST ||
          !Auto_OOBC ||
          !Auto_GGen ||
          !Auto_DDivyangjan ||
          !Auto_OOthers ||
          !Auto_Desc33 ||
          !Auto_Link33
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Auto_Link33);
      let obj = {
        Auto_criteria: "2.1.2",
        Auto_SC: Auto_SC,
        Auto_ST: Auto_ST,
        Auto_OBC: Auto_OBC,
        Auto_Gen: Auto_Gen,
        Auto_Divyangjan: Auto_Divyangjan,
        Auto_Others: Auto_Others,
        Auto_SSC: Auto_SSC,
        Auto_SST: Auto_SST,
        Auto_OOBC: Auto_OOBC,
        Auto_GGen: Auto_GGen,
        Auto_DDivyangjan: Auto_DDivyangjan,
        Auto_OOthers: Auto_OOthers,
        Auto_Desc33: Auto_Desc33,
        Auto_Link33: Auto_Link33,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getautocriteria2();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc34;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !Auto_TName ||
          !Auto_PAN ||
          !Auto_Designation ||
          !Auto_YOfAppoint ||
          !Auto_NoOfAppoint ||
          !Auto_DeptName ||
          !Auto_TYofExp ||
          !Auto_TService ||
          !Auto_Desc34 ||
          !Auto_Link34
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Auto_Link34);
      let obj = {
        Auto_criteria: "2.4.1",
        Auto_TName: Auto_TName,
        Auto_PAN: Auto_PAN,
        Auto_Designation: Auto_Designation,
        Auto_YOfAppoint: Auto_YOfAppoint,
        Auto_NoOfAppoint: Auto_NoOfAppoint,
        Auto_DeptName: Auto_DeptName,
        Auto_TYofExp: Auto_TYofExp,
        Auto_TService: Auto_TService,
        Auto_Desc34: Auto_Desc34,
        Auto_Link34: Auto_Link34,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getautocriteria2();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc35;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Auto_SerTeacher ||
          !Auto_Qualify ||
          !Auto_YOfQuali ||
          !Auto_YOfRecog ||
          !Auto_Whether ||
          !Auto_IsThereTeacher ||
          !Auto_NameScholar ||
          !Auto_YearScholar ||
          !Auto_Desc35 ||
          !Auto_Link35
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Auto_Link35);
      let obj = {
        Auto_criteria: "2.4.2",
        Auto_SerTeacher: Auto_SerTeacher,
        Auto_Qualify: Auto_Qualify,
        Auto_YOfQuali: Auto_YOfQuali,
        Auto_YOfRecog: Auto_YOfRecog,
        Auto_Whether: Auto_Whether,
        Auto_IsThereTeacher: Auto_IsThereTeacher,
        Auto_NameScholar: Auto_NameScholar,
        Auto_YearScholar: Auto_YearScholar,
        Auto_Desc35: Auto_Desc35,
        Auto_Link35: Auto_Link35,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getautocriteria2();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc37;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !Auto_pCode ||
          !Auto_PrgName2 ||
          !Auto_NStdAppeared ||
          !Auto_NStdPassed ||
          !Auto_Desc37 ||
          !Auto_Link37
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("qqqq,", Auto_Link37);
      let obj = {
        Auto_criteria: "2.6.3",
        Auto_pCode: Auto_pCode,
        Auto_PrgName2: Auto_PrgName2,
        Auto_NStdAppeared: Auto_NStdAppeared,
        Auto_NStdPassed: Auto_NStdPassed,
        Auto_Desc37: Auto_Desc37,
        Auto_Link37: Auto_Link37,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getautocriteria2();
      setadd5(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc38;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !Auto_PrgName3 ||
          !Auto_NStd ||
          !Auto_Gender ||
          !Auto_Category ||
          !Auto_SDomicile ||
          !Auto_NIndian ||
          !Auto_Email ||
          !Auto_MNo ||
          !Auto_YJoining ||
          !Auto_UID ||
          !Auto_Desc38 ||
          !Auto_Link38
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("rrr,", Auto_Link38);
      let obj = {
        Auto_criteria: "2.7.1",
        Auto_PrgName3: Auto_PrgName3,
        Auto_NStd: Auto_NStd,
        Auto_Gender: Auto_Gender,
        Auto_Category: Auto_Category,
        Auto_SDomicile: Auto_SDomicile,
        Auto_NIndian: Auto_NIndian,
        Auto_Email: Auto_Email,
        Auto_MNo: Auto_MNo,
        Auto_YJoining: Auto_YJoining,
        Auto_UID: Auto_UID,
        Auto_Desc38: Auto_Desc38,
        Auto_Link38: Auto_Link38,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getautocriteria2();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc36;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !Auto_pname1 ||
          !Auto_pcode1 ||
          !Auto_semester ||
          !Auto_last ||
          !Auto_Date ||
          !Auto_Desc36 ||
          !Auto_Link36
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd7(false);
        }
      }

      console.log("sss,", Auto_Link36);
      let obj = {
        Auto_criteria: "2.5.1",
        Auto_pname1: Auto_pname1,
        Auto_pcode1: Auto_pcode1,
        Auto_semester: Auto_semester,
        Auto_last: Auto_last,
        Auto_Date: Auto_Date,
        Auto_Desc36: Auto_Desc36,
        Auto_Link36: Auto_Link36,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getautocriteria2();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            Autonomous_Criteria-02
          </span>
        </div>

        <div className="container mt-3 ">
          <div
            className="row-fluid border bs"
            style={{
              padding: "30px",
            }}
          >
            <div class=" col-md-6">
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Auto_Year ? (
                <p>{obj1?.Auto_Year}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setAuto_Year(e.target.value)}
                >
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.1.1)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>

            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Programme name
                </label>
                {obj1?.Auto_PrgName1 ? (
                  <p>{obj1?.Auto_PrgName1}</p>
                ) : (
                  <select
                    class="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setAuto_PrgName1(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="BCA">BCA</option>
                    <option value="BSC">BSC</option>
                    <option value="BBA">BBA</option>
                    <option value="B.Com">B.Com</option>
                  </select>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Programme code
                </label>
                {obj1?.Auto_PrgCode ? (
                  <p>{obj1?.Auto_PrgCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setAuto_PrgCode(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of seats sanctioned
                </label>
                {obj1?.Auto_NoSeatSanctioned ? (
                  <p>{obj1?.Auto_NoSeatSanctioned}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setAuto_NoSeatSanctioned(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of Students admitted
                </label>
                {obj1?.Auto_NoStdAdmit ? (
                  <p>{obj1?.Auto_NoStdAdmit}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setAuto_NoStdAdmit(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.Auto_Desc32 ? (
                  <p>{obj1?.Auto_Desc32}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc32(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  id="upload1"
                  onChange={(e) => {
                    setAuto_Link32(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_2 (2.1.1)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <div className="row">
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Programme name
                        </label>
                        <br />
                        {edit1 ? (
                          <>
                            <select
                              class="form-select form-select-sm"
                              aria-label="Default select example"
                              style={{ width: "100%" }}
                              placeholder={Auto_PrgName1}
                              onChange={(e) => {
                                setAuto_PrgName1(e.target.value);
                              }}
                            >
                              <option selected>--Select--</option>
                              <option value="BCA">BCA</option>
                              <option value="BBA">BBA</option>
                              <option value="BBA">BBA</option>
                              <option value="B.Com">B.Com</option>
                            </select>
                          </>
                        ) : (
                          <>{Auto_PrgName1}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Programme code
                        </label>
                        <br />
                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_PrgCode}
                              onChange={(e) => {
                                setAuto_PrgCode(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_PrgCode}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Number of seats sanctioned
                        </label>
                        <br />
                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_NoSeatSanctioned}
                              onChange={(e) => {
                                setAuto_NoSeatSanctioned(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_NoSeatSanctioned}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Number of Students admitted
                        </label>
                        <br />
                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_NoStdAdmit}
                              onChange={(e) => {
                                setAuto_NoStdAdmit(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_NoStdAdmit}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Description(min 500 words)
                        </label>
                        <br />
                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="email"
                              id="name"
                              placeholder={Auto_Desc32}
                              onChange={(e) => setAuto_Desc32(e.target.value)}
                            />
                          </>
                        ) : (
                          <>{Auto_Desc32}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Upload the relevant document
                        </label>
                        <br />
                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="file"
                              name="Year"
                              id="upload1"
                              accept="image/*"
                              onChange={(e) =>
                                setAuto_Link32(e.target.files[0])
                              }
                            />
                          </>
                        ) : (
                          <>{Auto_Link32?.name}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit1 ? (
                  <>
                    <button
                      className="btn btn-success"
                      style={{ color: "white" }}
                      onClick={() => {
                        cat1();
                        handleClose();
                      }}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit1(true)}
                    >
                      Edit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.1.2)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>
            <div className="row">
              <div className="row">
                <h6 className="text-center text-decoration-underline">
                  Number of seats earmarked for reserved category as per GOI or
                  State Government rule
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC{" "}
                  </label>
                  {obj2?.Auto_SC ? (
                    <p>{obj2?.Auto_SC}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_SC(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST{" "}
                  </label>
                  {obj2?.Auto_ST ? (
                    <p>{obj2?.Auto_ST}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_ST(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>
                  {obj2?.Auto_OBC ? (
                    <p>{obj2?.Auto_OBC}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_OBC(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>
                  {obj2?.Auto_Gen ? (
                    <p>{obj2?.Auto_Gen}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_Gen(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>
                  {obj2?.Auto_Divyangjan ? (
                    <p>{obj2?.Auto_Divyangjan}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_Divyangjan(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>
                  {obj2?.Auto_Others ? (
                    <p>{obj2?.Auto_Others}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_Others(e.target.value)}
                    />
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students admitted from the reserved category
                </h6>

                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC{" "}
                  </label>
                  {obj2?.Auto_SSC ? (
                    <p>{obj2?.Auto_SSC}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_SSC(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST{" "}
                  </label>
                  {obj2?.Auto_SST ? (
                    <p>{obj2?.Auto_SST}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_SST(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>
                  {obj2?.Auto_OOBC ? (
                    <p>{obj2?.Auto_OOBC}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_OOBC(e.target.value)}
                    />
                  )}
                </div>

                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>
                  {obj2?.Auto_GGen ? (
                    <p>{obj2?.Auto_GGen}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_GGen(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>
                  {obj2?.Auto_DDivyangjan ? (
                    <p>{obj2?.Auto_DDivyangjan}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_DDivyangjan(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>
                  {obj2?.Auto_OOthers ? (
                    <p>{obj2?.Auto_OOthers}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setAuto_OOthers(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj2?.Auto_Desc33 ? (
                    <p>{obj2?.Auto_Desc33}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setAuto_Desc33(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="vi_0"
                    type="file"
                    name="upload2"
                    id="upload2"
                    accept="image/*"
                    onChange={(e) => {
                      setAuto_Link33(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow1}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_2 (2.1.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <h6 className="text-center text-decoration-underline">
                      Number of seats earmarked for reserved category as per GOI
                      or State Government rule
                    </h6>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        SC
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_SC}
                            onChange={(e) => {
                              setAuto_SC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_SC}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        ST{" "}
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_ST}
                            onChange={(e) => {
                              setAuto_ST(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_ST}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        OBC
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_OBC}
                            onChange={(e) => {
                              setAuto_OBC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_OBC}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Gen
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_Gen}
                            onChange={(e) => {
                              setAuto_Gen(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_Gen}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Divyangjan
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_Divyangjan}
                            onChange={(e) => {
                              setAuto_Divyangjan(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_Divyangjan}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Others
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_Others}
                            onChange={(e) => {
                              setAuto_Others(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_Others}</>
                      )}
                    </div>
                    <h6 className="text-center text-decoration-underline mt-3">
                      Number of students admitted from the reserved category
                    </h6>

                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        SC
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_SSC}
                            onChange={(e) => {
                              setAuto_SSC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_SSC}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        ST
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_SST}
                            onChange={(e) => {
                              setAuto_SST(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_SST}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        OBC
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_OOBC}
                            onChange={(e) => {
                              setAuto_OOBC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_OOBC}</>
                      )}
                    </div>

                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Gen
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_GGen}
                            onChange={(e) => {
                              setAuto_GGen(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_GGen}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Divyangjan
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_DDivyangjan}
                            onChange={(e) => {
                              setAuto_DDivyangjan(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_DDivyangjan}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Others
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_OOthers}
                            onChange={(e) => {
                              setAuto_OOthers(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_OOthers}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Auto_Desc33}
                            onChange={(e) => setAuto_Desc33(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Auto_Desc33}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload2"
                            id="name"
                            onChange={(e) => setAuto_Link33(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Auto_Link33?.name}</>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit2 ? (
                  <>
                    <button
                      className="btn btn-success"
                      style={{ color: "white" }}
                      onClick={() => {
                        cat2();
                        handleClose1();
                      }}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit2(true)}
                    >
                      Edit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.4.1 & 2.4.3)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>
            <div className="row">
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Full-time teacher
                  </label>
                  {obj3?.Auto_TName ? (
                    <p>{obj3?.Auto_TName}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setAuto_TName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    PAN
                  </label>
                  {obj3?.Auto_PAN ? (
                    <p>{obj3?.Auto_PAN}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setAuto_PAN(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Designation
                  </label>
                  {obj3?.Auto_Designation ? (
                    <p>{obj3?.Auto_Designation}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setAuto_Designation(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of appointment
                  </label>
                  {obj3?.Auto_YOfAppoint ? (
                    <p>{obj3?.Auto_YOfAppoint}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setAuto_YOfAppoint(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-9 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </label>
                  {obj3?.Auto_NoOfAppoint ? (
                    <p>{obj3?.Auto_NoOfAppoint}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setAuto_NoOfAppoint(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>
                  {obj3?.Auto_DeptName ? (
                    <p>{obj3?.Auto_DeptName}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setAuto_DeptName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total years of Experience in the same institution
                  </label>
                  {obj3?.Auto_TYofExp ? (
                    <p>{obj3?.Auto_TYofExp}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setAuto_TYofExp(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>
                  {obj3?.Auto_TService ? (
                    <p>{obj3?.Auto_TService}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setAuto_TService(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj3?.Auto_Desc34 ? (
                    <p>{obj3?.Auto_Desc34}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setAuto_Desc34(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="vi_0"
                    type="file"
                    name="upload3"
                    id="upload3"
                    accept="image/*"
                    onChange={(e) => {
                      setAuto_Link34(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow2}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_2 (2.4.1 & 2.4.3)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Full-time teacher
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_TName}
                          onChange={(e) => {
                            setAuto_TName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_TName}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      PAN
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_PAN}
                          onChange={(e) => {
                            setAuto_PAN(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_PAN}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Designation
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Designation}
                          onChange={(e) => {
                            setAuto_Designation(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Designation}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of appointment
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_YOfAppoint}
                          onChange={(e) => {
                            setAuto_YOfAppoint(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_YOfAppoint}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Department
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_NoOfAppoint}
                          onChange={(e) => {
                            setAuto_NoOfAppoint(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_NoOfAppoint}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Nature of appointment (Against Sanctioned post, temporary,
                      permanent)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_DeptName}
                          onChange={(e) => {
                            setAuto_DeptName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_DeptName}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Total years of Experience in the same institution
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_TYofExp}
                          onChange={(e) => {
                            setAuto_TYofExp(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_TYofExp}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Is the teacher still serving the institution/If not last
                      year of the service of Faculty to the Institution
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_TService}
                          onChange={(e) => {
                            setAuto_TService(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_TService}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc34}
                          onChange={(e) => setAuto_Desc34(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc34}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="name"
                          onChange={(e) => setAuto_Link34(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link34?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit3 ? (
                  <>
                    {" "}
                    <button
                      className="btn btn-success"
                      style={{ color: "white" }}
                      onClick={() => {
                        cat3();
                        handleClose2();
                      }}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit3(true)}
                    >
                      Edit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.4.2)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>
            <div className="row">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </label>
                  {obj4?.Auto_SerTeacher ? (
                    <p>{obj4?.Auto_SerTeacher}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setAuto_SerTeacher(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </label>
                  {obj4?.Auto_Qualify ? (
                    <p>{obj4?.Auto_Qualify}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setAuto_Qualify(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of obtaining the qualification
                  </label>
                  {obj4?.Auto_YOfQuali ? (
                    <p>{obj4?.Auto_YOfQuali}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setAuto_YOfQuali(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Recognition as a Research Guide
                  </label>
                  {obj4?.Auto_YOfRecog ? (
                    <p>{obj4?.Auto_YOfRecog}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setAuto_YOfRecog(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </label>
                  {obj4?.Auto_Whether ? (
                    <p>{obj4?.Auto_Whether}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setAuto_Whether(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>
                  {obj4?.Auto_IsThereTeacher ? (
                    <p>{obj4?.Auto_IsThereTeacher}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setAuto_IsThereTeacher(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scholar
                  </label>
                  {obj4?.Auto_NameScholar ? (
                    <p>{obj4?.Auto_NameScholar}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setAuto_NameScholar(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of registration of the scholar
                  </label>
                  {obj4?.Auto_YearScholar ? (
                    <p>{obj4?.Auto_YearScholar}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setAuto_YearScholar(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj4?.Auto_Desc35 ? (
                    <p>{obj4?.Auto_Desc35}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setAuto_Desc35(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="vi_0"
                    type="file"
                    name="file"
                    id="upload4"
                    accept="image/*"
                    onChange={(e) => {
                      setAuto_Link35(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow3}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_2 (2.4.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of full time serving teacher with
                        Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_SerTeacher}
                            onChange={(e) => {
                              setAuto_SerTeacher(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_SerTeacher}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Qualification (Ph.D./D.M/M.Ch./D.N.B Super
                        speciality/D.Sc./ D.Litt.)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_Qualify}
                            onChange={(e) => {
                              setAuto_Qualify(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_Qualify}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of obtaining the qualification
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_YOfQuali}
                            onChange={(e) => {
                              setAuto_YOfQuali(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_YOfQuali}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of Recognition as a Research Guide
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_YOfRecog}
                            onChange={(e) => {
                              setAuto_YOfRecog(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_YOfRecog}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Whether recognised as a Research Guide for
                        Ph.D./D.M/M.Ch./D.N.B Super
                        speciality/D.Sc./D’Lit.(YES/NO)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "270px" }}
                            onChange={(e) => setAuto_Whether(e.target.value)}
                          >
                            <option selected>--Select--</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </>
                      ) : (
                        <>{Auto_Whether}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Is the teacher still serving the institution/If not last
                        year of the service of Faculty to the Institution
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_IsThereTeacher}
                            onChange={(e) => {
                              setAuto_IsThereTeacher(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_IsThereTeacher}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the scholar
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_NameScholar}
                            onChange={(e) => {
                              setAuto_NameScholar(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_NameScholar}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of registration of the scholar
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Auto_YearScholar}
                            onChange={(e) => {
                              setAuto_YearScholar(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Auto_YearScholar}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Auto_Desc35}
                            onChange={(e) => setAuto_Desc35(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Auto_Desc35}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="email"
                            id="upload4"
                            onChange={(e) => setAuto_Link35(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Auto_Link35}</>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit4 ? (
                  <>
                    <button
                      className="btn btn-success"
                      style={{ color: "white" }}
                      onClick={() => {
                        cat4();
                        handleClose3();
                      }}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit4(true)}
                    >
                      Edit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.5.1)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>

            <div className="row">
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Programme name
                </label>
                {obj7?.Auto_pname1 ? (
                  <p>{obj7?.Auto_pname1}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setAuto_pname1(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="BCA">BCA</option>
                    <option value="BBA">BBA</option>
                    <option value="BBA">BBA</option>
                    <option value="B.Com">B.Com</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Semester/ year
                </label>
                {obj7?.Auto_semester ? (
                  <p>{obj7?.Auto_semester}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setAuto_semester(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="Semester-01">Semester-01</option>
                    <option value="Semester-02">Semester-02</option>
                    <option value="Semester-03">Semester-03</option>
                    <option value="Semester-04">Semester-04</option>
                    <option value="Semester-05">Semester-05</option>
                    <option value="Semester-06">Semester-06</option>
                    <option value="Semester-07">Semester-07</option>
                    <option value="Semester-08">Semester-08</option>
                  </select>
                )}
              </div>

              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Programme code
                </label>
                {obj7?.Auto_pcode1 ? (
                  <p>{obj7?.Auto_pcode1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setAuto_pcode1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Last date of the last semester-end/ year- end examination
                </label>
                {obj7?.Auto_last ? (
                  <p>{obj7?.Auto_last}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setAuto_last(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Date of declaration of results of semester-end/ year- end
                  examination
                </label>
                {obj7?.Auto_Date ? (
                  <p>{obj7?.Auto_Date}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setAuto_Date(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj7?.Auto_Desc36 ? (
                  <p>{obj7?.Auto_Desc36}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc36(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload7">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  id="upload7"
                  onChange={(e) => {
                    setAuto_Link36(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add7 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat7();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow6}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show6} onHide={handleClose6}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_2 (2.5.1)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <div className="row">
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Programme name
                        </label>
                        <br />
                        {edit7 ? (
                          <>
                            <select
                              class="form-select form-select-sm"
                              aria-label="Default select example"
                              style={{ width: "100%" }}
                              placeholder={Auto_pname1}
                              onChange={(e) => {
                                setAuto_pname1(e.target.value);
                              }}
                            >
                              <option selected>--Select--</option>
                              <option value="BCA">BCA</option>
                              <option value="BBA">BBA</option>
                              <option value="BBA">BBA</option>
                              <option value="B.Com">B.Com</option>
                            </select>
                          </>
                        ) : (
                          <>{Auto_pname1}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Semester/ year
                        </label>
                        <br />
                        {edit7 ? (
                          <>
                            <select
                              class="form-select form-select-sm"
                              aria-label="Default select example"
                              style={{ width: "100%" }}
                              placeholder={Auto_semester}
                              onChange={(e) => {
                                setAuto_semester(e.target.value);
                              }}
                            >
                              <option selected>--Select--</option>
                              <option value="Semester-01">Semester-01</option>
                              <option value="Semester-02">Semester-02</option>
                              <option value="Semester-03">Semester-03</option>
                              <option value="Semester-04">Semester-04</option>
                              <option value="Semester-05">Semester-05</option>
                              <option value="Semester-06">Semester-06</option>
                              <option value="Semester-07">Semester-07</option>
                              <option value="Semester-08">Semester-08</option>
                            </select>
                          </>
                        ) : (
                          <>{Auto_semester}</>
                        )}
                      </div>
                      <div className="col-md-4 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Programme code
                        </label>
                        <br />
                        {edit7 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_pcode1}
                              onChange={(e) => {
                                setAuto_pcode1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_pcode1}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Last date of the last semester-end/ year- end
                          examination
                        </label>
                        <br />
                        {edit7 ? (
                          <>
                            <input
                              className="vi_0"
                              type="date"
                              name="fname"
                              id="name"
                              placeholder={Auto_last}
                              onChange={(e) => {
                                setAuto_last(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_last}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Date of declaration of results of semester-end/ year-
                          end examination
                        </label>
                        <br />
                        {edit7 ? (
                          <>
                            <input
                              className="vi_0"
                              type="date"
                              name="fname"
                              id="name"
                              placeholder={Auto_Date}
                              onChange={(e) => {
                                setAuto_Date(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_Date}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Description(min 500 words)
                        </label>
                        <br />
                        {edit7 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="email"
                              id="name"
                              placeholder={Auto_Desc36}
                              onChange={(e) => setAuto_Desc36(e.target.value)}
                            />
                          </>
                        ) : (
                          <>{Auto_Desc36}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Upload the relevant document
                        </label>
                        <br />
                        {edit7 ? (
                          <>
                            <input
                              className="vi_0"
                              type="file"
                              name="Year"
                              id="upload1"
                              accept="image/*"
                              onChange={(e) =>
                                setAuto_Link36(e.target.files[0])
                              }
                            />
                          </>
                        ) : (
                          <>{Auto_Link36?.name}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit7 ? (
                  <>
                    {" "}
                    <button
                      className="btn btn-success"
                      style={{ color: "white" }}
                      onClick={() => {
                        cat7();
                        handleClose6();
                      }}
                    >
                      Submit
                    </button>{" "}
                  </>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit7(true)}
                    >
                      Edit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.6.3)</h5>

            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Name
                </label>
                {obj5?.Auto_PrgName2 ? (
                  <p>{obj5?.Auto_PrgName2}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setAuto_PrgName2(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="BCA">BCA</option>
                    <option value="BSC">BSC</option>
                    <option value="BBA">BBA</option>
                    <option value="B.Com">B.Com</option>
                  </select>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Code
                </label>
                {obj5?.Auto_pCode ? (
                  <p>{obj5?.Auto_pCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setAuto_pCode(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students appeared in the final year examination
                </label>
                {obj5?.Auto_NStdAppeared ? (
                  <p>{obj5?.Auto_NStdAppeared}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_NStdAppeared(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="email">
                {" "}
                Number of students passed in final year examination
              </label>
              {obj5?.Auto_NStdPassed ? (
                <p>{obj5?.Auto_NStdPassed}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAuto_NStdPassed(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj5?.Auto_Desc37 ? (
                <p>{obj5?.Auto_Desc37}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc37(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload5">
                {" "}
                Upload the relevant document
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="file"
                id="upload5"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link37(e.target.files[0]);
                }}
              />
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat5();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow4}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show4} onHide={handleClose4}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_2 (2.6.3)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={Auto_PrgName2}
                          onChange={(e) => setAuto_PrgName2(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="BCA">BCA</option>
                          <option value="BSC">BSC</option>
                          <option value="BBA">BBA</option>
                          <option value="B.Com">B.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{Auto_PrgName2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_pCode}
                          onChange={(e) => setAuto_pCode(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_pCode}</>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students appeared in the final year
                        examination
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Auto_NStdAppeared}
                            onChange={(e) =>
                              setAuto_NStdAppeared(e.target.value)
                            }
                          />
                        </>
                      ) : (
                        <>{Auto_NStdAppeared}</>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students passed in final year examination
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_NStdPassed}
                          onChange={(e) => setAuto_NStdPassed(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_NStdPassed}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc37}
                          onChange={(e) => setAuto_Desc37(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc37}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload5"
                          accept="image/*"
                          onChange={(e) => setAuto_Link37(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link37?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit5 ? (
                  <>
                    <button
                      className="btn btn-success"
                      style={{ color: "white" }}
                      onClick={() => {
                        cat5();
                        handleClose4();
                      }}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit5(true)}
                    >
                      Edit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.7.1)</h5>
            <div className="row">
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Name
                </label>
                {obj6?.Auto_PrgName3 ? (
                  <p>{obj6?.Auto_PrgName3}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => setAuto_PrgName3(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="BCA">BCA</option>
                    <option value="BSC">BSC</option>
                    <option value="BBA">BBA</option>
                    <option value="B.Com">B.Com</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the student
                </label>
                {obj6?.Auto_NStd ? (
                  <p>{obj6?.Auto_NStd}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_NStd(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Gender
                </label>
                {obj6?.Auto_Gender ? (
                  <p>{obj6?.Auto_Gender}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setAuto_Gender(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Category
                </label>
                {obj6?.Auto_Category ? (
                  <p>{obj6?.Auto_Category}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setAuto_Category(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  State of Domicile
                </label>
                {obj6?.Auto_SDomicile ? (
                  <p>{obj6?.Auto_SDomicile}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_SDomicile(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Nationality if othern than Indian
                </label>
                {obj6?.Auto_NIndian ? (
                  <p>{obj6?.Auto_NIndian}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_NIndian(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Email-ID
                </label>
                {obj6?.Auto_Email ? (
                  <p>{obj6?.Auto_Email}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Email(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Mobile Number
                </label>
                {obj6?.Auto_MNo ? (
                  <p>{obj6?.Auto_MNo}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_MNo(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of joining
                </label>
                {obj6?.Auto_YJoining ? (
                  <p>{obj6?.Auto_YJoining}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_YJoining(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Unique Enrolment ID / College ID/ University enrolment number
                </label>
                {obj6?.Auto_UID ? (
                  <p>{obj6?.Auto_UID}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_UID(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj6?.Auto_Desc38 ? (
                <p>{obj6?.Auto_Desc38}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc38(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload6">
                {" "}
                Upload the relevant document
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="file"
                id="upload6"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link38(e.target.files[0]);
                }}
              />
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add6 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat6();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow5}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show5} onHide={handleClose5}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_2 (2.7.1)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          style={{ width: "200px" }}
                          placeholder={Auto_PrgName3}
                          onChange={(e) => {
                            setAuto_PrgName3(e.target.value);
                          }}
                        >
                          <option selected>--Select--</option>
                          <option value="BCA">BCA</option>
                          <option value="BSC">BSC</option>
                          <option value="BBA">BBA</option>
                          <option value="B.Com">B.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{Auto_PrgName3}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Student Name
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_NStd}
                          onChange={(e) => {
                            setAuto_NStd(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_NStd}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Gender
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Gender}
                          onChange={(e) => {
                            setAuto_Gender(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Gender}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Category
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Category}
                          onChange={(e) => {
                            setAuto_Category(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Category}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      State of Domicile
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_SDomicile}
                          onChange={(e) => {
                            setAuto_SDomicile(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_SDomicile}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Nationality if othern than Indian
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_NIndian}
                          onChange={(e) => {
                            setAuto_NIndian(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_NIndian}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Email-ID
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Email}
                          onChange={(e) => {
                            setAuto_Email(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Email}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Mobile Number
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_MNo}
                          onChange={(e) => {
                            setAuto_MNo(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_MNo}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of joining
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_YJoining}
                          onChange={(e) => {
                            setAuto_YJoining(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_YJoining}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Unique Enrolment ID / College ID/ University enrolment
                      number
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_UID}
                          onChange={(e) => {
                            setAuto_UID(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_UID}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc38}
                          onChange={(e) => setAuto_Desc38(e.target.value)}
                        ></textarea>
                      </>
                    ) : (
                      <>{Auto_Desc38}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload6"
                          accept="image/*"
                          onChange={(e) => setAuto_Link38(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link38?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {edit6 ? (
                  <>
                    {" "}
                    <button
                      className="btn btn-success"
                      style={{ color: "white" }}
                      onClick={() => {
                        cat6();
                        handleClose5();
                      }}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => setedit6(true)}
                    >
                      Edit
                    </button>{" "}
                  </>
                )}
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => autocriteria2()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Auto_Criteria002;
