import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete, AiOutlineDelete, AiOutlineSave } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
function AddCampus() {
  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));

  const [imageURL, setimageURL] = useState(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL(URL.createObjectURL(event.target.files[0]));
      console.log("ghghg", URL.createObjectURL(event.target.files[0]));
    }
  };

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // show description model
  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  // ================ add campus details modal===============================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================ delete campus details modal===============================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================ edit campus details modal===============================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // =================== calling api for adding the campus content======================
  let formdata = new FormData();
  const [CampusImg1, setCampusImg1] = useState("");
  const [CampusBody, setCampusBody] = useState("");
  const [CampusImg2, setCampusImg2] = useState("");
  const [linksDescription, setlinksDescription] = useState("");
  const [linkName, setlinkName] = useState("");
  const [link, setlink] = useState("");
  const [linksAvail, setlinksAvail] = useState([]);

  const [View, setView] = useState({});

  function addLinks() {
    try {
      if (!linkName || !link) {
        return alert("please add link name and their respective link.");
      }
      // const obj = {
      //   linkName: linkName,
      //   link: link,
      // };
      setlinksAvail((curr) => [
        ...curr,
        {
          linkName: linkName,
          link: link,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  const AddCampusDetailsContent = async (e) => {
    e.preventDefault();
    if (
      !CampusImg1 ||
      !CampusBody ||
      !CampusImg2 ||
      !linksDescription ||
      !linksAvail
    ) {
      return alert("Please fill all details!!!");
    }
    let obj = {
      CampusImg1: CampusImg1,
      CampusBody: CampusBody,
      CampusImg2: CampusImg2,
      linksDescription: linksDescription,
      linksAvail: linksAvail,
    };

    try {
      const config = {
        url: "/admin/addCampusContent",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          getCampusContent();
          setCampusImg1("");
          setCampusBody("");
          setCampusImg2("");
          setlinksDescription("");
          setlinkName("");
          setlink("");
          setlinksAvail([]);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the Leadership content======================
  const [CampusDetails, setCampusDetails] = useState([]);
  const getCampusContent = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getCampusDetails"
      );
      if (res.status === 200) {
        setCampusDetails(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================== calling api for getting the specific campus content======================
  const getCampusContentById = async (id) => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getCampusDetailsById/" + id
      );
      if (res.status === 200) {
        setView(res.data.campusContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================== calling api for deleting the Leadership content======================
  const deleteContent = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/deleteCampus/" + View?._id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getCampusContent();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };

  // =================== calling api for editing the Leadership content======================
  const editContent = async (e) => {
    e.preventDefault();
    if (!CampusImg1 && !CampusBody && !CampusImg2 && !linksDescription) {
      return alert("Please Provide atleast one detail to edit!!!");
    }
    let obj = {
      CampusImg1: CampusImg1,
      CampusBody: CampusBody,
      CampusImg2: CampusImg2,
      linksDescription: linksDescription,
    };

    try {
      const config = {
        url: "/admin/editCampus/" + View?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getCampusContent();
          setCampusImg1("");
          setCampusBody("");
          setCampusImg2("");
          setlinksDescription("");
          setlinkName("");
          setlink("");
          setlinksAvail([]);
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  // ======================calling api for editing=================
  const EditLinkDetails = async (e, state, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/editLinkCampus/" + View?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          linkid: id,
          linkName: linkName,
          link: link,
          state: state,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getCampusContentById(View?._id);
          setlinkName("");
          setlink("");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getCampusContent();
  }, []);
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Campus
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>

        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Image</th>
                <th>Description</th>
                <th>Image</th>
                <th>Link Description</th>
                <th>Details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {CampusDetails?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>
                      <AiOutlineEye
                        onClick={() => {
                          setView(item);
                          handleShow4();
                        }}
                        fontSize={20}
                      />
                    </td>

                    <td>
                      {item?.CampusBody?.slice(0, 70)}
                      ...
                      <Link
                        onClick={() => {
                          handleShow7();
                          setView(item);
                        }}
                      >
                        Read More...
                      </Link>
                    </td>

                    <td>
                      <AiOutlineEye
                        onClick={() => {
                          setView(item);
                          handleShow3();
                        }}
                        fontSize={20}
                      />
                    </td>
                    <td>
                      {item?.linksDescription?.slice(0, 70)}
                      ...
                      <Link
                        onClick={() => {
                          handleShow8();
                          setView(item);
                        }}
                      >
                        Read More...
                      </Link>
                    </td>

                    <td>
                      <Table responsive bordered striped>
                        <thead>
                          <th>Link Name</th>
                          <th>Link</th>
                        </thead>
                        <tbody>
                          {item?.linksAvail?.map((val) => {
                            return (
                              <tr>
                                <td>{val?.linkName}</td>
                                <td>{val?.link}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-evenly">
                        <div className="">
                          <span>
                            <BiEdit
                              size={20}
                              c
                              onClick={() => {
                                setView(item);
                                getCampusContentById(item?._id);
                                handleShow2();
                              }}
                            />
                          </span>
                        </div>
                        <div className="">
                          <span
                            onClick={() => {
                              setView(item);
                              getCampusContentById(item?._id);
                              handleShow1();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <AiFillDelete />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Image2</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Campus/${View?.CampusImg1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Campus Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Campus/${View?.CampusImg2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Show description modal */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.CampusBody}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.linksDescription}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add campus details */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Campus</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label htmlFor="upload1">
                Upload Image 1 <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="upload1"
                  name="ImageStyle"
                  className="vi_0"
                  accept="image/*"
                  onChange={(e) => setCampusImg1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="mb-1">
              <label>
                Description <span className="font-css top">*</span>
              </label>
              <div className="">
                <textarea
                  className="vi_0"
                  id="w3review"
                  name="w3review"
                  value={CampusBody}
                  rows="4"
                  cols="50"
                  onChange={(e) => {
                    setCampusBody(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="mb-1">
              <label htmlFor="upload2">
                Upload Image 2 <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="upload2"
                  name="ImageStyle"
                  className="vi_0"
                  accept="image/*"
                  onChange={(e) => setCampusImg2(e.target.files[0])}
                />
              </div>
            </div>
            <div className="mb-1">
              <label>
                Link Description <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setlinksDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex" style={{ alignItems: "end", gap: "5px" }}>
              <div>
                <label>
                  Link Name <span className="font-css top">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    id="file-input"
                    name="ImageStyle"
                    className="vi_0"
                    onChange={(e) => setlinkName(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label>
                  Link<span className="font-css top">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    id="file-input"
                    name="ImageStyle"
                    className="vi_0"
                    onChange={(e) => setlink(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <Button onClick={addLinks}>ADD</Button>
              </div>
            </div>
            <div>
              {linksAvail?.map((val) => {
                return (
                  <tr>
                    <td>{val.linkName}</td>
                    <td>{val.link}</td>
                  </tr>
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(e) => AddCampusDetailsContent(e)}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Campus</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>
                Image1 <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="Eupload1"
                  name="ImageStyle"
                  className="vi_0"
                  hidden
                  accept="image/*"
                  onChange={(e) => {
                    onImageChange(e);
                    setCampusImg1(e.target.files[0]);
                  }}
                />
                <label for="Eupload1">
                  {CampusImg1 ? (
                    <img
                      src={imageURL}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  ) : (
                    <img
                      src={`https://brightnaac.co.in/Campus/${View?.CampusImg1}`}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  )}
                </label>
              </div>
            </div>
            <div className="mb-1">
              <label>
                Description <span className="font-css top">*</span>
              </label>
              <div className="">
                <textarea
                  id="w3review"
                  name="w3review"
                  placeholder={View?.CampusBody}
                  value={CampusBody}
                  rows="4"
                  cols="50"
                  onChange={(e) => {
                    setCampusBody(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="mb-1">
              <label>
                Image2 <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="Eupload2"
                  name="ImageStyle"
                  className="vi_0"
                  hidden
                  accept="image/*"
                  onChange={(e) => {
                    onImageChange(e);
                    setCampusImg2(e.target.files[0]);
                  }}
                />
                <label for="Eupload2">
                  {CampusImg2 ? (
                    <img
                      src={imageURL}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  ) : (
                    <img
                      src={`https://brightnaac.co.in/Campus/${View?.CampusImg2}`}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  )}
                </label>
              </div>
            </div>
            <div className="mb-1">
              <label>Link Description</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.linksDescription}
                  onChange={(e) => setlinksDescription(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>Links Available</label>
              <Table>
                <tbody>
                  {View?.linksAvail?.map((val1) => {
                    return (
                      <tr>
                        <td>
                          <input
                            type="text"
                            id="file-input"
                            name="ImageStyle"
                            className="vi_0"
                            placeholder={val1?.linkName}
                            onChange={(e) => setlinkName(e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            id="file-input"
                            name="ImageStyle"
                            className="vi_0"
                            placeholder={val1?.link}
                            onChange={(e) => setlink(e.target.value)}
                          />
                        </td>
                        <td>
                          <AiOutlineSave
                            onClick={(e) => {
                              EditLinkDetails(e, "EDIT", val1?._id);
                            }}
                          />{" "}
                          <AiOutlineDelete
                            onClick={(e) => {
                              EditLinkDetails(e, "DELETE", val1?._id);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div>
              <label>Add New Links</label>
              <div className="d-flex" style={{ alignItems: "end", gap: "5px" }}>
                <div>
                  <label>Link Name</label>
                  <div className="">
                    <input
                      type="text"
                      id="file-input"
                      name="ImageStyle"
                      className="vi_0"
                      onChange={(e) => setlinkName(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label>Link</label>
                  <div className="">
                    <input
                      type="text"
                      id="file-input"
                      name="ImageStyle"
                      className="vi_0"
                      onChange={(e) => setlink(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <Button
                    onClick={(e) => {
                      EditLinkDetails(e, "ADD");
                    }}
                  >
                    ADD
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => editContent(e)}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteContent(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AddCampus;
