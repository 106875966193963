import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import exportFromJSON from "export-from-json";
import { DatePicker, Space } from "antd";
import { AiOutlineEye } from "react-icons/ai";
const AdminClgUG01_121 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  const [content, setContent] = useState([]);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("1.2.1");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          Year: item?.Year,
          "Program Code": item?.Criteria01_121?.PrgmCode,
          "Program Name": item?.Criteria01_121?.PrgName1,
          "Year Of Introduction": item?.Criteria01_121?.YOfIntro,
          "Status of implementation of CBCS/elective course":
            item?.Criteria01_121?.Status,
          "Year of implementation of CBCS/elective course":
            item?.Criteria01_121?.YOfCBCS,
          "Year of revision (if any)": item?.Criteria01_121?.YOfRevision,
          "If revision has been carried out in the syllabus during the last 5 years, Percentage of content added or replaced":
            item?.Criteria01_121?.Revision,
          Description: item?.Criteria01_121?.Desc2,
          "Link to the relevant document": item?.Criteria01_121?.Link2,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const getCriteria01_121 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/ugcri01_121"
      );
      if (res.status === 200) {
        setContent(res.data.getC121);
        setdata1(res.data.getC121);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getCriteria01_121();
      getAllYears();
    }
  }, []);

  console.log(content);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-01-121
              </span>
            </div>
          </div>
        </div>
        <div className="ad-b  row align-items-center m-0">
          <div className=" col-lg-3 do-sear  d-flex ">
            <input
              type="text"
              placeholder="Search"
              className="vi_0"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>

          <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
            <DatePicker
              placeholder="Select Year (from)"
              onChange={onChangeFrom}
              picker="year"
              className="vi_0"
            />
          </div>
          <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
            <DatePicker
              onChange={onChangeTo}
              placeholder="Select Year (to)"
              picker="year"
              className="vi_0"
            />
          </div>

          <div className=" col-lg-2 text-end ">
            <Button className="btn-success" onClick={ExportToExcel}>
              Export Excel
            </Button>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/adminclgugcri113">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  onClick={() => navigate(`/adminclgugcri113/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 1.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-2"
                  onClick={() => navigate(`/adminclgugcri121/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 1.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri122/${params?.id}`)}
                >
                  <Button className="criteriabtn">1.2.2 & 1.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-4"
                  onClick={() => navigate(`/adminclgugcri132/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 1.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-5"
                  onClick={() => navigate(`/adminclgugcri133/${params?.id}`)}
                >
                  <Button style={{}} className="criteriabtn">
                    {" "}
                    1.3.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{
                textAlign: "center",
                width: "2000px",
                verticalAlign: "middle",
              }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Program Code</th>
                  <th>Program Name</th>
                  <th>Year Of Introduction</th>
                  <th>Status of implementation of CBCS/elective course</th>
                  <th>Year of implementation of CBCS/elective course</th>
                  <th>Year of revision (if any) </th>
                  <th>
                    If revision has been carried out in the syllabus during the
                    last 5 years, Percentage of content added or replaced
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* <th>Remarks</th> */}
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year &&
                      (SearchItem === "" ||
                        item1?.Criteria01_121?.criteria?.includes(SearchItem) ||
                        item1?.Year?.includes(SearchItem) ||
                        item1?.Criteria01_121?.PrgmCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria01_121?.PrgName1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria01_121?.YOfIntro?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria01_121?.Status?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria01_121?.YOfCBCS?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria01_121?.YOfRevision?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria01_121?.Revision?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria01_121?.Desc2?.toString()?.includes(
                          SearchItem
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?.Year}</td>
                        <td>{item?.Criteria01_121?.criteria}</td>

                        <td>{item?.Criteria01_121?.PrgmCode}</td>
                        <td>{item?.Criteria01_121?.PrgName1}</td>

                        <td>{item?.Criteria01_121?.YOfIntro}</td>
                        <td>{item?.Criteria01_121?.Status}</td>

                        <td>{item?.Criteria01_121?.YOfCBCS}</td>
                        <td>{item?.Criteria01_121?.YOfRevision}</td>

                        <td>{item?.Criteria01_121?.Revision}</td>
                        <td>
                          {item?.Criteria01_121?.Desc2?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow7();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow3();
                            }}
                            fontSize={20}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Criteria01_121?.Desc2}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_121?.Link2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AdminClgUG01_121;
