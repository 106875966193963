import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const UG_Criteria005 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);
  const [edit8, setedit8] = useState(false);
  const [add8, setadd8] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  //post method for criteria_05
  const [Year, setYear] = useState("");

  const [NameS, setNameS] = useState("");
  const [SName, setSName] = useState("");
  const [Amount, setAmount] = useState("");
  const [SName1, setSName1] = useState("");
  const [Amount1, setAmount1] = useState("");
  const [Desc21, setDesc21] = useState("");
  const [Link21, setLink21] = useState("");

  const [NamCap, setNamCap] = useState("");
  const [IDate, setIDate] = useState("");
  const [StdEnr, setStdEnr] = useState("");
  const [AgeName, setAgeName] = useState("");
  const [Desc22, setDesc22] = useState("");
  const [Link22, setLink22] = useState("");

  const [CampName, setCampName] = useState("");
  const [Nameact, setNameact] = useState("");
  const [NamePart, setNamePart] = useState("");
  const [Desc23, setDesc23] = useState("");
  const [Link23, setLink23] = useState("");

  const [PlaName, setPlaName] = useState("");
  const [Graduate, setGraduate] = useState("");
  const [ConDetails, setConDetails] = useState("");
  const [Pay, setPay] = useState("");
  const [Desc24, setDesc24] = useState("");
  const [Link24, setLink24] = useState("");

  const [HighName, setHighName] = useState("");
  const [PGrad, setPGrad] = useState("");
  const [Institute, setInstitute] = useState("");
  const [Admitted, setAdmitted] = useState("");
  const [Desc25, setDesc25] = useState("");
  const [Link25, setLink25] = useState("");

  const [No1, setNo1] = useState("");
  const [RollNo1, setRollNo1] = useState("");
  const [StdSelected1, setStdSelected1] = useState("");
  const [NET1, setNET1] = useState("");
  const [SLET1, setSLET1] = useState("");
  const [GATE1, setGATE1] = useState("");
  const [GMAT1, setGMAT1] = useState("");
  const [CAT1, setCAT1] = useState("");
  const [GRE1, setGRE1] = useState("");
  const [JAM1, setJAM1] = useState("");
  const [IELTS1, setIELTS1] = useState("");
  const [TOEFL1, setTOEFL1] = useState("");
  const [Civil1, setCivil1] = useState("");
  const [SGExam1, setSGExam1] = useState("");
  const [OtherExam1, setOtherExam1] = useState("");
  const [Total1, setTotal1] = useState("");
  const [GTotal1, setGTotal1] = useState("");
  const [Desc26, setDesc26] = useState("");
  const [Link26, setLink26] = useState("");

  const [Meda, setMeda] = useState("");
  const [TIndi, setTIndi] = useState("");
  const [USNI1, setUSNI1] = useState("");
  const [SCult, setSCult] = useState("");
  const [NaStd, setNaStd] = useState("");
  const [Desc27, setDesc27] = useState("");
  const [Link27, setLink27] = useState("");

  const [NStdParti, setNStdParti] = useState("");
  const [EDate, setEDate] = useState("");
  const [EName, setEName] = useState("");
  const [Desc28, setDesc28] = useState("");
  const [Link28, setLink28] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getugcriteria5 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));

      let am8 = sessionStorage.getItem("cat8");
      setobj8(JSON.parse(am8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getugcriteria5();
  }, []);

  const ugcriteria5 = async () => {
    if (!Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/adddetails05",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Year: Year,

          Criteria05_511: obj1,
          Link21: Link21,

          Criteria05_513: obj2,
          Link22: Link22,

          Criteria05_514: obj3,
          Link23: Link23,

          Criteria05_521: obj4,
          Link24: Link24,

          Criteria05_522: obj5,
          Link25: Link25,

          Criteria05_523: obj6,
          Link26: Link26,

          Criteria05_531: obj7,
          Link27: Link27,

          Criteria05_533: obj8,
          Link28: Link28,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          sessionStorage.removeItem("cat8");

          navigate("/ugcriteria05");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !NameS ||
          !SName ||
          !Amount ||
          !SName1 ||
          !Amount1 ||
          !Desc21 ||
          !Link21
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Link21);
      let obj = {
        criteria: "5.1.1 & 5.1.2",
        NameS: NameS,
        SName: SName,
        Amount: Amount,
        SName1: SName1,
        Amount1: Amount1,
        Desc21: Desc21,
        Link21: Link21,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getugcriteria5();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Desc22;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (!NamCap || !IDate || !StdEnr || !AgeName || !Desc22 || !Link22) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Link22);
      let obj = {
        criteria: "5.1.3",
        NamCap: NamCap,
        IDate: IDate,
        StdEnr: StdEnr,
        AgeName: AgeName,
        Desc22: Desc22,
        Link22: Link22,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getugcriteria5();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (!CampName || !Nameact || !NamePart || !Desc23 || !Link23) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Link23);
      let obj = {
        criteria: "5.1.4",
        CampName: CampName,
        Nameact: Nameact,
        NamePart: NamePart,
        Desc23: Desc23,
        Link23: Link23,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getugcriteria5();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Desc24;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !PlaName ||
          !Graduate ||
          !ConDetails ||
          !Pay ||
          !Desc24 ||
          !Link24
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Link24);
      let obj = {
        criteria: "5.2.1",
        PlaName: PlaName,
        Graduate: Graduate,
        ConDetails: ConDetails,
        Pay: Pay,
        Desc24: Desc24,
        Link24: Link24,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getugcriteria5();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Desc25;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !HighName ||
          !PGrad ||
          !Institute ||
          !Admitted ||
          !Desc25 ||
          !Link25
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("qqqq,", Link25);
      let obj = {
        criteria: "5.2.2",
        HighName: HighName,
        PGrad: PGrad,
        Institute: Institute,
        Admitted: Admitted,
        Desc25: Desc25,
        Link25: Link25,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getugcriteria5();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Desc26;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !No1 ||
          !RollNo1 ||
          !StdSelected1 ||
          !NET1 ||
          !SLET1 ||
          !GATE1 ||
          !GMAT1 ||
          !CAT1 ||
          !GRE1 ||
          !JAM1 ||
          !IELTS1 ||
          !TOEFL1 ||
          !Civil1 ||
          !SGExam1 ||
          !OtherExam1 ||
          !Total1 ||
          !GTotal1 ||
          !Desc26 ||
          !Link26
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("rrr,", Link26);
      let obj = {
        criteria: "5.2.3",
        No1: No1,
        RollNo1: RollNo1,
        StdSelected1: StdSelected1,
        NET1: NET1,
        SLET1: SLET1,
        GATE1: GATE1,
        GMAT1: GMAT1,
        CAT1: CAT1,
        GRE1: GRE1,
        JAM1: JAM1,

        IELTS1: IELTS1,
        TOEFL1: TOEFL1,
        Civil1: Civil1,
        SGExam1: SGExam1,
        OtherExam1: OtherExam1,
        Total1: Total1,
        GTotal1: GTotal1,
        Desc26: Desc26,
        Link26: Link26,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getugcriteria5();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = Desc27;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !Meda ||
          !TIndi ||
          !USNI1 ||
          !SCult ||
          !NaStd ||
          !Desc27 ||
          !Link27
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd7(false);
        }
      }

      console.log("sss,", Link27);
      let obj = {
        criteria: "5.3.1",
        Meda: Meda,
        TIndi: TIndi,
        USNI1: USNI1,
        SCult: SCult,
        NaStd: NaStd,
        Desc27: Desc27,
        Link27: Link27,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getugcriteria5();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat8 = () => {
    try {
      const minwords = 500;
      const newText = Desc28;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add8) {
        if (!NStdParti || !EDate || !EName || !Desc28 || !Link28) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd8(false);
        }
      }

      console.log("ttt,", Link28);
      let obj = {
        criteria: "5.3.3",
        NStdParti: NStdParti,
        EDate: EDate,
        EName: EName,
        Desc28: Desc28,
        Link28: Link28,
      };
      sessionStorage.setItem("cat8", JSON.stringify(obj));
      alert("Successfully added cat8");
      getugcriteria5();
      setedit8(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div className="container">
      <div className="pt-4   " style={{ padding: "10px" }}>
        <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
          {" "}
          UnderGraduate_Criteria-05
        </span>
      </div>
      <div className="container">
        <div
          className="row-fluid border bs"
          style={{
            padding: "30px",
          }}
        >
          <div class=" col-md-6">
            <label for="" class="form-label">
              Select Year
            </label>
            {obj1?.Year ? (
              <p>{obj1?.Year}</p>
            ) : (
              <select
                class="form-select form-select-sm vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setYear(e.target.value)}
              >
                <option selected>--select Year--</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_5 (5.1.1 & 5.1.2)</h5>
          <div className="row">
            <div className="col-md-6 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Name of the student
              </label>
              {obj1?.NameS ? (
                <p>{obj1?.NameS}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setNameS(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <h6 className="text-center text-decoration-underline">
            (Number of students benefited by government scheme and amount){" "}
          </h6>

          <div className="row">
            <div className="col-md-6 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Number of students
              </label>
              {obj1?.SName ? (
                <p>{obj1?.SName}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setSName(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Amount
              </label>
              {obj1?.Amount ? (
                <p>{obj1?.Amount}</p>
              ) : (
                <input
                  clasAmount=" forminput"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <h6 className="text-center text-decoration-underline">
            (Number of students benefited by the institution's schemes and
            amount){" "}
          </h6>

          <div className="row">
            <div className="col-md-6 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Number of students
              </label>
              {obj1?.SName1 ? (
                <p>{obj1?.SName1}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setSName1(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Amount
              </label>
              {obj1?.Amount1 ? (
                <p>{obj1?.Amount1}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAmount1(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <label className="content1" htmlFor="name">
              {" "}
              Description (min 500 words)
            </label>
            {obj1?.Desc21 ? (
              <p>{obj1?.Desc21}</p>
            ) : (
              <textarea
                className="vi_0"
                id="name"
                // cols={57}
                rows={3}
                onChange={(e) => {
                  setDesc21(e.target.value);
                }}
              ></textarea>
            )}
          </div>
          <div className="col-md-12 mt-3">
            <label className="content1" htmlFor="upload1">
              {" "}
              Upload the relevant document
            </label>
            <br />

            <input
              className="vi_0"
              type="file"
              name="Year"
              id="upload1"
              accept="image/*"
              onChange={(e) => {
                setLink21(e.target.files[0]);
              }}
            />
          </div>
          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add1 ? (
              <button
                type="submit"
                className="btn btn-success  mt-3 "
                onClick={() => {
                  cat1();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}
          </div>
          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria05_(5.1.1 &5.1.2)</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scheme
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={NameS}
                        onChange={(e) => {
                          setNameS(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{NameS}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline">
                (Number of students benefited by government scheme and amount){" "}
              </h6>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={SName}
                        onChange={(e) => {
                          setSName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{SName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Amount}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline">
                (Number of students benefited by the institution's schemes and
                amount){" "}
              </h6>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={SName1}
                        onChange={(e) => {
                          setSName1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{SName1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Amount1}
                        onChange={(e) => {
                          setAmount1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Amount1}</>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Description(min 500 words)
                </label>
                <br />
                {edit1 ? (
                  <>
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Desc21}
                      onChange={(e) => setDesc21(e.target.value)}
                    />
                  </>
                ) : (
                  <>{Desc21}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                {edit1 ? (
                  <>
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload1"
                      accept="image/*"
                      onChange={(e) => setLink21(e.target.files[0])}
                    />
                  </>
                ) : (
                  <>{Link21?.name}</>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit1(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                style={{ color: "white" }}
                onClick={() => {
                  cat1();
                  handleClose();
                }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_5 (5.1.3)</h5>
          <div className="row">
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Name of the capacity development and skills enhancement program
              </label>
              <br />
              {obj2?.NamCap ? (
                <p>{obj2?.NamCap}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="year"
                  id="name"
                  onChange={(e) => {
                    setNamCap(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="name">
                {" "}
                Date of implementation
              </label>
              <br />
              {obj2?.IDate ? (
                <p>{obj2?.IDate}</p>
              ) : (
                <input
                  className="vi_0"
                  type="date"
                  name="fname"
                  id="name"
                  onChange={(e) => {
                    setIDate(e.target.value);
                  }}
                />
              )}
            </div>

            <div className="col-md-6 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Number of students enrolled
              </label>
              <br />
              {obj2?.StdEnr ? (
                <p>{obj2?.StdEnr}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setStdEnr(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Name of the agencies/consultants involved with contact details
                (if any)
              </label>
              <br />
              {obj2?.AgeName ? (
                <p>{obj2?.AgeName}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAgeName(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              <br />
              {obj2?.Desc22 ? (
                <p>{obj2?.Desc22}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setDesc22(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <label className="content1" htmlFor="upload2">
              {" "}
              Upload the relevant document
            </label>
            <br />

            <input
              className="vi_0"
              type="file"
              name="Year"
              id="upload2"
              accept="image/*"
              onChange={(e) => {
                setLink22(e.target.files[0]);
              }}
            />
          </div>
          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add2 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat2();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                <button
                  className="btn btn-warning"
                  onClick={handleShow1}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}{" "}
          </div>
          <Modal size="lg" show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria05_(5.1.3)</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the capacity development and skills enhancement
                    program
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={NamCap}
                        onChange={(e) => {
                          setNamCap(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{NamCap}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of implementation
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="fname"
                        id="name"
                        placeholder={IDate}
                        onChange={(e) => {
                          setIDate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{IDate}</>
                  )}
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students enrolled
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={StdEnr}
                        onChange={(e) => {
                          setStdEnr(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{StdEnr}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={AgeName}
                        onChange={(e) => {
                          setAgeName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{AgeName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Desc22}
                        onChange={(e) => setDesc22(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Desc22}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        onChange={(e) => setLink22(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Link22?.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit2(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  cat2();
                  handleClose1();
                }}
                style={{ color: "white" }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "20px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_5 (5.1.4)</h5>
          <div className="row">
            <div className="col-md-12 mt-3 mb-3">
              <label className="content1" htmlFor="name">
                {" "}
                Number of students placed through campus placement
              </label>
              {obj3?.CampName ? (
                <p>{obj3?.CampName}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="fname"
                  id="name"
                  onChange={(e) => {
                    setCampName(e.target.value);
                  }}
                />
              )}
            </div>
            <h6 className="text-center text-decoration-underline">
              (Name of the Activity conducted by the HEI to offer guidance for
              competitive examinations/ career counseling offered by the
              institution during the last five years)
            </h6>
            <div className="col-md-6 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Name of the Activity
              </label>
              {obj3?.Nameact ? (
                <p>{obj3?.Nameact}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setNameact(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Number of students attended / participated
              </label>
              {obj3?.NamePart ? (
                <p>{obj3?.NamePart}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setNamePart(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj3?.Desc23 ? (
                <p>{obj3?.Desc23}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setDesc23(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload3">
                {" "}
                Upload the relevant document
              </label>
              <br />

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload3"
                accept="image/*"
                onChange={(e) => {
                  setLink23(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add3 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat3();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow2}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}{" "}
          </div>
          <Modal size="lg" show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria05--(5.1.4)</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students placed through campus placement
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={CampName}
                        onChange={(e) => {
                          setCampName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{CampName}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  (Name of the Activity conducted by the HEI to offer guidance
                  for competitive examinations/ career counseling offered by the
                  institution during the last five years)
                </h6>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the Activity
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Nameact}
                        onChange={(e) => {
                          setNameact(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Nameact}</>
                  )}
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students attended / participated
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={NamePart}
                        onChange={(e) => {
                          setNamePart(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{NamePart}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Desc23}
                        onChange={(e) => setDesc23(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Desc23}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className=" name"
                        type="file"
                        name="Year"
                        id="upload3 "
                        onChange={(e) => setLink23(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Link23?.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit3(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  cat3();
                  handleClose2();
                }}
                style={{ color: "white" }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "20px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_5 (5.2.1)</h5>
          <div className="row">
            <div className="col-md-12 mt-3 mb-3">
              <label className="content1" htmlFor="name">
                {" "}
                Name of student placed and contact details
              </label>
              {obj4?.PlaName ? (
                <p>{obj4?.PlaName}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="fname"
                  id="name"
                  onChange={(e) => {
                    setPlaName(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Program graduated from
              </label>
              {obj4?.Graduate ? (
                <p>{obj4?.Graduate}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setGraduate(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Name of the employer with contact details
              </label>
              {obj4?.ConDetails ? (
                <p>{obj4?.ConDetails}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setConDetails(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Pay package at appointment (In INR per annum)
              </label>
              {obj4?.Pay ? (
                <p>{obj4?.Pay}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setPay(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj4?.Desc24 ? (
                <p>{obj4?.Desc24}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setDesc24(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <label className="content1" htmlFor="upload4">
              {" "}
              Upload the relevant document
            </label>
            <br />

            <input
              className="vi_0"
              type="file"
              name="Year"
              id="upload4"
              accept="image/*"
              onChange={(e) => {
                setLink24(e.target.files[0]);
              }}
            />
          </div>

          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add4 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat4();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow3}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}{" "}
          </div>
          <Modal size="lg" show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria05--(5.2.1)</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student placed and contact details
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={PlaName}
                        onChange={(e) => {
                          setPlaName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{PlaName}</>
                  )}
                </div>

                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the employer with contact details
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Graduate}
                        onChange={(e) => {
                          setGraduate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Graduate}</>
                  )}
                </div>
                <div className="col-md-5 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={ConDetails}
                        onChange={(e) => {
                          setConDetails(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{ConDetails}</>
                  )}
                </div>
                <div className="col-md-7 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Pay package at appointment (In INR per annum)
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Pay}
                        onChange={(e) => {
                          setPay(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Pay}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Desc24}
                        onChange={(e) => setDesc24(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Desc24}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        onChange={(e) => setLink24(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Link24.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit4(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  cat4();
                  handleClose3();
                }}
                style={{ color: "white" }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "20px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_5 (5.2.2)</h5>
          <div className="row">
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="name">
                {" "}
                Name of student enrolling into higher education
              </label>
              {obj5?.HighName ? (
                <p>{obj5?.HighName}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="fname"
                  id="name"
                  onChange={(e) => {
                    setHighName(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Program graduated from
              </label>
              {obj5?.PGrad ? (
                <p>{obj5?.PGrad}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setPGrad(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Name of institution joined
              </label>
              {obj5?.Institute ? (
                <p>{obj5?.Institute}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setInstitute(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Name of programme admitted to
              </label>
              {obj5?.Admitted ? (
                <p>{obj5?.Admitted}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAdmitted(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj5?.Desc25 ? (
                <p>{obj5?.Desc25}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setDesc25(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload5">
                {" "}
                Upload the relevant document
              </label>
              <br />

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload5"
                accept="image/*"
                onChange={(e) => {
                  setLink25(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add5 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat5();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow4}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}{" "}
          </div>
          <Modal size="lg" show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria05_522</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student enrolling into higher education
                  </label>
                  <br />
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={HighName}
                        onChange={(e) => {
                          setHighName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{HighName}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>
                  <br />
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={PGrad}
                        onChange={(e) => {
                          setPGrad(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{PGrad}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of institution joined
                  </label>
                  <br />
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Institute}
                        onChange={(e) => {
                          setInstitute(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Institute}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of programme admitted to
                  </label>
                  <br />
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Admitted}
                        onChange={(e) => {
                          setAdmitted(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Admitted}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Desc25}
                        onChange={(e) => setDesc25(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Desc25}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload5">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload5"
                        accept="image/*"
                        onChange={(e) => {
                          setLink25(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Link25?.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit5(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  cat5();
                  handleClose4();
                }}
                style={{ color: "white" }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "20px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5> Criteria_5 (5.2.3)</h5>
          <div className="row">
            <div className="row">
              <div className="row">
                <div className="col-md-4 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Sl.no.
                  </label>
                  {obj6?.No1 ? (
                    <p>{obj6?.No1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setNo1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Registration number/roll number for the exam
                  </label>
                  {obj6?.RollNo1 ? (
                    <p>{obj6?.RollNo1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setRollNo1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student /qualified
                  </label>
                  {obj6?.StdSelected1 ? (
                    <p>{obj6?.StdSelected1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setStdSelected1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    NET
                  </label>
                  {obj6?.NET1 ? (
                    <p>{obj6?.NET1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setNET1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    SLET
                  </label>
                  {obj6?.SLET1 ? (
                    <p>{obj6?.SLET1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setSLET1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GATE
                  </label>
                  {obj6?.GATE1 ? (
                    <p>{obj6?.GATE1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setGATE1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GMAT
                  </label>
                  {obj6?.GMAT1 ? (
                    <p>{obj6?.GMAT1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setGMAT1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    CAT
                  </label>
                  {obj6?.CAT1 ? (
                    <p>{obj6?.CAT1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setCAT1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GRE
                  </label>
                  {obj6?.GRE1 ? (
                    <p>{obj6?.GRE1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setGRE1(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    JAM
                  </label>
                  {obj6?.JAM1 ? (
                    <p>{obj6?.JAM1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setJAM1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    IELTS
                  </label>
                  {obj6?.IELTS1 ? (
                    <p>{obj6?.IELTS1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setIELTS1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TOEFL
                  </label>
                  {obj6?.TOEFL1 ? (
                    <p>{obj6?.TOEFL1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setTOEFL1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Civil Services
                  </label>
                  {obj6?.Civil1 ? (
                    <p>{obj6?.Civil1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setCivil1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State government examinations
                  </label>
                  {obj6?.SGExam1 ? (
                    <p>{obj6?.SGExam1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setSGExam1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </label>
                  {obj6?.OtherExam1 ? (
                    <p>{obj6?.OtherExam1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setOtherExam1(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>
                  {obj6?.Total1 ? (
                    <p>{obj6?.Total1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setTotal1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Grand Total
                  </label>
                  {obj6?.GTotal1 ? (
                    <p>{obj6?.GTotal1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setGTotal1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj6?.Desc26 ? (
                    <p>{obj6?.Desc26}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc26(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload6"
                    accept="image/*"
                    onChange={(e) => {
                      setLink26(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add6 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat6();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow5}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}{" "}
          </div>
          <Modal size="lg" show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria05_5.2.3</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="row">
                  <div className="row">
                    <div className="col-md-4 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Sl.no.
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={No1}
                            onChange={(e) => {
                              setNo1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{No1}</>
                      )}
                    </div>
                    <div className="col-md-8 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Registration number/roll number for the exam
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={RollNo1}
                            onChange={(e) => {
                              setRollNo1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{RollNo1}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        NameS of students selected/ qualified
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={StdSelected1}
                            onChange={(e) => {
                              setStdSelected1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{StdSelected1}</>
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        NET
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={NET1}
                            onChange={(e) => {
                              setNET1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{NET1}</>
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        SLET
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={SLET1}
                            onChange={(e) => {
                              setSLET1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{SLET1}</>
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        GATE
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={GATE1}
                            onChange={(e) => {
                              setGATE1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{GATE1}</>
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        GMAT
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={GMAT1}
                            onChange={(e) => {
                              setGMAT1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{GMAT1}</>
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        CAT
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={CAT1}
                            onChange={(e) => {
                              setCAT1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{CAT1}</>
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        GRE
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={GRE1}
                            onChange={(e) => {
                              setGRE1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{GRE1}</>
                      )}
                    </div>

                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        JAM
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={JAM1}
                            onChange={(e) => {
                              setJAM1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{JAM1}</>
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        IELTS
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={IELTS1}
                            onChange={(e) => {
                              setIELTS1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{IELTS1}</>
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        TOEFL
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={TOEFL1}
                            onChange={(e) => {
                              setTOEFL1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{TOEFL1}</>
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Civil Services
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Civil1}
                            onChange={(e) => {
                              setCivil1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Civil1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        State government examinations
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={SGExam1}
                            onChange={(e) => {
                              setSGExam1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{SGExam1}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Other examinations conducted by the State / Central
                        Government Agencies (Specify)
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="a"
                            name="fname"
                            id="name"
                            placeholder={OtherExam1}
                            onChange={(e) => {
                              setOtherExam1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{OtherExam1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Total1}
                            onChange={(e) => {
                              setTotal1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Total1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Grand Total
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={GTotal1}
                            onChange={(e) => {
                              setGTotal1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{GTotal1}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc26}
                            onChange={(e) => setDesc26(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc26}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload6">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit6 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="email"
                            id="upload6"
                            accept="image/*"
                            onChange={(e) => {
                              setLink26(e.target.files[0]);
                            }}
                          />
                        </>
                      ) : (
                        <>{Link26?.name}</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit6(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  cat6();
                  handleClose5();
                }}
                style={{ color: "white" }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "20px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_5 (5.3.1)</h5>
          <div className="row">
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="name">
                {" "}
                Name of the award/ medal
              </label>
              {obj7?.Meda ? (
                <p>{obj7?.Meda}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="fname"
                  id="name"
                  onChange={(e) => {
                    setMeda(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Team / Individual
              </label>
              {obj7?.TIndi ? (
                <p>{obj7?.TIndi}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setTIndi(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                University/State/National/ International
              </label>

              {obj7?.USNI1 ? (
                <p>{obj7?.USNI1}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setUSNI1(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Sports/ Cultural
              </label>
              {obj7?.SCult ? (
                <p>{obj7?.SCult}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setSCult(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Name of the student
              </label>
              {obj7?.NaStd ? (
                <p>{obj7?.NaStd}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setNaStd(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj7?.Desc27 ? (
                <p>{obj7?.Desc27}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  // cols={77}
                  rows={3}
                  onChange={(e) => {
                    setDesc27(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload7">
                {" "}
                Upload the relevant document
              </label>
              <br />

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload7"
                accept="image/*"
                onChange={(e) => {
                  setLink27(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add7 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat7();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow6}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}{" "}
          </div>
          <Modal size="lg" show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria05__(5.3.1)</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/ medal
                  </label>
                  <br />
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Meda}
                        onChange={(e) => {
                          setMeda(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Meda}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Team / Individual
                  </label>
                  <br />
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={TIndi}
                        onChange={(e) => {
                          setTIndi(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{TIndi}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    University/State/National/ International
                  </label>
                  <br />
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={USNI1}
                        onChange={(e) => {
                          setUSNI1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{USNI1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Sports/ Cultural
                  </label>
                  <br />
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={SCult}
                        onChange={(e) => {
                          setSCult(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{SCult}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student
                  </label>
                  <br />
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={NaStd}
                        onChange={(e) => {
                          setNaStd(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{NaStd}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Desc27}
                        onChange={(e) => setDesc27(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Desc27}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload7">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload7"
                        accept="image/*"
                        onChange={(e) => {
                          setLink27(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Link27?.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit7(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  cat7();
                  handleClose6();
                }}
                style={{ color: "white" }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="container mt-3">
        <div
          className="row-fluid  border "
          style={{
            padding: "50px",
            borderRadius: "20px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Criteria_5 (5.3.3)</h5>
          <div className="row">
            <div className="col-md-12 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Name of the student participated
              </label>
              {obj8?.NStdParti ? (
                <p>{obj8?.NStdParti}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setNStdParti(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Date of event/competition (DD-MM-YYYY)
              </label>
              {obj8?.EDate ? (
                <p>{obj8?.EDate}</p>
              ) : (
                <input
                  className="vi_0"
                  type="date"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setEDate(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-6 mt-3 mb-3">
              <label className="content1" htmlFor="email">
                {" "}
                Name of the event/competition
              </label>
              {obj8?.EName ? (
                <p>{obj8?.EName}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setEName(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj8?.Desc28 ? (
                <p>{obj8?.Desc28}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  // cols={87}
                  rows={3}
                  onChange={(e) => {
                    setDesc28(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload8">
                {" "}
                Upload the relevant document
              </label>
              <br />

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload8"
                accept="image/*"
                onChange={(e) => {
                  setLink28(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="d-flex " style={{ float: "right", gap: "10px" }}>
            {add8 ? (
              <button
                type="submit"
                className="btn btn-success mt-3 "
                onClick={() => {
                  cat8();
                }}
              >
                ADD
              </button>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-warning"
                  onClick={handleShow7}
                  style={{ color: "black", marginTop: "15px" }}
                >
                  Preview
                </button>
              </>
            )}{" "}
          </div>
          <Modal size="lg" show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria05__(5.3.3)</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student participated
                  </label>
                  <br />
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={NStdParti}
                        onChange={(e) => setNStdParti(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{NStdParti}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date of event/competition (DD-MM-YYYY)
                  </label>
                  <br />
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={EDate}
                        onChange={(e) => setEDate(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{EDate}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the event/competition
                  </label>
                  <br />
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={EName}
                        onChange={(e) => setEName(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{EName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Desc28}
                        onChange={(e) => setDesc28(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Desc28}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload8">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload8"
                        accept="image/*"
                        onChange={(e) => {
                          setLink28(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Link28?.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit8(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  cat8();
                  handleClose7();
                }}
                style={{ color: "white" }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
      </div>

      <div className="text-center mt-3 mb-3">
        <button
          type="submit"
          className="btn btn-success "
          onClick={() => ugcriteria5()}
          style={{ color: "white", marginTop: "15px" }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default UG_Criteria005;
