import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Nav } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import { AiOutlineEye, AiOutlineRadarChart } from "react-icons/ai";
const Criteria1 = () => {
  let principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const navigate = useNavigate();
  const [View, setView] = useState({});
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");

  // =================================================================
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getautocri01details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.autocriteria01);
        // setdata1(res.data.autocriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria01);
    }
  };

  useEffect(() => {
    getContent();
  }, []);
  console.log(AllDetails);

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaA1/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);

          getContent();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //   ======================================================

  useEffect(() => {
    principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);

  return (
    <div>
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-01
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className="col-lg-12">
            <Nav defaultActiveKey="/autocriteria01">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/A_01_112")}
                >
                  <Button className="criteriabtn btn-sub1">1.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/A_01_121")}
                >
                  <Button className="criteriabtn btn-sub">1.1.3 & 1.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_01_132")}
                >
                  <Button className="criteriabtn btn-sub">1.3.2 & 1.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_01_134")}
                >
                  <Button className="criteriabtn btn-sub">1.3.4</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 1.1.2 */}
                  <th>Criteria</th>
                  <th>Programme Code</th>
                  <th>Program Name</th>
                  <th>Year of Introduction</th>
                  <th>Status of implementation of CBCS / ECS</th>
                  <th>Year of implementation of CBCS / ECS</th>
                  <th>Year of revision (if any)</th>
                  <th>
                    If revision has been carried out in the syllabus during last
                    5 years, Percentage of content added or replaced
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 1.1.3 & 1.2.1 */}
                  <th>Criteria</th>
                  <th>Name of the Course</th>
                  <th>Course Code</th>
                  <th>
                    Activities/Content with direct bearing on Employability/
                    Entrepreneurship/ Skill development
                  </th>
                  <th>Year Of Introduction</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 1.3.2 & 1.3.3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the value added courses offered (with 30 or more
                    contact hours)
                  </th>
                  <th>Course Code</th>
                  <th>Year of offering</th>
                  <th>No. of times offered during the same year</th>
                  <th>Duration of the course</th>
                  <th>Number of students enrolled in the year</th>
                  <th>Number of Students completing course in the year</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 1.3.4 */}
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Name of the students</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    principalDetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    (item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Auto_Criteria01_112?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_Pcode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_Pname?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_YOInt?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_SOInt?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_YOImple?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_Revi?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_YRevi?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_NCourse?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_CCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_Activities?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_YOIntro?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Name?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Course?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_YOOff?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Times?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_CDurat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Number1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Number2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_Prg2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_Prg1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_Name1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_Desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Auto_Year}</td>

                      {/* 1.1.2 */}
                      <td>{item?.Auto_Criteria01_112?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_Pcode}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_Pname}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_YOInt}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_SOInt}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_YOImple}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_YRevi}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_Revi}</td>
                      <td>
                        {item?.Auto_Criteria01_112?.Auto_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 1.1.3 & 1.2.1 */}
                      <td>{item?.Auto_Criteria01_113?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria01_113?.Auto_NCourse}</td>
                      <td>{item?.Auto_Criteria01_113?.Auto_CCode}</td>
                      <td>{item?.Auto_Criteria01_113?.Auto_Activities}</td>
                      <td>{item?.Auto_Criteria01_113?.Auto_YOIntro}</td>

                      <td>
                        {item?.Auto_Criteria01_113?.Auto_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 1.3.2 & 1.3.3 */}
                      <td>{item?.Auto_Criteria01_132?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Name}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Course}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_YOOff}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Times}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_CDurat}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Number1}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Number2}</td>

                      <td>
                        {item?.Auto_Criteria01_132?.Auto_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 1.3.4 */}
                      <td>{item?.Auto_Criteria01_134?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria01_134?.Auto_Prg1}</td>
                      <td>{item?.Auto_Criteria01_134?.Auto_Prg2}</td>
                      <td>{item?.Auto_Criteria01_134?.Auto_Name1}</td>

                      <td>
                        {item?.Auto_Criteria01_134?.Auto_Desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td style={{ border: "" }}>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          {/* <i class="fa-solid fa-check fa-lg" style={{color: "#26a269"}}></i> */}
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td style={{ border: "" }}>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td style={{ border: "" }}>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria01/${View?.Auto_Criteria01_112?.Auto_Link1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria01/${View?.Auto_Criteria01_113?.Auto_Link2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria01/${View?.Auto_Criteria01_132?.Auto_Link3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria01/${View?.Auto_Criteria01_134?.Auto_Link4}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria01_112?.Auto_Desc1}</div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose5}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria01_113?.Auto_Desc2}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose6}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria01_132?.Auto_Desc3}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria01_134?.Auto_Desc4}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose8}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                Autonomous_Criteria-01
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------1.1.3------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.2 & 1.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  <>{Value?.Auto_Criteria01_112?.Auto_Pname}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Status of implementation of CBCS / ECS
                  </label>

                  <>{Value?.Auto_Criteria01_112?.Auto_SOInt}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Programme code
                  </label>

                  <>{Value?.Auto_Criteria01_112?.Auto_Pcode}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Introduction
                  </label>

                  <>{Value?.Auto_Criteria01_112?.Auto_YOInt}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of revision (if any)
                  </label>

                  <>{Value?.Auto_Criteria01_112?.Auto_YRevi}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of implementation of CBCS / ECS
                  </label>

                  <>{Value?.Auto_Criteria01_112?.Auto_YOImple}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    If revision has been carried out in the syllabus during last
                    5 years, Percentage of content added or replaced
                  </label>

                  <>{Value?.Auto_Criteria01_112?.Auto_Revi}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria01_112?.Auto_Desc1}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria01_112?.Auto_Link1}</>
                </div>
              </div>
            </div>
            {/* ------1.1.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.3 & 1.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code
                  </label>

                  <>{Value?.Auto_Criteria01_113?.Auto_CCode}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Course
                  </label>

                  <>{Value?.Auto_Criteria01_113?.Auto_NCourse}</>
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year Of Introduction
                  </label>

                  <>{Value?.Auto_Criteria01_113?.Auto_YOIntro}</>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Activities/Content with direct bearing on Employability/
                    Entrepreneurship/ Skill development
                  </label>

                  <>{Value?.Auto_Criteria01_113?.Auto_Activities}</>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria01_113?.Auto_Desc2}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria01_113?.Auto_Link2}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.2 & 1.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the value added courses offered (with 30 or more
                    contact hours)
                  </label>

                  <>{Value?.Auto_Criteria01_132?.Auto_Name}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code (if any)
                  </label>

                  <>{Value?.Auto_Criteria01_132?.Auto_Course}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of offering
                  </label>

                  <>{Value?.Auto_Criteria01_132?.Auto_YOOff}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    No. of times offered during the same year
                  </label>

                  <>{Value?.Auto_Criteria01_132?.Auto_Times}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of course
                  </label>

                  <>{Value?.Auto_Criteria01_132?.Auto_CDurat}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students enrolled in the year
                  </label>

                  <>{Value?.Auto_Criteria01_132?.Auto_Number1}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students completing the course in the year
                  </label>

                  <>{Value?.Auto_Criteria01_132?.Auto_Number2}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria01_132?.Auto_Desc3}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria01_132?.Auto_Link3}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.4
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  <>{Value?.Auto_Criteria01_134?.Auto_Prg1}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  <>{Value?.Auto_Criteria01_134?.Auto_Prg2}</>
                </div>

                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the students
                  </label>

                  <>{Value?.Auto_Criteria01_134?.Auto_Name1}</>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria01_134?.Auto_Desc4}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria01_134?.Auto_Link4}</>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  className="btn btn-success"
                  onClick={(e) => {
                    approveCriteria(e, true, Value?._id);
                    handleClose();
                  }}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  className="btn btn-danger"
                  onClick={() => setSmShow(true)}
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => handleClosesm()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              onChange={(e) => setremark(e.target.value)}
              style={{ height: "200px" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                approveCriteria(e, false, Value?._id);
                handleClose();
                handleClosesm();
              }}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Criteria1;
