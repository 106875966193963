import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Nav from "react-bootstrap/Nav";

import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye, AiOutlineRadarChart } from "react-icons/ai";
import moment from "moment";

const UCriteria3 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [selectedYear, setselectedYear] = useState("");

  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);
  const [Value, setValue] = useState({});
  const [View, setView] = useState({});
  const [remark, setremark] = useState("");
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  // ================Show doc17 modal======================
  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  // ================Show doc18 modal======================
  const [show18, setShow18] = useState(false);
  const handleClose18 = () => setShow18(false);
  const handleShow18 = () => setShow18(true);

  // ================Show doc19 modal======================
  const [show19, setShow19] = useState(false);
  const handleClose19 = () => setShow19(false);
  const handleShow19 = () => setShow19(true);

  // ================Show doc20 modal======================
  const [show20, setShow20] = useState(false);
  const handleClose20 = () => setShow20(false);
  const handleShow20 = () => setShow20(true);

  // ================Show doc21 modal======================
  const [show21, setShow21] = useState(false);
  const handleClose21 = () => setShow21(false);
  const handleShow21 = () => setShow21(true);

  // ================Show doc22 modal======================
  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);

  // ================Show doc23 modal======================
  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);
  const handleShow23 = () => setShow23(true);

  // ================Show doc24 modal======================
  const [show24, setShow24] = useState(false);
  const handleClose24 = () => setShow24(false);
  const handleShow24 = () => setShow24(true);

  // ================Show doc25 modal======================
  const [show25, setShow25] = useState(false);
  const handleClose25 = () => setShow25(false);
  const handleShow25 = () => setShow25(true);

  // ================Show doc26 modal======================
  const [show26, setShow26] = useState(false);
  const handleClose26 = () => setShow26(false);
  const handleShow26 = () => setShow26(true);

  // ================Show doc27 modal======================
  const [show27, setShow27] = useState(false);
  const handleClose27 = () => setShow27(false);
  const handleShow27 = () => setShow27(true);

  // ================Show doc28 modal======================
  const [show28, setShow28] = useState(false);
  const handleClose28 = () => setShow28(false);
  const handleShow28 = () => setShow28(true);

  // ================Show doc29 modal======================
  const [show29, setShow29] = useState(false);
  const handleClose29 = () => setShow29(false);
  const handleShow29 = () => setShow29(true);

  // ================Show doc30 modal======================
  const [show30, setShow30] = useState(false);
  const handleClose30 = () => setShow30(false);
  const handleShow30 = () => setShow30(true);

  // ================Show doc31 modal======================
  const [show31, setShow31] = useState(false);
  const handleClose31 = () => setShow31(false);
  const handleShow31 = () => setShow31(true);

  // ================Show doc32 modal======================
  const [show32, setShow32] = useState(false);
  const handleClose32 = () => setShow32(false);
  const handleShow32 = () => setShow32(true);

  // ================Show doc33 modal======================
  const [show33, setShow33] = useState(false);
  const handleClose33 = () => setShow33(false);
  const handleShow33 = () => setShow33(true);

  // ================Show doc34 modal======================
  const [show34, setShow34] = useState(false);
  const handleClose34 = () => setShow34(false);
  const handleShow34 = () => setShow34(true);

  //integrating get method
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [AllDetails, setAllDetails] = useState([]);
  const getunicri03details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getunicri03details"
      );
      if (res.status == 200) {
        setAllDetails(res.data.unicriteria03);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria03);
    }
  };

  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getunicri03details();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaUn_3/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-03
              </span>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="ad-b  row align-items-center m-0 mt-4 p-0">
          <div className="">
            <Nav defaultActiveKey="/u-criteria3">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/Uni_03_312")}
                >
                  <Button className="criteriabtn btn-sub1">3.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/Uni_03_313")}
                >
                  <Button className="criteriabtn btn-sub">3.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_314")}
                >
                  <Button className="criteriabtn btn-sub">3.1.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_316")}
                >
                  <Button className="criteriabtn btn-sub">
                    3.1.6 & 3.2.1 & 3.2.2 & 3.2.4
                  </Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  className="p-0"
                  onClick={() => navigate("/Uni_03_332")}
                >
                  <Button className="criteriabtn btn-sub">3.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_333")}
                >
                  <Button className="criteriabtn btn-sub">3.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_343")}
                >
                  <Button className="criteriabtn btn-sub">3.4.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_344")}
                >
                  <Button className="criteriabtn btn-sub">3.4.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_345")}
                >
                  <Button className="criteriabtn btn-sub">3.4.5</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_346")}
                >
                  <Button className="criteriabtn btn-sub">3.4.6</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_347")}
                >
                  <Button className="criteriabtn btn-sub">3.4.7</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_352")}
                >
                  <Button className="criteriabtn btn-sub">
                    3.5.2 & 3.5.2.1
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_362")}
                >
                  <Button className="criteriabtn btn-sub">3.6.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_363")}
                >
                  <Button className="criteriabtn btn-sub">3.6.3 & 3.6.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/Uni_03_371")}
                >
                  <Button className="criteriabtn btn-sub">3.7.1 </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  className="p-0"
                  onClick={() => navigate("/Uni_03_372")}
                >
                  <Button className="criteriabtn btn-sub">3.7.2 </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "450000p0x" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  {/* 3.1.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher provided with seed money</th>
                  <th>The amount of seed money (INR in Lakhs)</th>
                  <th>Year of receiving</th>
                  <th>Description</th>
                  <th>Document</th>
                  {/* 3.1.3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </th>
                  <th>Name of the award/fellowship</th>
                  <th>Year of Award</th>
                  <th>Awarding Agency</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.1.4 */}
                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Name of the Research fellow</th>
                  <th>Year of enrollment</th>
                  <th>Duration of fellowship</th>
                  <th>Type of the fellowship</th>
                  <th>Granting agency</th>
                  <th>Qualifying exam if any (NET, GATE, etc.)</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.1.6 */}
                  <th>Criteria</th>
                  <th>Name of the Scheme/Project/ Endowments/ Chairs</th>
                  <th>
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </th>
                  <th>Name of the Funding agency</th>
                  <th>Type (Government/Non-Government)</th>
                  <th>Department</th>
                  <th>Year of Award</th>
                  <th>Funds provided (INR in lakhs)</th>
                  <th>Duration of the project</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.3.2 */}
                  <th>Criteria</th>
                  <th>Name of the workshop/ seminar</th>
                  <th>Number of the students</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.3.3 */}
                  <th>Criteria</th>
                  <th>Year of Award</th>
                  <th>Title of the innovation</th>
                  <th>Name of the Awardee</th>
                  <th>Name of the Awarding Agency with contact details</th>
                  <th>
                    Category- institution/teacher/research scholar/student
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.3 */}
                  <th>Criteria</th>
                  <th>Name of the Teacher</th>
                  <th>Patent Number</th>
                  <th>Title of the patent</th>
                  <th>Year of Award / publish of patent</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.4 */}
                  <th>Criteria</th>
                  <th> Name of the PhD scholar</th>
                  <th>Name of the Department</th>
                  <th>Name of the guide/s</th>
                  <th>Title of the thesis</th>
                  <th>Year of registration of the scholar</th>
                  <th>Year of PhD awarded</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Lit (YES/NO)
                  </th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.5 */}
                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Department of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>Document 1</th>
                  <th>Document 2</th>

                  {/* 3.4.6 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </th>
                  <th>Description (min 500 characters)</th>
                  <th>Link the relevant document</th>

                  {/* 3.4.7 */}
                  <th>Criteria</th>
                  <th>Name of the teacher</th>
                  <th>Name of the module developed</th>
                  <th>Platform on which module is developed</th>
                  <th>Date of launching e content</th>
                  <th>List of the e-content development facility available</th>
                  <th>Description (min 500 characters)</th>
                  <th>
                    Link to the relevant document and facility available in the
                    institution
                  </th>
                  <th>
                    Provide link to videos of the media centre and recording
                    facility
                  </th>

                  {/* 3.5.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher-consultants</th>
                  <th>Name of consultancy project</th>
                  <th>Consulting/Sponsoring agency with contact details</th>
                  <th>Revenue generated (INR in Lakhs)</th>
                  <th>Names of the teacher-consultants/corporate trainers</th>
                  <th>Title of the corporate training program</th>
                  <th>Agency seeking training with contact details</th>
                  <th>Revenue generated (amount in rupees)</th>
                  <th>Number of trainees</th>
                  <th>Description (min 500 characters)</th>
                  <th>Link the relevant document</th>

                  {/* 3.6.2 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Name of the Award/ recognition</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Year of award</th>
                  <th>Description (min 500 characters)</th>
                  <th>Link the relevant document</th>

                  {/* 3.6.3 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of activity</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description (min 500 characters)</th>
                  <th>Link the relevant document</th>

                  {/* 3.7.1 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the collaborating agency with contact details</th>
                  <th>Title of the collaborative activity</th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Nature of the activity</th>
                  <th>Description (min 500 characters)</th>
                  <th>Link the relevant document</th>

                  {/* 3.7.2 */}
                  <th>Criteria</th>
                  <th>
                    Name of the Organisation/institution/ industry with whom MoU
                    is signed
                  </th>
                  <th>Year of signing MoU</th>
                  <th>Duration of MoU</th>
                  <th>List the actual activities under each MOU year-wise</th>
                  <th>Description (min 500 characters)</th>
                  <th>Link the relevant document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toLowerCase())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    (item1?.approve === "false" ||
                      item1?.approve === "Pending") &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria03_312?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_Seed?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_SeedMoney?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_Receiving?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_FellowShip?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_AFellowship?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_Award?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_Agency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Sno?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Research?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Enroll?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Fellow?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Fellow1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Grant?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Endowments?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Invest?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_FAgency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Dep?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Award1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Project?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_Year1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_Seminar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_Parti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Uni_Criteria03_332?.Uni_DFrom)
                        .format("DD-MM-YYYY")
                        ?.toLowerCase()
                        ?.includes(SearchItem) ||
                      moment(item1?.Uni_Criteria03_332?.Uni_DTo)
                        .format("DD-MM-YYYY")
                        ?.toLowerCase()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria03_332?.Uni_Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_YAw?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Innova?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Awardee?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Details?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Desc17?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Teacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Patent?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Title?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Phd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Depar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Guide?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Thesis1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Scholar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Whether1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Recog?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Paper?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Author?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Dept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Journal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Pub?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_ISSN?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Desc9?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_SLno?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Name?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Book?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Paper1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Title4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Year2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_ISBN?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Name1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Teacher1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Module?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Platform?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Launch?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_List1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Desc20?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Consult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Project1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Agency1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Revenue?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Consult2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Title1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Agency3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Revenue1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Trainee?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Name2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Name3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Name4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Year4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Name5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_org?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Scheme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Year5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Participants1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Desc13?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_SLNO1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Colloborative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Agency2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Participants3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Collo?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Durat?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Activity?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_IIC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Signing?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Durat1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Uni_Year}</td>

                      {/* 3.1.2 */}
                      <td>{item?.Uni_Criteria03_312?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_312?.Uni_Seed}</td>
                      <td>{item?.Uni_Criteria03_312?.Uni_SeedMoney}</td>
                      <td>{item?.Uni_Criteria03_312?.Uni_Receiving}</td>
                      <td>
                        {item?.Uni_Criteria03_312?.Uni_Desc5?.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow19();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.3 */}
                      <td>{item?.Uni_Criteria03_313?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_FellowShip}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_AFellowship}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_Award}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_Agency}</td>
                      <td>
                        {item?.Uni_Criteria03_313?.Uni_Desc6.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow20();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.4 */}
                      <td>{item?.Uni_Criteria03_314?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Sno}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Research}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Enroll}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Fellow}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Fellow1}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Grant}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Qualify}</td>

                      <td>
                        {item?.Uni_Criteria03_314?.Uni_Desc23.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow21();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.6 */}
                      <td>{item?.Uni_Criteria03_316?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Endowments}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Invest}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_FAgency}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Type}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Dep}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Award1}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Funds}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Project}</td>

                      <td>
                        {item?.Uni_Criteria03_316?.Uni_Desc7.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow22();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.3.2 */}
                      <td>{item?.Uni_Criteria03_332?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_332?.Uni_Seminar}</td>
                      <td>{item?.Uni_Criteria03_332?.Uni_Parti}</td>
                      <td>{item?.Uni_Criteria03_332?.Uni_DFrom}</td>
                      <td>{item?.Uni_Criteria03_332?.Uni_DTo}</td>

                      <td>
                        {item?.Uni_Criteria03_332?.Uni_Desc8.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow23();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.3.3 */}
                      <td>{item?.Uni_Criteria03_333?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_YAw}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Innova}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Awardee}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Details}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Category}</td>

                      <td>
                        {item?.Uni_Criteria03_333?.Uni_Desc17.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow24();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.3 */}
                      <td>{item?.Uni_Criteria03_343?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_Teacher}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_Patent}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_Title}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_YOAw}</td>

                      <td>
                        {item?.Uni_Criteria03_343?.Uni_Desc18.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow25();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.4 */}
                      <td>{item?.Uni_Criteria03_344?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Phd}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Depar}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Guide}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Thesis1}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Scholar}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Phd1}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Whether1}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Recog}</td>

                      <td>
                        {item?.Uni_Criteria03_344?.Uni_Desc19.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow26();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.5 */}
                      <td>{item?.Uni_Criteria03_345?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Paper}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Author}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Dept}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Journal}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Pub}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_ISSN}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_ISIT}</td>
                      <td>
                        {item?.Uni_Criteria03_345?.Uni_Desc9.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow27();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.6 */}
                      <td>{item?.Uni_Criteria03_346?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_SLno}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Name}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Book}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Paper1}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Title4}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Year2}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_ISBN}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Name1}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Whether}</td>

                      <td>
                        {item?.Uni_Criteria03_346?.Uni_Desc10.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow28();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.7 */}
                      <td>{item?.Uni_Criteria03_347?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Teacher1}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Module}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Platform}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Launch}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_List1}</td>
                      <td>
                        {item?.Uni_Criteria03_347?.Uni_Desc20.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow29();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.5.2 */}
                      <td>{item?.Uni_Criteria03_352?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Consult}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Project1}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Agency1}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Revenue}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Consult2}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Title1}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Agency3}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Revenue1}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Trainee}</td>

                      <td>
                        {item?.Uni_Criteria03_352?.Uni_Desc21.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow30();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.2 */}
                      <td>{item?.Uni_Criteria03_362?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Name2}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Name3}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Name4}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Year4}</td>

                      <td>
                        {item?.Uni_Criteria03_362?.Uni_Desc12.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow31();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.3 */}
                      <td>{item?.Uni_Criteria03_363?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Name5}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_org}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Scheme}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Year5}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Participants1}</td>

                      <td>
                        {item?.Uni_Criteria03_363?.Uni_Desc13.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow32();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.1 */}
                      <td>{item?.Uni_Criteria03_371?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_SLNO1}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Agency2}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Colloborative}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Participants3}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Collo}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Durat}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Activity}</td>

                      <td>
                        {item?.Uni_Criteria03_371?.Uni_Desc14.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow33();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow17();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.2 */}
                      <td>{item?.Uni_Criteria03_372?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_Organ}</td>
                      {/* <td>{item?.Uni_Criteria03_372?.Uni_IIC}</td> */}
                      <td>{item?.Uni_Criteria03_372?.Uni_Signing}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_Durat1}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_List}</td>

                      <td>
                        {item?.Uni_Criteria03_372?.Uni_Desc15.slice(0, 70)}

                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow34();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow18();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              {" "}
              <span className="modal-head-text">
                University_Criteria-03
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------3.1.2------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the teacher provided with seed money
                  </label>

                  <>{Value?.Uni_Criteria03_312?.Uni_Seed}</>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    The amount of seed money (INR in Lakhs)
                  </label>

                  <>{Value?.Uni_Criteria03_312?.Uni_SeedMoney}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of receiving
                  </label>

                  <>{Value?.Uni_Criteria03_312?.Uni_Receiving}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_312?.Uni_Desc5}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_312?.Uni_Link5}</>
                </div>
              </div>
            </div>

            {/* ------3.1.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </label>

                  <>{Value?.Uni_Criteria03_313?.Uni_FellowShip}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/fellowship
                  </label>

                  <>{Value?.Uni_Criteria03_313?.Uni_AFellowship}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Award
                  </label>

                  <>{Value?.Uni_Criteria03_313?.Uni_Award}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Awarding Agency
                  </label>

                  <>{Value?.Uni_Criteria03_313?.Uni_Agency}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_313?.Uni_Desc6}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_313?.Uni_Link6}</>
                </div>
              </div>
            </div>

            {/* ------3.1.4-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.4
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-2 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl.no.
                  </label>

                  <>{Value?.Uni_Criteria03_314?.Uni_Sno}</>
                </div>
                <div className="col-md-5 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Research fellow
                  </label>

                  <>{Value?.Uni_Criteria03_314?.Uni_Research}</>
                </div>
                <div className="col-md-5 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of enrollment
                  </label>

                  <>{Value?.Uni_Criteria03_314?.Uni_Enroll}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of fellowship
                  </label>

                  <>{Value?.Uni_Criteria03_314?.Uni_Fellow}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Type of the fellowship
                  </label>

                  <>{Value?.Uni_Criteria03_314?.Uni_Fellow1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Granting agency
                  </label>

                  <>{Value?.Uni_Criteria03_314?.Uni_Grant}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualifying exam if any (NET, GATE, etc.)
                  </label>

                  <>{Value?.Uni_Criteria03_314?.Uni_Qualify}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_314?.Uni_Desc23}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_314?.Uni_Link23}</>
                </div>
              </div>
            </div>

            {/* ------3.1.6-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.6
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Scheme/Project/ Endowments/ Chairs
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_Endowments}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_Invest}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Funding agency
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_FAgency}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Type (Government/Non-Government)
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_Type}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_Dep}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_Award1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Funds provided (INR in lakhs)
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_Funds}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of the project
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_Project}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_Desc7}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_316?.Uni_Link7}</>
                </div>
              </div>
            </div>

            {/* ------3.3.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the workshop/ seminar
                  </label>

                  <>{Value?.Uni_Criteria03_332?.Uni_Seminar}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of the students
                  </label>

                  <>{Value?.Uni_Criteria03_332?.Uni_Parti}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date (From)
                  </label>

                  <>{Value?.Uni_Criteria03_332?.Uni_DFrom}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date (To)
                  </label>

                  <>{Value?.Uni_Criteria03_332?.Uni_DTo}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_332?.Uni_Desc8}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_332?.Uni_Link8}</>
                </div>
              </div>
            </div>

            {/* ------3.3.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award
                  </label>

                  <>{Value?.Uni_Criteria03_333?.Uni_YAw}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the innovation
                  </label>

                  <>{Value?.Uni_Criteria03_333?.Uni_Innova}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awardee
                  </label>

                  <>{Value?.Uni_Criteria03_333?.Uni_Awardee}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awarding Agency with contact details
                  </label>

                  <>{Value?.Uni_Criteria03_333?.Uni_Details}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category- institution/teacher/research scholar/student
                  </label>

                  <>{Value?.Uni_Criteria03_333?.Uni_Category}</>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_333?.Uni_Desc17}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_333?.Uni_Link17}</>
                </div>
              </div>
            </div>

            {/* ------3.4.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Teacher
                  </label>

                  <>{Value?.Uni_Criteria03_343?.Uni_Teacher}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Patent Number
                  </label>

                  <>{Value?.Uni_Criteria03_343?.Uni_Patent}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the patent
                  </label>

                  <>{Value?.Uni_Criteria03_343?.Uni_Title}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award / publish of patent
                  </label>

                  <>{Value?.Uni_Criteria03_343?.Uni_YOAw}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_343?.Uni_Desc18}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_343?.Uni_Link18}</>
                </div>
              </div>
            </div>

            {/* ------3.4.4-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.4
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the PhD scholar
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Phd}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Depar}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the guide/s
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Guide}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the thesis
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Thesis1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of registration of the scholar
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Scholar}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of PhD awarded
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Phd1}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./DLit (YES/NO)
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Whether1}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of PhD awarded
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Recog}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Desc9}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_344?.Uni_Link19}</>
                </div>
              </div>
            </div>

            {/* ------3.4.5-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.5
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of paper
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_Paper}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the author/s
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_Author}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of the teacher
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_Dept}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of journal
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_Journal}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of publication
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_Pub}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ISSN number
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_ISSN}</>
                </div>
                <h6>
                  Link to the recognition in UGC enlistment of the Journal{" "}
                </h6>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_ISIT}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_Desc9}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to website of the Journal
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_Link9}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to article/paper/abstract of the article
                  </label>

                  <>{Value?.Uni_Criteria03_345?.Uni_Link10}</>
                </div>
              </div>
            </div>

            {/* ------3.4.6-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.6
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_SLno}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_Name}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the book/chapters published
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_Book}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the paper
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_Paper1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the proceedings of the conference
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_Title4}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of publication
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_Year2}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ISBN/ISSN number of the proceeding
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_ISBN}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the publisher
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_Name1}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_Whether}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_Desc10}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_346?.Uni_Link11}</>
                </div>
              </div>
            </div>

            {/* ------3.4.7-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.7
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher
                  </label>

                  <>{Value?.Uni_Criteria03_347?.Uni_Teacher1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the module developed
                  </label>

                  <>{Value?.Uni_Criteria03_347?.Uni_Module}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Platform on which module is developed
                  </label>

                  <>{Value?.Uni_Criteria03_347?.Uni_Platform}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of launching e content
                  </label>

                  <>{Value?.Uni_Criteria03_347?.Uni_Launch}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    List of the e-content development facility available
                  </label>

                  <>{Value?.Uni_Criteria03_347?.Uni_List1}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_347?.Uni_Desc20}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to the relevant document and facility available in the
                    institution
                  </label>

                  <>{Value?.Uni_Criteria03_347?.Uni_Link20}</>
                </div>
              </div>
            </div>

            {/* ------3.5.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.5.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <h6 className="text-center text-decoration-underline">
                  Revenue generated from consultancy during the last five years
                </h6>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher consultant
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Consult}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of consultancy project
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Project1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Consulting/Sponsoring agency with contact details
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Agency1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Revenue generated (INR in Lakhs)
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Revenue}</>
                </div>
                <h6 className="text-center text-decoration-underline">
                  Revenue generated from corporate training during the last five
                  years
                </h6>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Names of the teacher-consultants/corporate trainers
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Consult2}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the corporate training program
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Title1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Agency seeking training with contact details
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Agency3}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Revenue generated (amount in rupees)
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Revenue1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of trainees
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Trainee}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Desc21}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_352?.Uni_Link22}</>
                </div>
              </div>
            </div>

            {/* ------3.6.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.6.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  <>{Value?.Uni_Criteria03_362?.Uni_Name2}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Award/ recognition
                  </label>

                  <>{Value?.Uni_Criteria03_362?.Uni_Name3}</>
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awarding government/ government recognised
                    bodies
                  </label>

                  <>{Value?.Uni_Criteria03_362?.Uni_Name4}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of award
                  </label>

                  <>{Value?.Uni_Criteria03_362?.Uni_Year4}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_362?.Uni_Desc12}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_362?.Uni_Link13}</>
                </div>
              </div>
            </div>

            {/* ------3.6.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.6.3 & 3.6.4
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  <>{Value?.Uni_Criteria03_363?.Uni_Name5}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Organising unit/ agency/ collaborating agency
                  </label>

                  <>{Value?.Uni_Criteria03_363?.Uni_org}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scheme
                  </label>

                  <>{Value?.Uni_Criteria03_363?.Uni_Scheme}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of the activity
                  </label>

                  <>{Value?.Uni_Criteria03_363?.Uni_Year5}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students participated in such activities
                  </label>

                  <>{Value?.Uni_Criteria03_363?.Uni_Participants1}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_363?.Uni_Desc13}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_363?.Uni_Link14}</>
                </div>
              </div>
            </div>

            {/* ------3.7.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  <>{Value?.Uni_Criteria03_371?.Uni_SLNO1}</>
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the collaborating agency with contact details
                  </label>

                  <>{Value?.Uni_Criteria03_371?.Uni_Agency2}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the collaborative activity
                  </label>

                  <>{Value?.Uni_Criteria03_371?.Uni_Colloborative}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the participant
                  </label>

                  <>{Value?.Uni_Criteria03_371?.Uni_Participants3}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of collaboration
                  </label>

                  <>{Value?.Uni_Criteria03_371?.Uni_Collo}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>

                  <>{Value?.Uni_Criteria03_371?.Uni_Durat}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of the activity
                  </label>

                  <>{Value?.Uni_Criteria03_371?.Uni_Activity}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_371?.Uni_Desc14}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_371?.Uni_Link15}</>
                </div>
              </div>
            </div>

            {/* ------3.7.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.7.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Organisation/institution/ industry with whom MoU
                    is signed
                  </label>

                  <>{Value?.Uni_Criteria03_372?.Uni_IIC}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of signing MoU
                  </label>

                  <>{Value?.Uni_Criteria03_372?.Uni_Signing}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of MoU
                  </label>

                  <>{Value?.Uni_Criteria03_372?.Uni_Durat1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    List the actual activities under each MOU year-wise
                  </label>

                  <>{Value?.Uni_Criteria03_372?.Uni_List}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Uni_Criteria03_372?.Uni_Desc15}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Uni_Criteria03_372?.Uni_Link16}</>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    approveCriteria(e, true, Value?._id);
                    handleClose();
                  }}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => handleClosesm()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              onChange={(e) => setremark(e.target.value)}
              style={{ height: "200px" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                approveCriteria(e, false, Value?._id);
                handleClose();
                handleClosesm();
              }}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_312?.Uni_Link5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_313?.Uni_Link6}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_314?.Uni_Link23}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_316?.Uni_Link7}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_332?.Uni_Link8}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_333?.Uni_Link17}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_343?.Uni_Link18}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_344?.Uni_Link19}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 9 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_345?.Uni_Link9}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 10 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_345?.Uni_Link10}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 11 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_346?.Uni_Link11}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 12 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_347?.Uni_Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 13 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_347?.Uni_Link21}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 14 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_352?.Uni_Link22}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 15 model */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_362?.Uni_Link13}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 16 model */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_363?.Uni_Link14}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 17 model */}
        <Modal show={show17} onHide={handleClose17}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_371?.Uni_Link15}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 18 model */}
        <Modal show={show18} onHide={handleClose18}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_372?.Uni_Link16}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show19} onHide={handleClose19}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_312?.Uni_Desc5}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show20} onHide={handleClose20}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_313?.Uni_Desc6}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show21} onHide={handleClose21}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_314?.Uni_Desc23}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show22} onHide={handleClose22}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_316?.Uni_Desc7}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show23} onHide={handleClose23}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_332?.Uni_Desc8}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show24} onHide={handleClose24}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_333?.Uni_Desc17}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show25} onHide={handleClose25}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_343?.Uni_Desc18}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show26} onHide={handleClose26}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_344?.Uni_Desc19}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show27} onHide={handleClose27}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_345?.Uni_Desc9}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show28} onHide={handleClose28}>
          <Modal.Header closeButton>
            <Modal.Title>Description (Criteria 3 3.1.3) </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_346?.Uni_Desc10}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show29} onHide={handleClose29}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_347?.Uni_Desc20}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show30} onHide={handleClose30}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_352?.Uni_Desc21}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show31} onHide={handleClose31}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_362?.Uni_Desc12}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show32} onHide={handleClose32}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_363?.Uni_Desc13}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show33} onHide={handleClose33}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_371?.Uni_Desc14}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show34} onHide={handleClose34}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria03_372?.Uni_Desc15}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UCriteria3;
