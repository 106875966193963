import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FiStar } from "react-icons/fi";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
const UnderGraduateCri1 = () => {
  const params = useParams();
  const navigate = useNavigate();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // show description model
  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // const [show, setShow] = useState(false);
  // const [smShow, setSmShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // const [Value, setValue] = useState({});
  // const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const ugCriteria01Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria01"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria01);
    }
  };

  // =================================================================

  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatingug1`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria01Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      ugCriteria01Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", params?.id);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-6 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-01
              </span>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="">Current Rating: </h6>
                <div className="mt-4">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            {/* <div className="col-lg-2 text-end">
              <Button
                type="Submit"
                className="btn btn-warning"
                style={{ color: "white" }}
                onClick={() => {
                  // setValue(item);
                  handleShow();
                }}
              >
                Add Rating
              </Button>
            </div> */}
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0 "
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              {allYears
                ?.sort((a, b) => {
                  return Number(b?.from) - Number(a?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  onClick={() => navigate(`/adminclgugcri113/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 1.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-2"
                  onClick={() => navigate(`/adminclgugcri121/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 1.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri122/${params?.id}`)}
                >
                  <Button className="criteriabtn">1.2.2 & 1.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-4"
                  onClick={() => navigate(`/adminclgugcri132/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 1.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-5"
                  onClick={() => navigate(`/adminclgugcri133/${params?.id}`)}
                >
                  <Button style={{}} className="criteriabtn">
                    {" "}
                    1.3.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4 ">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>
                  <th>Name of the teacher participated</th>
                  <th>
                    Name of the body in which full time teacher participated
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Program Code</th>
                  <th>Program Name</th>
                  <th>Year Of Introduction</th>
                  <th>Status of implementation of CBCS/elective course</th>
                  <th>Year of implementation of CBCS/elective course</th>
                  <th>Year of revision (if any)</th>
                  <th>
                    If revision has been carried out in the syllabus during the
                    last 5 years, Percentage of content added or replaced
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Name of Add on /Certificate programs offered</th>
                  <th>Course Code (if any)</th>
                  <th>Year of offering</th>
                  <th>No. of times offered during the same year</th>
                  <th>Duration of course</th>
                  <th>Number of students enrolled in the year</th>
                  <th>Number of Students completing the course in the year</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Year of offering</th>
                  <th>
                    Name of the student studied course on experiential learning
                    through project work/field work/internship
                  </th>
                  <th>
                    Name of the Course that include experiential learning
                    through project work/field work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    List of students undertaking project work/field
                    work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Year &&
                    (SearchItem === "" ||
                      item1?.Criteria01_113?.TeacherPar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_113?.BodyName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_113?.Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_121?.PrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_121?.YOfIntro?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.Status?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_121?.YOfCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.YOfRevision?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.Revision?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.Desc2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.CertiName?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_122?.CourseCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.YOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.NoOfTimes?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.CourseDuration?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.NoOfStudents?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.CourseCompletion?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.Desc3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_132?.PPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_132?.PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_132?.YYOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_132?.StdCourseStudied?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_132?.ExpCourse?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_132?.Desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_133?.PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_133?.PPPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_133?.StdList?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_133?.Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Year}</td>

                      <td>{item?.Criteria01_113?.TeacherPar}</td>
                      <td>{item?.Criteria01_113?.BodyName}</td>
                      <td>
                        {item?.Criteria01_113?.Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* <img
                          src={`https://brightnaac.co.in//${item?.Criteria01_113?.Link1}`}
                          alt="no-image"
                        /> */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria01_121?.PrgmCode}</td>
                      <td>{item?.Criteria01_121?.PrgName1}</td>
                      <td>{item?.Criteria01_121?.YOfIntro}</td>
                      <td>{item?.Criteria01_121?.Status}</td>
                      <td>{item?.Criteria01_121?.YOfCBCS}</td>
                      <td>{item?.Criteria01_121?.YOfRevision}</td>
                      <td>{item?.Criteria01_121?.Revision}</td>
                      <td>
                        {item?.Criteria01_121?.Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* <img
                          src={`https://brightnaac.co.in/UG_Criteria01/${item?.Criteria01_121?.Link2}`}
                          alt="img"
                          width={50}
                        /> */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria01_122?.CertiName}</td>
                      <td>{item?.Criteria01_122?.CourseCode}</td>
                      <td>{item?.Criteria01_122?.YOfOffering}</td>
                      <td>{item?.Criteria01_122?.NoOfTimes}</td>
                      <td>{item?.Criteria01_122?.CourseDuration}</td>
                      <td>{item?.Criteria01_122?.NoOfStudents}</td>
                      <td>{item?.Criteria01_122?.CourseCompletion}</td>
                      <td>
                        {item?.Criteria01_122?.Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria01_132?.PPrgmCode}</td>
                      <td>{item?.Criteria01_132?.PrgName2}</td>
                      <td>{item?.Criteria01_132?.YYOfOffering}</td>
                      <td>{item?.Criteria01_132?.StdCourseStudied}</td>
                      <td>{item?.Criteria01_132?.ExpCourse}</td>
                      <td>
                        {item?.Criteria01_132?.Desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* <img
                          src={`https://brightnaac.co.in/UG_Criteria01/${item?.Criteria01_132?.Link4}`}
                          alt="img"
                          width={50}
                        /> */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria01_133?.PrgName3}</td>
                      <td>{item?.Criteria01_133?.PPPrgmCode}</td>
                      <td>{item?.Criteria01_133?.StdList}</td>
                      <td>
                        {item?.Criteria01_132?.Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        {/* <img
                          src={`https://brightnaac.co.in/UG_Criteria01/${item?.Criteria01_133?.Link5}`}
                          alt="img"
                          width={50}
                        /> */}
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                        
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td> */}
                      {/* <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>

        <Modal size="sm" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Rating</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={50}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
            ,
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(e) => AddRating(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_113?.Link1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_121?.Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_122?.Link3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_132?.Link4}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_133?.Link5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Show description modal */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_113?.Desc1}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_121?.Desc2}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_122?.Desc3}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_132?.Desc4}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_132?.Desc5}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UnderGraduateCri1;
