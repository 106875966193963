import React from "react";

const PG2212aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <h6>
        Number of seats earmarked for reserved category as per GOI or State
        Government rule
      </h6>
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>SC</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria02_212?.PG_SC}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>ST</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_212?.PG_ST}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>OBC</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_212?.PG_OBC}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Gen</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_212?.PG_Gen}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Others</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_212?.PG_Others}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Divyangjan</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_212?.PG_Divyangjan}</span>
        </div>
      </div>

      <h6>Number of students admitted from the reserved category</h6>
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>SC</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria02_212?.PG_SSC}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>ST</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_212?.PG_SST}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>OBC</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_212?.PG_OOBC}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Gen</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_212?.PG_GGen}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Others</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_212?.PG_OOthers}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Divyangjan</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_212?.PG_DDivyangjan}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria02_212?.PG_Desc7}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/PG_Criteria02/${item?.PG_Criteria02_212?.PG_Link7}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG2212aqar;
