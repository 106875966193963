import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

function UniversityCri7() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================ description Show modal===============================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("University Criteria 7");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
          item1?.approve === "true" &&
          selectedYear === item1?.Uni_Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>Physical Facilities</td>
                <td>${item?.Uni_Criteria07_007?.Uni_PhyFac}</td>
              </tr>
              <tr>
                <td>Provision for Lift</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Prolift}</td>
              </tr>
              <tr>
                <td>Ramp/ Rails</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Ramp}</td>
              </tr>
              <tr>
                <td>Braille Software/Facilities</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Braille}</td>
              </tr>
              <tr>
                <td>Rest Rooms</td>
                <td>${item?.Uni_Criteria07_007?.Uni_RestRoom}</td>
              </tr>
              <tr>
                <td>Scribes for Examination</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Scribes}</td>
              </tr><tr>
              <td>Special skill Development for Differently Abled Students</td>
              <td>${item?.Uni_Criteria07_007?.Uni_SpecialSkills}</td>
            </tr>
            <tr>
                <td>Any other Similar Facility</td>
                <td>${item?.Uni_Criteria07_007?.Uni_AnyOther}</td>
              </tr>
              <tr>
                <td>Annual expenditure excluding salary component of the institution</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Annual}</td>
              </tr>
              <tr>
                <td>Number of initiatives to address locational Advantages and Disadvantages</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfIniti1}</td>
              </tr>
              <tr>
                <td>Number of initiatives taken to Engage with and contribute to Local Community</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfIniti2}</td>
              </tr>
              <tr>
                <td>Date and Duration of the Initiative Name of the Initiative Issues Addressed</td>
                <td>${item?.Uni_Criteria07_007?.Uni_DateDuration}</td>
              </tr>
              <tr>
                <td>Power Requirement met by Renewable Energy Sources</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Power}</td>
              </tr>
              <tr>
                <td>Total Power requirements</td>
                <td>${item?.Uni_Criteria07_007?.Uni_TPower}</td>
              </tr>
              <tr>
                <td>Renewable Energy Source</td>
                <td>${item?.Uni_Criteria07_007?.Uni_ReEnergyS}</td>
              </tr>
              <tr>
                <td>Renewable Energy Generated and Used</td>
                <td>${item?.Uni_Criteria07_007?.Uni_ReEnergyG}</td>
              </tr>
              <tr>
              <td>Energy Supplied to the Grid</td>
              <td>${item?.Uni_Criteria07_007?.Uni_EnergySup}</td>
            </tr>
            <tr>
                <td>Percentage Lighting through LED bulbs</td>
                <td>${item?.Uni_Criteria07_007?.Uni_PercentageLED}</td>
              </tr>
              <tr>
                <td>Percentage Lighting through other sources</td>
                <td>${item?.Uni_Criteria07_007?.Uni_PercentageLight}</td>
              </tr>
              <tr>
                <td>Number of Participating Students and Staff</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfParticipants}</td>
              </tr>
              <tr>
                <td>No. of Activities</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfActivities}</td>
              </tr><tr>
              <td>TTitle of the Program/Activity</td>
              <td>${item?.Uni_Criteria07_007?.Uni_TitlePro}</td>
            </tr>
            <tr>
                <td>Number of Participants</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfParticipants1}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
          <table>
              <tr>
                <td>From</td>
                <td>${moment(item?.Uni_Criteria07_007?.Uni_Date1).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              <tr>
                <td>To</td>
                <td>${moment(item?.Uni_Criteria07_007?.Uni_Date2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              </table>
          `;
        let tableMarkup3 = `
          <table>
              <tr>
                <td>MALE</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Male}</td>
              </tr>
              <tr>
                <td>FEMALE</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Female}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Yea}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Total}</td>
              </tr>
              </table>
          `;
        let obj = {
          Year: item?.Uni_Year,
          "Total Lighting requirements": item?.Uni_Criteria07_007?.Uni_Lighting,
          "Budget allocated for green initiatives":
            item?.Uni_Criteria07_007?.Uni_Budget,
          "Status of implementation of CBCS / Elective Course System (ECS)":
            tableMarkup1,
          "Date and Duration": tableMarkup2,
          "Number of participants by Gender": tableMarkup3,
          "Power Requirement Renewable": item?.Uni_Criteria07_007?.Uni_PowRe,
          "Initiative names": item?.Uni_Criteria07_007?.Uni_InitiName,
          "Duration of the Initiative":
            item?.Uni_Criteria07_007?.Uni_DurInitiative,
          Description: item?.Uni_Criteria07_007?.Uni_Desc29,
          "Relevent Document": item?.Uni_Criteria07_007?.Uni_Link29,
          "Relevent Document": item?.Uni_Criteria07_007?.Uni_Link30,
          "Relevent Document": item?.Uni_Criteria07_007?.Uni_Link31,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const getCriteria07 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUni_cri07details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.unicriteria07);
        setdata1(res.data.unicriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria07);
    }
  };
  console.log("AllDetails7", AllDetails);

  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatinguni7`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Uni_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getCriteria07();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      // approveCriteria();
      getCriteria07();
      getAllYears();
    }
  }, []);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="mt-4"> University Criteria7 </h4>
                </div>
                <div className="col-lg-6 text-end">
                  <div className="d-flex justify-content-end">
                    <h6 className="mt-4">Current Rating: </h6>
                    <div className="mt-4">
                      {AllDetails?.filter((item1) => {
                        if (
                          params?.id ===
                            item1?.addedByFac?.facultyAddedBy?._id &&
                          item1?.approve === "true" &&
                          selectedYear === item1?.Uni_Year
                        ) {
                          return true;
                        }
                        return false;
                      })?.map((item) => {
                        return (
                          <div>
                            <span className="">{item?.rating}</span>
                            <span>
                              <BsStarFill style={{ color: "#FFD700" }} />
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ad-b mt-4 row align-items-center">
                <div className=" col-lg-4 do-sear mt-4 d-flex ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="vi_0"
                    onChange={(e) => setSearchItem(e.target.value)}
                  />
                </div>

                <div className=" col-lg-2 lab">
                  <label>From</label>
                  <br />
                  <DatePicker
                    onChange={onChangeFrom}
                    picker="year"
                    className="vi_0"
                  />
                </div>
                <div className=" col-lg-2 lab">
                  <label>To</label>
                  <br />
                  <DatePicker
                    onChange={onChangeTo}
                    picker="year"
                    className="vi_0"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ad-b  row align-items-center">
            <div className=" col-lg-6 do-sear  d-flex align-items-center   ">
              <label style={{ width: "130px" }}>Select Year</label>
              <select
                className="criteriaform"
                style={{ width: "150px" }}
                onChange={(e) => setselectedYear(e.target.value)}
              >
                {allYears
                  ?.sort((a, b) => {
                    return Number(b?.from) - Number(a?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            </div>
            {/* <div className="col-lg-5">
              <Nav defaultActiveKey="/ugcriteria06">
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-1"
                    onClick={() => navigate(`/adminclgugcri6623/${params?.id}`)}
                  >
                    <Button className="criteriabtn"> 6.2.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    onClick={() => navigate(`/adminclgugcri6632/${params?.id}`)}
                  >
                    <Button className="criteriabtn">6.3.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() => navigate(`/adminclgugcri6633/${params?.id}`)}
                  >
                    <Button className="criteriabtn">6.3.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() => navigate(`/adminclgugcri6634/${params?.id}`)}
                  >
                    <Button className="criteriabtn">6.3.4</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() => navigate(`/adminclgugcri6642/${params?.id}`)}
                  >
                    <Button className="criteriabtn">6.4.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() => navigate(`/adminclgugcri6653/${params?.id}`)}
                  >
                    <Button
                      variant="warning"
                      style={{ backgroundColor: "#ffc107" }}
                      className="criteriabtn-0"
                    >
                      6.5.3
                    </Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div> */}

            <div className=" col-lg-3 text-end ">
              <Button onClick={ExportToExcel}>Export Excel</Button>
            </div>
            {/* <div className="col-lg-3">
              <Button
                type="Submit"
                className="btn btn-warning"
                style={{ color: "white" }}
                onClick={() => {
                  // setValue(item);
                  handleShow5();
                }}
              >
                Add Rating
              </Button>
            </div> */}
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black" }}>Sl.No</th>
                  <th style={{ border: "1px solid black" }}>Year</th>

                  <th style={{ border: "1px solid black" }}>
                    Total Lighting requirements
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Budget allocated for green initiatives
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Status of implementation of CBCS / Elective Course System
                    (ECS)
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Date and Duration
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Number of participants by Gender
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Power Requirement Renewable
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Initiative names
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Duration of the Initiative
                  </th>
                  <th style={{ border: "1px solid black" }}>Description</th>
                  <th style={{ border: "1px solid black" }}>
                    Relevant document
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Relevant document
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Relevant document
                  </th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Uni_Year &&
                    (SearchItem === "" ||
                      item1?.Uni_Year?.includes(SearchItem) ||
                      item1?.Uni_Criteria07_007?.Uni_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Lighting?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PhyFac?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Prolift?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Ramp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Braille?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_RestRoom?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Scribes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_SpecialSkills?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_AnyOther?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Annual?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfIniti1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfIniti2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_DateDuration?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Power?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_TPower?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_ReEnergyS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_ReEnergyG?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_EnergySup?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PercentageLED?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PercentageLight?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfParticipants?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfActivities?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_TitlePro?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfParticipants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Uni_Criteria07_007?.Uni_Date1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Uni_Criteria07_007?.Uni_Date2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria07_007?.Uni_Male?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Female?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Yea?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PowRe?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_InitiName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_DurInitiative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Desc29?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td style={{ border: "1px solid black" }}>{++i}.</td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Uni_Year}
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        {item?.Uni_Criteria07_007?.Uni_Lighting}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Uni_Criteria07_007?.Uni_Budget}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Physical Facilities
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Provision for Lift
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Ramp/ Rails
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Braille Software/Facilities
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Rest Rooms
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Scribes for Examination
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Special skill Development for Differently Abled
                              Students
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Any other Similar Facility
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Annual expenditure excluding salary component of
                              the institution
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of initiatives to address locational
                              Advantages and Disadvantages
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of initiatives taken to Engage with and
                              contribute to Local Community
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Date and Duration of the Initiative Name of the
                              Initiative Issues Addressed
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Power Requirement met by Renewable Energy Sources
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Total Power requirements
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Renewable Energy Source
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Renewable Energy Generated and Used
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Energy Supplied to the Grid
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Percentage Lighting through LED bulbs
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Percentage Lighting through other sources
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of Participating Students and Staff
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              No. of Activities
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              TTitle of the Program/Activity
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of Participants
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_PhyFac}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_Prolift}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_Ramp}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_Braille}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_RestRoom}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_Scribes}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_SpecialSkills}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_AnyOther}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_Annual}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_NoOfIniti1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_NoOfIniti2}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_DateDuration}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_Power}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_TPower}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_ReEnergyS}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_ReEnergyG}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_EnergySup}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_PercentageLED}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_PercentageLight}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_NoOfParticipants}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_NoOfActivities}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria07_007?.Uni_TitlePro}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {
                                  item?.Uni_Criteria07_007
                                    ?.Uni_NoOfParticipants1
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>From</th>
                            <th style={{ border: "1px solid black" }}>To</th>
                          </thead>
                          <tbody>
                            <td style={{ border: "1px solid black" }}>
                              {moment(
                                item?.Uni_Criteria07_007?.Uni_Date1
                              ).format("DD-MM-YYYY")}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {moment(
                                item?.Uni_Criteria07_007?.Uni_Date2
                              ).format("DD-MM-YYYY")}
                            </td>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>MALE</th>
                            <th style={{ border: "1px solid black" }}>
                              FEMALE
                            </th>
                            <th style={{ border: "1px solid black" }}>Year</th>
                            <th style={{ border: "1px solid black" }}>Total</th>
                          </thead>
                          <tbody>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Uni_Criteria07_007?.Uni_Male}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Uni_Criteria07_007?.Uni_Female}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Uni_Criteria07_007?.Uni_Yea}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Uni_Criteria07_007?.Uni_Total}
                            </td>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Uni_Criteria07_007?.Uni_PowRe}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Uni_Criteria07_007?.Uni_InitiName}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Uni_Criteria07_007?.Uni_DurInitiative}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Uni_Criteria07_007?.Uni_Desc29?.slice(0, 70)}...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose5}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria07/${View?.Uni_Criteria07_007?.Uni_Link29}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria07/${View?.Uni_Criteria07_007?.Uni_Link30}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria07/${View?.Uni_Criteria07_007?.Uni_Link31}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Body>
              <div>{View?.Uni_Criteria07_007?.Uni_Desc29}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose4}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UniversityCri7;
