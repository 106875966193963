import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Container, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function SyllabusFeedback() {
  const currentstudent = JSON.parse(sessionStorage.getItem("studentDetails"));
  const [selectedDate, setSelectedDate] = useState(null);
  const handleChange = (date) => {
    setSelectedDate(date);
  };
  const [name, setname] = useState("");
  const [Gender, setGender] = useState("male");
  const [Course, setCourse] = useState("");
  const [Semester, setSemester] = useState("");
  const [KudRegisterNo, setKudRegisterNo] = useState("");
  const [survey1, setSurvay1] = useState("");
  const [survey2, setSurvay2] = useState("");
  const [survey3, setSurvay3] = useState("");
  const [survey4, setSurvay4] = useState("");
  const [survey5, setSurvay5] = useState("");
  const [Suggestion, setSuggestion] = useState("");

  const Syllabusfeedback = async () => {
    try {
      const config = {
        url: "student/syllabusfeedback",
        method: "post",
        baseURL: "https://brightnaac.co.in/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          studentid: currentstudent?._id,
          name: name,
          gender: Gender,
          course: Course,
          semester: Semester,
          kudregisterno: KudRegisterNo,
          survay1: survey1,
          survey2: survey2,
          survey3: survey3,
          survey4: survey4,
          survey5: survey5,
          Suggestion: Suggestion,
          reviewyear: selectedDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        window.location.assign("/syllubussurveylist");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <div>
      <Container className="pt-4">
        <div>
          <div className="bgdesign">
            <h2>FEEDBACK from Students on Syllabus</h2>
            <p className="text">Dear Students,</p>
            <p className="text">
              Greetings from Govt. First Grade College, RANEBENNUR.
            </p>
            <p>
              You are hereby informed to fill the bellow required data as it is
              required for NAAC Process.
            </p>
            <p className="text">Thank you.</p>
            <p> IQAC Team </p>
            <hr />
            <div className="d-flex justify-content-between">
              <b>{currentstudent?.email} </b>
              <div>
                <span>Select Year : </span>
                <DatePicker
                  placeholderText="select year"
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleChange}
                  dateFormat="yyyy"
                  showYearPicker
                  maxDate={new Date()}
                />
              </div>
            </div>
            <hr />
            <p style={{ color: "red" }}>* Indicates required question</p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  1. Name of the Student :{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  style={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    2. Gender : <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="male"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                      onChange={(e) => setGender(e.target.value)}
                    />

                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                      onChange={(e) => setGender(e.target.value)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  3. Course : <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setCourse(e.target.value)}
                    >
                      <MenuItem value={"BA"}>BA</MenuItem>
                      <MenuItem value={"BCom"}>BCom</MenuItem>
                      <MenuItem value={"BSc"}>BSc</MenuItem>
                      <MenuItem value={"BBA"}>BBA</MenuItem>
                      <MenuItem value={"MA (Economics)"}>
                        MA (Economics)
                      </MenuItem>
                      <MenuItem value={"MCom"}>MCom</MenuItem>
                      <MenuItem value={"Msc(Comp. Sci)"}>
                        Msc(Comp. Sci)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel id="demo-radio-buttons-group-label">
                  4. Semester : <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setSemester(e.target.value)}
                    >
                      <MenuItem value={"1st Semester"}>1st Semester</MenuItem>
                      <MenuItem value={"2nd Semester"}>2nd Semester</MenuItem>
                      <MenuItem value={"3rd Semester"}>3rd Semester</MenuItem>
                      <MenuItem value={"4th Semester"}>4th Semester</MenuItem>
                      <MenuItem value={"5th Semester"}>5th Semester</MenuItem>
                      <MenuItem value={"6th Semester"}>6th Semester</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  Your KUD Exam Register No :{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  style={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  onChange={(e) => setKudRegisterNo(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="bgdesign mt-2">
            <FormLabel>
              Please give your response on bellow points, as per the above
              Legends: <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <p className="text">
              ವಿದ್ಯಾರ್ಥಿಗಳೇ ಈ ಕೆಳಗಿನ ವಿವರಗಳಿಗೆ ಮೇಲೆ ಸೂಚಿಸಿದಂತೆ, ನಿಮ್ಮ ಅಂಕಗಳನ್ನು
              ದಾಖಲಿಸಿ
            </p>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Very Good</th>
                  <th>Good</th>
                  <th>Average</th>
                  <th>Acceptable</th>
                  <th>Poor</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      1. Is syllabus creating interest in you?
                    </p>
                    <p className="text">
                      ಪಠ್ಯಕ್ರಮ ನಿಮ್ಮಲ್ಲಿ ಆಸಕ್ತಿ ಹುಟ್ಟಿಸುತ್ತದೆಯಾ?
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay1("Very Good")}
                      value="Very Good"
                      control={<Radio checked={survey1 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay1("Good")}
                      value="Good"
                      control={<Radio checked={survey1 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay1("Average")}
                      value="Average"
                      control={<Radio checked={survey1 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay1("Acceptable")}
                      value="Acceptable"
                      control={<Radio checked={survey1 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay1("Poor")}
                      value="Poor"
                      control={<Radio checked={survey1 === "Poor"} />}
                    />
                  </td>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">2. The link between the chapters is </p>
                    <p className="text">ಪಾಠಗಳ ನಡುವಿನ ಕೊಂಡಿ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay2("Very Good")}
                      value="Very Good"
                      control={<Radio checked={survey2 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay2("Good")}
                      value="Good"
                      control={<Radio checked={survey2 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay2("Average")}
                      value="Average"
                      control={<Radio checked={survey2 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay2("Acceptable")}
                      value="Acceptable"
                      control={<Radio checked={survey2 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay2("Poor")}
                      value="Poor"
                      control={<Radio checked={survey2 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">3. Availability of reference books </p>
                    <p className="text">ಪರಾಮರ್ಶನ ಗ್ರಂಥಗಳ ಲಭ್ಯತೆ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay3("Very Good")}
                      value="Very Good"
                      control={<Radio checked={survey3 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay3("Good")}
                      value="Good"
                      control={<Radio checked={survey3 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay3("Average")}
                      value="Average"
                      control={<Radio checked={survey3 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay3("Acceptable")}
                      value="Acceptable"
                      control={<Radio checked={survey3 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay3("Poor")}
                      value="Poor"
                      control={<Radio checked={survey3 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      4. Did syllabus fulfill the expectations?
                    </p>
                    <p className="text">
                      ಪಠ್ಯಕ್ರಮವು ನಿಮ್ಮ ನಿರೀಕ್ಷೆಗಳನ್ನು ತೃಪ್ತಿಗೊಳಿಸಿದೆಯಾ?
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay4("Very Good")}
                      value="Very Good"
                      control={<Radio checked={survey4 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay4("Good")}
                      value="Good"
                      control={<Radio checked={survey4 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay4("Average")}
                      value="Average"
                      control={<Radio checked={survey4 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay4("Acceptable")}
                      value="Acceptable"
                      control={<Radio checked={survey4 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay4("Poor")}
                      value="Poor"
                      control={<Radio checked={survey4 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">5. Overall the syllabus was? </p>
                    <p className="text">ಒಟ್ಟಾರೆ ಪಠ್ಯಕ್ರಮ ಹೇಗಿತ್ತು?</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay5("Very Good")}
                      value="Very Good"
                      control={<Radio checked={survey5 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay5("Good")}
                      value="Good"
                      control={<Radio checked={survey5 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay5("Average")}
                      value="Average"
                      control={<Radio checked={survey5 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay5("Acceptable")}
                      value="Acceptable"
                      control={<Radio checked={survey5 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvay5("Poor")}
                      value="Poor"
                      control={<Radio checked={survey5 === "Poor"} />}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="bgdesign mt-2">
            <FormLabel>
              <p className="text">6. Any other Suggestions:</p>{" "}
              <p className="text">ನಿಮ್ಮ ಸಲಹೆಗಳೇನಾದರೂ ಇದ್ದಲ್ಲಿ ಇಲ್ಲಿ ನಮೂದಿಸಿ</p>
            </FormLabel>
            <TextField
              id="filled-multiline-flexible"
              label="Your Answer"
              multiline
              maxRows={4}
              variant="filled"
              style={{ width: "100%" }}
              onChange={(e) => setSuggestion(e.target.value)}
            />
          </div>

          <div className="mt-3">
            <Button
              variant="contained"
              className="mb-3"
              onClick={Syllabusfeedback}
            >
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SyllabusFeedback;
