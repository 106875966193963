import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

function AdminClgUNI06_652() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);
  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("University-6.5.2");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          params?.id === item1?.addedByPri &&
          item1?.approve === "true" &&
          selectedYear === item1?.Uni_Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup = `
            <table>
              <tr>
                <td>Date (from)</td>
                <td>${moment(item?.Uni_Criteria06_652?.Uni_Sdate2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              <tr>
                <td>Date (to)</td>
                <td>${moment(item?.Uni_Criteria06_652?.Uni_Edate2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
            </table>
          `;
        let obj = {
          Year: item?.Uni_Year,
          "Orientation programme on quality issues for teachers and students":
            tableMarkup,
          "Participation in NIRF along with Status":
            item?.Uni_Criteria06_652?.Uni_nirfs,
          "Academic Administrative Audit (AAA) and follow up action":
            item?.Uni_Criteria06_652?.Uni_aaa,
          "NBA or any other certification received with program specifications":
            item?.Uni_Criteria06_652?.Uni_nba,
          "Conferences, Seminars, Workshops on quality conducted":
            item?.Uni_Criteria06_652?.Uni_cswq,
          "Collaborative quality initiatives with other institution(s) (Provide name of the institution and activity)":
            item?.Uni_Criteria06_652?.Uni_iaa,
          Description: item?.Uni_Criteria06_652?.Uni_desc6,
          "Relevent Document": item?.Uni_Criteria06_652?.Uni_doc9,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/PrinipalAdmin/Unicri06_652"
      );
      if (res.status === 200) {
        setAllDetails(res.data.getUni_C652);
        setdata1(res.data.getUni_C652);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.getUni_C652);
    }
  };
  console.log("allDetails112", AllDetails);

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      // approveCriteria();
      getContent();
      getAllYears();
    }
  }, []);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <h4 className="mt-4"> University Criteria 6 6.5.2</h4>
              <div className="ad-b mt-4 row align-items-center">
                <div className=" col-lg-4 do-sear mt-4 d-flex ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="vi_0"
                    onChange={(e) => setSearchItem(e.target.value)}
                  />
                </div>

                <div className=" col-lg-2 lab">
                  <label>From</label>
                  <br />
                  <DatePicker
                    onChange={onChangeFrom}
                    picker="year"
                    className="vi_0"
                  />
                </div>
                <div className=" col-lg-2 lab">
                  <label>To</label>
                  <br />
                  <DatePicker
                    onChange={onChangeTo}
                    picker="year"
                    className="vi_0"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ad-b  row align-items-center">
            <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
              <label style={{ width: "130px" }}>Select Year</label>
              <select
                className="criteriaform"
                style={{ width: "150px" }}
                onChange={(e) => setselectedYear(e.target.value)}
              >
                <option>Select Year</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-lg-5">
              <Nav defaultActiveKey="/unicriteria01">
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-1"
                    onClick={() =>
                      navigate(`/adminclgunicri6623/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.2.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    onClick={() =>
                      navigate(`/adminclgunicri6632/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.3.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri6633/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.3.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri6634/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.3.4</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri6642/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">6.4.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri6652/${params?.id}`)
                    }
                  >
                    <Button
                      variant="warning"
                      style={{ backgroundColor: "#ffc107" }}
                      className="criteriabtn-0"
                    >
                      6.5.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <div className=" col-lg-2 text-end ">
              <Button onClick={ExportToExcel}>Export Excel</Button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>
                    Orientation programme on quality issues for teachers and
                    students
                  </th>
                  <th>Participation in NIRF along with Status.</th>
                  <th>
                    Academic Administrative Audit (AAA) and follow up action
                  </th>
                  <th>
                    NBA or any other certification received with program
                    specifications.
                  </th>
                  <th>Conferences, Seminars, Workshops on quality conducted</th>
                  <th>
                    Collaborative quality initiatives with other institution(s)
                    (Provide name of the institution and activity)
                  </th>
                  <th>Description</th>
                  <th>Upload the relevant document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByPri &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Uni_Year &&
                    (SearchItem === "" ||
                      item1?.Uni_Year?.includes(SearchItem) ||
                      moment(item1?.Uni_Criteria06_652?.Uni_Sdate2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Uni_Criteria06_652?.Uni_Edate2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria06_652?.Uni_nirfs?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria06_652?.Uni_aaa?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria06_652?.Uni_nba?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria06_652?.Uni_cswq?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria06_652?.Uni_iaa?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria06_652?.Uni_desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>

                      <td>
                        <Table responsive>
                          <thead>
                            <th>Date (from)</th>
                            <th>Date (to)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {moment(
                                  item?.Uni_Criteria06_652?.Uni_Sdate2
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {moment(
                                  item?.Uni_Criteria06_652?.Uni_Edate2
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.Uni_Criteria06_652?.Uni_nirfs}</td>
                      <td>{item?.Uni_Criteria06_652?.Uni_aaa}</td>
                      <td>{item?.Uni_Criteria06_652?.Uni_nba}</td>
                      <td>{item?.Uni_Criteria06_652?.Uni_cswq}</td>
                      <td>{item?.Uni_Criteria06_652?.Uni_iaa}</td>
                      <td>
                        {item?.Uni_Criteria06_652?.Uni_desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_652?.Uni_doc9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* Show description modal */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Body>
              <div>{View?.Uni_Criteria06_652?.Uni_desc6}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AdminClgUNI06_652;
