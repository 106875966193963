import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import moment from "moment/moment";
import { AiOutlineEye } from "react-icons/ai";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

const PostGraduateCri3 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const pgCriteria03Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria03"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria03);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria03);
    }
  };
  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatingpg3`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          PG_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria03Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      pgCriteria03Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="mt-4"> PG Criteria3 </h4>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="mt-4">Current Rating: </h6>
                <div className="mt-4">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.PG_Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center">
            <div className="ad-b mt-4 row align-items-center">
              <div className=" col-lg-4 do-sear mt-4 d-flex ">
                <input
                  type="text"
                  placeholder="Search"
                  className="vi_0"
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {/* <button>Search</button> */}
              </div>

              <div className=" col-lg-2 lab">
                <label>From</label>
                <br />
                <DatePicker
                  onChange={onChangeFrom}
                  picker="year"
                  className="vi_0"
                />
              </div>
              <div className=" col-lg-2 lab">
                <label>To</label>
                <br />
                <DatePicker
                  onChange={onChangeTo}
                  picker="year"
                  className="vi_0"
                />
              </div>

              {/* <div className=" col-lg-2 lab mt-4">
              <Button>Submit</Button>
            </div> */}
              {/* <div className=" col-lg-2 text-end mt-4">
              <Button>Export Excel</Button>
            </div> */}
            </div>
            <div className="row">
              <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
                <label style={{ width: "130px" }}>Select Year</label>
                <select
                  className="criteriaform "
                  style={{ width: "150px" }}
                  onChange={(e) => setselectedYear(e.target.value)}
                >
                  {allYears
                    ?.sort((a, b) => {
                      return Number(b?.from) - Number(a?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg-5">
                <Nav defaultActiveKey="/pgcriteria03">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-1"
                      onClick={() =>
                        navigate(`/adminclgpgcri3312/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.1.2 & 3.1.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-2"
                      onClick={() =>
                        navigate(`/adminclgpgcri3322/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.2.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri3332/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.3.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri3333/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.3.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri3342/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.4.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri3343/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.4.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri3351/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.5.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri3352/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.5.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              {/* <div className="col-lg-2">
                <Button
                  type="Submit"
                  className="btn btn-warning"
                  style={{ color: "white" }}
                  onClick={() => {
                    // setValue(item);
                    handleShow();
                  }}
                >
                  Add Rating
                </Button>
              </div> */}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 w-100"></div>
        </div>
        <div className="container">
          <div className="mo-gra mt-2">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 3.1.2 & 3.1.3 */}
                  <th>Criteria</th>
                  <th>Name of the Principal Investigator/Co-Investigator</th>
                  <th>Name of the Project/ Endowments, Chairs</th>
                  <th>Department of Principal Investigator</th>
                  <th>Year of Award</th>
                  <th>Amount Sanctioned</th>
                  <th>Duration of the project</th>
                  <th>Name of the Funding Agency</th>
                  <th>Type (Government/non-Government)</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 3.2.2 */}
                  <th>Criteria</th>
                  <th>Number of Participants</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Name of the workshop/ seminar/ conference</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 3.3.2 */}
                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Dept of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>Relevant document1</th>
                  <th>Relevant document2</th>

                  {/* 3.3.3 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>Name of the conference</th>
                  <th>National / International</th>
                  <th>Affiliating Institute at the time of publication</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* (3.4.2) */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Year of award</th>
                  <th>Name of the Award/ recognition for Institution</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 3.4.3 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of the activity</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 3.5.1 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Title of the collaborative activity</th>
                  <th>
                    Name of the collaborating / linking agency with contact
                    details
                  </th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 3.5.3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the institution/ industry/ corporate house with whom
                    MoU is signed
                  </th>
                  <th>Year of signing MOU</th>
                  <th>Duration</th>
                  <th>
                    List the actual activities under each MOU and web links
                    year-wise
                  </th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.PG_Year &&
                    (SearchItem === "" ||
                      item1?.PG_Criteria03_311?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_311?.PG_ProName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_PrinciName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_PrinciDept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_YOfAw?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_311?.PG_ASanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_311?.PG_DProject?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_311?.PG_NFunding?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_322?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_322?.PG_NPati?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria03_322?.PG_FDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria03_322?.PG_TDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria03_322?.PG_NWorkshop?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_322?.PG_Desc13?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_332?.PG_TPapaer?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_AName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_TDept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_JName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_PubliYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_332?.PG_IISSN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_332?.PG_UUGC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_333?.PG_SNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_333?.PG_NTea?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Title1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Title2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Title3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_YPub?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_333?.PG_IIISBN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_333?.PG_NPub?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_ConName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_NatInt?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Affi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_criteria?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_ActiN?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_YearA?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_342?.PG_INAme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_Bodies?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_Desc16?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_343?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_343?.PG_NaActi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_343?.PG_Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_343?.PG_SchName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_343?.PG_ActiYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_343?.PG_NStudents?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_343?.PG_Desc17?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_351?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_351?.PG_SlNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_351?.PG_Collo?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_351?.PG_NCollo?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_351?.PG_NameParti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_351?.PG_ColloYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_351?.PG_Dur?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_351?.PG_Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_352?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_352?.PG_NMou?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_352?.PG_Mou?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_352?.PG_Durat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_352?.PG_List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_352?.PG_Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.PG_Year}</td>

                      {/* 311 */}
                      <td>{item?.PG_Criteria03_311?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_311?.PG_ProName}</td>
                      <td>{item?.PG_Criteria03_311?.PG_PrinciName}</td>
                      <td>{item?.PG_Criteria03_311?.PG_PrinciDept}</td>
                      <td>{item?.PG_Criteria03_311?.PG_YOfAw}</td>
                      <td>{item?.PG_Criteria03_311?.PG_ASanctioned}</td>
                      <td>{item?.PG_Criteria03_311?.PG_DProject}</td>
                      <td>{item?.PG_Criteria03_311?.PG_NFunding}</td>
                      <td>{item?.PG_Criteria03_311?.PG_Type}</td>
                      <td>{item?.PG_Criteria03_311?.PG_Desc12}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 322 */}
                      <td>{item?.PG_Criteria03_322?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_322?.PG_NPati}</td>
                      <td>
                        {moment(item?.PG_Criteria03_322?.PG_FDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.PG_Criteria03_322?.PG_TDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria03_322?.PG_NWorkshop}</td>
                      <td>{item?.PG_Criteria03_322?.PG_Desc13}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 332 */}
                      <td>{item?.PG_Criteria03_332?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_332?.PG_TPapaer}</td>
                      <td>{item?.PG_Criteria03_332?.PG_AName}</td>
                      <td>{item?.PG_Criteria03_332?.PG_TDept}</td>
                      <td>{item?.PG_Criteria03_332?.PG_JName}</td>
                      <td>{item?.PG_Criteria03_332?.PG_PubliYear}</td>
                      <td>{item?.PG_Criteria03_332?.PG_IISSN}</td>
                      <td>{item?.PG_Criteria03_332?.PG_UUGC}</td>
                      <td>{item?.PG_Criteria03_332?.PG_Desc14}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 333 */}
                      <td>{item?.PG_Criteria03_333?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_333?.PG_SNo}</td>
                      <td>{item?.PG_Criteria03_333?.PG_NTea}</td>
                      <td>{item?.PG_Criteria03_333?.PG_Title1}</td>
                      <td>{item?.PG_Criteria03_333?.PG_Title2}</td>
                      <td>{item?.PG_Criteria03_333?.PG_Title3}</td>
                      <td>{item?.PG_Criteria03_333?.PG_YPub}</td>
                      <td>{item?.PG_Criteria03_333?.PG_IIISBN}</td>
                      <td>{item?.PG_Criteria03_333?.PG_NPub}</td>
                      <td>{item?.PG_Criteria03_333?.PG_ConName}</td>
                      <td>{item?.PG_Criteria03_333?.PG_NatInt}</td>
                      <td>{item?.PG_Criteria03_333?.PG_Affi}</td>
                      <td>{item?.PG_Criteria03_333?.PG_Desc15}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 342 */}
                      <td>{item?.PG_Criteria03_342?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_342?.PG_ActiN}</td>
                      <td>{item?.PG_Criteria03_342?.PG_YearA}</td>
                      <td>{item?.PG_Criteria03_342?.PG_INAme}</td>
                      <td>{item?.PG_Criteria03_342?.PG_Bodies}</td>
                      <td>{item?.PG_Criteria03_342?.PG_Desc16}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 343 */}
                      <td>{item?.PG_Criteria03_343?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_343?.PG_NaActi}</td>
                      <td>{item?.PG_Criteria03_343?.PG_Organ}</td>
                      <td>{item?.PG_Criteria03_343?.PG_SchName}</td>
                      <td>{item?.PG_Criteria03_343?.PG_ActiYear}</td>
                      <td>{item?.PG_Criteria03_343?.PG_NStudents}</td>
                      <td>{item?.PG_Criteria03_343?.PG_Desc17}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 351 */}
                      <td>{item?.PG_Criteria03_351?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_351?.PG_SlNo}</td>
                      <td>{item?.PG_Criteria03_351?.PG_Collo}</td>
                      <td>{item?.PG_Criteria03_351?.PG_NCollo}</td>
                      <td>{item?.PG_Criteria03_351?.PG_NameParti}</td>
                      <td>{item?.PG_Criteria03_351?.PG_ColloYear}</td>
                      <td>{item?.PG_Criteria03_351?.PG_Dur}</td>
                      <td>{item?.PG_Criteria03_351?.PG_Desc18}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 352 */}
                      <td>{item?.PG_Criteria03_352?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_352?.PG_NMou}</td>
                      <td>{item?.PG_Criteria03_352?.PG_Mou}</td>
                      <td>{item?.PG_Criteria03_352?.PG_Durat}</td>
                      <td>{item?.PG_Criteria03_352?.PG_List}</td>
                      <td>{item?.PG_Criteria03_352?.PG_Desc19}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
              ,
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_311?.PG_Link12}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_322?.PG_Link13}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_332?.PG_Link14}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_332?.PG_Link15}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_333?.PG_Link16}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_342?.PG_Link17}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_342?.PG_Link18}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_351?.PG_Link19}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_352?.PG_Link20}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PostGraduateCri3;
