import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

const PgCriteria7 = () => {
  const navigate = useNavigate();
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaPG07/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getCriteria07();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("PG Criteria 7");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.PG_Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>Physical Facilities</td>
                <td>${item?.PG_Criteria07_007?.PG_PhyFac}</td>
              </tr>
              <tr>
                <td>Provision for Lift</td>
                <td>${item?.PG_Criteria07_007?.PG_Prolift}</td>
              </tr>
              <tr>
                <td>Ramp/ Rails</td>
                <td>${item?.PG_Criteria07_007?.PG_Ramp}</td>
              </tr>
              <tr>
                <td>Braille Software/Facilities</td>
                <td>${item?.PG_Criteria07_007?.PG_Braille}</td>
              </tr>
              <tr>
                <td>Rest Rooms</td>
                <td>${item?.PG_Criteria07_007?.PG_RestRoom}</td>
              </tr>
              <tr>
                <td>Scribes for Examination</td>
                <td>${item?.PG_Criteria07_007?.PG_Scribes}</td>
              </tr><tr>
              <td>Special skill Development for Differently Abled Students</td>
              <td>${item?.PG_Criteria07_007?.PG_SpecialSkills}</td>
            </tr>
            <tr>
                <td>Any other Similar Facility</td>
                <td>${item?.PG_Criteria07_007?.PG_AnyOther}</td>
              </tr>
              <tr>
                <td>Annual expenditure excluding salary component of the institution</td>
                <td>${item?.PG_Criteria07_007?.PG_Annual}</td>
              </tr>
              <tr>
                <td>Number of initiatives to address locational Advantages and Disadvantages</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfIniti1}</td>
              </tr>
              <tr>
                <td>Number of initiatives taken to Engage with and contribute to Local Community</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfIniti2}</td>
              </tr>
              <tr>
                <td>Date and Duration of the Initiative Name of the Initiative Issues Addressed</td>
                <td>${item?.PG_Criteria07_007?.PG_DateDuration}</td>
              </tr>
              <tr>
                <td>Power Requirement met by Renewable Energy Sources</td>
                <td>${item?.PG_Criteria07_007?.PG_Power}</td>
              </tr>
              <tr>
                <td>Total Power requirements</td>
                <td>${item?.PG_Criteria07_007?.PG_TPower}</td>
              </tr>
              <tr>
                <td>Renewable Energy Source</td>
                <td>${item?.PG_Criteria07_007?.PG_ReEnergyS}</td>
              </tr>
              <tr>
                <td>Renewable Energy Generated and Used</td>
                <td>${item?.PG_Criteria07_007?.PG_ReEnergyG}</td>
              </tr>
              <tr>
              <td>Energy Supplied to the Grid</td>
              <td>${item?.PG_Criteria07_007?.PG_EnergySup}</td>
            </tr>
            <tr>
                <td>Percentage Lighting through LED bulbs</td>
                <td>${item?.PG_Criteria07_007?.PG_PercentageLED}</td>
              </tr>
              <tr>
                <td>Percentage Lighting through other sources</td>
                <td>${item?.PG_Criteria07_007?.PG_PercentageLight}</td>
              </tr>
              <tr>
                <td>Number of Participating Students and Staff</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfParticipants}</td>
              </tr>
              <tr>
                <td>No. of Activities</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfActivities}</td>
              </tr><tr>
              <td>TTitle of the Program/Activity</td>
              <td>${item?.PG_Criteria07_007?.PG_TitlePro}</td>
            </tr>
            <tr>
                <td>Number of Participants</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfParticipants1}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
          <table>
              <tr>
                <td>From</td>
                <td>${moment(item?.PG_Criteria07_007?.PG_Date1).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              <tr>
                <td>To</td>
                <td>${moment(item?.PG_Criteria07_007?.PG_Date2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              </table>
          `;
        let tableMarkup3 = `
          <table>
              <tr>
                <td>MALE</td>
                <td>${item?.PG_Criteria07_007?.PG_Male}</td>
              </tr>
              <tr>
                <td>FEMALE</td>
                <td>${item?.PG_Criteria07_007?.PG_Female}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>${item?.PG_Criteria07_007?.PG_Yea}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${item?.PG_Criteria07_007?.PG_Total}</td>
              </tr>
              </table>
          `;
        let obj = {
          Year: item?.PG_Year,
          "Total Lighting requirements": item?.PG_Criteria07_007?.PG_Lighting,
          "Budget allocated for green initiatives":
            item?.PG_Criteria07_007?.PG_Budget,
          "Status of implementation of CBCS / Elective Course System (ECS)":
            tableMarkup1,
          "Date and Duration": tableMarkup2,
          "Number of participants by Gender": tableMarkup3,
          "Power Requirement Renewable": item?.PG_Criteria07_007?.PG_PowRe,
          "Initiative names": item?.PG_Criteria07_007?.PG_InitiName,
          "Duration of the Initiative":
            item?.PG_Criteria07_007?.PG_DurInitiative,
          Description: item?.PG_Criteria07_007?.PG_Desc29,
          "Relevent Document": item?.PG_Criteria07_007?.PG_Link29,
          "Relevent Document": item?.PG_Criteria07_007?.PG_Link30,
          "Relevent Document": item?.PG_Criteria07_007?.PG_Link31,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  //  ===========================================================================================

  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        approvedetails?._id === item1?.addedByPri &&
        item1?.approve === "true" &&
        selectedYear === item1?.PG_Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 7 });
  };

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const getCriteria07 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getPG_cri07details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria07);
        setdata1(res.data.pgcriteria07);
        setfilterPdf(res.data.pgcriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria07);
    }
  };
  console.log("AllDetails7", AllDetails);

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getCriteria07();
      getAllYears();
    }
  }, []);

  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-07
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>
            <div className=" col-lg-2 lab text-end">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = AllDetails?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.PG_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      approvedetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.PG_Year &&
                      (SearchItem === "" ||
                        item1?.PG_Year?.includes(SearchItem) ||
                        item1?.PG_Criteria07_007?.PG_Budget?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Lighting?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_PhyFac?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Prolift?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Ramp?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Braille?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_RestRoom?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Scribes?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_SpecialSkills?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_AnyOther?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Annual?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_NoOfIniti1?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_NoOfIniti2?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_DateDuration?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Power?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_TPower?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_ReEnergyS?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_ReEnergyG?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_EnergySup?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_PercentageLED?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_PercentageLight?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_NoOfParticipants?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_NoOfActivities?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_TitlePro?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_NoOfParticipants1?.toString()?.includes(
                          SearchItem
                        ) ||
                        moment(item1?.PG_Criteria07_007?.PG_Date1)
                          .format("DD-MM-YYYY")
                          ?.toString()
                          ?.includes(SearchItem) ||
                        moment(item1?.PG_Criteria07_007?.PG_Date2)
                          .format("DD-MM-YYYY")
                          ?.toString()
                          ?.includes(SearchItem) ||
                        item1?.PG_Criteria07_007?.PG_Male?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Female?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Yea?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Total?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_PowRe?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria07_007?.PG_InitiName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_DurInitiative?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PG_Criteria07_007?.PG_Desc29?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });

                  if (arr1?.length > 0) {
                    navigate("/PDFprintPG07", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>

          <div className="row justify-content-end m-0 mt-4">
            <div className=" col-lg-12 do-sear  d-flex align-items-center    ">
              <label style={{ width: "130px" }}>Select Year</label>
              <select
                className="criteriaform vi_0"
                style={{ width: "150px" }}
                onChange={(e) => setselectedYear(e.target.value)}
              >
                <option>Select Year</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>Total Lighting requirements</th>
                  <th>Budget allocated for green initiatives</th>
                  <th>
                    Status of implementation of CBCS / Elective Course System
                    (ECS)
                  </th>
                  <th>Date and Duration</th>
                  <th>Number of participants by Gender</th>
                  <th>Power Requirement Renewable</th>
                  <th>Initiative names</th>
                  <th>Duration of the Initiative</th>
                  <th>Description</th>
                  <th>Document1</th>
                  <th>Document2</th>
                  <th>Document3</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    (item1?.approve === "false" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "true") &&
                    selectedYear === item1?.PG_Year &&
                    (SearchItem === "" ||
                      item1?.PG_Year?.includes(SearchItem) ||
                      item1?.PG_Criteria07_007?.PG_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Lighting?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_PhyFac?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Prolift?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Ramp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Braille?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_RestRoom?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Scribes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_SpecialSkills?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_AnyOther?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Annual?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfIniti1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfIniti2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_DateDuration?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Power?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_TPower?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_ReEnergyS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_ReEnergyG?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_EnergySup?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_PercentageLED?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_PercentageLight?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfParticipants?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfActivities?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_TitlePro?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfParticipants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria07_007?.PG_Date1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria07_007?.PG_Date2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria07_007?.PG_Male?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Female?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Yea?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_PowRe?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_InitiName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_DurInitiative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Desc29?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.PG_Year}</td>

                      <td>{item?.PG_Criteria07_007?.PG_Lighting}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Budget}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Physical Facilities</th>
                            <th>Provision for Lift</th>
                            <th>Ramp/ Rails</th>
                            <th>Braille Software/Facilities</th>
                            <th>Rest Rooms</th>
                            <th>Scribes for Examination</th>
                            <th>
                              Special skill Development for Differently Abled
                              Students
                            </th>
                            <th>Any other Similar Facility</th>
                            <th>
                              Annual expenditure excluding salary component of
                              the institution
                            </th>
                            <th>
                              Number of initiatives to address locational
                              Advantages and Disadvantages
                            </th>
                            <th>
                              Number of initiatives taken to Engage with and
                              contribute to Local Community
                            </th>
                            <th>
                              Date and Duration of the Initiative Name of the
                              Initiative Issues Addressed
                            </th>
                            <th>
                              Power Requirement met by Renewable Energy Sources
                            </th>
                            <th>Total Power requirements</th>
                            <th>Renewable Energy Source</th>
                            <th>Renewable Energy Generated and Used</th>
                            <th>Energy Supplied to the Grid</th>
                            <th>Percentage Lighting through LED bulbs</th>
                            <th>Percentage Lighting through other sources</th>
                            <th>Number of Participating Students and Staff</th>
                            <th>No. of Activities</th>
                            <th>TTitle of the Program/Activity</th>
                            <th>Number of Participants</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.PG_Criteria07_007?.PG_PhyFac}</td>
                              <td>{item?.PG_Criteria07_007?.PG_Prolift}</td>
                              <td>{item?.PG_Criteria07_007?.PG_Ramp}</td>
                              <td>{item?.PG_Criteria07_007?.PG_Braille}</td>
                              <td>{item?.PG_Criteria07_007?.PG_RestRoom}</td>
                              <td>{item?.PG_Criteria07_007?.PG_Scribes}</td>
                              <td>
                                {item?.PG_Criteria07_007?.PG_SpecialSkills}
                              </td>
                              <td>{item?.PG_Criteria07_007?.PG_AnyOther}</td>
                              <td>{item?.PG_Criteria07_007?.PG_Annual}</td>
                              <td>{item?.PG_Criteria07_007?.PG_NoOfIniti1}</td>
                              <td>{item?.PG_Criteria07_007?.PG_NoOfIniti2}</td>
                              <td>
                                {item?.PG_Criteria07_007?.PG_DateDuration}
                              </td>
                              <td>{item?.PG_Criteria07_007?.PG_Power}</td>
                              <td>{item?.PG_Criteria07_007?.PG_TPower}</td>
                              <td>{item?.PG_Criteria07_007?.PG_ReEnergyS}</td>
                              <td>{item?.PG_Criteria07_007?.PG_ReEnergyG}</td>
                              <td>{item?.PG_Criteria07_007?.PG_EnergySup}</td>
                              <td>
                                {item?.PG_Criteria07_007?.PG_PercentageLED}
                              </td>
                              <td>
                                {item?.PG_Criteria07_007?.PG_PercentageLight}
                              </td>
                              <td>
                                {item?.PG_Criteria07_007?.PG_NoOfParticipants}
                              </td>
                              <td>
                                {item?.PG_Criteria07_007?.PG_NoOfActivities}
                              </td>
                              <td>{item?.PG_Criteria07_007?.PG_TitlePro}</td>
                              <td>
                                {item?.PG_Criteria07_007?.PG_NoOfParticipants1}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>From</th>
                            <th>To</th>
                          </thead>
                          <tbody>
                            <td>
                              {moment(item?.PG_Criteria07_007?.PG_Date1).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td>
                              {moment(item?.PG_Criteria07_007?.PG_Date2).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>MALE</th>
                            <th>FEMALE</th>
                            <th>Year</th>
                            <th>Total</th>
                          </thead>
                          <tbody>
                            <td>{item?.PG_Criteria07_007?.PG_Male}</td>
                            <td>{item?.PG_Criteria07_007?.PG_Female}</td>
                            <td>{item?.PG_Criteria07_007?.PG_Yea}</td>
                            <td>{item?.PG_Criteria07_007?.PG_Total}</td>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.PG_Criteria07_007?.PG_PowRe}</td>
                      <td>{item?.PG_Criteria07_007?.PG_InitiName}</td>
                      <td>{item?.PG_Criteria07_007?.PG_DurInitiative}</td>

                      <td>
                        {item?.PG_Criteria07_007?.PG_Desc29?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Body>
              <Modal.Header closeButton>
                <Modal.Title>Description</Modal.Title>
              </Modal.Header>
              <div>{View?.PG_Criteria07_007?.PG_Desc29}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose8}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="lg"
            show={show}
            onHide={(e) => {
              handleClose();
              // setedit1(false);
            }}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#" }}>Criteria 7 </Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-0 p-0">
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    PostGraduate_Criteria-07
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total Lighting requirements
                      </label>
                      <br />

                      {View?.PG_Criteria07_007?.PG_Lighting}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Budget allocated for green initiatives
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_Budget}
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Status of implementation of CBCS / Elective Course System
                    (ECS)
                  </h6>
                  <div className="row">
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Physical Facilities
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_PhyFac}
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Provision for Lift
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_Prolift}
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Ramp/ Rails
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_Ramp}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Braille Software/Facilities
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_Braille}
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Rest Rooms
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_RestRoom}
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Scribes for Examination
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_Scribes}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Special skill Development for Differently Abled Students
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_SpecialSkills}
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Any other Similar Facility
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_AnyOther}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Annual expenditure excluding salary component of the
                      institution
                    </label>
                    <br />
                    {View?.PG_Criteria07_007?.PG_Annual}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of initiatives to address locational Advantages and
                      Disadvantages
                    </label>
                    <br />
                    {View?.PG_Criteria07_007?.PG_NoOfIniti1}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of initiatives taken to Engage with and contribute
                      to Local Community
                    </label>
                    <br />
                    {View?.PG_Criteria07_007?.PG_NoOfIniti2}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date and Duration of the Initiative Name of the Initiative
                      Issues Addressed
                    </label>
                    <br />
                    {View?.PG_Criteria07_007?.PG_DateDuration}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Power Requirement met by Renewable Energy Sources
                    </label>
                    <br />
                    {View?.PG_Criteria07_007?.PG_Power}
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total Power requirements
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_TPower}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Renewable Energy Source
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_ReEnergyS}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Renewable Energy Generated and Used
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_ReEnergyG}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Energy Supplied to the Grid
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_EnergySup}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Percentage Lighting through other sources
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_PercentageLight}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of Participating Students and Staff
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_NoOfParticipants}
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Percentage Lighting through LED bulbs
                        </label>
                        <br />
                        {View?.PG_Criteria07_007?.PG_PercentageLED}
                      </div>
                      <div className="col-md-6 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          No. of Activities
                        </label>
                        <br />
                        {View?.PG_Criteria07_007?.PG_NoOfActivities}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Title of the Program/Activity
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_TitlePro}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of Participants
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_NoOfParticipants1}
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Date and Duration
                  </h6>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        From
                      </label>
                      <br />

                      {moment(View?.PG_Criteria07_007?.PG_Date1).format(
                        "DD_MM_YYYY"
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        To
                      </label>
                      <br />

                      {moment(View?.PG_Criteria07_007?.PG_Date2).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Number of participants by Gender
                  </h6>
                  <div className="row">
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Male
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_Male}
                    </div>
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Female
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_Female}
                    </div>
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_Yea}
                    </div>
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_Total}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Power Requirement Renewable
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_PowRe}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Initiative names
                      </label>
                      <br />
                      {View?.PG_Criteria07_007?.PG_InitiName}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Duration_of_the_Initiative
                    </label>
                    <br />
                    {View?.PG_Criteria07_007?.PG_DurInitiative}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description (min 500 words)
                    </label>
                    <br />
                    {View?.PG_Criteria07_007?.PG_Desc29}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document 1
                    </label>
                    <br />
                    <a
                      href={`https://brightnaac.co.in/PG_Criteria07/${View?.PG_Criteria07_007?.PG_Link29}`}
                      target="blank_"
                    >
                      View Document
                    </a>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload2">
                      {" "}
                      Document 2
                    </label>
                    <br />

                    <a
                      href={`https://brightnaac.co.in/PG_Criteria07/${View?.PG_Criteria07_007?.PG_Link30}`}
                      target="blank_"
                    >
                      View Document
                    </a>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload3">
                      {" "}
                      Document 3
                    </label>
                    <br />

                    <a
                      href={`https://brightnaac.co.in/PG_Criteria07/${View?.PG_Criteria07_007?.PG_Link31}`}
                      target="blank_"
                    >
                      View Document
                    </a>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {View?.approve == "true" ? (
                <></>
              ) : (
                <div className="row mt-3">
                  {/* <div className="text-end">
                        {edit1 ? (
                          <>
                            <button
                              className="btn btn-success"
                              style={{ color: "white" }}
                              onClick={(e) => {
                                editPG_Criteria07_007(e, Value?._id);
                                handleClose();
                              }}
                            >
                              Submit
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={() => {
                                // setedit1(true);
                              }}
                            >
                              Edit
                            </button>
                          </>
                        )}
                      </div> */}
                  <div>
                    <Button
                      variant="primary"
                      onClick={(e) => approveCriteria(e, true, View?._id)}
                    >
                      Approve{" "}
                    </Button>{" "}
                    <Button
                      variant="secondary"
                      onClick={() => setSmShow(true)}
                      className="me-2"
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              )}
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria07/${View?.PG_Criteria07_007?.PG_Link29}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria07/${View?.PG_Criteria07_007?.PG_Link30}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria07/${View?.PG_Criteria07_007?.PG_Link31}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            // size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Tell me the reason?..
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                style={{ height: "200px" }}
                onChange={(e) => setremark(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => approveCriteria(e, false, View?._id)}
              >
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PgCriteria7;
