import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineRadarChart } from "react-icons/ai";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const Auto_Criteria01 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);

  const [Auto_Pcode, setAuto_Pcode] = useState("");
  const [Auto_Pname, setAuto_Pname] = useState("");
  const [Auto_YOInt, setAuto_YOInt] = useState("");
  const [Auto_SOInt, setAuto_SOInt] = useState("");
  const [Auto_YOImple, setAuto_YOImple] = useState("");
  const [Auto_YRevi, setAuto_YRevi] = useState("");
  const [Auto_Revi, setAuto_Revi] = useState("");
  const [Auto_Desc1, setAuto_Desc1] = useState("");
  const [Auto_Link1, setAuto_Link1] = useState("");

  const [Auto_NCourse, setAuto_NCourse] = useState("");
  const [Auto_CCode, setAuto_CCode] = useState("");
  const [Auto_Activities, setAuto_Activities] = useState("");
  const [Auto_YOIntro, setAuto_YOIntro] = useState("");
  const [Auto_Desc2, setAuto_Desc2] = useState("");
  const [Auto_Link2, setAuto_Link2] = useState("");

  const [Auto_Name, setAuto_Name] = useState("");
  const [Auto_Course, setAuto_Course] = useState("");
  const [Auto_YOOff, setAuto_YOOff] = useState("");
  const [Auto_Times, setAuto_Times] = useState("");
  const [Auto_CDurat, setAuto_CDurat] = useState("");
  const [Auto_Number1, setAuto_Number1] = useState("");
  const [Auto_Number2, setAuto_Number2] = useState("");
  const [Auto_Desc3, setAuto_Desc3] = useState("");
  const [Auto_Link3, setAuto_Link3] = useState("");

  const [Auto_Prg1, setAuto_Prg1] = useState("");
  const [Auto_Prg2, setAuto_Prg2] = useState("");
  const [Auto_Name1, setAuto_Name1] = useState("");
  const [Auto_Desc4, setAuto_Desc4] = useState("");
  const [Auto_Link4, setAuto_Link4] = useState("");

  //integrating get method

  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [Auto_Criteria01, setAuto_Criteria01] = useState([]);
  const [Value, setValue] = useState({});

  //   ===============================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Autonomous_Criteria-01");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          //  Criteria 1_112

          Year: item?.Auto_Year,
          "Criteria_1.1.2": item?.Auto_Criteria01_112?.Auto_criteria,
          "Program Name": item?.Auto_Criteria01_112?.Auto_Pname,
          "Status of implementation of CBCS / ECS":
            item?.Auto_Criteria01_112?.Auto_SOInt,
          "Programme-code": item?.Auto_Criteria01_112?.Auto_Pcode,
          "Year of Introduction": item?.Auto_Criteria01_112?.Auto_YOInt,
          "Year of revision (if any)": item?.Auto_Criteria01_112?.Auto_YRevi,
          "Year of implementation of CBCS / ECS":
            item?.Auto_Criteria01_112?.Auto_YOImple,
          "If revision has been carried out in the syllabus during last 5 years, Percentage of content added or replaced":
            item?.Auto_Criteria01_112?.Auto_Revi,
          "Description (min 500 words)(1.1.2)":
            item?.Auto_Criteria01_112?.Auto_Desc1,
          "Upload the relevant document(1.1.2)":
            item?.Auto_Criteria01_112?.Auto_Link1,

          // Criteria 1_113

          "Criteria_1.1.3": item?.Auto_Criteria01_113?.Auto_criteria,
          "Name of the Course": item?.Auto_Criteria01_113?.Auto_NCourse,
          "Course Code": item?.Auto_Criteria01_113?.Auto_CCode,
          "Year Of Introduction": item?.Auto_Criteria01_113?.Auto_YOIntro,
          "Activities/Content with direct bearing on Employability/ Entrepreneurship/ Skill development":
            item?.Auto_Criteria01_113?.Auto_Activities,
          "Description (min 500 words)(1.1.3)":
            item?.Auto_Criteria01_113?.Auto_Desc2,
          "Upload the relevant document(1.1.3)":
            item?.Auto_Criteria01_113?.Auto_Link2,

          // Criteria 1_132
          "Criteria_1.3.2": item?.Auto_Criteria01_132?.Auto_criteria,
          "Name of the value added courses offered (with 30 or more contact hours)":
            item?.Auto_Criteria01_132?.Auto_Name,
          "Course Code (if any)": item?.Auto_Criteria01_132?.Auto_Course,
          "Year of offering": item?.Auto_Criteria01_132?.Auto_YOOff,
          "No. of times offered during the same year":
            item?.AutoCriteria01_132?.Auto_Times,
          "Duration of course": item?.Auto_Criteria01_132?.Auto_CDurat,
          "Number of students enrolled in the year":
            item?.Auto_Criteria01_132?.Auto_Number1,
          "Number of Students completing the course in the year":
            item?.Auto_Criteria01_132?.Auto_Number2,
          "Description (min 500 words)(1.3.2)":
            item?.Auto_Criteria01_132?.Auto_Desc3,
          "Upload the relevant document(1.3.2)":
            item?.Auto_Criteria01_132?.Auto_Link3,

          // Criteria 1_134
          "Criteria_1.3.4": item?.Auto_Criteria01_134?.Auto_criteria,
          Program_Name: item?.Auto_Criteria01_134?.Auto_Prg1,
          Program_Code: item?.Auto_Criteria01_134?.Auto_Prg2,
          "Name of the students": item?.Auto_Criteria01_134?.Auto_Name1,
          "Description (min 500 words)(1.3.4)":
            item?.Auto_Criteria01_134?.Auto_Desc4,
          "Upload the relevant document(1.3.4)":
            item?.Auto_Criteria01_134?.Auto_Link4,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editAuto_Criteria01_112 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc1) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      handleClose();
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria01_112/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Pcode: Auto_Pcode,
          Auto_Pname: Auto_Pname,
          Auto_YOInt: Auto_YOInt,
          Auto_SOInt: Auto_SOInt,
          Auto_YOImple: Auto_YOImple,
          Auto_YRevi: Auto_YRevi,
          Auto_Revi: Auto_Revi,
          Auto_Desc1: Auto_Desc1,
          Auto_Link1: Auto_Link1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri01details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria01_113 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc2) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria0a1_113/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_NCourse: Auto_NCourse,
          Auto_CCode: Auto_CCode,
          Auto_Activities: Auto_Activities,
          Auto_YOIntro: Auto_YOIntro,
          Auto_Desc2: Auto_Desc2,
          Auto_Link2: Auto_Link2,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri01details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria01_132 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Auto_Desc3) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria01_132/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Name: Auto_Name,
          Auto_Course: Auto_Course,
          Auto_YOOff: Auto_YOOff,
          Auto_Times: Auto_Times,
          Auto_CDurat: Auto_CDurat,
          Auto_Number1: Auto_Number1,
          Auto_Number2: Auto_Number2,
          Auto_Desc3: Auto_Desc3,
          Auto_Link3: Auto_Link3,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri01details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria01_134 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc4) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria01_134/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Prg1: Auto_Prg1,
          Auto_Prg2: Auto_Prg2,
          Auto_Name1: Auto_Name1,
          Auto_Desc4: Auto_Desc4,
          Auto_Link4: Auto_Link4,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri01details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getautocri01details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getautocri01details"
      );
      if (res.status == 200) {
        setAllDetails(res.data.autocriteria01);
        setAuto_Criteria01(res.data.autocriteria01);
        setdata1(res.data.autocriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria01);
    }
  };

  useEffect(() => {
    getautocri01details();
  }, []);
  console.log(Auto_Criteria01);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  // const autoCriteria01Details = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria1"
  //     );
  //     if (res.status === 200) {
  //       setAllDetails(res.data.pgcriteria01);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAllDetails(error.response.data.pgcriteria01);
  //   }
  // };

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-01
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/autocriteria001">
                <button className="btn btn-success">
                  Add Criteria 1{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>
                  {/* sub-criteria  112 & 122 */}
                  <th>Criteria</th>
                  <th>Programme code</th>
                  <th>Program Name</th>
                  <th>Year of Introduction</th>
                  <th>Status of implementation of CBCS / ECS</th>
                  <th>Year of implementation of CBCS / ECS</th>
                  <th>Year of revision (if any)</th>
                  <th>
                    If revision has been carried out in the syllabus during last
                    5 years, Percentage of content added or replaced
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* sub-criteria  113 & 121 */}
                  <th>Criteria</th>
                  <th>Name of the Course</th>
                  <th>Course Code</th>
                  <th>
                    Activities/Content with direct bearing on Employability/
                    Entrepreneurship/ Skill development
                  </th>
                  <th>Year Of Introduction</th>

                  <th>Description</th>
                  <th>Document</th>
                  {/* sub-criteria  132 & 133 */}
                  <th>Criteria</th>
                  <th>
                    Name of the value added courses offered (with 30 or more
                    contact hours)
                  </th>
                  <th>Course Code (if any)</th>
                  <th>Year of offering</th>
                  <th>No. of times offered during the same year</th>
                  <th>Duration of the course</th>

                  <th>Number of students enrolled in the year</th>
                  <th>Number of Students completing course in the year</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* sub-criteria  134 */}

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program code</th>
                  <th>Name of the students</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Auto_Criteria01?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Auto_Criteria01_112?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_Pcode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_Pname?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_YOInt?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_SOInt?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_YOImple?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_Revi?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_YRevi?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_112?.Auto_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_NCourse?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_CCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_Activities?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_YOIntro?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_113?.Auto_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Name?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Course?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_YOOff?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Times?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_CDurat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Number1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Number2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_132?.Auto_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_Prg2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_Prg1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_Name1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria01_134?.Auto_Desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Auto_Year}</td>
                      <td>{Facultydetails?.FName}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_Pcode}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_Pname}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_YOInt}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_SOInt}</td>
                      <td>{item?.Auto_Criteria01_112?.Auto_YOImple}</td>

                      <td>{item?.Auto_Criteria01_112?.Auto_YRevi}</td>

                      <td>{item?.Auto_Criteria01_112?.Auto_Revi}</td>
                      <td>
                        {item?.Auto_Criteria01_112?.Auto_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>
                      {/* sub-criteria  113 & 121 */}
                      <td>{item?.Auto_Criteria01_113?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria01_113?.Auto_NCourse}</td>
                      <td>{item?.Auto_Criteria01_113?.Auto_CCode}</td>
                      <td>{item?.Auto_Criteria01_113?.Auto_Activities}</td>
                      <td>{item?.Auto_Criteria01_113?.Auto_YOIntro}</td>

                      <td>
                        {item?.Auto_Criteria01_113?.Auto_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>
                      {/* sub-criteria  132 & 133 */}
                      <td>{item?.Auto_Criteria01_132?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Name}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Course}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_YOOff}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Times}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_CDurat}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Number1}</td>
                      <td>{item?.Auto_Criteria01_132?.Auto_Number2}</td>
                      <td>
                        {item?.Auto_Criteria01_132?.Auto_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>
                      {/* sub-criteria  134*/}
                      <td>{item?.Auto_Criteria01_134?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria01_134?.Auto_Prg1}</td>
                      <td>{item?.Auto_Criteria01_134?.Auto_Prg2}</td>
                      <td>{item?.Auto_Criteria01_134?.Auto_Name1}</td>
                      <td>
                        {item?.Auto_Criteria01_134?.Auto_Desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 1 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------1.1.3------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.2 & 1.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>
                  {edit1 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Auto_Criteria01_112?.Auto_Pname}
                        onChange={(e) => setAuto_Pname(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria01_112?.Auto_Pname}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Status of implementation of CBCS / ECS
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Auto_Criteria01_112?.Auto_SOInt}
                        onChange={(e) => setAuto_SOInt(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria01_112?.Auto_SOInt}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Programme code
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria01_112?.Auto_Pcode}
                        onChange={(e) => setAuto_Pcode(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria01_112?.Auto_Pcode}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of Introduction
                    </label>
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_112?.Auto_YOInt}
                          onChange={(e) => setAuto_YOInt(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_112?.Auto_YOInt}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of revision (if any)
                    </label>
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_112?.Auto_YRevi}
                          onChange={(e) => setAuto_YRevi(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_112?.Auto_YRevi}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of implementation of CBCS / ECS
                    </label>
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_112?.Auto_YOImple}
                          onChange={(e) => setAuto_YOImple(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_112?.Auto_YOImple}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      If revision has been carried out in the syllabus during
                      last 5 years, Percentage of content added or replaced
                    </label>
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_112?.Auto_Revi}
                          onChange={(e) => setAuto_Revi(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_112?.Auto_Revi}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_112?.Auto_Desc1}
                          onChange={(e) => setAuto_Desc1(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_112?.Auto_Desc1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3 ">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload1"
                          accept="image/*"
                          placeholder={Value?.Auto_Criteria01_112?.Auto_Link1}
                          onChange={(e) => setAuto_Link1(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_112?.Auto_Link1}</>
                    )}
                  </div>
                  {Value?.approve == "true" ? (
                    <></>
                  ) : (
                    <div className="row mt-3">
                      <div
                        className="text-end"
                        // style={{
                        //   float: "right",
                        //   gap: "10px",
                        //   marginLeft: "35rem",
                        // }}
                      >
                        {edit1 ? (
                          <>
                            <button
                              className="btn btn-success"
                              style={{ color: "white" }}
                              onClick={(e) => {
                                editAuto_Criteria01_112(e, Value?._id);
                              }}
                            >
                              Submit
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={() => setedit1(true)}
                            >
                              Edit
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* ------1.1.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.3 & 1.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria01_113?.Auto_CCode}
                        onChange={(e) => {
                          setAuto_CCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria01_113?.Auto_CCode}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Course
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria01_113?.Auto_NCourse}
                        onChange={(e) => {
                          setAuto_NCourse(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria01_113?.Auto_NCourse}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year Of Introduction
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria01_113?.Auto_YOIntro}
                        onChange={(e) => setAuto_YOIntro(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria01_113?.Auto_YOIntro}</>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Activities/Content with direct bearing on Employability/
                    Entrepreneurship/ Skill development
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria01_113?.Auto_Activities
                        }
                        onChange={(e) => setAuto_Activities(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria01_113?.Auto_Activities}</>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria01_113?.Auto_Desc2}
                        onChange={(e) => setAuto_Desc2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria01_113?.Auto_Desc2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria01_113?.Auto_Link2}
                        onChange={(e) => setAuto_Link2(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria01_113?.Auto_Link2}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="text-end"
                      // style={{
                      //   float: "right",
                      //   gap: "10px",
                      //   marginLeft: "35rem",
                      // }}
                    >
                      {edit2 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editAuto_Criteria01_113(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.2 & 1.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the value added courses offered (with 30 or more
                      contact hours)
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_132?.Auto_Name}
                          onChange={(e) => {
                            setAuto_Name(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_132?.Auto_Name}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Course Code (if any)
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_132?.Auto_Course}
                          onChange={(e) => {
                            setAuto_Course(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_132?.Auto_Course}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of offering
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_132?.Auto_YOOff}
                          onChange={(e) => {
                            setAuto_YOOff(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_132?.Auto_YOOff}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      No. of times offered during the same year
                    </label>
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_132?.Auto_Times}
                          onChange={(e) => {
                            setAuto_Times(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_132?.Auto_Times}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Duration of course
                    </label>
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_132?.Auto_CDurat}
                          onChange={(e) => {
                            setAuto_CDurat(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_132?.Auto_CDurat}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of students enrolled in the year
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_132?.Auto_Number1}
                          onChange={(e) => {
                            setAuto_Number1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_132?.Auto_Number1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of Students completing the course in the year
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_132?.Auto_Number2}
                          onChange={(e) => {
                            setAuto_Number2(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_132?.Auto_Number2}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit3 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_132?.Auto_Desc3}
                          onChange={(e) => setAuto_Desc3(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_132?.Auto_Desc3}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="upload3 "
                          placeholder={Value?.Auto_Criteria01_132?.Auto_Link3}
                          onChange={(e) => setAuto_Link3(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_132?.Auto_Link3}</>
                    )}
                  </div>
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="text-end"
                      // style={{
                      //   float: "right",
                      //   gap: "10px",
                      //   marginLeft: "33rem",
                      // }}
                    >
                      {edit3 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editAuto_Criteria01_132(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.4
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    {edit4 ? (
                      <>
                        <select
                          class="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={Value?.Auto_Criteria01_134?.Auto_Prg1}
                          onChange={(e) => {
                            setAuto_Prg1(e.target.value);
                          }}
                        >
                          <option selected>--Select--</option>
                          <option value="BCA">BCA</option>
                          <option value="BSC">BSC</option>
                          <option value="BBA">BBA</option>
                          <option value="B.Com">B.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_134?.Auto_Prg1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_134?.Auto_Prg2}
                          onChange={(e) => {
                            setAuto_Prg2(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_134?.Auto_Prg2}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the students
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_134?.Auto_Name1}
                          onChange={(e) => {
                            setAuto_Name1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_134?.Auto_Name1}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Auto_Criteria01_134?.Auto_Desc4}
                          onChange={(e) => setAuto_Desc4(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_134?.Auto_Desc4}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Document
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload4"
                          placeholder={Value?.Auto_Criteria01_134?.Auto_Link4}
                          onChange={(e) => setAuto_Link4(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria01_134?.Auto_Link4}</>
                    )}
                  </div>
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row ">
                    <div
                      className="text-end"
                      // style={{
                      //   float: "right",
                      //   gap: "10px",
                      //   marginLeft: "35rem",
                      // }}
                    >
                      {edit4 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editAuto_Criteria01_134(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria01_112?.Auto_Desc1}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria01_113?.Auto_Desc2}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria01_132?.Auto_Desc3}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria01_134?.Auto_Desc4}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria01/${View?.Auto_Criteria01_112?.Auto_Link1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria01/${View?.Auto_Criteria01_113?.Auto_Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria01/${View?.Auto_Criteria01_132?.Auto_Link3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria01/${View?.Auto_Criteria01_134?.Auto_Link4}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Auto_Criteria01;
