import React, { useEffect, useState } from "react";
import { Button, Image, Modal, Table } from "react-bootstrap";
import { AiFillDelete, AiFillLock, AiFillUnlock } from "react-icons/ai";

import "./Css/Table.css";
import axios from "axios";
const Approvedclg = () => {
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [View, setView] = useState({});
  const [collegeApp, setCollegeApp] = useState([]);
  const getallAppColleges = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/app/approvedlist"
      );
      if (res.status === 200) {
        setCollegeApp(res.data.Register);
      }
    } catch (error) {
      console.log(error);
      setCollegeApp(error.response.data.Register);
    }
  };

  // ==========================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getallAppColleges();
    }
  }, []);

  //  ===========================================

  const block = async (userId) => {
    try {
      const config = {
        url: "/app/blockUnblockUser/" + userId,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getallAppColleges();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // =================== calling api for deleting the slider content======================
  const deleteRegister = async (e, id) => {
    e.preventDefault();

    try {
      const config = {
        url: "/pri/deleteregister/" + id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getallAppColleges();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };
  useEffect(() => {
    getallAppColleges();
  }, []);
  return (
    <div className="conrainer-fluid">
      <div className="row m-0 align-items-center justify-content-center pt-5">
        <div className="header-01 col-lg-12">
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            Approve College
          </span>
        </div>
      </div>
      <br />
      <div className="row m-0 pt-4">
        <Table
          responsive
          striped
          bordered
          hover
          style={{
            textAlign: "center",
            verticalAlign: "middle",
            width: "3000px",
          }}
        >
          {" "}
          <thead>
            <tr
              style={{
                textAlign: "center",
                color: "white",
                backgroundColor: "#161632",
              }}
            >
              <th>Sl.No</th>
              <th>HEI Code</th>
              <th>HEI Name</th>
              <th>Email Id</th>
              <th>Specification</th>
              <th>Phone No.</th>
              <th>Land No.</th>
              <th>HEI</th>
              <th>WebsiteLink</th>
              <th>Principal/IQAC</th>
              <th>Address</th>
              <th>Country</th>
              <th>State</th>
              <th>City</th>
              <th>Pincode</th>
              <th>College Image</th>
              <th>College Logo</th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {collegeApp?.map((item, i) => {
              return (
                <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <td>{i + 1}</td>
                  <td>{item?.HeiCode}</td>
                  <td>{item?.HeiName}</td>
                  <td>{item?.Email}</td>
                  <td>{item?.Registered_By}</td>
                  <td>{item?.PNumber}</td>
                  <td>{item?.HeiLandNumber}</td>
                  <td>{item?.HEI}</td>
                  <td>{item?.WebsiteLink}</td>
                  <td>{item?.PrincipalName}</td>
                  <td>{item?.Address}</td>
                  <td>{item?.Country}</td>
                  <td>{item?.State}</td>
                  <td>{item?.City}</td>
                  <td>{item?.Pincode}</td>

                  <td>
                    <Image
                      src={`https://brightnaac.co.in/register/${item?.CollegeImg}`}
                      alt="pic"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </td>
                  <td>
                    <Image
                      src={`https://brightnaac.co.in/register/${item?.CollegeLogo}`}
                      alt="pic"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </td>

                  <td>
                    {item?.isBlocked == false ? (
                      <>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "green",
                            margin: "10px",
                          }}
                          onClick={() => {
                            block(item._id);
                          }}
                        >
                          <AiFillUnlock size={20} />
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "orange",
                            margin: "10px",
                          }}
                          onClick={() => {
                            block(item._id);
                          }}
                        >
                          <AiFillLock size={20} />
                        </span>
                      </>
                    )}

                    {/* <span
                      style={{
                        cursor: "pointer",
                        color: "red",
                        margin: "10px",
                      }}
                      onClick={() => {
                        setView(item);
                        handleShow1();
                      }}
                    >
                      <AiFillDelete size={20} />
                    </span> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal>

      {/* =======================delete description======================= */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ color: "#b00b39" }}>Warning..!</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span style={{ fontSize: "20px", fontWeight: "700" }}>
            Are you sure..!,{" "}
            <span style={{ color: "#b00b39" }}>
              you want to delete this item....!
            </span>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <span
            className="header-03"
            onClick={(e) => deleteRegister(e, View?._id)}
          >
            <Button>Delete</Button>
          </span>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Approvedclg;
