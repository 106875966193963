import React from "react";
import "./Styles/Eventspage.css";

import {
  CiClock1,
  CiCalendar,
  CiLocationOn,
  CiPhone,
  CiUser,
  CiHospital1,
  CiMail,
  CiGlobe,
} from "react-icons/ci";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

const Eventspage = () => {
  const location = useLocation();
  return (
    <div className="eventspage">
      <div
        className="ab-0"
        style={{
          backgroundImage: "url('../Image/about-us.jpg')",
          height: "250px",
          position: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="edg container">
          <div className="edgt-has-inline-style">
            {location?.state?.eventName}
          </div>
          <div class="edgt-breadcrumbs-info">
            <div
              itemProp="breadcrumb"
              classname="edgt-breadcrumbs "
              style={{ color: "#e1e0e0" }}
            >
              <Link
                itemProp="url"
                to="/"
                style={{ color: "#e1e0e0", textDecoration: "none" }}
              >
                Home
              </Link>
              <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
              <span classname="edgt-current">Events</span>
            </div>
          </div>
        </div>
      </div>
      <img
        src="../gallery/logob.png"
        alt="logob"
        className="img-logo"
        style={{ color: "white" }}
      />
      <div className="container mt-5 mb-5 border-bottom pb-2">
        <div className="event-time">
          {/* <div className=" date m-2">
            <div className=" day  fs-3 fw-medium  "> 10 </div>
            <div className="oct">oct</div>
          </div> */}

          <div className=" m-2">
            <div className=" fs-2 fw-medium   ">
              {" "}
              {location?.state?.eventName}
            </div>
            <div className="timing text-black-50">
              {" "}
              <CiClock1 />
              <span className="text-black-50">
                {" "}
                TIME: {location?.state?.startTime} - {location?.state?.endTime}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mb-3">
          <div className="col-lg-12">
            <figure>
              <img
                className="img-fluid"
                src={`https://brightnaac.co.in/Events/${location?.state?.eventImg}`}
                alt="event10"
                width="100%"
              />
            </figure>
          </div>
          {/* <div className="col-lg-6">
            <iframe
              style={{ height: "95%" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.7573847155477!2d77.73461717371583!3d12.987363587329455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11c4f2593603%3A0xfd28e018220a1c4f!2sITPL!5e0!3m2!1sen!2sin!4v1690960575950!5m2!1sen!2sin"
              width="100%"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div> */}
        </div>

        <div className="row p-2 mb-5">{location?.state?.eventDescription}</div>

        <div className="fs-4 mb-3">Event Details</div>

        <div className="row ">
          <div className="col-lg-6">
            <div className="">
              <div className="fs-6 mt-1 text-black-50">
                <CiCalendar />{" "}
                <span>
                  {" "}
                  DATE:{" "}
                  {moment(location?.state?.startDate).format(
                    "DD-MM-YYYY"
                  )} - {moment(location?.state?.endDate).format("DD-MM-YYYY")}
                </span>
              </div>

              <div className="fs-6 mt-1 text-black-50">
                <CiClock1 />
                <span>
                  {" "}
                  TIME: {location?.state?.startTime} -{" "}
                  {location?.state?.endTime}
                </span>
              </div>

              <div className="fs-6 mt-1 text-black-50">
                {" "}
                <CiHospital1 /> VENUE: {location?.state?.venue}
              </div>
              <div className="fs-6 mt-1 text-black-50 mb-3">
                <CiLocationOn />
                <span> ADDRESS: {location?.state?.address}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-6 ">
            <div className="">
              <div className="fs-6 mt-1 text-black-50">
                <CiUser />{" "}
                <span> ORGANIZER NAME: {location?.state?.organizerName} </span>
              </div>

              <div className="fs-6 mt-1 text-black-50">
                <CiPhone />
                <span> PHONE: {location?.state?.phone} </span>
              </div>

              <div className="fs-6 mt-1 text-black-50">
                {" "}
                <CiMail /> EMAIL: {location?.state?.email}
              </div>
              <div className="fs-6 mt-1 text-black-50">
                <CiGlobe />
                <span> WEBSITE: {location?.state?.website}</span>
              </div>
            </div>
          </div>

          <hr className=" mt-3 mb-5"></hr>
        </div>

        <div className="container">
          <div className="row align-items-center justify-content-center text-center mb-5 ">
            <div className="col-lg-12 col-md-6 col-sm-6 col-6">
              <div className="row">
                <div className=" col-10 text-end">
                  <div className="fw-medium  text-course">Next</div>
                  <div className="color ipm">English With Natives</div>
                </div>
                <div className="col-1">
                  <img
                    className="next-image ipm"
                    style={{
                      maxWidth: "100px",
                    }}
                    src="../gallery/train1.jpg"
                    alt="train1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eventspage;
