import React from "react";
import { Container } from "react-bootstrap";
import "../Component/Styles/Gallerypages.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
const Gallerypages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="gallerypages">
      <div
        className="ab-0"
        style={{
          backgroundImage: "url('../Image/about-us.jpg')",
          height: "250px",
          position: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="edg container">
          <div className="edgt-has-inline-style">Learn Advanced Course</div>
          <div class="edgt-breadcrumbs-info">
            <div
              itemProp="breadcrumb"
              classname="edgt-breadcrumbs "
              style={{ color: "#e1e0e0" }}
            >
              <a
                itemProp="url"
                href="/"
                style={{ color: "#e1e0e0", textDecoration: "none" }}
              >
                Home
              </a>
              <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
              <span classname="edgt-current">Technology</span>

              <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
              <span classname="edgt-current">Learn Advanced Course</span>
            </div>
          </div>
        </div>
      </div>

      <Container>
        <div className="row mt-5 mb-5">
          <div className="col mt-5">
            <div>
              <img
                src={`https://brightnaac.co.in/Gallery/${location?.state?.galleryImg}`}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <h2 className="fs-2">{location?.state?.heading}</h2>

        <div className="row mb-5">
          <div className="col-lg-8 mb-5">
            <p className="text-justify gallerypage-text">
              {location?.state?.description}{" "}
            </p>{" "}
          </div>
          <div className="col-lg-4">
            <div className="mb-2">
              <span className="text-uppercase fw-medium text-course">
                Place:
              </span>{" "}
              <span className="color">{location?.state?.place}</span>
            </div>

            <div className="mb-2">
              <span className="text-uppercase fw-medium text-course">
                ORGANIZER:
              </span>{" "}
              <span className="color">{location?.state?.organizer}</span>
            </div>
            <div className="mb-2">
              <span className="text-uppercase fw-medium text-course">
                CATEGORY:
              </span>{" "}
              <a className="color text-decoration-none text-course">
                {location?.state?.category} ,
              </a>{" "}
            </div>
            <div className="mb-4">
              <span className="text-uppercase fw-medium text-course">
                DATE:
              </span>{" "}
              <span className="color">
                {moment(location?.state?.date)?.format("DD-MM-YYYY")}
              </span>
            </div>

            <hr></hr>

            <div>
              <span className="text-uppercase fw-medium text-course">
                Share:
              </span>
              <span className="m-2">
                <img className="social-img" src="../gallery/facebook.png" />
              </span>
              <span className="m-2">
                <img className="social-img" src="../gallery/google-plus.png" />
              </span>

              <span className="m-2">
                <img className="social-img" src="../gallery/twitter.png" />
              </span>

              <span className="m-2">
                <img className="social-img" src="../gallery/linkedin.png" />
              </span>
              <span className="m-2">
                <img className="social-img" src="../gallery/youtube.png" />
              </span>
            </div>
          </div>
        </div>

        <hr></hr>
      </Container>

      <div className="container">
        <div className="row align-items-center justify-content-center text-center mt-5 mb-5 ">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <Link
              className="text-decoration-none  text-course"
              onClick={() => navigate(-1)}
            >
              Back
            </Link>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <div className="row">
              <div className=" col-7 text-end">
                <div className="fw-medium  text-course">Next</div>
                <div className="color ipm">Lorem crafts for ipsum</div>
              </div>
              <div className="col-4">
                <img
                  className="next-image ipm"
                  style={{
                    maxWidth: "100px",
                  }}
                  src="../gallery/train1.jpg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallerypages;
