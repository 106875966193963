import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const UG_Criteria02 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);

  const [PrgCode, setPrgCode] = useState("");
  const [NoSeatSanctioned, setNoSeatSanctioned] = useState("");
  const [NoStdAdmit, setNoStdAdmit] = useState("");
  const [PrgName1, setPrgName1] = useState("");
  const [Desc6, setDesc6] = useState("");
  const [Link6, setLink6] = useState("");

  const [SC, setSC] = useState("");
  const [ST, setST] = useState("");
  const [OBC, setOBC] = useState("");
  const [Gen, setGen] = useState("");
  const [Divyangjan, setDivyangjan] = useState("");
  const [Others, setOthers] = useState("");
  const [SSC, setSSC] = useState("");
  const [SST, setSST] = useState("");
  const [OOBC, setOOBC] = useState("");
  const [GGen, setGGen] = useState("");
  const [DDivyangjan, setDDivyangjan] = useState("");
  const [OOthers, setOOthers] = useState("");
  const [Desc7, setDesc7] = useState("");
  const [Link7, setLink7] = useState("");

  const [TName, setTName] = useState("");
  const [PAN, setPAN] = useState("");
  const [Designation, setDesignation] = useState("");
  const [YOfAppoint, setYOfAppoint] = useState("");
  const [NoOfAppoint, setNoOfAppoint] = useState("");
  const [DeptName, setDeptName] = useState("");
  const [TYofExp, setTYofExp] = useState("");
  const [TService, setTService] = useState("");
  const [Desc8, setDesc8] = useState("");
  const [Link8, setLink8] = useState("");

  const [SerTeacher, setSerTeacher] = useState("");
  const [Qualify, setQualify] = useState("");
  const [YOfQuali, setYOfQuali] = useState("");
  const [YOfRecog, setYOfRecog] = useState("");
  const [Whether, setWhether] = useState("");
  const [Desc9, setDesc9] = useState("");
  const [Link9, setLink9] = useState("");
  const [pCode, setpCode] = useState("");
  const [PrgName2, setPrgName2] = useState("");
  const [NStdAppeared, setNStdAppeared] = useState("");
  const [NStdPassed, setNStdPassed] = useState("");
  const [Desc10, setDesc10] = useState("");
  const [Link10, setLink10] = useState("");
  const [PrgName3, setPrgName3] = useState("");
  const [NStd, setNStd] = useState("");
  const [Gender, setGender] = useState("");
  const [Category, setCategory] = useState("");
  const [SDomicile, setSDomicile] = useState("");
  const [NIndian, setNIndian] = useState("");
  const [Email, setEmail] = useState("");
  const [MNo, setMNo] = useState("");
  const [YJoining, setYJoining] = useState("");
  const [UID, setUID] = useState("");
  const [Desc11, setDesc11] = useState("");
  const [Link11, setLink11] = useState("");

  const [View, setView] = useState({});
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [UG_Criteria02, setUG_Criteria02] = useState([]);
  const [Value, setValue] = useState({});

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Cri2.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
        <table>
          <tr>
            <td>SC</td>
            <td>${item?.Criteria02_212?.SC}</td>
          </tr>
          <tr>
            <td>ST</td>
            <td>${item?.Criteria02_212?.ST}</td>
          </tr>
          <tr>
            <td>OBC</td>
            <td>${item?.Criteria02_212?.OBC}</td>
          </tr>
          <tr>
            <td>General</td>
            <td>${item?.Criteria02_212?.Gen}</td>
          </tr>
          <tr>
            <td>Divyangjan</td>
            <td>${item?.Criteria02_212?.Divyangjan}</td>
          </tr>
          <tr>
            <td>Others</td>
            <td>${item?.Criteria02_212?.Others}</td>
          </tr>
        </table>
      `;

        let tableMarkup2 = `
        <table>
          <tr>
            <td>SC</td>
            <td>${item?.Criteria02_212?.SSC}</td>
          </tr>
          <tr>
            <td>ST</td>
            <td>${item?.Criteria02_212?.SST}</td>
          </tr>
          <tr>
            <td>OBC</td>
            <td>${item?.Criteria02_212?.OOBC}</td>
          </tr>
          <tr>
            <td>General</td>
            <td>${item?.Criteria02_212?.GGen}</td>
          </tr>
          <tr>
            <td>Divyangjan</td>          
            <td>${item?.Criteria02_212?.DDivyangjan}</td>
          </tr>
          <tr>
            <td>Others</td>
            <td>${item?.Criteria02_212?.OOthers}</td>
          </tr>
        </table>
      `;
        let obj = {
          //  Criteria 2_211

          Year: item?.Year,
          "Criteria_2.1.1": item?.Criteria02_211?.criteria,
          "Program Name": item?.Criteria02_211?.PrgName1,
          "Program Code": item?.Criteria02_211?.PrgCode,
          "Number of seats sanctioned": item?.Criteria02_211?.NoSeatSanctioned,
          "Number of Students admitted": item?.Criteria02_211?.NoStdAdmit,
          "Description(min 500 words)(2.1.1)": item?.Criteria02_211?.Desc6,
          "Upload the relevant document(2.1.1)": item?.Criteria02_211?.Link6,

          // Criteria 2_212

          "Criteria_2.1.2": item?.Criteria02_212?.criteria,

          "Number of seats earmarked for reserved category as per GOI or State Government rule":
            tableMarkup1,
          "Number of students admitted from the reserved category":
            tableMarkup2,
          "Description(min 500 words)(2.1.2)": item?.Criteria02_212?.Desc7,
          "Upload the relevant document(2.1.2)": item?.Criteria02_212?.Link7,

          // Criteria 2_241
          "Criteria_2.4.1": item?.Criteria02_241?.criteria,
          "Name of the Full-time teacher": item?.Criteria02_241?.TName,
          PAN: item?.Criteria02_241?.PAN,
          Designation: item?.Criteria02_241?.Designation,
          "Year of appointment": item?.Criteria02_241?.YOfAppoint,
          "Name of the Department": item?.Criteria02_241?.DeptName,
          "Nature of appointment (Against Sanctioned post,temporary,permanent)":
            item?.Criteria02_241?.NoOfAppoint,
          "Total years of Experience in the same institution":
            item?.Criteria02_241?.TYofExp,
          "Is the teacher still serving the institution/If not last  year of the service of Faculty to the Institution.":
            item?.Criteria02_241?.TService,
          "Description(min 500 words)(2.4.1)": item?.Criteria02_241?.Desc8,
          "Upload the relevant document(2.4.1)": item?.Criteria02_241?.Link8,

          // Criteria 2_242
          "Criteria_2.4.2": item?.Criteria02_242?.criteria,
          " Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.":
            item?.Criteria02_242?.SerTeacher,
          " Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./ D.Litt.)":
            item?.Criteria02_242?.Qualify,
          "Year of obtaining the qualification": item?.Criteria02_242?.YOfQuali,
          "Year of Recognition as a Research Guide":
            item?.Criteria02_242?.YOfRecog,
          " Whether recognised as a Research Guide for  Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.":
            item?.Criteria02_242?.Whether,
          "Description(min 500 words)(2.4.2)": item?.Criteria02_242?.Desc9,
          "Upload the relevant document(2.4.2)": item?.Criteria02_242?.Link9,

          // Criteria 2_263
          "Criteria_2.6.3": item?.Criteria02_263?.criteria,
          "Program Name1": item?.Criteria02_263?.PrgName2,
          "Program Code1": item?.Criteria02_263?.pCode,
          " Number of students appeared in the final year examination":
            item?.Criteria02_263?.NStdAppeared,
          " Number of students passed in final year examination":
            item?.Criteria02_263?.NStdPassed,
          "Description(min 500 words)(2.6.3)": item?.Criteria02_263?.Desc10,
          " Upload the relevant document(2.6.3)": item?.Criteria02_263?.Link10,

          //Criteria 2_271
          "Criteria_2.7.1": item?.Criteria02_271?.criteria,
          "Program Name2": item?.Criteria02_271?.PrgName3,
          "Name of the student": item?.Criteria02_271?.NStd,
          Gender: item?.Criteria02_271?.Gender,
          Category: item?.Criteria02_271?.Category,
          "State of Domicile": item?.Criteria02_271?.SDomicile,
          "Nationality if othern than Indian": item?.Criteria02_271?.NIndian,
          "Email-ID": item?.Criteria02_271?.Email,
          "Mobile Number": item?.Criteria02_271?.MNo,
          "Year of joining": item?.Criteria02_271?.YJoining,
          "Unique Enrollment ID / College ID/ University enrollment number":
            item?.Criteria02_271?.UID,
          "Description(min 500 words)(2.7.1)": item?.Criteria02_271?.Desc11,
          "Upload the relevant document(2.7.1)": item?.Criteria02_271?.Link11,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editCriteria02_211 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc6) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria02_211/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PrgName1: PrgName1,
          PrgCode: PrgCode,
          NoSeatSanctioned: NoSeatSanctioned,
          NoStdAdmit: NoStdAdmit,
          Desc6: Desc6,
          Link6: Link6,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri02details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria02_212 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc7;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc7) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria02_212/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          SC: SC,
          ST: ST,
          OBC: OBC,
          Gen: Gen,
          Divyangjan: Divyangjan,
          Others: Others,
          SSC: SSC,
          SST: SST,
          OOBC: OOBC,
          GGen: GGen,
          DDivyangjan: DDivyangjan,
          OOthers: OOthers,
          Desc7: Desc7,
          Link7: Link7,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri02details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria02_241 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc8;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc8) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria02_241/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          TName: TName,
          PAN: PAN,
          Designation: Designation,
          YOfAppoint: YOfAppoint,
          NoOfAppoint: NoOfAppoint,
          DeptName: DeptName,
          TYofExp: TYofExp,
          TService: TService,
          Desc8: Desc8,
          Link8: Link8,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri02details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria02_242 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc9;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc9) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria02_242/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          SerTeacher: SerTeacher,
          Qualify: Qualify,
          YOfQuali: YOfQuali,
          YOfRecog: YOfRecog,
          Whether: Whether,
          Desc9: Desc9,
          Link9: Link9,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri02details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria02_263 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc10;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc10) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria02_263/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          pCode: pCode,
          PrgName2: PrgName2,
          NStdAppeared: NStdAppeared,
          NStdPassed: NStdPassed,
          Desc10: Desc10,
          Link10: Link10,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri02details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria02_271 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc11;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc11) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria02_271/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PrgName3: PrgName3,
          NStd: NStd,
          Gender: Gender,
          Category: Category,
          SDomicile: SDomicile,
          NIndian: NIndian,
          Email: Email,
          MNo: MNo,
          YJoining: YJoining,
          UID: UID,
          Desc11: Desc11,
          Link11: Link11,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri02details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getcri02details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria02"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria02);
        setUG_Criteria02(res.data.ugcriteria02);
        setdata1(res.data.ugcriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria02);
    }
  };

  useEffect(() => {
    getcri02details();
  }, []);
  console.log(Value);

  // =================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  // ================================================

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-02
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/ugcriteria002">
                <button className="btn btn-success">
                  Add Criteria 2{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>
                  <th>Criteria</th>
                  <th>Program Code</th>
                  <th>Program Name</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of Students admitted</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>Criteria</th>
                  <th>SC</th>
                  <th>ST</th>
                  <th>OBC</th>
                  <th>Divyangjan</th>
                  <th>Gen</th>
                  <th>Others</th>
                  <th>SC</th>
                  <th>ST</th>
                  <th>OBC</th>
                  <th>Divyangjan</th>
                  <th>Gen</th>
                  <th>Others</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>Name of the Department</th>
                  <th>
                    Nature of appointment (Against Sanctioned post,
                    temporary,permanent)
                  </th>

                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution.
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if other than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>
                    Unique Enrollment ID / College ID/ University enrollment
                    number
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {UG_Criteria02?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Criteria02_211?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria02_211?.PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_211?.PrgCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_211?.NoSeatSanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_211?.NoStdAdmit?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_211?.Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.SC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.ST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.OBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.Gen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.Divyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.Others?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.SSC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.SST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.OOBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.GGen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.DDivyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.OOthers?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_212?.Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.TName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.PAN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_241?.Designation?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.YOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_241?.NoOfAppoint?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.DeptName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.TYofExp?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_241?.TService?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.SerTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.YOfQuali?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_242?.Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.YOfRecog?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_242?.Desc9?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_263?.PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_263?.pCode
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria02_263?.NStdAppeared?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_263?.NStdPassed?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_263?.Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.NStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Gender?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.SDomicile?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.NIndian?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Email?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.MNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_271?.YJoining?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria02_271?.UID?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Desc11?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Criteria02_211?.criteria}</td>
                      <td>{item?.Criteria02_211?.PrgCode}</td>
                      <td>{item?.Criteria02_211?.PrgName1}</td>
                      <td>{item?.Criteria02_211?.NoSeatSanctioned}</td>
                      <td>{item?.Criteria02_211?.NoStdAdmit}</td>
                      <td>
                        {item?.Criteria02_211?.Desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_212?.criteria}</td>
                      <td>{item?.Criteria02_212?.SC}</td>
                      <td>{item?.Criteria02_212?.ST}</td>
                      <td>{item?.Criteria02_212?.OBC}</td>
                      <td>{item?.Criteria02_212?.Divyangjan}</td>
                      <td>{item?.Criteria02_212?.Gen}</td>
                      <td>{item?.Criteria02_212?.Others}</td>
                      <td>{item?.Criteria02_212?.SSC}</td>
                      <td>{item?.Criteria02_212?.SST}</td>
                      <td>{item?.Criteria02_212?.OOBC}</td>
                      <td>{item?.Criteria02_212?.DDivyangjan}</td>
                      <td>{item?.Criteria02_212?.GGen}</td>
                      <td>{item?.Criteria02_212?.OOthers}</td>
                      <td>
                        {item?.Criteria02_212?.Desc7?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_241?.criteria}</td>
                      <td>{item?.Criteria02_241?.TName}</td>
                      <td>{item?.Criteria02_241?.PAN}</td>
                      <td>{item?.Criteria02_241?.Designation}</td>
                      <td>{item?.Criteria02_241?.YOfAppoint}</td>
                      <td>{item?.Criteria02_241?.NoOfAppoint}</td>
                      <td>{item?.Criteria02_241?.DeptName}</td>
                      <td>{item?.Criteria02_241?.TYofExp}</td>
                      <td>{item?.Criteria02_241?.TService}</td>
                      <td>
                        {item?.Criteria02_241?.Desc8?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_242?.criteria}</td>
                      <td>{item?.Criteria02_242?.SerTeacher}</td>
                      <td>{item?.Criteria02_242?.Qualify}</td>
                      <td>{item?.Criteria02_242?.YOfQuali}</td>
                      <td>{item?.Criteria02_242?.YOfRecog}</td>
                      <td>{item?.Criteria02_242?.Whether}</td>
                      <td>
                        {item?.Criteria02_242?.Desc9?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_263?.criteria}</td>
                      <td>{item?.Criteria02_263?.PrgName2}</td>
                      <td>{item?.Criteria02_263?.pCode}</td>
                      <td>{item?.Criteria02_263?.NStdAppeared}</td>
                      <td>{item?.Criteria02_263?.NStdPassed}</td>
                      <td>
                        {item?.Criteria02_263?.Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_271?.criteria}</td>
                      <td>{item?.Criteria02_271?.PrgName3}</td>
                      <td>{item?.Criteria02_271?.NStd}</td>
                      <td>{item?.Criteria02_271?.Gender}</td>
                      <td>{item?.Criteria02_271?.Category}</td>
                      <td>{item?.Criteria02_271?.SDomicile}</td>
                      <td>{item?.Criteria02_271?.NIndian}</td>
                      <td>{item?.Criteria02_271?.Email}</td>
                      <td>{item?.Criteria02_271?.MNo}</td>
                      <td>{item?.Criteria02_271?.YJoining}</td>
                      <td>{item?.Criteria02_271?.UID}</td>
                      <td>
                        {item?.Criteria02_271?.Desc11?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={(e) => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            setedit6(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                UnderGraduate_Criteria-02
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme name
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Criteria02_211?.PrgName1}
                        onChange={(e) => {
                          setPrgName1(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Criteria02_211?.PrgName1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program code
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_211?.PrgCode}
                        onChange={(e) => {
                          setPrgCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_211?.PrgCode}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of seats sanctioned
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_211?.NoSeatSanctioned}
                        onChange={(e) => {
                          setNoSeatSanctioned(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_211?.NoSeatSanctioned}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students admitted
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_211?.NoStdAdmit}
                        onChange={(e) => setNoStdAdmit(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria02_211?.NoStdAdmit}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_211?.Desc6}
                        onChange={(e) => setDesc6(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_211?.Desc6}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload1"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.Criteria02_211?.Link6}
                        onChange={(e) => setLink6(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_211?.Link6}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit1 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria02_211(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                <h6 className="text-center text-decoration-underline">
                  Number of seats earmarked for reserved category as per GOI or
                  State Government rule
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    SC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.SC}
                        onChange={(e) => {
                          setSC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.SC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST{" "}
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.ST}
                        onChange={(e) => {
                          setST(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.ST}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.OBC}
                        onChange={(e) => {
                          setOBC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.OBC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.Gen}
                        onChange={(e) => {
                          setGen(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.Gen}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.Divyangjan}
                        onChange={(e) => {
                          setDivyangjan(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.Divyangjan}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.Others}
                        onChange={(e) => {
                          setOthers(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.Others}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students admitted from the reserved category
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.SSC}
                        onChange={(e) => {
                          setSSC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.SSC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.SST}
                        onChange={(e) => {
                          setSST(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.SST}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.OOBC}
                        onChange={(e) => {
                          setOOBC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.OOBC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.GGen}
                        onChange={(e) => {
                          setGGen(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.GGen}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.DDivyangjan}
                        onChange={(e) => {
                          setDDivyangjan(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.DDivyangjan}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_212?.OOthers}
                        onChange={(e) => {
                          setOOthers(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.OOthers}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_212?.Desc7}
                        onChange={(e) => setDesc7(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.Desc7}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.Criteria02_212?.Link7}
                        onChange={(e) => setLink7(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_212?.Link7}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit2 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria02_212(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.1 & 2.4.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.1 & 2.4.3------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Full-time teacher
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_241?.TName}
                        onChange={(e) => {
                          setTName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.TName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    PAN
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_241?.PAN}
                        onChange={(e) => {
                          setPAN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.PAN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Designation
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_241?.Designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.Designation}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of appointment
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_241?.YOfAppoint}
                        onChange={(e) => {
                          setYOfAppoint(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.YOfAppoint}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_241?.NoOfAppoint}
                        onChange={(e) => {
                          setNoOfAppoint(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.NoOfAppoint}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_241?.DeptName}
                        onChange={(e) => {
                          setDeptName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.DeptName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total years of Experience in the same institution
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_241?.TYofExp}
                        onChange={(e) => {
                          setTYofExp(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.TYofExp}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_241?.TService}
                        onChange={(e) => {
                          setTService(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.TService}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_241?.Desc8}
                        onChange={(e) => setDesc8(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.Desc8}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.Criteria02_241?.Link8}
                        onChange={(e) => setLink8(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_241?.Link8}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit3 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria02_241(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                {/* -------2.4.2------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_242?.SerTeacher}
                        onChange={(e) => {
                          setSerTeacher(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_242?.SerTeacher}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_242?.Qualify}
                        onChange={(e) => {
                          setQualify(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_242?.Qualify}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of obtaining the qualification
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_242?.YOfQuali}
                        onChange={(e) => {
                          setYOfQuali(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_242?.YOfQuali}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Recognition as a Research Guide
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_242?.YOfRecog}
                        onChange={(e) => {
                          setYOfRecog(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_242?.YOfRecog}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </label>

                  {edit4 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "270px" }}
                        placeholder={Value?.Criteria02_242?.Whether}
                        onChange={(e) => setWhether(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Criteria02_242?.Whether}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_242?.Desc9}
                        onChange={(e) => setDesc9(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_242?.Desc9}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        accept="image/*"
                        placeholder={Value?.Criteria02_242?.Link9}
                        onChange={(e) => {
                          setLink9(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_242?.Link9}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit4 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria02_242(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.6.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.6.3------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit5 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Criteria02_263?.PrgName2}
                        onChange={(e) => setPrgName2(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Criteria02_263?.PrgName2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_263?.pCode}
                        onChange={(e) => setpCode(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_263?.pCode}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students appeared in the final year examination
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Criteria02_263?.NStdAppeared}
                          onChange={(e) => setNStdAppeared(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Criteria02_263?.NStdAppeared}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students passed in final year examination
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_263?.NStdPassed}
                        onChange={(e) => setNStdPassed(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_263?.NStdPassed}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_263?.Desc10}
                        onChange={(e) => setDesc10(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_263?.Desc10}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload5"
                        accept="image/*"
                        placeholder={Value?.Criteria02_263?.Link10}
                        onChange={(e) => {
                          setLink10(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_263?.Link10}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit5 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria02_263(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    Program Name
                  </label>

                  {edit6 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Criteria02_271?.PrgName3}
                        onChange={(e) => {
                          setPrgName3(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.PrgName3}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Student Name
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria02_271?.NStd}
                        onChange={(e) => {
                          setNStd(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.NStd}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gender
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_271?.Gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.Gender}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria02_271?.Category}
                        onChange={(e) => {
                          setCategory(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.Category}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State of Domicile
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_271?.SDomicile}
                        onChange={(e) => {
                          setSDomicile(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.SDomicile}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Nationality if othern than Indian
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_271?.NIndian}
                        onChange={(e) => {
                          setNIndian(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.NIndian}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Email-ID
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_271?.Email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.Email}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Mobile Number
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_271?.MNo}
                        onChange={(e) => {
                          setMNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.MNo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of joining
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_271?.YJoining}
                        onChange={(e) => {
                          setYJoining(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.YJoining}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Unique Enrollment ID / College ID/ University enrollment
                    number
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_271?.UID}
                        onChange={(e) => {
                          setUID(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.UID}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria02_271?.Desc11}
                        onChange={(e) => setDesc11(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.Desc11}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload6"
                        accept="image/*"
                        placeholder={Value?.Criteria02_271?.Link11}
                        onChange={(e) => {
                          setLink11(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria02_271?.Link11}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit6 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria02_271(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit6(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={(e) => {
                handleClose();
                setedit1(false);
                setedit2(false);
                setedit3(false);
                setedit4(false);
                setedit5(false);
                setedit6(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria02_211?.Desc6}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria02_212?.Desc7}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria02_241?.Desc8}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria02_242?.Desc9}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria02_263?.Desc10}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>{View?.Criteria02_271?.Desc11}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_211?.Link6}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_212?.Link7}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_241?.Link8}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_242?.Link9}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_263?.Link10}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_271?.Link11}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                ></object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default UG_Criteria02;
