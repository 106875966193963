import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Uni_Criteria005 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);
  const [edit8, setedit8] = useState(false);
  const [add8, setadd8] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  //post method for Uni_criteria_05
  const [Uni_Year, setUni_Year] = useState("");

  const [Uni_Scheme, setUni_Scheme] = useState("");
  const [Uni_Std, setUni_Std] = useState("");
  const [Uni_Amt, setUni_Amt] = useState("");
  const [Uni_Std1, setUni_Std1] = useState("");
  const [Uni_Amt1, setUni_Amt1] = useState("");
  const [Uni_Std2, setUni_Std2] = useState("");
  const [Uni_Amt2, setUni_Amt2] = useState("");
  const [Uni_NGO, setUni_NGO] = useState("");
  const [Uni_Desc18, setUni_Desc18] = useState("");
  const [Uni_Link18, setUni_Link18] = useState("");

  const [Uni_Capabality, setUni_Capabality] = useState("");
  const [Uni_Date, setUni_Date] = useState("");
  const [Uni_Enrolled, setUni_Enrolled] = useState("");
  const [Uni_Agencies, setUni_Agencies] = useState("");
  const [Uni_Desc19, setUni_Desc19] = useState("");
  const [Uni_Link19, setUni_Link19] = useState("");

  const [Uni_HEI, setUni_HEI] = useState("");
  const [Uni_Number, setUni_Number] = useState("");
  const [Uni_Desc20, setUni_Desc20] = useState("");
  const [Uni_Link20, setUni_Link20] = useState("");

  const [Uni_Placed1, setUni_Placed1] = useState("");
  const [Uni_Graduate, setUni_Graduate] = useState("");
  const [Uni_employer, setUni_employer] = useState("");
  const [Uni_Package, setUni_Package] = useState("");
  const [Uni_Desc21, setUni_Desc21] = useState("");
  const [Uni_Link21, setUni_Link21] = useState("");

  const [Uni_Higher, setUni_Higher] = useState("");
  const [Uni_Graduat1, setUni_Graduat1] = useState("");
  const [Uni_Institute, setUni_Institute] = useState("");
  const [Uni_Admit, setUni_Admit] = useState("");
  const [Uni_Desc22, setUni_Desc22] = useState("");
  const [Uni_Link22, setUni_Link22] = useState("");

  const [Uni_No, setUni_No] = useState("");
  const [Uni_RollNo, setUni_RollNo] = useState("");
  const [Uni_StdSelected, setUni_StdSelected] = useState("");
  const [Uni_NET, setUni_NET] = useState("");
  const [Uni_SLET, setUni_SLET] = useState("");
  const [Uni_GATE, setUni_GATE] = useState("");
  const [Uni_GMAT, setUni_GMAT] = useState("");
  const [Uni_CAT, setUni_CAT] = useState("");
  const [Uni_GRE, setUni_GRE] = useState("");
  const [Uni_JAM, setUni_JAM] = useState("");
  const [Uni_IELTS, setUni_IELTS] = useState("");
  const [Uni_TOEFL, setUni_TOEFL] = useState("");
  const [Uni_Civil, setUni_Civil] = useState("");
  const [Uni_State, setUni_State] = useState("");
  const [Uni_OtherExam, setUni_OtherExam] = useState("");
  const [Uni_Total, setUni_Total] = useState("");
  const [Uni_GTotal, setUni_GTotal] = useState("");
  const [Uni_Desc23, setUni_Desc23] = useState("");
  const [Uni_Link23, setUni_Link23] = useState("");

  const [Uni_Medal, setUni_Medal] = useState("");
  const [Uni_Individual, setUni_Individual] = useState("");
  const [Uni_Inter, setUni_Inter] = useState("");
  const [Uni_Event, setUni_Event] = useState("");
  const [Uni_Student, setUni_Student] = useState("");
  const [Uni_Desc24, setUni_Desc24] = useState("");
  const [Uni_Link24, setUni_Link24] = useState("");

  const [Uni_Event1, setUni_Event1] = useState("");
  const [Uni_Event2, setUni_Event2] = useState("");
  const [Uni_Desc25, setUni_Desc25] = useState("");
  const [Uni_Link25, setUni_Link25] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getunicriteria5 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));

      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));

      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));

      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));

      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));

      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));

      let am8 = sessionStorage.getItem("cat8");
      setobj8(JSON.parse(am8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getunicriteria5();
  }, []);

  const unicriteria5 = async () => {
    if (!Uni_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/uniadddetails05",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Year: Uni_Year,

          Uni_Criteria05_511: obj1,
          Uni_Link18: Uni_Link18,

          Uni_Criteria05_513: obj2,
          Uni_Link19: Uni_Link19,

          Uni_Criteria05_512: obj3,
          Uni_Link20: Uni_Link20,

          Uni_Criteria05_522: obj4,
          Uni_Link21: Uni_Link21,

          Uni_Criteria05_523: obj5,
          Uni_Link22: Uni_Link22,

          Uni_Criteria05_521: obj6,
          Uni_Link23: Uni_Link23,

          Uni_Criteria05_531: obj7,
          Uni_Link24: Uni_Link24,

          Uni_Criteria05_533: obj8,
          Uni_Link25: Uni_Link25,

          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          sessionStorage.removeItem("cat8");

          navigate("/unicriteria05");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Uni_Scheme ||
          !Uni_Std ||
          !Uni_Amt ||
          !Uni_Std1 ||
          !Uni_Amt1 ||
          !Uni_Std2 ||
          !Uni_Amt2 ||
          !Uni_NGO ||
          !Uni_Desc18 ||
          !Uni_Link18
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Uni_Link18);
      let obj = {
        Uni_criteria: "5.1.1",
        Uni_Scheme: Uni_Scheme,
        Uni_Std: Uni_Std,
        Uni_Amt: Uni_Amt,
        Uni_Std1: Uni_Std1,
        Uni_Amt1: Uni_Amt1,
        Uni_Std2: Uni_Std2,
        Uni_Amt2: Uni_Amt2,
        Uni_NGO: Uni_NGO,
        Uni_Desc18: Uni_Desc18,
        Uni_Link18: Uni_Link18,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getunicriteria5();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !Uni_Capabality ||
          !Uni_Date ||
          !Uni_Enrolled ||
          !Uni_Agencies ||
          !Uni_Desc19 ||
          !Uni_Link19
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Uni_Link19);
      let obj = {
        Uni_criteria: "5.1.3",
        Uni_Capabality: Uni_Capabality,
        Uni_Date: Uni_Date,
        Uni_Enrolled: Uni_Enrolled,
        Uni_Agencies: Uni_Agencies,
        Uni_Desc19: Uni_Desc19,
        Uni_Link19: Uni_Link19,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getunicriteria5();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc20;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add3) {
        if (!Uni_HEI || !Uni_Number || !Uni_Desc20 || !Uni_Link20) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Uni_Link20);
      let obj = {
        Uni_criteria: "5.1.2",
        Uni_HEI: Uni_HEI,
        Uni_Number: Uni_Number,
        Uni_Desc20: Uni_Desc20,
        Uni_Link20: Uni_Link20,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getunicriteria5();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Uni_Placed1 ||
          !Uni_Graduate ||
          !Uni_employer ||
          !Uni_Package ||
          !Uni_Desc21 ||
          !Uni_Link21
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Uni_Link21);
      let obj = {
        Uni_criteria: "5.2.2",
        Uni_Placed1: Uni_Placed1,
        Uni_Graduate: Uni_Graduate,
        Uni_employer: Uni_employer,
        Uni_Package: Uni_Package,
        Uni_Desc21: Uni_Desc21,
        Uni_Link21: Uni_Link21,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getunicriteria5();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc22;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !Uni_Higher ||
          !Uni_Graduat1 ||
          !Uni_Institute ||
          !Uni_Admit ||
          !Uni_Desc22 ||
          !Uni_Link22
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("pppp,", Uni_Link22);
      let obj = {
        Uni_criteria: "5.2.3",
        Uni_Higher: Uni_Higher,
        Uni_Graduat1: Uni_Graduat1,
        Uni_Institute: Uni_Institute,
        Uni_Admit: Uni_Admit,
        Uni_Desc22: Uni_Desc22,
        Uni_Link22: Uni_Link22,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getunicriteria5();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !Uni_No ||
          !Uni_RollNo ||
          !Uni_StdSelected ||
          !Uni_NET ||
          !Uni_SLET ||
          !Uni_GATE ||
          !Uni_GMAT ||
          !Uni_CAT ||
          !Uni_GRE ||
          !Uni_JAM ||
          !Uni_IELTS ||
          !Uni_TOEFL ||
          !Uni_Civil ||
          !Uni_State ||
          !Uni_OtherExam ||
          !Uni_Total ||
          !Uni_GTotal ||
          !Uni_Desc23 ||
          !Uni_Link23
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("sss,", Uni_Link23);
      let obj = {
        Uni_criteria: "5.2.1",
        Uni_No: Uni_No,
        Uni_RollNo: Uni_RollNo,
        Uni_StdSelected: Uni_StdSelected,
        Uni_NET: Uni_NET,
        Uni_SLET: Uni_SLET,
        Uni_GATE: Uni_GATE,
        Uni_GMAT: Uni_GMAT,
        Uni_CAT: Uni_CAT,
        Uni_GRE: Uni_GRE,
        Uni_JAM: Uni_JAM,

        Uni_IELTS: Uni_IELTS,
        Uni_TOEFL: Uni_TOEFL,
        Uni_Civil: Uni_Civil,
        Uni_State: Uni_State,
        Uni_OtherExam: Uni_OtherExam,
        Uni_Total: Uni_Total,
        Uni_GTotal: Uni_GTotal,
        Uni_Desc23: Uni_Desc23,
        Uni_Link23: Uni_Link23,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getunicriteria5();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc24;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !Uni_Medal ||
          !Uni_Individual ||
          !Uni_Inter ||
          !Uni_Event ||
          !Uni_Student ||
          !Uni_Desc24 ||
          !Uni_Link24
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd7(false);
        }
      }

      console.log("ttt,", Uni_Link24);
      let obj = {
        Uni_criteria: "5.3.1",
        Uni_Medal: Uni_Medal,
        Uni_Individual: Uni_Individual,
        Uni_Inter: Uni_Inter,
        Uni_Event: Uni_Event,
        Uni_Student: Uni_Student,
        Uni_Desc24: Uni_Desc24,
        Uni_Link24: Uni_Link24,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getunicriteria5();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat8 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc25;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add8) {
        if (!Uni_Event1 || !Uni_Event2 || !Uni_Desc25 || !Uni_Link25) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd8(false);
        }
      }

      console.log("uuu,", Uni_Link25);
      let obj = {
        Uni_criteria: "5.3.3",
        Uni_Event1: Uni_Event1,
        Uni_Event2: Uni_Event2,
        Uni_Desc25: Uni_Desc25,
        Uni_Link25: Uni_Link25,
      };
      sessionStorage.setItem("cat8", JSON.stringify(obj));
      alert("Successfully added cat8");
      setedit8(false);
      getunicriteria5();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <h2
        className="text-center mt-5"
        style={{ fontWeight: "700", color: "#E42127" }}
      >
        University Criteria 05 Form
      </h2>
      <h6 className="text-center" style={{ color: "#E42127" }}>
        (please fill all the details)
      </h6>
      <br />

      <div className="container">
        <div
          className="row-fluid border mb-3"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
          <div
            className="row-fluid border"
            style={{
              padding: "30px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <div class=" col-md-6">
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Uni_Year ? (
                <p>{obj1?.Uni_Year}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setUni_Year(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_5 (5.1.1)</h5>

              <div className="row">
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the scheme
                    </label>
                    {obj1?.Uni_Scheme ? (
                      <p>{obj1?.Uni_Scheme}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => setUni_Scheme(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  Number of students benefited by government scheme and amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>
                  {obj1?.Uni_Std ? (
                    <p>{obj1?.Uni_Std}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_Std(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>
                  {obj1?.Uni_Amt ? (
                    <p>{obj1?.Uni_Amt}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_Amt(e.target.value)}
                    />
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  Number of students benefited by the institution's schemes and
                  amount{" "}
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>
                  {obj1?.Uni_Std1 ? (
                    <p>{obj1?.Uni_Std1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_Std1(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>
                  {obj1?.Uni_Amt1 ? (
                    <p>{obj1?.Uni_Amt1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_Amt1(e.target.value)}
                    />
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  Number of students benefited by the non-government agencies
                  (NGOs) and amount{" "}
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>
                  {obj1?.Uni_Std2 ? (
                    <p>{obj1?.Uni_Std2}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_Std2(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>
                  {obj1?.Uni_Amt2 ? (
                    <p>{obj1?.Uni_Amt2}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_Amt2(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the NGO/agency
                  </label>
                  {obj1?.Uni_NGO ? (
                    <p>{obj1?.Uni_NGO}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setUni_NGO(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj1?.Uni_Desc18 ? (
                    <p>{obj1?.Uni_Desc18}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      // cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc18(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link18(e.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add1 ? (
                  <button
                    type="submit"
                    className="btn btn-success  mt-3 "
                    onClick={() => {
                      cat1();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria-[5.1.1]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="row">
                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Name of the scheme
                        </label>
                        <br />
                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="email"
                              id="name"
                              placeholder={Uni_Scheme}
                              onChange={(e) => setUni_Scheme(e.target.value)}
                            />
                          </>
                        ) : (
                          <>{Uni_Scheme}</>
                        )}
                      </div>
                    </div>
                    <h6 className="text-center text-decoration-underline">
                      Number of students benefited by government scheme and
                      amount
                    </h6>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Std}
                            onChange={(e) => setUni_Std(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Std}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Amount
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Amt}
                            onChange={(e) => setUni_Amt(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Amt}</>
                      )}
                    </div>

                    <h6 className="text-center text-decoration-underline">
                      Number of students benefited by the institution's schemes
                      and amount
                    </h6>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Std1}
                            onChange={(e) => setUni_Std1(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Std1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Amount
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Amt1}
                            onChange={(e) => setUni_Amt1(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Amt1}</>
                      )}
                    </div>

                    <h6 className="text-center text-decoration-underline">
                      Number of students benefited by the non-government
                      agencies (NGOs) and amount
                    </h6>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Std2}
                            onChange={(e) => setUni_Std2(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Std2}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Amount
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Amt2}
                            onChange={(e) => setUni_Amt2(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Amt2}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the NGO/agency
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_NGO}
                            onChange={(e) => setUni_NGO(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_NGO}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc18}
                            onChange={(e) => setUni_Desc18(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc18}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="upload1">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="Year"
                            id="upload1"
                            accept="image/*"
                            onChange={(e) => setUni_Link18(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link18?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit1(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat1();
                      handleClose();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_5 (5.1.2)</h5>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counselling offered by
                    the institution during the last five years
                  </label>

                  {obj3?.Uni_HEI ? (
                    <p>{obj3?.Uni_HEI}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_HEI(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students attended / participated
                  </label>
                  {obj3?.Uni_Number ? (
                    <p>{obj3?.Uni_Number}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Number(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj3?.Uni_Desc20 ? (
                    <p>{obj3?.Uni_Desc20}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc20(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload3"
                    id="upload3"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link20(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add3 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat3();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow2}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[5.1.2]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the Activity conducted by the HEI to offer
                        guidance for competitive examinations/ career
                        counselling offered by the institution during the last
                        five years
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_HEI}
                            onChange={(e) => {
                              setUni_HEI(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_HEI}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Number of students attended / participated
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Number}
                            onChange={(e) => {
                              setUni_Number(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Number}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc20}
                            onChange={(e) => setUni_Desc20(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc20}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit3 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload3"
                            id="upload3 "
                            onChange={(e) => setUni_Link20(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link20.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit3(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat3();
                      handleClose2();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_5 (5.1.3)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the capability enhancement program
                  </label>
                  {obj2?.Uni_Capabality ? (
                    <p>{obj2?.Uni_Capabality}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Capabality(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of implementation (DD-MM-YYYY)
                  </label>
                  {obj2?.Uni_Date ? (
                    <p>{obj2?.Uni_Date}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="date"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Date(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students enrolled
                  </label>
                  {obj2?.Uni_Enrolled ? (
                    <p>{obj2?.Uni_Enrolled}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Enrolled(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-8 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </label>
                  {obj2?.Uni_Agencies ? (
                    <p>{obj2?.Uni_Agencies}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Agencies(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj2?.Uni_Desc19 ? (
                  <p>{obj2?.Uni_Desc19}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc19(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload2">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  name="upload2"
                  id="upload2"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link19(e.target.files[0]);
                  }}
                />
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add2 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat2();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow1}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria05_[5.1.3]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the capability enhancement program
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Capabality}
                            onChange={(e) => {
                              setUni_Capabality(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Capabality}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Date of implementation (DD-MM-YYYY)
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="date"
                            name="year"
                            id="name"
                            placeholder={Uni_Date}
                            onChange={(e) => {
                              setUni_Date(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Date}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students enrolled
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Enrolled}
                            onChange={(e) => setUni_Enrolled(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Enrolled}</>
                      )}
                    </div>
                    <div className="col-md-8 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the agencies/consultants involved with contact
                        details (if any)
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Agencies}
                            onChange={(e) => setUni_Agencies(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Agencies}</>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_Desc19}
                          onChange={(e) => setUni_Desc19(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_Desc19}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload2"
                          id="name"
                          onChange={(e) => setUni_Link19(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_Link19?.name}</>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit2(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat2();
                      handleClose1();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "20px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5> Criteria_5 (5.2.1)</h5>
              <div className="row">
                <div className="row">
                  <div className="row">
                    <div className="col-md-4 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Sl.no.
                      </label>
                      {obj6?.Uni_No ? (
                        <p>{obj6?.Uni_No}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_No(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-8 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Registration number/roll number for the exam
                      </label>
                      {obj6?.Uni_RollNo ? (
                        <p>{obj6?.Uni_RollNo}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_RollNo(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-8 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the student /qualified
                      </label>
                      {obj6?.Uni_StdSelected ? (
                        <p>{obj6?.Uni_StdSelected}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_StdSelected(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-4 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        NET
                      </label>
                      {obj6?.Uni_NET ? (
                        <p>{obj6?.Uni_NET}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_NET(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        SLET
                      </label>
                      {obj6?.Uni_SLET ? (
                        <p>{obj6?.Uni_SLET}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_SLET(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        GATE
                      </label>
                      {obj6?.Uni_GATE ? (
                        <p>{obj6?.Uni_GATE}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_GATE(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        GMAT
                      </label>
                      {obj6?.Uni_GMAT ? (
                        <p>{obj6?.Uni_GMAT}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_GMAT(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        CAT
                      </label>
                      {obj6?.Uni_CAT ? (
                        <p>{obj6?.Uni_CAT}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_CAT(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        GRE
                      </label>
                      {obj6?.Uni_GRE ? (
                        <p>{obj6?.Uni_GRE}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_GRE(e.target.value);
                          }}
                        />
                      )}
                    </div>

                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        JAM
                      </label>
                      {obj6?.Uni_JAM ? (
                        <p>{obj6?.Uni_JAM}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_JAM(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        IELTS
                      </label>
                      {obj6?.Uni_IELTS ? (
                        <p>{obj6?.Uni_IELTS}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_IELTS(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        TOEFL
                      </label>
                      {obj6?.Uni_TOEFL ? (
                        <p>{obj6?.Uni_TOEFL}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_TOEFL(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-3 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Civil Services
                      </label>
                      {obj6?.Uni_Civil ? (
                        <p>{obj6?.Uni_Civil}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_Civil(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-9 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        State government examinations
                      </label>
                      {obj6?.Uni_State ? (
                        <p>{obj6?.Uni_State}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_State(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-12 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Other examinations conducted by the State / Central
                        Government Agencies (Specify)
                      </label>
                      {obj6?.Uni_OtherExam ? (
                        <p>{obj6?.Uni_OtherExam}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_OtherExam(e.target.value);
                          }}
                        />
                      )}
                    </div>

                    <div className="col-md-6 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total
                      </label>
                      {obj6?.Uni_Total ? (
                        <p>{obj6?.Uni_Total}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_Total(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-6 mt-3 mb-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Grand Total
                      </label>
                      {obj6?.Uni_GTotal ? (
                        <p>{obj6?.Uni_GTotal}</p>
                      ) : (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_GTotal(e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Description (min 500 words)
                      </label>
                      {obj6?.Uni_Desc23 ? (
                        <p>{obj6?.Uni_Desc23}</p>
                      ) : (
                        <textarea
                          className="vi_0"
                          id="name"
                          cols={57}
                          rows={3}
                          onChange={(e) => {
                            setUni_Desc23(e.target.value);
                          }}
                        ></textarea>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload6">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />

                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload6"
                        accept="image/*"
                        onChange={(e) => {
                          setUni_Link23(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add6 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat6();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow5}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}{" "}
              </div>
              <Modal size="lg" show={show5} onHide={handleClose5}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria05_5.2.1</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="row">
                      <div className="row">
                        <div className="col-md-4 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Sl.no.
                          </label>
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_No}
                                onChange={(e) => {
                                  setUni_No(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_No}</>
                          )}
                        </div>
                        <div className="col-md-8 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Registration number/roll number for the exam
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_RollNo}
                                onChange={(e) => {
                                  setUni_RollNo(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_RollNo}</>
                          )}
                        </div>
                        <div className="col-md-12 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Uni_NameS of students selected/ qualified
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_StdSelected}
                                onChange={(e) => {
                                  setUni_StdSelected(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_StdSelected}</>
                          )}
                        </div>
                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            NET
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_NET}
                                onChange={(e) => {
                                  setUni_NET(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_NET}</>
                          )}
                        </div>
                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            SLET
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_SLET}
                                onChange={(e) => {
                                  setUni_SLET(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_SLET}</>
                          )}
                        </div>
                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            GATE
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_GATE}
                                onChange={(e) => {
                                  setUni_GATE(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_GATE}</>
                          )}
                        </div>
                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            GMAT
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_GMAT}
                                onChange={(e) => {
                                  setUni_GMAT(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_GMAT}</>
                          )}
                        </div>
                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            CAT
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_CAT}
                                onChange={(e) => {
                                  setUni_CAT(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_CAT}</>
                          )}
                        </div>
                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            GRE
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_GRE}
                                onChange={(e) => {
                                  setUni_GRE(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_GRE}</>
                          )}
                        </div>

                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            JAM
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_JAM}
                                onChange={(e) => {
                                  setUni_JAM(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_JAM}</>
                          )}
                        </div>
                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            IELTS
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_IELTS}
                                onChange={(e) => {
                                  setUni_IELTS(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_IELTS}</>
                          )}
                        </div>
                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            TOEFL
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_TOEFL}
                                onChange={(e) => {
                                  setUni_TOEFL(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_TOEFL}</>
                          )}
                        </div>
                        <div className="col-md-3 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Civil Services
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_Civil}
                                onChange={(e) => {
                                  setUni_Civil(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_Civil}</>
                          )}
                        </div>
                        <div className="col-md-6 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            State government examinations
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_State}
                                onChange={(e) => {
                                  setUni_State(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_State}</>
                          )}
                        </div>
                        <div className="col-md-12 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Other examinations conducted by the State / Central
                            Government Agencies (Specify)
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_OtherExam}
                                onChange={(e) => {
                                  setUni_OtherExam(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_OtherExam}</>
                          )}
                        </div>
                        <div className="col-md-6 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Total
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_Total}
                                onChange={(e) => {
                                  setUni_Total(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_Total}</>
                          )}
                        </div>
                        <div className="col-md-6 mt-3 mb-3">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Grand Total
                          </label>{" "}
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="fname"
                                id="name"
                                placeholder={Uni_GTotal}
                                onChange={(e) => {
                                  setUni_GTotal(e.target.value);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_GTotal}</>
                          )}
                        </div>
                        <div className="col-md-6 mt-3 ">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Description(min 500 words)
                          </label>
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="text"
                                name="email"
                                id="name"
                                placeholder={Uni_Desc23}
                                onChange={(e) => setUni_Desc23(e.target.value)}
                              />
                            </>
                          ) : (
                            <>{Uni_Desc23}</>
                          )}
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="content1" htmlFor="upload6">
                            {" "}
                            Upload the relevant document
                          </label>
                          <br />
                          {edit6 ? (
                            <>
                              <input
                                className="vi_0"
                                type="file"
                                name="email"
                                id="upload6"
                                accept="image/*"
                                onChange={(e) => {
                                  setUni_Link23(e.target.files[0]);
                                }}
                              />
                            </>
                          ) : (
                            <>{Uni_Link23?.name}</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit6(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat6();
                      handleClose5();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_5 (5.2.2)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student placed
                  </label>
                  {obj4?.Uni_Placed1 ? (
                    <p>{obj4?.Uni_Placed1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_Placed1(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>
                  {obj4?.Uni_Graduate ? (
                    <p>{obj4?.Uni_Graduate}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_Graduate(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the employer
                  </label>
                  {obj4?.Uni_employer ? (
                    <p>{obj4?.Uni_employer}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_employer(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Pay package at appointment
                  </label>
                  {obj4?.Uni_Package ? (
                    <p>{obj4?.Uni_Package}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_Package(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj4?.Uni_Desc21 ? (
                  <p>{obj4?.Uni_Desc21}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc21(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload4">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload4"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link21(e.target.files[0]);
                  }}
                />
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add4 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat4();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow3}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[5.2.2]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of student placed
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Placed1}
                            onChange={(e) => {
                              setUni_Placed1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Placed1}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Program graduated from
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Graduate}
                            onChange={(e) => {
                              setUni_Graduate(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Graduate}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the employer
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_employer}
                            onChange={(e) => {
                              setUni_employer(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_employer}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Pay package at appointment
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Package}
                            onChange={(e) => {
                              setUni_Package(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Package}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <textarea
                            rows={3}
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc21}
                            onChange={(e) => setUni_Desc21(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc21}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload4">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="email"
                            id="upload4"
                            onChange={(e) => setUni_Link21(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link21.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit4(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat4();
                      handleClose3();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_5 (5.2.3)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student enrolling into higher education
                  </label>
                  {obj5?.Uni_Higher ? (
                    <p>{obj5?.Uni_Higher}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_Higher(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>
                  {obj5?.Uni_Graduat1 ? (
                    <p>{obj5?.Uni_Graduat1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_Graduat1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of institution joined
                  </label>
                  {obj5?.Uni_Institute ? (
                    <p>{obj5?.Uni_Institute}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_Institute(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of programme admitted to
                  </label>
                  {obj5?.Uni_Admit ? (
                    <p>{obj5?.Uni_Admit}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="Year"
                      id="name"
                      onChange={(e) => {
                        setUni_Admit(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj5?.Uni_Desc22 ? (
                  <p>{obj5?.Uni_Desc22}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc22(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload5">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload5"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link22(e.target.files[0]);
                  }}
                />
              </div>

              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add5 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat5();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow4}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show4} onHide={handleClose4}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[5.2.3]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of student enrolling into higher education
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Higher}
                            onChange={(e) => {
                              setUni_Higher(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Higher}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Program graduated from
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Graduat1}
                            onChange={(e) => {
                              setUni_Graduat1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Graduat1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of institution joined
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Institute}
                            onChange={(e) => {
                              setUni_Institute(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Institute}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of programme admitted to
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Admit}
                            onChange={(e) => {
                              setUni_Admit(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Admit}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <textarea
                            rows={3}
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc22}
                            onChange={(e) => setUni_Desc22(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc22}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload5">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="email"
                            id="upload5"
                            onChange={(e) => setUni_Link22(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link22?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit5(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat5();
                      handleClose4();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_5 (5.3.1)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/ medal
                  </label>

                  {obj7?.Uni_Medal ? (
                    <p>{obj7?.Uni_Medal}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Medal(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Team / Individual
                  </label>
                  {obj7?.Uni_Individual ? (
                    <p>{obj7?.Uni_Individual}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Individual(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    inter-university / state / National/ International
                  </label>
                  {obj7?.Uni_Inter ? (
                    <p>{obj7?.Uni_Inter}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Inter(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1"> Name of the event</label>
                  {obj7?.Uni_Event ? (
                    <p>{obj7?.Uni_Event}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Event(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the student
                  </label>
                  {obj7?.Uni_Student ? (
                    <p>{obj7?.Uni_Student}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Student(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj7?.Uni_Desc24 ? (
                    <p>{obj7?.Uni_Desc24}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc24(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload8">
                    {" "}
                    Link to website of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload8"
                    id="upload8"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link24(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add7 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat7();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={handleShow6}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show6} onHide={handleClose6}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[5.3.1]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the award/ medal
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Uni_Medal}
                            onChange={(e) => {
                              setUni_Medal(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Medal}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Team / Individual
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Individual}
                            onChange={(e) => {
                              setUni_Individual(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Individual}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        inter-university / state / National/ International
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Inter}
                            onChange={(e) => {
                              setUni_Inter(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Inter}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the event
                      </label>{" "}
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Event}
                            onChange={(e) => {
                              setUni_Event(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Event}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the student
                      </label>{" "}
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Student}
                            onChange={(e) => {
                              setUni_Student(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Student}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc24}
                            onChange={(e) => setUni_Desc24(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc24}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Link to website of the Journal
                      </label>
                      <br />
                      {edit7 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload7"
                            id="upload7"
                            onChange={(e) => setUni_Link24(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link24?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit7(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat7();
                      handleClose6();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_5 (5.3.3)</h5>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of event/competition (DD-MM-YYYY)
                  </label>

                  {obj8?.Uni_Event1 ? (
                    <p>{obj8?.Uni_Event1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="date"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Event1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the event/competition
                  </label>
                  {obj8?.Uni_Event2 ? (
                    <p>{obj8?.Uni_Event2}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setUni_Event2(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description min 500 words
                  </label>
                  {obj8?.Uni_Desc25 ? (
                    <p>{obj8?.Uni_Desc25}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setUni_Desc25(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload9">
                    {" "}
                    Upload the relevant document of the Journal
                  </label>
                  <br />

                  <input
                    className="vi_0"
                    type="file"
                    name="upload9"
                    id="upload9"
                    accept="image/*"
                    onChange={(e) => {
                      setUni_Link25(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex " style={{ float: "right", gap: "10px" }}>
                {add8 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat8();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow7}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
              <Modal size="lg" show={show7} onHide={handleClose7}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Criteria_[5.3.3]</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Date of event/competition (DD-MM-YYYY)
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="date"
                            name="year"
                            id="name"
                            placeholder={Uni_Event1}
                            onChange={(e) => {
                              setUni_Event1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Event1}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the event/competition
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="fname"
                            id="name"
                            placeholder={Uni_Event2}
                            onChange={(e) => {
                              setUni_Event2(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_Event2}</>
                      )}
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Uni_Desc25}
                            onChange={(e) => setUni_Desc25(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Desc25}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document of the Journal
                      </label>
                      <br />
                      {edit8 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload8"
                            id="upload8"
                            onChange={(e) => setUni_Link25(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_Link25?.name}</>
                      )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit8(true)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ color: "white" }}
                    onClick={() => {
                      cat8();
                      handleClose7();
                    }}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>

              <br />
            </div>
          </div>

          <div className="text-center mt-3 mb-3">
            <button
              type="submit"
              className="btn btn-success "
              onClick={() => unicriteria5()}
              style={{ color: "white", marginTop: "15px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uni_Criteria005;
