import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, ModalTitle } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

import exportFromJSON from "export-from-json";
const PG_Criteria05 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);
  const [edit8, setedit8] = useState(false);

  const [PG_NameS, setPG_NameS] = useState("");
  const [PG_SName, setPG_SName] = useState("");
  const [PG_Amount, setPG_Amount] = useState("");
  const [PG_SName1, setPG_SName1] = useState("");
  const [PG_Amount1, setPG_Amount1] = useState("");
  const [PG_Desc21, setPG_Desc21] = useState("");
  const [PG_Link21, setPG_Link21] = useState("");

  const [PG_NamCap, setPG_NamCap] = useState("");
  const [PG_IDate, setPG_IDate] = useState("");
  const [PG_StdEnr, setPG_StdEnr] = useState("");
  const [PG_AgeName, setPG_AgeName] = useState("");
  const [PG_Desc22, setPG_Desc22] = useState("");
  const [PG_Link22, setPG_Link22] = useState("");

  const [PG_CampName, setPG_CampName] = useState("");
  const [PG_Nameact, setPG_Nameact] = useState("");
  const [PG_NamePart, setPG_NamePart] = useState("");
  const [PG_Desc23, setPG_Desc23] = useState("");
  const [PG_Link23, setPG_Link23] = useState("");

  const [PG_PlaName, setPG_PlaName] = useState("");
  const [PG_Graduate, setPG_Graduate] = useState("");
  const [PG_ConDetails, setPG_ConDetails] = useState("");
  const [PG_Pay, setPG_Pay] = useState("");
  const [PG_Desc24, setPG_Desc24] = useState("");
  const [PG_Link24, setPG_Link24] = useState("");

  const [PG_HighName, setPG_HighName] = useState("");
  const [PG_PGrad, setPG_PGrad] = useState("");
  const [PG_Institute, setPG_Institute] = useState("");
  const [PG_Admitted, setPG_Admitted] = useState("");
  const [PG_Desc25, setPG_Desc25] = useState("");
  const [PG_Link25, setPG_Link25] = useState("");

  const [PG_No1, setPG_No1] = useState("");
  const [PG_RollNo1, setPG_RollNo1] = useState("");
  const [PG_StdSelected1, setPG_StdSelected1] = useState("");
  const [PG_NET1, setPG_NET1] = useState("");
  const [PG_SLET1, setPG_SLET1] = useState("");
  const [PG_GATE1, setPG_GATE1] = useState("");
  const [PG_GMAT1, setPG_GMAT1] = useState("");
  const [PG_CAT1, setPG_CAT1] = useState("");
  const [PG_GRE1, setPG_GRE1] = useState("");
  const [PG_JAM1, setPG_JAM1] = useState("");
  const [PG_IELTS1, setPG_IELTS1] = useState("");
  const [PG_TOEFL1, setPG_TOEFL1] = useState("");
  const [PG_Civil1, setPG_Civil1] = useState("");
  const [PG_SGExam1, setPG_SGExam1] = useState("");
  const [PG_OtherExam1, setPG_OtherExam1] = useState("");
  const [PG_Total1, setPG_Total1] = useState("");
  const [PG_GTotal1, setPG_GTotal1] = useState("");
  const [PG_Desc26, setPG_Desc26] = useState("");
  const [PG_Link26, setPG_Link26] = useState("");

  const [PG_Meda, setPG_Meda] = useState("");
  const [PG_TIndi, setPG_TIndi] = useState("");
  const [PG_USNI1, setPG_USNI1] = useState("");
  const [PG_SCult, setPG_SCult] = useState("");
  const [PG_NaStd, setPG_NaStd] = useState("");
  const [PG_Desc27, setPG_Desc27] = useState("");
  const [PG_Link27, setPG_Link27] = useState("");

  const [PG_NStdParti, setPG_NStdParti] = useState("");
  const [PG_EDate, setPG_EDate] = useState("");
  const [PG_EName, setPG_EName] = useState("");
  const [PG_Desc28, setPG_Desc28] = useState("");
  const [PG_Link28, setPG_Link28] = useState("");

  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);
  const [PG_Criteria05, setPG_Criteria05] = useState([]);
  const [Value, setValue] = useState({});

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("PG_Cri5.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
          <table>
            <tr>
              <td>Number of students</td>
              <td>${item?.PG_Criteria05_511?.PG_SName}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>${item?.PG_Criteria05_511?.PG_Amount}</td>
            </tr>
          </table>
        `;
        let tableMarkup2 = `
          <table>
            <tr>
              <td>Number of students</td>
              <td>${item?.PG_Criteria05_511?.PG_SName1}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>${item?.PG_Criteria05_511?.PG_Amount1}</td>
            </tr>
          </table>
        `;
        let tableMarkup3 = `
        <table>
          <tr>
            <td>Name of the Activity</td>
            <td>${item?.PG_Criteria05_514?.PG_Nameact}</td>
          </tr>
          <tr>
            <td>Number of students attended / participated</td>
            <td>${item?.PG_Criteria05_514?.PG_NamePart}</td>
          </tr>
        </table>
      `;
        let obj = {
          //  Criteria 5_511

          PG_Year: item?.PG_Year,

          "Criteria_(5.1.1)": item?.PG_Criteria05_511?.PG_criteria,
          "Name of the scheme": item?.PG_Criteria05_511?.PG_NameS,
          "Number of students benefited by government scheme and amount":
            tableMarkup1,
          "Number of students benefited by the institution's schemes and amount":
            tableMarkup2,
          "Description(min 500 words)": item?.PG_Criteria05_511?.PG_Desc21,
          "Upload the relevant document": item?.PG_Criteria05_511?.PG_Link21,

          // Criteria 5_513

          "Criteria_5.1.3": item?.PG_Criteria05_513?.PG_criteria,
          "Name of the capacity development and skills enhancement program":
            item?.PG_Criteria05_513?.PG_NamCap,
          "Date of implementation": moment(
            item?.PG_Criteria05_513?.PG_IDate
          ).format("DD-MM-YYYY"),
          "Number of students enrolled": item?.PG_Criteria05_513?.PG_StdEnr,
          "Name of the agencies/consultants involved with contact details (if any)":
            item?.PG_Criteria05_513?.PG_AgeName,
          "Description(min 500 words)": item?.PG_Criteria05_513?.PG_Desc22,
          "Upload the relevant document": item?.PG_Criteria05_513?.PG_Link22,

          // Criteria 5_514
          "Criteria_5.1.4": item?.PG_Criteria05_514?.PG_criteria,
          "Number of students placed through campus placement":
            item?.PG_Criteria05_514?.PG_CampName,
          "Name of the Activity conducted by the HEI to offer guidance for competitive examinations/ career counseling offered by the institution during the last five years":
            tableMarkup3,
          "Description (min 500 words)(5.1.4)":
            item?.PG_Criteria05_514?.PG_Desc23,
          "Upload the relevant document(5.1.4)":
            item?.PG_Criteria05_514?.PG_Link23,

          // Criteria 5_521
          "Criteria_5.2.1": item?.PG_Criteria05_521?.PG_criteria,
          "Name of student placed and contact details":
            item?.PG_Criteria05_521?.PG_PlaName,
          "Program graduated from": item?.PG_Criteria05_521?.PG_Graduate,
          "Name of the employer with contact details":
            item?.PG_Criteria05_521?.PG_ConDetails,
          "Pay package at appointment (In INR per annum)":
            item?.PG_Criteria05_521?.PG_Pay,
          "Description (min 500 words)(5.2.1)":
            item?.PG_Criteria05_521?.PG_Desc24,
          "Upload the relevant document(5.2.1)":
            item?.PG_Criteria05_521?.PG_Link24,

          // Criteria 5_522
          "Criteria_5.2.2": item?.PG_Criteria05_522?.PG_criteria,
          "Name of student enrolling into higher education":
            item?.PG_Criteria05_522?.PG_HighName,
          "Program Graduated from": item?.PG_Criteria05_522?.PG_PGrad,
          "Name of institution joined": item?.PG_Criteria05_522?.PG_Institute,
          "Name of programme admitted to": item?.PG_Criteria05_522?.PG_Admitted,
          "Description (min 500 words)(5.2.2)":
            item?.PG_Criteria05_522?.PG_Desc25,
          "Upload the relevant document(5.2.2)":
            item?.PG_Criteria05_522?.PG_Link25,

          // Criteria 5_523
          "Criteria_5.2.3": item?.PG_Criteria05_523?.PG_criteria,
          "Sl.no.": item?.PG_Criteria05_523?.PG_No1,
          "Registration number/roll number for the exam":
            item?.PG_Criteria05_523?.PG_RollNo1,
          "Names of students selected/ qualified":
            item?.PG_Criteria05_523?.PG_StdSelected1,
          NET: item?.PG_Criteria05_523?.PG_NET1,
          SLET: item?.PG_Criteria05_523?.PG_SLET1,
          GATE: item?.PG_Criteria05_523?.PG_GATE1,
          GMAT: item?.PG_Criteria05_523?.PG_GMAT1,
          CAT: item?.PG_Criteria05_523?.PG_CAT1,
          GRE: item?.PG_Criteria05_523?.PG_GRE1,
          JAM: item?.PG_Criteria05_523?.PG_JAM1,
          IELTS: item?.PG_Criteria05_523?.PG_IELTS1,
          TOEFL: item?.PG_Criteria05_523?.PG_TOEFL1,
          "Civil Services": item?.PG_Criteria05_523?.PG_Civil1,
          "State government examinations": item?.PG_Criteria05_523?.PG_SGExam1,
          "Other examinations conducted by the State / Central Government Agencies (Specify)":
            item?.PG_Criteria05_523?.PG_OtherExam1,
          Total: item?.PG_Criteria05_523?.PG_Total1,
          "Grand Total": item?.PG_Criteria05_523?.PG_GTotal1,
          "Description (min 500 words)(5.2.3)":
            item?.PG_Criteria05_523?.PG_Desc26,
          "Upload the relevant document(5.2.3)":
            item?.PG_Criteria05_523?.PG_Link26,

          // Criteria 5_531
          "Criteria_5.3.1": item?.PG_Criteria05_531?.PG_criteria,
          "Name of the award/ medal": item?.PG_Criteria05_531?.PG_Meda,
          "Team / Individual": item?.PG_Criteria05_531?.PG_TIndi,
          "University/State/National/ International1":
            item?.PG_Criteria05_531?.PG_USNI1,
          "Sports/ Cultural": item?.PG_Criteria05_531?.PG_SCult,
          "Name of the student": item?.PG_Criteria05_531?.PG_NaStd,
          "Description (min 500 words)(5.3.1)":
            item?.PG_Criteria05_531?.PG_Desc27,
          "Upload the relevant document(5.3.1)":
            item?.PG_Criteria05_531?.PG_Link27,

          // Criteria 5_533
          "Criteria_5.3.3": item?.PG_Criteria05_533?.PG_criteria,
          "Name of the student participated":
            item?.PG_Criteria05_533?.PG_NStdParti,
          "Date of event/activity (DD-MM-YYYY)": moment(
            item?.PG_Criteria05_533?.PG_EDate
          ).format("DD-MM-YYYY"),
          "Name of the event/activity": item?.PG_Criteria05_533?.PG_EName,
          "Description (min 500 words)(5.3.3)":
            item?.PG_Criteria05_533?.PG_Desc28,
          "Upload the relevant document(5.3.3)":
            item?.PG_Criteria05_533?.PG_Link28,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editPG_Criteria05_511 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc21) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editPG_Criteria05_511/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_NameS: PG_NameS,
          PG_SName: PG_SName,
          PG_Amount: PG_Amount,
          PG_SName1: PG_SName1,
          PG_Amount1: PG_Amount1,
          PG_Desc21: PG_Desc21,
          PG_Link21: PG_Link21,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri05details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria05_513 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc22;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc22) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      handleClose();
      const config = {
        url: `/FacultyAdmin/editPG_Criteria05_513/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_NamCap: PG_NamCap,
          PG_IDate: PG_IDate,
          PG_StdEnr: PG_StdEnr,
          PG_AgeName: PG_AgeName,
          PG_Desc22: PG_Desc22,
          PG_Link22: PG_Link22,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri05details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria05_514 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (PG_Desc23) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria05_514/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_CampName: PG_CampName,
          PG_Nameact: PG_Nameact,
          PG_NamePart: PG_NamePart,
          PG_Desc23: PG_Desc23,
          PG_Link23: PG_Link23,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri05details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria05_521 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc24;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc24) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria05_521/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_PlaName: PG_PlaName,
          PG_Graduate: PG_Graduate,
          PG_ConDetails: PG_ConDetails,
          PG_Pay: PG_Pay,
          PG_Desc24: PG_Desc24,
          PG_Link24: PG_Link24,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri05details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editPG_Criteria05_522 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc25;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc25) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria05_522/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_HighName: PG_HighName,
          PG_PGrad: PG_PGrad,
          PG_Institute: PG_Institute,
          PG_Admitted: PG_Admitted,
          PG_Desc25: PG_Desc25,
          PG_Link25: PG_Link25,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri05details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria05_523 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc26;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc26) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria05_523/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_No1: PG_No1,
          PG_RollNo1: PG_RollNo1,
          PG_StdSelected1: PG_StdSelected1,
          PG_NET1: PG_NET1,
          PG_SLET1: PG_SLET1,
          PG_GATE1: PG_GATE1,
          PG_GMAT1: PG_GMAT1,
          PG_CAT1: PG_CAT1,
          PG_GR1E: PG_GRE1,
          PG_JAM1: PG_JAM1,

          PG_IELTS1: PG_IELTS1,
          PG_TOEFL1: PG_TOEFL1,
          PG_Civil1: PG_Civil1,
          PG_SGExam1: PG_SGExam1,
          PG_OtherExam1: PG_OtherExam1,
          PG_Total1: PG_Total1,
          PG_GTotal1: PG_GTotal1,
          PG_Desc26: PG_Desc26,
          PG_Link26: PG_Link26,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri05details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria05_531 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc27;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc27) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria05_531/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Meda: PG_Meda,
          PG_TIndi: PG_TIndi,
          PG_USNI1: PG_USNI1,
          PG_SCult: PG_SCult,
          PG_NaStd: PG_NaStd,
          PG_Desc27: PG_Desc27,
          PG_Link27: PG_Link27,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri05details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria05_533 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc28;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc28) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria05_533/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_NStdParti: PG_NStdParti,
          PG_EDate: PG_EDate,
          PG_EName: PG_EName,
          PG_Desc28: PG_Desc28,
          PG_Link28: PG_Link28,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri05details();
          setedit8(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getPG_cri05details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getPG_cri05details"
      );
      if (res.status === 200) {
        setPG_Criteria05(res.data.pgcriteria05);
        setdata1(res.data.pgcriteria05);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPG_cri05details();
  }, []);
  console.log(Value);

  // =================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  const pgCriteria05Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria5"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria05);
    }
  };

  // ================================================
  console.log("PG_Criteria05: ", PG_Criteria05);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-05
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/pgcriteria005">
                <button className="btn btn-success">
                  Add Criteria 5{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Name of the scheme</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the capacity development and skills enhancement
                    program
                  </th>
                  <th>Date of implementation</th>
                  <th>Number of students enrolled</th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Number of students placed through campus placement</th>
                  <th>Name of the Activity</th>
                  <th>Number of students attended / participated</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student placed and contact details</th>
                  <th>Program graduated from</th>
                  <th>Name of the employer with contact details</th>
                  <th>Pay package at appointment (In INR per annum)</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student enrolling into higher education</th>
                  <th>Program graduated from</th>
                  <th>Name of institution joined</th>
                  <th>Name of program admitted to</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Registration number/roll number for the exam</th>
                  <th>Names of students selected/ qualified</th>
                  <th>NET</th>
                  <th>SLET</th>
                  <th>GATE</th>
                  <th>GMAT</th>
                  <th>CAT</th>
                  <th>GRE</th>
                  <th>JAM</th>
                  <th>IELTS</th>
                  <th>TOEFL</th>
                  <th>Civil Services</th>
                  <th>State government examinations</th>
                  <th>
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </th>
                  <th>Total</th>
                  <th>Grand Total</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>University/State/National/ International</th>
                  <th>Sports/ Cultural</th>
                  <th>Name of the student</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the student participated</th>
                  <th>Date of event/activity (DD-MM-YYYY)</th>
                  <th>Name of the event/activity</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {PG_Criteria05?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.PG_Criteria05_511?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_NameS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_511?.PG_SName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_Amount?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_SName1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_Amount1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_511?.PG_Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_513?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_513?.PG_NamCap?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.PG_Criteria05_513?.PG_IDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria05_513?.PG_StdEnr?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_513?.PG_AgeName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_513?.PG_Desc22?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_514?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_514?.PG_CampName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_514?.PG_Nameact?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_514?.PG_NamePart?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_514?.PG_Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_521?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_521?.PG_PlaName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_521?.PG_Graduate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_521?.PG_ConDetails?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_521?.PG_Pay?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_521?.PG_Desc24?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_522?.PG_HighName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_PGrad?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_Institute?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_Admitted?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_522?.PG_Desc25?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_523?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_No1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_RollNo1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_StdSelected1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_523?.PG_NET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_SLET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_GATE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_GMAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_CAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_GRE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_JAM1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_IELTS1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_TOEFL1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_Civil1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_SGExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_OtherExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_GTotal1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_523?.PG_Desc26?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_531?.PG_Meda?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_TIndi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_USNI1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_SCult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_NaStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_531?.PG_Desc27?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_533?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria05_533?.PG_NStdParti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.PG_Criteria05_533?.PG_EDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria05_533?.PG_EName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria05_533?.PG_Desc28?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.PG_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.PG_Criteria05_511?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_511?.PG_NameS}</td>
                      <td>{item?.PG_Criteria05_511?.PG_SName}</td>
                      <td>{item?.PG_Criteria05_511?.PG_Amount}</td>
                      <td>{item?.PG_Criteria05_511?.PG_SName1}</td>
                      <td>{item?.PG_Criteria05_511?.PG_Amount1}</td>
                      <td>
                        {item?.PG_Criteria05_511?.PG_Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_513?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_513?.PG_NamCap}</td>
                      <td>
                        {moment(item?.PG_Criteria05_513?.PG_IDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria05_513?.PG_StdEnr}</td>
                      <td>{item?.PG_Criteria05_513?.PG_AgeName}</td>
                      <td>
                        {item?.PG_Criteria05_513?.PG_Desc22?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_514?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_514?.PG_CampName}</td>
                      <td>{item?.PG_Criteria05_514?.PG_Nameact}</td>
                      <td>{item?.PG_Criteria05_514?.PG_NamePart}</td>
                      <td>
                        {item?.PG_Criteria05_514?.PG_Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_521?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_521?.PG_PlaName}</td>
                      <td>{item?.PG_Criteria05_521?.PG_Graduate}</td>
                      <td>{item?.PG_Criteria05_521?.PG_ConDetails}</td>
                      <td>{item?.PG_Criteria05_521?.PG_Pay}</td>
                      <td>
                        {item?.PG_Criteria05_521?.PG_Desc24?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_522?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_522?.PG_HighName}</td>
                      <td>{item?.PG_Criteria05_522?.PG_PGrad}</td>
                      <td>{item?.PG_Criteria05_522?.PG_Institute}</td>
                      <td>{item?.PG_Criteria05_522?.PG_Admitted}</td>
                      <td>
                        {item?.PG_Criteria05_522?.PG_Desc25?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_523?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_523?.PG_No1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_RollNo1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_StdSelected1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_NET1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_SLET1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_GATE1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_GMAT1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_CAT1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_GRE1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_JAM1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_IELTS1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_TOEFL1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_Civil1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_SGExam1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_OtherExam1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_Total1}</td>
                      <td>{item?.PG_Criteria05_523?.PG_GTotal1}</td>
                      <td>
                        {item?.PG_Criteria05_523?.PG_Desc26?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_531?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_531?.PG_Meda}</td>
                      <td>{item?.PG_Criteria05_531?.PG_TIndi}</td>
                      <td>{item?.PG_Criteria05_531?.PG_USNI1}</td>
                      <td>{item?.PG_Criteria05_531?.PG_SCult}</td>
                      <td>{item?.PG_Criteria05_531?.PG_NaStd}</td>
                      <td>
                        {item?.PG_Criteria05_531?.PG_Desc27?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria05_533?.PG_criteria}</td>
                      <td>{item?.PG_Criteria05_533?.PG_NStdParti}</td>
                      <td>
                        {moment(item?.PG_Criteria05_533?.PG_EDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria05_533?.PG_EName}</td>
                      <td>
                        {item?.PG_Criteria05_533?.PG_Desc28?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={() => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            setedit6(false);
            setedit7(false);
            setedit8(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                PostGraduate_Criteria-05
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="row">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the scheme
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.PG_Criteria05_511?.PG_NameS}
                          onChange={(e) => {
                            setPG_NameS(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_511?.PG_NameS}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  (Number of students benefited by government scheme and amount){" "}
                </h6>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.PG_Criteria05_511?.PG_SName}
                          onChange={(e) => {
                            setPG_SName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_511?.PG_SName}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Amount
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.PG_Criteria05_511?.PG_Amount}
                          onChange={(e) => {
                            setPG_Amount(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_511?.PG_Amount}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  (Number of students benefited by the institution's schemes and
                  amount){" "}
                </h6>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.PG_Criteria05_511?.PG_SName1}
                          onChange={(e) => {
                            setPG_SName1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_511?.PG_SName1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Amount
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.PG_Criteria05_511?.PG_Amount1}
                          onChange={(e) => {
                            setPG_Amount1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_511?.PG_Amount1}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_511?.PG_Desc21}
                        onChange={(e) => setPG_Desc21(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_511?.PG_Desc21}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria05_511?.PG_Link21}
                        onChange={(e) => setPG_Link21(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_511?.PG_Link21}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit1 ? (
                        <>
                          {" "}
                          <Button
                            className="btn btn-success"
                            style={{ color: "black" }}
                            onClick={(e) => {
                              editPG_Criteria05_511(e, Value?._id);
                              // handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the capacity development and skills enhancement
                      program
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.PG_Criteria05_513?.PG_NamCap}
                          onChange={(e) => {
                            setPG_NamCap(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_513?.PG_NamCap}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Date of implementation
                    </label>{" "}
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="date"
                          name="fname"
                          id="name"
                          placeholder={Value?.PG_Criteria05_513?.PG_IDate}
                          onChange={(e) => {
                            setPG_IDate(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_513?.PG_IDate}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students enrolled
                    </label>{" "}
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.PG_Criteria05_513?.PG_StdEnr}
                          onChange={(e) => {
                            setPG_StdEnr(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_513?.PG_StdEnr}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the agencies/consultants involved with contact
                      details (if any)
                    </label>{" "}
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.PG_Criteria05_513?.PG_AgeName}
                          onChange={(e) => {
                            setPG_AgeName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_513?.PG_AgeName}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit2 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.PG_Criteria05_513?.PG_Desc22}
                          onChange={(e) => setPG_Desc22(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_513?.PG_Desc22}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload2"
                          id="name"
                          accept="image/*"
                          placeholder={Value?.PG_Criteria05_512?.PG_Link22}
                          onChange={(e) => setPG_Link22(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.PG_Criteria05_513?.PG_Link22}</>
                    )}
                  </div>
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit2 ? (
                        <>
                          <Button
                            className="btn btn-success"
                            onClick={(e) => {
                              editPG_Criteria05_513(e, Value?._id);
                              // handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.4
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students placed through campus placement
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_514?.PG_CampName}
                        onChange={(e) => {
                          setPG_CampName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_514?.PG_CampName}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  (Name of the Activity conducted by the HEI to offer guidance
                  for competitive examinations/ career counseling offered by the
                  institution during the last five years)
                </h6>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the Activity
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_514?.PG_Nameact}
                        onChange={(e) => {
                          setPG_Nameact(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_514?.PG_Nameact}</>
                  )}
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students attended / participated
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_514?.PG_NamePart}
                        onChange={(e) => {
                          setPG_NamePart(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_514?.PG_NamePart}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_514?.PG_Desc23}
                        onChange={(e) => setPG_Desc23(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_514?.PG_Desc23}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3 "
                        placeholder={Value?.PG_Criteria05_514?.PG_Link23}
                        onChange={(e) => setPG_Link23(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_514?.PG_Link23}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit3 ? (
                        <>
                          <Button
                            className="btn btn-success"
                            onClick={(e) => {
                              editPG_Criteria05_514(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student placed and contact details
                  </label>{" "}
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_521?.PG_PlaName}
                        onChange={(e) => {
                          setPG_PlaName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_521?.PG_PlaName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the employer with contact details
                  </label>{" "}
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_521?.PG_Graduate}
                        onChange={(e) => {
                          setPG_Graduate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_521?.PG_Graduate}</>
                  )}
                </div>
                <div className="col-md-5 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>{" "}
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_521?.PG_ConDetails}
                        onChange={(e) => {
                          setPG_ConDetails(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_521?.PG_ConDetails}</>
                  )}
                </div>
                <div className="col-md-7 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Pay package at appointment (In INR per annum)
                  </label>{" "}
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_521?.PG_Pay}
                        onChange={(e) => {
                          setPG_Pay(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_521?.PG_Pay}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_521?.PG_Desc24}
                        onChange={(e) => setPG_Desc24(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_521?.PG_Desc24}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        placeholder={Value?.PG_Criteria05_521?.PG_Link24}
                        onChange={(e) => setPG_Link24(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_521?.PG_Link24}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit4 ? (
                        <>
                          <Button
                            className="btn btn-success"
                            onClick={(e) => {
                              editPG_Criteria05_521(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student enrolling into higher education
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_522?.PG_HighName}
                        onChange={(e) => {
                          setPG_HighName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_522?.PG_HighName}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>{" "}
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_522?.PG_PGrad}
                        onChange={(e) => {
                          setPG_PGrad(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_522?.PG_PGrad}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of institution joined
                  </label>{" "}
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_522?.PG_Institute}
                        onChange={(e) => {
                          setPG_Institute(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_522?.PG_Institute}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of program admitted to
                  </label>{" "}
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_522?.PG_Admitted}
                        onChange={(e) => {
                          setPG_Admitted(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_522?.PG_Admitted}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_522?.PG_Desc25}
                        onChange={(e) => setPG_Desc25(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_522?.PG_Desc25}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload5"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria05_522?.PG_Link25}
                        onChange={(e) => {
                          setPG_Link25(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_522?.PG_Link25}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit5 ? (
                        <>
                          <Button
                            className="btn btn-success"
                            style={{ color: "black" }}
                            onClick={(e) => {
                              editPG_Criteria05_522(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Sl.no.
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_No1}
                        onChange={(e) => {
                          setPG_No1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_No1}</>
                  )}
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Registration number/roll number for the exam
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_RollNo1}
                        onChange={(e) => {
                          setPG_RollNo1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_RollNo1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of students selected/ qualified
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_StdSelected1}
                        onChange={(e) => {
                          setPG_StdSelected1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_StdSelected1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    NET
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_NET1}
                        onChange={(e) => {
                          setPG_NET1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_NET1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    SLET
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_SLET1}
                        onChange={(e) => {
                          setPG_SLET1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_SLET1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GATE
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_GATE1}
                        onChange={(e) => {
                          setPG_GATE1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_GATE1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GMAT
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_GMAT1}
                        onChange={(e) => {
                          setPG_GMAT1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_GMAT1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    CAT
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_CAT1}
                        onChange={(e) => {
                          setPG_CAT1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_CAT1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GRE
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_GRE1}
                        onChange={(e) => {
                          setPG_GRE1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_GRE1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    JAM
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_JAM1}
                        onChange={(e) => {
                          setPG_JAM1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_JAM1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    IELTS
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_IELTS1}
                        onChange={(e) => {
                          setPG_IELTS1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_IELTS1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TOEFL
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_TOEFL1}
                        onChange={(e) => {
                          setPG_TOEFL1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_TOEFL1}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Civil Services
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_Civil1}
                        onChange={(e) => {
                          setPG_Civil1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_Civil1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State government examinations
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_SGExam1}
                        onChange={(e) => {
                          setPG_SGExam1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_SGExam1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_OtherExam1}
                        onChange={(e) => {
                          setPG_OtherExam1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_OtherExam1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_Total1}
                        onChange={(e) => {
                          setPG_Total1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_Total1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Grand Total
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_GTotal1}
                        onChange={(e) => {
                          setPG_GTotal1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_GTotal1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_523?.PG_Desc26}
                        onChange={(e) => setPG_Desc26(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_Desc26}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload6"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria05_523?.PG_Link26}
                        onChange={(e) => {
                          setPG_Link26(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_523?.PG_Link26}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit6 ? (
                        <>
                          <Button
                            className="btn btn-success"
                            style={{ color: "black" }}
                            onClick={(e) => {
                              editPG_Criteria05_523(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit6(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    Name of the award/ medal
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_531?.PG_Meda}
                        onChange={(e) => {
                          setPG_Meda(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_531?.PG_Meda}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Team / Individual
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_531?.PG_TIndi}
                        onChange={(e) => {
                          setPG_TIndi(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_531?.PG_TIndi}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    University/State/National/ International
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_531?.PG_USNI1}
                        onChange={(e) => {
                          setPG_USNI1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_531?.PG_USNI1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Sports/ Cultural
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_531?.PG_SCult}
                        onChange={(e) => {
                          setPG_SCult(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_531?.PG_SCult}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria05_531?.PG_NaStd}
                        onChange={(e) => {
                          setPG_NaStd(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_531?.PG_NaStd}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_531?.PG_Desc27}
                        onChange={(e) => setPG_Desc27(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_531?.PG_Desc27}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload7"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria05_531?.PG_Link27}
                        onChange={(e) => {
                          setPG_Link27(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_531?.PG_Link27}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit7 ? (
                        <>
                          <Button
                            className="btn btn-success"
                            style={{ color: "black" }}
                            onClick={(e) => {
                              editPG_Criteria05_531(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit7(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student participated
                  </label>{" "}
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_533?.PG_NStdParti}
                        onChange={(e) => setPG_NStdParti(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_533?.PG_NStdParti}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date of event/activity (DD-MM-YYYY)
                  </label>{" "}
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_533?.PG_EDate}
                        onChange={(e) => setPG_EDate(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.PG_Criteria05_533?.PG_EDate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the event/activity
                  </label>{" "}
                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_533?.PG_EName}
                        onChange={(e) => setPG_EName(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_533?.PG_EName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    Description
                  </label>

                  {edit8 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria05_533?.PG_Desc28}
                        onChange={(e) => setPG_Desc28(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_533?.PG_Desc28}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload8">
                    {" "}
                    Document
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        id="upload8"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria05_533?.PG_Link28}
                        onChange={(e) => {
                          setPG_Link28(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria05_533?.PG_Link28}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit8 ? (
                        <>
                          {" "}
                          <Button
                            className="btn btn-success"
                            onClick={(e) => {
                              editPG_Criteria05_533(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Button
                            className="btn btn-danger"
                            onClick={() => setedit8(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
                setedit1(false);
                setedit2(false);
                setedit3(false);
                setedit4(false);
                setedit5(false);
                setedit6(false);
                setedit7(false);
                setedit8(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria05_511?.PG_Desc21}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria05_513?.PG_Desc22}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria05_514?.PG_Desc23}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria05_521?.PG_Desc24}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria05_522?.PG_Desc25}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria05_523?.PG_Desc26}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria05_531?.PG_Desc27}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_8 */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria05_533?.PG_Desc28}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_511?.PG_Link21}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_513?.PG_Link22}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_514?.PG_Link23}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_521?.PG_Link24}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_522?.PG_Link25}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_523?.PG_Link26}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_531?.PG_Link27}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria05/${View?.PG_Criteria05_533?.PG_Link28}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default PG_Criteria05;
