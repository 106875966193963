import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

const AutonomousCri4 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const autoCriteria04Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getautocriteria04"
      );
      if (res.status === 200) {
        setAllDetails(res.data.autocriteria04);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria04);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/LibraryAdmin/addRatingauto4`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Auto_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          autoCriteria04Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      autoCriteria04Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="mt-4"> Autonomous Criteria4 </h4>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="mt-4">Current Rating: </h6>
                <div className="mt-4">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Auto_Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center">
            <div className="ad-b mt-4 row align-items-center">
              <div className=" col-lg-4 do-sear mt-4 d-flex ">
                <input
                  type="text"
                  placeholder="Search"
                  className="vi_0"
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {/* <button>Search</button> */}
              </div>

              <div className=" col-lg-2 lab">
                <label>From</label>
                <br />
                <DatePicker
                  onChange={onChangeFrom}
                  picker="year"
                  className="vi_0"
                />
              </div>
              <div className=" col-lg-2 lab">
                <label>To</label>
                <br />
                <DatePicker
                  onChange={onChangeTo}
                  picker="year"
                  className="vi_0"
                />
              </div>

              {/* <div className=" col-lg-2 lab mt-4">
              <Button>Submit</Button>
            </div> */}
              {/* <div className=" col-lg-2 text-end mt-4">
              <Button>Export Excel</Button>
            </div> */}
            </div>
            <div className="row">
              <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
                <label style={{ width: "130px" }}>Select Year</label>
                <select
                  className="criteriaform "
                  style={{ width: "150px" }}
                  onChange={(e) => setselectedYear(e.target.value)}
                >
                  {allYears
                    ?.sort((a, b) => {
                      return Number(b?.from) - Number(a?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg-5">
                <Nav defaultActiveKey="/autocriteria04">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-1"
                      onClick={() =>
                        navigate(`/adminclgautocri4413/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">4.1.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-2"
                      onClick={() =>
                        navigate(`/adminclgautocri4414/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">4.1.4 & 4.4.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri4422/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">4.2.2 & 4.2.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              {/* <div className="col-lg-2">
                <Button
                  type="Submit"
                  className="btn btn-warning"
                  style={{ color: "white" }}
                  onClick={() => {
                    // setValue(item);
                    handleShow();
                  }}
                >
                  Add Rating
                </Button>
              </div> */}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 w-100"></div>
        </div>
        <div className="container">
          <div className="mo-gra mt-2">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 4.1.3 */}
                  <th>Criteria</th>
                  <th>
                    Room number or Name of Classrooms and Seminar halls with
                    LCD/wifi/LAN facilities
                  </th>
                  <th>Type of ICT facility</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 4.1.4 & 4.4.1 */}
                  <th>Criteria</th>
                  <th>
                    Budget allocated for infrastructure augmentation (INR in
                    Lakh)
                  </th>
                  <th>
                    Expenditure for infrastructure augmentation (INR in Lakh)
                  </th>
                  <th>Total expenditure excluding Salary (INR in Lakh)</th>
                  <th>
                    Expenditure on maintenance of academic facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </th>
                  <th>
                    Expenditure on maintenance of physical facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 4.2.2 & 4.2.3 */}
                  <th>Criteria</th>
                  <th>Library Resources</th>
                  <th>If yes, details of memberships/subscriptions</th>
                  <th>
                    Expenditure on subscription to e-journals, e-books (INR in
                    lakhs)
                  </th>
                  <th>Total Library Expenditure</th>
                  <th>Description</th>
                  <th>Relevant document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Auto_Year &&
                    (SearchItem === "" ||
                      item1?.Auto_Criteria04_413?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_413?.Auto_Room?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_413?.Auto_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_413?.Auto_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Expenditure?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Expend1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Expend2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_Library?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_IfYes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_Expend3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Auto_Year}</td>

                      {/* 413 */}
                      <td>{item?.Auto_Criteria04_413?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria04_413?.Auto_Room}</td>
                      <td>{item?.Auto_Criteria04_413?.Auto_Type}</td>
                      <td>
                        {item?.Auto_Criteria04_413?.Auto_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 414 */}
                      <td>{item?.Auto_Criteria04_414?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Budget}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Expenditure}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Total}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Expend1}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Expend2}</td>
                      <td>
                        {item?.Auto_Criteria04_414?.Auto_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 332 */}
                      <td>{item?.Auto_Criteria04_422?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria04_422?.Auto_Library}</td>
                      <td>{item?.Auto_Criteria04_422?.Auto_IfYes}</td>
                      <td>{item?.Auto_Criteria04_422?.Auto_Expend3}</td>
                      <td>{item?.Auto_Criteria04_422?.Auto_Total1}</td>
                      <td>
                        {item?.Auto_Criteria04_422?.Auto_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria4/${View?.Auto_Criteria04_413?.Auto_Link1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria4/${View?.Auto_Criteria04_414?.Auto_Link2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria4/${View?.Auto_Criteria04_422?.Auto_Link3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal_4 */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Body>
              <div>{View?.Auto_Criteria04_413?.Auto_Desc1}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose4}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal_5 */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Body>
              <div>{View?.Auto_Criteria04_414?.Auto_Desc2}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose5}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_6 */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Body>
              <div>{View?.Auto_Criteria04_422?.Auto_Desc3}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose6}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AutonomousCri4;
