import React, { useEffect, useState } from "react";
import "./Styles/Contactus.css";
import axios from "axios";
import { Link } from "react-router-dom";
const Contactus = () => {
  const [name, setname] = useState("");
  const [website, setwebsite] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;
  var Name = /^[a-zA-Z][a-zA-Z]/;
  const AddContact = async (e) => {
    e.preventDefault();
    if (!name) {
      return alert("Enter Your name");
    }
    if (!name.match(Name)) {
      return alert("Please Enter Valid Name");
    }

    if (!email) {
      return alert("Enter Your Email");
    }
    if (!email.match(validRegex)) {
      return alert("Please Enter Valid Email-Id");
    }

    if (!website) {
      return alert("Enter Your Website");
    }

    if (!message) {
      return alert("Enter Your Message");
    }

    try {
      const config = {
        url: "/admin/addcontactHelp",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          name: name,
          website: website,
          email: email,
          message: message,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("request sent Successfully");
          window.location.reload(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // =================== calling api for getting the Contact======================
  const [Contact, setContact] = useState([]);
  const getContact = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getcontact"
      );
      if (res.status === 200) {
        setContact(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setContact(error.response.data.allContent);
    }
  };

  useEffect(() => {
    getContact();
  }, []);
  return (
    <div className="contactus">
      <div
        className="ab-0"
        style={{
          backgroundImage: "url('../Image/about-us.jpg')",
          height: "250px",
          position: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="edg container">
          <div className="edgt-has-inline-style">Contact</div>
          <div class="edgt-breadcrumbs-info">
            <div
              itemProp="breadcrumb"
              classname="edgt-breadcrumbs "
              style={{ color: "#e1e0e0" }}
            >
              <Link
                itemProp="url"
                to="/"
                style={{ color: "#e1e0e0", textDecoration: "none" }}
              >
                Home
              </Link>
              <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
              <span classname="edgt-current">Contact</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-lg-5">
            <p className="h1 mb-4 fw-medium "> Contact Details</p>

            {Contact?.map((item) => {
              return (
                <>
                  <div className="mb-4 fs-5">{item?.officeName}</div>
                  <div className="row mb-3">
                    <div className="col-1 text-left ">
                      {" "}
                      <img
                        style={{
                          height: "20px",
                        }}
                        src="../gallery/call.png"
                      />
                    </div>{" "}
                    <div className=" col-6">{item?.phone}</div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-1 text-left ">
                      {" "}
                      <img
                        style={{
                          height: "20px",
                        }}
                        src="../gallery/email.png"
                      />
                    </div>{" "}
                    <div className=" col-6">{item?.email}</div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-1 text-left ">
                      {" "}
                      <img
                        style={{
                          height: "20px",
                        }}
                        src="../gallery/loc.png"
                      />
                    </div>{" "}
                    <div className=" col-6">{item?.address}</div>
                  </div>

                  <hr
                    style={{
                      maxWidth: "300px",
                    }}
                  ></hr>
                </>
              );
            })}
            {/* <div className="mb-4 fs-5">Bengalore Office</div>
            <div className="row mb-3">
              <div className="col-1 text-left ">
                {" "}
                <img
                  style={{
                    height: "20px",
                  }}
                  src="../gallery/call.png"
                />
              </div>{" "}
              <div className=" col-6">+91 8709749634</div>
            </div>
            <div className="row mb-3">
              <div className="col-1 text-left ">
                {" "}
                <img
                  style={{
                    height: "20px",
                  }}
                  src="../gallery/email.png"
                />
              </div>{" "}
              <div className=" col-6">help.contact@gmail.com</div>
            </div>
            <div className="row mb-4">
              <div className="col-1 text-left ">
                {" "}
                <img
                  style={{
                    height: "20px",
                  }}
                  src="../gallery/loc.png"
                />
              </div>{" "}
              <div className=" col-6">
                184 Main Singapura Street West Bengalore 560097
              </div>
            </div> */}
          </div>
          <div className="col-lg-7 border">
            <p className="p-3 border-bottom contact-form-text">
              Feel free to write us
            </p>
            <div className="m-auto">
              <form className="p-4">
                <div className="row">
                  <div class="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="Name"
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>
                  <div class="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="91+ 8074736456"
                      onChange={(e) => setwebsite(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div class="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label for="exampleFormControlInput1" class="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      class="form-c border"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                </div>

                <div class="mb-5">
                  <label for="" class="form-label">
                    Message
                  </label>
                  <textarea
                    class="form-c border "
                    placeholder="Message"
                    style={{
                      height: "120px",
                    }}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    onChange={(e) => setmessage(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className="btn-submit border"
                  onClick={(e) => AddContact(e)}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <iframe
        className="mb-0 mt-3 p-0"
        style={{ marginBottom: "-10px" }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9819.728769822817!2d77.53498257499511!3d13.076439823117747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22b323283b93%3A0x22c9ba226d1b3b20!2sParNets%20Digital%20Advertisements%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1686055873146!5m2!1sen!2sin"
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Contactus;
