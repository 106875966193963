import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Nav } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

const UCriteria6 = () => {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const [U_Criteria06, setU_Criteria06] = useState([]);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const navigate = useNavigate();

  const [smShow, setSmShow] = useState(false);
  const [View, setView] = useState({});

  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  //============= criteria 623 =============== //

  const [Uni_Year, setUni_Year] = useState("");
  const [Uni_yoi1, setUni_yoi1] = useState("");
  const [Uni_yoi2, setUni_yoi2] = useState("");
  const [Uni_yoi3, setUni_yoi3] = useState("");
  const [Uni_yoi4, setUni_yoi4] = useState("");
  const [Uni_doc1, setUni_doc1] = useState("");
  const [Uni_doc2, setUni_doc2] = useState("");
  const [Uni_doc3, setUni_doc3] = useState("");
  const [Uni_doc4, setUni_doc4] = useState("");
  const [Uni_desc1, setUni_desc1] = useState("");

  //============= criteria 632 =============== //

  const [Uni_Not, setUni_Not] = useState("");
  const [Uni_Asr, setUni_Asr] = useState("");
  const [Uni_NOC, setUni_NOC] = useState("");
  const [Uni_Nop, setUni_Nop] = useState("");
  const [Uni_desc2, setUni_desc2] = useState("");
  const [Uni_doc5, setUni_doc5] = useState("");

  //============= criteria 633 =============== //

  const [Uni_Sdate, setUni_Sdate] = useState("");
  const [Uni_Edate, setUni_Edate] = useState("");
  const [Uni_titlep, setUni_titlep] = useState("");
  const [Uni_Nfp, setUni_Nfp] = useState("");
  const [Uni_doc6, setUni_doc6] = useState("");
  const [Uni_desc3, setUni_desc3] = useState("");

  //============= criteria 634 =============== //

  const [Uni_Sdate1, setUni_Sdate1] = useState("");
  const [Uni_Edate1, setUni_Edate1] = useState("");
  const [Uni_namet, setUni_namet] = useState("");
  const [Uni_prgm50, setUni_prgm50] = useState("");
  const [Uni_doc7, setUni_doc7] = useState("");
  const [Uni_desc4, setUni_desc4] = useState("");

  //============= criteria 642 =============== //

  const [Uni_pgrant, setUni_pgrant] = useState("");
  const [Uni_Fundg, setUni_Fundg] = useState("");
  const [Uni_namefgov, setUni_namefgov] = useState("");
  const [Uni_doc8, setUni_doc8] = useState("");
  const [Uni_desc5, setUni_desc5] = useState("");

  //============= criteria 652 =============== //

  const [Uni_Sdate2, setUni_Sdate2] = useState("");
  const [Uni_Edate2, setUni_Edate2] = useState("");
  const [Uni_nirfs, setUni_nirfs] = useState("");
  const [Uni_aaa, setUni_aaa] = useState("");
  const [Uni_nba, setUni_nba] = useState("");
  const [Uni_cswq, setUni_cswq] = useState("");
  const [Uni_iaa, setUni_iaa] = useState("");
  const [Uni_doc9, setUni_doc9] = useState("");
  const [Uni_desc6, setUni_desc6] = useState("");

  // =============== Edit ==================

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);

  // =======================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/approveCriteriaU06/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          UniCriteria06Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //===================================

  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================== Edit Code ==============================
  const editCriteria06_623 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editUCriteria06_623/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Year: Uni_Year,
          Uni_yoi1: Uni_yoi1,
          Uni_yoi2: Uni_yoi2,
          Uni_yoi3: Uni_yoi3,
          Uni_yoi4: Uni_yoi4,
          Uni_doc1: Uni_doc1,
          Uni_doc2: Uni_doc2,
          Uni_doc3: Uni_doc3,
          Uni_doc4: Uni_doc4,
          Uni_desc1: Uni_desc1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_632 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editUCriteria06_632/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Not: Uni_Not,
          Uni_Asr: Uni_Asr,
          Uni_NOC: Uni_NOC,
          Uni_Nop: Uni_Nop,
          Uni_doc5: Uni_doc5,
          Uni_desc2: Uni_desc2,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_633 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editUCriteria06_633/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Sdate: Uni_Sdate,
          Uni_Edate: Uni_Edate,
          Uni_titlep: Uni_titlep,
          Uni_Nfp: Uni_Nfp,
          Uni_doc6: Uni_doc6,
          Uni_desc3: Uni_desc3,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria06_634 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editUCriteria06_634/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Sdate1: Uni_Sdate1,
          Uni_Edate1: Uni_Edate1,
          Uni_namet: Uni_namet,
          Uni_prgm50: Uni_prgm50,
          Uni_doc7: Uni_doc7,
          Uni_desc4: Uni_desc4,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_642 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editUCriteria06_642/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_pgrant: Uni_pgrant,
          Uni_Fundg: Uni_Fundg,
          Uni_namefgov: Uni_namefgov,
          Uni_doc8: Uni_doc8,
          Uni_desc5: Uni_desc5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_652 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editUCriteria06_652/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Sdate2: Uni_Sdate2,
          Uni_Edate2: Uni_Edate2,
          Uni_nirfs: Uni_nirfs,
          Uni_aaa: Uni_aaa,
          Uni_nba: Uni_nba,
          Uni_cswq: Uni_cswq,
          Uni_iaa: Uni_iaa,
          Uni_desc6: Uni_desc6,
          Uni_doc9: Uni_doc9,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const UniCriteria06Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/PrinipalAdmin/getdetailsU06"
      );
      if (res.status === 200) {
        setAllDetails(res.data.Unicriteria06);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.Unicriteria06);
    }
  };

  // =================================================================

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      UniCriteria06Details();
    }
  }, []);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-8 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-06
              </span>
            </div>

            <div className=" col-lg-4 lab text-end ">
              <Link to="/ugcriteria6">
                <button>
                  Add Criteria 6{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0 ">
            <div className=" col-lg-4 do-sear d-flex p-0  ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeFrom}
                picker="year"
                placeholder="Select Year (from)"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                picker="year"
                placeholder="Select Year (to)"
                className="vi_0"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className="">
            <Nav defaultActiveKey="/u-criteria06">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  className="p-0"
                  onClick={() => navigate("/U_06_623")}
                >
                  <Button className="criteriabtn btn-sub1">6.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/U_06_632")}
                >
                  <Button className="criteriabtn btn-sub">6.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_06_633")}
                >
                  <Button className="criteriabtn btn-sub">6.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_06_634")}
                >
                  <Button className="criteriabtn btn-sub">6.3.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_06_642")}
                >
                  <Button className="criteriabtn btn-sub">6.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_06_653")}
                >
                  <Button className="criteriabtn btn-sub">6.5.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/*  Criteria 06 6.2.3  */}

                  <th>Criteria</th>
                  <th>Administration</th>
                  <th>Finance and Accounts</th>
                  <th>Student Admissions and Support</th>
                  <th>Examinations</th>
                  <th>Description</th>

                  {/*  Criteria 06 6.3.2  */}

                  <th>Criteria</th>
                  <th>Name of the teacher</th>
                  <th>Amount of support received (in INR)</th>
                  <th>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </th>
                  <th>
                    Name of the professional body for which membership fee is
                    provided
                  </th>
                  <th>Description</th>
                  <th>Relevant document</th>
                  {/*  Criteria 06 6.3.3  */}

                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>
                    Title of the professional development/ administrative
                    training programs organised for teaching staff (Professional
                    development/ administrative training programs)
                  </th>
                  <th>No. of participants</th>
                  <th>Description</th>
                  <th>Relevant document</th>
                  {/*  Criteria 06 6.3.4  */}

                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>Name of teacher who attended</th>
                  <th>Title of the program</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  {/*  Criteria 06 6.4.2  */}

                  <th>Criteria</th>
                  <th>Purpose of the Grant</th>
                  <th>Funds/ Grants received (INR in lakhs)</th>
                  <th>
                    Name of the non government funding agencies/ individuals
                  </th>
                  <th>Description with minimum 500 Characters</th>
                  <th>
                    Upload the relevant document Upload the Audited Statement of
                    Accounts reflecting the receipts{" "}
                  </th>

                  {/*  Criteria 06 6.5.2  */}

                  <th>Criteria</th>
                  <th>Date</th>

                  <th>Participation in NIRF along with Status.</th>
                  <th>
                    Academic Administrative Audit (AAA) and follow up action
                  </th>
                  <th>
                    NBA or any other certification received with program
                    specifications.
                  </th>
                  <th>Conferences, Seminars, Workshops on quality conducted</th>
                  <th>
                    Collaborative quality initiatives with other institution(s)
                    (Provide name of the institution and activity)
                  </th>
                  <th>Description with minimum 500 Characters</th>
                  <th>Upload the relevant document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{item?._id}</td>
                      <td>{item?.Uni_Year}</td>
                      <td>{item?.Uni_Criteria06_623?.Uni_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Relevant document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria06_623?.Uni_yoi1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow1();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th>Relevant document</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria06_623?.Uni_yoi2}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow2();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Relevant document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria06_623?.Uni_yoi3}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow3();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Relevant document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Uni_Criteria06_623?.Uni_yoi4}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow4();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>

                      <td>
                        {item?.Uni_Criteria06_623?.Uni_desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>{item?.Uni_Criteria06_632?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria06_632?.Uni_Not}</td>
                      <td>{item?.Uni_Criteria06_632?.Uni_Asr}</td>
                      <td>{item?.Uni_Criteria06_632?.Uni_NOC}</td>
                      <td>{item?.Uni_Criteria06_632?.Uni_Nop}</td>

                      <td>
                        {item?.Uni_Criteria06_632?.Uni_desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria06_633?.Uni_criteria}</td>
                      <td>
                        {moment(item?.Uni_Criteria06_633?.Uni_Sdate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Uni_Criteria06_633?.Uni_Edate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria06_633?.Uni_titlep}</td>
                      <td>{item?.Uni_Criteria06_633?.Uni_Nfp}</td>

                      <td>
                        {item?.Uni_Criteria06_633?.Uni_desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria06_634?.Uni_criteria}</td>
                      <td>
                        {moment(item?.Uni_Criteria06_634?.Uni_Sdate1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Uni_Criteria06_634?.Uni_Edate1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria06_634?.Uni_namet}</td>
                      <td>{item?.Uni_Criteria06_634?.Uni_prgm50}</td>

                      <td>
                        {item?.Uni_Criteria06_634?.Uni_desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria06_642?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria06_642?.Uni_pgrant}</td>
                      <td>{item?.Uni_Criteria06_642?.Uni_Fundg}</td>
                      <td>{item?.Uni_Criteria06_642?.Uni_namefgov}</td>

                      <td>
                        {item?.Uni_Criteria06_642?.Uni_desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria06_652?.Uni_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Date (from)</th>
                            <th>Date (to)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {moment(
                                  item?.Uni_Criteria06_652?.Uni_Sdate2
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {moment(
                                  item?.Uni_Criteria06_652?.Uni_Edate2
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.Uni_Criteria06_652?.Uni_nirfs}</td>
                      <td>{item?.Uni_Criteria06_652?.Uni_aaa}</td>
                      <td>{item?.Uni_Criteria06_652?.Uni_nba}</td>
                      <td>{item?.Uni_Criteria06_652?.Uni_cswq}</td>
                      <td>{item?.Uni_Criteria06_652?.Uni_iaa}</td>

                      <td>
                        {item?.Uni_Criteria06_652?.Uni_desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Body>
              <div>{View?.Uni_Criteria06_623?.Uni_desc1}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Body>
              <div>{View?.Uni_Criteria06_632?.Uni_desc2}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Body>
              <div>{View?.Uni_Criteria06_633?.Uni_desc3}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose12}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Body>
              <div>{View?.Uni_Criteria06_634?.Uni_desc4}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Body>
              <div>{View?.Uni_Criteria06_642?.Uni_desc5}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose14}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Body>
              <div>{View?.Uni_Criteria06_652?.Uni_desc6}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_623?.Uni_doc1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_623?.Uni_doc2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_623?.Uni_doc3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_623?.Uni_doc4}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_632?.Uni_doc5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_633?.Uni_doc6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_634?.Uni_doc7}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_642?.Uni_doc8}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria06/${View?.Uni_Criteria06_652?.Uni_doc9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              University Criteria 06{" "}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div className="row ">
                <h6>Administration</h6>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.UGCriteria06_623?.yoi1}
                          className="vi_0"
                          onChange={(e) => {
                            setUni_yoi1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.yoi1}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Upload the relevant document</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload1"
                          className="vi_0"
                          placeholder={Uni_doc1}
                          onChange={(e) => setUni_doc1(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_doc1?.name}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ">
                <h6>Finance and Accounts</h6>

                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.UGCriteria06_623?.Uni_yoi2}
                          className="vi_0"
                          onChange={(e) => setUni_yoi2(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.Uni_yoi2}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Upload the relevant document</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload2"
                          className="vi_0"
                          placeholder={Uni_doc2}
                          onChange={(e) => setUni_doc2(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_doc2?.name}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ">
                <h6>Student Admissions and Support</h6>

                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.UGCriteria06_623.Uni_yoi3}
                          className="vi_0"
                          onChange={(e) => setUni_yoi3(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.Uni_yoi3}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Upload the relevant document</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload3"
                          className="vi_0"
                          placeholder={Uni_doc3}
                          onChange={(e) => setUni_doc3(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_doc3?.name}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <h6>Examinations</h6>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.Uni_Criteria06_633?.Uni_yoi4}
                          className="vi_0"
                          onChange={(e) => setUni_yoi4(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria06_633?.Uni_yoi4}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Upload the relevant document</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload4"
                          className="vi_0"
                          placeholder={Uni_doc4}
                          onChange={(e) => setUni_doc4(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_doc4?.name}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3 ">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description with minimum 500 Characters
                </label>
                {edit1 ? (
                  <>
                    <textarea
                      className="name"
                      type="text"
                      placeholder={Value?.Uni_Criteria06_633?.ugdesc1}
                      onChange={(e) => setUni_desc1(e.target.value)}
                    />
                  </>
                ) : (
                  <>{Value?.Uni_Criteria06_633?.ugdesc1}</>
                )}
              </div>

              <div className="text-end mt-4">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={setedit1}
                  className="m-2"
                  style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                >
                  Edit
                </Button>
                {/* {add1 ? ( */}
                <Button
                  type="submit"
                  className="btn btn-success  "
                  onClick={(e) => {
                    editCriteria06_623(e, Value?._id);
                    handleClose();
                  }}
                >
                  ADD
                </Button>
                {/* ) : (
                <></>
              )} */}
              </div>
            </div>

            <div>
              <div className="row ">
                <div className="col-lg-12">
                  <div className="lab mb-3">
                    <label>Name of the teacher</label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.Uni_Criteria06_632?.Uni_Not}
                          className="vi_0"
                          onChange={(e) => setUni_Not(e.target.value)}
                        />{" "}
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria06_632?.Uni_Not}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="lab mb-3">
                    <label>Amount of support received (in INR)</label>
                    <br />

                    {edit2 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.Uni_Criteria06_632?.Uni_Asr}
                          className="vi_0"
                          onChange={(e) => setUni_Asr(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria06_632?.Uni_Asr}</>
                    )}
                  </div>
                </div>
              </div>

              <div className="lab mb-4">
                <label>
                  Name of conference/ workshop attended for which financial
                  support provided
                </label>
                <br />
                {edit2 ? (
                  <>
                    <input
                      type="text"
                      placeholder={Value?.Uni_Criteria06_632?.Uni_NOC}
                      className="vi_0"
                      onChange={(e) => setUni_NOC(e.target.value)}
                    />
                  </>
                ) : (
                  <>{Value?.Uni_Criteria06_632?.Uni_NOC}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  Name of the professional body for which membership fee is
                  provided
                </label>
                <br />
                {edit2 ? (
                  <>
                    <input
                      type="text"
                      placeholder={Value?.Uni_Criteria06_632?.Uni_Nop}
                      className="vi_0"
                      onChange={(e) => setUni_Nop(e.target.value)}
                    />{" "}
                  </>
                ) : (
                  <>{Value?.Uni_Criteria06_632?.Uni_Nop}</>
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description with minimum 500 Characters
                </label>
                {edit2 ? (
                  <>
                    <textarea
                      type="text"
                      id="name"
                      placeholder={Value?.Uni_Criteria06_632?.Uni_desc2}
                      onChange={(e) => {
                        setUni_desc2(e.target.value);
                      }}
                    />
                  </>
                ) : (
                  <>{Value?.Uni_Criteria06_632?.Uni_desc2}</>
                )}
              </div>

              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>
                  <br />
                  {edit2 ? (
                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setUni_doc5(e.target.files[0])}
                    />
                  ) : (
                    <>{Uni_doc5?.name}</>
                  )}
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={setedit2}
                  className="m-2"
                  style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                >
                  Edit
                </Button>
                {/* {add1 ? ( */}
                <Button
                  type="submit"
                  className="btn btn-success  "
                  onClick={(e) => {
                    editCriteria06_632(e, Value?._id);
                    handleClose();
                  }}
                >
                  ADD
                </Button>
                {/* ) : (
                <></>
              )} */}
              </div>
            </div>

            <div>
              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from) (DD-MM-YYYY)</label>
                  <br />
                  {edit3 ? (
                    <input
                      type="date"
                      placeholder={Value?.Uni_Criteria06_633?.Uni_Sdate}
                      className="vi_0"
                      onChange={(e) => setUni_Sdate(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_633?.Uni_Sdate}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to) (DD-MM-YYYY)</label>
                  <br />
                  {edit3 ? (
                    <input
                      type="date"
                      placeholder={Value?.Uni_Criteria06_633?.Uni_Edate}
                      className="vi_0"
                      onChange={(e) => setUni_Edate(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_633?.Uni_Edate}</>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>
                    {" "}
                    Title of the professional development/ administrative
                    training programs organised for teaching staff (Professional
                    development/ administrative training programs)
                  </label>
                  <br />
                  {edit3 ? (
                    <input
                      type="text"
                      placeholder={Value?.Uni_Criteria06_633?.Uni_titlep}
                      className="vi_0"
                      onChange={(e) => setUni_titlep(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_633?.Uni_titlep}</>
                  )}
                </div>

                <div className=" col-lg-6 lab mb-4">
                  <label>No. of participants</label>
                  <br />
                  {edit3 ? (
                    <input
                      type="number"
                      placeholder={Value?.Uni_Criteria06_633?.Uni_Nfp}
                      className="vi_0"
                      onChange={(e) => setUni_Nfp(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_633?.Uni_Nfp}</>
                  )}
                </div>

                <div className=" col-lg-6 lab mb-4">
                  <label>Upload the relevant document</label>
                  <br />
                  {edit3 ? (
                    <input
                      type="file"
                      placeholder={Uni_doc6}
                      className="vi_0"
                      id="upload6"
                      onChange={(e) => setUni_doc6(e.target.files[0])}
                    />
                  ) : (
                    <>{Uni_doc6?.name}</>
                  )}
                </div>
                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description with minimum 500 Characters
                  </label>
                  {edit3 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      placeholder={Value?.Uni_Criteria06_633?.Uni_desc3}
                      onChange={(e) => {
                        setUni_desc3(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_633?.Uni_desc3}</>
                  )}
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  type="submit"
                  onClick={setedit3}
                  className="m-2"
                  variant="warning "
                  style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                >
                  Preview
                </Button>
                <Button
                  type="submit"
                  onClick={(e) => {
                    editCriteria06_633(e, Value?._id);
                    handleClose();
                  }}
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Add
                </Button>
              </div>
            </div>

            <div>
              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from) (DD-MM-YYYY)</label>
                  <br />
                  {edit4 ? (
                    <input
                      type="date"
                      className="vi_0"
                      placeholder={Value?.Uni_Criteria06_634?.Uni_Sdate1}
                      onChange={(e) => setUni_Sdate1(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_634?.Uni_Sdate1}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to) (DD-MM-YYYY)</label>
                  <br />
                  {edit4 ? (
                    <input
                      type="date"
                      placeholder={Value?.Uni_Criteria06_634?.Uni_Edate1}
                      className="vi_0"
                      onChange={(e) => setUni_Edate1(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_634?.Uni_Edate1}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Name of teacher who attended</label>
                  <br />
                  {edit4 ? (
                    <input
                      type="text"
                      placeholder={Value?.Uni_Criteria06_634?.Uni_namet}
                      className="vi_0"
                      onChange={(e) => setUni_namet(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_634?.Uni_namet}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Title of the program</label>
                  <br />
                  {edit4 ? (
                    <input
                      type="text"
                      placeholder={Value?.Uni_Criteria06_634?.Uni_prgm50}
                      className="vi_0"
                      onChange={(e) => setUni_prgm50(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_634?.Uni_prgm50}</>
                  )}
                </div>
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description with minimum 500 Characters
                </label>
                {edit4 ? (
                  <textarea
                    className="name"
                    type="text"
                    id="name"
                    placeholder={Value?.Uni_Criteria06_634?.Uni_desc4}
                    onChange={(e) => {
                      setUni_desc4(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Uni_Criteria06_634?.Uni_desc4}</>
                )}
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label>Upload the relevant document</label>
                <br />
                {edit4 ? (
                  <input
                    type="file"
                    className="vi_0"
                    placeholder={Uni_doc7}
                    onChange={(e) => setUni_doc7(e.target.files[0])}
                  />
                ) : (
                  <>{Uni_doc7.name}</>
                )}
              </div>

              <div className="text-end mt-4">
                <Button
                  type="submit"
                  onClick={setedit4}
                  className="m-2"
                  variant="warning "
                  style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                >
                  Edit
                </Button>

                <Button
                  type="submit"
                  onClick={(e) => {
                    editCriteria06_634(e, Value?._id);
                    handleClose();
                  }}
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Submit
                </Button>
              </div>
            </div>

            <div>
              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Purpose of the Grant</label>
                  <br />
                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Value?.Uni_Criteria06_642?.Uni_pgrant}
                      className="vi_0"
                      onChange={(e) => setUni_pgrant(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_642?.Uni_pgrant}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label> Funds/ Grants received (INR in lakhs)</label>
                  <br />
                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Value?.Uni_Criteria06_642?.Uni_Fundg}
                      className="vi_0"
                      onChange={(e) => setUni_Fundg(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_642?.Uni_Fundg}</>
                  )}
                </div>
              </div>

              <div className="  lab mb-4">
                <label>
                  {" "}
                  Name of the non government funding agencies/ individuals
                </label>
                <br />
                {edit5 ? (
                  <input
                    type="text"
                    placeholder={Value?.Uni_Criteria06_642?.Uni_namefgov}
                    className="vi_0"
                    onChange={(e) => setUni_namefgov(e.target.value)}
                  />
                ) : (
                  <>{Value?.Uni_Criteria06_642?.Uni_namefgov}</>
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description with minimum 500 Characters
                </label>
                {edit5 ? (
                  <textarea
                    className="name"
                    type="text"
                    id="name"
                    placeholder={Value?.Uni_Criteria06_642?.Uni_desc5}
                    onChange={(e) => {
                      setUni_desc5(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Uni_Criteria06_642?.Uni_desc5}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  Upload the Audited Statement of Accounts reflecting the
                  receipts
                </label>
                <br />
                {edit5 ? (
                  <input
                    type="file"
                    className="vi_0"
                    onChange={(e) => setUni_doc8(e.target.files[0])}
                  />
                ) : (
                  <>{Uni_doc8.name}</>
                )}
              </div>

              <div className="text-end mt-4">
                <Button
                  type="submit"
                  onClick={setedit5}
                  className="m-2"
                  variant="warning "
                  style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                >
                  Preview
                </Button>
                <Button
                  type="submit"
                  onClick={(e) => {
                    editCriteria06_642(e, Value?._id);
                    handleClose();
                  }}
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Add
                </Button>
              </div>
            </div>

            <div>
              <div className="lab">
                <label>
                  Orientation programme on quality issues for teachers and
                  students,{" "}
                </label>
              </div>

              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from) (DD-MM-YYYY)</label>
                  <br />
                  {edit6 ? (
                    <input
                      type="date"
                      placeholder={Value?.Uni_Criteria06_652?.Uni_Sdate2}
                      className="vi_0"
                      onChange={(e) => setUni_Sdate2(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_652?.Uni_Sdate2}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to) (DD-MM-YYYY)</label>
                  <br />
                  {edit6 ? (
                    <input
                      type="date"
                      placeholder={Value?.Uni_Criteria06_652?.Uni_Edate2}
                      className="vi_0"
                      onChange={(e) => setUni_Edate2(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria06_652?.Uni_Edate2}</>
                  )}
                </div>
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label> Participation in NIRF along with Status.</label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Uni_Criteria06_652?.Uni_nirfs}
                    className="vi_0"
                    onChange={(e) => setUni_nirfs(e.target.value)}
                  />
                ) : (
                  <>{Value?.Uni_Criteria06_652?.Uni_nirfs}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  Academic Administrative Audit (AAA) and follow up action
                </label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Uni_Criteria06_652?.Uni_aaa}
                    className="vi_0"
                    onChange={(e) => setUni_aaa(e.target.value)}
                  />
                ) : (
                  <>{Value?.Uni_Criteria06_652?.Uni_aaa}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  {" "}
                  NBA or any other certification received with program
                  specifications.{" "}
                </label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Uni_Criteria06_652?.Uni_nba}
                    className="vi_0"
                    onChange={(e) => setUni_nba(e.target.value)}
                  />
                ) : (
                  <>{Value?.Uni_Criteria06_652?.Uni_nba}</>
                )}
              </div>

              <div className="  lab mb-4">
                <label>
                  {" "}
                  Conferences, Seminars, Workshops on quality conducted{" "}
                </label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Uni_Criteria06_652?.Uni_cswq}
                    className="vi_0"
                    onChange={(e) => setUni_cswq(e.target.value)}
                  />
                ) : (
                  <>{Value?.Uni_Criteria06_652?.Uni_cswq}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  {" "}
                  Collaborative quality initiatives with other institution(s)
                  (Provide name of the institution and activity)
                </label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Uni_Criteria06_652?.iaa}
                    className="vi_0"
                    onChange={(e) => setUni_iaa(e.target.value)}
                  />
                ) : (
                  <>{Value?.Uni_Criteria06_652?.setUni_iaa}</>
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description with minimum 500 Characters
                </label>
                {edit6 ? (
                  <textarea
                    className="name"
                    type="text"
                    id="name"
                    onChange={(e) => {
                      setUni_desc6(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Uni_Criteria06_652?.Uni_desc6}</>
                )}
              </div>

              <div className="lab mb-4">
                <label> Upload the relevant document</label>
                <br />

                {edit6 ? (
                  <>
                    <input
                      type="file"
                      className="vi_0"
                      placeholder={Uni_doc9}
                      onChange={(e) => setUni_doc9(e.target.files[0])}
                    />
                  </>
                ) : (
                  <>{Uni_doc9.name}</>
                )}
              </div>

              <div className="text-end mt-4">
                <Button
                  type="submit"
                  onClick={setedit6}
                  className="m-2"
                  variant="warning "
                  style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                >
                  Preview
                </Button>
                <Button
                  type="submit"
                  onClick={(e) => {
                    editCriteria06_652(e, Value?._id);
                    handleClose();
                  }}
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Add
                </Button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  variant="primary"
                  onClick={(e) => approveCriteria(e, true, Value?._id)}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              style={{ height: "200px" }}
              onChange={(e) => setremark(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => approveCriteria(e, false, Value?._id)}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UCriteria6;
