import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Uni_Criteria007 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //post method for Uni_criteria_07
  const [Uni_MainYear7, setUni_MainYear7] = useState("");

  const [Uni_Budget, setUni_Budget] = useState("");
  const [Uni_Lighting, setUni_Lighting] = useState("");
  const [Uni_PhyFac, setUni_PhyFac] = useState("");
  const [Uni_Prolift, setUni_Prolift] = useState("");
  const [Uni_Ramp, setUni_Ramp] = useState("");
  const [Uni_Braille, setUni_Braille] = useState("");
  const [Uni_RestRoom, setUni_RestRoom] = useState("");
  const [Uni_Scribes, setUni_Scribes] = useState("");
  const [Uni_SpecialSkills, setUni_SpecialSkills] = useState("");
  const [Uni_AnyOther, setUni_AnyOther] = useState("");
  const [Uni_Annual, setUni_Annual] = useState("");
  const [Uni_NoOfIniti1, setUni_NoOfIniti1] = useState("");
  const [Uni_NoOfIniti2, setUni_NoOfIniti2] = useState("");
  const [Uni_Date1, setUni_Date1] = useState("");
  const [Uni_DateDuration, setUni_DateDuration] = useState("");
  const [Uni_Date2, setUni_Date2] = useState("");
  const [Uni_Power, setUni_Power] = useState("");
  const [Uni_TPower, setUni_TPower] = useState("");
  const [Uni_ReEnergyS, setUni_ReEnergyS] = useState("");
  const [Uni_ReEnergyG, setUni_ReEnergyG] = useState("");
  const [Uni_EnergySup, setUni_EnergySup] = useState("");
  const [Uni_PercentageLED, setUni_PercentageLED] = useState("");
  const [Uni_PercentageLight, setUni_PercentageLight] = useState("");
  const [Uni_NoOfParticipants, setUni_NoOfParticipants] = useState("");
  const [Uni_NoOfActivities, setUni_NoOfActivities] = useState("");
  const [Uni_TitlePro, setUni_TitlePro] = useState("");
  const [Uni_NoOfParticipants1, setUni_NoOfParticipants1] = useState("");
  const [Uni_Male, setUni_Male] = useState("");
  const [Uni_Female, setUni_Female] = useState("");
  const [Uni_Yea, setUni_Yea] = useState("");
  const [Uni_Total, setUni_Total] = useState("");
  const [Uni_PowRe, setUni_PowRe] = useState("");
  const [Uni_InitiName, setUni_InitiName] = useState("");
  const [Uni_DurInitiative, setUni_DurInitiative] = useState("");
  const [Uni_Desc29, setUni_Desc29] = useState("");
  const [Uni_Link29, setUni_Link29] = useState("");
  const [Uni_Link30, setUni_Link30] = useState("");
  const [Uni_Link31, setUni_Link31] = useState("");

  const [obj1, setobj1] = useState({});
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getunicriteria7 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getunicriteria7();
  }, []);

  const unicriteria7 = async () => {
    if (!Uni_MainYear7) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/uniadddetails07",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Year: Uni_MainYear7,

          Uni_Criteria07_007: obj1,
          Uni_Link29: Uni_Link29,
          Uni_Link30: Uni_Link30,
          Uni_Link31: Uni_Link31,

          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          navigate("/unicriteria07");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc29;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Uni_Budget ||
          !Uni_Lighting ||
          !Uni_PhyFac ||
          !Uni_Prolift ||
          !Uni_Ramp ||
          !Uni_Braille ||
          !Uni_RestRoom ||
          !Uni_Scribes ||
          !Uni_SpecialSkills ||
          !Uni_AnyOther ||
          !Uni_Annual ||
          !Uni_NoOfIniti1 ||
          !Uni_NoOfIniti2 ||
          !Uni_Date1 ||
          !Uni_DateDuration ||
          !Uni_Date2 ||
          !Uni_Power ||
          !Uni_TPower ||
          !Uni_ReEnergyS ||
          !Uni_ReEnergyG ||
          !Uni_EnergySup ||
          !Uni_PercentageLED ||
          !Uni_PercentageLight ||
          !Uni_NoOfParticipants ||
          !Uni_NoOfActivities ||
          !Uni_TitlePro ||
          !Uni_NoOfParticipants1 ||
          !Uni_Male ||
          !Uni_Female ||
          !Uni_Yea ||
          !Uni_Total ||
          !Uni_PowRe ||
          !Uni_InitiName ||
          !Uni_DurInitiative ||
          !Uni_Desc29 ||
          !Uni_Link29 ||
          !Uni_Link30 ||
          !Uni_Link31
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Uni_Link29);
      console.log("mm,", Uni_Link30);
      console.log("ooo,", Uni_Link31);
      let obj = {
        Uni_criteria: "0.0.7",
        Uni_Budget: Uni_Budget,
        Uni_Lighting: Uni_Lighting,
        Uni_PhyFac: Uni_PhyFac,
        Uni_Prolift: Uni_Prolift,
        Uni_Ramp: Uni_Ramp,
        Uni_Braille: Uni_Braille,
        Uni_RestRoom: Uni_RestRoom,
        Uni_Scribes: Uni_Scribes,
        Uni_SpecialSkills: Uni_SpecialSkills,
        Uni_AnyOther: Uni_AnyOther,
        Uni_Annual: Uni_Annual,
        Uni_NoOfIniti1: Uni_NoOfIniti1,
        Uni_NoOfIniti2: Uni_NoOfIniti2,
        Uni_Date1: Uni_Date1,
        Uni_DateDuration: Uni_DateDuration,
        Uni_Date2: Uni_Date2,
        Uni_Power: Uni_Power,
        Uni_TPower: Uni_TPower,
        Uni_ReEnergyS: Uni_ReEnergyS,
        Uni_ReEnergyG: Uni_ReEnergyG,
        Uni_EnergySup: Uni_EnergySup,
        Uni_PercentageLED: Uni_PercentageLED,
        Uni_PercentageLight: Uni_PercentageLight,
        Uni_NoOfParticipants: Uni_NoOfParticipants,
        Uni_NoOfActivities: Uni_NoOfActivities,
        Uni_TitlePro: Uni_TitlePro,
        Uni_NoOfParticipants1: Uni_NoOfParticipants1,
        Uni_Male: Uni_Male,
        Uni_Female: Uni_Female,
        Uni_Yea: Uni_Yea,
        Uni_Total: Uni_Total,
        Uni_PowRe: Uni_PowRe,
        Uni_InitiName: Uni_InitiName,
        Uni_DurInitiative: Uni_DurInitiative,
        Uni_Desc29: Uni_Desc29,
        Uni_Link29: Uni_Link29,
        Uni_Link30: Uni_Link30,
        Uni_Link31: Uni_Link31,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getunicriteria7();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <h2
        className="text-center mt-5"
        style={{ fontWeight: "700", color: "#E42127" }}
      >
        University Criteria-07 Form
      </h2>
      <h6 className="text-center" style={{ color: "#E42127" }}>
        (please fill all the details)
      </h6>
      <br />

      <div className="container">
        <div
          className="row-fluid border mb-3"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        >
          <div
            className="row-fluid border"
            style={{
              padding: "30px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <div class=" col-md-6">
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Uni_MainYear7 ? (
                <p>{obj1?.Uni_MainYear7}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setUni_MainYear7(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>

          <div className="container mt-3">
            <div
              className="row-fluid  border "
              style={{
                padding: "50px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <h5>Criteria_07</h5>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Lighting requirements
                  </label>
                  {obj1?.Uni_Lighting ? (
                    <p>{obj1?.Uni_Lighting}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Lighting(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Budget allocated for green initiatives
                  </label>
                  {obj1?.Uni_Budget ? (
                    <p>{obj1?.Uni_Budget}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Budget(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Status of implementation of CBCS / Elective Course System (ECS):
              </h6>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Physical Facilities
                  </label>
                  {obj1?.Uni_PhyFac ? (
                    <p>{obj1?.Uni_PhyFac}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => {
                        setUni_PhyFac(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Provision for Lift
                  </label>
                  {obj1?.Uni_Prolift ? (
                    <p>{obj1?.Uni_Prolift}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => {
                        setUni_Prolift(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Ramp/ Rails
                  </label>
                  {obj1?.Uni_Ramp ? (
                    <p>{obj1?.Uni_Ramp}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => {
                        setUni_Ramp(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Braille Software/Facilities
                  </label>
                  {obj1?.Uni_Braille ? (
                    <p>{obj1?.Uni_Braille}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => {
                        setUni_Braille(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Rest Rooms
                  </label>
                  {obj1?.Uni_RestRoom ? (
                    <p>{obj1?.Uni_RestRoom}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => {
                        setUni_RestRoom(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Scribes for Examination
                  </label>
                  {obj1?.Uni_Scribes ? (
                    <p>{obj1?.Uni_Scribes}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => {
                        setUni_Scribes(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Special skill Development for Differently Abled Students
                  </label>
                  {obj1?.Uni_SpecialSkills ? (
                    <p>{obj1?.Uni_SpecialSkills}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "593px" }}
                      onChange={(e) => {
                        setUni_SpecialSkills(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Any other Similar Facility
                  </label>
                  {obj1?.Uni_AnyOther ? (
                    <p>{obj1?.Uni_AnyOther}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => {
                        setUni_AnyOther(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Annual expenditure excluding salary component of the
                  institution
                </label>
                {obj1?.Uni_Annual ? (
                  <p>{obj1?.Uni_Annual}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_Annual(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives to address locational Advantages and
                  Disadvantages
                </label>
                {obj1?.Uni_NoOfIniti1 ? (
                  <p>{obj1?.Uni_NoOfIniti1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_NoOfIniti1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives taken to Engage with and contribute to
                  Local Community
                </label>
                {obj1?.Uni_NoOfIniti2 ? (
                  <p>{obj1?.Uni_NoOfIniti2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_NoOfIniti2(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Date and Duration of the Initiative Name of the Initiative
                  Issues Addressed
                </label>
                {obj1?.Uni_DateDuration ? (
                  <p>{obj1?.Uni_DateDuration}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_DateDuration(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Power Requirement met by Renewable Energy Sources
                </label>
                {obj1?.Uni_Power ? (
                  <p>{obj1?.Uni_Power}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_Power(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="row">
                {" "}
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Power requirements
                  </label>
                  {obj1?.Uni_TPower ? (
                    <p>{obj1?.Uni_TPower}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_TPower(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Source:
                  </label>
                  {obj1?.Uni_ReEnergyS ? (
                    <p>{obj1?.Uni_ReEnergyS}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_ReEnergyS(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Generated and Used
                  </label>
                  {obj1?.Uni_ReEnergyG ? (
                    <p>{obj1?.Uni_ReEnergyG}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_ReEnergyG(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Energy Supplied to the Grid
                  </label>
                  {obj1?.Uni_EnergySup ? (
                    <p>{obj1?.Uni_EnergySup}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_EnergySup(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Percentage Lighting through LED bulbs
                  </label>
                  {obj1?.Uni_PercentageLED ? (
                    <p>{obj1?.Uni_PercentageLED}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_PercentageLED(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Percentage Lighting through other sources
                  </label>
                  {obj1?.Uni_PercentageLight ? (
                    <p>{obj1?.Uni_PercentageLight}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_PercentageLight(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participating Students and Staff
                  </label>
                  {obj1?.Uni_NoOfParticipants ? (
                    <p>{obj1?.Uni_NoOfParticipants}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_NoOfParticipants(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    No. of Activities:
                  </label>
                  {obj1?.Uni_NoOfActivities ? (
                    <p>{obj1?.Uni_NoOfActivities}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_NoOfActivities(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TTitle of the Program/Activity
                  </label>
                  {obj1?.Uni_TitlePro ? (
                    <p>{obj1?.Uni_TitlePro}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_TitlePro(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participants
                  </label>
                  {obj1?.Uni_NoOfParticipants1 ? (
                    <p>{obj1?.Uni_NoOfParticipants1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_NoOfParticipants1(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Date and Duration:
              </h6>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    From
                  </label>
                  {obj1?.Uni_Date1 ? (
                    <p>{obj1?.Uni_Date1}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Date1(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    To
                  </label>
                  {obj1?.Uni_Date2 ? (
                    <p>{obj1?.Uni_Date2}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Date2(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Number of participants by Gender:
              </h6>
              <div className="row">
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    MALE
                  </label>
                  {obj1?.Uni_Male ? (
                    <p>{obj1?.Uni_Male}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Male(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    FEMALE
                  </label>
                  {obj1?.Uni_Female ? (
                    <p>{obj1?.Uni_Female}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Female(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year
                  </label>
                  {obj1?.Uni_Yea ? (
                    <p>{obj1?.Uni_Yea}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Yea(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>
                  {obj1?.Uni_Total ? (
                    <p>{obj1?.Uni_Total}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Total(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement Renewable
                  </label>
                  {obj1?.Uni_PowRe ? (
                    <p>{obj1?.Uni_PowRe}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_PowRe(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Initiative names
                  </label>
                  {obj1?.Uni_InitiName ? (
                    <p>{obj1?.Uni_InitiName}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_InitiName(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration_of_the_Initiative
                  </label>
                  {obj1?.Uni_DurInitiative ? (
                    <p>{obj1?.Uni_DurInitiative}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_DurInitiative(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj1?.Uni_Desc29 ? (
                    <p>{obj1?.Uni_Desc29}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      rows={3}
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Desc29(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Document Upload Select file to upload
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link29(e.target.files[0]);
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload2">
                  {" "}
                  Document Upload Select file to upload
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload2"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link30(e.target.files[0]);
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Document Upload Select file to upload
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link31(e.target.files[0]);
                  }}
                />
              </div>
              <div className="d-flex  " style={{ float: "right", gap: "10px" }}>
                {add1 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat1();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-warning"
                      onClick={handleShow}
                      style={{ color: "black", marginTop: "15px" }}
                    >
                      Preview
                    </button>
                  </>
                )}{" "}
              </div>
              <br />
            </div>

            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_07</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Lighting requirements
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_Lighting(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_Lighting}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Budget allocated for green initiatives
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_Budget(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_Budget}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Status of implementation of CBCS / Elective Course System
                  (ECS):
                </h6>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Physical Facilities
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        onChange={(e) => {
                          setUni_PhyFac(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Uni_PhyFac}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Provision for Lift
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        onChange={(e) => {
                          setUni_Prolift(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Uni_Prolift}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Ramp/ Rails
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        onChange={(e) => {
                          setUni_Ramp(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Uni_Ramp}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Braille Software/Facilities
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        onChange={(e) => {
                          setUni_Braille(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Uni_Braille}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Rest Rooms
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        onChange={(e) => {
                          setUni_RestRoom(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Uni_RestRoom}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Scribes for Examination
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        onChange={(e) => {
                          setUni_Scribes(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Uni_Scribes}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Special skill Development for Differently Abled Students
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "500px" }}
                        onChange={(e) => {
                          setUni_SpecialSkills(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Uni_SpecialSkills}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Any other Similar Facility
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        onChange={(e) => {
                          setUni_AnyOther(e.target.value);
                        }}
                      >
                        <option selected>--select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Uni_AnyOther}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Annual expenditure excluding salary component of the
                    institution
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Annual(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_Annual}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_NoOfIniti1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_NoOfIniti1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_NoOfIniti2(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_NoOfIniti2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_DateDuration(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_DateDuration}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement met by Renewable Energy Sources
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Power(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_Power}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Power requirements
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_TPower(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_TPower}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Source
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_ReEnergyS(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_ReEnergyS}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Generated and Used
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_ReEnergyG(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_ReEnergyG}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Energy Supplied to the Grid
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_EnergySup(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_EnergySup}</>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Percentage Lighting through other sources
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_PercentageLight(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_PercentageLight}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participating Students and Staff
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_NoOfParticipants(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_NoOfParticipants}</>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Percentage Lighting through LED bulbs
                      </label>
                      <br />
                      {edit1 ? (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_PercentageLED(e.target.value);
                          }}
                        />
                      ) : (
                        <>{Uni_PercentageLED}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        No. of Activities:
                      </label>
                      <br />
                      {edit1 ? (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          onChange={(e) => {
                            setUni_NoOfActivities(e.target.value);
                          }}
                        />
                      ) : (
                        <>{Uni_NoOfActivities}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      TTitle of the Program/Activity
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_TitlePro(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_TitlePro}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participants:
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_NoOfParticipants1(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_NoOfParticipants1}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Date and Duration:
                </h6>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      From
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_Date1(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_Date1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      To
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_Date2(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_Date2}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of participants by Gender
                </h6>
                <div className="row">
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      MALE:
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_Male(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_Male}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      FEMALE
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_Female(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_Female}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_Yea(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_Yea}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_Total(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_Total}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Power Requirement Renewable
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_PowRe(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_PowRe}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Initiative names
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setUni_InitiName(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_InitiName}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration_of_the_Initiative
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_DurInitiative(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_DurInitiative}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description (min 500 words)
                  </label>
                  <br />
                  {edit1 ? (
                    <textarea
                      className="vi_0"
                      rows={3}
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setUni_Desc29(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_Desc29}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document Upload Select file to upload
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload1"
                      accept="image/*"
                      onChange={(e) => {
                        setUni_Link29(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Uni_Link29?.name}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Document Upload Select file to upload
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload2"
                      accept="image/*"
                      onChange={(e) => {
                        setUni_Link30(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Uni_Link30?.name}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Document Upload Select file to upload
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload3"
                      accept="image/*"
                      onChange={(e) => {
                        setUni_Link31(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Uni_Link31?.name}</>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat1();
                    handleClose();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
          <div className="text-center  mb-3">
            <button
              type="submit"
              className="btn btn-success "
              onClick={() => unicriteria7()}
              style={{ color: "white" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uni_Criteria007;
