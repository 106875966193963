import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";

function A_06_623() {
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [content, setContent] = useState([]);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("A-6.2.3");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.Auto_Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
        <table>
          <tr>
            <td>Year of implementation</td>
            <td>${item?.Auto_Criteria06_623?.Auto_yoi1}</td>
          </tr>
          <tr>
            <td>Relevant document</td>
            <td>${item?.Auto_Criteria06_623?.Auto_doc1}</td>
          </tr>
        </table>
      `;
        let tableMarkup2 = `
        <table>
          <tr>
            <td>Year of implementation</td>
            <td>${item?.Auto_Criteria06_623?.Auto_yoi2}</td>
          </tr>
          <tr>
            <td>Relevant document</td>
            <td>${item?.Auto_Criteria06_623?.Auto_doc2}</td>
          </tr>
        </table>
      `;
        let tableMarkup3 = `
        <table>
          <tr>
            <td>Year of implementation</td>
            <td>${item?.Auto_Criteria06_623?.Auto_yoi3}</td>
          </tr>
          <tr>
            <td>Relevant document</td>
            <td>${item?.Auto_Criteria06_623?.Auto_doc3}</td>
          </tr>
        </table>
      `;
        let tableMarkup4 = `
        <table>
          <tr>
            <td>Year of implementation</td>
            <td>${item?.Auto_Criteria06_623?.Auto_yoi4}</td>
          </tr>
          <tr>
            <td>Relevant document</td>
            <td>${item?.Auto_Criteria06_623?.Auto_doc4}</td>
          </tr>
        </table>
      `;
        let obj = {
          Year: item?.Auto_Year,
          Administration: tableMarkup1,
          "Finance and Accounts": tableMarkup2,
          "Student Admissions and Support": tableMarkup3,
          Examinations: tableMarkup4,
          Description: item?.Auto_Criteria06_623?.Auto_desc1,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  //  ===========================================================================================
  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        pricipalDetails?._id === item1?.addedByPri &&
        item1?.approve === "true" &&
        selectedYear === item1?.Auto_Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 6 });
  };

  // =================================================================
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/PrinipalAdmin/Autocri06_623"
      );
      if (res.status === 200) {
        setContent(res.data.getAuto_C623);
        setdata1(res.data.getAuto_C623);
        setfilterPdf(res.data.getAuto_C623);
      }
    } catch (error) {
      console.log(error);
      setContent(error.response.data.getAuto_C623);
    }
  };
  console.log("content", content);

  // =================================================================
  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);

  console.log("principal", pricipalDetails?._id);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                Autonomous_Criteria-06-623
              </span>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>

            <div className=" col-lg-2 lab  text-end">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = content?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Auto_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id === item1?.addedByPri &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Auto_Year &&
                      (SearchItem === "" ||
                        item1?.Auto_Year?.includes(SearchItem) ||
                        item1?.Auto_Criteria06_623?.Auto_yoi1?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria06_623?.Auto_yoi2?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria06_623?.Auto_yoi3?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria06_623?.Auto_yoi4?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria06_623?.Auto_desc1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });

                  if (arr1?.length > 0) {
                    navigate("/PDFprintA6_623", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4 align-items-center">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/autocriteria05">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/A_06_623")}
                >
                  <Button
                    variant="warning"
                    style={{ backgroundColor: "#ffc107" }}
                    className="criteriabtn-0 btn-sub1"
                  >
                    6.2.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/A_06_632")}
                >
                  <Button className="criteriabtn btn-sub">6.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_06_633")}
                >
                  <Button className="criteriabtn btn-sub">6.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_06_634")}
                >
                  <Button className="criteriabtn btn-sub">6.3.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_06_642")}
                >
                  <Button className="criteriabtn btn-sub">6.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_06_653")}
                >
                  <Button className="criteriabtn btn-sub">6.5.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Administration</th>
                  <th>Finance and Accounts</th>
                  <th>Student Admissions and Support</th>
                  <th>Examinations</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Auto_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id === item1?.addedByPri &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Auto_Year &&
                      (SearchItem === "" ||
                        item1?.Auto_Year?.includes(SearchItem) ||
                        item1?.Auto_Criteria06_623?.Auto_yoi1?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria06_623?.Auto_yoi2?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria06_623?.Auto_yoi3?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria06_623?.Auto_yoi4?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria06_623?.Auto_desc1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?.Auto_Year}</td>
                        <td>
                          <Table responsive>
                            <thead>
                              <th style={{ border: "1px solid black" }}>
                                Year of implementation
                              </th>
                              <th style={{ border: "1px solid black" }}>
                                Relevant document
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ border: "1px solid black" }}>
                                  {item?.Auto_Criteria06_623?.Auto_yoi1}
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                  <AiOutlineEye
                                    onClick={() => {
                                      setView(item);
                                      handleShow1();
                                    }}
                                    fontSize={20}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          <Table responsive>
                            <thead>
                              <th style={{ border: "1px solid black" }}>
                                Year of implementation
                              </th>
                              <th style={{ border: "1px solid black" }}>
                                Relevant document
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ border: "1px solid black" }}>
                                  {item?.Auto_Criteria06_623?.Auto_yoi2}
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                  <AiOutlineEye
                                    onClick={() => {
                                      setView(item);
                                      handleShow2();
                                    }}
                                    fontSize={20}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          <Table responsive>
                            <thead>
                              <th style={{ border: "1px solid black" }}>
                                Year of implementation
                              </th>
                              <th style={{ border: "1px solid black" }}>
                                Relevant document
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ border: "1px solid black" }}>
                                  {item?.Auto_Criteria06_623?.Auto_yoi3}
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                  <AiOutlineEye
                                    onClick={() => {
                                      setView(item);
                                      handleShow3();
                                    }}
                                    fontSize={20}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          <Table responsive>
                            <thead>
                              <th style={{ border: "1px solid black" }}>
                                Year of implementation
                              </th>
                              <th style={{ border: "1px solid black" }}>
                                Relevant document
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ border: "1px solid black" }}>
                                  {item?.Auto_Criteria06_623?.Auto_yoi4}
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                  <AiOutlineEye
                                    onClick={() => {
                                      setView(item);
                                      handleShow4();
                                    }}
                                    fontSize={20}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          {item?.Auto_Criteria06_623?.Auto_desc1?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow10();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Body>
              <div>{View?.Auto_Criteria06_623?.Auto_desc1}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_623?.Auto_doc1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_623?.Auto_doc2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_623?.Auto_doc3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_623?.Auto_doc4}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default A_06_623;
