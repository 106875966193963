import React from "react";

const UG1122aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of Add on /Certificate programs offered</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria01_122?.CertiName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Course Code</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_122?.CourseCode}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of offering</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_122?.YOfOffering}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>No. of times offered during the same year</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_122?.NoOfTimes}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Duration of course</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_122?.CourseDuration}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Number of students enrolled in the year </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_122?.NoOfStudents}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Number of Students completing the course in the year</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_122?.CourseCompletion}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria01_122?.Desc3}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria01/${item?.Criteria01_122?.Link3}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG1122aqar;
