import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

const PgCriteria6 = () => {
  const navigate = useNavigate();

  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [remark, setremark] = useState("");
  const [Value, setValue] = useState({});
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  //======== Criteria 6.1.1 =============
  const [show611, setShow611] = useState(false);
  const handleClose611 = () => setShow611(false);
  const handleShow611 = () => setShow611(true);

  const [show601, setShow601] = useState(false);
  const handleClose601 = () => setShow601(false);
  const handleShow601 = () => setShow601(true);

  const [show6001, setShow6001] = useState(false);
  const handleClose6001 = () => setShow6001(false);
  const handleShow6001 = () => setShow6001(true);

  //======== Criteria 6.1.2 =============
  const [show612, setShow612] = useState(false);
  const handleClose612 = () => setShow612(false);
  const handleShow612 = () => setShow612(true);

  const [show6121, setShow6121] = useState(false);
  const handleClose6121 = () => setShow6121(false);
  const handleShow6121 = () => setShow6121(true);

  const [show6122, setShow6122] = useState(false);
  const handleClose6122 = () => setShow6122(false);
  const handleShow6122 = () => setShow6122(true);

  const [show6123, setShow6123] = useState(false);
  const handleClose6123 = () => setShow6123(false);
  const handleShow6123 = () => setShow6123(true);

  const [show6124, setShow6124] = useState(false);
  const handleClose6124 = () => setShow6124(false);
  const handleShow6124 = () => setShow6124(true);

  const [show6125, setShow6125] = useState(false);
  const handleClose6125 = () => setShow6125(false);
  const handleShow6125 = () => setShow6125(true);

  const [show6126, setShow6126] = useState(false);
  const handleClose6126 = () => setShow6126(false);
  const handleShow6126 = () => setShow6126(true);

  // 623

  const [Year, setYear] = useState("");
  const [PG_yoi1, setPG_yoi1] = useState("");
  const [PG_yoi2, setPG_yoi2] = useState("");
  const [PG_yoi3, setPG_yoi3] = useState("");
  const [PG_yoi4, setPG_yoi4] = useState("");
  const [PG_doc1, setPG_doc1] = useState("");
  const [PG_doc2, setPG_doc2] = useState("");
  const [PG_doc3, setPG_doc3] = useState("");
  const [PG_doc4, setPG_doc4] = useState("");
  const [PG_desc1, setPG_desc1] = useState("");

  // 632
  const [PG_Not, setPG_Not] = useState("");
  const [PG_Asr, setPG_Asr] = useState("");
  const [PG_NOC, setPG_NOC] = useState("");
  const [PG_Nop, setPG_Nop] = useState("");
  const [PG_desc2, setPG_desc2] = useState("");
  const [PG_doc5, setPG_doc5] = useState("");

  //============= criteria 633 =============== //

  const [PG_Sdate, setPG_Sdate] = useState("");
  const [PG_Edate, setPG_Edate] = useState("");
  const [PG_titlep, setPG_titlep] = useState("");
  const [PG_Nfp, setPG_Nfp] = useState("");
  const [PG_doc6, setPG_doc6] = useState("");
  const [PG_desc3, setPG_desc3] = useState("");

  //============= criteria 634 =============== //

  const [PG_Sdate1, setPG_Sdate1] = useState("");
  const [PG_Edate1, setPG_Edate1] = useState("");
  const [PG_namet, setPG_namet] = useState("");
  const [PG_prgm50, setPG_prgm50] = useState("");
  const [PG_doc7, setPG_doc7] = useState("");
  const [PG_desc4, setPG_desc4] = useState("");

  //============= criteria 642 =============== //

  const [PG_pgrant, setPG_pgrant] = useState("");
  const [PG_Fundg, setPG_Fundg] = useState("");
  const [PG_namefgov, setPG_namefgov] = useState("");
  const [PG_doc8, setPG_doc8] = useState("");
  const [PG_desc5, setPG_desc5] = useState("");

  //============= criteria 653 =============== //

  const [PG_Sdate2, setPG_Sdate2] = useState("");
  const [PG_Edate2, setPG_Edate2] = useState("");
  const [PG_nirfs, setPG_nirfs] = useState("");
  const [PG_aaa, setPG_aaa] = useState("");
  const [PG_nba, setPG_nba] = useState("");
  const [PG_cswq, setPG_cswq] = useState("");
  const [PG_iaa, setPG_iaa] = useState("");
  const [PG_doc9, setPG_doc9] = useState("");
  const [PG_desc6, setPG_desc6] = useState("");

  //  ===============================================

  const [edit1, setedit1] = useState();
  const [edit2, setedit2] = useState();
  const [edit3, setedit3] = useState();
  const [edit4, setedit4] = useState();
  const [edit5, setedit5] = useState();
  const [edit6, setedit6] = useState();

  // ================================================

  const editCriteria06_623 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/PrinipalAdmin/editCriteriaPG06_623/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_yoi1: PG_yoi1,
          PG_yoi2: PG_yoi2,
          PG_yoi3: PG_yoi3,
          PG_yoi4: PG_yoi4,
          PG_doc1: PG_doc1,
          PG_doc2: PG_doc2,
          PG_doc3: PG_doc3,
          PG_doc4: PG_doc4,
          PG_desc1: PG_desc1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria06Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_632 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/PrinipalAdmin/editCriteriaPG06_632/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Not: PG_Not,
          PG_Asr: PG_Asr,
          PG_NOC: PG_NOC,
          PG_Nop: PG_Nop,
          PG_desc2: PG_desc2,
          PG_doc5: PG_doc5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria06Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_633 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/PrinipalAdmin/editCriteriaPG06_633/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Sdate: PG_Sdate,
          PG_Edate: PG_Edate,
          PG_titlep: PG_titlep,
          PG_Nfp: PG_Nfp,
          PG_doc6: PG_doc6,
          PG_desc3: PG_desc3,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria06Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_634 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/PrinipalAdmin/editCriteriaPG06_634/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Sdate1: PG_Sdate1,
          PG_Edate1: PG_Edate1,
          PG_namet: PG_namet,
          PG_prgm50: PG_prgm50,
          PG_doc7: PG_doc7,
          PG_desc4: PG_desc4,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria06Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_642 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/PrinipalAdmin/editCriteriaPG06_642/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_pgrant: PG_pgrant,
          PG_Fundg: PG_Fundg,
          PG_namefgov: PG_namefgov,
          PG_doc8: PG_doc8,
          PG_desc5: PG_desc5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria06Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_653 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/PrinipalAdmin/editCriteriaPG06_653/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Sdate2: PG_Sdate2,
          PG_Edate2: PG_Edate2,
          PG_nirfs: PG_nirfs,
          PG_aaa: PG_aaa,
          PG_nba: PG_nba,
          PG_cswq: PG_cswq,
          PG_iaa: PG_iaa,
          PG_doc9: PG_doc9,
          PG_desc6: PG_desc6,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria06Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  //  ===============================================

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // ===========================================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/pgadmin/approveCriteriaPG06/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria06Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const pgCriteria06Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/pgadmin/getpgcri6"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria06);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria06);
    }
  };

  // =================================================================

  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      pgCriteria06Details();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-8 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                PostGraduate_Criteria-06
              </span>
            </div>

            <div className=" col-lg-4 lab text-end ">
              <Link to="/pgcriteria6">
                <button>
                  Add Criteria 6{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0 ">
            <div className=" col-lg-3 do-sear d-flex p-0  ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeFrom}
                picker="year"
                placeholder="Select Year (from)"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                picker="year"
                placeholder="Select Year (to)"
                className="vi_0"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className="">
            <Nav defaultActiveKey="/pgcriteria06">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/PG_06_611")}
                >
                  <Button className="criteriabtn  btn-sub">6.1.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/PG_06_612")}
                >
                  <Button className="criteriabtn  btn-sub">6.1.2</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/PG_06_623")}
                >
                  <Button className="criteriabtn  btn-sub">6.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/PG_06_623")}
                >
                  <Button className="criteriabtn  btn-sub">6.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_06_633")}
                >
                  <Button className="criteriabtn  btn-sub">6.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_06_634")}
                >
                  <Button className="criteriabtn  btn-sub">6.3.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_06_642")}
                >
                  <Button className="criteriabtn  btn-sub">6.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/PG_06_653")}
                >
                  <Button className="criteriabtn btn-sub">6.5.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", minWidth: "12000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>
                  {/* Criteria 06 6.1.1 */}
                  <th>Criteria</th>
                  <th>Vision_Mission</th>
                  <th>Vision_Mission_Statement</th>
                  <th>Vision_Mission_2022-23</th>

                  {/* Criteria 06 6.1.2 */}
                  <th>Criteria</th>
                  <th>Participative management</th>
                  <th>Acadamic monitoring committee</th>
                  <th>Office Order</th>
                  <th>Decentralisation_20-21</th>
                  <th>Decentralisation_21-22</th>
                  <th>Decentralisation_2022-23</th>
                  <th>Academic Monitoring Committee_2022-23</th>
                  {/* 6.2.3 */}
                  <th>Criteria</th>
                  <th>Administration</th>
                  <th>Finance and Accounts</th>
                  <th>Student Admissions and Support</th>
                  <th>Examinations</th>
                  <th>Description</th>

                  {/* 6.3.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher</th>
                  <th>Amount of support received (in INR)</th>
                  <th>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </th>
                  <th>
                    Name of the professional body for which membership fee is
                    provided
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 6.3.3 */}
                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>
                    Title of the professional development/ administrative
                    training programs organised for teaching staff (Professional
                    development/ administrative training programs)
                  </th>
                  <th>No. of participants</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 6.3.4 */}
                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>Name of teacher who attended</th>
                  <th>Title of the program</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 6.4.2 */}
                  <th>Criteria</th>
                  <th>Purpose of the Grant</th>
                  <th>Funds/ Grants received (INR in lakhs)</th>
                  <th>
                    Name of the non government funding agencies/ individuals
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 6.5.3 */}
                  <th>Criteria</th>
                  <th>
                    Orientation programme on quality issues for teachers and
                    students
                  </th>
                  <th>Participation in NIRF along with Status</th>
                  <th>
                    Academic Administrative Audit (AAA) and follow up action
                  </th>
                  <th>
                    NBA or any other certification received with program
                    specifications
                  </th>
                  <th>Conferences, Seminars, Workshops on quality conducted</th>
                  <th>
                    Collaborative quality initiatives with other institution(s)
                    (Provide name of the institution and activity)
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id === item1?.addedByPri &&
                    item1?.approve === "Pending" &&
                    (SearchItem === "" ||
                      item1?.Year?.toString()?.includes(SearchItem) ||
                      item1?.PG_Criteria06_623?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_yoi1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_yoi2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_yoi3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_yoi4?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_632?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_632?.PG_Not?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_632?.PG_Asr?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_632?.PG_NOC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_632?.PG_Nop?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_632?.PG_desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_633?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria06_633?.PG_Sdate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria06_633?.PG_Edate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria06_633?.PG_titlep?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_633?.PG_Nfp?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_633?.PG_desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_634?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria06_634?.PG_Sdate1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria06_634?.PG_Edate1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria06_634?.PG_namet?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_634?.PG_prgm50?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_634?.PG_desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_642?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_642?.PG_pgrant?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_642?.PG_Fundg?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_642?.PG_namefgov?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_642?.PG_desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria06_653?.PG_Sdate3)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria06_653?.PG_Edate3)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria06_653?.PG_nirfs?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_aaa?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_isoc?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_nba?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_cswq?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_iaa?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                }).map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{item?._id}</td>
                      <td>{item?.Year}</td>

                      <td>{item?.PG_Criteria06_611?.criteria}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow611();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow601();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6001();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_612?.criteria}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow612();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6121();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6122();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6123();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6124();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6125();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6126();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_623?.PG_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria06_623?.PG_yoi1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow1();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria06_623?.PG_yoi2}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow2();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria06_623?.PG_yoi3}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow3();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria06_623?.PG_yoi4}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow4();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>

                      <td>
                        {item?.PG_Criteria06_623?.PG_desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>{item?.PG_Criteria06_632?.PG_criteria}</td>
                      <td>{item?.PG_Criteria06_632?.PG_Not}</td>
                      <td>{item?.PG_Criteria06_632?.PG_Asr}</td>
                      <td>{item?.PG_Criteria06_632?.PG_NOC}</td>
                      <td>{item?.PG_Criteria06_632?.PG_Nop}</td>

                      <td>
                        {item?.PG_Criteria06_632?.PG_desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_633?.PG_criteria}</td>
                      <td>
                        {moment(item?.PG_Criteria06_633?.PG_Sdate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.PG_Criteria06_633?.PG_Edate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria06_633?.PG_titlep}</td>
                      <td>{item?.PG_Criteria06_633?.PG_Nfp}</td>

                      <td>
                        {item?.PG_Criteria06_633?.PG_desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_634?.PG_criteria}</td>
                      <td>
                        {moment(item?.PG_Criteria06_634?.PG_Sdate1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.PG_Criteria06_634?.PG_Edate1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria06_634?.PG_namet}</td>
                      <td>{item?.PG_Criteria06_634?.PG_prgm50}</td>

                      <td>
                        {item?.PG_Criteria06_634?.PG_desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_642?.PG_criteria}</td>
                      <td>{item?.PG_Criteria06_642?.PG_pgrant}</td>
                      <td>{item?.PG_Criteria06_642?.PG_Fundg}</td>
                      <td>{item?.PG_Criteria06_642?.PG_namefgov}</td>

                      <td>
                        {item?.PG_Criteria06_642?.PG_desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_653?.PG_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Date (from)</th>
                            <th>Date (to)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {moment(
                                  item?.PG_Criteria06_653?.PG_Sdate2
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {moment(
                                  item?.PG_Criteria06_653?.PG_Edate2
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.PG_Criteria06_653?.PG_nirfs}</td>
                      <td>{item?.PG_Criteria06_653?.PG_aaa}</td>
                      <td>{item?.PG_Criteria06_653?.PG_nba}</td>
                      <td>{item?.PG_Criteria06_653?.PG_cswq}</td>
                      <td>{item?.PG_Criteria06_653?.PG_iaa}</td>
                      <td>
                        {item?.PG_Criteria06_653?.PG_desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Criteria 6.1.1 Modal */}

          <Modal show={show611} onHide={handleClose611}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_611?.VisionMission && (
                    <>
                      {View?.PG_Criteria06_611?.VisionMission.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_611?.VisionMission}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_611?.VisionMission}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_611?.VisionMission}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show601} onHide={handleClose601}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_611?.VisionMissionStatement && (
                    <>
                      {View?.PG_Criteria06_611?.VisionMissionStatement.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_611?.VisionMissionStatement}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_611?.VisionMissionStatement}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_611?.VisionMissionStatement}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6001} onHide={handleClose6001}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_611?.VisionMissionyesr && (
                    <>
                      {View?.PG_Criteria06_611?.VisionMissionyesr.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_611?.VisionMissionyesr}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_611?.VisionMissionyesr}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_611?.VisionMissionyesr}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={show612} onHide={handleClose612}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_612?.ParticipativeMng && (
                    <>
                      {View?.PG_Criteria06_612?.ParticipativeMng.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.ParticipativeMng}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.ParticipativeMng}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.ParticipativeMng}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6121} onHide={handleClose6121}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_612?.AcadamicMont && (
                    <>
                      {View?.PG_Criteria06_612?.AcadamicMont.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.AcadamicMont}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.AcadamicMont}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.AcadamicMont}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6122} onHide={handleClose6122}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_612?.OficeOrder && (
                    <>
                      {View?.PG_Criteria06_612?.OficeOrder.endsWith(".pdf") ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.OficeOrder}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.OficeOrder}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.OficeOrder}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6123} onHide={handleClose6123}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_612?.Decentralisation1 && (
                    <>
                      {View?.PG_Criteria06_612?.Decentralisation1.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation1}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation1}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation1}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6124} onHide={handleClose6124}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_612?.Decentralisation2 && (
                    <>
                      {View?.PG_Criteria06_612?.Decentralisation2.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation2}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation2}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation2}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6125} onHide={handleClose6125}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_612?.Decentralisation3 && (
                    <>
                      {View?.PG_Criteria06_612?.Decentralisation3.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation3}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation3}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation3}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6126} onHide={handleClose6126}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.PG_Criteria06_612?.Decentralisation3 && (
                    <>
                      {View?.PG_Criteria06_612?.Decentralisation3.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation3}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation3}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_612?.Decentralisation3}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.PG_Criteria06_623?.PG_desc1}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.PG_Criteria06_632?.PG_desc2}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.PG_Criteria06_633?.PG_desc3}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose12}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.PG_Criteria06_634?.PG_desc4}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.PG_Criteria06_642?.PG_desc5}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose14}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.PG_Criteria06_653?.PG_desc6}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_623?.PG_doc1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_623?.PG_doc2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_623?.PG_doc3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_623?.PG_doc4}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_632?.PG_doc5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_633?.PG_doc6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_634?.PG_doc7}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_642?.PG_doc8}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_653?.PG_doc9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Add Model */}
          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#" }}>
                Under Graduate Criteria 06{" "}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>
                <div className="row ">
                  <h6>Administration</h6>
                  <div className="col-lg-6">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Value?.PG_Criteria06_623?.PG_yoi1}
                            className="vi_0"
                            onChange={(e) => {
                              setPG_yoi1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Value?.PG_Criteria06_623?.PG_yoi1}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="lab mb-4">
                      <label>Upload the relevant document</label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload1"
                            className="vi_0"
                            placeholder={PG_doc1}
                            onChange={(e) => setPG_doc1(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{PG_doc1?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <h6>Finance and Accounts</h6>

                  <div className="col-lg-6">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Value?.PG_Criteria06_623?.PG_yoi2}
                            className="vi_0"
                            onChange={(e) => setPG_yoi2(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Value?.PG_Criteria06_623?.PG_yoi2}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="lab mb-4">
                      <label>Upload the relevant document</label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload2"
                            className="vi_0"
                            placeholder={PG_doc2}
                            onChange={(e) => setPG_doc2(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{PG_doc2?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <h6>Student Admissions and Support</h6>

                  <div className="col-lg-6">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Value?.PG_Criteria06_623.PG_yoi3}
                            className="vi_0"
                            onChange={(e) => setPG_yoi3(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Value?.PG_Criteria06_623?.PG_yoi3}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="lab mb-4">
                      <label>Upload the relevant document</label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload3"
                            className="vi_0"
                            placeholder={PG_doc3}
                            onChange={(e) => setPG_doc3(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{PG_doc3?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <h6>Examinations</h6>
                  <div className="col-lg-6">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Value?.PG_Criteria06_623?.PG_yoi4}
                            className="vi_0"
                            onChange={(e) => setPG_yoi4(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Value?.PG_Criteria06_623?.PG_yoi4}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="lab mb-4">
                      <label>Upload the relevant document</label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload4"
                            className="vi_0"
                            placeholder={PG_doc4}
                            onChange={(e) => setPG_doc4(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{PG_doc4?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3 ">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description with minimum 500 Characters
                  </label>
                  {edit1 ? (
                    <>
                      <textarea
                        className="name"
                        type="text"
                        placeholder={Value?.PG_Criteria06_623?.PG_desc1}
                        onChange={(e) => setPG_desc1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria06_623?.PG_desc1}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => setedit1(true)}
                      >
                        Edit
                      </button>
                      <Button
                        variant="warning"
                        style={{ color: "black" }}
                        onClick={(e) => {
                          editCriteria06_623(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="row ">
                  <div className="col-lg-12">
                    <div className="lab mb-3">
                      <label>Name of the teacher</label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Value?.PG_Criteria06_632?.PG_Not}
                            className="vi_0"
                            onChange={(e) => setPG_Not(e.target.value)}
                          />{" "}
                        </>
                      ) : (
                        <>{Value?.PG_Criteria06_632?.PG_Not}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-3">
                      <label>Amount of support received (in INR)</label>
                      <br />

                      {edit2 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Value?.PG_Criteria06_632?.PG_Asr}
                            className="vi_0"
                            onChange={(e) => setPG_Asr(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Value?.PG_Criteria06_632?.PG_Asr}</>
                      )}
                    </div>
                  </div>
                </div>

                <div className="lab mb-4">
                  <label>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={Value?.PG_Criteria06_632?.PG_NOC}
                        className="vi_0"
                        onChange={(e) => setPG_NOC(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria06_632?.PG_NOC}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    Name of the professional body for which membership fee is
                    provided
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={Value?.PG_Criteria06_632?.PG_Nop}
                        className="vi_0"
                        onChange={(e) => setPG_Nop(e.target.value)}
                      />{" "}
                    </>
                  ) : (
                    <>{Value?.PG_Criteria06_632?.PG_Nop}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description with minimum 500 Characters
                  </label>
                  {edit2 ? (
                    <>
                      <textarea
                        type="text"
                        id="name"
                        placeholder={Value?.PG_Criteria06_632?.PG_desc2}
                        onChange={(e) => {
                          setPG_desc2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria06_632?.PG_desc2}</>
                  )}
                </div>

                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Upload the relevant document</label>
                    <br />
                    {edit2 ? (
                      <input
                        type="file"
                        placeholder=" "
                        className="vi_0"
                        onChange={(e) => setPG_doc5(e.target.files[0])}
                      />
                    ) : (
                      <>{PG_doc5?.name}</>
                    )}
                  </div>
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => setedit2(true)}
                      >
                        Edit
                      </button>
                      <Button
                        variant="warning"
                        style={{ color: "black" }}
                        onClick={(e) => {
                          editCriteria06_632(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="row">
                  <div className=" col-lg-6 lab mb-4">
                    <label>Date (from) (DD-MM-YYYY)</label>
                    <br />
                    {edit3 ? (
                      <input
                        type="date"
                        placeholder={Value?.PG_Criteria06_633?.PG_Sdate}
                        classNameeditCriteria06_633="vi_0"
                        onChange={(e) => setPG_Sdate(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_633?.PG_Sdate}</>
                    )}
                  </div>
                  <div className=" col-lg-6 lab mb-4">
                    <label>Date (to) (DD-MM-YYYY)</label>
                    <br />
                    {edit3 ? (
                      <input
                        type="date"
                        placeholder={Value?.PG_Criteria06_633?.setPG_Edate}
                        className="vi_0"
                        onChange={(e) => setPG_Edate(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_633?.setPG_Edate}</>
                    )}
                  </div>

                  <div className=" col-lg-12 lab mb-4">
                    <label>
                      {" "}
                      Title of the professional development/ administrative
                      training programs organised for teaching staff
                      (Professional development/ administrative training
                      programs)
                    </label>
                    <br />
                    {edit3 ? (
                      <input
                        type="text"
                        placeholder={Value?.PG_Criteria06_633?.PG_titlep}
                        className="vi_0"
                        onChange={(e) => setPG_titlep(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_633?.PG_titlep}</>
                    )}
                  </div>

                  <div className=" col-lg-6 lab mb-4">
                    <label>No. of participants</label>
                    <br />
                    {edit3 ? (
                      <input
                        type="number"
                        placeholder={Value?.PG_Criteria06_633?.PG_Nfp}
                        className="vi_0"
                        onChange={(e) => setPG_Nfp(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_633?.PG_Nfp}</>
                    )}
                  </div>

                  <div className=" col-lg-6 lab mb-4">
                    <label>Upload the relevant document</label>
                    <br />
                    {edit3 ? (
                      <input
                        type="file"
                        placeholder={PG_doc6}
                        className="vi_0"
                        id="upload6"
                        onChange={(e) => setPG_doc6(e.target.files[0])}
                      />
                    ) : (
                      <>{PG_doc6?.name}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3 ">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description with minimum 500 Characters
                    </label>
                    {edit3 ? (
                      <textarea
                        className="vi_0"
                        type="text"
                        placeholder={Value?.PG_Criteria06_633?.PG_desc3}
                        onChange={(e) => {
                          setPG_desc3(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_633?.PG_desc3}</>
                    )}
                  </div>
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => setedit3(true)}
                      >
                        Edit
                      </button>
                      <Button
                        variant="warning"
                        style={{ color: "black" }}
                        onClick={(e) => {
                          editCriteria06_633(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="row">
                  <div className=" col-lg-6 lab mb-4">
                    <label>Date (from) (DD-MM-YYYY)</label>
                    <br />
                    {edit4 ? (
                      <input
                        type="date"
                        className="vi_0"
                        placeholder={Value?.PG_Criteria06_634?.PG_Sdate1}
                        onChange={(e) => setPG_Sdate1(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_634?.PG_Sdate1}</>
                    )}
                  </div>
                  <div className=" col-lg-6 lab mb-4">
                    <label>Date (to) (DD-MM-YYYY)</label>
                    <br />
                    {edit4 ? (
                      <input
                        type="date"
                        placeholder={Value?.PG_Criteria06_634?.PG_Edate1}
                        className="vi_0"
                        onChange={(e) => setPG_Edate1(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_634?.PG_Edate1}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className=" col-lg-6 lab mb-4">
                    <label>Name of teacher who attended</label>
                    <br />
                    {edit4 ? (
                      <input
                        type="text"
                        placeholder={Value?.PG_Criteria06_634?.PG_namet}
                        className="vi_0"
                        onChange={(e) => setPG_namet(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_634?.PG_namet}</>
                    )}
                  </div>
                  <div className=" col-lg-6 lab mb-4">
                    <label>Title of the program</label>
                    <br />
                    {edit4 ? (
                      <input
                        type="text"
                        placeholder={Value?.PG_Criteria06_634?.PG_prgm50}
                        className="vi_0"
                        onChange={(e) => setPG_prgm50(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_634?.PG_prgm50}</>
                    )}
                  </div>
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description with minimum 500 Characters
                  </label>
                  {edit4 ? (
                    <textarea
                      className="name"
                      type="text"
                      id="name"
                      placeholder={Value?.PG_Criteria06_634?.PG_desc4}
                      onChange={(e) => {
                        setPG_desc4(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria06_634?.PG_desc4}</>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>Upload the relevant document</label>
                  <br />
                  {edit4 ? (
                    <input
                      type="file"
                      className="vi_0"
                      placeholder={PG_doc7}
                      onChange={(e) => setPG_doc7(e.target.files[0])}
                    />
                  ) : (
                    <>{PG_doc7.name}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => setedit4(true)}
                      >
                        Edit
                      </button>
                      <Button
                        variant="warning"
                        style={{ color: "black" }}
                        onClick={(e) => {
                          editCriteria06_634(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="row">
                  <div className=" col-lg-6 lab mb-4">
                    <label>Purpose of the Grant</label>
                    <br />
                    {edit5 ? (
                      <input
                        type="text"
                        placeholder={Value?.PG_Criteria06_642?.PG_pgrant}
                        className="vi_0"
                        onChange={(e) => setPG_pgrant(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_642?.PG_pgrant}</>
                    )}
                  </div>
                  <div className=" col-lg-6 lab mb-4">
                    <label> Funds/ Grants received (INR in lakhs)</label>
                    <br />
                    {edit5 ? (
                      <input
                        type="text"
                        placeholder={Value?.PG_Criteria06_642?.PG_Fundg}
                        className="vi_0"
                        onChange={(e) => setPG_Fundg(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_642?.PG_Fundg}</>
                    )}
                  </div>
                </div>

                <div className="  lab mb-4">
                  <label>
                    {" "}
                    Name of the non government funding agencies/ individuals
                  </label>
                  <br />
                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Value?.PG_Criteria06_642?.PG_namefgov}
                      className="vi_0"
                      onChange={(e) => setPG_namefgov(e.target.value)}
                    />
                  ) : (
                    <>{Value?.PG_Criteria06_642?.PG_namefgov}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description with minimum 500 Characters
                  </label>
                  {edit5 ? (
                    <textarea
                      className="name"
                      type="text"
                      id="name"
                      placeholder={Value?.PG_Criteria06_642?.PG_desc5}
                      onChange={(e) => {
                        setPG_desc5(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria06_642?.PG_desc5}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    Upload the Audited Statement of Accounts reflecting the
                    receipts
                  </label>
                  <br />
                  {edit5 ? (
                    <input
                      type="file"
                      className="vi_0"
                      onChange={(e) => setPG_doc8(e.target.files[0])}
                    />
                  ) : (
                    <>{PG_doc8.name}</>
                  )}
                </div>

                <div className="text-end mt-4">
                  <Button
                    type="submit"
                    onClick={setedit5}
                    className="m-2"
                    variant="warning "
                    style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                  >
                    Preview
                  </Button>
                  <Button
                    type="submit"
                    onClick={(e) => {
                      editCriteria06_642(e, Value?._id);
                      handleClose();
                    }}
                    style={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Add
                  </Button>
                </div>
              </div>

              <div>
                <div className="lab">
                  <label>
                    Orientation programme on quality issues for teachers and
                    students,{" "}
                  </label>
                </div>

                <div className="row">
                  <div className=" col-lg-6 lab mb-4">
                    <label>Date (from) (DD-MM-YYYY)</label>
                    <br />
                    {edit6 ? (
                      <input
                        type="date"
                        placeholder={Value?.PG_Criteria06_653?.PG_Sdate2}
                        className="vi_0"
                        onChange={(e) => setPG_Sdate2(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_653?.PG_Sdate2}</>
                    )}
                  </div>
                  <div className=" col-lg-6 lab mb-4">
                    <label>Date (to) (DD-MM-YYYY)</label>
                    <br />
                    {edit6 ? (
                      <input
                        type="date"
                        placeholder={Value?.PG_Criteria06_653?.PG_Edate2}
                        className="vi_0"
                        onChange={(e) => setPG_Edate2(e.target.value)}
                      />
                    ) : (
                      <>{Value?.PG_Criteria06_653?.PG_Edate2}</>
                    )}
                  </div>
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label> Participation in NIRF along with Status.</label>
                  <br />
                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Value?.PG_Criteria06_653?.PG_nirfs}
                      className="vi_0"
                      onChange={(e) => setPG_nirfs(e.target.value)}
                    />
                  ) : (
                    <>{Value?.PG_Criteria06_653?.PG_nirfs}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    Academic Administrative Audit (AAA) and follow up action
                  </label>
                  <br />
                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Value?.PG_Criteria06_653?.PG_aaa}
                      className="vi_0"
                      onChange={(e) => setPG_aaa(e.target.value)}
                    />
                  ) : (
                    <>{Value?.PG_Criteria06_653?.PG_aaa}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    {" "}
                    NBA or any other certification received with program
                    specifications.{" "}
                  </label>
                  <br />
                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Value?.PG_Criteria06_653?.PG_nba}
                      className="vi_0"
                      onChange={(e) => setPG_nba(e.target.value)}
                    />
                  ) : (
                    <>{Value?.PG_Criteria06_653?.PG_nba}</>
                  )}
                </div>

                <div className="  lab mb-4">
                  <label>
                    {" "}
                    Conferences, Seminars, Workshops on quality conducted{" "}
                  </label>
                  <br />
                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Value?.PG_Criteria06_653?.PG_cswq}
                      className="vi_0"
                      onChange={(e) => setPG_cswq(e.target.value)}
                    />
                  ) : (
                    <>{Value?.PG_Criteria06_653?.PG_cswq}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    {" "}
                    Collaborative quality initiatives with other institution(s)
                    (Provide name of the institution and activity)
                  </label>
                  <br />
                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Value?.PG_Criteria06_653?.PG_iaa}
                      className="vi_0"
                      onChange={(e) => setPG_iaa(e.target.value)}
                    />
                  ) : (
                    <>{Value?.PG_Criteria06_653?.PG_iaa}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description with minimum 500 Characters
                  </label>
                  {edit6 ? (
                    <textarea
                      className="name"
                      type="text"
                      id="name"
                      onChange={(e) => {
                        setPG_desc6(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria06_653?.PG_desc6}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label> Upload the relevant document</label>
                  <br />

                  {edit6 ? (
                    <>
                      <input
                        type="file"
                        className="vi_0"
                        placeholder={PG_doc9}
                        onChange={(e) => setPG_doc9(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{PG_doc9.name}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => setedit5(true)}
                      >
                        Edit
                      </button>
                      <Button
                        variant="warning"
                        style={{ color: "black" }}
                        onClick={(e) => {
                          editCriteria06_653(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {Value?.approve === "false" ? (
                <></>
              ) : (
                <div>
                  <Button
                    variant="primary"
                    onClick={(e) => approveCriteria(e, true, Value?._id)}
                  >
                    Approve{" "}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => setSmShow(true)}
                    className="me-2"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal>

          {
            // remarks model
          }

          <Modal
            // size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Tell me the reason?..
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                style={{ height: "200px" }}
                onChange={(e) => setremark(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => approveCriteria(e, false, Value?._id)}
              >
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PgCriteria6;
