import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

import exportFromJSON from "export-from-json";
const PG_Criteria03 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);
  const [edit8, setedit8] = useState(false);

  const [PG_ProName, setPG_ProName] = useState("");
  const [PG_PrinciName, setPG_PrinciName] = useState("");
  const [PG_PrinciDept, setPG_PrinciDept] = useState("");
  const [PG_YOfAw, setPG_YOfAw] = useState("");
  const [PG_ASanctioned, setPG_ASanctioned] = useState("");
  const [PG_DProject, setPG_DProject] = useState("");
  const [PG_NFunding, setPG_NFunding] = useState("");
  const [PG_Type, setPG_Type] = useState("");
  const [PG_Desc12, setPG_Desc12] = useState("");
  const [PG_Link12, setPG_Link12] = useState("");

  const [PG_NPati, setPG_NPati] = useState("");
  const [PG_FDate, setPG_FDate] = useState("");
  const [PG_TDate, setPG_TDate] = useState("");
  const [PG_NWorkshop, setPG_NWorkshop] = useState("");
  const [PG_Desc13, setPG_Desc13] = useState("");
  const [PG_Link13, setPG_Link13] = useState("");

  const [PG_TPapaer, setPG_TPapaer] = useState("");
  const [PG_AName, setPG_AName] = useState("");
  const [PG_TDept, setPG_TDept] = useState("");
  const [PG_JName, setPG_JName] = useState("");
  const [PG_PubliYear, setPG_PubliYear] = useState("");
  const [PG_IISSN, setPG_IISSN] = useState("");
  const [PG_UUGC, setPG_UUGC] = useState("");
  const [PG_Desc14, setPG_Desc14] = useState("");
  const [PG_Link14, setPG_Link14] = useState("");
  const [PG_Link15, setPG_Link15] = useState("");

  const [PG_SNo, setPG_SNo] = useState("");
  const [PG_NTea, setPG_NTea] = useState("");
  const [PG_Title1, setPG_Title1] = useState("");
  const [PG_Title2, setPG_Title2] = useState("");
  const [PG_Title3, setPG_Title3] = useState("");
  const [PG_YPub, setPG_YPub] = useState("");
  const [PG_IIISBN, setPG_IIISBN] = useState("");
  const [PG_NPub, setPG_NPub] = useState("");
  const [PG_ConName, setPG_ConName] = useState("");
  const [PG_NatInt, setPG_NatInt] = useState("");
  const [PG_Affi, setPG_Affi] = useState("");
  const [PG_Desc15, setPG_Desc15] = useState("");
  const [PG_Link16, setPG_Link16] = useState("");

  const [PG_ActiN, setPG_ActiN] = useState("");
  const [PG_YearA, setPG_YearA] = useState("");
  const [PG_INAme, setPG_INAme] = useState("");
  const [PG_Bodies, setPG_Bodies] = useState("");
  const [PG_Desc16, setPG_Desc16] = useState("");
  const [PG_Link17, setPG_Link17] = useState("");

  const [PG_NaActi, setPG_NaActi] = useState("");
  const [PG_Organ, setPG_Organ] = useState("");
  const [PG_SchName, setPG_SchName] = useState("");
  const [PG_ActiYear, setPG_ActiYear] = useState("");
  const [PG_NStudents, setPG_NStudents] = useState("");
  const [PG_Desc17, setPG_Desc17] = useState("");
  const [PG_Link18, setPG_Link18] = useState("");

  const [PG_SlNo, setPG_SlNo] = useState("");
  const [PG_Collo, setPG_Collo] = useState("");
  const [PG_NCollo, setPG_NCollo] = useState("");
  const [PG_NameParti, setPG_NameParti] = useState("");
  const [PG_ColloYear, setPG_ColloYear] = useState("");
  const [PG_Dur, setPG_Dur] = useState("");
  const [PG_Desc18, setPG_Desc18] = useState("");
  const [PG_Link19, setPG_Link19] = useState("");

  const [PG_NMou, setPG_NMou] = useState("");
  const [PG_Mou, setPG_Mou] = useState("");
  const [PG_Durat, setPG_Durat] = useState("");
  const [PG_List, setPG_List] = useState("");
  const [PG_Desc19, setPG_Desc19] = useState("");
  const [PG_Link20, setPG_Link20] = useState("");

  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  const [PG_Criteria03, setPG_Criteria03] = useState([]);
  const [Value, setValue] = useState({});

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("PG_Cri3.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
        <table>
          <tr>
            <td>Is it listed in UGC Care list/Scopus/Web of Science/other, mention</td>
            <td>${item?.PG_Criteria03_332?.PG_UUGC}</td>
          </tr>
          <tr>
            <td>Link to website of the Journal</td>
            <td>${item?.PG_Criteria03_332?.PG_Link14}</td>
          </tr>
          <tr>
            <td>Link to article/paper/abstract of the article</td>
            <td>${item?.PG_Criteria03_332?.PG_Link15}</td>
          </tr>
        </table>
      `;
        let obj = {
          //  Criteria 3_311

          PG_Year: item?.PG_Year,
          "Criteria_(3.1.1)": item?.PG_Criteria03_311?.PG_criteria,
          "Name of the Principal Investigator/Co-Investigator":
            item?.PG_Criteria03_311?.PG_PrinciName,
          "Name of the Project/ Endowments, Chairs":
            item?.PG_Criteria03_311?.PG_ProName,
          "Department of Principal Investigator":
            item?.PG_Criteria03_311?.PG_PrinciDept,
          "Year of Award": item?.PG_Criteria03_311?.PG_YOfAw,
          "Amount Sanctioned": item?.PG_Criteria03_311?.PG_ASanctioned,
          "Duration of the project": item?.PG_Criteria03_311?.PG_DProject,
          "Name of the Funding Agency": item?.PG_Criteria03_311?.PG_NFunding,
          "Type (Government/non-Government)": item?.PG_Criteria03_311?.PG_Type,
          "Description(min 500 words)_(3.1.1)":
            item?.PG_Criteria03_311?.PG_Desc12,
          "Upload the relevant document_(3.1.1)":
            item?.PG_Criteria03_311?.PG_Link12,

          // Criteria 3_322

          "Criteria_(3.2.2)": item?.PG_Criteria03_322?.PG_criteria,
          "Number of Participants": item?.PG_Criteria03_322?.PG_NPati,
          "Date (From)": moment(item?.PG_Criteria03_322?.PG_FDate).format(
            "DD-MM-YYYY"
          ),
          "Date (To)": moment(item?.PG_Criteria03_322?.PG_TDate).format(
            "DD-MM-YYYY"
          ),
          "Name of the workshop/ seminar/ conference":
            item?.PG_Criteria03_322?.PG_NWorkshop,
          "Description(min 500 words)_(3.2.2)":
            item?.PG_Criteria03_322?.PG_Desc13,
          "Upload the relevant document_(3.2.2)":
            item?.PG_Criteria03_322?.PG_Link13,

          // Criteria 3 _332
          "Criteria_(3.3.2)": item?.PG_Criteria03_332?.PG_criteria,
          "Title of paper": item?.PG_Criteria03_332?.PG_TPapaer,
          "Name of the author/s": item?.PG_Criteria03_332?.PG_AName,
          "Department of the teacher": item?.PG_Criteria03_332?.PG_TDept,
          "Name of journal": item?.PG_Criteria03_332?.PG_JName,
          "Year of Publication": item?.PG_Criteria03_332?.PG_PubliYear,
          "ISSN number": item?.PG_Criteria03_332?.PG_IISSN,
          "Link to the recognition in UGC enlistment of the Journal /Digital Object Identifier (doi) number":
            tableMarkup1,
          //  "Is it listed in UGC Care list/Scopus/Web of Science/other, mention":
          //  item?.PG_Criteria03_332?.PG_UUGC,
          Description: item?.PG_Criteria03_332?.PG_Desc14,
          // "Relevant document1": item?.PG_Criteria03_332?.PG_Link14,
          // "Relevant document2": item?.PG_Criteria03_332?.PG_Link15,

          // Criteria 3_333
          "Criteria_(3.3.3)": item?.PG_Criteria03_333?.PG_criteria,
          "Sl. No.": item?.PG_Criteria03_333?.PG_SNo,
          "Name of the teacher": item?.PG_Criteria03_333?.PG_NTea,
          "Title of the book/chapters published":
            item?.PG_Criteria03_333?.PG_Title1,
          "Title of the paper": item?.PG_Criteria03_333?.PG_Title2,
          "Title of the proceedings of the conference":
            item?.PG_Criteria03_333?.PG_Title3,
          "Year of publication": item?.PG_Criteria03_333?.PG_YPub,
          "ISBN/ISSN number of the proceeding":
            item?.PG_Criteria03_333?.PG_IIISBN,
          "Name of the publisher": item?.PG_Criteria03_333?.PG_NPub,
          "Name of the conference": item?.PG_Criteria03_333?.PG_ConName,
          "National / International": item?.PG_Criteria03_333?.PG_NatInt,
          "Affiliating Institute at the time of publication ":
            item?.PG_Criteria03_333?.PG_Affi,
          "Description (min 500 words)_(3.3.3)":
            item?.PG_Criteria03_333?.PG_Desc15,
          "Upload the relevant document_(3.3.3)":
            item?.PG_Criteria03_333?.PG_Link16,

          // Criteria  3_342

          "Criteria_(3.4.2)": item?.PG_Criteria03_342?.PG_criteria,
          "Name of the activity": item?.PG_Criteria03_342?.PG_ActiN,
          "Year of award": item?.PG_Criteria03_342?.PG_YearA,
          "Name of the Award/ recognition for Institution":
            item?.PG_Criteria03_342?.PG_INAme,
          "Name of the Awarding government/ government recognised bodies":
            item?.PG_Criteria03_342?.PG_Bodies,
          "Description (min 500 words)_(3.4.2)":
            item?.PG_Criteria03_342?.PG_Desc16,
          "Upload the relevant document_(3.4.2)":
            item?.PG_Criteria03_342?.PG_Link17,

          //  Criteria 3_343
          "Criteria_(3.4.3)": item?.PG_Criteria03_343?.PG_criteria,
          "Name of the Activity": item?.PG_Criteria03_343?.PG_NaActi,
          "Organising unit/ agency/ collaborating agency":
            item?.PG_Criteria03_343?.PG_Organ,
          "Name of the scheme": item?.PG_Criteria03_343?.PG_SchName,
          "Year of the activity": item?.PG_Criteria03_343?.PG_ActiYear,
          "Number of students participated in such activities":
            item?.PG_Criteria03_343?.PG_NStudents,
          "Description(min 500 words)_(3.4.3)":
            item?.PG_Criteria03_343?.PG_Desc17,
          "Upload the relevant document_(3.4.3)":
            item?.PG_Criteria03_343?.PG_Link18,

          // Criteria 3_351
          "Criteria_(3.5.1)": item?.PG_Criteria03_351?.PG_criteria,
          "Si. No.": item?.PG_Criteria03_351?.PG_SlNo,
          "Title of the collaborative activity":
            item?.PG_Criteria03_351?.PG_Collo,
          "   Name of the collaborating / linking agency with contact details":
            item?.PG_Criteria03_351?.PG_NCollo,
          "Name of the participant": item?.PG_Criteria03_351?.PG_NameParti,
          "Year of collaboration": item?.PG_Criteria03_351?.PG_ColloYear,
          Duration: item?.PG_Criteria03_351?.PG_Dur,
          "Description(min 500 words)_(3.5.1)":
            item?.PG_Criteria03_351?.PG_Desc18,
          "Upload the relevant document_(3.5.1)":
            item?.PG_Criteria03_351?.PG_Link19,

          // Criteria 3_352

          "Criteria_(3.5.2)": item?.PG_Criteria03_352?.PG_criteria,
          "   Name of the institution/ industry/ corporate house with whom MoU is signed":
            item?.PG_Criteria03_352?.PG_NMou,
          "Year of signing MOU": item?.PG_Criteria03_352?.PG_Mou,
          Duration1: item?.PG_Criteria03_352?.PG_Durat,
          "  List the actual activities under each MOU and web links year-wise":
            item?.PG_Criteria03_352?.PG_List,
          "Description(min 500 words)_(3.5.2)":
            item?.PG_Criteria03_352?.PG_Desc19,
          "Upload the relevant document_(3.5.2)":
            item?.PG_Criteria03_352?.PG_Link20,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editPG_Criteria03_311 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc12;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc12) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria03_311/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_criteria: "3.1.1 & 3.1.3",
          PG_ProName: PG_ProName,
          PG_PrinciName: PG_PrinciName,
          PG_PrinciDept: PG_PrinciDept,
          PG_YOfAw: PG_YOfAw,
          PG_ASanctioned: PG_ASanctioned,
          PG_DProject: PG_DProject,
          PG_NFunding: PG_NFunding,
          PG_Type: PG_Type,
          PG_Desc12: PG_Desc12,
          PG_Link12: PG_Link12,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri03details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria03_322 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc13;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc13) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria03_322/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_criteria: "3.2.2",
          PG_NPati: PG_NPati,
          PG_FDate: PG_FDate,
          PG_TDate: PG_TDate,
          PG_NWorkshop: PG_NWorkshop,
          PG_Desc13: PG_Desc13,
          PG_Link13: PG_Link13,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri03details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria03_332 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc14;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc14) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria03_332/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_criteria: "3.3.2",
          PG_TPapaer: PG_TPapaer,
          PG_AName: PG_AName,
          PG_TDept: PG_TDept,
          PG_JName: PG_JName,
          PG_PubliYear: PG_PubliYear,
          PG_IISSN: PG_IISSN,
          PG_UUGC: PG_UUGC,
          PG_Desc14: PG_Desc14,
          PG_Link14: PG_Link14,
          PG_Link15: PG_Link15,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri03details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria03_333 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc15;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc15) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria03_333/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_criteria: "3.3.3",
          PG_SNo: PG_SNo,
          PG_NTea: PG_NTea,
          PG_Title1: PG_Title1,
          PG_Title2: PG_Title2,
          PG_Title3: PG_Title3,
          PG_YPub: PG_YPub,
          PG_IIISBN: PG_IIISBN,
          PG_NPub: PG_NPub,
          PG_ConName: PG_ConName,
          PG_NatInt: PG_NatInt,
          PG_Affi: PG_Affi,
          PG_Desc15: PG_Desc15,
          PG_Link16: PG_Link16,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri03details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria03_342 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc16;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc16) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria03_342/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_criteria: "3.4.2",
          PG_ActiN: PG_ActiN,
          PG_YearA: PG_YearA,
          PG_INAme: PG_INAme,
          PG_Bodies: PG_Bodies,
          PG_Desc16: PG_Desc16,
          PG_Link17: PG_Link17,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri03details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria03_343 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc17;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc17) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria03_343/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_criteria: "3.4.3",
          PG_NaActi: PG_NaActi,
          PG_Organ: PG_Organ,
          PG_SchName: PG_SchName,
          PG_ActiYear: PG_ActiYear,
          PG_NStudents: PG_NStudents,
          PG_Desc17: PG_Desc17,
          PG_Link18: PG_Link18,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri03details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editPG_Criteria03_351 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (PG_Desc18) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria03_351/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_criteria: "3.5.1",
          PG_SlNo: PG_SlNo,
          PG_Collo: PG_Collo,
          PG_NCollo: PG_NCollo,
          PG_NameParti: PG_NameParti,
          PG_ColloYear: PG_ColloYear,
          PG_Dur: PG_Dur,
          PG_Desc18: PG_Desc18,
          PG_Link19: PG_Link19,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri03details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editPG_Criteria03_352 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc19) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editPG_Criteria03_352/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_criteria: "3.5.2",
          PG_NMou: PG_NMou,
          PG_Mou: PG_Mou,
          PG_Durat: PG_Durat,
          PG_List: PG_List,
          PG_Desc19: PG_Desc19,
          PG_Link20: PG_Link20,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri03details();
          setedit8(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const getPG_cri03details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria03"
      );
      if (res.status === 200) {
        setPG_Criteria03(res.data.pgcriteria03);
        setdata1(res.data.pgcriteria03);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  useEffect(() => {
    getPG_cri03details();
  }, []);
  console.log(Value);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-03
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/pgcriteria003">
                <button className="btn btn-success">
                  Add Criteria 3{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>
                  <th>Criteria</th>
                  <th>Name of the Project/ Endowments, Chairs</th>
                  <th>Name of the Principal Investigator/Co-Investigator</th>
                  <th>Department of Principal Investigator</th>
                  <th>Year of Award</th>
                  <th>Amount Sanctioned</th>
                  <th>Duration of the project</th>
                  <th>Name of the Funding Agency</th>
                  <th>Type (Government/non-Government)</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Number of Participants</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Name of the workshop/ seminar/ conference</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Department of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>Document 1</th>
                  <th>Document 2</th>

                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>Name of the conference</th>
                  <th>National / International</th>
                  <th>Affiliating Institute at the time of publication </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th> Name of the activity</th>
                  <th>Year of award</th>
                  <th>Name of the Award/ recognition for Institution</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>

                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of the activity</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>

                  <th>Sl. No.</th>
                  <th>Title of the collaborative activity</th>
                  <th>
                    Name of the collaborating / linking agency with contact
                    details
                  </th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the institution/ industry/ corporate house with whom
                    MoU is signed
                  </th>
                  <th>Year of signing MOU</th>
                  <th>Duration</th>
                  <th>
                    List the actual activities under each MOU and web links
                    year-wise
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {PG_Criteria03?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.PG_Criteria03_311?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_311?.PG_ProName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_PrinciName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_PrinciDept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_YOfAw?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_311?.PG_ASanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_311?.PG_DProject?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_311?.PG_NFunding?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_311?.PG_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_322?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_322?.PG_NPati?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria03_322?.PG_FDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria03_322?.PG_TDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria03_322?.PG_NWorkshop?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_322?.PG_Desc13?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_332?.PG_TPapaer?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_AName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_TDept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_JName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_PubliYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_332?.PG_IISSN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_332?.PG_UUGC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_332?.PG_Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_333?.PG_SNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_333?.PG_NTea?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Title1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Title2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Title3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_YPub?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_333?.PG_IIISBN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_333?.PG_NPub?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_ConName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_NatInt?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Affi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_333?.PG_Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_criteria?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_ActiN?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_YearA?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_342?.PG_INAme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_Bodies?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_342?.PG_Desc16?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_343?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_343?.PG_NaActi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_343?.PG_Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_343?.PG_SchName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_343?.PG_ActiYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_343?.PG_NStudents?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_343?.PG_Desc17?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_351?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_351?.PG_SlNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_351?.PG_Collo?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_351?.PG_NCollo?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_351?.PG_NameParti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_351?.PG_ColloYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_351?.PG_Dur?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_351?.PG_Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_352?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_352?.PG_NMou?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_352?.PG_Mou?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_352?.PG_Durat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria03_352?.PG_List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria03_352?.PG_Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.PG_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.PG_Criteria03_311?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_311?.PG_ProName}</td>
                      <td>{item?.PG_Criteria03_311?.PG_PrinciName}</td>
                      <td>{item?.PG_Criteria03_311?.PG_PrinciDept}</td>
                      <td>{item?.PG_Criteria03_311?.PG_YOfAw}</td>
                      <td>{item?.PG_Criteria03_311?.PG_ASanctioned}</td>
                      <td>{item?.PG_Criteria03_311?.PG_DProject}</td>
                      <td>{item?.PG_Criteria03_311?.PG_NFunding}</td>
                      <td>{item?.PG_Criteria03_311?.PG_Type}</td>
                      <td>
                        {item?.PG_Criteria03_311?.PG_Desc12?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.PG_Criteria03_322?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_322?.PG_NPati}</td>
                      <td>
                        {moment(item?.PG_Criteria03_322?.PG_FDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.PG_Criteria03_322?.PG_TDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria03_322?.PG_NWorkshop}</td>
                      <td>
                        {item?.PG_Criteria03_322?.PG_Desc13?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.PG_Criteria03_332?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_332?.PG_TPapaer}</td>
                      <td>{item?.PG_Criteria03_332?.PG_AName}</td>
                      <td>{item?.PG_Criteria03_332?.PG_TDept}</td>
                      <td>{item?.PG_Criteria03_332?.PG_JName}</td>
                      <td>{item?.PG_Criteria03_332?.PG_PubliYear}</td>
                      <td>{item?.PG_Criteria03_332?.PG_IISSN}</td>
                      <td>{item?.PG_Criteria03_332?.PG_UUGC}</td>
                      <td>
                        {item?.PG_Criteria03_332?.PG_Desc14?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.PG_Criteria03_333?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_333?.PG_SNo}</td>
                      <td>{item?.PG_Criteria03_333?.PG_NTea}</td>
                      <td>{item?.PG_Criteria03_333?.PG_Title1}</td>
                      <td>{item?.PG_Criteria03_333?.PG_Title2}</td>
                      <td>{item?.PG_Criteria03_333?.PG_Title3}</td>
                      <td>{item?.PG_Criteria03_333?.PG_YPub}</td>
                      <td>{item?.PG_Criteria03_333?.PG_IIISBN}</td>
                      <td>{item?.PG_Criteria03_333?.PG_NPub}</td>
                      <td>{item?.PG_Criteria03_333?.PG_ConName}</td>
                      <td>{item?.PG_Criteria03_333?.PG_NatInt}</td>
                      <td>{item?.PG_Criteria03_333?.PG_Affi}</td>
                      <td>
                        {item?.PG_Criteria03_333?.PG_Desc15?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.PG_Criteria03_342?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_342?.PG_ActiN}</td>
                      <td>{item?.PG_Criteria03_342?.PG_YearA}</td>
                      <td>{item?.PG_Criteria03_342?.PG_INAme}</td>
                      <td>{item?.PG_Criteria03_342?.PG_Bodies}</td>
                      <td>
                        {item?.PG_Criteria03_342?.PG_Desc16?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.PG_Criteria03_343?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_343?.PG_NaActi}</td>
                      <td>{item?.PG_Criteria03_343?.PG_Organ}</td>
                      <td>{item?.PG_Criteria03_343?.PG_SchName}</td>
                      <td>{item?.PG_Criteria03_343?.PG_ActiYear}</td>
                      <td>{item?.PG_Criteria03_343?.PG_NStudents}</td>
                      <td>
                        {item?.PG_Criteria03_343?.PG_Desc17?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.PG_Criteria03_351?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_351?.PG_SlNo}</td>
                      <td>{item?.PG_Criteria03_351?.PG_Collo}</td>
                      <td>{item?.PG_Criteria03_351?.PG_NCollo}</td>
                      <td>{item?.PG_Criteria03_351?.PG_NameParti}</td>
                      <td>{item?.PG_Criteria03_351?.PG_ColloYear}</td>
                      <td>{item?.PG_Criteria03_351?.PG_Dur}</td>
                      <td>
                        {item?.PG_Criteria03_351?.PG_Desc18?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.PG_Criteria03_352?.PG_criteria}</td>
                      <td>{item?.PG_Criteria03_352?.PG_NMou}</td>
                      <td>{item?.PG_Criteria03_352?.PG_Mou}</td>
                      <td>{item?.PG_Criteria03_352?.PG_Durat}</td>
                      <td>{item?.PG_Criteria03_352?.PG_List}</td>
                      <td>
                        {item?.PG_Criteria03_352?.PG_Desc19?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow17();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={() => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            setedit6(false);
            setedit7(false);
            setedit8(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                PostGraduate_Criteria-03
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.1 & 3.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    Name of the Project/ Endowments, Chairs
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_ProName}
                        onChange={(e) => {
                          setPG_ProName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_ProName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Principal Investigator/Co-Investigator
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_PrinciName}
                        onChange={(e) => {
                          setPG_PrinciName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_PrinciName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Department of Principal Investigator
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_PrinciDept}
                        onChange={(e) => {
                          setPG_PrinciDept(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_PrinciDept}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Award
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="number"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_YOfAw}
                        onChange={(e) => {
                          setPG_YOfAw(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_YOfAw}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount Sanctioned
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_ASanctioned}
                        onChange={(e) => {
                          setPG_ASanctioned(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_ASanctioned}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration of the project
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_DProject}
                        onChange={(e) => {
                          setPG_DProject(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_DProject}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of Funding Agency
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_NFunding}
                        onChange={(e) => {
                          setPG_NFunding(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_NFunding}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Type (Government/non-Government)
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_Type}
                        onChange={(e) => {
                          setPG_Type(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_Type}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_Desc12}
                        onChange={(e) => setPG_Desc12(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_Desc12}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_311?.PG_Link12}
                        accept="image/*"
                        onChange={(e) => setPG_Link12(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_311?.PG_Link12}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria03_311(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------3.2.2------ */}
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Participants
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_322?.PG_NPati}
                        onChange={(e) => {
                          setPG_NPati(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_322?.PG_NPati}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date (From)
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_322?.PG_FDate}
                        onChange={(e) => {
                          setPG_FDate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.PG_Criteria03_322?.PG_FDate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date (To)
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_322?.PG_TDate}
                        onChange={(e) => {
                          setPG_TDate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.PG_Criteria03_322?.PG_TDate).format(
                        "DD_MM_YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of workshop/seminar/conference
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_322?.PG_NWorkshop}
                        onChange={(e) => {
                          setPG_NWorkshop(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_322?.PG_NWorkshop}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_322?.PG_Desc13}
                        onChange={(e) => setPG_Desc13(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_322?.PG_Desc13}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_322?.PG_Link13}
                        accept="image/*"
                        onChange={(e) => setPG_Link13(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_322?.PG_Link13}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit2 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria03_322(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                {/* -------3.3.2------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of paper
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_332?.PG_TPapaer}
                        onChange={(e) => {
                          setPG_TPapaer(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_332?.PG_TPapaer}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the author/s
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_332?.PG_AName}
                        onChange={(e) => {
                          setPG_AName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_332?.PG_AName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of the teacher
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_332?.PG_TDept}
                        onChange={(e) => {
                          setPG_TDept(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_332?.PG_TDept}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of journal
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_332?.PG_JName}
                        onChange={(e) => {
                          setPG_JName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_332?.PG_JName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of publication
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="number"
                        id="name"
                        placeholder={Value?.PG_Criteria03_332?.PG_PubliYear}
                        onChange={(e) => {
                          setPG_PubliYear(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_332?.PG_PubliYear}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    ISSN number
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_332?.PG_IISSN}
                        onChange={(e) => {
                          setPG_IISSN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_332?.PG_IISSN}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  Link to the recognition in UGC enlistment of the Journal
                  /Digital Object Identifier (doi) number
                </h6>
                <div className="col-md-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_332?.PG_UUGC}
                        onChange={(e) => {
                          setPG_UUGC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_332?.PG_UUGC}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_332?.PG_Desc14}
                        onChange={(e) => setPG_Desc14(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_332?.PG_Desc14}</>
                  )}
                </div>
                <div className="col-md-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document 1
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_332?.PG_Link14}
                        accept="image/*"
                        onChange={(e) => setPG_Link14(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_332?.PG_Link14}</>
                  )}
                </div>
                <div className="col-md-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document 2
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_332?.PG_Link15}
                        accept="image/*"
                        onChange={(e) => setPG_Link15(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_332?.PG_Link15}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit3 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria03_332(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                {/* -------3.3.3------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_SNo}
                        onChange={(e) => {
                          setPG_SNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_SNo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_NTea}
                        onChange={(e) => {
                          setPG_NTea(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_NTea}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the book/chapters published
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_Title1}
                        onChange={(e) => {
                          setPG_Title1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_Title1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Title of the proceedings of conference
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_Title2}
                        onChange={(e) => {
                          setPG_Title2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_Title2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the paper
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_Title3}
                        onChange={(e) => {
                          setPG_Title3(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_Title3}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of publication
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_YPub}
                        onChange={(e) => {
                          setPG_YPub(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_YPub}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    ISBN/ISSN number of the proceeding
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_IIISBN}
                        onChange={(e) => {
                          setPG_IIISBN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_IIISBN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the publisher
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_NPub}
                        onChange={(e) => {
                          setPG_NPub(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_NPub}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the conference
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_ConName}
                        onChange={(e) => {
                          setPG_ConName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_ConName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    National / International
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_NatInt}
                        onChange={(e) => {
                          setPG_NatInt(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_NatInt}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Affiliating Institute at the time of publication
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_Affi}
                        onChange={(e) => {
                          setPG_Affi(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_Affi}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_Desc15}
                        onChange={(e) => setPG_Desc15(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_Desc15}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_333?.PG_Link16}
                        accept="image/*"
                        onChange={(e) => setPG_Link16(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_333?.PG_Link16}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit4 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria03_333(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------3.4.2------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_342?.PG_ActiN}
                        onChange={(e) => {
                          setPG_ActiN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_342?.PG_ActiN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of award
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_342?.PG_YearA}
                        onChange={(e) => {
                          setPG_YearA(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_342?.PG_YearA}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Award/ recognition for Institution
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_342?.PG_INAme}
                        onChange={(e) => {
                          setPG_INAme(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_342?.PG_INAme}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awarding government/ government recognised
                    bodies
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_342?.PG_Bodies}
                        onChange={(e) => {
                          setPG_Bodies(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_342?.PG_Bodies}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_342?.PG_Desc16}
                        onChange={(e) => setPG_Desc16(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_342?.PG_Desc16}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_342?.PG_Link17}
                        accept="image/*"
                        onChange={(e) => setPG_Link17(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_342?.PG_Link17}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit5 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria03_342(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_343?.PG_NaActi}
                        onChange={(e) => {
                          setPG_NaActi(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_343?.PG_NaActi}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Organising unit/agency/collaborating agency
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_343?.PG_Organ}
                        onChange={(e) => {
                          setPG_Organ(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_343?.PG_Organ}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scheme
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_343?.PG_SchName}
                        onChange={(e) => {
                          setPG_SchName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_343?.PG_SchName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of the activity
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_343?.PG_ActiYear}
                        onChange={(e) => {
                          setPG_ActiYear(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_343?.PG_ActiYear}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students participated in such activities
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_343?.PG_NStudents}
                        onChange={(e) => {
                          setPG_NStudents(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_343?.PG_NStudents}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_343?.PG_Desc17}
                        onChange={(e) => setPG_Desc17(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_343?.PG_Desc17}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_343?.PG_Link18}
                        accept="image/*"
                        onChange={(e) => setPG_Link18(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_343?.PG_Link18}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit6 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria03_343(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit6(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.5.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_351?.PG_SlNo}
                        onChange={(e) => {
                          setPG_SlNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_351?.PG_SlNo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the collaborative activity
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_351?.PG_Collo}
                        onChange={(e) => {
                          setPG_Collo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_351?.PG_Collo}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the collaborating / linking agency with contact
                    details
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_351?.PG_NCollo}
                        onChange={(e) => {
                          setPG_NCollo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_351?.PG_NCollo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the participant
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_351?.PG_NameParti}
                        onChange={(e) => {
                          setPG_NameParti(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_351?.PG_NameParti}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of collaboration
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_351?.PG_ColloYear}
                        onChange={(e) => {
                          setPG_ColloYear(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_351?.PG_ColloYear}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_351?.PG_Dur}
                        onChange={(e) => {
                          setPG_Dur(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_351?.PG_Dur}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_351?.PG_Desc18}
                        onChange={(e) => setPG_Desc18(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_351?.PG_Desc18}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_351?.PG_Link19}
                        accept="image/*"
                        onChange={(e) => setPG_Link19(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{PG_Link19.name}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit7 ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={(e) => {
                              editPG_Criteria03_351(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit7(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.5.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <h6>Criteria02--(3.5.2)</h6>
                {/* -------3.5.2------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the institution/ industry/ corporate house with whom
                    MoU is signed
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_352?.PG_NMou}
                        onChange={(e) => {
                          setPG_NMou(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_352?.PG_NMou}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of signing MOU
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_352?.PG_Mou}
                        onChange={(e) => {
                          setPG_Mou(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_352?.PG_Mou}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_352?.PG_Durat}
                        onChange={(e) => {
                          setPG_Durat(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_352?.PG_Durat}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    List the actual activities under each MOU and web links
                    year-wise
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria03_352?.PG_List}
                        onChange={(e) => {
                          setPG_List(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_352?.PG_List}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit8 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria03_352?.PG_Desc19}
                        onChange={(e) => setPG_Desc19(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_352?.PG_Desc19}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.PG_Criteria03_352?.PG_Link20}
                        accept="image/*"
                        onChange={(e) => setPG_Link20(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria03_352?.PG_Link20}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit8 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria03_352(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit8(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
                setedit1(false);
                setedit2(false);
                setedit3(false);
                setedit4(false);
                setedit5(false);
                setedit6(false);
                setedit7(false);
                setedit8(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria03_311?.PG_Desc12}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria03_322?.PG_Desc13}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria03_332?.PG_Desc14}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria03_333?.PG_Desc15}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria03_342?.PG_Desc16}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria03_343?.PG_Desc17}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria03_351?.PG_Desc18}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_8 */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria03_352?.PG_Desc19}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_311?.PG_Link12}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                ></object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_322?.PG_Link13}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_332?.PG_Link14}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_332?.PG_Link15}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_333?.PG_Link16}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_342?.PG_Link17}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_343?.PG_Link18}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_351?.PG_Link19}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 9 model */}
        <Modal show={show17} onHide={handleClose17}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria03/${View?.PG_Criteria03_352?.PG_Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default PG_Criteria03;
