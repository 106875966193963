import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./CollegeAdmin.css";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
function AdminProfile() {
  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  let [details, setDetails] = useState("");
  details = JSON.parse(sessionStorage.getItem("Puser"));
  // =========================================

  // ================Show  modal======================
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [Ohidepassword, setOhidepassword] = useState(true);
  const [Nhidepassword, setNhidepassword] = useState(true);

  const [HeiName, setHeiName] = useState("");
  const [Email, setEmail] = useState("");
  const [PNumber, setPNumber] = useState("");
  const [HeiLandNumber, setHeiLandNumber] = useState("");
  const [PrincipalName, setPrincipalName] = useState("");
  const [password, setpassword] = useState("");
  const [CollegeImg, setCollegeImg] = useState("");
  const [CollegeLogo, setCollegeLogo] = useState("");
  const [HEI, setHEI] = useState("");

  const [update, setUpdate] = useState(false);

  // Update Code

  async function updateDetails() {
    if (
      !HeiName &&
      !Email &&
      !PNumber &&
      !HeiLandNumber &&
      !PrincipalName &&
      !password &&
      !CollegeImg &&
      !CollegeLogo &&
      !HEI
    ) {
      return alert("Please provide atleast one field to update.");
    }
    // formdata.append("HeiName", HeiName);
    // formdata.append("Email", Email);
    // formdata.append("PNumber", PNumber);
    // formdata.append("HeiLandNumber", HeiLandNumber);
    // formdata.append("PrincipalName", PrincipalName);
    // formdata.append("CollegeImg", CollegeImg);
    // formdata.append("CollegeLogo", CollegeLogo);
    // formdata.append("HEI", HEI);
    let obj = {
      HeiName: HeiName,
      Email: Email,
      PNumber: PNumber,
      HeiLandNumber: HeiLandNumber,
      PrincipalName: PrincipalName,
      CollegeImg: CollegeImg,
      CollegeLogo: CollegeLogo,
      HEI: HEI,
    };

    try {
      const config = {
        url: `/pri/updatePrincipalProfile/${details?._id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem(
            "Puser",
            JSON.stringify(res.data.updateDetails)
          );
          alert(res.data.success);
          window.location.reload(true);
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      // alert("error");
    }
  }

  const changepwd = async () => {
    try {
      const config = {
        url: "/pri/updatePwd/" + details?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        header: { "content-type": "application/json" },
        data: {
          oldPwd: oldPassword,
          newPwd: newPassword,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setOldPassword("");
          setNewPassword("");
          sessionStorage.removeItem("Puser");
          window.location.assign("/login");
        }
      });
    } catch (error) {
      return alert(error.response.data.error);
    }
  };

  useEffect(() => {
    if (!details) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, [details]);

  return (
    <>
      <div className="container">
        <div className="ad-b mt-4 mb-3"></div>
        <div className="main-body">
          <div className="row m-0">
            {/* <div>
                <span className="profilepage">Profile</span>
              </div> */}

            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      src={`https://brightnaac.co.in/register/${details?.CollegeLogo}`}
                      alt="Admin"
                      width="150"
                      height="150px"
                    />
                    <div className="mt-3">
                      <div
                        style={{
                          fontSize: "17px ",
                          fontWeight: "600",
                          color: "#393186",
                        }}
                      >
                        {details?.PrincipalName}
                      </div>
                      <div
                        style={{
                          fontSize: "17px ",
                          fontWeight: "600",
                          color: "#393186",
                        }}
                      >
                        {details?.Address}
                        {details?.City},{details?.Pincode}.
                      </div>

                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                      />
                      <Button className="mt-4" onClick={onButtonClick}>
                        Upload Image
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <label className="mb-0" for="HeiName">
                          HEI Name{" "}
                        </label>

                        <>
                          <input
                            className=" vi_0"
                            type="text"
                            name="year"
                            id="HeiName"
                            placeholder={details?.HeiName}
                            onChange={(e) => setHeiName(e.target.value)}
                          />
                        </>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <label className="mb-0">Email</label>
                        <input
                          type="email"
                          placeholder={details?.Email}
                          className="vi_0"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <label className="mb-0">Phone No</label>
                        <input
                          type="number"
                          placeholder={details?.PNumber}
                          className="vi_0"
                          onChange={(e) => setPNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <label className="mb-0">HEI Land Line</label>
                        <input
                          type="number"
                          placeholder={details?.HeiLandNumber}
                          className="vi_0"
                          onChange={(e) => setHeiLandNumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <label className="mb-0">HEI </label>
                        <input
                          type="text"
                          className="vi_0"
                          placeholder={details?.HEI}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <label className="mb-0">HEI </label>
                        <select
                          className="vi_0"
                          onChange={(e) => setHEI(e.target.value)}
                        >
                          <option>Options to update HEI</option>
                          <option value={"UG&PG"}>UG & PG</option>
                          <option value={"Autonomous"}>Autonomous</option>
                          <option value={"University"}>University</option>
                          <option value={"UG"}>UG</option>
                          <option value={"PG"}>PG</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <label className="mb-0">Principal Name </label>
                        <input
                          type="text"
                          className="vi_0"
                          placeholder={details?.PrincipalName}
                          onChange={(e) => setPrincipalName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <label className="mb-0" htmlFor="upload1">
                          Upload HEI Image
                        </label>
                        <input
                          type="file"
                          name="file"
                          id="upload1"
                          className="vi_0"
                          accept="/imgage*"
                          // placeholder={details?.CollegeImg}
                          onChange={(e) => setCollegeImg(e.target.files[0])}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="do-sear mb-2">
                        <label className="mb-0" htmlFor="upload2">
                          Upload HEI Logo
                        </label>
                        <input
                          type="file"
                          className="vi_0"
                          name="file"
                          id="upload2"
                          // placeholder={details?.CollegeLogo}
                          accept="/image*"
                          onChange={(e) => setCollegeLogo(e.target.files[0])}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div
                        className="do-sear   mt-2"
                        style={{ marginRight: "5px" }}
                      >
                        <Button onClick={updateDetails}>Save Changes</Button>
                      </div>

                      <div className="do-sear  text-end  mt-2">
                        <Button onClick={handleShow}>ChangePassword</Button>
                      </div>
                    </div>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>ChangePassword</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="container">
                          {/* <div className="">
                  <div className="do-sear mt-2">
                    <label>Registered Email ID</label>
                    <input
                      type="text"
                      placeholder="Enter Registerd Email ID"
                      className="vi_0"
                    />
                  </div>
                </div> */}
                          <div className="">
                            <div className="do-sear mt-2">
                              <label> Old Password</label>
                              <div className="d-flex align-items-center">
                                <input
                                  type={Ohidepassword ? "password" : "text"}
                                  placeholder="Enter Old Password"
                                  value={oldPassword}
                                  className="vi_0"
                                  onChange={(e) =>
                                    setOldPassword(e.target.value)
                                  }
                                />
                                {Ohidepassword ? (
                                  <FaEye
                                    style={{
                                      padding: "5px",
                                      fontSize: "33px",
                                      position: "absolute",
                                      right: "29px",
                                    }}
                                    onClick={() => setOhidepassword(false)}
                                  />
                                ) : (
                                  <FaEyeSlash
                                    style={{
                                      padding: "5px",
                                      fontSize: "33px",
                                      position: "absolute",
                                      right: "29px",
                                    }}
                                    onClick={() => setOhidepassword(true)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="do-sear mt-2">
                              <label> New Password</label>
                              <div className="d-flex align-items-center">
                                <input
                                  type={Nhidepassword ? "password" : "text"}
                                  placeholder="Enter New Password"
                                  value={newPassword}
                                  className="vi_0"
                                  onChange={(e) =>
                                    setNewPassword(e.target.value)
                                  }
                                />
                                {Nhidepassword ? (
                                  <FaEye
                                    style={{
                                      padding: "5px",
                                      fontSize: "33px",
                                      position: "absolute",
                                      right: "29px",
                                    }}
                                    onClick={() => setNhidepassword(false)}
                                  />
                                ) : (
                                  <FaEyeSlash
                                    style={{
                                      padding: "5px",
                                      fontSize: "33px",
                                      position: "absolute",
                                      right: "29px",
                                    }}
                                    onClick={() => setNhidepassword(true)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="warning"
                          onClick={changepwd}
                          style={{ color: "black" }}
                        >
                          submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminProfile;
