import React from "react";

const UG1121aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Program Code</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria01_121?.PrgmCode}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Program Name</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_121?.PrgName1}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year Of Introduction</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_121?.YOfIntro}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Status of implementation of CBCS/elective course</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_121?.Status}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of implementation of CBCS/elective course</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_121?.YOfCBCS}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of revision (if any) </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_121?.YOfRevision}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            If revision has been carried out in the syllabus during the last 5
            years, Percentage of content added or replaced{" "}
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_121?.Revision}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria01_121?.Desc2}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria01/${item?.Criteria01_121?.Link2}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG1121aqar;
