import React from "react";

const PG2241aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the Full-time teacher</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria02_241?.PG_TName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>PAN</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_241?.PG_PAN}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Designation</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_241?.PG_Designation}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of appointment</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_241?.PG_YOfAppoint}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Nature of appointment (Against Sanctioned post, temporary,permanent)
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_241?.PG_NoOfAppoint}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the Department</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_241?.PG_DeptName}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span> Total years of Experience in the same institution</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria02_241?.PG_TYofExp}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Is the teacher still serving the institution/If not last year of the
            service of Faculty to the Institution.
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_241?.PG_TService}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria02_241?.PG_Desc8}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/PG_Criteria02/${item?.PG_Criteria02_241?.PG_Link8}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG2241aqar;
