import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Styles/SubHeader.css";
import { Link } from "react-router-dom";
function SubHeader() {
  let myobj = { id: "#target" };
  return (
    <>
      <div className="sub-header">
        {["xl"].map((expand) => (
          <Navbar key={expand} expand={expand}>
            <Container fluid>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Offcanvas
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-center flex-grow-1 pe-3">
                    <LinkContainer to="/" style={{ color: "red" }}>
                      <Nav.Link>Home</Nav.Link>
                    </LinkContainer>

                    <div className="dropdown">
                      <LinkContainer
                        to="/about-us"
                        state={{ id: "demo" }}
                        style={{ color: "red" }}
                      >
                        <Nav.Link
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          // style={{ color: "red" }}
                        >
                          About
                        </Nav.Link>
                      </LinkContainer>
                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            href="#vissionmisssion"
                            state={{ id: "VM1" }}
                            style={{ color: "blue" }}
                          >
                            Vision & Mission
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/about-us"
                            state={{ id: "VF1" }}
                            style={{ color: "blue" }}
                          >
                            Value Framework
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/about-us"
                            state={{ id: "LD1" }}
                            style={{ color: "blue" }}
                          >
                            Leadership
                          </Link>
                        </li>

                        {/* <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/about-us"
                            state={{ id: "OR1" }}
                          >
                            Organisation
                          </Link>
                        </li> */}

                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/about-us"
                            state={{ id: "CM1" }}
                            style={{ color: "blue" }}
                          >
                            Campus
                          </Link>
                        </li>

                        {/* <li>
                          <Link active className="dropdown-item" to="/about-us">
                            Naac Office ,New Delhi
                          </Link>
                        </li> */}
                      </ul>
                    </div>

                    <div className="dropdown">
                      <LinkContainer
                        to="/assessment"
                        state={{ id: "demo" }}
                        style={{ color: "red" }}
                      >
                        <Nav.Link
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          Assessment & Accreditation
                        </Nav.Link>
                      </LinkContainer>
                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/assessment"
                            state={{ id: "AC1" }}
                            style={{ color: "blue" }}
                          >
                            Accreditation
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to={`/assessment`}
                            state={{ id: "EC1" }}
                            style={{ color: "blue" }}
                          >
                            Eligibility Criteria
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/assessment"
                            state={{ id: "UA1" }}
                            style={{ color: "blue" }}
                          >
                            Units Of Assessment
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/assessment"
                            state={{ id: "P1" }}
                            style={{ color: "blue" }}
                          >
                            Process
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/assessment"
                            state={{ id: "CW1" }}
                            style={{ color: "blue" }}
                          >
                            Criteria & Weightages
                          </Link>
                        </li>

                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/assessment"
                            state={{ id: "G1" }}
                            style={{ color: "blue" }}
                          >
                            Grading
                          </Link>
                        </li>

                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/assessment"
                            state={{ id: "GR1" }}
                            style={{ color: "blue" }}
                          >
                            Grievance Redressal
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/assessment"
                            state={{ id: "RA1" }}
                            style={{ color: "blue" }}
                          >
                            Re-Assessment
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/assessment"
                            state={{ id: "CA1" }}
                            style={{ color: "blue" }}
                          >
                            Cycles Of Accreditation
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/assessment"
                            state={{ id: "AO1" }}
                            style={{ color: "blue" }}
                          >
                            Assessment Of Outcome
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="dropdown">
                      <LinkContainer to="/information" style={{ color: "red" }}>
                        <Nav.Link
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          Information For Institutions
                        </Nav.Link>
                      </LinkContainer>
                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/information"
                            style={{ color: "blue" }}
                          >
                            Sponsored Seminars
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/aqar"
                            style={{ color: "blue" }}
                          >
                            AQAR
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/information"
                            style={{ color: "blue" }}
                          >
                            IQAC
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown">
                      <LinkContainer
                        to="/resources"
                        state={{ id: "demo" }}
                        style={{ color: "red" }}
                      >
                        <Nav.Link
                          // href="/resources"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          Resource
                        </Nav.Link>
                      </LinkContainer>
                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/resources"
                            state={{ id: "LIRC1" }}
                            style={{ color: "blue" }}
                          >
                            Library
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/resources"
                            state={{ id: "PUB1" }}
                            style={{ color: "blue" }}
                          >
                            Publications
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="dropdown">
                      <Nav.Link
                        href=""
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        style={{ color: "red" }}
                      >
                        Pages
                      </Nav.Link>
                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/courses"
                            style={{ color: "blue" }}
                          >
                            Courses
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/events"
                            style={{ color: "blue" }}
                          >
                            Events
                          </Link>
                        </li>
                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/gallery"
                            style={{ color: "blue" }}
                          >
                            Gallery
                          </Link>
                        </li>

                        <li>
                          <Link
                            active
                            className="dropdown-item"
                            to="/faq"
                            style={{ color: "blue" }}
                          >
                            FAQs
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div>
                      <Nav.Link href="collegelist" style={{ color: "red" }}>
                        {" "}
                        List Of Colleges
                      </Nav.Link>
                    </div>

                    <div>
                      <Nav.Link href="/contactus" style={{ color: "red" }}>
                        Contact Us
                      </Nav.Link>
                    </div>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </>
  );
}

export default SubHeader;
