import React from "react";

const UG3333aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the activity</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria03_333?.NaActi}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Organising unit/ agency/ collaborating agency</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_333?.Organ}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the scheme</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_333?.SchName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of the activity</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_333?.ActiYear}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Number of students participated in such activities</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_333?.NStudents}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria03_333?.Desc17}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria03/${item?.Criteria03_333?.Link18}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG3333aqar;
