import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

function UniversityCri2() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const UniCriteria02Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUnicri02details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.Unicriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.Unicriteria02);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatinguni2`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Uni_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          UniCriteria02Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      UniCriteria02Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center mt-4">
            <div className="col-lg-6 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-02
              </span>
            </div>
            <div className="col-lg-6 text-end">
              <div className="row justify-content-end align-items-center">
                <div className=" col-lg-6 text-center">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Uni_Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div className=" mb-2">
                        Current Rating :
                        <span className="m-1">{item?.rating}</span>
                        <span className="m-1">
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0 mt-4">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            {/* <div className="col-lg-2 text-end">
              <Button
                type="Submit"
                className="btn btn-warning"
                style={{ color: "white" }}
                onClick={() => {
                  // setValue(item);
                  handleShow();
                }}
              >
                Add Rating
              </Button>
            </div> */}
          </div>
        </div>

        <div className="row m-0 mt-4">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform  vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              {allYears
                ?.sort((a, b) => {
                  return Number(b?.from) - Number(a?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/unicriteria02">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  onClick={() => navigate(`/adminclgunicri2211/${params?.id}`)}
                >
                  <Button className="criteriabtn">2.1.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-2"
                  onClick={() => navigate(`/adminclgunicri2212/${params?.id}`)}
                >
                  <Button className="criteriabtn">2.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri2241/${params?.id}`)}
                >
                  <Button className="criteriabtn">2.4.1 & 2.4.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri2242/${params?.id}`)}
                >
                  <Button className="criteriabtn">2.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri2244/${params?.id}`)}
                >
                  <Button className="criteriabtn">2.4.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri2251/${params?.id}`)}
                >
                  <Button className="criteriabtn">2.5.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri2263/${params?.id}`)}
                >
                  <Button className="criteriabtn">2.6.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri2271/${params?.id}`)}
                >
                  <Button className="criteriabtn">2.7.1</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 2.1.1 */}
                  <th>Criteria</th>
                  <th>Programme name</th>
                  <th>Programme code</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of eligible applications received</th>
                  <th>Number of Students admitted</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 2.1.2 */}
                  <th>Criteria</th>
                  <th>
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </th>
                  <th>
                    Number of students admitted from the reserved category
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                  {/* 2.4.1 & 2.4.3 */}
                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </th>
                  <th>Name of the Department</th>
                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 2.4.2 */}
                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 2.4.4 */}
                  <th>Criteria</th>
                  <th>
                    Name of full time teachers receiving awards from state
                    level, national level, international level
                  </th>
                  <th>Year of Award</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Name of the Awarding Agency</th>
                  <th>
                    Name of the award, fellowship, received from Government or
                    Government recognised bodies
                  </th>
                  <th>
                    Incentives/Type of the incentive given by the HEI in
                    recognition of the award
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 2.5.1 */}
                  <th>Criteria</th>
                  <th>Programme name</th>
                  <th>Programme code</th>
                  <th>Semester/ year</th>
                  <th>
                    Last date of the last semester-end/ year- end examination
                  </th>
                  <th>
                    Date of declaration of results of semester-end/ year- end
                    examination
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* (2.6.3) */}
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* (2.7.1) */}
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if othern than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Uni_Year &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria02_211?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_PrgCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_NoSeatSanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_eligible_applications_received?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_NoStdAdmit?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_Desc32?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_SC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_ST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_OBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Gen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Divyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Others?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_SSC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_SST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_OOBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_GGen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_DDivyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_OOthers?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Desc33?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_TName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_PAN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_Designation?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_YOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_NoOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_DeptName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_TYofExp?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_TService?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_Desc34?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_SerTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_YOfQuali?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_Desc35?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_fullTeacherName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_YrAward?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_PAN1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_Desi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_AwardAgency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_AwardName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_Incentives?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_Desc36?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_251?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_251?.Uni_pname1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_251?.Uni_pcode1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_251?.Uni_semester?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Uni_Criteria02_251?.Uni_last)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Uni_Criteria02_251?.Uni_Date)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria02_251?.Uni_Desc37?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_pCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_NStdAppeared?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_NStdPassed?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_Desc38?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_NStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_Gender?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_SDomicile?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_NIndian?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_Email?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_MNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_YJoining?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_UID?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_Desc39?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Uni_Year}</td>

                      <td>{item?.Uni_Criteria02_211?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_211?.Uni_PrgName1}</td>
                      <td>{item?.Uni_Criteria02_211?.Uni_PrgCode}</td>
                      <td>{item?.Uni_Criteria02_211?.Uni_NoSeatSanctioned}</td>
                      <td>
                        {
                          item?.Uni_Criteria02_211
                            ?.Uni_eligible_applications_received
                        }
                      </td>
                      <td>{item?.Uni_Criteria02_211?.Uni_NoStdAdmit}</td>
                      <td>
                        {item?.Uni_Criteria02_211?.Uni_Desc32?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 212 */}
                      <td>{item?.Uni_Criteria02_212?.Uni_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria02_212?.Uni_SC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_ST}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_OBC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_Gen}</td>
                              <td>
                                {item?.Uni_Criteria02_212?.Uni_Divyangjan}
                              </td>
                              <td>{item?.Uni_Criteria02_212?.Uni_Others}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria02_212?.Uni_SSC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_SST}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_OOBC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_GGen}</td>
                              <td>
                                {item?.Uni_Criteria02_212?.Uni_DDivyangjan}
                              </td>
                              <td>{item?.Uni_Criteria02_212?.Uni_OOthers}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        {item?.Uni_Criteria02_212?.Uni_Desc33?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 241 */}
                      <td>{item?.Uni_Criteria02_241_243?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_TName}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_PAN}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_Designation}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_YOfAppoint}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_NoOfAppoint}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_DeptName}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_TYofExp}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_TService}</td>
                      <td>
                        {item?.Uni_Criteria02_241_243?.Uni_Desc34?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 242 */}
                      <td>{item?.Uni_Criteria02_242?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_242?.Uni_SerTeacher}</td>
                      <td>{item?.Uni_Criteria02_242?.Uni_Qualify}</td>
                      <td>{item?.Uni_Criteria02_242?.Uni_YOfQuali}</td>
                      <td>
                        {item?.Uni_Criteria02_242?.Uni_Desc35?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 244 */}
                      <td>{item?.Uni_criteria02_244?.Uni_criteria}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_fullTeacherName}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_YrAward}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_PAN1}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_Desi}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_AwardAgency}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_AwardName}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_Incentives}</td>
                      <td>
                        {item?.Uni_criteria02_244?.Uni_Desc36?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 251 */}
                      <td>{item?.Uni_Criteria02_251?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_251?.Uni_pname1}</td>
                      <td>{item?.Uni_Criteria02_251?.Uni_pcode1}</td>
                      <td>{item?.Uni_Criteria02_251?.Uni_semester}</td>
                      <td>
                        {moment(item?.Uni_Criteria02_251?.Uni_last)?.format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Uni_Criteria02_251?.Uni_Date)?.format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {item?.Uni_Criteria02_251?.Uni_Link37?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 263 */}
                      <td>{item?.Uni_Criteria02_263?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_PrgName2}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_pCode}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_NStdAppeared}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_NStdPassed}</td>
                      <td>
                        {item?.Uni_Criteria02_263?.Uni_Desc38?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 271 */}
                      <td>{item?.Uni_Criteria02_271?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_PrgName3}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_NStd}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_Gender}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_Category}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_SDomicile}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_NIndian}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_Email}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_MNo}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_YJoining}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_UID}</td>
                      <td>
                        {item?.Uni_Criteria02_271?.Uni_Desc39?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_211?.Uni_Link32}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_212?.Uni_Link33}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_241_243?.Uni_Link34}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_242?.Uni_Link35}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_263?.Uni_Link38}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_271?.Uni_Link39}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_251?.Uni_Link37}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_criteria02_244?.Uni_Link36}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal_9 */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria02_211?.Uni_Desc32}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal_10 */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Uni_Criteria02_212?.Uni_Desc33}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_11 */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria02_241_243?.Uni_Desc34}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_12 */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria02_242?.Uni_Desc35}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose12}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_13 */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_criteria02_244?.Uni_Desc36}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal_14 */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria02_251?.Uni_Desc37}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose14}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_15 */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria02_263?.Uni_Desc38}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_16 */}
          <Modal show={show16} onHide={handleClose16}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria02_271?.Uni_Desc39}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose16}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UniversityCri2;
