import axios from "axios";
import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export default function AQARDetails() {
  // ================Generate AQAR Report modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const navigate = useNavigate();
  const startYear = 1900;
  const endYear = new Date().getFullYear();
  const [startYearReport, setstartYearReport] = useState(endYear);
  const [endYearReport, setendYearReport] = useState();
  const [startYearReport1, setstartYearReport1] = useState(endYear);
  const [endYearReport1, setendYearReport1] = useState();
  const yearList = [];
  for (let i = endYear; i >= startYear; i--) {
    yearList.push(i);
  }

  const [institutionName, setinstitutionName] = useState("");
  const [institutionHead, setinstitutionHead] = useState("");
  const [designation, setdesignation] = useState("");
  const [insFnOwnCampus, setinsFnOwnCampus] = useState("");
  const [phoneno1, setphoneno1] = useState("");
  const [mobileno1, setmobileno1] = useState("");
  const [regEmail1, setregEmail1] = useState("");
  const [altEmail1, setaltEmail1] = useState("");
  const [address1, setaddress1] = useState("");
  const [city1, setcity1] = useState("");
  const [state1, setstate1] = useState("");
  const [pincode1, setpincode1] = useState("");
  const [affliated2, setaffliated2] = useState("");
  const [instituteType2, setinstituteType2] = useState("");
  const [location2, setlocation2] = useState("");
  const [financialStatus2, setfinancialStatus2] = useState("");
  const [iqacDirector2, setiqacDirector2] = useState("");
  const [phoneno2, setphoneno2] = useState("");
  const [mobileno2, setmobileno2] = useState("");
  const [regEmail2, setregEmail2] = useState("");
  const [altEmail2, setaltEmail2] = useState("");
  const [weblink3, setweblink3] = useState("");
  const [academicCalendar4, setacademicCalendar4] = useState("");
  const [ifYes4, setifYes4] = useState("");
  const [doe6, setdoe6] = useState("");
  const [iqasDoc7, setiqasDoc7] = useState("");
  const [fundsListDoc8, setfundsListDoc8] = useState("");
  const [compositionIQAC9, setcompositionIQAC9] = useState("");
  const [latestNotification9, setlatestNotification9] = useState("");
  const [noOfIQAC10, setnoOfIQAC10] = useState("");
  const [minutesOfIQAC10, setminutesOfIQAC10] = useState("");
  const [minutesOfMeetings10, setminutesOfMeetings10] = useState("");
  const [iqacRecvFunding11, setiqacRecvFunding11] = useState("");
  const [significantContri12, setsignificantContri12] = useState("");
  const [significantContriDoc12, setsignificantContriDoc12] = useState("");
  const [PA13, setPA13] = useState("");
  const [Achievement13, setAchievement13] = useState("");
  const [planDoc, setplanDoc] = useState("");
  const [placedBeforeStatutoryBody14, setplacedBeforeStatutoryBody14] =
    useState("");
  const [accredBodyIqac15, setaccredBodyIqac15] = useState("");
  const [aishe16, setaishe16] = useState("");
  const [yos16, setyos16] = useState("");
  const [dos16, setdos16] = useState("");
  const [mis17, setmis17] = useState("");
  const [ifyes17, setifyes17] = useState("");

  const [accrediationDetailsArr, setaccrediationDetailsArr] = useState([]);
  const [accCycle, setaccCycle] = useState("");
  const [accGrade, setaccGrade] = useState("");
  const [accCgpa, setaccCgpa] = useState("");
  const [accYoa, setaccYoa] = useState("");
  const [accPF, setaccPF] = useState("");
  const [accPT, setaccPT] = useState("");

  function aacrediationDetailsFn() {
    setaccrediationDetailsArr([
      ...accrediationDetailsArr,
      {
        accCycle: accCycle,
        accGrade: accGrade,
        accCgpa: accCgpa,
        accYoa: accYoa,
        accPF: accPF,
        accPT: accPT,
      },
    ]);
  }

  const [iqas1, setiqas1] = useState("");
  const [iqas2, setiqas2] = useState("");
  const [iqas3, setiqas3] = useState("");
  const [iqasArr, setiqasArr] = useState([]);

  function iqasFn() {
    setiqasArr([
      ...iqasArr,
      {
        iqas1: iqas1,
        iqas2: iqas2,
        iqas3: iqas3,
      },
    ]);
  }

  const [Institution8, setInstitution8] = useState("");
  const [Scheme8, setScheme8] = useState("");
  const [Fa8, setFa8] = useState("");
  const [Yoawd8, setYoawd8] = useState("");
  const [amount8, setamount8] = useState("");
  const [Detail8Arr, setDetail8Arr] = useState([]);

  function Details8Fn() {
    setDetail8Arr([
      ...Detail8Arr,
      {
        Institution8: Institution8,
        Scheme8: Scheme8,
        Fa8: Fa8,
        Yoawd8: Yoawd8,
        amount8: amount8,
      },
    ]);
  }

  function ChangeYearsAQAR(value) {
    if (Number(value)) {
      setstartYearReport(Number(value));
      setendYearReport(Number(value) + 1);
    } else {
      setstartYearReport("");
      setendYearReport("");
    }
  }
  function ChangeYearsAQAR1(value) {
    if (Number(value)) {
      setstartYearReport1(Number(value));
      setendYearReport1(Number(value) + 1);
    } else {
      setstartYearReport1("");
      setendYearReport1("");
    }
  }

  async function AddAqar() {
    try {
      const obj = {
        startDate: startYearReport1,
        endDate: endYearReport1,
        institutionName: institutionName,
        institutionHead: institutionHead,
        designation: designation,
        insFnOwnCampus: insFnOwnCampus,
        phoneno1: phoneno1,
        mobileno1: mobileno1,
        regEmail1: regEmail1,
        altEmail1: altEmail1,
        address1: address1,
        city1: city1,
        state1: state1,
        pincode1: pincode1,
        affliated2: affliated2,
        instituteType2: instituteType2,
        location2: location2,
        financialStatus2: financialStatus2,
        iqacDirector2: iqacDirector2,
        phoneno2: phoneno2,
        mobileno2: mobileno2,
        regEmail2: regEmail2,
        altEmail2: altEmail2,
        weblink3: weblink3,
        academicCalendar4: academicCalendar4,
        ifYes4: ifYes4,
        accredDetails5: accrediationDetailsArr,
        doe6: doe6,
        iqas7: iqasArr,
        iqasDoc7: iqasDoc7,
        fundsList8: Detail8Arr,
        fundsListDoc8: fundsListDoc8,
        compositionIQAC9: compositionIQAC9,
        latestNotification9: latestNotification9,
        noOfIQAC10: noOfIQAC10,
        minutesOfIQAC10: minutesOfIQAC10,
        minutesOfMeetings10: minutesOfMeetings10,
        iqacRecvFunding11: iqacRecvFunding11,
        significantContri12: significantContri12,
        significantContriDoc12: significantContriDoc12,
        PA13: PA13,
        Achievement13: Achievement13,
        planDoc: planDoc,
        placedBeforeStatutoryBody14: placedBeforeStatutoryBody14,
        accredBodyIqac15: accredBodyIqac15,
        aishe16: aishe16,
        yos16: yos16,
        dos16: dos16,
        mis17: mis17,
        ifyes17: ifyes17,
      };

      const config = {
        url: "/pri/postAqarDetails",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          navigate("/aqar-list");
          // getAllFinalReports();
          // handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      return alert(error.response.data.error);
    }
  }

  return (
    <div>
      <div
        class=" col-md-12-fluid border mt-5"
        style={{
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <label>Date</label>
        <div className="d-flex">
          <div style={{ marginRight: "10px" }}>
            <div>Start Date: </div>
            <select
              style={{ width: "124px" }}
              onChange={(e) => ChangeYearsAQAR1(e.target.value)}
            >
              <option>Choose option</option>
              {yearList?.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <div> End Date:</div>
            <input value={endYearReport1} style={{ width: "124px" }} disabled />
          </div>
        </div>
      </div>
      <div
        class=" col-md-12-fluid border mt-5"
        style={{
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <h5>1. Name of the Institution</h5>

            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={institutionName}
              onChange={(e) => setinstitutionName(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <label>Name of the head of the Institution</label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={institutionHead}
              onChange={(e) => setinstitutionHead(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label>Designation</label>

            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={designation}
              onChange={(e) => setdesignation(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <label>Does the Institution function from own campus</label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={insFnOwnCampus}
              onChange={(e) => setinsFnOwnCampus(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <label>Phone no/Alternate Phone no</label>

            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={phoneno1}
              onChange={(e) => setphoneno1(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label>Mobile no.</label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={mobileno1}
              onChange={(e) => setmobileno1(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label>Registered Email</label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={regEmail1}
              onChange={(e) => setregEmail1(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <label>Alternate Email</label>

            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={altEmail1}
              onChange={(e) => setaltEmail1(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label>Address</label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={address1}
              onChange={(e) => setaddress1(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label>City/Town</label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={city1}
              onChange={(e) => setcity1(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <label>state/UT</label>

            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={state1}
              onChange={(e) => setstate1(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label>Pincode </label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={pincode1}
              onChange={(e) => setpincode1(e.target.value)}
            />
          </div>
        </div>
        <h5>2. Institutional Status</h5>
        <div>
          <div className="row">
            <div className="col-md-4">
              <label>Affiliated / Constituent</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={affliated2}
                onChange={(e) => setaffliated2(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Type of Institution </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={instituteType2}
                onChange={(e) => setinstituteType2(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Location</label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={location2}
                onChange={(e) => setlocation2(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label>Financial Status</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={financialStatus2}
                onChange={(e) => setfinancialStatus2(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Name of the IQAC co-ordinator/ Director </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={iqacDirector2}
                onChange={(e) => setiqacDirector2(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Phone no./ Alternate Phone no.</label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={phoneno2}
                onChange={(e) => setphoneno2(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label>Mobile no.</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={mobileno2}
                onChange={(e) => setmobileno2(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Registered Email</label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={regEmail2}
                onChange={(e) => setregEmail2(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Alternate Email</label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={altEmail2}
                onChange={(e) => setaltEmail2(e.target.value)}
              />
            </div>
          </div>
        </div>

        <h5>3. Website Address</h5>
        <div className="row">
          <div className="col-md-6">
            <label>Web-link of the AQAR: (Previous Academic Year)</label>

            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={weblink3}
              onChange={(e) => setweblink3(e.target.value)}
            />
          </div>
        </div>

        <h5>4. Whether Academic Calendar prepared during the year</h5>
        <input
          type="text"
          className="vi_0"
          aria-label="Default select example"
          style={{ width: "270px" }}
          value={academicCalendar4}
          onChange={(e) => setacademicCalendar4(e.target.value)}
        />
        <div className="row">
          <div className="col-md-6">
            <label>
              If Yes, Whether it is uploaded in the institutional websit
              weblink:{" "}
            </label>

            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={ifYes4}
              onChange={(e) => setifYes4(e.target.value)}
            />
          </div>
        </div>

        <h5>5. Accrediation Details</h5>
        <div>
          <Button onClick={aacrediationDetailsFn}>Add</Button>
          <Table reponsive>
            <thead>
              <th>Cycle</th>
              <th>Grade</th>
              <th>CGPA</th>
              <th>Year of Accrediation</th>
              <th>
                Validity
                <Table responsive>
                  <thead>
                    <th>Period From </th>
                    <th>Period To </th>
                  </thead>
                </Table>
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "120px" }}
                    value={accCycle}
                    onChange={(e) => {
                      setaccCycle(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "120px" }}
                    value={accGrade}
                    onChange={(e) => {
                      setaccGrade(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "120px" }}
                    value={accCgpa}
                    onChange={(e) => {
                      setaccCgpa(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "120px" }}
                    value={accYoa}
                    onChange={(e) => {
                      setaccYoa(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="vi_0"
                            aria-label="Default select example"
                            style={{ width: "120px" }}
                            value={accPF}
                            onChange={(e) => {
                              setaccPF(e.target.value);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="vi_0"
                            aria-label="Default select example"
                            style={{ width: "120px" }}
                            value={accPT}
                            onChange={(e) => {
                              setaccPT(e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table>
            <tbody>
              {accrediationDetailsArr.map((item) => {
                return (
                  <tr>
                    <td>{item.accCycle}</td>
                    <td>{item.accGrade}</td>
                    <td>{item.accCgpa}</td>
                    <td>{item.accYoa}</td>
                    <td>{item.accPF}</td>
                    <td>{item.accPT}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <h5>6. Date of Establishment of IQAC</h5>
        <input
          type="text"
          className="vi_0"
          aria-label="Default select example"
          style={{ width: "270px" }}
          value={doe6}
          onChange={(e) => {
            setdoe6(e.target.value);
          }}
        />

        <h5>7. Internal Quality Assurance System</h5>
        <div>
          <Button onClick={iqasFn}>Add</Button>
          <Table>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    value={iqas1}
                    onChange={(e) => setiqas1(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    value={iqas2}
                    onChange={(e) => setiqas2(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    value={iqas3}
                    onChange={(e) => setiqas3(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Table>
            <tbody>
              {iqasArr.map((item) => {
                return (
                  <tr>
                    <td>{item.iqas1}</td>
                    <td>{item.iqas2}</td>
                    <td>{item.iqas3}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div>
          <label htmlFor="iqasDoc7-7">
            {" "}
            Internal Quality Assurance System Doc
          </label>
          <input
            id="iqasDoc7-7"
            type="file"
            className="vi_0"
            aria-label="Default select example"
            style={{ width: "270px" }}
            accept="image/*"
            onChange={(e) => setiqasDoc7(e.target.files[0])}
          />
        </div>

        <h5>
          8. Provide the list of funds by Central/ State Government-
          UGC/CSIR/DST/DBT/ICMR/TEQIP/World Bank/CPE of UGC etc.
        </h5>
        <div>
          <Button onClick={Details8Fn}>Add</Button>
          <Table>
            <thead>
              <th>Institution/Departmen t/Faculty</th>
              <th>Scheme</th>
              <th>Funding Agency</th>
              <th> Year of award with duration</th>
              <th>Amount</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "120px" }}
                    value={Institution8}
                    onChange={(e) => setInstitution8(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "120px" }}
                    value={Scheme8}
                    onChange={(e) => setScheme8(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "120px" }}
                    value={Fa8}
                    onChange={(e) => setFa8(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "120px" }}
                    value={Yoawd8}
                    onChange={(e) => setYoawd8(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "120px" }}
                    value={amount8}
                    onChange={(e) => setamount8(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Table>
            <tbody>
              {Detail8Arr.map((item) => {
                return (
                  <tr>
                    <td>{item.Institution8}</td>
                    <td>{item.Scheme8}</td>
                    <td>{item.Fa8}</td>
                    <td>{item.Yoawd8}</td>
                    <td>{item.amount8}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div>
            <label htmlFor="amount8-8">
              {" "}
              Provide the list of funds by Central/ State Government-
              UGC/CSIR/DST/DBT/ICMR/TEQIP/World Bank/CPE of UGC etc. Doc
            </label>
            <input
              id="amount8-8"
              type="file"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              accept="image/*"
              onChange={(e) => setfundsListDoc8(e.target.files[0])}
            />
          </div>
        </div>

        <div>
          <div>
            <h5>
              9. Whether composition of IQAC as per latest NAAC guidelines:
            </h5>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={compositionIQAC9}
              onChange={(e) => setcompositionIQAC9(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="latestNotification9-9">
              Upload latest notification of formation of IQAC
            </label>
            <input
              id="latestNotification9-9"
              type="file"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              accept="image/*"
              onChange={(e) => setlatestNotification9(e.target.files[0])}
            />
          </div>
        </div>

        <div>
          <div>
            <h5>10. Number of IQAC meetings held during the year :</h5>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={noOfIQAC10}
              onChange={(e) => setnoOfIQAC10(e.target.value)}
            />
          </div>
          <div>
            <label>
              The minutes of IQAC meeting and compliances to the decisions have
              been uploaded on the institutional website
            </label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={minutesOfIQAC10}
              onChange={(e) => setminutesOfIQAC10(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="minutesOfMeetings10-10">
              Upload the minutes of meeting and action taken report{" "}
            </label>
            <input
              id="minutesOfMeetings10-10"
              type="file"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              accept="image/*"
              onChange={(e) => setminutesOfMeetings10(e.target.files[0])}
            />
          </div>
        </div>

        <div>
          <div>
            <h5>
              11. Whether IQAC received funding from any of the funding agency
              to support its activities during the year? :
            </h5>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={iqacRecvFunding11}
              onChange={(e) => setiqacRecvFunding11(e.target.value)}
            />
          </div>
        </div>

        <div>
          <div>
            <h5>
              12. Significant contributions made by IQAC during the current
              year(maximum five bullets)
            </h5>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={significantContri12}
              onChange={(e) => setsignificantContri12(e.target.value)}
            />
          </div>
          <div>
            <input
              type="file"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              accept="image/*"
              onChange={(e) => setsignificantContriDoc12(e.target.files[0])}
            />
          </div>
        </div>

        <div>
          <h5>
            13. Plan of action chalked out by the IQAC in the beginning of the
            academic year towards Quality Enhancement and outcome achieved by
            the end of the academic year
          </h5>
          <Table>
            <thead>
              <th>Plan of Action </th>
              <th>Achivements/Outcomes </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    value={PA13}
                    onChange={(e) => setPA13(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    value={Achievement13}
                    onChange={(e) => setAchievement13(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <input
            type="file"
            className="vi_0"
            aria-label="Default select example"
            style={{ width: "270px" }}
            accept="image/*"
            onChange={(e) => setplanDoc(e.target.files[0])}
          />
        </div>

        <div>
          <h5>14. Whether AQAR was placed before statutory body ?</h5>
          <input
            type="text"
            className="vi_0"
            aria-label="Default select example"
            style={{ width: "270px" }}
            value={placedBeforeStatutoryBody14}
            onChange={(e) => setplacedBeforeStatutoryBody14(e.target.value)}
          />
        </div>

        <div>
          <h5>
            15. Whether NAAC/or any other accredited body(s) visited IQAC or
            interacted with it to assess the functioning ?
          </h5>
          <input
            type="text"
            className="vi_0"
            aria-label="Default select example"
            style={{ width: "270px" }}
            value={accredBodyIqac15}
            onChange={(e) => setaccredBodyIqac15(e.target.value)}
          />
        </div>

        <div>
          <div>
            <h5>16. Whether institutional data submitted to AISHE:</h5>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={aishe16}
              onChange={(e) => setaishe16(e.target.value)}
            />
          </div>
          <div>
            <label>Year of Submission </label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={yos16}
              onChange={(e) => setyos16(e.target.value)}
            />
          </div>
          <div>
            <label>Date of Submission </label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              value={dos16}
              onChange={(e) => setdos16(e.target.value)}
            />
          </div>
        </div>

        <div>
          <h5>17. Does the Institution have Management Information System ?</h5>
          <input
            type="text"
            className="vi_0"
            aria-label="Default select example"
            style={{ width: "270px" }}
            value={mis17}
            onChange={(e) => setmis17(e.target.value)}
          />
        </div>

        <div>
          <label>
            If yes, give a brief descripiton and a list of modules currently
            operational (maximum 500 words)
          </label>
          <textarea
            cols={30}
            rows={10}
            className="vi_0"
            aria-label="Default select example"
            style={{ width: "270px" }}
            value={ifyes17}
            onChange={(e) => setifyes17(e.target.value)}
          ></textarea>
        </div>
        <Button onClick={AddAqar}>Add Report</Button>
      </div>

      {/* <Button onClick={handleShow2}>View</Button> */}
      {/* generate AQAR */}
      {/* <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>AQAR Report Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-around">
            <div>
              <div>Start Date: </div>
              <select
                style={{ width: "124px" }}
                onChange={(e) => ChangeYearsAQAR(e.target.value)}
              >
                <option>Choose option</option>
                {yearList?.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div> End Date:</div>
              <input
                value={endYearReport}
                style={{ width: "124px" }}
                disabled
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() =>
              navigate("/generate-aqar-report", {
                state: { startYearReport, endYearReport },
              })
            }
          >
            View
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}
