import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import "../Component/Styles/Announment.css";
import axios from "axios";

function Announcement() {
  // ================ show graphic modal===============================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================ show graphic modal===============================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // =================== calling api for getting the slider content======================
  const [AnnouncemnetData, setAnnouncemnetData] = useState([]);
  const [View, setView] = useState({});
  const [View1, setView1] = useState({});
  const getallAnnoun = async () => {
    let res = await axios.get(
      "https://brightnaac.co.in/api/admin/getallAnnouncement"
    );
    if (res.status === 200) {
      setAnnouncemnetData(res.data.allData);
    }
  };

  // =================== calling api for getting the slider content======================
  const [notifications, setnotifications] = useState([]);
  const getallNotifications = async () => {
    let res = await axios.get(
      "https://brightnaac.co.in/api/admin/getallNotification"
    );
    if (res.status === 200) {
      setnotifications(res.data.allData);
    }
  };

  useEffect(() => {
    getallAnnoun();
    getallNotifications();
  }, []);
  return (
    <>
      <section>
        <div
          className="announ"
          //  data-aos="fade-up" data-aos-duration="2000"
        >
          <Container>
            <div className="announcment">
              {/* <div className="noti">
                <marquee>
                  <span className="px-2">
                    This is a sample scrolling text that has scrolls in the
                    upper direction.
                  </span>
                  <span className="px-2">
                    This is a sample scrolling text that has scrolls in the
                    upper direction.
                  </span>
                </marquee>
              </div> */}
              <div className="an-new">
                <div className="row">
                  <div className="col-md-6 p-0">
                    <div className="rfce">
                      <h4 className="announ-0">Announcement</h4>
                      <div className="mar_an">
                        <marquee direction="up" scrollamount="3">
                          {AnnouncemnetData?.map((item) => {
                            return (
                              <div
                                className="marq mb-2"
                                onClick={() => {
                                  setView(item);
                                  handleShow4();
                                }}
                              >
                                {item?.topic}
                              </div>
                            );
                          })}
                        </marquee>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 p-0">
                    <div className="rfce">
                      <h4 className="announ-0">Notification</h4>
                      <div className="mar_an">
                        <marquee direction="up" scrollamount="5">
                          {notifications?.map((item) => {
                            return (
                              <div
                                className="marq mb-2 "
                                style={{ paddingLeft: "13px" }}
                                onClick={() => {
                                  setView1(item);
                                  handleShow5();
                                }}
                              >
                                {item?.topic}
                              </div>
                            );
                          })}
                        </marquee>
                      </div>
                    </div>
                  </div>
                </div>
                {/* show model */}
                <Modal show={show4} onHide={handleClose4}>
                  <Modal.Header closeButton>
                    {/* <Modal.Title>Final Report</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div>
                        <object
                          width="100%"
                          height="400"
                          data={`https://brightnaac.co.in/Announcement/${View?.doc}`}
                          type="application/pdf"
                        >
                          {" "}
                        </object>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                {/* show model */}
                <Modal show={show5} onHide={handleClose5}>
                  <Modal.Header closeButton>
                    {/* <Modal.Title>Final Report</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div>
                        <object
                          width="100%"
                          height="400"
                          data={`https://brightnaac.co.in/Notification/${View1?.doc}`}
                          type="application/pdf"
                        >
                          {" "}
                        </object>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}

export default Announcement;
