import axios from "axios";
import React, { useEffect, useState } from "react";
import UG4413aqar from "./UG4413aqar";
import UG4414aqar from "./UG4414aqar";
import UG4422aqar from "./UG4422aqar";
import { useNavigate } from "react-router-dom";
const UGC4aqar = ({ startYeardata, endYeardata }) => {
  const navigate = useNavigate();
  const [UG_Criteria04, setUG_Criteria04] = useState([]);
  const ugCriteria04Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getugcriteria04"
      );
      if (res.status === 200) {
        setUG_Criteria04(
          res.data.ugcriteria04?.filter(
            (item) =>
              item?.UG_Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.ugcriteria04?.filter(
          (item) =>
            item?.UG_Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setUG_Criteria04(error.response.data.ugcriteria04);
    }
  };

  useEffect(() => {
    ugCriteria04Details();
  }, [startYeardata, endYeardata]);
  return (
    <div>
      <div className="text-center">
        <b>Criteria 4</b>
      </div>
      {UG_Criteria04?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 4 (4.1.3)
            </div>
            <UG4413aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 4 (4.1.4 & 4.4.1)
            </div>
            <UG4414aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 4 (4.2.2 & 4.2.3)
            </div>
            <UG4422aqar item={item} />
          </div>
        );
      })}
    </div>
  );
};

export default UGC4aqar;
