import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Container, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function AlumunsFeedback() {
  const Alumni = JSON.parse(sessionStorage.getItem("Alumnistudent"));
  const [selectedDate, setSelectedDate] = useState(null);
  const handleChange = (date) => {
    setSelectedDate(date);
  };
  const [Year, setYear] = useState(null);
  const handleChange1 = (date) => {
    setYear(date);
  };

  const [name, setname] = useState("");
  const [Course, setCourse] = useState("");
  const [RegisterNo, setRegisterNo] = useState("");

  const [Survey1, setSurvey1] = useState("");
  const [Survey2, setSurvey2] = useState("");
  const [Survey3, setSurvey3] = useState("");
  const [Survey4, setSurvey4] = useState("");
  const [Survey5, setSurvey5] = useState("");
  const [Survey6, setSurvey6] = useState("");
  const [Survey7, setSurvey7] = useState("");
  const [Survey8, setSurvey8] = useState("");
  const [Suggestions, setSuggestions] = useState("");

  const Alumnifeedback = async () => {
    try {
      const config = {
        url: "student/alumnifeedback",
        method: "post",
        baseURL: "https://brightnaac.co.in/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          studentid: Alumni?._id,
          name: name,
          course: Course,
          registerno: RegisterNo,
          year: Year,
          survey1: Survey1,
          survey2: Survey2,
          survey3: Survey3,
          survey4: Survey4,
          survey5: Survey5,
          survey6: Survey6,
          survey7: Survey7,
          survey8: Survey8,
          suggestion: Suggestions,
          reviewyear: selectedDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        window.location.assign("/alumnifeefbacklist");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  return (
    <div>
      <Container className="pt-4">
        <div>
          <div className="bgdesign">
            <h2>
              Feedback from Alumnus on Academics / College Administration /
              Infrastructure for the year 2020-21
            </h2>
            <p className="text">Dear Students,</p>
            <p className="text">
              Greetings from Govt. First Grade College, RANEBENNUR.
            </p>
            <p>
              You are hereby informed to fill the bellow required data as it is
              required for NAAC Process.
            </p>
            <p className="text">Thank you.</p>
            <p> IQAC Team </p>
            <hr />
            <div className="d-flex justify-content-between">
              <b>{Alumni?.email} </b>
              <div>
                <span>Select Year : </span>
                <DatePicker
                  placeholderText="select year"
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleChange}
                  dateFormat="yyyy"
                  showYearPicker
                  maxDate={new Date()}
                />
              </div>
            </div>
            <hr />
            <p style={{ color: "red" }}>* Indicates required question</p>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  Name of the Alumni <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  Unique ID (Exam Register No.){" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setRegisterNo(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  Course Studied <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setCourse(e.target.value)}
                    >
                      <MenuItem value={"BA"}>BA</MenuItem>
                      <MenuItem value={"BCom"}>BCom</MenuItem>
                      <MenuItem value={"BSc"}>BSc</MenuItem>
                      <MenuItem value={"BBA"}>BBA</MenuItem>
                      <MenuItem value={"MA (Economics)"}>
                        MA (Economics)
                      </MenuItem>
                      <MenuItem value={"MCom"}>MCom</MenuItem>
                      <MenuItem value={"Msc(Comp. Sci)"}>
                        Msc(Comp. Sci)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  Year of Graduation<span style={{ color: "red" }}>*</span>
                  <p>ನಿಮ್ಮ ಪದವಿ ವ್ಯಾಸಂಗ ಮುಗಿದ ವರ್ಷ</p>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    {/* <InputLabel id="demo-simple-select-label">Choose..</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e)=>setYear(e.target.value)}
                >
                  <MenuItem value={"2019-10"}>2019-10</MenuItem>
                  <MenuItem value={"2010-11"}>2010-11</MenuItem>
                  <MenuItem value={"2011-12"}>2011-12</MenuItem>
                  <MenuItem value={"2012-13"}>2012-13</MenuItem>
                  <MenuItem value={"2013-14"}>2013-14</MenuItem>
                  <MenuItem value={"2014-15"}>2014-15</MenuItem>
                  <MenuItem value={"2015-16"}>2015-16</MenuItem>
                  <MenuItem value={"2016-17"}>2016-17</MenuItem>
                  <MenuItem value={"2017-18"}>2017-18</MenuItem>
                  <MenuItem value={"2018-19"}>2018-19</MenuItem>
                  <MenuItem value={"2019-20"}>2019-20</MenuItem>
                  <MenuItem value={"2020-21"}>2020-21</MenuItem>
                  <MenuItem value={"2021-22"}>2021-22</MenuItem>
                </Select> */}
                    <DatePicker
                      placeholderText="Year of Graduation"
                      className="form-control"
                      selected={Year}
                      onChange={handleChange1}
                      dateFormat="yyyy"
                      showYearPicker
                      maxDate={new Date()}
                    />
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
          <div className="bgdesign mt-2">
            <FormLabel>
              Legends (Mention your marks on bellow points){" "}
              <span style={{ color: "red" }}>*</span>
              <p> ಕೆಳಗಿನ ವಿವರಗಳಿಗೆ ನಿಮ್ಮ ಅಂಕಗಳನ್ನು ನೀಡಿ</p>
            </FormLabel>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Very Good</th>
                  <th>Good</th>
                  <th>Average</th>
                  <th>Acceptable</th>
                  <th>Poor</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      1. Alumni Association / Network of old friends of your
                      college
                    </p>
                    <p className="text">
                      {" "}
                      ನಿಮ್ಮ ಕಾಲೇಜಿನ ಹಳೇ ಸ್ನೇಹಿತರ/ ಹಳೇ ವಿದ್ಯಾರ್ಥಿ ಸಂಘದ ಜೊತೆಗಿನ
                      ನಿಮ್ಮ ಸಂಪರ್ಕ
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey1 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey1 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey1 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey1 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey1 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      2. Head of the institution is accessible{" "}
                    </p>
                    <p className="text">
                      ಕಾಲೇಜಿನ ಪ್ರಾಂಶುಪಾಲರ ನಿಮ್ಮೊಂದಿಗಿನ ಲಭ್ಯತೆ
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey2 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey2 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey2 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey2 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey2 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      3. Administrative staff of the institution is accessible
                      for various services{" "}
                    </p>
                    <p className="text">
                      {" "}
                      ವಿವಿಧ ಸೇವೆಗಳಿಗೆ ಕಾಲೇಜಿನ ಆಡಳಿತ ಸಿಬ್ಬಂದಿಗಳ ಲಭ್ಯತೆ
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey3 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey3 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey3 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey3 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey3 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      4. Faculty members are reachable for guidance and support{" "}
                    </p>
                    <p>
                      ಬೆಂಬಲ ಹಾಗೂ ಮಾರ್ಗದರ್ಶನಕ್ಕಾಗಿ ಕಾಲೇಜಿನ ಉಪನ್ಯಾಸಕರ ತಲುಪುವಿಕೆ
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey4 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey4 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey4 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey4 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey4 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      5. Alumni members are encouraged to provide valuable
                      suggestions for the growth of the institution{" "}
                    </p>
                    <p className="text">
                      {" "}
                      ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳ ಸದಸ್ಯರಿಗೆ ಸಂಸ್ಥೆಯ ಬೆಳವಣಿಗೆಗೆ ಅಮೂಲ್ಯವಾದ
                      ಸಲಹೆಗಳನ್ನು ನೀಡಲು ಪ್ರೋತ್ಸಾಹಿಸಲಾಗುತ್ತದೆ
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey5 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey5 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey5 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey5 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey5 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      6. Platform is provided for regular interaction with
                      current students{" "}
                    </p>
                    <p className="text">
                      ಪ್ರಸ್ತುತ ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ನಿಯಮಿತವಾಗಿ ಸಂವಹನ ನಡೆಸಲು
                      ವೇದಿಕೆಯನ್ನು ಒದಗಿಸಲಾಗಿದೆ
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey6 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey6 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey6 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey6 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey6 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      7. Rate the course you studied in terms of employability
                      skills Rate the course you studied in terms of
                      employability skills{" "}
                    </p>
                    <p className="text">
                      ಉದ್ಯೋಗ ಕೌಶಲ್ಯದ ದೃಷ್ಟಿಯಿಂದ ನೀವು ಅಧ್ಯಯನ ಮಾಡಿದ ಕೋರ್ಸ್ ಅನ್ನು
                      ರೇಟ್ ಮಾಡಿ
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey7 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey7 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey7 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey7 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey7 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      8. Rate your stay at college in terms of teaching,
                      learning and administration{" "}
                    </p>
                    <p className="text">
                      ಬೋಧನೆ, ಕಲಿಕೆ ಮತ್ತು ಆಡಳಿತದ ವಿಷಯದಲ್ಲಿ ಕಾಲೇಜಿನಲ್ಲಿ ನಿಮ್ಮ
                      ವಾಸ್ತವ್ಯವನ್ನು ರೇಟ್ ಮಾಡಿ
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey8 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey8 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey8 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey8 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey8 === "Poor"} />}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="bgdesign mt-2">
            <FormLabel>
              <p className="text">9. Overall feedback about college</p>{" "}
              <p className="text">ಕಾಲೇಜಿನ ಬಗ್ಗೆ ಒಟ್ಟಾರೆ ಪ್ರತಿಕ್ರಿಯೆ</p>
            </FormLabel>
            <TextField
              id="filled-multiline-flexible"
              label="Your Answer"
              multiline
              maxRows={4}
              variant="filled"
              style={{ width: "100%" }}
              onChange={(e) => setSuggestions(e.target.value)}
            />
          </div>

          <div className="mt-3">
            <Button
              variant="contained"
              className="mb-3"
              onClick={Alumnifeedback}
            >
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AlumunsFeedback;
