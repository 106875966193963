import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PG_Criteria002 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //post method for criteria_02
  const [PG_Year, setPG_Year] = useState("");

  const [PG_PrgCode, setPG_PrgCode] = useState("");
  const [PG_NoSeatSanctioned, setPG_NoSeatSanctioned] = useState("");
  const [PG_NoStdAdmit, setPG_NoStdAdmit] = useState("");
  const [PG_PrgName1, setPG_PrgName1] = useState("");
  const [PG_Desc6, setPG_Desc6] = useState("");
  const [PG_Link6, setPG_Link6] = useState("");

  const [PG_SC, setPG_SC] = useState("");
  const [PG_ST, setPG_ST] = useState("");
  const [PG_OBC, setPG_OBC] = useState("");
  const [PG_Gen, setPG_Gen] = useState("");
  const [PG_Divyangjan, setPG_Divyangjan] = useState("");
  const [PG_Others, setPG_Others] = useState("");
  const [PG_SSC, setPG_SSC] = useState("");
  const [PG_SST, setPG_SST] = useState("");
  const [PG_OOBC, setPG_OOBC] = useState("");
  const [PG_GGen, setPG_GGen] = useState("");
  const [PG_DDivyangjan, setPG_DDivyangjan] = useState("");
  const [PG_OOthers, setPG_OOthers] = useState("");
  const [PG_Desc7, setPG_Desc7] = useState("");
  const [PG_Link7, setPG_Link7] = useState("");

  const [PG_TName, setPG_TName] = useState("");
  const [PG_PAN, setPG_PAN] = useState("");
  const [PG_Designation, setPG_Designation] = useState("");
  const [PG_YOfAppoint, setPG_YOfAppoint] = useState("");
  const [PG_NoOfAppoint, setPG_NoOfAppoint] = useState("");
  const [PG_DeptName, setPG_DeptName] = useState("");
  const [PG_TYofExp, setPG_TYofExp] = useState("");
  const [PG_TService, setPG_TService] = useState("");
  const [PG_Desc8, setPG_Desc8] = useState("");
  const [PG_Link8, setPG_Link8] = useState("");

  const [PG_SerTeacher, setPG_SerTeacher] = useState("");
  const [PG_Qualify, setPG_Qualify] = useState("");
  const [PG_YOfQuali, setPG_YOfQuali] = useState("");
  const [PG_YOfRecog, setPG_YOfRecog] = useState("");
  const [PG_Whether, setPG_Whether] = useState("");
  const [PG_IsThereTeacher, setPG_IsThereTeacher] = useState("");
  const [PG_NameScholar, setPG_NameScholar] = useState("");
  const [PG_YearScholar, setPG_YearScholar] = useState("");
  const [PG_Thesis, setPG_Thesis] = useState("");
  const [PG_Desc9, setPG_Desc9] = useState("");
  const [PG_Link9, setPG_Link9] = useState("");

  const [PG_pCode, setPG_pCode] = useState("");
  const [PG_PrgName2, setPG_PrgName2] = useState("");
  const [PG_NStdAppeared, setPG_NStdAppeared] = useState("");
  const [PG_NStdPassed, setPG_NStdPassed] = useState("");
  const [PG_Desc10, setPG_Desc10] = useState("");
  const [PG_Link10, setPG_Link10] = useState("");

  const [PG_PrgName3, setPG_PrgName3] = useState("");
  const [PG_NStd, setPG_NStd] = useState("");
  const [PG_Gender, setPG_Gender] = useState("");
  const [PG_Category, setPG_Category] = useState("");
  const [PG_SDomicile, setPG_SDomicile] = useState("");
  const [PG_NIndian, setPG_NIndian] = useState("");
  const [PG_Email, setPG_Email] = useState("");
  const [PG_MNo, setPG_MNo] = useState("");
  const [PG_YJoining, setPG_YJoining] = useState("");
  const [PG_UID, setPG_UID] = useState("");
  const [PG_Desc11, setPG_Desc11] = useState("");
  const [PG_Link11, setPG_Link11] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getpgcriteria2 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpgcriteria2();
  }, []);

  const pgcriteria2 = async () => {
    if (!PG_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/pgcriteria02",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Year: PG_Year,

          PG_Criteria02_211: obj1,
          PG_Link6: PG_Link6,

          PG_Criteria02_212: obj2,
          PG_Link7: PG_Link7,

          PG_Criteria02_241: obj3,
          PG_Link8: PG_Link8,

          PG_Criteria02_242: obj4,
          PG_Link9: PG_Link9,

          PG_Criteria02_263: obj5,
          PG_Link10: PG_Link10,

          PG_Criteria02_271: obj6,
          PG_Link11: PG_Link11,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          navigate("/pgcriteria02");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !PG_PrgName1 ||
          !PG_PrgCode ||
          !PG_NoSeatSanctioned ||
          !PG_NoStdAdmit ||
          !PG_Desc6 ||
          !PG_Link6
        ) {
          return alert("Please fill all the fields 111111111111111");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", PG_Link6);
      let obj = {
        PG_criteria: "2.1.1",
        PG_PrgName1: PG_PrgName1,
        PG_PrgCode: PG_PrgCode,
        PG_NoSeatSanctioned: PG_NoSeatSanctioned,
        PG_NoStdAdmit: PG_NoStdAdmit,
        PG_Desc6: PG_Desc6,
        PG_Link6: PG_Link6,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getpgcriteria2();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc7;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !PG_SC ||
          !PG_ST ||
          !PG_OBC ||
          !PG_Gen ||
          !PG_Divyangjan ||
          !PG_Others ||
          !PG_SSC ||
          !PG_SST ||
          !PG_OOBC ||
          !PG_GGen ||
          !PG_DDivyangjan ||
          !PG_OOthers ||
          !PG_Desc7 ||
          !PG_Link7
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", PG_Link7);
      let obj = {
        PG_criteria: "2.1.2",
        PG_SC: PG_SC,
        PG_ST: PG_ST,
        PG_OBC: PG_OBC,
        PG_Gen: PG_Gen,
        PG_Divyangjan: PG_Divyangjan,
        PG_Others: PG_Others,
        PG_SSC: PG_SSC,
        PG_SST: PG_SST,
        PG_OOBC: PG_OOBC,
        PG_GGen: PG_GGen,
        PG_DDivyangjan: PG_DDivyangjan,
        PG_OOthers: PG_OOthers,
        PG_Desc7: PG_Desc7,
        PG_Link7: PG_Link7,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getpgcriteria2();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc8;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !PG_TName ||
          !PG_PAN ||
          !PG_Designation ||
          !PG_YOfAppoint ||
          !PG_NoOfAppoint ||
          !PG_DeptName ||
          !PG_TYofExp ||
          !PG_TService ||
          !PG_Desc8 ||
          !PG_Link8
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", PG_Link8);
      let obj = {
        PG_criteria: "2.4.1",
        PG_TName: PG_TName,
        PG_PAN: PG_PAN,
        PG_Designation: PG_Designation,
        PG_YOfAppoint: PG_YOfAppoint,
        PG_NoOfAppoint: PG_NoOfAppoint,
        PG_DeptName: PG_DeptName,
        PG_TYofExp: PG_TYofExp,
        PG_TService: PG_TService,
        PG_Desc8: PG_Desc8,
        PG_Link8: PG_Link8,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getpgcriteria2();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc9;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !PG_SerTeacher ||
          !PG_Qualify ||
          !PG_YOfQuali ||
          !PG_YOfRecog ||
          !PG_Whether ||
          !PG_IsThereTeacher ||
          !PG_NameScholar ||
          !PG_YearScholar ||
          !PG_Thesis ||
          !PG_Desc9 ||
          !PG_Link9
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", PG_Link9);
      let obj = {
        PG_criteria: "2.4.2",
        PG_SerTeacher: PG_SerTeacher,
        PG_Qualify: PG_Qualify,
        PG_YOfQuali: PG_YOfQuali,
        PG_YOfRecog: PG_YOfRecog,
        PG_Whether: PG_Whether,
        PG_IsThereTeacher: PG_IsThereTeacher,
        PG_NameScholar: PG_NameScholar,
        PG_YearScholar: PG_YearScholar,
        PG_Thesis: PG_Thesis,
        PG_Desc9: PG_Desc9,
        PG_Link9: PG_Link9,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getpgcriteria2();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc10;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !PG_pCode ||
          !PG_PrgName2 ||
          !PG_NStdAppeared ||
          !PG_NStdPassed ||
          !PG_Desc10 ||
          !PG_Link10
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("qqqq,", PG_Link10);
      let obj = {
        PG_criteria: "2.6.3",
        PG_pCode: PG_pCode,
        PG_PrgName2: PG_PrgName2,
        PG_NStdAppeared: PG_NStdAppeared,
        PG_NStdPassed: PG_NStdPassed,
        PG_Desc10: PG_Desc10,
        PG_Link10: PG_Link10,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getpgcriteria2();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc11;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !PG_PrgName3 ||
          !PG_NStd ||
          !PG_Gender ||
          !PG_Category ||
          !PG_SDomicile ||
          !PG_NIndian ||
          !PG_Email ||
          !PG_MNo ||
          !PG_YJoining ||
          !PG_UID ||
          !PG_Desc11 ||
          !PG_Link11
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("rrr,", PG_Link11);
      let obj = {
        PG_criteria: "2.7.1",
        PG_PrgName3: PG_PrgName3,
        PG_NStd: PG_NStd,
        PG_Gender: PG_Gender,
        PG_Category: PG_Category,
        PG_SDomicile: PG_SDomicile,
        PG_NIndian: PG_NIndian,
        PG_Email: PG_Email,
        PG_MNo: PG_MNo,
        PG_YJoining: PG_YJoining,
        PG_UID: PG_UID,
        PG_Desc11: PG_Desc11,
        PG_Link11: PG_Link11,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getpgcriteria2();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            PostGraduation_Criteria-02
          </span>
        </div>

        <div className="container">
          <div className="row-fluid bs border ">
            <div class=" col-md-6" style={{ padding: "20px" }}>
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.PG_Year ? (
                <p>{obj1?.PG_Year}</p>
              ) : (
                <select
                  class="form-select form-select-sm vi_0"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setPG_Year(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.1.1)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>

            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program name
                </label>
                {obj1?.PG_PrgName1 ? (
                  <p>{obj1?.PG_PrgName1}</p>
                ) : (
                  <select
                    class="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setPG_PrgName1(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="MCA">MCA</option>
                    <option value="MSC">MSC</option>
                    <option value="MBA">MBA</option>
                    <option value="M.Com">M.Com</option>
                  </select>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program code
                </label>
                {obj1?.PG_PrgCode ? (
                  <p>{obj1?.PG_PrgCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    id="name"
                    onChange={(e) => {
                      setPG_PrgCode(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of seats sanctioned
                </label>
                {obj1?.PG_NoSeatSanctioned ? (
                  <p>{obj1?.PG_NoSeatSanctioned}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => {
                      setPG_NoSeatSanctioned(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of Students admitted
                </label>
                {obj1?.PG_NoStdAdmit ? (
                  <p>{obj1?.PG_NoStdAdmit}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => {
                      setPG_NoStdAdmit(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.PG_Desc6 ? (
                  <p>{obj1?.PG_Desc6}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc6(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  id="upload1"
                  onChange={(e) => {
                    setPG_Link6(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end ">
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button className="btn btn-warning mt-3" onClick={handleShow}>
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>PG_Criteria02_211</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program name
                    </label>

                    {edit1 ? (
                      <>
                        <select
                          class="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={PG_PrgName1}
                          onChange={(e) => {
                            setPG_PrgName1(e.target.value);
                          }}
                        >
                          <option selected>--Select--</option>
                          <option value="MCA">MCA</option>
                          <option value="MSC">MSC</option>
                          <option value="MBA">MBA</option>
                          <option value="M.Com">M.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{PG_PrgName1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program code
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_PrgCode}
                          onChange={(e) => {
                            setPG_PrgCode(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PrgCode}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of seats sanctioned
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NoSeatSanctioned}
                          onChange={(e) => {
                            setPG_NoSeatSanctioned(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NoSeatSanctioned}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of Students admitted
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NoStdAdmit}
                          onChange={(e) => {
                            setPG_NoStdAdmit(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NoStdAdmit}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit1 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc6}
                          onChange={(e) => setPG_Desc6(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc6}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload1"
                          accept="image/*"
                          onChange={(e) => setPG_Link6(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link6?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat1();
                    handleClose();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.1.2)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>

            <div className="row">
              <h6 className="text-center text-decoration-underline">
                Number of seats earmarked for reserved category as per GOI or
                State Government rule
              </h6>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  SC{" "}
                </label>
                {obj2?.PG_SC ? (
                  <p>{obj2?.PG_SC}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_SC(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  ST{" "}
                </label>
                {obj2?.PG_ST ? (
                  <p>{obj2?.PG_ST}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_ST(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  OBC
                </label>
                {obj2?.PG_OBC ? (
                  <p>{obj2?.PG_OBC}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    id="name"
                    onChange={(e) => setPG_OBC(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Gen
                </label>
                {obj2?.PG_Gen ? (
                  <p>{obj2?.PG_Gen}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_Gen(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Divyangjan
                </label>
                {obj2?.PG_Divyangjan ? (
                  <p>{obj2?.PG_Divyangjan}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_Divyangjan(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Others
                </label>
                {obj2?.PG_Others ? (
                  <p>{obj2?.PG_Others}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_Others(e.target.value)}
                  />
                )}
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Number of students admitted from the reserved category
              </h6>

              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  SC{" "}
                </label>
                {obj2?.PG_SSC ? (
                  <p>{obj2?.PG_SSC}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_SSC(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  ST{" "}
                </label>
                {obj2?.PG_SST ? (
                  <p>{obj2?.PG_SST}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_SST(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  OBC
                </label>
                {obj2?.PG_OOBC ? (
                  <p>{obj2?.PG_OOBC}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_OOBC(e.target.value)}
                  />
                )}
              </div>

              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Gen
                </label>
                {obj2?.PG_GGen ? (
                  <p>{obj2?.PG_GGen}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_GGen(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Divyangjan
                </label>
                {obj2?.PG_DDivyangjan ? (
                  <p>{obj2?.PG_DDivyangjan}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_DDivyangjan(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Others
                </label>
                {obj2?.PG_OOthers ? (
                  <p>{obj2?.PG_OOthers}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    onChange={(e) => setPG_OOthers(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj2?.PG_Desc7 ? (
                  <p>{obj2?.PG_Desc7}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc7(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload2">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="upload2"
                  id="upload2"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link7(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end ">
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow1}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_2 (2.1.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <h6 className="text-center text-decoration-underline">
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </h6>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      SC
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_SC}
                          onChange={(e) => {
                            setPG_SC(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SC}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ST{" "}
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_ST}
                          onChange={(e) => {
                            setPG_ST(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_ST}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      OBC
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_OBC}
                          onChange={(e) => {
                            setPG_OBC(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_OBC}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Gen
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_Gen}
                          onChange={(e) => {
                            setPG_Gen(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Gen}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Divyangjan
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_Divyangjan}
                          onChange={(e) => {
                            setPG_Divyangjan(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Divyangjan}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Others
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_Others}
                          onChange={(e) => {
                            setPG_Others(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Others}</>
                    )}
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Number of students admitted from the reserved category
                  </h6>

                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      SC
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_SSC}
                          onChange={(e) => {
                            setPG_SSC(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SSC}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ST
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_SST}
                          onChange={(e) => {
                            setPG_SST(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SST}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      OBC
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_OOBC}
                          onChange={(e) => {
                            setPG_OOBC(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_OOBC}</>
                    )}
                  </div>

                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Gen
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_GGen}
                          onChange={(e) => {
                            setPG_GGen(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_GGen}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Divyangjan
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_DDivyangjan}
                          onChange={(e) => {
                            setPG_DDivyangjan(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_DDivyangjan}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Others
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          placeholder={PG_OOthers}
                          onChange={(e) => {
                            setPG_OOthers(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_OOthers}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc7}
                          onChange={(e) => setPG_Desc7(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc7}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload2"
                          id="name"
                          onChange={(e) => setPG_Link7(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link7?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit2(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat2();
                    handleClose1();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.4.1 & 2.4.3)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>

            <div className="row">
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Full-time teacher
                </label>
                {obj3?.PG_TName ? (
                  <p>{obj3?.PG_TName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_TName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  PAN
                </label>
                {obj3?.PG_PAN ? (
                  <p>{obj3?.PG_PAN}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_PAN(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Designation
                </label>
                {obj3?.PG_Designation ? (
                  <p>{obj3?.PG_Designation}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Designation(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of appointment
                </label>
                {obj3?.PG_YOfAppoint ? (
                  <p>{obj3?.PG_YOfAppoint}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_YOfAppoint(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-9 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Nature of appointment (Against Sanctioned post, temporary,
                  permanent)
                </label>
                {obj3?.PG_NoOfAppoint ? (
                  <p>{obj3?.PG_NoOfAppoint}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_NoOfAppoint(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Department
                </label>
                {obj3?.PG_DeptName ? (
                  <p>{obj3?.PG_DeptName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_DeptName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Total years of Experience in the same institution
                </label>
                {obj3?.PG_TYofExp ? (
                  <p>{obj3?.PG_TYofExp}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_TYofExp(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Is the teacher still serving the institution/If not last year
                  of the service of Faculty to the Institution
                </label>
                {obj3?.PG_TService ? (
                  <p>{obj3?.PG_TService}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_TService(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj3?.PG_Desc8 ? (
                  <p>{obj3?.PG_Desc8}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc8(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="upload3"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link8(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end ">
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow2}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria02_241</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Full-time teacher
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_TName}
                          onChange={(e) => {
                            setPG_TName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_TName}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      PAN
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_PAN}
                          onChange={(e) => {
                            setPG_PAN(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PAN}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Designation
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Designation}
                          onChange={(e) => {
                            setPG_Designation(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Designation}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of appointment
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_YOfAppoint}
                          onChange={(e) => {
                            setPG_YOfAppoint(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_YOfAppoint}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Department
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NoOfAppoint}
                          onChange={(e) => {
                            setPG_NoOfAppoint(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NoOfAppoint}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Nature of appointment (Against Sanctioned post, temporary,
                      permanent)
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_DeptName}
                          onChange={(e) => {
                            setPG_DeptName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_DeptName}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Total years of Experience in the same institution
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_TYofExp}
                          onChange={(e) => {
                            setPG_TYofExp(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_TYofExp}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Is the teacher still serving the institution/If not last
                      year of the service of Faculty to the Institution
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_TService}
                          onChange={(e) => {
                            setPG_TService(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_TService}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc8}
                          onChange={(e) => setPG_Desc8(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc8}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="name"
                          onChange={(e) => setPG_Link8(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link8?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit3(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat3();
                    handleClose2();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.4.2)</h5>
            <h6>Average enrolement percentage(Average of last five years)</h6>

            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                  Super speciality/D.Sc./D.Litt.
                </label>
                {obj4?.PG_SerTeacher ? (
                  <p>{obj4?.PG_SerTeacher}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_SerTeacher(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                  D.Litt.)
                </label>
                {obj4?.PG_Qualify ? (
                  <p>{obj4?.PG_Qualify}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_Qualify(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of obtaining the qualification
                </label>
                {obj4?.PG_YOfQuali ? (
                  <p>{obj4?.PG_YOfQuali}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_YOfQuali(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of Recognition as a Research Guide
                </label>
                {obj4?.PG_YOfRecog ? (
                  <p>{obj4?.PG_YOfRecog}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_YOfRecog(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Whether recognised as a Research Guide for
                  Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                </label>
                {obj4?.PG_Whether ? (
                  <p>{obj4?.PG_Whether}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => setPG_Whether(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Is the teacher still serving the institution/If not last year
                  of the service of Faculty to the Institution
                </label>
                {obj4?.PG_IsThereTeacher ? (
                  <p>{obj4?.PG_IsThereTeacher}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_IsThereTeacher(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the scholar
                </label>
                {obj4?.PG_NameScholar ? (
                  <p>{obj4?.PG_NameScholar}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_NameScholar(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of registration of the scholar
                </label>
                {obj4?.PG_YearScholar ? (
                  <p>{obj4?.PG_YearScholar}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_YearScholar(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Title of the thesis of the for scholar
                </label>
                {obj4?.PG_Thesis ? (
                  <p>{obj4?.PG_Thesis}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_Thesis(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj4?.PG_Desc9 ? (
                  <p>{obj4?.PG_Desc9}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc9(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload4">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="file"
                  id="upload4"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link9(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="text-end ">
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow3}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_2 (2.4.2)</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of full time serving teacher with
                        Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.
                      </label>
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={PG_SerTeacher}
                            onChange={(e) => {
                              setPG_SerTeacher(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PG_SerTeacher}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Qualification (Ph.D./D.M/M.Ch./D.N.B Super
                        speciality/D.Sc./ D.Litt.)
                      </label>
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={PG_Qualify}
                            onChange={(e) => {
                              setPG_Qualify(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PG_Qualify}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of obtaining the qualification
                      </label>
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={PG_YOfQuali}
                            onChange={(e) => {
                              setPG_YOfQuali(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PG_YOfQuali}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of Recognition as a Research Guide
                      </label>
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={PG_YOfRecog}
                            onChange={(e) => {
                              setPG_YOfRecog(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PG_YOfRecog}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Whether recognised as a Research Guide for
                        Ph.D./D.M/M.Ch./D.N.B Super
                        speciality/D.Sc./D’Lit.(YES/NO)
                      </label>
                      {edit4 ? (
                        <>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            style={{ width: "270px" }}
                            onChange={(e) => setPG_Whether(e.target.value)}
                          >
                            <option selected>--select--</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </>
                      ) : (
                        <>{PG_Whether}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Is the teacher still serving the institution/If not last
                        year of the service of Faculty to the Institution
                      </label>
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={PG_IsThereTeacher}
                            onChange={(e) => {
                              setPG_IsThereTeacher(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PG_IsThereTeacher}</>
                      )}
                    </div>

                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the scholar
                      </label>
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={PG_NameScholar}
                            onChange={(e) => {
                              setPG_NameScholar(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PG_NameScholar}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of registration of the scholar
                      </label>
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={PG_YearScholar}
                            onChange={(e) => {
                              setPG_YearScholar(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PG_YearScholar}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Title of the thesis of the for scholar
                      </label>
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={PG_Thesis}
                            onChange={(e) => {
                              setPG_Thesis(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{PG_Thesis}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>

                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={PG_Desc9}
                            onChange={(e) => setPG_Desc9(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{PG_Desc9}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>

                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="email"
                            id="upload4"
                            onChange={(e) => setPG_Link9(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{PG_Link9}</>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit4(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat4();
                    handleClose3();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.6.3)</h5>

            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Name
                </label>
                {obj5?.PG_PrgName2 ? (
                  <p>{obj5?.PG_PrgName2}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setPG_PrgName2(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="MCA">MCA</option>
                    <option value="MSC">MSC</option>
                    <option value="MBA">MBA</option>
                    <option value="M.Com">M.Com</option>
                  </select>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Code
                </label>
                {obj5?.PG_pCode ? (
                  <p>{obj5?.PG_pCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_pCode(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students appeared in the final year examination
                </label>
                {obj5?.PG_NStdAppeared ? (
                  <p>{obj5?.PG_NStdAppeared}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NStdAppeared(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="email">
                {" "}
                Number of students passed in final year examination
              </label>
              {obj5?.PG_NStdPassed ? (
                <p>{obj5?.PG_NStdPassed}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setPG_NStdPassed(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj5?.PG_Desc10 ? (
                <p>{obj5?.PG_Desc10}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setPG_Desc10(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload5">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="file"
                id="upload5"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link10(e.target.files[0]);
                }}
              />
            </div>

            <div className="text-end ">
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat5();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow4}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show4} onHide={handleClose4}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria02_2.6.3</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>
                    {edit5 ? (
                      <>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={PG_PrgName2}
                          onChange={(e) => setPG_PrgName2(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="MCA">MCA</option>
                          <option value="MSC">MBA</option>
                          <option value="MBA">MBA</option>
                          <option value="M.Com">M.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{PG_PrgName2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_pCode}
                          onChange={(e) => setPG_pCode(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_pCode}</>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of students appeared in the final year
                        examination
                      </label>
                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={PG_NStdAppeared}
                            onChange={(e) => setPG_NStdAppeared(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{PG_NStdAppeared}</>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students passed in final year examination
                    </label>
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_NStdPassed}
                          onChange={(e) => setPG_NStdPassed(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_NStdPassed}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc10}
                          onChange={(e) => setPG_Desc10(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc10}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload5"
                          accept="image/*"
                          onChange={(e) => setPG_Link10(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link10?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit5(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat5();
                    handleClose4();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_2 (2.7.1)</h5>
            <div className="row">
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Name
                </label>
                {obj6?.PG_PrgName3 ? (
                  <p>{obj6?.PG_PrgName3}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => setPG_PrgName3(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="MCA">MCA</option>
                    <option value="MSC">MSC</option>
                    <option value="MBA">MBA</option>
                    <option value="M.Com">M.Com</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the student
                </label>
                {obj6?.PG_NStd ? (
                  <p>{obj6?.PG_NStd}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_NStd(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Gender
                </label>
                {obj6?.PG_Gender ? (
                  <p>{obj6?.PG_Gender}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Gender(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Category
                </label>
                {obj6?.PG_Category ? (
                  <p>{obj6?.PG_Category}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Category(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  State of Domicile
                </label>
                {obj6?.PG_SDomicile ? (
                  <p>{obj6?.PG_SDomicile}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_SDomicile(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Nationality if othern than Indian
                </label>
                {obj6?.PG_NIndian ? (
                  <p>{obj6?.PG_NIndian}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NIndian(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Email-ID
                </label>
                {obj6?.PG_Email ? (
                  <p>{obj6?.PG_Email}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Email(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Mobile Number
                </label>
                {obj6?.PG_MNo ? (
                  <p>{obj6?.PG_MNo}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_MNo(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of joining
                </label>
                {obj6?.PG_YJoining ? (
                  <p>{obj6?.PG_YJoining}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_YJoining(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Unique Enrolment ID / College ID/ University enrolment number
                </label>
                {obj6?.PG_UID ? (
                  <p>{obj6?.PG_UID}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_UID(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj6?.PG_Desc11 ? (
                <p>{obj6?.PG_Desc11}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setPG_Desc11(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload6">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="file"
                id="upload6"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link11(e.target.files[0]);
                }}
              />
            </div>
            <div className="text-end ">
              {add6 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat6();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow5}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show5} onHide={handleClose5}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria02_2.7.1</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    {edit6 ? (
                      <>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          style={{ width: "200px" }}
                          placeholder={PG_PrgName3}
                          onChange={(e) => {
                            setPG_PrgName3(e.target.value);
                          }}
                        >
                          <option selected>--Select--</option>
                          <option value="MCA">MCA</option>
                          <option value="MSC">MSC</option>
                          <option value="MBA">MBA</option>
                          <option value="M.Com">M.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{PG_PrgName3}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Student Name
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={PG_NStd}
                          onChange={(e) => {
                            setPG_NStd(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NStd}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Gender
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Gender}
                          onChange={(e) => {
                            setPG_Gender(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Gender}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Category
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Category}
                          onChange={(e) => {
                            setPG_Category(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Category}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      State of Domicile
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_SDomicile}
                          onChange={(e) => {
                            setPG_SDomicile(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SDomicile}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Nationality if othern than Indian
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          placeholder={PG_NIndian}
                          onChange={(e) => {
                            setPG_NIndian(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NIndian}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Email-ID
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="email"
                          placeholder={PG_Email}
                          onChange={(e) => {
                            setPG_Email(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Email}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Mobile Number
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_MNo}
                          onChange={(e) => {
                            setPG_MNo(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_MNo}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of joining
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_YJoining}
                          onChange={(e) => {
                            setPG_YJoining(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_YJoining}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Unique Enrolment ID / College ID/ University enrolment
                      number
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_UID}
                          onChange={(e) => {
                            setPG_UID(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_UID}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc11}
                          onChange={(e) => setPG_Desc11(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc11}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload6"
                          accept="image/*"
                          onChange={(e) => setPG_Link11(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link11?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit6(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat6();
                    handleClose5();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => pgcriteria2()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PG_Criteria002;
