import React from "react";

const UG5513aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Name of the capacity development and skills enhancement program
          </span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_513?.NamCap}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date of implementation</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_513?.IDate}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Number of students enrolled</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_513?.StdEnr}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Name of the agencies/consultants involved with contact details (if
            any)
          </span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_513?.AgeName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria05_513?.Desc22}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria05/${item?.Criteria05_513?.Link22}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG5513aqar;
