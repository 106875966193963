import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import exportFromJSON from "export-from-json";
import { DatePicker, Space } from "antd";
import { AiOutlineEye } from "react-icons/ai";

function Criteria04_422() {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [View, setView] = useState({});

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================date filter=====================

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  // ================date filter=====================
  const navigate = useNavigate();

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("4.2.2 & 4.2.3");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.UG_Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          UG_Year: item?.UG_Year,
          "Library Resources	": item?.UG_Criteria04_422?.UG_Library,
          "If yes, details of memberships/subscriptions":
            item?.UG_Criteria04_422?.UG_IfYes,
          "Expenditure on subscription to e-journals, e-books (INR in lakhs)":
            item?.UG_Criteria04_422?.UG_Expend3,
          "Total Library Expenditure": item?.UG_Criteria04_422?.UG_Total1,
          "Description(min 500 characters)3": item?.UG_Criteria04_422?.UG_Desc3,
          "Upload the relevant document3": item?.UG_Criteria04_422?.UG_Link3,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================
  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        pricipalDetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
        item1?.approve === "true" &&
        selectedYear === item1?.UG_Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 4 });
  };

  //  ============================================================

  const [content, setContent] = useState([]);
  const [selectedYear, setselectedYear] = useState("");
  const getCriteria04_422 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/ugcri04_422"
      );
      if (res.status === 200) {
        setContent(res.data.getC422);
        setdata1(res.data.getC422);
        setfilterPdf(res.data.getC422);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getCriteria04_422();
      getAllYears();
    }
  }, []);

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);

  console.log(content);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-04-422 & 423
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>

            <div className=" col-lg-2 lab text-end ">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = content?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.UG_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.UG_Year &&
                      (SearchItem === "" ||
                        item1?.UG_Year?.includes(SearchItem) ||
                        item1?.UG_Criteria04_422?.UG_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.UG_Criteria04_422?.UG_Library?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UG_Criteria04_422?.UG_IfYes?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UG_Criteria04_422?.UG_Expend3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UG_Criteria04_422?.UG_Total1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UG_Criteria04_422?.UG_Desc3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });
                  if (arr1?.length > 0) {
                    navigate("/pdfPrintUG4_422", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4 align-items-center">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="col-lg-9">
            <Nav defaultActiveKey="/ugcriteria01">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/UGCri04413")}
                >
                  <Button className="criteriabtn btn-sub1"> 4.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UGCri04414")}
                >
                  <Button className="criteriabtn btn-sub">
                    {" "}
                    4.1.4 & 4.4.1
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UGCri04422")}
                >
                  <Button
                    variant="warning"
                    style={{ backgroundColor: "#ffc107" }}
                    className="criteriabtn-0 btn-sub"
                  >
                    4.2.2 & 4.2.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Library Resources</th>
                  <th>If yes, details of memberships/subscriptions</th>
                  <th>
                    Expenditure on subscription to e-journals, e-books (INR in
                    lakhs)
                  </th>
                  <th>Total Library Expenditure</th>
                  <th>Description </th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.UG_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.UG_Year &&
                      (SearchItem === "" ||
                        item1?.UG_Year?.includes(SearchItem) ||
                        item1?.UG_Criteria04_422?.UG_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.UG_Criteria04_422?.UG_Library?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UG_Criteria04_422?.UG_IfYes?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UG_Criteria04_422?.UG_Expend3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UG_Criteria04_422?.UG_Total1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UG_Criteria04_422?.UG_Desc3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?.UG_Year}</td>
                        <td>{item?.UG_Criteria04_422?.UG_criteria}</td>
                        <td>{item?.UG_Criteria04_422?.UG_Library}</td>
                        <td>{item?.UG_Criteria04_422?.UG_IfYes}</td>
                        <td>{item?.UG_Criteria04_422?.UG_Expend3}</td>
                        <td>{item?.UG_Criteria04_422?.UG_Total1}</td>
                        <td>
                          {item?.UG_Criteria04_422?.UG_Desc3?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow6();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>

                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            fontSize={20}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.UG_Criteria04_422?.UG_Desc3}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose6}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria4/${View?.UG_Criteria04_422?.UG_Link3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  ></object>
                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
          </Modal>
          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Criteria04_422;
