import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";

function U_02_212() {
  const navigate = useNavigate();
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ================================  Export to excel  ====================================

  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("University-2.1.2");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          approvedetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
          item1?.approve === "true" &&
          selectedYear === item1?.Uni_Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>SC</td>
                <td>${item?.Uni_Criteria02_212?.Uni_SC}</td>
              </tr>
              <tr>
                <td>ST</td>
                <td>${item?.Uni_Criteria02_212?.Uni_ST}</td>
              </tr>
              <tr>
                <td>OBC</td>
                <td>${item?.Uni_Criteria02_212?.Uni_OBC}</td>
              </tr>
              <tr>
                <td>General</td>
                <td>${item?.Uni_Criteria02_212?.Uni_Gen}</td>
              </tr>
              <tr>
                <td>Divyangjan</td>
                <td>${item?.Uni_Criteria02_212?.Uni_Divyangjan}</td>
              </tr>
              <tr>
                <td>Others</td>
                <td>${item?.Uni_Criteria02_212?.Uni_Others}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
            <table>
              <tr>
                <td>SC</td>
                <td>${item?.Uni_Criteria02_212?.Uni_SSC}</td>
              </tr>
              <tr>
                <td>ST</td>
                <td>${item?.Uni_Criteria02_212?.Uni_SST}</td>
              </tr>
              <tr>
                <td>OBC</td>
                <td>${item?.Uni_Criteria02_212?.Uni_OOBC}</td>
              </tr>
              <tr>
                <td>General</td>
                <td>${item?.Uni_Criteria02_212?.Uni_GGen}</td>
              </tr>
              <tr>
                <td>Divyangjan</td>
                <td>${item?.Uni_Criteria02_212?.Uni_DDivyangjan}</td>
              </tr>
              <tr>
                <td>Others</td>
                <td>${item?.Uni_Criteria02_212?.Uni_OOthers}</td>
              </tr>
            </table>
          `;

        let obj = {
          Year: item?.Uni_Year,
          "Number of seats earmarked for reserved category as per GOI or State Government rule":
            tableMarkup1,
          "Number of students admitted from the reserved category":
            tableMarkup2,
          Description: item?.Uni_Criteria02_212?.Uni_Desc33,
          "Relevant Document": item?.Uni_Criteria02_212?.Uni_Link33,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================

  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        approvedetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
        item1?.approve === "true" &&
        selectedYear === item1?.Uni_Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 2 });
  };

  // =================================================================

  const [AllDetails, setAllDetails] = useState([]);
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUni_Criteria02_212"
      );
      if (res.status === 200) {
        setAllDetails(res.data.getUni_C212);
        setdata1(res.data.getUni_C212);
        setfilterPdf(res.data.getUni_C212);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.getUni_C212);
    }
  };
  console.log("allDetails", AllDetails);

  // =================================================================

  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);

  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12">
              <span style={{ fontSize: "19px", fontWeight: "bold" }}>
                University_Criteria-02-212
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>

            <div className=" col-lg-2 lab text-end">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = AllDetails?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Uni_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      approvedetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Uni_Year &&
                      (SearchItem === "" ||
                        item1?.Uni_Year?.includes(SearchItem) ||
                        item1?.Uni_Criteria02_212?.Uni_SC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_ST?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_OBC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_Gen?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_Divyangjan?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_Others?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_SSC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_SST?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_OOBC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_GGen?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_DDivyangjan?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_OOthers?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_Desc33?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });

                  if (arr1?.length > 0) {
                    navigate("/PDFprintU2_212", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4 align-items-center">
          <div className=" col-lg-3 do-sear d-flex align-items-center">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/unicriteria02">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/U_02_211")}
                >
                  <Button className="criteriabtn btn-sub">2.1.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/U_02_212")}
                >
                  <Button
                    variant="warning"
                    style={{ backgroundColor: "#ffc107" }}
                    className="criteriabtn-0 btn-sub"
                  >
                    2.1.2
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_241")}
                >
                  <Button className="criteriabtn btn-sub">2.4.1 & 2.4.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_242")}
                >
                  <Button className="criteriabtn btn-sub">2.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_244")}
                >
                  <Button className="criteriabtn btn-sub">2.4.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_251")}
                >
                  <Button className="criteriabtn btn-sub">2.5.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_263")}
                >
                  <Button className="criteriabtn btn-sub">2.6.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_271")}
                >
                  <Button className="criteriabtn btn-sub">2.7.1</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "3000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </th>
                  <th>
                    Number of students admitted from the reserved category
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Uni_Year &&
                    (SearchItem === "" ||
                      item1?.Uni_Year?.includes(SearchItem) ||
                      item1?.Uni_Criteria02_212?.Uni_SC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_ST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_OBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Gen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Divyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Others?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_SSC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_SST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_OOBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_GGen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_DDivyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_OOthers?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Desc33?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>

                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria02_212?.Uni_SC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_ST}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_OBC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_Gen}</td>
                              <td>
                                {item?.Uni_Criteria02_212?.Uni_Divyangjan}
                              </td>
                              <td>{item?.Uni_Criteria02_212?.Uni_Others}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria02_212?.Uni_SSC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_SST}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_OOBC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_GGen}</td>
                              <td>
                                {item?.Uni_Criteria02_212?.Uni_DDivyangjan}
                              </td>
                              <td>{item?.Uni_Criteria02_212?.Uni_OOthers}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        {item?.Uni_Criteria02_212?.Uni_Desc33?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Uni_Criteria02_212?.Uni_Desc33}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/uni_Criteria02/${View?.Uni_Criteria02_212?.Uni_Link33}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default U_02_212;
