import React, { useState } from "react";
import "./Css/adminpanel.css";
import { NavLink } from "react-bootstrap";
import axios from "axios";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

function Admin() {
  const [Adminemail, setAdminemail] = useState("");
  const [AdminPassword, setAdminPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const Adminlogin = async () => {
    try {
      const config = {
        url: "/admin/adminlogin",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        header: { "content-type": "application/json" },
        data: {
          AEmail: Adminemail,
          APassword: AdminPassword,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.setItem("Admin", JSON.stringify(res.data.Adetails));
          window.location.assign("/dashboard");
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  return (
    <section className="llogin">
      <div class="login-box">
        <div className="content-login">
          <div
            className="left"
            style={{
              backgroundImage: "url('NME-Logo.png')",
              height: "91%",
              position: "center",
              backgroundSize: "cover",
            }}
          >
            <figure></figure>
          </div>

          <div className="form-login">
            <div className="login-heading">Admin Login</div>

            <form>
              <label>Email</label>

              <input
                type="email"
                placeholder="Enter Your User Id"
                onChange={(e) => setAdminemail(e.target.value)}
              />

              <label>Password</label>

              <input
                type={isRevealPwd ? "text" : "password"}
                value={AdminPassword}
                placeholder="Enter Your Password"
                onChange={(e) => setAdminPassword(e.target.value)}
              />
              {isRevealPwd ? (
                <BsFillEyeFill
                  className="eye"
                  size={18}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
              ) : (
                <BsFillEyeSlashFill
                  className="eye"
                  size={18}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
              )}

              <div className="login-btn">
                <NavLink>
                  <button type="button" onClick={Adminlogin}>
                    Log in
                  </button>
                </NavLink>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Admin;
