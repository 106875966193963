import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const Uni_Criteria07 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);

  const [Uni_Budget, setUni_Budget] = useState("");
  const [Uni_Lighting, setUni_Lighting] = useState("");
  const [Uni_PhyFac, setUni_PhyFac] = useState("");
  const [Uni_Prolift, setUni_Prolift] = useState("");
  const [Uni_Ramp, setUni_Ramp] = useState("");
  const [Uni_Braille, setUni_Braille] = useState("");
  const [Uni_RestRoom, setUni_RestRoom] = useState("");
  const [Uni_Scribes, setUni_Scribes] = useState("");
  const [Uni_SpecialSkills, setUni_SpecialSkills] = useState("");
  const [Uni_AnyOther, setUni_AnyOther] = useState("");
  const [Uni_Annual, setUni_Annual] = useState("");
  const [Uni_NoOfIniti1, setUni_NoOfIniti1] = useState("");
  const [Uni_NoOfIniti2, setUni_NoOfIniti2] = useState("");
  const [Uni_Date1, setUni_Date1] = useState("");
  const [Uni_DateDuration, setUni_DateDuration] = useState("");
  const [Uni_Date2, setUni_Date2] = useState("");
  const [Uni_Power, setUni_Power] = useState("");
  const [Uni_TPower, setUni_TPower] = useState("");
  const [Uni_ReEnergyS, setUni_ReEnergyS] = useState("");
  const [Uni_ReEnergyG, setUni_ReEnergyG] = useState("");
  const [Uni_EnergySup, setUni_EnergySup] = useState("");
  const [Uni_PercentageLED, setUni_PercentageLED] = useState("");
  const [Uni_PercentageLight, setUni_PercentageLight] = useState("");
  const [Uni_NoOfParticipants, setUni_NoOfParticipants] = useState("");
  const [Uni_NoOfActivities, setUni_NoOfActivities] = useState("");
  const [Uni_TitlePro, setUni_TitlePro] = useState("");
  const [Uni_NoOfParticipants1, setUni_NoOfParticipants1] = useState("");
  const [Uni_Male, setUni_Male] = useState("");
  const [Uni_Female, setUni_Female] = useState("");
  const [Uni_Yea, setUni_Yea] = useState("");
  const [Uni_Total, setUni_Total] = useState("");
  const [Uni_PowRe, setUni_PowRe] = useState("");
  const [Uni_InitiName, setUni_InitiName] = useState("");
  const [Uni_DurInitiative, setUni_DurInitiative] = useState("");
  const [Uni_Desc29, setUni_Desc29] = useState("");
  const [Uni_Link29, setUni_Link29] = useState("");
  const [Uni_Link30, setUni_Link30] = useState("");
  const [Uni_Link31, setUni_Link31] = useState("");

  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [Uni_Criteria07, setUni_Criteria07] = useState([]);
  const [Value, setValue] = useState({});

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Cri7.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>Physical Facilities</td>
                <td>${item?.Uni_Criteria07_007?.Uni_PhyFac}</td>
              </tr>
              <tr>
                <td>Provision for Lift</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Prolift}</td>
              </tr>
              <tr>
                <td>Ramp/ Rails</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Ramp}</td>
              </tr>
              <tr>
                <td>Braille Software/Facilities</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Braille}</td>
              </tr>
              <tr>
                <td>Rest Rooms</td>
                <td>${item?.Uni_Criteria07_007?.Uni_RestRoom}</td>
              </tr>
              <tr>
                <td>Scribes for Examination</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Scribes}</td>
              </tr><tr>
              <td>Special skill Development for Differently Abled Students</td>
              <td>${item?.Uni_Criteria07_007?.Uni_SpecialSkills}</td>
            </tr>
            <tr>
                <td>Any other Similar Facility</td>
                <td>${item?.Uni_Criteria07_007?.Uni_AnyOther}</td>
              </tr>
              <tr>
                <td>Annual expenditure excluding salary component of the institution</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Annual}</td>
              </tr>
              <tr>
                <td>Number of initiatives to address locational Advantages and Disadvantages</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfIniti1}</td>
              </tr>
              <tr>
                <td>Number of initiatives taken to Engage with and contribute to Local Community</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfIniti2}</td>
              </tr>
              <tr>
                <td>Date and Duration of the Initiative Name of the Initiative Issues Addressed</td>
                <td>${item?.Uni_Criteria07_007?.Uni_DateDuration}</td>
              </tr>
              <tr>
                <td>Power Requirement met by Renewable Energy Sources</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Power}</td>
              </tr>
              <tr>
                <td>Total Power requirements</td>
                <td>${item?.Uni_Criteria07_007?.Uni_TPower}</td>
              </tr>
              <tr>
                <td>Renewable Energy Source</td>
                <td>${item?.Uni_Criteria07_007?.Uni_ReEnergyS}</td>
              </tr>
              <tr>
                <td>Renewable Energy Generated and Used</td>
                <td>${item?.Uni_Criteria07_007?.Uni_ReEnergyG}</td>
              </tr>
              <tr>
              <td>Energy Supplied to the Grid</td>
              <td>${item?.Uni_Criteria07_007?.Uni_EnergySup}</td>
            </tr>
            <tr>
                <td>Percentage Lighting through LED bulbs</td>
                <td>${item?.Uni_Criteria07_007?.Uni_PercentageLED}</td>
              </tr>
              <tr>
                <td>Percentage Lighting through other sources</td>
                <td>${item?.Uni_Criteria07_007?.Uni_PercentageLight}</td>
              </tr>
              <tr>
                <td>Number of Participating Students and Staff</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfParticipants}</td>
              </tr>
              <tr>
                <td>No. of Activities</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfActivities}</td>
              </tr><tr>
              <td>TTitle of the Program/Activity</td>
              <td>${item?.Uni_Criteria07_007?.Uni_TitlePro}</td>
            </tr>
            <tr>
                <td>Number of Participants</td>
                <td>${item?.Uni_Criteria07_007?.Uni_NoOfParticipants1}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
          <table>
              <tr>
                <td>From</td>
                <td>${moment(item?.Uni_Criteria07_007?.Uni_Date1).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              <tr>
                <td>To</td>
                <td>${moment(item?.Uni_Criteria07_007?.Uni_Date2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              </table>
          `;
        let tableMarkup3 = `
          <table>
              <tr>
                <td>MALE</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Male}</td>
              </tr>
              <tr>
                <td>FEMALE</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Female}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Yea}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${item?.Uni_Criteria07_007?.Uni_Total}</td>
              </tr>
              </table>
          `;
        let obj = {
          Year: item?.Uni_Year,
          "Total Lighting requirements": item?.Uni_Criteria07_007?.Uni_Lighting,
          "Budget allocated for green initiatives":
            item?.Uni_Criteria07_007?.Uni_Budget,
          "Status of implementation of CBCS / Elective Course System (ECS)":
            tableMarkup1,
          "Date and Duration": tableMarkup2,
          "Number of participants by Gender": tableMarkup3,
          "Power Requirement Renewable": item?.Uni_Criteria07_007?.Uni_PowRe,
          "Initiative names": item?.Uni_Criteria07_007?.Uni_InitiName,
          "Duration of the Initiative":
            item?.Uni_Criteria07_007?.Uni_DurInitiative,
          Description: item?.Uni_Criteria07_007?.Uni_Desc29,
          "Relevent Document": item?.Uni_Criteria07_007?.Uni_Link29,
          "Relevent Document": item?.Uni_Criteria07_007?.Uni_Link30,
          "Relevent Document": item?.Uni_Criteria07_007?.Uni_Link31,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editUni_Criteria07_007 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc29;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc29) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria07_007/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Budget: Uni_Budget,
          Uni_Lighting: Uni_Lighting,
          Uni_PhyFac: Uni_PhyFac,
          Uni_Prolift: Uni_Prolift,
          Uni_Ramp: Uni_Ramp,
          Uni_Braille: Uni_Braille,
          Uni_RestRoom: Uni_RestRoom,
          Uni_Scribes: Uni_Scribes,
          Uni_SpecialSkills: Uni_SpecialSkills,
          Uni_AnyOther: Uni_AnyOther,
          Uni_Annual: Uni_Annual,
          Uni_NoOfIniti1: Uni_NoOfIniti1,
          Uni_NoOfIniti2: Uni_NoOfIniti2,
          Uni_Date1: Uni_Date1,
          Uni_DateDuration: Uni_DateDuration,
          Uni_Date2: Uni_Date2,
          Uni_Power: Uni_Power,
          Uni_TPower: Uni_TPower,
          Uni_ReEnergyS: Uni_ReEnergyS,
          Uni_ReEnergyG: Uni_ReEnergyG,
          Uni_EnergySup: Uni_EnergySup,
          Uni_PercentageLED: Uni_PercentageLED,
          Uni_PercentageLight: Uni_PercentageLight,
          Uni_NoOfParticipants: Uni_NoOfParticipants,
          Uni_NoOfActivities: Uni_NoOfActivities,
          Uni_TitlePro: Uni_TitlePro,
          Uni_NoOfParticipants1: Uni_NoOfParticipants1,
          Uni_Male: Uni_Male,
          Uni_Female: Uni_Female,
          Uni_Yea: Uni_Yea,
          Uni_Total: Uni_Total,
          Uni_PowRe: Uni_PowRe,
          Uni_InitiName: Uni_InitiName,
          Uni_DurInitiative: Uni_DurInitiative,
          Uni_Desc29: Uni_Desc29,
          Uni_Link29: Uni_Link29,
          Uni_Link30: Uni_Link30,
          Uni_Link31: Uni_Link31,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUni_cri07details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getUni_cri07details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUni_cri07details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.unicriteria07);
        setUni_Criteria07(res.data.unicriteria07);
        setdata1(res.data.unicriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria07);
    }
  };

  useEffect(() => {
    getUni_cri07details();
  }, []);
  console.log(Value);

  // =================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  // const ugCriteria07Details = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria7"
  //     );
  //     if (res.status === 200) {
  //       setAllDetails(res.data.ugcriteria07);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAllDetails(error.response.data.ugcriteria07);
  //   }
  // };

  // ================================================

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-07
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/unicriteria007">
                <button className="btn btn-success">
                  Add Criteria 7{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Total Lighting requirements</th>
                  <th>Budget allocated for green initiatives</th>
                  <th>Physical Facilities</th>
                  <th>Provision for Lift</th>
                  <th>Ramp/ Rails</th>
                  <th>Braille Software/Facilities</th>
                  <th>Rest Rooms</th>
                  <th>Scribes for Examination</th>
                  <th>
                    Special skill Development for Differently Abled Students
                  </th>
                  <th>Any other Similar Facility</th>
                  <th>
                    Annual expenditure excluding salary component of the
                    institution
                  </th>
                  <th>
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </th>
                  <th>
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </th>
                  <th>
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </th>
                  <th>Power Requirement met by Renewable Energy Sources</th>
                  <th>Total Power requirements</th>
                  <th>Renewable Energy Source</th>
                  <th>Renewable Energy Generated and Used </th>
                  <th>Energy Supplied to the Grid</th>
                  <th>Percentage Lighting through LED bulbs</th>
                  <th>Percentage Lighting through other sources</th>
                  <th>Number of Participating Students and Staff</th>
                  <th>No. of Activities</th>
                  <th>TTitle of the Program/Activity</th>
                  <th>Number of Participants</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Male</th>
                  <th>Female</th>
                  <th>Year</th>
                  <th>Total</th>
                  <th>Power Requirement Renewable</th>
                  <th>Initiative names</th>
                  <th>Duration_of_the_Initiative</th>
                  <th>Description </th>
                  <th>Document 1</th>
                  <th>Document 2</th>
                  <th>Document 3</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Uni_Criteria07?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Uni_Year?.includes(SearchItem) ||
                      item1?.Uni_Criteria07_007?.Uni_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Lighting?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PhyFac?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Prolift?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Ramp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Braille?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_RestRoom?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Scribes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_SpecialSkills?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_AnyOther?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Annual?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfIniti1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfIniti2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_DateDuration?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Power?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_TPower?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_ReEnergyS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_ReEnergyG?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_EnergySup?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PercentageLED?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PercentageLight?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfParticipants?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfActivities?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_TitlePro?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfParticipants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Uni_Criteria07_007?.Uni_Date1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Uni_Criteria07_007?.Uni_Date2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria07_007?.Uni_Male?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Female?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Yea?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PowRe?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_InitiName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_DurInitiative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Desc29?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Uni_Criteria07_007?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Lighting}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Budget}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PhyFac}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Prolift}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Ramp}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Braille}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_RestRoom}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Scribes}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_SpecialSkills}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_AnyOther}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Annual}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfIniti1}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfIniti2}</td>
                      <td>
                        {moment(
                          item?.Uni_Criteria07_007?.Uni_DateDuration
                        ).format("DD-MM-YYY")}
                      </td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Power}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_TPower}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_ReEnergyS}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_ReEnergyG}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_EnergySup}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PercentageLED}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PercentageLight}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfParticipants}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfActivities}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_TitlePro}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfParticipants1}</td>
                      <td>
                        {moment(item?.Uni_Criteria07_007?.Uni_Date1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Uni_Criteria07_007?.Uni_Date2).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Male}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Female}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Yea}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Total}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PowRe}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_InitiName}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_DurInitiative}</td>
                      <td>
                        {item?.Uni_Criteria07_007?.Uni_Desc29?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={(e) => {
            handleClose();
            setedit1(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 7 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Criteria-07
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Lighting requirements
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Lighting}
                        onChange={(e) => {
                          setUni_Lighting(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Lighting}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Budget allocated for green initiatives
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Budget}
                        onChange={(e) => {
                          setUni_Budget(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Budget}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Status of implementation of CBCS / Elective Course System
                  (ECS)
                </h6>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Physical Facilities
                    </label>

                    {edit1 ? (
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Uni_Criteria07_007?.Uni_PhyFac}
                        onChange={(e) => {
                          setUni_PhyFac(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_PhyFac}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Provision for Lift
                    </label>

                    {edit1 ? (
                      <select
                        className=" form-select form-select-sm  vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Prolift}
                        onChange={(e) => {
                          setUni_Prolift(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Prolift}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Ramp/ Rails
                    </label>

                    {edit1 ? (
                      <select
                        className=" form-select form-select-sm  vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Ramp}
                        onChange={(e) => {
                          setUni_Ramp(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Ramp}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Braille Software/Facilities
                    </label>

                    {edit1 ? (
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Braille}
                        onChange={(e) => {
                          setUni_Braille(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Braille}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Rest Rooms{" "}
                    </label>

                    {edit1 ? (
                      <select
                        className=" form-select form-select-sm  vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Uni_Criteria07_007?.Uni_RestRoom}
                        onChange={(e) => {
                          setUni_RestRoom(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_RestRoom}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Scribes for Examination
                    </label>

                    {edit1 ? (
                      <select
                        className=" form-select form-select-sm  vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Scribes}
                        onChange={(e) => {
                          setUni_Scribes(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Scribes}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Special skill Development for Differently Abled Students
                    </label>

                    {edit1 ? (
                      <select
                        className=" form-select form-select-sm  vi_0"
                        aria-label="Default select example"
                        style={{ width: "435px" }}
                        placeholder={
                          Value?.Uni_Criteria07_007?.Uni_SpecialSkills
                        }
                        onChange={(e) => {
                          setUni_SpecialSkills(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_SpecialSkills}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Any other Similar Facility
                    </label>

                    {edit1 ? (
                      <select
                        className=" form-select form-select-sm  vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Uni_Criteria07_007?.Uni_AnyOther}
                        onChange={(e) => {
                          setUni_AnyOther(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_AnyOther}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Annual expenditure excluding salary component of the
                    institution
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_Annual}
                      onChange={(e) => {
                        setUni_Annual(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_Annual}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_NoOfIniti1}
                      onChange={(e) => {
                        setUni_NoOfIniti1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_NoOfIniti1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_NoOfIniti2}
                      onChange={(e) => {
                        setUni_NoOfIniti2(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_NoOfIniti2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_DateDuration}
                      onChange={(e) => {
                        setUni_DateDuration(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_DateDuration}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement met by Renewable Energy Sources
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_Power}
                      onChange={(e) => {
                        setUni_Power(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_Power}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Power requirements
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_TPower}
                        onChange={(e) => {
                          setUni_TPower(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_TPower}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Source:
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_ReEnergyS}
                        onChange={(e) => {
                          setUni_ReEnergyS(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_ReEnergyS}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Generated and Used
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_ReEnergyG}
                        onChange={(e) => {
                          setUni_ReEnergyG(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_ReEnergyG}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Energy Supplied to the Grid:
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_EnergySup}
                        onChange={(e) => {
                          setUni_EnergySup(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_EnergySup}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Percentage Lighting through other sources
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria07_007?.Uni_PercentageLight
                        }
                        onChange={(e) => {
                          setUni_PercentageLight(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_PercentageLight}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participating Students and Staff
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria07_007?.Uni_NoOfParticipants
                        }
                        onChange={(e) => {
                          setUni_NoOfParticipants(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_NoOfParticipants}</>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Percentage Lighting through LED bulbs
                      </label>

                      {edit1 ? (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={
                            Value?.Uni_Criteria07_007?.Uni_PercentageLED
                          }
                          onChange={(e) => {
                            setUni_PercentageLED(e.target.value);
                          }}
                        />
                      ) : (
                        <>{Value?.Uni_Criteria07_007?.Uni_PercentageLED}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        No. of Activities:
                      </label>

                      {edit1 ? (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={
                            Value?.Uni_Criteria07_007?.Uni_NoOfActivities
                          }
                          onChange={(e) => {
                            setUni_NoOfActivities(e.target.value);
                          }}
                        />
                      ) : (
                        <>{Value?.Uni_Criteria07_007?.Uni_NoOfActivities}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      TTitle of the Program/Activity
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_TitlePro}
                        onChange={(e) => {
                          setUni_TitlePro(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_TitlePro}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participants
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria07_007?.Uni_NoOfParticipants1
                        }
                        onChange={(e) => {
                          setUni_NoOfParticipants1(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_NoOfParticipants1}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Date and Duration
                </h6>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      From
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Date1}
                        onChange={(e) => {
                          setUni_Date1(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Date1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      To
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Date2}
                        onChange={(e) => {
                          setUni_Date2(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Date2}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of participants by Gender
                </h6>
                <div className="row">
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      MALE
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Male}
                        onChange={(e) => {
                          setUni_Male(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Male}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      FEMALE
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Female}
                        onChange={(e) => {
                          setUni_Female(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Female}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Yea}
                        onChange={(e) => {
                          setUni_Yea(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Yea}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_Total}
                        onChange={(e) => {
                          setUni_Total(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_Total}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Power Requirement Renewable
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_PowRe}
                        onChange={(e) => {
                          setUni_PowRe(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_PowRe}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Initiative names
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria07_007?.Uni_InitiName}
                        onChange={(e) => {
                          setUni_InitiName(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Uni_Criteria07_007?.Uni_InitiName}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration_of_the_Initiative
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_DurInitiative}
                      onChange={(e) => {
                        setUni_DurInitiative(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_DurInitiative}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <textarea
                      className="vi_0"
                      rows={3}
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_Desc29}
                      onChange={(e) => {
                        setUni_Desc29(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_Desc29}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document 1
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload1"
                      accept="image/*"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_Link29}
                      onChange={(e) => {
                        setUni_Link29(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_Link29}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Document 2
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload2"
                      accept="image/*"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_Link30}
                      onChange={(e) => {
                        setUni_Link30(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_Link30}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Document 3
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload3"
                      accept="image/*"
                      placeholder={Value?.Uni_Criteria07_007?.Uni_Link31}
                      onChange={(e) => {
                        setUni_Link31(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Value?.Uni_Criteria07_007?.Uni_Link31}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria07_007(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => {
                              setedit1(true);
                            }}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria07_007?.Uni_Desc29}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria07/${View?.Uni_Criteria07_007?.Uni_Link29}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria07/${View?.Uni_Criteria07_007?.Uni_Link30}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria07/${View?.Uni_Criteria07_007?.Uni_Link31}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Uni_Criteria07;
