import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiSolidReport } from "react-icons/bi";
import { BiSolidDashboard } from "react-icons/bi";
import { FaGraduationCap } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { SiGoogleforms } from "react-icons/si";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import FacultyAdminHeader from "./FacultyAdminHeader";
import { IoPersonAddSharp } from "react-icons/io5";
function FacultyDashboard(props) {
  let FacultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const [collapsed, setCollapsed] = React.useState(false);

  console.log("FacultyDetails", FacultyDetails);
  useEffect(() => {
    if (!FacultyDetails) {
      alert("Please login first");
      window.location.assign("/flogin");
    }
  }, [FacultyDetails]);

  return (
    <div style={{ display: "flex", height: "100%", minHeight: "100vh" }}>
      <Sidebar
        collapsed={collapsed}
        height="100vh"
        collapsedWidth="4.5rem"
        backgroundColor="#2A2A72"
        style={{
          position: "sticky",
          top: "auto",
          padding: "0rem",
          margin: "0rem",
        }}
      >
        <Menu
          transitionDuration={900}
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0)
                return {
                  color: disabled ? "blue" : "white",
                  backgroundColor: active ? "red" : undefined,
                };
            },
          }}
          rootStyles={{
            color: "#344cff",
          }}
        >
          <div className="dash-logo text-center pt-3 ">
            <h4 onClick={() => setCollapsed(!collapsed)}>Admin</h4>
          </div>

          <hr style={{ color: "white" }} />

          <MenuItem
            component={<Link to="/FacultyDashboard" />}
            icon={<BiSolidDashboard />}
          >
            Dashboard
          </MenuItem>
          <div>
            {FacultyDetails?.facultyAddedBy?.HEI === "PG" ? (
              <>
                <SubMenu label="PostGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/pgcriteria01" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pgcriteria02" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pgcriteria03" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pgcriteria05" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pgcriteria07" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {FacultyDetails?.facultyAddedBy?.HEI === "UG" ? (
              <>
                <SubMenu label="UnderGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/ugcriteria01" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/ugcriteria02" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/ugcriteria03" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>

                  <MenuItem
                    component={<Link to="/ugcriteria05" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>

                  <MenuItem
                    component={<Link to="/ugcriteria07" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {FacultyDetails?.facultyAddedBy?.HEI === "University" ? (
              <>
                <SubMenu label="University" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/unicriteria01" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/unicriteria02" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/unicriteria03" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>

                  <MenuItem
                    component={<Link to="/unicriteria05" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>

                  <MenuItem
                    component={<Link to="/unicriteria07" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {FacultyDetails?.facultyAddedBy?.HEI === "Autonomous" ? (
              <>
                <SubMenu label="Autonomous" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/autocriteria01" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/autocriteria02" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/autocriteria03" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/autocriteria05" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/autocriteria07" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {FacultyDetails?.facultyAddedBy?.HEI === "UG&PG" ? (
              <>
                <SubMenu label="UnderGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/ugcriteria01" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/ugcriteria02" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/ugcriteria03" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>

                  <MenuItem
                    component={<Link to="/ugcriteria05" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>

                  <MenuItem
                    component={<Link to="/ugcriteria07" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>

                <SubMenu label="PostGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={<Link to="/pgcriteria01" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pgcriteria02" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={<Link to="/pgcriteria03" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>

                  <MenuItem
                    component={<Link to="/pgcriteria05" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>

                  <MenuItem
                    component={<Link to="/pgcriteria07" />}
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

         
          <MenuItem
            component={<Link to="/facultyfeedbacklist" />}
            icon={<IoPersonAddSharp  />}
          >
            Feedback
          </MenuItem>
        </Menu>
      </Sidebar>

      <main style={{}}>
        <div>
          <FacultyAdminHeader />
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default FacultyDashboard;
