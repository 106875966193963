import axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import PG1113aqar from "./PG1113aqar";
import PG1121aqar from "./PG1121aqar";
import PG1122aqar from "./PG1122aqar";
import PG1132aqar from "./PG1132aqar";
import PG1133aqar from "./PG1133aqar";

const PGC1aqar = React.memo(({ startYeardata, endYeardata }) => {
  const navigate = useNavigate();
  //   const [AllDetails, setAllDetails] = useState([]);
  //   const ugCriteria01Details = async () => {
  //     try {
  //       const res = await axios.get(
  //         "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria01"
  //       );
  //       if (res.status === 200) {
  //         setAllDetails(
  //           res.data.ugcriteria01?.filter(
  //             (item) =>
  //               item?.Year === `${startYeardata}-${endYeardata}` &&
  //               item?.approve === "true"
  //           )
  //         );
  //         let dataArr = res.data.ugcriteria01?.filter(
  //           (item) =>
  //             item?.Year === `${startYeardata}-${endYeardata}` &&
  //             item?.approve === "true"
  //         );
  //         if (!dataArr?.length) {
  //           alert("No data available");
  //           navigate("/finalreports");
  //           return;
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setAllDetails(error.response.data.ugcriteria01);
  //     }
  //   };

  const [content, setContent] = useState([]);
  const getpgCriteria1 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcri01details"
      );
      if (res.status === 200) {
        setContent(
          res.data.pgcriteria01?.filter(
            (item) =>
              item?.PG_Year === `${startYeardata}-${endYeardata}` &&
              item?.PG_approve === "true"
          )
        );
        // setdata1(res.data.pgcriteria01);
        let dataArr = res.data.pgcriteria01?.filter(
          (item) =>
            item?.PG_Year === `${startYeardata}-${endYeardata}` &&
            item?.PG_approve === "true"
        );
        if (!dataArr?.length) {
          alert("No data available");
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setContent(error.response.data.pgcriteria01);
    }
  };

  useEffect(() => {
    getpgCriteria1();
  }, [startYeardata, endYeardata]);

  return (
    <div>
      <div className="text-center">
        <b>Criteria 1</b>
      </div>
      {content?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.1.3)
            </div>
            {/* <UG1113aqar item={item} /> */}
            <PG1113aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.2.1)
            </div>
            <PG1121aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.2.2)
            </div>
            <PG1122aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.3.2)
            </div>
            <PG1132aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.3.3)
            </div>
            <PG1133aqar item={item} />
          </div>
        );
      })}
    </div>
  );
});

export default PGC1aqar;
