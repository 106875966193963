import axios from "axios";
import React, { useState } from "react";
import { Button, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Styles/form.css";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
function Llogin() {
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const Librarianlogin = async () => {
    try {
      const config = {
        url: "/lib/libraryianl",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        header: { "content-type": "application/json" },
        data: {
          Email: Email,
          Password: Password,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.setItem("Luser", JSON.stringify(res.data.details));
          window.location.assign("/librarydashboard");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  return (
    <>
      <section className="llogin">
        <div class="login-box">
          <div className="content-login">
            <div
              className="left"
              style={{
                backgroundImage: "url('NME-Logo.png')",
                height: "75%",
                position: "center",
                backgroundSize: "cover",
              }}
            >
              <figure></figure>
            </div>

            <div className="form-login">
              <div className="login-heading">Login</div>

              <form>
                <span>(For Librarian / PDC)</span>
                <label>Email</label>

                <input
                  type="email"
                  placeholder="Enter Your User Id"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <label>Password</label>

                <input
                  type={isRevealPwd ? "text" : "password"}
                  value={Password}
                  placeholder="Enter Your Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {isRevealPwd ? (
                  <BsFillEyeFill
                    className="eye"
                    size={18}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                ) : (
                  <BsFillEyeSlashFill
                    className="eye"
                    size={18}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                )}

                <div className="login-btn">
                  <NavLink>
                    <button type="button" onClick={Librarianlogin}>
                      Log in
                    </button>
                  </NavLink>
                </div>

                {/* <div className="create" style={{ color: "#393f81" }}>
                  Don't have an account?{" "}
                  <Link to="/registerrorm" style={{ color: "#393f81" }}>
                    Register here
                  </Link>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Llogin;
