import axios from "axios";
import React, { useEffect, useState } from "react";
import UG6623aqar from "./UG6623aqar";
import UG6632aqar from "./UG6632aqar";
import UG6633aqar from "./UG6633aqar";
import UG6634aqar from "./UG6634aqar";
import UG6642aqar from "./UG6642aqar";
import UG6653aqar from "./UG6653aqar";
import { useNavigate } from "react-router-dom";
import UG6611aqar from "./UG6611aqar";
import UG6612aqar from "./UG6612aqar";

const UGC6aqar = ({ startYeardata, endYeardata }) => {
  const [AllDetails2, setAllDetails2] = useState([]);
  const navigate = useNavigate();
  const ugCriteria06Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/ugadmin/getcri6"
      );
      if (res.status === 200) {
        setAllDetails2(
          res.data.ugcriteria06?.filter(
            (item) =>
              item?.UGYear === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.ugcriteria06?.filter(
          (item) =>
            item?.UGYear === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setAllDetails2(error.response.data.ugcriteria06);
    }
  };
  useEffect(() => {
    ugCriteria06Details();
  }, [startYeardata, endYeardata]);

  console.log("AllDetails2: ", AllDetails2);
  return (
    <div>
      <div className="text-center">
        <b>Criteria 6</b>
      </div>
      {AllDetails2?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.1.1)
            </div>
            <UG6611aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.1.2)
            </div>
            <UG6612aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.2.3)
            </div>
            <UG6623aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.3.2)
            </div>
            <UG6632aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.3.3)
            </div>
            <UG6633aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.3.4)
            </div>
            <UG6634aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.4.2)
            </div>
            <UG6642aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.5.3)
            </div>
            <UG6653aqar item={item} />
          </div>
        );
      })}
    </div>
  );
};

export default UGC6aqar;
