import React from "react";
import { Container } from "react-bootstrap";
import "../Component/Styles/header.css";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";

function Header() {
  return (
    <>
      <div className="sec-h">
        <Container fluid>
          <div className="sec-he">
            <div className="sec-he p-2">
              <div>
                <img
                  src="NME-Logo.png"
                  alt="logo"
                  className="img-fluid logo"
                  style={{ height: "3.5625rem" }}
                />
              </div>

              <div className="icon-header-text">NAAC Made Easy</div>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <div className="dropdown">
                <div
                  className="dropdown-toggle reglink"
                  data-bs-toggle="dropdown"
                >
                  Login
                </div>

                <ul class="dropdown-menu">
                  <li>
                    <Link active className="dropdown-item" to="/flogin">
                      Faculty
                    </Link>
                  </li>
                  <li>
                    <Link active className="dropdown-item" to="/llogin">
                      Librarian / PDC
                    </Link>
                  </li>

                  <li>
                    <Link active className="dropdown-item" to="/login">
                      Principal / IQAC
                    </Link>
                  </li>

                  <li>
                    <Link active className="dropdown-item" to="/student-login">
                      Student
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="m-2 reg">
                <Link to="/registerrorm" className="reglink">
                  Register
                </Link>
              </div>
            </div>
          </div>

          {/* <div className=" ms-5">
            <div className="notif">
              <marquee>
                <span
                  className="px-2 "
                  style={{
                    fontSize: "20px",
                    minWidth: "100px",
                  }}
                >
                  Important Notice Regarding
                  <img
                    src="../gallery/new.gif"
                    alt="new"
                    style={{ height: "17px", margin: "1px" }}
                  />
                </span>
                <span
                  className="px-2"
                  style={{
                    minWidth: "100px",
                    fontSize: "20px",
                  }}
                >
                  This is a sample scrolling text that has scrolls in the upper
                  direction
                  <img
                    src="../gallery/new.gif"
                    alt="new"
                    style={{ height: "17px", margin: "1px" }}
                  />
                </span>
              </marquee>
            </div>
          </div> */}
        </Container>
      </div>
    </>
  );
}

export default Header;
