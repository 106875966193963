import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

function FacultyAdminHeader() {
  let FacultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));

  useEffect(() => {
    if (!FacultyDetails) {
      alert("Please login first");
      window.location.assign("/flogin");
    }
  }, [FacultyDetails]);
  return (
    <>
      <div
        className="header"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <Navbar expand="lg" style={{ background: "#2A2A72" }}>
          <Container fluid>
            <Navbar.Brand href="#">
              <h3 className="admin-heading">Faculty Panel</h3>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
                <div className=" d-flex align-items-center justify-content-center">
                  <div class="dropdown ">
                    <img
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      src={`https://brightnaac.co.in/register/${FacultyDetails?.facultyAddedBy?.CollegeLogo}`}
                      alt="logo"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        border: "2px solid black",
                        borderColor: "#393186",
                      }}
                    />{" "}
                    <ul class="dropdown-menu">
                      <li>
                        <Link class="dropdown-item" to="/FacultyProfile">
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="dropdown-item"
                          to="/flogin"
                          onClick={() => sessionStorage.removeItem("Fuser")}
                        >
                          {" "}
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="m-3"
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    {FacultyDetails?.FName}{" "}
                    <div
                      className="d-block"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      ID:{FacultyDetails?._id}
                    </div>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default FacultyAdminHeader;
