import React from "react";

const PG5514aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Number of students placed through campus placement</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_514?.PG_CampName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 report-head  mb-3">
          <span>
            Name of the Activity conducted by the HEI to offer guidance for
            competitive examinations/ career counseling offered by the
            institution during the last five years
          </span>
          <div className="row">
            <div className="col-lg-6 report-head  mt-2">
              <div>
                <span>Name of the Activity</span>
              </div>
              <div>
                <span>Number of students attended / participated</span>
              </div>
            </div>
            <div className="col-lg-6 mt-2">
              <div className="report-text">
                <span>{item?.PG_Criteria05_514?.PG_Nameact}</span>
              </div>
              <div className="report-text">
                <span> {item?.PG_Criteria05_514?.PG_NamePart}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria05_514?.PG_Desc23}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {/* <a href={`${item?.PG_Criteria05_514?.PG_Link23}`}> */}

            {/* </a> */}

            <a
              href={`https://brightnaac.co.in/PG_Criteria05/${item?.PG_Criteria05_514?.PG_Link23}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG5514aqar;
