import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import exportFromJSON from "export-from-json";
import { DatePicker, Space } from "antd";
import moment from "moment";

const Criteria03313 = () => {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [View, setView] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  // ================date filter=====================

  const navigate = useNavigate();

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("3.1.3");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          Year: item?.Year,
          "Number of Participants": item?.Criteria03_313?.NPati,
          "Date (From)": moment(item?.Criteria03_313?.FDate).format(
            "DD-MM-YYYY"
          ),
          "Date (To)": moment(item?.Criteria03_313?.TDate).format("DD-MM-YYYY"),
          "Name of the Workshop/Seminar/Conference":
            item?.Criteria03_313?.NWorkshop,
          Description: item?.Criteria03_313?.Desc13,
          Document: item?.Criteria03_313?.Link13,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================
  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        pricipalDetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
        item1?.approve === "true" &&
        selectedYear === item1?.Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 3 });
  };

  //  ===========================================================================================

  const [content, setContent] = useState([]);
  const [selectedYear, setselectedYear] = useState("");
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/ugcri3_313"
      );
      if (res.status === 200) {
        setContent(res.data.getC313);
        setdata1(res.data.getC313);
        setfilterPdf(res.data.getC313);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);
  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);

  console.log(content);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-03-313
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>
            <div className=" col-lg-2 lab text-end">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = content?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year &&
                      (SearchItem === "" ||
                        item1?.Criteria03_313?.criteria?.includes(SearchItem) ||
                        item1?.Year?.includes(SearchItem) ||
                        item1?.Criteria03_313?.NPati?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.FDate?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.TDate?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.NWorkshop?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.Desc12?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.Link12?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });
                  // console.log(sendData);
                  if (arr1?.length > 0) {
                    navigate("/PDFprintUG3_313", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4 align-items-center">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="col-lg-9">
            <div>
              <Nav defaultActiveKey="">
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-1"
                    onClick={() => navigate("/UGCri03312")}
                  >
                    <Button className="criteriabtn btn-sub1">
                      3.1.1 & 3.1.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-2"
                    onClick={() => navigate("/UGCri03313")}
                  >
                    <Button
                      className="criteriabtn-0  btn-sub"
                      variant="warning"
                      style={{ backgroundColor: "#ffc107" }}
                    >
                      3.1.3
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-3"
                    onClick={() => navigate("/UGCri03321")}
                  >
                    <Button className="criteriabtn  btn-sub">3.2.1</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-4"
                    onClick={() => navigate("/UGCri03322")}
                  >
                    <Button className="criteriabtn  btn-sub">3.2.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-5"
                    onClick={() => navigate("/UGCri03332")}
                  >
                    <Button style={{}} className="criteriabtn  btn-sub">
                      {" "}
                      3.3.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-5"
                    onClick={() => navigate("/UGCri03333")}
                  >
                    <Button style={{}} className="criteriabtn btn-sub">
                      {" "}
                      3.3.3
                    </Button>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-5"
                    onClick={() => navigate("/UGCri03341")}
                  >
                    <Button style={{}} className="criteriabtn  btn-sub">
                      {" "}
                      3.4.1
                    </Button>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-5"
                    onClick={() => navigate("/UGCri03342")}
                  >
                    <Button style={{}} className="criteriabtn  btn-sub">
                      {" "}
                      3.4.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              // style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Number of Participants</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Name of the workshop/ seminar/ conference</th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year &&
                      (SearchItem === "" ||
                        item1?.Criteria03_313?.criteria?.includes(SearchItem) ||
                        item1?.Year?.includes(SearchItem) ||
                        item1?.Criteria03_313?.NPati?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.FDate?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.TDate?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.NWorkshop?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.Desc13?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.Link13?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?.Year}</td>
                        <td>{item?.Criteria03_313?.criteria}</td>
                        <td>{item?.Criteria03_313?.NPati}</td>
                        <td>
                          {moment(item?.Criteria03_313?.FDate).format(
                            "DD-MM_YYYY"
                          )}
                        </td>
                        <td>
                          {moment(item?.Criteria03_313?.TDate).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{item?.Criteria03_313?.NWorkshop}</td>
                        <td style={{ textAlign: "justify" }}>
                          {item?.Criteria03_313?.Desc13?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow10();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow2();
                            }}
                            fontSize={20}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_313?.Link13}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Criteria03_313?.Desc13}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Criteria03313;
