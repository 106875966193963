import React, { useEffect, useState } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";

function AddYears() {
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));

  // ================ add year modal===============================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================ edit year modal===============================

  const [ViewYears, setViewYears] = useState({});
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================ delete year modal===============================

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================ set date function datepicker ===============================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  // ================ calling api to add years ===============================
  const addYears = async (e) => {
    e.preventDefault();
    if (!FromDate || !toDate) {
      return alert("Please fill all the fields!!!");
    }
    try {
      const config = {
        url: "/admin/addAcadYears",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          from: FromDate,
          to: toDate,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          getAllYears();
          setFromDate("");
          settoDate("");
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // ================ calling api to get all added years ===============================
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ================ calling api to edit years ===============================
  const editYears = async (e, id) => {
    e.preventDefault();
    if (!FromDate || !toDate) {
      return alert("Please fill all the fields!!!");
    }
    try {
      const config = {
        url: "/admin/editYears/" + id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          from: FromDate,
          to: toDate,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAllYears();
          setFromDate("");
          settoDate("");
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // ================ calling api to delete years ===============================
  const deleteYears = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/deleteYears/" + id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAllYears();
          setFromDate("");
          settoDate("");
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // ==========================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row justify-content-bteween align-items-center  mt-4 mb-4">
        <div className="col-lg-11 ">
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            Add Years
          </span>
        </div>
        <div className="col-lg-1">
          <Button className="btn-success" onClick={handleShow}>
            Add+
          </Button>
        </div>
      </div>
      <div>
        <Table striped bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>S.no.</th>
              <th>Academic years</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allYears
              ?.sort((a, b) => {
                return Number(a?.from) - Number(b?.from);
              })
              ?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>
                      {item?.from} - {item?.to}
                    </td>
                    <td>
                      <BiEdit
                        size={20}
                        style={{ color: "Blue", margin: "10px" }}
                        onClick={() => {
                          setViewYears(item);
                          handleShow1();
                        }}
                      />
                      <AiOutlineDelete
                        size={20}
                        style={{ color: "red", margin: "10px" }}
                        onClick={() => {
                          setViewYears(item);
                          handleShow2();
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>

      {/* ============================Add Acadmic year===================================== */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Academic year</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>From</label>
            <DatePicker
              onChange={onChangeFrom}
              picker="year"
              className="vi_0"
            />
          </div>
          <div>
            <label>To</label>

            <DatePicker onChange={onChangeTo} picker="year" className="vi_0" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => addYears(e)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ============================edit Acadmic year===================================== */}

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Academic year</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>From</label>
            <DatePicker
              onChange={onChangeFrom}
              placeholder={ViewYears?.from}
              picker="year"
              className="vi_0"
            />
          </div>
          <div>
            <label>To</label>
            <DatePicker
              onChange={onChangeTo}
              picker="year"
              className="vi_0"
              placeholder={ViewYears?.to}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => editYears(e, ViewYears?._id)}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ============================delete Academic year===================================== */}

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Academic year</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to parmanently delete the date???
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => deleteYears(e, ViewYears?._id)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddYears;
