import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
function AddFaculty() {
  let principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Fname, setFname] = useState("");
  const [PhoneNo, setPhoneNo] = useState();
  const [Email, setEmail] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Gtype, setGtype] = useState("");
  const [Ftype, setFtype] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  const [addFaculty, setFacultyAdded] = useState([]);
  const getallregisteruser = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/paf/getallpusers"
      );
      if (res.status === 200) {
        setFacultyAdded(res.data.AddFaculty);
      }
    } catch (error) {
      console.log(error);
      setFacultyAdded(error.response.data.AddFaculty);
    }
  };
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;
  var F_Name = /^[a-zA-Z][a-zA-Z]/;
  const add = async (e) => {
    e.preventDefault();
    if (!Fname) {
      return alert("Enter Your name");
    }
    if (!Fname.match(F_Name)) {
      return alert("Please Enter Valid Name");
    }
    if (!Email) {
      return alert("Enter Your Email");
    }
    if (!Email.match(validRegex)) {
      return alert("Please Enter Valid Email-Id");
    }
    if (!PhoneNo) {
      return alert("Enter Contact Number");
    }
    if (PhoneNo.length !== 10) {
      return alert("Enter Contact Number should be 10 digits");
    }

    if (!PhoneNo.match(mobilevalid)) {
      return alert("Enter Valid Contact Number");
    }
    if (!Designation) {
      return alert("Select Your Designation");
    }

    if (!Gtype) {
      return alert("Select Your Gender");
    }

    if (!Ftype) {
      return alert("Select Your Faculty Type");
    }

    try {
      const config = {
        url: "/paf/paddfaculty/",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          FName: Fname,
          PNumber: PhoneNo,
          Email: Email,
          Designation: Designation,
          Gender: Gtype,
          ftype: Ftype,
          facultyAddedBy: principalDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getallregisteruser();
          handleClose();
          setFname("");
          setPhoneNo("");
          setEmail("");
          setDesignation("");
          setGtype("");
          setFtype("");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const block = async (userId) => {
    try {
      const config = {
        url: "/paf/blockUnblockFUser/" + userId,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getallregisteruser();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getallregisteruser();
    }
  }, []);

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <h5 className="mt-4">Faculty List</h5>
          <div
            className="ad-b mt-4"
            style={{
              display: "flex",
              gap: "21px",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <div className="do-sear mt-2 d-flex ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>
            <div className="">
              {" "}
              <Button className="btn-success" onClick={handleShow}>
                Add Faculty
                <i
                  class="fa-solid fa-plus fa-sm"
                  style={{ color: "#ffffff" }}
                ></i>
              </Button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th> Faculty Name</th>
                  <th>Email</th>
                  <th>Phone No</th>
                  <th>Faculty Type</th>
                  <th>Gender</th>
                  <th>Designation</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {addFaculty
                  ?.filter((item1) => {
                    if (
                      item1?.facultyAddedBy === principalDetails?._id &&
                      (SearchItem == "" ||
                        item1?.FName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Email?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.PNumber?.toString()?.includes(SearchItem) ||
                        item1?.ftype
                          ?.toLowerCase()
                          ?.includes(SearchItem?.toLowerCase()) ||
                        item1?.Gender?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Designation?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ))
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.FName}</td>
                        <td>{item?.Email}</td>
                        <td>{item?.PNumber}</td>
                        <td>{item?.ftype}</td>
                        <td>{item?.Gender}</td>
                        <td>{item?.Designation}</td>
                        <td>
                          {item?.isBlocked == false ? (
                            <>
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: "green",
                                  fontSize: "20px",
                                }}
                                onClick={() => {
                                  block(item._id);
                                }}
                              >
                                <AiFillUnlock />
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: "red",
                                  fontSize: "31px",
                                }}
                                onClick={() => {
                                  block(item._id);
                                }}
                              >
                                <AiFillLock />
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
        {/* Add Model */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Add Faculty</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row m-0 p-0 ">
              <div className="col-lg-12">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter name"
                  className="vi_0"
                  onChange={(e) => setFname(e.target.value)}
                />
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-lg-12">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter email"
                  className="vi_0"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className=" row p-0 m-0 ad_exp align-items-center ">
              <div className="col-lg-12">
                <label>Gender</label>
                <div className="d-flex align-items-center justify-content-start">
                  <div className="d-flex">
                    <input
                      className="vi_0"
                      type="radio"
                      name="gender"
                      value="male"
                      onChange={(e) => setGtype(e.target.value)}
                    />
                    <label className="" style={{ marginInline: "5px" }}>
                      Male
                    </label>
                  </div>
                  <div className="d-flex" style={{ marginLeft: "20px" }}>
                    <input
                      className="vi_0"
                      type="radio"
                      name="gender"
                      value="female"
                      onChange={(e) => setGtype(e.target.value)}
                    />
                    <label style={{ marginInline: "5px" }}>Female</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-0 p-0">
              <div className="col-lg-12">
                <label>Phone no</label>
                <input
                  type="number"
                  placeholder="Enter phone no "
                  className="vi_0"
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </div>
            </div>

            <div className="row m-0 p-0">
              <div className="col-lg-12">
                <label>Faculty Type</label>
                <select
                  onChange={(e) => setFtype(e.target.value)}
                  className="vi_0"
                >
                  <option className="vi_0">Select option</option>
                  <option className="vi_0">Faculty</option>
                  <option className="vi_0">Library</option>
                </select>
              </div>
            </div>

            <div className="row m-0 p-0">
              <div className="col-lg-12">
                <label>Designation</label>
                <input
                  type="email"
                  placeholder="Enter Designation"
                  className="vi_0"
                  onChange={(e) => setDesignation(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={(e) => add(e)}>
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AddFaculty;
