import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";

const Ucriteria7 = () => {
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
  console.log("Apprve Details", approvedetails);
  const [selectedYear, setselectedYear] = useState("");

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Uni_Criteria07, setUni_Criteria07] = useState([]);
  const [Value, setValue] = useState({});
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);
  const [remark, setremark] = useState("");
  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Cri7.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          //  Criteria 1_113

          Uni_Year: item?.Uni_Year,

          "Total Lighting requirements": item?.Uni_Criteria07_007?.Uni_Lighting,
          "Budget allocated for green initiatives:":
            item?.Uni_Criteria07_007?.Uni_Budget,
          "Physical Facilities:": item?.Uni_Criteria07_007?.Uni_PhyFac,
          "Provision for Lift:": item?.Uni_Criteria07_007?.Uni_Prolift,

          "Ramp/ Rails:": item?.Uni_Criteria07_007?.Uni_Ramp,
          "Braille Software/Facilities:": item?.Uni_Criteria07_007?.Uni_Braille,
          "Rest Rooms:": item?.Uni_Criteria07_007?.Uni_RestRoom,
          "Scribes for Examination": item?.Uni_Criteria07_007?.Uni_Scribes,
          "Special skill Development for Differently Abled Students:":
            item?.Uni_Criteria07_007?.Uni_SpecialSkills,
          "Any other Similar Facility:": item?.Uni_Criteria07_007?.Uni_AnyOther,
          "Annual expenditure excluding salary component of the institution":
            item?.Uni_Criteria07_007?.Uni_Annual,

          "Number of initiatives to address locational Advantages and Disadvantages":
            item?.Uni_Criteria07_007?.Uni_NoOfIniti1,
          "Number of initiatives taken to Engage with and contribute to Local Community":
            item?.Uni_Criteria07_007?.Uni_NoOfIniti2,
          "Date and Duration of the Initiative Name of the Initiative Issues Addressed":
            item?.Uni_Criteria07_007?.Uni_DateDuration,

          "Power Requirement met by Renewable Energy Sources":
            item?.Uni_Criteria07_007?.Uni_Power,
          "Total Power requirements": item?.Uni_Criteria07_007?.Uni_TPower,
          "Renewable Energy Source:": item?.Uni_Criteria07_007?.Uni_ReEnergyS,
          "Renewable Energy Generated and Used :":
            item?.Uni_Criteria07_007?.Uni_ReEnergyG,
          "Energy Supplied to the Grid:":
            item?.Uni_Criteria07_007?.Uni_EnergySup,
          "Percentage Lighting through LED bulbs:":
            item?.Uni_Criteria07_007?.Uni_PercentageLED,
          "Percentage Lighting through other sources":
            item?.Uni_Criteria07_007?.Uni_PercentageLight,
          "Number of Participating Students and Staff:":
            item?.Uni_Criteria07_007?.Uni_NoOfParticipants,
          "No. of Activities:": item?.Uni_Criteria07_007?.Uni_NoOfActivities,
          "TTitle of the Program/Activity:":
            item?.Uni_Criteria07_007?.Uni_TitlePro,

          "Number of Participants:":
            item?.Uni_Criteria07_007?.Uni_NoOfParticipants1,
          "From:": item?.Uni_Criteria07_007?.Uni_Date1,
          "To:": item?.Uni_Criteria07_007?.Uni_Date2,
          "MALE:": item?.Uni_Criteria07_007?.Uni_Male,
          "FEMALE:": item?.Uni_Criteria07_007?.Uni_Female,
          Year: item?.Uni_Criteria07_007?.Uni_Yea,
          Total: item?.Uni_Criteria07_007?.Uni_Total,
          "Power Requirement Renewable": item?.Uni_Criteria07_007?.Uni_PowRe,

          "Initiative names:": item?.Uni_Criteria07_007?.Uni_InitiName,
          Duration_of_the_Initiative:
            item?.Uni_Criteria07_007?.Uni_DurInitiative,
          "Description (min 500 characters)":
            item?.Uni_Criteria07_007?.Uni_Desc29,
          "Document Upload Select file to upload1:":
            item?.Uni_Criteria07_007?.Uni_Link29,
          "Document Upload Select file to upload2:":
            item?.Uni_Criteria07_007?.Uni_Link30,
          "Document Upload Select file to upload3:":
            item?.Uni_Criteria07_007?.Uni_Link31,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  // ============================================================================================
  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        approvedetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
        item1?.approve === "true" &&
        selectedYear === item1?.Uni_Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 7 });
  };

  //  ===========================================================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaUn_1/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUni_cri07details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ============================================================

  const [AllDetails, setAllDetails] = useState([]);

  const getUni_cri07details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUni_cri07details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.unicriteria07);
        setdata1(res.data.unicriteria07);
        setfilterPdf(res.data.unicriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria07);
    }
  };

  useEffect(() => {
    getUni_cri07details();
  }, []);
  console.log(Value);

  // =================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================

  // ================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getUni_cri07details();
      getAllYears();
    }
  }, []);
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);
  console.log(AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-8 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-07
              </span>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <Button className="btn btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>Criteria</th>
                  <th>Total Lighting requirements</th>
                  <th>Budget allocated for green initiatives:</th>
                  <th>Physical Facilities:</th>
                  <th>Provision for Lift:</th>
                  <th>Ramp/ Rails:</th>
                  <th>Braille Software/Facilities:</th>
                  <th>Rest Rooms:</th>
                  <th>Scribes for Examination</th>
                  <th>
                    Special skill Development for Differently Abled Students:
                  </th>
                  <th>Any other Similar Facility:</th>
                  <th>
                    Annual expenditure excluding salary component of the
                    institution
                  </th>
                  <th>
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </th>
                  <th>
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </th>
                  <th>
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </th>
                  <th>Power Requirement met by Renewable Energy Sources</th>
                  <th>Total Power requirements</th>
                  <th>Renewable Energy Source:</th>
                  <th>Renewable Energy Generated and Used :</th>
                  <th>Energy Supplied to the Grid:</th>
                  <th>Percentage Lighting through LED bulbs:</th>
                  <th>Percentage Lighting through other sources</th>
                  <th>Number of Participating Students and Staff:</th>
                  <th>No. of Activities:</th>
                  <th>TTitle of the Program/Activity:</th>
                  <th>Number of Participants:</th>
                  <th>From:</th>
                  <th>To:</th>
                  <th>MALE:</th>
                  <th>FEMALE:</th>
                  <th>Year</th>
                  <th>Total</th>
                  <th>Power Requirement Renewable</th>
                  <th>Initiative names:</th>
                  <th>Duration_of_the_Initiative</th>
                  <th>Description (min 500 characters)</th>
                  <th>Document Upload Select file to upload:</th>
                  <th>Document Upload Select file to upload:</th>
                  <th>Document Upload Select file to upload:</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    (item1?.approve === "Pending" ||
                      item1?.approve === "true") &&
                    selectedYear === item1?.Year &&
                    (SearchItem === "" ||
                      item1?.Year?.includes(SearchItem) ||
                      item1?.Uni_Criteria07_007?.Uni_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Lighting?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PhyFac?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Prolift?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Ramp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Braille?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_RestRoom?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Scribes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_SpecialSkills?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_AnyOther?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Annual?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfIniti1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfIniti2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_DateDuration?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Power?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_TPower?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_ReEnergyS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_ReEnergyG?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_EnergySup?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PercentageLED?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PercentageLight?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfParticipants?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfActivities?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_TitlePro?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_NoOfParticipants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Uni_Criteria07_007?.Uni_Date1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Uni_Criteria07_007?.Uni_Date2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria07_007?.Uni_Male?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Female?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Yea?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_PowRe?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_InitiName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_DurInitiative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria07_007?.Uni_Desc29?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>

                      <td>{item?.Uni_Criteria07_007?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Lighting}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Budget}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PhyFac}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Prolift}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Ramp}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Braille}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_RestRoom}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Scribes}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_SpecialSkills}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_AnyOther}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Annual}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfIniti1}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfIniti2}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_DateDuration}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Power}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_TPower}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_ReEnergyS}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_ReEnergyG}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_EnergySup}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PercentageLED}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PercentageLight}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfParticipants}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfActivities}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_TitlePro}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfParticipants1}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Date1}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Date2}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Male}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Female}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Yea}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Total}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PowRe}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_InitiName}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_DurInitiative}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Desc29}</td>
                      <td>
                        <img
                          src={`https://brightnaac.co.in/Uni_Criteria07/${item?.Uni_Criteria07_007?.Uni_Link29}`}
                          alt="img"
                          width={50}
                        />
                      </td>

                      <td>
                        <img
                          src={`https://brightnaac.co.in/Uni_Criteria07/${item?.Uni_Criteria07_007?.Uni_Link30}`}
                          alt="img"
                          width={50}
                        />
                      </td>

                      <td>
                        <img
                          src={`https://brightnaac.co.in/Uni_Criteria07/${item?.Uni_Criteria07_007?.Uni_Link31}`}
                          alt="img"
                          width={50}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 7 </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div
                className="row border "
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                {" "}
                <h6>Criteria07</h6>{" "}
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Lighting requirements
                    </label>
                    <br />
                    <>{Value?.Uni_Criteria07_007?.Uni_Lighting}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Budget allocated for green initiatives:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Budget}</>
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Status of implementation of CBCS / Elective Course System
                  (ECS):
                </h6>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Physical Facilities:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_PhyFac}</>
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Provision for Lift:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Prolift}</>
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Ramp/ Rails:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Ramp}</>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Braille Software/Facilities:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Braille}</>
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Rest Rooms:{" "}
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_RestRoom}</>
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Scribes for Examination
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Scribes}</>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Special skill Development for Differently Abled Students:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_SpecialSkills}</>
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Any other Similar Facility:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_AnyOther}</>
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Annual expenditure excluding salary component of the
                    institution
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_Annual}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_NoOfIniti1}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_NoOfIniti2}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_DateDuration}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement met by Renewable Energy Sources
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_Power}</>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Power requirements
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_TPower}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Source:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_ReEnergyS}</>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Generated and Used :
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_ReEnergyG}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Energy Supplied to the Grid:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_EnergySup}</>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Percentage Lighting through other sources
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_PercentageLight}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participating Students and Staff:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_NoOfParticipants}</>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Percentage Lighting through LED bulbs:
                      </label>
                      <br />

                      <>{Value?.Uni_Criteria07_007?.Uni_PercentageLED}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        No. of Activities:
                      </label>
                      <br />

                      <>{Value?.Uni_Criteria07_007?.Uni_NoOfActivities}</>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      TTitle of the Program/Activity:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_TitlePro}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participants:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_NoOfParticipants1}</>
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Date and Duration:
                </h6>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      From:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Date1}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      To:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Date2}</>
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of participants by Gender:
                </h6>
                <div className="row">
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      MALE:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Male}</>
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      FEMALE:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Female}</>
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Yea}</>
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_Total}</>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Power Requirement Renewable
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_PowRe}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Initiative names:
                    </label>
                    <br />

                    <>{Value?.Uni_Criteria07_007?.Uni_InitiName}</>
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration_of_the_Initiative
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_DurInitiative}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description (min 50 characters)
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_Desc29}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document Upload Select file to upload:
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_Link29}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Document Upload Select file to upload:
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_Link30}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Document Upload Select file to upload:
                  </label>
                  <br />

                  <>{Value?.Uni_Criteria07_007?.Uni_Link31}</>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    approveCriteria(e, true, Value?._id);
                    handleClose();
                  }}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => handleClosesm()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              onChange={(e) => setremark(e.target.value)}
              style={{ height: "200px" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                approveCriteria(e, false, Value?._id);
                handleClose();
                handleClosesm();
              }}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Ucriteria7;
