import axios from "axios";
import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";

const Faq = () => {
  // =================== calling api for getting the FAQs======================
  const [FAQs, setFAQs] = useState([]);
  const getFaq = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getFAQ");
      if (res.status === 200) {
        setFAQs(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setFAQs(error.response.data.allContent);
    }
  };

  useEffect(() => {
    getFaq();
  }, []);
  return (
    <div className="container">
      <h4 className="text-center mt-4">FAQs</h4>
      <br />
      <Accordion defaultActiveKey="">
        {FAQs?.map((item, i) => {
          return (
            <Accordion.Item eventKey={i}>
              <Accordion.Header>
                <p
                  style={{
                    fontSize: 18,
                    // marginTop: 20,

                    textAlign: "justify",
                  }}
                >
                  {item?.question}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p
                  style={{
                    fontSize: 18,
                    // marginTop: 20,
                    padding: "10px 10px ",
                    textAlign: "justify",
                  }}
                >
                  {item?.answer}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
        {/* <Accordion.Item eventKey="1">
          <Accordion.Header>
            <p
              style={{
                fontSize: 18,
                // marginTop: 20,

                textAlign: "justify",
              }}
            >
              Can I just enroll in a single course? I'm not interested in the
              entire Specialization.
            </p>
          </Accordion.Header>
          <Accordion.Body>
            <p
              style={{
                fontSize: 18,
                // marginTop: 20,
                padding: "10px 10px ",
                textAlign: "justify",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <p
              style={{
                fontSize: 18,
                // marginTop: 20,

                textAlign: "justify",
              }}
            >
              What background knowledge is necessary?
            </p>
          </Accordion.Header>
          <Accordion.Body>
            <p
              style={{
                fontSize: 18,
                // marginTop: 20,
                padding: "10px 10px ",
                textAlign: "justify",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <p
              style={{
                fontSize: 18,
                // marginTop: 20,

                textAlign: "justify",
              }}
            >
              Do I need to take the courses in a specific order?
            </p>
          </Accordion.Header>
          <Accordion.Body>
            <p
              style={{
                fontSize: 18,
                padding: "10px 10px ",
                textAlign: "justify",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <p
              style={{
                fontSize: 18,
                // marginTop: 20,

                textAlign: "justify",
              }}
            >
              Do I need to take the courses in a specific order?
            </p>
          </Accordion.Header>
          <Accordion.Body>
            <p
              style={{
                fontSize: 18,
                padding: "10px 10px ",
                textAlign: "justify",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <p
              style={{
                fontSize: 18,
                // marginTop: 20,

                textAlign: "justify",
              }}
            >
              Do I need to take the courses in a specific order?
            </p>
          </Accordion.Header>
          <Accordion.Body>
            <p
              style={{
                fontSize: 18,
                padding: "10px 10px ",
                textAlign: "justify",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            <p
              style={{
                fontSize: 18,
                // marginTop: 20,

                textAlign: "justify",
              }}
            >
              Do I need to take the courses in a specific order?
            </p>
          </Accordion.Header>
          <Accordion.Body>
            <p
              style={{
                fontSize: 18,
                padding: "10px 10px ",
                textAlign: "justify",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            <p
              style={{
                fontSize: 18,
                // marginTop: 20,

                textAlign: "justify",
              }}
            >
              Do I need to take the courses in a specific order?
            </p>
          </Accordion.Header>
          <Accordion.Body>
            <p
              style={{
                fontSize: 18,
                padding: "10px 10px ",
                textAlign: "justify",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Accordion.Body>
        </Accordion.Item> */}
      </Accordion>
      <br />
    </div>
  );
};

export default Faq;
