import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

const PostGraduateCri6 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const pgCriteria06Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/pgadmin/getpgcri6"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria06);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria06);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/pgadmin/addRatingpg6`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          PG_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          pgCriteria06Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      pgCriteria06Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="mt-4"> PG Criteria6 </h4>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="mt-4">Current Rating: </h6>
                <div className="mt-4">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByPri &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center">
            <div className="ad-b mt-4 row align-items-center">
              <div className=" col-lg-4 do-sear mt-4 d-flex ">
                <input
                  type="text"
                  placeholder="Search"
                  className="vi_0"
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {/* <button>Search</button> */}
              </div>

              <div className=" col-lg-2 lab">
                <label>From</label>
                <br />
                <DatePicker
                  onChange={onChangeFrom}
                  picker="year"
                  className="vi_0"
                />
              </div>
              <div className=" col-lg-2 lab">
                <label>To</label>
                <br />
                <DatePicker
                  onChange={onChangeTo}
                  picker="year"
                  className="vi_0"
                />
              </div>

              {/* <div className=" col-lg-2 lab mt-4">
              <Button>Submit</Button>
            </div> */}
              {/* <div className=" col-lg-2 text-end mt-4">
              <Button>Export Excel</Button>
            </div> */}
            </div>
            <div className="row">
              <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
                <label style={{ width: "130px" }}>Select Year</label>
                <select
                  className="criteriaform "
                  style={{ width: "150px" }}
                  onChange={(e) => setselectedYear(e.target.value)}
                >
                  {allYears
                    ?.sort((a, b) => {
                      return Number(b?.from) - Number(a?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg-5">
                <Nav defaultActiveKey="/pgcriteria06">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-1"
                      onClick={() =>
                        navigate(`/adminclgpgcri6623/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">6.2.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-2"
                      onClick={() =>
                        navigate(`/adminclgpgcri6632/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">6.3.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri6633/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">6.3.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri6634/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">6.3.4</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri6642/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">6.4.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgpgcri6653/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">6.5.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              {/* <div className="col-lg-2">
                <Button
                  type="Submit"
                  className="btn btn-warning"
                  style={{ color: "white" }}
                  onClick={() => {
                    // setValue(item);
                    handleShow();
                  }}
                >
                  Add Rating
                </Button>
              </div> */}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 w-100"></div>
        </div>
        <div className="container">
          <div className="mo-gra mt-2">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 6.2.3 */}
                  <th>Criteria</th>
                  <th>Administration</th>
                  <th>Finance and Accounts</th>
                  <th>Student Admissions and Support</th>
                  <th>Examinations</th>
                  <th>Description</th>

                  {/* 6.3.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher</th>
                  <th>Amount of support received (in INR)</th>
                  <th>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </th>
                  <th>
                    Name of the professional body for which membership fee is
                    provided
                  </th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  {/* 6.3.3 */}
                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>
                    Title of the professional development/ administrative
                    training programs organised for teaching staff (Professional
                    development/ administrative training programs)
                  </th>
                  <th>No. of participants</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  {/* 6.3.4 */}
                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>Name of teacher who attended</th>
                  <th>Title of the program</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  {/* 6.4.2 */}
                  <th>Criteria</th>
                  <th>Purpose of the Grant</th>
                  <th>Funds/ Grants received (INR in lakhs)</th>
                  <th>
                    Name of the non government funding agencies/ individuals
                  </th>
                  <th>Description</th>
                  <th>Audited Statement of Accounts reflecting the receipts</th>

                  {/* 6.5.3 */}
                  <th>Criteria</th>
                  <th>
                    Orientation programme on quality issues for teachers and
                    students
                  </th>
                  <th>Participation in NIRF along with Status</th>
                  <th>
                    Academic Administrative Audit (AAA) and follow up action
                  </th>
                  <th>ISO Certification. and nature and validity period</th>
                  <th>
                    NBA or any other certification received with program
                    specifications
                  </th>
                  <th>Conferences, Seminars, Workshops on quality conducted</th>
                  <th>
                    Collaborative quality initiatives with other institution(s)
                    (Provide name of the institution and activity)
                  </th>
                  <th>Description</th>
                  <th>Relevant document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByPri &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Year &&
                    (SearchItem === "" ||
                      item1?.Year?.toString()?.includes(SearchItem) ||
                      item1?.PG_Criteria06_623?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_yoi1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_yoi2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_yoi3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_yoi4?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_623?.PG_desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_632?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_632?.PG_Not?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_632?.PG_Asr?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_632?.PG_NOC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_632?.PG_Nop?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_632?.PG_desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_633?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria06_633?.PG_Sdate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria06_633?.PG_Edate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria06_633?.PG_titlep?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_633?.PG_Nfp?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_633?.PG_desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_634?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria06_634?.PG_Sdate1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria06_634?.PG_Edate1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria06_634?.PG_namet?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_634?.PG_prgm50?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_634?.PG_desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_642?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_642?.PG_pgrant?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_642?.PG_Fundg?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria06_642?.PG_namefgov?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_642?.PG_desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria06_653?.PG_Sdate3)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria06_653?.PG_Edate3)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria06_653?.PG_nirfs?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_aaa?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_isoc?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_nba?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_cswq?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_iaa?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria06_653?.PG_desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                }).map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{item?._id}</td>
                      <td>{item?.Year}</td>

                      <td>{item?.PG_Criteria06_623?.PG_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Relevant document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria06_623?.PG_yoi1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow1();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Relevant document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria06_623?.PG_yoi2}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow2();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Relevant document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria06_623?.PG_yoi3}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow3();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Relevant document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.PG_Criteria06_623?.PG_yoi4}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow4();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.PG_Criteria06_623?.PG_desc1}</td>

                      <td>{item?.PG_Criteria06_632?.PG_criteria}</td>
                      <td>{item?.PG_Criteria06_632?.PG_Not}</td>
                      <td>{item?.PG_Criteria06_632?.PG_Asr}</td>
                      <td>{item?.PG_Criteria06_632?.PG_NOC}</td>
                      <td>{item?.PG_Criteria06_632?.PG_Nop}</td>
                      <td>{item?.PG_Criteria06_632?.PG_desc2}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_633?.PG_criteria}</td>
                      <td>
                        {moment(item?.PG_Criteria06_633?.PG_Sdate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.PG_Criteria06_633?.PG_Edate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria06_633?.PG_titlep}</td>
                      <td>{item?.PG_Criteria06_633?.PG_Nfp}</td>
                      <td>{item?.PG_Criteria06_633?.PG_desc3}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_634?.PG_criteria}</td>
                      <td>
                        {moment(item?.PG_Criteria06_634?.PG_Sdate1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.PG_Criteria06_634?.PG_Edate1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria06_634?.PG_namet}</td>
                      <td>{item?.PG_Criteria06_634?.PG_prgm50}</td>
                      <td>{item?.PG_Criteria06_634?.PG_desc4}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_642?.PG_criteria}</td>
                      <td>{item?.PG_Criteria06_642?.PG_pgrant}</td>
                      <td>{item?.PG_Criteria06_642?.PG_Fundg}</td>
                      <td>{item?.PG_Criteria06_642?.PG_namefgov}</td>
                      <td>{item?.PG_Criteria06_642?.PG_desc5}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria06_653?.PG_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Date (from)</th>
                            <th>Date (to)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {moment(
                                  item?.PG_Criteria06_653?.PG_Sdate2
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {moment(
                                  item?.PG_Criteria06_653?.PG_Edate2
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.PG_Criteria06_653?.PG_nirfs}</td>
                      <td>{item?.PG_Criteria06_653?.PG_aaa}</td>
                      <td>{item?.PG_Criteria06_653?.PG_isoc}</td>
                      <td>{item?.PG_Criteria06_653?.PG_nba}</td>
                      <td>{item?.PG_Criteria06_653?.PG_cswq}</td>
                      <td>{item?.PG_Criteria06_653?.PG_iaa}</td>
                      <td>{item?.PG_Criteria06_653?.PG_desc6}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
              ,
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_623?.PG_doc1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_623?.PG_doc2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_623?.PG_doc3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_623?.PG_doc4}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_632?.PG_doc5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_633?.PG_doc6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_634?.PG_doc7}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_642?.PG_doc8}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/PG_Criteria06/${View?.PG_Criteria06_653?.PG_doc9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PostGraduateCri6;
