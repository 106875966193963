import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Auto_Criteria005 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);
  const [edit8, setedit8] = useState(false);
  const [add8, setadd8] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  //post method for auto_criteria_05
  const [Auto_Year, setAuto_Year] = useState("");

  const [Auto_Scheme, setAuto_Scheme] = useState("");
  const [Auto_Std, setAuto_Std] = useState("");
  const [Auto_Amt, setAuto_Amt] = useState("");
  const [Auto_Std1, setAuto_Std1] = useState("");
  const [Auto_Amt1, setAuto_Amt1] = useState("");
  const [Auto_Std2, setAuto_Std2] = useState("");
  const [Auto_Amt2, setAuto_Amt2] = useState("");
  const [Auto_NGO, setAuto_NGO] = useState("");
  const [Auto_Desc18, setAuto_Desc18] = useState("");
  const [Auto_Link18, setAuto_Link18] = useState("");

  const [Auto_Capabality, setAuto_Capabality] = useState("");
  const [Auto_Date, setAuto_Date] = useState("");
  const [Auto_Enrolled, setAuto_Enrolled] = useState("");
  const [Auto_Agencies, setAuto_Agencies] = useState("");
  const [Auto_Desc19, setAuto_Desc19] = useState("");
  const [Auto_Link19, setAuto_Link19] = useState("");

  const [Auto_Activity, setAuto_Activity] = useState("");
  const [Auto_Attended, setAuto_Attended] = useState("");
  const [Auto_Placed, setAuto_Placed] = useState("");
  const [Auto_Desc20, setAuto_Desc20] = useState("");
  const [Auto_Link20, setAuto_Link20] = useState("");

  const [Auto_Placed1, setAuto_Placed1] = useState("");
  const [Auto_Graduate, setAuto_Graduate] = useState("");
  const [Auto_employer, setAuto_employer] = useState("");
  const [Auto_Package, setAuto_Package] = useState("");
  const [Auto_Desc21, setAuto_Desc21] = useState("");
  const [Auto_Link21, setAuto_Link21] = useState("");

  const [Auto_Higher, setAuto_Higher] = useState("");
  const [Auto_Graduat1, setAuto_Graduat1] = useState("");
  const [Auto_Institute, setAuto_Institute] = useState("");
  const [Auto_Admit, setAuto_Admit] = useState("");
  const [Auto_Desc22, setAuto_Desc22] = useState("");
  const [Auto_Link22, setAuto_Link22] = useState("");

  const [Auto_No, setAuto_No] = useState("");
  const [Auto_RollNo, setAuto_RollNo] = useState("");
  const [Auto_StdSelected, setAuto_StdSelected] = useState("");
  const [Auto_NET, setAuto_NET] = useState("");
  const [Auto_SLET, setAuto_SLET] = useState("");
  const [Auto_GATE, setAuto_GATE] = useState("");
  const [Auto_GMAT, setAuto_GMAT] = useState("");
  const [Auto_CAT, setAuto_CAT] = useState("");
  const [Auto_GRE, setAuto_GRE] = useState("");
  const [Auto_JAM, setAuto_JAM] = useState("");
  const [Auto_IELTS, setAuto_IELTS] = useState("");
  const [Auto_TOEFL, setAuto_TOEFL] = useState("");
  const [Auto_Civil, setAuto_Civil] = useState("");
  const [Auto_State, setAuto_State] = useState("");
  const [Auto_OtherExam, setAuto_OtherExam] = useState("");
  const [Auto_Total, setAuto_Total] = useState("");
  const [Auto_GTotal, setAuto_GTotal] = useState("");
  const [Auto_Desc23, setAuto_Desc23] = useState("");
  const [Auto_Link23, setAuto_Link23] = useState("");

  const [Auto_Medal, setAuto_Medal] = useState("");
  const [Auto_Individual, setAuto_Individual] = useState("");
  const [Auto_Inter, setAuto_Inter] = useState("");
  const [Auto_Event, setAuto_Event] = useState("");
  const [Auto_Student, setAuto_Student] = useState("");
  const [Auto_Desc24, setAuto_Desc24] = useState("");
  const [Auto_Link24, setAuto_Link24] = useState("");

  const [Auto_Event1, setAuto_Event1] = useState("");
  const [Auto_Event2, setAuto_Event2] = useState("");
  const [Auto_Desc25, setAuto_Desc25] = useState("");
  const [Auto_Link25, setAuto_Link25] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getautocriteria5 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));

      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));

      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));

      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));

      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));

      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));

      let am8 = sessionStorage.getItem("cat8");
      setobj8(JSON.parse(am8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getautocriteria5();
  }, []);

  const autocriteria5 = async () => {
    if (!Auto_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/autoadddetails05",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Year: Auto_Year,

          Auto_Criteria05_511: obj1,
          Auto_Link18: Auto_Link18,

          Auto_Criteria05_513: obj2,
          Auto_Link19: Auto_Link19,

          Auto_Criteria05_514: obj3,
          Auto_Link20: Auto_Link20,

          Auto_Criteria05_521: obj4,
          Auto_Link21: Auto_Link21,

          Auto_Criteria05_522: obj5,
          Auto_Link22: Auto_Link22,

          Auto_Criteria05_523: obj6,
          Auto_Link23: Auto_Link23,

          Auto_Criteria05_531: obj7,
          Auto_Link24: Auto_Link24,

          Auto_Criteria05_533: obj8,
          Auto_Link25: Auto_Link25,

          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          sessionStorage.removeItem("cat8");

          navigate("/autocriteria05");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Auto_Scheme ||
          !Auto_Std ||
          !Auto_Amt ||
          !Auto_Std1 ||
          !Auto_Amt1 ||
          !Auto_Std2 ||
          !Auto_Amt2 ||
          !Auto_NGO ||
          !Auto_Desc18 ||
          !Auto_Link18
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Auto_Link18);
      let obj = {
        Auto_criteria: "5.1.1 & 5.1.2",
        Auto_Scheme: Auto_Scheme,
        Auto_Std: Auto_Std,
        Auto_Amt: Auto_Amt,
        Auto_Std1: Auto_Std1,
        Auto_Amt1: Auto_Amt1,
        Auto_Std2: Auto_Std2,
        Auto_Amt2: Auto_Amt2,
        Auto_NGO: Auto_NGO,
        Auto_Desc18: Auto_Desc18,
        Auto_Link18: Auto_Link18,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getautocriteria5();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !Auto_Capabality ||
          !Auto_Date ||
          !Auto_Enrolled ||
          !Auto_Agencies ||
          !Auto_Desc19 ||
          !Auto_Link19
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Auto_Link19);
      let obj = {
        Auto_criteria: "5.1.3",
        Auto_Capabality: Auto_Capabality,
        Auto_Date: Auto_Date,
        Auto_Enrolled: Auto_Enrolled,
        Auto_Agencies: Auto_Agencies,
        Auto_Desc19: Auto_Desc19,
        Auto_Link19: Auto_Link19,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getautocriteria5();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc20;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !Auto_Activity ||
          !Auto_Attended ||
          !Auto_Placed ||
          !Auto_Desc20 ||
          !Auto_Link20
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Auto_Link20);
      let obj = {
        Auto_criteria: "5.1.4",
        Auto_Activity: Auto_Activity,
        Auto_Attended: Auto_Attended,
        Auto_Placed: Auto_Placed,
        Auto_Desc20: Auto_Desc20,
        Auto_Link20: Auto_Link20,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getautocriteria5();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Auto_Placed1 ||
          !Auto_Graduate ||
          !Auto_employer ||
          !Auto_Package ||
          !Auto_Desc21 ||
          !Auto_Link21
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Auto_Link21);
      let obj = {
        Auto_criteria: "5.2.1",
        Auto_Placed1: Auto_Placed1,
        Auto_Graduate: Auto_Graduate,
        Auto_employer: Auto_employer,
        Auto_Package: Auto_Package,
        Auto_Desc21: Auto_Desc21,
        Auto_Link21: Auto_Link21,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getautocriteria5();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc22;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !Auto_Higher ||
          !Auto_Graduat1 ||
          !Auto_Institute ||
          !Auto_Admit ||
          !Auto_Desc22 ||
          !Auto_Link22
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("pppp,", Auto_Link22);
      let obj = {
        Auto_criteria: "5.2.2",
        Auto_Higher: Auto_Higher,
        Auto_Graduat1: Auto_Graduat1,
        Auto_Institute: Auto_Institute,
        Auto_Admit: Auto_Admit,
        Auto_Desc22: Auto_Desc22,
        Auto_Link22: Auto_Link22,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getautocriteria5();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !Auto_No ||
          !Auto_RollNo ||
          !Auto_StdSelected ||
          !Auto_NET ||
          !Auto_SLET ||
          !Auto_GATE ||
          !Auto_GMAT ||
          !Auto_CAT ||
          !Auto_GRE ||
          !Auto_JAM ||
          !Auto_IELTS ||
          !Auto_TOEFL ||
          !Auto_Civil ||
          !Auto_State ||
          !Auto_OtherExam ||
          !Auto_Total ||
          !Auto_GTotal ||
          !Auto_Desc23 ||
          !Auto_Link23
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("sss,", Auto_Link23);
      let obj = {
        Auto_criteria: "5.2.3",
        Auto_No: Auto_No,
        Auto_RollNo: Auto_RollNo,
        Auto_StdSelected: Auto_StdSelected,
        Auto_NET: Auto_NET,
        Auto_SLET: Auto_SLET,
        Auto_GATE: Auto_GATE,
        Auto_GMAT: Auto_GMAT,
        Auto_CAT: Auto_CAT,
        Auto_GRE: Auto_GRE,
        Auto_JAM: Auto_JAM,

        Auto_IELTS: Auto_IELTS,
        Auto_TOEFL: Auto_TOEFL,
        Auto_Civil: Auto_Civil,
        Auto_State: Auto_State,
        Auto_OtherExam: Auto_OtherExam,
        Auto_Total: Auto_Total,
        Auto_GTotal: Auto_GTotal,
        Auto_Desc23: Auto_Desc23,
        Auto_Link23: Auto_Link23,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getautocriteria5();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc24;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !Auto_Medal ||
          !Auto_Individual ||
          !Auto_Inter ||
          !Auto_Event ||
          !Auto_Student ||
          !Auto_Desc24 ||
          !Auto_Link24
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd7(false);
        }
      }

      console.log("ttt,", Auto_Link24);
      let obj = {
        Auto_criteria: "5.3.1",
        Auto_Medal: Auto_Medal,
        Auto_Individual: Auto_Individual,
        Auto_Inter: Auto_Inter,
        Auto_Event: Auto_Event,
        Auto_Student: Auto_Student,
        Auto_Desc24: Auto_Desc24,
        Auto_Link24: Auto_Link24,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getautocriteria5();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat8 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc25;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add8) {
        if (!Auto_Event1 || !Auto_Event2 || !Auto_Desc25 || !Auto_Link25) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd8(false);
        }
      }

      console.log("uuu,", Auto_Link25);
      let obj = {
        Auto_criteria: "5.3.3",
        Auto_Event1: Auto_Event1,
        Auto_Event2: Auto_Event2,
        Auto_Desc25: Auto_Desc25,
        Auto_Link25: Auto_Link25,
      };
      sessionStorage.setItem("cat8", JSON.stringify(obj));
      alert("Successfully added cat8");
      getautocriteria5();
      setedit8(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            Autonomous_Criteria-05
          </span>
        </div>

        <div className="container">
          <div
            className="row-fluid border bs"
            style={{
              padding: "30px",
            }}
          >
            <div class=" col-md-6">
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Auto_Year ? (
                <p>{obj1?.Auto_Year}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setAuto_Year(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.1.1)</h5>

            <div className="row">
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the scheme
                  </label>
                  {obj1?.Auto_Scheme ? (
                    <p>{obj1?.Auto_Scheme}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => setAuto_Scheme(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline">
                Number of students benefited by government scheme and amount
              </h6>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students
                </label>
                {obj1?.Auto_Std ? (
                  <p>{obj1?.Auto_Std}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Std(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Amount
                </label>
                {obj1?.Auto_Amt ? (
                  <p>{obj1?.Auto_Amt}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Amt(e.target.value)}
                  />
                )}
              </div>
              <h6 className="text-center text-decoration-underline">
                Number of students benefited by the institution's schemes and
                amount{" "}
              </h6>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students
                </label>
                {obj1?.Auto_Std1 ? (
                  <p>{obj1?.Auto_Std1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Std1(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Amount
                </label>
                {obj1?.Auto_Amt1 ? (
                  <p>{obj1?.Auto_Amt1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Amt1(e.target.value)}
                  />
                )}
              </div>
              <h6 className="text-center text-decoration-underline">
                Number of students benefited by the non-government agencies
                (NGOs) and amount{" "}
              </h6>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students
                </label>
                {obj1?.Auto_Std2 ? (
                  <p>{obj1?.Auto_Std2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Std2(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Amount
                </label>
                {obj1?.Auto_Amt2 ? (
                  <p>{obj1?.Auto_Amt2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Amt2(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the NGO/agency
                </label>
                {obj1?.Auto_NGO ? (
                  <p>{obj1?.Auto_NGO}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_NGO(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.Auto_Desc18 ? (
                  <p>{obj1?.Auto_Desc18}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc18(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link18(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success  mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_5 (5.1.1)</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Name of the scheme
                      </label>
                      <br />
                      {edit1 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Auto_Scheme}
                            onChange={(e) => setAuto_Scheme(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Auto_Scheme}</>
                      )}
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline">
                    Number of students benefited by government scheme and amount
                  </h6>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Std}
                          onChange={(e) => setAuto_Std(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Std}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Amount
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Amt}
                          onChange={(e) => setAuto_Amt(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Amt}</>
                    )}
                  </div>

                  <h6 className="text-center text-decoration-underline">
                    Number of students benefited by the institution's schemes
                    and amount
                  </h6>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Std1}
                          onChange={(e) => setAuto_Std1(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Std1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Amount
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Amt1}
                          onChange={(e) => setAuto_Amt1(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Amt1}</>
                    )}
                  </div>

                  <h6 className="text-center text-decoration-underline">
                    Number of students benefited by the non-government agencies
                    (NGOs) and amount
                  </h6>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Std2}
                          onChange={(e) => setAuto_Std2(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Std2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Amount
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Amt2}
                          onChange={(e) => setAuto_Amt2(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Amt2}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the NGO/agency
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_NGO}
                          onChange={(e) => setAuto_NGO(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_NGO}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc18}
                          onChange={(e) => setAuto_Desc18(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc18}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload1"
                          accept="image/*"
                          onChange={(e) => setAuto_Link18(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link18?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat1();
                    handleClose();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.1.3)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the capability enhancement program
                </label>
                {obj2?.Auto_Capabality ? (
                  <p>{obj2?.Auto_Capabality}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Capabality(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Date of implementation (DD-MM-YYYY)
                </label>
                {obj2?.Auto_Date ? (
                  <p>{obj2?.Auto_Date}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Date(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students enrolled
                </label>
                {obj2?.Auto_Enrolled ? (
                  <p>{obj2?.Auto_Enrolled}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Enrolled(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-8 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the agencies/consultants involved with contact details
                  (if any)
                </label>
                {obj2?.Auto_Agencies ? (
                  <p>{obj2?.Auto_Agencies}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Agencies(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj2?.Auto_Desc19 ? (
                <p>{obj2?.Auto_Desc19}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc19(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload2">
                {" "}
                Upload the relevant document
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="upload2"
                id="upload2"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link19(e.target.files[0]);
                }}
              />
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow1}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria05_[5.1.3]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the capability enhancement program
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Capabality}
                          onChange={(e) => {
                            setAuto_Capabality(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Capabality}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Date of implementation (DD-MM-YYYY)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="date"
                          name="year"
                          id="name"
                          placeholder={Auto_Date}
                          onChange={(e) => {
                            setAuto_Date(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Date}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students enrolled
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Enrolled}
                          onChange={(e) => setAuto_Enrolled(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Enrolled}</>
                    )}
                  </div>
                  <div className="col-md-8 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the agencies/consultants involved with contact
                      details (if any)
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Agencies}
                          onChange={(e) => setAuto_Agencies(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Agencies}</>
                    )}
                  </div>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Desc19}
                        onChange={(e) => setAuto_Desc19(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Desc19}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        onChange={(e) => setAuto_Link19(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Auto_Link19?.name}</>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit2(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat2();
                    handleClose1();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.1.4)</h5>
            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Activity conducted by the HEI to offer guidance
                  for competitive examinations/ career counselling offered by
                  the institution during the last five years
                </label>

                {obj3?.Auto_Activity ? (
                  <p>{obj3?.Auto_Activity}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Activity(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-5 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of students attended / participated
                </label>
                {obj3?.Auto_Attended ? (
                  <p>{obj3?.Auto_Attended}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Attended(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-7 mt-3">
                <label className="content1">
                  {" "}
                  Number of students placed through campus placement
                </label>
                {obj3?.Auto_Placed ? (
                  <p>{obj3?.Auto_Placed}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Placed(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj3?.Auto_Desc20 ? (
                  <p>{obj3?.Auto_Desc20}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc20(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Upload the relevant document
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload3"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link20(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow2}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[5.1.4]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Activity conducted by the HEI to offer
                      guidance for competitive examinations/ career counselling
                      offered by the institution during the last five years
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Activity}
                          onChange={(e) => {
                            setAuto_Activity(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Activity}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of students attended / participated
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Attended}
                          onChange={(e) => {
                            setAuto_Attended(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Attended}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of students placed through campus placement
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Placed}
                          onChange={(e) => {
                            setAuto_Placed(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Placed}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc20}
                          onChange={(e) => setAuto_Desc20(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc20}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="upload3 "
                          onChange={(e) => setAuto_Link20(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link20?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit3(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat3();
                    handleClose2();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.2.1)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of student placed
                </label>
                {obj4?.Auto_Placed1 ? (
                  <p>{obj4?.Auto_Placed1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Placed1(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Program graduated from
                </label>
                {obj4?.Auto_Graduate ? (
                  <p>{obj4?.Auto_Graduate}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Graduate(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the employer
                </label>
                {obj4?.Auto_employer ? (
                  <p>{obj4?.Auto_employer}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_employer(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Pay package at appointment
                </label>
                {obj4?.Auto_Package ? (
                  <p>{obj4?.Auto_Package}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Package(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj4?.Auto_Desc21 ? (
                <p>{obj4?.Auto_Desc21}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc21(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload4">
                {" "}
                Upload the relevant document
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload4"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link21(e.target.files[0]);
                }}
              />
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow3}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[5.2.1]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of student placed
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Placed1}
                          onChange={(e) => {
                            setAuto_Placed1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Placed1}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program graduated from
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Graduate}
                          onChange={(e) => {
                            setAuto_Graduate(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Graduate}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the employer
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_employer}
                          onChange={(e) => {
                            setAuto_employer(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_employer}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Pay package at appointment
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Package}
                          onChange={(e) => {
                            setAuto_Package(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Package}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <textarea
                          rows={3}
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc21}
                          onChange={(e) => setAuto_Desc21(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc21}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload4"
                          onChange={(e) => setAuto_Link21(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link21?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit4(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat4();
                    handleClose3();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.2.2)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of student enrolling into higher education
                </label>
                {obj5?.Auto_Higher ? (
                  <p>{obj5?.Auto_Higher}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Higher(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Program graduated from
                </label>
                {obj5?.Auto_Graduat1 ? (
                  <p>{obj5?.Auto_Graduat1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Graduat1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of institution joined
                </label>
                {obj5?.Auto_Institute ? (
                  <p>{obj5?.Auto_Institute}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Institute(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of programme admitted to
                </label>
                {obj5?.Auto_Admit ? (
                  <p>{obj5?.Auto_Admit}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Admit(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj5?.Auto_Desc22 ? (
                <p>{obj5?.Auto_Desc22}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc22(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload5">
                {" "}
                Upload the relevant document
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload5"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link22(e.target.files[0]);
                }}
              />
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat5();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow4}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show4} onHide={handleClose4}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[5.2.2]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of student enrolling into higher education
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Higher}
                          onChange={(e) => {
                            setAuto_Higher(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Higher}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program graduated from
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Graduat1}
                          onChange={(e) => {
                            setAuto_Graduat1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Graduat1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of institution joined
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Institute}
                          onChange={(e) => {
                            setAuto_Institute(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Institute}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of programme admitted to
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Admit}
                          onChange={(e) => {
                            setAuto_Admit(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Admit}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <textarea
                          rows={3}
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc22}
                          onChange={(e) => setAuto_Desc22(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc22}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload5">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload5"
                          onChange={(e) => setAuto_Link22(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link22?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit5(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat5();
                    handleClose4();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "20px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5> Criteria_5 (5.2.3)</h5>
            <div className="row">
              <div className="row">
                <div className="row">
                  <div className="col-md-4 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Sl.no.
                    </label>
                    {obj6?.Auto_No ? (
                      <p>{obj6?.Auto_No}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_No(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-8 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Registration number/roll number for the exam
                    </label>
                    {obj6?.Auto_RollNo ? (
                      <p>{obj6?.Auto_RollNo}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_RollNo(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-8 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the students /qualified
                    </label>
                    {obj6?.Auto_StdSelected ? (
                      <p>{obj6?.Auto_StdSelected}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_StdSelected(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-4 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      NET
                    </label>
                    {obj6?.Auto_NET ? (
                      <p>{obj6?.Auto_NET}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_NET(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      SLET
                    </label>
                    {obj6?.Auto_SLET ? (
                      <p>{obj6?.Auto_SLET}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_SLET(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GATE
                    </label>
                    {obj6?.Auto_GATE ? (
                      <p>{obj6?.Auto_GATE}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_GATE(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GMAT
                    </label>
                    {obj6?.Auto_GMAT ? (
                      <p>{obj6?.Auto_GMAT}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_GMAT(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      CAT
                    </label>
                    {obj6?.Auto_CAT ? (
                      <p>{obj6?.Auto_CAT}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_CAT(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GRE
                    </label>
                    {obj6?.Auto_GRE ? (
                      <p>{obj6?.Auto_GRE}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_GRE(e.target.value);
                        }}
                      />
                    )}
                  </div>

                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      JAM
                    </label>
                    {obj6?.Auto_JAM ? (
                      <p>{obj6?.Auto_JAM}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_JAM(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      IELTS
                    </label>
                    {obj6?.Auto_IELTS ? (
                      <p>{obj6?.Auto_IELTS}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_IELTS(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      TOEFL
                    </label>
                    {obj6?.Auto_TOEFL ? (
                      <p>{obj6?.Auto_TOEFL}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_TOEFL(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Civil Services
                    </label>
                    {obj6?.Auto_Civil ? (
                      <p>{obj6?.Auto_Civil}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_Civil(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-9 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      State government examinations
                    </label>
                    {obj6?.Auto_State ? (
                      <p>{obj6?.Auto_State}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_State(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Other examinations conducted by the State / Central
                      Government Agencies (Specify)
                    </label>
                    {obj6?.Auto_OtherExam ? (
                      <p>{obj6?.Auto_OtherExam}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_OtherExam(e.target.value);
                        }}
                      />
                    )}
                  </div>

                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total
                    </label>
                    {obj6?.Auto_Total ? (
                      <p>{obj6?.Auto_Total}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_Total(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Grand Total
                    </label>
                    {obj6?.Auto_GTotal ? (
                      <p>{obj6?.Auto_GTotal}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_GTotal(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description (min 500 words)
                    </label>
                    {obj6?.Auto_Desc23 ? (
                      <p>{obj6?.Auto_Desc23}</p>
                    ) : (
                      <textarea
                        className="vi_0"
                        id="name"
                        cols={57}
                        rows={3}
                        onChange={(e) => {
                          setAuto_Desc23(e.target.value);
                        }}
                      ></textarea>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload6">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />

                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload6"
                      accept="image/*"
                      onChange={(e) => {
                        setAuto_Link23(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add6 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat6();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow5}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show5} onHide={handleClose5}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria05_5.2.3</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <div className="row">
                      <div className="col-md-4 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Sl.no.
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_No}
                              onChange={(e) => {
                                setAuto_No(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_No}</>
                        )}
                      </div>
                      <div className="col-md-8 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Registration number/roll number for the exam
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_RollNo}
                              onChange={(e) => {
                                setAuto_RollNo(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_RollNo}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Name of students selected/ qualified
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_StdSelected}
                              onChange={(e) => {
                                setAuto_StdSelected(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_StdSelected}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          NET
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_NET}
                              onChange={(e) => {
                                setAuto_NET(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_NET}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          SLET
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_SLET}
                              onChange={(e) => {
                                setAuto_SLET(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_SLET}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          GATE
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_GATE}
                              onChange={(e) => {
                                setAuto_GATE(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_GATE}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          GMAT
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_GMAT}
                              onChange={(e) => {
                                setAuto_GMAT(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_GMAT}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          CAT
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_CAT}
                              onChange={(e) => {
                                setAuto_CAT(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_CAT}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          GRE
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_GRE}
                              onChange={(e) => {
                                setAuto_GRE(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_GRE}</>
                        )}
                      </div>

                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          JAM
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_JAM}
                              onChange={(e) => {
                                setAuto_JAM(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_JAM}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          IELTS
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_IELTS}
                              onChange={(e) => {
                                setAuto_IELTS(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_IELTS}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          TOEFL
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_TOEFL}
                              onChange={(e) => {
                                setAuto_TOEFL(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_TOEFL}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Civil Services
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_Civil}
                              onChange={(e) => {
                                setAuto_Civil(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_Civil}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          State government examinations
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_State}
                              onChange={(e) => {
                                setAuto_State(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_State}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Other examinations conducted by the State / Central
                          Government Agencies (Specify)
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_OtherExam}
                              onChange={(e) => {
                                setAuto_OtherExam(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_OtherExam}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Total
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_Total}
                              onChange={(e) => {
                                setAuto_Total(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_Total}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Grand Total
                        </label>{" "}
                        <br /> <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={Auto_GTotal}
                              onChange={(e) => {
                                setAuto_GTotal(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_GTotal}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Description(min 500 words)
                        </label>
                        <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="email"
                              id="name"
                              placeholder={Auto_Desc23}
                              onChange={(e) => setAuto_Desc23(e.target.value)}
                            />
                          </>
                        ) : (
                          <>{Auto_Desc23}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="upload6">
                          {" "}
                          Upload the relevant document
                        </label>
                        <br />
                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="file"
                              name="email"
                              id="upload6"
                              accept="image/*"
                              onChange={(e) => {
                                setAuto_Link23(e.target.files[0]);
                              }}
                            />
                          </>
                        ) : (
                          <>{Auto_Link23?.name}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit6(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat6();
                    handleClose5();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.3.1)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the award/ medal
                </label>

                {obj7?.Auto_Medal ? (
                  <p>{obj7?.Auto_Medal}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Medal(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Team / Individual
                </label>
                {obj7?.Auto_Individual ? (
                  <p>{obj7?.Auto_Individual}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Individual(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  inter-university / state / National/ International
                </label>
                {obj7?.Auto_Inter ? (
                  <p>{obj7?.Auto_Inter}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Inter(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Name of the event</label>
                {obj7?.Auto_Event ? (
                  <p>{obj7?.Auto_Event}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Event(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the student
                </label>
                {obj7?.Auto_Student ? (
                  <p>{obj7?.Auto_Student}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Student(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description min 500 words
                </label>
                {obj7?.Auto_Desc24 ? (
                  <p>{obj7?.Auto_Desc24}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc24(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload8">
                  {" "}
                  Link to website of the Journal
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload8"
                  id="upload8"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link24(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add7 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat7();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow6}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show6} onHide={handleClose6}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[5.3.1]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the award/ medal
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Medal}
                          onChange={(e) => {
                            setAuto_Medal(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Medal}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Team / Individual
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Individual}
                          onChange={(e) => {
                            setAuto_Individual(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Individual}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      inter-university / state / National/ International
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Inter}
                          onChange={(e) => {
                            setAuto_Inter(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Inter}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the event
                    </label>{" "}
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Event}
                          onChange={(e) => {
                            setAuto_Event(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Event}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the student
                    </label>{" "}
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Student}
                          onChange={(e) => {
                            setAuto_Student(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Student}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc24}
                          onChange={(e) => setAuto_Desc24(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc24}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Link to website of the Journal
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload7"
                          id="upload7"
                          onChange={(e) => setAuto_Link24(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link24?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit7(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat7();
                    handleClose6();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.3.3)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Date of event/competition (DD-MM-YYYY)
                </label>

                {obj8?.Auto_Event1 ? (
                  <p>{obj8?.Auto_Event1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Event1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the event/competition
                </label>
                {obj8?.Auto_Event2 ? (
                  <p>{obj8?.Auto_Event2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Event2(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description min 500 words
                </label>
                {obj8?.Auto_Desc25 ? (
                  <p>{obj8?.Auto_Desc25}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc25(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload9">
                  {" "}
                  Upload the relevant document of the Journal
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload9"
                  id="upload9"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link25(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add8 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat8();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow7}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show7} onHide={handleClose7}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[5.3.3]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Date of event/competition (DD-MM-YYYY)
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="date"
                          name="year"
                          id="name"
                          placeholder={Auto_Event1}
                          onChange={(e) => {
                            setAuto_Event1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Event1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the event/competition
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Event2}
                          onChange={(e) => {
                            setAuto_Event2(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Event2}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc25}
                          onChange={(e) => setAuto_Desc25(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc25}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document of the Journal
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload8"
                          id="upload8"
                          onChange={(e) => setAuto_Link25(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link25?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit8(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat8();
                    handleClose7();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => autocriteria5()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Auto_Criteria005;
