import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./googleform.css";
import axios from "axios";
function StudentDashboard() {
  const currentstudent = JSON.parse(sessionStorage.getItem("studentDetails"));
  const Alumni = JSON.parse(sessionStorage.getItem("Alumnistudent"));

  const [SSS, setSSS] = useState([]);
  const getallsssfeedback = async () => {
    try {
      const response = await axios.get(
        "https://brightnaac.co.in/api/student/addsssfeedback"
      );
      if (response.status === 200) {
        setSSS(response.data.success);
      }
    } catch (error) {
      alert("no data present");
    }
  };
  const [SyllanusFeed, setSyllanusFeed] = useState([]);
  const getallsyllabusfeedback = async () => {
    try {
      const response = await axios.get(
        "https://brightnaac.co.in/api/student/syllabusfeedback"
      );
      if (response.status === 200) {
        setSyllanusFeed(response.data.success);
      }
    } catch (error) {
      alert("no data present");
    }
  };

  const [TeacherFeed, setTeacherFeed] = useState([]);
  const getallteacherfeedback = async () => {
    try {
      const response = await axios.get(
        "https://brightnaac.co.in/api/student/teacherfeedback"
      );
      if (response.status === 200) {
        setTeacherFeed(response.data.success);
      }
    } catch (error) {
      alert("no data present");
    }
  };
  useEffect(() => {
    getallsssfeedback();
    getallsyllabusfeedback();
    getallteacherfeedback();
  }, []);

  return (
    <>
      <div className="ad-dash mt-5">
        <div className="container">
          <div className="vina">
            <div className="np-p mt-4">
              {currentstudent ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <Card style={{ width: "18rem" }}>
                        <Card.Body>
                          <Card.Title
                            style={{
                              fontSize: "18px",
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                              borderBottom: "2px solid ",
                              textAlign: "center",
                            }}
                          >
                            Total SSS Feedback
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted text-center">
                            {SSS?.length}
                          </Card.Subtitle>
                          <Card.Text>
                            Contributing to Campus Excellence, Enhancing Student
                            Experiences
                          </Card.Text>
                          <Card.Link href="/studentsatsurveylist">
                            View
                          </Card.Link>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-4">
                      <Card style={{ width: "18rem" }}>
                        <Card.Body>
                          <Card.Title
                            style={{
                              fontSize: "18px",
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                              borderBottom: "2px solid ",
                              textAlign: "center",
                            }}
                          >
                            Total Syllabus Feedback
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted text-center">
                            {SyllanusFeed?.length}
                          </Card.Subtitle>
                          <Card.Text>
                            Collaborating for Curriculum Enhancement, Enriching
                            Learning Outcomes
                          </Card.Text>
                          <Card.Link href="/studentsatsurveylist">
                            View
                          </Card.Link>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="col-md-4">
                      <Card style={{ width: "18rem" }}>
                        <Card.Body>
                          <Card.Title
                            style={{
                              fontSize: "18px",
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                              borderBottom: "2px solid ",
                              textAlign: "center",
                            }}
                          >
                            Total Teacher Feedback
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted text-center">
                            {TeacherFeed?.length}
                          </Card.Subtitle>
                          <Card.Text>
                            Leading the Way in Teacher Development, Empowering
                            Student Progress
                          </Card.Text>
                          <Card.Link href="/teacherfeedbacklist">
                            View
                          </Card.Link>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {Alumni ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <Card style={{ width: "18rem" }}>
                        <Card.Body>
                          <Card.Title
                            style={{
                              fontSize: "18px",
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                              borderBottom: "2px solid ",
                              textAlign: "center",
                            }}
                          >
                            Total Alumni Feedback
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted text-center">
                            {SSS?.length}
                          </Card.Subtitle>
                          <Card.Text>
                            Driving Alumni Impact, Enhancing Institutional
                            Reputation
                          </Card.Text>
                          <Card.Link href="/alumnifeefbacklist">View</Card.Link>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentDashboard;
