import React, { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useState } from "react";
import axios from "axios";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const IiqaMain = () => {
  let principal = JSON.parse(sessionStorage.getItem("Puser"));
  const navigate = useNavigate();

  // ================add final report modal======================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================Show IIQA report modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Generate AQAR Report modal======================
  const [View1, setView1] = useState({});
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [allIIQAReport, setallIIQAReport] = useState([]);

  const getIIQAReport = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/pri/getIIQAReport"
      );
      if (res.status === 200) {
        setallIIQAReport(res.data.IIQAReport);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    principal = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principal) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getIIQAReport();
    }
  }, []);

  const [SearchData, setSearchData] = useState("");
  const [View, setView] = useState({});

  const deleteIIQA = async () => {
    try {
      const config = {
        url: "/deleteIIQA/" + View1?._id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api/pri",
      };
      await axios(config).then(function (res) {
        if ((res.status = 200)) {
          alert("Data deleted successfully");
          window.location.reload();
          console.log(res);
        } else {
          alert(res.status.error);
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to delete Details, Please try after some time");
    }
  };

  // Call the function to generate and download the PDF
  const dataToPrintRef = useRef(null);

  const handlePrint = () => {
    if (dataToPrintRef.current) {
      const content = dataToPrintRef.current.innerHTML;
      const originalDocument = document.body.innerHTML;
      document.body.innerHTML = content;
      window.print();
      document.body.innerHTML = originalDocument;
    }
  };

  console.log("allIIQAReport", allIIQAReport);
  return (
    <div className="add-gr">
      <div className="container">
        <h5 className="mt-4">
          Institutional Information for Quality Assessment(IIQA)
        </h5>
        <div
          className="ad-b mt-4"
          style={{
            display: "flex",
            gap: "21px",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <div className="do-sear mt-2 d-flex ">
            <input
              type="text"
              placeholder="Search"
              className="vi_0"
              value={SearchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>
          <div className="">
            <button
              style={{ backgroundColor: "#393186", marginRight: "10px" }}
              onClick={() => window.location.assign("/iiqareport")}
            >
              Generate IIQA Report
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table
            responsive
            striped
            bordered
            hover
            style={{ textAlign: "center" }}
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Year</th>
                <th>Date of Submission</th>
                <th>AISHE ID</th>
                <th>Institution Track ID</th>
                <th>View</th>
                <th>Delete</th>
                {/* <th>Document Name</th>
                <th>Doc</th> */}
              </tr>
            </thead>
            <tbody>
              {allIIQAReport
                ?.filter(
                  (val) =>
                    val?.CollegeId?._id?.toString() == principal?._id.toString()
                )
                ?.filter(
                  (val) =>
                    SearchData == "" ||
                    val?.InstitutionTrackID?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase()) ||
                    val?.selectedyear
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase()) ||
                    val?.Dateofsubmission?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase()) ||
                    val?.AISHEID?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase())
                )

                .map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{item?.selectedyear}</td>
                      <td>{item?.Dateofsubmission}</td>
                      <td>{item?.AISHEID}</td>
                      <td>{item?.InstitutionTrackID}</td>
                      <td>
                        <BsFileEarmarkPdf
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiFillDelete
                          onClick={() => {
                            setView1(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>

      {/* show model */}
      <Modal show={show1} onHide={handleClose1} className="iiqareportmodal">
        <Modal.Header closeButton>
          <Modal.Title>IIQA Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="pdf" ref={dataToPrintRef}>
            <div style={{ scrollBehavior: "smooth", padding: "20px" }}>
              <div style={{ display: "grid" }}>
                <img
                  src="/header.png"
                  alt="image"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    // width: "150px",
                    // height: "150px",
                  }}
                />
              </div>
              <h6 style={{ textAlign: "center" }}>
                Institutional Information for Quality Assessment(IIQA)
              </h6>
              <h6 style={{ textAlign: "center" }}>{View?.CollegeName}</h6>
              <div
                style={{
                  margin: "20px",
                  marginTop: "50px",
                  marginBottom: "30px",
                }}
              >
                <div className="row">
                  <p
                    className="col-3"
                    style={{ margin: "0px", fontWeight: 600 }}
                  >
                    Date of submission
                  </p>
                  <p className="col-1" style={{ margin: "0px" }}>
                    :
                  </p>
                  <p className="col-2" style={{ margin: "0px" }}>
                    {moment(View?.Dateofsubmission)?.format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="row">
                  <p
                    className="col-3"
                    style={{ margin: "0px", fontWeight: 600 }}
                  >
                    AISHE ID
                  </p>
                  <p className="col-1" style={{ margin: "0px" }}>
                    :
                  </p>
                  <p className="col-2" style={{ margin: "0px" }}>
                    {View?.AISHEID}
                  </p>
                </div>
                <div className="row">
                  <p
                    className="col-3"
                    style={{ margin: "0px", fontWeight: 600 }}
                  >
                    Institution Track ID
                  </p>
                  <p className="col-1" style={{ margin: "0px" }}>
                    :
                  </p>
                  <p className="col-2" style={{ margin: "0px" }}>
                    {View?.InstitutionTrackID}
                  </p>
                </div>
              </div>
              <div>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td rowspan="2" style={{ width: "30px" }}>
                        1
                      </td>
                      <td style={{ width: "50%", fontWeight: "bold" }}>
                        Application For
                      </td>
                      <td style={{ width: "50%" }}>{View?.ApplicationFor}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        Cycle of Accreditation
                      </td>
                      <td>
                        {View?.Cyclename}
                        <table>
                          <tr>
                            <td style={{ width: "100px" }}>Cycle</td>
                            <td style={{ width: "100px" }}>Date</td>
                            <td style={{ width: "100px" }}>Grade</td>
                          </tr>
                          <tr>
                            <td>{View?.CycleNumber}</td>
                            <td>
                              {moment(View?.Cycledate)?.format("DD/MM/YYYY")}
                            </td>
                            <td>{View?.Cyclegrade}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td style={{ fontWeight: "bold" }}>
                        Name of the College
                      </td>
                      <td>{View?.CollegeName}</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td style={{ fontWeight: "bold" }}>
                        Date of establishment of the Institution
                      </td>
                      <td>
                        {moment(View?.Collegeestablishmentdate)?.format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="2">4</td>
                      <td style={{ fontWeight: "bold" }}>
                        Name of the Head of the Institution
                      </td>
                      <td>{View?.Collegeheadname}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Designation</td>
                      <td>{View?.Designation}</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td style={{ fontWeight: "bold" }}>
                        Does the college function from Own Campus
                      </td>
                      <td>{View?.OwnCampus}</td>
                    </tr>
                    <tr>
                      <td rowspan="10">6</td>
                      <td style={{ fontWeight: "bold" }}>
                        Address of the College
                      </td>
                      <td>{View?.Collegeaddress}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>State/UT</td>
                      <td>{View?.CollegeState}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>District</td>
                      <td>{View?.CollegeDistrict}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>City</td>
                      <td>{View?.CollegeCity}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Pin</td>
                      <td>{View?.CollegePin}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Phone No</td>
                      <td>{View?.CollegePhoneNo}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Fax No</td>
                      <td>{View?.CollegeFaxNo}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Mobile No</td>
                      <td>{View?.CollegeMobileNo}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Registered Email</td>
                      <td>{View?.CollegeRegisteredEmail}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Alternate Email </td>
                      <td>{View?.CollegeAlternateEmail}</td>
                    </tr>
                    <tr>
                      <td rowspan="10">7</td>
                      <td style={{ fontWeight: "bold" }}>
                        Alternate Faculty Contact Details
                      </td>
                      <td>{View?.AlternateFaculty}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Address</td>
                      <td>{View?.ACollegeaddress}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>State/UT</td>
                      <td>{View?.ACollegeState}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>City</td>
                      <td>{View?.ACollegeCity}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Pin</td>
                      <td>{View?.ACollegePin}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Phone No</td>
                      <td>{View?.ACollegePhoneNo}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Fax No</td>
                      <td>{View?.ACollegeFaxNo}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Mobile No</td>
                      <td>{View?.ACollegeMobileNo}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Email</td>
                      <td>{View?.ACollegeRegisteredEmail}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Alternate Email </td>
                      <td>{View?.ACollegeAlternateEmail}</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td style={{ fontWeight: "bold" }}>Website</td>
                      <td>{View?.Website}</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td style={{ fontWeight: "bold" }}>
                        Has the Institution completed 6 years of existence /
                        Years of graduation of last two batches
                      </td>
                      <td>
                        <p style={{ margin: "0px", fontSize: "12px" }}>
                          {View?.Instc6yoexistence}
                        </p>
                        Year1- {View?.graduationy1} Year2- {View?.graduationy2}
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td style={{ fontWeight: "bold" }}>
                        Nature of the college
                      </td>
                      <td>{View?.Natureofthecollege}</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td style={{ fontWeight: "bold" }}>
                        College Affiliation
                      </td>
                      <td>{View?.CollegeAffiliation}</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td colSpan={2} style={{ fontWeight: "bold" }}>
                        Name of the affiliating University(ies) and the state(s)
                        in which the University(ies) is located
                        <table style={{ width: "90%", margin: "10px" }}>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>State</td>
                            <td style={{ fontWeight: "bold" }}>
                              University Name
                            </td>
                            <td style={{ fontWeight: "bold" }}>Documents</td>
                          </tr>
                          <tr>
                            <td>{View?.affiliating_Uni_State}</td>
                            <td>{View?.affiliating_Uni_Name}</td>
                            <td>
                              <a
                                target="_blank"
                                href={`https://brightnaac.co.in/IIQA/${View?.affiliating_Uni_Doc}`}
                              >
                                View Document
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td style={{ fontWeight: "bold" }}>
                        Is the Institution recognized under section 2(f) of the
                        UGC Act?
                      </td>
                      <td>
                        {View?.section2UGCAct}
                        {/* <p style={{ fontSize: "12px" }}>10/01/1990</p> */}
                        <p style={{ fontSize: "12px" }}>
                          <a
                            target="_blank"
                            href={`https://brightnaac.co.in/IIQA/${View?.section2UGCAct_Doc}`}
                          >
                            View Document
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td style={{ fontWeight: "bold" }}>
                        <p style={{ fontSize: "12px" }}>
                          Is the Institution recognized under section 12B of the
                          UGC Act?
                        </p>
                        <p style={{ fontSize: "12px" }}>
                          If yes, date of recognition by UGC under section 12B
                          along with latest Plan General Development Grant
                          release letter
                        </p>
                      </td>
                      <td>
                        {View?.section12BUGCAct}
                        {/* <p style={{ fontSize: "12px" }}>10/01/1990</p> */}
                        <p style={{ fontSize: "12px" }}>
                          <a
                            target="_blank"
                            href={`https://brightnaac.co.in/IIQA/${View?.section12BUGCAct_Doc}`}
                          >
                            View Document
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td style={{ fontWeight: "bold" }}>
                        Is the institution recognised as an Autonomous College
                        by the UGC?
                      </td>
                      <td> {View?.recognised_Aut_Clg}</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td style={{ fontWeight: "bold" }}>
                        Is the institution recognised as a ‘College with
                        Potential for Excellence (CPE)’ by the UGC?
                      </td>
                      <td> {View?.CPE}</td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td style={{ fontWeight: "bold" }}>
                        Is the institution recognised as a ‘College of
                        Excellence’ by the UGC?
                      </td>
                      <td> {View?.College_Excellence}</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>18</td>
                      <td style={{ fontWeight: "bold" }}>
                        Is the College offering any programmes recognised by any
                        Statutory Regulatory Authority (SRA)
                      </td>
                      <td> {View?.recognised_SRA}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        Statutory Regulatory Authorities
                      </td>
                      <td>
                        <table style={{ width: "80%" }}>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>SRA program</td>
                            <td style={{ fontWeight: "bold" }}>Document</td>
                          </tr>
                          {View?.recognised_SRA === "Yes" ? (
                            <tr>
                              <td>{View?.SRA_program}</td>
                              <td>
                                <a
                                  target="_blank"
                                  href={`https://brightnaac.co.in/IIQA/${View?.SRA_Document}`}
                                >
                                  View Document
                                </a>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={2} style={{ textAlign: "center" }}>
                                No Content
                              </td>
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td style={{ fontWeight: "bold" }}>
                        If the institution is not affiliated to a university and
                        is offering programmes recognized by any Statutory
                        Regulatory Authorities (SRA), are the programmes
                        recognized by Association of Indian Universities(AIU) or
                        other appropriate Government authorities as equivalent
                        to UG / PG Programmes of a University
                      </td>
                      <td>{View?.SRA_AIU}</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td colSpan={2} style={{ fontWeight: "bold" }}>
                        Number of programmes offered
                        <table style={{ width: "90%", margin: "20px" }}>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Programmes</td>
                            <td style={{ fontWeight: "bold" }}>Number</td>
                          </tr>
                          <tr>
                            <td>UG</td>
                            <td>{View?.UG}</td>
                          </tr>
                          <tr>
                            <td>PG</td>
                            <td>{View?.PG}</td>
                          </tr>
                          <tr>
                            <td>
                              Post Master's (DM,Ayurveda Vachaspathi,M.Ch)
                            </td>
                            <td>{View?.Ayurveda}</td>
                          </tr>
                          <tr>
                            <td>Pre Doctoral (M.Phil)</td>
                            <td>{View?.M_Phil}</td>
                          </tr>
                          <tr>
                            <td>Doctoral (Ph.D)</td>
                            <td>{View?.PhD}</td>
                          </tr>
                          <tr>
                            <td>Post Doctoral (D.Sc , D.Litt , LLD)</td>
                            <td>{View?.LLD}</td>
                          </tr>
                          <tr>
                            <td>
                              PG Diploma recognised by statutory authority
                              including university
                            </td>
                            <td>{View?.PG_Diploma}</td>
                          </tr>
                          <tr>
                            <td>Diploma</td>
                            <td>{View?.Diploma}</td>
                          </tr>
                          <tr>
                            <td>Certificate / Awareness</td>
                            <td>{View?.Certificate}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td colSpan={2} style={{ fontWeight: "bold" }}>
                        Programme Details
                        <table style={{ width: "90%", margin: "20px" }}>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Program</td>
                            <td style={{ fontWeight: "bold" }}>Department</td>
                            <td style={{ fontWeight: "bold" }}>
                              University Affiliation
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              SRA Recognition
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              Affiliation Status
                            </td>
                          </tr>
                          {View?.Programme_Array?.map((item) => (
                            <tr>
                              <td>{item?.Program_Name}</td>
                              <td>{item?.Program_Department}</td>
                              <td>{item?.Program_University}</td>
                              <td>{item?.Program_SRA}</td>
                              <td>{item?.Program_Affiliation}</td>
                            </tr>
                          ))}
                        </table>
                        <a
                          target="_blank"
                          href={`https://brightnaac.co.in/IIQA/${View?.Programme_Doc}`}
                        >
                          View Document
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>22</td>
                      <td colSpan={2} style={{ fontWeight: "bold" }}>
                        Number of Teaching Staff by employment status (permanent
                        / temporary) and by gender
                        <table style={{ width: "90%", margin: "20px" }}>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Type</td>
                            <td style={{ fontWeight: "bold" }}>Male</td>
                            <td style={{ fontWeight: "bold" }}>Female</td>
                            <td style={{ fontWeight: "bold" }}>Transgender</td>
                            <td style={{ fontWeight: "bold" }}>Total</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Permanent</td>
                            <td>{View?.Teaching_P_Male}</td>
                            <td>{View?.Teaching_P_Female}</td>
                            <td>{View?.Teaching_P_Transgender}</td>
                            <td>
                              {parseInt(View?.Teaching_P_Male) +
                                parseInt(View?.Teaching_P_Female) +
                                parseInt(View?.Teaching_P_Transgender)}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Temporary</td>
                            <td>{View?.Teaching_T_Male}</td>
                            <td>{View?.Teaching_T_Female}</td>
                            <td>{View?.Teaching_T_Transgender}</td>
                            <td>
                              {parseInt(View?.Teaching_T_Male) +
                                parseInt(View?.Teaching_T_Female) +
                                parseInt(View?.Teaching_T_Transgender)}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>23</td>
                      <td colSpan={2} style={{ fontWeight: "bold" }}>
                        Number of Non-Teaching Staff by employment status
                        (permanent / temporary) and by gender
                        <table style={{ width: "90%", margin: "20px" }}>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Type</td>
                            <td style={{ fontWeight: "bold" }}>Male</td>
                            <td style={{ fontWeight: "bold" }}>Female</td>
                            <td style={{ fontWeight: "bold" }}>Transgender</td>
                            <td style={{ fontWeight: "bold" }}>Total</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Permanent</td>
                            <td>{View?.N_Teaching_P_Male}</td>
                            <td>{View?.N_Teaching_P_Female}</td>
                            <td>{View?.N_Teaching_P_Transgender}</td>
                            <td>
                              {parseInt(View?.N_Teaching_P_Male) +
                                parseInt(View?.N_Teaching_P_Female) +
                                parseInt(View?.N_Teaching_P_Transgender)}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Temporary</td>
                            <td>{View?.N_Teaching_T_Male}</td>
                            <td>{View?.N_Teaching_T_Female}</td>
                            <td>{View?.N_Teaching_T_Transgender}</td>
                            <td>
                              {parseInt(View?.N_Teaching_T_Male) +
                                parseInt(View?.N_Teaching_T_Female) +
                                parseInt(View?.N_Teaching_T_Transgender)}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>24</td>
                      <td colSpan={2} style={{ fontWeight: "bold" }}>
                        Number of Students on roll by gender
                        <table style={{ width: "90%", margin: "20px" }}>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Male</td>
                            <td style={{ fontWeight: "bold" }}>Female</td>
                            <td style={{ fontWeight: "bold" }}>Transgender</td>
                            <td style={{ fontWeight: "bold" }}>Total</td>
                          </tr>
                          <tr>
                            <td>{View?.Students_M}</td>
                            <td>{View?.Students_F}</td>
                            <td>{View?.Students_T}</td>
                            <td>
                              {parseInt(View?.Students_M) +
                                parseInt(View?.Students_F) +
                                parseInt(View?.Students_T)}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td style={{ fontWeight: "bold" }}>
                        Does the institution have statutory cells / committees
                      </td>
                      <td>{View?.committees ? parse(View?.committees) : ""}</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>26</td>
                      <td style={{ fontWeight: "bold" }}>
                        Date of establishment of IQAC
                      </td>
                      <td>
                        {moment(View?.Date_establishment_IQAC)?.format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        The minutes of IQAC meeting and Action Taken Report
                        should be uploaded on the institutional website
                      </td>
                      <td>
                        <table>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Date</td>
                            <td style={{ fontWeight: "bold" }}>
                              View Document
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              {moment(View?.IQAC_meeting_Date)?.format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>
                              <a
                                target="_blank"
                                href={`https://brightnaac.co.in/IIQA/${View?.IQAC_meeting_Doc}`}
                              >
                                {`https://brightnaac.co.in/IIQA/${View?.IQAC_meeting_Doc}`}
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>27</td>
                      <td style={{ fontWeight: "bold" }}>
                        Date of submission of AQARs of last 4 years to NAAC
                      </td>
                      <td>
                        <table>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>Date</td>
                            <td style={{ fontWeight: "bold" }}>
                              View Document
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {moment(View?.Date_submission_AQARs1)?.format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>
                              <a
                                target="_blank"
                                href={`https://brightnaac.co.in/IIQA/${View?.Date_submission_AQARs_Doc1}`}
                              >
                                {`https://brightnaac.co.in/IIQA/${View?.Date_submission_AQARs_Doc1}`}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              {moment(View?.Date_submission_AQARs2)?.format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>
                              <a
                                target="_blank"
                                href={`https://brightnaac.co.in/IIQA/${View?.Date_submission_AQARs_Doc2}`}
                              >
                                {`https://brightnaac.co.in/IIQA/${View?.Date_submission_AQARs_Doc2}`}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              {moment(View?.Date_submission_AQARs3)?.format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>
                              <a
                                target="_blank"
                                href={`https://brightnaac.co.in/IIQA/${View?.Date_submission_AQARs_Doc3}`}
                              >
                                {`https://brightnaac.co.in/IIQA/${View?.Date_submission_AQARs_Doc3}`}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              {moment(View?.Date_submission_AQARs4)?.format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>
                              <a
                                target="_blank"
                                href={`https://brightnaac.co.in/IIQA/${View?.Date_submission_AQARs_Doc4}`}
                              >
                                {`https://brightnaac.co.in/IIQA/${View?.Date_submission_AQARs_Doc4}`}
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>28</td>
                      <td style={{ fontWeight: "bold" }}>
                        Has the institution made statutory declaration on the
                        institution website under Section 4 (1) (b) of the RTI
                        Act 2005 as issued and amended from time to time.
                      </td>
                      <td>
                        {View?.RTI_Act_2005}
                        <p style={{ fontSize: "12px" }}>
                          <a
                            target="_blank"
                            href={`https://brightnaac.co.in/IIQA/${View?.RTI_Act_2005_Doc}`}
                          >
                            {`https://brightnaac.co.in/IIQA/${View?.RTI_Act_2005_Doc}`}
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>29</td>
                      <td style={{ fontWeight: "bold" }}>
                        Does the college have an academic MoU with any foreign
                        institution
                      </td>
                      <td>{View?.MoU_foreign}</td>
                    </tr>
                    <tr>
                      <td>30</td>
                      <td style={{ fontWeight: "bold" }}>
                        Date of uploading data on MHRD website for All India
                        Survey on Higher Education (AISHE).
                      </td>
                      <td>
                        {moment(View?.MHRD_Date)?.format("DD/MM/YYYY")}
                        <p style={{ fontSize: "12px" }}>
                          <a
                            target="_blank"
                            href={`https://brightnaac.co.in/IIQA/${View?.MHRD_Doc}`}
                          >
                            View Document
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>31</td>
                      <td style={{ fontWeight: "bold" }}>
                        Attach Certification by the Head of the Institution for
                        having complied with Rules & Regulations of Central
                        Government, UGC and other Statutory Bodies, State
                        Government and Affiliating University in the prescribed
                        format enclosed herewith.
                      </td>
                      <td>
                        <a
                          target="_blank"
                          href={`https://brightnaac.co.in/IIQA/${View?.AttachCertification}`}
                        >
                          View Document
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td style={{ fontWeight: "bold" }}>
                        Registration Fee paid details.
                      </td>
                      <td>
                        {View?.Registration_Fee
                          ? parse(View?.Registration_Fee)
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => handlePrint()}>
            Download
          </Button>
          <Link
            style={{
              backgroundColor: "#dc3545",
              color: "white",
              padding: "6px 15px",
              fontSize: "14px",
              borderRadius: "3px",
              // fontWeight: 600,
            }}
            to="/Editiiqa"
            state={{ View: View }}
          >
            Edit
          </Link>
          {/* <Button variant="danger">Edit</Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete the data</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body></Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            No
          </Button>
          <Button variant="success" onClick={deleteIIQA}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IiqaMain;
