import React from "react";

const UG2271aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Program Name</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria02_271?.PrgName3}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Name of the student</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria02_271?.NStd}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Gender</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria02_271?.Gender}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Category</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_271?.Category}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>State of Domicile</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_271?.SDomicile}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Nationality if othern than Indian</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_271?.NIndian}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Email-ID</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_271?.Email}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Mobile Number</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_271?.MNo}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of joining</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_271?.YJoining}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Unique Enrolment ID / College ID/ University enrolment number
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.Criteria02_271?.UID}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria02_271?.Desc11}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria02/${item?.Criteria02_271?.Link11}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG2271aqar;
