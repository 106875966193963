import React from "react";

const UG4413aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Room number or Name of Classrooms and Seminar Halls with
            LCD/wifi/LAN facilities
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.UG_Criteria04_413?.UG_Room}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Type of ICT facility</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.UG_Criteria04_413?.UG_Type}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.UG_Criteria04_413?.UG_Desc1}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria4/${item?.UG_Criteria04_413?.UG_Link1}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG4413aqar;
