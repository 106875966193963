import axios from "axios";
import React, { useState } from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
function StudentRegister() {
  const [CourseStart, setCourseStatr] = useState(null);
  const [CourseEnd, setCourseEnd] = useState("");

  const lastYearChange = (date) => {
    setCourseEnd(date);
  };
  const handleYearChange = (date) => {
    setCourseStatr(date);
  };
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [Password, setPassword] = useState("");
  const [Name, setName] = useState("");
  const [Status, setStatus] = useState("");
  const [CollageName, setCollageName] = useState("");
  const [HEI, setHEI] = useState("");
  const [Course, setCourse] = useState("");
  const [RegistrationNo, setRegistrationNo] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [Profilepic, setProfilepic] = useState("");

  const RegisterStudent = async () => {
    try {
      const config = {
        url: "student/regStudent",
        method: "post",
        baseURL: "https://brightnaac.co.in/api/",
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          name: Name,
          status: Status,
          collagename: CollageName,
          hei: HEI,
          course: Course,
          registrationno: RegistrationNo,
          coursestatr: CourseStart,
          courseend: CourseEnd,
          mobileno: MobileNo,
          email: EmailId,
          password: Password,
          profilepic: Profilepic,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        window.location.assign("/studentdeglist");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  return (
    <div>
      <h2
        className="mt-2"
        style={{
          textAlign: "center",
          color: "#ff0018f2",
          borderBottom: "2px #ff0018f2 solid",
        }}
      >
        Student Register Form
      </h2>
      <Container>
        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Student Name : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Control
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Enter Name"
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Student Status : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Select onChange={(e) => setStatus(e.target.value)}>
                <option>Choose..</option>
                <option value={"Alumni"}>Alumni</option>
                <option value={"Student"}>Student</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                College Name : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setCollageName(e.target.value)}
                placeholder="Enter Registration No."
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                HEI : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Select onChange={(e) => setHEI(e.target.value)}>
                <option>Choose..</option>
                <option value={"UG"}>UG</option>
                <option value={"PG"}>PG</option>
                <option value={"UG & PG"}>UG & PG</option>
                <option value={"Autonomus"}>Autonomus</option>
                <option value={"University"}>University</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Course : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Control
                onChange={(e) => setCourse(e.target.value)}
                type="text"
                placeholder="Enter Course Name"
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Registration No : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Control
                onChange={(e) => setRegistrationNo(e.target.value)}
                type="text"
                placeholder="Enter Registration No."
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Course Duration : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <div className="d-flex gap-3">
                <DatePicker
                  className="form-control"
                  selected={CourseStart}
                  onChange={handleYearChange}
                  showYearPicker
                  dateFormat="yyyy"
                  placeholderText="from"
                />
                <DatePicker
                  className="form-control"
                  selected={CourseEnd}
                  onChange={lastYearChange}
                  showYearPicker
                  dateFormat="yyyy"
                  placeholderText="to"
                />
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Mobile No : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Control
                onChange={(e) => setMobileNo(e.target.value)}
                type="text"
                placeholder="Enter Mobile No."
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Email Id : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Control
                type="email"
                onChange={(e) => setEmailId(e.target.value)}
                placeholder="Enter Email Id"
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Prifile pic : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setProfilepic(e.target.files[0])}
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Password : <sapn style={{ color: "red" }}>*</sapn>
              </Form.Label>
              <Form.Control
                type={isRevealPwd ? "text" : "password"}
                value={Password}
                placeholder="Enter Your Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {isRevealPwd ? (
                <BsFillEyeFill
                  style={{
                    position: "absolute",
                    marginTop: "-27px",
                    marginLeft: "307px",
                  }}
                  size={18}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
              ) : (
                <BsFillEyeSlashFill
                  style={{
                    position: "absolute",
                    marginTop: "-27px",
                    marginLeft: "307px",
                  }}
                  size={18}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
              )}
            </Form.Group>
          </div>
        </Row>
      </Container>

      <div className="d-flex justify-content-center">
        <Button onClick={RegisterStudent}> SUBMIT</Button>
      </div>
    </div>
  );
}

export default StudentRegister;
