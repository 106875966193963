import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

function Ucriteria2() {
  const navigate = useNavigate();

  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));

  // =====================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =======================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaUn_2/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          UniCriteria02Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================

  const [AllDetails, setAllDetails] = useState([]);
  const UniCriteria02Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUnicri02details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.Unicriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.Unicriteria02);
    }
  };

  // =================================================================

  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      UniCriteria02Details();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);

  return (
    <div>
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-02
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="ad-b  row align-items-center m-0 mt-4 p-0">
          <div className="">
            <Nav defaultActiveKey="/unicriteria02">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/U_02_211")}
                >
                  <Button className="criteriabtn btn-sub1">2.1.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/U_02_212")}
                >
                  <Button className="criteriabtn btn-sub">2.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_241")}
                >
                  <Button className="criteriabtn  btn-sub">
                    2.4.1 & 2.4.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_242")}
                >
                  <Button className="criteriabtn  btn-sub">2.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_244")}
                >
                  <Button className="criteriabtn  btn-sub">2.4.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_251")}
                >
                  <Button className="criteriabtn  btn-sub">2.5.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_263")}
                >
                  <Button className="criteriabtn  btn-sub">2.6.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_02_271")}
                >
                  <Button className="criteriabtn  btn-sub">2.7.1</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "22000px" }}
            >
              <thead>
                <tr>
                  <th className="theadstyle">S.No</th>
                  <th className="theadstyle">Id</th>
                  <th className="theadstyle">Year</th>

                  {/* 2.1.1 */}
                  <th className="theadstyle">Criteria</th>
                  <th className="theadstyle">Program name</th>
                  <th className="theadstyle">Program code</th>
                  <th className="theadstyle">Number of seats sanctioned</th>
                  <th className="theadstyle">
                    Number of eligible applications received
                  </th>
                  <th className="theadstyle">Number of Students admitted</th>
                  <th className="theadstyle">Description</th>
                  <th className="theadstyle">Relevant Document</th>

                  {/* 2.1.2 */}
                  <th className="theadstyle">Criteria</th>
                  <th className="theadstyle">
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </th>
                  <th className="theadstyle">
                    Number of students admitted from the reserved category
                  </th>
                  <th className="theadstyle">Description</th>
                  <th>Relevant Document</th>

                  {/* 2.4.1 & 2.4.3 */}
                  <th className="theadstyle">Criteria</th>
                  <th className="theadstyle">Name of the Full-time teacher</th>
                  <th className="theadstyle">PAN</th>
                  <th className="theadstyle">Designation</th>
                  <th className="theadstyle">Year of appointment</th>
                  <th className="theadstyle">
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </th>
                  <th className="theadstyle">Name of the Department</th>
                  <th className="theadstyle">
                    Total years of Experience in the same institution
                  </th>
                  <th className="theadstyle">
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th className="theadstyle">Description</th>
                  <th className="theadstyle">Relevant Document</th>

                  {/* 2.4.2 */}
                  <th className="theadstyle">Criteria</th>
                  <th className="theadstyle">
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th className="theadstyle">
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th className="theadstyle">
                    Year of obtaining the qualification
                  </th>
                  <th className="theadstyle">Description</th>
                  <th className="theadstyle">Relevant Document</th>

                  {/* 2.4.4 */}
                  <th className="theadstyle">Criteria</th>
                  <th className="theadstyle">
                    Name of full time teachers receiving awards from state
                    level, national level, international level
                  </th>
                  <th className="theadstyle">Year of Award</th>
                  <th className="theadstyle">PAN</th>
                  <th className="theadstyle">Designation</th>
                  <th className="theadstyle">Name of the Awarding Agency</th>
                  <th className="theadstyle">
                    Name of the award, fellowship, received from Government or
                    Government recognised bodies
                  </th>
                  <th className="theadstyle">
                    Incentives/Type of the incentive given by the HEI in
                    recognition of the award
                  </th>
                  <th className="theadstyle">Description</th>
                  <th className="theadstyle">Relevant Document</th>

                  {/* 2.5.1 */}
                  <th className="theadstyle">Criteria</th>
                  <th className="theadstyle">Program name</th>
                  <th className="theadstyle">Program code</th>
                  <th className="theadstyle">Semester/ year</th>
                  <th className="theadstyle">
                    Last date of the last semester-end/ year- end examination
                  </th>
                  <th className="theadstyle">
                    Date of declaration of results of semester-end/ year- end
                    examination
                  </th>
                  <th className="theadstyle">Description</th>
                  <th className="theadstyle">Relevant Document</th>

                  {/* (2.6.3) */}
                  <th className="theadstyle">Criteria</th>
                  <th className="theadstyle">Program Name</th>
                  <th className="theadstyle">Program Code</th>
                  <th className="theadstyle">
                    Number of students appeared in the final year examination
                  </th>
                  <th className="theadstyle">
                    Number of students passed in final year examination
                  </th>
                  <th className="theadstyle">Description</th>
                  <th className="theadstyle">Relevant Document</th>

                  {/* (2.7.1) */}
                  <th className="theadstyle">Criteria</th>
                  <th className="theadstyle">Program Name</th>
                  <th className="theadstyle">Name of the student</th>
                  <th className="theadstyle">Gender</th>
                  <th className="theadstyle">Category</th>
                  <th className="theadstyle">State of Domicile</th>
                  <th className="theadstyle">
                    Nationality if other than Indian
                  </th>
                  <th className="theadstyle">Email-ID</th>
                  <th className="theadstyle">Mobile Number</th>
                  <th className="theadstyle">Year of joining</th>
                  <th className="theadstyle">Students Unique enrollment ID</th>
                  <th className="theadstyle">Description</th>
                  <th className="theadstyle">Relevant document</th>

                  <th className="theadstyle">View</th>
                  <th className="theadstyle">Status</th>
                  <th className="theadstyle">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    (approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "Pending") ||
                    (item1?.approve === "false" &&
                      (SearchItem === "" ||
                        item1?.Uni_Criteria02_211?.Uni_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_211?.Uni_PrgName1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_211?.Uni_PrgCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_211?.Uni_NoSeatSanctioned?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_211?.Uni_eligible_applications_received?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_211?.Uni_NoStdAdmit?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_211?.Uni_Desc32?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_SC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_ST?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_OBC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_Gen?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_Divyangjan?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_Others?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_SSC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_SST?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_OOBC?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_GGen?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_DDivyangjan?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_OOthers?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_212?.Uni_Desc33?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_TName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_PAN?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_Designation?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_YOfAppoint?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_NoOfAppoint?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_DeptName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_TYofExp?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_TService?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_241_243?.Uni_Desc34?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_242?.Uni_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_242?.Uni_SerTeacher?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_242?.Uni_Qualify?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_242?.Uni_YOfQuali?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_242?.Uni_Desc35?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_criteria02_244?.Uni_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_criteria02_244?.Uni_fullTeacherName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_criteria02_244?.Uni_YrAward?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_criteria02_244?.Uni_PAN1?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_criteria02_244?.Uni_Desi?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_criteria02_244?.Uni_AwardAgency?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_criteria02_244?.Uni_AwardName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_criteria02_244?.Uni_Incentives?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_criteria02_244?.Uni_Desc36?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_251?.Uni_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_251?.Uni_pname1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_251?.Uni_pcode1?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_251?.Uni_semester?.toString()?.includes(
                          SearchItem
                        ) ||
                        moment(item1?.Uni_Criteria02_251?.Uni_last)
                          .format("DD-MM-YYYY")
                          ?.toString()
                          ?.includes(SearchItem) ||
                        moment(item1?.Uni_Criteria02_251?.Uni_Date)
                          .format("DD-MM-YYYY")
                          ?.toString()
                          ?.includes(SearchItem) ||
                        item1?.Uni_Criteria02_251?.Uni_Desc37?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_263?.Uni_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_263?.Uni_PrgName2?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_263?.Uni_pCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_263?.Uni_NStdAppeared?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_263?.Uni_NStdPassed?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_263?.Uni_Desc38?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_criteria?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_PrgName3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_NStd?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_Gender?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_Category?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_SDomicile?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_NIndian?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_Email?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_MNo?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_YJoining?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_UID?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Uni_Criteria02_271?.Uni_Desc39?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag)
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Uni_Year}</td>

                      <td>{item?.Uni_Criteria02_211?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_211?.Uni_PrgName1}</td>
                      <td>{item?.Uni_Criteria02_211?.Uni_PrgCode}</td>
                      <td>{item?.Uni_Criteria02_211?.Uni_NoSeatSanctioned}</td>
                      <td>
                        {
                          item?.Uni_Criteria02_211
                            ?.Uni_eligible_applications_received
                        }
                      </td>
                      <td>{item?.Uni_Criteria02_211?.Uni_NoStdAdmit}</td>

                      <td>
                        {item?.Uni_Criteria02_211?.Uni_Desc32?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 212 */}
                      <td>{item?.Uni_Criteria02_212?.Uni_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria02_212?.Uni_SC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_ST}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_OBC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_Gen}</td>
                              <td>
                                {item?.Uni_Criteria02_212?.Uni_Divyangjan}
                              </td>
                              <td>{item?.Uni_Criteria02_212?.Uni_Others}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>SC</th>
                            <th>ST</th>
                            <th>OBC</th>
                            <th>General</th>
                            <th>Divyangjan</th>
                            <th>Others</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria02_212?.Uni_SSC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_SST}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_OOBC}</td>
                              <td>{item?.Uni_Criteria02_212?.Uni_GGen}</td>
                              <td>
                                {item?.Uni_Criteria02_212?.Uni_DDivyangjan}
                              </td>
                              <td>{item?.Uni_Criteria02_212?.Uni_OOthers}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        {item?.Uni_Criteria02_212?.Uni_Desc33?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 241 */}
                      <td>{item?.Uni_Criteria02_241_243?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_TName}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_PAN}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_Designation}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_YOfAppoint}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_NoOfAppoint}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_DeptName}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_TYofExp}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_TService}</td>
                      <td>
                        {item?.Uni_Criteria02_241_243?.Uni_Desc34?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 242 */}
                      <td>{item?.Uni_Criteria02_242?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_242?.Uni_SerTeacher}</td>
                      <td>{item?.Uni_Criteria02_242?.Uni_Qualify}</td>
                      <td>{item?.Uni_Criteria02_242?.Uni_YOfQuali}</td>

                      <td>
                        {item?.Uni_Criteria02_242?.Uni_Desc35?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 244 */}
                      <td>{item?.Uni_criteria02_244?.Uni_criteria}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_fullTeacherName}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_YrAward}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_PAN1}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_Desi}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_AwardAgency}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_AwardName}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_Incentives}</td>

                      <td>
                        {item?.Uni_criteria02_244?.Uni_Desc36?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 251 */}
                      <td>{item?.Uni_Criteria02_251?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_251?.Uni_pname1}</td>
                      <td>{item?.Uni_Criteria02_251?.Uni_pcode1}</td>
                      <td>{item?.Uni_Criteria02_251?.Uni_semester}</td>
                      <td>
                        {moment(item?.Uni_Criteria02_251?.Uni_last)?.format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Uni_Criteria02_251?.Uni_Date)?.format(
                          "DD-MM-YYYY"
                        )}
                      </td>

                      <td>
                        {item?.Uni_Criteria02_251?.Uni_Link37?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 263 */}
                      <td>{item?.Uni_Criteria02_263?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_PrgName2}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_pCode}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_NStdAppeared}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_NStdPassed}</td>

                      <td>
                        {item?.Uni_Criteria02_263?.Uni_Desc38?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 271 */}
                      <td>{item?.Uni_Criteria02_271?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_PrgName3}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_NStd}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_Gender}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_Category}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_SDomicile}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_NIndian}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_Email}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_MNo}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_YJoining}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_UID}</td>
                      <td>
                        {item?.Uni_Criteria02_271?.Uni_Desc39?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td style={{ border: "" }}>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          {/* <i class="fa-solid fa-check fa-lg" style={{color: "#26a269"}}></i> */}
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td style={{ border: "" }}>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td style={{ border: "" }}>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ textAlign: "justify" }}>
                  {View?.Uni_Criteria02_211?.Uni_Desc32}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ textAlign: "justify" }}>
                  {View?.Uni_Criteria02_212?.Uni_Desc33}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ textAlign: "justify" }}>
                  {View?.Uni_Criteria02_241_243?.Uni_Desc34}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ textAlign: "justify" }}>
                  {View?.Uni_Criteria02_242?.Uni_Desc35}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose12}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ textAlign: "justify" }}>
                  {View?.Uni_criteria02_244?.Uni_Desc36}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ textAlign: "justify" }}>
                  {View?.Uni_Criteria02_251?.Uni_Link37}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose14}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ textAlign: "justify" }}>
                  {View?.Uni_Criteria02_263?.Uni_Desc38}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show16} onHide={handleClose16}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p style={{ textAlign: "justify" }}>
                  {View?.Uni_Criteria02_271?.Uni_Desc39}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose16}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_211?.Uni_Link32}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_212?.Uni_Link33}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_241_243?.Uni_Link34}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_242?.Uni_Link35}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_263?.Uni_Link38}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_271?.Uni_Link39}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_251?.Uni_Link37}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_criteria02_244?.Uni_Link36}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Add Model */}
          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#" }}>
                {" "}
                <span className="modal-head-text">
                  University_Criteria-02
                </span>{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-0 p-0">
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-2.1.1
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Programme name
                    </label>

                    <>{Value?.Uni_Criteria02_211?.Uni_PrgName1}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Programme code
                    </label>

                    <>{Value?.Uni_Criteria02_211?.Uni_PrgCode}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of seats sanctioned
                    </label>

                    <>{Value?.Uni_Criteria02_211?.Uni_NoSeatSanctioned}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of eligible applications received
                    </label>

                    <>
                      {
                        Value?.Uni_Criteria02_211
                          ?.Uni_eligible_applications_received
                      }
                    </>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of Students admitted
                    </label>

                    <>{Value?.Uni_Criteria02_211?.Uni_NoStdAdmit}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Uni_Criteria02_211?.Uni_Desc32}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Uni_Criteria02_211?.Uni_Link32}</>
                  </div>
                </div>
              </div>

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-2.1.2
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  {/* -------2.1.2------ */}
                  <h6>
                    Average enrolement percentage(Average of last five years)
                  </h6>
                  <h6 className="text-center text-decoration-underline">
                    Number of seats earmarked for reserved category as per GOI
                    or State Government rule
                  </h6>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      SC
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_SC}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ST{" "}
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_ST}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      OBC
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_OBC}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Gen
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_Gen}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Divyangjan
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_Divyangjan}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Others
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_Others}</>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Number of students admitted from the reserved category
                  </h6>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      SC
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_SSC}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ST
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_SST}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      OBC
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_OOBC}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Gen
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_GGen}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Divyangjan
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_DDivyangjan}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Others
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_OOthers}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_Desc33}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Uni_Criteria02_212?.Uni_Link33}</>
                  </div>
                </div>
              </div>

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-2.4.1 & 2.4.3
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Full-time teacher
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_TName}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      PAN
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_PAN}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Designation
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_Designation}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of appointment
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_YOfAppoint}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Department
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_NoOfAppoint}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Nature of appointment (Against Sanctioned post, temporary,
                      permanent)
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_DeptName}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Total years of Experience in the same institution
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_TYofExp}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Is the teacher still serving the institution/If not last
                      year of the service of Faculty to the Institution
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_TService}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_Desc34}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Uni_Criteria02_241_243?.Uni_Link34}</>
                  </div>
                </div>
              </div>

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-2.4.2
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  {/* -------2.4.2------ */}
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of full time serving teacher with
                      Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.
                    </label>

                    <>{Value?.Uni_Criteria02_242?.Uni_SerTeacher}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Qualification (Ph.D./D.M/M.Ch./D.N.B Super
                      speciality/D.Sc./ D.Litt.)
                    </label>

                    <>{Value?.Uni_Criteria02_242?.Uni_Qualify}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of obtaining the qualification
                    </label>

                    <>{Value?.Uni_Criteria02_242?.Uni_YOfQuali}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Uni_Criteria02_242?.Uni_Desc35}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Uni_Criteria02_242?.Uni_Link35}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-2.4.4
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  {/* -------2.4.4------ */}
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of full time teachers receiving awards from state
                      level, national level, international level
                    </label>

                    <>{Value?.Uni_criteria02_244?.Uni_fullTeacherName}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of Award
                    </label>

                    <>{Value?.Uni_criteria02_244?.Uni_YrAward}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      PAN
                    </label>

                    <>{Value?.Uni_criteria02_244?.Uni_PAN1}</>
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Designation
                    </label>

                    <>{Value?.Uni_criteria02_244?.Uni_Desi}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the Awarding Agency{" "}
                    </label>

                    <>{Value?.Uni_criteria02_244?.Uni_AwardAgency}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the award, fellowship, received from Government or
                      Government recognised bodies
                    </label>

                    <>{Value?.Uni_criteria02_244?.Uni_AwardName}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Incentives/Type of the incentive given by the HEI in
                      recognition of the award{" "}
                    </label>

                    <>{Value?.Uni_criteria02_244?.Uni_Incentives}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Uni_criteria02_244?.Uni_Desc36}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload5">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Uni_criteria02_244?.Uni_Link36}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-2.5.1
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  {/* -------2.5.1------ */}
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Programme Name{" "}
                    </label>

                    <>{Value?.Uni_Criteria02_251?.Uni_pname1}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Programme Code
                    </label>

                    <>{Value?.Uni_Criteria02_251?.Uni_pcode1}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Semester/ year
                    </label>

                    <>{Value?.Uni_Criteria02_251?.Uni_semester}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Last date of the last semester-end/ year- end examination
                    </label>

                    <>{Value?.Uni_Criteria02_251?.Uni_last}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date of declaration of results of semester-end/ year- end
                      examination{" "}
                    </label>

                    <>{Value?.Uni_Criteria02_251?.Uni_Date}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Uni_Criteria02_251?.Uni_Desc37}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload76">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Uni_Criteria02_251?.Uni_Link37}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-2.6.3
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <h6>Criteria02--(2.6.3)</h6>
                  {/* -------2.6.3------ */}
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    <>{Value?.Uni_Criteria02_263?.Uni_PrgName2}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>

                    <>{Value?.Uni_Criteria02_263?.Uni_pCode}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students appeared in the final year examination
                    </label>

                    <>{Value?.Uni_Criteria02_263?.Uni_NStdAppeared}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students passed in final year examination
                    </label>

                    <>{Value?.Uni_Criteria02_263?.Uni_NStdPassed}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Uni_Criteria02_263?.Uni_Desc38}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload7">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Uni_Criteria02_263?.Uni_Link38}</>
                  </div>
                </div>
              </div>
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-2.7.1
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <h6>Criteria02--(2.7.1)</h6>
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the student
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_NStd}</>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Gender
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_Gender}</>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Category
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_Category}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        State of Domicile
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_SDomicile}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Nationality if other than Indian
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_NIndian}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Email-ID
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_Email}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Program name
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_PrgName3}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Mobile Number
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_MNo}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year of joining
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_YJoining}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Unique Enrolment ID / College ID/ University enrolment
                        number
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_UID}</>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Description
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_Desc39}</>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="upload8">
                        {" "}
                        Document
                      </label>

                      <>{Value?.Uni_Criteria02_271?.Uni_Link39}</>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {Value?.approve === "false" ? (
                <></>
              ) : (
                <div>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      approveCriteria(e, true, Value?._id);
                      handleClose();
                    }}
                  >
                    Approve{" "}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => setSmShow(true)}
                    className="me-2"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal>

          {
            // remarks model
          }

          <Modal
            // size="sm"
            show={smShow}
            onHide={() => handleClosesm()}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Tell me the reason?..
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                onChange={(e) => setremark(e.target.value)}
                style={{ height: "200px" }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  approveCriteria(e, false, Value?._id);
                  handleClose();
                  handleClosesm();
                }}
              >
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Ucriteria2;
