import React, { useEffect, useState } from "react";
import "./Styles/form.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const StudentLogin = () => {
  const navigate = useNavigate();
  const [studentname, setstudentname] = useState("");
  const [studentemail, setstudentemail] = useState("");
  const [studentphone, setstudentphone] = useState("");
  const [studentHEIid, setstudentHEIid] = useState("");
  const [studentgender, setstudentgender] = useState("");
  const [studentpassword, setstudentpassword] = useState("");

  const RegisterStudent = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/student/regStudent",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          studentname: studentname,
          studentemail: studentemail,
          studentphone: studentphone,
          studentHEIid: studentHEIid,
          studentgender: studentgender,
          studentpassword: studentpassword,
        },
      };
      console.log(config, 999898);
      const response = await axios(config);
      if (response.status === 201) {
        alert(response.data.msg);
        navigate("/student-login");
      }
    } catch (error) {
      console.log(error);
      return alert(error.response.data.msg);
    }
  };

  // get all HEIs
  const [collegeApp, setCollegeApp] = useState([]);
  const getallAppColleges = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/app/approvedlist"
      );
      if (res.status === 200) {
        setCollegeApp(res.data.Register);
      }
    } catch (error) {
      console.log(error);
      setCollegeApp(error.response.data.Register);
    }
  };

  useEffect(() => {
    getallAppColleges();
  }, []);

  return (
    <div className="container">
      <div className="row mt-5 align-items-center justify-content-center">
        <div className="col-lg-7 border mb-5">
          <p className="p-3 border-bottom text-center contact-form-text">
            Student Registration
          </p>
          <div className="m-auto">
            <form className="p-4">
              <div className="row">
                <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    class="form-c border"
                    id="exampleFormControlInput1"
                    placeholder="Name"
                    onChange={(e) => setstudentname(e.target.value)}
                  />
                </div>
                <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    class="form-c border"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                    onChange={(e) => setstudentemail(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label">
                    Phone No
                  </label>
                  <input
                    type="number"
                    class="form-c border"
                    id="exampleFormControlInput1"
                    placeholder=" Phone No"
                    onChange={(e) => setstudentphone(e.target.value)}
                  />
                </div>
                <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label">
                    HEI Name
                  </label>
                  <select
                    class="form-c border"
                    placeholder="Course"
                    onChange={(e) => setstudentHEIid(e.target.value)}
                  >
                    <option>Choose ...</option>
                    {collegeApp
                      ?.filter((item) => item?.isApprove)
                      ?.map((val) => {
                        return <option value={val?._id}>{val?.HeiName}</option>;
                      })}
                  </select>
                </div>
              </div>

              <div className="row mt-2 mb-3">
                <div class="  col-2">
                  <label for="exampleFormControlInput1" class="form-label">
                    Gender
                  </label>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <input
                    name="gender"
                    type="radio"
                    class=" "
                    value={"male"}
                    id="exampleFormControlInput1"
                    placeholder=""
                    style={{ marginRight: "10px" }}
                    onChange={(e) => setstudentgender(e.target.value)}
                  />{" "}
                  <label class="form-label">male</label>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <input
                    name="gender"
                    type="radio"
                    class=" "
                    value={"female"}
                    id="exampleFormControlInput1"
                    placeholder=""
                    style={{ marginRight: "10px" }}
                    onChange={(e) => setstudentgender(e.target.value)}
                  />{" "}
                  <label class="form-label">female</label>
                </div>
              </div>

              <div className="row">
                <div class="mb-3 col-lg col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    class="form-c border"
                    id="exampleFormControlInput1"
                    placeholder=" Password"
                    onChange={(e) => setstudentpassword(e.target.value)}
                  />
                </div>
              </div>

              <button className="border" onClick={(e) => RegisterStudent(e)}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentLogin;
