import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { BsFillFilePersonFill } from "react-icons/bs";
import { BiSolidDashboard } from "react-icons/bi";
import { FaGraduationCap } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { SiGoogleforms } from "react-icons/si";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import AdminHeader from "./AdminClgHeader.";
import "./admin.css";

function AdminclgDashboard(props) {
  let collegedetails = JSON.parse(sessionStorage.getItem("collegeDetails"));
  const [collapsed, setCollapsed] = React.useState(false);

  const [principaldetails, setprincipaldetails] = useState({});

  async function getPrincipalById() {
    try {
      const res = await axios.get(
        `https://brightnaac.co.in/api/pri/getPrincipalById/${collegedetails?._id}`
      );
      if (res.status === 200) {
        setprincipaldetails(res.data.getPrincipalDetails);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  }

  useEffect(() => {
    getPrincipalById();
  }, []);

  return (
    <div style={{ display: "flex", height: "100%", minHeight: "100vh" }}>
      {" "}
      <Sidebar
        collapsed={collapsed}
        // breakPoint={breakpoint? "sm" : ""}
        height="100vh"
        collapsedWidth="4.5rem"
        backgroundColor="#11112e"
        style={{
          position: "sticky",
          top: "auto",
          padding: "0rem",
          margin: "0rem",
        }}
      >
        <Menu
          transitionDuration={900}
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: disabled ? "blue" : "white",
                  backgroundColor: active ? "red" : undefined,
                };
            },
          }}
          rootStyles={{
            // backgroundColor: '#e1e1e1',
            color: "#344cff",
          }}
        >
          <div
            style={{
              height: "64px",
              minHeight: "64px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 20px",
              marginTop: "6px",
              color: "white !important",
            }}
            onClick={() => setCollapsed(!collapsed)}
            className="aaa"
          >
            Admin
          </div>

          <hr style={{ color: "white" }} />

          <MenuItem
            component={<Link to="/clgdashboard" />}
            icon={<BiSolidDashboard />}
          >
            Dashboard
          </MenuItem>
          <div>
            {principaldetails?.HEI === "PG" ? (
              <>
                <SubMenu label="PostGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri1/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri2/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri3/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri4/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri5/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri6/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria6
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri7/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {principaldetails?.HEI === "UG" ? (
              <>
                <SubMenu label="UnderGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri1/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri2/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri3/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri4/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri5/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri6/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria6
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri7/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {principaldetails?.HEI === "University" ? (
              <>
                <SubMenu label="University" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={
                      <Link to={`/adminclgunicri1/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgunicri2/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgunicri3/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgunicri4/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgunicri5/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgunicri6/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria6
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgunicri7/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {principaldetails?.HEI === "Autonomous" ? (
              <>
                <SubMenu label="Autonomous" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={
                      <Link to={`/adminclgautocri1/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgautocri2/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgautocri3/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgautocri4/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgautocri5/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgautocri6/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria6
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgautocri7/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {principaldetails?.HEI === "UG&PG" ? (
              <>
                <SubMenu label="UnderGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri1/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri2/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri3/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri4/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri5/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri6/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria6
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgugcri7/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>

                <SubMenu label="PostGraduation" icon={<FaGraduationCap />}>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri1/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria1
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri2/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria2
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri3/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria3
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri4/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria4
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri5/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria5
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri6/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria6
                  </MenuItem>
                  <MenuItem
                    component={
                      <Link to={`/adminclgpgcri7/${principaldetails?._id}`} />
                    }
                    icon={<SiGoogleforms />}
                  >
                    Criteria7
                  </MenuItem>
                </SubMenu>
              </>
            ) : (
              <></>
            )}
          </div>

          <MenuItem
            component={<Link to="/adminfinalreports" />}
            icon={<FaPeopleGroup />}
          >
            Final Report
          </MenuItem>
        </Menu>
      </Sidebar>
      <main style={{}}>
        <div>
          <AdminHeader />
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default AdminclgDashboard;
