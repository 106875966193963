import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function SingalCourse() {
  return (
    <>
      <div className="amy">
        <div
          className="ab-0"
          style={{
            backgroundImage: "url('../Image/about-us.jpg')",
            height: "250px",
            position: "center",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="edg container">
            <div className="edgt-has-inline-style">Course Descripation</div>
            <div class="edgt-breadcrumbs-info">
              <div
                itemProp="breadcrumb"
                classname="edgt-breadcrumbs "
                style={{ color: "#e1e0e0" }}
              >
                <Link
                  itemProp="url"
                  to="/"
                  style={{ color: "#e1e0e0", textDecoration: "none" }}
                >
                  Home
                </Link>
                <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
                <a
                  itemProp="url"
                  href="/!#"
                  style={{ color: "#e1e0e0", textDecoration: "none" }}
                >
                  Course
                </a>
                <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
                <span classname="edgt-current">Course Descripation</span>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="amy-d">
            <Container>
              <h4 style={{ color: "black" }}>Development</h4>
              <div className="dre">
                <Row>
                  <Col md={8}>
                    <div className="so-0">
                      <div
                        className="sso-9"
                        style={{
                          backgroundImage: "url('../Image/course-1.jpg')",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          height: "300px",
                        }}
                      ></div>
                      <div className="edg-tab">
                        <div className="edg-tab-0">
                          <ul className="tab-list">
                            <li>Overview</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </div>
            </Container>
          </div>
        </section>
      </div>
    </>
  );
}

export default SingalCourse;
