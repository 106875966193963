import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AiFillDelete, AiOutlineDelete, AiOutlineSave } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
function Course() {
  // =================Add Course====================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // =================Delete Course====================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // =================Edit Course====================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const [imageURL1, setimageURL1] = useState(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL1(URL.createObjectURL(event.target.files[0]));
      console.log("ghghg", URL.createObjectURL(event.target.files[0]));
    }
  };

  const [imageURL2, setimageURL2] = useState(null);

  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
      console.log("ghghg", URL.createObjectURL(event.target.files[0]));
    }
  };
  // =================Add Course===========================
  const [instructorName, setinstructorName] = useState("");
  const [instructorImg, setinstructorImg] = useState("");
  const [courseCategory, setcourseCategory] = useState("");
  const [courseName, setcourseName] = useState("");
  const [coursePrice, setcoursePrice] = useState("");
  const [courseImg, setcourseImg] = useState("");
  const [courseDescription, setcourseDescription] = useState("");
  const [courseFeatures, setcourseFeatures] = useState([]);
  const [courseFeat, setcourseFeat] = useState("");
  const [courseData, setcourseData] = useState("");
  const [View, setView] = useState({});
  const AddCourseContent = async (e) => {
    e.preventDefault();

    if (
      !instructorName ||
      !instructorImg ||
      !courseCategory ||
      !courseName ||
      !coursePrice ||
      !courseImg ||
      !courseDescription
    ) {
      return alert("Please fill all the fields!!!");
    }
    let obj = {
      instructorName: instructorName,
      instructorImg: instructorImg,
      courseCategory: courseCategory,
      courseName: courseName,
      coursePrice: coursePrice,
      courseImg: courseImg,
      courseDescription: courseDescription,
      courseFeatures: courseFeatures,
    };

    try {
      const config = {
        url: "/admin/addCourse",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          getCourseList();
          setinstructorName();
          setinstructorImg("");
          setcourseCategory("");
          setcourseName("");
          setcoursePrice("");
          setcourseImg("");
          setcourseDescription("");
          setcourseFeatures([]);
          setcourseFeat("");
          setcourseData("");
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose();
    }
  };

  function addLinks() {
    try {
      if (!courseFeat || !courseData) {
        return alert("Please add feature and feature description!!!");
      }

      setcourseFeatures((curr) => [
        ...curr,
        {
          courseFeat: courseFeat,
          courseData: courseData,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  // =================== calling api for getting the Course content======================
  const [CoursesList, setCoursesList] = useState([]);
  const getCourseList = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getCourseDetails"
      );
      if (res.status === 200) {
        setCoursesList(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================== calling api for getting the specific course content======================
  const getContentById = async (id) => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getCourseDetailsById/" + id
      );
      if (res.status === 200) {
        setView(res.data.courseContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ======================calling api for editing=================
  const EditCourseFeatDetails = async (e, state, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/editCoursesFeat/" + View?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          courseFeatureid: id,
          courseFeat: courseFeat,
          courseData: courseData,
          state: state,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getContentById(View?._id);
          setcourseFeat("");
          setcourseData("");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // =================== calling api for editing the Course content======================
  const editContent = async (e) => {
    e.preventDefault();
    if (
      !instructorName &&
      !instructorImg &&
      !courseCategory &&
      !courseName &&
      !coursePrice &&
      !courseImg &&
      !courseDescription
    ) {
      return alert("Please Provide atleast one detail to edit!!!");
    }

    let obj = {
      instructorName: instructorName,
      instructorImg: instructorImg,
      courseCategory: courseCategory,
      courseName: courseName,
      coursePrice: coursePrice,
      courseImg: courseImg,
      courseDescription: courseDescription,
    };

    try {
      const config = {
        url: "/admin/editCourses/" + View?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getCourseList();
          setinstructorName();
          setinstructorImg("");
          setcourseCategory("");
          setcourseName("");
          setcoursePrice("");
          setcourseImg("");
          setcourseDescription("");
          setcourseFeatures([]);
          setcourseFeat("");
          setcourseData("");
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  // =================== calling api for deleting the Course content======================
  const deleteContent = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/deleteCourse/" + View?._id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getCourseList();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };

  // =================== calling api for updating the popular flag of the Course content======================
  const updatePopularFlag = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/popularStatus/" + id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          // alert(res.data.success);
          getCourseList();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      // handleClose1();
    }
  };

  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getCourseList();
  }, []);

  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Course Page
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Instructor Image</th>
                <th>Instructor Name</th>
                <th>Course Category</th>
                <th>Course Name</th>
                <th>Course Price</th>
                <th>Course Image</th>
                <th>Course Description</th>
                <th>Course Features</th>
                <th>Flag as Popular</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {CoursesList?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>
                      <img
                        style={{ width: "100%" }}
                        src={`https://brightnaac.co.in/Course/${item?.instructorImg}`}
                        alt="no-image"
                      />
                    </td>
                    <td>{item?.instructorName}</td>
                    <td>{item?.courseCategory}</td>
                    <td>{item?.courseName}</td>
                    <td>{item?.coursePrice}</td>
                    <td>
                      <img
                        style={{ width: "100%" }}
                        src={`https://brightnaac.co.in/Course/${item?.courseImg}`}
                        alt="no-image"
                      />
                    </td>
                    <td>
                      {item?.courseDescription?.slice(0, 70)}
                      ...
                      <Link
                        onClick={() => {
                          handleShow7();
                          setView(item);
                        }}
                      >
                        Read More...
                      </Link>
                    </td>

                    <td>
                      <Table responsive>
                        <thead>
                          <th>Feature</th>
                          <th>Description</th>
                        </thead>
                        <tbody>
                          {item?.courseFeatures?.map((item1) => {
                            return (
                              <tr>
                                <td>{item1?.courseFeat}</td>
                                <td>{item1?.courseData}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </td>
                    <td>
                      {item?.popular ? (
                        <>
                          <Switch
                            {...label}
                            // defaultChecked
                            checked
                            color="warning"
                            onClick={(e) => {
                              updatePopularFlag(e, item?._id);
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Switch
                            {...label}
                            disable
                            color="warning"
                            onClick={(e) => {
                              updatePopularFlag(e, item?._id);
                            }}
                          />
                        </>
                      )}
                    </td>
                    <td style={{ gap: "10px" }} className="d-flex">
                      <span
                        onClick={() => {
                          handleShow2();
                          setView(item);
                          getContentById(item?._id);
                        }}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          margin: "10px",
                        }}
                      >
                        <BiEdit size={20} />
                      </span>
                      <span
                        onClick={() => {
                          handleShow1();
                          setView(item);
                          getContentById(item?._id);
                        }}
                        style={{
                          cursor: "pointer",
                          color: "red",
                          margin: "10px",
                        }}
                      >
                        <AiFillDelete size={20} />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Show Body */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Body</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.courseDescription}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ===================add Courses=================== */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Instructor Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Instructor name"
                  onChange={(e) => setinstructorName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label htmlFor="upload1">Instructor Image</Form.Label>
                <Form.Control
                  type="file"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setinstructorImg(e.target.files[0])}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course category</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Course Category"
                  onChange={(e) => setcourseCategory(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Course Name"
                  onChange={(e) => setcourseName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course Price</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter course Price"
                  onChange={(e) => setcoursePrice(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course Image</Form.Label>
                <Form.Control
                  type="file"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setcourseImg(e.target.files[0])}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Course Description"
                  onChange={(e) => setcourseDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course Features</Form.Label>
                <div
                  className="d-flex"
                  style={{ alignItems: "end", gap: "5px" }}
                >
                  <div>
                    <label>Feature</label>
                    <div className="">
                      <input
                        type="text"
                        id="file-input"
                        name="ImageStyle"
                        className="vi_0"
                        onChange={(e) => setcourseFeat(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Description</label>
                    <div className="">
                      <input
                        type="text"
                        id="file-input"
                        name="ImageStyle"
                        className="vi_0"
                        onChange={(e) => setcourseData(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <Button onClick={addLinks}>ADD</Button>
                  </div>
                </div>
                <div>
                  {courseFeatures?.map((val) => {
                    return (
                      <div className="d-flex" style={{ gap: "10px" }}>
                        <div>{val?.courseFeat}</div>
                        <div>{val?.courseData}</div>
                      </div>
                    );
                  })}
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddCourseContent(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ===================Delete Courses=================== */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteContent(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>

        {/* ===================edit Courses=================== */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Instructor Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={View?.instructorName}
                  onChange={(e) => setinstructorName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Instructor Image</Form.Label>
                <Form.Control
                  type="file"
                  id="Eupload1"
                  accept="image/*"
                  hidden
                  onChange={(e) => {
                    onImageChange(e);
                    setinstructorImg(e.target.files[0]);
                  }}
                />
                <Form.Label for="Eupload1">
                  {instructorImg ? (
                    <img
                      src={imageURL1}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  ) : (
                    <img
                      src={`https://brightnaac.co.in/Course/${View?.instructorImg}`}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  )}
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course category</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={View?.courseCategory}
                  onChange={(e) => setcourseCategory(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={View?.courseName}
                  onChange={(e) => setcourseName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course Price</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={View?.coursePrice}
                  onChange={(e) => setcoursePrice(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course Image</Form.Label>
                <Form.Control
                  type="file"
                  id="Eupload2"
                  accept="image/*"
                  hidden
                  onChange={(e) => {
                    onImageChange2(e);
                    setcourseImg(e.target.files[0]);
                  }}
                />
                <Form.Label for="Eupload2">
                  {courseImg ? (
                    <img
                      src={imageURL2}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  ) : (
                    <img
                      src={`https://brightnaac.co.in/Course/${View?.courseImg}`}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  )}
                </Form.Label>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Course Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={View?.courseDescription}
                  onChange={(e) => setcourseDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label> Course Features</Form.Label>
                <Table>
                  <tbody>
                    {View?.courseFeatures?.map((val1) => {
                      return (
                        <tr>
                          <td>
                            <div className="">
                              <input
                                type="text"
                                id="file-input"
                                name="ImageStyle"
                                className="vi_0"
                                placeholder={val1?.courseFeat}
                                onChange={(e) => setcourseFeat(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="">
                              <input
                                type="text"
                                id="file-input"
                                name="ImageStyle"
                                className="vi_0"
                                placeholder={val1?.courseData}
                                onChange={(e) => setcourseData(e.target.value)}
                              />
                            </div>
                          </td>

                          <td>
                            <div
                              className="d-flex"
                              style={{ alignItems: "end", gap: "5px" }}
                            >
                              <AiOutlineSave
                                onClick={(e) => {
                                  EditCourseFeatDetails(e, "EDIT", val1?._id);
                                }}
                              />{" "}
                              <AiOutlineDelete
                                onClick={(e) => {
                                  EditCourseFeatDetails(e, "DELETE", val1?._id);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label> Add Course Features</Form.Label>
                <div
                  className="d-flex"
                  style={{ alignItems: "end", gap: "5px" }}
                >
                  <div>
                    <label>Feature</label>
                    <div className="">
                      <input
                        type="text"
                        id="file-input"
                        name="ImageStyle"
                        className="vi_0"
                        onChange={(e) => setcourseFeat(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Description</label>
                    <div className="">
                      <input
                        type="text"
                        id="file-input"
                        name="ImageStyle"
                        className="vi_0"
                        onChange={(e) => setcourseData(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <Button onClick={(e) => EditCourseFeatDetails(e, "ADD")}>
                      ADD
                    </Button>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => editContent(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Course;
