import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import moment from "moment/moment";
import { AiOutlineEye } from "react-icons/ai";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

const UnderGraduateCri5 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const ugCriteria05Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getcri05details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria05);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  // =================================================================

  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatingug5`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria05Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      ugCriteria05Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="mt-4"> UG Criteria5 </h4>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="mt-4">Current Rating: </h6>
                <div className="mt-4">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center">
            <div className="ad-b mt-4 row align-items-center">
              <div className=" col-lg-4 do-sear mt-4 d-flex ">
                <input
                  type="text"
                  placeholder="Search"
                  className="vi_0"
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {/* <button>Search</button> */}
              </div>

              <div className=" col-lg-2 lab">
                <label>From</label>
                <br />
                <DatePicker
                  onChange={onChangeFrom}
                  picker="year"
                  className="vi_0"
                />
              </div>
              <div className=" col-lg-2 lab">
                <label>To</label>
                <br />
                <DatePicker
                  onChange={onChangeTo}
                  picker="year"
                  className="vi_0"
                />
              </div>

              {/* <div className=" col-lg-2 lab mt-4">
              <Button>Submit</Button>
            </div> */}
              {/* <div className=" col-lg-2 text-end mt-4">
              <Button>Export Excel</Button>
            </div> */}
            </div>
            <div className="row">
              <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
                <label style={{ width: "130px" }}>Select Year</label>
                <select
                  className="criteriaform "
                  style={{ width: "150px" }}
                  onChange={(e) => setselectedYear(e.target.value)}
                >
                  {allYears
                    ?.sort((a, b) => {
                      return Number(b?.from) - Number(a?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg-5">
                <Nav defaultActiveKey="/ugcriteria05">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-1"
                      onClick={() =>
                        navigate(`/adminclgugcri5511/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.1.1 & 5.1.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-2"
                      onClick={() =>
                        navigate(`/adminclgugcri5513/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.1.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgugcri5514/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.1.4</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgugcri5521/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.2.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgugcri5522/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.2.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgugcri5523/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.2.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgugcri5531/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.3.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgugcri5533/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">5.3.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              {/* <div className="col-lg-2">
                <Button
                  type="Submit"
                  className="btn btn-warning"
                  style={{ color: "white" }}
                  onClick={() => {
                    // setValue(item);
                    handleShow();
                  }}
                >
                  Add Rating
                </Button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-2">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>ID</th>
                  <th>Year</th>

                  <th>Criteria</th>
                  <th>Name of the scheme</th>
                  <th>
                    Number of students benefited by government scheme and amount
                  </th>
                  <th>
                    Number of students benefited by the institution's schemes
                    and amount{" "}
                  </th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the capacity development and skills enhancement
                    program
                  </th>
                  <th>Date of implementation</th>
                  <th>Number of students enrolled </th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  <th>Criteria</th>
                  <th>Number of students placed through campus placement</th>
                  <th>
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counseling offered by
                    the institution during the last five years
                  </th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  <th>Criteria</th>
                  <th>Name of student placed and contact details</th>
                  <th>Program graduated from</th>
                  <th>Name of the employer with contact details</th>
                  <th>Pay package at appointment (In INR per annum)</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  <th>Criteria</th>
                  <th>Name of student enrolling into higher education</th>
                  <th>Program graduated from</th>
                  <th>Name of institution joined</th>
                  <th>Name of programme admitted to</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Registration number/roll number for the exam</th>
                  <th>Names of students selected/ qualified</th>
                  <th>NET</th>
                  <th>SLET</th>
                  <th>GATE</th>
                  <th>GMAT</th>
                  <th>CAT</th>
                  <th>GRE</th>
                  <th>JAM</th>
                  <th>IELTS</th>
                  <th>TOEFL</th>
                  <th>Civil Services</th>
                  <th>State government examinations</th>
                  <th>
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </th>
                  <th>Total</th>
                  <th>Grand Total</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  <th>Criteria</th>
                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>University/State/National/ International1</th>
                  <th>Sports/ Cultural</th>
                  <th>Name of the student</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  <th>Criteria</th>
                  <th>Name of the student participated</th>
                  <th>Date of event/competition (DD-MM-YYYY)</th>
                  <th>Name of the event/competition</th>
                  <th>Description</th>
                  <th>Relevant document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Year &&
                    (SearchItem === "" ||
                      item1?.Criteria05_511?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_511?.NameS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_511?.SName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.Amount?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.SName1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.Amount1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_511?.Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_513?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_513?.NamCap?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Criteria05_513?.IDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_513?.StdEnr?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_513?.AgeName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_513?.Desc22?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_514?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_514?.CampName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_514?.Nameact?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_514?.NamePart?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_514?.Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_521?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_521?.PlaName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_521?.Graduate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_521?.ConDetails?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_521?.Pay?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_521?.Desc24?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_522?.HighName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.PGrad?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.Institute?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.Admitted?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_522?.Desc25?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_523?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_523?.No1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.RollNo1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.StdSelected1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_523?.NET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.SLET1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GATE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GMAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.CAT1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GRE1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.JAM1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.IELTS1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.TOEFL1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.Civil1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.SGExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.OtherExam1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.GTotal1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_523?.Desc26?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_531?.Meda?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.TIndi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.USNI1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.SCult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.NaStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_531?.Desc27?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_533?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_533?.NStdParti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Criteria05_533?.EDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_533?.EName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_533?.Desc28?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                }).map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{item?._id}</td>
                      <td>{item?.Year}</td>

                      <td>{item?.Criteria05_511?.criteria}</td>
                      <td>{item?.Criteria05_511?.NameS}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Number of students
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Amount
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Criteria05_511?.SName}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Criteria05_511?.Amount}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Number of students
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Amount
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Criteria05_511?.SName1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Criteria05_511?.Amount1}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.Criteria05_511?.Desc21}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_513?.criteria}</td>
                      <td>{item?.Criteria05_513?.NamCap}</td>
                      <td>
                        {moment(item?.Criteria05_513?.IDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Criteria05_513?.StdEnr}</td>
                      <td>{item?.Criteria05_513?.AgeName}</td>
                      <td>{item?.Criteria05_513?.Desc22}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_514?.criteria}</td>
                      <td>{item?.Criteria05_514?.CampName}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Name of the Activity</th>
                            <th>Number of students attended / participated</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Criteria05_514?.Nameact}</td>
                              <td>{item?.Criteria05_514?.NamePart}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.Criteria05_514?.Desc23}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_521?.criteria}</td>
                      <td>{item?.Criteria05_521?.PlaName}</td>
                      <td>{item?.Criteria05_521?.Graduate}</td>
                      <td>{item?.Criteria05_521?.ConDetails}</td>
                      <td>{item?.Criteria05_521?.Pay}</td>
                      <td>{item?.Criteria05_521?.Desc24}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_522?.criteria}</td>
                      <td>{item?.Criteria05_522?.HighName}</td>
                      <td>{item?.Criteria05_522?.PGrad}</td>
                      <td>{item?.Criteria05_522?.Institute}</td>
                      <td>{item?.Criteria05_522?.Admitted}</td>
                      <td>{item?.Criteria05_522?.Desc25}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_523?.criteria}</td>
                      <td>{item?.Criteria05_523?.No1}</td>
                      <td>{item?.Criteria05_523?.RollNo1}</td>
                      <td>{item?.Criteria05_523?.StdSelected1}</td>
                      <td>{item?.Criteria05_523?.NET1}</td>
                      <td>{item?.Criteria05_523?.SLET1}</td>
                      <td>{item?.Criteria05_523?.GATE1}</td>
                      <td>{item?.Criteria05_523?.GMAT1}</td>
                      <td>{item?.Criteria05_523?.CAT1}</td>
                      <td>{item?.Criteria05_523?.GRE1}</td>
                      <td>{item?.Criteria05_523?.JAM1}</td>
                      <td>{item?.Criteria05_523?.IELTS1}</td>
                      <td>{item?.Criteria05_523?.TOEFL1}</td>
                      <td>{item?.Criteria05_523?.Civil1}</td>
                      <td>{item?.Criteria05_523?.SGExam1}</td>
                      <td>{item?.Criteria05_523?.OtherExam1}</td>
                      <td>{item?.Criteria05_523?.Total1}</td>
                      <td>{item?.Criteria05_523?.GTotal1}</td>
                      <td>{item?.Criteria05_523?.Desc26}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_531?.criteria}</td>
                      <td>{item?.Criteria05_531?.Meda}</td>
                      <td>{item?.Criteria05_531?.TIndi}</td>
                      <td>{item?.Criteria05_531?.USNI1}</td>
                      <td>{item?.Criteria05_531?.SCult}</td>
                      <td>{item?.Criteria05_531?.NaStd}</td>
                      <td>{item?.Criteria05_531?.Desc27}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria05_533?.criteria}</td>
                      <td>{item?.Criteria05_533?.NStdParti}</td>
                      <td>
                        {moment(item?.Criteria05_533?.EDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Criteria05_533?.EName}</td>
                      <td>{item?.Criteria05_533?.Desc28}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
              ,
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_511?.Link21}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_513?.Link22}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_514?.Link23}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_521?.Link24}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_522?.Link25}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_523?.Link26}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_531?.Link27}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_533?.Link28}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default UnderGraduateCri5;
