import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import UGC1aqar from "./UGC1aqar";
import UGC2aqar from "./UGC2aqar";
import UGC3aqar from "./UGC3aqar";
import UGC4aqar from "./UGC4aqar";
import UGC5aqar from "./UGC5aqar";
import UGC6aqar from "./UGC6aqar";
import UGC7aqar from "./UGC7aqar";
import { useLocation } from "react-router-dom";
import PGC1aqar from "./PGC1aqar";
import PGC2aqar from "./PGC2aqar";
import PGC3aqar from "./PGC3aqar";
import PGC4aqar from "./PGC4aqar";
import PGC5aqar from "./PGC5aqar";
import PGC6aqar from "./PGC6aqar";
import PGC7aqar from "./PGC7aqar";
import { Table } from "react-bootstrap";
export default function CreateMergedReportIQAC() {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const location = useLocation();
  const startYeardata = location?.state?.startYearReport;
  const endYeardata = location?.state?.endYearReport;
  const reportDetails = location?.state?.itemDetails;
  let arrOfYears = [];
  for (let i = startYeardata; i < endYeardata; i++) {
    arrOfYears.push(Number(i));
  }
  // Call the function to generate and download the PDF
  const dataToPrintRef = useRef(null);

  const handlePrint = () => {
    if (dataToPrintRef.current) {
      const content = dataToPrintRef.current.innerHTML;
      const originalDocument = document.body.innerHTML;
      document.body.innerHTML = content;
      window.print();
      document.body.innerHTML = originalDocument;
    }
  };

  // useEffect(() => {

  //   console.log("arrOfYears:", arrOfYears);
  // }, [startYeardata, endYeardata]);

  return (
    <>
      <div
        id="pdf"
        ref={dataToPrintRef}
        style={{ overflow: "hidden", height: "700px", overflowY: "scroll" }}
      >
        <div className="text-center">
          {/* <img
            src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
            alt="img"
            className=" pdfimg pt-2 "
          /> */}
          <img src="./header.png" alt="img" className=" pdfimg pt-2 " />
          <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
        </div>
        <div style={{ border: "2px solid black", textAlign: "center" }}>
          <h6>PART A</h6>
        </div>
        <Table responsive>
          <tbody>
            <tr style={{ border: "2px solid black" }}>
              <td>
                <div style={{ color: "red" }}>
                  <b>Data of Institution</b>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>1. Name of the Institution</b>
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.institutionName}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Name of the head of the Institution
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.institutionHead}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Designation
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.designation}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Does the Institution function from own campus
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.insFnOwnCampus}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Phone no/Alternate Phone no
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.phoneno1}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Mobile no
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.mobileno1}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Registered Email
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.regEmail1}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Alternate Email
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.altEmail1}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Address
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.address1}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                City/Town
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.city1}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                State/UT
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.state1}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Pincode
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.pincode1}</b>
              </td>
            </tr>
            <tr style={{ border: "2px solid black" }}>
              <td>
                <b>2. Institutional Status</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Affiliated / Constituent
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.affliated2}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Type of Institution
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.instituteType2}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Location
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.location2}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Financial Status
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.financialStatus2}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Name of the IQAC co-ordinator/Director
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.iqacDirector2}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Phone no/Alternate Phone no
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.phoneno2}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Mobile no
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.mobileno2}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Registered Email
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.regEmail2}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Alternate Email
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.altEmail2}</b>
              </td>
            </tr>
            <tr style={{ border: "2px solid black" }}>
              <td>
                <b>3. Website Address</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Web-link of the AQAR: (Previous Academic Year)
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.weblink3}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>4. Whether Academic Calendar prepared during the year</b>
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.academicCalendar4}</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                if yes,whether it is uploaded in the institutional website:
                Weblink :
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>{reportDetails.ifYes4}</b>
              </td>
            </tr>
            <tr style={{ border: "2px solid black" }}>
              <td>
                <b>5. Accrediation Details</b>
              </td>
            </tr>
          </tbody>
        </Table>
        <div style={{ border: "2px solid black" }}>
          <Table responsive>
            <thead>
              <th>Cycle</th>
              <th>Grade</th>
              <th>CGPA</th>
              <th>Year of Accrediation</th>
              <th>
                Validity
                <Table responsive>
                  <thead>
                    <th>Period From</th>
                    <th>Period To</th>
                  </thead>
                </Table>
              </th>
            </thead>
            <tbody>
              {reportDetails.accredDetails5.map((item) => {
                return (
                  <tr>
                    <td>{item.accCycle}</td>
                    <td>{item.accGrade}</td>
                    <td>{item.accGrade}</td>
                    <td>{item.accYoa}</td>
                    <td>
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>{item.accPF}</td>
                            <td>{item.accPT}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Table>
          <tbody>
            <tr style={{ border: "2px solid black" }}>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>6. Date of Establishment of IQAC</b>
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.doe6}
              </td>
            </tr>
            <tr style={{ border: "2px solid black" }}>
              <td>
                <b>7. Internal Quality Assurance System</b>
              </td>
            </tr>
          </tbody>
        </Table>
        <div style={{ border: "2px solid black" }}>
          <Table>
            <tbody>
              {reportDetails.iqas7.map((item) => {
                return (
                  <tr>
                    <td style={{ border: "2px solid black" }}>{item.iqas1}</td>
                    <td style={{ border: "2px solid black" }}>{item.iqas2}</td>
                    <td style={{ border: "2px solid black" }}>{item.iqas3}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div style={{ textAlign: "center" }}>
            <a
              href={`https://brightnaac.co.in/AQAR/${reportDetails.iqasDoc7}`}
              target="blank_"
            >
              View Document
            </a>
          </div>
        </div>
        <div style={{ border: "2px solid black" }}>
          <div>
            <b>
              8. Provide the list of funds by Central/ State Government-
              UGC/CSIR/DST/DBT/ICMR/TEQIP/World Bank/CPE of UGC etc.
            </b>
          </div>
          <Table>
            <tbody>
              {reportDetails.fundsList8.map((item) => {
                return (
                  <tr>
                    <td style={{ border: "2px solid black" }}>
                      {item.Institution8}
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      {item.Scheme8}
                    </td>
                    <td style={{ border: "2px solid black" }}>{item.Fa8}</td>
                    <td style={{ border: "2px solid black" }}>{item.Yoawd8}</td>
                    <td style={{ border: "2px solid black" }}>
                      {item.amount8}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div style={{ textAlign: "center" }}>
            <a
              href={`https://brightnaac.co.in/AQAR/${reportDetails.fundsListDoc8}`}
              target="blank_"
            >
              View Document
            </a>
          </div>
        </div>
        <Table>
          <tbody>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>
                  9. Whether composition of IQAC as per latest NAAC guidelines:
                </b>
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.compositionIQAC9}
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Upload latest notification of formation of IQAC
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <a
                  href={`https://brightnaac.co.in/AQAR/${reportDetails.latestNotification9}`}
                  target="blank_"
                >
                  {" "}
                  View Document
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>10. Number of IQAC meetings held during the year :</b>
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.noOfIQAC10}
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                The minutes of IQAC meeting and compliances to the decisions
                have been uploaded on the institutional website
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.minutesOfIQAC10}
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Upload the minutes of meeting and action taken report
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <a
                  href={`https://brightnaac.co.in/AQAR/${reportDetails.minutesOfMeetings10}`}
                  target="blank_"
                >
                  {" "}
                  View Document
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>
                  11. Whether IQAC received funding from any of the funding
                  agency to support its activities during the year?
                </b>
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.iqacRecvFunding11}
              </td>
            </tr>
            <tr></tr>
          </tbody>
        </Table>
        <div style={{ border: "2px solid black", fontSize: "14px" }}>
          <div>
            <b>
              12. Significant contributions made by IQAC during the current
              year(maximum five bullets)
            </b>
          </div>

          <div style={{ border: "2px solid black" }}>
            {reportDetails.significantContri12}
          </div>
          <div style={{ textAlign: "center" }}>
            <a
              href={`https://brightnaac.co.in/AQAR/${reportDetails.significantContriDoc12}`}
              target="blank_"
            >
              {" "}
              View Document
            </a>
          </div>
        </div>
        <div style={{ border: "2px solid black", fontSize: "14px" }}>
          <div>
            <b>
              13. Plan of action chalked out by the IQAC in the beginning of the
              academic year towards Quality Enhancement and outcome achieved by
              the end of the academic year
            </b>
          </div>

          <div style={{ border: "2px solid black" }}>
            <Table>
              <thead>
                <th style={{ border: "2px solid black" }}>Plan of Action</th>
                <th style={{ border: "2px solid black" }}>
                  Achivements/Outcomes
                </th>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "2px solid black" }}>
                    {reportDetails.planOfAction13.PA13}
                  </td>
                  <td style={{ border: "2px solid black" }}>
                    {reportDetails.planOfAction13.Achievement13}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div style={{ textAlign: "center" }}>
              <a
                href={`https://brightnaac.co.in/AQAR/${reportDetails.planOfAction13.planDoc}`}
                target="blank_"
              >
                {" "}
                View Document
              </a>
            </div>
          </div>
        </div>
        <Table>
          <tbody>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>14. Whether AQAR was placed before statutory body ?</b>
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.placedBeforeStatutoryBody14}
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>
                  15. Whether NAAC/or any other accredited body(s) visited IQAC
                  or interacted with it to assess the functioning ?
                </b>
              </td>

              {/* <a href="/" target="blank_">
                  {" "}
                  View Document
                </a> */}
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.accredBodyIqac15}
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>16. Whether institutional data submitted to AISHE:</b>
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.aishe16}
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Year of Submission
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.yos16}
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                Date of Submission
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {/* <a href="/" target="blank_">
                  {" "}
                  View Document
                </a> */}
                {reportDetails.dos16}
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                <b>
                  17. Does the Institution have Management Information System ?
                </b>
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.mis17}
              </td>
            </tr>
            <tr>
              <td style={{ border: "2px solid black", width: "50%" }}>
                If yes, give a brief descripiton and a list of modules currently
                operational (maximum 500 words)
              </td>
              <td style={{ border: "2px solid black", width: "50%" }}>
                {reportDetails.ifyes17}
              </td>
            </tr>
          </tbody>
        </Table>
        <div style={{ border: "2px solid black", textAlign: "center" }}>
          <h6>PART B</h6>
          <div style={{ color: "red" }}>
            <b>CURRICULAR ASPECTS</b>
          </div>
        </div>

        <div className="" style={{ textAlign: "justify" }}>
          <div className="row align-items-center m-0 p-3"></div>
          {arrOfYears?.map((val, i) => {
            return (
              <div key={i}>
                <div>
                  <div
                    style={{ color: "red", textAlign: "center", margin: "5px" }}
                  >
                    <b>
                      UG {Number(val)}-{Number(val) + 1}{" "}
                    </b>
                  </div>
                  <UGC1aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                  <UGC2aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                  <UGC3aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                  <UGC4aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                  <UGC5aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                  <UGC6aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                  <UGC7aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                </div>
                <div>
                  <div
                    style={{ color: "red", textAlign: "center", margin: "5px" }}
                  >
                    <b>
                      PG {Number(val)}-{Number(val) + 1}
                    </b>
                  </div>
                  <PGC1aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                  <PGC2aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                  <PGC3aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                  <PGC4aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />{" "}
                  <PGC5aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />{" "}
                  <PGC6aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />{" "}
                  <PGC7aqar
                    startYeardata={Number(val)}
                    endYeardata={Number(val) + 1}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="text-center">
        <button className="ad" style={{}} onClick={() => handlePrint()}>
          Download
        </button>
      </div>
    </>
  );
}
