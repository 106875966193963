import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Image, Modal, Table } from "react-bootstrap";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

function Announcement() {
  // ================ add graphic modal===============================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================ delete graphic modal===============================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================ edit graphic modal===============================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================ show graphic modal===============================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // =================== calling api for adding the slider content======================
  let formdata = new FormData();
  const [doc, setdoc] = useState("");
  const [Topic, setTopic] = useState("");
  const [View, setView] = useState({});

  const AddAnnouncement = async (e) => {
    e.preventDefault();
    if (!doc || !Topic) {
      return alert("Please fill all the fields!!!");
    }
    formdata.set("doc", doc);
    formdata.set("topic", Topic);
    try {
      const config = {
        url: "/admin/addAnnouncement",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formdata,
      };
      await axios(config).then((res) => {
        alert("Added Successfully");
        setdoc("");
        setTopic("");
        getallAnnoun();
        handleClose();
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the slider content======================
  const [AnnouncemnetData, setAnnouncemnetData] = useState([]);
  const getallAnnoun = async () => {
    let res = await axios.get(
      "https://brightnaac.co.in/api/admin/getallAnnouncement"
    );
    if (res.status === 200) {
      setAnnouncemnetData(res.data.allData);
    }
  };

  // =================== calling api for deleting the slider content======================
  const deleteAnnouncement = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/deleteAnnouncement/" + id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setdoc("");
          setTopic("");
          getallAnnoun();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };

  const editAnnoun = async (e, id) => {
    e.preventDefault();

    if (!doc && !Topic) {
      return alert("Please Provide atleast one detail to edit!!!");
    }
    formdata.set("doc", doc);
    formdata.set("topic", Topic);
    try {
      const config = {
        url: "/admin/editAnnouncements/" + id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formdata,
      };
      await axios(config).then((res) => {
        alert(res.data.success);
        setdoc("");
        setTopic("");
        getallAnnoun();
        handleClose2();
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  useEffect(() => {
    getallAnnoun();
  }, []);

  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Announcement Page
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table bordered responsive striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Topic</th>
                <th>Document</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {AnnouncemnetData?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{i + 1}</td>
                    <td>{item?.topic}</td>
                    <td>
                      <AiOutlineEye
                        onClick={() => {
                          setView(item);
                          handleShow4();
                        }}
                        fontSize={20}
                      />
                    </td>
                    <td>
                      <BiEdit
                        size={20}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          margin: "10px",
                        }}
                        onClick={() => {
                          setView(item);
                          handleShow2();
                        }}
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          color: "red",
                          margin: "10px",
                        }}
                        onClick={() => {
                          setView(item);
                          handleShow1();
                        }}
                      >
                        <AiFillDelete size={20} />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* =======================add slider======================= */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Announcement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label htmlFor="upload1">Document</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setdoc(e.target.files[0])}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Topic</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setTopic(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddAnnouncement(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* =======================delete slider======================= */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span
              className="header-03"
              onClick={(e) => deleteAnnouncement(e, View?._id)}
            >
              <Button>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>

        {/* =======================edit slider======================= */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title> Edit Announcement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label htmlFor="upload1">Attach Document</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setdoc(e.target.files[0])}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Topic</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={View?.topic}
                  onChange={(e) => setTopic(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => editAnnoun(e, View?._id)}>
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Announcement/${View?.doc}`}
                  type="application/pdf"
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Announcement;
