import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const UG_Criteria07 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [Budget, setBudget] = useState("");
  const [Lighting, setLighting] = useState("");
  const [PhyFac, setPhyFac] = useState("");
  const [Prolift, setProlift] = useState("");
  const [Ramp, setRamp] = useState("");
  const [Braille, setBraille] = useState("");
  const [RestRoom, setRestRoom] = useState("");
  const [Scribes, setScribes] = useState("");
  const [SpecialSkills, setSpecialSkills] = useState("");
  const [AnyOther, setAnyOther] = useState("");
  const [Annual, setAnnual] = useState("");
  const [NoOfIniti1, setNoOfIniti1] = useState("");
  const [NoOfIniti2, setNoOfIniti2] = useState("");
  const [Date1, setDate1] = useState("");
  const [DateDuration, setDateDuration] = useState("");
  const [Date2, setDate2] = useState("");
  const [Power, setPower] = useState("");
  const [TPower, setTPower] = useState("");
  const [ReEnergyS, setReEnergyS] = useState("");
  const [ReEnergyG, setReEnergyG] = useState("");
  const [EnergySup, setEnergySup] = useState("");
  const [PercentageLED, setPercentageLED] = useState("");
  const [PercentageLight, setPercentageLight] = useState("");
  const [NoOfParticipants, setNoOfParticipants] = useState("");
  const [NoOfActivities, setNoOfActivities] = useState("");
  const [TitlePro, setTitlePro] = useState("");
  const [NoOfParticipants1, setNoOfParticipants1] = useState("");
  const [Male, setMale] = useState("");
  const [Female, setFemale] = useState("");
  const [Yea, setYea] = useState("");
  const [Total, setTotal] = useState("");
  const [PowRe, setPowRe] = useState("");
  const [InitiName, setInitiName] = useState("");
  const [DurInitiative, setDurInitiative] = useState("");
  const [Desc29, setDesc29] = useState("");
  const [Link29, setLink29] = useState("");
  const [Link30, setLink30] = useState("");
  const [Link31, setLink31] = useState("");

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [View, setView] = useState({});
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [UG_Criteria07, setUG_Criteria07] = useState([]);
  const [Value, setValue] = useState({});

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Cri7.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>Physical Facilities</td>
                <td>${item?.Criteria07_007?.PhyFac}</td>
              </tr>
              <tr>
                <td>Provision for Lift</td>
                <td>${item?.Criteria07_007?.Prolift}</td>
              </tr>
              <tr>
                <td>Ramp/ Rails</td>
                <td>${item?.Criteria07_007?.Ramp}</td>
              </tr>
              <tr>
                <td>Braille Software/Facilities</td>
                <td>${item?.Criteria07_007?.Braille}</td>
              </tr>
              <tr>
                <td>Rest Rooms</td>
                <td>${item?.Criteria07_007?.RestRoom}</td>
              </tr>
              <tr>
                <td>Scribes for Examination</td>
                <td>${item?.Criteria07_007?.Scribes}</td>
              </tr><tr>
              <td>Special skill Development for Differently Abled Students</td>
              <td>${item?.Criteria07_007?.SpecialSkills}</td>
            </tr>
            <tr>
                <td>Any other Similar Facility</td>
                <td>${item?.Criteria07_007?.AnyOther}</td>
              </tr>
              <tr>
                <td>Annual expenditure excluding salary component of the institution</td>
                <td>${item?.Criteria07_007?.Annual}</td>
              </tr>
              <tr>
                <td>Number of initiatives to address locational Advantages and Disadvantages</td>
                <td>${item?.Criteria07_007?.NoOfIniti1}</td>
              </tr>
              <tr>
                <td>Number of initiatives taken to Engage with and contribute to Local Community</td>
                <td>${item?.Criteria07_007?.NoOfIniti2}</td>
              </tr>
              <tr>
                <td>Date and Duration of the Initiative Name of the Initiative Issues Addressed</td>
                <td>${item?.Criteria07_007?.DateDuration}</td>
              </tr>
              <tr>
                <td>Power Requirement met by Renewable Energy Sources</td>
                <td>${item?.Criteria07_007?.Power}</td>
              </tr>
              <tr>
                <td>Total Power requirements</td>
                <td>${item?.Criteria07_007?.TPower}</td>
              </tr>
              <tr>
                <td>Renewable Energy Source</td>
                <td>${item?.Criteria07_007?.ReEnergyS}</td>
              </tr>
              <tr>
                <td>Renewable Energy Generated and Used</td>
                <td>${item?.Criteria07_007?.ReEnergyG}</td>
              </tr>
              <tr>
              <td>Energy Supplied to the Grid</td>
              <td>${item?.Criteria07_007?.EnergySup}</td>
            </tr>
            <tr>
                <td>Percentage Lighting through LED bulbs</td>
                <td>${item?.Criteria07_007?.PercentageLED}</td>
              </tr>
              <tr>
                <td>Percentage Lighting through other sources</td>
                <td>${item?.Criteria07_007?.PercentageLight}</td>
              </tr>
              <tr>
                <td>Number of Participating Students and Staff</td>
                <td>${item?.Criteria07_007?.NoOfParticipants}</td>
              </tr>
              <tr>
                <td>No. of Activities</td>
                <td>${item?.Criteria07_007?.NoOfActivities}</td>
              </tr><tr>
              <td>Title of the Program/Activity</td>
              <td>${item?.Criteria07_007?.TitlePro}</td>
            </tr>
            <tr>
                <td>Number of Participants</td>
                <td>${item?.Criteria07_007?.NoOfParticipants1}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
          <table>
              <tr>
                <td>From</td>
                <td>${moment(item?.Criteria07_007?.Date1).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              <tr>
                <td>To</td>
                <td>${moment(item?.Criteria07_007?.Date2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              </table>
          `;
        let tableMarkup3 = `
          <table>
              <tr>
               <td>Male</td>
                <td>${item?.Criteria07_007?.Male}</td>
              </tr>
              <tr>
                 <td>Female</td>
                <td>${item?.Criteria07_007?.Female}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>${item?.Criteria07_007?.Yea}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${item?.Criteria07_007?.Total}</td>
              </tr>
              </table>
          `;
        let obj = {
          Year: item?.Year,
          "Total Lighting requirements": item?.Criteria07_007?.Lighting,
          "Budget allocated for green initiatives":
            item?.Criteria07_007?.Budget,
          "Status of implementation of CBCS / Elective Course System (ECS)":
            tableMarkup1,
          "Date and Duration": tableMarkup2,
          "Number of participants by Gender": tableMarkup3,
          "Power Requirement Renewable": item?.Criteria07_007?.PowRe,
          "Initiative names": item?.Criteria07_007?.InitiName,
          "Duration of the Initiative": item?.Criteria07_007?.DurInitiative,
          Description: item?.Criteria07_007?.Desc29,
          "Relevent Document": item?.Criteria07_007?.Link29,
          "Relevent Document": item?.Criteria07_007?.Link30,
          "Relevent Document": item?.Criteria07_007?.Link31,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editCriteria07_007 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc29;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Desc29) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editCriteria07_007/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Budget: Budget,
          Lighting: Lighting,
          PhyFac: PhyFac,
          Prolift: Prolift,
          Ramp: Ramp,
          Braille: Braille,
          RestRoom: RestRoom,
          Scribes: Scribes,
          SpecialSkills: SpecialSkills,
          AnyOther: AnyOther,
          Annual: Annual,
          NoOfIniti1: NoOfIniti1,
          NoOfIniti2: NoOfIniti2,
          Date1: Date1,
          DateDuration: DateDuration,
          Date2: Date2,
          Power: Power,
          TPower: TPower,
          ReEnergyS: ReEnergyS,
          ReEnergyG: ReEnergyG,
          EnergySup: EnergySup,
          PercentageLED: PercentageLED,
          PercentageLight: PercentageLight,
          NoOfParticipants: NoOfParticipants,
          NoOfActivities: NoOfActivities,
          TitlePro: TitlePro,
          NoOfParticipants1: NoOfParticipants1,
          Male: Male,
          Female: Female,
          Yea: Yea,
          Total: Total,
          PowRe: PowRe,
          InitiName: InitiName,
          DurInitiative: DurInitiative,
          Desc29: Desc29,
          Link29: Link29,
          Link30: Link30,
          Link31: Link31,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri07details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getcri07details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getcri07details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria07);
        setUG_Criteria07(res.data.ugcriteria07);
        setdata1(res.data.ugcriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria07);
    }
  };

  useEffect(() => {
    getcri07details();
  }, []);
  console.log(Value);

  // =================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  return (
    <div>
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                UnderGraduate_Criteria-07
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/ugcriteria007">
                <button className="btn btn-success">
                  Add Criteria 7
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>FacultyName</th>

                  <th>Criteria</th>
                  <th>Total Lighting requirements</th>
                  <th>Budget allocated for green initiatives </th>
                  <th>Physical Facilities </th>
                  <th>Provision for Lift </th>
                  <th>Ramp/ Rails </th>
                  <th>Braille Software/Facilities </th>
                  <th>Rest Rooms </th>
                  <th>Scribes for Examination</th>
                  <th>
                    Special skill Development for Differently Abled Students
                  </th>
                  <th>Any other Similar Facility </th>
                  <th>
                    Annual expenditure excluding salary component of the
                    institution
                  </th>
                  <th>
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </th>
                  <th>
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </th>
                  <th>
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </th>
                  <th>Power Requirement met by Renewable Energy Sources</th>
                  <th>Total Power requirements</th>
                  <th>Renewable Energy Source </th>
                  <th>Renewable Energy Generated and Used </th>
                  <th>Energy Supplied to the Grid </th>
                  <th>Percentage Lighting through LED bulbs </th>
                  <th>Percentage Lighting through other sources</th>
                  <th>Number of Participating Students and Staff </th>
                  <th>No. of Activities </th>
                  <th>Title of the Program/Activity </th>
                  <th>Number of Participants </th>
                  <th>From </th>
                  <th>To </th>
                  <th>Male </th>
                  <th>Female </th>
                  <th>Year</th>
                  <th>Total</th>
                  <th>Power Requirement Renewable</th>
                  <th>Initiative names </th>
                  <th>Duration_of_the_Initiative</th>
                  <th>Description </th>
                  <th>Document 1</th>
                  <th>Document 2</th>
                  <th>Document 3</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {UG_Criteria07?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Year?.includes(SearchItem) ||
                      item1?.Criteria07_007?.Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.Lighting?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.PhyFac?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Prolift?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Ramp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Braille?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.RestRoom?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Scribes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.SpecialSkills?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.AnyOther?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Annual?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.NoOfIniti1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.NoOfIniti2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.DateDuration?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Power?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.TPower?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.ReEnergyS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.ReEnergyG?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.EnergySup?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.PercentageLED?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.PercentageLight?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.NoOfParticipants?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.NoOfActivities?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.TitlePro?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.NoOfParticipants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Criteria07_007?.Date1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Criteria07_007?.Date2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria07_007?.Male?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.Female?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.Yea?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.PowRe?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.InitiName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.DurInitiative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Desc29?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Criteria07_007?.criteria}</td>
                      <td>{item?.Criteria07_007?.Lighting}</td>
                      <td>{item?.Criteria07_007?.Budget}</td>
                      <td>{item?.Criteria07_007?.PhyFac}</td>
                      <td>{item?.Criteria07_007?.Prolift}</td>
                      <td>{item?.Criteria07_007?.Ramp}</td>
                      <td>{item?.Criteria07_007?.Braille}</td>
                      <td>{item?.Criteria07_007?.RestRoom}</td>
                      <td>{item?.Criteria07_007?.Scribes}</td>
                      <td>{item?.Criteria07_007?.SpecialSkills}</td>
                      <td>{item?.Criteria07_007?.AnyOther}</td>
                      <td>{item?.Criteria07_007?.Annual}</td>
                      <td>{item?.Criteria07_007?.NoOfIniti1}</td>
                      <td>{item?.Criteria07_007?.NoOfIniti2}</td>
                      <td>{item?.Criteria07_007?.DateDuration}</td>
                      <td>{item?.Criteria07_007?.Power}</td>
                      <td>{item?.Criteria07_007?.TPower}</td>
                      <td>{item?.Criteria07_007?.ReEnergyS}</td>
                      <td>{item?.Criteria07_007?.ReEnergyG}</td>
                      <td>{item?.Criteria07_007?.EnergySup}</td>
                      <td>{item?.Criteria07_007?.PercentageLED}</td>
                      <td>{item?.Criteria07_007?.PercentageLight}</td>
                      <td>{item?.Criteria07_007?.NoOfParticipants}</td>
                      <td>{item?.Criteria07_007?.NoOfActivities}</td>
                      <td>{item?.Criteria07_007?.TitlePro}</td>
                      <td>{item?.Criteria07_007?.NoOfParticipants1}</td>
                      <td>
                        {moment(item?.Criteria07_007?.Date1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Criteria07_007?.Date2).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Criteria07_007?.Male}</td>
                      <td>{item?.Criteria07_007?.Female}</td>
                      <td>{item?.Criteria07_007?.Yea}</td>
                      <td>{item?.Criteria07_007?.Total}</td>
                      <td>{item?.Criteria07_007?.PowRe}</td>
                      <td>{item?.Criteria07_007?.InitiName}</td>
                      <td>{item?.Criteria07_007?.DurInitiative}</td>
                      <td>
                        {item?.Criteria07_007?.Desc29?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={(e) => {
            handleClose();
            setedit1(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              {" "}
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                UnderGraduate_Criteria-07
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Criteria-07
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Lighting requirements
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.Lighting}
                        onChange={(e) => {
                          setLighting(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.Lighting}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Budget allocated for green initiatives
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.Budget}
                        onChange={(e) => {
                          setBudget(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.Budget}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Status of implementation of CBCS / Elective Course System
                  (ECS)
                </h6>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Physical Facilities
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Criteria07_007?.PhyFac}
                        onChange={(e) => {
                          setPhyFac(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Criteria07_007?.PhyFac}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Provision for Lift
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Criteria07_007?.Prolift}
                        onChange={(e) => {
                          setProlift(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Criteria07_007?.Prolift}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Ramp/ Rails
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Criteria07_007?.Ramp}
                        onChange={(e) => {
                          setRamp(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Criteria07_007?.Ramp}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Braille Software/Facilities
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Criteria07_007?.Braille}
                        onChange={(e) => {
                          setBraille(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Criteria07_007?.Braille}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Rest Rooms
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Criteria07_007?.RestRoom}
                        onChange={(e) => {
                          setRestRoom(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Criteria07_007?.RestRoom}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Scribes for Examination
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Criteria07_007?.Scribes}
                        onChange={(e) => {
                          setScribes(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Criteria07_007?.Scribes}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Special skill Development for Differently Abled Students
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "435px" }}
                        placeholder={Value?.Criteria07_007?.SpecialSkills}
                        onChange={(e) => {
                          setSpecialSkills(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Criteria07_007?.SpecialSkills}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Any other Similar Facility
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Criteria07_007?.AnyOther}
                        onChange={(e) => {
                          setAnyOther(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.Criteria07_007?.AnyOther}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Annual expenditure excluding salary component of the
                    institution
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Criteria07_007?.Annual}
                      onChange={(e) => {
                        setAnnual(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.Annual}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Criteria07_007?.NoOfIniti1}
                      onChange={(e) => {
                        setNoOfIniti1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.NoOfIniti1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Criteria07_007?.NoOfIniti2}
                      onChange={(e) => {
                        setNoOfIniti2(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.NoOfIniti2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Criteria07_007?.DateDuration}
                      onChange={(e) => {
                        setDateDuration(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.DateDuration}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement met by Renewable Energy Sources
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Criteria07_007?.Power}
                      onChange={(e) => {
                        setPower(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.Power}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Power requirements
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.TPower}
                        onChange={(e) => {
                          setTPower(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.TPower}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Source:
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.ReEnergyS}
                        onChange={(e) => {
                          setReEnergyS(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.ReEnergyS}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Generated and Used
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.ReEnergyG}
                        onChange={(e) => {
                          setReEnergyG(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.ReEnergyG}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Energy Supplied to the Grid
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.EnergySup}
                        onChange={(e) => {
                          setEnergySup(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.EnergySup}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Percentage Lighting through other sources
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.PercentageLight}
                        onChange={(e) => {
                          setPercentageLight(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.PercentageLight}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participating Students and Staff:
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.NoOfParticipants}
                        onChange={(e) => {
                          setNoOfParticipants(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.NoOfParticipants}</>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Percentage Lighting through LED bulbs
                      </label>
                      <br />
                      {edit1 ? (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Criteria07_007?.PercentageLED}
                          onChange={(e) => {
                            setPercentageLED(e.target.value);
                          }}
                        />
                      ) : (
                        <>{Value?.Criteria07_007?.PercentageLED}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        No. of Activities
                      </label>
                      <br />
                      {edit1 ? (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Criteria07_007?.NoOfActivities}
                          onChange={(e) => {
                            setNoOfActivities(e.target.value);
                          }}
                        />
                      ) : (
                        <>{Value?.Criteria07_007?.NoOfActivities}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Title of the Program/Activity
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.TitlePro}
                        onChange={(e) => {
                          setTitlePro(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.TitlePro}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participants
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.NoOfParticipants1}
                        onChange={(e) => {
                          setNoOfParticipants1(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.NoOfParticipants1}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Date and Duration
                </h6>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      From
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.Date1}
                        onChange={(e) => {
                          setDate1(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.Date1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      To
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.Date2}
                        onChange={(e) => {
                          setDate2(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.Date2}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of participants by Gender
                </h6>
                <div className="row">
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Male
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.Male}
                        onChange={(e) => {
                          setMale(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.Male}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Female
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.Female}
                        onChange={(e) => {
                          setFemale(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.Female}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.Yea}
                        onChange={(e) => {
                          setYea(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.Yea}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.Total}
                        onChange={(e) => {
                          setTotal(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.Total}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Power Requirement Renewable
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.PowRe}
                        onChange={(e) => {
                          setPowRe(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.PowRe}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Initiative names
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria07_007?.InitiName}
                        onChange={(e) => {
                          setInitiName(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.Criteria07_007?.InitiName}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration_of_the_Initiative
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Criteria07_007?.DurInitiative}
                      onChange={(e) => {
                        setDurInitiative(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.DurInitiative}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br />
                  {edit1 ? (
                    <textarea
                      className="vi_0"
                      rows={3}
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.Criteria07_007?.Desc29}
                      onChange={(e) => {
                        setDesc29(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.Desc29}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document 1
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload1"
                      accept="image/*"
                      placeholder={Value?.Criteria07_007?.Link29}
                      onChange={(e) => {
                        setLink29(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.Link29}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Document 2
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload2"
                      accept="image/*"
                      placeholder={Value?.Criteria07_007?.Link30}
                      onChange={(e) => {
                        setLink30(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.Link30}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Document 3
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload3"
                      accept="image/*"
                      placeholder={Value?.Criteria07_007?.Link31}
                      onChange={(e) => {
                        setLink31(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Value?.Criteria07_007?.Link31}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria07_007(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => {
                              setedit1(true);
                            }}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
                setedit1(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria07_007?.Desc29}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria07/${View?.Criteria07_007?.Link29}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria07/${View?.Criteria07_007?.Link30}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria07/${View?.Criteria07_007?.Link31}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                ></object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default UG_Criteria07;
