import React from "react";
import "./Styles/Pagenotfound.css";
import { Link } from "react-router-dom";
const Pagenotfound = () => {
  return (
    <div className="pagenotfound">
      <div class="center">
        <div>
          <h1>404</h1>

          <p>
            Oops! The page you are looking for does not exist. It might have
            been moved or deleted.
          </p>
          <br />
          <Link to="/" className=" btn-not text-decoration-none">
            BACK TO HOMEPAGE
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Pagenotfound;
