
import React from "react";
import "./Styles/form.css";
const Facultylogin = () => {
  return (

    <div className="rigesterpage">
<div className="container " >
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-7 border mt-5 mb-5 form-col">
          <p className="p-3 border-bottom text-center contact-form-text">
           Registration Form
          </p>
          <div className="m-auto">
            <form className="p-2">
              <div className="row">
                <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label label-r">
                    Name
                  </label>
                  <input
                    type="text"
                    class="form-c border"
                    id="exampleFormControlInput1"
                    placeholder="Name"
                  />
                </div>
                <div class=" col-lg-6 col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label  label-r">
                    Email address
                  </label>
                  <input
                    type="email"
                    class="form-c border"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                  />
                </div>
              </div>

              <div className="row">
                <div class=" col-lg-6 col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label  label-r">
                    Phone No
                  </label>
                  <input
                    type="tel"
                    class="form-c border"
                    id="exampleFormControlInput1"
                    placeholder=" Phone No"
                  />
                </div>
                <div class=" col-lg-6 col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label  label-r">
                    Specification
                  </label>
                  <select class="form-c border" placeholder="Course">
                    <option>Choose ...</option>
                    <option></option>
                  </select>
                </div>
              </div>

              <div className="row mt-2 mb-2">
                <div class="  col-3">
                  <label for="exampleFormControlInput1" class="form-label  label-r">
                    Gender
                  </label>
                </div>
                <div className="col-md-3 " id="Rgender">
                  <input
                    name="gender"
                    type="radio"
                    class=" "
                    id="exampleFormControlInput1"
                    placeholder=""
                  />{" "}
                  <label class="form-label label-r">male</label>
                </div> 
                <div className="col-md-4" id="Rgender">
                  <input
                    name="gender"
                    type="radio"
                    class=" "
                    id="exampleFormControlInput1"
                    placeholder=""
                  />{" "}
                  <label class="form-label  label-r">female</label>
                </div>
              </div>

              <div className="row">
                <div class="mb-3 col-lg col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label  label-r">
                    Password
                  </label>
                  <input
                    type="password"
                    class="form-c border"
                    id="exampleFormControlInput1"
                    placeholder=" Password"
                  />
                </div>
                <div class="mb-3 col-lg col-md-12 col-sm-12 col-12">
                  <label for="exampleFormControlInput1" class="form-label  label-r">
                   Confirm Password
                  </label>
                  <input
                    type="password"
                    class="form-c border"
                    id="exampleFormControlInput1"
                    placeholder=" Password"
                  />
                </div>
              </div>

              <button className="btn-submit  border ">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  );
};

export default Facultylogin;
