import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

const Criteria7 = () => {
  const navigate = useNavigate();
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));

  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Autonomous Criteria 7");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          approvedetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
          item1?.approve === "true" &&
          selectedYear === item1?.Auto_Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>Physical Facilities</td>
                <td>${item?.Auto_Criteria07_007?.Auto_PhyFac}</td>
              </tr>
              <tr>
                <td>Provision for Lift</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Prolift}</td>
              </tr>
              <tr>
                <td>Ramp/ Rails</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Ramp}</td>
              </tr>
              <tr>
                <td>Braille Software/Facilities</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Braille}</td>
              </tr>
              <tr>
                <td>Rest Rooms</td>
                <td>${item?.Auto_Criteria07_007?.Auto_RestRoom}</td>
              </tr>
              <tr>
                <td>Scribes for Examination</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Scribes}</td>
              </tr><tr>
              <td>Special skill Development for Differently Abled Students</td>
              <td>${item?.Auto_Criteria07_007?.Auto_SpecialSkills}</td>
            </tr>
            <tr>
                <td>Any other Similar Facility</td>
                <td>${item?.Auto_Criteria07_007?.Auto_AnyOther}</td>
              </tr>
              <tr>
                <td>Annual expenditure excluding salary component of the institution</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Annual}</td>
              </tr>
              <tr>
                <td>Number of initiatives to address locational Advantages and Disadvantages</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfIniti1}</td>
              </tr>
              <tr>
                <td>Number of initiatives taken to Engage with and contribute to Local Community</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfIniti2}</td>
              </tr>
              <tr>
                <td>Date and Duration of the Initiative Name of the Initiative Issues Addressed</td>
                <td>${item?.Auto_Criteria07_007?.Auto_DateDuration}</td>
              </tr>
              <tr>
                <td>Power Requirement met by Renewable Energy Sources</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Power}</td>
              </tr>
              <tr>
                <td>Total Power requirements</td>
                <td>${item?.Auto_Criteria07_007?.Auto_TPower}</td>
              </tr>
              <tr>
                <td>Renewable Energy Source</td>
                <td>${item?.Auto_Criteria07_007?.Auto_ReEnergyS}</td>
              </tr>
              <tr>
                <td>Renewable Energy Generated and Used</td>
                <td>${item?.Auto_Criteria07_007?.Auto_ReEnergyG}</td>
              </tr>
              <tr>
              <td>Energy Supplied to the Grid</td>
              <td>${item?.Auto_Criteria07_007?.Auto_EnergySup}</td>
            </tr>
            <tr>
                <td>Percentage Lighting through LED bulbs</td>
                <td>${item?.Auto_Criteria07_007?.Auto_PercentageLED}</td>
              </tr>
              <tr>
                <td>Percentage Lighting through other sources</td>
                <td>${item?.Auto_Criteria07_007?.Auto_PercentageLight}</td>
              </tr>
              <tr>
                <td>Number of Participating Students and Staff</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfParticipants}</td>
              </tr>
              <tr>
                <td>No. of Activities</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfActivities}</td>
              </tr><tr>
              <td>TTitle of the Program/Activity</td>
              <td>${item?.Auto_Criteria07_007?.Auto_TitlePro}</td>
            </tr>
            <tr>
                <td>Number of Participants</td>
                <td>${item?.Auto_Criteria07_007?.Auto_NoOfParticipants1}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
          <table>
              <tr>
                <td>From</td>
                <td>${moment(item?.Auto_Criteria07_007?.Auto_Date1).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              <tr>
                <td>To</td>
                <td>${moment(item?.Auto_Criteria07_007?.Auto_Date2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              </table>
          `;
        let tableMarkup3 = `
          <table>
              <tr>
                <td>MALE</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Male}</td>
              </tr>
              <tr>
                <td>FEMALE</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Female}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Yea}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${item?.Auto_Criteria07_007?.Auto_Total}</td>
              </tr>
              </table>
          `;
        let obj = {
          Year: item?.Auto_Year,
          "Total Lighting requirements":
            item?.Auto_Criteria07_007?.Auto_Lighting,
          "Budget allocated for green initiatives":
            item?.Auto_Criteria07_007?.Auto_Budget,
          "Status of implementation of CBCS / Elective Course System (ECS)":
            tableMarkup1,
          "Date and Duration": tableMarkup2,
          "Number of participants by Gender": tableMarkup3,
          "Power Requirement Renewable": item?.Auto_Criteria07_007?.Auto_PowRe,
          "Initiative names": item?.Auto_Criteria07_007?.Auto_InitiName,
          "Duration of the Initiative":
            item?.Auto_Criteria07_007?.Auto_DurInitiative,
          Description: item?.Auto_Criteria07_007?.Auto_Desc29,
          "Relevent Document": item?.Auto_Criteria07_007?.Auto_Link29,
          "Relevent Document": item?.Auto_Criteria07_007?.Auto_Link30,
          "Relevent Document": item?.Auto_Criteria07_007?.Auto_Link31,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  //  ===========================================================================================

  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        approvedetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
        item1?.approve === "true" &&
        selectedYear === item1?.Auto_Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 7 });
  };

  // ==================================================================
  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/ugadmin/approveCriteria/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getCriteria07();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const getCriteria07 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getAuto_cri07details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.autocriteria07);
        setdata1(res.data.autocriteria07);
        setfilterPdf(res.data.autocriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria07);
    }
  };
  console.log("AllDetails7", AllDetails);

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getCriteria07();
      getAllYears();
    }
  }, []);
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-8 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-07
              </span>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <Button className="btn btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black" }}>Sl.No</th>
                  <th style={{ border: "1px solid black" }}>Year</th>

                  <th style={{ border: "1px solid black" }}>
                    Total Lighting requirements
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Budget allocated for green initiatives
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Status of implementation of CBCS / Elective Course System
                    (ECS)
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Date and Duration
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Number of participants by Gender
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Power Requirement Renewable
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Initiative names
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Duration of the Initiative
                  </th>
                  <th style={{ border: "1px solid black" }}>Description</th>
                  <th style={{ border: "1px solid black" }}>
                    Relevant document
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Relevant document
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Relevant document
                  </th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Auto_Year &&
                    (SearchItem === "" ||
                      item1?.Auto_Year?.includes(SearchItem) ||
                      item1?.Auto_Criteria07_007?.Auto_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Lighting?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_PhyFac?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Prolift?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Ramp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Braille?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_RestRoom?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Scribes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_SpecialSkills?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_AnyOther?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Annual?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfIniti1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfIniti2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_DateDuration?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Power?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_TPower?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_ReEnergyS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_ReEnergyG?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_EnergySup?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_PercentageLED?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_PercentageLight?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfParticipants?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfActivities?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_TitlePro?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_NoOfParticipants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Auto_Criteria07_007?.Auto_Date1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Auto_Criteria07_007?.Auto_Date2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Auto_Criteria07_007?.Auto_Male?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Female?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Yea?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_PowRe?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_InitiName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_DurInitiative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria07_007?.Auto_Desc29?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td style={{ border: "1px solid black" }}>{++i}.</td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Year}
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_Lighting}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_Budget}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Physical Facilities
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Provision for Lift
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Ramp/ Rails
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Braille Software/Facilities
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Rest Rooms
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Scribes for Examination
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Special skill Development for Differently Abled
                              Students
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Any other Similar Facility
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Annual expenditure excluding salary component of
                              the institution
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of initiatives to address locational
                              Advantages and Disadvantages
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of initiatives taken to Engage with and
                              contribute to Local Community
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Date and Duration of the Initiative Name of the
                              Initiative Issues Addressed
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Power Requirement met by Renewable Energy Sources
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Total Power requirements
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Renewable Energy Source
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Renewable Energy Generated and Used
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Energy Supplied to the Grid
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Percentage Lighting through LED bulbs
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Percentage Lighting through other sources
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of Participating Students and Staff
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              No. of Activities
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              TTitle of the Program/Activity
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of Participants
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_PhyFac}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Prolift}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Ramp}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Braille}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_RestRoom}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Scribes}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_SpecialSkills}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_AnyOther}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Annual}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_NoOfIniti1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_NoOfIniti2}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_DateDuration}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Power}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_TPower}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_ReEnergyS}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_ReEnergyG}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_EnergySup}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_PercentageLED}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {
                                  item?.Auto_Criteria07_007
                                    ?.Auto_PercentageLight
                                }
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {
                                  item?.Auto_Criteria07_007
                                    ?.Auto_NoOfParticipants
                                }
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_NoOfActivities}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_TitlePro}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {
                                  item?.Auto_Criteria07_007
                                    ?.Auto_NoOfParticipants1
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>From</th>
                            <th style={{ border: "1px solid black" }}>To</th>
                          </thead>
                          <tbody>
                            <td style={{ border: "1px solid black" }}>
                              {moment(
                                item?.Auto_Criteria07_007?.Auto_Date1
                              ).format("DD-MM-YYYY")}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {moment(
                                item?.Auto_Criteria07_007?.Auto_Date2
                              ).format("DD-MM-YYYY")}
                            </td>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>MALE</th>
                            <th style={{ border: "1px solid black" }}>
                              FEMALE
                            </th>
                            <th style={{ border: "1px solid black" }}>Year</th>
                            <th style={{ border: "1px solid black" }}>Total</th>
                          </thead>
                          <tbody>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Auto_Criteria07_007?.Auto_Male}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Auto_Criteria07_007?.Auto_Female}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Auto_Criteria07_007?.Auto_Yea}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Auto_Criteria07_007?.Auto_Total}
                            </td>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_PowRe}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_InitiName}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_DurInitiative}
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_Desc29?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {/* Show description modal */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Body>
              <div>{View?.Auto_Criteria07_007?.Auto_Desc29}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose8}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria07/${View?.Auto_Criteria07_007?.Auto_Link29}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria07/${View?.Auto_Criteria07_007?.Auto_Link30}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria07/${View?.Auto_Criteria07_007?.Auto_Link31}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* View Model */}
          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#" }}>Criteria 7 </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div
                  className="row border "
                  style={{
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  {" "}
                  <h6>Criteria07</h6>{" "}
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total Lighting requirements
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Lighting}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Budget allocated for green initiatives:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Budget}</>
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Status of implementation of CBCS / Elective Course System
                    (ECS):
                  </h6>
                  <div className="row">
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Physical Facilities:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_PhyFac}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Provision for Lift:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Prolift}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Ramp/ Rails:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Ramp}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Braille Software/Facilities:
                      </label>
                      <br />
                      <>{Value?.Auto_Criteria07_007?.Auto_Braille}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Rest Rooms:{" "}
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_RestRoom}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Scribes for Examination
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Scribes}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Special skill Development for Differently Abled
                        Students:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_SpecialSkills}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Any other Similar Facility:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_AnyOther}</>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Annual expenditure excluding salary component of the
                      institution
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_Annual}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of initiatives to address locational Advantages and
                      Disadvantages
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_NoOfIniti1}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of initiatives taken to Engage with and contribute
                      to Local Community
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_NoOfIniti2}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date and Duration of the Initiative Name of the Initiative
                      Issues Addressed
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_DateDuration}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Power Requirement met by Renewable Energy Sources
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_Power}</>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total Power requirements
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_TPower}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Renewable Energy Source:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_ReEnergyS}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Renewable Energy Generated and Used :
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_ReEnergyG}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Energy Supplied to the Grid:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_EnergySup}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Percentage Lighting through other sources
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_PercentageLight}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of Participating Students and Staff:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_NoOfParticipants}</>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Percentage Lighting through LED bulbs:
                        </label>
                        <br />

                        <>{Value?.Auto_Criteria07_007?.Auto_PercentageLED}</>
                      </div>
                      <div className="col-md-6 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          No. of Activities:
                        </label>
                        <br />

                        <>{Value?.Auto_Criteria07_007?.Auto_NoOfActivities}</>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        TTitle of the Program/Activity:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_TitlePro}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of Participants:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_NoOfParticipants1}</>
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Date and Duration:
                  </h6>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        From:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Date1}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        To:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Date2}</>
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Number of participants by Gender:
                  </h6>
                  <div className="row">
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        MALE:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Male}</>
                    </div>
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        FEMALE:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Female}</>
                    </div>
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Yea}</>
                    </div>
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_Total}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Power Requirement Renewable
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_PowRe}</>
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Initiative names:
                      </label>
                      <br />

                      <>{Value?.Auto_Criteria07_007?.Auto_InitiName}</>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Duration_of_the_Initiative
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_DurInitiative}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description (min 50 characters)
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_Desc29}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document Upload Select file to upload:
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_Link29}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload2">
                      {" "}
                      Document Upload Select file to upload:
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_Link30}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload3">
                      {" "}
                      Document Upload Select file to upload:
                    </label>
                    <br />

                    <>{Value?.Auto_Criteria07_007?.Auto_Link31}</>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {Value?.approve === "false" ? (
                <></>
              ) : (
                <div>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      approveCriteria(e, true, Value?._id);
                      handleClose();
                    }}
                  >
                    Approve{" "}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => setSmShow(true)}
                    className="me-2"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal>

          <Modal
            // size="sm"
            show={smShow}
            onHide={() => handleClosesm()}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Tell me the reason?..
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                onChange={(e) => setremark(e.target.value)}
                style={{ height: "200px" }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  approveCriteria(e, false, Value?._id);
                  handleClose();
                  handleClosesm();
                }}
              >
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Criteria7;
