import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";

function AHome() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
      <div className="conrainer">
        <div className="row">
          <div className="header-01">
            <h2
              style={{
                textAlign: "center",
                fontFamily: "fangsong",
                fontWeight: "600",
              }}
            >
              Home Page
            </h2>
          </div>
          <div className="header-02">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>
        <br />
        <div className="row">
          <table>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight:"41px"
                }}
              >
                <th>Sl.</th>
                <th>Name</th>
                <th>Phone No.</th>
                <th>Email Id</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: "center", border: "1px solid #161632" }}>
                <td style={{ border: "1px solid #161632" }}>1</td>
                <td style={{ border: "1px solid #161632" }}>Bishwajit</td>
                <td style={{ border: "1px solid #161632" }}>7541086135</td>
                <td style={{ border: "1px solid #161632" }}>dev@gmail.com</td>
                <td style={{ border: "1px solid #161632" }}>Bengalore</td>
                <td style={{ border: "1px solid #161632" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleShow1()}
                  >
                    <AiFillDelete />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Submit</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span className="header-03">
              <Button>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AHome;
