import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { BsArrowUp } from "react-icons/bs";
import "./Css/dashboard.css";
import axios from "axios";
function Dashboard() {
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  const [collegeApp, setCollegeApp] = useState([]);
  const getallAppColleges = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/app/approvedlist"
      );
      if (res.status === 200) {
        setCollegeApp(res.data.Register);
      }
    } catch (error) {
      console.log(error);
      setCollegeApp(error.response.data.Register);
    }
  };

  // =================== calling api for getting the Course content======================
  const [CoursesList, setCoursesList] = useState([]);
  const getCourseList = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getCourseDetails"
      );
      if (res.status === 200) {
        setCoursesList(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================== calling api for getting the Event content======================
  const [eventDetails, seteventDetails] = useState([]);
  const getEventContent = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getEvents");
      if (res.status === 200) {
        seteventDetails(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ==========================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getallAppColleges();
      getCourseList();
      getEventContent();
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center mt-4 m-0">
          <div className="col-lg-12 mb-4 ">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Dashboard
            </span>
          </div>
          <Card
            className="dashcard"
            style={{
              width: "16rem",
              height: "14rem",
              backgroundColor: "#3399ff",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title style={{ color: "white", textAlign: "center" }}>
                HEI List
              </Card.Title>
              {/* <hr /> */}
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                }}
              >
                <span>
                  <ol>
                    <li>1. UG</li>
                    <li>2. PG</li>
                    <li>3. UG & PG</li>
                    <li>4. Autonomous</li>
                    <li>5. University</li>
                  </ol>
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "16rem",
              height: "14rem",
              backgroundColor: "#3399ff",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title style={{ color: "white", textAlign: "center" }}>
                Number of UG Colleges
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <span>
                  {
                    collegeApp.filter((collegeData) => collegeData.HEI === "UG")
                      ?.length
                  }
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "16rem",
              height: "14rem",
              backgroundColor: "#3399ff",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title style={{ color: "white", textAlign: "center" }}>
                Number of PG Colleges
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <span>
                  {
                    collegeApp.filter((collegeData) => collegeData.HEI === "PG")
                      ?.length
                  }
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "16rem",
              height: "14rem",
              backgroundColor: "#3399ff",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title style={{ color: "white", textAlign: "center" }}>
                Number of UG & PG Colleges
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <span>
                  {
                    collegeApp.filter(
                      (collegeData) => collegeData.HEI === "UG&PG"
                    )?.length
                  }
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "16rem",
              height: "14rem",
              backgroundColor: "#3399ff",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title style={{ color: "white", textAlign: "center" }}>
                Number of Autonomous Colleges
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <span>
                  {
                    collegeApp.filter(
                      (collegeData) => collegeData.HEI === "Autonomous"
                    )?.length
                  }
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "16rem",
              height: "14rem",
              backgroundColor: "#3399ff",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title style={{ color: "white", textAlign: "center" }}>
                Number of Universities
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <span>
                  {
                    collegeApp.filter(
                      (collegeData) => collegeData.HEI === "University"
                    )?.length
                  }
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "16rem",
              height: "14rem",
              backgroundColor: "#f9b115",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title style={{ color: "white", textAlign: "center" }}>
                Total Courses
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <span>{CoursesList?.length}</span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "16rem",
              height: "14rem",
              backgroundColor: "#e55353",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title style={{ color: "white", textAlign: "center" }}>
                Number Of Events
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <span>{eventDetails?.length}</span>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
