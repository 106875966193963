import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const UG_Criteria01 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [TeacherPar, setTeacherPar] = useState("");
  const [BodyName, setBodyName] = useState("");
  const [Desc1, setDesc1] = useState("");
  const [Link1, setLink1] = useState("");
  const [PrgmCode, setPrgmCode] = useState("");
  const [YOfIntro, setYOfIntro] = useState("");
  const [PrgName1, setPrgName1] = useState("");
  const [Status, setStatus] = useState("");
  const [YOfCBCS, setYOfCBCS] = useState("");
  const [YOfRevision, setYOfRevision] = useState("");
  const [Revision, setRevision] = useState("");
  const [Desc2, setDesc2] = useState("");
  const [Link2, setLink2] = useState("");
  const [CertiName, setCertiName] = useState("");
  const [CourseCode, setCourseCode] = useState("");
  const [YOfOffering, setYOfOffering] = useState("");
  const [NoOfTimes, setNoOfTimes] = useState("");
  const [CourseDuration, setCourseDuration] = useState("");
  const [NoOfStudents, setNoOfStudents] = useState("");
  const [CourseCompletion, setCourseCompletion] = useState("");
  const [Desc3, setDesc3] = useState("");
  const [Link3, setLink3] = useState("");
  const [PPrgmCode, setPPrgmCode] = useState("");
  const [PrgName2, setPrgName2] = useState("");
  const [YYOfOffering, setYYOfOffering] = useState("");
  const [StdCourseStudied, setStdCourseStudied] = useState("");
  const [ExpCourse, setExpCourse] = useState("");
  const [Desc4, setDesc4] = useState("");
  const [Link4, setLink4] = useState("");
  const [PrgName3, setPrgName3] = useState("");
  const [PPPrgmCode, setPPPrgmCode] = useState("");
  const [StdList, setStdList] = useState("");
  const [Desc5, setDesc5] = useState("");
  const [Link5, setLink5] = useState("");

  const [View, setView] = useState({});
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [UG_Criteria01, setUG_Criteria01] = useState([]);
  const [Value, setValue] = useState({});

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Cri1.0");

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          //  Criteria 1_113

          Year: item?.Year,
          "Criteria_1.1.3": item?.Criteria01_113?.criteria,
          "Name of the teacher participated": item?.Criteria01_113?.TeacherPar,
          "Name of the body in which full time teacher participated":
            item?.Criteria01_113?.BodyName,
          "Description (min 500 words)(1.1.3)": item?.Criteria01_113?.Desc1,
          "Upload the relevant document(1.1.3)": item?.Criteria01_113?.Link1,

          // Criteria 1_121

          "Criteria_1.2.1": item?.Criteria01_121?.criteria,
          "Program Code": item?.Criteria01_121?.PrgmCode,
          "Program Name": item?.Criteria01_121?.PrgName1,
          "Year Of Introduction": item?.Criteria01_121?.YOfIntro,
          "Status of implementation of CBCS/Elective course":
            item?.Criteria01_121?.Status,
          "Year of implementation of CBCS/Elective course":
            item?.Criteria01_121?.YOfCBCS,
          "Year of revision (if any)": item?.Criteria01_121?.YOfRevision,

          "If revision has been carried out in the syllabus during the last 5 years, Percentage of content added or replaced":
            item?.Criteria01_121?.Revision,
          "Description (min 500 words)(1.2.1)": item?.Criteria01_121?.Desc2,
          "Upload the relevant document(1.2.1)": item?.Criteria01_121?.Link2,

          // Criteria 1_122
          "Criteria_1.2.2": item?.Criteria01_122?.criteria,
          "Name of Add on /Certificate programs offered":
            item?.Criteria01_122?.CertiName,
          "Course Code (if any)": item?.Criteria01_122?.CourseCode,
          "Year of offering": item?.Criteria01_122?.YOfOffering,
          "No. of times offered during the same year":
            item?.Criteria01_122?.NoOfTimes,
          "Duration of course": item?.Criteria01_122?.CourseDuration,
          "Number of students enrolled in the year":
            item?.Criteria01_122?.NoOfStudents,
          "Number of Students completing the course in the year":
            item?.Criteria01_122?.CourseCompletion,
          "Description (min 500 words)(1.2.2)": item?.Criteria01_122?.Desc3,
          "Upload the relevant document(1.2.2)": item?.Criteria01_122?.Link3,

          // Criteria 1_132
          "Criteria_1.3.2": item?.Criteria01_132?.criteria,
          "Program Name1": item?.Criteria01_132?.PrgName2,
          "Program Code1": item?.Criteria01_132?.PPrgmCode,
          "Year of offering1": item?.Criteria01_132?.YYOfOffering,
          "Name of the student studied course on experiential learning through project work/field work/internship":
            item?.Criteria01_132?.StdCourseStudied,
          "Name of the Course that include experiential learning through project work/field work/internship":
            item?.Criteria01_132?.ExpCourse,
          "Description (min 500 words)(1.3.2)": item?.Criteria01_132?.Desc4,
          "Upload the relevant document(1.3.2)": item?.Criteria01_132?.Link4,

          // Criteria 1_133
          "Criteria_1.3.3": item?.Criteria01_133?.criteria,
          "Program Name2": item?.Criteria01_133?.PrgName3,
          "Program Code2": item?.Criteria01_133?.PPPrgmCode,
          "List of students undertaking project work/field work/internship":
            item?.Criteria01_133?.StdList,
          "Description (min 500 words)(1.3.3)": item?.Criteria01_133?.Desc5,
          "Upload the relevant document(1.3.3)": item?.Criteria01_133?.Link5,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editCriteria01_113 = async (e, _id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc1) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      handleClose();
      const config = {
        url: `/FacultyAdmin/editCriteria01_113/${_id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          TeacherPar: TeacherPar,
          BodyName: BodyName,
          Desc1: Desc1,
          Link1: Link1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setedit1(false);
          getcri01details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria01_121 = async (e, _id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc2) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      handleClose1();
      const config = {
        url: `/FacultyAdmin/editCriteria01_121/${_id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PrgmCode: PrgmCode,
          PrgName1: PrgName1,
          YOfIntro: YOfIntro,
          Status: Status,
          YOfCBCS: YOfCBCS,
          YOfRevision: YOfRevision,
          Revision: Revision,
          Desc2: Desc2,
          Link2: Link2,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setedit2(false);
          getcri01details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria01_122 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc3) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria01_122/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          CertiName: CertiName,
          CourseCode: CourseCode,
          YOfOffering: YOfOffering,
          NoOfTimes: NoOfTimes,
          CourseDuration: CourseDuration,
          NoOfStudents: NoOfStudents,
          CourseCompletion: CourseCompletion,
          Desc3: Desc3,
          Link3: Link3,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setedit3(false);
          getcri01details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria01_132 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc4) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria01_132/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PPrgmCode: PPrgmCode,
          PrgName2: PrgName2,
          YYOfOffering: YYOfOffering,
          StdCourseStudied: StdCourseStudied,
          ExpCourse: ExpCourse,
          Desc4: Desc4,
          Link4: Link4,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setedit4(false);
          getcri01details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria01_133 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Desc5) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria01_133/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PrgName3: PrgName3,
          PPPrgmCode: PPPrgmCode,
          StdList: StdList,
          Desc5: Desc5,
          Link5: Link5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setedit5(false);
          getcri01details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const [AllDetails, setAllDetails] = useState([]);
  const getcri01details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria01"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria01);
        setUG_Criteria01(res.data.ugcriteria01);
        setdata1(res.data.ugcriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria01);
    }
  };

  useEffect(() => {
    getcri01details();
  }, []);
  console.log(Value);

  // =================================================

  console.log("data", UG_Criteria01);
  const [SearchItem, setSearchItem] = useState("");

  // ================================================

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-01
              </span>
            </div>

            <div className=" col-lg-4 lab text-end">
              <Link to="/ugcriteria001">
                <button className="btn btn-success">
                  Add Criteria 1{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Name of the teacher participated</th>
                  <th>
                    Name of the body in which full time teacher participated
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Year Of Introduction</th>
                  <th>Status of implementation of CBCS/Elective course</th>
                  <th>Year of implementation of CBCS/Elective course</th>
                  <th>Year of revision </th>
                  <th>
                    If revision has been carried out in the syllabus during the
                    last 5 years, Percentage of content added or replaced
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of Add on /Certificate programs offered</th>
                  <th>Course Code (if any)</th>
                  <th>Year of offering</th>
                  <th>No. of times offered during the same year</th>
                  <th>Duration of course</th>
                  <th>Number of students enrolled in the year</th>
                  <th>Number of Students completing the course in the year</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Code</th>
                  <th>Program Name</th>
                  <th>Year of offering</th>
                  <th>
                    Name of the student studied course on experiential learning
                    through project work/field work/internship
                  </th>
                  <th>
                    Name of the Course that include experiential learning
                    through project work/field work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    List of students undertaking project work/field
                    work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {UG_Criteria01?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Criteria01_113?.TeacherPar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_113?.BodyName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_113?.Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_121?.PrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_121?.YOfIntro?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.Status?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_121?.YOfCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.YOfRevision?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.Revision?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_121?.Desc2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.CertiName?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_122?.CourseCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.YOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.NoOfTimes?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.CourseDuration?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.NoOfStudents?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.CourseCompletion?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_122?.Desc3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_132?.PPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_132?.PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_132?.YYOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_132?.StdCourseStudied?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_132?.ExpCourse?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_132?.Desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_133?.PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_133?.PPPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria01_133?.StdList?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria01_133?.Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Criteria01_113?.criteria}</td>
                      <td>{item?.Criteria01_113?.TeacherPar}</td>
                      <td>{item?.Criteria01_113?.BodyName}</td>
                      <td>
                        {item?.Criteria01_113?.Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria01_121?.criteria}</td>
                      <td>{item?.Criteria01_121?.PrgName1}</td>
                      <td>{item?.Criteria01_121?.PrgmCode}</td>
                      <td>{item?.Criteria01_121?.YOfIntro}</td>
                      <td>{item?.Criteria01_121?.Status}</td>
                      <td>{item?.Criteria01_121?.YOfCBCS}</td>
                      <td>{item?.Criteria01_121?.YOfRevision}</td>
                      <td>{item?.Criteria01_121?.Revision}</td>
                      <td>
                        {item?.Criteria01_121?.Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria01_122?.criteria}</td>
                      <td>{item?.Criteria01_122?.CertiName}</td>
                      <td>{item?.Criteria01_122?.CourseCode}</td>
                      <td>{item?.Criteria01_122?.YOfOffering}</td>
                      <td>{item?.Criteria01_122?.NoOfTimes}</td>
                      <td>{item?.Criteria01_122?.CourseDuration}</td>
                      <td>{item?.Criteria01_122?.NoOfStudents}</td>
                      <td>{item?.Criteria01_122?.CourseCompletion}</td>
                      <td>
                        {item?.Criteria01_122?.Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria01_132?.criteria}</td>
                      <td>{item?.Criteria01_132?.PPrgmCode}</td>
                      <td>{item?.Criteria01_132?.PrgName2}</td>
                      <td>{item?.Criteria01_132?.YYOfOffering}</td>
                      <td>{item?.Criteria01_132?.StdCourseStudied}</td>
                      <td>{item?.Criteria01_132?.ExpCourse}</td>
                      <td>
                        {item?.Criteria01_132?.Desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria01_133?.criteria}</td>
                      <td>{item?.Criteria01_133?.PrgName3}</td>
                      <td>{item?.Criteria01_133?.PPPrgmCode}</td>
                      <td>{item?.Criteria01_133?.StdList}</td>
                      <td>
                        {item?.Criteria01_133?.Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={(e) => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                UnderGraduate_Criteria-01
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------1.1.3------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher participated
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_113?.TeacherPar}
                        onChange={(e) => {
                          setTeacherPar(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria01_113?.TeacherPar}</>
                  )}
                </div>
                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the body in which full time teacher participated
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_113?.BodyName}
                        onChange={(e) => setBodyName(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_113?.BodyName}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_113?.Desc1}
                        onChange={(e) => setDesc1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria01_113?.Desc1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria01_113?.Link1}
                        accept="image/*"
                        onChange={(e) => setLink1(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria01_113?.Link1}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end  ">
                      {edit1 ? (
                        <>
                          {" "}
                          <Button
                            className="btn btn-success "
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria01_113(e, Value?._id);
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => {
                              setedit1(true);
                            }}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* ------1.2.1-------- */}
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria01_121?.PrgmCode}
                        onChange={(e) => {
                          setPrgmCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_121?.PrgmCode}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit2 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Criteria01_121?.PrgName1}
                        onChange={(e) => setPrgName1(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_121?.PrgName1}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year Of Introduction
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_121?.YOfIntro}
                        onChange={(e) => setYOfIntro(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_121?.YOfIntro}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of revision (if any)
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_121?.YOfRevision}
                        onChange={(e) => setYOfRevision(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_121?.YOfRevision}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Status of implementation of CBCS/elective course
                  </label>

                  {edit2 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Criteria01_121?.Status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option selected>--select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_121?.Status}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of implementation of CBCS/Elective course
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_121?.YOfCBCS}
                        onChange={(e) => setYOfCBCS(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_121?.YOfCBCS}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    If revision has been carried out in the syllabus during the
                    last 5 years, Percentage of content added or replaced
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_121?.Revision}
                        onChange={(e) => setRevision(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_121?.Revision}</>
                    </>
                  )}
                  <div className="col-lg-12">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit2 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Criteria01_121?.Desc2}
                          onChange={(e) => setDesc2(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Criteria01_121?.Desc2}</>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <label className="content1" htmlFor="upload2">
                      {" "}
                      Document
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="name vi_0"
                          type="file"
                          name="Year"
                          id="name"
                          placeholder={Value?.Criteria01_121?.Link2}
                          accept="image/*"
                          onChange={(e) => setLink2(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Criteria01_121?.Link2}</>
                    )}
                  </div>
                  {Value?.approve == "true" ? (
                    <></>
                  ) : (
                    <div className="row mt-3">
                      <div className="text-end">
                        {edit2 ? (
                          <>
                            <button
                              className="btn btn-success"
                              style={{ color: "white" }}
                              onClick={(e) => {
                                editCriteria01_121(e, Value?._id);
                              }}
                            >
                              Submit
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={() => setedit2(true)}
                            >
                              Edit
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria - 1.2.2 & 1.2.3
                </span>
              </div>
              <div className="col-lg-12">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of Add on /Certificate programs offered
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria01_122?.CertiName}
                        onChange={(e) => {
                          setCertiName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_122?.CertiName}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code (if any)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_122?.CourseCode}
                        onChange={(e) => {
                          setCourseCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_122?.CourseCode}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of offering
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_122?.YOfOffering}
                        onChange={(e) => {
                          setYOfOffering(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_122?.YOfOffering}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    No. of times offered during the same year
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_122?.NoOfTimes}
                        onChange={(e) => {
                          setNoOfTimes(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_122?.NoOfTimes}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of course
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_122?.CourseDuration}
                        onChange={(e) => {
                          setCourseDuration(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_122?.CourseDuration}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students enrolled in the year
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_122?.NoOfStudents}
                        onChange={(e) => {
                          setNoOfStudents(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_122?.NoOfStudents}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students completing the course in the year
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_122?.CourseCompletion}
                        onChange={(e) => {
                          setCourseCompletion(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_122?.CourseCompletion}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_122?.Desc3}
                        onChange={(e) => setDesc3(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria01_122?.Desc3}</>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria01_122?.Link3}
                        accept="image/*"
                        onChange={(e) => setLink3(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria01_122?.Link3}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-4">
                    <div className="text-end  ">
                      {edit3 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria01_122(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria - 1.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit4 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Criteria01_132?.PrgName2}
                        onChange={(e) => {
                          setPrgName2(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_132?.PrgName2}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_132?.PPrgmCode}
                        onChange={(e) => {
                          setPPrgmCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_132?.PPrgmCode}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of offering
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_132?.YYOfOffering}
                        onChange={(e) => {
                          setYYOfOffering(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_132?.YYOfOffering}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student studied course on experiential learning
                    through project work/field work/internship
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_132?.StdCourseStudied}
                        onChange={(e) => {
                          setStdCourseStudied(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_132?.StdCourseStudied}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the Course that include experiential learning
                    through project work/field work/internship
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_132?.ExpCourse}
                        onChange={(e) => {
                          setExpCourse(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_132?.ExpCourse}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_132?.Desc4}
                        onChange={(e) => setDesc4(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria01_132?.Desc4}</>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria01_132?.Link4}
                        accept="image/*"
                        onChange={(e) => setLink4(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria01_132?.Link4}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3 ">
                    <div className="text-end  ">
                      {edit4 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria01_132(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "1.1875rem", fontWeight: "bold" }}
                >
                  Sub_Criteria - 1.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit5 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Criteria01_133?.PrgName3}
                        onChange={(e) => {
                          setPrgName3(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_133?.PrgName3}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria01_133?.PPPrgmCode}
                        onChange={(e) => {
                          setPPPrgmCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_133?.PPPrgmCode}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    List of students undertaking project work/field
                    work/internship
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_133?.StdList}
                        onChange={(e) => {
                          setStdList(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.Criteria01_133?.StdList}</>
                    </>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria01_133?.Desc5}
                        onChange={(e) => setDesc5(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria01_133?.Desc5}</>
                  )}
                </div>
                <div className="col-lg-12">
                  <label className="content1" htmlFor="upload5">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria01_133?.Link5}
                        accept="image/*"
                        onChange={(e) => setLink5(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria01_133?.Link5}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end  ">
                      {edit5 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria01_133(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <>
              <Button
                onClick={(e) => {
                  handleClose();
                  setedit1(false);
                  setedit2(false);
                  setedit3(false);
                  setedit4(false);
                  setedit5(false);
                }}
                className="me-2"
              >
                Close
              </Button>
            </>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_113?.Desc1}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_121?.Desc2}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_122?.Desc3}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_132?.Desc4}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria01_133?.Desc5}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_113?.Link1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_121?.Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_122?.Link3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_132?.Link4}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria01/${View?.Criteria01_133?.Link5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default UG_Criteria01;
