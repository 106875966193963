import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Nav from "react-bootstrap/Nav";

import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye, AiOutlineRadarChart } from "react-icons/ai";
import moment from "moment";

const UCriteria4 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [selectedYear, setselectedYear] = useState("");

  const [Value, setValue] = useState({});
  const [View, setView] = useState({});
  const [remark, setremark] = useState("");

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc18 modal======================
  const [show18, setShow18] = useState(false);
  const handleClose18 = () => setShow18(false);
  const handleShow18 = () => setShow18(true);

  // ================Show doc19 modal======================
  const [show19, setShow19] = useState(false);
  const handleClose19 = () => setShow19(false);
  const handleShow19 = () => setShow19(true);

  // ================Show doc20 modal======================
  const [show20, setShow20] = useState(false);
  const handleClose20 = () => setShow20(false);
  const handleShow20 = () => setShow20(true);

  //integrating get method
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [AllDetails, setAllDetails] = useState([]);
  const getunicri04details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getunicri04details"
      );
      if (res.status == 200) {
        setAllDetails(res.data.unicriteria04);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria04);
    }
  };

  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getunicri04details();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaUn_1/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri04details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-04
              </span>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>

        <div className="ad-b  row align-items-center m-0 mt-4 p-0">
          <div className="col-lg-12">
            <Nav defaultActiveKey="/u-criteria4">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  className="p-0"
                  onClick={() => navigate("/U_04_414")}
                >
                  <Button className="criteriabtn  btn-sub1">4.1.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/U_04_422")}
                >
                  <Button className="criteriabtn btn-sub">4.2.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/U_04_431")}
                >
                  <Button className="criteriabtn btn-sub">4.3.1</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>

        <div className="container mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "35000" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  {/* 4.1.4 */}
                  <th>Criteria</th>
                  <th>
                    Budget allocated for infrastructure augmentation (INR in
                    Lakh)
                  </th>
                  <th>
                    Expenditure for infrastructure augmentation (INR in Lakh)
                  </th>
                  <th>Total expenditure excluding Salary (INR in Lakh)</th>
                  <th>
                    Expenditure on maintenance of academic facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </th>
                  <th>
                    Expenditure on maintenance of physical facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 4.2.2 */}
                  <th>Criteria</th>
                  <th>Library Resources</th>
                  <th>If yes, details of memberships/subscriptions</th>
                  <th>
                    Expenditure on subscription to e-journals, e-books (INR in
                    lakhs)
                  </th>
                  <th>Total Library Expenditure</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  {/* 4.3.1 */}
                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>
                    Room number or Name of Classrooms and Seminar halls with
                    ICT-enabled facilities
                  </th>
                  <th>Type of ICT facility</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toLowerCase())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    (item1?.approve === "false" ||
                      item1?.approve === "Pending") &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria04_431?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_431?.Uni_Room?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_431?.Uni_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_431?.Uni_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Expenditure?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Expend1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Expend2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_414?.Uni_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_Library?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_IfYes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_Expend3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria04_422?.Uni_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Uni_Year}</td>

                      {/* 4.1.4 */}
                      <td>{item?.Uni_Criteria04_414?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Budget}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Expenditure}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Total}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Expend1}</td>
                      <td>{item?.Uni_Criteria04_414?.Uni_Expend2}</td>
                      <td>
                        {item?.Uni_Criteria04_414?.Uni_Desc2?.slice(0, 200)}
                        <br />
                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow19();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 4.2.2 */}
                      <td>{item?.Uni_Criteria04_422?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria04_422?.Uni_Library}</td>
                      <td>{item?.Uni_Criteria04_422?.Uni_IfYes}</td>
                      <td>{item?.Uni_Criteria04_422?.Uni_Expend3}</td>
                      <td>{item?.Uni_Criteria04_422?.Uni_Total1}</td>
                      <td>
                        {item?.Uni_Criteria04_422?.Uni_Desc3.slice(0, 200)}
                        <br />
                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow20();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 4.3.1 */}
                      <td>{item?.Uni_Criteria04_431?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria04_431?.Uni_Room}</td>
                      <td>{item?.Uni_Criteria04_431?.Uni_Type}</td>
                      <td>
                        {item?.Uni_Criteria04_431?.Uni_Desc1.slice(0, 200)}
                        <br />
                        <Link
                          to=""
                          onClick={() => {
                            setView(item);
                            handleShow18();
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 4 </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* ------4.1.4 & 4.4.1-------- */}

            <div className="container">
              <div className="row-fluid mt-3">
                <div
                  className="row border"
                  style={{
                    padding: "30px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <h6>Criteria04--(4.1.4 & 4.4.1)</h6>

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Budget allocated for infrastructure aunimentation (INR
                        in Lakh)
                      </label>

                      <>{Value?.Uni_Criteria04_414?.Uni_Budget}</>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Expenditure for infrastructure aunimentation (INR in
                        Lakh)
                      </label>
                      <br />

                      <>{Value?.Uni_Criteria04_414?.Uni_Expenditure}</>
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total expenditure excluding Salary (INR in Lakh)
                      </label>

                      <>{Value?.Uni_Criteria04_414?.Uni_Total}</>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Expenditure on maintenance of academic facilities
                      (excluding salary for human resources) (INR in Lakh)
                    </label>

                    <>{Value?.Uni_Criteria04_414?.Uni_Expend1}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Expenditure on maintenance of physical facilities
                      (excluding salary for human resources) (INR in Lakh)
                    </label>

                    <>{Value?.Uni_Criteria04_414?.Uni_Expend2}</>
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 characters)
                    </label>

                    <>{Value?.Uni_Criteria04_414?.Uni_Desc2}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    <>{Value?.Uni_Criteria04_414?.Uni_Link2}</>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row-fluid mt-3">
                <div
                  className="row border "
                  style={{
                    padding: "50px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <div className="row">
                    <h6>Criteria04--(4.2.2)</h6>
                    <div className="row">
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label className="content1" htmlFor="name">
                            {" "}
                            Library resources
                          </label>

                          <>{Value?.Uni_Criteria04_422?.Uni_Library}</>
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="content1" htmlFor="name">
                            {" "}
                            If yes, details of memberships/subscriptions
                          </label>

                          <>{Value?.Uni_Criteria04_422?.Uni_IfYes}</>
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="content1" htmlFor="name">
                            {" "}
                            Expenditure on subscription for the recourses (INR
                            in Lakhs)
                          </label>

                          <>{Value?.Uni_Criteria04_422?.Uni_Expend3}</>
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="content1" htmlFor="name">
                            {" "}
                            Total Library Expenditure
                          </label>

                          <>{Value?.Uni_Criteria04_422?.Uni_Total1}</>
                        </div>
                        <div className="col-md-12 mt-3 ">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Description(min 500 characters)
                          </label>

                          <>{Value?.Uni_Criteria04_422?.Uni_Desc3}</>
                        </div>
                        <div className="col-md-12 mt-3 ">
                          <label className="content1" htmlFor="email">
                            {" "}
                            Upload the relevant document
                          </label>
                          <br />

                          <>{Value?.Uni_Criteria04_422?.Uni_Link3}</>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row-fluid mt-3">
                <div
                  className="row border"
                  style={{
                    padding: "30px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <h6>Criteria04--(4.3.1)</h6>
                  <div className="row">
                    <div className="col-md-8 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Room number or Name of Classrooms and Seminar halls with
                        ICT-enabled facilities
                      </label>

                      <>{Value?.Uni_Criteria04_431?.Uni_Room}</>
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Type of ICT facility
                      </label>

                      <>{Value?.Uni_Criteria04_431?.Uni_Type}</>
                    </div>

                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 characters)
                      </label>

                      <>{Value?.Uni_Criteria04_431?.Uni_Desc1}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1">
                        {" "}
                        Upload the relevant document
                      </label>

                      <>{Value?.Uni_Criteria04_431?.Uni_Link1}</>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve ? (
              <></>
            ) : (
              <>
                <Button
                  variant="warning"
                  onClick={() => {
                    handleClose();
                  }}
                  className="me-2"
                >
                  Close
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea className="form-control" style={{ height: "200px" }} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary">Submit </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria4/${View?.Uni_Criteria04_414?.Uni_Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria4/${View?.Uni_Criteria04_422?.Uni_Link3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Final Report</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria4/${View?.Uni_Criteria04_431?.Uni_Link1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={show19} onHide={handleClose19}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria04_414?.Uni_Desc2}
              </p>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show20} onHide={handleClose20}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria04_422?.Uni_Desc3}
              </p>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show18} onHide={handleClose18}>
          <Modal.Header closeButton>
            <Modal.Title>Description </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p style={{ textAlign: "justify" }}>
                {View?.Uni_Criteria04_431?.Uni_Desc1}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose18}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UCriteria4;
