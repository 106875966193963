import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";

const UG_06_632 = () => {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [selectedYear, setselectedYear] = useState("");
  const [content, setContent] = useState([]);
  const [View, setView] = useState([]);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ================  date filter  ================

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  // ================date filter=====================

  const navigate = useNavigate();
  const [Value, setValue] = useState({});
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("UG_criteria(6.3.2)");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.UGYear) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          //  criteria 06 6.3.2
          Sub_criteria2: item?.UgCriteria06_632?.criteria,
          "Name of the teacher": item?.UgCriteria06_632?.UGNot,
          "Amount of support received (in INR)": item?.UgCriteria06_632?.UGAsr,
          "Name of conference/ workshop attended for which financial support provided":
            item?.UgCriteria06_632?.NOC,
          "Name of the professional body for which membership fee is provided":
            item?.UgCriteria06_632?.Nop,
          "Description 2": item?.UgCriteria06_632?.ugdesc2,
          "Document 5": item?.UgCriteria06_632?.doc5,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================
  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        pricipalDetails?._id === item1?.addedByPri &&
        item1?.approve === "true" &&
        selectedYear === item1?.UGYear
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 6 });
  };

  //  ===========================================================================================

  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/ugadmin/Ugcri06_632"
      );
      if (res.status === 200) {
        setContent(res.data.getUG_C632);
        setdata1(res.data.getUG_C632);
        setfilterPdf(res.data.getUG_C632);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);
  console.log("content", content);

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);

  console.log("principal", pricipalDetails?._id);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-06-632
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>

            <div className=" col-lg-2 lab text-end">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = content?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.UGYear?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id === item1?.addedByPri &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.UGYear &&
                      (SearchItem === "" ||
                        item1?.UgCriteria06_632?.criteria?.includes(
                          SearchItem
                        ) ||
                        item1?.UGYear?.includes(SearchItem) ||
                        item1?.UgCriteria06_632?.UGNot?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UgCriteria06_632?.UGAsr?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UgCriteria06_632?.NOC?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UgCriteria06_632?.Nop?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UgCriteria06_632?.ugdesc2
                          ?.toLowerCase()
                          ?.includes(SearchItem?.toLowerCase()) ||
                        item1?.UgCriteria06_632?.doc5
                          ?.toLowerCase()
                          ?.includes(SearchItem?.toLowerCase())) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });

                  if (arr1?.length > 0) {
                    navigate("/PDFprintUG6_632", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4 align-items-center">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/UG_06_623">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/UG_06_623")}
                >
                  <Button className="criteriabtn btn-sub1">6.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UG_06_632")}
                >
                  <Button
                    variant="warning"
                    style={{ backgroundColor: "#ffc107" }}
                    className="criteriabtn-0 btn-sub"
                  >
                    6.3.2
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UG_06_633")}
                >
                  {" "}
                  <Button className="criteriabtn btn-sub">6.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-4"
                  onClick={() => navigate("/UG_06_634")}
                >
                  {" "}
                  <Button className="criteriabtn btn-sub">6.3.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UG_06_642")}
                >
                  <Button className="criteriabtn btn-sub">6.4.2</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UG_06_653")}
                >
                  <Button className="criteriabtn btn-sub">6.5.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  {/*  criteria 06 6.3.2  */}
                  <th>S/NO</th>
                  <th>criteria</th>
                  <th>Name of the teacher</th>
                  <th>Amount of support received (in INR)</th>
                  <th>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </th>
                  <th>
                    Name of the professional body for which membership fee is
                    provided
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.UGYear?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id == item1?.addedByPri &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.UGYear &&
                      (SearchItem === "" ||
                        item1?.UgCriteria06_632?.criteria?.includes(
                          SearchItem
                        ) ||
                        item1?.UGYear?.includes(SearchItem) ||
                        item1?.UgCriteria06_632?.UGNot?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UgCriteria06_632?.UGAsr?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UgCriteria06_632?.NOC?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UgCriteria06_632?.Nop?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UgCriteria06_632?.ugdesc2
                          ?.toLowerCase()
                          ?.includes(SearchItem?.toLowerCase()) ||
                        item1?.UgCriteria06_632?.doc5
                          ?.toLowerCase()
                          ?.includes(SearchItem?.toLowerCase())) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?.UgCriteria06_632?.criteria}</td>
                        <td>{item?.UgCriteria06_632?.UGNot}</td>
                        <td>{item?.UgCriteria06_632?.UGAsr}</td>
                        <td>{item?.UgCriteria06_632?.NOC}</td>
                        <td>{item?.UgCriteria06_632?.Nop}</td>
                        <td>
                          {item?.UgCriteria06_632?.ugdesc2?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow5();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            fontSize={20}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria06/${View?.UgCriteria06_632?.doc5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.UgCriteria06_632?.ugdesc2}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UG_06_632;
