import React from "react";

const UG6632aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Name of the teacher</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_632?.UGNot}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Amount of support received (in INR)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_632?.UGNot}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Name of conference/ workshop attended for which financial support
            provided
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_632?.UGNot}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Name of the professional body for which membership fee is provided
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_632?.UGNot}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.UgCriteria06_632?.ugdesc2}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {" "}
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.UgCriteria06_632?.doc5}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG6632aqar;
