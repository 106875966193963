import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const PG_Criteria07 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);

  const [PG_Budget, setPG_Budget] = useState("");
  const [PG_Lighting, setPG_Lighting] = useState("");
  const [PG_PhyFac, setPG_PhyFac] = useState("");
  const [PG_Prolift, setPG_Prolift] = useState("");
  const [PG_Ramp, setPG_Ramp] = useState("");
  const [PG_Braille, setPG_Braille] = useState("");
  const [PG_RestRoom, setPG_RestRoom] = useState("");
  const [PG_Scribes, setPG_Scribes] = useState("");
  const [PG_SpecialSkills, setPG_SpecialSkills] = useState("");
  const [PG_AnyOther, setPG_AnyOther] = useState("");
  const [PG_Annual, setPG_Annual] = useState("");
  const [PG_NoOfIniti1, setPG_NoOfIniti1] = useState("");
  const [PG_NoOfIniti2, setPG_NoOfIniti2] = useState("");
  const [PG_Date1, setPG_Date1] = useState("");
  const [PG_DateDuration, setPG_DateDuration] = useState("");
  const [PG_Date2, setPG_Date2] = useState("");
  const [PG_Power, setPG_Power] = useState("");
  const [PG_TPower, setPG_TPower] = useState("");
  const [PG_ReEnergyS, setPG_ReEnergyS] = useState("");
  const [PG_ReEnergyG, setPG_ReEnergyG] = useState("");
  const [PG_EnergySup, setPG_EnergySup] = useState("");
  const [PG_PercentageLED, setPG_PercentageLED] = useState("");
  const [PG_PercentageLight, setPG_PercentageLight] = useState("");
  const [PG_NoOfParticipants, setPG_NoOfParticipants] = useState("");
  const [PG_NoOfActivities, setPG_NoOfActivities] = useState("");
  const [PG_TitlePro, setPG_TitlePro] = useState("");
  const [PG_NoOfParticipants1, setPG_NoOfParticipants1] = useState("");
  const [PG_Male, setPG_Male] = useState("");
  const [PG_Female, setPG_Female] = useState("");
  const [PG_Yea, setPG_Yea] = useState("");
  const [PG_Total, setPG_Total] = useState("");
  const [PG_PowRe, setPG_PowRe] = useState("");
  const [PG_InitiName, setPG_InitiName] = useState("");
  const [PG_DurInitiative, setPG_DurInitiative] = useState("");
  const [PG_Desc29, setPG_Desc29] = useState("");
  const [PG_Link29, setPG_Link29] = useState("");
  const [PG_Link30, setPG_Link30] = useState("");
  const [PG_Link31, setPG_Link31] = useState("");

  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [PG_Criteria07, setPG_Criteria07] = useState([]);
  const [Value, setValue] = useState({});

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Cri7.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>Physical Facilities</td>
                <td>${item?.PG_Criteria07_007?.PG_PhyFac}</td>
              </tr>
              <tr>
                <td>Provision for Lift</td>
                <td>${item?.PG_Criteria07_007?.PG_Prolift}</td>
              </tr>
              <tr>
                <td>Ramp/ Rails</td>
                <td>${item?.PG_Criteria07_007?.PG_Ramp}</td>
              </tr>
              <tr>
                <td>Braille Software/Facilities</td>
                <td>${item?.PG_Criteria07_007?.PG_Braille}</td>
              </tr>
              <tr>
                <td>Rest Rooms</td>
                <td>${item?.PG_Criteria07_007?.PG_RestRoom}</td>
              </tr>
              <tr>
                <td>Scribes for Examination</td>
                <td>${item?.PG_Criteria07_007?.PG_Scribes}</td>
              </tr><tr>
              <td>Special skill Development for Differently Abled Students</td>
              <td>${item?.PG_Criteria07_007?.PG_SpecialSkills}</td>
            </tr>
            <tr>
                <td>Any other Similar Facility</td>
                <td>${item?.PG_Criteria07_007?.PG_AnyOther}</td>
              </tr>
              <tr>
                <td>Annual expenditure excluding salary component of the institution</td>
                <td>${item?.PG_Criteria07_007?.PG_Annual}</td>
              </tr>
              <tr>
                <td>Number of initiatives to address locational Advantages and Disadvantages</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfIniti1}</td>
              </tr>
              <tr>
                <td>Number of initiatives taken to Engage with and contribute to Local Community</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfIniti2}</td>
              </tr>
              <tr>
                <td>Date and Duration of the Initiative Name of the Initiative Issues Addressed</td>
                <td>${item?.PG_Criteria07_007?.PG_DateDuration}</td>
              </tr>
              <tr>
                <td>Power Requirement met by Renewable Energy Sources</td>
                <td>${item?.PG_Criteria07_007?.PG_Power}</td>
              </tr>
              <tr>
                <td>Total Power requirements</td>
                <td>${item?.PG_Criteria07_007?.PG_TPower}</td>
              </tr>
              <tr>
                <td>Renewable Energy Source</td>
                <td>${item?.PG_Criteria07_007?.PG_ReEnergyS}</td>
              </tr>
              <tr>
                <td>Renewable Energy Generated and Used</td>
                <td>${item?.PG_Criteria07_007?.PG_ReEnergyG}</td>
              </tr>
              <tr>
              <td>Energy Supplied to the Grid</td>
              <td>${item?.PG_Criteria07_007?.PG_EnergySup}</td>
            </tr>
            <tr>
                <td>Percentage Lighting through LED bulbs</td>
                <td>${item?.PG_Criteria07_007?.PG_PercentageLED}</td>
              </tr>
              <tr>
                <td>Percentage Lighting through other sources</td>
                <td>${item?.PG_Criteria07_007?.PG_PercentageLight}</td>
              </tr>
              <tr>
                <td>Number of Participating Students and Staff</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfParticipants}</td>
              </tr>
              <tr>
                <td>No. of Activities</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfActivities}</td>
              </tr><tr>
              <td>TTitle of the Program/Activity</td>
              <td>${item?.PG_Criteria07_007?.PG_TitlePro}</td>
            </tr>
            <tr>
                <td>Number of Participants</td>
                <td>${item?.PG_Criteria07_007?.PG_NoOfParticipants1}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
          <table>
              <tr>
                <td>From</td>
                <td>${moment(item?.PG_Criteria07_007?.PG_Date1).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              <tr>
                <td>To</td>
                <td>${moment(item?.PG_Criteria07_007?.PG_Date2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              </table>
          `;
        let tableMarkup3 = `
          <table>
              <tr>
                <td>MALE</td>
                <td>${item?.PG_Criteria07_007?.PG_Male}</td>
              </tr>
              <tr>
                <td>FEMALE</td>
                <td>${item?.PG_Criteria07_007?.PG_Female}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>${item?.PG_Criteria07_007?.PG_Yea}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${item?.PG_Criteria07_007?.PG_Total}</td>
              </tr>
              </table>
          `;
        let obj = {
          Year: item?.PG_Year,
          "Total Lighting requirements": item?.PG_Criteria07_007?.PG_Lighting,
          "Budget allocated for green initiatives":
            item?.PG_Criteria07_007?.PG_Budget,
          "Status of implementation of CBCS / Elective Course System (ECS)":
            tableMarkup1,
          "Date and Duration": tableMarkup2,
          "Number of participants by Gender": tableMarkup3,
          "Power Requirement Renewable": item?.PG_Criteria07_007?.PG_PowRe,
          "Initiative names": item?.PG_Criteria07_007?.PG_InitiName,
          "Duration of the Initiative":
            item?.PG_Criteria07_007?.PG_DurInitiative,
          Description: item?.PG_Criteria07_007?.PG_Desc29,
          "Relevent Document": item?.PG_Criteria07_007?.PG_Link29,
          "Relevent Document": item?.PG_Criteria07_007?.PG_Link30,
          "Relevent Document": item?.PG_Criteria07_007?.PG_Link31,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editPG_Criteria07_007 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc29;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc29) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria07_007/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Budget: PG_Budget,
          PG_Lighting: PG_Lighting,
          PG_PhyFac: PG_PhyFac,
          PG_Prolift: PG_Prolift,
          PG_Ramp: PG_Ramp,
          PG_Braille: PG_Braille,
          PG_RestRoom: PG_RestRoom,
          PG_Scribes: PG_Scribes,
          PG_SpecialSkills: PG_SpecialSkills,
          PG_AnyOther: PG_AnyOther,
          PG_Annual: PG_Annual,
          PG_NoOfIniti1: PG_NoOfIniti1,
          PG_NoOfIniti2: PG_NoOfIniti2,
          PG_Date1: PG_Date1,
          PG_DateDuration: PG_DateDuration,
          PG_Date2: PG_Date2,
          PG_Power: PG_Power,
          PG_TPower: PG_TPower,
          PG_ReEnergyS: PG_ReEnergyS,
          PG_ReEnergyG: PG_ReEnergyG,
          PG_EnergySup: PG_EnergySup,
          PG_PercentageLED: PG_PercentageLED,
          PG_PercentageLight: PG_PercentageLight,
          PG_NoOfParticipants: PG_NoOfParticipants,
          PG_NoOfActivities: PG_NoOfActivities,
          PG_TitlePro: PG_TitlePro,
          PG_NoOfParticipants1: PG_NoOfParticipants1,
          PG_Male: PG_Male,
          PG_Female: PG_Female,
          PG_Yea: PG_Yea,
          PG_Total: PG_Total,
          PG_PowRe: PG_PowRe,
          PG_InitiName: PG_InitiName,
          PG_DurInitiative: PG_DurInitiative,
          PG_Desc29: PG_Desc29,
          PG_Link29: PG_Link29,
          PG_Link30: PG_Link30,
          PG_Link31: PG_Link31,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPG_cri07details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getPG_cri07details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getPG_cri07details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria07);
        setPG_Criteria07(res.data.pgcriteria07);
        setdata1(res.data.pgcriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria07);
    }
  };

  useEffect(() => {
    getPG_cri07details();
  }, []);
  console.log(Value);

  // =================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                PostGraduate_Criteria-07
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/pgcriteria007">
                <button className="btn btn-success">
                  Add Criteria 7
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Total Lighting requirements</th>
                  <th>Budget allocated for green initiatives </th>
                  <th>Physical Facilities </th>
                  <th>Provision for Lift </th>
                  <th>Ramp/ Rails </th>
                  <th>Braille Software/Facilities </th>
                  <th>Rest Rooms </th>
                  <th>Scribes for Examination</th>
                  <th>
                    Special skill Development for Differently Abled Students
                  </th>
                  <th>Any other Similar Facility </th>
                  <th>
                    Annual expenditure excluding salary component of the
                    institution
                  </th>
                  <th>
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </th>
                  <th>
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </th>
                  <th>
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </th>
                  <th>Power Requirement met by Renewable Energy Sources</th>
                  <th>Total Power requirements</th>
                  <th>Renewable Energy Source </th>
                  <th>Renewable Energy Generated and Used </th>
                  <th>Energy Supplied to the Grid </th>
                  <th>Percentage Lighting through LED bulbs </th>
                  <th>Percentage Lighting through other sources</th>
                  <th>Number of Participating Students and Staff </th>
                  <th>No. of Activities </th>
                  <th>Title of the Program/Activity </th>
                  <th>Number of Participants </th>
                  <th>From </th>
                  <th>To </th>
                  <th>Male</th>
                  <th>Female</th>
                  <th>Year</th>
                  <th>Total</th>
                  <th>Power Requirement Renewable</th>
                  <th>Initiative names </th>
                  <th>Duration_of_the_Initiative</th>
                  <th>Description</th>
                  <th>Document 1</th>
                  <th>Document 2</th>
                  <th>Document 3 </th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {PG_Criteria07?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.PG_Year?.includes(SearchItem) ||
                      item1?.PG_Criteria07_007?.PG_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Lighting?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_PhyFac?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Prolift?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Ramp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Braille?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_RestRoom?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Scribes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_SpecialSkills?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_AnyOther?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Annual?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfIniti1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfIniti2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_DateDuration?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Power?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_TPower?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_ReEnergyS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_ReEnergyG?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_EnergySup?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_PercentageLED?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_PercentageLight?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfParticipants?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfActivities?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_TitlePro?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_NoOfParticipants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.PG_Criteria07_007?.PG_Date1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.PG_Criteria07_007?.PG_Date2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.PG_Criteria07_007?.PG_Male?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Female?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Yea?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_PowRe?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria07_007?.PG_InitiName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_DurInitiative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria07_007?.PG_Desc29?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.PG_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.PG_Criteria07_007?.PG_criteria}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Lighting}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Budget}</td>
                      <td>{item?.PG_Criteria07_007?.PG_PhyFac}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Prolift}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Ramp}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Braille}</td>
                      <td>{item?.PG_Criteria07_007?.PG_RestRoom}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Scribes}</td>
                      <td>{item?.PG_Criteria07_007?.PG_SpecialSkills}</td>
                      <td>{item?.PG_Criteria07_007?.PG_AnyOther}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Annual}</td>
                      <td>{item?.PG_Criteria07_007?.PG_NoOfIniti1}</td>
                      <td>{item?.PG_Criteria07_007?.PG_NoOfIniti2}</td>
                      <td>{item?.PG_Criteria07_007?.PG_DateDuration}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Power}</td>
                      <td>{item?.PG_Criteria07_007?.PG_TPower}</td>
                      <td>{item?.PG_Criteria07_007?.PG_ReEnergyS}</td>
                      <td>{item?.PG_Criteria07_007?.PG_ReEnergyG}</td>
                      <td>{item?.PG_Criteria07_007?.PG_EnergySup}</td>
                      <td>{item?.PG_Criteria07_007?.PG_PercentageLED}</td>
                      <td>{item?.PG_Criteria07_007?.PG_PercentageLight}</td>
                      <td>{item?.PG_Criteria07_007?.PG_NoOfParticipants}</td>
                      <td>{item?.PG_Criteria07_007?.PG_NoOfActivities}</td>
                      <td>{item?.PG_Criteria07_007?.PG_TitlePro}</td>
                      <td>{item?.PG_Criteria07_007?.PG_NoOfParticipants1}</td>
                      <td>
                        {moment(item?.PG_Criteria07_007?.PG_Date1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.PG_Criteria07_007?.PG_Date2).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.PG_Criteria07_007?.PG_Male}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Female}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Yea}</td>
                      <td>{item?.PG_Criteria07_007?.PG_Total}</td>
                      <td>{item?.PG_Criteria07_007?.PG_PowRe}</td>
                      <td>{item?.PG_Criteria07_007?.PG_InitiName}</td>
                      <td>{item?.PG_Criteria07_007?.PG_DurInitiative}</td>
                      <td>
                        {item?.PG_Criteria07_007?.PG_Desc29?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={(e) => {
            handleClose();
            setedit1(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 7 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  PostGraduate_Criteria-07
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Lighting requirements
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_Lighting}
                        onChange={(e) => {
                          setPG_Lighting(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Lighting}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Budget allocated for green initiatives
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_Budget}
                        onChange={(e) => {
                          setPG_Budget(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Budget}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Status of implementation of CBCS / Elective Course System
                  (ECS)
                </h6>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Physical Facilities
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.PG_Criteria07_007?.PG_PhyFac}
                        onChange={(e) => {
                          setPG_PhyFac(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_PhyFac}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Provision for Lift
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.PG_Criteria07_007?.PG_Prolift}
                        onChange={(e) => {
                          setPG_Prolift(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Prolift}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Ramp/ Rails
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.PG_Criteria07_007?.PG_Ramp}
                        onChange={(e) => {
                          setPG_Ramp(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Ramp}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Braille Software/Facilities
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.PG_Criteria07_007?.PG_Braille}
                        onChange={(e) => {
                          setPG_Braille(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Braille}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Rest Rooms
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.PG_Criteria07_007?.PG_RestRoom}
                        onChange={(e) => {
                          setPG_RestRoom(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_RestRoom}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Scribes for Examination
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.PG_Criteria07_007?.PG_Scribes}
                        onChange={(e) => {
                          setPG_Scribes(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Scribes}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Special skill Development for Differently Abled Students
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "435px" }}
                        placeholder={Value?.PG_Criteria07_007?.PG_SpecialSkills}
                        onChange={(e) => {
                          setPG_SpecialSkills(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_SpecialSkills}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Any other Similar Facility
                    </label>
                    <br />
                    {edit1 ? (
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.PG_Criteria07_007?.PG_AnyOther}
                        onChange={(e) => {
                          setPG_AnyOther(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_AnyOther}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Annual expenditure excluding salary component of the
                    institution
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.PG_Criteria07_007?.PG_Annual}
                      onChange={(e) => {
                        setPG_Annual(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_Annual}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.PG_Criteria07_007?.PG_NoOfIniti1}
                      onChange={(e) => {
                        setPG_NoOfIniti1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_NoOfIniti1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.PG_Criteria07_007?.PG_NoOfIniti2}
                      onChange={(e) => {
                        setPG_NoOfIniti2(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_NoOfIniti2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.PG_Criteria07_007?.PG_DateDuration}
                      onChange={(e) => {
                        setPG_DateDuration(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_DateDuration}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement met by Renewable Energy Sources
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.PG_Criteria07_007?.PG_Power}
                      onChange={(e) => {
                        setPG_Power(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_Power}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total Power requirements
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_TPower}
                        onChange={(e) => {
                          setPG_TPower(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_TPower}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Source
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_ReEnergyS}
                        onChange={(e) => {
                          setPG_ReEnergyS(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_ReEnergyS}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Renewable Energy Generated and Used
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_ReEnergyG}
                        onChange={(e) => {
                          setPG_ReEnergyG(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_ReEnergyG}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Energy Supplied to the Grid
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_EnergySup}
                        onChange={(e) => {
                          setPG_EnergySup(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_EnergySup}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Percentage Lighting through other sources
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.PG_Criteria07_007?.PG_PercentageLight
                        }
                        onChange={(e) => {
                          setPG_PercentageLight(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_PercentageLight}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participating Students and Staff
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.PG_Criteria07_007?.PG_NoOfParticipants
                        }
                        onChange={(e) => {
                          setPG_NoOfParticipants(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_NoOfParticipants}</>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Percentage Lighting through LED bulbs
                      </label>
                      <br />
                      {edit1 ? (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={
                            Value?.PG_Criteria07_007?.PG_PercentageLED
                          }
                          onChange={(e) => {
                            setPG_PercentageLED(e.target.value);
                          }}
                        />
                      ) : (
                        <>{Value?.PG_Criteria07_007?.PG_PercentageLED}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        No. of Activities
                      </label>
                      <br />
                      {edit1 ? (
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={
                            Value?.PG_Criteria07_007?.PG_NoOfActivities
                          }
                          onChange={(e) => {
                            setPG_NoOfActivities(e.target.value);
                          }}
                        />
                      ) : (
                        <>{Value?.PG_Criteria07_007?.PG_NoOfActivities}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Title of the Program/Activity
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_TitlePro}
                        onChange={(e) => {
                          setPG_TitlePro(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_TitlePro}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Participants
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.PG_Criteria07_007?.PG_NoOfParticipants1
                        }
                        onChange={(e) => {
                          setPG_NoOfParticipants1(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_NoOfParticipants1}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Date and Duration
                </h6>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      From
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_Date1}
                        onChange={(e) => {
                          setPG_Date1(e.target.value);
                        }}
                      />
                    ) : (
                      <>
                        {moment(Value?.PG_Criteria07_007?.PG_Date1).format(
                          "DD_MM_YYYY"
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      To
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_Date2}
                        onChange={(e) => {
                          setPG_Date2(e.target.value);
                        }}
                      />
                    ) : (
                      <>
                        {moment(Value?.PG_Criteria07_007?.PG_Date2).format(
                          "DD-MM-YYYY"
                        )}
                      </>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of participants by Gender
                </h6>
                <div className="row">
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Male
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_Male}
                        onChange={(e) => {
                          setPG_Male(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Male}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Female
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_Female}
                        onChange={(e) => {
                          setPG_Female(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Female}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_Yea}
                        onChange={(e) => {
                          setPG_Yea(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Yea}</>
                    )}
                  </div>
                  <div className="col-md-3 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_Total}
                        onChange={(e) => {
                          setPG_Total(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_Total}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Power Requirement Renewable
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_PowRe}
                        onChange={(e) => {
                          setPG_PowRe(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_PowRe}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Initiative names
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria07_007?.PG_InitiName}
                        onChange={(e) => {
                          setPG_InitiName(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Value?.PG_Criteria07_007?.PG_InitiName}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration_of_the_Initiative
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.PG_Criteria07_007?.PG_DurInitiative}
                      onChange={(e) => {
                        setPG_DurInitiative(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_DurInitiative}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description (min 500 words)
                  </label>
                  <br />
                  {edit1 ? (
                    <textarea
                      className="vi_0"
                      rows={3}
                      type="text"
                      name="email"
                      id="name"
                      placeholder={Value?.PG_Criteria07_007?.PG_Desc29}
                      onChange={(e) => {
                        setPG_Desc29(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_Desc29}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document 1
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="Vi_0"
                      type="file"
                      name="Year"
                      id="upload1"
                      accept="image/*"
                      placeholder={Value?.PG_Criteria07_007?.PG_Link29}
                      onChange={(e) => {
                        setPG_Link29(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_Link29}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Document 2
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="Vi_0"
                      type="file"
                      name="Year"
                      id="upload2"
                      accept="image/*"
                      placeholder={Value?.PG_Criteria07_007?.PG_Link30}
                      onChange={(e) => {
                        setPG_Link30(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_Link30}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Document 3
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="Vi_0"
                      type="file"
                      name="Year"
                      id="upload3"
                      accept="image/*"
                      placeholder={Value?.PG_Criteria07_007?.PG_Link31}
                      onChange={(e) => {
                        setPG_Link31(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <>{Value?.PG_Criteria07_007?.PG_Link31}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria07_007(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => {
                              setedit1(true);
                            }}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={(e) => {
                handleClose();
                setedit1(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria07_007?.PG_Desc29}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria07/${View?.PG_Criteria07_007?.PG_Link29}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria07/${View?.PG_Criteria07_007?.PG_Link30}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria07/${View?.PG_Criteria07_007?.PG_Link31}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default PG_Criteria07;
