import React, { useEffect, useState } from "react";
import "./Styles/Events.css";
import {
  CiClock1,
  CiCalendar,
  CiLocationOn,
  CiHospital1,
} from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
const Events = () => {
  let navigate = useNavigate();
  const [SearchItem, setSearchItem] = useState("");

  const routeChange = (val) => {
    let path = `/eventspage`;
    navigate(path, { state: val });
  };
  // =================== calling api for getting the Event content======================
  const [eventDetails, seteventDetails] = useState([]);
  const getEventContent = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getEvents");
      if (res.status === 200) {
        seteventDetails(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getEventContent();
  }, []);

  return (
    <div className="events">
      <div
        className="ab-0"
        style={{
          backgroundImage: "url('../Image/about-us.jpg')",
          height: "250px",
          position: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="edg container">
          <div className="edgt-has-inline-style">Events</div>
          <div class="edgt-breadcrumbs-info">
            <div
              itemProp="breadcrumb"
              classname="edgt-breadcrumbs "
              style={{ color: "#e1e0e0" }}
            >
              <Link
                itemProp="url"
                to="/"
                style={{ color: "#e1e0e0", textDecoration: "none" }}
              >
                Home
              </Link>
              <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
              <span classname="edgt-current">Events</span>
            </div>
          </div>
        </div>
      </div>

      <div className=" fs-1 mt-5 mb-5 text-center">Upcoming Events</div>

      <form className=" container">
        <div className="row align-items-center justify-content-between mb-5 ">
          <div class="mb-3 col-lg-6 col-md-12 col-sm-12 col-12 ">
            <label for="exampleFormControlInput1" class="form-label">
              Search
            </label>
            <input
              type="search"
              class="form-c border"
              id="exampleFormControlInput1"
              placeholder="Keyword"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
        </div>
      </form>

      <div className="container border p-3 mb-5 fs-2">Events</div>
      {eventDetails
        ?.filter((item1) => {
          if (
            SearchItem === "" ||
            item1?.eventName?.toLowerCase()?.includes(SearchItem?.toLowerCase())
          ) {
            return true;
          }
          return false;
        })
        ?.map((item) => {
          return (
            <div className="container">
              <div className="row  mb-4 ">
                <div
                  className="col-lg-6 gallery-course "
                  onClick={() => routeChange(item)}
                >
                  <div className="p-2">
                    <figure>
                      <img
                        className="img-fluid"
                        src={`https://brightnaac.co.in/Events/${item?.eventImg}`}
                        alt="event10"
                        style={{ width: "100%" }}
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-6 gallery-course" onClick={routeChange}>
                  <div className="">
                    <div className="h3 text-decoration-none">
                      <Link className="text-decoration-none event-head">
                        {item?.eventName}
                      </Link>
                    </div>

                    <div className="fs-6 mt-1 text-black-50">
                      <CiCalendar />{" "}
                      <span>
                        {" "}
                        DATE: {moment(item?.startDate).format(
                          "DD-MM-YYYY"
                        )} - {moment(item?.endDate).format("DD-MM-YYYY")}
                      </span>
                    </div>

                    <div className="fs-6 mt-1 text-black-50">
                      <CiClock1 />
                      <span>
                        {" "}
                        TIME: {item?.startTime} - {item?.endTime}{" "}
                      </span>
                    </div>

                    <div className="fs-6 mt-1 text-black-50">
                      {" "}
                      <CiHospital1 /> VENUE: {item?.venue}
                    </div>
                    <div className="fs-6 mt-1 text-black-50">
                      <CiLocationOn />
                      <span> ADDRESS: {item?.address}</span>
                    </div>

                    <div className="mt-5 fs-6 text-black-50">
                      {item?.eventDescription?.slice(0, 100)}...
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Events;
