import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
function AdminClgHeader() {
  let collegedetails = JSON.parse(sessionStorage.getItem("collegeDetails"));
  const [AllDetails1, setAllDetails1] = useState([]);
  const ugCriteria01Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria01"
      );
      if (res.status === 200) {
        setAllDetails1(res.data.ugcriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails1(error.response.data.ugcriteria01);
    }
  };
  const [AllDetails2, setAllDetails2] = useState([]);
  const ugCriteria02Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria02"
      );
      if (res.status === 200) {
        setAllDetails2(res.data.ugcriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails2(error.response.data.ugcriteria02);
    }
  };
  // =================================================================
  const [AllDetails3, setAllDetails3] = useState([]);
  const ugCriteria03Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria03"
      );
      if (res.status === 200) {
        setAllDetails3(res.data.ugcriteria03);
      }
    } catch (error) {
      console.log(error);
      setAllDetails3(error.response.data.ugcriteria03);
    }
  };
  // =================================================================
  const [AllDetails4, setAllDetails4] = useState([]);
  const ugCriteria04Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getugcriteria04"
      );
      if (res.status === 200) {
        setAllDetails4(res.data.ugcriteria04);
      }
    } catch (error) {
      console.log(error);
      setAllDetails4(error.response.data.ugcriteria04);
    }
  };

  // =================================================================
  const [AllDetails5, setAllDetails5] = useState([]);
  const ugCriteria05Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria05"
      );
      if (res.status === 200) {
        setAllDetails5(res.data.ugcriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails5(error.response.data.ugcriteria05);
    }
  };
  // =================================================================
  const [AllDetails6, setAllDetails6] = useState([]);
  const ugCriteria06Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/ugadmin/getcri6"
      );
      if (res.status === 200) {
        setAllDetails6(res.data.ugcriteria06);
      }
    } catch (error) {
      console.log(error);
      setAllDetails6(error.response.data.ugcriteria06);
    }
  };

  // =================================================================
  const [AllDetails7, setAllDetails7] = useState([]);
  const getCriteria07 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getcri07details"
      );
      if (res.status === 200) {
        setAllDetails7(res.data.ugcriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails7(error.response.data.ugcriteria07);
    }
  };
  // =================================================================
  const [AllDetails8, setAllDetails8] = useState([]);
  const pgCriteria01Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcri01details"
      );
      if (res.status === 200) {
        setAllDetails8(res.data.pgcriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails8(error.response.data.pgcriteria01);
    }
  };
  // =================================================================
  const [AllDetails9, setAllDetails9] = useState([]);
  const pgCriteria02Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcri02details"
      );
      if (res.status === 200) {
        setAllDetails9(res.data.pgcriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails9(error.response.data.pgcriteria02);
    }
  };

  // =================================================================
  const [AllDetails10, setAllDetails10] = useState([]);
  const pgCriteria03Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria03"
      );
      if (res.status === 200) {
        setAllDetails10(res.data.pgcriteria03);
      }
    } catch (error) {
      console.log(error);
      setAllDetails10(error.response.data.pgcriteria03);
    }
  };

  // =================================================================
  const [AllDetails11, setAllDetails11] = useState([]);
  const pgCriteria04Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getpgcriteria04"
      );
      if (res.status === 200) {
        setAllDetails11(res.data.pgcriteria04);
      }
    } catch (error) {
      console.log(error);
      setAllDetails11(error.response.data.pgcriteria04);
    }
  };
  // =================================================================
  const [AllDetails12, setAllDetails12] = useState([]);
  const pgCriteria05Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getPG_cri05details"
      );
      if (res.status === 200) {
        setAllDetails12(res.data.pgcriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails12(error.response.data.pgcriteria05);
    }
  };
  // =================================================================
  const [AllDetails13, setAllDetails13] = useState([]);
  const pgCriteria06Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/pgadmin/getpgcri6"
      );
      if (res.status === 200) {
        setAllDetails13(res.data.pgcriteria06);
      }
    } catch (error) {
      console.log(error);
      setAllDetails13(error.response.data.pgcriteria06);
    }
  };
  // =================================================================
  const [AllDetails14, setAllDetails14] = useState([]);
  const getpgCriteria07 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getPG_cri07details"
      );
      if (res.status === 200) {
        setAllDetails14(res.data.pgcriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails14(error.response.data.pgcriteria07);
    }
  };

  useEffect(() => {
    ugCriteria01Details();
    ugCriteria02Details();
    ugCriteria03Details();
    ugCriteria04Details();
    ugCriteria05Details();
    ugCriteria06Details();
    getCriteria07();
    pgCriteria01Details();
    pgCriteria02Details();
    pgCriteria03Details();
    pgCriteria04Details();
    pgCriteria05Details();
    pgCriteria06Details();
    getpgCriteria07();
  }, []);
  return (
    <>
      <div
        className="header"
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          background: "#11112e",
        }}
      >
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              <h3 className="admin-heading" style={{ color: "white" }}>
                {collegedetails?.HeiName}
              </h3>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
                <div
                  className=" d-flex align-items-center justify-content-center"
                  style={{ gap: "1em" }}
                >
                  <div>
                    <Link to="/dashboard">
                      <Button variant="danger">Dashboard</Button>
                    </Link>
                  </div>

                  {/* <div class="dropdown ">
                    <img
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      src={`https://brightnaac.co.in/register/${collegedetails?.CollegeImg}`}
                      alt="logo"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        border: "2px solid black",
                        borderColor: "#393186",
                      }}
                    />
                  </div> */}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default AdminClgHeader;
