import axios from "axios";
import React, { useState } from "react";
import { Button, NavLink } from "react-bootstrap";
import "./admin.css";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
function AdminClgLogin() {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const AdminLogin = async () => {
    try {
      const config = {
        url: "/nacc/nacclogin",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        header: { "content-type": "application/json" },
        data: {
          Email: Email,
          Password: Password,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.setItem("Naccuser", JSON.stringify(res.data.details));
          window.location.assign("/dashboard");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  return (
    <>
      <section class="vh-100 p-lg-5 " style={{ backgroundColor: "#9A616D" }}>
        <div
          class="container p-lg-3"
          style={{
            maxWidth: "900px",
          }}
        >
          <section className="llogin">
            <div class="login-box">
              <div className="content-login">
                <div
                  className="left"
                  style={{
                    backgroundImage: "url('NME-Logo.png')",
                    height: "75%",
                    position: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <figure></figure>
                </div>

                <div className="form-login">
                  <div className="login-heading">Admin Login</div>
                  <form>
                    <label>Email</label>

                    <input
                      type="email"
                      placeholder="Enter Your User Id"
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <label>Password</label>

                    <input
                      type={isRevealPwd ? "text" : "password"}
                      value={Password}
                      placeholder="Enter Your Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {isRevealPwd ? (
                      <BsFillEyeFill
                        className="eye"
                        size={18}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        className="eye"
                        size={18}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    )}

                    <div className="login-btn">
                      <NavLink>
                        <button type="button" onClick={AdminLogin}>
                          Log in
                        </button>
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default AdminClgLogin;
