import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

function LibraryProfile() {
  const fuser = sessionStorage.getItem("Luser");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [facultyDetails, setfacultyDetails] = useState({});

  const [FName, setFName] = useState("");
  const [Email, setEmail] = useState("");
  const [PNumber, setPNumber] = useState("");
  const [Ohidepassword, setOhidepassword] = useState(true);
  const [Nhidepassword, setNhidepassword] = useState(true);
  const [oldpassword, setoldpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");

  const getFacultyDetails = async () => {
    try {
      const response = await axios.get(
        "https://brightnaac.co.in/api/flt/getFacById/" + JSON.parse(fuser)?._id
      );
      if (response.status === 200) {
        setfacultyDetails(response.data.data);
      }
    } catch (error) {
      alert("no data present");
    }
  };

  const editFacultyDetails = async () => {
    if (!FName && !Email && !PNumber) {
      return alert("Fill some field to update");
    }
    try {
      const config = {
        url: "/lib/editLibDetails/" + JSON.parse(fuser)?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        header: { "content-type": "application/json" },
        data: {
          FName: FName,
          Email: Email,
          PNumber: Number(PNumber),
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("Luser", JSON.stringify(res.data.data));
          // <FacultyDashboard />;
          alert(res.data.success);
          setFName("");
          setEmail("");
          setPNumber("");
          window.location.reload(true);
        }
      });
    } catch (error) {
      return alert(error.response.data.error);
    }
  };

  const changepwd = async () => {
    try {
      const config = {
        url: "/lib/UpdateLibPassword/" + JSON.parse(fuser)?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        header: { "content-type": "application/json" },
        data: {
          Email: Email,
          oldpassword: oldpassword,
          newpassword: newpassword,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setoldpassword("");
          setnewpassword("");
          sessionStorage.removeItem("Luser");
          window.location.assign("/llogin");
        }
      });
    } catch (error) {
      return alert(error.response.data.error);
    }
  };

  useEffect(() => {
    if (!fuser) {
      navigate("/llogin");
      return alert("Please login first");
    } else {
      getFacultyDetails();
    }
  }, [fuser]);

  console.log("oooo: ", facultyDetails);

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-3"></div>
          <div className="main-body">
            <div
              className="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Full Name</label>
                          <input
                            type="text"
                            placeholder={JSON.parse(fuser)?.FName}
                            className="vi_0"
                            onChange={(e) => setFName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Email</label>
                          <input
                            type="email"
                            placeholder={JSON.parse(fuser)?.Email}
                            className="vi_0"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Mobile Number</label>
                          <input
                            type="number"
                            placeholder={JSON.parse(fuser)?.PNumber}
                            className="vi_0"
                            onChange={(e) => setPNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Faculty Type</label>
                          <input
                            type="text"
                            placeholder={JSON.parse(fuser)?.ftype}
                            className="vi_0"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Designation</label>
                          <input
                            type="text"
                            placeholder={JSON.parse(fuser)?.Designation}
                            className="vi_0"
                            disabled
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="do-sear mb-2">
                          <label className="mb-0">Aadhar Number</label>
                          <input
                            type="email"
                            placeholder="0101-2323-4747"
                            className="vi_0"
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div>
                          <Button
                            variant="primary "
                            // onClick={handleShow1}
                            style={{ marginRight: "10px" }}
                            // style={{ color: "white", marginTop: "10px" }}
                            onClick={editFacultyDetails}
                          >
                            Update Details
                          </Button>

                          <Button
                            variant="primary "
                            onClick={handleShow1}
                            // style={{
                            //   color: "white",
                            //   float: "right",
                            //   marginTop: "10px",
                            // }}
                          >
                            Change Password
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>ChangePassword</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                {/* <div className="">
                  <div className="do-sear mt-2">
                    <label>Registered Email ID</label>
                    <input
                      type="text"
                      placeholder="Enter Registerd Email ID"
                      className="vi_0"
                    />
                  </div>
                </div> */}
                <div className="">
                  <div className="do-sear mt-2">
                    <label> Old Password</label>
                    <div className="d-flex align-items-center">
                      <input
                        type={Ohidepassword ? "password" : "text"}
                        placeholder="Enter Old Password"
                        value={oldpassword}
                        className="vi_0"
                        onChange={(e) => setoldpassword(e.target.value)}
                      />
                      {Ohidepassword ? (
                        <FaEye
                          style={{
                            padding: "5px",
                            fontSize: "33px",
                            position: "absolute",
                            right: "29px",
                          }}
                          onClick={() => setOhidepassword(false)}
                        />
                      ) : (
                        <FaEyeSlash
                          style={{
                            padding: "5px",
                            fontSize: "33px",
                            position: "absolute",
                            right: "29px",
                          }}
                          onClick={() => setOhidepassword(true)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="do-sear mt-2">
                    <label> New Password</label>
                    <div className="d-flex align-items-center">
                      <input
                        type={Nhidepassword ? "password" : "text"}
                        placeholder="Enter New Password"
                        value={newpassword}
                        className="vi_0"
                        onChange={(e) => setnewpassword(e.target.value)}
                      />
                      {Nhidepassword ? (
                        <FaEye
                          style={{
                            padding: "5px",
                            fontSize: "33px",
                            position: "absolute",
                            right: "29px",
                          }}
                          onClick={() => setNhidepassword(false)}
                        />
                      ) : (
                        <FaEyeSlash
                          style={{
                            padding: "5px",
                            fontSize: "33px",
                            position: "absolute",
                            right: "29px",
                          }}
                          onClick={() => setNhidepassword(true)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="warning"
                onClick={changepwd}
                style={{ color: "black" }}
              >
                submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default LibraryProfile;
