import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Container, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function TeacherFeedback() {
  const currentstudent = JSON.parse(sessionStorage.getItem("studentDetails"));
  const [selectedDate, setSelectedDate] = useState(null);
  const handleChange = (date) => {
    setSelectedDate(date);
  };
  const [StudentId, setStudentId] = useState("");
  const [Gender, setGender] = useState("male");
  const [Course, setCourse] = useState("");
  const [Semester, setSemester] = useState("");
  const [TeacherName, TeacherNameset] = useState("");
  const [Subject, Subjectset] = useState("");
  const [Survey1, setSurvey1] = useState("");
  const [Survey2, setSurvey2] = useState("");
  const [Survey3, setSurvey3] = useState("");
  const [Survey4, setSurvey4] = useState("");
  const [Survey5, setSurvey5] = useState("");
  const [Survey6, setSurvey6] = useState("");
  const [Survey7, setSurvey7] = useState("");
  const [Survey8, setSurvey8] = useState("");
  const [Survey9, setSurvey9] = useState("");
  const [Survey10, setSurvey10] = useState("");
  const [Suggestions, setSuggestions] = useState("");
  const Tracherfeedback = async () => {
    try {
      const config = {
        url: "student/teacherfeedback",
        method: "post",
        baseURL: "https://brightnaac.co.in/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          studentid: currentstudent?._id,
          StudentId: StudentId,
          gender: Gender,
          course: Course,
          semester: Semester,
          teachername: TeacherName,
          subject: Subject,
          survey1: Survey1,
          survey2: Survey2,
          survey3: Survey3,
          survey4: Survey4,
          survey5: Survey5,
          survey6: Survey6,
          survey7: Survey7,
          survey8: Survey8,
          survey9: Survey9,
          survey10: Survey10,
          suggestion: Suggestions,
          reviewyear: selectedDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        window.location.assign("/teacherfeedbacklist");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  return (
    <div>
      <Container className="pt-4">
        <div>
          <div className="bgdesign">
            <h2>Feedback from Students on Teachers</h2>
            <p className="text">Dear Students,</p>
            <p className="text">
              Greetings from Govt. First Grade College, RANEBENNUR.
            </p>
            <p>
              You are hereby informed to fill the bellow required data as it is
              required for NAAC Process.
            </p>
            <p className="text">Thank you.</p>
            <p> IQAC Team </p>
            <hr />
            <div className="d-flex justify-content-between">
              <b>{currentstudent?.email} </b>
              <div>
                <span>Select Year : </span>
                <DatePicker
                  placeholderText="select year"
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleChange}
                  dateFormat="yyyy"
                  showYearPicker
                  maxDate={new Date()}
                />
              </div>
            </div>
            <hr />
            <p style={{ color: "red" }}>* Indicates required question</p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  Student ID (University Reg.No.) :{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  style={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  onChange={(e) => setStudentId(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Gender : <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="male"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                      onChange={(e) => setGender(e.target.value)}
                    />

                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                      onChange={(e) => setGender(e.target.value)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  Course : <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setCourse(e.target.value)}
                    >
                      <MenuItem value={"BA"}>BA</MenuItem>
                      <MenuItem value={"BCom"}>BCom</MenuItem>
                      <MenuItem value={"BSc"}>BSc</MenuItem>
                      <MenuItem value={"BBA"}>BBA</MenuItem>
                      <MenuItem value={"MA (Economics)"}>
                        MA (Economics)
                      </MenuItem>
                      <MenuItem value={"MCom"}>MCom</MenuItem>
                      <MenuItem value={"Msc(Comp. Sci)"}>
                        Msc(Comp. Sci)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel id="demo-radio-buttons-group-label">
                  Semester : <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setSemester(e.target.value)}
                    >
                      <MenuItem value={"1st Semester"}>1st Semester</MenuItem>
                      <MenuItem value={"2nd Semester"}>2nd Semester</MenuItem>
                      <MenuItem value={"3rd Semester"}>3rd Semester</MenuItem>
                      <MenuItem value={"4th Semester"}>4th Semester</MenuItem>
                      <MenuItem value={"5th Semester"}>5th Semester</MenuItem>
                      <MenuItem value={"6th Semester"}>6th Semester</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  Name of the Teacher : <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => TeacherNameset(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  Subject : <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => Subjectset(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="bgdesign mt-2">
            <FormLabel>
              Please give your response on bellow points, as per the above
              Legends: <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <p className="text">
              ವಿದ್ಯಾರ್ಥಿಗಳೇ ಈ ಕೆಳಗಿನ ವಿವರಗಳಿಗೆ ಮೇಲೆ ಸೂಚಿಸಿದಂತೆ, ನಿಮ್ಮ ಅಂಕಗಳನ್ನು
              ದಾಖಲಿಸಿ
            </p>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Very Good</th>
                  <th>Good</th>
                  <th>Average</th>
                  <th>Acceptable</th>
                  <th>Poor</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">1. Regularity of teacher to class :</p>
                    <p className="text">ತರಗತಿಗಳಿಗೆ ಉಪನ್ಯಾಸಕರ ನಿಯಮಿತತೆ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey1 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey1 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey1 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey1 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey1 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">2. Preparation for the class </p>
                    <p className="text">ತರಗತಿಗಳಿಗೆ ಸಿದ್ಧತೆ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey2 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey2 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey2 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey2 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey2 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">3. Subject knowledge of teacher </p>
                    <p className="text">ಉಪನ್ಯಾಸಕರ ವಿಷಯ ಜ್ಞಾನ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey3 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey3 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey3 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey3 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey3 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      4. Presentation and communication skills
                    </p>
                    <p className="text">ಪ್ರಸ್ತುತಪಡಿಸುವಿಕೆ ಹಾಗೂ ಸಂವಹನ ಜ್ಞಾನ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey4 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey4 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey4 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey4 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey4 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">5. Knowledge on usage of ICT </p>
                    <p className="text">ಮಾಹಿತಿ ಹಾಗೂ ಸಂವಹನ ತಂತ್ರಜ್ಞಾನದ ಜ್ಞಾನ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey5 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey5 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey5 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey5 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey5 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">6. Time allotted to interaction </p>
                    <p className="text">ಪ್ರಶ್ನೋತ್ತರಕ್ಕೆ ನೀಡುವ ಸಮಯ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey6 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey6 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey6 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey6 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey6 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      7. Process of internal assessment by teacher{" "}
                    </p>
                    <p className="text">ಉಪನ್ಯಾಸಕರಿಂದ ಆಂತರಿಕ ಮೌಲ್ಯಮಾಪನ ಕ್ರಿಯೆ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey7 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey7 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey7 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey7 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey7 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">8. Availability outside the class </p>
                    <p className="text">ತರಗತಿಯ ಹೊರಗೆ ಲಭ್ಯತೆ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey8 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey8 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey8 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey8 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey8 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">9. Guidance and mentoring </p>
                    <p className="text">ಮಾರ್ಗದರ್ಶನ ಹಾಗೂ ಆಪ್ತಸಲಹೆ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={(e) => setSurvey9(e.target.value)}
                      value={"Very Good"}
                      control={<Radio checked={Survey9 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={(e) => setSurvey9(e.target.value)}
                      value={"Good"}
                      control={<Radio checked={Survey9 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={(e) => setSurvey9(e.target.value)}
                      value={"Average"}
                      control={<Radio checked={Survey9 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={(e) => setSurvey9(e.target.value)}
                      value={"Acceptable"}
                      control={<Radio checked={Survey9 === "Acceptable"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={(e) => setSurvey9(e.target.value)}
                      value={"Poor"}
                      control={<Radio checked={Survey9 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">10. Guidance and mentoring </p>
                    <p className="text">ಮಾರ್ಗದರ್ಶನ ಹಾಗೂ ಆಪ್ತಸಲಹೆ</p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey10 === "Very Good"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey10 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey10 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey10 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey10 === "Poor"} />}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="bgdesign mt-2">
            <FormLabel>
              <p className="text">11. Any Other Suggestions </p>{" "}
              <p className="text">ಇತರೆ ಯಾವುದೇ ಸಲಹೆಗಳು </p>
            </FormLabel>
            <TextField
              id="filled-multiline-flexible"
              label="Your Answer"
              multiline
              maxRows={4}
              variant="filled"
              style={{ width: "100%" }}
              onChange={(e) => setSuggestions(e.target.value)}
            />
          </div>

          <div className="mt-3">
            <Button
              variant="contained"
              className="mb-3"
              onClick={Tracherfeedback}
            >
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default TeacherFeedback;
