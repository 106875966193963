import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const UG_Criteria002 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //post method for criteria_02
  const [MainYear2, setMainYear2] = useState("");

  const [PrgCode, setPrgCode] = useState("");
  const [NoSeatSanctioned, setNoSeatSanctioned] = useState("");
  const [NoStdAdmit, setNoStdAdmit] = useState("");
  const [PrgName1, setPrgName1] = useState("");
  const [Desc6, setDesc6] = useState("");
  const [Link6, setLink6] = useState("");

  const [SC, setSC] = useState("");
  const [ST, setST] = useState("");
  const [OBC, setOBC] = useState("");
  const [Gen, setGen] = useState("");
  const [Divyangjan, setDivyangjan] = useState("");
  const [Others, setOthers] = useState("");
  const [SSC, setSSC] = useState("");
  const [SST, setSST] = useState("");
  const [OOBC, setOOBC] = useState("");
  const [GGen, setGGen] = useState("");
  const [DDivyangjan, setDDivyangjan] = useState("");
  const [OOthers, setOOthers] = useState("");
  const [Desc7, setDesc7] = useState("");
  const [Link7, setLink7] = useState("");

  const [TName, setTName] = useState("");
  const [PAN, setPAN] = useState("");
  const [Designation, setDesignation] = useState("");
  const [YOfAppoint, setYOfAppoint] = useState("");
  const [NoOfAppoint, setNoOfAppoint] = useState("");
  const [DeptName, setDeptName] = useState("");
  const [TYofExp, setTYofExp] = useState("");
  const [TService, setTService] = useState("");
  const [Desc8, setDesc8] = useState("");
  const [Link8, setLink8] = useState("");

  const [SerTeacher, setSerTeacher] = useState("");
  const [Qualify, setQualify] = useState("");
  const [YOfQuali, setYOfQuali] = useState("");
  const [YOfRecog, setYOfRecog] = useState("");
  const [Whether, setWhether] = useState("");
  const [Desc9, setDesc9] = useState("");
  const [Link9, setLink9] = useState("");

  const [pCode, setpCode] = useState("");
  const [PrgName2, setPrgName2] = useState("");
  const [NStdAppeared, setNStdAppeared] = useState("");
  const [NStdPassed, setNStdPassed] = useState("");
  const [Desc10, setDesc10] = useState("");
  const [Link10, setLink10] = useState("");

  const [PrgName3, setPrgName3] = useState("");
  const [NStd, setNStd] = useState("");
  const [Gender, setGender] = useState("");
  const [Category, setCategory] = useState("");
  const [SDomicile, setSDomicile] = useState("");
  const [NIndian, setNIndian] = useState("");
  const [Email, setEmail] = useState("");
  const [MNo, setMNo] = useState("");
  const [YJoining, setYJoining] = useState("");
  const [UID, setUID] = useState("");
  const [Desc11, setDesc11] = useState("");
  const [Link11, setLink11] = useState("");

  var mobilevalid = /^[6-9][0-9]{9}$/;

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getugcriteria2 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getugcriteria2();
  }, []);

  const ugcriteria2 = async () => {
    if (!MainYear2) {
      return alert("Please select the Year");
    }

    try {
      const config = {
        url: "/FacultyAdmin/adddetails02",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Year: MainYear2,

          Criteria02_211: obj1,
          Link6: Link6,

          Criteria02_212: obj2,
          Link7: Link7,

          Criteria02_241: obj3,
          Link8: Link8,

          Criteria02_242: obj4,
          Link9: Link9,

          Criteria02_263: obj5,
          Link10: Link10,

          Criteria02_271: obj6,
          Link11: Link11,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);

          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          navigate("/ugcriteria02");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !PrgName1 ||
          !PrgCode ||
          !NoSeatSanctioned ||
          !NoStdAdmit ||
          !Desc6 ||
          !Link6
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Link6);
      let obj = {
        criteria: "2.1.1",
        PrgName1: PrgName1,
        PrgCode: PrgCode,
        NoSeatSanctioned: NoSeatSanctioned,
        NoStdAdmit: NoStdAdmit,
        Desc6: Desc6,
        Link6: Link6,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getugcriteria2();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Desc7;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add2) {
        if (
          !SC ||
          !ST ||
          !OBC ||
          !Gen ||
          !Divyangjan ||
          !Others ||
          !SSC ||
          !SST ||
          !OOBC ||
          !GGen ||
          !DDivyangjan ||
          !OOthers ||
          !Desc7 ||
          !Link7
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Link7);
      let obj = {
        criteria: "2.1.2",
        SC: SC,
        ST: ST,
        OBC: OBC,
        Gen: Gen,
        Divyangjan: Divyangjan,
        Others: Others,
        SSC: SSC,
        SST: SST,
        OOBC: OOBC,
        GGen: GGen,
        DDivyangjan: DDivyangjan,
        OOthers: OOthers,
        Desc7: Desc7,
        Link7: Link7,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getugcriteria2();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Desc8;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add3) {
        if (
          !TName ||
          !PAN ||
          !Designation ||
          !YOfAppoint ||
          !NoOfAppoint ||
          !DeptName ||
          !TYofExp ||
          !TService ||
          !Desc8 ||
          !Link8
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Link8);
      let obj = {
        criteria: "2.4.1",
        TName: TName,
        PAN: PAN,
        Designation: Designation,
        YOfAppoint: YOfAppoint,
        NoOfAppoint: NoOfAppoint,
        DeptName: DeptName,
        TYofExp: TYofExp,
        TService: TService,
        Desc8: Desc8,
        Link8: Link8,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getugcriteria2();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Desc9;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add4) {
        if (
          !SerTeacher ||
          !Qualify ||
          !YOfQuali ||
          !YOfRecog ||
          !Whether ||
          !Desc9 ||
          !Link9
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Link9);
      let obj = {
        criteria: "2.4.2",
        SerTeacher: SerTeacher,
        Qualify: Qualify,
        YOfQuali: YOfQuali,
        YOfRecog: YOfRecog,
        Whether: Whether,
        Desc9: Desc9,
        Link9: Link9,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getugcriteria2();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Desc10;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add5) {
        if (
          !pCode ||
          !PrgName2 ||
          !NStdAppeared ||
          !NStdPassed ||
          !Desc10 ||
          !Link10
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("qqqq,", Link10);
      let obj = {
        criteria: "2.6.3",
        pCode: pCode,
        PrgName2: PrgName2,
        NStdAppeared: NStdAppeared,
        NStdPassed: NStdPassed,
        Desc10: Desc10,
        Link10: Link10,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getugcriteria2();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Desc11;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !PrgName3 ||
          !NStd ||
          !Gender ||
          !Category ||
          !SDomicile ||
          !NIndian ||
          !Email ||
          !MNo ||
          !YJoining ||
          !UID ||
          !Desc11 ||
          !Link11
        ) {
          return alert("Please fill all the fields");
        } else if (!MNo) {
          return alert("Enter Contact Number");
        } else if (MNo.length !== 10) {
          return alert("Entered Contact Number should be 10 digits");
        } else if (!MNo.match(mobilevalid)) {
          return alert("Enter Valid Contact Number");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("rrr,", Link11);
      let obj = {
        criteria: "2.7.1",
        PrgName3: PrgName3,
        NStd: NStd,
        Gender: Gender,
        Category: Category,
        SDomicile: SDomicile,
        NIndian: NIndian,
        Email: Email,
        MNo: MNo,
        YJoining: YJoining,
        UID: UID,
        Desc11: Desc11,
        Link11: Link11,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getugcriteria2();
      setedit6(false);
      setMNo("");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            UnderGraduate_Criteria-02
          </span>
        </div>

        <div className="container mt-3">
          <div className="row-fluid">
            <div
              class=" col-md-6-fluid border"
              style={{
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <label>Select Year</label>
              {obj1?.MainYear2 ? (
                <p>{obj1?.MainYear2}</p>
              ) : (
                <select
                  className="vi_0"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setMainYear2(e.target.value)}
                >
                  <option>Select Year</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}{" "}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row-fluid border p-4 bs mt-5 ">
            <h3>Criteria 2.1.1</h3>
            <h6>Average enrollement percentage(Average of last five years)</h6>

            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Programme name
                </label>
                {obj1?.PrgName1 ? (
                  <p>{obj1?.PrgName1}</p>
                ) : (
                  <select
                    class="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setPrgName1(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="BCA">BCA</option>
                    <option value="BSC">BSC</option>
                    <option value="BBA">BBA</option>
                    <option value="B.Com">B.Com</option>
                  </select>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Programme code
                </label>
                {obj1?.PrgCode ? (
                  <p>{obj1?.PrgCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPrgCode(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of seats sanctioned
                </label>
                {obj1?.NoSeatSanctioned ? (
                  <p>{obj1?.NoSeatSanctioned}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setNoSeatSanctioned(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of Students admitted
                </label>
                {obj1?.NoStdAdmit ? (
                  <p>{obj1?.NoStdAdmit}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setNoStdAdmit(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.Desc6 ? (
                  <p>{obj1?.Desc6}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setDesc6(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>
                <br />
                <input
                  className="vi_0"
                  type="file"
                  id="upload1"
                  onChange={(e) => {
                    setLink6(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="pt-2 text-end mt-3 ">
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3  "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria-02-211</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <div className="row">
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Programme Name
                        </label>

                        {edit1 ? (
                          <>
                            <select
                              class="form-select form-select-sm"
                              aria-label="Default select example"
                              style={{ width: "100%" }}
                              placeholder={PrgName1}
                              onChange={(e) => {
                                setPrgName1(e.target.value);
                              }}
                            >
                              <option selected>--Select--</option>
                              <option value="BCA">BCA</option>
                              <option value="BBA">BBA</option>
                              <option value="BBA">BBA</option>
                              <option value="B.Com">B.Com</option>
                            </select>
                          </>
                        ) : (
                          <>{PrgName1}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Programme Code
                        </label>

                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PrgCode}
                              onChange={(e) => {
                                setPrgCode(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PrgCode}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Number of seats sanctioned
                        </label>

                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={NoSeatSanctioned}
                              onChange={(e) => {
                                setNoSeatSanctioned(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{NoSeatSanctioned}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="content1" htmlFor="name">
                          {" "}
                          Number of Students admitted
                        </label>

                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={NoStdAdmit}
                              onChange={(e) => {
                                setNoStdAdmit(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{NoStdAdmit}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          Description(min 500 words)
                        </label>

                        {edit1 ? (
                          <>
                            <textarea
                              className="vi_0"
                              type="text"
                              name="email"
                              id="name"
                              placeholder={Desc6}
                              onChange={(e) => setDesc6(e.target.value)}
                            />
                          </>
                        ) : (
                          <>{Desc6}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Upload the relevant document
                        </label>

                        {edit1 ? (
                          <>
                            <input
                              className="vi_0"
                              type="file"
                              name="Year"
                              id="upload1"
                              accept="image/*"
                              onChange={(e) => setLink6(e.target.files[0])}
                            />
                          </>
                        ) : (
                          <>{Link6?.name}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat1();
                    handleClose();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container">
          <div className="row-fluid border p-4 bs mt-5 ">
            <h3>Criteria 2.1.2</h3>

            <h6>Average enrollement percentage(Average of last five years)</h6>
            <div className="row">
              <div className="row">
                <h6 className="text-center text-decoration-underline">
                  Number of seats earmarked for reserved category as per GOI or
                  State Government rule
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC{" "}
                  </label>
                  {obj2?.SC ? (
                    <p>{obj2?.SC}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setSC(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST{" "}
                  </label>
                  {obj2?.ST ? (
                    <p>{obj2?.ST}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setST(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>
                  {obj2?.OBC ? (
                    <p>{obj2?.OBC}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setOBC(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>
                  {obj2?.Gen ? (
                    <p>{obj2?.Gen}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setGen(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>
                  {obj2?.Divyangjan ? (
                    <p>{obj2?.Divyangjan}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setDivyangjan(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>
                  {obj2?.Others ? (
                    <p>{obj2?.Others}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setOthers(e.target.value)}
                    />
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students admitted from the reserved category
                </h6>

                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC{" "}
                  </label>
                  {obj2?.SSC ? (
                    <p>{obj2?.SSC}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setSSC(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST{" "}
                  </label>
                  {obj2?.SST ? (
                    <p>{obj2?.SST}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setSST(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>
                  {obj2?.OOBC ? (
                    <p>{obj2?.OOBC}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setOOBC(e.target.value)}
                    />
                  )}
                </div>

                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>
                  {obj2?.GGen ? (
                    <p>{obj2?.GGen}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setGGen(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>
                  {obj2?.DDivyangjan ? (
                    <p>{obj2?.DDivyangjan}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setDDivyangjan(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>
                  {obj2?.OOthers ? (
                    <p>{obj2?.OOthers}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => setOOthers(e.target.value)}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj2?.Desc7 ? (
                    <p>{obj2?.Desc7}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc7(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="vi_0"
                    type="file"
                    name="upload2"
                    id="upload2"
                    accept="image/*"
                    onChange={(e) => {
                      setLink7(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="pt-2 text-end mt-3 ">
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success  mt-3 "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow1}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_2 (2.1.2)</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <h6 className="text-center text-decoration-underline">
                      Number of seats earmarked for reserved category as per GOI
                      or State Government rule
                    </h6>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        SC
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={SC}
                            onChange={(e) => {
                              setSC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{SC}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        ST{" "}
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={ST}
                            onChange={(e) => {
                              setST(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{ST}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        OBC
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={OBC}
                            onChange={(e) => {
                              setOBC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{OBC}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Gen
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Gen}
                            onChange={(e) => {
                              setGen(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Gen}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Divyangjan
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Divyangjan}
                            onChange={(e) => {
                              setDivyangjan(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Divyangjan}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Others
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Others}
                            onChange={(e) => {
                              setOthers(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Others}</>
                      )}
                    </div>
                    <h6 className="text-center text-decoration-underline mt-3">
                      Number of students admitted from the reserved category
                    </h6>

                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        SC
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={SSC}
                            onChange={(e) => {
                              setSSC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{SSC}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        ST
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={SST}
                            onChange={(e) => {
                              setSST(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{SST}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        OBC
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={OOBC}
                            onChange={(e) => {
                              setOOBC(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{OOBC}</>
                      )}
                    </div>

                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Gen
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={GGen}
                            onChange={(e) => {
                              setGGen(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{GGen}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Divyangjan
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={DDivyangjan}
                            onChange={(e) => {
                              setDDivyangjan(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{DDivyangjan}</>
                      )}
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Others
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={OOthers}
                            onChange={(e) => {
                              setOOthers(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{OOthers}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <textarea
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc7}
                            onChange={(e) => setDesc7(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc7}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit2 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload2"
                            id="name"
                            onChange={(e) => setLink7(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link7?.name}</>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit2(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat2();
                    handleClose1();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container">
          <div className="row-fluid border p-4 bs mt-5 ">
            <h3>Criteria 2.4.1 & 2.4.3</h3>

            <h6>Average enrollement percentage(Average of last five years)</h6>
            <div className="row">
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Full-time teacher
                  </label>
                  {obj3?.TName ? (
                    <p>{obj3?.TName}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setTName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    PAN
                  </label>
                  {obj3?.PAN ? (
                    <p>{obj3?.PAN}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setPAN(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Designation
                  </label>
                  {obj3?.Designation ? (
                    <p>{obj3?.Designation}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setDesignation(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of appointment
                  </label>
                  {obj3?.YOfAppoint ? (
                    <p>{obj3?.YOfAppoint}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setYOfAppoint(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-9 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </label>
                  {obj3?.NoOfAppoint ? (
                    <p>{obj3?.NoOfAppoint}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setNoOfAppoint(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>
                  {obj3?.DeptName ? (
                    <p>{obj3?.DeptName}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setDeptName(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total years of Experience in the same institution
                  </label>
                  {obj3?.TYofExp ? (
                    <p>{obj3?.TYofExp}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setTYofExp(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>
                  {obj3?.TService ? (
                    <p>{obj3?.TService}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="fname"
                      id="name"
                      onChange={(e) => {
                        setTService(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj3?.Desc8 ? (
                    <p>{obj3?.Desc8}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc8(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload3">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  <input
                    className="vi_0"
                    type="file"
                    name="upload3"
                    id="upload3"
                    accept="image/*"
                    onChange={(e) => {
                      setLink8(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="pt-2 text-end mt-3 ">
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow2}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria-2.4.1 & 2.4.3</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Full-time teacher
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={TName}
                          onChange={(e) => {
                            setTName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{TName}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      PAN
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PAN}
                          onChange={(e) => {
                            setPAN(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PAN}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Designation
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Designation}
                          onChange={(e) => {
                            setDesignation(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Designation}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of appointment
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={YOfAppoint}
                          onChange={(e) => {
                            setYOfAppoint(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{YOfAppoint}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Department
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={NoOfAppoint}
                          onChange={(e) => {
                            setNoOfAppoint(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{NoOfAppoint}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Nature of appointment (Against Sanctioned post, temporary,
                      permanent)
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={DeptName}
                          onChange={(e) => {
                            setDeptName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{DeptName}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Total years of Experience in the same institution
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={TYofExp}
                          onChange={(e) => {
                            setTYofExp(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{TYofExp}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Is the teacher still serving the institution/If not last
                      year of the service of Faculty to the Institution
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={TService}
                          onChange={(e) => {
                            setTService(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{TService}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Desc8}
                          onChange={(e) => setDesc8(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Desc8}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="name"
                          onChange={(e) => setLink8(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Link8?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit3(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat3();
                    handleClose2();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container">
          <div className="row-fluid border p-4 bs mt-5 ">
            <h3>Criteria-2.4.2</h3>
            <h6>Average enrollement percentage(Average of last five years)</h6>
            <div className="row">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </label>
                  {obj4?.SerTeacher ? (
                    <p>{obj4?.SerTeacher}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setSerTeacher(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </label>
                  {obj4?.Qualify ? (
                    <p>{obj4?.Qualify}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setQualify(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of obtaining the qualification
                  </label>
                  {obj4?.YOfQuali ? (
                    <p>{obj4?.YOfQuali}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setYOfQuali(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Recognition as a Research Guide
                  </label>
                  {obj4?.YOfRecog ? (
                    <p>{obj4?.YOfRecog}</p>
                  ) : (
                    <input
                      className="vi_0"
                      type="text"
                      name="year"
                      id="name"
                      onChange={(e) => {
                        setYOfRecog(e.target.value);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </label>
                  {obj4?.Whether ? (
                    <p>{obj4?.Whether}</p>
                  ) : (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      onChange={(e) => setWhether(e.target.value)}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description (min 500 words)
                  </label>
                  {obj4?.Desc9 ? (
                    <p>{obj4?.Desc9}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      id="name"
                      cols={57}
                      rows={3}
                      onChange={(e) => {
                        setDesc9(e.target.value);
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Upload the relevant document
                  </label>

                  <input
                    className="vi_0"
                    type="file"
                    name="file"
                    id="upload4"
                    accept="image/*"
                    onChange={(e) => {
                      setLink9(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="pt-2 text-end mt-3 ">
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button className="btn btn-warning" onClick={handleShow3}>
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_2 (2.4.2)</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of full time serving teacher with
                        Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={SerTeacher}
                            onChange={(e) => {
                              setSerTeacher(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{SerTeacher}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Qualification (Ph.D./D.M/M.Ch./D.N.B Super
                        speciality/D.Sc./ D.Litt.)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Qualify}
                            onChange={(e) => {
                              setQualify(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Qualify}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of obtaining the qualification
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={YOfQuali}
                            onChange={(e) => {
                              setYOfQuali(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{YOfQuali}</>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of Recognition as a Research Guide
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={YOfRecog}
                            onChange={(e) => {
                              setYOfRecog(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{YOfRecog}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Whether recognised as a Research Guide for
                        Ph.D./D.M/M.Ch./D.N.B Super
                        speciality/D.Sc./D’Lit.(YES/NO)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="year"
                            id="name"
                            placeholder={Whether}
                            onChange={(e) => {
                              setWhether(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Whether}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description(min 500 words)
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={Desc9}
                            onChange={(e) => setDesc9(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Desc9}</>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Upload the relevant document
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="email"
                            id="upload4"
                            onChange={(e) => setLink9(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Link9?.name}</>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit4(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat4();
                    handleClose3();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container">
          <div className="row-fluid border p-4 bs mt-5 ">
            <h3>Criteria-2.6.3</h3>

            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Name
                </label>
                {obj5?.PrgName2 ? (
                  <p>{obj5?.PrgName2}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setPrgName2(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="BCA">BCA</option>
                    <option value="BSC">BSC</option>
                    <option value="BBA">BBA</option>
                    <option value="B.Com">B.Com</option>
                  </select>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Code
                </label>
                {obj5?.pCode ? (
                  <p>{obj5?.pCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setpCode(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students appeared in the final year examination
                </label>
                {obj5?.NStdAppeared ? (
                  <p>{obj5?.NStdAppeared}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setNStdAppeared(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="email">
                {" "}
                Number of students passed in final year examination
              </label>
              {obj5?.NStdPassed ? (
                <p>{obj5?.NStdPassed}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setNStdPassed(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj5?.Desc10 ? (
                <p>{obj5?.Desc10}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setDesc10(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload5">
                Upload the relevant document
              </label>
              <input
                className="vi_0"
                type="file"
                name="file"
                id="upload5"
                accept="image/*"
                onChange={(e) => {
                  setLink10(e.target.files[0]);
                }}
              />
            </div>

            <div className="text-end mt-4">
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat5();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow4}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show4} onHide={handleClose4}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria-2.6.3</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    {edit5 ? (
                      <>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={PrgName2}
                          onChange={(e) => setPrgName2(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="BCA">BCA</option>
                          <option value="BSC">BSC</option>
                          <option value="BBA">BBA</option>
                          <option value="B.Com">B.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{PrgName2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={pCode}
                          onChange={(e) => setpCode(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{pCode}</>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        Number of students appeared in the final year
                        examination
                      </label>

                      {edit5 ? (
                        <>
                          <input
                            className="vi_0"
                            type="text"
                            name="email"
                            id="name"
                            placeholder={NStdAppeared}
                            onChange={(e) => setNStdAppeared(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{NStdAppeared}</>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students passed in final year examination
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={NStdPassed}
                          onChange={(e) => setNStdPassed(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{NStdPassed}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      Description(min 500 words)
                    </label>

                    {edit5 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Desc10}
                          onChange={(e) => setDesc10(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Desc10}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="name"
                          type="file"
                          name="email"
                          id="upload5"
                          accept="image/*"
                          onChange={(e) => {
                            setLink10(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{Link10?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit5(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "White" }}
                  onClick={() => {
                    cat5();
                    handleClose4();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div className="row-fluid border p-4 bs mt-5 ">
            <h3>Criteria-2.7.1</h3>
            <div className="row">
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Name
                </label>
                {obj6?.PrgName3 ? (
                  <p>{obj6?.PrgName3}</p>
                ) : (
                  <select
                    class="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => setPrgName3(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="BCA">BCA</option>
                    <option value="BSC">BSC</option>
                    <option value="BBA">BBA</option>
                    <option value="B.Com">B.Com</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the student
                </label>
                {obj6?.NStd ? (
                  <p>{obj6?.NStd}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setNStd(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Gender
                </label>
                {obj6?.Gender ? (
                  <p>{obj6?.Gender}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Category
                </label>
                {obj6?.Category ? (
                  <p>{obj6?.Category}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  State of Domicile
                </label>
                {obj6?.SDomicile ? (
                  <p>{obj6?.SDomicile}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setSDomicile(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Nationality if othern than Indian
                </label>
                {obj6?.NIndian ? (
                  <p>{obj6?.NIndian}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setNIndian(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Email-ID
                </label>
                {obj6?.Email ? (
                  <p>{obj6?.Email}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Mobile Number
                </label>
                {obj6?.MNo ? (
                  <p>{obj6?.MNo}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="number"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setMNo(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of joining
                </label>
                {obj6?.YJoining ? (
                  <p>{obj6?.YJoining}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setYJoining(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Unique Enrollment ID / College ID/ University enrollment
                  number
                </label>
                {obj6?.UID ? (
                  <p>{obj6?.UID}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUID(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj6?.Desc11 ? (
                <p>{obj6?.Desc11}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setDesc11(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload6">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="file"
                id="upload6"
                accept="image/*"
                onChange={(e) => {
                  setLink11(e.target.files[0]);
                }}
              />
            </div>
            <div className="text-end mt-4">
              {add6 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat6();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow5}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show5} onHide={handleClose5}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria-2.7.1</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <select
                          class="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "200px" }}
                          placeholder={PrgName3}
                          onChange={(e) => {
                            setPrgName3(e.target.value);
                          }}
                        >
                          <option selected>--Select--</option>
                          <option value="BCA">BCA</option>
                          <option value="BSC">BSC</option>
                          <option value="BBA">BBA</option>
                          <option value="B.Com">B.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{PrgName3}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Student Name
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={NStd}
                          onChange={(e) => {
                            setNStd(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{NStd}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Gender
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Gender}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Gender}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Category
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Category}
                          onChange={(e) => {
                            setCategory(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Category}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      State of Domicile
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={SDomicile}
                          onChange={(e) => {
                            setSDomicile(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{SDomicile}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Nationality if othern than Indian
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={NIndian}
                          onChange={(e) => {
                            setNIndian(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{NIndian}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Email-ID
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Email}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Mobile Number
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="number"
                          name="email"
                          id="name"
                          placeholder={MNo}
                          onChange={(e) => {
                            setMNo(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{MNo}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of joining
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={YJoining}
                          onChange={(e) => {
                            setYJoining(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{YJoining}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Unique Enrollment ID / College ID/ University enrollment
                      number
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={UID}
                          onChange={(e) => {
                            setUID(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{UID}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit6 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Desc11}
                          onChange={(e) => setDesc11(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Desc11}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload6"
                          accept="image/*"
                          onChange={(e) => setLink11(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Link11.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit6(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat6();
                    handleClose5();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => ugcriteria2()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UG_Criteria002;
