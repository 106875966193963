import React from "react";

const UG6642aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Purpose of the Grant</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_642?.pgrant}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Funds/ Grants received (INR in lakhs)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_642?.Fundg}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the non government funding agencies/ individuals</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_642?.namefgov}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.UgCriteria06_642?.ugdesc5}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.UgCriteria06_642?.doc8}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG6642aqar;
