import axios from "axios";
import React, { useEffect, useState } from "react";
import UG5511aqar from "./UG5511aqar";
import UG5513aqar from "./UG5513aqar";
import UG5514aqar from "./UG5514aqar";
import UG5521aqar from "./UG5521aqar";
import UG5522aqar from "./UG5522aqar";
import UG5523aqar from "./UG5523aqar";
import UG5531aqar from "./UG5531aqar";
import UG5533aqar from "./UG5533aqar";
import { useNavigate } from "react-router-dom";
const UGC5aqar = ({ startYeardata, endYeardata }) => {
  const navigate = useNavigate();
  const [AllDetails1, setAllDetails1] = useState([]);
  const ugCriteria05Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getcri05details"
      );
      if (res.status === 200) {
        setAllDetails1(
          res.data.ugcriteria05?.filter(
            (item) =>
              item?.Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.ugcriteria05?.filter(
          (item) =>
            item?.Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setAllDetails1(error.response.data.ugcriteria05);
    }
  };

  useEffect(() => {
    ugCriteria05Details();
  }, [startYeardata, endYeardata]);
  return (
    <div>
      <div className="text-center">
        <b>Criteria 5</b>
      </div>
      {AllDetails1?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 5 (5.1.1 & 5.1.2)
            </div>
            <UG5511aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 5 (5.1.3)
            </div>
            <UG5513aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 5 (5.1.4)
            </div>
            <UG5514aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 5 (5.2.1)
            </div>
            <UG5521aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 5 (5.2.2)
            </div>
            <UG5522aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 5 (5.2.3)
            </div>
            <UG5523aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 5 (5.3.1)
            </div>
            <UG5531aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 5 (5.3.3)
            </div>
            <UG5533aqar item={item} />
          </div>
        );
      })}
    </div>
  );
};

export default UGC5aqar;
