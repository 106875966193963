import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

function Adminhelpuser() {
  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [View, setView] = useState({});
  const [name, setname] = useState("");
  const [website, setwebsite] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const AddContact = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/addcontactHelp",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          name: name,
          website: website,
          email: email,
          message: message,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          getContact();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the Contact======================
  const [Contact, setContact] = useState([]);
  const getContact = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getcontactHelp"
      );
      if (res.status === 200) {
        setContact(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setContact(error.response.data.allContent);
    }
  };

  const UpdateContact = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/editcontactHelp/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          name: name,
          website: website,
          email: email,
          message: message,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getContact();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  const deleteContact = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deletecontactHelp/${View?._id}`,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Deleted Successfully");
          getContact();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose1();
    }
  };

  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getContact();
  }, []);
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-12">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Requested Users
            </span>
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                }}
              >
                <th>Sl.No</th>
                <th>Name</th>
                <th>Website</th>
                <th>Email Id</th>
                <th>Message</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Contact?.filter((val) => val?.issueResolved === false)?.map(
                (item, i) => {
                  return (
                    <tr
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <td>{++i}</td>
                      <td>{item?.name}</td>
                      <td>{item?.website}</td>
                      <td>{item?.email}</td>
                      <td>{item?.message}</td>
                      <td>
                        <span
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          onClick={(w) => {
                            UpdateContact(w, item?._id);
                          }}
                        >
                          <Button className="btn btn-success">Resolved</Button>
                        </span>
                        {/* <span
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                        >
                          <AiFillDelete  size={20 } color="red"/>
                        </span> */}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Contact Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>Office Name</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>website</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setwebsite(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>E-mail</label>
              <div className="">
                <input
                  type="email"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>message</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setmessage(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddContact(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteContact(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Adminhelpuser;
