import axios from "axios";
import React, { useState } from "react";
import { Button, NavLink } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Styles/form.css";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

export default function StudentloginR() {
  const [studentemail, setstudentemail] = useState("");
  const [studentpassword, setstudentpassword] = useState("");

  const navigate = useNavigate();

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const loginstudent = async () => {
    try {
      const config = {
        url: "/student/currentStudent",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          email: studentemail,
          password: studentpassword,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.setItem(
            "studentDetails",
            JSON.stringify(res.data.studentDetails)
          );
          window.location.assign("/studentdashboard");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const Alumnistudent = async () => {
    try {
      const config = {
        url: "/student/alumniStudent",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          email: studentemail,
          password: studentpassword,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.setItem(
            "Alumnistudent",
            JSON.stringify(res.data.studentDetails)
          );
          window.location.assign("/studentdashboard");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const [LoginForm, setLoginForm] = useState(false);
  return (
    <>
      <section className="llogin">
        {LoginForm === false ? (
          <>
            <div class="login-box">
              <div className="content-login">
                <div
                // className="left"
                // style={{
                //   backgroundImage: "url('NME-Logo.png')",
                //   height: "75%",
                //   position: "center",
                //   backgroundSize: "cover",
                // }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Button onClick={() => setLoginForm(true)}>
                      Alumni Login
                    </Button>
                  </div>
                  <img
                    className="left"
                    style={{
                      height: "75%",
                      position: "center",
                      backgroundSize: "cover",
                    }}
                    src="./NME-Logo.png"
                    alt=""
                  />
                </div>

                <div className="form-login">
                  <div className="login-heading">Student Login</div>
                  <form>
                    <label>Email</label>

                    <input
                      type="email"
                      placeholder="Enter Your User Id"
                      value={studentemail}
                      onChange={(e) => setstudentemail(e.target.value)}
                    />

                    <label>Password</label>

                    <input
                      type={isRevealPwd ? "text" : "password"}
                      value={studentpassword}
                      placeholder="Enter Your Password"
                      onChange={(e) => setstudentpassword(e.target.value)}
                    />
                    {isRevealPwd ? (
                      <BsFillEyeFill
                        className="eye"
                        size={18}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        className="eye"
                        size={18}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    )}

                    <div className="login-btn" onClick={() => loginstudent()}>
                      <NavLink>
                        <button type="button">Log in</button>
                      </NavLink>
                    </div>

                    <div className="create" style={{ color: "#393f81" }}>
                      Don't have an account?{" "}
                      <Link to="/studentregister" style={{ color: "#393f81" }}>
                        Register here
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {LoginForm === true ? (
          <>
            <div class="login-box">
              <div className="content-login">
                <div>
                  <div style={{ textAlign: "center" }}>
                    <Button onClick={() => setLoginForm(false)}>
                      Student Login
                    </Button>
                  </div>
                  <img
                    className="left"
                    style={{
                      height: "75%",
                      position: "center",
                      backgroundSize: "cover",
                    }}
                    src="./NME-Logo.png"
                    alt=""
                  />
                </div>

                <div className="form-login">
                  <div className="login-heading">Alumni Login</div>
                  <form>
                    <label>Email</label>

                    <input
                      type="email"
                      placeholder="Enter Your User Id"
                      value={studentemail}
                      onChange={(e) => setstudentemail(e.target.value)}
                    />

                    <label>Password</label>

                    <input
                      type={isRevealPwd ? "text" : "password"}
                      value={studentpassword}
                      placeholder="Enter Your Password"
                      onChange={(e) => setstudentpassword(e.target.value)}
                    />
                    {isRevealPwd ? (
                      <BsFillEyeFill
                        className="eye"
                        size={18}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        className="eye"
                        size={18}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    )}

                    <div className="login-btn" onClick={() => Alumnistudent()}>
                      <NavLink>
                        <button type="button">Log in</button>
                      </NavLink>
                    </div>

                    <div className="create" style={{ color: "#393f81" }}>
                      Don't have an account?{" "}
                      <Link to="/studentregister" style={{ color: "#393f81" }}>
                        Register here
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </section>
    </>
  );
}
