import axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import PG2211aqar from "./PG2211aqar";
import PG2212aqar from "./PG2212aqar";
import PG2241aqar from "./PG2241aqar";
import PG2242aqar from "./PG2242aqar";
import PG2263aqar from "./PG2263aqar";
import PG2271aqar from "./PG2271aqar";

const PGC2aqar = React.memo(({ startYeardata, endYeardata }) => {
  const [content1, setContent1] = useState([]);
  const navigate = useNavigate();
  const getpgCriteria2 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcri02details"
      );
      if (res.status === 200) {
        setContent1(
          res.data.pgcriteria02?.filter(
            (item) =>
              item?.PG_Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.pgcriteria02?.filter(
          (item) =>
            item?.PG_Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setContent1(error.response.data.pgcriteria02);
    }
  };

  useEffect(() => {
    getpgCriteria2();
  }, [startYeardata, endYeardata]);

  return (
    <div>
      <div className="text-center">
        <b>Criteria 2</b>
      </div>
      {content1?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.1.1)
            </div>
            <PG2211aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.1.2)
            </div>
            <PG2212aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.4.1 & 2.4.3)
            </div>
            <PG2241aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.4.2)
            </div>
            <PG2242aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.6.3)
            </div>
            <PG2263aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 2 (2.7.1)
            </div>
            <PG2271aqar item={item} />
          </div>
        );
      })}
    </div>
  );
});

export default PGC2aqar;
