import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Container, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function FacultyFeedback() {
  let FacultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));

  const [selectedDate, setSelectedDate] = useState(null);
  const handleChange = (date) => {
    setSelectedDate(date);
  };
  const [Name, setName] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Survey1, setSurvey1] = useState("");
  const [Survey2, setSurvey2] = useState("");
  const [Survey3, setSurvey3] = useState("");
  const [Survey4, setSurvey4] = useState("");
  const [Survey5, setSurvey5] = useState("");
  const [Survey6, setSurvey6] = useState("");
  const [Survey7, setSurvey7] = useState("");
  const [Survey8, setSurvey8] = useState("");
  const [Survey9, setSurvey9] = useState("");
  const [Survey10, setSurvey10] = useState("");
  const [Survey11, setSurvey11] = useState("");
  const [Survey12, setSurvey12] = useState("");
  const [Suggestions, setSuggestions] = useState("");

  const Facultyfeedback = async () => {
    try {
      const config = {
        url: "FacultyAdmin/facultyfeedback",
        method: "post",
        baseURL: "https://brightnaac.co.in/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          facultyid: FacultyDetails?._id,
          name: Name,
          designation: Designation,
          survey1: Survey1,
          survey2: Survey2,
          survey3: Survey3,
          survey4: Survey4,
          survey5: Survey5,
          survey6: Survey6,
          survey7: Survey7,
          survey8: Survey8,
          survey9: Survey9,
          survey10: Survey10,
          survey11: Survey11,
          survey12: Survey12,
          suggestion: Suggestions,
          reviewyear: selectedDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        window.location.assign("/facultyfeedbacklist");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  return (
    <div>
      <Container className="pt-4">
        <div>
          <div className="bgdesign">
            <h2>
              Feedback from Faculty members on Curriculum / Infrastructure/
              Governance/ IQAC
            </h2>
            <p className="text">Dear Students,</p>
            <p className="text">
              Greetings from Govt. First Grade College, RANEBENNUR.
            </p>
            <p>
              You are hereby informed to fill the bellow required data as it is
              required for NAAC Process.
            </p>
            <p className="text">Thank you.</p>
            <p> IQAC Team </p>
            <hr />
            <div className="d-flex justify-content-between">
              <b>{FacultyDetails?.Email} </b>
              <div>
                <span>Select Year : </span>
                <DatePicker
                  placeholderText="select year"
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleChange}
                  dateFormat="yyyy"
                  showYearPicker
                  maxDate={new Date()}
                />
              </div>
            </div>
            <hr />
            <p style={{ color: "red" }}>* Indicates required question</p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  1. Name of the Faculty :{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  2. Designation : <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setDesignation(e.target.value)}
                    >
                      <MenuItem value={"Associate Professor"}>
                        Associate Professor
                      </MenuItem>
                      <MenuItem value={"Assistant Professor"}>
                        Assistant Professor
                      </MenuItem>
                      <MenuItem value={"Guest Faculty"}>Guest Faculty</MenuItem>
                      <MenuItem
                        value={"Group B & C Employee (Administrative Staff)"}
                      >
                        Group B & C Employee (Administrative Staff)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
          <div className="bgdesign mt-2">
            <FormLabel>
              Please give your response on bellow points, as per the above
              Legends: <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <p className="text">
              ವಿದ್ಯಾರ್ಥಿಗಳೇ ಈ ಕೆಳಗಿನ ವಿವರಗಳಿಗೆ ಮೇಲೆ ಸೂಚಿಸಿದಂತೆ, ನಿಮ್ಮ ಅಂಕಗಳನ್ನು
              ದಾಖಲಿಸಿ
            </p>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Very Good</th>
                  <th>Good</th>
                  <th>Average</th>
                  <th>Acceptable</th>
                  <th>Poor</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      1. The Committee/BOS is taking care to ensure the
                      relevance of the course offering and updating from time to
                      time.
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey1 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey1 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey1 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey1 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey1 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      2. Aims Objectives and outcomes of the course and syllabus
                      are well defined and clear to students and faculty members{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey2 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey2 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey2 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey2 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey2 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      3. Teaching of the subjects has encouraged the faculty
                      members to conduct research work{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey3 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey3 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey3 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey3 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey3 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      4. Student centered learning resources are available at
                      college
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey4 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey4 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey4 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey4 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey4 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      5. Institution academic calendar of events is designed and
                      implemented in adherence to University calendar of events.{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey5 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey5 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey5 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey5 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey5 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      6. The library staff, resource and reading room are
                      effective and accessible{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey6 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey6 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey6 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey6 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey6 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      7. The classrooms, labs and furniture facilities are
                      adequate and well maintained{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey7 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey7 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey7 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey7 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey7 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      8. Supporting infrastructure like Toilet, Drinking Water,
                      Canteen, Parking facilities are available{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey8 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey8 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey8 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey8 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey8 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      9. The teachers are encouraged to organize/participate in
                      workshops/ conferences/ community services/consultancy
                      services on qualitative themes{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey9 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey9 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey9 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey9 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey9 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      10. Sports infrastructure and Staff is effective and
                      Adequate{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey10 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey10 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey10 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey10 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey10("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey10 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      11. The administration is sincerely putting efforts and
                      accessible for the development of the institution{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey11("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey11 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey11("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey11 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey11("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey11 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey11("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey11 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey11("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey11 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      12. The IQAC is working well for promoting quality to
                      achieve excellence in higher education{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey12("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey12 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey12("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey12 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey12("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey12 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey12("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey12 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey12("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey12 === "Poor"} />}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="bgdesign mt-2">
            <FormLabel>
              <p className="text">13. Any other suggestions</p>{" "}
            </FormLabel>
            <TextField
              id="filled-multiline-flexible"
              label="Your Answer"
              multiline
              maxRows={4}
              variant="filled"
              style={{ width: "100%" }}
              onChange={(e) => setSuggestions(e.target.value)}
            />
          </div>

          <div className="mt-3">
            <Button
              variant="contained"
              className="mb-3"
              onClick={Facultyfeedback}
            >
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default FacultyFeedback;
