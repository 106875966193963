import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap/cjs";
import "./admin.css";
import { Card } from "react-bootstrap";
import axios from "axios";

function FacultyAdmindashboard() {
  const [FacultyFeed, setFacultyFeed] = useState([]);
  const getAllfacultyFeedback = async () => {
    try {
      const response = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/facultyfeedback"
      );
      if (response.status === 200) {
        setFacultyFeed(response.data.success);
      }
    } catch (error) {
      alert("no data present");
    }
  };
  useEffect(() => {
    getAllfacultyFeedback();
  }, []);
  return (
    <>
      <div className="ad-dash mt-5">
        <div className="container">
          <div className="vina">
            <div className="np-p mt-4">
              <div className="row">
                <div className="col-md-4">
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: "18px",
                          fontFamily: "sans-serif",
                          fontWeight: "bold",
                          borderBottom: "2px solid ",
                          textAlign: "center",
                        }}
                      >
                        Total Faculty Feedback
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted text-center">
                        {FacultyFeed?.length}
                      </Card.Subtitle>
                      <Card.Text>
                        Guiding Faculty Improvement Efforts, Cultivating
                        Teaching Excellence
                      </Card.Text>
                      <Card.Link href="/facultyfeedbacklist">View</Card.Link>
                    </Card.Body>
                  </Card>
                </div>
                {/* <div className="col-md-4">
              <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: "18px",
                        fontFamily: "sans-serif",
                        fontWeight: "bold",
                        borderBottom: "2px solid ",
                        textAlign: "center",
                      }}
                    >
                      Total Syllabus Feedback
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted text-center">
                 
                    </Card.Subtitle>
                    <Card.Text>
                    Collaborating for Curriculum Enhancement, Enriching Learning Outcomes
                    </Card.Text>
                    <Card.Link href="/studentsatsurveylist">View</Card.Link>
                  </Card.Body>
                </Card>
              </div> */}

                {/* <div className="col-md-4">
              <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: "18px",
                        fontFamily: "sans-serif",
                        fontWeight: "bold",
                        borderBottom: "2px solid ",
                        textAlign: "center",
                      }}
                    >
                      Total Teacher Feedback
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted text-center">
                  
                    </Card.Subtitle>
                    <Card.Text>
                    Leading the Way in Teacher Development, Empowering Student Progress
                    </Card.Text>
                    <Card.Link href="/teacherfeedbacklist">View</Card.Link>
                  </Card.Body>
                </Card>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FacultyAdmindashboard;
