import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import exportFromJSON from "export-from-json";

const Criteria3 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  // ================Show doc17 modal======================
  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  // ================Show doc18 modal======================
  const [show18, setShow18] = useState(false);
  const handleClose18 = () => setShow18(false);
  const handleShow18 = () => setShow18(true);

  // ================Show doc19 modal======================
  const [show19, setShow19] = useState(false);
  const handleClose19 = () => setShow19(false);
  const handleShow19 = () => setShow19(true);

  // ================Show doc20 modal======================
  const [show20, setShow20] = useState(false);
  const handleClose20 = () => setShow20(false);
  const handleShow20 = () => setShow20(true);

  // ================Show doc21 modal======================
  const [show21, setShow21] = useState(false);
  const handleClose21 = () => setShow21(false);
  const handleShow21 = () => setShow21(true);

  // ================Show doc22 modal======================
  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);
  // ================Show doc23 modal======================
  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);
  const handleShow23 = () => setShow23(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaA3/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getContent();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  const [Content, setContent] = useState([]);
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getautocri03details"
      );
      if (res.status === 200) {
        setContent(res.data.autocriteria03);
      }
    } catch (error) {
      console.log(error);
      setContent(error.response.data.autocriteria03);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
    }
  }, []);
  console.log("fjfjfjfjf", Content);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-03
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className="">
            <Nav defaultActiveKey="/autocriteria03">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/A_03_312")}
                >
                  <Button className="criteriabtn btn-sub1">3.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/A_03_313")}
                >
                  <Button className="criteriabtn btn-sub">3.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_03_321")}
                >
                  <Button className="criteriabtn btn-sub">
                    3.2.1 & 3.2.2 & 3.2.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_03_332")}
                >
                  <Button className="criteriabtn btn-sub">3.3.2</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_03_343")}
                >
                  <Button className="criteriabtn btn-sub">3.4.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_03_344")}
                >
                  <Button className="criteriabtn btn-sub">3.4.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_03_351")}
                >
                  <Button className="criteriabtn btn-sub">3.5.1&3.5.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_03_362")}
                >
                  <Button className="criteriabtn btn-sub">3.6.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_03_363")}
                >
                  <Button className="criteriabtn btn-sub">3.6.3 & 3.6.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_03_371")}
                >
                  <Button className="criteriabtn btn-sub">3.7.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_03_372")}
                >
                  <Button className="criteriabtn btn-sub">3.7.2</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "15000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 3.1.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher provided with seed money</th>
                  <th>The amount of seed money (INR in Lakhs)</th>
                  <th>Year of receiving</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.1.3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </th>
                  <th>Name of the award/fellowship</th>
                  <th>Year of Award</th>
                  <th>Awarding Agency</th>
                  <th>Description</th>
                  <th>Document</th>
                  {/* 3.2.1 & 3.2.2 & 3.2.4 */}
                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </th>
                  <th>Name of the Funding agency</th>
                  <th>Type (Government/Non-Government)</th>
                  <th>Department of Principal Investigator/ Co Investigator</th>
                  <th>Year of Award</th>
                  <th>Funds provided (INR in lakhs)</th>
                  <th>Duration of the project</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.3.2 */}
                  <th>Criteria</th>
                  <th>Name of the workshop/ seminar</th>
                  <th>Name of the Participants</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.3 */}
                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Department of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>Document Journal</th>
                  <th>Document of the article</th>

                  {/* 3.4.4 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.5.1 & 3.5.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher-consultants</th>
                  <th>
                    Name of consultancy project/corporate training program
                  </th>
                  <th>Consulting/Sponsoring agency with contact details</th>
                  <th>Revenue generated (INR in Lakhs)</th>
                  <th>Number of trainees</th>
                  <th>Names of the teachers/staff</th>
                  <th>Name of the facilities developed and department</th>
                  <th>Agency seeking training with contact details</th>
                  <th>Name of consultancy</th>
                  <th>Total amount spent (INR in Lakhs)</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.6.2 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Name of the Award/ recognition</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Year of award</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.6.3 & 3.6.4 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of activity</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.7.1 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the collaborating agency with contact details</th>
                  <th>Title of the collaborative activity</th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Nature of the activity</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.7.2 */}
                  <th>Criteria</th>
                  <th>Organisation with which MoU is signed</th>
                  <th>Name of the institution/ industry/ corporate house</th>
                  <th>Year of signing MoU</th>
                  <th>Duration</th>
                  <th>List the actual activities under each MOU year-wise</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {Content?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    (item1?.approve === "false" ||
                      item1?.approve === "Pending") &&
                    (SearchItem === "" ||
                      item1?.Auto_Criteria03_312?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_SeedMoney?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_Seed?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_Receiving?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_FellowShip?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_AFellowship?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_Award?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_Agency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_SLNO?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Invest?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_FAgency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Dep?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Award1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Funds?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Project?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_332?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_332?.Auto_Seminar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_332?.Auto_Parti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Auto_Criteria03_332?.Auto_DFrom)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Auto_Criteria03_332?.Auto_DTo)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Auto_Criteria03_332?.Auto_Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Paper?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Author?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Dept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Journal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Pub?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_ISSN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_ISIT?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Desc9?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_SLno?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Name?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Book?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Paper1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Title4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Year2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_ISBN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Name1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Consult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Project1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Agency1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Revenue?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Trainee?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Desc11?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Name2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Name3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Name4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Year4?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Name5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_org?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Scheme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Year5?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Participants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Desc13?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_SLNO1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Colloborative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Agency2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Participants3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Collo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Durat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Activity?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_IIC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Signing?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Durat1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Auto_Year}</td>

                      {/* 3.1.2 */}
                      <td>{item?.Auto_Criteria03_312?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_312?.Auto_Seed}</td>
                      <td>{item?.Auto_Criteria03_312?.Auto_SeedMoney}</td>
                      <td>{item?.Auto_Criteria03_312?.Auto_Receiving}</td>

                      <td>
                        {item?.Auto_Criteria03_312?.Auto_Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.3 */}
                      <td>{item?.Auto_Criteria03_313?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_FellowShip}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_AFellowship}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_Award}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_Agency}</td>

                      <td>
                        {item?.Auto_Criteria03_313?.Auto_Desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.2.1 & 3.2.2 & 3.2.4 */}
                      <td>{item?.Auto_Criteria03_321?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_SLNO}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Invest}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_FAgency}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Type}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Dep}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Award1}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Funds}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Project}</td>

                      <td>
                        {item?.Auto_Criteria03_321?.Auto_Desc7?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.3.2 */}
                      <td>{item?.Auto_Criteria03_332?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_332?.Auto_Seminar}</td>
                      <td>{item?.Auto_Criteria03_332?.Auto_Parti}</td>
                      <td>
                        {moment(item?.Auto_Criteria03_332?.Auto_DFrom).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Auto_Criteria03_332?.Auto_DTo).format(
                          "DD-MM-YYYY"
                        )}
                      </td>

                      <td>
                        {item?.Auto_Criteria03_332?.Auto_Desc8?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.3 */}
                      <td>{item?.Auto_Criteria03_343?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Paper}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Author}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Dept}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Journal}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Pub}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_ISSN}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_ISIT}</td>

                      <td>
                        {item?.Auto_Criteria03_343?.Auto_Desc9?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow17();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.4 */}
                      <td>{item?.Auto_Criteria03_344?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_SLno}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Name}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Book}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Paper1}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Title4}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Year2}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_ISBN}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Name1}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Whether}</td>

                      <td>
                        {item?.Auto_Criteria03_344?.Auto_Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow18();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.5.1 & 3.5.2 */}
                      <td>{item?.Auto_Criteria03_351?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Consult}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Project1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Agency1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Revenue}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Trainee}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Teacher1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Facility1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Agen1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Consult2}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Total1}</td>
                      <td>
                        {item?.Auto_Criteria03_351?.Auto_Desc11?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow19();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.2 */}
                      <td>{item?.Auto_Criteria03_362?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Name2}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Name3}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Name4}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Year4}</td>

                      <td>
                        {item?.Auto_Criteria03_362?.Auto_Desc12?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow20();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.3 & 3.6.4 */}
                      <td>{item?.Auto_Criteria03_363?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Name5}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_org}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Scheme}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Year5}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Participants1}</td>

                      <td>
                        {item?.Auto_Criteria03_363?.Auto_Desc13?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow21();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.1 */}
                      <td>{item?.Auto_Criteria03_371?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_SLNO1}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Agency2}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Colloborative}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Participants3}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Collo}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Durat}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Activity}</td>

                      <td>
                        {item?.Auto_Criteria03_371?.Auto_Desc14?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow22();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.2 */}
                      <td>{item?.Auto_Criteria03_372?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_Organ}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_IIC}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_Signing}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_Durat1}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_List}</td>

                      <td>
                        {item?.Auto_Criteria03_372?.Auto_Desc15?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow23();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          {/* <i class="fa-solid fa-check fa-lg" style={{color: "#26a269"}}></i> */}
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {/* show 13 model */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_312?.Auto_Desc5}</div>
            </Modal.Body>
          </Modal>
          {/* show 14 model */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_313?.Auto_Desc6}</div>
            </Modal.Body>
          </Modal>
          {/* show 15 model */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_321?.Auto_Desc7}</div>
            </Modal.Body>
          </Modal>
          {/* show 16 model */}
          <Modal show={show16} onHide={handleClose16}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_332?.Auto_Desc8}</div>
            </Modal.Body>
          </Modal>
          {/* show 17 model */}
          <Modal show={show17} onHide={handleClose17}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_343?.Auto_Desc9}</div>
            </Modal.Body>
          </Modal>
          {/* show 18 model */}
          <Modal show={show18} onHide={handleClose18}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_344?.Auto_Desc10}</div>
            </Modal.Body>
          </Modal>
          {/* show 19 model */}
          <Modal show={show19} onHide={handleClose19}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_351?.Auto_Desc11}</div>
            </Modal.Body>
          </Modal>
          {/* show 20 model */}
          <Modal show={show20} onHide={handleClose20}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_362?.Auto_Desc12}</div>
            </Modal.Body>
          </Modal>
          {/* show 21 model */}
          <Modal show={show21} onHide={handleClose21}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_363?.Auto_Desc13}</div>
            </Modal.Body>
          </Modal>
          {/* show 22 model */}
          <Modal show={show22} onHide={handleClose22}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_371?.Auto_Desc14}</div>
            </Modal.Body>
          </Modal>
          {/* show 23 model */}
          <Modal show={show23} onHide={handleClose23}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_372?.Auto_Desc15}</div>
            </Modal.Body>
          </Modal>
          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_312?.Auto_Link5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_313?.Auto_Link6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_321?.Auto_Link7}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_332?.Auto_Link8}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_343?.Auto_Link9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_343?.Auto_Link10}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_344?.Auto_Link11}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_351?.Auto_Link12}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_362?.Auto_Link13}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 10 model */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_363?.Auto_Link14}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 11 model */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_371?.Auto_Link15}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* show 12 model */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_372?.Auto_Link16}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* Add Model */}
          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#" }}>
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Autonomous_Criteria-03
                </span>{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-0 p-0">
              {/* ------3.1.2------ */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.1.2
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the teacher provided with seed money
                    </label>

                    <>{Value?.Auto_Criteria03_312?.Auto_Seed}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      The amount of seed money (INR in Lakhs)
                    </label>

                    <>{Value?.Auto_Criteria03_312?.Auto_SeedMoney}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of receiving
                    </label>

                    <>{Value?.Auto_Criteria03_312?.Auto_Receiving}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_312?.Auto_Desc5}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Auto_Criteria03_312?.Auto_Link5}</>
                  </div>
                </div>
              </div>

              {/* ------3.1.3-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.1.3
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher awarded national/ international
                      fellowship/financial support
                    </label>

                    <>{Value?.Auto_Criteria03_313?.Auto_FellowShip}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the award/fellowship
                    </label>

                    <>{Value?.Auto_Criteria03_313?.Auto_AFellowship}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of Award
                    </label>

                    <>{Value?.Auto_Criteria03_313?.Auto_Award}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Awarding Agency
                    </label>

                    <>{Value?.Auto_Criteria03_313?.Auto_Agency}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_313?.Auto_Desc6}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Auto_Criteria03_313?.Auto_Link6}</>
                  </div>
                </div>
              </div>

              {/* ------3.2.1-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.2.1
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-2 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Sl.no.
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_SLNO}</>
                  </div>
                  <div className="col-md-10 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Principal Investigator/ Co Investigator (if
                      applicable)
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_Invest}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Funding agency
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_FAgency}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Type (Government/Non-Government)
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_Type}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Department of Principal Investigator/ Co Investigator
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_Dep}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of Award
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_Award1}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Funds provided (INR in lakhs)
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_Funds}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Duration of the project
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_Project}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_Desc7}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Auto_Criteria03_321?.Auto_Link7}</>
                  </div>
                </div>
              </div>

              {/* ------3.3.2-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.3.2
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the workshop/ seminar
                    </label>

                    <>{Value?.Auto_Criteria03_332?.Auto_Seminar}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the students
                    </label>

                    <>{Value?.Auto_Criteria03_332?.Auto_Parti}</>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date (From)
                    </label>

                    <>
                      {moment(Value?.Auto_Criteria03_332?.Auto_DFrom).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date (To)
                    </label>

                    <>
                      {moment(Value?.Auto_Criteria03_332?.Auto_DTo).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_332?.Auto_Desc8}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Auto_Criteria03_332?.Auto_Link8}</>
                  </div>
                </div>
              </div>

              {/* ------3.4.3-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.4.3
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of paper
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_Paper}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the author/s
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_Author}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Department of the teacher
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_Dept}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of journal
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_Journal}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of publication
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_Pub}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ISSN number
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_ISSN}</>
                  </div>
                  <h6>
                    Link to the recognition in UGC enlistment of the Journal{" "}
                  </h6>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Is it listed in UGC Care list/Scopus/Web of Science/other,
                      mention
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_ISIT}</>
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_Desc9}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document 1
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_Link9}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document 2
                    </label>

                    <>{Value?.Auto_Criteria03_343?.Auto_Link10}</>
                  </div>
                </div>
              </div>

              {/* ------3.4.4-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.4.4
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      Sl. No.
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_SLno}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_Name}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the book/chapters published
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_Book}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the paper
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_Paper1}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the proceedings of the conference
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_Title4}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of publication
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_Year2}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ISBN/ISSN number of the proceeding
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_ISBN}</>
                  </div>

                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the publisher
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_Name1}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Whether at the time of publication Affiliating Institution
                      Was same
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_Whether}</>
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_Desc10}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Auto_Criteria03_344?.Auto_Link11}</>
                  </div>
                </div>
              </div>

              {/* ------3.5.1-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.5.1 & 3.5.2
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher-consultants
                    </label>

                    <>{Value?.Auto_Criteria03_351?.Auto_Consult}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of consultancy project/corporate training program
                    </label>

                    <>{Value?.Auto_Criteria03_351?.Auto_Project1}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Consulting/Sponsoring agency with contact details
                    </label>

                    <>{Value?.Auto_Criteria03_351?.Auto_Agency1}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Revenue generated (INR in Lakhs)
                    </label>

                    <>{Value?.Auto_Criteria03_351?.Auto_Revenue}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of trainees
                    </label>

                    <>{Value?.Auto_Criteria03_351?.Auto_Trainee}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_351?.Auto_Desc11}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Auto_Criteria03_351?.Auto_Link12}</>
                  </div>
                </div>
              </div>

              {/* ------3.6.2-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.6.2
                  </span>
                </div>
                <div className="row  m-0 ">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the activity
                    </label>

                    <>{Value?.Auto_Criteria03_362?.Auto_Name2}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Award/ recognition
                    </label>

                    <>{Value?.Auto_Criteria03_362?.Auto_Name3}</>
                  </div>
                  <div className="col-md-8 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Awarding government/ government recognised
                      bodies
                    </label>

                    <>{Value?.Auto_Criteria03_362?.Auto_Name4}</>
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of award
                    </label>

                    <>{Value?.Auto_Criteria03_362?.Auto_Year4}</>
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_362?.Auto_Desc12}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Auto_Criteria03_362?.Auto_Link13}</>
                  </div>
                </div>
              </div>

              {/* ------3.6.3-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.6.3 & 3.6.4
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the activity
                    </label>

                    <>{Value?.Auto_Criteria03_363?.Auto_Name5}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Organising unit/ agency/ collaborating agency
                    </label>

                    <>{Value?.Auto_Criteria03_363?.Auto_org}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the scheme
                    </label>

                    <>{Value?.Auto_Criteria03_363?.Auto_Scheme}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of the activity
                    </label>

                    <>{Value?.Auto_Criteria03_363?.Auto_Year5}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of students participated in such activities
                    </label>

                    <>{Value?.Auto_Criteria03_363?.Auto_Participants1}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_363?.Auto_Desc13}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Auto_Criteria03_363?.Auto_Link14}</>
                  </div>
                </div>
              </div>

              {/* ------3.7.1-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.7.1
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Sl. No.
                    </label>

                    <>{Value?.Auto_Criteria03_371?.Auto_SLNO1}</>
                  </div>
                  <div className="col-md-8 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the collaborating agency with contact details
                    </label>

                    <>{Value?.Auto_Criteria03_371?.Auto_Agency2}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the collaborative activity
                    </label>

                    <>{Value?.Auto_Criteria03_371?.Auto_Colloborative}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the participant
                    </label>

                    <>{Value?.Auto_Criteria03_371?.Auto_Participants3}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of collaboration
                    </label>

                    <>{Value?.Auto_Criteria03_371?.Auto_Collo}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Duration
                    </label>

                    <>{Value?.Auto_Criteria03_371?.Auto_Durat}</>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Nature of the activity
                    </label>

                    <>{Value?.Auto_Criteria03_371?.Auto_Activity}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    <>{Value?.Auto_Criteria03_371?.Auto_Desc14}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    <>{Value?.Auto_Criteria03_371?.Auto_Link15}</>
                  </div>
                </div>
              </div>

              {/* ------3.7.2-------- */}

              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Sub_Criteria-3.7.2
                  </span>
                </div>
                <div className="row  m-0 ">
                  {" "}
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Organisation with which MoU is signed
                      </label>

                      <>{Value?.Auto_Criteria03_372?.Auto_Organ}</>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Name of the institution/ industry/ corporate house
                      </label>

                      <>{Value?.Auto_Criteria03_372?.Auto_IIC}</>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Year of signing MoU
                      </label>

                      <>{Value?.Auto_Criteria03_372?.Auto_Signing}</>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        Duration
                      </label>

                      <>{Value?.Auto_Criteria03_372?.Auto_Durat1}</>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="content1" htmlFor="name">
                        {" "}
                        List the actual activities under each MOU year-wise
                      </label>

                      <>{Value?.Auto_Criteria03_372?.Auto_List}</>
                    </div>

                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Description
                      </label>

                      <>{Value?.Auto_Criteria03_372?.Auto_Desc15}</>
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Document
                      </label>

                      <>{Value?.Auto_Criteria03_372?.Auto_Link16}</>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {Value?.approve === "false" ? (
                <></>
              ) : (
                <div>
                  <Button
                    className="btn btn-success"
                    onClick={(e) => {
                      approveCriteria(e, true, Value?._id);
                      handleClose();
                    }}
                  >
                    Approve{" "}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => setSmShow(true)}
                    className="btn btn-danger"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal>
          {
            // remarks model
          }
          <Modal
            // size="sm"
            show={smShow}
            onHide={() => handleClosesm()}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Tell me the reason?..
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                onChange={(e) => setremark(e.target.value)}
                style={{ height: "200px" }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  approveCriteria(e, false, Value?._id);
                  handleClose();
                  handleClosesm();
                }}
              >
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Criteria3;
