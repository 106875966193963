import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

const PdfPrintUN3_352 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }
  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    console.log("ddkd1", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("ddkd2", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("ddkd3", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("ddkd4", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

    // const input = document.getElementById("pdf");
    // const options = { scrollY: -window.scrollY, useCORS: true };
    // const canvas = await html2canvas(input, options);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    // pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    pdf.save("3.5.2.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);
  if (location?.state == null) {
    navigate("/Uni_03_352");
  }
  return (
    <div>
      <div className="m-auto ">
        <div id="pdf">
          <div className="p-2">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className="img-fluid mt-5"
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                year:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.Uni_Year}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state[location?.state?.length - 1]?.criteria}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>
                    {location?.state["0"]["Uni_Criteria03_352"]["Uni_criteria"]}
                  </>
                )}
              </div>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>
                    Revenue generated from consultancy during the last five
                    years
                  </th>
                  <th>
                    Revenue generated from corporate training during the last
                    five years
                  </th>
                  <th>Number of trainees</th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>
                </tr>
              </thead>
              <tbody>
                {finalArr?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>

                      <td>
                        <Table responsive>
                          <thead>
                            <th>Name of the teacher consultant</th>
                            <th>Name of consultancy project</th>
                            <th>
                              Consulting/Sponsoring agency with contact details
                            </th>
                            <th>Revenue generated (INR in Lakhs)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria03_352?.Uni_Consult}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Project1}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Agency1}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Revenue}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>
                              Names of the teacher-consultants/corporate
                              trainers
                            </th>
                            <th>Title of the corporate training program</th>
                            <th>
                              Agency seeking training with contact details
                            </th>
                            <th>Revenue generated (amount in rupees)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria03_352?.Uni_Consult2}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Title1}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Agency3}</td>
                              <td>{item?.Uni_Criteria03_352?.Uni_Revenue1}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Trainee}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Desc21}</td>
                      <img
                        src={`https://brightnaac.co.in/Uni_Criteria03/${item?.Uni_Criteria03_352?.Uni_Link22}`}
                        alt="img"
                        width={50}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="text-center">
          <button className="ad" style={{}} onClick={createPDF}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PdfPrintUN3_352;
