import React from "react";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Uni_Criteria001 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  //integrating post method for Uni_Cirteria_01
  const [Uni_MainYear1, setUni_MainYear1] = useState("");

  const [Uni_ProCode, setUni_ProCode] = useState("");
  const [Uni_ProName, setUni_ProName] = useState("");
  const [Uni_YoI, setUni_YoI] = useState("");
  const [Uni_StCBCS, setUni_StCBCS] = useState("");
  const [Uni_StEcs, setUni_StEcs] = useState("");
  const [Uni_YICBCS, setUni_YICBCS] = useState("");
  const [Uni_YIEcs, setUni_YIEcs] = useState("");
  const [Uni_YrCBCS, setUni_YrCBCS] = useState("");
  const [Uni_YrEcs, setUni_YrEcs] = useState("");
  const [Uni_IfCBCS, setUni_IfCBCS] = useState("");
  const [Uni_IfEcs, setUni_IfEcs] = useState("");
  const [Uni_LCBCS, setUni_LCBCS] = useState("");
  const [Uni_LEcs, setUni_LEcs] = useState("");
  const [Uni_Desc1, setUni_Desc1] = useState("");
  const [Uni_Link1, setUni_Link1] = useState("");
  const [Uni_Link2, setUni_Link2] = useState("");

  const [Uni_CourCode, setUni_CourCode] = useState("");
  const [Uni_CourName, setUni_CourName] = useState("");
  const [Uni_CourYOfIntro, setUni_CourYOfIntro] = useState("");
  const [Uni_CourActivities, setUni_CourActivities] = useState("");
  // const [Uni_Courlink, setUni_Courlink] = useState("");
  const [Uni_Desc2, setUni_Desc2] = useState("");
  const [Uni_Link3, setUni_Link3] = useState("");

  const [Uni_ValAddCour, setUni_ValAddCour] = useState("");
  const [Uni_CourseCode, setUni_CourseCode] = useState("");
  const [Uni_YOfOffering, setUni_YOfOffering] = useState("");
  const [Uni_NoOfTimes, setUni_NoOfTimes] = useState("");
  const [Uni_CourseDuration, setUni_CourseDuration] = useState("");
  const [Uni_NoOfStudents, setUni_NoOfStudents] = useState("");
  const [Uni_CourseCompletion, setUni_CourseCompletion] = useState("");
  const [Uni_Link4, setUni_Link4] = useState("");
  const [Uni_Desc3, setUni_Desc3] = useState("");

  const [Uni_PPrgmCode, setUni_PPrgmCode] = useState("");
  const [Uni_PrgName2, setUni_PrgName2] = useState("");
  const [Uni_NoofStu, setUni_NoofStu] = useState("");
  // const [Uni_YYOfOffering, setUni_YYOfOffering] = useState("");
  // const [Uni_Relvdoc, setUni_Relvdoc] = useState("");
  const [Uni_Link5, setUni_Link5] = useState("");
  const [Uni_Desc4, setUni_Desc4] = useState("");

  const [Uni_Sem, setUni_Sem] = useState("");
  const [Uni_Feedback, setUni_Feedback] = useState("");
  const [Uni_Link6, setUni_Link6] = useState("");
  const [Uni_Desc5, setUni_Desc5] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getUnicriteria1 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUnicriteria1();
  }, []);

  const Unicriteria1 = async () => {
    if (!Uni_MainYear1) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/Unicriteria001",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Year: Uni_MainYear1,

          Uni_Criteria1_112_122: obj1,
          Uni_Link1: Uni_Link1,
          Uni_Link2: Uni_Link2,

          Uni_Criteria01_113_121: obj2,
          Uni_Link3: Uni_Link3,

          Uni_Criteria01_132_133: obj3,
          Uni_Link4: Uni_Link4,

          Uni_Criteria01_134: obj4,
          Uni_Link5: Uni_Link5,

          Uni_Criteria01_141_142: obj5,
          Uni_Link6: Uni_Link6,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          navigate("/unicriteria01");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Uni_ProCode ||
          !Uni_ProName ||
          !Uni_YoI ||
          !Uni_StCBCS ||
          !Uni_StEcs ||
          !Uni_YICBCS ||
          !Uni_YIEcs ||
          !Uni_YrCBCS ||
          !Uni_YrEcs ||
          !Uni_IfCBCS ||
          !Uni_IfEcs ||
          !Uni_Link1 ||
          !Uni_Link2 ||
          !Uni_Desc1
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
        {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Uni_Link1);
      console.log("nnnnnnnn3,", Uni_Link2);
      let obj = {
        Uni_criteria: "1.1.2 & 1.2.2",
        Uni_ProCode: Uni_ProCode,
        Uni_ProName: Uni_ProName,
        Uni_YoI: Uni_YoI,
        Uni_StCBCS: Uni_StCBCS,
        Uni_StEcs: Uni_StEcs,
        Uni_YICBCS: Uni_YICBCS,
        Uni_YIEcs: Uni_YIEcs,
        Uni_YrCBCS: Uni_YrCBCS,
        Uni_YrEcs: Uni_YrEcs,
        Uni_IfCBCS: Uni_IfCBCS,
        Uni_IfEcs: Uni_IfEcs,

        Uni_Link1: Uni_Link1,
        Uni_Link2: Uni_Link2,
        Uni_Desc1: Uni_Desc1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getUnicriteria1();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !Uni_CourCode ||
          !Uni_CourName ||
          !Uni_CourYOfIntro ||
          !Uni_CourActivities ||
          // !Uni_Courlink ||
          !Uni_Desc2 ||
          !Uni_Link3
        ) {
          return alert("Please fill all the fields ");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }
      console.log("mmmmm,", Uni_Link3);
      let obj = {
        Uni_criteria: "1.1.3 & 1.2.1",
        Uni_CourCode: Uni_CourCode,
        Uni_CourName: Uni_CourName,
        Uni_CourYOfIntro: Uni_CourYOfIntro,
        Uni_CourActivities: Uni_CourActivities,
        // Uni_Courlink: Uni_Courlink,
        Uni_Link3: Uni_Link3,
        Uni_Desc2: Uni_Desc2,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getUnicriteria1();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !Uni_ValAddCour ||
          !Uni_CourseCode ||
          !Uni_YOfOffering ||
          !Uni_NoOfTimes ||
          !Uni_CourseDuration ||
          !Uni_NoOfStudents ||
          !Uni_CourseCompletion ||
          !Uni_Link4 ||
          !Uni_Desc3
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Uni_Link4);
      let obj = {
        Uni_criteria: "1.3.2 & 1.3.3",
        Uni_ValAddCour: Uni_ValAddCour,
        Uni_CourseCode: Uni_CourseCode,
        Uni_YOfOffering: Uni_YOfOffering,
        Uni_NoOfTimes: Uni_NoOfTimes,
        Uni_CourseDuration: Uni_CourseDuration,
        Uni_NoOfStudents: Uni_NoOfStudents,
        Uni_CourseCompletion: Uni_CourseCompletion,
        Uni_Link4: Uni_Link4,
        Uni_Desc3: Uni_Desc3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getUnicriteria1();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Uni_PPrgmCode ||
          !Uni_PrgName2 ||
          !Uni_NoofStu ||
          // !Uni_Relvdoc ||
          !Uni_Desc4 ||
          !Uni_Link5
        ) {
          return alert("Please fill all the fields ");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Uni_Link5);
      let obj = {
        Uni_criteria: "1.3.4",
        Uni_PPrgmCode: Uni_PPrgmCode,
        Uni_PrgName2: Uni_PrgName2,
        Uni_NoofStu: Uni_NoofStu,
        // Uni_Relvdoc: Uni_Relvdoc,
        Uni_Desc4: Uni_Desc4,
        Uni_Link5: Uni_Link5,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getUnicriteria1();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Uni_Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (!Uni_Sem || !Uni_Feedback || !Uni_Link6 || !Uni_Desc5) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("qqqq,", Uni_Link6);
      let obj = {
        Uni_criteria: "1.4.1 & 1.4.2",
        Uni_Sem: Uni_Sem,
        Uni_Feedback: Uni_Feedback,
        Uni_Link6: Uni_Link6,
        Uni_Desc5: Uni_Desc5,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getUnicriteria1();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            University_Criteria-01
          </span>
        </div>
        <div className="container">
          <div
            className="row-fluid border bs"
            style={{
              padding: "30px",
            }}
          >
            <div class=" col-md-6">
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Uni_MainYear1 ? (
                <p>{obj1?.Uni_MainYear1}</p>
              ) : (
                <select
                  className="form-select form-select-sm vi_0"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setUni_MainYear1(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_1 (1.1.2 & 1.2.2)</h5>
            <div className="row">
              <div className="col-md-4 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Code
                </label>
                {obj1?.Uni_ProCode ? (
                  <p>{obj1?.Uni_ProCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_ProCode(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program name
                </label>
                {obj1?.Uni_ProName ? (
                  <p>{obj1?.Uni_ProName}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setUni_ProName(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="BCA">BCA</option>
                    <option value="BBA">BBA</option>
                    <option value="B.Com">B.Com</option>
                    <option value="BSC">BSC</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of Introduction
                </label>
                {obj1?.Uni_YoI ? (
                  <p>{obj1?.Uni_YoI}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_YoI(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline">
              Status of implementation of CBCS / Elective Course System (ECS)
            </h6>
            <div className="row ">
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  CBCS
                </label>
                {obj1?.Uni_StCBCS ? (
                  <p>{obj1?.Uni_StCBCS}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => setUni_StCBCS(e.target.value)}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  ECS
                </label>
                {obj1?.Uni_StEcs ? (
                  <p>{obj1?.Uni_StEcs}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => setUni_StEcs(e.target.value)}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline">
              Year of implemetation of CBCS / Elective Course System (ECS)
            </h6>
            <div className="row">
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  CBCS
                </label>
                {obj1?.Uni_YICBCS ? (
                  <p>{obj1?.Uni_YICBCS}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_YICBCS(e.target.value);
                    }}
                  />
                )}
              </div>
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  ECS
                </label>
                {obj1?.Uni_YIEcs ? (
                  <p>{obj1?.Uni_YIEcs}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_YIEcs(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline">
              Year of revision (if any)
            </h6>
            <div className="row">
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  CBCS
                </label>
                {obj1?.Uni_YrCBCS ? (
                  <p>{obj1?.Uni_YrCBCS}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_YrCBCS(e.target.value);
                    }}
                  />
                )}
              </div>
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  ECS
                </label>
                {obj1?.Uni_YrEcs ? (
                  <p>{obj1?.Uni_YrEcs}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_YrEcs(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline">
              If revision has been carried out in the syllabus during last 5
              years, Percentage of content added or replaced
            </h6>
            <div className="row">
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  CBCS
                </label>
                {obj1?.Uni_IfCBCS ? (
                  <p>{obj1?.Uni_IfCBCS}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_IfCBCS(e.target.value);
                    }}
                  />
                )}
              </div>
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  ECS
                </label>
                {obj1?.Uni_IfEcs ? (
                  <p>{obj1?.Uni_IfEcs}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_IfEcs(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline">
              Link to the relevant documents
            </h6>
            <div className="row ">
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  CBCS
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="fname"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setUni_Link1(e.target.files[0])}
                />
              </div>
              <div class=" col-md-6 mb-3">
                <label for="" class="form-label">
                  ECS
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="fname"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => setUni_Link2(e.target.files[0])}
                />
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj1?.Uni_Desc1 ? (
                <p>{obj1?.Uni_Desc1}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  // cols={57}
                  rows={3}
                  onChange={(e) => setUni_Desc1(e.target.value)}
                ></textarea>
              )}
            </div>

            <div className="text-end mt-3">
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button className="btn btn-warning " onClick={handleShow}>
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal show={show} onHide={handleClose} size="">
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_1 (1.1.2 & 1.2.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_ProCode}
                          onChange={(e) => {
                            setUni_ProCode(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_ProCode}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program name
                    </label>

                    {edit1 ? (
                      <>
                        <select
                          className="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          placeholder={Uni_ProName}
                          style={{ width: "100%" }}
                          onChange={(e) => setUni_ProName(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="BCA">BCA</option>
                          <option value="BBA">BBA</option>
                          <option value="B.Com">B.Com</option>
                          <option value="BSC">BSC</option>
                        </select>
                      </>
                    ) : (
                      <>{Uni_ProName}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of Introduction
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_YoI}
                          onChange={(e) => setUni_YoI(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_YoI}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  Status of implementation of CBCS / Elective Course System
                  (ECS)
                </h6>
                <div className="row ">
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      CBCS
                    </label>

                    {edit1 ? (
                      <>
                        <select
                          className="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          placeholder={Uni_StCBCS}
                          onChange={(e) => setUni_StCBCS(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </>
                    ) : (
                      <>{Uni_StCBCS}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      ECS
                    </label>

                    {edit1 ? (
                      <>
                        <select
                          className="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          placeholder={Uni_StEcs}
                          onChange={(e) => setUni_StEcs(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </>
                    ) : (
                      <>{Uni_StEcs}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  Year of implemetation of CBCS / Elective Course System (ECS)
                </h6>
                <div className="row d-flex">
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      CBCS
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_YICBCS}
                          onChange={(e) => setUni_YICBCS(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_YICBCS}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      ECS
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_YIEcs}
                          onChange={(e) => setUni_YIEcs(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_YIEcs}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  Year of revision (if any)
                </h6>
                <div className="row d-flex">
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      CBCS
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_YrCBCS}
                          onChange={(e) => setUni_YrCBCS(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_YrCBCS}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      ECS
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_YrEcs}
                          onChange={(e) => setUni_YrEcs(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_YrEcs}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  If revision has been carried out in the syllabus during last 5
                  years, Percentage of content added or replaced
                </h6>
                <div className="row d-flex">
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      CBCS
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_IfCBCS}
                          onChange={(e) => setUni_IfCBCS(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_IfCBCS}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      ECS
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_IfEcs}
                          onChange={(e) => setUni_IfEcs(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_IfEcs}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  Link to the relevant documents
                </h6>
                <div className="row ">
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      CBCS
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload1"
                          id="upload1"
                          accept="image/*"
                          placeholder={Uni_Link1}
                          onChange={(e) => setUni_Link1(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_Link1?.name}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label for="" class="form-label">
                      ECS
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload2"
                          id="upload1"
                          accept="image/*"
                          placeholder={Uni_Link2}
                          onChange={(e) => setUni_Link2(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_Link2?.name}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description
                    </label>

                    {edit1 ? (
                      <>
                        <textarea
                          className="vi_0"
                          id="name"
                          // cols={57}
                          rows={3}
                          placeholder={Uni_Desc1}
                          onChange={(e) => setUni_Desc1(e.target.value)}
                        ></textarea>
                      </>
                    ) : (
                      <>{Uni_Desc1}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat1();
                    handleClose();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_1 (1.1.3 & 1.2.1)</h5>
            <div className="row">
              <div className="col-md-4 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Course Code
                </label>

                {obj2?.Uni_CourCode ? (
                  <p>{obj2?.Uni_CourCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_CourCode(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Course
                </label>
                {obj2?.Uni_CourName ? (
                  <p>{obj2?.Uni_CourName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_CourName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of introduction
                </label>
                {obj2?.Uni_CourYOfIntro ? (
                  <p>{obj2?.Uni_CourYOfIntro}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_CourYOfIntro(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Activities/Content with direct bearing on Employability/
                  Entrepreneurship/ Skill development
                </label>
                {obj2?.Uni_CourActivities ? (
                  <p>{obj2?.Uni_CourActivities}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_CourActivities(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>

                {obj2?.Uni_Desc2 ? (
                  <p>{obj2?.Uni_Desc2}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc2(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Link to the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="upload3"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link3(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="text-end mt-3 ">
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button className="btn btn-warning" onClick={handleShow1}>
                    Preview
                  </button>
                </>
              )}
            </div>

            <Modal show={show1} onHide={handleClose1} size="">
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_1 (1.1.3 & 1.2.1)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Course Code
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_CourCode}
                          onChange={(e) => {
                            setUni_CourCode(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_CourCode}</>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of introduction
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_CourYOfIntro}
                          onChange={(e) => {
                            setUni_CourYOfIntro(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_CourYOfIntro}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Course
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_CourName}
                          onChange={(e) => {
                            setUni_CourName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_CourName}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Activities/Content with direct bearing on Employability/
                      Entrepreneurship/ Skill development
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_CourActivities}
                          onChange={(e) => {
                            setUni_CourActivities(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_CourActivities}</>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description
                    </label>

                    {edit2 ? (
                      <>
                        <textarea
                          className="vi_0"
                          id="name"
                          // cols={57}
                          rows={3}
                          placeholder={Uni_Desc2}
                          onChange={(e) => setUni_Desc2(e.target.value)}
                        ></textarea>
                      </>
                    ) : (
                      <>{Uni_Desc2}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Link to the relevant document
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          id="upload3"
                          accept="image/*"
                          onChange={(e) => {
                            setUni_Link3(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_Link3?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit2(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat2();
                    handleClose1();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_1 (1.3.2 & 1.3.3)</h5>
            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the value added courses offered (with 30 or more
                  contact hours)
                </label>
                {obj3?.Uni_ValAddCour ? (
                  <p>{obj3?.Uni_ValAddCour}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setUni_ValAddCour(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Course Code (if any)
                </label>
                {obj3?.Uni_CourseCode ? (
                  <p>{obj3?.Uni_CourseCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setUni_CourseCode(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of offering
                </label>
                {obj3?.Uni_YOfOffering ? (
                  <p>{obj3?.Uni_YOfOffering}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_YOfOffering(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  No. of times offered during the same year
                </label>
                {obj3?.Uni_NoOfTimes ? (
                  <p>{obj3?.Uni_NoOfTimes}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_NoOfTimes(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Duration of the course
                </label>
                {obj3?.Uni_CourseDuration ? (
                  <p>{obj3?.Uni_CourseDuration}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_CourseDuration(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students enrolled in the year
                </label>
                {obj3?.Uni_NoOfStudents ? (
                  <p>{obj3?.Uni_NoOfStudents}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_NoOfStudents(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of Students completing the course in the year
                </label>
                {obj3?.Uni_CourseCompletion ? (
                  <p>{obj3?.Uni_CourseCompletion}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setUni_CourseCompletion(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj3?.Uni_Desc3 ? (
                  <p>{obj3?.Uni_Desc3}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setUni_Desc3(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  id="upload4"
                  accept="image/*"
                  onChange={(e) => {
                    setUni_Link4(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="text-end mt-4">
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button className="btn btn-warning" onClick={handleShow2}>
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal show={show2} onHide={handleClose2} size="">
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_1 (1.3.2 & 1.3.3)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the value added courses offered (with 30 or more
                      contact hours)
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Uni_ValAddCour}
                          onChange={(e) => {
                            setUni_ValAddCour(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_ValAddCour}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Course Code (if any)
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_CourseCode}
                          onChange={(e) => {
                            setUni_CourseCode(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_CourseCode}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of offering
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_YOfOffering}
                          onChange={(e) => {
                            setUni_YOfOffering(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_YOfOffering}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      No. of times offered during the same year
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_NoOfTimes}
                          onChange={(e) => {
                            setUni_NoOfTimes(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_NoOfTimes}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Duration of the course
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_CourseDuration}
                          onChange={(e) => {
                            setUni_CourseDuration(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_CourseDuration}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students enrolled in the year
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_NoOfStudents}
                          onChange={(e) => {
                            setUni_NoOfStudents(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_NoOfStudents}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of Students completing the course in the year
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Uni_CourseCompletion}
                          onChange={(e) => {
                            setUni_CourseCompletion(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_CourseCompletion}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description
                    </label>

                    {edit3 ? (
                      <>
                        <textarea
                          className="vi_0"
                          id="name"
                          // cols={57}
                          rows={3}
                          placeholder={Uni_Desc3}
                          onChange={(e) => {
                            setUni_Desc3(e.target.value);
                          }}
                        ></textarea>
                      </>
                    ) : (
                      <>{Uni_Desc3}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="upload1"
                          accept="image/*"
                          placeholder={Uni_Link4}
                          onChange={(e) => setUni_Link4(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_Link4?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit3(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat3();
                    handleClose2();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_1 (1.3.4)</h5>
            <div className="row">
              <div className="col-md-4 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program Code
                </label>
                {obj4?.Uni_PPrgmCode ? (
                  <p>{obj4?.Uni_PPrgmCode}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    placeholder={Uni_PPrgmCode}
                    onChange={(e) => setUni_PPrgmCode(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Program name
                </label>
                {obj4?.Uni_PrgName2 ? (
                  <p>{obj4?.Uni_PrgName2}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setUni_PrgName2(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="BCA">BCA</option>
                    <option value="BBA">BBA</option>
                    <option value="B.Com">B.Com</option>
                    <option value="BSC">BSC</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the students
                </label>
                {obj4?.Uni_NoofStu ? (
                  <p>{obj4?.Uni_NoofStu}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => setUni_NoofStu(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj4?.Uni_Desc4 ? (
                <p>{obj4?.Uni_Desc4}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  // cols={57}
                  rows={3}
                  placeholder={Uni_Desc4}
                  onChange={(e) => setUni_Desc4(e.target.value)}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3 mb-3">
              <label className="content1" htmlFor="name">
                {" "}
                Link to the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="fname"
                id="upload1"
                accept="image/*"
                // placeholder={Uni_Relvdoc}
                onChange={(e) => setUni_Link5(e.target.files[0])}
              />
            </div>
            <div className="text-end  mt-3">
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button className="btn btn-warning" onClick={handleShow3}>
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal show={show3} onHide={handleClose3} size="">
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_1 (1.3.4)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_PPrgmCode}
                          onClick={(e) => setUni_PPrgmCode(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_PPrgmCode}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program name
                    </label>

                    {edit4 ? (
                      <>
                        <select
                          className="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={Uni_PrgName2}
                          onChange={(e) => setUni_PrgName2(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="BCA">BCA</option>
                          <option value="BBA">BBA</option>
                          <option value="B.Com">B.Com</option>
                          <option value="BSC">BSC</option>
                        </select>
                      </>
                    ) : (
                      <>{Uni_PrgName2}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the students
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Uni_NoofStu}
                          onClick={(e) => setUni_NoofStu(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Uni_NoofStu}</>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description
                    </label>

                    {edit4 ? (
                      <>
                        <textarea
                          className="vi_0"
                          id="name"
                          // cols={57}
                          rows={3}
                          placeholder={Uni_Desc4}
                          onClick={(e) => setUni_Desc4(e.target.value)}
                        ></textarea>
                      </>
                    ) : (
                      <>{Uni_Desc4}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Document
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="fname"
                          id="name"
                          placeholder={Uni_Link5}
                          onChange={(e) => setUni_Link5(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Uni_Link5?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit4(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat4();
                    handleClose3();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_1 (1.4.1 & 1.4.2)</h5>

            <div class=" col-md-6 mb-3">
              <label for="" class="form-label">
                Select Semester
              </label>
              {obj5?.Uni_Sem ? (
                <p>{obj5?.Uni_Sem}</p>
              ) : (
                <select
                  className="form-select form-select-sm vi_0"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setUni_Sem(e.target.value)}
                >
                  <option selected>--Select--</option>
                  <option value="Semester-01">Semester-01</option>
                  <option value="Semester-02">Semester-02</option>
                  <option value="Semester-03">Semester-03</option>
                  <option value="Semester-04">Semester-04</option>
                  <option value="Semester-05">Semester-05</option>
                  <option value="Semester-06">Semester-06</option>
                </select>
              )}
            </div>

            <label htmlFor="">
              <h6>
                {" "}
                Feedback processes of the institution may be classified as
                follows:
              </h6>
            </label>

            <label htmlFor="">
              <p>
                {" "}
                A. Feedback collected, analysed and action taken and feedback
                available on website
              </p>
              <p>B. Feedback collected, analysed and action has been taken </p>

              <p> C. Feedback collected and analysed</p>
              <p> D. Feedback collected</p>
              <p> E. Feedback not collected</p>
            </label>

            {obj5?.Uni_Feedback ? (
              <p>{obj5?.Uni_Feedback}</p>
            ) : (
              <>
                <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                  <input
                    type="radio"
                    value="Any 4 of the above"
                    name="one"
                    onChange={(e) => setUni_Feedback(e.target.value)}
                  />
                  <label htmlFor="">Any 4 of the above</label>
                </div>
                <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                  <input
                    type="radio"
                    value="Any 3 of the above"
                    name="one"
                    onChange={(e) => setUni_Feedback(e.target.value)}
                  />
                  <label htmlFor="">Any 3 of the above</label>
                </div>

                <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                  <input
                    type="radio"
                    value="Any 2 of the above"
                    name="one"
                    onChange={(e) => setUni_Feedback(e.target.value)}
                  />
                  <label htmlFor="">Any 2 of the above</label>
                </div>

                <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                  <input
                    type="radio"
                    value="Any 1 of the above"
                    name="one"
                    onChange={(e) => setUni_Feedback(e.target.value)}
                  />
                  <label htmlFor="">Any 1 of the above</label>
                </div>

                <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                  <input
                    type="radio"
                    value="None of the above"
                    name="one"
                    onChange={(e) => setUni_Feedback(e.target.value)}
                  />
                  <label htmlFor="">None of the above</label>
                </div>
              </>
            )}

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj5?.Uni_Desc5 ? (
                <p>{obj5?.Uni_Desc5}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  // cols={57}
                  rows={3}
                  placeholder={Uni_Desc5}
                  onChange={(e) => setUni_Desc5(e.target.value)}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload1"
                accept="image/*"
                onChange={(e) => {
                  setUni_Link6(e.target.files[0]);
                }}
              />
            </div>

            <div className="text-end mt-3">
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={() => {
                    cat5();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow4}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal show={show4} onHide={handleClose4} size="">
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria_1 (1.4.1 & 1.4.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div class=" col-md-12 mb-3">
                    <label for="" class="form-label">
                      Select Semester
                    </label>

                    {edit5 ? (
                      <>
                        <select
                          className="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          placeholder={Uni_Sem}
                          onChange={(e) => {
                            setUni_Sem(e.target.value);
                          }}
                        >
                          <option selected>--Select--</option>
                          <option value="Semester-01">Semester-01</option>
                          <option value="Semester-02">Semester-02</option>
                          <option value="Semester-03">Semester-03</option>
                          <option value="Semester-04">Semester-04</option>
                          <option value="Semester-05">Semester-05</option>
                          <option value="Semester-06">Semester-06</option>
                        </select>
                      </>
                    ) : (
                      <>{Uni_Sem}</>
                    )}
                  </div>

                  <label htmlFor="">
                    <h6>
                      {" "}
                      Feedback processes of the institution may be classified as
                      follows:
                    </h6>
                  </label>

                  <label htmlFor="">
                    <p>
                      {" "}
                      A. Feedback collected, analysed and action taken and
                      feedback available on website
                    </p>
                    <p>
                      B. Feedback collected, analysed and action has been taken{" "}
                    </p>

                    <p> C. Feedback collected and analysed</p>
                    <p> D. Feedback collected</p>
                    <p> E. Feedback not collected</p>
                  </label>
                  {edit5 ? (
                    <>
                      <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                        <input
                          type="radio"
                          value="Any 4 of the above"
                          name="one"
                          onChange={(e) => setUni_Feedback(e.target.value)}
                        />
                        <label htmlFor="">Any 4 of the above</label>
                      </div>
                      <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                        <input
                          type="radio"
                          value="Any 3 of the above"
                          name="one"
                          onChange={(e) => setUni_Feedback(e.target.value)}
                        />
                        <label htmlFor="">Any 3 of the above</label>
                      </div>

                      <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                        <input
                          type="radio"
                          value="Any 2 of the above"
                          name="one"
                          onChange={(e) => setUni_Feedback(e.target.value)}
                        />
                        <label htmlFor="">Any 2 of the above</label>
                      </div>

                      <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                        <input
                          type="radio"
                          value="Any 1 of the above"
                          name="one"
                          onChange={(e) => setUni_Feedback(e.target.value)}
                        />
                        <label htmlFor="">Any 1 of the above</label>
                      </div>

                      <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                        <input
                          type="radio"
                          value="None of the above"
                          name="one"
                          onChange={(e) => setUni_Feedback(e.target.value)}
                        />
                        <label htmlFor="">None of the above</label>
                      </div>
                    </>
                  ) : (
                    <>{Uni_Feedback}</>
                  )}

                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description
                    </label>

                    {edit5 ? (
                      <>
                        <textarea
                          className="vi_0"
                          id="name"
                          // cols={57}
                          rows={3}
                          placeholder={Uni_Desc5}
                          onChange={(e) => setUni_Desc5(e.target.value)}
                        ></textarea>
                      </>
                    ) : (
                      <>{Uni_Desc5}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload5"
                          id="upload1"
                          accept="image/*"
                          placeholder={Uni_Link5}
                          onChange={(e) => {
                            setUni_Link6(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{Uni_Link5?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit5(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat5();
                    handleClose4();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div className="text-center mt-3 mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => Unicriteria1()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Uni_Criteria001;
