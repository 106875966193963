import axios from "axios";
import React, { useEffect, useState } from "react";
import UG1133aqar from "./UG1133aqar";
import UG1132aqar from "./UG1132aqar";
import UG1122aqar from "./UG1122aqar";
import UG1121aqar from "./UG1121aqar";
import UG1113aqar from "./UG1113aqar";
import { useNavigate } from "react-router-dom";
const UGC1aqar = React.memo(({ startYeardata, endYeardata }) => {
  const navigate = useNavigate();
  const [AllDetails, setAllDetails] = useState([]);
  const ugCriteria01Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria01"
      );
      if (res.status === 200) {
        setAllDetails(
          res.data.ugcriteria01?.filter(
            (item) =>
              item?.Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.ugcriteria01?.filter(
          (item) =>
            item?.Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          alert("No data available");
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria01);
    }
  };

  useEffect(() => {
    ugCriteria01Details();
  }, [startYeardata, endYeardata]);

  console.log("changingDeta: ", startYeardata, endYeardata);

  return (
    <div>
      <div className="text-center">
        <b>Criteria 1</b>
      </div>
      {AllDetails?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.1.3)
            </div>
            <UG1113aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.2.1)
            </div>
            <UG1121aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.2.2 & 1.2.3)
            </div>
            <UG1122aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.3.2)
            </div>
            <UG1132aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 1 (1.3.3)
            </div>
            <UG1133aqar item={item} />
          </div>
        );
      })}
    </div>
  );
});

export default UGC1aqar;
