import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Container } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Resources = () => {
  const location = useLocation();
  const { id } = location?.state;
  // =================== calling api for getting the Leadership content======================
  const [libList, setlibList] = useState([]);
  const getLibrList = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getAdminLibr"
      );
      if (res.status === 200) {
        setlibList(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setlibList(error.response.data.allContent);
    }
  };

  // =================== calling api for getting the publication content======================
  const [publicationList, setpublicationList] = useState([]);
  const getPulicationList = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getAdminpub"
      );
      if (res.status === 200) {
        setpublicationList(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setpublicationList(error.response.data.allContent);
    }
  };

  useEffect(() => {
    AOS.init({ duration: "2000" });
    getLibrList();
    getPulicationList();
  }, []);

  useEffect(() => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [id]);

  return (
    <div>
      <Carousel variant="dark">
        {libList[0]?.imgs?.map((item) => {
          return (
            <Carousel.Item>
              <img
                className="d-block w-100 "
                src={`https://brightnaac.co.in/AdminLibr/${item?.imgName}`}
                alt="First slide"
                style={{ height: "350px" }}
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <section id="LIRC1">
        <Container>
          <div className="content">
            <h4 className="text-center">{libList[0]?.heading}</h4>
            <p className="educat">{libList[0]?.description}</p>
          </div>
        </Container>
      </section>
      <div class="container" id="PUB1">
        <h3 style={{ textAlign: "center" }}>Publications</h3>
        {publicationList?.map((item) => {
          return (
            <div
              class="row grayBg borderRadiousAll "
              style={{
                backgroundColor: "#c0d0d04b",
                padding: "20px",
                borderRadius: "10px",
                margin: "10px",
              }}
              data-aos="fade-up"
              data-aos-offset="200"
            >
              <div
                class="col-12 col-md-2 padding20"
                style={{ padding: "20px" }}
              >
                <img
                  src={`https://brightnaac.co.in/AdminPublication/${item?.img}`}
                  alt=""
                  class="img-fluid"
                />
              </div>
              <div class="col-12 col-md-10 padding20">
                <p style={{ padding: "20px" }}>
                  <b>{item?.heading}</b>
                </p>
                <ul class="ulPadding">
                  {item?.linksAvail?.map((val) => {
                    return (
                      <li>
                        <a
                          id="anchor"
                          // href="/docs/Promotional%20Materials/Vision_Mission_Value.pdf"
                          href={val?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {val?.linkName}
                        </a>
                      </li>
                    );
                  })}
                  {/* <li>
                <a
                  id="anchor"
                  href="/images/docs/NAAC Brochure_2018.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NAAC Brochure-2018
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Publication/NAAC Broucher January 2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NAAC Brochure-2019
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Publication/Revised_NAAC_Brochure.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NAAC Brochure-2021
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Publication/NAAC_FINAL_BROCHURE_Hindi_12072022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NAAC Brochure (Hindi){" "}
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Publication/NAAC Compendium.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NAAC Compendium
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Promotional%20Materials/Microsoft%20Word%20-%20Grading%20_System_%20NAAC.docx.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Grading System
                </a>
              </li>
              <li>
                <a href="/!#" id="anchor" target="_blank">
                  Frequently Asked Questions
                </a>
              </li> */}
                </ul>
              </div>
            </div>
          );
        })}
        {/* <p></p>
        <div
          class="row grayBg anchorMargin borderRadiousAll mt-5"
          style={{
            backgroundColor: "#c0d0d04b",
            padding: "20px",
            borderRadius: "10px",
          }}
          data-aos="fade-up"
        >
          <div class="col-12 col-md-2 padding20" style={{ padding: "20px" }}>
            <img src="/Image/guideline.png" alt="" class="img-fluid" />
          </div>
          <div class="col-12 col-md-10 padding20">
            <p style={{ padding: "20px" }}>
              <b>Guidelines</b>
            </p>
            <ul class="ulPadding">
              <li>
                <img src="/Image/new.gif" alt="" width="36" height="20" />
                <a
                  id="anchor"
                  href="/images/docs/Guidelines/Guidelines-to-Peer-Team-Members-01-04-2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guidelines Peer Team (Assessors){" "}
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/index.php/en/resources/19-quick-links/84-guidelines-and-intent-appeal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guidelines for Grievance Redressal (Appeals) and Intent for
                  Appeal{" "}
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Guidelines/Guidelines_Institutions.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Institutions
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Guidelines/Guidelines%20For%20Peer%20Team%20SEPT%202016.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Peer Team
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/index.php/en/resources/2-uncategorised/83-aqar-online-submission"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AQAR Online Submission (December 2018)
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Guidelines/IQAC-2017-revised%20%286%29.doc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Creation of the Internal Quality Assurance Cell (IQAC) in
                  Accredited Institutions
                </a>
                <span style={{ color: "#ff0000" }}></span>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Guidelines/Guidelines-Colleges-Library.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Quality Indicators in Library and Information Services:
                  Affiliated/Constituent Colleges
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Guidelines/Library-Guidelines-Universities.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Quality Indicators in Library and Information Services:
                  Universities / Autonomous Colleges
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Guidelines/NAAC%20Guidelines%20Financial%20June.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guidelines for Financial Assistance for Assessment and
                  Accreditation of Higher Education Institutions
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Guidelines/Guidelines%20on%20%20reassessment%204%20July%20%20%202017.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guidelines on reassessment
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Guidelines/Guidelines%20on%20complaints%20handling%20mechanism%20%202nd%20June%20%202017.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guidelines on complaints handling mechanism
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p></p>
        <div
          class="row grayBg anchorMargin  borderRadiousAll mt-5"
          style={{
            backgroundColor: "#c0d0d04b",
            padding: "20px",
            borderRadius: "10px",
          }}
          data-aos="fade-up"
        >
          <div class="col-12 col-md-2 padding20" style={{ padding: "20px" }}>
            <img src="/Image/accredition.png" alt="" class="img-fluid" />
          </div>
          <div class="col-12 col-md-10 padding20">
            <p style={{ padding: "20px" }}>
              <b>Analysis of Accreditation Reports (State-wise)</b>
            </p>
            <div class="row">
              <div class="col-12 col-md-4">
                <ul class="ulPadding">
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/StateWise%20Analysis%20of%20Accreditaiton%20Reports%20-AP.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Andhra Pradesh
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/State%20wise%20Analysis%20Report%20of%20Haryana.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Haryana
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/Karnataka Book ISBN number.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Karnataka
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/Analysis-Kerala.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kerala
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/images/docs/Publication/State_wise_AAR_Uttar_Pradesh_ISBN_number.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Uttar Pradesh
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/images/docs/Publication/Uttar_Pradesh_Hindi_Book.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Uttar Pradesh Hindi Book{" "}
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/images/docs/Publication/State_Level_Analysis_of_Himachal_Pradesh_2672022.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Himachal Pradesh
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-4">
                <ul class="ulPadding">
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/State-wise-Analysis-MadhyaPadesh.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Madhya Pradesh
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/images/docs/Publication/State_Wise_Analysis_of_Accreditation_Report_Madhya_Pradesh_17-03-2023.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Madhya Pradesh
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/State_Level_Accreditation_Maharstra_Report.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Maharashtra
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/Quality%20Assesement%20Analysis%20in_N_East.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      North East
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/Analysis_Punjab.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Punjab
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/State_Level_Accredited_HEI_J_K_&amp;_Ladakh.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      J K &amp; Ladakh
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/State_Level_Accredited_HEI_Uttarkhand_Book_12072022.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Uttarkhand
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/images/docs/Publication/State_Level_Analysis_of_Accredited_HEIs_Ins_Gujarat_1-12-2022.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gujarat
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-4">
                <ul class="ulPadding">
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/Analysis-Rajasthan.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Rajasthan
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/Analysis-Tamilnadu.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tamil Nadu
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/Analysis-West_Bengal.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      West Bengal
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/NAAC-Gujurat-SWA.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gujarat
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/State-wise%20Analysis%20of%20Accreditation%20Report-Odhisha.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Odisha
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/docs/Analysis%20of%20Accreditation%20Reports/Telangana_Book.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Telangana
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/images/docs/Publication/State_Wise_Analysis_of_Accreditation_Report_Jharkhand_17-03-2023.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Jharkhand
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <p><a id="practices"></a></p> */}
        {/* <div
          class="row grayBg anchorMargin borderRadiousAll mt-5"
          style={{
            backgroundColor: "#c0d0d04b",
            padding: "20px",
            borderRadius: "10px",
          }}
          data-aos="fade-up"
        >
          <div class="col-12 col-md-2 padding20" style={{ padding: "20px" }}>
            <img src="/Image/bestpractices.png" alt="" class="img-fluid" />
          </div>
          <div class="col-12 col-md-10 padding20">
            <p style={{ padding: "20px" }}>
              <b>Best Practices</b>
            </p>
            <ul class="ulPadding">
              <li>
                <img src="/Image/new.gif" alt="" width="36" height="20" />
                <a
                  id="anchor"
                  href="/Image/docs/Publication/Best_Practice_series_student_admission_process_ISBN.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Student admission Process : Case Presentation
                </a>
              </li>
              <li>
                <img src="/Image/new.gif" alt="" width="36" height="20" />
                <a
                  id="anchor"
                  href="/Image/docs/Publication/EQUAM-BI-NAAC-Project-Document.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Internal Quality Assurance Mechanism: Good Practices of
                  Partner Institutions from Europe and India
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Best%20Practises%20in%20Curricular%20Aspects.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Curricular Aspects
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Best%20Practise%20in%20Community%20Engagement.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Community Engagement-Case Studies
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Evaluation%20of%20Students%20-%20Case%20Presentations.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Evaluation of Students - Case Presentations
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Best%20Practise%20in%20Higher%20Education.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Higher Education
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Best%20Practise%20in%20Quality%20Management%20in%20Higher%20Education.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Higher Education for Quality Management
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Best%20Practises%20Internal%20Quality%20Assurance%20Cell%20Activities.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Internal Quality Assurance Cell Activities
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Best%20Practices%20in%20%20Library%20and%20Information%20Services%20A%20Case%20Presentation.doc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Library and Information Services: A Case Presentation
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Student%20Feedback%20and%20Participation.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Students Feedback and Participation - Case Studies
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Students%20Participation%20in%20Quality%20Enhancement.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Students Participation in Quality Enhancement
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Best%20Practices/Best%20Practices%20in%20Teacher%20Education.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Teacher Education
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Publication/Best_Practices_Management_Practices_ISBN.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Management Practices: Case Presentations
                </a>
              </li>
            </ul>
          </div>
        </div> */}
        {/* <p><a id="QualityFactsheets"></a></p> */}
        {/*<div
          class="row grayBg anchorMargin  borderRadiousAll mt-5"
          style={{
            backgroundColor: "#c0d0d04b",
            padding: "20px",
            borderRadius: "10px",
          }}
          data-aos="fade-up"
        >
          <div class="col-12 col-md-2 padding20" style={{ padding: "20px" }}>
            <img src="/Image/accredition.png" alt="" class="img-fluid" />
          </div>
          <div class="col-12 col-md-10 padding20">
            <p style={{ padding: "20px" }}>
              <b>Quality Fact Sheets and Recommendations(State-wise)</b>
            </p>
            <div class="row">
              <div class="col-12 col-md-4">
                <ul class="ulPadding">
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/docs/Qualityfactsheet/andhra-pradesh.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Andhra Pradesh
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/docs/Qualityfactsheet/Odisha_quality_fact_sheet.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Odisha
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/Image/docs/QuailityFactSheet/QuailityFactSheet_Haryana_with_ISBN_13092022.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Haryana
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/Image/docs/QuailityFactSheet/Uttarakhand_ISBN_QFS_31-1-2023.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Uttarakhand
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/images/docs/QuailityFactSheet/Quality_Fact_Sheet_and_Recommendations_Jharkhand_17-03-2023.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Jharkhand
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-4">&nbsp;</div>
              <div class="col-12 col-md-4">&nbsp;</div>
            </div>
          </div>
        </div>
        <p></p>
        <div
          class="row grayBg anchorMargin borderRadiousAll mt-5"
          style={{
            backgroundColor: "#c0d0d04b",
            padding: "20px",
            borderRadius: "10px",
          }}
          data-aos="fade-up"
        >
          <div class="col-12 col-md-2 padding20" style={{ padding: "20px" }}>
            <img src="/Image/books.png" alt="" class="img-fluid" />
          </div>
          <div class="col-12 col-md-10 padding20">
            <p style={{ padding: "20px" }}>
              <b>Books</b>
            </p>
            <ul class="ulPadding">
              <li>
                <img src="/Image/new.gif" alt="" width="36" height="20" />
                <a
                  id="anchor"
                  href="/Image/docs/Publication/books/NAAC_Role_of_Indian_Higher_Education1_FINAL_2_31-1-2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Innovations in Quality Assurance: Role of Indian Higher
                  Education Institutions
                </a>
              </li>
              <li>
                <img src="/Image/new.gif" alt="" width="36" height="20" />
                <a
                  id="anchor"
                  href="/Image/docs/Publication/books/QMS_IN_HE_1772022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Quality Management System in Higher Education
                </a>
              </li>
              <li>
                <img src="/Image/new.gif" alt="" width="36" height="20" />
                <a
                  id="anchor"
                  href="/Image/docs/Publication/Performance_Analysis_ISBN.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Performance Analysis of Government Colleges
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/Image/docs/Publication/Trend_Analysis_of_Accredited_Institutions_with_ISBN_No.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trend Analysis of Accredited Institution (Based on Revised
                  Accreditation Framework)
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/Accreditation-Outcome.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Accreditation Outcome - Emerging Issues for Policy Planning
                  and Systemic Action
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/APQN.Pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  APQN Conference Proceedings
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/Great%20Institutions%20of%20Higher%20Learning%20-%20Colleges%20in%20India.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Great Institutions in Higher Learning - Colleges in India
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/Great%20Institutions%20of%20Higher%20Learning%20-%20Univeristies%20in%20Indi.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Great Institutions in Higher Learning - Universities in India
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/innovations%20in%20teacher%20education.zip"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Innovation in Teacher Education: International Practices of
                  Quality Assurance
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/International%20Perspectives%20on%20Student%20Participation%20in%20Quality%20Enhancement.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  International Perspectives on Student Participation in Quality
                  Enhancement
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/NAAC%20A%20Decade%20of%20Dedication%20to%20Quality%20Assurance.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NAAC: A Decade of Dedication to Quality Assurance
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/NAAC%20Assessment%20Update.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NAAC: Assessment Update - Gender Sensitive Quality Indicators
                  &amp; Disabled Friendly Environment at Education Campus
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/New%20Assessor%20Hand%20Book-2007.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  New Assessor Hand Book-2007
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/Quality%20Assurance%20Activities%20of%20SLQACs-2008.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Quality Assurance Activities of SLQACs - 2008
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/Quality%20Enhancement%20in%20Teacher%20Education.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Quality Enhancement in Teacher Education
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/Quality%20Higher%20Education%20and%20Sustainable%20Development.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Quality Higher Education and Sustainable Development
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/Total%20Quality%20Management%20for%20Tertiary%20Education.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Total Quality Management for Tertiary Education
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Books/Value%20framework%20for%20Assessment%20of%20HEI%27s.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Value framework for Assessment of HEI's
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Publication/books/Pursuit_of_Quality_in_Higher_Education.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pursuit of Quality in Higher Education: An Indian Perspective
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="row grayBg anchorMargin borderRadiousAll mt-5"
          style={{
            backgroundColor: "#c0d0d04b",
            padding: "20px",
            borderRadius: "10px",
          }}
          data-aos="fade-up"
        >
          <div class="col-12 col-md-2 padding20" style={{ padding: "20px" }}>
            <img src="/Image/toolkit.png" alt="" class="img-fluid" />
          </div>
          <div class="col-12 col-md-10 padding20">
            <p style={{ padding: "20px" }}>
              <b>Toolkit For Teacher Education Institutions</b>
            </p>
            <ul class="ulPadding">
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Resources/Toolkit4Tr_Edctn_Institution/QAHE-Book.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Quality Assurance in Higher Education - An Introduction
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Resources/Toolkit4Tr_Edctn_Institution/An-Anthology-of-Best-Practices-in-Teacher-Education.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  An Anthology of “Best Practices” In Teacher Education
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Resources/Toolkit4Tr_Edctn_Institution/Quality-Assuranc_A-Took-Kit.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Quality Assurance Toolkit For Teacher Education Institutions
                  (QATTEI)
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/images/docs/Resources/Toolkit4Tr_Edctn_Institution/Quality-Indicators-TE.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Indicators of Quality in Teacher Education
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="row grayBg anchorMargin borderRadiousAll mt-5"
          style={{
            backgroundColor: "#c0d0d04b",
            padding: "20px",
            borderRadius: "10px",
          }}
          data-aos="fade-up"
        >
          <div class="col-12 col-md-2 padding20" style={{ padding: "20px" }}>
            <img src="/Image/posters.png" alt="" class="img-fluid" />
          </div>
          <div class="col-12 col-md-10 padding20">
            <p style={{ padding: "20px" }}>
              <b>Posters</b>
            </p>
            <ul class="ulPadding">
              <li>
                <a
                  id="anchor"
                  href="/docs/Posters/Getting%20to%20NAAC.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Getting to NAAC
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Posters/2015-Vision%2C%20Mission%20%26%20Value%20FrameWork.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vision, Mission and Value Framework
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Posters/Seven%20Steps%20to%20Quality.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Seven Steps to Quality
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Posters/2015-IQAC%20poster.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IQAC Poster
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Posters/2015-Ten%20Action%20Points%20for%20IQAC.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ten Action Points for IQAC
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/Posters/2015-Student%20Charter.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Student Charter
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="row grayBg anchorMargin borderRadiousAll mt-5"
          style={{
            backgroundColor: "#c0d0d04b",
            padding: "20px",
            borderRadius: "10px",
          }}
          data-aos="fade-up"
        >
          <div class="col-12 col-md-2 padding20" style={{ padding: "20px" }}>
            <img src="/Image/reports.png" alt="" class="img-fluid" />
          </div>
          <div class="col-12 col-md-10 padding20">
            <p style={{ padding: "20px" }}>
              <b>Annual Reports</b>
            </p>
            <div class="row">
              <div class="col-12 col-md-3">
                <ul class="ulPadding">
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/Annual%20Report%202005-2006.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2005 - 06
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/Annual%20Report%202006-07.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2006 - 07
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/annual%20report%202007-2008.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2007 - 08
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/Annual%20Report%202008-09.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2008 - 09
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/Naac%20Annual%20Report.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2009 - 10
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/Annual%20report%20-%202010-11.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2010 - 11
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/images/docs/annual report NAAC 2011-12.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2011 - 12
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/Annual_Report_NAAC(1-109).pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2012 - 13 Part 1
                    </a>
                    |
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/Annual_Report_NAAC(110-220)_2012-13.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Part 2
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/NAAC%20ANNUAL%20REPORT%202013-14.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2013 - 14
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-3">
                <ul class="ulPadding"></ul>
              </div>
              <div class="col-12 col-md-6">
                <ul class="ulPadding">
                  <li>
                    <a
                      id="anchor"
                      href="/images/docs/AnnualReport/Annual Report 14-15.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2014 - 15
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/Annual_Report_15-16.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2015 - 16
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/docs/Annual%20Reports/Annual Report 2016-17.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2016 - 17
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/images/docs/NAAC ANNUAL Report 17-18.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2017 - 18
                    </a>
                  </li>
                  <li>
                    <a
                      id="anchor"
                      href="/images/docs/AnnualReport/Annual%20Report%2018-19.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2018 - 19
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/Image/docs/AnnualReport/Hindi_Translation_of_Annual_Report_19-20 .pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hindi Version 2019 - 2020
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/Image/docs/AnnualReport/NAAC_Annual_report_2019-2020en.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2019 - 20
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/Image/docs/Manuals/Annual_Report_Book_2020-20221_13092022.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2020 - 21
                    </a>
                  </li>
                  <li>
                    <img src="/Image/new.gif" alt="" width="36" height="20" />
                    <a
                      id="anchor"
                      href="/Image/docs/AnnualReport/Annual_Report_2020-21_Hindi_Version_1-12-2022.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2020 - 21 Hindi Version
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row grayBg anchorMargin borderRadiousAll mt-5"
          style={{
            backgroundColor: "#c0d0d04b",
            padding: "20px",
            borderRadius: "10px",
          }}
          data-aos="fade-up"
        >
          <div class="col-12 col-md-2 padding20" style={{ padding: "20px" }}>
            <img src="/Image/newsletter.png" alt="" class="img-fluid" />
          </div>
          <div class="col-12 col-md-10 padding20">
            <p style={{ padding: "20px" }}>
              <b>NAAC Newsletter</b>
            </p>
            <ul class="ulPadding">
              <li>
                NAAC news letters July 2019 - Vol XIV - Issue 2
                <a
                  id="anchor"
                  href="/Image/docs/SeminarBrochures/NAAC NEWS LETTER_July_2019.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (English version)
                </a>
                <a
                  id="anchor"
                  href="Image/docs/SeminarBrochures/NAAC NEWS LETTER_July_2019_Hindi.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (Hindi version)
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/Image/docs/SeminarBrochures/FINAL NAAC NEWS LETTER_JAN_2019.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  January 2019 - Vol XV - Issue 1
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/NAAC%20Newsletter/NAAC_NEWS_DEC_14.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  December 2014 - Vol XIV - Issue 2
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/NAAC%20Newsletter/NAAC%20Newsletter_High_apr_may_2014.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  April - May 2014 - Vol XIV - Issue 1
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/NAAC%20Newsletter/August%202013%20-%20Vol%20XIII-Issue%201.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  August 2013 - Vol XIII - Issue 1
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/NAAC%20Newsletter/January%202012%20-%20vol%20XII%20-Issue%201.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  January 2012 - Vol XII - Issue 1
                </a>
              </li>
              <li>
                <a
                  id="anchor"
                  href="/docs/NAAC%20Newsletter/July%202011%20NAAC.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  July 2011 - Vol XI - Issue 1 and 2
                </a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
      <div class="preFooter">
        <div class="container">
          <div class="row">
            <div class="col-3 col-md-2 paddingTop10 text-center">
              <a
                href="https://education.gov.in/"
                target="_blank"
                title="education.gov.in"
              >
                <img
                  class="img-fluid"
                  alt="education.gov.in"
                  src="/Image/ministry_of_education.png"
                />
              </a>
            </div>
            <div class="col-3 col-md-2 paddingTop10 text-center">
              <a
                href="https://inflibnet.ac.in/"
                target="_blank"
                title="INFIBNET"
              >
                <img
                  class="img-fluid"
                  alt="INFIBNET"
                  src="/Image/inflibnet-logo.png"
                />
              </a>
            </div>
            <div class="col-3 col-md-2 paddingTop10 text-center">
              <a href="https://ugc.ac.in/" target="_blank" title="ugc.ac.in">
                <img
                  class="img-fluid"
                  alt="ugc.ac.in"
                  src="/Image/ugc_logo.png"
                />
              </a>
            </div>
            <div class="col-3 col-md-2 paddingTop10 text-center">
              <a
                href="https://www.gatiresources.in/"
                target="_blank"
                title="gatiresources.in"
              >
                <img
                  class="img-fluid"
                  alt="www.gatiresources.in"
                  src="/Image/GATI1.png"
                />
              </a>
            </div>
            <div class="col-3 col-md-2 paddingTop10">
              <a
                href="https://aishe.gov.in/aishe/home"
                target="_blank"
                title="AISHE"
              >
                <img
                  class="img-fluid"
                  alt="AISHE"
                  src="/Image/aishe-logo.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
