import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { DatePicker, Space } from "antd";
import axios from "axios";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

import exportFromJSON from "export-from-json";

const Auto_Criteria04 = () => {
  const navigate = useNavigate();
  let librarianDetails = JSON.parse(sessionStorage.getItem("Luser"));

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);

  const [View, setView] = useState({});

  const [app1, setapp1] = useState(true);
  const [Auto_Room, setAuto_Room] = useState("");
  const [Auto_Type, setAuto_Type] = useState("");
  const [Auto_Desc1, setAuto_Desc1] = useState("");
  const [Auto_Link1, setAuto_Link1] = useState("");

  const [Auto_Budget, setAuto_Budget] = useState("");
  const [Auto_Expenditure, setAuto_Expenditure] = useState("");
  const [Auto_Total, setAuto_Total] = useState("");
  const [Auto_Expend1, setAuto_Expend1] = useState("");
  const [Auto_Expend2, setAuto_Expend2] = useState("");
  const [Auto_Desc2, setAuto_Desc2] = useState("");
  const [Auto_Link2, setAuto_Link2] = useState("");

  const [Auto_Library, setAuto_Library] = useState("");
  const [Auto_IfYes, setAuto_IfYes] = useState("");
  const [Auto_Expend3, setAuto_Expend3] = useState("");
  const [Auto_Total1, setAuto_Total1] = useState("");
  const [Auto_Desc3, setAuto_Desc3] = useState("");
  const [Auto_Link3, setAuto_Link3] = useState("");

  const [Auto_TNa, setAuto_TNa] = useState("");
  const [Auto_Develop, setAuto_Develop] = useState("");
  const [Auto_Platform, setAuto_Platform] = useState("");
  const [Auto_DateLaunch, setAuto_DateLaunch] = useState("");
  const [Auto_List, setAuto_List] = useState("");
  const [Auto_Desc4, setAuto_Desc4] = useState("");
  const [Auto_Link4, setAuto_Link4] = useState("");
  const [Auto_Link5, setAuto_Link5] = useState("");

  //integrating get method
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [Auto_Criteria04, setAuto_Criteria04] = useState([]);
  const [Value, setValue] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  const editAuto_Criteria04_413 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc1) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/LibraryAdmin/editAuto_Criteria04_413/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Room: Auto_Room,
          Auto_Type: Auto_Type,
          Auto_Desc1: Auto_Desc1,
          Auto_Link1: Auto_Link1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri04details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria04_414 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc2) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/LibraryAdmin/editAuto_Criteria04_414/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Budget: Auto_Budget,
          Auto_Expenditure: Auto_Expenditure,
          Auto_Total: Auto_Total,
          Auto_Expend1: Auto_Expend1,
          Auto_Expend2: Auto_Expend2,
          Auto_Desc2: Auto_Desc2,
          Auto_Link2: Auto_Link2,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri04details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria04_422 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc3) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/LibraryAdmin/editAuto_Criteria04_422/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Library: Auto_Library,
          Auto_IfYes: Auto_IfYes,
          Auto_Expend3: Auto_Expend3,
          Auto_Total1: Auto_Total1,
          Auto_Desc3: Auto_Desc3,
          Auto_Link3: Auto_Link3,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri04details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria04_434 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc4) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/LibraryAdmin/editAuto_Criteria04_434/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_TNa: Auto_TNa,
          Auto_Develop: Auto_Develop,
          Auto_Platform: Auto_Platform,
          Auto_DateLaunch: Auto_DateLaunch,
          Auto_List: Auto_List,
          Auto_Desc4: Auto_Desc4,
          Auto_Link4: Auto_Link4,
          Auto_Link5: Auto_Link5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri04details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Auto_Criteria_4.0 ");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          //  Criteria 4_413

          Auto_Year: item?.Auto_Year,
          "Criteria_(4.1.3)": item?.Auto_Criteria04_413?.Auto_criteria,
          "Room number or Name  of classrooms/Seminar Hall with LCD / wifi/LAN facilities with room numbers":
            item?.Auto_Criteria04_413?.Auto_Room,
          "Type of ICT Facility": item?.Auto_Criteria04_413?.Auto_Type,
          "Description(min 500 words)1": item?.Auto_Criteria04_413?.Auto_Desc1,
          "Upload the Relevant Document1":
            item?.Auto_Criteria04_413?.Auto_Link1,

          // Criteria 4_414

          "Criteria_(4.1.4 & 4.4.1)": item?.Auto_Criteria04_414?.Auto_criteria,
          "Budget Allocated for Infrastructure Augmentation (INR inLakhs)":
            item?.Auto_Criteria04_414?.Auto_Budget,
          "Expenditure for Infrastructure Augmentation (INR in Lakhs)":
            item?.Auto_Criteria04_414?.Auto_Expenditure,
          "Total Expenditure Excluding Salary (INR in Lakhs)":
            item?.Auto_Criteria04_414?.Auto_Total,
          "Expenditure on Maintenance of Academic Facilities (excluding salary for Human Resources) (INR in Lakhs)":
            item?.Auto_Criteria04_414?.Auto_Expend1,
          "Expenditure on Maintenance of Physical Facilities (excluding salary for Human Resources) (INR in Lakhs)":
            item?.Auto_Criteria04_414?.Auto_Expend2,
          "Description(min 500 words)2": item?.Auto_Criteria04_414?.Auto_Desc2,
          "Upload the relevant document2":
            item?.Auto_Criteria04_414?.Auto_Link2,

          // Criteria 4_422

          "Criteria_(4.2.2  & 4.2.3)": item?.Auto_Criteria04_422?.Auto_criteria,
          "Library Resources	": item?.Auto_Criteria04_422?.Auto_Library,
          "If yes, details of Memberships/Subscriptions":
            item?.Auto_Criteria04_422?.Auto_IfYes,
          "Expenditure on Subscription to E-journals, E-books (INR in lakhs)":
            item?.Auto_Criteria04_422?.Auto_Expend3,
          "Total Library Expenditure": item?.Auto_Criteria04_422?.Auto_Total1,
          "Description(min 500 words)3": item?.Auto_Criteria04_422?.Auto_Desc3,
          "Upload the relevant document3":
            item?.Auto_Criteria04_422?.Auto_Link3,

          // Criteria 4_434

          "Criteria_(4.3.4)": item?.Auto_Criteria04_434?.Auto_criteria,
          "Library Name of the teacher 	": item?.Auto_Criteria04_434?.Auto_TNa,
          "Name of the Module Developed":
            item?.Auto_Criteria04_434?.Auto_Develop,
          "Platform on Which Module is Developed ":
            item?.Auto_Criteria04_434?.Auto_Platform,
          "Date of Launching E-content": moment(
            item?.Auto_Criteria04_434?.Auto_DateLaunch
          ).format("DD-MM-YYYY"),
          "List of the E-content development Facility Available ":
            item?.Auto_Criteria04_434?.Auto_List,
          "Description(min 500 words)4": item?.Auto_Criteria04_434?.Auto_Desc4,
          "Provide link to videos of the media centre and recording facility":
            item?.Auto_Criteria04_434?.Auto_Link4,
          "Link to the relevant document and facility available in the institution":
            item?.Auto_Criteria04_434?.Auto_Link5,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const getautocri04details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getautocriteria04"
      );
      if (res.status === 200) {
        setAuto_Criteria04(res.data.autocriteria04);
        setdata1(res.data.autocriteria04);
      }
    } catch (error) {
      console.log(error);
      setAuto_Criteria04(error.response.autocriteria04);
    }
  };

  useEffect(() => {
    librarianDetails = JSON.parse(sessionStorage.getItem("Luser"));
    if (!librarianDetails) {
      alert("Please login first");
      window.location.assign("/llogin");
    } else {
      getautocri04details();
    }
  }, []);
  console.log(Value);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                Autonomous_Criteria-04
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/autocriteria004">
                <button className=" btn btn-success">
                  Add Criteria 4{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0 ">
            <div className=" col-lg-3 do-sear d-flex p-0  ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeFrom}
                picker="year"
                placeholder="Select Year (from)"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                picker="year"
                placeholder="Select Year (to)"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>SI.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>
                  <th>Criteria</th>
                  <th>
                    Room number or Name of Classrooms/Seminar Hall with LCD /
                    wifi/LAN facilities with room numbers
                  </th>
                  <th>Type of ICT facility</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Budget Allocated for Infrastructure Augmentation (INR
                    inLakhs)
                  </th>
                  <th>
                    Expenditure for Infrastructure Augmentation (INR in Lakhs)
                  </th>
                  <th>Total Expenditure Excluding Salary (INR in Lakhs)</th>
                  <th>
                    Expenditure on Maintenance of Academic Facilities (excluding
                    salary for Human Resources) (INR in Lakhs)
                  </th>
                  <th>
                    Expenditure on Maintenance of Physical Facilities (excluding
                    salary for Human Resources) (INR in Lakhs)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Library Resources</th>
                  <th>If yes, details of memberships/subscriptions</th>
                  <th>
                    Expenditure on Subscription to E-Journals, E-Books (INR in
                    lakhs)
                  </th>
                  <th>Total Library Expenditure</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the Teacher </th>
                  <th>Name of the Module Developed</th>
                  <th>Platform on Which Module is Developed </th>
                  <th>Date of Launching E-content</th>
                  <th>List of the E-content Development Facility Available </th>
                  <th>Description</th>
                  <th>Document 1</th>
                  <th>Document 2</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {Auto_Criteria04?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    librarianDetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Auto_Criteria04_413?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_413?.Auto_Room?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_413?.Auto_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_413?.Auto_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Expenditure?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Expend1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Expend2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_414?.Auto_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_Library?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_IfYes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_Expend3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria04_422?.Auto_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_434?.Auto_criteria?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_434?.Auto_TNa?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_434?.Auto_Develop?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_434?.Auto_Platform?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_434?.Auto_DateLaunch?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_434?.Auto_List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria04_434.Auto_Desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Auto_Year}</td>
                      <td> {librarianDetails?.FName}</td>
                      <td>{item?.Auto_Criteria04_413?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria04_413?.Auto_Room}</td>
                      <td>{item?.Auto_Criteria04_413?.Auto_Type}</td>
                      <td>
                        {item?.Auto_Criteria04_413?.Auto_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* ====== */}
                      <td>{item?.Auto_Criteria04_414?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Budget}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Expenditure}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Total}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Expend1}</td>
                      <td>{item?.Auto_Criteria04_414?.Auto_Expend2}</td>
                      <td>
                        {item?.Auto_Criteria04_414?.Auto_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* ====== */}
                      <td>{item?.Auto_Criteria04_422?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria04_422?.Auto_Library}</td>
                      <td>{item?.Auto_Criteria04_422?.Auto_IfYes}</td>
                      <td>{item?.Auto_Criteria04_422?.Auto_Expend3}</td>
                      <td>{item?.Auto_Criteria04_422?.Auto_Total1}</td>
                      <td>
                        {item?.Auto_Criteria04_422?.Auto_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria04_434?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria04_434?.Auto_TNa}</td>
                      <td>{item?.Auto_Criteria04_434?.Auto_Develop}</td>
                      <td>{item?.Auto_Criteria04_434?.Auto_Platform}</td>
                      <td>
                        {moment(
                          item?.Auto_Criteria04_434?.Auto_DateLaunch
                        ).format("DD-MM-YYYY")}
                      </td>
                      <td>{item?.Auto_Criteria04_434?.Auto_List}</td>

                      <td>
                        {item?.Auto_Criteria04_434?.Auto_Desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green " }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={() => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold", color: "white" }}
              >
                Autonomous_Criteria-04
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-413
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Room number or Name of Classrooms/Seminar Hall with LCD /
                    wifi/LAN facilities with room numbers
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_413?.Auto_Room}
                        onChange={(e) => setAuto_Room(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_413?.Auto_Room}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Type of ICT facility
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_413?.Auto_Type}
                        onChange={(e) => setAuto_Type(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_413?.Auto_Type}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br />
                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_413?.Auto_Desc1}
                        onChange={(e) => setAuto_Desc1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_413?.Auto_Desc1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1"> Document</label>
                  <br />
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        placeholder={Auto_Link1}
                        onChange={(e) => setAuto_Link1(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_413?.Auto_Link1}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="text-end mt-3">
                    {edit1 ? (
                      <>
                        <Button
                          className="btn btn-success"
                          onClick={(e) => {
                            editAuto_Criteria04_413(e, Value?._id);
                            setedit1(false);
                            handleClose();
                          }}
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit1(true)}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* ------4.1.4 & 4.4.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-414 & 441
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Budget Allocated for Infrastructure Augmentation (INR in
                    inLakhs)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_414?.Auto_Budget}
                        onChange={(e) => {
                          setAuto_Budget(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_414?.Auto_Budget}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Expenditure for Infrastructure Augmentation (INR in Lakhs)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria04_414?.Auto_Expenditure
                        }
                        onChange={(e) => {
                          setAuto_Expenditure(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_414?.Auto_Expenditure}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Expenditure Excluding Salary (INR in Lakhs)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_414?.Auto_Total}
                        onChange={(e) => setAuto_Total(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_414?.Auto_Total}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Expenditure on Maintenance of Academic Facilities (excluding
                    salary for Human Resources) (INR in Lakhs)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_414?.Auto_Expend1}
                        onChange={(e) => setAuto_Expend1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_414?.Auto_Expend1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Expenditure on Maintenance of Physical Facilities (excluding
                    salary for Human Resources) (INR in Lakh)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_414?.Auto_Expend2}
                        onChange={(e) => setAuto_Expend2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_414?.Auto_Expend2}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_414?.Auto_Desc2}
                        onChange={(e) => setAuto_Desc2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_414?.Auto_Desc2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        accept="image/*"
                        placeholder={Auto_Link2}
                        onChange={(e) => setAuto_Link2(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_414?.Auto_Link2}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="text-end mt-3 ">
                    {edit2 ? (
                      <>
                        {" "}
                        <Button
                          className="btn btn-success"
                          onClick={(e) => {
                            editAuto_Criteria04_414(e, Value?._id);
                            setedit2(false);
                            handleClose();
                          }}
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit2(true)}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-422 & 423
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Library resources
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Auto_Criteria04_422?.Auto_Library}
                        onChange={(e) => setAuto_Library(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Books">Books</option>
                        <option value="Journals ">Journals </option>
                        <option value="e – journals ">e – journals </option>
                        <option value="e-books">e-books</option>
                        <option value="e-ShodhSindhu">e-ShodhSindhu</option>
                        <option value="Shodhganga">Shodhganga</option>
                        <option value="Databases">Databases</option>
                        <option value="Local and / or Remote access to e- resources (Specify)">
                          e-ShodhSindhu
                        </option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_422?.Auto_Library}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    If yes, Details of Memberships/Subscriptions
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_422?.Auto_IfYes}
                        onChange={(e) => {
                          setAuto_IfYes(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_422?.Auto_IfYes}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Expenditure on Subscription to E-Journals, E-Books (INR in
                    lakhs)
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_422?.Auto_Expend3}
                        onChange={(e) => {
                          setAuto_Expend3(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_422?.Auto_Expend3}</>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total Library Expenditure
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_422?.Auto_Total1}
                        onChange={(e) => {
                          setAuto_Total1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_422?.Auto_Total1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_422?.Auto_Desc3}
                        onChange={(e) => setAuto_Desc3(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_422?.Auto_Desc3}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>
                  <br />
                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3 "
                        accept="image/*"
                        placeholder={Auto_Link3}
                        onChange={(e) => setAuto_Link3(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_422?.Auto_Link3}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="text-end mt-3 ">
                    {edit3 ? (
                      <>
                        <Button
                          className="btn btn-success"
                          onClick={(e) => {
                            editAuto_Criteria04_422(e, Value?._id);
                            handleClose();
                            setedit3(false);
                          }}
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit3(true)}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
              {/* ------4.3.4-------- */}
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-434
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Teacher
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_434?.Auto_TNa}
                        onChange={(e) => {
                          setAuto_TNa(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_434?.Auto_TNa}</>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Module Developed
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_434?.Auto_Develop}
                        onChange={(e) => {
                          setAuto_Develop(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_434?.Auto_Develop}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Platform on Which Module is Developed
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_434?.Auto_Platform}
                        onChange={(e) => {
                          setAuto_Platform(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_434?.Auto_Platform}</>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of Launching E-content
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria04_434?.setAuto_DateLaunch
                        }
                        onChange={(e) => {
                          setAuto_DateLaunch(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_434?.Auto_DateLaunch}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    List of the E-content Development Faclity Avaliable
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_434?.Auto_List}
                        onChange={(e) => {
                          setAuto_List(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_434?.Auto_List}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria04_434?.Auto_Desc4}
                        onChange={(e) => setAuto_Desc4(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_434?.Auto_Desc4}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document 1
                  </label>
                  <br />
                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload4"
                        id="upload4 "
                        accept="image/*"
                        placeholder={Auto_Link4}
                        onChange={(e) => setAuto_Link4(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria04_434?.Auto_Link4}</>
                  )}
                  <div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Document 2
                      </label>
                      <br />
                      {edit4 ? (
                        <>
                          <input
                            className="vi_0"
                            type="file"
                            name="upload5"
                            id="upload5"
                            accept="image/*"
                            placeholder={Auto_Link5}
                            onChange={(e) => setAuto_Link5(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Value?.Auto_Criteria04_434?.Auto_Link5}</>
                      )}
                    </div>
                    {Value?.approve == "true" ? (
                      <></>
                    ) : (
                      <div className="text-end mt-3">
                        {edit4 ? (
                          <>
                            <Button
                              className="btn btn-success"
                              onClick={(e) => {
                                editAuto_Criteria04_434(e, Value?._id);
                                handleClose();
                                setedit4(false);
                              }}
                            >
                              Submit
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              type="submit"
                              className="btn btn-danger"
                              onClick={() => setedit4(true)}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
                setedit1(false);
                setedit2(false);
                setedit3(false);
                setedit4(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria04_413?.Auto_Desc1}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria04_414?.Auto_Desc2}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria04_422?.Auto_Desc3}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria04_434?.Auto_Desc4}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Title>Relevant Document</Modal.Title>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria4/${View?.Auto_Criteria04_413?.Auto_Link1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria4/${View?.Auto_Criteria04_414?.Auto_Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria4/${View?.Auto_Criteria04_422?.Auto_Link3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria4/${View?.Auto_Criteria04_434?.Auto_Link4}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria4/${View?.Auto_Criteria04_434?.Auto_Link5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Auto_Criteria04;
