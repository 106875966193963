import React from "react";

const UG5531aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the award/ medal</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_531?.Meda}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Team / Individual</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_531?.TIndi}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>University/State/National/ International1</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_531?.USNI1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Sports/ Cultural</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_531?.USNI1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the student</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_531?.USNI1}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria05_531?.Desc27}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria05/${item?.Criteria05_531?.Link27}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG5531aqar;
