import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
function FAQ() {
  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [View, setView] = useState({});
  const [question, setquestion] = useState("");
  const [answer, setanswer] = useState("");
  const AddFAQ = async (e) => {
    e.preventDefault();
    if (!question || !answer) {
      return alert("Please fill all the fields");
    }
    try {
      const config = {
        url: "/admin/addFAQ",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          question: question,
          answer: answer,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          setquestion("");
          setanswer("");
          getFaq();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the FAQs======================
  const [FAQs, setFAQs] = useState([]);
  const getFaq = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getFAQ");
      if (res.status === 200) {
        setFAQs(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setFAQs(error.response.data.allContent);
    }
  };

  const UpdateFAQs = async (e) => {
    e.preventDefault();
    if (!question && !answer) {
      return alert("Please fill all the fields");
    }
    try {
      const config = {
        url: `/admin/editFAQ/${View?._id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          question: question,
          answer: answer,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setquestion("");
          setanswer("");
          getFaq();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  const deleteFAQs = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteFAQ/${View?._id}`,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Deleted Successfully");
          getFaq();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose1();
    }
  };

  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getFaq();
  }, []);
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              FAQ
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {FAQs?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>{item?.question}</td>
                    <td>
                      {item?.answer?.slice(0, 50)}
                      ...
                      <Link
                        onClick={() => {
                          handleShow7();
                          setView(item);
                        }}
                      >
                        Read More...
                      </Link>
                    </td>

                    <td>
                      <div className="d-flex justify-content-center">
                        <span
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            margin: "10px",
                          }}
                        >
                          <BiEdit
                            size={20}
                            onClick={() => {
                              setView(item);
                              handleShow2();
                            }}
                          />
                        </span>

                        <span
                          style={{
                            cursor: "pointer",
                            margin: "10px",
                            color: "red",
                          }}
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                        >
                          <AiFillDelete size={20} />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Show Description */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Headline</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.answer}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Update FAQs Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>Question</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.question}
                  onChange={(e) => setquestion(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>Answer</label>
              <div className="">
                <textarea
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setanswer(e.target.value)}
                >
                  {View?.answer}
                </textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => UpdateFAQs(e)}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add FAQs Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>Question</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setquestion(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>Answer</label>
              <div className="">
                <textarea
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setanswer(e.target.value)}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddFAQ(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteFAQs(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default FAQ;
