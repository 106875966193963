import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const Uni_Criteria02 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);
  const [edit8, setedit8] = useState(false);

  const [Uni_PrgCode, setUni_PrgCode] = useState("");
  const [Uni_NoSeatSanctioned, setUni_NoSeatSanctioned] = useState("");
  const [
    Uni_eligible_applications_received,
    setUni_eligible_applications_received,
  ] = useState("");
  const [Uni_NoStdAdmit, setUni_NoStdAdmit] = useState("");
  const [Uni_PrgName1, setUni_PrgName1] = useState("");
  const [Uni_Desc32, setUni_Desc32] = useState("");
  const [Uni_Link32, setUni_Link32] = useState("");

  const [Uni_SC, setUni_SC] = useState("");
  const [Uni_ST, setUni_ST] = useState("");
  const [Uni_OBC, setUni_OBC] = useState("");
  const [Uni_Gen, setUni_Gen] = useState("");
  const [Uni_Divyangjan, setUni_Divyangjan] = useState("");
  const [Uni_Others, setUni_Others] = useState("");
  const [Uni_SSC, setUni_SSC] = useState("");
  const [Uni_SST, setUni_SST] = useState("");
  const [Uni_OOBC, setUni_OOBC] = useState("");
  const [Uni_GGen, setUni_GGen] = useState("");
  const [Uni_DDivyangjan, setUni_DDivyangjan] = useState("");
  const [Uni_OOthers, setUni_OOthers] = useState("");
  const [Uni_Desc33, setUni_Desc33] = useState("");
  const [Uni_Link33, setUni_Link33] = useState("");

  const [Uni_TName, setUni_TName] = useState("");
  const [Uni_PAN, setUni_PAN] = useState("");
  const [Uni_Designation, setUni_Designation] = useState("");
  const [Uni_YOfAppoint, setUni_YOfAppoint] = useState("");
  const [Uni_NoOfAppoint, setUni_NoOfAppoint] = useState("");
  const [Uni_DeptName, setUni_DeptName] = useState("");
  const [Uni_TYofExp, setUni_TYofExp] = useState("");
  const [Uni_TService, setUni_TService] = useState("");
  const [Uni_Desc34, setUni_Desc34] = useState("");
  const [Uni_Link34, setUni_Link34] = useState("");

  const [Uni_SerTeacher, setUni_SerTeacher] = useState("");
  const [Uni_Qualify, setUni_Qualify] = useState("");
  const [Uni_YOfQuali, setUni_YOfQuali] = useState("");
  const [Uni_Desc35, setUni_Desc35] = useState("");
  const [Uni_Link35, setUni_Link35] = useState("");

  const [Uni_fullTeacherName, setUni_fullTeacherName] = useState("");
  const [Uni_YrAward, setUni_YrAward] = useState("");
  const [Uni_PAN1, setUni_PAN1] = useState("");
  const [Uni_Desi, setUni_Desi] = useState("");
  const [Uni_AwardAgency, setUni_AwardAgency] = useState("");
  const [Uni_AwardName, setUni_AwardName] = useState("");
  const [Uni_Incentives, setUni_Incentives] = useState("");
  const [Uni_Desc36, setUni_Desc36] = useState("");
  const [Uni_Link36, setUni_Link36] = useState("");

  const [Uni_pname1, setUni_pname1] = useState("");
  const [Uni_pcode1, setUni_pcode1] = useState("");
  const [Uni_semester, setUni_semester] = useState("");
  const [Uni_last, setUni_last] = useState("");
  const [Uni_Date, setUni_Date] = useState("");
  const [Uni_Desc37, setUni_Desc37] = useState("");
  const [Uni_Link37, setUni_Link37] = useState("");

  const [Uni_pCode, setUni_pCode] = useState("");
  const [Uni_PrgName2, setUni_PrgName2] = useState("");
  const [Uni_NStdAppeared, setUni_NStdAppeared] = useState("");
  const [Uni_NStdPassed, setUni_NStdPassed] = useState("");
  const [Uni_Desc38, setUni_Desc38] = useState("");
  const [Uni_Link38, setUni_Link38] = useState("");

  const [Uni_PrgName3, setUni_PrgName3] = useState("");
  const [Uni_NStd, setUni_NStd] = useState("");
  const [Uni_Gender, setUni_Gender] = useState("");
  const [Uni_Category, setUni_Category] = useState("");
  const [Uni_SDomicile, setUni_SDomicile] = useState("");
  const [Uni_NIndian, setUni_NIndian] = useState("");
  const [Uni_Email, setUni_Email] = useState("");
  const [Uni_MNo, setUni_MNo] = useState("");
  const [Uni_YJoining, setUni_YJoining] = useState("");
  const [Uni_UID, setUni_UID] = useState("");
  const [Uni_Desc39, setUni_Desc39] = useState("");
  const [Uni_Link39, setUni_Link39] = useState("");

  //integrating get method
  const [View, setView] = useState();
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  const [show18, setShow18] = useState(false);
  const handleClose18 = () => setShow18(false);
  const handleShow18 = () => setShow18(true);

  const [Uni_Criteria02, setUni_Criteria02] = useState([]);
  const [Value, setValue] = useState({});

  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Uni_Cri2.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>SC</td>
                <td>${item?.Uni_Criteria02_212?.Uni_SC}</td>
              </tr>
              <tr>
                <td>ST</td>
                <td>${item?.Uni_Criteria02_212?.Uni_ST}</td>
              </tr>
              <tr>
                <td>OBC</td>
                <td>${item?.Uni_Criteria02_212?.Uni_OBC}</td>
              </tr>
              <tr>
                <td>General</td>
                <td>${item?.Uni_Criteria02_212?.Uni_Gen}</td>
              </tr>
              <tr>
                <td>Divyangjan</td>
                <td>${item?.Uni_Criteria02_212?.Uni_Divyangjan}</td>
              </tr>
              <tr>
                <td>Others</td>
                <td>${item?.Uni_Criteria02_212?.Uni_Others}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
            <table>
              <tr>
                <td>SC</td>
                <td>${item?.Uni_Criteria02_212?.Uni_SSC}</td>
              </tr>
              <tr>
                <td>ST</td>
                <td>${item?.Uni_Criteria02_212?.Uni_SST}</td>
              </tr>
              <tr>
                <td>OBC</td>
                <td>${item?.Uni_Criteria02_212?.Uni_OOBC}</td>
              </tr>
              <tr>
                <td>General</td>
                <td>${item?.Uni_Criteria02_212?.Uni_GGen}</td>
              </tr>
              <tr>
                <td>Divyangjan</td>
                <td>${item?.Uni_Criteria02_212?.Uni_DDivyangjan}</td>
              </tr>
              <tr>
                <td>Others</td>
                <td>${item?.Uni_Criteria02_212?.Uni_OOthers}</td>
              </tr>
            </table>
          `;
        let obj = {
          //  Criteria 2_211

          Uni_Year: item?.Uni_Year,
          "Criteria_2.1.1": item?.Uni_Criteria02_211?.Uni_criteria,
          "Program Name": item?.Uni_Criteria02_211?.Uni_PrgName1,
          "Program Code": item?.Uni_Criteria02_211?.Uni_PrgCode,
          "Number of seats sanctioned":
            item?.Uni_Criteria02_211?.Uni_NoSeatSanctioned,
          "Number of eligible applications received":
            item?.Uni_Criteria02_211?.Uni_eligible_applications_received,
          "Number of Students admitted":
            item?.Uni_Criteria02_211?.Uni_NoStdAdmit,
          "Description(min 500 words)(2.1.1)":
            item?.Uni_Criteria02_211?.Uni_Desc32,
          "Upload the relevant document(2.1.1)":
            item?.Uni_Criteria02_211?.Uni_Link32,

          // Criteria 2_212

          "Criteria_2.1.2": item?.Uni_Criteria02_212?.Uni_criteria,
          "Number of seats earmarked for reserved category as per GOI or State Government rule":
            tableMarkup1,
          "Number of students admitted from the reserved category":
            tableMarkup2,
          "Description(min 500 words)(2.1.2)":
            item?.Uni_Criteria02_212?.Uni_Desc33,
          "Upload the relevant document(2.1.2)":
            item?.Uni_Criteria02_212?.Uni_Link33,

          // Criteria 2_241
          "Criteria_2.4.1": item?.Uni_Criteria02_241_243?.Uni_criteria,
          "Name of the Full-time teacher":
            item?.Uni_Criteria02_241_243?.Uni_TName,
          PAN1: item?.Uni_Criteria02_241_243?.Uni_PAN,
          Designation1: item?.Uni_Criteria02_241_243?.Uni_Designation,
          "Year of appointment": item?.Uni_Criteria02_241_243?.Uni_YOfAppoint,
          "Nature of appointment (Against Sanctioned post,temporary,permanent)":
            item?.Uni_Criteria02_241_243?.Uni_NoOfAppoint,
          "Name of the Department": item?.Uni_Criteria02_241_243?.Uni_DeptName,
          "Total years of Experience in the same institution":
            item?.Uni_Criteria02_241_243?.Uni_TYofExp,
          "Is the teacher still serving the institution/If not last  year of the service of Faculty to the Institution.":
            item?.Uni_Criteria02_241_243?.Uni_TService,
          "Description(min 500 words)(2.4.1)":
            item?.Uni_Criteria02_241_243?.Uni_Desc34,
          "Upload the relevant document(2.4.1)":
            item?.Uni_Criteria02_241_243?.Uni_Link34,

          // Criteria 2_242
          "Criteria_2.4.2": item?.Uni_Criteria02_242?.Uni_criteria,
          " Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.":
            item?.Uni_Criteria02_242?.Uni_SerTeacher,
          " Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./ D.Litt.)":
            item?.Uni_Criteria02_242?.Uni_Qualify,
          "Year of obtaining the qualification":
            item?.Uni_Criteria02_242?.Uni_YOfQuali,
          "Description(min 500 words)(2.4.2)":
            item?.Uni_Criteria02_242?.Uni_Desc35,
          "Upload the relevant document(2.4.2)":
            item?.Uni_Criteria02_242?.Uni_Link35,

          // Criteria 2_244
          "Criteria_2.4.4": item?.Uni_criteria02_244?.Uni_criteria,
          "Name of full time teachers receiving awards from state level, national level, international level":
            item?.Uni_criteria02_244?.Uni_fullTeacherName,
          "Year of Award": item?.Uni_criteria02_244?.Uni_YrAward,
          PAN: item?.Uni_criteria02_244?.Uni_PAN1,
          Designation: item?.Uni_criteria02_244?.Uni_Desi,
          "Name of the Awarding Agency":
            item?.Uni_criteria02_244?.Uni_AwardAgency,
          "Name of the award, fellowship, received from Government or Government recognised bodies":
            item?.Uni_criteria02_244?.Uni_AwardName,
          "Incentives/Type of the incentive given by the HEI in recognition of the award":
            item?.Uni_criteria02_244?.Uni_Incentives,
          "Description(min 500 words)(2.4.4)":
            item?.Uni_criteria02_244?.Uni_Desc36,
          "Upload the relevant document(2.4.4)":
            item?.Uni_criteria02_244?.Uni_Link36,

          // Criteria 2_251
          "Criteria_2.5.1": item?.Uni_Criteria02_251?.Uni_criteria,
          " Program name.": item?.Uni_Criteria02_251?.Uni_pname1,
          "Semester/ year": item?.Uni_Criteria02_251?.Uni_semester,
          "Program code": item?.Uni_Criteria02_251?.Uni_pcode1,
          "Last date of the last semester-end/ year- end examination": moment(
            item?.Uni_Criteria02_251?.Uni_last
          ).format("DD-MM-YYY"),
          " Date of declaration of results of semester-end/ year- end examination":
            moment(item?.Uni_Criteria02_251?.Uni_Date).format("DD-MM-YYYY"),
          "Description(min 500 words)(2.5.1)":
            item?.Uni_Criteria02_251?.Uni_Desc37,
          "Upload the relevant document(2.5.1)":
            item?.Uni_Criteria02_251?.Uni_Link37,

          // Criteria 2_263
          "Criteria_2.6.3": item?.Uni_Criteria02_263?.Uni_criteria,
          Program_Name: item?.Uni_Criteria02_263?.Uni_PrgName2,
          Program_Code: item?.Uni_Criteria02_263?.Uni_pCode,
          " Number of students appeared in the final year examination":
            item?.Uni_Criteria02_263?.Uni_NStdAppeared,
          " Number of students passed in final year examination":
            item?.Uni_Criteria02_263?.Uni_NStdPassed,
          "Description(min 500 words)(2.6.3)":
            item?.Uni_Criteria02_263?.Uni_Desc38,
          " Upload the relevant document(2.6.3)":
            item?.Uni_Criteria02_263?.Uni_Link38,

          //Criteria 2_271
          "Criteria_2.7.1": item?.Uni_Criteria02_271?.Uni_criteria,
          "Program-Name": item?.Uni_Criteria02_271?.Uni_PrgName3,
          "Name of the student": item?.Uni_Criteria02_271?.Uni_NStd,
          Gender: item?.Uni_Criteria02_271?.Uni_Gender,
          Category: item?.Uni_Criteria02_271?.Uni_Category,
          "State of Domicile": item?.Uni_Criteria02_271?.Uni_SDomicile,
          "Nationality if other than Indian":
            item?.Uni_Criteria02_271?.Uni_NIndian,
          "Email-ID": item?.Uni_Criteria02_271?.Uni_Email,
          "Mobile Number": item?.Uni_Criteria02_271?.Uni_MNo,
          "Year of joining": item?.Uni_Criteria02_271?.Uni_YJoining,
          "Student Unique Enrolment ID ": item?.Uni_Criteria02_271?.Uni_UID,
          "Description(min 500 words)(2.7.1)":
            item?.Uni_Criteria02_271?.Uni_Desc39,
          "Upload the relevant document(2.7.1)":
            item?.Uni_Criteria02_271?.Uni_Link39,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editUni_Criteria02_211 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc32;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc32) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria02_211/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_PrgName1: Uni_PrgName1,
          Uni_PrgCode: Uni_PrgCode,
          Uni_NoSeatSanctioned: Uni_NoSeatSanctioned,
          Uni_eligible_applications_received:
            Uni_eligible_applications_received,
          Uni_NoStdAdmit: Uni_NoStdAdmit,
          Uni_Desc32: Uni_Desc32,
          Uni_Link32: Uni_Link32,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicri02details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria02_212 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc33;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Uni_Desc33) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria02_212/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_SC: Uni_SC,
          Uni_ST: Uni_ST,
          Uni_OBC: Uni_OBC,
          Uni_Gen: Uni_Gen,
          Uni_Divyangjan: Uni_Divyangjan,
          Uni_Others: Uni_Others,
          Uni_SSC: Uni_SSC,
          Uni_SST: Uni_SST,
          Uni_OOBC: Uni_OOBC,
          Uni_GGen: Uni_GGen,
          Uni_DDivyangjan: Uni_DDivyangjan,
          Uni_OOthers: Uni_OOthers,
          Uni_Desc33: Uni_Desc33,
          Uni_Link33: Uni_Link33,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicri02details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria02_241_243 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc34;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc34) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria02_241_243/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_TName: Uni_TName,
          Uni_PAN: Uni_PAN,
          Uni_Designation: Uni_Designation,
          Uni_YOfAppoint: Uni_YOfAppoint,
          Uni_NoOfAppoint: Uni_NoOfAppoint,
          Uni_DeptName: Uni_DeptName,
          Uni_TYofExp: Uni_TYofExp,
          Uni_TService: Uni_TService,
          Uni_Desc34: Uni_Desc34,
          Uni_Link34: Uni_Link34,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicri02details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria02_242 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc35;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc35) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria02_242/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_SerTeacher: Uni_SerTeacher,
          Uni_Qualify: Uni_Qualify,
          Uni_YOfQuali: Uni_YOfQuali,
          Uni_Desc35: Uni_Desc35,
          Uni_Link35: Uni_Link35,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicri02details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_criteria02_244 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc36;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc36) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_criteria02_244/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_fullTeacherName: Uni_fullTeacherName,
          Uni_YrAward: Uni_YrAward,
          Uni_PAN1: Uni_PAN1,
          Uni_Desi: Uni_Desi,
          Uni_AwardAgency: Uni_AwardAgency,
          Uni_AwardName: Uni_AwardName,
          Uni_Incentives: Uni_Incentives,
          Uni_Desc36: Uni_Desc36,
          Uni_Link36: Uni_Link36,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicri02details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria02_251 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc37;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc37) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria02_251/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_pname1: Uni_pname1,
          Uni_pcode1: Uni_pcode1,
          Uni_semester: Uni_semester,
          Uni_last: Uni_last,
          Uni_Date: Uni_Date,
          Uni_Desc37: Uni_Desc37,
          Uni_Link37: Uni_Link37,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicri02details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria02_263 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc38;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc38) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria02_263/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_pCode: Uni_pCode,
          Uni_PrgName2: Uni_PrgName2,
          Uni_NStdAppeared: Uni_NStdAppeared,
          Uni_NStdPassed: Uni_NStdPassed,
          Uni_Desc38: Uni_Desc38,
          Uni_Link38: Uni_Link38,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicri02details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria02_271 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc39;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc39) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria02_271/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_PrgName3: Uni_PrgName3,
          Uni_NStd: Uni_NStd,
          Uni_Gender: Uni_Gender,
          Uni_Category: Uni_Category,
          Uni_SDomicile: Uni_SDomicile,
          Uni_NIndian: Uni_NIndian,
          Uni_Email: Uni_Email,
          Uni_MNo: Uni_MNo,
          Uni_YJoining: Uni_YJoining,
          Uni_UID: Uni_UID,
          Uni_Desc39: Uni_Desc39,
          Uni_Link39: Uni_Link39,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicri02details();
          setedit8(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getUnicri02details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUnicri02details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.Unicriteria02);
        setUni_Criteria02(res.data.Unicriteria02);
        setdata1(res.data.Unicriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.Unicriteria02);
    }
  };

  useEffect(() => {
    getUnicri02details();
  }, []);
  console.log(Uni_Criteria02);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  // const uniCriteria02Details = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://brightnaac.co.in/api/FacultyAdmin/getunicriteria2"
  //     );
  //     if (res.status === 200) {
  //       setAllDetails(res.data.unicriteria02);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAllDetails(error.response.data.unicriteria02);
  //   }
  // };

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-02
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/unicriteria002">
                <button className="btn btn-success">
                  Add Criteria 2{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of eligible applications received</th>
                  <th>Number of Students admitted</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>SC</th>
                  <th>ST</th>
                  <th>OBC</th>
                  <th>Gen</th>
                  <th>Divyangjan</th>
                  <th>Others</th>
                  <th>SSC</th>
                  <th>SST</th>
                  <th>OOBC</th>
                  <th>GGen</th>
                  <th>DDivyangjan</th>
                  <th>OOthers</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>
                    Nature of appointment (Against Sanctioned post,
                    temporary,permanent)
                  </th>
                  <th>Name of the Department</th>
                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution.
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of full time teachers receiving awards from state
                    level, national level, international level
                  </th>
                  <th>Year of Award</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Name of the Awarding Agency</th>
                  <th>
                    Name of the award, fellowship, received from Government or
                    Government recognised bodies
                  </th>
                  <th>
                    Incentives/Type of the incentive given by the HEI in
                    recognition of the award
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Semester/ year</th>
                  <th>Program Code</th>
                  <th>
                    Last date of the last semester-end/ year- end examination
                  </th>
                  <th>
                    Date of declaration of results of semester-end/ year- end
                    examination
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if other than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>Student Unique Enrollment ID</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Uni_Criteria02?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria02_211?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_PrgCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_NoSeatSanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_eligible_applications_received?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_NoStdAdmit?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_211?.Uni_Desc32?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_SC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_ST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_OBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Gen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Divyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Others?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_SSC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_SST?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_OOBC?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_GGen?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_DDivyangjan?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_OOthers?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_212?.Uni_Desc33?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_TName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_PAN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_Designation?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_YOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_NoOfAppoint?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_DeptName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_TYofExp?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_TService?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_241_243?.Uni_Desc34?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_SerTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_YOfQuali?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_242?.Uni_Desc35?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_fullTeacherName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_YrAward?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_PAN1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_Desi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_AwardAgency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_AwardName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_Incentives?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_criteria02_244?.Uni_Desc36?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_251?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_251?.Uni_pname1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_251?.Uni_pcode1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_251?.Uni_semester?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Uni_Criteria02_251?.Uni_last)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Uni_Criteria02_251?.Uni_Date)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria02_251?.Uni_Desc37?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_pCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_NStdAppeared?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_NStdPassed?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_263?.Uni_Desc38?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_NStd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_Gender?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_SDomicile?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_NIndian?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_Email?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_MNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_YJoining?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_UID?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria02_271?.Uni_Desc39?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Uni_Criteria02_211?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_211?.Uni_PrgName1}</td>
                      <td>{item?.Uni_Criteria02_211?.Uni_PrgCode}</td>
                      <td>{item?.Uni_Criteria02_211?.Uni_NoSeatSanctioned}</td>
                      <td>
                        {
                          item?.Uni_Criteria02_211
                            ?.Uni_eligible_applications_received
                        }
                      </td>

                      <td>{item?.Uni_Criteria02_211?.Uni_NoStdAdmit}</td>
                      <td>
                        {item?.Uni_Criteria02_211?.Uni_Desc32?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria02_212?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_SC}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_ST}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_OBC}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_Gen}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_Divyangjan}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_Others}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_SSC}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_SST}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_OOBC}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_GGen}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_DDivyangjan}</td>
                      <td>{item?.Uni_Criteria02_212?.Uni_OOthers}</td>
                      <td>
                        {item?.Uni_Criteria02_212?.Uni_Desc33?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria02_241_243?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_TName}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_PAN}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_Designation}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_YOfAppoint}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_NoOfAppoint}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_DeptName}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_TYofExp}</td>
                      <td>{item?.Uni_Criteria02_241_243?.Uni_TService}</td>
                      <td>
                        {item?.Uni_Criteria02_241_243?.Uni_Desc34?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria02_242?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_242?.Uni_SerTeacher}</td>
                      <td>{item?.Uni_Criteria02_242?.Uni_Qualify}</td>
                      <td>{item?.Uni_Criteria02_242?.Uni_YOfQuali}</td>
                      <td>
                        {item?.Uni_Criteria02_242?.Uni_Desc35?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_criteria02_244?.Uni_criteria}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_fullTeacherName}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_YrAward}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_PAN1}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_Desi}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_AwardAgency}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_AwardName}</td>
                      <td>{item?.Uni_criteria02_244?.Uni_Incentives}</td>
                      <td>
                        {item?.Uni_criteria02_244?.Uni_Desc36?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria02_251?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_251?.Uni_pname1}</td>
                      <td>{item?.Uni_Criteria02_251?.Uni_semester}</td>
                      <td>{item?.Uni_Criteria02_251?.Uni_pcode1}</td>
                      <td>
                        {moment(item?.Uni_Criteria02_251?.Uni_last).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Uni_Criteria02_251?.Uni_Date).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {item?.Uni_Criteria02_251?.Uni_Desc37?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria02_263?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_PrgName2}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_pCode}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_NStdAppeared}</td>
                      <td>{item?.Uni_Criteria02_263?.Uni_NStdPassed}</td>
                      <td>
                        {item?.Uni_Criteria02_263?.Uni_Desc38?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria02_271?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_PrgName3}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_NStd}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_Gender}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_Category}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_SDomicile}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_NIndian}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_Email}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_MNo}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_YJoining}</td>
                      <td>{item?.Uni_Criteria02_271?.Uni_UID}</td>
                      <td>
                        {item?.Uni_Criteria02_271?.Uni_Desc39?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={(e) => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            setedit6(false);
            setedit7(false);
            setedit8(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 2 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program name
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Uni_Criteria02_211?.Uni_PrgName1}
                        onChange={(e) => {
                          setUni_PrgName1(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_211?.Uni_PrgName1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program code
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_211?.Uni_PrgCode}
                        onChange={(e) => {
                          setUni_PrgCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_211?.Uni_PrgCode}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of seats sanctioned
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria02_211?.Uni_NoSeatSanctioned
                        }
                        onChange={(e) => {
                          setUni_NoSeatSanctioned(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_211?.Uni_NoSeatSanctioned}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of eligible applications received
                  </label>

                  {edit1 ? (
                    <>
                      {" "}
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria02_211
                            ?.Uni_eligible_applications_received
                        }
                        onChange={(e) => {
                          setUni_eligible_applications_received(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {
                        Value?.Uni_Criteria02_211
                          ?.Uni_eligible_applications_received
                      }
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students admitted
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_211?.Uni_NoStdAdmit}
                        onChange={(e) => {
                          setUni_NoStdAdmit(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_211?.Uni_NoStdAdmit}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        rows={3}
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_211?.Uni_Desc32}
                        onChange={(e) => setUni_Desc32(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_211?.Uni_Desc32}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload1"
                        id="upload1"
                        accept="image/*"
                        onChange={(e) => setUni_Link32(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_211?.Uni_Link32}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="mt-3 text-end ">
                      {edit1 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria02_211(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.1.2------ */}
                <h6>
                  Average enrollement percentage(Average of last five years)
                </h6>
                <h6 className="text-center text-decoration-underline">
                  Number of seats earmarked for reserved category as per GOI or
                  State Government rule
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_SC}
                        onChange={(e) => {
                          setUni_SC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_SC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST{" "}
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_ST}
                        onChange={(e) => {
                          setUni_ST(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_ST}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_OBC}
                        onChange={(e) => {
                          setUni_OBC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_OBC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_Gen}
                        onChange={(e) => {
                          setUni_Gen(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_Gen}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_Divyangjan}
                        onChange={(e) => {
                          setUni_Divyangjan(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_Divyangjan}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_Others}
                        onChange={(e) => {
                          setUni_Others(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_Others}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students admitted from the reserved category
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_SSC}
                        onChange={(e) => {
                          setUni_SSC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_SSC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_SST}
                        onChange={(e) => {
                          setUni_SST(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_SST}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_OOBC}
                        onChange={(e) => {
                          setUni_OOBC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_OOBC}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_GGen}
                        onChange={(e) => {
                          setUni_GGen(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_GGen}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_DDivyangjan}
                        onChange={(e) => {
                          setUni_DDivyangjan(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_DDivyangjan}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_OOthers}
                        onChange={(e) => {
                          setUni_OOthers(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_OOthers}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_212?.Uni_Desc33}
                        onChange={(e) => setUni_Desc33(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_Desc33}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="upload2"
                        onChange={(e) => setUni_Link33(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_212?.Uni_Link33}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="mt-3 text-end ">
                      {edit2 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria02_212(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.1 & 2.4.3
                </span>
              </div>{" "}
              <div className="row  m-0 ">
                {/* -------2.4.1 & 2.4.3------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Full-time teacher
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_241_243?.Uni_TName}
                        onChange={(e) => {
                          setUni_TName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_TName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    PAN
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_241_243?.Uni_PAN}
                        onChange={(e) => {
                          setUni_PAN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_PAN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Designation
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria02_241_243?.Uni_Designation
                        }
                        onChange={(e) => {
                          setUni_Designation(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_Designation}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of appointment
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria02_241_243?.Uni_YOfAppoint
                        }
                        onChange={(e) => {
                          setUni_YOfAppoint(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_YOfAppoint}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria02_241_243?.Uni_NoOfAppoint
                        }
                        onChange={(e) => {
                          setUni_NoOfAppoint(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_NoOfAppoint}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria02_241_243?.Uni_DeptName
                        }
                        onChange={(e) => {
                          setUni_DeptName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_DeptName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total years of Experience in the same institution
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_241_243?.Uni_TYofExp}
                        onChange={(e) => {
                          setUni_TYofExp(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_TYofExp}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria02_241_243?.Uni_TService
                        }
                        onChange={(e) => {
                          setUni_TService(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_TService}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_241_243?.Uni_Desc34}
                        onChange={(e) => setUni_Desc34(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_Desc34}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3"
                        placeholder={Uni_Link34}
                        onChange={(e) => setUni_Link34(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_241_243?.Uni_Link34}</>
                  )}
                </div>
              </div>
              {Value?.approve == "true" ? (
                <></>
              ) : (
                <div className="row mt-3">
                  <div className="mt-3 text-end ">
                    {edit3 ? (
                      <>
                        <button
                          className="btn btn-success"
                          style={{ color: "white" }}
                          onClick={(e) => {
                            editUni_Criteria02_241_243(e, Value?._id);
                          }}
                        >
                          Submit
                        </button>
                      </>
                    ) : (
                      <>
                        {" "}
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit3(true)}
                        >
                          Edit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.2------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_242?.Uni_SerTeacher}
                        onChange={(e) => {
                          setUni_SerTeacher(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_242?.Uni_SerTeacher}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_242?.Uni_Qualify}
                        onChange={(e) => {
                          setUni_Qualify(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_242?.Uni_Qualify}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of obtaining the qualification
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_242?.Uni_YOfQuali}
                        onChange={(e) => {
                          setUni_YOfQuali(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_242?.Uni_YOfQuali}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_242?.Uni_Desc35}
                        onChange={(e) => setUni_Desc35(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_242?.Uni_Desc35}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload4"
                        id="upload4"
                        placeholder={Uni_Link35}
                        onChange={(e) => setUni_Link35(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_242?.Uni_Link35}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="col-12 text-end ">
                      {edit4 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria02_242(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.4
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.4------ */}
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of full time teachers receiving awards from state
                      level, national level, international level
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={
                            Value?.Uni_criteria02_244?.Uni_fullTeacherName
                          }
                          onChange={(e) => {
                            setUni_fullTeacherName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_criteria02_244?.Uni_fullTeacherName}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of Award
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Value?.Uni_criteria02_244?.Uni_YrAward}
                          onChange={(e) => {
                            setUni_YrAward(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_criteria02_244?.Uni_YrAward}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      PAN
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_criteria02_244?.Uni_PAN1}
                          onChange={(e) => {
                            setUni_PAN1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_criteria02_244?.Uni_PAN1}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Designation
                    </label>

                    {edit5 ? (
                      <>
                        {" "}
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Uni_criteria02_244?.Uni_Desi}
                          onChange={(e) => {
                            setUni_Desi(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_criteria02_244?.Uni_Desi}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the Awarding Agency{" "}
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={
                            Value?.Uni_criteria02_244?.Uni_AwardAgency
                          }
                          onChange={(e) => {
                            setUni_AwardAgency(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_criteria02_244?.Uni_AwardAgency}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the award, fellowship, received from Government or
                    Government recognised bodies
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_criteria02_244?.Uni_AwardName}
                        onChange={(e) => {
                          setUni_AwardName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_criteria02_244?.Uni_AwardName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Incentives/Type of the incentive given by the HEI in
                    recognition of the award{" "}
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_criteria02_244?.Uni_Incentives}
                        onChange={(e) => {
                          setUni_Incentives(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_criteria02_244?.Uni_Incentives}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className="vi_0"
                        id="name"
                        // cols={57}
                        rows={3}
                        placeholder={Value?.Uni_criteria02_244?.Uni_Desc36}
                        onChange={(e) => setUni_Desc36(e.target.value)}
                      ></textarea>
                    </>
                  ) : (
                    <>{Value?.Uni_criteria02_244?.Uni_Desc36}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload5">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload5"
                        id="upload5"
                        accept="image/*"
                        placeholder={Uni_Link36}
                        onChange={(e) => setUni_Link36(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_criteria02_244?.Uni_Link36}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="mt-3 text-end ">
                      {edit5 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_criteria02_244(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.5.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.5.1------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme Name{" "}
                  </label>

                  {edit6 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Uni_Criteria02_251?.Uni_pname1}
                        onChange={(e) => setUni_pname1(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_251?.Uni_pname1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_251?.Uni_pcode1}
                        onChange={(e) => {
                          setUni_pcode1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_251?.Uni_pcode1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Semester/ year
                  </label>

                  {edit6 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Uni_Criteria02_251?.Uni_semester}
                        onChange={(e) => setUni_semester(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Semester-01">Semester-01</option>
                        <option value="Semester-02">Semester-02</option>
                        <option value="Semester-03">Semester-03</option>
                        <option value="Semester-04">Semester-04</option>
                        <option value="Semester-05">Semester-05</option>
                        <option value="Semester-06">Semester-06</option>
                        <option value="Semester-07">Semester-07</option>
                        <option value="Semester-08">Semester-08</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_251?.Uni_semester}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Last date of the last semester-end/ year- end examination
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_251?.Uni_last}
                        onChange={(e) => {
                          setUni_last(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_251?.Uni_last}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date of declaration of results of semester-end/ year- end
                    examination{" "}
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_251?.Uni_Date}
                        onChange={(e) => {
                          setUni_Date(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_251?.Uni_Date}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        id="name"
                        // cols={57}
                        rows={3}
                        placeholder={Value?.Uni_Criteria02_251?.Uni_Desc37}
                        onChange={(e) => setUni_Desc37(e.target.value)}
                      ></textarea>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_251?.Uni_Desc37}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload76">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload6"
                        id="upload6"
                        accept="image/*"
                        placeholder={Uni_Link37}
                        onChange={(e) => setUni_Link37(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_251?.Uni_Link37}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="mt-3 text-end ">
                      {edit6 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria02_251(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit6(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.6.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <h6>Criteria02--(2.6.3)</h6>
                {/* -------2.6.3------ */}
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Name
                    </label>

                    {edit7 ? (
                      <>
                        <select
                          className="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={Value?.Uni_Criteria02_263?.Uni_PrgName2}
                          onChange={(e) => setUni_PrgName2(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="BCA">BCA</option>
                          <option value="BSC">BSC</option>
                          <option value="BBA">BBA</option>
                          <option value="B.Com">B.Com</option>
                        </select>
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria02_263?.Uni_PrgName2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Program Code
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria02_263?.Uni_pCode}
                          onChange={(e) => setUni_pCode(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria02_263?.Uni_pCode}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students appeared in the final year examination
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={
                            Value?.Uni_Criteria02_263?.Uni_NStdAppeared
                          }
                          onChange={(e) => setUni_NStdAppeared(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria02_263?.Uni_NStdAppeared}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students passed in final year examination
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_263?.Uni_NStdPassed}
                        onChange={(e) => setUni_NStdPassed(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_263?.Uni_NStdPassed}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <textarea
                        className="vi_0"
                        id="name"
                        // cols={57}
                        rows={3}
                        placeholder={Value?.Uni_Criteria02_263?.Uni_Desc38}
                        onChange={(e) => setUni_Desc38(e.target.value)}
                      ></textarea>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_263?.Uni_Desc38}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload7">
                    {" "}
                    Document
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload7"
                        id="upload7"
                        accept="image/*"
                        placeholder={Uni_Link38}
                        onChange={(e) => {
                          setUni_Link38(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_263?.Uni_Link38}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="mt-3 text-end ">
                      {edit7 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria02_263(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit7(true)}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the student
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_271?.Uni_NStd}
                        onChange={(e) => {
                          setUni_NStd(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_NStd}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gender
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_271?.Uni_Gender}
                        onChange={(e) => {
                          setUni_Gender(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_Gender}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_271?.Uni_Category}
                        onChange={(e) => {
                          setUni_Category(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_Category}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State of Domicile
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_271?.Uni_SDomicile}
                        onChange={(e) => {
                          setUni_SDomicile(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_SDomicile}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Nationality if other than Indian
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_271?.Uni_NIndian}
                        onChange={(e) => {
                          setUni_NIndian(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_NIndian}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Email-ID
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="email"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_271?.Uni_Email}
                        onChange={(e) => {
                          setUni_Email(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_Email}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program name
                  </label>

                  {edit8 ? (
                    <>
                      <select
                        className="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Uni_Criteria02_271?.Uni_PrgName3}
                        onChange={(e) => setUni_PrgName3(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_PrgName3}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Mobile Number
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_271?.Uni_MNo}
                        onChange={(e) => {
                          setUni_MNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_MNo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of joining
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_271?.Uni_YJoining}
                        onChange={(e) => {
                          setUni_YJoining(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_YJoining}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Student Unique Enrollment ID
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria02_271?.Uni_UID}
                        onChange={(e) => {
                          setUni_UID(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_UID}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>

                  {edit8 ? (
                    <>
                      <textarea
                        className="vi_0"
                        id="name"
                        // cols={57}
                        rows={3}
                        placeholder={Value?.Uni_Criteria02_271?.Uni_Desc39}
                        onChange={(e) => setUni_Desc39(e.target.value)}
                      ></textarea>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_Desc39}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload8">
                    {" "}
                    Document
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload8"
                        id="upload8"
                        accept="image/*"
                        placeholder={Uni_Link39}
                        onChange={(e) => setUni_Link39(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria02_271?.Uni_Link39}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="mt-3 text-end ">
                      {edit8 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria02_271(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit8(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria02_211?.Uni_Desc32}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria02_212?.Uni_Desc33}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria02_241_243?.Uni_Desc34}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria02_242?.Uni_Desc35}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_criteria02_244?.Uni_Desc36}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria02_251?.Uni_Desc37}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria02_263?.Uni_Desc38}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_8 */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria02_271?.Uni_Desc39}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_211?.Uni_Link32}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_212?.Uni_Link33}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_241_243?.Uni_Link34}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_242?.Uni_Link35}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_criteria02_244?.Uni_Link36}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_251?.Uni_Link37}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_263?.Uni_Link38}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 8 model */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria02/${View?.Uni_Criteria02_271?.Uni_Link39}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Uni_Criteria02;
