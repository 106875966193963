import React, { useEffect, useState } from "react";
import { Button, Image, Modal, Table } from "react-bootstrap";
import axios from "axios";
const Collegeslist = () => {
  const [collegeApp, setCollegeApp] = useState([]);
  const getallAppColleges = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/app/approvedlist"
      );
      if (res.status === 200) {
        setCollegeApp(res.data.Register);
      }
    } catch (error) {
      console.log(error);
      setCollegeApp(error.response.data.Register);
    }
  };

  useEffect(() => {
    getallAppColleges();
  }, []);
  return (
    <div className="conrainer">
      <div className="row m-0">
        <div className="header-01">
          <h2
            style={{
              textAlign: "center",
              fontFamily: "fangsong",
              fontWeight: "600",
            }}
          >
            Colleges List
          </h2>
        </div>
        <div className="header-02"></div>
      </div>
      <br />
      <div className="row m-0">
        <Table
          responsive
          striped
          bordered
          hover
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr
              style={{
                textAlign: "center",
                color: "white",
                backgroundColor: "#161632",
              }}
            >
              <th>Sl.No</th>
              <th>HEI Code</th>
              <th>HEI Name</th>
              <th>HEI</th>
              <th>WebsiteLink</th>
              <th>College Image</th>
              <th>College Logo</th>
            </tr>
          </thead>
          <tbody>
            {collegeApp
              ?.filter((item) => item?.isApprove)
              ?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item?.HeiCode}</td>
                    <td>{item?.HeiName}</td>
                    <td>{item?.HEI}</td>
                    <td>{item?.WebsiteLink}</td>
                    <td>
                      <Image
                        src={`https://brightnaac.co.in/register/${item?.CollegeImg}`}
                        alt="pic"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>
                      <Image
                        src={`https://brightnaac.co.in/register/${item?.CollegeLogo}`}
                        alt="pic"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Collegeslist;
