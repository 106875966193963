import React from "react";

const PG6612aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Participative management</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.PG_Criteria06_612?.ParticipativeMng}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Acadamic monitoring committee</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.PG_Criteria06_612?.AcadamicMont}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Office Order</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.PG_Criteria06_612?.OficeOrder}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Decentralisation_20-21</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.PG_Criteria06_612?.Decentralisation1}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Decentralisation_21-22</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.PG_Criteria06_612?.Decentralisation2}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Decentralisation_2022-23</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.PG_Criteria06_612?.Decentralisation3}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Academic Monitoring Committee_2022-23</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.PG_Criteria06_612?.AcadamicMont1}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG6612aqar;
