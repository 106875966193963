import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Nav } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
const UgCriteria6 = () => {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const [UG_Criteria06, setUG_Criteria06] = useState([]);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const navigate = useNavigate();
  const [View, setView] = useState([]);

  //======== Criteria 6.1.1 =============
  const [show611, setShow611] = useState(false);
  const handleClose611 = () => setShow611(false);
  const handleShow611 = () => setShow611(true);

  const [show601, setShow601] = useState(false);
  const handleClose601 = () => setShow601(false);
  const handleShow601 = () => setShow601(true);

  const [show6001, setShow6001] = useState(false);
  const handleClose6001 = () => setShow6001(false);
  const handleShow6001 = () => setShow6001(true);

  //======== Criteria 6.1.2 =============
  const [show612, setShow612] = useState(false);
  const handleClose612 = () => setShow612(false);
  const handleShow612 = () => setShow612(true);

  const [show6121, setShow6121] = useState(false);
  const handleClose6121 = () => setShow6121(false);
  const handleShow6121 = () => setShow6121(true);

  const [show6122, setShow6122] = useState(false);
  const handleClose6122 = () => setShow6122(false);
  const handleShow6122 = () => setShow6122(true);

  const [show6123, setShow6123] = useState(false);
  const handleClose6123 = () => setShow6123(false);
  const handleShow6123 = () => setShow6123(true);

  const [show6124, setShow6124] = useState(false);
  const handleClose6124 = () => setShow6124(false);
  const handleShow6124 = () => setShow6124(true);

  const [show6125, setShow6125] = useState(false);
  const handleClose6125 = () => setShow6125(false);
  const handleShow6125 = () => setShow6125(true);

  const [show6126, setShow6126] = useState(false);
  const handleClose6126 = () => setShow6126(false);
  const handleShow6126 = () => setShow6126(true);

  // ================Show doc1 modal======================

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [smShow, setSmShow] = useState(false);

  // ====================================

  // post method for criteria

  const [yoi1, setyoi1] = useState("");
  const [yoi2, setyoi2] = useState("");
  const [yoi3, setyoi3] = useState("");
  const [yoi4, setyoi4] = useState("");
  const [doc1, setdoc1] = useState("");
  const [doc2, setdoc2] = useState("");
  const [doc3, setdoc3] = useState("");
  const [doc4, setdoc4] = useState("");
  const [ugdesc1, setugdesc1] = useState("");

  //============= criteria 632 =============

  const [UGNot, setUGNot] = useState("");
  const [UGAsr, setUGAsr] = useState("");
  const [NOC, setNOC] = useState("");
  const [Nop, setNop] = useState("");
  const [ugdesc2, setugdesc2] = useState("");
  const [doc5, setdoc5] = useState("");

  //============= criteria 633 =============
  const [Sdate, setSdate] = useState("");
  const [Edate, setEdate] = useState("");
  const [title_dev, settitle_dev] = useState("");
  const [titlep, settitlep] = useState("");
  const [Nfp, setNfp] = useState("");
  const [doc6, setdoc6] = useState("");
  const [ugdesc3, setugdesc3] = useState("");

  //============= criteria 634 ==============

  const [Sdate1, setSdate1] = useState("");
  const [Edate1, setEdate1] = useState("");
  const [namet, setnamet] = useState("");
  const [prgm50, setprgm50] = useState("");
  const [doc7, setdoc7] = useState("");
  const [ugdesc4, setugdesc4] = useState("");

  //============= criteria 642 =============

  const [pgrant, setpgrant] = useState("");
  const [Fundg, setFundg] = useState("");
  const [namefgov, setnamefgov] = useState("");
  const [doc8, setdoc8] = useState("");
  const [ugdesc5, setugdesc5] = useState("");

  //============= criteria 653 =============

  const [Sdate2, setSdate2] = useState("");
  const [Edate2, setEdate2] = useState("");
  const [nirfs, setNirfs] = useState("");
  const [iqac, setIqac] = useState("");
  const [nba, setNba] = useState("");
  const [cswq, setCswq] = useState("");
  const [iaa, setiaa] = useState("");
  const [doc9, setdoc9] = useState("");
  const [ugdesc6, setugdesc6] = useState("");

  // =============== Edit ==================

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);

  // =======================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/ugadmin/approveCriteria/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria06Details();
          setedit1(false);
          setedit2(false);
          setedit3(false);
          setedit4(false);
          setedit5(false);
          setedit6(false);
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //===================================

  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================== Edit Code ==============================
  const editCriteria06_623 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = ugdesc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (ugdesc1) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/ugadmin/editCriteria06_623/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          yoi1: yoi1,
          doc1: doc1,
          yoi2: yoi2,
          doc2: doc2,
          yoi3: yoi3,
          doc3: doc3,
          yoi4: yoi4,
          doc4: doc4,
          ugdesc1: ugdesc1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_632 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = ugdesc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (ugdesc2) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/ugadmin/editCriteria06_632/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          UGNot: UGNot,
          UGAsr: UGAsr,
          NOC: NOC,
          Nop: Nop,
          ugdesc2: ugdesc2,
          doc5: doc5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_633 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = ugdesc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (ugdesc3) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/ugadmin/editCriteria06_633/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Sdate: Sdate,
          Edate: Edate,
          title_dev: title_dev,
          titlep: titlep,
          Nfp: Nfp,
          ugdesc3: ugdesc3,
          doc6: doc6,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria06_634 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = ugdesc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (ugdesc4) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/ugadmin/editCriteria06_634/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Sdate1: Sdate1,
          prgm50: prgm50,
          doc7: doc7,
          Edate1: Edate1,
          namet: namet,
          ugdesc4: ugdesc4,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_642 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = ugdesc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (ugdesc5) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/ugadmin/editCriteria06_642/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          pgrant: pgrant,
          Fundg: Fundg,
          namefgov: namefgov,
          doc8: doc8,
          ugdesc5: ugdesc5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_653 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = ugdesc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (ugdesc6) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/ugadmin/editCriteria06_653/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Sdate2: Sdate2,
          Edate2: Edate2,
          nba: nba,
          cswq: cswq,
          nirfs: nirfs,
          iaa: iaa,
          doc9: doc9,
          iqac: iqac,
          ugdesc6: ugdesc6,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const ugCriteria06Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/ugadmin/getcri6"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria06);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria06);
    }
  };

  // =================================================================

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      ugCriteria06Details();
    }
  }, []);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-8 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-06
              </span>
            </div>

            <div className=" col-lg-4 lab text-end ">
              <Link to="/ugcriteria6">
                <button>
                  Add Criteria 6
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0 ">
            <div className=" col-lg-3 do-sear d-flex p-0  ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeFrom}
                picker="year"
                placeholder="Select Year (from)"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                picker="year"
                placeholder="Select Year (to)"
                className="vi_0"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className="">
            <Nav defaultActiveKey="/UG_06_623">
              {/* <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/UG_06_611")}
                >
                  <Button className="criteriabtn-0 btn-sub1">6.1.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/UG_06_612")}
                >
                  <Button className="criteriabtn-0 btn-sub1">6.1.2</Button>
                </Nav.Link>
              </Nav.Item> */}

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UG_06_611")}
                >
                  <Button className="criteriabtn btn-sub">6.1.1</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UG_06_612")}
                >
                  <Button className="criteriabtn btn-sub">6.1.2</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UG_06_623")}
                >
                  <Button className="criteriabtn btn-sub">6.2.3</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UG_06_632")}
                >
                  <Button className="criteriabtn btn-sub">6.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UG_06_633")}
                >
                  {" "}
                  <Button className="criteriabtn btn-sub">6.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-4"
                  onClick={() => navigate("/UG_06_634")}
                >
                  {" "}
                  <Button className="criteriabtn btn-sub">6.3.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UG_06_642")}
                >
                  <Button className="criteriabtn btn-sub">6.4.2</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UG_06_653")}
                >
                  <Button className="criteriabtn btn-sub">6.5.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              striped
              bordered
              hover
              responsive
              style={{ minWidth: "10000px", textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* Criteria 06 6.1.1 */}
                  <th>Criteria</th>
                  <th>Vision_Mission</th>
                  <th>Vision_Mission_Statement</th>
                  <th>Vision_Mission_2022-23</th>

                  {/* Criteria 06 6.1.2 */}
                  <th>Criteria</th>
                  <th>Participative management</th>
                  <th>Acadamic monitoring committee</th>
                  <th>Office Order</th>
                  <th>Decentralisation_20-21</th>
                  <th>Decentralisation_21-22</th>
                  <th>Decentralisation_2022-23</th>
                  <th>Academic Monitoring Committee_2022-23</th>

                  {/*  Criteria 06 6.2.3  */}

                  <th>Criteria</th>
                  <th>Administration</th>
                  <th>Finance and Accounts</th>
                  <th>Student Admissions and Support</th>
                  <th>Examinations</th>
                  <th>Description</th>

                  {/*  Criteria 06 6.3.2  */}

                  <th>Criteria</th>
                  <th>Name of the teacher</th>
                  <th>Amount of support received (in INR)</th>
                  <th>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </th>
                  <th>
                    Name of the professional body for which membership fee is
                    provided
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/*  Criteria 06 6.3.3  */}

                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>
                    {" "}
                    Title of the professional development program organised for
                    teaching staff
                  </th>
                  <th>
                    Title of the administrative training program organised for
                    non-teaching staff
                  </th>
                  <th>No. of participants</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/*  Criteria 06 6.3.4  */}

                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>Name of teacher who attended</th>
                  <th>Title of the program</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/*  Criteria 06 6.4.2  */}

                  <th>Criteria</th>
                  <th>Purpose of the Grant</th>
                  <th>Funds/ Grants received (INR in lakhs)</th>
                  <th>
                    Name of the non government funding agencies/ individuals
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/*  Criteria 06 6.5.3  */}

                  <th>Criteria</th>
                  <th> Regular meetings of the IQAC held</th>
                  <th>Conferences, Seminars, Workshops on quality conducted</th>
                  <th>
                    Collaborative quality initiatives with other institution(s)
                    (Provide name of the institution and activity)
                  </th>
                  <th>Participation in NIRF along with Status.</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>

                  <th>
                    Any other quality audit as recognized by the State, National
                    or International agencies (ISO certification, NBA and such
                    others)
                  </th>

                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.UGYear?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    pricipalDetails?._id == item1?.addedByPri &&
                    item1?.approve === "Pending" &&
                    // selectedYear === item1?.UGYear &&
                    (SearchItem === "" ||
                      item1?.UGCriteria06_623?.criteria?.includes(SearchItem) ||
                      item1?.UGYear?.includes(SearchItem) ||
                      item1?.UGCriteria06_623?.yoi1
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UGCriteria06_623?.yoi2
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UGCriteria06_623?.yoi3
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UGCriteria06_623?.yoi4
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UGCriteria06_623?.doc4
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UGCriteria06_623?.doc3
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UGCriteria06_623?.doc2
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UGCriteria06_623?.ugdesc1
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UGCriteria06_623?.doc1
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_632?.criteria?.includes(SearchItem) ||
                      item1?.UGYear?.includes(SearchItem) ||
                      item1?.UgCriteria06_632?.UGNot?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_632?.UGAsr?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_632?.NOC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_632?.Nop?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_632?.ugdesc2
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_632?.doc5
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_634?.criteria?.includes(SearchItem) ||
                      item1?.UGYear?.includes(SearchItem) ||
                      item1?.UgCriteria06_634?.Sdate1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_634?.Edate1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_634?.namet
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_634?.prgm50
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_634?.ugdesc4
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_634?.doc7
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_633?.criteria?.includes(SearchItem) ||
                      item1?.UGYear?.includes(SearchItem) ||
                      item1?.UgCriteria06_633?.Sdate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_633?.Edate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_633?.titlep
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_633?.Nfp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_633?.ugdesc3
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_633?.doc6
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_642?.criteria?.includes(SearchItem) ||
                      item1?.UGYear?.includes(SearchItem) ||
                      item1?.UgCriteria06_642?.pgrant
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_642?.Fundg?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_642?.namefgov
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_642?.ugdesc5
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_642?.doc8
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_653?.criteria?.includes(SearchItem) ||
                      item1?.UGYear?.includes(SearchItem) ||
                      item1?.UgCriteria06_653?.Sdate2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_653?.Edate2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UgCriteria06_653?.nirfs
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_653?.aaa
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_653?.isoc
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_653?.nba
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_653?.cswq
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_653?.iaa
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_653?.ugdesc6
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.UgCriteria06_653?.doc9
                        ?.toLowerCase()
                        ?.includes(SearchItem?.toLowerCase())) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.UGYear}</td>
                      {/* Criteria 06 6.1.1 */}
                      <td> {item?.UGCriteria06_611?.criteria}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow611();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow601();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6001();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>

                      {/* Criteria 06 6.1.2 */}

                      <td>{item?.UGCriteria06_612?.criteria}</td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow612();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6121();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6122();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6123();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6124();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6125();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            handleShow6126();
                            setView(item);
                          }}
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      </td>

                      {/* Criteria 06 6.2.3 */}
                      <td>{item?.UGCriteria06_623.criteria}</td>
                      <td>
                        <Table striped bordered hover responsive>
                          <thead>
                            <th>Year Of Implementation</th>
                            <th>Document</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.UGCriteria06_623?.yoi1}</td>
                              <td>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow1();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table striped bordered hover size="sm" responsive>
                          <thead>
                            <th>Year of implementation</th>
                            <th>Document</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.UGCriteria06_623?.yoi2}</td>
                              <td>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow2();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table striped bordered hover size="sm" responsive>
                          <thead>
                            <th>Year of implementation</th>
                            <th>Document</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.UGCriteria06_623?.yoi3}</td>
                              <td>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow3();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table striped bordered hover responsive>
                          <thead>
                            <th>Year of implementation</th>
                            <th>Document</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.UGCriteria06_623?.yoi4}</td>
                              <td>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow4();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        {item?.UGCriteria06_623?.ugdesc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      {/* Criteria 06 6.3.2 */}
                      <td>{item?.UgCriteria06_632.criteria}</td>
                      <td>{item?.UgCriteria06_632?.UGNot}</td>
                      <td>{item?.UgCriteria06_632?.UGAsr}</td>
                      <td>{item?.UgCriteria06_632?.NOC}</td>
                      <td>{item?.UgCriteria06_632?.Nop}</td>
                      <td>
                        {item?.UgCriteria06_632?.ugdesc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* Criteria 06 6.3.3 */}

                      <td>{item?.UgCriteria06_633.criteria}</td>
                      <td>{item?.UgCriteria06_633?.Sdate}</td>
                      <td>{item?.UgCriteria06_633?.Edate}</td>
                      <td>{item?.UgCriteria06_633?.title_dev} </td>
                      <td>{item?.UgCriteria06_633?.titlep}</td>
                      <td>{item?.UgCriteria06_633?.Nfp}</td>
                      <td>
                        {item?.UgCriteria06_633?.ugdesc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* Criteria 06 6.3.4 */}

                      <td>{item?.UgCriteria06_634.criteria}</td>
                      <td>{item?.UgCriteria06_634?.Sdate1}</td>
                      <td>{item?.UgCriteria06_634?.Edate1}</td>
                      <td>{item?.UgCriteria06_634?.namet}</td>
                      <td>{item?.UgCriteria06_634?.prgm50}</td>
                      <td>
                        {item?.UgCriteria06_634?.ugdesc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* Criteria 06 6.4.2 */}

                      <td>{item?.UgCriteria06_642.criteria}</td>
                      <td>{item?.UgCriteria06_642?.pgrant}</td>
                      <td>{item?.UgCriteria06_642?.Fundg}</td>
                      <td>{item?.UgCriteria06_642?.namefgov}</td>

                      <td>
                        {item?.UgCriteria06_642?.ugdesc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* Criteria 06 6.5.3 */}

                      <td>{item?.UgCriteria06_653.criteria}</td>
                      <td>{item?.UgCriteria06_653?.iqac}</td>
                      <td>{item?.UgCriteria06_653?.cswq}</td>
                      <td>{item?.UgCriteria06_653?.iaa}</td>
                      <td>{item?.UgCriteria06_653?.nirfs}</td>
                      <td>{item?.UgCriteria06_653?.Sdate2}</td>
                      <td>{item?.UgCriteria06_653?.Edate2}</td>
                      <td>{item?.UgCriteria06_653?.nba}</td>

                      <td>
                        {item?.UgCriteria06_653?.ugdesc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Criteria 6.1.1 Modal */}

          <Modal show={show611} onHide={handleClose611}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_611?.VisionMission && (
                    <>
                      {View?.UGCriteria06_611?.VisionMission.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_611?.VisionMission}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_611?.VisionMission}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_611?.VisionMission}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show601} onHide={handleClose601}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_611?.VisionMissionStatement && (
                    <>
                      {View?.UGCriteria06_611?.VisionMissionStatement.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_611?.VisionMissionStatement}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_611?.VisionMissionStatement}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_611?.VisionMissionStatement}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6001} onHide={handleClose6001}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_611?.VisionMissionyesr && (
                    <>
                      {View?.UGCriteria06_611?.VisionMissionyesr.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_611?.VisionMissionyesr}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_611?.VisionMissionyesr}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_611?.VisionMissionyesr}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Criteria 6.1.2 Modal */}

          <Modal show={show612} onHide={handleClose612}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_612?.ParticipativeMng && (
                    <>
                      {View?.UGCriteria06_612?.ParticipativeMng.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.ParticipativeMng}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.ParticipativeMng}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.ParticipativeMng}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6121} onHide={handleClose6121}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_612?.AcadamicMont && (
                    <>
                      {View?.UGCriteria06_612?.AcadamicMont.endsWith(".pdf") ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.AcadamicMont}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.AcadamicMont}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.AcadamicMont}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6122} onHide={handleClose6122}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_612?.OficeOrder && (
                    <>
                      {View?.UGCriteria06_612?.OficeOrder.endsWith(".pdf") ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.OficeOrder}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.OficeOrder}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.OficeOrder}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6123} onHide={handleClose6123}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_612?.Decentralisation1 && (
                    <>
                      {View?.UGCriteria06_612?.Decentralisation1.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation1}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation1}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation1}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6124} onHide={handleClose6124}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_612?.Decentralisation2 && (
                    <>
                      {View?.UGCriteria06_612?.Decentralisation2.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation2}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation2}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation2}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6125} onHide={handleClose6125}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_612?.Decentralisation3 && (
                    <>
                      {View?.UGCriteria06_612?.Decentralisation3.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation3}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation3}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation3}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={show6126} onHide={handleClose6126}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {View?.UGCriteria06_612?.Decentralisation3 && (
                    <>
                      {View?.UGCriteria06_612?.Decentralisation3.endsWith(
                        ".pdf"
                      ) ? (
                        <iframe
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation3}`}
                          width="100%"
                          height="400"
                          frameborder="0"
                          allowfullscreen
                        >
                          Sorry, your browser doesn't support embedded PDFs.
                          <a
                            href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation3}`}
                          >
                            Download the PDF
                          </a>{" "}
                          instead.
                        </iframe>
                      ) : (
                        <img
                          src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation3}`}
                          alt="Image"
                          width="100%"
                          height="auto"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_623?.doc1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_623?.doc2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_623?.doc3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_623?.doc4}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria06/${View?.UgCriteria06_632?.doc5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria06/${View?.UgCriteria06_633?.doc6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 10 model */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria06/${View?.UgCriteria06_634?.doc7}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 12 model */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria06/${View?.UgCriteria06_642?.doc8}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  ></object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 14 model */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria06/${View?.UgCriteria06_653?.doc9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  ></object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 15 model */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.UgCriteria06_653?.ugdesc6}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 13 model */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.UgCriteria06_642?.ugdesc5}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 11 model */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.UgCriteria06_634?.ugdesc4}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.UgCriteria06_633?.ugdesc3}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.UgCriteria06_632?.ugdesc2}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.UGCriteria06_623?.ugdesc1}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose5}>
                close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={() => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            setedit6(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton style={{ background: "#393186" }}>
            <Modal.Title>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold", color: "white" }}
              >
                UnderGraduate_Criteria-06
              </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-611
                </span>
              </div>

              <div className="row  m-0 ">
                <h6>
                  The governance of the institution is reflective of an
                  effective leadership in tune with the vision and mission of
                  the institution
                </h6>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Vision_Mission:</label>
                    {Value?.UGCriteria06_611?.VisionMission}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Vision_Mission_Statement:</label>
                    {Value?.UGCriteria06_611?.VisionMissionStatement}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Vision_Mission_2022-23:</label>
                    {Value?.UGCriteria06_611?.VisionMissionyesr}
                  </div>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-612
                </span>
              </div>

              <div className="row  m-0 ">
                <h6>
                  The institution practices decentralization and participative
                  management
                </h6>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Participative management:</label>
                    {Value?.UGCriteria06_612?.ParticipativeMng}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Acadamic monitoring committee:</label>
                    {Value?.UGCriteria06_612?.AcadamicMont}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Office Order:</label>
                    {Value?.UGCriteria06_612?.OficeOrder}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Decentralisation_20-21:</label>
                    {Value?.UGCriteria06_612?.Decentralisation1}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Decentralisation_21-22:</label>
                    {Value?.UGCriteria06_612?.Decentralisation2}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Decentralisation_2022-23:</label>
                    {Value?.UGCriteria06_612?.Decentralisation3}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Academic Monitoring Committee_2022-23:</label>
                    {Value?.UGCriteria06_612?.AcadamicMont1}
                  </div>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-623
                </span>
              </div>

              <div className="row  m-0 ">
                <h6>Administration</h6>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Year of implementation:</label>

                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.UGCriteria06_623?.yoi1}
                          className="vi_0"
                          onChange={(e) => {
                            setyoi1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.yoi1}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Document</label>

                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload1"
                          className="vi_0"
                          placeholder={doc1}
                          onChange={(e) => setdoc1(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.doc1}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row m-0  ">
                <h6>Finance and Accounts</h6>

                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Year of implementation:</label>

                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.UGCriteria06_623?.yoi2}
                          className="vi_0"
                          onChange={(e) => setyoi2(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.yoi2}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Document</label>

                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload2"
                          className="vi_0"
                          placeholder={doc2}
                          onChange={(e) => setdoc2(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.doc2}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row m-0  ">
                <h6>Student Admissions and Support</h6>

                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Year of implementation:</label>

                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.UGCriteria06_623.yoi3}
                          className="vi_0"
                          onChange={(e) => setyoi3(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.yoi3}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Document</label>

                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload3"
                          className="vi_0"
                          placeholder={doc3}
                          onChange={(e) => setdoc3(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.doc3}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row m-0 ">
                <h6>Examinations</h6>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Year of implementation:</label>

                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.UGCriteria06_623?.yoi4}
                          className="vi_0"
                          onChange={(e) => setyoi4(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.yoi4}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-3">
                    <label>Document</label>

                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload4"
                          className="vi_0"
                          placeholder={doc4}
                          onChange={(e) => setdoc4(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.UGCriteria06_623?.doc4}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-md-12 mb-3 m-0">
                  <label className="content1 " htmlFor="name">
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        placeholder={Value?.UGCriteria06_623?.ugdesc1}
                        onChange={(e) => setugdesc1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.UGCriteria06_623?.ugdesc1}</>
                  )}
                </div>
              </div>

              <div className="text-end ">
                {edit1 ? (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-success  "
                      onClick={(e) => {
                        editCriteria06_623(e, Value?._id);
                        handleClose();
                        setedit1(false);
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="editbtn"
                      type="submit"
                      onClick={setedit1}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-632
                </span>
              </div>
              <div className="row m-0 ">
                <div className="col-lg-12">
                  <div className="lab mb-3">
                    <label>Name of the teacher</label>

                    {edit2 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.UgCriteria06_632?.UGNot}
                          className="vi_0"
                          onChange={(e) => setUGNot(e.target.value)}
                        />{" "}
                      </>
                    ) : (
                      <div className="text-justify">
                        {Value?.UgCriteria06_632?.UGNot}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="lab mb-3">
                    <label>Amount of support received (in INR)</label>

                    {edit2 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.UgCriteria06_632?.UGAsr}
                          className="vi_0"
                          onChange={(e) => setUGAsr(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.UgCriteria06_632?.UGAsr}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <label>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={Value?.UgCriteria06_632?.NOC}
                        className="vi_0"
                        onChange={(e) => setNOC(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.UgCriteria06_632?.NOC}</>
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <label>
                    Name of the professional body for which membership fee is
                    provided
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={Value?.UgCriteria06_632?.Nop}
                        className="vi_0"
                        onChange={(e) => setNop(e.target.value)}
                      />{" "}
                    </>
                  ) : (
                    <>{Value?.UgCriteria06_632?.Nop}</>
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        type="text"
                        className="vi_0"
                        id="name"
                        placeholder={Value?.UgCriteria06_632?.ugdesc2}
                        onChange={(e) => {
                          setugdesc2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.UgCriteria06_632?.ugdesc2}</>
                  )}
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="lab mb-4">
                    <label>Document</label>

                    {edit2 ? (
                      <input
                        type="file"
                        placeholder=" "
                        className="vi_0"
                        onChange={(e) => setdoc5(e.target.files[0])}
                      />
                    ) : (
                      <>{Value?.UgCriteria06_632?.doc5}</>
                    )}
                  </div>
                </div>
              </div>

              <div className="text-end">
                {edit2 ? (
                  <Button
                    type="submit"
                    className="btn btn-success  "
                    onClick={(e) => {
                      editCriteria06_632(e, Value?._id);
                      handleClose();
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <>
                    {" "}
                    <Button
                      type="submit"
                      onClick={setedit2}
                      className="editbtn"
                    >
                      Edit
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-633
                </span>
              </div>
              <div className="row m-0">
                <div className=" col-lg-6 lab mb-3">
                  <label>Date (from)</label>

                  {edit3 ? (
                    <input
                      type="date"
                      placeholder={Value?.UgCriteria06_633?.Sdate}
                      className="vi_0"
                      onChange={(e) => setSdate(e.target.value)}
                    />
                  ) : (
                    <>
                      {moment(Value?.UgCriteria06_633?.Sdate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-3">
                  <label>Date (to) </label>

                  {edit3 ? (
                    <input
                      type="date"
                      placeholder={Value?.UgCriteria06_633?.Edate}
                      className="vi_0"
                      onChange={(e) => setEdate(e.target.value)}
                    />
                  ) : (
                    <>
                      {moment(Value?.UgCriteria06_633?.Edate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-3">
                  <label>
                    {" "}
                    Title of the professional development program organised for
                    teaching staff
                  </label>

                  {edit3 ? (
                    <input
                      type="text"
                      placeholder={Value?.UgCriteria06_633?.title_dev}
                      className="vi_0"
                      onChange={(e) => settitle_dev(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_633?.title_dev}</>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-3">
                  <label>
                    {" "}
                    Title of the administrative training program organised for
                    non-teaching staff
                  </label>

                  {edit3 ? (
                    <input
                      type="text"
                      placeholder={Value?.UgCriteria06_633?.titlep}
                      className="vi_0"
                      onChange={(e) => settitlep(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_633?.titlep}</>
                  )}
                </div>

                <div className=" col-lg-6 lab mb-3">
                  <label>No. of participants</label>

                  {edit3 ? (
                    <input
                      type="number"
                      placeholder={Value?.UgCriteria06_633?.Nfp}
                      className="vi_0"
                      onChange={(e) => setNfp(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_633?.Nfp}</>
                  )}
                </div>

                <div className=" col-lg-6 lab mb-3">
                  <label>Document</label>

                  {edit3 ? (
                    <input
                      type="file"
                      placeholder={doc6}
                      className="vi_0"
                      id="upload6"
                      onChange={(e) => setdoc6(e.target.files[0])}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_633?.doc6}</>
                  )}
                </div>
                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      placeholder={Value?.UgCriteria06_633?.ugdesc3}
                      onChange={(e) => {
                        setugdesc3(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_633?.ugdesc3}</>
                  )}
                </div>
              </div>

              <div className="text-end">
                {edit3 ? (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-success  "
                      onClick={(e) => {
                        editCriteria06_633(e, Value?._id);
                        handleClose();
                      }}
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-danger  "
                      onClick={setedit3}
                      // className="editbtn"
                    >
                      Edit
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-634
                </span>
              </div>
              <div className="row m-0">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from)</label>

                  {edit4 ? (
                    <input
                      type="date"
                      className="vi_0"
                      placeholder={Value?.UgCriteria06_634?.Sdate1}
                      onChange={(e) => setSdate1(e.target.value)}
                    />
                  ) : (
                    <>
                      {moment(Value?.UgCriteria06_634?.Sdate1).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to)</label>

                  {edit4 ? (
                    <input
                      type="date"
                      placeholder={Value?.UgCriteria06_634?.Edate1}
                      className="vi_0"
                      onChange={(e) => setEdate1(e.target.value)}
                    />
                  ) : (
                    <>
                      {moment(Value?.UgCriteria06_634?.Edate1).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row m-0">
                <div className=" col-lg-6 lab mb-4">
                  <label>Name of teacher who attended</label>

                  {edit4 ? (
                    <input
                      type="text"
                      placeholder={Value?.UgCriteria06_634?.namet}
                      className="vi_0"
                      onChange={(e) => setnamet(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_634?.namet}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Title of the program</label>

                  {edit4 ? (
                    <input
                      type="text"
                      placeholder={Value?.UgCriteria06_634?.prgm50}
                      className="vi_0"
                      onChange={(e) => setprgm50(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_634?.prgm50}</>
                  )}
                </div>
              </div>

              <div className="row m-0">
                <div className=" col-lg-12 lab mb-4">
                  <label>Document</label>

                  {edit4 ? (
                    <input
                      type="file"
                      className="vi_0"
                      placeholder={doc7}
                      onChange={(e) => setdoc7(e.target.files[0])}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_634?.doc7}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>

                  {edit4 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      placeholder={Value?.UgCriteria06_634?.ugdesc4}
                      onChange={(e) => {
                        setugdesc4(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_634?.ugdesc4}</>
                  )}
                </div>
              </div>

              <div className="text-end mt-4">
                {edit4 ? (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-success"
                      onClick={(e) => {
                        editCriteria06_634(e, Value?._id);
                        handleClose();
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      onClick={setedit4}
                      className="editbtn"
                    >
                      Edit
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-642
                </span>
              </div>
              <div className="row m-0">
                <div className=" col-lg-6 lab mb-4">
                  <label>Purpose of the Grant</label>

                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Value?.UgCriteria06_642?.pgrant}
                      className="vi_0"
                      onChange={(e) => setpgrant(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_642?.pgrant}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label> Funds/ Grants received (INR in lakhs)</label>

                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Value?.UgCriteria06_642?.Fundg}
                      className="vi_0"
                      onChange={(e) => setFundg(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_642?.Fundg}</>
                  )}
                </div>
              </div>
              <div className="row m-0">
                <div className="col-lg-12 mb-3">
                  <label>
                    {" "}
                    Name of the non government funding agencies/ individuals
                  </label>

                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Value?.UgCriteria06_642?.namefgov}
                      className="vi_0"
                      onChange={(e) => setnamefgov(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_642?.namefgov}</>
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <label>
                    Upload the Audited Statement of Accounts reflecting the
                    receipts
                  </label>

                  {edit5 ? (
                    <input
                      type="file"
                      className="vi_0"
                      onChange={(e) => setdoc8(e.target.files[0])}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_642?.doc8}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>

                  {edit5 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      placeholder={Value?.UgCriteria06_642?.ugdesc5}
                      onChange={(e) => {
                        setugdesc5(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_642?.ugdesc5}</>
                  )}
                </div>
              </div>

              <div className="text-end mt-4">
                {edit5 ? (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-success"
                      onClick={(e) => {
                        editCriteria06_642(e, Value?._id);
                        handleClose();
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      onClick={setedit5}
                      className="editbtn"
                    >
                      Edit
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-653
                </span>
              </div>
              <div className="col-lg-12 mb-4">
                <label>Regular meetings of the IQAC held</label>

                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.UGCriteria06_653?.iqac}
                    className="vi_0"
                    onChange={(e) => setIqac(e.target.value)}
                  />
                ) : (
                  <>{Value?.UGCriteria06_653?.iqac}</>
                )}
              </div>
              <div className="col-lg-12 mb-3">
                <label>
                  {" "}
                  Conferences, Seminars, Workshops on quality conducted{" "}
                </label>

                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.UgCriteria06_653?.cswq}
                    className="vi_0"
                    onChange={(e) => setCswq(e.target.value)}
                  />
                ) : (
                  <>{Value?.UgCriteria06_653?.cswq}</>
                )}
              </div>
              <div className="col-lg-12 mb-3">
                <label>
                  {" "}
                  Collaborative quality initiatives with other institution(s)
                  (Provide name of the institution and activity)
                </label>

                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.UgCriteria06_653?.iaa}
                    className="vi_0"
                    onChange={(e) => setiaa(e.target.value)}
                  />
                ) : (
                  <>{Value?.UgCriteria06_653?.iaa}</>
                )}
              </div>
              <div className="row m-0">
                <div className=" col-lg-12 lab mb-4">
                  <label> Participation in NIRF along with Status.</label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Value?.UgCriteria06_653?.nirfs}
                      className="vi_0"
                      onChange={(e) => setNirfs(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_653?.nirfs}</>
                  )}
                </div>
                <div className="lab">
                  <label>
                    Orientation programme on quality issues for teachers and
                    students,{" "}
                  </label>
                </div>
                <div className="row m-0">
                  <div className=" col-lg-6 lab mb-4">
                    <label>Date (from)</label>

                    {edit6 ? (
                      <input
                        type="date"
                        placeholder={Value?.UgCriteria06_653?.Sdate2}
                        className="vi_0"
                        onChange={(e) => setSdate2(e.target.value)}
                      />
                    ) : (
                      <>
                        {moment(Value?.UgCriteria06_653?.Sdate2).format(
                          "DD-MM-YYYY"
                        )}
                      </>
                    )}
                  </div>
                  <div className=" col-lg-6 lab mb-4">
                    <label>Date (to) </label>

                    {edit6 ? (
                      <input
                        type="date"
                        placeholder={Value?.UgCriteria06_653?.Edate2}
                        className="vi_0"
                        onChange={(e) => setEdate2(e.target.value)}
                      />
                    ) : (
                      <>
                        {moment(Value?.UgCriteria06_653?.Edate2).format(
                          "DD-MM-YYYY"
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 mb-3">
                  <label>
                    {" "}
                    Any other quality audit as recognized by the State, National
                    or International agencies (ISO certification, NBA and such
                    others)
                  </label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Value?.UgCriteria06_653?.nba}
                      className="vi_0"
                      onChange={(e) => setNba(e.target.value)}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_653?.nba}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>

                  {edit6 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      placeholder={Value?.UgCriteria06_653?.ugdesc6}
                      onChange={(e) => {
                        setugdesc6(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.UgCriteria06_653?.ugdesc6}</>
                  )}
                </div>
                <div className="lab mb-3">
                  <label> Document</label>

                  {edit6 ? (
                    <>
                      <input
                        type="file"
                        className="vi_0"
                        placeholder={Value?.UgCriteria06_653?.doc9}
                        onChange={(e) => setdoc9(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.UgCriteria06_653?.doc9}</>
                  )}
                </div>
              </div>

              <div className="text-end">
                {edit6 ? (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-success"
                      onClick={(e) => {
                        editCriteria06_653(e, Value?._id);
                        handleClose();
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      onClick={setedit6}
                      className="editbtn"
                    >
                      Edit
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  className="btn btn-success"
                  onClick={(e) => {
                    approveCriteria(e, true, Value?._id);

                    handleClose();
                    setedit1(false);
                    setedit2(false);
                    setedit3(false);
                    setedit4(false);
                    setedit5(false);
                    setedit6(false);
                  }}
                >
                  Approve
                </Button>{" "}
                <Button className="editbtn" onClick={() => setSmShow(true)}>
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              style={{ height: "200px" }}
              onChange={(e) => setremark(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => approveCriteria(e, false, Value?._id)}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UgCriteria6;
