import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineRadarChart, AiOutlineEye } from "react-icons/ai";
import moment from "moment";

const Uni_Criteria05 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);
  const [edit8, setedit8] = useState(false);

  const [Uni_Scheme, setUni_Scheme] = useState("");
  const [Uni_Std, setUni_Std] = useState("");
  const [Uni_Amt, setUni_Amt] = useState("");
  const [Uni_Std1, setUni_Std1] = useState("");
  const [Uni_Amt1, setUni_Amt1] = useState("");
  const [Uni_Std2, setUni_Std2] = useState("");
  const [Uni_Amt2, setUni_Amt2] = useState("");
  const [Uni_NGO, setUni_NGO] = useState("");
  const [Uni_Desc18, setUni_Desc18] = useState("");
  const [Uni_Link18, setUni_Link18] = useState("");

  const [Uni_Capabality, setUni_Capabality] = useState("");
  const [Uni_Date, setUni_Date] = useState("");
  const [Uni_Enrolled, setUni_Enrolled] = useState("");
  const [Uni_Agencies, setUni_Agencies] = useState("");
  const [Uni_Desc19, setUni_Desc19] = useState("");
  const [Uni_Link19, setUni_Link19] = useState("");

  const [Uni_HEI, setUni_HEI] = useState("");
  const [Uni_Number, setUni_Number] = useState("");
  const [Uni_Desc20, setUni_Desc20] = useState("");
  const [Uni_Link20, setUni_Link20] = useState("");

  const [Uni_Placed1, setUni_Placed1] = useState("");
  const [Uni_Graduate, setUni_Graduate] = useState("");
  const [Uni_employer, setUni_employer] = useState("");
  const [Uni_Package, setUni_Package] = useState("");
  const [Uni_Desc21, setUni_Desc21] = useState("");
  const [Uni_Link21, setUni_Link21] = useState("");

  const [Uni_Higher, setUni_Higher] = useState("");
  const [Uni_Graduat1, setUni_Graduat1] = useState("");
  const [Uni_Institute, setUni_Institute] = useState("");
  const [Uni_Admit, setUni_Admit] = useState("");
  const [Uni_Desc22, setUni_Desc22] = useState("");
  const [Uni_Link22, setUni_Link22] = useState("");

  const [Uni_No, setUni_No] = useState("");
  const [Uni_RollNo, setUni_RollNo] = useState("");
  const [Uni_StdSelected, setUni_StdSelected] = useState("");
  const [Uni_NET, setUni_NET] = useState("");
  const [Uni_SLET, setUni_SLET] = useState("");
  const [Uni_GATE, setUni_GATE] = useState("");
  const [Uni_GMAT, setUni_GMAT] = useState("");
  const [Uni_CAT, setUni_CAT] = useState("");
  const [Uni_GRE, setUni_GRE] = useState("");
  const [Uni_JAM, setUni_JAM] = useState("");
  const [Uni_IELTS, setUni_IELTS] = useState("");
  const [Uni_TOEFL, setUni_TOEFL] = useState("");
  const [Uni_Civil, setUni_Civil] = useState("");
  const [Uni_State, setUni_State] = useState("");
  const [Uni_OtherExam, setUni_OtherExam] = useState("");
  const [Uni_Total, setUni_Total] = useState("");
  const [Uni_GTotal, setUni_GTotal] = useState("");
  const [Uni_Desc23, setUni_Desc23] = useState("");
  const [Uni_Link23, setUni_Link23] = useState("");

  const [Uni_Medal, setUni_Medal] = useState("");
  const [Uni_Individual, setUni_Individual] = useState("");
  const [Uni_Inter, setUni_Inter] = useState("");
  const [Uni_Event, setUni_Event] = useState("");
  const [Uni_Student, setUni_Student] = useState("");
  const [Uni_Desc24, setUni_Desc24] = useState("");
  const [Uni_Link24, setUni_Link24] = useState("");

  const [Uni_Event1, setUni_Event1] = useState("");
  const [Uni_Event2, setUni_Event2] = useState("");
  const [Uni_Desc25, setUni_Desc25] = useState("");
  const [Uni_Link25, setUni_Link25] = useState("");

  //integrating get method
  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);
  const [Uni_Criteria05, setUni_Criteria05] = useState([]);
  const [Value, setValue] = useState({});

  //   ===============================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Uni_Cri5.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let table1 = `
        <table>
          <thead>
            <th>Number of students</th>
            <th>Amount</th>
          </thead>
          <tbody>
            <tr>
              <td>${item?.Uni_Criteria05_511?.Uni_Std}</td>
              <td>${item?.Uni_Criteria05_511?.Uni_Amt}</td>
            </tr>
          </tbody>
        </table>
      `;
        let table2 = `
        <table>
          <thead>
            <th>Number of students</th>
            <th>Amount</th>
          </thead>
          <tbody>
            <tr>
              <td>${item?.Uni_Criteria05_511?.Uni_Std1}</td>
              <td>${item?.Uni_Criteria05_511?.Uni_Amt1}</td>
            </tr>
          </tbody>
        </table>
      `;
        let table3 = `
      <table>
        <thead>
          <th>Number of students</th>
          <th>Amount</th>
        </thead>
        <tbody>
          <tr>
            <td>${item?.Uni_Criteria05_511?.Uni_Std2}</td>
            <td>${item?.Uni_Criteria05_511?.Uni_Amt2}</td>
          </tr>
        </tbody>
      </table>
    `;
        let obj = {
          //  Criteria 5_511

          Uni_Year: item?.Uni_Year,
          "Criteria_5.1.1": item?.Uni_Criteria05_511?.Uni_criteria,
          "Name of the scheme": item?.Uni_Criteria05_511?.Uni_Scheme,
          "Number of students benefited by government scheme and amount":
            table1,
          "Number of students benefited by the institution's schemes and amount":
            table2,
          "Number of students benefited by the non-government agencies (NGOs) and amount":
            table3,
          "Name of the NGO/agency": item?.Uni_Criteria05_511?.Uni_NGO,
          "Description (min 500 words)(5.1.1)":
            item?.Uni_Criteria05_511?.Uni_Desc18,
          "Upload the relevant document(5.1.1)":
            item?.Uni_Criteria05_511?.Uni_Link18,

          // Criteria 5_512
          "Criteria_5.1.2": item?.Uni_Criteria05_512?.Uni_criteria,
          "Name of the Activity conducted by the HEI to offer guidance for competitive examinations/ career counselling offered by the institution during the last five years":
            item?.Uni_Criteria05_512?.Uni_HEI,
          "Number of students attended / participated":
            item?.Uni_Criteria05_512?.Uni_Number,
          "Description (min 500 words)(5.1.2)":
            item?.Uni_Criteria05_512?.Uni_Desc20,
          "Upload the relevant document(5.1.2)":
            item?.Uni_Criteria05_512?.Uni_Link20,

          // Criteria 5_513

          "Criteria_5.1.3": item?.Uni_Criteria05_513?.Uni_criteria,
          "Name of the capacity development and skills enhancement schemes":
            item?.Uni_Criteria05_513?.Uni_Capabality,
          "Date of implementation (DD-MM-YYYY)": moment(
            item?.Uni_Criteria05_513?.Uni_Date
          ).format("DD-MM-YYYY"),
          "Number of students enrolled": item?.Uni_Criteria05_513?.Uni_Enrolled,
          "Name of the agencies/consultants involved with contact details (if any)":
            item?.Uni_Criteria05_513?.Uni_Agencies,
          "Description (min 500 words)(5.1.3)":
            item?.Uni_Criteria05_513?.Uni_Desc19,
          "Upload the relevant document(5.1.3)":
            item?.Uni_Criteria05_513?.Uni_Link19,

          // Criteria 5_521
          "Criteria_5.2.1": item?.Uni_Criteria05_521?.Uni_criteria,
          "Sl.no.": item?.Uni_Criteria05_521?.Uni_No,
          "Registration number/roll number for the exam":
            item?.Uni_Criteria05_521?.Uni_RollNo,
          "Names of students selected/ qualified":
            item?.Uni_Criteria05_521?.Uni_StdSelected,
          NET: item?.Uni_Criteria05_521?.Uni_NET,
          SLET: item?.Uni_Criteria05_521?.Uni_SLET,
          GATE: item?.Uni_Criteria05_521?.Uni_GATE,
          GMAT: item?.Uni_Criteria05_521?.Uni_GMAT,
          CAT: item?.Uni_Criteria05_521?.Uni_CAT,
          GRE: item?.Uni_Criteria05_521?.Uni_GRE,
          JAM: item?.Uni_Criteria05_521?.Uni_JAM,
          IELTS: item?.Uni_Criteria05_521?.Uni_IELTS,
          TOEFL: item?.Uni_Criteria05_521?.Uni_TOEFL,
          "Civil Services": item?.Uni_Criteria05_521?.Uni_Civil,
          "State government examinations": item?.Uni_Criteria05_521?.Uni_State,
          "Other examinations conducted by the State / Central Government Agencies (Specify)":
            item?.Uni_Criteria05_521?.Uni_OtherExam,
          Total: item?.Uni_Criteria05_521?.Uni_Total,
          "Grand Total": item?.Uni_Criteria05_521?.Uni_GTotal,
          "Description (min 500 words)(5.2.1)":
            item?.Uni_Criteria05_521?.Uni_Desc23,
          "Upload the relevant document(5.2.1)":
            item?.Uni_Criteria05_521?.Uni_Link23,

          // Criteria 5_522
          "Criteria_5.2.2": item?.Uni_Criteria05_522?.Uni_criteria,
          "Name of student placed": item?.Uni_Criteria05_522?.Uni_Placed1,
          "Program graduated from": item?.Uni_Criteria05_522?.Uni_Graduate,
          "Name of the employer with contact details":
            item?.Uni_Criteria05_522?.Uni_employer,
          "Pay package at appointment": item?.Uni_Criteria05_522?.Uni_Package,
          "Description (min 500 words)(5.2.2)":
            item?.Uni_Criteria05_522?.Uni_Desc21,
          "Upload the relevant document(5.2.2)":
            item?.Uni_Criteria05_522?.Uni_Link21,

          // Criteria 5_523
          "Criteria_5.2.3": item?.Uni_Criteria05_523?.Uni_criteria,
          "Name of student enrolling into higher education":
            item?.Uni_Criteria05_523?.Uni_Higher,
          "Program Graduated from": item?.Uni_Criteria05_523?.Uni_Graduat1,
          "Name of institution joined": item?.Uni_Criteria05_523?.Uni_Institute,
          "Name of program admitted to": item?.Uni_Criteria05_523?.Uni_Admit,
          "Description min 500 words(5.2.3)":
            item?.Uni_Criteria05_523?.Uni_Desc22,
          "Link to website of the Journal(5.2.3)":
            item?.Uni_Criteria05_523?.Uni_Link22,

          // Criteria 5_531
          "Criteria_5.3.1": item?.Uni_Criteria05_531?.Uni_criteria,
          "Name of the award/ medal ": item?.Uni_Criteria05_531?.Uni_Medal,
          "Team / Individual": item?.Uni_Criteria05_531?.Uni_Individual,
          "Inter-university / state / National/ International":
            item?.Uni_Criteria05_531?.Uni_Inter,
          "Name of the event": item?.Uni_Criteria05_531?.Uni_Event,
          "Name of the student": item?.Uni_Criteria05_531?.Uni_Student,
          "Description min 500 words(5.3.1)":
            item?.Uni_Criteria05_531?.Uni_Desc24,
          "Link to relevnt document(5.3.1)":
            item?.Uni_Criteria05_531?.Uni_Link24,

          // Criteria 5_533
          "Criteria_5.3.3": item?.Uni_Criteria05_533?.Uni_criteria,
          "Date of event/competition (DD-MM-YYYY) ": moment(
            item?.Uni_Criteria05_533?.Uni_Event1
          ).format("DD-MM-YYYY"),
          "Name of the event/competition": item?.Uni_Criteria05_533?.Uni_Event2,
          "Description min 500 words(5.3.3)":
            item?.Uni_Criteria05_533?.Uni_Desc25,
          "Link to relevnt document(5.3.3)":
            item?.Uni_Criteria05_533?.Uni_Link25,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editUni_Criteria05_511 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc18) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria05_511/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Scheme: Uni_Scheme,
          Uni_Std: Uni_Std,
          Uni_Amt: Uni_Amt,
          Uni_Std1: Uni_Std1,
          Uni_Amt1: Uni_Amt1,
          Uni_Std2: Uni_Std2,
          Uni_Amt2: Uni_Amt2,
          Uni_NGO: Uni_NGO,
          Uni_Desc18: Uni_Desc18,
          Uni_Link18: Uni_Link18,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri05details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria05_513 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc19) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria05_513/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Capabality: Uni_Capabality,
          Uni_Date: Uni_Date,
          Uni_Enrolled: Uni_Enrolled,
          Uni_Agencies: Uni_Agencies,
          Uni_Desc19: Uni_Desc19,
          Uni_Link19: Uni_Link19,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri05details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria05_512 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc20;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc20) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria05_512/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_HEI: Uni_HEI,
          Uni_Number: Uni_Number,
          Uni_Desc20: Uni_Desc20,
          Uni_Link20: Uni_Link20,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri05details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria05_522 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria05_522/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Placed1: Uni_Placed1,
          Uni_Graduate: Uni_Graduate,
          Uni_employer: Uni_employer,
          Uni_Package: Uni_Package,
          Uni_Desc21: Uni_Desc21,
          Uni_Link21: Uni_Link21,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri05details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editUni_Criteria05_523 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc22;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria05_523/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Higher: Uni_Higher,
          Uni_Graduat1: Uni_Graduat1,
          Uni_Institute: Uni_Institute,
          Uni_Admit: Uni_Admit,
          Uni_Desc22: Uni_Desc22,
          Uni_Link22: Uni_Link22,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri05details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria05_521 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc23) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria05_521/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_No: Uni_No,
          Uni_RollNo: Uni_RollNo,
          Uni_StdSelected: Uni_StdSelected,
          Uni_NET: Uni_NET,
          Uni_SLET: Uni_SLET,
          Uni_GATE: Uni_GATE,
          Uni_GMAT: Uni_GMAT,
          Uni_CAT: Uni_CAT,
          Uni_GRE: Uni_GRE,
          Uni_JAM: Uni_JAM,

          Uni_IELTS: Uni_IELTS,
          Uni_TOEFL: Uni_TOEFL,
          Uni_Civil: Uni_Civil,
          Uni_State: Uni_State,
          Uni_OtherExam: Uni_OtherExam,
          Uni_Total: Uni_Total,
          Uni_GTotal: Uni_GTotal,
          Uni_Desc23: Uni_Desc23,
          Uni_Link23: Uni_Link23,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri05details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria05_531 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc24;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria05_531/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Medal: Uni_Medal,
          Uni_Individual: Uni_Individual,
          Uni_Inter: Uni_Inter,
          Uni_Event: Uni_Event,
          Uni_Student: Uni_Student,
          Uni_Desc24: Uni_Desc24,
          Uni_Link24: Uni_Link24,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri05details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria05_533 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc25;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria05_533/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Event1: Uni_Event1,
          Uni_Event2: Uni_Event2,
          Uni_Desc25: Uni_Desc25,
          Uni_Link25: Uni_Link25,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri05details();
          setedit8(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getunicri05details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getunicri05details"
      );
      if (res.status == 200) {
        setAllDetails(res.data.unicriteria05);
        setUni_Criteria05(res.data.unicriteria05);
        setdata1(res.data.unicriteria05);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria05);
    }
  };

  useEffect(() => {
    getunicri05details();
  }, []);
  console.log(Uni_Criteria05);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-05
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/unicriteria005">
                <button className="btn btn-success ">
                  Add Criteria 5{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid ">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Name of the scheme</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Number of students</th>
                  <th>Amount</th>
                  <th>Name of the NGO/agency</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counselling offered by
                    the institution during the last five years
                  </th>
                  <th>Number of students attended / participated</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the capacity development and skills enhancement
                    schemes
                  </th>
                  <th>Date of implementation (DD-MM-YYYY)</th>
                  <th>Number of students enrolled</th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Registration number/roll number for the exam</th>
                  <th>Names of students selected/ qualified</th>
                  <th>NET</th>
                  <th>SLET</th>
                  <th>GATE</th>
                  <th>GMAT</th>
                  <th>CAT</th>
                  <th>GRE</th>
                  <th>JAM</th>
                  <th>IELTS</th>
                  <th>TOEFL</th>
                  <th>Civil Services</th>
                  <th>State government examinations</th>
                  <th>
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </th>
                  <th>Total</th>
                  <th>Grand Total</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student placed</th>
                  <th>Program graduated from</th>
                  <th>Name of the employer with contact details</th>
                  <th>Pay package at appointment</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of student enrolling into higher education</th>
                  <th>Program graduated from</th>
                  <th>Name of institution joined</th>
                  <th>Name of program admitted to</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the award/ medal</th>
                  <th>Team / Individual</th>
                  <th>Inter-university / state / National/ International</th>
                  <th>Name of the event</th>
                  <th>Name of the student</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Date of event/competition (DD-MM-YYYY)</th>
                  <th>Name of the event/competition</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Uni_Criteria05?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria05_511?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Scheme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Std?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Amt?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Std1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Amt1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Std2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Amt2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_NGO?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_511?.Uni_Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_512?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_512?.Uni_HEI?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_512?.Uni_Number?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_512?.Uni_Desc20?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_513?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_513?.Uni_Capabality?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Uni_Criteria05_513?.Uni_Date)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria05_513?.Uni_Enrolled?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_513?.Uni_Agencies?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_513?.Uni_Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_No?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_RollNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_StdSelected?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_NET?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_SLET?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_GATE?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_GMAT?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_CAT?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_GRE?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_JAM?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_IELTS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_TOEFL?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_Civil?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_State?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_OtherExam?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_GTotal?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_521?.Uni_Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_Placed1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_Graduate?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_employer?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_Package?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_522?.Uni_Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Higher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Graduat1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Institute?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Admit?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_523?.Uni_Desc22?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Medal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Individual?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Inter?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Event?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Student?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_531?.Uni_Desc24?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_533?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Uni_Criteria05_533?.Uni_Event1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria05_533?.Uni_Event2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria05_533?.Uni_Desc25?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Uni_Criteria05_511?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Scheme}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Std}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Amt}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Std1}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Amt1}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Std2}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_Amt2}</td>
                      <td>{item?.Uni_Criteria05_511?.Uni_NGO}</td>
                      <td>
                        {item?.Uni_Criteria05_511?.Uni_Desc18?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_512?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_512?.Uni_HEI}</td>
                      <td>{item?.Uni_Criteria05_512?.Uni_Number}</td>
                      <td>
                        {item?.Uni_Criteria05_512?.Uni_Desc20?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_513?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_513?.Uni_Capabality}</td>
                      <td>
                        {moment(item?.Uni_Criteria05_513?.Uni_Date).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria05_513?.Uni_Enrolled}</td>
                      <td>{item?.Uni_Criteria05_513?.Uni_Agencies}</td>
                      <td>
                        {item?.Uni_Criteria05_513?.Uni_Desc19?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_521?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_No}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_RollNo}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_StdSelected}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_NET}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_SLET}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GATE}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GMAT}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_CAT}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GRE}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_JAM}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_IELTS}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_TOEFL}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_Civil}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_State}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_OtherExam}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_Total}</td>
                      <td>{item?.Uni_Criteria05_521?.Uni_GTotal}</td>
                      <td>
                        {item?.Uni_Criteria05_521?.Uni_Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_522?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_Placed1}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_Graduate}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_employer}</td>
                      <td>{item?.Uni_Criteria05_522?.Uni_Package}</td>
                      <td>
                        {item?.Uni_Criteria05_522?.Uni_Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_523?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Higher}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Graduat1}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Institute}</td>
                      <td>{item?.Uni_Criteria05_523?.Uni_Admit}</td>
                      <td>
                        {item?.Uni_Criteria05_523?.Uni_Desc22?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_531?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Medal}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Individual}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Inter}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Event}</td>
                      <td>{item?.Uni_Criteria05_531?.Uni_Student}</td>
                      <td>
                        {item?.Uni_Criteria05_531?.Uni_Desc24?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria05_533?.Uni_criteria}</td>
                      <td>
                        {moment(item?.Uni_Criteria05_533?.Uni_Event1).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria05_533?.Uni_Event2}</td>
                      <td>
                        {item?.Uni_Criteria05_533?.Uni_Desc25?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={(e) => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            setedit6(false);
            setedit7(false);
            setedit8(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 5 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------5.1.1------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the scheme
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_Scheme}
                        onChange={(e) => setUni_Scheme(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_Scheme}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students benefited by government scheme and amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_Std}
                        onChange={(e) => setUni_Std(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_Std}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_Amt}
                        onChange={(e) => setUni_Amt(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_Amt}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students benefited by the institution's schemes and
                  amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_Std1}
                        onChange={(e) => setUni_Std1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_Std1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_Amt1}
                        onChange={(e) => setUni_Amt1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_Amt1}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students benefited by the non-government agencies
                  (NGOs) and amount
                </h6>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_Std2}
                        onChange={(e) => setUni_Std2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_Std2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_Amt2}
                        onChange={(e) => setUni_Amt2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_Amt2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the NGO/agency
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_NGO}
                        onChange={(e) => setUni_NGO(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_NGO}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_Desc18}
                        onChange={(e) => setUni_Desc18(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_Desc18}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria05_511?.Uni_Link18}
                        onChange={(e) => setUni_Link18(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_511?.Uni_Link18}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria05_511(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.1.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counselling offered by
                    the institution during the last five years
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_512?.Uni_HEI}
                        onChange={(e) => {
                          setUni_HEI(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_512?.Uni_HEI}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students attended / participated
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_512?.Uni_Number}
                        onChange={(e) => {
                          setUni_Number(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_512?.Uni_Number}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_512?.Uni_Desc20}
                        onChange={(e) => setUni_Desc20(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_512?.Uni_Desc20}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3 "
                        accept="image?*"
                        placeholder={Value?.Uni_Criteria05_512?.Uni_Link20}
                        onChange={(e) => setUni_Link20(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_512?.Uni_Link20}</>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="text-end">
                    {edit3 ? (
                      <>
                        {" "}
                        <button
                          className="btn btn-success"
                          style={{ color: "white" }}
                          onClick={(e) => {
                            editUni_Criteria05_512(e, Value?._id);
                            handleClose();
                          }}
                        >
                          Submit
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit3(true)}
                        >
                          Edit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* ------5.1.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the capacity development & skills enhancement
                    schemes
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_513?.Uni_Capabality}
                        onChange={(e) => {
                          setUni_Capabality(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_513?.Uni_Capabality}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of implementation (DD-MM-YYYY)
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_513?.Uni_Date}
                        onChange={(e) => {
                          setUni_Date(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.Uni_Criteria05_513?.Uni_Date).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students enrolled
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_513?.Uni_Enrolled}
                        onChange={(e) => setUni_Enrolled(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_513?.Uni_Enrolled}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_513?.Uni_Agencies}
                        onChange={(e) => setUni_Agencies(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_513?.Uni_Agencies}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_513?.Uni_Desc19}
                        onChange={(e) => setUni_Desc19(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_513?.Uni_Desc19}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        placeholder={Uni_Link19}
                        onChange={(e) => setUni_Link19(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_513?.Uni_Link19}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit2 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria05_513(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.2.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-4 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Sl.no.
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_No}
                        onChange={(e) => {
                          setUni_No(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_No}</>
                  )}
                </div>
                <div className="col-md-8 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Registration number/roll number for the exam
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_RollNo}
                        onChange={(e) => {
                          setUni_RollNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_RollNo}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Uni_NameS of students selected/ qualified
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_StdSelected}
                        onChange={(e) => {
                          setUni_StdSelected(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_StdSelected}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    NET
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_NET}
                        onChange={(e) => {
                          setUni_NET(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_NET}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    SLET
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_SLET}
                        onChange={(e) => {
                          setUni_SLET(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_SLET}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GATE
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_GATE}
                        onChange={(e) => {
                          setUni_GATE(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_GATE}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GMAT
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_GMAT}
                        onChange={(e) => {
                          setUni_GMAT(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_GMAT}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    CAT
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_CAT}
                        onChange={(e) => {
                          setUni_CAT(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_CAT}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    GRE
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_GRE}
                        onChange={(e) => {
                          setUni_GRE(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_GRE}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    JAM
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_JAM}
                        onChange={(e) => {
                          setUni_JAM(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_JAM}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    IELTS
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_IELTS}
                        onChange={(e) => {
                          setUni_IELTS(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_IELTS}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TOEFL
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_TOEFL}
                        onChange={(e) => {
                          setUni_TOEFL(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_TOEFL}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Civil Services
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_Civil}
                        onChange={(e) => {
                          setUni_Civil(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_Civil}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State government examinations
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_State}
                        onChange={(e) => {
                          setUni_State(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_State}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Other examinations conducted by the State / Central
                    Government Agencies (Specify)
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_OtherExam}
                        onChange={(e) => {
                          setUni_OtherExam(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_OtherExam}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_Total}
                        onChange={(e) => {
                          setUni_Total(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_Total}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Grand Total
                  </label>{" "}
                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_GTotal}
                        onChange={(e) => {
                          setUni_GTotal(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_GTotal}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_Desc23}
                        onChange={(e) => setUni_Desc23(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_Desc23}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload6"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria05_521?.Uni_Link23}
                        onChange={(e) => {
                          setUni_Link23(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_521?.Uni_Link23}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit6 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria05_521(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit6(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.2.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student placed
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_522?.Uni_Placed1}
                        onChange={(e) => {
                          setUni_Placed1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_522?.Uni_Placed1}</>
                  )}
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_522?.Uni_Graduate}
                        onChange={(e) => {
                          setUni_Graduate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_522?.Uni_Graduate}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the employer
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_522?.Uni_employer}
                        onChange={(e) => {
                          setUni_employer(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_522?.Uni_employer}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Pay package at appointment
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_522?.Uni_Package}
                        onChange={(e) => {
                          setUni_Package(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_522?.Uni_Package}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        rows={3}
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_522?.Uni_Desc21}
                        onChange={(e) => setUni_Desc21(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_522?.Uni_Desc21}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria05_522?.Uni_Link21}
                        onChange={(e) => setUni_Link21(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_522?.Uni_Link21}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit4 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria05_522(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.2.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.2.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of student enrolling into higher education
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_523?.Uni_Higher}
                        onChange={(e) => {
                          setUni_Higher(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_523?.Uni_Higher}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Program graduated from
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_523?.Uni_Graduat1}
                        onChange={(e) => {
                          setUni_Graduat1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_523?.Uni_Graduat1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of institution joined
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_523?.Uni_Institute}
                        onChange={(e) => {
                          setUni_Institute(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_523?.Uni_Institute}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of program admitted to
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_523?.Uni_Admit}
                        onChange={(e) => {
                          setUni_Admit(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_523?.Uni_Admit}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        rows={3}
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_523?.Uni_Desc22}
                        onChange={(e) => setUni_Desc22(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_523?.Uni_Desc22}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload5">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload5"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria05_523?.Uni_Link22}
                        onChange={(e) => setUni_Link22(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_523?.Uni_Link22}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit5 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria05_523(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.3.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/ medal
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_531?.Uni_Medal}
                        onChange={(e) => {
                          setUni_Medal(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_531?.Uni_Medal}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Team / Individual
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_531?.Uni_Individual}
                        onChange={(e) => {
                          setUni_Individual(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_531?.Uni_Individual}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Inter-university / state / National/ International
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_531?.Uni_Inter}
                        onChange={(e) => {
                          setUni_Inter(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_531?.Uni_Inter}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the event
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_531?.Uni_Event}
                        onChange={(e) => {
                          setUni_Event(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_531?.Uni_Event}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the student
                  </label>{" "}
                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_531?.Uni_Student}
                        onChange={(e) => {
                          setUni_Student(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_531?.Uni_Student}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_531?.Uni_Desc24}
                        onChange={(e) => setUni_Desc24(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_531?.Uni_Desc24}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload7"
                        id="upload7"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria05_531?.Uni_Link24}
                        onChange={(e) => setUni_Link24(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_531?.Uni_Link24}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit7 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria05_531(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit7(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------5.3.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-5.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of event/competition (DD-MM-YYYY)
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_533?.Uni_Event1}
                        onChange={(e) => {
                          setUni_Event1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.Uni_Criteria05_533?.Uni_Event1).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the event/competition
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_533?.Uni_Event2}
                        onChange={(e) => {
                          setUni_Event2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_533?.Uni_Event2}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit8 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria05_533?.Uni_Desc25}
                        onChange={(e) => setUni_Desc25(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_533?.Uni_Desc25}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload8"
                        id="upload8"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria05_533?.Uni_Link25}
                        onChange={(e) => setUni_Link25(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria05_533?.Uni_Link25}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit8 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria05_533(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit8(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_511?.Uni_Desc18}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_512?.Uni_Desc20}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_513?.Uni_Desc19}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_521?.Uni_Desc23}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_522?.Uni_Desc21}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_523?.Uni_Desc22}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_531?.Uni_Desc24}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_8 */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria05_533?.Uni_Desc25}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Title>Relevant Document</Modal.Title>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_511?.Uni_Link18}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Title>Relevant Document</Modal.Title>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_512?.Uni_Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Title>Relevant Document</Modal.Title>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_513?.Uni_Link19}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Title>Relevant Document</Modal.Title>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_521?.Uni_Link23}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Title>Relevant Document</Modal.Title>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_522?.Uni_Link21}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Title>Relevant Document</Modal.Title>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_523?.Uni_Link22}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Title>Relevant Document</Modal.Title>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_531?.Uni_Link24}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria05/${View?.Uni_Criteria05_533?.Uni_Link25}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Uni_Criteria05;
