import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

function UgCriteria7() {
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));

  // ================Show doc8 modal======================

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================ Show doc3 modal ====================

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("UG Criteria 7");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
            <table>
              <tr>
                <td>Physical Facilities</td>
                <td>${item?.Criteria07_007?.PhyFac}</td>
              </tr>
              <tr>
                <td>Provision for Lift</td>
                <td>${item?.Criteria07_007?.Prolift}</td>
              </tr>
              <tr>
                <td>Ramp/ Rails</td>
                <td>${item?.Criteria07_007?.Ramp}</td>
              </tr>
              <tr>
                <td>Braille Software/Facilities</td>
                <td>${item?.Criteria07_007?.Braille}</td>
              </tr>
              <tr>
                <td>Rest Rooms</td>
                <td>${item?.Criteria07_007?.RestRoom}</td>
              </tr>
              <tr>
                <td>Scribes for Examination</td>
                <td>${item?.Criteria07_007?.Scribes}</td>
              </tr><tr>
              <td>Special skill Development for Differently Abled Students</td>
              <td>${item?.Criteria07_007?.SpecialSkills}</td>
            </tr>
            <tr>
                <td>Any other Similar Facility</td>
                <td>${item?.Criteria07_007?.AnyOther}</td>
              </tr>
              <tr>
                <td>Annual expenditure excluding salary component of the institution</td>
                <td>${item?.Criteria07_007?.Annual}</td>
              </tr>
              <tr>
                <td>Number of initiatives to address locational Advantages and Disadvantages</td>
                <td>${item?.Criteria07_007?.NoOfIniti1}</td>
              </tr>
              <tr>
                <td>Number of initiatives taken to Engage with and contribute to Local Community</td>
                <td>${item?.Criteria07_007?.NoOfIniti2}</td>
              </tr>
              <tr>
                <td>Date and Duration of the Initiative Name of the Initiative Issues Addressed</td>
                <td>${item?.Criteria07_007?.DateDuration}</td>
              </tr>
              <tr>
                <td>Power Requirement met by Renewable Energy Sources</td>
                <td>${item?.Criteria07_007?.Power}</td>
              </tr>
              <tr>
                <td>Total Power requirements</td>
                <td>${item?.Criteria07_007?.TPower}</td>
              </tr>
              <tr>
                <td>Renewable Energy Source</td>
                <td>${item?.Criteria07_007?.ReEnergyS}</td>
              </tr>
              <tr>
                <td>Renewable Energy Generated and Used</td>
                <td>${item?.Criteria07_007?.ReEnergyG}</td>
              </tr>
              <tr>
              <td>Energy Supplied to the Grid</td>
              <td>${item?.Criteria07_007?.EnergySup}</td>
            </tr>
            <tr>
                <td>Percentage Lighting through LED bulbs</td>
                <td>${item?.Criteria07_007?.PercentageLED}</td>
              </tr>
              <tr>
                <td>Percentage Lighting through other sources</td>
                <td>${item?.Criteria07_007?.PercentageLight}</td>
              </tr>
              <tr>
                <td>Number of Participating Students and Staff</td>
                <td>${item?.Criteria07_007?.NoOfParticipants}</td>
              </tr>
              <tr>
                <td>No. of Activities</td>
                <td>${item?.Criteria07_007?.NoOfActivities}</td>
              </tr><tr>
              <td>TTitle of the Program/Activity</td>
              <td>${item?.Criteria07_007?.TitlePro}</td>
            </tr>
            <tr>
                <td>Number of Participants</td>
                <td>${item?.Criteria07_007?.NoOfParticipants1}</td>
              </tr>
            </table>
          `;

        let tableMarkup2 = `
          <table>
              <tr>
                <td>From</td>
                <td>${moment(item?.Criteria07_007?.Date1).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              <tr>
                <td>To</td>
                <td>${moment(item?.Criteria07_007?.Date2).format(
                  "DD-MM-YYYY"
                )}</td>
              </tr>
              </table>
          `;
        let tableMarkup3 = `
          <table>
              <tr>
                <td>MALE</td>
                <td>${item?.Criteria07_007?.Male}</td>
              </tr>
              <tr>
                <td>FEMALE</td>
                <td>${item?.Criteria07_007?.Female}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>${item?.Criteria07_007?.Yea}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${item?.Criteria07_007?.Total}</td>
              </tr>
              </table>
          `;
        let obj = {
          Year: item?.Year,
          "Total Lighting requirements": item?.Criteria07_007?.Lighting,
          "Budget allocated for green initiatives":
            item?.Criteria07_007?.Budget,
          "Status of implementation of CBCS / Elective Course System (ECS)":
            tableMarkup1,
          "Date and Duration": tableMarkup2,
          "Number of participants by Gender": tableMarkup3,
          "Power Requirement Renewable": item?.Criteria07_007?.PowRe,
          "Initiative names": item?.Criteria07_007?.InitiName,
          "Duration of the Initiative": item?.Criteria07_007?.DurInitiative,
          Description: item?.Criteria07_007?.Desc29,
          "Relevent Document": item?.Criteria07_007?.Link29,
          "Relevent Document": item?.Criteria07_007?.Link30,
          "Relevent Document": item?.Criteria07_007?.Link31,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaUG7/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getCriteria07();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //  ===========================================================================================

  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        pricipalDetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
        item1?.approve === "true" &&
        selectedYear === item1?.Auto_Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 7 });
  };

  //===================================

  const [AllDetails, setAllDetails] = useState([]);
  const getCriteria07 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getcri07details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.ugcriteria07);
        setdata1(res.data.ugcriteria07);
        setfilterPdf(res.data.ugcriteria07);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.ugcriteria07);
    }
  };
  console.log("AllDetails7", AllDetails);

  // =================================================================
  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getCriteria07();
      getAllYears();
    }
  }, []);

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);

  console.log("fjfjfjfjf", AllDetails);
  console.log("principal", pricipalDetails?._id);
  return (
    <div>
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-07
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0 ">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>
            <div className=" col-lg-2 lab text-end">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = AllDetails?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }

                    if (
                      pricipalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Year &&
                      (SearchItem === "" ||
                        item1?.Year?.includes(SearchItem) ||
                        item1?.Criteria07_007?.Budget?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.Lighting?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.PhyFac?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.Prolift?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.Ramp?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.Braille?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.RestRoom?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.Scribes?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.SpecialSkills?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.AnyOther?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.Annual?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.NoOfIniti1?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.NoOfIniti2?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.DateDuration?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.Power?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.TPower?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.ReEnergyS?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.ReEnergyG?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.EnergySup?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.PercentageLED?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.PercentageLight?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.NoOfParticipants?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.NoOfActivities?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.TitlePro?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.NoOfParticipants1?.toString()?.includes(
                          SearchItem
                        ) ||
                        moment(item1?.Criteria07_007?.Date1)
                          .format("DD-MM-YYYY")
                          ?.toString()
                          ?.includes(SearchItem) ||
                        moment(item1?.Criteria07_007?.Date2)
                          .format("DD-MM-YYYY")
                          ?.toString()
                          ?.includes(SearchItem) ||
                        item1?.Criteria07_007?.Male?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.Female?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.Yea?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.Total?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.PowRe?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria07_007?.InitiName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.DurInitiative?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria07_007?.Desc29?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });

                  if (arr1?.length > 0) {
                    navigate("/PDFprintUG07", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>

          <div className="row justify-content-end m-0 mt-4">
            <div className=" col-lg-12 do-sear  d-flex align-items-center  p-0  ">
              <label style={{ width: "130px" }}>Select Year</label>
              <select
                className="criteriaform vi_0"
                style={{ width: "150px" }}
                onChange={(e) => setselectedYear(e.target.value)}
              >
                <option>Select Year</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              style={{ minWidth: "6000px" }}
              responsive
              striped
              bordered
              hover
            >
              <thead>
                <tr>
                  <th>
                    <div className="table-head-text">Sl.No</div>
                  </th>
                  <th>
                    <div className="table-head-text">Year</div>
                  </th>
                  <th>
                    <div className="table-head-text">
                      {" "}
                      Total Lighting requirements
                    </div>
                  </th>
                  <th>
                    <div className="table-head-text">
                      {" "}
                      Budget allocated for green initiatives
                    </div>
                  </th>
                  <th>
                    <div className="table-head-text">
                      {" "}
                      Status of implementation of CBCS / Elective Course System
                      (ECS)
                    </div>
                  </th>
                  <th>
                    <div className="table-head-text">Date and Duration</div>
                  </th>
                  <th>
                    <div className="table-head-text">
                      {" "}
                      Number of participants by Gender
                    </div>
                  </th>
                  <th>
                    <div className="table-head-text">
                      Power Requirement Renewable
                    </div>
                  </th>
                  <th>
                    <div className="table-head-text">Initiative names</div>
                  </th>
                  <th>
                    <div className="table-head-text">
                      Duration of the Initiative
                    </div>
                  </th>
                  <th>
                    <div className="table-head-text">Description</div>
                  </th>
                  <th>
                    <div className="table-head-text">Document</div>
                  </th>
                  <th>
                    <div className="table-head-text">Document</div>
                  </th>
                  <th>
                    <div className="table-head-text">Document</div>
                  </th>
                  <th>
                    <div className="table-head-text">View</div>
                  </th>
                  <th>
                    <div className="table-head-text">Status</div>
                  </th>
                  <th>
                    <div className="table-head-text">Remarks</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    pricipalDetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    (item1?.approve === "Pending" ||
                      item1?.approve === "true") &&
                    selectedYear === item1?.Year &&
                    (SearchItem === "" ||
                      item1?.Year?.includes(SearchItem) ||
                      item1?.Criteria07_007?.Budget?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.Lighting?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.PhyFac?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Prolift?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Ramp?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Braille?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.RestRoom?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Scribes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.SpecialSkills?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.AnyOther?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Annual?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.NoOfIniti1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.NoOfIniti2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.DateDuration?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Power?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.TPower?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.ReEnergyS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.ReEnergyG?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.EnergySup?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.PercentageLED?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.PercentageLight?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.NoOfParticipants?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.NoOfActivities?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.TitlePro?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.NoOfParticipants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Criteria07_007?.Date1)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Criteria07_007?.Date2)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria07_007?.Male?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.Female?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.Yea?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.Total?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.PowRe?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria07_007?.InitiName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.DurInitiative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria07_007?.Desc29?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td style={{ border: "" }}>{++i}.</td>
                      <td style={{ border: "" }}>{item?.Year}</td>

                      <td style={{ border: "" }}>
                        {item?.Criteria07_007?.Lighting}
                      </td>
                      <td style={{ border: "" }}>
                        {item?.Criteria07_007?.Budget}
                      </td>

                      <td>
                        <Table responsive style={{ minWidth: "1000px" }}>
                          <thead>
                            <th style={{ border: "" }}>Physical Facilities</th>
                            <th style={{ border: "" }}>Provision for Lift</th>
                            <th style={{ border: "" }}>Ramp/ Rails</th>
                            <th style={{ border: "" }}>
                              Braille Software/Facilities
                            </th>
                            <th style={{ border: "" }}>Rest Rooms</th>
                            <th style={{ border: "" }}>
                              Scribes for Examination
                            </th>
                            <th style={{ border: "" }}>
                              Special skill Development for Differently Abled
                              Students
                            </th>
                            <th style={{ border: "" }}>
                              Any other Similar Facility
                            </th>
                            <th style={{ border: "" }}>
                              Annual expenditure excluding salary component of
                              the institution
                            </th>
                            <th style={{ border: "" }}>
                              Number of initiatives to address locational
                              Advantages and Disadvantages
                            </th>
                            <th style={{ border: "" }}>
                              Number of initiatives taken to Engage with and
                              contribute to Local Community
                            </th>
                            <th style={{ border: "" }}>
                              Date and Duration of the Initiative Name of the
                              Initiative Issues Addressed
                            </th>
                            <th style={{ border: "" }}>
                              Power Requirement met by Renewable Energy Sources
                            </th>
                            <th style={{ border: "" }}>
                              Total Power requirements
                            </th>
                            <th style={{ border: "" }}>
                              Renewable Energy Source
                            </th>
                            <th style={{ border: "" }}>
                              Renewable Energy Generated and Used
                            </th>
                            <th style={{ border: "" }}>
                              Energy Supplied to the Grid
                            </th>
                            <th style={{ border: "" }}>
                              Percentage Lighting through LED bulbs
                            </th>
                            <th style={{ border: "" }}>
                              Percentage Lighting through other sources
                            </th>
                            <th style={{ border: "" }}>
                              Number of Participating Students and Staff
                            </th>
                            <th style={{ border: "" }}>No. of Activities</th>
                            <th style={{ border: "" }}>
                              TTitle of the Program/Activity
                            </th>
                            <th style={{ border: "" }}>
                              Number of Participants
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: "200px" }}>
                                {item?.Criteria07_007?.PhyFac}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.Prolift}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.Ramp}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.Braille}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.RestRoom}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.Scribes}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.SpecialSkills}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.AnyOther}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.Annual}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.NoOfIniti1}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.NoOfIniti2}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.DateDuration}
                              </td>
                              <td style={{ border: "1" }}>
                                {item?.Criteria07_007?.Power}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.TPower}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.ReEnergyS}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.ReEnergyG}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.EnergySup}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.PercentageLED}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.PercentageLight}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.NoOfParticipants}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.NoOfActivities}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.TitlePro}
                              </td>
                              <td style={{ border: "" }}>
                                {item?.Criteria07_007?.NoOfParticipants1}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "" }}>From</th>
                            <th style={{ border: "" }}>To</th>
                          </thead>
                          <tbody>
                            <td style={{ border: "" }}>
                              {moment(item?.Criteria07_007?.Date1).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td style={{ border: "" }}>
                              {moment(item?.Criteria07_007?.Date2).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "" }}>
                        <Table responsive striped border>
                          <thead>
                            <th style={{ border: "" }}>MALE</th>
                            <th style={{ border: "" }}>FEMALE</th>
                            <th style={{ border: "" }}>Year</th>
                            <th style={{ border: "" }}>Total</th>
                          </thead>
                          <tbody>
                            <td style={{ border: "" }}>
                              {item?.Criteria07_007?.Male}
                            </td>
                            <td style={{ border: "" }}>
                              {item?.Criteria07_007?.Female}
                            </td>
                            <td style={{ border: "" }}>
                              {item?.Criteria07_007?.Yea}
                            </td>
                            <td style={{ border: "" }}>
                              {item?.Criteria07_007?.Total}
                            </td>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "" }}>
                        {item?.Criteria07_007?.PowRe}
                      </td>
                      <td style={{ border: "" }}>
                        {item?.Criteria07_007?.InitiName}
                      </td>
                      <td style={{ border: "" }}>
                        {item?.Criteria07_007?.DurInitiative}
                      </td>

                      <td style={{ border: "" }}>
                        {item?.Criteria07_007?.Desc29?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td style={{ border: "" }}>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td style={{ border: "" }}>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td style={{ border: "" }}>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td style={{ border: "" }}>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          {/* <i class="fa-solid fa-check fa-lg" style={{color: "#26a269"}}></i> */}
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td style={{ border: "" }}>
                        {" "}
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td style={{ border: "" }}>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {/* Show description modal */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Criteria07_007?.Desc29}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose8}>
                close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria07/${View?.Criteria07_007?.Link29}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria07/${View?.Criteria07_007?.Link30}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria07/${View?.Criteria07_007?.Link31}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton style={{ background: "#393186" }}>
              <Modal.Title>
                <span
                  className=""
                  style={{
                    fontSize: "19px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  UnderGraduate_Criteria-07
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-0 p-0">
              <div className="cri623">
                <div className="text-center mb-3">
                  <span
                    className=""
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                  >
                    Criteria-07
                  </span>
                </div>

                <div className="row  m-0 ">
                  {" "}
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total Lighting requirements
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Lighting}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Budget allocated for green initiatives:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Budget}</>
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Status of implementation of CBCS / Elective Course System
                    (ECS):
                  </h6>
                  <div className="row">
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Physical Facilities:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.PhyFac}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Provision for Lift:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Prolift}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Ramp/ Rails:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Ramp}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Braille Software/Facilities:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Braille}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Rest Rooms:{" "}
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.RestRoom}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Scribes for Examination
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Scribes}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Special skill Development for Differently Abled
                        Students:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.SpecialSkills}</>
                    </div>
                    <div className="col-md-4 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Any other Similar Facility:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.AnyOther}</>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Annual expenditure excluding salary component of the
                      institution
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.Annual}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of initiatives to address locational Advantages and
                      Disadvantages
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.NoOfIniti1}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of initiatives taken to Engage with and contribute
                      to Local Community
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.NoOfIniti2}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date and Duration of the Initiative Name of the Initiative
                      Issues Addressed
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.DateDuration}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Power Requirement met by Renewable Energy Sources
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.Power}</>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total Power requirements
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.TPower}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Renewable Energy Source:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.ReEnergyS}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Renewable Energy Generated and Used :
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.ReEnergyG}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Energy Supplied to the Grid:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.EnergySup}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Percentage Lighting through other sources
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.PercentageLight}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of Participating Students and Staff:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.NoOfParticipants}</>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Percentage Lighting through LED bulbs:
                        </label>
                        <br />

                        <>{Value?.Criteria07_007?.PercentageLED}</>
                      </div>
                      <div className="col-md-6 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          No. of Activities:
                        </label>
                        <br />

                        <>{Value?.Criteria07_007?.NoOfActivities}</>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        TTitle of the Program/Activity:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.TitlePro}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Number of Participants:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.NoOfParticipants1}</>
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Date and Duration:
                  </h6>
                  <div className="row">
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        From:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Date1}</>
                    </div>
                    <div className="col-md-6 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        To:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Date2}</>
                    </div>
                  </div>
                  <h6 className="text-center text-decoration-underline mt-3">
                    Number of participants by Gender:
                  </h6>
                  <div className="row">
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        MALE:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Male}</>
                    </div>
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        FEMALE:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Female}</>
                    </div>
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Year
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Yea}</>
                    </div>
                    <div className="col-md-3 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Total
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.Total}</>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Power Requirement Renewable
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.PowRe}</>
                    </div>
                    <div className="col-md-12 mt-3 ">
                      <label className="content1" htmlFor="email">
                        {" "}
                        Initiative names:
                      </label>
                      <br />

                      <>{Value?.Criteria07_007?.InitiName}</>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Duration_of_the_Initiative
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.DurInitiative}</>
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.Desc29}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document 1:
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.Link29}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload2">
                      {" "}
                      Document 2:
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.Link30}</>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload3">
                      {" "}
                      Document 3:
                    </label>
                    <br />

                    <>{Value?.Criteria07_007?.Link31}</>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {Value?.approve === "false" ? (
                <></>
              ) : (
                <div>
                  <Button
                    className="btn-success"
                    onClick={(e) => {
                      approveCriteria(e, true, Value?._id);
                      handleClose();
                    }}
                  >
                    Approve{" "}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => setSmShow(true)}
                    className="me-2"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal>

          {
            // remarks model
          }

          <Modal
            // size="sm"
            show={smShow}
            onHide={() => handleClosesm()}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Tell me the reason?..
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                onChange={(e) => setremark(e.target.value)}
                style={{ height: "200px" }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={(e) => {
                  approveCriteria(e, false, Value?._id);
                  handleClose();
                  handleClosesm();
                }}
              >
                Submit{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UgCriteria7;
