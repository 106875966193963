import moment from "moment";
import React from "react";

const PG5513aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Name of the capacity development and skills enhancement program
          </span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_513?.PG_NamCap}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date of implementation</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>
            {" "}
            {moment(item?.PG_Criteria05_513?.PG_IDate).format("DD-MM-YYYY")}
          </span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Number of students enrolled</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_513?.PG_StdEnr}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Name of the agencies/consultants involved with contact details (if
            any)
          </span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_513?.PG_AgeName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria05_513?.PG_Desc22}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {/* <a href={`${item?.PG_Criteria05_513?.PG_Link22}`}> */}

            {/* </a> */}

            <a
              href={`https://brightnaac.co.in/PG_Criteria05/${item?.PG_Criteria05_513?.PG_Link22}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG5513aqar;
