import moment from "moment";
import React from "react";

const PG6634aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (from)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {" "}
            {moment(item?.PG_Criteria06_634?.Sdate1).format("DD-MM-YYYY")}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (to)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {" "}
            {moment(item?.PG_Criteria06_634?.Edate1).format("DD-MM-YYYY")}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of teacher who attended</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria06_634?.PG_namet}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Title of the program</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria06_634?.PG_prgm50}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria06_634?.PG_desc4}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {/* <a href={`${item?.PG_Criteria06_634?.PG_doc7}`}> */}

            {/* </a> */}
            <a
              href={`https://brightnaac.co.in/PG_Criteria06/${item?.PG_Criteria06_634?.PG_doc7}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG6634aqar;
