import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Nav } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
const Criteria6 = () => {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const [UG_Criteria06, setUG_Criteria06] = useState([]);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const navigate = useNavigate();

  const [smShow, setSmShow] = useState(false);
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [View, setView] = useState({});

  // ====================================

  //Autonumous method for criteria

  // 06_623

  const [Auto_Year, setAuto_Year] = useState("");
  const [Auto_yoi1, setAuto_yoi1] = useState("");
  const [Auto_yoi2, setAuto_yoi2] = useState("");
  const [Auto_yoi3, setAuto_yoi3] = useState("");
  const [Auto_yoi4, setAuto_yoi4] = useState("");
  const [Auto_doc1, setAuto_doc1] = useState("");
  const [Auto_doc2, setAuto_doc2] = useState("");
  const [Auto_doc3, setAuto_doc3] = useState("");
  const [Auto_doc4, setAuto_doc4] = useState("");
  const [Auto_desc1, setAuto_desc1] = useState("");

  // 06_632

  const [Auto_teacher, setAuto_teacher] = useState("");
  const [Auto_conference, setAuto_conference] = useState("");
  const [Auto_professional, setAuto_professional] = useState("");
  const [Auto_Amount, setAuto_Amount] = useState("");
  const [Auto_support, setAuto_support] = useState("");
  const [Auto_desc2, setAuto_desc2] = useState("");
  const [Auto_doc5, setAuto_doc5] = useState("");

  //============= criteria 633 =============== //

  const [Auto_Startdate, setAuto_Startdate] = useState("");
  const [Auto_Enddate, setAuto_Enddate] = useState("");
  const [Auto_title, setAuto_title] = useState("");
  const [Auto_participants, setAuto_participants] = useState("");
  const [Auto_doc6, setAuto_doc6] = useState("");
  const [Auto_desc3, setAuto_desc3] = useState("");

  //============= criteria 634 =============== //

  const [Auto_NameT, setAuto_NameT] = useState("");
  const [Auto_Titleprogram, setAuto_Titleprogram] = useState("");
  const [Auto_DateS, setAuto_DateS] = useState("");
  const [Auto_DateE, setAuto_DateE] = useState("");
  const [Auto_doc7, setAuto_doc7] = useState("");
  const [Auto_desc4, setAuto_desc4] = useState("");

  //============= criteria 642 =============== //

  const [Auto_agencies, setAuto_agencies] = useState("");
  const [Auto_Grant, setAuto_Grant] = useState("");
  const [Auto_Funds, setAuto_Funds] = useState("");
  const [Auto_doc8, setAuto_doc8] = useState("");
  const [Auto_desc5, setAuto_desc5] = useState("");

  //============= criteria 653 =============== //

  const [Auto_Conferences, setAuto_Conferences] = useState("");
  const [Auto_AcademicAA, setAuto_AcademicAA] = useState("");
  const [Auto_NIRF, setAuto_NIRF] = useState("");
  const [Auto_ISO, setAuto_ISO] = useState("");
  const [Auto_NBA, setAuto_NBA] = useState("");
  const [Auto_institution, setAuto_institution] = useState("");
  const [Auto_From, setAuto_From] = useState("");
  const [Auto_To, setAuto_To] = useState("");
  const [Auto_doc9, setAuto_doc9] = useState("");
  const [Auto_desc6, setAuto_desc6] = useState("");

  // =============== Edit ==================

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);

  // =======================================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/approveCriteriaA6/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ACriteria06Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //===================================

  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================== Edit Code ==============================
  const editCriteria06_623 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editCriteriaA06_623/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_yoi1: Auto_yoi1,
          Auto_yoi2: Auto_yoi2,
          Auto_yoi3: Auto_yoi3,
          Auto_yoi4: Auto_yoi4,
          Auto_doc1: Auto_doc1,
          Auto_doc2: Auto_doc2,
          Auto_doc3: Auto_doc3,
          Auto_doc4: Auto_doc4,
          Auto_desc1: Auto_desc1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_632 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editCriteriaA06_632/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_teacher: Auto_teacher,
          Auto_conference: Auto_conference,
          Auto_professional: Auto_professional,
          Auto_Amount: Auto_Amount,
          Auto_support: Auto_support,
          Auto_doc5: Auto_doc5,
          Auto_desc2: Auto_desc2,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_633 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editCriteriaA06_633/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Startdate: Auto_Startdate,
          Auto_Enddate: Auto_Enddate,
          Auto_title: Auto_title,
          Auto_participants: Auto_participants,
          Auto_doc6: Auto_doc6,
          Auto_desc3: Auto_desc3,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria06_634 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editCriteriaA06_634/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_NameT: Auto_NameT,
          Auto_Titleprogram: Auto_Titleprogram,
          Auto_DateS: Auto_DateS,
          Auto_DateE: Auto_DateE,
          Auto_doc7: Auto_doc7,
          Auto_desc4: Auto_desc4,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_642 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editCriteriaA06_642/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_agencies: Auto_agencies,
          Auto_Grant: Auto_Grant,
          Auto_Funds: Auto_Funds,
          Auto_doc8: Auto_doc8,
          Auto_desc5: Auto_desc5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria06_653 = async (e, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/PrinipalAdmin/editCriteriaA06_653/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Conferences: Auto_Conferences,
          Auto_AcademicAA: Auto_AcademicAA,
          Auto_NIRF: Auto_NIRF,
          Auto_ISO: Auto_ISO,
          Auto_NBA: Auto_NBA,
          Auto_institution: Auto_institution,
          Auto_From: Auto_From,
          Auto_To: Auto_To,
          Auto_doc9: Auto_doc9,
          Auto_desc6: Auto_desc6,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setAllDetails();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // =================================================================

  const [AllDetails, setAllDetails] = useState([]);
  const ACriteria06Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/PrinipalAdmin/getAutocri6"
      );
      if (res.status === 200) {
        setAllDetails(res.data.Autocriteria06);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.Autocriteria06);
    }
  };

  // =================================================================

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      ACriteria06Details();
    }
  }, []);
  console.log(AllDetails);

  return (
    <div>
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-8 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-06
              </span>
            </div>

            <div className=" col-lg-4 lab text-end ">
              <Link to="/aucriteria6">
                <button>
                  Add Criteria 6{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0 ">
            <div className=" col-lg-3 do-sear d-flex p-0  ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeFrom}
                picker="year"
                placeholder="Select Year (from)"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                picker="year"
                placeholder="Select Year (to)"
                className="vi_0"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className="">
            <Nav defaultActiveKey="/A_06_623">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/A_06_623")}
                >
                  <Button className="criteriabtn-0 btn-sub1">6.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/A_06_632")}
                >
                  {" "}
                  <Button className="criteriabtn-0 btn-sub">6.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_06_633")}
                >
                  <Button className="criteriabtn btn-sub">6.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-4"
                  onClick={() => navigate("/A_06_634")}
                >
                  <Button className="criteriabtn btn-sub">6.3.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/A_06_642")}
                >
                  <Button className="criteriabtn btn-sub">6.4.2</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/A_06_653")}
                >
                  <Button className="criteriabtn btn-sub">6.5.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>{" "}
        </div>

        <div className="container mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/*  Criteria 06 6.2.3  */}

                  <th>Criteria</th>
                  <th>Administration</th>
                  <th>Finance and Accounts</th>
                  <th>Student Admissions and Support</th>
                  <th>Examinations</th>
                  <th>Description</th>

                  {/*  Criteria 06 6.3.2  */}

                  <th>Criteria</th>
                  <th>Name of the teacher</th>
                  <th>Amount of support received </th>
                  <th>Amount of support received (in INR)</th>
                  <th>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </th>
                  <th>
                    Name of the professional body for which membership fee is
                    provided
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/*  Criteria 06 6.3.3  */}

                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>
                    Title of the professional development/ administrative
                    training programs organised for teaching staff (Professional
                    development/ administrative training programs)
                  </th>
                  <th>No. of participants</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/*  Criteria 06 6.3.4  */}

                  <th>Criteria</th>
                  <th>Date (from)</th>
                  <th>Date (to)</th>
                  <th>Name of teacher who attended</th>
                  <th>Title of the program</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/*  Criteria 06 6.4.2  */}

                  <th>Criteria</th>
                  <th>Purpose of the Grant</th>
                  <th>Funds/ Grants received (INR in lakhs)</th>
                  <th>
                    Name of the non government funding agencies/ individuals
                  </th>
                  <th>Description</th>
                  <th>Audited Statement of Accounts reflecting the receipts</th>

                  {/*  Criteria 06 6.5.3  */}

                  <th>Criteria</th>
                  <th>
                    Orientation programme on quality issues for teachers and
                    students
                  </th>
                  <th>Participation in NIRF along with Status</th>
                  <th>
                    Academic Administrative Audit (AAA) and follow up action
                  </th>
                  <th>ISO Certification. and nature and validity period</th>
                  <th>
                    NBA or any other certification received with program
                    specifications
                  </th>
                  <th>Conferences, Seminars, Workshops on quality conducted</th>
                  <th>
                    Collaborative quality initiatives with other institution(s)
                    (Provide name of the institution and activity)
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>

              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    pricipalDetails?._id === item1?.addedByPri &&
                    item1?.approve === "Pending" &&
                    // selectedYear === item1?.Auto_Year &&
                    (SearchItem === "" ||
                      item1?.Auto_Year?.includes(SearchItem) ||
                      item1?.Auto_Criteria06_623?.Auto_yoi1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria06_623?.Auto_yoi2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria06_623?.Auto_yoi3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria06_623?.Auto_yoi4?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria06_623?.Auto_desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Auto_Year}</td>
                      <td>{item?.Auto_Criteria06_623?.Auto_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria06_623?.Auto_yoi1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow1();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria06_623?.Auto_yoi2}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow2();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria06_623?.Auto_yoi3}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow3();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Year of implementation
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Document
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria06_623?.Auto_yoi4}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow4();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>

                      <td>
                        {item?.Auto_Criteria06_623?.Auto_desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>{item?.Auto_Criteria06_632?.Auto_criteria}</td>

                      <td>{item?.Auto_Criteria06_632?.Auto_teacher}</td>
                      <td>{item?.Auto_Criteria06_632?.Auto_Amount}</td>
                      <td>{item?.Auto_Criteria06_632?.Auto_support}</td>
                      <td>{item?.Auto_Criteria06_632?.Auto_conference}</td>
                      <td>{item?.Auto_Criteria06_632?.Auto_professional}</td>

                      <td>
                        {item?.Auto_Criteria06_632?.Auto_desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria06_633?.Auto_criteria}</td>

                      <td>
                        {moment(
                          item?.Auto_Criteria06_633?.Auto_Startdate
                        ).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {moment(item?.Auto_Criteria06_633?.Auto_Enddate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Auto_Criteria06_633?.Auto_title}</td>
                      <td>{item?.Auto_Criteria06_633?.Auto_participants}</td>

                      <td>
                        {item?.Auto_Criteria06_633?.Auto_desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria06_634.Auto_criteria}</td>

                      <td>
                        {moment(item?.Auto_Criteria06_634?.Auto_DateS).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Auto_Criteria06_634?.Auto_DateE).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Auto_Criteria06_634?.Auto_NameT}</td>
                      <td>{item?.Auto_Criteria06_634?.Auto_Titleprogram}</td>

                      <td>
                        {item?.Auto_Criteria06_634?.Auto_desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria06_642?.Auto_criteria}</td>

                      <td>{item?.Auto_Criteria06_642?.Auto_Grant}</td>
                      <td>{item?.Auto_Criteria06_642?.Auto_Funds}</td>
                      <td>{item?.Auto_Criteria06_642?.Auto_agencies}</td>

                      <td>
                        {item?.Auto_Criteria06_642?.Auto_desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria06_653?.Auto_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Date (from)</th>
                            <th>Date (to)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {moment(
                                  item?.Auto_Criteria06_653?.Auto_From
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {moment(
                                  item?.Auto_Criteria06_653?.Auto_To
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>{item?.Auto_Criteria06_653?.Auto_NIRF}</td>
                      <td>{item?.Auto_Criteria06_653?.Auto_AcademicAA}</td>
                      <td>{item?.Auto_Criteria06_653?.Auto_ISO}</td>
                      <td>{item?.Auto_Criteria06_653?.Auto_NBA}</td>
                      <td>{item?.Auto_Criteria06_653?.Auto_Conferences}</td>
                      <td>{item?.Auto_Criteria06_653?.Auto_institution}</td>

                      <td>
                        {item?.Auto_Criteria06_653?.Auto_desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria06_623?.Auto_desc1}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria06_632?.Auto_desc2}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria06_633?.Auto_desc3}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose12}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria06_634?.Auto_desc4}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria06_642?.Auto_desc5}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose14}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria06_653?.Auto_desc6}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_623?.Auto_doc1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_623?.Auto_doc2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_623?.Auto_doc3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_623?.Auto_doc4}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_632?.Auto_doc5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 6 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_633?.Auto_doc6}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 7 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_634?.Auto_doc7}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 8 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_642?.Auto_doc8}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 9 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/A_Criteria06/${View?.Auto_Criteria06_653?.Auto_doc9}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={() => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            setedit6(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold", color: "white" }}
              >
                Autonomous_Criteria-06
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-623
                </span>
              </div>
              <div className="row  m-0 ">
                <h6>Administration</h6>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.Auto_Criteria06_623?.Auto_yoi1}
                          className="vi_0"
                          onChange={(e) => {
                            setAuto_yoi1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_623?.Auto_yoi1}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Document</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload1"
                          className="vi_0"
                          placeholder={Auto_doc1}
                          onChange={(e) => setAuto_doc1(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_623?.Auto_doc1}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ">
                <h6>Finance and Accounts</h6>

                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.Auto_Criteria06_623?.Auto_yoi2}
                          className="vi_0"
                          onChange={(e) => setAuto_yoi2(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_623?.Auto_yoi2}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Document</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload2"
                          className="vi_0"
                          placeholder={Auto_doc2}
                          onChange={(e) => setAuto_doc2(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_623?.Auto_doc2}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ">
                <h6>Student Admissions and Support</h6>

                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.Auto_Criteria06_623.Auto_yoi3}
                          className="vi_0"
                          onChange={(e) => setAuto_yoi3(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_623?.Auto_yoi3}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Document</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload3"
                          className="vi_0"
                          placeholder={Auto_doc3}
                          onChange={(e) => setAuto_doc3(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_623?.Auto_doc3}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <h6>Examinations</h6>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.Auto_Criteria06_623?.Auto_yoi4}
                          className="vi_0"
                          onChange={(e) => setAuto_yoi4(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_623?.Auto_yoi4}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Document</label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload4"
                          className="vi_0"
                          placeholder={Auto_doc4}
                          onChange={(e) => setAuto_doc4(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_623?.Auto_doc4}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description
                </label>
                {edit1 ? (
                  <>
                    <textarea
                      className="vi_0"
                      type="text"
                      placeholder={Value?.Auto_Criteria06_623?.Auto_desc1}
                      onChange={(e) => setAuto_desc1(e.target.value)}
                    />
                  </>
                ) : (
                  <>{Value?.Auto_Criteria06_623?.Auto_desc1}</>
                )}
              </div>

              {Value?.approve == "true" ? (
                <></>
              ) : (
                <div className="text-end mt-3">
                  {edit1 ? (
                    <>
                      <Button
                        className="btn btn-success"
                        onClick={(e) => {
                          editCriteria06_623(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </Button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit1(true)}
                      >
                        Edit
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-632
                </span>
              </div>
              <div className="row m-0 ">
                <div className="col-lg-12">
                  <div className="lab mb-3">
                    <label>Name of the teacher</label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.Auto_Criteria06_632?.Auto_teacher}
                          className="vi_0"
                          onChange={(e) => setAuto_teacher(e.target.value)}
                        />{" "}
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_632?.Auto_teacher}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="lab mb-3">
                    <label>Amount of support received (in INR)</label>
                    <br />

                    {edit2 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Value?.Auto_Criteria06_632?.Auto_Amount}
                          className="vi_0"
                          onChange={(e) => setAuto_Amount(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Auto_Criteria06_632?.Auto_Amount}</>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="lab mb-3">
                  <label>Amount of support received </label>
                  <br />

                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={Value?.Auto_Criteria06_632?.Auto_support}
                        className="vi_0"
                        onChange={(e) => setAuto_support(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria06_632?.Auto_support}</>
                  )}
                </div>
              </div>

              <div className="lab mb-4">
                <label>
                  Name of conference/ workshop attended for which financial
                  support provided
                </label>
                <br />
                {edit2 ? (
                  <>
                    <input
                      type="text"
                      placeholder={Value?.Auto_Criteria06_632?.Auto_conference}
                      className="vi_0"
                      onChange={(e) => setAuto_conference(e.target.value)}
                    />
                  </>
                ) : (
                  <>{Value?.Auto_Criteria06_632?.Auto_conference}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  Name of the professional body for which membership fee is
                  provided
                </label>
                <br />
                {edit2 ? (
                  <>
                    <input
                      type="text"
                      placeholder={
                        Value?.Auto_Criteria06_632?.Auto_professional
                      }
                      className="vi_0"
                      onChange={(e) => setAuto_professional(e.target.value)}
                    />{" "}
                  </>
                ) : (
                  <>{Value?.Auto_Criteria06_632?.Auto_professional}</>
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description
                </label>
                {edit2 ? (
                  <>
                    <textarea
                      type="text"
                      id="name"
                      className="vi_0"
                      placeholder={Value?.Auto_Criteria06_632?.Auto_desc2}
                      onChange={(e) => {
                        setAuto_desc2(e.target.value);
                      }}
                    />
                  </>
                ) : (
                  <>{Value?.Auto_Criteria06_632?.Auto_desc2}</>
                )}
              </div>

              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Document</label>
                  <br />
                  {edit2 ? (
                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setAuto_doc5(e.target.files[0])}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_632?.Auto_doc5}</>
                  )}
                </div>
              </div>

              {Value?.approve == "true" ? (
                <></>
              ) : (
                <div className="text-end mt-3">
                  {edit2 ? (
                    <>
                      <Button
                        className="btn btn-success"
                        onClick={(e) => {
                          editCriteria06_632(e, Value?._id);
                          handleClose();
                          setedit2(false);
                        }}
                      >
                        Submit
                      </Button>
                    </>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit2(true)}
                      >
                        Edit
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-633
                </span>
              </div>
              <div className="row m-0">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from) (DD-MM-YYYY)</label>
                  <br />
                  {edit3 ? (
                    <input
                      type="date"
                      placeholder={Value?.Auto_Criteria06_633?.Auto_Startdate}
                      classNameeditCriteria06_633="vi_0"
                      onChange={(e) => setAuto_Startdate(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_633?.Auto_Startdate}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to) (DD-MM-YYYY)</label>
                  <br />
                  {edit3 ? (
                    <input
                      type="date"
                      placeholder={Value?.Auto_Criteria06_633?.Auto_Enddate}
                      className="vi_0"
                      onChange={(e) => setAuto_Enddate(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_633?.Auto_Enddate}</>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>
                    {" "}
                    Title of the professional development/ administrative
                    training programs organised for teaching staff (Professional
                    development/ administrative training programs)
                  </label>
                  <br />
                  {edit3 ? (
                    <input
                      type="text"
                      placeholder={Value?.Auto_Criteria06_633?.Auto_title}
                      className="vi_0"
                      onChange={(e) => setAuto_title(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_633?.Auto_title}</>
                  )}
                </div>

                <div className=" col-lg-6 lab mb-4">
                  <label>No. of participants</label>
                  <br />
                  {edit3 ? (
                    <input
                      type="number"
                      placeholder={
                        Value?.Auto_Criteria06_633?.Auto_participants
                      }
                      className="vi_0"
                      onChange={(e) => setAuto_participants(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_633?.Auto_participants}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>
                  {edit3 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      placeholder={Value?.Auto_Criteria06_633?.Auto_desc3}
                      onChange={(e) => {
                        setAuto_desc3(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_633?.Auto_desc3}</>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>Document</label>
                  <br />
                  {edit3 ? (
                    <input
                      type="file"
                      placeholder={Auto_doc6}
                      className="vi_0"
                      id="upload6"
                      onChange={(e) => setAuto_doc6(e.target.files[0])}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_633?.Auto_doc6}</>
                  )}
                </div>
              </div>
              {Value?.approve == "true" ? (
                <></>
              ) : (
                <div className="text-end mt-3">
                  {edit3 ? (
                    <>
                      <Button
                        className="btn btn-success"
                        onClick={(e) => {
                          editCriteria06_633(e, Value?._id);
                          handleClose();
                          setedit3(false);
                        }}
                      >
                        Submit
                      </Button>
                    </>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit3(true)}
                      >
                        Edit
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-634
                </span>
              </div>
              <div className="row m-0">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from) (DD-MM-YYYY)</label>
                  <br />
                  {edit4 ? (
                    <input
                      type="date"
                      className="vi_0"
                      placeholder={Value?.Auto_Criteria06_634?.Auto_DateS}
                      onChange={(e) => setAuto_DateS(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_634?.Auto_DateS}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to) (DD-MM-YYYY)</label>
                  <br />
                  {edit4 ? (
                    <input
                      type="date"
                      placeholder={Value?.Auto_Criteria06_634?.Auto_DateE}
                      className="vi_0"
                      onChange={(e) => setAuto_DateE(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_634?.Auto_DateE}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Name of teacher who attended</label>
                  <br />
                  {edit4 ? (
                    <input
                      type="text"
                      placeholder={Value?.Auto_Criteria06_634?.Auto_NameT}
                      className="vi_0"
                      onChange={(e) => setAuto_NameT(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_634?.Auto_NameT}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Title of the program</label>
                  <br />
                  {edit4 ? (
                    <input
                      type="text"
                      placeholder={
                        Value?.Auto_Criteria06_634?.Auto_Titleprogram
                      }
                      className="vi_0"
                      onChange={(e) => setAuto_Titleprogram(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_634?.Auto_Titleprogram}</>
                  )}
                </div>
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description
                </label>
                {edit4 ? (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    placeholder={Value?.Auto_Criteria06_634?.Auto_desc4}
                    onChange={(e) => {
                      setAuto_desc4(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_634?.Auto_desc4}</>
                )}
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label>Document</label>
                <br />
                {edit4 ? (
                  <input
                    type="file"
                    className="vi_0"
                    placeholder={Auto_doc7}
                    onChange={(e) => setAuto_doc7(e.target.files[0])}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_634?.Auto_doc7}</>
                )}
              </div>

              {Value?.approve == "true" ? (
                <></>
              ) : (
                <div className="text-end mt-3">
                  {edit4 ? (
                    <>
                      <Button
                        className="btn btn-success"
                        onClick={(e) => {
                          editCriteria06_634(e, Value?._id);
                          handleClose();
                          setedit4(false);
                        }}
                      >
                        Submit
                      </Button>
                    </>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit4(true)}
                      >
                        Edit
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-642
                </span>
              </div>
              <div className="row m-0">
                <div className=" col-lg-6 lab mb-4">
                  <label>Purpose of the Grant</label>
                  <br />
                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Value?.Auto_Criteria06_642?.Auto_Grant}
                      className="vi_0"
                      onChange={(e) => setAuto_Grant(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_642?.Auto_Grant}</>
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label> Funds/ Grants received (INR in lakhs)</label>
                  <br />
                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Value?.Auto_Criteria06_642?.Auto_Funds}
                      className="vi_0"
                      onChange={(e) => setAuto_Funds(e.target.value)}
                    />
                  ) : (
                    <>{Value?.Auto_Criteria06_642?.Auto_Funds}</>
                  )}
                </div>
              </div>

              <div className="  lab mb-4">
                <label>
                  {" "}
                  Name of the non government funding agencies/ individuals
                </label>
                <br />
                {edit5 ? (
                  <input
                    type="text"
                    placeholder={Value?.Auto_Criteria06_642?.Auto_agencies}
                    className="vi_0"
                    onChange={(e) => setAuto_agencies(e.target.value)}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_642?.Auto_agencies}</>
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description
                </label>
                {edit5 ? (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    placeholder={Value?.Auto_Criteria06_642?.Auto_desc5}
                    onChange={(e) => {
                      setAuto_desc5(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_642?.Auto_desc5}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>Document</label>
                <br />
                {edit5 ? (
                  <input
                    type="file"
                    className="vi_0"
                    onChange={(e) => setAuto_doc8(e.target.files[0])}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_642?.Auto_doc8}</>
                )}
              </div>

              <div className="text-end mt-4">
                {edit5 ? (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-success"
                      onClick={(e) => {
                        editCriteria06_642(e, Value?._id);
                        handleClose();
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="btn btn-danger"
                      type="submit"
                      onClick={setedit5}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-653
                </span>
              </div>
              <div className="row m-0">
                <label>
                  Orientation programme on quality issues for teachers and
                  students,{" "}
                </label>
              </div>

              <div className=" col-lg-6 lab mb-4">
                <label>Date (from) (DD-MM-YYYY)</label>
                <br />
                {edit6 ? (
                  <input
                    type="date"
                    placeholder={Value?.Auto_Criteria06_653?.Auto_From}
                    className="vi_0"
                    onChange={(e) => setAuto_From(e.target.value)}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_653?.Auto_From}</>
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label>Date (to) (DD-MM-YYYY)</label>
                <br />
                {edit6 ? (
                  <input
                    type="date"
                    placeholder={Value?.Auto_Criteria06_653?.PG_Edate2}
                    className="vi_0"
                    onChange={(e) => setAuto_To(e.target.value)}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_653?.PG_Edate2}</>
                )}
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label> Participation in NIRF along with Status.</label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Auto_Criteria06_653?.Auto_NIRF}
                    className="vi_0"
                    onChange={(e) => setAuto_NIRF(e.target.value)}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_653?.Auto_NIRF}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  Academic Administrative Audit (AAA) and follow up action
                </label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Auto_Criteria06_653?.Auto_AcademicAA}
                    className="vi_0"
                    onChange={(e) => setAuto_AcademicAA(e.target.value)}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_653?.Auto_AcademicAA}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  {" "}
                  ISO Certification. and nature and validity period{" "}
                </label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Auto_Criteria06_653?.Auto_ISO}
                    className="vi_0"
                    onChange={(e) => setAuto_ISO(e.target.value)}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_653?.Auto_ISO}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  {" "}
                  NBA or any other certification received with program
                  specifications.{" "}
                </label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Auto_Criteria06_653?.Auto_NBA}
                    className="vi_0"
                    onChange={(e) => setAuto_NBA(e.target.value)}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_653?.Auto_NBA}</>
                )}
              </div>

              <div className="  lab mb-4">
                <label>
                  {" "}
                  Conferences, Seminars, Workshops on quality conducted{" "}
                </label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Auto_Criteria06_653?.Auto_Conferences}
                    className="vi_0"
                    onChange={(e) => setAuto_Conferences(e.target.value)}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_653?.Auto_Conferences}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  {" "}
                  Collaborative quality initiatives with other institution(s)
                  (Provide name of the institution and activity)
                </label>
                <br />
                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Value?.Auto_Criteria06_653?.Auto_institution}
                    className="vi_0"
                    onChange={(e) => setAuto_institution(e.target.value)}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_653?.Auto_institution}</>
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description
                </label>
                {edit6 ? (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    onChange={(e) => {
                      setAuto_desc6(e.target.value);
                    }}
                  />
                ) : (
                  <>{Value?.Auto_Criteria06_653?.Auto_desc6}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>Document</label>
                <br />

                {edit6 ? (
                  <>
                    <input
                      type="file"
                      className="vi_0"
                      placeholder={Auto_doc9}
                      onChange={(e) => setAuto_doc9(e.target.files[0])}
                    />
                  </>
                ) : (
                  <>{Value?.Auto_Criteria06_653?.Auto_doc9}</>
                )}
              </div>

              {Value?.approve == "true" ? (
                <></>
              ) : (
                <div className="text-end mt-4">
                  {edit6 ? (
                    <>
                      <Button
                        className="btn btn-success"
                        onClick={(e) => {
                          editCriteria06_653(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </Button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit6(true)}
                      >
                        Edit
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  variant="primary"
                  onClick={(e) => approveCriteria(e, true, Value?._id)}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              style={{ height: "200px" }}
              onChange={(e) => setremark(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => approveCriteria(e, false, Value?._id)}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Criteria6;
