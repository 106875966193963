import React from "react";

const PG5523aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Registration number/roll number for the exam</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_RollNo1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Names of students selected/ qualified</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_StdSelected1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>NET</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_NET1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>SLET</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_SLET1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>GATE</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_GATE1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>GMAT</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_GMAT1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>CAT</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_CAT1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>GRE</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_GRE1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>JAM</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_JAM1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>IELTS</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_IELTS1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>TOEFL</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_TOEFL1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Civil Services</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_Civil1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>State government examinations</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_SGExam1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Other examinations conducted by the State / Central Government
            Agencies (Specify)
          </span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_OtherExam1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Total</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_Total1}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Grand Total</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_523?.PG_GTotal1}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria05_523?.PG_Desc26}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {/* <a href={`${item?.PG_Criteria05_523?.PG_Link26}`}> */}

            {/* </a> */}
            <a
              href={`https://brightnaac.co.in/PG_Criteria05/${item?.PG_Criteria05_523?.PG_Link26}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG5523aqar;
