import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

function CommitedEducation() {
  // const now = 90;
  // const now1 = 75;

  // =================== calling api for getting the CTE======================
  const [CTE, setCTE] = useState([]);
  const getCTE = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getCTE");
      if (res.status === 200) {
        setCTE(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setCTE(error.response.data.allContent);
    }
  };

  useEffect(() => {
    getCTE();
  }, []);

  return (
    <>
      <div
        className="commited"
        data-aos="fade-up"
        data-aos-duration="2000"
        style={{ marginBottom: "30px" }}
      >
        <Container>
          <Row className="justify-content-evenly align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12" md={6}>
              <div className="edgt-eh-item-inner">
                <div className="edgt-eh-custom-2737">
                  <div className="edgt-st-inner">
                    <h4 className="edgt-st-title homee mt-3">
                      Committed to Educating{" "}
                    </h4>
                    <p
                      className="edgt-st-text"
                      style={{
                        color: "#888",
                        fontSize: 18,
                        marginTop: 20,
                      }}
                    >
                      {CTE[0]?.description}
                    </p>
                  </div>
                  <div className="edgt-progress-bar mb-5 mt-5">
                    <div class="edgt-pb-title">Certificates</div>
                    <div>
                      <ProgressBar
                        now={CTE[0]?.certificatePercent}
                        label={`${CTE[0]?.certificatePercent}%`}
                      />
                    </div>
                  </div>
                  <div className="edgt-progress-bar ">
                    <div class="edgt-pb-title">Education skills</div>
                    <div>
                      <ProgressBar
                        now={CTE[0]?.educationPercent}
                        label={`${CTE[0]?.educationPercent}%`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Col className="col-lg-6 ">
              <div className="committed-group">
                <img
                  src={`https://brightnaac.co.in/CTE/${CTE[0]?.img}`}
                  alt=""
                  style={{ width: "100%", maxHeight: "475px" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CommitedEducation;
