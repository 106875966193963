import React from "react";
import { Link } from "react-router-dom";

function Aqar() {
  return (
    <>
      <div className="aqar">
        <div
          className="ab-0"
          style={{
            backgroundImage: "url('../Image/about-us.jpg')",
            height: "250px",
            position: "center",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="edg container">
            <div className="edgt-has-inline-style">Annual Quality Assurance Report</div>
            <div class="edgt-breadcrumbs-info">
              <div
                itemProp="breadcrumb"
                classname="edgt-breadcrumbs "
                style={{ color: "#e1e0e0" }}
              >
                <Link
                  itemProp="url"
                  to="/"
                  style={{ color: "#e1e0e0", textDecoration: "none" }}
                >
                  Home
                </Link>
                <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
                <span classname="edgt-current">AQAR</span>
              </div>
            </div>
          </div>
        </div>

        <img
          src="../gallery/logob.png"
          className="img-logo"
          alt="logob"
          style={{ color: "white" }}
        />
        <br />

        <div className="container">
            <center><h5>Notice</h5></center>
          <div className="aqar-heading">
            <h5>
              {" "}
              Sub: Mandatory Submission of Annual Quality Assurance Report
              (AQAR) by Internal Quality Assurance Cell (IQAC) of HEIs
            </h5>
          </div><br/>
          <div className="aqar-description">
            <h6>
              NAAC advocates that every accredited institution need to establish
              an Internal Quality Assurance Cell (IQAC) as a quality sustenance
              measure. NAAC intends IQACs to be more developmental oriented
              focusing on continuous improvement. NAAC has prepared Guidelines
              for Creation of the Internal Quality Assurance Cell (IQAC) and
              Submission of Annual Quality Assurance Report (AQAR) for
              Accredited Institutions.
            </h6>
            <br />
            <h6>
              So far submission of AQARs was not a mandatory requirement for
              institutions applying to NAAC 2nd and subsequent cycles of
              Assessment and Accreditation (A& A). It has now been decided by
              the Executive committee of NAAC that regular submission of AQARs
              should be made mandatory for 2nd and subsequent cycles of
              accreditation.
            </h6>
            <br />
            <h6>
              In view of the decision of Executive Committee of NAAC the
              following will be the pre-requisites for submission of LOI for all
              Higher Education Institutions (HEIs) opting for 2 nd and
              subsequent cycles of A& A with effect from 16th September 2016:
            </h6>
            <br />
            <ol type="i">
              <li>Having a functional IQAC.</li>
              <li>
                The minutes of IQAC meeting and compliance to the decisions
                should be uploaded on the institutional website.
              </li>
              <li>
                Mandatory submission of AQARs on a regular basis for
                institutions undergoing the second and subsequent cycles of
                Assessment and Accreditation by NAAC.
              </li>
              <li>
                Upload the AQAR’s on institutional website for access to all
                stakeholders.
              </li>
            </ol>
          </div><br/>
          <div className="row">
            <div className="col-sm-3">
              <span><b>Place:</b> Bengaluru </span><br/>
             <span> <b>Date:</b> 14 th June 2016</span>
            </div>
            <div className="col-sm-7"></div>
            <div className="col-sm-2">
               <span><b>Prof. D. P. Singh <br/>
               &nbsp;&nbsp;&nbsp;&nbsp; Director</b></span> 
                </div>
          </div>
        </div>
      </div><br/>
    </>
  );
}

export default Aqar;
