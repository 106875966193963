import React, { useEffect, useState } from "react";
import { BiSolidDashboard, BiSolidReport } from "react-icons/bi";
import { FaGraduationCap } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { SiGoogleforms } from "react-icons/si";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import StudentHeader from "./StudentHeader";

function StudentSidebar(props) {
  // let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  // useEffect(() => {
  //     pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  //     if (!pricipalDetails) {
  //       alert("Please login first");
  //       window.location.assign("/login");
  //     }
  //   }, []);

  const currentstudent = JSON.parse(sessionStorage.getItem("studentDetails"));
  const Alumni = JSON.parse(sessionStorage.getItem("Alumnistudent"));

  return (
    <div style={{ display: "flex", height: "100%", minHeight: "100vh" }}>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        // breakPoint={breakpoint? "sm" : ""}
        height="100vh"
        collapsedWidth="4.5rem"
        backgroundColor="#393186"
        style={{
          position: "sticky",
          top: "auto",
          padding: "0rem",
          margin: "0rem",
        }}
      >
        <Menu
          transitionDuration={900}
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: disabled ? "blue" : "white",
                  backgroundColor: active ? "red" : undefined,
                };
            },
          }}
          rootStyles={{
            // backgroundColor: '#e1e1e1',
            color: "#344cff",
          }}
        >

          {currentstudent ? (<>
            <div
            style={{
              height: "64px",
              minHeight: "64px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 20px",
              marginTop: "6px",
              color: "white !important",
            }}
            onClick={() => setCollapsed(!collapsed)}
            className="aaa"
          >
            Student Panel
          </div>
          </>):(<>
            <div
            style={{
              height: "64px",
              minHeight: "64px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 20px",
              marginTop: "6px",
              color: "white !important",
            }}
            onClick={() => setCollapsed(!collapsed)}
            className="aaa"
          >
            Alumni Student 
          </div></>)}
        
          <hr style={{ color: "white" }} />

          <MenuItem
            component={<Link to="/studentdashboard" />}
            icon={<BiSolidDashboard />}
          >
            Dashboard
          </MenuItem>
          <div>
            {currentstudent ? (
              <MenuItem
                component={<Link to="/studentsatsurveylist" />}
                icon={<FaPeopleGroup />}
              >
                SSS
              </MenuItem>
            ) : (
              ""
            )}

            {currentstudent ? (
              <MenuItem
                component={<Link to="/syllubussurveylist" />}
                icon={<FaPeopleGroup />}
              >
                Syllabus
              </MenuItem>
            ) : (
              ""
            )}

            {currentstudent ? (
              <MenuItem
                component={<Link to="/teacherfeedbacklist" />}
                icon={<FaPeopleGroup />}
              >
                Teacher
              </MenuItem>
            ) : (
              ""
            )}

          
            {Alumni ? (  <MenuItem
              component={<Link to="/alumnifeefbacklist" />}
              icon={<FaPeopleGroup />}
            >
              Alumnus
            </MenuItem>):("") }
          

            {/* {pricipalDetails?.HEI === "PG" ? (
            <> */}
            {/* <SubMenu label="PostGraduation" icon={<FaGraduationCap />}>
                <MenuItem
                  component={<Link to="/pg-Criteria1" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria1
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria2" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria2
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria3" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria3
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria4" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria4
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria5" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria5
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria6" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria6
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria7" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria7
                </MenuItem>
              </SubMenu> */}
            {/* </>
          ) : (
            <></>
          )} */}
          </div>

          <div>
            {/* {pricipalDetails?.HEI === "UG" ? (
            <> */}
            {/* <SubMenu label="UnderGraduation" icon={<FaGraduationCap />}>
                <MenuItem
                  component={<Link to="/ug-Criteria1" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria1
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria2" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria2
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria3" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria3
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria4" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria4
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria5" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria5
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria6" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria6
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria7" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria7
                </MenuItem>
              </SubMenu> */}
            {/* </>
          ) : (
            <></>
          )} */}
          </div>

          <div>
            {/* {pricipalDetails?.HEI === "University" ? (
            <> */}
            {/* <SubMenu label="University" icon={<FaGraduationCap />}>
                <MenuItem
                  component={<Link to="/u-Criteria1" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria1
                </MenuItem>
                <MenuItem
                  component={<Link to="/u-Criteria2" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria2
                </MenuItem>
                <MenuItem
                  component={<Link to="/u-Criteria3" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria3
                </MenuItem>
                <MenuItem
                  component={<Link to="/u-Criteria4" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria4
                </MenuItem>
                <MenuItem
                  component={<Link to="/u-Criteria5" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria5
                </MenuItem>
                <MenuItem
                  component={<Link to="/u-Criteria6" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria6
                </MenuItem>
                <MenuItem
                  component={<Link to="/u-Criteria7" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria7
                </MenuItem>
              </SubMenu> */}
            {/* </>
          ) : (
            <></>
          )} */}
          </div>

          <div>
            {/* {pricipalDetails?.HEI === "Autonomous" ? (
            <> */}
            {/* <SubMenu label="Autonomous" icon={<FaGraduationCap />}>
                <MenuItem
                  component={<Link to="/Criteria1" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria1
                </MenuItem>
                <MenuItem
                  component={<Link to="/Criteria2" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria2
                </MenuItem>
                <MenuItem
                  component={<Link to="/Criteria3" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria3
                </MenuItem>
                <MenuItem
                  component={<Link to="/Criteria4" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria4
                </MenuItem>
                <MenuItem
                  component={<Link to="/Criteria5" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria5
                </MenuItem>
                <MenuItem
                  component={<Link to="/Criteria6" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria6
                </MenuItem>
                <MenuItem
                  component={<Link to="/Criteria7" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria7
                </MenuItem>
              </SubMenu> */}
            {/* </>
          ) : (
            <></>
          )} */}
          </div>

          <div>
            {/* {pricipalDetails?.HEI === "UG&PG" ? (
            <> */}
            {/* <SubMenu label="UnderGraduation" icon={<FaGraduationCap />}>
                <MenuItem
                  component={<Link to="/ug-Criteria1" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria1
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria2" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria2
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria3" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria3
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria4" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria4
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria5" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria5
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria6" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria6
                </MenuItem>
                <MenuItem
                  component={<Link to="/ug-Criteria7" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria7
                </MenuItem>
              </SubMenu> */}

            {/* <SubMenu label="PostGraduation" icon={<FaGraduationCap />}>
                <MenuItem
                  component={<Link to="/pg-Criteria1" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria1
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria2" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria2
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria3" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria3
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria4" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria4
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria5" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria5
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria6" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria6
                </MenuItem>
                <MenuItem
                  component={<Link to="/pg-Criteria7" />}
                  icon={<SiGoogleforms />}
                >
                  Criteria7
                </MenuItem>
              </SubMenu> */}
            {/* </>
          ) : (
            <></>
          )} */}
          </div>

          {/* <MenuItem
          component={<Link to="/addfaculty" />}
          icon={<FaPeopleGroup />}
        >
          Faculty List
        </MenuItem> */}
          {/* <MenuItem
          component={<Link to="/finalreports" />}
          icon={<BiSolidReport />}
        >
          Final Reports
        </MenuItem> */}
        </Menu>
      </Sidebar>

      <main style={{}}>
        <div>
          <StudentHeader />
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default StudentSidebar;
