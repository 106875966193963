import React from "react";

const UG6634aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (from)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_634?.Sdate1}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (to)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_634?.Edate1}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of teacher who attended</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_634?.namet}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Title of the program</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_634?.prgm50}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.UgCriteria06_634?.ugdesc4}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.UgCriteria06_634?.doc7}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG6634aqar;
