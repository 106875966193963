import axios from "axios";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Aucriteria6 = () => {
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [View, setView] = useState({});

  // ===========================================================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  // ===========================================================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  // ===========================================================

  //  Edit

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);

  //  ADD

  const [add1, setadd1] = useState(true);
  const [add2, setadd2] = useState(true);
  const [add3, setadd3] = useState(true);
  const [add4, setadd4] = useState(true);
  const [add5, setadd5] = useState(true);
  const [add6, setadd6] = useState(true);

  //  Models

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // =============================================

  //Autonumous method for criteria

  // 06_623

  const [Auto_Year, setAuto_Year] = useState("");
  const [Auto_yoi1, setAuto_yoi1] = useState("");
  const [Auto_yoi2, setAuto_yoi2] = useState("");
  const [Auto_yoi3, setAuto_yoi3] = useState("");
  const [Auto_yoi4, setAuto_yoi4] = useState("");
  const [Auto_doc1, setAuto_doc1] = useState("");
  const [Auto_doc2, setAuto_doc2] = useState("");
  const [Auto_doc3, setAuto_doc3] = useState("");
  const [Auto_doc4, setAuto_doc4] = useState("");
  const [Auto_desc1, setAuto_desc1] = useState("");

  // 06_632

  const [Auto_teacher, setAuto_teacher] = useState("");
  const [Auto_conference, setAuto_conference] = useState("");
  const [Auto_professional, setAuto_professional] = useState("");
  const [Auto_Amount, setAuto_Amount] = useState("");
  const [Auto_support, setAuto_support] = useState("");
  const [Auto_desc2, setAuto_desc2] = useState("");
  const [Auto_doc5, setAuto_doc5] = useState("");

  //============= criteria 633 =============== //

  const [Auto_Startdate, setAuto_Startdate] = useState("");
  const [Auto_Enddate, setAuto_Enddate] = useState("");
  const [Auto_title, setAuto_title] = useState("");
  const [Auto_participants, setAuto_participants] = useState("");
  const [Auto_doc6, setAuto_doc6] = useState("");
  const [Auto_desc3, setAuto_desc3] = useState("");

  //============= criteria 634 =============== //

  const [Auto_NameT, setAuto_NameT] = useState("");
  const [Auto_Titleprogram, setAuto_Titleprogram] = useState("");
  const [Auto_DateS, setAuto_DateS] = useState("");
  const [Auto_DateE, setAuto_DateE] = useState("");
  const [Auto_doc7, setAuto_doc7] = useState("");
  const [Auto_desc4, setAuto_desc4] = useState("");

  //============= criteria 642 =============== //

  const [Auto_agencies, setAuto_agencies] = useState("");
  const [Auto_Grant, setAuto_Grant] = useState("");
  const [Auto_Funds, setAuto_Funds] = useState("");
  const [Auto_doc8, setAuto_doc8] = useState("");
  const [Auto_desc5, setAuto_desc5] = useState("");

  //============= criteria 653 =============== //

  const [Auto_Conferences, setAuto_Conferences] = useState("");
  const [Auto_AcademicAA, setAuto_AcademicAA] = useState("");
  const [Auto_NIRF, setAuto_NIRF] = useState("");
  const [Auto_ISO, setAuto_ISO] = useState("");
  const [Auto_NBA, setAuto_NBA] = useState("");
  const [Auto_institution, setAuto_institution] = useState("");
  const [Auto_From, setAuto_From] = useState("");
  const [Auto_To, setAuto_To] = useState("");
  const [Auto_doc9, setAuto_doc9] = useState("");
  const [Auto_desc6, setAuto_desc6] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getAucriteria1 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));
      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAucriteria1();
  }, []);

  const Aucriteria1 = async () => {
    if (!Auto_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/PrinipalAdmin/detailsAutocri6",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Year: Auto_Year,
          Auto_Criteria06_623: obj1,
          Auto_Criteria06_632: obj2,
          Auto_Criteria06_633: obj3,
          Auto_Criteria06_634: obj4,
          Auto_Criteria06_642: obj5,
          Auto_Criteria06_653: obj6,

          Auto_doc1: Auto_doc1,
          Auto_doc2: Auto_doc2,
          Auto_doc3: Auto_doc3,
          Auto_doc4: Auto_doc4,
          Auto_doc5: Auto_doc5,
          Auto_doc6: Auto_doc6,
          Auto_doc7: Auto_doc7,
          Auto_doc8: Auto_doc8,
          Auto_doc9: Auto_doc9,
          addedByPri: pricipalDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);

          sessionStorage.removeItem("cat1");
          sessionStorage.removeItem("cat2");
          sessionStorage.removeItem("cat3");
          sessionStorage.removeItem("cat4");
          sessionStorage.removeItem("cat5");
          sessionStorage.removeItem("cat6");

          navigate("/Criteria6");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  // 6.2.3

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Auto_desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add1) {
        if (
          !Auto_yoi1 ||
          !Auto_yoi2 ||
          !Auto_yoi3 ||
          !Auto_yoi4 ||
          !Auto_doc1 ||
          !Auto_doc2 ||
          !Auto_doc3 ||
          !Auto_doc4 ||
          !Auto_desc1
        ) {
          return alert("Please fill all the details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Auto_doc1);
      console.log("nnnnnnnn,", Auto_doc2);
      console.log("nnnnnnnn,", Auto_doc3);
      console.log("nnnnnnnn,", Auto_doc4);
      let obj = {
        Auto_criteria: "6.2.3",
        Auto_yoi1: Auto_yoi1,
        Auto_yoi2: Auto_yoi2,
        Auto_yoi3: Auto_yoi3,
        Auto_yoi4: Auto_yoi4,
        Auto_doc1: Auto_doc1,
        Auto_doc2: Auto_doc2,
        Auto_doc3: Auto_doc3,
        Auto_doc4: Auto_doc4,
        Auto_desc1: Auto_desc1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getAucriteria1();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.3.2

  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Auto_desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add2) {
        if (
          !Auto_teacher ||
          !Auto_conference ||
          !Auto_professional ||
          !Auto_Amount ||
          !Auto_support ||
          !Auto_doc5 ||
          !Auto_desc2
        ) {
          return alert("Please fill all the details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }
      console.log("nnnnnnnn,", Auto_doc5);
      let obj = {
        Auto_criteria: "6.3.2",
        Auto_teacher: Auto_teacher,
        Auto_conference: Auto_conference,
        Auto_professional: Auto_professional,
        Auto_Amount: Auto_Amount,
        Auto_support: Auto_support,
        Auto_doc5: Auto_doc5,
        Auto_desc2: Auto_desc2,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getAucriteria1();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.3.3
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Auto_desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add3) {
        if (
          !Auto_Startdate ||
          !Auto_Enddate ||
          !Auto_title ||
          !Auto_participants ||
          !Auto_doc6 ||
          !Auto_desc3
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("nnnnnnnn,", Auto_doc6);
      let obj = {
        Auto_criteria: "6.3.3",
        Auto_Startdate: Auto_Startdate,
        Auto_Enddate: Auto_Enddate,
        Auto_title: Auto_title,
        Auto_participants: Auto_participants,
        Auto_doc6: Auto_doc6,
        Auto_desc3: Auto_desc3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getAucriteria1();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };

  //  Criteria 6.3.4

  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Auto_desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Auto_NameT ||
          !Auto_Titleprogram ||
          !Auto_DateS ||
          !Auto_DateE ||
          !Auto_doc7 ||
          !Auto_desc4
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("nnnnnnnn,", Auto_doc7);

      let obj = {
        Auto_criteria: "6.3.4",
        Auto_NameT: Auto_NameT,
        Auto_Titleprogram: Auto_Titleprogram,
        Auto_DateS: Auto_DateS,
        Auto_DateE: Auto_DateE,
        Auto_doc7: Auto_doc7,
        Auto_desc4: Auto_desc4,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      setedit4(false);
      getAucriteria1();
    } catch (error) {
      console.log(error);
    }
  };

  //   Criteria 6.4.2

  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Auto_desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Auto_agencies ||
          !Auto_Grant ||
          !Auto_Funds ||
          !Auto_doc8 ||
          !Auto_desc5
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }
      console.log("nnnnnnnn,", Auto_doc8);
      let obj = {
        Auto_criteria: "6.4.2",
        Auto_agencies: Auto_agencies,
        Auto_Grant: Auto_Grant,
        Auto_Funds: Auto_Funds,
        Auto_doc8: Auto_doc8,
        Auto_desc5: Auto_desc5,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getAucriteria1();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.5.3

  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Auto_desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Auto_Conferences ||
          !Auto_AcademicAA ||
          !Auto_NIRF ||
          !Auto_ISO ||
          !Auto_NBA ||
          !Auto_institution ||
          !Auto_From ||
          !Auto_To ||
          !Auto_doc9 ||
          !Auto_desc6
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }
      console.log("nnnnnnnn,", Auto_doc9);
      let obj = {
        Auto_criteria: "6.5.3",
        Auto_Conferences: Auto_Conferences,
        Auto_AcademicAA: Auto_AcademicAA,
        Auto_NIRF: Auto_NIRF,
        Auto_ISO: Auto_ISO,
        Auto_NBA: Auto_NBA,
        Auto_institution: Auto_institution,
        Auto_From: Auto_From,
        Auto_To: Auto_To,
        Auto_doc9: Auto_doc9,
        Auto_desc6: Auto_desc6,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getAucriteria1();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  // ====================================================

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div>
      <div className="pt-4   " style={{ padding: "10px" }}>
        <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
          {" "}
          Autonomous_Criteria-06
        </span>
      </div>

      <div className="container">
        <div
          className="row-fluid border bs mt-3"
          style={{
            padding: "30px",
          }}
        >
          <div class=" col-md-6">
            <label for="" class="form-label">
              Select Year
            </label>
            {obj1?.Auto_Year ? (
              <p>{obj1?.Auto_Year}</p>
            ) : (
              <select
                class="form-select form-select-sm"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setAuto_Year(e.target.value)}
              >
                <option>Select Year</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h3>Criteria 6.2.3</h3>
            <h5>Areas Of E-Governance</h5>
            <div className="row ">
              <h6>Administration</h6>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {obj1?.Auto_yoi1 ? (
                    <p>{obj1?.Auto_yoi1}</p>
                  ) : (
                    <input
                      type="text"
                      className="vi_0"
                      onChange={(e) => {
                        setAuto_yoi1(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Relevant Document</label>

                  <input
                    type="file"
                    id="Upload1"
                    className="vi_0"
                    onChange={(e) => setAuto_doc1(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <h6>Finance and Accounts</h6>

              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {obj1?.Auto_yoi2 ? (
                    <p>{obj1?.Auto_yoi2}</p>
                  ) : (
                    <input
                      type="text"
                      className="vi_0"
                      onChange={(e) => setAuto_yoi2(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Relevant Document</label>

                  <input
                    type="file"
                    id="Upload2"
                    className="vi_0"
                    onChange={(e) => setAuto_doc2(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <h6>Student Admissions and Support</h6>

              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {obj1?.Auto_yoi3 ? (
                    <p>{obj1?.Auto_yoi3}</p>
                  ) : (
                    <input
                      type="text"
                      className="vi_0"
                      onChange={(e) => setAuto_yoi3(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Relevant Document</label>

                  <input
                    type="file"
                    id="Upload3"
                    className="vi_0"
                    onChange={(e) => setAuto_doc3(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <h6>Examinations</h6>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {obj1?.Auto_yoi4 ? (
                    <p>{obj1?.Auto_yoi4}</p>
                  ) : (
                    <input
                      type="text"
                      className="vi_0"
                      onChange={(e) => setAuto_yoi4(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Relevant Document</label>

                  <input
                    type="file"
                    id="Upload4"
                    className="vi_0"
                    onChange={(e) => setAuto_doc4(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (minimum 500 characters)
                </label>
                {obj1?.Auto_desc1 ? (
                  <p>{obj1?.Auto_desc1}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_desc1(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>

              <div className="text-end ">
                {add1 ? (
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      cat1();
                      setadd1(false);
                    }}
                  >
                    Add
                  </Button>
                ) : (
                  <>
                    <Button className="btn btn-warning" onClick={handleShow1}>
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* 06-623 */}

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row ">
                <h6>Administration</h6>
                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>

                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Auto_yoi1}
                          className="vi_0"
                          onChange={(e) => {
                            setAuto_yoi1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_yoi1}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Document</label>

                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload1"
                          className="vi_0"
                          placeholder={Auto_doc1}
                          onChange={(e) => setAuto_doc1(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_doc1?.name}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ">
                <h6>Finance and Accounts</h6>

                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>

                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Auto_yoi2}
                          className="vi_0"
                          onChange={(e) => setAuto_yoi2(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_yoi2}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Document</label>

                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload2"
                          className="vi_0"
                          placeholder={Auto_doc2}
                          onChange={(e) => setAuto_doc2(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_doc2?.name}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ">
                <h6>Student Admissions and Support</h6>

                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>

                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Auto_yoi3}
                          className="vi_0"
                          onChange={(e) => setAuto_yoi3(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_yoi3}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Document</label>

                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload3"
                          className="vi_0"
                          placeholder={Auto_doc4}
                          onChange={(e) => setAuto_doc4(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_doc4?.name}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <h6>Examinations</h6>
                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>

                    {edit1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Auto_yoi4}
                          className="vi_0"
                          onChange={(e) => setAuto_yoi4(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_yoi4}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Document</label>

                    {edit1 ? (
                      <>
                        <input
                          type="file"
                          id="upload4"
                          className="vi_0"
                          placeholder={Auto_doc4}
                          onChange={(e) => setAuto_doc4(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_doc4?.name}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description
                </label>
                {edit1 ? (
                  <>
                    <textarea
                      className="vi_0"
                      type="text"
                      placeholder={Auto_desc1}
                      onChange={(e) => setAuto_desc1(e.target.value)}
                    />
                  </>
                ) : (
                  <>
                    {Auto_desc1?.slice(0, 70)}
                    ...
                    <Link
                      onClick={() => {
                        handleShow7();
                        setView(Auto_desc1);
                      }}
                    >
                      Read More...
                    </Link>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {edit1 ? (
              <>
                <Button
                  variant="success"
                  style={{ color: "black" }}
                  onClick={() => {
                    cat1();
                    handleClose1();
                  }}
                >
                  Submit
                </Button>
              </>
            ) : (
              <>
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h3>Criteria 6.3.2</h3>
            <h6 className="mb-5">
              {" "}
              Average percentage of teachers provided with financial support to
              attend conferences / workshops and towards membership fee of
              professional bodies during the last five years (8)
            </h6>
            <div className="row ">
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Name of the teacher</label>

                  {obj2?.Auto_teacher ? (
                    <p>{obj2?.Auto_teacher}</p>
                  ) : (
                    <input
                      type=""
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setAuto_teacher(e.target.value)}
                    />
                  )}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Amount of support </label>

                  {obj2?.Auto_Amount ? (
                    <p>{obj2?.Auto_Amount}</p>
                  ) : (
                    <input
                      type=""
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setAuto_Amount(e.target.value)}
                    />
                  )}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Amount of support received (in INR)</label>

                  {obj2?.Auto_support ? (
                    <p>{obj2?.Auto_support}</p>
                  ) : (
                    <input
                      type="text"
                      className="vi_0"
                      onChange={(e) => setAuto_support(e.target.value)}
                    />
                  )}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Relevant Document</label>

                  <input
                    type="file"
                    id="Upload5"
                    className="vi_0"
                    onChange={(e) => setAuto_doc5(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <div className="lab mb-4">
              <label>
                Name of conference/ workshop attended for which financial
                support provided
              </label>

              {obj2?.Auto_conference ? (
                <p>{obj2?.Auto_conference}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAuto_conference(e.target.value)}
                />
              )}
            </div>

            <div className="lab mb-4">
              <label>
                Name of the professional body for which membership fee is
                provided
              </label>

              {obj2?.Auto_professional ? (
                <p>{obj2?.Auto_professional}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAuto_professional(e.target.value)}
                />
              )}
            </div>

            <div className="row">
              <div className="col-lg-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (minimum 500 characters)
                </label>
                {obj2?.Auto_desc2 ? (
                  <p>{obj2?.Auto_desc2}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_desc2(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>

              <div className="text-end mt-3">
                {add2 ? (
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      cat2();
                    }}
                  >
                    Add
                  </Button>
                ) : (
                  <>
                    <Button onClick={handleShow2} className="btn btn-warning">
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* 06_632 */}

        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row ">
                <div className="col-lg-12">
                  <div className="lab mb-3">
                    <label>Name of the teacher</label>

                    {edit2 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Auto_teacher}
                          className="vi_0"
                          onChange={(e) => setAuto_teacher(e.target.value)}
                        />{" "}
                      </>
                    ) : (
                      <>{Auto_teacher}</>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="lab mb-3">
                    <label>Amount of support received (in INR)</label>

                    {edit2 ? (
                      <>
                        <input
                          type="text"
                          placeholder={Auto_Amount}
                          className="vi_0"
                          onChange={(e) => setAuto_Amount(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Amount}</>
                    )}
                  </div>
                </div>
              </div>

              <div className="lab mb-4">
                <label>
                  Name of conference/ workshop attended for which financial
                  support provided
                </label>

                {edit2 ? (
                  <>
                    <input
                      type="text"
                      placeholder={Auto_conference}
                      className="vi_0"
                      onChange={(e) => setAuto_conference(e.target.value)}
                    />
                  </>
                ) : (
                  <>{Auto_conference}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  Name of the professional body for which membership fee is
                  provided
                </label>

                {edit2 ? (
                  <>
                    <input
                      type="text"
                      placeholder={Auto_professional}
                      className="vi_0"
                      onChange={(e) => setAuto_professional(e.target.value)}
                    />{" "}
                  </>
                ) : (
                  <>{Auto_professional}</>
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description
                </label>
                {edit2 ? (
                  <>
                    <textarea
                      type="text"
                      className="vi_0"
                      id="name"
                      placeholder={Auto_desc2}
                      onChange={(e) => {
                        setAuto_desc2(e.target.value);
                      }}
                    />
                  </>
                ) : (
                  <>
                    {Auto_desc2?.slice(0, 70)}
                    ...
                    <Link
                      onClick={() => {
                        handleShow8();
                        setView(Auto_desc2);
                      }}
                    >
                      Read More...
                    </Link>
                  </>
                )}
              </div>

              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Document</label>

                  {edit2 ? (
                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setAuto_doc5(e.target.files[0])}
                    />
                  ) : (
                    <>{Auto_doc5?.name}</>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-success"
              onClick={() => setedit2(true)}
            >
              Edit
            </button>
            <Button
              variant="warning"
              style={{ color: "black" }}
              onClick={() => {
                cat2();
                handleClose2();
                setedit2(false);
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h3>Criteria 6.3.3</h3>
            <h6 className="mb-5">
              {" "}
              Average number of professional development / administrative
              training programs organized by the institution for teaching and
              non teaching staff during the last five years (8)
            </h6>
            <div className="row">
              <div className=" col-lg-6 lab mb-4">
                <label>Date (from)</label>

                {obj3?.Auto_Startdate ? (
                  <p>{obj3?.Auto_Startdate}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setAuto_Startdate(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label>Date (to)</label>

                {obj3?.Auto_Enddate ? (
                  <p>{obj3?.Auto_Enddate}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setAuto_Enddate(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label>No. of participants</label>

                {obj3?.Auto_participants ? (
                  <p>{obj3?.Auto_participants}</p>
                ) : (
                  <input
                    type="number"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setAuto_participants(e.target.value)}
                  />
                )}
              </div>

              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Relevant Document</label>

                  {obj1?.Auto_doc6 ? (
                    <p>{obj1?.Auto_doc6}</p>
                  ) : (
                    <input
                      type="file"
                      id="Upload6"
                      className="vi_0"
                      onChange={(e) => setAuto_doc6(e.target.files[0])}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="lab mb-4">
              <label>
                {" "}
                Title of the professional development/ administrative training
                programs organised for teaching staff (Professional development/
                administrative training programs)
              </label>

              {obj3?.Auto_title ? (
                Auto_title
              ) : (
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => setAuto_title(e.target.value)}
                />
              )}
            </div>

            <div className="row">
              <div className="col-lg-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (minimum 500 characters)
                </label>
                {obj3?.Auto_desc3 ? (
                  <p>{obj3?.Auto_desc3}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_desc3(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>

              <div className="text-end ">
                {add3 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat3();
                      setadd3(false);
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <Button
                      onClick={handleShow3}
                      className="btn btn-success mt-3 "
                    >
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* 06_633 */}

        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                <div className=" col-lg-12 lab mb-4">
                  <label>Date (from) (DD-MM-YYYY)</label>

                  {edit3 ? (
                    <input
                      type="date"
                      className="vi_0"
                      placeholder={Auto_Startdate}
                      onChange={(e) => setAuto_Startdate(e.target.value)}
                    />
                  ) : (
                    <>{Auto_Startdate}</>
                  )}
                </div>
                <div className=" col-lg-12 lab mb-4">
                  <label>Date (to) (DD-MM-YYYY)</label>

                  {edit3 ? (
                    <input
                      type="date"
                      placeholder={Auto_Enddate}
                      className="vi_0"
                      onChange={(e) => setAuto_Enddate(e.target.value)}
                    />
                  ) : (
                    <>{Auto_Enddate}</>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>
                    Title of the professional development/ administrative
                    training programs organised for teaching staff (Professional
                    development/ administrative training programs)
                  </label>

                  {edit3 ? (
                    <input
                      type="text"
                      placeholder={Auto_title}
                      className="vi_0"
                      onChange={(e) => setAuto_title(e.target.value)}
                    />
                  ) : (
                    <>{Auto_title}</>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>No. of participants</label>

                  {edit3 ? (
                    <input
                      type="text"
                      placeholder={Auto_participants}
                      className="vi_0"
                      onChange={(e) => setAuto_participants(e.target.value)}
                    />
                  ) : (
                    <>{Auto_participants}</>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>Relevant document</label>

                  {edit3 ? (
                    <input
                      type="file"
                      placeholder={Auto_doc6}
                      className="vi_0"
                      id="upload6"
                      onChange={(e) => setAuto_doc6(e.target.files[0])}
                    />
                  ) : (
                    <>{Auto_doc6?.name}</>
                  )}
                </div>
                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit3 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      placeholder={Auto_desc3}
                      onChange={(e) => {
                        setAuto_desc3(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_desc3}</>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-success"
                onClick={() => setedit3(true)}
              >
                Edit
              </button>
              <Button
                variant="warning"
                style={{ color: "black" }}
                onClick={() => {
                  cat3();
                  handleClose3();
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal.Footer>
        </Modal>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h3>Criteria 6.3.4</h3>
            <h6 className="mb-5">
              {" "}
              Average percentage of teachers undergoing online/ face-to-face
              Faculty Development Programmes during the last five years{" "}
            </h6>
            <div className="row">
              <div className=" col-lg-6 lab mb-4">
                <label>Date (from)</label>

                {obj4?.Auto_DateS ? (
                  <p>{obj4?.Auto_DateS}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setAuto_DateS(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label>Date (to) </label>

                {obj4?.Auto_DateE ? (
                  <p>{obj4?.Auto_DateE}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setAuto_DateE(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-6 lab mb-4">
                <label>Name of teacher who attended</label>

                {obj4?.Auto_NameT ? (
                  <p>{obj4?.Auto_NameT}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setAuto_NameT(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label>Title of the program</label>

                {obj4?.Auto_Titleprogram ? (
                  <p>{obj4?.Auto_Titleprogram}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setAuto_Titleprogram(e.target.value)}
                  />
                )}
              </div>

              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Relevant Document</label>

                  <input
                    type="file"
                    id="Upload7"
                    className="vi_0"
                    onChange={(e) => setAuto_doc7(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (minimum 500 characters)
                </label>
                {obj4?.Auto_desc4 ? (
                  <p>{obj4?.Auto_desc4}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_desc4(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>

              <div className="text-end ">
                {add4 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat4();
                      setadd4(false);
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <Button onClick={handleShow4}>Preview</Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* 06_634*/}

        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                <div className=" col-lg-12 lab mb-4">
                  <label>Date (from)</label>

                  {edit4 ? (
                    <input
                      type="date"
                      className="vi_0"
                      placeholder={Auto_DateS}
                      onChange={(e) => setAuto_DateS(e.target.value)}
                    />
                  ) : (
                    <>{Auto_DateS}</>
                  )}
                </div>
                <div className=" col-lg-12 lab mb-4">
                  <label>Date (to)</label>

                  {edit4 ? (
                    <input
                      type="date"
                      placeholder={Auto_DateE}
                      className="vi_0"
                      onChange={(e) => setAuto_DateE(e.target.value)}
                    />
                  ) : (
                    <>{Auto_DateE}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-12 lab mb-4">
                  <label>Name of teacher who attended</label>

                  {edit4 ? (
                    <input
                      type="text"
                      placeholder={Auto_NameT}
                      className="vi_0"
                      onChange={(e) => setAuto_NameT(e.target.value)}
                    />
                  ) : (
                    <>{Auto_NameT}</>
                  )}
                </div>
                <div className=" col-lg-12 lab mb-4">
                  <label>Title of the program</label>

                  {edit4 ? (
                    <input
                      type="text"
                      placeholder={Auto_Titleprogram}
                      className="vi_0"
                      onChange={(e) => setAuto_Titleprogram(e.target.value)}
                    />
                  ) : (
                    <>{Auto_Titleprogram}</>
                  )}
                </div>
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description
                </label>
                {edit4 ? (
                  <textarea
                    className="name"
                    type="text"
                    id="name"
                    placeholder={Auto_desc4}
                    onChange={(e) => {
                      setAuto_desc4(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_desc4}</>
                )}
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label>Relevant document</label>

                {edit4 ? (
                  <input
                    type="file"
                    className="vi_0"
                    placeholder={Auto_doc7}
                    onChange={(e) => setAuto_doc7(e.target.files[0])}
                  />
                ) : (
                  <>{Auto_doc7.name}</>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {edit4 ? (
              <>
                <Button
                  variant="warning"
                  style={{ color: "black" }}
                  onClick={() => {
                    cat4();
                    handleClose4();
                  }}
                >
                  Submit
                </Button>
              </>
            ) : (
              <>
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={() => setedit4(true)}
                >
                  Edit
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h3>Criteria 6.4.2</h3>
            <h6 className="mb-5">
              {" "}
              Funds / Grants received from non-government bodies, individuals,
              philanthropists during the last five years (not covered in
              Criterion III and V) (INR in Lakhs) (10)
            </h6>

            <div className=" col-lg-6 lab mb-4">
              <label>Purpose of the Grant</label>

              {obj5?.Auto_Grant ? (
                <p>{obj5?.Auto_Grant}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAuto_Grant(e.target.value)}
                />
              )}
            </div>
            <div className=" col-lg-6 lab mb-4">
              <label> Funds/ Grants received (INR in lakhs)</label>

              {obj5?.Auto_Funds ? (
                <p>{obj5?.Auto_Funds}</p>
              ) : (
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => setAuto_Funds(e.target.value)}
                />
              )}
            </div>

            <div className="  lab mb-4">
              <label>
                Name of the non government funding agencies/ individuals
              </label>

              {obj5?.Auto_agencies ? (
                <p>{obj5?.Auto_agencies}</p>
              ) : (
                <input
                  type=""
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAuto_agencies(e.target.value)}
                />
              )}
            </div>

            <div className="lab mb-4">
              <label>
                {" "}
                Upload document to Audited Statement of Accounts reflecting the
                receipts{" "}
              </label>

              <input
                type="file"
                id="Upload8"
                className="vi_0"
                onChange={(e) => setAuto_doc8(e.target.files[0])}
              />
            </div>

            <div className="row">
              <div className="col-lg-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (minimum 500 characters)
                </label>
                {obj5?.Auto_desc5 ? (
                  <p>{obj5?.Auto_desc5}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_desc5(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>

              <div className="text-end">
                {add5 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat5();
                      setadd5(false);
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <Button onClick={handleShow5}>Preview</Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                <div className=" col-lg-12 lab mb-4">
                  <label>Purpose of the Grant</label>

                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Auto_Grant}
                      className="vi_0"
                      onChange={(e) => setAuto_Grant(e.target.value)}
                    />
                  ) : (
                    <>{Auto_Grant}</>
                  )}
                </div>
                <div className=" col-lg-12 lab mb-4">
                  <label> Funds/ Grants received (INR in lakhs)</label>

                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Auto_Funds}
                      className="vi_0"
                      onChange={(e) => setAuto_Funds(e.target.value)}
                    />
                  ) : (
                    <>{Auto_Funds}</>
                  )}
                </div>
              </div>

              <div className="  lab mb-4">
                <label>
                  Name of the non government funding agencies/ individuals
                </label>

                {edit5 ? (
                  <input
                    type="text"
                    placeholder={Auto_agencies}
                    className="vi_0"
                    onChange={(e) => setAuto_agencies(e.target.value)}
                  />
                ) : (
                  <>{Auto_agencies}</>
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description
                </label>
                {edit5 ? (
                  <textarea
                    className="name"
                    type="text"
                    id="name"
                    placeholder={Auto_desc5}
                    onChange={(e) => {
                      setAuto_desc5(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_desc5}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  Upload the Audited Statement of Accounts reflecting the
                  receipts
                </label>

                {edit5 ? (
                  <input
                    type="file"
                    className="vi_0"
                    onChange={(e) => setAuto_doc8(e.target.files[0])}
                  />
                ) : (
                  <>{Auto_doc8.name}</>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-success"
                onClick={() => setedit5(true)}
              >
                Edit
              </button>
              <Button
                variant="warning"
                style={{ color: "black" }}
                onClick={() => {
                  cat5();
                  handleClose5();
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal.Footer>
        </Modal>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h3>Criteria 6.5.3</h3>
            <h6 className="mb-5 ">
              {" "}
              Quality assurance initiatives of the institution include: (10) 1.
              Regular meeting of Internal Quality Assurance Cell (IQAC);
              Feedback collected, analysed and used for improvements 2.
              Collaborative quality intitiatives with other institution(s) 3.
              Participation in NIRF 4.Any other quality audit recognized by
              state, national or international agencies (ISO Certification)
            </h6>
            <div className="row">
              <div className="lab">
                <label>
                  Orientation programme on quality issues for teachers and
                  students,{" "}
                </label>
              </div>

              <div className="row ">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from)</label>

                  {obj6?.Auto_From ? (
                    <p>{obj6?.Auto_From}</p>
                  ) : (
                    <input
                      type="date"
                      className="vi_0"
                      onChange={(e) => setAuto_From(e.target.value)}
                    />
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to)</label>

                  {obj6?.Auto_To ? (
                    <p>{obj6?.Auto_To}</p>
                  ) : (
                    <input
                      type="date"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setAuto_To(e.target.value)}
                    />
                  )}
                </div>
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label> Participation in NIRF along with Status.</label>

                {obj6?.Auto_NIRF ? (
                  <p>{obj6?.Auto_NIRF}</p>
                ) : (
                  <input
                    type="text"
                    className="vi_0"
                    onChange={(e) => setAuto_NIRF(e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className="lab mb-4">
              <label>
                {" "}
                Academic Administrative Audit (AAA) and follow up action{" "}
              </label>

              {obj6?.Auto_AcademicAA ? (
                <p>{obj6?.Auto_AcademicAA}</p>
              ) : (
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => setAuto_AcademicAA(e.target.value)}
                />
              )}
            </div>

            <div className="lab mb-4">
              <label> ISO Certification. and nature and validity period </label>

              {obj6?.Auto_ISO ? (
                <p>{obj6?.Auto_ISO}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAuto_ISO(e.target.value)}
                />
              )}
            </div>

            <div className="lab mb-4">
              <label>
                NBA or any other certification received with program
                specifications.
              </label>

              {obj6?.Auto_NBA ? (
                <p>{obj6?.Auto_NBA}</p>
              ) : (
                <input
                  type=""
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAuto_NBA(e.target.value)}
                />
              )}
            </div>

            <div className="lab mb-4">
              <label>
                Conferences, Seminars, Workshops on quality conducted{" "}
              </label>

              {obj6?.Auto_Conferences ? (
                <p>{obj6?.Auto_Conferences}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAuto_Conferences(e.target.value)}
                />
              )}
            </div>
            <div className="lab mb-4">
              <label> ISO Certification. and nature and validity period </label>

              {obj6?.Auto_ISO ? (
                <p>{obj6?.Auto_ISO}</p>
              ) : (
                <input
                  type=""
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAuto_ISO(e.target.value)}
                />
              )}
            </div>

            <div className="lab mb-4">
              <label>
                {" "}
                Collaborative quality initiatives with other institution(s)
                (Provide name of the institution and activity)
              </label>

              {obj6?.Auto_institution ? (
                <p>{obj6?.Auto_institution}</p>
              ) : (
                <input
                  type=""
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setAuto_institution(e.target.value)}
                />
              )}
            </div>

            <div className="row">
              <div className="lab mb-4">
                <label> Upload Relevent the doument</label>

                <input
                  type="file"
                  id="Upload9"
                  className="vi_0"
                  onChange={(e) => setAuto_doc9(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (minimum 500 characters)
                </label>
                {obj6?.Auto_desc6 ? (
                  <p>{obj6?.Auto_desc6}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_desc6(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>

              <div className="text-end ">
                {add6 ? (
                  <button
                    type="submit"
                    className="btn btn-success mt-3 "
                    onClick={() => {
                      cat6();
                      setadd6(false);
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    <Button
                      className="btn btn-success mt-3 "
                      onClick={handleShow6}
                    >
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="lab">
                <label>
                  Orientation programme on quality issues for teachers and
                  students,
                </label>
              </div>

              <div className="row">
                <div className=" col-lg-12 lab mb-4">
                  <label>Date (from) (DD-MM-YYYY)</label>

                  {edit6 ? (
                    <input
                      type="date"
                      placeholder={Auto_From}
                      className="vi_0"
                      onChange={(e) => setAuto_From(e.target.value)}
                    />
                  ) : (
                    <>{Auto_From}</>
                  )}
                </div>
                <div className=" col-lg-12 lab mb-4">
                  <label>Date (to) (DD-MM-YYYY)</label>

                  {edit6 ? (
                    <input
                      type="date"
                      placeholder={Auto_To}
                      className="vi_0"
                      onChange={(e) => setAuto_To(e.target.value)}
                    />
                  ) : (
                    <>{Auto_To}</>
                  )}
                </div>
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label> Participation in NIRF along with Status.</label>

                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Auto_NIRF}
                    className="vi_0"
                    onChange={(e) => setAuto_NIRF(e.target.value)}
                  />
                ) : (
                  <>{Auto_NIRF}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>Academic Administrative Audit (AAA)</label>

                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Auto_AcademicAA}
                    className="vi_0"
                    onChange={(e) => setAuto_AcademicAA(e.target.value)}
                  />
                ) : (
                  <>{Auto_AcademicAA}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  {" "}
                  NBA or any other certification received with program
                  specifications.{" "}
                </label>

                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Auto_NBA}
                    className="vi_0"
                    onChange={(e) => setAuto_NBA(e.target.value)}
                  />
                ) : (
                  <>{Auto_NBA}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  {" "}
                  ISO Certification. and nature and validity period{" "}
                </label>

                {edit6 ? (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setAuto_ISO(e.target.value)}
                  />
                ) : (
                  <>{Auto_ISO}</>
                )}
              </div>

              <div className="  lab mb-4">
                <label>
                  {" "}
                  Conferences, Seminars, Workshops on quality conducted{" "}
                </label>

                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Auto_Conferences}
                    className="vi_0"
                    onChange={(e) => setAuto_Conferences(e.target.value)}
                  />
                ) : (
                  <>{Auto_Conferences}</>
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  {" "}
                  Collaborative quality initiatives with other institution(s)
                  (Provide name of the institution and activity)
                </label>

                {edit6 ? (
                  <input
                    type="text"
                    placeholder={Auto_institution}
                    className="vi_0"
                    onChange={(e) => setAuto_institution(e.target.value)}
                  />
                ) : (
                  <>{Auto_institution}</>
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description
                </label>
                {edit6 ? (
                  <textarea
                    className="name"
                    type="text"
                    id="name"
                    onChange={(e) => {
                      setAuto_desc6(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_desc6}</>
                )}
              </div>

              <div className="lab mb-4">
                <label> Upload the relevant document</label>

                {edit6 ? (
                  <>
                    <input
                      type="file"
                      className="vi_0"
                      placeholder={Auto_doc9}
                      onChange={(e) => setAuto_doc9(e.target.files[0])}
                    />
                  </>
                ) : (
                  <>{Auto_doc9.name}</>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {edit6 ? (
              <>
                <Button
                  className="btn btn-success"
                  onClick={() => {
                    cat6();
                    handleClose6();
                  }}
                >
                  Submit
                </Button>
              </>
            ) : (
              <>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit6(true)}
                >
                  Edit
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <div className="text-center mt-5 mb-5">
          <button
            type="submit"
            className="btn btn-danger "
            onClick={() => Aucriteria1()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Aucriteria6;
