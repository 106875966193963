import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";

function PG_01_121() {
  const navigate = useNavigate();

  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [selectedYear, setselectedYear] = useState("");
  const [content, setContent] = useState([]);
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("PG_01_1.2.1");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.PG_approve === "true" && selectedYear === item1?.PG_Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          Year: item?.PG_Year,
          Criteria: item?.PG_Criteria01_121?.PG_criteria,

          "Program Code": item?.PG_Criteria01_121?.PG_PrgmCode,
          " Program Name": item?.PG_Criteria01_121?.PG_PrgName1,

          "Year Of Introduction": item?.PG_Criteria01_121?.PG_YOfIntro,
          "Status of implementation of CBCS/elective course":
            item?.PG_Criteria01_121?.PG_Status,
          "Year of implementation of CBCS/elective course":
            item?.PG_Criteria01_121?.PG_YOfCBCS,
          "Description (min 50 characters)": item?.PG_Criteria01_121?.PG_Desc2,
          "Upload the relevant document": item?.PG_Criteria01_121?.PG_Link2,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      // setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  //  ===========================================================================================
  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        pricipalDetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
        item1?.PG_approve === "true" &&
        selectedYear === item1?.PG_Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 1 });
  };

  //  ===========================================================================================

  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/pgcri01_121"
      );
      if (res.status === 200) {
        setContent(res.data.getPG_C121);
        setfilterPdf(res.data.getPG_C121);
        setdata1(res.data.getPG_C121);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);
  console.log("content", content);

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);

  console.log("principal", pricipalDetails?._id);
  console.log("pfd", filterPdf);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-01-121
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>

            <div className=" col-lg-2 lab text-end">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = content?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.PG_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.PG_approve === "true" &&
                      selectedYear === item1?.PG_Year &&
                      (SearchItem === "" ||
                        item1?.PG_Criteria01_121?.PG_criteria?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Year?.includes(SearchItem) ||
                        item1?.PG_Criteria01_121?.PG_PrgmCode?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.PG_Criteria01_121?.PG_PrgName1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase() ||
                            item1?.PG_Criteria01_121?.PG_YOfIntro?.toString()?.includes(
                              SearchItem
                            ) ||
                            item1?.PG_Criteria01_121?.PG_Status?.toLowerCase()?.includes()
                        ) ||
                        item1?.PG_Criteria01_121?.PG_YOfCBCS?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase() ||
                            item1?.PG_Criteria01_121?.PG_YOfRevision?.toString()?.includes(
                              SearchItem
                            ) ||
                            item1?.PG_Criteria01_121?.PG_Desc2?.toLowerCase()?.includes(
                              SearchItem?.toLowerCase()
                            ) ||
                            item1?.PG_Criteria01_121?.PG_Link2?.toLowerCase()?.includes(
                              SearchItem?.toLowerCase()
                            )
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });

                  if (arr1?.length > 0) {
                    navigate("/PDFprintPG1_121", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-end m-0 mt-4 align-items-center">
        <div className=" col-lg-3 do-sear d-flex align-items-center  ">
          <label style={{ width: "130px" }}>Select Year</label>
          <select
            className="criteriaform vi_0"
            style={{ width: "150px" }}
            onChange={(e) => setselectedYear(e.target.value)}
          >
            <option>Select Year</option>
            {allYears
              ?.sort((a, b) => {
                return Number(a?.from) - Number(b?.from);
              })
              ?.map((item) => {
                return (
                  <option value={`${item?.from}-${item?.to}`}>
                    {item?.from} - {item?.to}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="col-lg-9">
          <Nav defaultActiveKey="/ugcriteria04">
            <Nav.Item>
              <Nav.Link
                className="p-0"
                eventKey="link-1"
                onClick={() => navigate("/PG_01_113")}
              >
                <Button className="criteriabtn btn-sub1">1.1.3</Button>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="p-0"
                eventKey="link-2"
                onClick={() => navigate("/PG_01_121")}
              >
                <Button
                  variant="warning"
                  style={{ backgroundColor: "#ffc107" }}
                  className="criteriabtn-0 btn-sub"
                >
                  1.2.1
                </Button>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="p-0"
                eventKey="link-3"
                onClick={() => navigate("/PG_01_122")}
              >
                <Button className="criteriabtn btn-sub">1.2.2</Button>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="p-0"
                eventKey="link-3"
                onClick={() => navigate("/PG_01_132")}
              >
                <Button className="criteriabtn btn-sub">1.3.2</Button>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="p-0"
                eventKey="link-3"
                onClick={() => navigate("/PG_01_133")}
              >
                <Button className="criteriabtn btn-sub">1.3.3</Button>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
      <div className="container-fluid mt-4">
        <div className="mo-gra">
          <Table
            responsive
            striped
            bordered
            hover
            // style={{ textAlign: "center" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Year</th>
                <th>Criteria</th>
                <th>Program Code</th>
                <th>Program Name</th>
                <th>Year Of Introduction</th>
                <th>Status of implementation of CBCS/elective course</th>
                <th>Year of implementation of CBCS/elective course</th>
                <th>Description</th>
                <th>Document</th>
              </tr>
            </thead>
            <tbody>
              {content
                ?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    pricipalDetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.PG_approve === "true" &&
                    selectedYear === item1?.PG_Year &&
                    (SearchItem === "" ||
                      item1?.PG_Criteria01_121?.PG_criteria?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Year?.includes(SearchItem) ||
                      item1?.PG_Criteria01_121?.PG_PrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_121?.PG_PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase() ||
                          item1?.PG_Criteria01_121?.PG_YOfIntro?.toString()?.includes(
                            SearchItem
                          ) ||
                          item1?.PG_Criteria01_121?.PG_Status?.toLowerCase()?.includes()
                      ) ||
                      item1?.PG_Criteria01_121?.PG_YOfCBCS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase() ||
                          item1?.PG_Criteria01_121?.PG_Desc2?.toLowerCase()?.includes(
                            SearchItem?.toLowerCase()
                          )
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })
                ?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.PG_Year}</td>
                      <td>{item?.PG_Criteria01_121?.PG_criteria}</td>
                      <td>{item?.PG_Criteria01_121?.PG_PrgmCode}</td>
                      <td>{item?.PG_Criteria01_121?.PG_PrgName1}</td>
                      <td>{item?.PG_Criteria01_121?.PG_YOfIntro}</td>
                      <td>{item?.PG_Criteria01_121?.PG_Status}</td>
                      <td>{item?.PG_Criteria01_121?.PG_YOfCBCS}</td>
                      <td>
                        {item?.PG_Criteria01_121?.PG_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />{" "}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        {/* show model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_121?.PG_Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Show description modal */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.PG_Criteria01_121?.PG_Desc2}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default PG_01_121;
