import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FaDownload, FaEye } from "react-icons/fa";
import { Document } from "react-pdf";
const Adminfinalreports = () => {
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetalils = JSON.parse(sessionStorage.getItem("collegeDetails"));
  const [View, setView] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [SearchData, setSearchData] = useState("");

  // ================ calling api to get all final reports list ===============================
  const [finalReportList, setfinalReportList] = useState([]);
  const getAllFinalReports = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/pri/getAllReports"
      );
      if (res.status === 200) {
        setfinalReportList(res.data.getFinalReports);
      }
    } catch (error) {
      console.log(error);
      setfinalReportList(error.response.data.getFinalReports);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getAllFinalReports();
    }
  }, []);
  return (
    <div className="add-gr">
      <div className="container">
        <h5 className="mt-4">Final Report List</h5>
        <div
          className="ad-b mt-4"
          style={{
            display: "flex",
            gap: "21px",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <div
            className="ad-b mt-4"
            style={{
              display: "flex",
              gap: "21px",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <div className="do-sear mt-2 d-flex ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                value={SearchData}
                onChange={(e) => setSearchData(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table
            responsive
            striped
            bordered
            hover
            style={{ textAlign: "center" }}
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Report Type </th>
                <th>Year</th>
                <th>Document Name</th>
                <th>Doc</th>
              </tr>
            </thead>
            <tbody>
              {finalReportList
                ?.filter(
                  (val) =>
                    val?.addedByCollege?._id?.toString() ==
                    collegeDetalils?._id.toString()
                )
                ?.filter(
                  (val) =>
                    SearchData == "" ||
                    val?.ReportType?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase()) ||
                    val?.year
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase()) ||
                    val?.docName
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase())
                )
                ?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{item?.ReportType}</td>
                      <td>
                        <div style={{ fontSize: "15px" }}>
                          {item?.year?.map((ele, index) => {
                            return (
                              <>
                                <span key={index}>
                                  {ele}
                                  {index + 1 === item.year.length ? (
                                    <></>
                                  ) : (
                                    <>, </>
                                  )}{" "}
                                </span>
                              </>
                            );
                          })}
                        </div>
                      </td>
                      <td>{item?.docName}</td>
                      <td>
                        <FaEye
                          onClick={() => {
                            setView(item);
                            handleShow();
                          }}
                          fontSize={20}
                        />
                      </td>
                      {/* <td><FaDownload fontSize={15}/></td> */}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

        <Modal show={show} onHide={handleClose} className="iiqareportmodal">
          <Modal.Header closeButton>
            <Modal.Title>Final Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/FinalReport/${View?.finalReportDoc}`}
                  type="application/pdf"
                ></object>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
};

export default Adminfinalreports;
