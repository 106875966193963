import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";

function Event() {
  // ================Add event model==================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================delete event model==================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================edit event model==================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================description event model==================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;

  // Creating image URL
  const [imageURL, setimageURL] = useState(null);
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL(URL.createObjectURL(event.target.files[0]));
      console.log("ghghg", URL.createObjectURL(event.target.files[0]));
    }
  };

  // ===============Add Event=====================
  const [eventImg, seteventImg] = useState("");
  const [eventName, seteventName] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [eventDescription, seteventDescription] = useState("");
  const [venue, setvenue] = useState("");
  const [address, setaddress] = useState("");
  const [organizerName, setorganizerName] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [website, setwebsite] = useState("");
  const [DescriptionDetails, setDescriptionDetails] = useState("");
  const [View, setView] = useState({});

  const addEvent = async (e) => {
    e.preventDefault();

    if (!eventImg) {
      return alert("Enter Event Image");
    }

    if (!eventName) {
      return alert("Enter Event Name");
    }

    if (!email) {
      return alert("Enter Your Email");
    }
    if (!email.match(validRegex)) {
      return alert("Please Enter Valid Email-Id");
    }
    if (!phone) {
      return alert("Enter Contact Number");
    }
    if (phone.length !== 10) {
      return alert("Enter Contact Number should be 10 digits");
    }

    if (!phone.match(mobilevalid)) {
      return alert("Enter Valid Contact Number");
    }

    if (!startDate) {
      return alert("Enter Start Date");
    }
    if (!endDate) {
      return alert("Enter End Date");
    }
    if (!startTime) {
      return alert("Enter Start Time");
    }
    if (!endTime) {
      return alert("Enter End Time");
    }

    if (!eventDescription) {
      return alert("Enter Event Description");
    }
    if (!website) {
      return alert("Copy & Paste Your Website link ");
    }
    if (!organizerName) {
      return alert("Enter Organizer Name");
    }
    if (!address) {
      return alert("Enter Address");
    }

    if (!venue) {
      return alert("Enter Venue");
    }

    let obj = {
      eventImg: eventImg,
      eventName: eventName,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      eventDescription: eventDescription,
      venue: venue,
      address: address,
      organizerName: organizerName,
      phone: phone,
      email: email,
      website: website,
    };
    try {
      const config = {
        url: "/admin/addEvent",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          getEventContent();
          seteventImg("");
          seteventName("");
          setstartDate("");
          setendDate("");
          setstartTime("");
          setendTime("");
          seteventDescription("");
          setvenue("");
          setaddress("");
          setorganizerName("");
          setphone("");
          setemail("");
          setwebsite("");
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // =================== calling api for getting the Event content======================
  const [eventDetails, seteventDetails] = useState([]);
  const getEventContent = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getEvents");
      if (res.status === 200) {
        seteventDetails(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================== calling api for editing the Leadership content======================
  const editEvent = async (e) => {
    e.preventDefault();
    if (
      !eventImg &&
      !eventName &&
      !email &&
      !phone &&
      !startDate &&
      !endDate &&
      !startTime &&
      !endTime &&
      !eventDescription &&
      !website &&
      !organizerName &&
      !address &&
      !venue
    ) {
      return alert("Please provide atleast one field to edit!!!");
    }

    let obj = {
      eventImg: eventImg,
      eventName: eventName,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      eventDescription: eventDescription,
      venue: venue,
      address: address,
      organizerName: organizerName,
      phone: phone,
      email: email,
      website: website,
    };
    try {
      const config = {
        url: "/admin/editEvent/" + View?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getEventContent();
          seteventImg("");
          seteventName("");
          setstartDate("");
          setendDate("");
          setstartTime("");
          setendTime("");
          seteventDescription("");
          setvenue("");
          setaddress("");
          setorganizerName("");
          setphone("");
          setemail("");
          setwebsite("");
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  // =================== calling api for deleting the Leadership content======================
  const deleteContent = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/deleteEvent/" + View?._id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getEventContent();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };

  // ====================================================================================
  useEffect(() => {
    getEventContent();
  }, []);

  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Event Page
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Event Image</th>
                <th>Event Name</th>
                <th>Event Starts</th>
                <th>Event Ends</th>
                <th>Event Start Timing</th>
                <th>Event End Timing</th>
                <th>Event Description</th>
                <th>Event Venue</th>
                <th>Event Address</th>
                <th>Event Organizer Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Event Website</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {eventDetails?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>
                      <img
                        src={`https://brightnaac.co.in/Events/${item?.eventImg}`}
                        style={{ width: "100%", height: "auto" }}
                        alt="no-image"
                      />
                    </td>
                    <td>{item?.eventName}</td>
                    <td>{moment(item?.startDate).format("DD-MM-YYYY")}</td>
                    <td>{moment(item?.endDate).format("DD-MM-YYYY")}</td>
                    <td>{item?.startTime}</td>
                    <td>{item?.endTime}</td>
                    <td>
                      {item?.eventDescription?.slice(0, 50)}{" "}
                      <Link
                        onClick={() => {
                          setDescriptionDetails(item?.eventDescription);
                          handleShow3();
                        }}
                      >
                        Read More...
                      </Link>
                    </td>
                    <td>{item?.venue}</td>
                    <td>{item?.address}</td>
                    <td>{item?.organizerName}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.email}</td>
                    <td>{item?.website}</td>
                    <td>
                      <span
                        onClick={() => {
                          setView(item);
                          handleShow2();
                        }}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          margin: "10px",
                        }}
                      >
                        <BiEdit size={20} />
                      </span>
                      <span
                        onClick={() => {
                          setView(item);
                          handleShow1();
                        }}
                        style={{
                          cursor: "pointer",
                          color: "red",
                          margin: "10px",
                        }}
                      >
                        <AiFillDelete size={20} />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* add Event */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Image: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  className="vi_0"
                  type="file"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => seteventImg(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Name: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => seteventName(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Starting Date: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="date"
                  className="vi_0"
                  onChange={(e) => setstartDate(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Ending Date: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="date"
                  className="vi_0"
                  onChange={(e) => setendDate(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Starting Time: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="time"
                  className="vi_0"
                  onChange={(e) => setstartTime(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Ending Time: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="time"
                  className="vi_0"
                  onChange={(e) => setendTime(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Description: </b>
                </lable>
              </div>
              <div>
                {" "}
                <textarea
                  id="w3review"
                  name="w3review"
                  className="vi_0"
                  rows="4"
                  cols="50"
                  onChange={(e) => seteventDescription(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Venue: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => setvenue(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Address: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => setaddress(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Organizer Name: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => setorganizerName(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Phone: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="number"
                  className="vi_0"
                  onChange={(e) => setphone(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Email: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Website: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  className="vi_0"
                  onChange={(e) => setwebsite(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => addEvent(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* delete event */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteContent(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>

        {/* edit event */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Image: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="file"
                  className="vi_0"
                  id="Eupload1"
                  accept="image/*"
                  hidden
                  onChange={(e) => {
                    onImageChange(e);
                    seteventImg(e.target.files[0]);
                  }}
                />
                <label for="Eupload1">
                  {eventImg ? (
                    <img
                      src={imageURL}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  ) : (
                    <img
                      src={`https://brightnaac.co.in/Events/${View?.eventImg}`}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  )}
                </label>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Name: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  className="vi_0"
                  placeholder={View?.eventName}
                  onChange={(e) => seteventName(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Starting Date: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="date"
                  className="vi_0"
                  // placeholder={View?.startDate}

                  onChange={(e) => setstartDate(e.target.value)}
                />
                {moment(View?.startDate).format("DD-MM-YYYY")}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Ending Date: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="date"
                  className="vi_0"
                  onChange={(e) => setendDate(e.target.value)}
                />
                {moment(View?.endDate).format("DD-MM-YYYY")}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Starting Time: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="time"
                  className="vi_0"
                  onChange={(e) => setstartTime(e.target.value)}
                />
                {View?.startTime}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Ending Time: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="time"
                  className="vi_0"
                  onChange={(e) => setendTime(e.target.value)}
                />
                {View?.endTime}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Description: </b>
                </lable>
              </div>
              <div>
                {" "}
                <textarea
                  id="w3review"
                  name="w3review"
                  className="vi_0"
                  rows="4"
                  cols="50"
                  onChange={(e) => seteventDescription(e.target.value)}
                >
                  {View?.eventDescription}
                </textarea>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Venue: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  placeholder={View?.venue}
                  className="vi_0"
                  onChange={(e) => setvenue(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Address: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  placeholder={View?.address}
                  className="vi_0"
                  onChange={(e) => setaddress(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Event Organizer Name: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  placeholder={View?.organizerName}
                  className="vi_0"
                  onChange={(e) => setorganizerName(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Phone: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="number"
                  placeholder={View?.phone}
                  className="vi_0"
                  onChange={(e) => setphone(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Email: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="email"
                  className="vi_0"
                  placeholder={View?.email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4">
                <lable>
                  <b>Website: </b>
                </lable>
              </div>
              <div className="col-md-8">
                {" "}
                <input
                  type="text"
                  className="vi_0"
                  placeholder={View?.website}
                  onChange={(e) => setwebsite(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => editEvent(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show3} onHide={handleClose3}>
          <Modal.Body>
            <div>{DescriptionDetails}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Event;
