import React from "react";

const UG3341aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Title of the collaborative activity</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria03_341?.Collo}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Name of the collaborating / linking agency with contact details
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_341?.NCollo}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the participant</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_341?.NameParti}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of collaboration</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_341?.ColloYear}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Duration</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_341?.Dur}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria03_341?.Desc18}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria03/${item?.Criteria03_341?.Link19}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG3341aqar;
