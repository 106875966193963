import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PG_Criteria007 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //post method for pg_criteria_07
  const [PG_MainYear7, setPG_MainYear7] = useState("");

  const [PG_Budget, setPG_Budget] = useState("");
  const [PG_Lighting, setPG_Lighting] = useState("");
  const [PG_PhyFac, setPG_PhyFac] = useState("");
  const [PG_Prolift, setPG_Prolift] = useState("");
  const [PG_Ramp, setPG_Ramp] = useState("");
  const [PG_Braille, setPG_Braille] = useState("");
  const [PG_RestRoom, setPG_RestRoom] = useState("");
  const [PG_Scribes, setPG_Scribes] = useState("");
  const [PG_SpecialSkills, setPG_SpecialSkills] = useState("");
  const [PG_AnyOther, setPG_AnyOther] = useState("");
  const [PG_Annual, setPG_Annual] = useState("");
  const [PG_NoOfIniti1, setPG_NoOfIniti1] = useState("");
  const [PG_NoOfIniti2, setPG_NoOfIniti2] = useState("");
  const [PG_Date1, setPG_Date1] = useState("");
  const [PG_DateDuration, setPG_DateDuration] = useState("");
  const [PG_Date2, setPG_Date2] = useState("");
  const [PG_Power, setPG_Power] = useState("");
  const [PG_TPower, setPG_TPower] = useState("");
  const [PG_ReEnergyS, setPG_ReEnergyS] = useState("");
  const [PG_ReEnergyG, setPG_ReEnergyG] = useState("");
  const [PG_EnergySup, setPG_EnergySup] = useState("");
  const [PG_PercentageLED, setPG_PercentageLED] = useState("");
  const [PG_PercentageLight, setPG_PercentageLight] = useState("");
  const [PG_NoOfParticipants, setPG_NoOfParticipants] = useState("");
  const [PG_NoOfActivities, setPG_NoOfActivities] = useState("");
  const [PG_TitlePro, setPG_TitlePro] = useState("");
  const [PG_NoOfParticipants1, setPG_NoOfParticipants1] = useState("");
  const [PG_Male, setPG_Male] = useState("");
  const [PG_Female, setPG_Female] = useState("");
  const [PG_Yea, setPG_Yea] = useState("");
  const [PG_Total, setPG_Total] = useState("");
  const [PG_PowRe, setPG_PowRe] = useState("");
  const [PG_InitiName, setPG_InitiName] = useState("");
  const [PG_DurInitiative, setPG_DurInitiative] = useState("");
  const [PG_Desc29, setPG_Desc29] = useState("");
  const [PG_Link29, setPG_Link29] = useState("");
  const [PG_Link30, setPG_Link30] = useState("");
  const [PG_Link31, setPG_Link31] = useState("");

  const [obj1, setobj1] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getpgcriteria7 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpgcriteria7();
  }, []);

  const pgcriteria7 = async () => {
    if (!PG_MainYear7) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/pgadddetails07",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Year: PG_MainYear7,

          PG_Criteria07_007: obj1,
          PG_Link29: PG_Link29,
          PG_Link30: PG_Link30,
          PG_Link31: PG_Link31,

          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          navigate("/pgcriteria07");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc29;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !PG_Budget ||
          !PG_Lighting ||
          !PG_PhyFac ||
          !PG_Prolift ||
          !PG_Ramp ||
          !PG_Braille ||
          !PG_RestRoom ||
          !PG_Scribes ||
          !PG_SpecialSkills ||
          !PG_AnyOther ||
          !PG_Annual ||
          !PG_NoOfIniti1 ||
          !PG_NoOfIniti2 ||
          !PG_Date1 ||
          !PG_DateDuration ||
          !PG_Date2 ||
          !PG_Power ||
          !PG_TPower ||
          !PG_ReEnergyS ||
          !PG_ReEnergyG ||
          !PG_EnergySup ||
          !PG_PercentageLED ||
          !PG_PercentageLight ||
          !PG_NoOfParticipants ||
          !PG_NoOfActivities ||
          !PG_TitlePro ||
          !PG_NoOfParticipants1 ||
          !PG_Male ||
          !PG_Female ||
          !PG_Yea ||
          !PG_Total ||
          !PG_PowRe ||
          !PG_InitiName ||
          !PG_DurInitiative ||
          !PG_Desc29 ||
          !PG_Link29 ||
          !PG_Link30 ||
          !PG_Link31
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", PG_Link29);
      console.log("mm,", PG_Link30);
      console.log("ooo,", PG_Link31);
      let obj = {
        PG_criteria: "0.0.7",
        PG_Budget: PG_Budget,
        PG_Lighting: PG_Lighting,
        PG_PhyFac: PG_PhyFac,
        PG_Prolift: PG_Prolift,
        PG_Ramp: PG_Ramp,
        PG_Braille: PG_Braille,
        PG_RestRoom: PG_RestRoom,
        PG_Scribes: PG_Scribes,
        PG_SpecialSkills: PG_SpecialSkills,
        PG_AnyOther: PG_AnyOther,
        PG_Annual: PG_Annual,
        PG_NoOfIniti1: PG_NoOfIniti1,
        PG_NoOfIniti2: PG_NoOfIniti2,
        PG_Date1: PG_Date1,
        PG_DateDuration: PG_DateDuration,
        PG_Date2: PG_Date2,
        PG_Power: PG_Power,
        PG_TPower: PG_TPower,
        PG_ReEnergyS: PG_ReEnergyS,
        PG_ReEnergyG: PG_ReEnergyG,
        PG_EnergySup: PG_EnergySup,
        PG_PercentageLED: PG_PercentageLED,
        PG_PercentageLight: PG_PercentageLight,
        PG_NoOfParticipants: PG_NoOfParticipants,
        PG_NoOfActivities: PG_NoOfActivities,
        PG_TitlePro: PG_TitlePro,
        PG_NoOfParticipants1: PG_NoOfParticipants1,
        PG_Male: PG_Male,
        PG_Female: PG_Female,
        PG_Yea: PG_Yea,
        PG_Total: PG_Total,
        PG_PowRe: PG_PowRe,
        PG_InitiName: PG_InitiName,
        PG_DurInitiative: PG_DurInitiative,
        PG_Desc29: PG_Desc29,
        PG_Link29: PG_Link29,
        PG_Link30: PG_Link30,
        PG_Link31: PG_Link31,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getpgcriteria7();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            PostGraduation_Criteria-07
          </span>
        </div>

        <dic className="container">
          <div className="row-fluid border bs">
            <div class=" col-md-6" style={{ padding: "20px" }}>
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.PG_MainYear7 ? (
                <p>{obj1?.PG_MainYear7}</p>
              ) : (
                <select
                  class="form-select form-select-sm vi_0"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setPG_MainYear7(e.target.value)}
                >
                  <option selected>--select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </dic>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_07</h5>

            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total Lighting requirements
                </label>
                {obj1?.PG_Lighting ? (
                  <p>{obj1?.PG_Lighting}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Lighting(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Budget allocated for green initiatives:
                </label>
                {obj1?.PG_Budget ? (
                  <p>{obj1?.PG_Budget}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Budget(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline mt-3">
              Status of implementation of CBCS / Elective Course System (ECS):
            </h6>
            <div className="row">
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Physical Facilities:
                </label>
                {obj1?.PG_PhyFac ? (
                  <p>{obj1?.PG_PhyFac}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setPG_PhyFac(e.target.value);
                    }}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Provision for Lift:
                </label>
                {obj1?.PG_Prolift ? (
                  <p>{obj1?.PG_Prolift}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setPG_Prolift(e.target.value);
                    }}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Ramp/ Rails:
                </label>
                {obj1?.PG_Ramp ? (
                  <p>{obj1?.PG_Ramp}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setPG_Ramp(e.target.value);
                    }}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Braille Software/Facilities:
                </label>
                {obj1?.PG_Braille ? (
                  <p>{obj1?.PG_Braille}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setPG_Braille(e.target.value);
                    }}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Rest Rooms:{" "}
                </label>
                {obj1?.PG_RestRoom ? (
                  <p>{obj1?.PG_RestRoom}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setPG_RestRoom(e.target.value);
                    }}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Scribes for Examination
                </label>
                {obj1?.PG_Scribes ? (
                  <p>{obj1?.PG_Scribes}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setPG_Scribes(e.target.value);
                    }}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Special skill Development for Differently Abled Students:
                </label>
                {obj1?.PG_SpecialSkills ? (
                  <p>{obj1?.PG_SpecialSkills}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "593px" }}
                    onChange={(e) => {
                      setPG_SpecialSkills(e.target.value);
                    }}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Any other Similar Facility:
                </label>
                {obj1?.PG_AnyOther ? (
                  <p>{obj1?.PG_AnyOther}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setPG_AnyOther(e.target.value);
                    }}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Annual expenditure excluding salary component of the institution
              </label>
              {obj1?.PG_Annual ? (
                <p>{obj1?.PG_Annual}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setPG_Annual(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Number of initiatives to address locational Advantages and
                Disadvantages
              </label>
              {obj1?.PG_NoOfIniti1 ? (
                <p>{obj1?.PG_NoOfIniti1}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setPG_NoOfIniti1(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Number of initiatives taken to Engage with and contribute to
                Local Community
              </label>
              {obj1?.PG_NoOfIniti2 ? (
                <p>{obj1?.PG_NoOfIniti2}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setPG_NoOfIniti2(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Date and Duration of the Initiative Name of the Initiative
                Issues Addressed
              </label>
              {obj1?.PG_DateDuration ? (
                <p>{obj1?.PG_DateDuration}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setPG_DateDuration(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Power Requirement met by Renewable Energy Sources
              </label>
              {obj1?.PG_Power ? (
                <p>{obj1?.PG_Power}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setPG_Power(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="row">
              {" "}
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total Power requirements
                </label>
                {obj1?.PG_TPower ? (
                  <p>{obj1?.PG_TPower}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_TPower(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Renewable Energy Source:
                </label>
                {obj1?.PG_ReEnergyS ? (
                  <p>{obj1?.PG_ReEnergyS}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_ReEnergyS(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Renewable Energy Generated and Used :
                </label>
                {obj1?.PG_ReEnergyG ? (
                  <p>{obj1?.PG_ReEnergyG}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_ReEnergyG(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Energy Supplied to the Grid:
                </label>
                {obj1?.PG_EnergySup ? (
                  <p>{obj1?.PG_EnergySup}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_EnergySup(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Percentage Lighting through LED bulbs:
                </label>
                {obj1?.PG_PercentageLED ? (
                  <p>{obj1?.PG_PercentageLED}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_PercentageLED(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Percentage Lighting through other sources
                </label>
                {obj1?.PG_PercentageLight ? (
                  <p>{obj1?.PG_PercentageLight}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_PercentageLight(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of Participating Students and Staff:
                </label>
                {obj1?.PG_NoOfParticipants ? (
                  <p>{obj1?.PG_NoOfParticipants}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NoOfParticipants(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  No. of Activities:
                </label>
                {obj1?.PG_NoOfActivities ? (
                  <p>{obj1?.PG_NoOfActivities}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NoOfActivities(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  TTitle of the Program/Activity:
                </label>
                {obj1?.PG_TitlePro ? (
                  <p>{obj1?.PG_TitlePro}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_TitlePro(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of Participants:
                </label>
                {obj1?.PG_NoOfParticipants1 ? (
                  <p>{obj1?.PG_NoOfParticipants1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NoOfParticipants1(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline mt-3">
              Date and Duration:
            </h6>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  From:
                </label>
                {obj1?.PG_Date1 ? (
                  <p>{obj1?.PG_Date1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Date1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  To:
                </label>
                {obj1?.PG_Date2 ? (
                  <p>{obj1?.PG_Date2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Date2(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline mt-3">
              Number of participants by Gender:
            </h6>
            <div className="row">
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  MALE:
                </label>
                {obj1?.PG_Male ? (
                  <p>{obj1?.PG_Male}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Male(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  FEMALE:
                </label>
                {obj1?.PG_Female ? (
                  <p>{obj1?.PG_Female}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Female(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year
                </label>
                {obj1?.PG_Yea ? (
                  <p>{obj1?.PG_Yea}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Yea(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total
                </label>
                {obj1?.PG_Total ? (
                  <p>{obj1?.PG_Total}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Total(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Power Requirement Renewable
                </label>
                {obj1?.PG_PowRe ? (
                  <p>{obj1?.PG_PowRe}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_PowRe(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Initiative names:
                </label>
                {obj1?.PG_InitiName ? (
                  <p>{obj1?.PG_InitiName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_InitiName(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Duration_of_the_Initiative
                </label>
                {obj1?.PG_DurInitiative ? (
                  <p>{obj1?.PG_DurInitiative}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_DurInitiative(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.PG_Desc29 ? (
                  <p>{obj1?.PG_Desc29}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    rows={3}
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Desc29(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Document Upload Select file to upload:
              </label>

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload1"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link29(e.target.files[0]);
                }}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload2">
                {" "}
                Document Upload Select file to upload:
              </label>

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload2"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link30(e.target.files[0]);
                }}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload3">
                {" "}
                Document Upload Select file to upload:
              </label>

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload3"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link31(e.target.files[0]);
                }}
              />
            </div>
            <div className="text-end">
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
          </div>

          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>PG_Criteria_07</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Lighting requirements
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_Lighting(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_Lighting}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Budget allocated for green initiatives:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_Budget(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_Budget}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Status of implementation of CBCS / Elective Course System (ECS):
              </h6>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Physical Facilities:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setPG_PhyFac(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{PG_PhyFac}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Provision for Lift:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setPG_Prolift(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{PG_Prolift}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Ramp/ Rails:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setPG_Ramp(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{PG_Ramp}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Braille Software/Facilities:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setPG_Braille(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{PG_Braille}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Rest Rooms:{" "}
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setPG_RestRoom(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{PG_RestRoom}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Scribes for Examination
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setPG_Scribes(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{PG_Scribes}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Special skill Development for Differently Abled Students:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "500px" }}
                      onChange={(e) => {
                        setPG_SpecialSkills(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{PG_SpecialSkills}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Any other Similar Facility:
                  </label>

                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setPG_AnyOther(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{PG_AnyOther}</>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Annual expenditure excluding salary component of the
                  institution
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Annual(e.target.value);
                    }}
                  />
                ) : (
                  <>{PG_Annual}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives to address locational Advantages and
                  Disadvantages
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NoOfIniti1(e.target.value);
                    }}
                  />
                ) : (
                  <>{PG_NoOfIniti1}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives taken to Engage with and contribute to
                  Local Community
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NoOfIniti2(e.target.value);
                    }}
                  />
                ) : (
                  <>{PG_NoOfIniti2}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Date and Duration of the Initiative Name of the Initiative
                  Issues Addressed
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_DateDuration(e.target.value);
                    }}
                  />
                ) : (
                  <>{PG_DateDuration}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Power Requirement met by Renewable Energy Sources
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Power(e.target.value);
                    }}
                  />
                ) : (
                  <>{PG_Power}</>
                )}
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Power requirements
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_TPower(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_TPower}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Source:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_ReEnergyS(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_ReEnergyS}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Generated and Used :
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_ReEnergyG(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_ReEnergyG}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Energy Supplied to the Grid:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_EnergySup(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_EnergySup}</>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Percentage Lighting through other sources
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_PercentageLight(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_PercentageLight}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participating Students and Staff:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_NoOfParticipants(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_NoOfParticipants}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Percentage Lighting through LED bulbs:
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_PercentageLED(e.target.value);
                        }}
                      />
                    ) : (
                      <>{PG_PercentageLED}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      No. of Activities:
                    </label>

                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_NoOfActivities(e.target.value);
                        }}
                      />
                    ) : (
                      <>{PG_NoOfActivities}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TTitle of the Program/Activity:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_TitlePro(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_TitlePro}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participants:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_NoOfParticipants1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_NoOfParticipants1}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Date and Duration:
              </h6>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    From:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_Date1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_Date1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    To:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_Date2(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_Date2}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Number of participants by Gender:
              </h6>
              <div className="row">
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    MALE:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_Male(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_Male}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    FEMALE:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_Female(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_Female}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_Yea(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_Yea}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_Total(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_Total}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement Renewable
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_PowRe(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_PowRe}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Initiative names:
                  </label>

                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPG_InitiName(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PG_InitiName}</>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Duration_of_the_Initiative
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_DurInitiative(e.target.value);
                    }}
                  />
                ) : (
                  <>{PG_DurInitiative}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Description (min 50 words)
                </label>

                {edit1 ? (
                  <textarea
                    className="vi_0"
                    rows={3}
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Desc29(e.target.value);
                    }}
                  />
                ) : (
                  <>{PG_Desc29}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Document Upload Select file to upload:
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setPG_Link29(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{PG_Link29?.name}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload2">
                  {" "}
                  Document Upload Select file to upload:
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload2"
                    accept="image/*"
                    onChange={(e) => {
                      setPG_Link30(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{PG_Link30?.name}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Document Upload Select file to upload:
                </label>

                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload3"
                    accept="image/*"
                    onChange={(e) => {
                      setPG_Link31(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{PG_Link31?.name}</>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit1(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                style={{ color: "white" }}
                onClick={() => {
                  cat1();
                  handleClose();
                }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="text-center mt-4  mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => pgcriteria7()}
            style={{ color: "white" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PG_Criteria007;
