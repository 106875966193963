import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Nav } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { DatePicker } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
const UgCriteria2 = () => {
  let principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [View, setView] = useState([]);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);
  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [Value, setValue] = useState({});
  const [UG_Criteria02, setUG_Criteria02] = useState([]);
  const ugCriteria02Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria02"
      );
      if (res.status === 200) {
        setUG_Criteria02(res.data.ugcriteria02);
      }
    } catch (error) {
      console.log(error);
      setUG_Criteria02(error.response.data.ugcriteria02);
    }
  };

  const [SearchItem, setSearchItem] = useState("");
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [remark, setremark] = useState("");
  const approveCriteria02 = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaUG2/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria02Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      ugCriteria02Details();
    }
  }, []);

  console.log("UG_Criteria02", UG_Criteria02);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-02
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div>
            <Nav defaultActiveKey="">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/CriteriaUG02_211")}
                >
                  <Button className="criteriabtn btn-sub1"> 2.1.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/CriteriaUG02_212")}
                >
                  <Button className="criteriabtn btn-sub"> 2.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/CriteriaUG02_241")}
                >
                  <Button className="criteriabtn btn-sub">2.4.1 & 2.4.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-4"
                  onClick={() => navigate("/CriteriaUG02_242")}
                >
                  <Button className="criteriabtn btn-sub"> 2.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/CriteriaUG02_263")}
                >
                  <Button style={{}} className="criteriabtn btn-sub">
                    {" "}
                    2.6.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/CriteriaUG02_271")}
                >
                  <Button style={{}} className="criteriabtn btn-sub">
                    2.7.1
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", minWidth: "10000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>ID</th>
                  <th>Year</th>

                  <th>Program Code</th>
                  <th>Program Name</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of Students admitted</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>Criteria</th>
                  <th>SC</th>
                  <th>ST</th>
                  <th>OBC</th>
                  <th>Divyangjan</th>
                  <th>Gen</th>
                  <th>Others</th>
                  <th>SC</th>
                  <th>ST</th>
                  <th>OBC</th>
                  <th>Divyangjan</th>
                  <th>Gen</th>
                  <th>Others</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>Name of the Department</th>
                  <th>
                    Nature of appointment (Against Sanctioned post,
                    temporary,permanent)
                  </th>

                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution.
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if other than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>
                    Unique Enrollment ID / College ID/ University enrollment
                    number
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {UG_Criteria02?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    principalDetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "Pending" &&
                    (SearchItem === "" ||
                      item1?.Criteria02_211?.NoSeatSanctioned?.toLowerCase()?.includes(
                        SearchItem?.toLocaleLowerCase()
                      ) ||
                      item1?.Criteria02_211?.NoStdAdmit?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_211?.Desc6?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_211?.PrgCode?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_211?.PrgName1?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_211?.criteria
                        ?.toLocaleLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.Criteria02_211?.Link6?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.DDivyangjan?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.Desc7?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.Divyangjan?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.GGen?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.Gen?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.Link7?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.OBC?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.OOBC?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.OOthers?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.Others?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.SC?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.SSC?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.SST?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.ST?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_212?.criteria
                        ?.toLocaleLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.Criteria02_241?.DeptName?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.Desc8?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.Designation?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.NoOfAppoint?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.PAN?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.TName?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.TService?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.YOfAppoint?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_241?.criteria
                        ?.toLocaleLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.Criteria02_242?.Desc9?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.Link9?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.Qualify?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.SerTeacher?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.Whether?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.YOfQuali?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.YOfRecog?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_242?.criteria
                        ?.toLocaleLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.Criteria02_263?.Desc10?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_263?.Link10?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_263?.NStdAppeared?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_263?.NStdPassed?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_263?.PrgName2?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_263?.criteria
                        ?.toLocaleLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.Criteria02_263?.pCode
                        ?.toLocaleLowerCase()
                        ?.includes(SearchItem?.toLowerCase()) ||
                      item1?.Criteria02_271?.Category?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Desc11?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Email?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Gender?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.Link11?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.MNo?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.NIndian?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.NStd?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.PrgName3?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.SDomicile?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.YJoining?.toLocaleLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria02_271?.criteria
                        ?.toLocaleLowerCase()
                        ?.includes(SearchItem?.toLowerCase())) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                }).map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?._id}</td>
                      <td>{item?.Year}</td>
                      <td>{item?.Criteria02_211?.criteria}</td>
                      <td>{item?.Criteria02_211?.PrgCode}</td>
                      <td>{item?.Criteria02_211?.PrgName1}</td>
                      <td>{item?.Criteria02_211?.NoSeatSanctioned}</td>
                      <td>{item?.Criteria02_211?.NoStdAdmit}</td>
                      <td>
                        {item?.Criteria02_211?.Desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_212?.SC}</td>
                      <td>{item?.Criteria02_212?.ST}</td>
                      <td>{item?.Criteria02_212?.OBC}</td>
                      <td>{item?.Criteria02_212?.Divyangjan}</td>
                      <td>{item?.Criteria02_212?.Gen}</td>
                      <td>{item?.Criteria02_212?.Others}</td>
                      <td>{item?.Criteria02_212?.SSC}</td>
                      <td>{item?.Criteria02_212?.SST}</td>
                      <td>{item?.Criteria02_212?.OOBC}</td>
                      <td>{item?.Criteria02_212?.DDivyangjan}</td>
                      <td>{item?.Criteria02_212?.GGen}</td>
                      <td>{item?.Criteria02_212?.OOthers}</td>
                      <td>
                        {item?.Criteria02_212?.Desc7?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_241?.criteria}</td>
                      <td>{item?.Criteria02_241?.TName}</td>
                      <td>{item?.Criteria02_241?.PAN}</td>
                      <td>{item?.Criteria02_241?.Designation}</td>
                      <td>{item?.Criteria02_241?.YOfAppoint}</td>
                      <td>{item?.Criteria02_241?.NoOfAppoint}</td>
                      <td>{item?.Criteria02_241?.DeptName}</td>
                      <td>{item?.Criteria02_241?.TYofExp}</td>
                      <td>{item?.Criteria02_241?.TService}</td>

                      <td>
                        {item?.Criteria02_241?.Desc8?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_242?.criteria}</td>
                      <td>{item?.Criteria02_242?.SerTeacher}</td>
                      <td>{item?.Criteria02_242?.Qualify}</td>
                      <td>{item?.Criteria02_242?.YOfQuali}</td>
                      <td>{item?.Criteria02_242?.YOfRecog}</td>
                      <td>{item?.Criteria02_242?.Whether}</td>

                      <td>
                        {item?.Criteria02_242?.Desc9?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_263?.criteria}</td>
                      <td>{item?.Criteria02_263?.PrgName2}</td>
                      <td>{item?.Criteria02_263?.pCode}</td>
                      <td>{item?.Criteria02_263?.NStdAppeared}</td>
                      <td>{item?.Criteria02_263?.NStdPassed}</td>
                      <td>
                        {item?.Criteria02_263?.Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Criteria02_271?.criteria}</td>
                      <td>{item?.Criteria02_271?.PrgName3}</td>
                      <td>{item?.Criteria02_271?.NStd}</td>
                      <td>{item?.Criteria02_271?.Gender}</td>
                      <td>{item?.Criteria02_271?.Category}</td>
                      <td>{item?.Criteria02_271?.SDomicile}</td>
                      <td>{item?.Criteria02_271?.NIndian}</td>
                      <td>{item?.Criteria02_271?.Email}</td>
                      <td>{item?.Criteria02_271?.MNo}</td>
                      <td>{item?.Criteria02_271?.YJoining}</td>
                      <td>{item?.Criteria02_271?.UID}</td>
                      <td>
                        {item?.Criteria02_271?.Desc11?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        {/* Show description modal */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.Criteria02_211?.Desc6}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria02_212?.Desc7}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria02_241?.Desc8}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria02_242?.Desc9}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria02_263?.Desc10}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria02_271?.Desc11}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose12}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevent Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_211?.Link6}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevent Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_212?.Link7}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevent Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_241?.Link8}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevent Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_242?.Link9}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevent Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_263?.Link10}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>{" "}
        {/* show 6 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevent Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria02/${View?.Criteria02_271?.Link11}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton style={{ background: "#393186" }}>
            <Modal.Title>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold", color: "white" }}
              >
                UnderGraduate_Criteria-02
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.1
                </span>
              </div>
              <div className="row m-0">
                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name :
                  </label>
                  <br />
                  <>{Value?.Criteria02_211?.PrgName1}</>
                </div>
                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>
                  <br />
                  <>{Value?.Criteria02_211?.PrgCode}</>
                </div>
                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of seats sanctioned
                  </label>
                  <br />
                  <>{Value?.Criteria02_211?.NoSeatSanctioned}</>
                </div>
                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students admitted
                  </label>
                  <br />
                  <>{Value?.Criteria02_211?.NoStdAdmit}</>
                </div>

                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Document
                  </label>
                  <br />
                  <>{Value?.Criteria02_211?.Link6}</>
                </div>
                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>
                  <br />
                  <p>{Value?.Criteria02_211?.Desc6}</p>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-lg-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>
                  <br />

                  <>{Value?.Criteria02_212?.SC}</>
                </div>
                <div className="col-lg-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST
                  </label>
                  <br />

                  <>
                    <>{Value?.Criteria02_212?.ST}</>
                  </>
                </div>

                <div className="col-lg-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    OBC
                  </label>
                  <br />

                  <>{Value?.Criteria02_212?.OBC}</>
                </div>
                <div className="col-lg-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Gen
                  </label>
                  <br />

                  <>{Value?.Criteria02_212?.Gen}</>
                </div>

                <div className="col-lg-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Divyangjan
                  </label>
                  <br />
                  <>{Value?.Criteria02_212?.Divyangjan}</>
                </div>

                <div className="col-lg-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Others
                  </label>
                  <br /> <>{Value?.Criteria02_212?.Others}</>
                </div>

                <div className="col-lg-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    SC
                  </label>
                  <br />
                  <>{Value?.Criteria02_212?.SSC}</>
                </div>

                <div className="col-lg-6 mt-3">
                  <label className="content1"> SST</label>
                  <br />
                  <> {Value?.Criteria02_212?.SST}</>
                </div>

                <div className="col-lg-6 mt-3">
                  <label className="content1"> ST</label>
                  <br />
                  <> {Value?.Criteria02_212?.SST}</>
                </div>

                <div className="col-lg-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    OBC
                  </label>
                  <br /> <>{Value?.Criteria02_212?.OOBC}</>
                </div>

                <div className="col-lg-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Gen
                  </label>
                  <br />
                  <>{Value?.Criteria02_212?.GGen}</>
                </div>

                <div className="col-lg-6 mt-3">
                  <label className="content1"> DDivyangjan</label>
                  <br />
                  <> {Value?.Criteria02_212?.DDivyangjan}</>
                </div>
                <div className="col-lg-6 mt-3">
                  <label className="content1"> Others</label>
                  <br />
                  <> {Value?.Criteria02_212?.OOthers}</>
                </div>

                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br /> <p>{Value?.Criteria02_212?.Desc7}</p>
                </div>

                <div className="col-lg-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>
                  <br />
                  {Value?.Criteria02_212?.Link7}
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria - 2.4.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1">
                    {" "}
                    Name of the Full-time teacher
                  </label>
                  <br />
                  <> {Value?.Criteria02_241?.TName}</>
                </div>

                <div className="col-md-6 mt-3">
                  <label className="content1"> PAN</label>
                  <br />
                  <> {Value?.Criteria02_241?.PAN}</>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Designation
                  </label>
                  <br /> <>{Value?.Criteria02_241?.Designation}</>
                </div>

                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of appointment
                  </label>
                  <br />
                  <>{Value?.Criteria02_241?.YOfAppoint}</>
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1">
                    {" "}
                    Nature of appointment (Against Sanctioned post,
                    temporary,permanent)
                  </label>
                  <br />
                  <> {Value?.Criteria02_241?.NoOfAppoint}</>
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1"> Name of the Department</label>
                  <br />
                  <> {Value?.Criteria02_241?.DeptName}</>
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total years of Experience in the same institution
                  </label>
                  <br /> <>{Value?.Criteria02_241?.TYofExp}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution.
                  </label>
                  <br />
                  <>{Value?.Criteria02_241?.TService}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>
                  <br />

                  <p>{Value?.Criteria02_241?.Desc8}</p>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Document
                  </label>
                  <br />

                  <>{Value?.Criteria02_241?.Link8}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria - 2.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </label>
                  <br /> <>{Value?.Criteria02_242?.SerTeacher}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </label>
                  <br /> <>{Value?.Criteria02_242?.Qualify}</>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of obtaining the qualification
                  </label>
                  <br /> <>{Value?.Criteria02_242?.YOfQuali}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Recognition as a Research Guide
                  </label>
                  <br /> <>{Value?.Criteria02_242?.YOfRecog}</>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </label>
                  <br /> <>{Value?.Criteria02_242?.Whether}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>
                  <br /> <p>{Value?.Criteria02_242?.Desc9}</p>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload2">
                    {" "}
                    Document
                  </label>
                  <br /> <>{Value?.Criteria02_242?.Link9}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria - 2.6.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>
                  <br />
                  {Value?.Criteria02_263?.PrgName2}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>
                  <br />
                  {Value?.Criteria02_263?.pCode}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students appeared in the final year examination
                  </label>
                  <br />
                  {Value?.Criteria02_263?.NStdAppeared}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    {" "}
                    Number of students passed in final year examination
                  </label>
                  <br />
                  {Value?.Criteria02_263?.NStdPassed}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1"> Description</label>
                  <br />
                  <p>{Value?.Criteria02_263?.Desc10}</p>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1"> Document</label>
                  <br />

                  {Value?.Criteria02_263?.Link10}
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>
                  <br />
                  {Value?.Criteria02_271?.PrgName3}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the student
                  </label>
                  <br />
                  {Value?.Criteria02_271?.NStd}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Gender
                  </label>
                  <br />
                  {Value?.Criteria02_271?.Gender}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1"> Category</label>
                  <br />
                  {Value?.Criteria02_271?.Category}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1"> State of Domicile</label>
                  <br />
                  {Value?.Criteria02_271?.SDomicile}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">
                    {" "}
                    Nationality if othern than Indian
                  </label>
                  <br />
                  {Value?.Criteria02_271?.NIndian}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1"> Email-ID</label>
                  <br />
                  {Value?.Criteria02_271?.Email}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1"> Mobile Number</label>
                  <br />
                  {Value?.Criteria02_271?.MNo}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1"> Year of joining</label>
                  <br />
                  {Value?.Criteria02_271?.YJoining}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">
                    {" "}
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </label>
                  <br />
                  {Value?.Criteria02_271?.UID}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1"> Description</label>
                  <br />
                  <p>{Value?.Criteria02_271?.Desc11}</p>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1"> Document</label>
                  <br />

                  {Value?.Criteria02_271?.Link11}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  className="btn-success"
                  onClick={(e) => approveCriteria02(e, true, Value?._id)}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {
          // remarks model
        }
        <Modal
          // size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              style={{ height: "200px" }}
              onChange={(e) => setremark(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => approveCriteria02(e, false, Value?._id)}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UgCriteria2;
