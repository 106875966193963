import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const UG_Criteria007 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //post method for criteria_07
  const [MainYear7, setMainYear7] = useState("");

  const [Budget, setBudget] = useState("");
  const [Lighting, setLighting] = useState("");
  const [PhyFac, setPhyFac] = useState("");
  const [Prolift, setProlift] = useState("");
  const [Ramp, setRamp] = useState("");
  const [Braille, setBraille] = useState("");
  const [RestRoom, setRestRoom] = useState("");
  const [Scribes, setScribes] = useState("");
  const [SpecialSkills, setSpecialSkills] = useState("");
  const [AnyOther, setAnyOther] = useState("");
  const [Annual, setAnnual] = useState("");
  const [NoOfIniti1, setNoOfIniti1] = useState("");
  const [NoOfIniti2, setNoOfIniti2] = useState("");
  const [Date1, setDate1] = useState("");
  const [DateDuration, setDateDuration] = useState("");
  const [Date2, setDate2] = useState("");
  const [Power, setPower] = useState("");
  const [TPower, setTPower] = useState("");
  const [ReEnergyS, setReEnergyS] = useState("");
  const [ReEnergyG, setReEnergyG] = useState("");
  const [EnergySup, setEnergySup] = useState("");
  const [PercentageLED, setPercentageLED] = useState("");
  const [PercentageLight, setPercentageLight] = useState("");
  const [NoOfParticipants, setNoOfParticipants] = useState("");
  const [NoOfActivities, setNoOfActivities] = useState("");
  const [TitlePro, setTitlePro] = useState("");
  const [NoOfParticipants1, setNoOfParticipants1] = useState("");
  const [Male, setMale] = useState("");
  const [Female, setFemale] = useState("");
  const [Yea, setYea] = useState("");
  const [Total, setTotal] = useState("");
  const [PowRe, setPowRe] = useState("");
  const [InitiName, setInitiName] = useState("");
  const [DurInitiative, setDurInitiative] = useState("");
  const [Desc29, setDesc29] = useState("");
  const [Link29, setLink29] = useState("");
  const [Link30, setLink30] = useState("");
  const [Link31, setLink31] = useState("");

  const [obj1, setobj1] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getugcriteria7 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getugcriteria7();
  }, []);

  const ugcriteria7 = async () => {
    if (!MainYear7) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/adddetails07",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Year: MainYear7,

          Criteria07_007: obj1,
          Link29: Link29,
          Link30: Link30,
          Link31: Link31,

          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          navigate("/ugcriteria07");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Desc29;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Budget ||
          !Lighting ||
          !PhyFac ||
          !Prolift ||
          !Ramp ||
          !Braille ||
          !RestRoom ||
          !Scribes ||
          !SpecialSkills ||
          !AnyOther ||
          !Annual ||
          !NoOfIniti1 ||
          !NoOfIniti2 ||
          !Date1 ||
          !DateDuration ||
          !Date2 ||
          !Power ||
          !TPower ||
          !ReEnergyS ||
          !ReEnergyG ||
          !EnergySup ||
          !PercentageLED ||
          !PercentageLight ||
          !NoOfParticipants ||
          !NoOfActivities ||
          !TitlePro ||
          !NoOfParticipants1 ||
          !Male ||
          !Female ||
          !Yea ||
          !Total ||
          !PowRe ||
          !InitiName ||
          !DurInitiative ||
          !Desc29 ||
          !Link29 ||
          !Link30 ||
          !Link31
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
        {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Link29);
      console.log("mm,", Link30);
      console.log("ooo,", Link31);
      let obj = {
        criteria: "0.0.7",
        Budget: Budget,
        Lighting: Lighting,
        PhyFac: PhyFac,
        Prolift: Prolift,
        Ramp: Ramp,
        Braille: Braille,
        RestRoom: RestRoom,
        Scribes: Scribes,
        SpecialSkills: SpecialSkills,
        AnyOther: AnyOther,
        Annual: Annual,
        NoOfIniti1: NoOfIniti1,
        NoOfIniti2: NoOfIniti2,
        Date1: Date1,
        DateDuration: DateDuration,
        Date2: Date2,
        Power: Power,
        TPower: TPower,
        ReEnergyS: ReEnergyS,
        ReEnergyG: ReEnergyG,
        EnergySup: EnergySup,
        PercentageLED: PercentageLED,
        PercentageLight: PercentageLight,
        NoOfParticipants: NoOfParticipants,
        NoOfActivities: NoOfActivities,
        TitlePro: TitlePro,
        NoOfParticipants1: NoOfParticipants1,
        Male: Male,
        Female: Female,
        Yea: Yea,
        Total: Total,
        PowRe: PowRe,
        InitiName: InitiName,
        DurInitiative: DurInitiative,
        Desc29: Desc29,
        Link29: Link29,
        Link30: Link30,
        Link31: Link31,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getugcriteria7();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div className="container">
      <div className="pt-4   " style={{ padding: "10px" }}>
        <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
          {" "}
          UnderGraduate_Criteria-07
        </span>
      </div>
      <div className="container">
        <div
          className="row-fluid border bs"
          style={{
            padding: "30px",
          }}
        >
          <div class=" col-md-6">
            <label for="" class="form-label">
              Select Year
            </label>
            {obj1?.MainYear7 ? (
              <p>{obj1?.MainYear7}</p>
            ) : (
              <select
                className="form-select form-select-sm vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setMainYear7(e.target.value)}
              >
                <option selected>--select Year--</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_07</h5>

            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total Lighting requirements
                </label>
                {obj1?.Lighting ? (
                  <p>{obj1?.Lighting}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setLighting(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Budget allocated for green initiatives
                </label>
                {obj1?.Budget ? (
                  <p>{obj1?.Budget}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setBudget(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline mt-3">
              Status of implementation of CBCS / Elective Course System (ECS)
            </h6>
            <div className="row">
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Physical Facilities
                </label>
                {obj1?.PhyFac ? (
                  <p>{obj1?.PhyFac}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setPhyFac(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Provision for Lift
                </label>
                {obj1?.Prolift ? (
                  <p>{obj1?.Prolift}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setProlift(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Ramp/ Rails
                </label>
                {obj1?.Ramp ? (
                  <p>{obj1?.Ramp}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setRamp(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Braille Software/Facilities
                </label>
                {obj1?.Braille ? (
                  <p>{obj1?.Braille}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setBraille(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Rest Rooms
                </label>
                {obj1?.RestRoom ? (
                  <p>{obj1?.RestRoom}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setRestRoom(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Scribes for Examination
                </label>
                {obj1?.Scribes ? (
                  <p>{obj1?.Scribes}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setScribes(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Special skill Development for Differently Abled Students
                </label>
                {obj1?.SpecialSkills ? (
                  <p>{obj1?.SpecialSkills}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "593px" }}
                    onChange={(e) => {
                      setSpecialSkills(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Any other Similar Facility
                </label>
                {obj1?.AnyOther ? (
                  <p>{obj1?.AnyOther}</p>
                ) : (
                  <select
                    className="form-select form-select-sm vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setAnyOther(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Annual expenditure excluding salary component of the institution
              </label>
              {obj1?.Annual ? (
                <p>{obj1?.Annual}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAnnual(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Number of initiatives to address locational Advantages and
                Disadvantages
              </label>
              {obj1?.NoOfIniti1 ? (
                <p>{obj1?.NoOfIniti1}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setNoOfIniti1(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Number of initiatives taken to Engage with and contribute to
                Local Community
              </label>
              {obj1?.NoOfIniti2 ? (
                <p>{obj1?.NoOfIniti2}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setNoOfIniti2(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Date and Duration of the Initiative Name of the Initiative
                Issues Addressed
              </label>
              {obj1?.DateDuration ? (
                <p>{obj1?.DateDuration}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setDateDuration(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Power Requirement met by Renewable Energy Sources
              </label>
              {obj1?.Power ? (
                <p>{obj1?.Power}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setPower(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="row">
              {" "}
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total Power requirements
                </label>
                {obj1?.TPower ? (
                  <p>{obj1?.TPower}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setTPower(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Renewable Energy Source
                </label>
                {obj1?.ReEnergyS ? (
                  <p>{obj1?.ReEnergyS}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setReEnergyS(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Renewable Energy Generated and Used
                </label>
                {obj1?.ReEnergyG ? (
                  <p>{obj1?.ReEnergyG}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setReEnergyG(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Energy Supplied to the Grid
                </label>
                {obj1?.EnergySup ? (
                  <p>{obj1?.EnergySup}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setEnergySup(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Percentage Lighting through LED bulbs
                </label>
                {obj1?.PercentageLED ? (
                  <p>{obj1?.PercentageLED}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPercentageLED(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Percentage Lighting through other sources
                </label>
                {obj1?.PercentageLight ? (
                  <p>{obj1?.PercentageLight}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPercentageLight(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of Participating Students and Staff
                </label>
                {obj1?.NoOfParticipants ? (
                  <p>{obj1?.NoOfParticipants}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setNoOfParticipants(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  No. of Activities
                </label>
                {obj1?.NoOfActivities ? (
                  <p>{obj1?.NoOfActivities}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setNoOfActivities(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Title of the Program/Activity
                </label>
                {obj1?.TitlePro ? (
                  <p>{obj1?.TitlePro}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setTitlePro(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of Participants
                </label>
                {obj1?.NoOfParticipants1 ? (
                  <p>{obj1?.NoOfParticipants1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setNoOfParticipants1(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline mt-3">
              Date and Duration
            </h6>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  From
                </label>
                {obj1?.Date1 ? (
                  <p>{obj1?.Date1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setDate1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  To
                </label>
                {obj1?.Date2 ? (
                  <p>{obj1?.Date2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setDate2(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline mt-3">
              Number of participants by Gender
            </h6>
            <div className="row">
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  MALE
                </label>
                {obj1?.Male ? (
                  <p>{obj1?.Male}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setMale(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  FEMALE
                </label>
                {obj1?.Female ? (
                  <p>{obj1?.Female}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setFemale(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year
                </label>
                {obj1?.Yea ? (
                  <p>{obj1?.Yea}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setYea(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total
                </label>
                {obj1?.Total ? (
                  <p>{obj1?.Total}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setTotal(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Power Requirement Renewable
                </label>
                {obj1?.PowRe ? (
                  <p>{obj1?.PowRe}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPowRe(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Initiative names
                </label>
                {obj1?.InitiName ? (
                  <p>{obj1?.InitiName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setInitiName(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Duration_of_the_Initiative
                </label>
                {obj1?.DurInitiative ? (
                  <p>{obj1?.DurInitiative}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setDurInitiative(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.Desc29 ? (
                  <p>{obj1?.Desc29}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    rows={3}
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setDesc29(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Document Upload Select file to upload
              </label>
              <br />
              <input
                className="name"
                type="file"
                name="Year"
                id="upload1"
                accept="image/*"
                onChange={(e) => {
                  setLink29(e.target.files[0]);
                }}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload2">
                {" "}
                Document Upload Select file to upload
              </label>
              <br />
              <input
                className="name"
                type="file"
                name="Year"
                id="upload2"
                accept="image/*"
                onChange={(e) => {
                  setLink30(e.target.files[0]);
                }}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload3">
                {" "}
                Document Upload Select file to upload
              </label>
              <br />
              <input
                className="name"
                type="file"
                name="Year"
                id="upload3"
                accept="image/*"
                onChange={(e) => {
                  setLink31(e.target.files[0]);
                }}
              />
            </div>
            <div className="d-flex  " style={{ float: "right", gap: "10px" }}>
              <button
                className="btn btn-warning "
                onClick={handleShow}
                style={{ color: "black", marginTop: "15px" }}
              >
                Preview
              </button>
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <></>
              )}{" "}
            </div>
            <br />
          </div>

          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria_07</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Lighting requirements
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setLighting(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Lighting}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Budget allocated for green initiatives:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setBudget(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Budget}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Status of implementation of CBCS / Elective Course System (ECS):
              </h6>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Physical Facilities:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      className="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setPhyFac(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{PhyFac}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Provision for Lift:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      className="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setProlift(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Prolift}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Ramp/ Rails:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      className="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setRamp(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Ramp}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Braille Software/Facilities:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      className="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setBraille(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Braille}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Rest Rooms:{" "}
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      className="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setRestRoom(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{RestRoom}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Scribes for Examination
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      className="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setScribes(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Scribes}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Special skill Development for Differently Abled Students:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      className="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "500px" }}
                      onChange={(e) => {
                        setSpecialSkills(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{SpecialSkills}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Any other Similar Facility:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      className="form-select form-select-sm vi_0"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setAnyOther(e.target.value);
                      }}
                    >
                      <option selected>--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{AnyOther}</>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Annual expenditure excluding salary component of the
                  institution
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAnnual(e.target.value);
                    }}
                  />
                ) : (
                  <>{Annual}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives to address locational Advantages and
                  Disadvantages
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setNoOfIniti1(e.target.value);
                    }}
                  />
                ) : (
                  <>{NoOfIniti1}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives taken to Engage with and contribute to
                  Local Community
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setNoOfIniti2(e.target.value);
                    }}
                  />
                ) : (
                  <>{NoOfIniti2}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Date and Duration of the Initiative Name of the Initiative
                  Issues Addressed
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setDateDuration(e.target.value);
                    }}
                  />
                ) : (
                  <>{DateDuration}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Power Requirement met by Renewable Energy Sources
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPower(e.target.value);
                    }}
                  />
                ) : (
                  <>{Power}</>
                )}
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Power requirements
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setTPower(e.target.value);
                      }}
                    />
                  ) : (
                    <>{TPower}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Source
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setReEnergyS(e.target.value);
                      }}
                    />
                  ) : (
                    <>{ReEnergyS}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Generated and Used
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setReEnergyG(e.target.value);
                      }}
                    />
                  ) : (
                    <>{ReEnergyG}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Energy Supplied to the Grid
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setEnergySup(e.target.value);
                      }}
                    />
                  ) : (
                    <>{EnergySup}</>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Percentage Lighting through other sources
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPercentageLight(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PercentageLight}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participating Students and Staff
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setNoOfParticipants(e.target.value);
                      }}
                    />
                  ) : (
                    <>{NoOfParticipants}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Percentage Lighting through LED bulbs
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPercentageLED(e.target.value);
                        }}
                      />
                    ) : (
                      <>{PercentageLED}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      No. of Activities
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setNoOfActivities(e.target.value);
                        }}
                      />
                    ) : (
                      <>{NoOfActivities}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Title of the Program/Activity
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setTitlePro(e.target.value);
                      }}
                    />
                  ) : (
                    <>{TitlePro}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participants:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setNoOfParticipants1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{NoOfParticipants1}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Date and Duration
              </h6>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    From
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setDate1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Date1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    To
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setDate2(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Date2}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Number of participants by Gender
              </h6>
              <div className="row">
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    MALE:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setMale(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Male}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    FEMALE
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setFemale(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Female}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setYea(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Yea}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setTotal(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Total}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement Renewable
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setPowRe(e.target.value);
                      }}
                    />
                  ) : (
                    <>{PowRe}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Initiative names
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setInitiName(e.target.value);
                      }}
                    />
                  ) : (
                    <>{InitiName}</>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Duration_of_the_Initiative
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setDurInitiative(e.target.value);
                    }}
                  />
                ) : (
                  <>{DurInitiative}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Description (min 500 words)
                </label>
                <br />
                {edit1 ? (
                  <textarea
                    className="vi_0"
                    rows={3}
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setDesc29(e.target.value);
                    }}
                  />
                ) : (
                  <>{Desc29}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Document Upload Select file to upload
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setLink29(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{Link29?.name}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload2">
                  {" "}
                  Document Upload Select file to upload
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload2"
                    accept="image/*"
                    onChange={(e) => {
                      setLink30(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{Link30?.name}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Document Upload Select file to upload
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload3"
                    accept="image/*"
                    onChange={(e) => {
                      setLink31(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{Link31?.name}</>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit1(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                style={{ color: "white" }}
                onClick={() => {
                  cat1();
                  handleClose();
                }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
        <div className="text-center  mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => ugcriteria7()}
            style={{ color: "white" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UG_Criteria007;
