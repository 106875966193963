import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";

function AdminClgUNI01_112() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("University-1.1.2 & 1.2.2");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
          item1?.approve === "true" &&
          selectedYear === item1?.Uni_Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableA = `
            <table>
              <tr>
                <td>CBCS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_StCBCS}</td>
              </tr>
              <tr>
                <td>ECS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_StEcs}</td>
              </tr>
              
            </table>
            `;

        let tableB = `
            <table>
              <tr>
                <td>CBCS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_YICBCS}</td>
              </tr>
              <tr>
                <td>ECS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_YIEcs}</td>
              </tr>
              
            </table>
            `;
        let tableC = `
            <table>
              <tr>
                <td>CBCS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_YrCBCS}</td>
              </tr>
              <tr>
                <td>ECS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_YrEcs}</td>
              </tr>
              
            </table>
            `;
        let tableD = `
            <table>
              <tr>
                <td>CBCS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_IfCBCS}</td>
              </tr>
              <tr>
                <td>ECS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_IfEcs}</td>
              </tr>
              
            </table>
            `;
        let tableE = `
            <table>
              <tr>
                <td>CBCS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_Link1}</td>
              </tr>
              <tr>
                <td>ECS</td>
                <td>${item?.Uni_Criteria1_112_122?.Uni_Link2}</td>
              </tr>
              
            </table>
            `;
        let obj = {
          Year: item?.Uni_Year,
          "Programme Code": item?.Uni_Criteria1_112_122?.Uni_ProCode,
          "Programme name": item?.Uni_Criteria1_112_122?.Uni_ProName,
          "Year of Introduction": item?.Uni_Criteria1_112_122?.Uni_YoI,
          "Status of implementation of CBCS / Elective Course System (ECS)":
            tableA,
          "Year of implemetation of CBCS / Elective Course System (ECS)":
            tableB,
          "Year of revision": tableC,
          "If revision has been carried out in the syllabus during last 5 years, Percentage of content added or replaced":
            tableD,
          "Link to the relevant documents": tableE,
          Description: item?.Uni_Criteria1_112_122?.Uni_Desc1,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUni_Criteria1_112_122"
      );
      if (res.status === 200) {
        setAllDetails(res.data.getUni_C112_122);
        setdata1(res.data.getUni_C112_122);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.getUni_C112_122);
    }
  };
  console.log("allDetails112", AllDetails);

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      // approveCriteria();
      getContent();
      getAllYears();
    }
  }, []);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <h4 className="mt-4"> University Criteria 1 1.1.2 & 1.2.2</h4>
              <div className="ad-b mt-4 row align-items-center">
                <div className=" col-lg-4 do-sear mt-4 d-flex ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="vi_0"
                    onChange={(e) => setSearchItem(e.target.value)}
                  />
                </div>

                <div className=" col-lg-2 lab">
                  <label>From</label>
                  <br />
                  <DatePicker
                    onChange={onChangeFrom}
                    picker="year"
                    className="vi_0"
                  />
                </div>
                <div className=" col-lg-2 lab">
                  <label>To</label>
                  <br />
                  <DatePicker
                    onChange={onChangeTo}
                    picker="year"
                    className="vi_0"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ad-b  row align-items-center">
            <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
              <label style={{ width: "130px" }}>Select Year</label>
              <select
                className="criteriaform"
                style={{ width: "150px" }}
                onChange={(e) => setselectedYear(e.target.value)}
              >
                <option>Select Year</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-lg-5">
              <Nav defaultActiveKey="/unicriteria01">
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-1"
                    onClick={() =>
                      navigate(`/adminclgunicri1112/${params?.id}`)
                    }
                  >
                    <Button
                      variant="warning"
                      style={{ backgroundColor: "#ffc107" }}
                      className="criteriabtn-0"
                    >
                      1.1.2 & 1.2.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    onClick={() =>
                      navigate(`/adminclgunicri1113/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">1.1.3 & 1.2.1</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri1132/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">1.3.2 & 1.3.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri1134/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">1.3.4</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() =>
                      navigate(`/adminclgunicri1141/${params?.id}`)
                    }
                  >
                    <Button className="criteriabtn">1.4.1 & 1.4.2</Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <div className=" col-lg-2 text-end ">
              <Button onClick={ExportToExcel}>Export Excel</Button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>Programme Code</th>
                  <th>Programme name</th>
                  <th>Year of Introduction</th>
                  <th>
                    Status of implementation of CBCS / Elective Course System
                    (ECS)
                  </th>
                  <th>
                    Year of implemetation of CBCS / Elective Course System (ECS)
                  </th>
                  <th>Year of revision</th>
                  <th>
                    If revision has been carried out in the syllabus during last
                    5 years, Percentage of content added or replaced
                  </th>
                  <th>Link to the relevant documents</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Uni_Year &&
                    (SearchItem === "" ||
                      item1?.Uni_Year?.includes(SearchItem) ||
                      item1?.Uni_Criteria1_112_122?.Uni_ProCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_ProName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YoI?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_StCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_StEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YICBCS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YIEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YrCBCS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YrEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_IfCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_IfEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_Desc1?.toString()?.includes(
                        SearchItem
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>

                      <td>{item?.Uni_Criteria1_112_122?.Uni_ProCode}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_ProName}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_YoI}</td>
                      <td>
                        <Table>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_StCBCS}</td>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_StEcs}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_YICBCS}</td>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_YIEcs}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_YrCBCS}</td>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_YrEcs}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_IfCBCS}</td>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_IfEcs}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow1();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                              <td>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow2();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        {item?.Uni_Criteria1_112_122?.Uni_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria1_112_122?.Uni_Link1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria1_112_122?.Uni_Link2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Body>
              <div>{View?.Uni_Criteria1_112_122?.Uni_Desc1}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AdminClgUNI01_112;
