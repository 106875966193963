import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete, AiOutlineDelete, AiOutlineSave } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsImages } from "react-icons/bs";

function AdminPublication() {
  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
  const [imageURL, setimageURL] = useState(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL(URL.createObjectURL(event.target.files[0]));
      console.log("ghghg", URL.createObjectURL(event.target.files[0]));
    }
  };
  const formdata = new FormData();
  // ================ add campus details modal===============================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================ delete campus details modal============================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================ edit campus details modal==============================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================ show specific image modal===============================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [SpecificImg, setSpecificImg] = useState({});
  //   const [SpecificImgId, setSpecificImgId] = useState("");
  // =================== calling api for adding the campus content============
  const [img, setimg] = useState("");
  const [speciImg, setspeciImg] = useState("");
  const [heading, setheading] = useState("");
  const [linkName, setlinkName] = useState("");
  const [link, setlink] = useState("");
  const [linksAvail, setlinksAvail] = useState([]);

  const [View, setView] = useState({});

  function addLinks() {
    try {
      if (!linkName || !link) {
        return alert("please add link name and their respective link.");
      }
      setlinksAvail((curr) => [
        ...curr,
        {
          linkName: linkName,
          link: link,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  const AddPublicationDetails = async (e) => {
    e.preventDefault();
    if (!img || !heading || !linkName || !link) {
      return alert("Please fill all fields");
    }
    let obj = {
      img: img,
      heading: heading,
      linksAvail: linksAvail,
    };

    try {
      const config = {
        url: "/admin/addAdminpub",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          getPulicationList();
          setimg("");
          setlinksAvail([]);
          setheading("");
          setlinkName("");
          setlink("");
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the publication content======================
  const [publicationList, setpublicationList] = useState([]);
  const getPulicationList = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getAdminpub"
      );
      if (res.status === 200) {
        setpublicationList(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setpublicationList(error.response.data.allContent);
    }
  };

  // =================== calling api for getting the specific publication content======================
  const getPulicationListById = async (id) => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getAdminpubById/" + id
      );
      if (res.status === 200) {
        setView(res.data.Content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================== calling api for deleting the publication content======================
  const deleteContent = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/deleteAdminpub/" + View?._id,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPulicationList();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      handleClose1();
    }
  };

  // =================== calling api for editing the publication content======================
  const editContent = async (e) => {
    e.preventDefault();
    if (!img && !heading) {
      return alert("Please Provide atleast one detail to edit!!!");
    }
    // formdata.set('img', img);
    // formdata.set('heading', heading);
    // formdata.set('linksAvail', linksAvail);
    let obj = {
      img: img,
      heading: heading,
      linksAvail: linksAvail,
    };
    console.log("tttg", obj);
    try {
      const config = {
        url: "/admin/editAdminpub/" + View?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPulicationList();
          setimg("");
          setlinksAvail([]);
          setheading("");
          setlinkName("");
          setlink("");
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  // ======================calling api for editing=================
  const EditLinkDetails = async (e, state, id) => {
    e.preventDefault();
    try {
      const config = {
        url: "/admin/editLinks/" + View?._id,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          linkid: id,
          linkName: linkName,
          link: link,
          state: state,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getPulicationListById(View?._id);
          setlinkName("");
          setlink("");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getPulicationList();
  }, []);
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Publications
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Logo</th>
                <th>Heading</th>
                <th>Link Details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {publicationList?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>
                      <div
                        onClick={() => {
                          setSpecificImg(item);
                          handleShow4();
                        }}
                      >
                        <img
                          src={`https://brightnaac.co.in/AdminPublication/${item?.img}`}
                          style={{ width: "40%" }}
                          onClick={() => handleShow4}
                          alt="no-image"
                        />
                      </div>
                    </td>
                    <td>{item?.heading}</td>
                    <td>
                      <Table responsive bordered striped>
                        <thead>
                          <th>Link Name</th>
                          <th>Link</th>
                        </thead>
                        <tbody>
                          {item?.linksAvail?.map((val) => {
                            return (
                              <tr>
                                <td>{val?.linkName}</td>
                                <td>{val?.link}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </td>

                    <td>
                      <div className="d-flex align-items-center justify-content-evenly">
                        <div className="">
                          <span
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              margin: "10px",
                            }}
                          >
                            <BiEdit
                              size={20}
                              onClick={() => {
                                setView(item);
                                //   getPulicationListById(item?._id);
                                handleShow2();
                              }}
                            />
                          </span>
                        </div>
                        <div className="">
                          <span
                            onClick={() => {
                              setView(item);
                              // getPulicationListById(item?._id);
                              handleShow1();
                            }}
                            style={{
                              cursor: "pointer",
                              color: "red",
                              margin: "10px",
                            }}
                          >
                            <AiFillDelete size={20} />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Publication details */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Publication details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label htmlFor="upload1">
                Logo<span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="upload1"
                  name="ImageStyle"
                  className="vi_0"
                  accept="image/*"
                  onChange={(e) => setimg(e.target.files[0])}
                />
              </div>
            </div>
            <div className="mb-1">
              <label>
                Heading <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setheading(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex" style={{ alignItems: "end", gap: "5px" }}>
              <div>
                <label>
                  Link Name <span className="font-css top">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    id="file-input"
                    name="ImageStyle"
                    className="vi_0"
                    onChange={(e) => setlinkName(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label>
                  Link<span className="font-css top">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    id="file-input"
                    name="ImageStyle"
                    className="vi_0"
                    onChange={(e) => setlink(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <Button onClick={addLinks}>ADD</Button>
              </div>
            </div>
            <div>
              {linksAvail?.map((val) => {
                return (
                  <tr>
                    <td>
                      <span style={{ marginRight: "10px" }}>
                        {val.linkName}
                      </span>
                    </td>
                    <td>
                      <span style={{ marginRight: "10px" }}>{val.link}</span>
                    </td>
                  </tr>
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddPublicationDetails(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show4} onHide={handleClose4}>
          <Modal.Body>
            <div>
              <img
                src={`https://brightnaac.co.in/AdminPublication/${SpecificImg?.img}`}
                style={{ width: "100%" }}
                alt="no-image"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show2} onHide={handleClose2} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Publication Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>Logo</label>
              <div className="">
                <input
                  type="file"
                  id="Eupload1"
                  name="ImageStyle"
                  className="vi_0"
                  hidden
                  accept="image/*"
                  onChange={(e) => {
                    onImageChange(e);
                    setimg(e.target.files[0]);
                  }}
                />
                <label for="Eupload1">
                  {img ? (
                    <img
                      src={imageURL}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  ) : (
                    <img
                      src={`https://brightnaac.co.in/AdminPublication/${View?.img}`}
                      alt="no-image"
                      style={{ width: "30%" }}
                    />
                  )}
                </label>
              </div>
            </div>
            <div className="mb-1">
              <label>Heading</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.heading}
                  onChange={(e) => setheading(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-1">
              <label>Links Available</label>
              <Table>
                <tbody>
                  {View?.linksAvail?.map((val1) => {
                    return (
                      <tr>
                        <td>
                          <input
                            type="text"
                            id="file-input"
                            name="ImageStyle"
                            className="vi_0"
                            placeholder={val1?.linkName}
                            onChange={(e) => setlinkName(e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            id="file-input"
                            name="ImageStyle"
                            className="vi_0"
                            placeholder={val1?.link}
                            onChange={(e) => setlink(e.target.value)}
                          />
                        </td>
                        <td>
                          <AiOutlineSave
                            size={20}
                            color="blue"
                            margin={1}
                            onClick={(e) => {
                              EditLinkDetails(e, "EDIT", val1?._id);
                            }}
                          />{" "}
                          <AiOutlineDelete
                            size={20}
                            color="red"
                            margin={1}
                            onClick={(e) => {
                              EditLinkDetails(e, "DELETE", val1?._id);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div>
              <label>Add New Links</label>
              <div className="d-flex" style={{ alignItems: "end", gap: "5px" }}>
                <div>
                  <label>Link Name</label>
                  <div className="">
                    <input
                      type="text"
                      id="file-input"
                      name="ImageStyle"
                      className="vi_0"
                      onChange={(e) => setlinkName(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label>Link</label>
                  <div className="">
                    <input
                      type="text"
                      id="file-input"
                      name="ImageStyle"
                      className="vi_0"
                      onChange={(e) => setlink(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <Button
                    onClick={(e) => {
                      EditLinkDetails(e, "ADD");
                    }}
                  >
                    ADD
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => editContent(e)}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteContent(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AdminPublication;
