import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useState } from "react";
import axios from "axios";
import { BsFileEarmarkPdf } from "react-icons/bs";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function UGC1() {
  return (
    <>
      <option value={"1.1.3"}>1.1.3</option>
      <option value={"1.2.1"}>1.2.1</option>
      <option value={"1.2.2 & 1.2.3"}>1.2.2 & 1.2.3</option>
      <option value={"1.3.2"}>1.3.2</option>
      <option value={"1.3.3"}>1.3.3</option>
    </>
  );
}

function UGC2() {
  return (
    <>
      <option value={"2.1.1"}>2.1.1</option>
      <option value={"2.1.2"}>2.1.2</option>
      <option value={"2.4.1 & 2.4.3"}>2.4.1 & 2.4.3</option>
      <option value={"2.4.2"}>2.4.2</option>
      <option value={"2.6.3"}>2.6.3</option>
      <option value={"2.7.1"}>2.7.1</option>
    </>
  );
}
function UGC3() {
  return (
    <>
      <option value={"3.1.2"}>3.1.2</option>
      <option value={"3.1.3"}>3.1.3</option>
      <option value={"3.2.1"}>3.2.1</option>
      <option value={"3.2.2"}>3.2.2</option>
      <option value={"3.3.2"}>3.3.2</option>
      <option value={"3.3.3"}>3.3.3</option>
      <option value={"3.4.1"}>3.4.1</option>
      <option value={"3.4.2"}>3.4.2</option>
    </>
  );
}
function UGC4() {
  return (
    <>
      <option value={"4.1.3"}>4.1.3</option>
      <option value={"4.1.4 & 4.4.1"}>4.1.4 & 4.4.1</option>
      <option value={"4.2.2 & 4.2.3"}>4.2.2 & 4.2.3</option>
    </>
  );
}
function UGC5() {
  return (
    <>
      <option value={"5.1.1 & 5.1.2"}>5.1.1 & 5.1.2</option>
      <option value={"5.1.3"}>5.1.3</option>
      <option value={"5.1.4"}>5.1.4</option>
      <option value={"5.2.1"}>5.2.1</option>
      <option value={"5.2.2"}>5.2.2</option>
      <option value={"5.2.3"}>5.2.3</option>
      <option value={"5.3.1"}>5.3.1</option>
      <option value={"5.3.3"}>5.3.3</option>
    </>
  );
}
function UGC6() {
  return (
    <>
      <option value={"6.2.3"}>6.2.3</option>
      <option value={"6.3.2"}>6.3.2</option>
      <option value={"6.3.3"}>6.3.3</option>
      <option value={"6.3.4"}>6.3.4</option>
      <option value={"6.4.2"}>6.4.2</option>
      <option value={"6.5.3"}>6.5.3</option>
    </>
  );
}

function PGC1() {
  return (
    <>
      <option value={"1.1.3"}>1.1.3</option>
      <option value={"1.2.1"}>1.2.1</option>
      <option value={"1.2.2"}>1.2.2</option>
      <option value={"1.3.2"}>1.3.2</option>
      <option value={"1.3.3"}>1.3.3</option>
    </>
  );
}
function PGC2() {
  return (
    <>
      <option value={"2.1.1"}>2.1.1</option>
      <option value={"2.1.2"}>2.1.2</option>
      <option value={"2.4.1 & 2.4.3"}>2.4.1 & 2.4.3</option>
      <option value={"2.4.2"}>2.4.2</option>
      <option value={"2.6.3"}>2.6.3</option>
      <option value={"2.7.1"}>2.7.1</option>
    </>
  );
}
function PGC3() {
  return (
    <>
      <option value={"3.1.1 & 3.1.3"}>3.1.1 & 3.1.3</option>
      <option value={"3.2.2"}>3.2.2</option>
      <option value={"3.3.2"}>3.3.2</option>
      <option value={"3.3.3"}>3.3.3</option>
      <option value={"3.4.2"}>3.4.2</option>
      <option value={"3.4.3"}>3.4.3</option>
      <option value={"3.5.1"}>3.5.1</option>
      <option value={"3.5.2"}>3.5.2</option>
    </>
  );
}
function PGC4() {
  return (
    <>
      <option value={"4.1.3"}>4.1.3</option>
      <option value={"4.1.4 & 4.4.1"}>4.1.4 & 4.4.1</option>
      <option value={"4.2.2 & 4.2.3"}>4.2.2 & 4.2.3</option>
    </>
  );
}
function PGC5() {
  return (
    <>
      <option value={"5.1.1 & 5.1.2"}>5.1.1 & 5.1.2</option>
      <option value={"5.1.3"}>5.1.3</option>
      <option value={"5.1.4"}>5.1.4</option>
      <option value={"5.2.1"}>5.2.1</option>
      <option value={"5.2.2"}>5.2.2</option>
      <option value={"5.2.3"}>5.2.3</option>
      <option value={"5.3.1"}>5.3.1</option>
      <option value={"5.3.3"}>5.3.3</option>
    </>
  );
}
function PGC6() {
  return (
    <>
      <option value={"6.2.3"}>6.2.3</option>
      <option value={"6.3.2"}>6.3.2</option>
      <option value={"6.3.3"}>6.3.3</option>
      <option value={"6.3.4"}>6.3.4</option>
      <option value={"6.4.2"}>6.4.2</option>
      <option value={"6.5.3"}>6.5.3</option>
    </>
  );
}

function AUTOC1() {
  return (
    <>
      <option value={"1.1.2"}>1.1.2</option>
      <option value={"1.1.3 & 1.2.1"}>1.1.3 & 1.2.1</option>
      <option value={"1.3.2 & 1.3.3"}>1.3.2 & 1.3.3</option>
      <option value={"1.3.4"}>1.3.4</option>
    </>
  );
}
function AUTOC2() {
  return (
    <>
      <option value={"2.1.1"}>2.1.1</option>
      <option value={"2.1.2"}>2.1.2</option>
      <option value={"2.4.1 & 2.4.3"}>2.4.1 & 2.4.3</option>
      <option value={"2.4.2"}>2.4.2</option>
      <option value={"2.5.1"}>2.5.1</option>
      <option value={"2.6.3"}>2.6.3</option>
      <option value={"2.7.1"}>2.7.1</option>
    </>
  );
}
function AUTOC3() {
  return (
    <>
      <option value={"3.1.2"}>3.1.2</option>
      <option value={"3.1.3"}>3.1.3</option>
      <option value={"3.2.1 & 3.2.2 & 3.2.3"}>3.2.1 & 3.2.2 & 3.2.3</option>
      <option value={"3.3.2"}>3.3.2</option>
      <option value={"3.4.3"}>3.4.3</option>
      <option value={"3.4.4"}>3.4.4</option>
      <option value={"3.5.1 & 3.5.2"}>3.5.1 & 3.5.2</option>
      <option value={"3.6.2"}>3.6.2</option>
      <option value={"3.6.3 & 3.6.4"}>3.6.3 & 3.6.4</option>
      <option value={"3.7.1"}>3.7.1</option>
      <option value={"3.7.2"}>3.7.2</option>
    </>
  );
}
function AUTOC4() {
  return (
    <>
      <option value={"4.1.3"}>4.1.3</option>
      <option value={"4.1.4 & 4.4.1"}>4.1.4 & 4.4.1</option>
      <option value={"4.2.2 & 4.2.3"}>4.2.2 & 4.2.3</option>
    </>
  );
}
function AUTOC5() {
  return (
    <>
      <option value={"5.1.1"}>5.1.1</option>
      <option value={"5.1.3"}>5.1.3</option>
      <option value={"5.1.4"}>5.1.4</option>
      <option value={"5.2.1"}>5.2.1</option>
      <option value={"5.2.2"}>5.2.2</option>
      <option value={"5.2.3"}>5.2.3</option>
      <option value={"5.3.1"}>5.3.1</option>
      <option value={"5.3.3"}>5.3.3</option>
    </>
  );
}
function AUTOC6() {
  return (
    <>
      <option value={"6.2.3"}>6.2.3</option>
      <option value={"6.3.2"}>6.3.2</option>
      <option value={"6.3.3"}>6.3.3</option>
      <option value={"6.3.4"}>6.3.4</option>
      <option value={"6.4.2"}>6.4.2</option>
      <option value={"6.5.3"}>6.5.3</option>
    </>
  );
}
function UNIC1() {
  return (
    <>
      <option value={"1.1.2 & 1.2.2"}>1.1.2 & 1.2.2</option>
      <option value={"1.1.3 & 1.2.1"}>1.1.3 & 1.2.1</option>
      <option value={"1.3.2 & 1.3.3"}>1.3.2 & 1.3.3</option>
      <option value={"1.3.4"}>1.3.4</option>
      <option value={"1.4.1 & 1.4.2"}>1.4.1 & 1.4.2</option>
    </>
  );
}
function UNIC2() {
  return (
    <>
      <option value={"2.1.1"}>2.1.1</option>
      <option value={"2.1.2"}>2.1.2</option>
      <option value={"2.4.1 & 2.4.3"}>2.4.1 & 2.4.3</option>
      <option value={"2.4.2"}>2.4.2</option>
      <option value={"2.4.4"}>2.4.4</option>
      <option value={"2.5.1"}>2.5.1</option>
      <option value={"2.6.3"}>2.6.3</option>
      <option value={"2.7.1"}>2.7.1</option>
    </>
  );
}
function UNIC3() {
  return (
    <>
      <option value={"3.1.2"}>3.1.2</option>
      <option value={"3.1.3"}>3.1.3</option>
      <option value={"3.1.4"}>3.1.4</option>
      <option value={"3.1.6 & 3.2.1 & 3.2.2 & 3.2.4"}>
        3.1.6 & 3.2.1 & 3.2.2 & 3.2.4
      </option>
      <option value={"3.3.2"}>3.3.2</option>
      <option value={"3.3.3"}>3.3.3</option>
      <option value={"3.4.3"}>3.4.3</option>
      <option value={"3.4.4"}>3.4.4</option>
      <option value={"3.4.5"}>3.4.5</option>
      <option value={"3.4.6"}>3.4.6</option>
      <option value={"3.4.7"}>3.4.7</option>
      <option value={"3.5.2 & 3.5.2.1"}>3.5.2 & 3.5.2.1</option>
      <option value={"3.6.2"}>3.6.2</option>
      <option value={"3.6.3 & 3.6.4"}>3.6.3 & 3.6.4</option>
      <option value={"3.7.1"}>3.7.1</option>
      <option value={"3.7.2"}>3.7.2</option>
    </>
  );
}
function UNIC4() {
  return (
    <>
      <option value={"4.1.4"}>4.1.4</option>
      <option value={"4.2.2"}>4.2.2</option>
      <option value={"4.3.1"}>4.3.1</option>
    </>
  );
}
function UNIC5() {
  return (
    <>
      <option value={"5.1.1"}>5.1.1</option>
      <option value={"5.1.2"}>5.1.2</option>
      <option value={"5.1.3"}>5.1.3</option>
      <option value={"5.2.1"}>5.2.1</option>
      <option value={"5.2.2"}>5.2.2</option>
      <option value={"5.2.3"}>5.2.3</option>
      <option value={"5.3.1"}>5.3.1</option>
      <option value={"5.3.3"}>5.3.3</option>
    </>
  );
}
function UNIC6() {
  return (
    <>
      <option value={"6.2.3"}>6.2.3</option>
      <option value={"6.3.2"}>6.3.2</option>
      <option value={"6.3.3"}>6.3.3</option>
      <option value={"6.3.4"}>6.3.4</option>
      <option value={"6.4.2"}>6.4.2</option>
      <option value={"6.5.3"}>6.5.3</option>
    </>
  );
}
const FinalReports = () => {
  let principal = JSON.parse(sessionStorage.getItem("Puser"));

  // ====================== Year selector =======================
  const navigate = useNavigate();
  const startYear = 1900;
  const endYear = new Date().getFullYear();
  const [startYearReport, setstartYearReport] = useState(endYear);
  const [endYearReport, setendYearReport] = useState();

  const [startYearReportIQAC, setstartYearReportIQAC] = useState(endYear);
  const [endYearReportIQAC, setendYearReportIQAC] = useState();
  const yearList = [];
  for (let i = endYear; i >= startYear; i--) {
    yearList.push(i);
  }

  function ChangeYearsIQAC(value) {
    if (Number(value)) {
      setstartYearReportIQAC(Number(value));
      setendYearReportIQAC(Number(value) + 5);
    } else {
      setstartYearReportIQAC("");
      setendYearReportIQAC("");
    }
  }

  function ChangeYearsAQAR(value) {
    if (Number(value)) {
      setstartYearReport(Number(value));
      setendYearReport(Number(value) + 1);
    } else {
      setstartYearReport("");
      setendYearReport("");
    }
  }

  // ================add final report modal======================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================Show final report modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Generate AQAR Report modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Generate AQAR Report modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Generate IQAC Report modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ==================calling api to add final report===================
  const [course, setcourse] = useState("");
  const [ReportType, setReportType] = useState("");
  const [year, setyear] = useState("");
  const [criteria, setcriteria] = useState("");
  const [subcriteria, setsubcriteria] = useState("");
  const [docName, setdocName] = useState("");
  const [finalReportDoc, setfinalReportDoc] = useState("");
  const [View, setView] = useState({});
  // const [addedByCollege, setaddedByCollege] = useState("");

  const addFinalReport = async (e) => {
    e.preventDefault();
    const obj = {
      ReportType: ReportType,
      year: SelectYear,
      docName: docName,
      finalReportDoc: finalReportDoc,
      addedByCollege: principal?._id,
    };
    try {
      const config = {
        url: "/pri/addFinalReport",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };

      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          setSelectYear([]);
          getAllFinalReports();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  // ================ calling api to get all final reports list ===============================

  const [finalReportList, setfinalReportList] = useState([]);
  const [SearchData, setSearchData] = useState("");
  const [searchedfinalReportList, setsearchedfinalReportList] = useState([]);
  const getAllFinalReports = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/pri/getAllReports"
      );
      if (res.status === 200) {
        setfinalReportList(res.data.getFinalReports);
        setsearchedfinalReportList(res.data.getFinalReports);
      }
    } catch (error) {
      console.log(error);
      setfinalReportList(error.response.data.getFinalReports);
    }
  };
  console.log("searchedfinalReportList", searchedfinalReportList);
  // ================ calling api to get all added years ===============================
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  console.log("allYears", allYears);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [SelectYear, setSelectYear] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectYear(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  console.log("SelectYear", SelectYear);

  useEffect(() => {
    principal = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principal) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getAllYears();
      getAllFinalReports();
    }
  }, []);
  return (
    <div className="add-gr">
      <div className="container">
        <h5 className="mt-4">Final Report List</h5>
        <div
          className="ad-b mt-4"
          style={{
            display: "flex",
            gap: "21px",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <div className="do-sear mt-2 d-flex ">
            <input
              type="text"
              placeholder="Search"
              className="vi_0"
              value={SearchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>

          <div className="">
            <button
              style={{ backgroundColor: "#393186", marginRight: "10px" }}
              onClick={() => navigate("/IiqaMain")}
            >
              Generate IIQA Report
            </button>
            <button
              style={{ backgroundColor: "#393186", marginRight: "10px" }}
              // onClick={handleShow4}
              onClick={() => navigate("/ssr-List")}
            >
              Generate SSR Report
            </button>

            <button
              style={{ backgroundColor: "#393186", marginRight: "10px" }}
              onClick={() => navigate("/iqac-list")}
            >
              Generate IQAC Report
            </button>
            <button
              style={{ backgroundColor: "#393186", marginRight: "10px" }}
              onClick={() => navigate("/aqar-list")} //handleShow2 //handleShow4
            >
              Generate AQAR Report
            </button>
            <button style={{ backgroundColor: "#393186" }} onClick={handleShow}>
              Upload Report
              <i
                class="fa-solid fa-plus fa-sm"
                style={{ color: "#ffffff" }}
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table
            responsive
            striped
            bordered
            hover
            style={{ textAlign: "center" }}
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Report Type </th>
                <th>Year</th>
                <th>Document Name</th>
                <th>Doc</th>
              </tr>
            </thead>
            <tbody>
              {searchedfinalReportList
                ?.filter(
                  (val) =>
                    val?.addedByCollege?._id?.toString() ==
                    principal?._id.toString()
                )
                ?.filter(
                  (val) =>
                    SearchData == "" ||
                    val?.ReportType?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase()) ||
                    val?.year
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase()) ||
                    val?.docName
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(SearchData?.toString()?.toLowerCase())
                )
                ?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{item?.ReportType}</td>
                      <td>
                        <div style={{ fontSize: "15px" }}>
                          {item?.year?.map((ele, index) => {
                            return (
                              <>
                                <span key={index}>
                                  {ele}
                                  {index + 1 === item.year.length ? (
                                    <></>
                                  ) : (
                                    <>, </>
                                  )}{" "}
                                </span>
                              </>
                            );
                          })}
                        </div>
                      </td>

                      <td>{item?.docName}</td>
                      <td>
                        <BsFileEarmarkPdf
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      {/* Add Model */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#" }}>Add Final Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="row mt-2 mb-3">
            <div className="col-5">
              <label for="exampleFormControlInput1" className="form-label">
                Course :
              </label>
            </div>
            <div className="col-7" id="Rtype">
              {principal?.HEI === "UG&PG" ? (
                <select
                  className="vi_0"
                  onChange={(e) => setcourse(e.target.value)}
                >
                  <option selected>Choose ...</option>
                  <option value={"UG"}>UG</option>
                  <option value={"PG"}>PG</option>
                </select>
              ) : (
                <>
                  {principal?.HEI === "UG" ? (
                    <select
                      className="vi_0"
                      onChange={(e) => setcourse(e.target.value)}
                    >
                      <option selected>Choose ...</option>
                      <option value={"UG"}>UG</option>
                    </select>
                  ) : (
                    <>
                      {principal?.HEI === "PG" ? (
                        <select
                          className="vi_0"
                          onChange={(e) => setcourse(e.target.value)}
                        >
                          <option selected>Choose ...</option>
                          <option value={"PG"}>PG</option>
                        </select>
                      ) : (
                        <>
                          {principal?.HEI === "Autonomous" ? (
                            <select
                              className="vi_0"
                              onChange={(e) => setcourse(e.target.value)}
                            >
                              <option selected>Choose ...</option>
                              <option value={"Autonomous"}>Autonomous</option>
                            </select>
                          ) : (
                            <>
                              {principal?.HEI === "University" ? (
                                <select
                                  className="vi_0"
                                  onChange={(e) => setcourse(e.target.value)}
                                >
                                  <option selected>Choose ...</option>
                                  <option value={"University"}>
                                    University
                                  </option>
                                </select>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div> */}

          <div className="row mt-2 mb-3">
            <div className="col-5">
              <label for="exampleFormControlInput1" className="form-label">
                Report Type :
              </label>
            </div>
            <div className="col" id="Rtype">
              <select
                className="vi_0"
                onChange={(e) => setReportType(e.target.value)}
              >
                <option selected>Choose ...</option>
                <option selected value={"SSR"}>
                  SSR
                </option>
                <option selected value={"AQAR"}>
                  AQAR
                </option>
                <option selected value={"IQAC"}>
                  IQAC
                </option>
                <option selected value={"IIQA"}>
                  IIQA
                </option>
              </select>
            </div>
          </div>

          <div className="row mt-2 mb-3">
            <div className="col-5">
              <label for="exampleFormControlInput1" className="form-label">
                Year :
              </label>
            </div>
            <div className="col-7" id="Rtype">
              {ReportType === "" ? (
                <select className="vi_0">
                  <option selected>Choose...</option>
                </select>
              ) : (
                ""
              )}

              {ReportType === "AQAR" || ReportType === "IIQA" ? (
                <select
                  className="vi_0"
                  onChange={(e) => setSelectYear(e.target.value)}
                >
                  <option selected>Choose ...</option>
                  {allYears
                    // ?.sort((a, b) => {
                    //   return Number(a?.from) - Number(b?.from);
                    // })
                    ?.map((item) => {
                      console.log("item", item);
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              ) : (
                ""
              )}

              {ReportType === "IQAC" || ReportType === "SSR" ? (
                <FormControl sx={{ m: 1, width: 253 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Choose..
                  </InputLabel>
                  <Select
                    className="vi_0"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={SelectYear}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {allYears.map((item) => (
                      <MenuItem key={item} value={`${item?.from}-${item?.to}`}>
                        <Checkbox
                          checked={
                            SelectYear.indexOf(`${item?.from}-${item?.to}`) > -1
                          }
                        />
                        <ListItemText primary={`${item?.from}-${item?.to}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* 
          <div className="row mt-2 mb-3">
            <div className="col-5">
              <label for="exampleFormControlInput1" className="form-label">
                Criteria :
              </label>
            </div>
            <div className="col" id="Rtype">
              <select
                className="vi_0"
                onChange={(e) => setcriteria(e.target.value)}
              >
                <option selected>Choose ...</option>
                <option value={"Criteria1"}>Criteria1</option>
                <option value={"Criteria2"}>Criteria2</option>
                <option value={"Criteria3"}>Criteria3</option>
                <option value={"Criteria4"}>Criteria4</option>
                <option value={"Criteria5"}>Criteria5</option>
                <option value={"Criteria6"}>Criteria6</option>
                <option value={"Criteria7"}>Criteria7</option>
              </select>
            </div>
          </div>

          <div className="row mt-2 mb-3">
            <div className="col-5">
              <label for="exampleFormControlInput1" className="form-label">
                Sub Criteria :
              </label>
            </div>
            <div className="col-7" id="Rtype">
              <select
                className="vi_0"
                onChange={(e) => setsubcriteria(e.target.value)}
              >
                <option selected>Choose ...</option>
                {criteria == "Criteria1" && course === "UG" ? (
                  <UGC1 />
                ) : criteria == "Criteria2" && course === "UG" ? (
                  <UGC2 />
                ) : criteria == "Criteria3" && course === "UG" ? (
                  <UGC3 />
                ) : criteria == "Criteria4" && course === "UG" ? (
                  <UGC4 />
                ) : criteria == "Criteria5" && course === "UG" ? (
                  <UGC5 />
                ) : criteria == "Criteria6" && course === "UG" ? (
                  <UGC6 />
                ) : criteria == "Criteria7" && course === "UG" ? (
                  <></>
                ) : criteria == "Criteria1" && course === "PG" ? (
                  <PGC1 />
                ) : criteria == "Criteria2" && course === "PG" ? (
                  <PGC2 />
                ) : criteria == "Criteria3" && course === "PG" ? (
                  <PGC3 />
                ) : criteria == "Criteria4" && course === "PG" ? (
                  <PGC4 />
                ) : criteria == "Criteria5" && course === "PG" ? (
                  <PGC5 />
                ) : criteria == "Criteria6" && course === "PG" ? (
                  <PGC6 />
                ) : criteria == "Criteria7" && course === "PG" ? (
                  <></>
                ) : criteria == "Criteria1" && course === "Autonomous" ? (
                  <AUTOC1 />
                ) : criteria == "Criteria2" && course === "Autonomous" ? (
                  <AUTOC2 />
                ) : criteria == "Criteria3" && course === "Autonomous" ? (
                  <AUTOC3 />
                ) : criteria == "Criteria4" && course === "Autonomous" ? (
                  <AUTOC4 />
                ) : criteria == "Criteria5" && course === "Autonomous" ? (
                  <AUTOC5 />
                ) : criteria == "Criteria6" && course === "Autonomous" ? (
                  <AUTOC6 />
                ) : criteria == "Criteria7" && course === "Autonomous" ? (
                  <></>
                ) : criteria == "Criteria1" && course === "University" ? (
                  <UNIC1 />
                ) : criteria == "Criteria2" && course === "University" ? (
                  <UNIC2 />
                ) : criteria == "Criteria3" && course === "University" ? (
                  <UNIC3 />
                ) : criteria == "Criteria4" && course === "University" ? (
                  <UNIC4 />
                ) : criteria == "Criteria5" && course === "University" ? (
                  <UNIC5 />
                ) : criteria == "Criteria6" && course === "University" ? (
                  <UNIC6 />
                ) : criteria == "Criteria7" && course === "University" ? (
                  <></>
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div> */}

          <div className="row mt-2 mb-3">
            <div className="col-5">
              <label for="exampleFormControlInput1" className="form-label">
                Document Name
              </label>
            </div>
            <div className="col-7" id="Rtype">
              <input
                type="text"
                className="vi_0"
                onChange={(e) => setdocName(e.target.value)}
              ></input>
            </div>
          </div>

          <div className="row mt-2 mb-3">
            <div className="col-5">
              <label for="exampleFormControlInput1" className="form-label">
                Upload
              </label>
            </div>
            <div className="col-7" id="Rtype">
              <input
                type="file"
                className="vi_0"
                // accept="image/*"
                onChange={(e) => setfinalReportDoc(e.target.files[0])}
              ></input>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => addFinalReport(e)}>
            Add
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* show model */}
      <Modal show={show1} onHide={handleClose1} className="iiqareportmodal">
        <Modal.Header closeButton>
          <Modal.Title>Final Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <object
                width="100%"
                height="400"
                data={`https://brightnaac.co.in/FinalReport/${View?.finalReportDoc}`}
                type="application/pdf"
              >
                {" "}
              </object>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
      </Modal>

      {/* generate AQAR */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>AQAR Report Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-around">
            <div>
              <div>Start Date: </div>
              <select
                style={{ width: "124px" }}
                onChange={(e) => ChangeYearsAQAR(e.target.value)}
              >
                <option>Choose option</option>
                {yearList?.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div> End Date:</div>
              <input
                value={endYearReport}
                style={{ width: "124px" }}
                disabled
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() =>
              navigate("/generate-aqar-report", {
                state: { startYearReport, endYearReport },
              })
            }
          >
            View
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>AQAR Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() =>
              navigate("/generate-aqar-report", {
                state: { startYearReport, endYearReport },
              })
            }
          >
            View
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* generate IQAC */}

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>IQAC Report Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-around">
            <div>
              <div>Start Date: </div>
              <select
                style={{ width: "124px" }}
                onChange={(e) => ChangeYearsIQAC(e.target.value)}
              >
                <option>Choose option</option>
                {yearList?.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div> End Date:</div>
              <input
                value={endYearReportIQAC}
                style={{ width: "124px" }}
                disabled
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() =>
              navigate("/generate-iqac-report", {
                state: { startYearReportIQAC, endYearReportIQAC },
              })
            }
          >
            View
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>IQAC Report Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-around">
            <div>
              <div>Start Date: </div>
              <select
                style={{ width: "124px" }}
                onChange={(e) => ChangeYearsIQAC(e.target.value)}
              >
                <option>Choose option</option>
                {yearList?.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div> End Date:</div>
              <input
                value={endYearReportIQAC}
                style={{ width: "124px" }}
                disabled
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() =>
              navigate("/aaraddrepoprt", {
                state: { startYearReportIQAC, endYearReportIQAC },
              })
            }
          >
            View
          </Button>
          <Button
            variant="success"
            onClick={() =>
              navigate("/ssrviewreport", {
                state: { startYearReportIQAC, endYearReportIQAC },
              })
            }
          >
            ADD
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FinalReports;
