import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const PdfPrintUN3_313 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }
  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    console.log("ddkd1", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("ddkd2", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("ddkd3", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("ddkd4", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

    // const input = document.getElementById("pdf");
    // const options = { scrollY: -window.scrollY, useCORS: true };
    // const canvas = await html2canvas(input, options);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    // pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    pdf.save("3.1.3.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);
  if (location?.state == null) {
    navigate("/Uni_03_313");
  }
  return (
    <div>
      <div className="m-auto ">
        <div id="pdf">
          <div className="p-2">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className="img-fluid mt-5"
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                year:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.Uni_Year}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state[location?.state?.length - 1]?.criteria}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>
                    {location?.state["0"]["Uni_Criteria03_313"]["Uni_criteria"]}
                  </>
                )}
              </div>
            </div>

            {finalArr?.map((item, i) => {
              return (
                <div
                  className="container-fluid report-pdf mt-5"
                  style={{ padding: "0px 100px" }}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>
                        {" "}
                        Name of the teacher awarded national/ international
                        fellowship/financial support
                      </span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Uni_Criteria03_313?.Uni_FellowShip}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Name of the award/fellowship</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Uni_Criteria03_313?.Uni_AFellowship}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Year of Award</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Uni_Criteria03_313?.Uni_Award}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Awarding Agency</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Uni_Criteria03_313?.Uni_Agency}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Description</span>
                    </div>
                    <div className="col-lg-12 report-text  mb-3">
                      <span> {item?.Uni_Criteria03_313?.Uni_Desc6}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>
                        {/* <a href={`${item?.Uni_Criteria03_313?.Uni_Link6}`}> */}
                        {item?.Uni_Criteria03_313?.Uni_Link6}
                        {/* </a> */}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="text-center">
          <button className="ad" style={{}} onClick={createPDF}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PdfPrintUN3_313;
