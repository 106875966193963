import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../Component/Styles/About.css";
import Announcement from "./Announcement";
import axios from "axios";

function About() {
  // =================== calling api for getting the slider content======================
  const [Descp, setDescp] = useState([]);
  const getDescData = async () => {
    let res = await axios.get("https://brightnaac.co.in/api/admin/getdescp");
    if (res.status === 200) {
      setDescp(res.data.allData);
    }
  };

  useEffect(() => {
    getDescData();
  }, []);
  return (
    <>
      <section>
        <div
          className="ab-sec"
          //  data-aos="fade-up" data-aos-duration="2000"
        >
          <Container>
            <div className="content">
              <h2 className="text-center">{Descp[0]?.topic}</h2>
              <p className="educat">{Descp[0]?.body}</p>
            </div>
          </Container>
        </div>
      </section>{" "}
      <Announcement />
    </>
  );
}

export default About;
