import React from "react";

const UG4414aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Budget Allocated for Infrastructure Augmentation (INR in Lakhs)
          </span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.UG_Criteria04_414?.UG_Budget}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Expenditure for Infrastructure Augmentation (INR in Lakhs)
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.UG_Criteria04_414?.UG_Expenditure}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Total Expenditure Excluding Salary (INR in Lakh)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.UG_Criteria04_414?.UG_Total}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Expenditure on Maintenance of Academic Facilities (Excluding salary
            for Human Resources) (INR in Lakhs)
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.UG_Criteria04_414?.UG_Expend1}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Expenditure on Maintenance of Physical Facilities (Excluding Salary
            for Human Resources) (INR in Lakh)
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.UG_Criteria04_414?.UG_Expend2}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.UG_Criteria04_414?.UG_Desc2}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria4/${item?.UG_Criteria04_414?.UG_Link2}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG4414aqar;
