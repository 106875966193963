import React, { useEffect, useState } from "react";
import "../Component/Styles/Gallery.css";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import axios from "axios";

const Gallery = () => {
  let navigate = useNavigate();
  const routeChange = (val) => {
    let path = `/gallerypages`;
    let obj = JSON.parse(JSON.stringify(val));
    navigate(path, { state: obj });
  };
  // =================== calling api for getting the Gallery======================
  const [Gallery, setGallery] = useState([]);
  const getGallery = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getGallery"
      );
      if (res.status === 200) {
        setGallery(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setGallery(error.response.data.allContent);
    }
  };

  useEffect(() => {
    getGallery();
  }, []);
  return (
    <div className="gallery">
      <div
        className="ab-0"
        style={{
          backgroundImage: "url('../Image/about-us.jpg')",
          height: "250px",
          position: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="edg container">
          <div className="edgt-has-inline-style">Gallery</div>
          <div class="edgt-breadcrumbs-info">
            <div
              itemProp="breadcrumb"
              classname="edgt-breadcrumbs "
              style={{ color: "#e1e0e0" }}
            >
              <Link
                itemProp="url"
                to="/"
                style={{ color: "#e1e0e0", textDecoration: "none" }}
              >
                Home
              </Link>
              <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
              <span classname="edgt-current">Gallery</span>
            </div>
          </div>
        </div>
      </div>

      <Container className="">
        <div className="row mt-5 ">
          <h2>Gallery</h2>
          {Gallery?.map((item) => {
            return (
              <div
                className="col-lg-4 mt-5 mb-5 gallery-course "
                onClick={() => routeChange(item)}
              >
                <div className="p-2">
                  <figure>
                    <img
                      className="img-fluid"
                      src={`https://brightnaac.co.in/Gallery/${item?.galleryImg}`}
                      alt="ps1"
                    />
                  </figure>
                </div>

                <div className="gallery-text">{item?.heading}</div>
                <div className="gallery-sub-text">
                  {item?.category}/{item?.place}
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="row ">
          <div className="col-lg-6 mb-5 gallery-course " onClick={routeChange}>
            <div className="p-2">
              <figure>
                <img
                  className="img-fluid"
                  src="../gallery/ps-1.jpg"
                  alt="ps1"
                />
              </figure>
            </div>
            <div className="gallery-text">Learn Advanced Course</div>
            <div className="gallery-sub-text">Technology/University</div>
          </div>
          <div className="col-lg-6 mb-5 gallery-course" onClick={routeChange}>
            <div className="p-2">
              <figure>
                <img
                  className="img-fluid"
                  src="../gallery/ps-3.jpg"
                  alt="ps3"
                />
              </figure>
            </div>
            <div className="gallery-text">Learn Advanced Course</div>
            <div className="gallery-sub-text">Technology/University</div>
          </div>
          <div></div>
        </div>

        <div className="row mb-5 ">
          <div className="col-lg-6 mb-5 gallery-course" onClick={routeChange}>
            <div className="p-2">
              <figure>
                <img
                  className="img-fluid"
                  src="../gallery/ps-3.jpg"
                  alt="ps3"
                />
              </figure>
            </div>{" "}
            <div className="gallery-text">Learn Advanced Course</div>
            <div className="gallery-sub-text">Technology/University</div>
          </div>
          <div className="col-lg-6 mb-5 gallery-course" onClick={routeChange}>
            <div className="p-2">
              <figure>
                <img
                  className="img-fluid"
                  src="../gallery/ps-1.jpg"
                  alt="ps1"
                />
              </figure>
            </div>
            <div className="gallery-text">Learn Advanced Course</div>
            <div className="gallery-sub-text">Technology/University</div>
          </div>
          <div></div>
        </div> */}
      </Container>
    </div>
  );
};

export default Gallery;
