import axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import PG7777aqar from "./PG7777aqar";

const PGC7aqar = React.memo(({ startYeardata, endYeardata }) => {
  const navigate = useNavigate();
  // =================================================================
  const [pg7Details, setpg7Details] = useState([]);
  const getpgCriteria07 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getPG_cri07details"
      );
      if (res.status === 200) {
        setpg7Details(
          res.data.pgcriteria07?.filter(
            (item) =>
              item?.PG_Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.pgcriteria07?.filter(
          (item) =>
            item?.PG_Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setpg7Details(error.response.data.pgcriteria07);
    }
  };
  useEffect(() => {
    getpgCriteria07();
  }, [startYeardata, endYeardata]);
  return (
    <div>
      <div className="text-center">
        <b>Criteria 7</b>
      </div>
      {pg7Details?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">Criteria : 7</div>
            <PG7777aqar item={item} />
          </div>
        );
      })}
    </div>
  );
});

export default PGC7aqar;
