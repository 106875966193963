import React from "react";

const UG5511aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the scheme</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_511?.NameS}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 report-head  mb-3">
          <span>
            Number of students benefited by government scheme and amount
          </span>
          <div className="row">
            <div className="col-lg-6 report-head  mt-2">
              <div>
                <span> Number of students</span>
              </div>
              <div>
                <span>Amount</span>
              </div>
            </div>
            <div className="col-lg-6 mt-2">
              <div className="report-text">
                <span>{item?.Criteria05_511?.SName}</span>
              </div>
              <div className="report-text">
                <span> {item?.Criteria05_511?.Amount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 report-head  mb-3">
          <span>
            Number of students benefited by the institution's schemes and amount
          </span>
        </div>
        <div className="row">
          <div className="col-lg-6 report-head mt-2">
            <div>
              <span> Number of students</span>
            </div>
            <div>
              <span>Amount</span>
            </div>
          </div>
          <div className="col-lg-6 mt-2">
            <div className="report-text">
              <span>{item?.Criteria05_511?.SName1}</span>
            </div>
            <div className="report-text">
              <span> {item?.Criteria05_511?.Amount1}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria05_511?.Desc21}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria05/${item?.Criteria05_511?.Link21}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG5511aqar;
