import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineRadarChart } from "react-icons/ai";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const Uni_Criteria03 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);
  const [edit8, setedit8] = useState(false);
  const [edit9, setedit9] = useState(false);
  const [edit10, setedit10] = useState(false);
  const [edit11, setedit11] = useState(false);
  const [edit12, setedit12] = useState(false);
  const [edit13, setedit13] = useState(false);
  const [edit14, setedit14] = useState(false);
  const [edit15, setedit15] = useState(false);
  const [edit16, setedit16] = useState(false);

  const [Uni_Seed, setUni_Seed] = useState("");
  const [Uni_SeedMoney, setUni_SeedMoney] = useState("");
  const [Uni_Receiving, setUni_Receiving] = useState(""); // 3.1.2
  const [Uni_Desc5, setUni_Desc5] = useState("");
  const [Uni_Link5, setUni_Link5] = useState("");

  const [Uni_FellowShip, setUni_FellowShip] = useState("");
  const [Uni_AFellowship, setUni_AFellowship] = useState("");
  const [Uni_Award, setUni_Award] = useState(""); // 3.1.3
  const [Uni_Agency, setUni_Agency] = useState("");
  const [Uni_Desc6, setUni_Desc6] = useState("");
  const [Uni_Link6, setUni_Link6] = useState("");

  const [Uni_Sno, setUni_Sno] = useState("");
  const [Uni_Research, setUni_Research] = useState("");
  const [Uni_Enroll, setUni_Enroll] = useState(""); // 3.1.4
  const [Uni_Fellow, setUni_Fellow] = useState("");
  const [Uni_Fellow1, setUni_Fellow1] = useState("");
  const [Uni_Grant, setUni_Grant] = useState("");
  const [Uni_Qualify, setUni_Qualify] = useState("");
  const [Uni_Desc23, setUni_Desc23] = useState("");
  const [Uni_Link23, setUni_Link23] = useState("");

  const [Uni_Endowments, setUni_Endowments] = useState("");
  const [Uni_Invest, setUni_Invest] = useState("");
  const [Uni_FAgency, setUni_FAgency] = useState(""); //  3.1.6
  const [Uni_Type, setUni_Type] = useState("");
  const [Uni_Dep, setUni_Dep] = useState("");
  const [Uni_Award1, setUni_Award1] = useState("");
  const [Uni_Funds, setUni_Funds] = useState("");
  const [Uni_Project, setUni_Project] = useState("");
  const [Uni_Desc7, setUni_Desc7] = useState("");
  const [Uni_Link7, setUni_Link7] = useState("");

  const [Uni_Seminar, setUni_Seminar] = useState("");
  const [Uni_Parti, setUni_Parti] = useState("");
  const [Uni_DFrom, setUni_DFrom] = useState("");
  const [Uni_DTo, setUni_DTo] = useState(""); //  3.3.2
  const [Uni_Desc8, setUni_Desc8] = useState("");
  const [Uni_Link8, setUni_Link8] = useState("");

  const [Uni_YAw, setUni_YAw] = useState("");
  const [Uni_Innova, setUni_Innova] = useState("");
  const [Uni_Awardee, setUni_Awardee] = useState("");
  const [Uni_Details, setUni_Details] = useState("");
  const [Uni_Category, setUni_Category] = useState("");
  const [Uni_Desc17, setUni_Desc17] = useState(""); //  3.3.3
  const [Uni_Link17, setUni_Link17] = useState("");

  const [Uni_Teacher, setUni_Teacher] = useState("");
  const [Uni_Patent, setUni_Patent] = useState("");
  const [Uni_Title, setUni_Title] = useState("");
  const [Uni_YOAw, setUni_YOAw] = useState(""); //  3.4.3
  const [Uni_Desc18, setUni_Desc18] = useState("");
  const [Uni_Link18, setUni_Link18] = useState("");

  const [Uni_Phd, setUni_Phd] = useState("");
  const [Uni_Depar, setUni_Depar] = useState("");
  const [Uni_Guide, setUni_Guide] = useState(""); //  3.4.4
  const [Uni_Thesis1, setUni_Thesis1] = useState("");
  const [Uni_Scholar, setUni_Scholar] = useState("");
  const [Uni_Phd1, setUni_Phd1] = useState("");
  const [Uni_Whether1, setUni_Whether1] = useState("");
  const [Uni_Recog, setUni_Recog] = useState("");
  const [Uni_Desc19, setUni_Desc19] = useState("");
  const [Uni_Link19, setUni_Link19] = useState("");

  const [Uni_Paper, setUni_Paper] = useState("");
  const [Uni_Author, setUni_Author] = useState("");
  const [Uni_Dept, setUni_Dept] = useState("");
  const [Uni_Journal, setUni_Journal] = useState("");
  const [Uni_Pub, setUni_Pub] = useState("");
  const [Uni_ISSN, setUni_ISSN] = useState(""); //  3.4.5
  const [Uni_ISIT, setUni_ISIT] = useState("");
  const [Uni_Desc9, setUni_Desc9] = useState("");
  const [Uni_Link9, setUni_Link9] = useState("");
  const [Uni_Link10, setUni_Link10] = useState("");

  const [Uni_SLno, setUni_SLno] = useState("");
  const [Uni_Name, setUni_Name] = useState("");
  const [Uni_Book, setUni_Book] = useState("");
  const [Uni_Paper1, setUni_Paper1] = useState("");
  const [Uni_Title4, setUni_Title4] = useState("");
  const [Uni_Year2, setUni_Year2] = useState(""); //3.4.6
  const [Uni_ISBN, setUni_ISBN] = useState("");
  const [Uni_Whether, setUni_Whether] = useState("");
  const [Uni_Name1, setUni_Name1] = useState("");
  const [Uni_Desc10, setUni_Desc10] = useState("");
  const [Uni_Link11, setUni_Link11] = useState("");

  const [Uni_Teacher1, setUni_Teacher1] = useState("");
  const [Uni_Module, setUni_Module] = useState("");
  const [Uni_Platform, setUni_Platform] = useState("");
  const [Uni_Launch, setUni_Launch] = useState(""); //3.4.7
  const [Uni_List1, setUni_List1] = useState("");
  const [Uni_Desc20, setUni_Desc20] = useState("");
  const [Uni_Link20, setUni_Link20] = useState("");
  const [Uni_Link21, setUni_Link21] = useState("");

  const [Uni_Consult, setUni_Consult] = useState("");
  const [Uni_Project1, setUni_Project1] = useState("");
  const [Uni_Agency1, setUni_Agency1] = useState("");
  const [Uni_Revenue, setUni_Revenue] = useState("");
  const [Uni_Consult2, setUni_Consult2] = useState("");
  const [Uni_Title1, setUni_Title1] = useState("");
  const [Uni_Revenue1, setUni_Revenue1] = useState(""); // 3.5.1
  const [Uni_Agency3, setUni_Agency3] = useState("");
  const [Uni_Trainee, setUni_Trainee] = useState("");
  const [Uni_Desc21, setUni_Desc21] = useState("");
  const [Uni_Link22, setUni_Link22] = useState("");

  const [Uni_Name2, setUni_Name2] = useState("");
  const [Uni_Name3, setUni_Name3] = useState("");
  const [Uni_Name4, setUni_Name4] = useState(""); //  3.6.2
  const [Uni_Year4, setUni_Year4] = useState("");
  const [Uni_Desc12, setUni_Desc12] = useState("");
  const [Uni_Link13, setUni_Link13] = useState("");

  const [Uni_Name5, setUni_Name5] = useState("");
  const [Uni_org, setUni_org] = useState("");
  const [Uni_Scheme, setUni_Scheme] = useState("");
  const [Uni_Year5, setUni_Year5] = useState("");
  const [Uni_Participants1, setUni_Participants1] = useState("");
  const [Uni_Desc13, setUni_Desc13] = useState(""); // 3.6.3
  const [Uni_Link14, setUni_Link14] = useState("");

  const [Uni_SLNO1, setUni_SLNO1] = useState("");
  const [Uni_Colloborative, setUni_Colloborative] = useState("");
  const [Uni_Agency2, setUni_Agency2] = useState("");
  const [Uni_Participants3, setUni_Participants3] = useState("");
  const [Uni_Collo, setUni_Collo] = useState("");
  const [Uni_Durat, setUni_Durat] = useState(""); // 3.7.1
  const [Uni_Activity, setUni_Activity] = useState("");
  const [Uni_Desc14, setUni_Desc14] = useState("");
  const [Uni_Link15, setUni_Link15] = useState("");

  const [Uni_IIC, setUni_IIC] = useState("");
  const [Uni_Signing, setUni_Signing] = useState(""); // 3.7.2
  const [Uni_Durat1, setUni_Durat1] = useState("");
  const [Uni_List, setUni_List] = useState("");
  const [Uni_Desc15, setUni_Desc15] = useState("");
  const [Uni_Link16, setUni_Link16] = useState("");

  //integrating get method
  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  const [show18, setShow18] = useState(false);
  const handleClose18 = () => setShow18(false);
  const handleShow18 = () => setShow18(true);

  const [show19, setShow19] = useState(false);
  const handleClose19 = () => setShow19(false);
  const handleShow19 = () => setShow19(true);

  const [show20, setShow20] = useState(false);
  const handleClose20 = () => setShow20(false);
  const handleShow20 = () => setShow20(true);

  const [show21, setShow21] = useState(false);
  const handleClose21 = () => setShow21(false);
  const handleShow21 = () => setShow21(true);

  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);

  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);
  const handleShow23 = () => setShow23(true);

  const [show24, setShow24] = useState(false);
  const handleClose24 = () => setShow24(false);
  const handleShow24 = () => setShow24(true);

  const [show25, setShow25] = useState(false);
  const handleClose25 = () => setShow25(false);
  const handleShow25 = () => setShow25(true);

  const [show26, setShow26] = useState(false);
  const handleClose26 = () => setShow26(false);
  const handleShow26 = () => setShow26(true);

  const [show27, setShow27] = useState(false);
  const handleClose27 = () => setShow27(false);
  const handleShow27 = () => setShow27(true);

  const [show28, setShow28] = useState(false);
  const handleClose28 = () => setShow28(false);
  const handleShow28 = () => setShow28(true);

  const [show29, setShow29] = useState(false);
  const handleClose29 = () => setShow29(false);
  const handleShow29 = () => setShow29(true);

  const [show30, setShow30] = useState(false);
  const handleClose30 = () => setShow30(false);
  const handleShow30 = () => setShow30(true);

  const [show31, setShow31] = useState(false);
  const handleClose31 = () => setShow31(false);
  const handleShow31 = () => setShow31(true);

  const [show32, setShow32] = useState(false);
  const handleClose32 = () => setShow32(false);
  const handleShow32 = () => setShow32(true);

  const [show33, setShow33] = useState(false);
  const handleClose33 = () => setShow33(false);
  const handleShow33 = () => setShow33(true);

  const [show34, setShow34] = useState(false);
  const handleClose34 = () => setShow34(false);
  const handleShow34 = () => setShow34(true);
  const [Uni_Criteria03, setUni_Criteria03] = useState([]);
  const [Value, setValue] = useState({});

  //   ===============================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Uni_Cri3.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
        <table>
          <tr>
            <td>Is it listed in UGC Care list/Scopus/Web of Science/other, mention</td>
 <td>${item?.Uni_Criteria03_345?.Uni_ISIT}</td>
          </tr>
          <tr>
            <td>Link to website of the Journal</td>
            <td>${item?.Uni_Criteria03_345?.Uni_Link9}</td>
          </tr>
          <tr>
            <td>Link to article/paper/abstract of the article</td>
          <td>${item?.Uni_Criteria03_345?.Uni_Link10}</td>
          </tr>
        </table>
      `;

        let table1 = `
      <table>
        <thead>
          <th>Name of the teacher-consultants</th>
            <th>Name of consultancy project</th>
          <th>Consulting/Sponsoring agency with contact details</th>
          <th>Revenue generated (INR in Lakhs)</th>
        </thead>
        <tbody>
          <tr>
            <td>${item?.Uni_Criteria03_352?.Uni_Consult}</td>
            <td>${item?.Uni_Criteria03_352?.Uni_Project1}</td>
            <td>${item?.Uni_Criteria03_352?.Uni_Agency1}</td>
            <td>${item?.Uni_Criteria03_352?.Uni_Revenue}</td>
          </tr>
        </tbody>
      </table>
    `;
        let table2 = `
      <table>
        <thead>
         <th>Names of the teachers-consultants/corporate trainers</th>
        <th>Title of the corporate training program</th>
          <th>Agency seeking training with contact details</th>
           <th>Revenue generated(amount in rupees)</th>
          <th>Number of trainees</th>
        </thead>
        <tbody>
          <tr>
            <td>${item?.Uni_Criteria03_352?.Uni_Consult2}</td>
            <td>${item?.Uni_Criteria03_352?.Uni_Title1}</td>
            <td>${item?.Uni_Criteria03_352?.Uni_Agency3}</td>
            <td>${item?.Uni_Criteria03_352?.Uni_Revenue1}</td>
            <td>${item?.Uni_Criteria03_352?.Uni_Trainee}</td>
          </tr>
        </tbody>
      </table>
    `;

        let obj = {
          //  Criteria 3_312

          Uni_Year: item?.Uni_Year,
          "Criteria_3.1.2": item?.Uni_Criteria03_312?.Uni_criteria,
          "Name of the teacher provided with seed money":
            item?.Uni_Criteria03_312?.Uni_Seed,
          "The amount of seed money (INR in Lakhs)":
            item?.Uni_Criteria03_312?.Uni_SeedMoney,
          "Year of receiving": item?.Uni_Criteria03_312?.Uni_Receiving,
          "Description (min 500 words)(3.1.2)":
            item?.Uni_Criteria03_312?.Uni_Desc5,
          "Upload the relevant document(3.1.2)":
            item?.Uni_Criteria03_312?.Uni_Link5,

          // Criteria 3_313

          "Criteria_3.1.3": item?.Uni_Criteria03_313?.Uni_criteria,
          "Name of the teacher awarded national/ international fellowship/financial support":
            item?.Uni_Criteria03_313?.Uni_FellowShip,
          "Name of the award/fellowship":
            item?.Uni_Criteria03_313?.Uni_AFellowship,
          "Year of Award": item?.Uni_Criteria03_313?.Uni_Award,
          "Awarding Agency": item?.Uni_Criteria03_313?.Uni_Agency,
          "Description (min 500 words)(3.1.3)":
            item?.Uni_Criteria03_313?.Uni_Desc6,
          "Upload the relevant document(3.1.3)":
            item?.Uni_Criteria03_313?.Uni_Link6,

          // Criteria 3_314

          "Criteria_3.1.4": item?.Uni_Criteria03_314?.Uni_criteria,
          "SI.No": item?.Uni_Criteria03_314?.Uni_Sno,
          "Name of the Research fellow": item?.Uni_Criteria03_314?.Uni_Research,
          "Year of Enrollment": item?.Uni_Criteria03_314?.Uni_Enroll,
          "Duration of fellowship": item?.Uni_Criteria03_314?.Uni_Fellow,
          "Type of the fellowship": item?.Uni_Criteria03_314?.Uni_Fellow1,
          "Granting agency": item?.Uni_Criteria03_314?.Uni_Grant,
          "Qualifying exam if any (NET, GATE, etc.)":
            item?.Uni_Criteria03_314?.Uni_Qualify,
          "Description (min 500 words)(3.1.4)":
            item?.Uni_Criteria03_314?.Uni_Desc23,
          "Upload the relevant document(3.1.4)":
            item?.Uni_Criteria03_314?.Uni_Link23,

          // Criteria 3_316
          "Criteria_3.1.6,3.2.1,3.2.2,3.2.3":
            item?.Uni_Criteria03_316?.Uni_criteria,
          "Name of the Scheme/Project/ Endowments/ Chairs":
            item?.Uni_Criteria03_316?.Uni_Endowments,
          "Name of the Principal Investigator/ Co Investigator (if applicable)":
            item?.Uni_Criteria03_316?.Uni_Invest,
          "Name of the Funding agency": item?.Uni_Criteria03_316?.Uni_FAgency,
          "Type (Government/Non-Government)":
            item?.Uni_Criteria03_316?.Uni_Type,
          Department: item?.Uni_Criteria03_316?.Uni_Dep,
          "Year of Award1": item?.Uni_Criteria03_316?.Uni_Award1,
          "Funds provided (INR in lakhs)": item?.Uni_Criteria03_316?.Uni_Funds,
          "Duration of the project": item?.Uni_Criteria03_316?.Uni_Project,
          "Description (min 500 words)(3.1.6)":
            item?.Uni_Criteria03_316?.Uni_Desc7,
          "Upload the relevant document(3.1.6)":
            item?.Uni_Criteria03_316?.Uni_Link7,

          // Criteria 3_332
          "Criteria_3.3.2": item?.Uni_Criteria03_332?.Uni_criteria,
          "Name of the workshop/ seminar":
            item?.Uni_Criteria03_332?.Uni_Seminar,
          "Number of participants": item?.Uni_Criteria03_332?.Uni_Parti,
          "Date (From)": moment(item?.Uni_Criteria03_332?.Uni_DFrom).format(
            "DD-MM-YYYY"
          ),
          "Date (To)": moment(item?.Uni_Criteria03_332?.Uni_DTo).format(
            "DD-MM-YYYY"
          ),
          "Description (min 500 words)(3.3.2)":
            item?.Uni_Criteria03_332?.Uni_Desc8,
          "Upload the relevant document(3.3.2)":
            item?.Uni_Criteria03_332?.Uni_Link8,

          // Criteria 3_333
          "Criteria_3.3.3": item?.Uni_Criteria03_333?.Uni_criteria,
          Year_of_Award: item?.Uni_Criteria03_333?.Uni_YAw,
          "Title of the innovation": item?.Uni_Criteria03_333?.Uni_Innova,
          Name_of_the_Award: item?.Uni_Criteria03_333?.Uni_Awardee,
          "Name of the Awarding Agency with contact details":
            item?.Uni_Criteria03_333?.Uni_Details,
          "Category- institution/teacher/research scholar/student":
            item?.Uni_Criteria03_333?.Uni_Category,
          "Description (min 500 words)(3.3.3)":
            item?.Uni_Criteria03_333?.Uni_Desc17,
          "Upload the relevant document(3.3.3)":
            item?.Uni_Criteria03_333?.Uni_Link17,

          // Criteria 3_343
          "Criteria_3.4.3": item?.Uni_Criteria03_343?.Uni_criteria,
          "Name of the Teacher": item?.Uni_Criteria03_343?.Uni_Teacher,
          "Patent Number": item?.Uni_Criteria03_343?.Uni_Patent,
          "Title of the patent": item?.Uni_Criteria03_343?.Uni_Title,
          "Year of Award / publish of patent":
            item?.Uni_Criteria03_343?.Uni_YOAw,
          "Description (min 500 words)(3.4.3)":
            item?.Uni_Criteria03_343?.Uni_Desc18,
          "Upload the relevant document(3.4.3)":
            item?.Uni_Criteria03_343?.Uni_Link18,

          // Criteria 3_344
          "Criteria_3.4.4": item?.Uni_Criteria03_344?.Uni_criteria,
          "Name of the PhD scholar": item?.Uni_Criteria03_344?.Uni_Phd,
          "Name of the Department": item?.Uni_Criteria03_344?.Uni_Depar,
          "Name of the guide/s": item?.Uni_Criteria03_344?.Uni_Guide,
          "Title of the thesis": item?.Uni_Criteria03_344?.Uni_Thesis1,
          "Year of registration of the scholar":
            item?.Uni_Criteria03_344?.Uni_Scholar,
          "Year of PhD awarded": item?.Uni_Criteria03_344?.Uni_Phd1,
          "Whether recognised as a Research Guide for Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit (YES/NO)":
            item?.Uni_Criteria03_344?.Uni_Whether1,
          "Year of Recognition as a Research Guide":
            item?.Uni_Criteria03_344?.Uni_Recog,
          "Description (min 500 words)(3.4.4)":
            item?.Uni_Criteria03_344?.Uni_Desc19,
          "Upload the relevant document(3.4.4)":
            item?.Uni_Criteria03_344?.Uni_Link19,

          // Criteria 3_345
          "Criteria_3.4.5": item?.Uni_Criteria03_345?.Uni_criteria,
          "Title of paper": item?.Uni_Criteria03_345?.Uni_Paper,
          "Name of the author/s": item?.Uni_Criteria03_345?.Uni_Author,
          "Department of the teacher": item?.Uni_Criteria03_345?.Uni_Dept,
          "Name of journal": item?.Uni_Criteria03_345?.Uni_Journal,
          "Year of publication": item?.Uni_Criteria03_345?.Uni_Pub,
          "ISSN number": item?.Uni_Criteria03_345?.Uni_ISSN,
          "Link to the recognition in UGC enlistment of the Journal /Digital Object Identifier (doi) number":
            tableMarkup1,
          "Description min 500 words(3.4.5)":
            item?.Uni_Criteria03_345?.Uni_Desc9,
          // "Link to website of the Journal(3.4.5)":
          //   item?.Uni_Criteria03_345?.Uni_Link9,
          // "Link to article/paper/abstract of the article(3.4.5)":
          //   item?.Uni_Criteria03_345?.Uni_Link10,

          // Criteria 3_346
          "Criteria_3.4.6": item?.Uni_Criteria03_346?.Uni_criteria,
          "Sl. No.": item?.Uni_Criteria03_346?.Uni_SLno,
          "Name of the teacher": item?.Uni_Criteria03_346?.Uni_Name,
          "Title of the book/chapters published":
            item?.Uni_Criteria03_346?.Uni_Book,
          "Title of the paper": item?.Uni_Criteria03_346?.Uni_Paper1,
          "Title of the proceedings of the conference":
            item?.Uni_Criteria03_346?.Uni_Title4,
          "Year of publication1": item?.Uni_Criteria03_346?.Uni_Year2,
          "ISBN/ISSN number of the proceeding":
            item?.Uni_Criteria03_346?.Uni_ISBN,
          "Whether at the time of publication Affiliating Institution Was same":
            item?.Uni_Criteria03_346?.Uni_Whether,
          "Name of the publisher": item?.Uni_Criteria03_346?.Uni_Name1,
          "Description min 500 words(3.4.6)":
            item?.Uni_Criteria03_346?.Uni_Desc10,
          "Link to relevnt document(3.4.6)":
            item?.Uni_Criteria03_346?.Uni_Link11,

          // Criteria 3_347
          "Criteria_3.4.7": item?.Uni_Criteria03_347?.Uni_criteria,
          "Name of the teacher1": item?.Uni_Criteria03_347?.Uni_Teacher1,
          "Name of the module developed": item?.Uni_Criteria03_347?.Uni_Module,
          "Platform on which module is developed":
            item?.Uni_Criteria03_347?.Uni_Platform,
          "Date of launching e content": moment(
            item?.Uni_Criteria03_347?.Uni_Launch
          ).format("DD-MM-YYYY"),
          "List of the e-content development facility available":
            item?.Uni_Criteria03_347?.Uni_List1,
          "Description min 500 words(3.4.7)":
            item?.Uni_Criteria03_347?.Uni_Desc20,
          "Link to the relevant document and facility available in the institution":
            item?.Uni_Criteria03_347?.Uni_Link20,
          "Provide link to videos of the media centre and recording facility":
            item?.Uni_Criteria03_347?.Uni_Link21,

          // Criteria 3_352
          "Criteria_3.5.2": item?.Uni_Criteria03_352?.Uni_criteria,
          "Total amount generated from consultancy and corporate training year wise":
            table1,
          "Total amount spent on developing facilities, training teachers and staff for undertaking consultancy during the last five years":
            table2,
          "Description min 500 words(3.5.2)":
            item?.Uni_Criteria03_352?.Uni_Desc21,
          "Link to relevnt document(3.5.2)":
            item?.Uni_Criteria03_352?.Uni_Link22,

          // Criteria 3_362
          "Criteria_3.6.2": item?.Uni_Criteria03_362?.Uni_criteria,
          "Name of the activity ": item?.Uni_Criteria03_362?.Uni_Name2,
          "Name of the Award/ recognition": item?.Uni_Criteria03_362?.Uni_Name3,
          "Name of the Awarding government/ government recognised bodies":
            item?.Uni_Criteria03_362?.Uni_Name4,
          "Year of Award2": item?.Uni_Criteria03_362?.Uni_Year4,
          "Description min 500 words(3.6.2)":
            item?.Uni_Criteria03_362?.Uni_Desc12,
          "Link to relevnt document(3.6.2)":
            item?.Uni_Criteria03_362?.Uni_Link13,

          // Criteria 3_363
          "Criteria_3.6.3": item?.Uni_Criteria03_363?.Uni_criteria,
          "Name of the Activity": item?.Uni_Criteria03_363?.Uni_Name5,
          "Organising unit/ agency/ collaborating agency":
            item?.Uni_Criteria03_363?.Uni_org,
          "Name of the scheme": item?.Uni_Criteria03_363?.Uni_Scheme,
          "Year of activity": item?.Uni_Criteria03_363?.Uni_Year5,
          "Number of students participated in such activities":
            item?.Uni_Criteria03_363?.Uni_Participants1,
          "Description min 500 words(3.6.3)":
            item?.Uni_Criteria03_363?.Uni_Desc13,
          "Link to relevnt document(3.6.3)":
            item?.Uni_Criteria03_363?.Uni_Link14,

          // Criteria 3_371
          "Criteria_3.7.1": item?.Uni_Criteria03_371?.Uni_criteria,
          "SI. No.": item?.Uni_Criteria03_371?.Uni_SLNO1,
          "Title of the collaborative activity":
            item?.Uni_Criteria03_371?.Uni_Colloborative,
          "Name of the collaborating agency with contact details":
            item?.Uni_Criteria03_371?.Uni_Agency2,
          "Name of the participant":
            item?.Uni_Criteria03_371?.Uni_Participants3,
          "Year of collaboration": item?.Uni_Criteria03_371?.Uni_Collo,
          Duration1: item?.Uni_Criteria03_371?.Uni_Durat,
          "Nature of the activity": item?.Uni_Criteria03_371?.Uni_Activity,
          "Description min 500 words(3.7.1)":
            item?.Uni_Criteria03_371?.Uni_Desc14,
          "Link to relevnt document(3.7.1)":
            item?.Uni_Criteria03_371?.Uni_Link15,

          // Criteria 3_372
          "Criteria_3.7.2": item?.Uni_Criteria03_372?.Uni_criteria,
          "Name of the organisation /institution/ industry with whom MoU is signed":
            item?.Uni_Criteria03_372?.Uni_IIC,
          "Year of signing MoU": item?.Uni_Criteria03_372?.Uni_Signing,
          Duration2: item?.Uni_Criteria03_372?.Uni_Durat1,
          "List the actual activities under each MOU year-wise":
            item?.Uni_Criteria03_372?.Uni_List,
          "Description min 500 words(3.7.2)":
            item?.Uni_Criteria03_372?.Uni_Desc15,
          "Link to relevnt document(3.7.2)":
            item?.Uni_Criteria03_372?.Uni_Link16,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editUni_Criteria03_312 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc5) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_312/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Seed: Uni_Seed,
          Uni_SeedMoney: Uni_SeedMoney,
          Uni_Receiving: Uni_Receiving,
          Uni_Desc5: Uni_Desc5,
          Uni_Link5: Uni_Link5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_313 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc6) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_313/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_FellowShip: Uni_FellowShip,
          Uni_AFellowship: Uni_AFellowship,
          Uni_Award: Uni_Award,
          Uni_Agency: Uni_Agency,
          Uni_Desc6: Uni_Desc6,
          Uni_Link6: Uni_Link6,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_314 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc23) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_314/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Sno: Uni_Sno,
          Uni_Research: Uni_Research,
          Uni_Enroll: Uni_Enroll,
          Uni_Fellow: Uni_Fellow,
          Uni_Fellow1: Uni_Fellow1,
          Uni_Grant: Uni_Grant,
          Uni_Qualify: Uni_Qualify,
          Uni_Desc23: Uni_Desc23,
          Uni_Link23: Uni_Link23,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_316 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc7;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc7) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_316/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Endowments: Uni_Endowments,
          Uni_Invest: Uni_Invest,
          Uni_FAgency: Uni_FAgency,
          Uni_Type: Uni_Type,
          Uni_Dep: Uni_Dep,
          Uni_Award1: Uni_Award1,
          Uni_Funds: Uni_Funds,
          Uni_Project: Uni_Project,
          Uni_Desc7: Uni_Desc7,
          Uni_Link7: Uni_Link7,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_332 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc8;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc8) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_332/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Seminar: Uni_Seminar,
          Uni_Parti: Uni_Parti,
          Uni_DFrom: Uni_DFrom,
          Uni_DTo: Uni_DTo,
          Uni_Desc8: Uni_Desc8,
          Uni_Link8: Uni_Link8,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editUni_Criteria03_333 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc17;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc17) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_333/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_YAw: Uni_YAw,
          Uni_Innova: Uni_Innova,
          Uni_Awardee: Uni_Awardee,
          Uni_Details: Uni_Details,
          Uni_Category: Uni_Category,
          Uni_Desc17: Uni_Desc17,
          Uni_Link17: Uni_Link17,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editUni_Criteria03_343 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc18) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_343/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Teacher: Uni_Teacher,
          Uni_Patent: Uni_Patent,
          Uni_Title: Uni_Title,
          Uni_YOAw: Uni_YOAw,
          Uni_Desc18: Uni_Desc18,
          Uni_Link18: Uni_Link18,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editUni_Criteria03_344 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc19) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_344/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Phd: Uni_Phd,
          Uni_Depar: Uni_Depar,
          Uni_Guide: Uni_Guide,
          Uni_Thesis1: Uni_Thesis1,
          Uni_Scholar: Uni_Scholar,
          Uni_Phd1: Uni_Phd1,
          Uni_Whether1: Uni_Whether1,
          Uni_Recog: Uni_Recog,
          Uni_Desc19: Uni_Desc19,
          Uni_Link19: Uni_Link19,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit8(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editUni_Criteria03_345 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc9;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc9) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_345/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Paper: Uni_Paper,
          Uni_Author: Uni_Author,
          Uni_Dept: Uni_Dept,
          Uni_Journal: Uni_Journal,
          Uni_Pub: Uni_Pub,
          Uni_ISSN: Uni_ISSN,
          Uni_ISIT: Uni_ISIT,
          Uni_Desc9: Uni_Desc9,
          Uni_Link9: Uni_Link9,
          Uni_Link10: Uni_Link10,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit9(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_346 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc10;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc10) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_346/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_SLno: Uni_SLno,
          Uni_Name: Uni_Name,
          Uni_Book: Uni_Book,
          Uni_Paper1: Uni_Paper1,
          Uni_Title4: Uni_Title4,
          Uni_Year2: Uni_Year2,
          Uni_ISBN: Uni_ISBN,
          Uni_Whether: Uni_Whether,
          Uni_Name1: Uni_Name1,
          Uni_Desc10: Uni_Desc10,
          Uni_Link11: Uni_Link11,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit10(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editUni_Criteria03_347 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc20;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc20) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_347/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Teacher1: Uni_Teacher1,
          Uni_Module: Uni_Module,
          Uni_Platform: Uni_Platform,
          Uni_Launch: Uni_Launch,
          Uni_List1: Uni_List1,
          Uni_Desc20: Uni_Desc20,
          Uni_Link20: Uni_Link20,
          Uni_Link21: Uni_Link21,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit11(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_352 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc21) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_352/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Consult: Uni_Consult,
          Uni_Project1: Uni_Project1,
          Uni_Agency1: Uni_Agency1,
          Uni_Revenue: Uni_Revenue,
          Uni_Consult2: Uni_Consult2,
          Uni_Title1: Uni_Title1,
          Uni_Agency3: Uni_Agency3,
          Uni_Revenue1: Uni_Revenue1,
          Uni_Trainee: Uni_Trainee,
          Uni_Desc21: Uni_Desc21,
          Uni_Link22: Uni_Link22,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit12(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_362 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc12;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc12) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_362/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Name2: Uni_Name2,
          Uni_Name3: Uni_Name3,
          Uni_Name4: Uni_Name4,
          Uni_Year4: Uni_Year4,
          Uni_Desc12: Uni_Desc12,
          Uni_Link13: Uni_Link13,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit13(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_363 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc13;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Uni_Desc13) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_363/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Name5: Uni_Name5,
          Uni_org: Uni_org,
          Uni_Scheme: Uni_Scheme,
          Uni_Year5: Uni_Year5,
          Uni_Participants1: Uni_Participants1,
          Uni_Desc13: Uni_Desc13,
          Uni_Link14: Uni_Link14,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit14(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_371 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc14;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc14) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_371/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_SLNO1: Uni_SLNO1,
          Uni_Colloborative: Uni_Colloborative,
          Uni_Agency2: Uni_Agency2,
          Uni_Participants3: Uni_Participants3,
          Uni_Collo: Uni_Collo,
          Uni_Durat: Uni_Durat,
          Uni_Activity: Uni_Activity,
          Uni_Desc14: Uni_Desc14,
          Uni_Link15: Uni_Link15,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit15(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria03_372 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc15;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc15) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria03_372/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_IIC: Uni_IIC,
          Uni_Signing: Uni_Signing,
          Uni_Durat1: Uni_Durat1,
          Uni_List: Uni_List,
          Uni_Desc15: Uni_Desc15,
          Uni_Link16: Uni_Link16,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getunicri03details();
          setedit16(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getunicri03details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getunicri03details"
      );
      if (res.status == 200) {
        setAllDetails(res.data.unicriteria03);
        setUni_Criteria03(res.data.unicriteria03);
        setdata1(res.data.unicriteria03);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.unicriteria03);
    }
  };

  useEffect(() => {
    getunicri03details();
  }, []);
  console.log(Uni_Criteria03);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  // const uniCriteria01Details = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria1"
  //     );
  //     if (res.status === 200) {
  //       setAllDetails(res.data.pgcriteria01);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAllDetails(error.response.data.pgcriteria01);
  //   }
  // };

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-03
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/unicriteria003">
                <button className=" btn btn-success">
                  Add Criteria 3{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "20000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  {/* 3.1.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher provided with seed money</th>
                  <th>The amount of seed money (INR in Lakhs)</th>
                  <th>Year of receiving</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.1.3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </th>
                  <th>Name of the award/fellowship</th>
                  <th>Year of Award</th>
                  <th>Awarding Agency</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.1.4 */}
                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>Name of the Research fellow</th>
                  <th>Year of enrollment</th>
                  <th>Duration of fellowship</th>
                  <th>Type of the fellowship</th>
                  <th>Granting agency</th>
                  <th>Qualifying exam if any (NET, GATE, etc.)</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.1.6 */}
                  <th>Criteria</th>
                  <th>Name of the Scheme/Project/ Endowments/ Chairs</th>
                  <th>
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </th>
                  <th>Name of the Funding agency</th>
                  <th>Type (Government/Non-Government)</th>
                  <th>Department</th>
                  <th>Year of Award</th>
                  <th>Funds provided (INR in lakhs)</th>
                  <th>Duration of the project</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.3.2 */}
                  <th>Criteria</th>
                  <th>Name of the workshop/ seminar</th>
                  <th>Number of participants</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.3.3 */}
                  <th>Criteria</th>
                  <th>Year of Award</th>
                  <th>Title of the innovation</th>
                  <th>Name of the Awardee</th>
                  <th>Name of the Awarding Agency with contact details</th>
                  <th>
                    Category- institution/teacher/research scholar/student
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.3 */}
                  <th>Criteria</th>
                  <th>Name of the Teacher</th>
                  <th>Patent Number</th>
                  <th>Title of the patent</th>
                  <th>Year of Award / publish of patent</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.4 */}
                  <th>Criteria</th>
                  <th> Name of the PhD scholar</th>
                  <th>Name of the Department</th>
                  <th>Name of the guide/s</th>
                  <th>Title of the thesis</th>
                  <th>Year of registration of the scholar</th>
                  <th>Year of PhD awarded</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit (YES/NO)
                  </th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.4.5 */}
                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Department of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>Document 1</th>
                  <th>Document 2</th>

                  {/* 3.4.6 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>

                  <th>
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </th>
                  <th>Name of the publisher</th>
                  <th>Description</th>
                  <th>Document</th>
                  {/* 3.4.7 */}
                  <th>Criteria</th>
                  <th>Name of the teacher</th>
                  <th>Name of the module developed</th>
                  <th>Platform on which module is developed</th>
                  <th>Date of launching e content</th>
                  <th>List of the e-content development facility available</th>
                  <th>Description</th>
                  <th>Document 1</th>
                  <th>Document 2</th>

                  {/* 3.5.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher-consultants</th>
                  <th>Name of consultancy project</th>
                  <th>Consulting/Sponsoring agency with contact details</th>
                  <th>Revenue generated (INR in Lakhs)</th>
                  <th>Names of the teacher-consultants/corporate trainers</th>
                  <th>Title of the corporate training program</th>
                  <th>Agency seeking training with contact details</th>
                  <th>Revenue generated (amount in rupees)</th>
                  <th>Number of trainees</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.6.2 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Name of the Award/ recognition</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Year of award</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.6.3 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of activity</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.7.1 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the collaborating agency with contact details</th>
                  <th>Title of the collaborative activity</th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Nature of the activity</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 3.7.2 */}
                  <th>Criteria</th>
                  <th>
                    Name of the Organisation/institution/ industry/ with whom
                    MoU is signed
                  </th>
                  <th>Year of signing MoU</th>
                  <th>Duration of MoU</th>
                  <th>List the actual activities under each MOU year-wise</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Uni_Criteria03?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria03_312?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_Seed?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_SeedMoney?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_Receiving?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_312?.Uni_Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_FellowShip?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_AFellowship?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_Award?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_Agency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_313?.Uni_Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Sno?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Research?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Enroll?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Fellow?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Fellow1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Grant?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_314?.Uni_Desc23?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Endowments?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Invest?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_FAgency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Dep?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Award1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Funds?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Project?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_316?.Uni_Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_Seminar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_Parti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_DFrom?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_DTo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_332?.Uni_Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_YAw?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Innova?.toLowerCase()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Awardee?.toLowerCase()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Details?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Category?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_333?.Uni_Desc17?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Teacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Patent?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Title?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_YOAw?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_343?.Uni_Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Phd?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Depar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Guide?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Thesis1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Scholar?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Phd1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Whether1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Recog?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_344?.Uni_Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Paper?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Author?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Dept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Journal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Pub?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_ISSN?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_ISIT?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_345?.Uni_Desc9?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_SLno?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Name?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Book?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Paper1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Title4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Year2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_ISBN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Name1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_346?.Uni_Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Teacher1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Module?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Platform?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Uni_Criteria03_347?.Uni_Launch)
                        ?.format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Uni_Criteria03_347?.Uni_List1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_347?.Uni_Desc20?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Consult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Project1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Agency1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Revenue?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Consult2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Title1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Agency3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Revenue1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Trainee?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_352?.Uni_Desc21?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Name2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Name3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Name4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Year4?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_362?.Uni_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Name5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_org?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Scheme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Year5?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Participants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_363?.Uni_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_SLNO1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Colloborative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Agency2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Participants3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Collo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Durat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Activity?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_371?.Uni_Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_IIC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Signing?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Durat1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria03_372?.Uni_Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      {/* 3.1.2 */}
                      <td>{item?.Uni_Criteria03_312?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_312?.Uni_Seed}</td>
                      <td>{item?.Uni_Criteria03_312?.Uni_SeedMoney}</td>
                      <td>{item?.Uni_Criteria03_312?.Uni_Receiving}</td>
                      <td>
                        {item?.Uni_Criteria03_312?.Uni_Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow17();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.3 */}
                      <td>{item?.Uni_Criteria03_313?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_FellowShip}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_AFellowship}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_Award}</td>
                      <td>{item?.Uni_Criteria03_313?.Uni_Agency}</td>
                      <td>
                        {item?.Uni_Criteria03_313?.Uni_Desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow18();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.4 */}
                      <td>{item?.Uni_Criteria03_314?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Sno}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Research}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Enroll}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Fellow}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Fellow1}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Grant}</td>
                      <td>{item?.Uni_Criteria03_314?.Uni_Qualify}</td>
                      <td>
                        {item?.Uni_Criteria03_314?.Uni_Desc23?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow19();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.6 */}
                      <td>{item?.Uni_Criteria03_316?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Endowments}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Invest}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_FAgency}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Type}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Dep}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Award1}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Funds}</td>
                      <td>{item?.Uni_Criteria03_316?.Uni_Project}</td>
                      <td>
                        {item?.Uni_Criteria03_316?.Uni_Desc7?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow20();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.3.2 */}
                      <td>{item?.Uni_Criteria03_332?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_332?.Uni_Seminar}</td>
                      <td>{item?.Uni_Criteria03_332?.Uni_Parti}</td>
                      <td>
                        {moment(item?.Uni_Criteria03_332?.Uni_DFrom).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Uni_Criteria03_332?.Uni_DTo).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {item?.Uni_Criteria03_332?.Uni_Desc8?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow21();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.3.3 */}
                      <td>{item?.Uni_Criteria03_333?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_YAw}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Innova}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Awardee}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Details}</td>
                      <td>{item?.Uni_Criteria03_333?.Uni_Category}</td>
                      <td>
                        {item?.Uni_Criteria03_333?.Uni_Desc17?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow22();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.3 */}
                      <td>{item?.Uni_Criteria03_343?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_Teacher}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_Patent}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_Title}</td>
                      <td>{item?.Uni_Criteria03_343?.Uni_YOAw}</td>
                      <td>
                        {item?.Uni_Criteria03_343?.Uni_Desc18?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow23();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.4 */}
                      <td>{item?.Uni_Criteria03_344?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Phd}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Depar}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Guide}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Thesis1}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Scholar}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Phd1}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Whether1}</td>
                      <td>{item?.Uni_Criteria03_344?.Uni_Recog}</td>
                      <td>
                        {item?.Uni_Criteria03_344?.Uni_Desc19?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow24();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.5 */}
                      <td>{item?.Uni_Criteria03_345?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Paper}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Author}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Dept}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Journal}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_Pub}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_ISSN}</td>
                      <td>{item?.Uni_Criteria03_345?.Uni_ISIT}</td>
                      <td>
                        {item?.Uni_Criteria03_345?.Uni_Desc9?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow25();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow26();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.6 */}
                      <td>{item?.Uni_Criteria03_346?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_SLno}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Name}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Book}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Paper1}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Title4}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Year2}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_ISBN}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Whether}</td>
                      <td>{item?.Uni_Criteria03_346?.Uni_Name1}</td>
                      <td>
                        {item?.Uni_Criteria03_346?.Uni_Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow27();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.7 */}
                      <td>{item?.Uni_Criteria03_347?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Teacher1}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Module}</td>
                      <td>{item?.Uni_Criteria03_347?.Uni_Platform}</td>
                      <td>
                        {moment(item?.Uni_Criteria03_347?.Uni_Launch).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Uni_Criteria03_347?.Uni_List1}</td>
                      <td>
                        {item?.Uni_Criteria03_347?.Uni_Desc20?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow28();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow29();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.5.2 */}
                      <td>{item?.Uni_Criteria03_352?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Consult}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Project1}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Agency1}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Revenue}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Consult2}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Title1}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Agency3}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Revenue1}</td>
                      <td>{item?.Uni_Criteria03_352?.Uni_Trainee}</td>
                      <td>
                        {item?.Uni_Criteria03_352?.Uni_Desc21?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow30();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.2 */}
                      <td>{item?.Uni_Criteria03_362?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Name2}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Name3}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Name4}</td>
                      <td>{item?.Uni_Criteria03_362?.Uni_Year4}</td>
                      <td>
                        {item?.Uni_Criteria03_362?.Uni_Desc12?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow31();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.3 */}
                      <td>{item?.Uni_Criteria03_363?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Name5}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_org}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Scheme}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Year5}</td>
                      <td>{item?.Uni_Criteria03_363?.Uni_Participants1}</td>
                      <td>
                        {item?.Uni_Criteria03_363?.Uni_Desc13?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow32();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.1 */}
                      <td>{item?.Uni_Criteria03_371?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_SLNO1}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Agency2}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Colloborative}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Participants3}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Collo}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Durat}</td>
                      <td>{item?.Uni_Criteria03_371?.Uni_Activity}</td>
                      <td>
                        {item?.Uni_Criteria03_371?.Uni_Desc14?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow33();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.2 */}
                      <td>{item?.Uni_Criteria03_372?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_IIC}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_Signing}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_Durat1}</td>
                      <td>{item?.Uni_Criteria03_372?.Uni_List}</td>
                      <td>
                        {item?.Uni_Criteria03_372?.Uni_Desc15?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow34();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 3 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------3.1.2------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the teacher provided with seed money
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_312?.Uni_Seed}
                        onChange={(e) => setUni_Seed(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_312?.Uni_Seed}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    The amount of seed money (INR in Lakhs)
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_312?.Uni_SeedMoney}
                        onChange={(e) => setUni_SeedMoney(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_312?.Uni_SeedMoney}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of receiving
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_312?.Uni_Receiving}
                        onChange={(e) => setUni_Receiving(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_312?.Uni_Receiving}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_312?.Uni_Desc5}
                        onChange={(e) => setUni_Desc5(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_312?.Uni_Desc5}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_312?.Uni_Link5}
                        onChange={(e) => setUni_Link5(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_312?.Uni_Link5}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_312(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.1.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_313?.Uni_FellowShip}
                        onChange={(e) => {
                          setUni_FellowShip(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_313?.Uni_FellowShip}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/fellowship
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_313?.Uni_AFellowship}
                        onChange={(e) => {
                          setUni_AFellowship(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_313?.Uni_AFellowship}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Award
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_313?.Uni_Award}
                        onChange={(e) => setUni_Award(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_313?.Uni_Award}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Awarding Agency
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_313?.Uni_Agency}
                        onChange={(e) => setUni_Agency(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_313?.Uni_Agency}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_313?.Uni_Desc6}
                        onChange={(e) => setUni_Desc6(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_313?.Uni_Desc6}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload2"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_313?.Uni_Link6}
                        onChange={(e) => setUni_Link6(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_313?.Uni_Link6}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit2 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_313(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.1.4-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.4
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-2 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl.no.
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_314?.Uni_Sno}
                        onChange={(e) => {
                          setUni_Sno(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_314?.Uni_Sno}</>
                  )}
                </div>
                <div className="col-md-5 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Research fellow
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_314?.Uni_Research}
                        onChange={(e) => {
                          setUni_Research(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_314?.Uni_Research}</>
                  )}
                </div>
                <div className="col-md-5 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of enrolment
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_314?.Uni_Enroll}
                        onChange={(e) => {
                          setUni_Enroll(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_314?.Uni_Enroll}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of fellowship
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_314?.Uni_Fellow}
                        onChange={(e) => {
                          setUni_Fellow(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_314?.Uni_Fellow}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Type of the fellowship
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_314?.Uni_Fellow1}
                        onChange={(e) => {
                          setUni_Fellow1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_314?.Uni_Fellow1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Granting agency
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_314?.Uni_Grant}
                        onChange={(e) => {
                          setUni_Grant(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_314?.Uni_Grant}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualifying exam if any (NET, GATE, etc.)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_314?.Uni_Qualify}
                        onChange={(e) => {
                          setUni_Qualify(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_314?.Uni_Qualify}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_314?.Uni_Desc23}
                        onChange={(e) => setUni_Desc23(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_314?.Uni_Desc23}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3 "
                        accept="image/*"
                        placeholder={Uni_Link23}
                        onChange={(e) => setUni_Link23(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_314?.Uni_Link23}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit3 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_314(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.1.6-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.6 & 3.2.1 & 3.2.2 & 3.2.4
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Scheme/Project/ Endowments/ Chairs
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_Endowments}
                        onChange={(e) => {
                          setUni_Endowments(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_Endowments}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_Invest}
                        onChange={(e) => {
                          setUni_Invest(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_Invest}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Funding agency
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_FAgency}
                        onChange={(e) => {
                          setUni_FAgency(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_FAgency}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Type (Government/Non-Government)
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_Type}
                        onChange={(e) => {
                          setUni_Type(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_Type}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of Principal Investigator/ Co Investigator
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_Dep}
                        onChange={(e) => {
                          setUni_Dep(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_Dep}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_Award1}
                        onChange={(e) => {
                          setUni_Award1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_Award1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Funds provided (INR in lakhs)
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_Funds}
                        onChange={(e) => {
                          setUni_Funds(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_Funds}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of the project
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_Project}
                        onChange={(e) => {
                          setUni_Project(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_Project}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_Desc7}
                        onChange={(e) => setUni_Desc7(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_Desc7}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload4"
                        id="upload4 "
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_316?.Uni_Link7}
                        onChange={(e) => setUni_Link7(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_316?.Uni_Link7}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit4 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_316(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.3.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the workshop/ seminar
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_332?.Uni_Seminar}
                        onChange={(e) => {
                          setUni_Seminar(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_332?.Uni_Seminar}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the students
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_332?.Uni_Parti}
                        onChange={(e) => {
                          setUni_Parti(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_332?.Uni_Parti}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date (From)
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_332?.Uni_DFrom}
                        onChange={(e) => {
                          setUni_DFrom(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.Uni_Criteria03_332?.Uni_DFrom).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date (To)
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_332?.Uni_DTo}
                        onChange={(e) => {
                          setUni_DTo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.Uni_Criteria03_332?.Uni_DTo).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        rows={3}
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_332?.Uni_Desc8}
                        onChange={(e) => setUni_Desc8(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_332?.Uni_Desc8}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_332?.Uni_Link8}
                        onChange={(e) => setUni_Link8(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_332?.Uni_Link8}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit5 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_332(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.3.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_333?.Uni_YAw}
                        onChange={(e) => {
                          setUni_YAw(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_333?.Uni_YAw}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the innovation
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_333?.Uni_Innova}
                        onChange={(e) => {
                          setUni_Innova(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_333?.Uni_Innova}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awardee
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_333?.Uni_Awardee}
                        onChange={(e) => {
                          setUni_Awardee(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_333?.Uni_Awardee}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awarding Agency with contact details
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_333?.Uni_Details}
                        onChange={(e) => {
                          setUni_Details(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_333?.Uni_Details}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category- institution/teacher/research scholar/student
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_333?.Uni_Category}
                        onChange={(e) => {
                          setUni_Category(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_333?.Uni_Category}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_333?.Uni_Desc17}
                        onChange={(e) => setUni_Desc17(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_333?.Uni_Desc17}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload6"
                        id="upload6 "
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_333?.Uni_Link17}
                        onChange={(e) => setUni_Link17(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_333?.Uni_Link17}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit6 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_333(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>{" "}
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit6(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.4.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Teacher
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_343?.Uni_Teacher}
                        onChange={(e) => {
                          setUni_Teacher(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_343?.Uni_Teacher}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Patent Number
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_343?.Uni_Patent}
                        onChange={(e) => {
                          setUni_Patent(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_343?.Uni_Patent}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the patent
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_343?.Uni_Title}
                        onChange={(e) => {
                          setUni_Title(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_343?.Uni_Title}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award / publish of patent
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_343?.Uni_YOAw}
                        onChange={(e) => {
                          setUni_YOAw(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_343?.Uni_YOAw}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_343?.Uni_Desc18}
                        onChange={(e) => setUni_Desc18(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_343?.Uni_Desc18}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload7"
                        id="upload7 "
                        accept="image/*"
                        placeholder={Uni_Link18}
                        onChange={(e) => setUni_Link18(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_343?.Uni_Link18}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit7 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_343(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit7(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.4.4-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.4
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the PhD scholar
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_344?.Uni_Phd}
                          onChange={(e) => {
                            setUni_Phd(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Phd}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Department
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_344?.Uni_Depar}
                          onChange={(e) => {
                            setUni_Depar(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Depar}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the guide/s
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_344?.Uni_Guide}
                          onChange={(e) => {
                            setUni_Guide(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Guide}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the thesis
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_344?.Uni_Thesis1}
                          onChange={(e) => {
                            setUni_Thesis1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Thesis1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of registration of the scholar
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_344?.Uni_Scholar}
                          onChange={(e) => {
                            setUni_Scholar(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Scholar}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of PhD awarded
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_344?.Uni_Phd1}
                          onChange={(e) => {
                            setUni_Phd1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Phd1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Whether recognised as a Research Guide for
                      Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit
                      (YES/NO)
                    </label>

                    {edit8 ? (
                      <>
                        <select
                          class="form-select form-select-sm vi_0"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          placeholder={Value?.Uni_Criteria03_344?.Uni_Whether1}
                          onChange={(e) => setUni_Whether1(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Whether1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of PhD awarded
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_344?.Uni_Recog}
                          onChange={(e) => {
                            setUni_Recog(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Recog}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      Description
                    </label>

                    {edit8 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_344?.Uni_Desc19}
                          onChange={(e) => setUni_Desc19(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Desc19}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload8"
                          id="upload8 "
                          accept="image/*"
                          placeholder={Uni_Link19}
                          onChange={(e) => setUni_Link19(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_344?.Uni_Desc19}</>
                    )}
                  </div>
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit8 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_344(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit8(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.4.5-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.5
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of paper
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_Paper}
                        onChange={(e) => {
                          setUni_Paper(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_Paper}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the author/s
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_Author}
                        onChange={(e) => {
                          setUni_Author(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_Author}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of the teacher
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_Dept}
                        onChange={(e) => {
                          setUni_Dept(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_Dept}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of journal
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_Journal}
                        onChange={(e) => {
                          setUni_Journal(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_Journal}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of publication
                  </label>
                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_Pub}
                        onChange={(e) => {
                          setUni_Pub(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_Pub}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ISSN number
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_ISSN}
                        onChange={(e) => {
                          setUni_ISSN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_ISSN}</>
                  )}
                </div>
                <h6>
                  Link to the recognition in UGC enlistment of the Journal{" "}
                </h6>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_ISIT}
                        onChange={(e) => {
                          setUni_ISIT(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_ISIT}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit9 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_Desc9}
                        onChange={(e) => setUni_Desc9(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_Desc9}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document 1
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload5"
                        id="upload5 "
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_Link9}
                        onChange={(e) => setUni_Link9(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_Link9}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document 2
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload6"
                        id="upload6 "
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_345?.Uni_Link10}
                        onChange={(e) => setUni_Link10(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_345?.Uni_Link10}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit9 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_345(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit9(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.4.6-------- */}
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.6
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_SLno}
                        onChange={(e) => {
                          setUni_SLno(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_SLno}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_Name}
                        onChange={(e) => {
                          setUni_Name(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_Name}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the book/chapters published
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_Book}
                        onChange={(e) => {
                          setUni_Book(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_Book}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the paper
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_Paper1}
                        onChange={(e) => {
                          setUni_Paper1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_Paper1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the proceedings of the conference
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_Title4}
                        onChange={(e) => {
                          setUni_Title4(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_Title4}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of publication
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_Year2}
                        onChange={(e) => {
                          setUni_Year2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_Year2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ISBN/ISSN number of the proceeding
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_ISBN}
                        onChange={(e) => {
                          setUni_ISBN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_ISBN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the publisher
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_Name1}
                        onChange={(e) => {
                          setUni_Name1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_Name1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </label>

                  {edit10 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Uni_Criteria03_346?.Uni_Whether}
                        onChange={(e) => setUni_Whether(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_Whether}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit10 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_Desc10}
                        onChange={(e) => setUni_Desc10(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_Desc10}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload11"
                        id="upload11 "
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_346?.Uni_Link11}
                        onChange={(e) => setUni_Link11(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_346?.Uni_Link11}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit10 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_346(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit10(true)}
                          >
                            Edit
                          </button>{" "}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.4.7-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.7
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher
                    </label>

                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_347?.Uni_Teacher1}
                          onChange={(e) => {
                            setUni_Teacher1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_347?.Uni_Teacher1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the module developed
                    </label>

                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_347?.Uni_Module}
                          onChange={(e) => {
                            setUni_Module(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_347?.Uni_Module}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Platform on which module is developed
                    </label>

                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_347?.Uni_Platform}
                          onChange={(e) => {
                            setUni_Platform(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_347?.Uni_Platform}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Date of launching e content
                    </label>

                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="date"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_347?.Uni_Launch}
                          onChange={(e) => {
                            setUni_Launch(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_347?.Uni_Launch}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      List of the e-content development facility available
                    </label>

                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_347?.Uni_List1}
                          onChange={(e) => {
                            setUni_List1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_347?.Uni_List1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description
                    </label>

                    {edit11 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Value?.Uni_Criteria03_347?.Uni_Desc20}
                          onChange={(e) => setUni_Desc20(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_347?.Uni_Desc20}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Document
                    </label>

                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload13"
                          id="upload13"
                          accept="image/*"
                          placeholder={Uni_Link21}
                          onChange={(e) => setUni_Link21(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria03_347?.Uni_Link21}</>
                    )}
                  </div>
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit11 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_347(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit11(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.5.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.5.2 & 3.5.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <h6 className="text-center text-decoration-underline">
                  Revenue generated from consultancy during the last five years
                </h6>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher consultant
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Consult}
                        onChange={(e) => {
                          setUni_Consult(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Consult}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of consultancy project
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Project1}
                        onChange={(e) => {
                          setUni_Project1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Project1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Consulting/Sponsoring agency with contact details
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Agency1}
                        onChange={(e) => {
                          setUni_Agency1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Agency1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Revenue generated (INR in Lakhs)
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Revenue}
                        onChange={(e) => {
                          setUni_Revenue(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Revenue}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  Revenue generated from corporate training during the last five
                  years
                </h6>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Names of the teacher-consultants/corporate trainers
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Consult2}
                        onChange={(e) => {
                          setUni_Consult2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Consult2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the corporate training program
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Title1}
                        onChange={(e) => {
                          setUni_Title1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Title1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Agency seeking training with contact details
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Agency3}
                        onChange={(e) => {
                          setUni_Agency3(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Agency3}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Revenue generated (amount in rupees)
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Revenue1}
                        onChange={(e) => {
                          setUni_Revenue1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Revenue1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of trainees
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Trainee}
                        onChange={(e) => {
                          setUni_Trainee(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Trainee}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit12 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_352?.Uni_Desc21}
                        onChange={(e) => setUni_Desc21(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Desc21}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit12 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload14"
                        id="upload14"
                        accept="image/*"
                        placeholder={Uni_Link22}
                        onChange={(e) => setUni_Link22(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_352?.Uni_Link22}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit12 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_352(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit12(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.6.2-------- */}
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.6.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  {edit13 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_362?.Uni_Name2}
                        onChange={(e) => {
                          setUni_Name2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_362?.Uni_Name2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Award/ recognition
                  </label>

                  {edit13 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_362?.Uni_Name3}
                        onChange={(e) => {
                          setUni_Name3(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_362?.Uni_Name3}</>
                  )}
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awarding government/ government recognised
                    bodies
                  </label>

                  {edit13 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_362?.Uni_Name4}
                        onChange={(e) => {
                          setUni_Name4(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_362?.Uni_Name4}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of award
                  </label>

                  {edit13 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_362?.Uni_Year4}
                        onChange={(e) => {
                          setUni_Year4(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_362?.Uni_Year4}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit13 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_362?.Uni_Desc12}
                        onChange={(e) => setUni_Desc12(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_362?.Uni_Desc12}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit13 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload9"
                        id="upload9"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_362?.Uni_Link13}
                        onChange={(e) => setUni_Link13(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_362?.Uni_Link13}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit13 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_362(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit13(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.6.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.6.3 & 3.6.4
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  {edit14 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_363?.Uni_Name5}
                        onChange={(e) => {
                          setUni_Name5(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_363?.Uni_Name5}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Organising unit/ agency/ collaborating agency
                  </label>

                  {edit14 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_363?.Uni_org}
                        onChange={(e) => {
                          setUni_org(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_363?.Uni_org}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scheme
                  </label>

                  {edit14 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_363?.Uni_Scheme}
                        onChange={(e) => {
                          setUni_Scheme(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_363?.Uni_Scheme}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of the activity
                  </label>

                  {edit14 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_363?.Uni_Year5}
                        onChange={(e) => {
                          setUni_Year5(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_363?.Uni_Year5}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students participated in such activities
                  </label>

                  {edit14 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria03_363?.Uni_Participants1
                        }
                        onChange={(e) => {
                          setUni_Participants1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_363?.Uni_Participants1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit14 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_363?.Uni_Desc13}
                        onChange={(e) => setUni_Desc13(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_363?.Uni_Desc13}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit14 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload10"
                        id="upload10"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_363?.Uni_Link14}
                        onChange={(e) => setUni_Link14(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_363?.Uni_Link14}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit14 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_363(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit14(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.7.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {edit15 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_371?.Uni_SLNO1}
                        onChange={(e) => {
                          setUni_SLNO1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_371?.Uni_SLNO1}</>
                  )}
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the collaborating agency with contact details
                  </label>

                  {edit15 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_371?.Uni_Agency2}
                        onChange={(e) => {
                          setUni_Agency2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_371?.Uni_Agency2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the collaborative activity
                  </label>

                  {edit15 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria03_371?.Uni_Colloborative
                        }
                        onChange={(e) => {
                          setUni_Colloborative(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_371?.Uni_Colloborative}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the participant
                  </label>

                  {edit15 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Uni_Criteria03_371?.Uni_Participants3
                        }
                        onChange={(e) => {
                          setUni_Participants3(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_371?.Uni_Participants3}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of collaboration
                  </label>

                  {edit15 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_371?.Uni_Collo}
                        onChange={(e) => {
                          setUni_Collo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_371?.Uni_Collo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>

                  {edit15 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_371?.Uni_Durat}
                        onChange={(e) => {
                          setUni_Durat(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_371?.Uni_Durat}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of the activity
                  </label>

                  {edit15 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_371?.Uni_Activity}
                        onChange={(e) => {
                          setUni_Activity(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_371?.Uni_Activity}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit15 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_371?.Uni_Desc14}
                        onChange={(e) => setUni_Desc14(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_371?.Uni_Desc14}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit15 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload17"
                        id="upload17"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_371?.Uni_Link15}
                        onChange={(e) => setUni_Link15(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_371?.Uni_Link15}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit15 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_371(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit15(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.7.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.7.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the institution/ industry/ corporate house
                  </label>

                  {edit16 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_372?.Uni_IIC}
                        onChange={(e) => {
                          setUni_IIC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_372?.Uni_IIC}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of signing MoU
                  </label>

                  {edit16 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_372?.Uni_Signing}
                        onChange={(e) => {
                          setUni_Signing(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_372?.Uni_Signing}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>

                  {edit16 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_372?.Uni_Durat1}
                        onChange={(e) => {
                          setUni_Durat1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_372?.Uni_Durat1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    List the actual activities under each MOU year-wise
                  </label>

                  {edit16 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_372?.Uni_List}
                        onChange={(e) => {
                          setUni_List(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_372?.Uni_List}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit16 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Uni_Criteria03_372?.Uni_Desc15}
                        onChange={(e) => setUni_Desc15(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_372?.Uni_Desc15}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit16 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload12"
                        id="upload12"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria03_372?.Uni_Link16}
                        onChange={(e) => setUni_Link16(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria03_372?.Uni_Link16}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit16 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editUni_Criteria03_372(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit16(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_312?.Uni_Desc5}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_313?.Uni_Desc6}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_314?.Uni_Desc23}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_316?.Uni_Desc7}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_332?.Uni_Desc8}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_333?.Uni_Desc17}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_343?.Uni_Desc18}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_8 */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_344?.Uni_Desc19}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_9 */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_345?.Uni_Desc9}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_10 */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_346?.Uni_Desc10}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_11 */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_347?.Uni_Desc20}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_12 */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_352?.Uni_Desc21}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose12}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_13 */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_362?.Uni_Desc12}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose13}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_14 */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_363?.Uni_Desc13}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose14}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_15 */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_371?.Uni_Desc14}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose15}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_16 */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria03_372?.Uni_Desc15}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose16}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show17} onHide={handleClose17}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_312?.Uni_Link5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show18} onHide={handleClose18}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_313?.Uni_Link6}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show19} onHide={handleClose19}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_314?.Uni_Link23}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show20} onHide={handleClose20}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_316?.Uni_Link7}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show21} onHide={handleClose21}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_332?.Uni_Link8}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show22} onHide={handleClose22}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_333?.Uni_Link17}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show23} onHide={handleClose23}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_343?.Uni_Link18}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show24} onHide={handleClose24}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_344?.Uni_Link19}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 9 model */}
        <Modal show={show25} onHide={handleClose25}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_345?.Uni_Link9}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 10 model */}
        <Modal show={show26} onHide={handleClose26}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_345?.Uni_Link10}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 11 model */}
        <Modal show={show27} onHide={handleClose27}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_346?.Uni_Link11}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 12 model */}
        <Modal show={show28} onHide={handleClose28}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_347?.Uni_Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 13 model */}
        <Modal show={show29} onHide={handleClose29}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_347?.Uni_Link21}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 14 model */}
        <Modal show={show30} onHide={handleClose30}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_352?.Uni_Link22}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 15 model */}
        <Modal show={show31} onHide={handleClose31}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_362?.Uni_Link13}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 16 model */}
        <Modal show={show32} onHide={handleClose32}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_363?.Uni_Link14}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 17 model */}
        <Modal show={show33} onHide={handleClose33}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_371?.Uni_Link15}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 18 model */}
        <Modal show={show34} onHide={handleClose34}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria03/${View?.Uni_Criteria03_372?.Uni_Link16}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Uni_Criteria03;
