import React from "react";

const UG1132aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Program Name</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria01_132?.PrgName2}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Program Code</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_132?.PPrgmCode}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of offering</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_132?.YYOfOffering}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Name of the student studied course on experiential learning through
            project work/field work/internship
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_132?.StdCourseStudied}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Name of the Course that include experiential learning through
            project work/field work/internship{" "}
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria01_132?.ExpCourse}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria01_132?.Desc4}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria01/${item?.Criteria01_132?.Link4}`}
              target="blank_"
            >
              {" "}
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG1132aqar;
