import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";

function A_02_242() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================

  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("A-2.4.2");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.Auto_Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          Year: item?.Auto_Year,
          // Criteria 2_242
          "Criteria_2.4.2": item?.Auto_Criteria02_242?.Auto_criteria,
          " Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D.Litt.":
            item?.Auto_Criteria02_242?.Auto_SerTeacher,
          " Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./ D.Litt.)":
            item?.Auto_Criteria02_242?.Auto_Qualify,
          "Year of obtaining the qualification":
            item?.Auto_Criteria02_242?.Auto_YOfQuali,
          "Year of Recognition as a Research Guide":
            item?.Auto_Criteria02_242?.Auto_YOfRecog,
          " Whether recognised as a Research Guide for  Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D'Lit.":
            item?.Auto_Criteria02_242?.Auto_Whether,
          " Is the teacher still serving the institution/If not last year of the service of Faculty to the Institution":
            item?.Auto_Criteria02_242?.Auto_IsThereTeacher,
          "Name of the scholar": item?.Auto_Criteria02_242?.Auto_NameScholar,
          "Year of registration of the scholar":
            item?.Auto_Criteria02_242?.Auto_YearScholar,
          "Description(min 50 characters)(2.4.2)":
            item?.Auto_Criteria02_242?.Auto_Desc32,
          "Upload the relevant document(2.4.2)":
            item?.Auto_Criteria02_242?.Auto_Link32,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================

  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        approvedetails?._id === item1?.addedByFac?.facultyAddedBy?._id &&
        item1?.approve === "true" &&
        selectedYear === item1?.Auto_Year
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 2 });
  };
  // =================================================================

  const [AllDetails, setAllDetails] = useState([]);
  const getpgCriteria02_242 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/autocri02_242"
      );
      if (res.status === 200) {
        setAllDetails(res.data.getAuto_C242);
        setfilterPdf(res.data.getAuto_C242);
        setdata1(res.data.getAuto_C242);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.getAuto_C242);
    }
  };
  console.log("AllDetailspg242", AllDetails);

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getpgCriteria02_242();
      getAllYears();
    }
  }, []);

  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);
  return (
    <div>
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-02-242
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>

            <div className=" col-lg-2 lab text-end">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = AllDetails?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Auto_Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      approvedetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Auto_Year &&
                      (SearchItem === "" ||
                        item1?.Auto_Year?.includes(SearchItem) ||
                        item1?.Auto_Criteria02_242?.Auto_SerTeacher?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Auto_Criteria02_242?.Auto_Qualify?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Auto_Criteria02_242?.Auto_YOfQuali?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria02_242?.Auto_YOfRecog?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Auto_Criteria02_242?.Auto_Whether?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria02_242?.Auto_IsThereTeacher?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Auto_Criteria02_242?.Auto_NameScholar?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Auto_Criteria02_242?.Auto_YearScholar?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Auto_Criteria02_242?.Auto_Thesis?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Auto_Criteria02_242?.Auto_Desc35?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });

                  if (arr1?.length > 0) {
                    navigate("/PDFprintA2_242", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>

          <div className="row justify-content-end m-0 mt-4 align-items-center">
            <div className=" col-lg-3 do-sear d-flex align-items-center  ">
              <label style={{ width: "130px" }}>Select Year</label>
              <select
                className="criteriaform vi_0"
                style={{ width: "150px" }}
                onChange={(e) => setselectedYear(e.target.value)}
              >
                <option>Select Year</option>
                {allYears
                  ?.sort((a, b) => {
                    return Number(a?.from) - Number(b?.from);
                  })
                  ?.map((item) => {
                    return (
                      <option value={`${item?.from}-${item?.to}`}>
                        {item?.from} - {item?.to}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-lg-9">
              <Nav defaultActiveKey="/riteria02">
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-1"
                    onClick={() => navigate("/A_02_211")}
                  >
                    <Button className="criteriabtn btn-sub">2.1.1</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-2"
                    onClick={() => navigate("/A_02_212")}
                  >
                    <Button className="criteriabtn btn-sub">2.1.2</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-3"
                    onClick={() => navigate("/A_02_241")}
                  >
                    <Button className="criteriabtn btn-sub">
                      2.4.1 & 2.4.3
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-3"
                    onClick={() => navigate("/A_02_242")}
                  >
                    <Button
                      variant="warning"
                      style={{ backgroundColor: "#ffc107" }}
                      className="criteriabtn-0 btn-sub"
                    >
                      2.4.2
                    </Button>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-3"
                    onClick={() => navigate("/A_02_251")}
                  >
                    <Button className="criteriabtn btn-sub">2.5.1</Button>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-3"
                    onClick={() => navigate("/A_02_263")}
                  >
                    <Button className="criteriabtn btn-sub">2.6.3</Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-0"
                    eventKey="link-3"
                    onClick={() => navigate("/A_02_271")}
                  >
                    <Button className="criteriabtn btn-sub">2.7.1</Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th>Name of the scholar</th>
                  <th>Year of registration of the scholar</th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    approvedetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Auto_Year &&
                    (SearchItem === "" ||
                      item1?.Auto_Year?.includes(SearchItem) ||
                      item1?.Auto_Criteria02_242?.Auto_SerTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_Qualify?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_YOfQuali?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_YOfRecog?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_Whether?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_IsThereTeacher?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_NameScholar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_YearScholar?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_242?.Auto_Desc35?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Auto_Year}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_SerTeacher}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_Qualify}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_YOfQuali}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_YOfRecog}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_Whether}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_IsThereTeacher}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_NameScholar}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_YearScholar}</td>

                      <td>
                        {item?.Auto_Criteria02_242?.Auto_Desc35?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{View?.Auto_Criteria02_242?.Auto_Desc35}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_242?.Auto_Link35}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* <div className="d-flex justify-content-end pages">
          <div>
            <nav aria-label="Page navigation example ">
              <ul class="pagination pagination-lg">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default A_02_242;
