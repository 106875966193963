import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
function Gallery() {
  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // show modal
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [galleryImg, setgalleryImg] = useState({});
  const [View, setView] = useState({});
  const [description, setdescription] = useState("");
  const [heading, setheading] = useState("");
  const [place, setplace] = useState("");
  const [organizer, setorganizer] = useState("");
  const [category, setcategory] = useState("");
  const [date, setdate] = useState("");
  const [showMore, setshowMore] = useState("");
  const [certificatePercent, setcertificatePercent] = useState("");
  const [educationPercent, seteducationPercent] = useState("");

  const AddGallery = async (e) => {
    e.preventDefault();
    if (
      !galleryImg ||
      !heading ||
      !description ||
      !place ||
      !organizer ||
      !category ||
      !date
    ) {
      return alert("Please fill all fields");
    }
    const obj = {
      galleryImg: galleryImg,
      heading: heading,
      description: description,
      place: place,
      organizer: organizer,
      category: category,
      date: date,
    };
    try {
      const config = {
        url: "/admin/addGallery",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          setgalleryImg("");
          setheading("");
          setdescription("");
          setplace("");
          setorganizer("");
          setcategory("");
          setcategory("");
          setdate("");
          getGallery();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the Gallery======================
  const [Gallery, setGallery] = useState([]);
  const getGallery = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getGallery"
      );
      if (res.status === 200) {
        setGallery(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setGallery(error.response.data.allContent);
    }
  };

  const UpdateGallery = async (e) => {
    e.preventDefault();

    if (
      !galleryImg &&
      !heading &&
      !description &&
      !place &&
      !organizer &&
      !category &&
      !date
    ) {
      return alert("Please Provide atleast one detail to edit!!!");
    }
    const obj = {
      galleryImg: galleryImg,
      heading: heading,
      description: description,
      place: place,
      organizer: organizer,
      category: category,
      date: date,
    };
    try {
      const config = {
        url: `/admin/editGallery/${View?._id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setgalleryImg("");
          setheading("");
          setdescription("");
          setplace("");
          setorganizer("");
          setcategory("");
          setcategory("");
          setdate("");
          getGallery();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  const deleteGallery = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteGallery/${View?._id}`,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Deleted Successfully");
          setgalleryImg("");
          setheading("");
          setdescription("");
          setplace("");
          setorganizer("");
          setcategory("");
          setcategory("");
          setdate("");
          getGallery();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose1();
    }
  };

  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getGallery();
  }, []);
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Gallery
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Image</th>
                <th>Topic</th>
                <th>Description</th>
                <th>place</th>
                <th>Organizer</th>
                <th>Category</th>
                <th>Posted-Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Gallery?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>
                      <img
                        src={`https://brightnaac.co.in/Gallery/${item?.galleryImg}`}
                        style={{ width: "30%" }}
                        alt="no-image"
                      />
                    </td>
                    <td>{item?.heading}</td>
                    <td>
                      {item?.description?.slice(0, 100)}
                      <Link
                        onClick={() => {
                          setshowMore(item?.description);
                          handleShow3();
                        }}
                      >
                        Read More...
                      </Link>
                    </td>
                    <td>{item?.place}</td>
                    <td>{item?.organizer}</td>
                    <td>{item?.category}</td>
                    <td>{moment(item?.date)?.format("DD-MM-YYYY")}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-evenly">
                        <div className="">
                          <span
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              margin: "10px",
                            }}
                          >
                            <BiEdit
                              size={20}
                              onClick={() => {
                                setView(item);
                                handleShow2();
                              }}
                            />
                          </span>
                        </div>
                        <div className="">
                          <span
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            style={{
                              cursor: "pointer",
                              color: "red",
                              margin: "10px",
                            }}
                          >
                            <AiFillDelete size={20} />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Update Gallery</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>
                Upload Image <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setgalleryImg(e.target.files[0])}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Topic <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.heading}
                  onChange={(e) => setheading(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Description <span className="font-css top">*</span>
              </label>
              <div className="">
                <textarea
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  rows="5"
                  cols="30"
                  onChange={(e) => setdescription(e.target.value)}
                >
                  {View?.description}
                </textarea>
              </div>
            </div>

            <div className="mb-1">
              <label>
                Place <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.place}
                  onChange={(e) => setplace(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Organizer <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.organizer}
                  onChange={(e) => setorganizer(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Category <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.category}
                  onChange={(e) => setcategory(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Posted-Date <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="date"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setdate(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => UpdateGallery(e)}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Gallery</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>
                Upload Image <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setgalleryImg(e.target.files[0])}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Topic <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setheading(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Description <span className="font-css top">*</span>
              </label>
              <div className="">
                <textarea
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  rows="5"
                  cols="30"
                  onChange={(e) => setdescription(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="mb-1">
              <label>
                Place <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setplace(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Organizer <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setorganizer(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Category <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setcategory(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Posted-Date <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="date"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setdate(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddGallery(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteGallery(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>

        {/* show modal */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Body>
            <div className="mb-1">{showMore}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Gallery;
