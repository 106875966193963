import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

function Admincontactus() {
  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [View, setView] = useState({});
  const [officeName, setofficeName] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;

  const AddContact = async (e) => {
    e.preventDefault();

    if (!officeName) {
      return alert("Please Enter Office Name");
    }
    if (!email) {
      return alert("Enter Your Email");
    }
    if (!email.match(validRegex)) {
      return alert("Please Enter Valid Email-Id");
    }
    if (!phone) {
      return alert("Please Enter Phone Number");
    }
    if (phone.length !== 10) {
      return alert("Enter Contact Number should be 10 digits");
    }

    if (!phone.match(mobilevalid)) {
      return alert("Enter Valid Contact Number");
    }
    if (!address) {
      return alert("Enter Address");
    }

    try {
      const config = {
        url: "/admin/addcontact",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          officeName: officeName,
          phone: phone,
          email: email,
          address: address,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          setofficeName("");
          setemail("");
          setphone("");
          setaddress("");
          getContact();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the Contact======================
  const [Contact, setContact] = useState([]);
  const getContact = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getcontact"
      );
      if (res.status === 200) {
        setContact(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setContact(error.response.data.allContent);
    }
  };

  const UpdateContact = async (e) => {
    e.preventDefault();
    if (!officeName && !phone && !email && !address) {
      return alert("Please fill all the details !!!");
    }

    if (phone) {
      console.log("fhfhf", /^[6-9][0-9]{9}$/.test(phone));
      if (!/^[6-9][0-9]{9}$/.test(phone)) {
        return alert("Enter Valid Contact Number");
      }
    }

    if (email) {
      console.log("fhfhf", validRegex.test(email));
      if (!validRegex.test(email)) {
        return alert("Please Enter Valid Email-Id");
      }
    }
    // if (email.match(validRegex)) {
    //
    // }

    try {
      const config = {
        url: `/admin/editcontact/${View?._id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
        data: {
          officeName: officeName,
          phone: phone,
          email: email,
          address: address,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setofficeName("");
          setphone("");
          setemail("");
          setaddress("");
          getContact();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  const deleteContact = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deletecontact/${View?._id}`,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Deleted Successfully");
          getContact();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose1();
    }
  };

  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getContact();
  }, []);
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Contact Us
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Office Name</th>
                <th>Phone No.</th>
                <th>Email Id</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Contact?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>{item?.officeName}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.email}</td>
                    <td>{item?.address}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-evenly">
                        <div
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            margin: "10px",
                          }}
                        >
                          <BiEdit
                            size={20}
                            onClick={() => {
                              setView(item);
                              handleShow2();
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                          color: "red",
                          margin: "10px",
                        }}
                        onClick={() => {
                          setView(item);
                          handleShow1();
                        }}
                      >
                        <AiFillDelete size={20} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Update Contact Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>Office Name</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.officeName}
                  onChange={(e) => setofficeName(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>Phone</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.phone}
                  onChange={(e) => setphone(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>E-mail</label>
              <div className="">
                <input
                  type="email"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>Address</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.address}
                  onChange={(e) => setaddress(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => UpdateContact(e)}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Contact Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>Office Name</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setofficeName(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>Phone</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setphone(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>E-mail</label>
              <div className="">
                <input
                  type="email"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>Address</label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setaddress(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddContact(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteContact(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Admincontactus;
