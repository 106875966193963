import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

const Auto_Criteria03 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);
  const [edit8, setedit8] = useState(false);
  const [edit9, setedit9] = useState(false);
  const [edit10, setedit10] = useState(false);
  const [edit11, setedit11] = useState(false);

  const [Auto_Seed, setAuto_Seed] = useState("");
  const [Auto_SeedMoney, setAuto_SeedMoney] = useState("");
  const [Auto_Receiving, setAuto_Receiving] = useState("");
  const [Auto_Desc5, setAuto_Desc5] = useState("");
  const [Auto_Link5, setAuto_Link5] = useState("");

  const [Auto_FellowShip, setAuto_FellowShip] = useState("");
  const [Auto_AFellowship, setAuto_AFellowship] = useState("");
  const [Auto_Award, setAuto_Award] = useState("");
  const [Auto_Agency, setAuto_Agency] = useState("");
  const [Auto_Desc6, setAuto_Desc6] = useState("");
  const [Auto_Link6, setAuto_Link6] = useState("");

  const [Auto_SLNO, setAuto_SLNO] = useState("");
  const [Auto_Invest, setAuto_Invest] = useState("");
  const [Auto_FAgency, setAuto_FAgency] = useState("");
  const [Auto_Type, setAuto_Type] = useState("");
  const [Auto_Dep, setAuto_Dep] = useState("");
  const [Auto_Award1, setAuto_Award1] = useState("");
  const [Auto_Funds, setAuto_Funds] = useState("");
  const [Auto_Project, setAuto_Project] = useState("");
  const [Auto_Desc7, setAuto_Desc7] = useState("");
  const [Auto_Link7, setAuto_Link7] = useState("");

  const [Auto_Seminar, setAuto_Seminar] = useState("");
  const [Auto_Parti, setAuto_Parti] = useState("");
  const [Auto_DFrom, setAuto_DFrom] = useState("");
  const [Auto_DTo, setAuto_DTo] = useState("");
  const [Auto_Desc8, setAuto_Desc8] = useState("");
  const [Auto_Link8, setAuto_Link8] = useState("");

  const [Auto_Paper, setAuto_Paper] = useState("");
  const [Auto_Author, setAuto_Author] = useState("");
  const [Auto_Dept, setAuto_Dept] = useState("");
  const [Auto_Journal, setAuto_Journal] = useState("");
  const [Auto_Pub, setAuto_Pub] = useState("");
  const [Auto_ISSN, setAuto_ISSN] = useState("");
  const [Auto_ISIT, setAuto_ISIT] = useState("");
  const [Auto_Desc9, setAuto_Desc9] = useState("");
  const [Auto_Link9, setAuto_Link9] = useState("");
  const [Auto_Link10, setAuto_Link10] = useState("");

  const [Auto_SLno, setAuto_SLno] = useState("");
  const [Auto_Name, setAuto_Name] = useState("");
  const [Auto_Book, setAuto_Book] = useState("");
  const [Auto_Paper1, setAuto_Paper1] = useState("");
  const [Auto_Title4, setAuto_Title4] = useState("");
  const [Auto_Year2, setAuto_Year2] = useState("");
  const [Auto_ISBN, setAuto_ISBN] = useState("");
  const [Auto_Whether, setAuto_Whether] = useState("");
  const [Auto_Name1, setAuto_Name1] = useState("");
  const [Auto_Desc10, setAuto_Desc10] = useState("");
  const [Auto_Link11, setAuto_Link11] = useState("");

  const [Auto_Consult, setAuto_Consult] = useState("");
  const [Auto_Project1, setAuto_Project1] = useState("");
  const [Auto_Agency1, setAuto_Agency1] = useState("");
  const [Auto_Revenue, setAuto_Revenue] = useState("");
  const [Auto_Trainee, setAuto_Trainee] = useState("");
  const [Auto_Teacher1, setAuto_Teacher1] = useState("");
  const [Auto_Facility1, setAuto_Facility1] = useState("");
  const [Auto_Agen1, setAuto_Agen1] = useState("");
  const [Auto_Consult2, setAuto_Consult2] = useState("");
  const [Auto_Total1, setAuto_Total1] = useState("");
  const [Auto_Desc11, setAuto_Desc11] = useState("");
  const [Auto_Link12, setAuto_Link12] = useState("");

  const [Auto_Name2, setAuto_Name2] = useState("");
  const [Auto_Name3, setAuto_Name3] = useState("");
  const [Auto_Name4, setAuto_Name4] = useState("");
  const [Auto_Year4, setAuto_Year4] = useState("");
  const [Auto_Desc12, setAuto_Desc12] = useState("");
  const [Auto_Link13, setAuto_Link13] = useState("");

  const [Auto_Name5, setAuto_Name5] = useState("");
  const [Auto_org, setAuto_org] = useState("");
  const [Auto_Scheme, setAuto_Scheme] = useState("");
  const [Auto_Year5, setAuto_Year5] = useState("");
  const [Auto_Participants1, setAuto_Participants1] = useState("");
  const [Auto_Desc13, setAuto_Desc13] = useState("");
  const [Auto_Link14, setAuto_Link14] = useState("");

  const [Auto_SLNO1, setAuto_SLNO1] = useState("");
  const [Auto_Colloborative, setAuto_Colloborative] = useState("");
  const [Auto_Agency2, setAuto_Agency2] = useState("");
  const [Auto_Participants3, setAuto_Participants3] = useState("");
  const [Auto_Collo, setAuto_Collo] = useState("");
  const [Auto_Durat, setAuto_Durat] = useState("");
  const [Auto_Activity, setAuto_Activity] = useState("");
  const [Auto_Desc14, setAuto_Desc14] = useState("");
  const [Auto_Link15, setAuto_Link15] = useState("");

  const [Auto_Organ, setAuto_Organ] = useState("");
  const [Auto_IIC, setAuto_IIC] = useState("");
  const [Auto_Signing, setAuto_Signing] = useState("");
  const [Auto_Durat1, setAuto_Durat1] = useState("");
  const [Auto_List, setAuto_List] = useState("");
  const [Auto_Desc15, setAuto_Desc15] = useState("");
  const [Auto_Link16, setAuto_Link16] = useState("");

  //integrating get method
  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  const [show18, setShow18] = useState(false);
  const handleClose18 = () => setShow18(false);
  const handleShow18 = () => setShow18(true);

  const [show19, setShow19] = useState(false);
  const handleClose19 = () => setShow19(false);
  const handleShow19 = () => setShow19(true);

  const [show20, setShow20] = useState(false);
  const handleClose20 = () => setShow20(false);
  const handleShow20 = () => setShow20(true);

  const [show21, setShow21] = useState(false);
  const handleClose21 = () => setShow21(false);
  const handleShow21 = () => setShow21(true);

  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);

  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);
  const handleShow23 = () => setShow23(true);
  const [Auto_Criteria03, setAuto_Criteria03] = useState([]);
  const [Value, setValue] = useState({});

  //   ===============================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Autonomous_Criteria-03");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
        <table>
          <tr>
            <td>Is it listed in UGC Care list/Scopus/Web of Science/other, mention</td>
            <td>${item?.Auto_Criteria03_343?.Auto_UUGC}</td>
          </tr>
          <tr>
            <td>Link to website of the Journal</td>
            <td>${item?.Auto_Criteria03_343?.Auto_Link9}</td>
          </tr>
          <tr>
            <td>Link to article/paper/abstract of the article</td>
            <td>${item?.Auto_Criteria02_343?.Auto_Link10}</td>
          </tr>
        </table>
      `;
        let table1 = `
      <table>
        <thead>
          <th>Name of the teacher-consultants</th>
          <th>Name of consultancy project/corporate training program</th>
          <th>Consulting/Sponsoring agency with contact details</th>
          <th>Revenue generated (INR in Lakhs)</th>
          <th>Number of trainees</th>
        </thead>
        <tbody>
          <tr>
            <td>${item?.Auto_Criteria03_351?.Auto_Consult}</td>
            <td>${item?.Auto_Criteria03_351?.Auto_Project1}</td>
            <td>${item?.Auto_Criteria03_351?.Auto_Agency1}</td>
            <td>${item?.Auto_Criteria03_351?.Auto_Revenue}</td>
            <td>${item?.Auto_Criteria03_351?.Auto_Trainee}</td>
          </tr>
        </tbody>
      </table>
    `;
        let table2 = `
      <table>
        <thead>
          <th>Names of the teachers/staff</th>
          <th>Name of the facilities developed and department</th>
          <th>Agency seeking training with contact details</th>
          <th>Name of consultancy</th>
          <th>Total amount spent (INR in Lakhs)</th>
        </thead>
        <tbody>
          <tr>
            <td>${item?.Auto_Criteria03_351?.Auto_Teacher1}</td>
            <td>${item?.Auto_Criteria03_351?.Auto_Facility1}</td>
            <td>${item?.Auto_Criteria03_351?.Auto_Agen1}</td>
            <td>${item?.Auto_Criteria03_351?.Auto_Consult2}</td>
            <td>${item?.Auto_Criteria03_351?.Auto_Total1}</td>
          </tr>
        </tbody>
      </table>
    `;
        let obj = {
          //  Criteria 3_312

          Auto_Year: item?.Auto_Year,
          "Criteria_3.1.2": item?.Auto_Criteria03_312?.Auto_criteria,
          "Name of the teacher provided with seed money":
            item?.Auto_Criteria03_312?.Auto_Seed,
          "The amount of seed money (INR in Lakhs)":
            item?.Auto_Criteria03_312?.Auto_SeedMoney,
          "Year of receiving": item?.Auto_Criteria03_312?.Auto_Receiving,
          "Description (min 500 words)(3.1.2)":
            item?.Auto_Criteria03_312?.Auto_Desc5,
          "Document(3.1.2)": item?.Auto_Criteria03_312?.Auto_Link5,

          // Criteria 3_313

          "Criteria_3.1.3": item?.Auto_Criteria03_313?.Auto_criteria,
          "Name of the teacher awarded national/ international fellowship/financial support":
            item?.Auto_Criteria03_313?.Auto_FellowShip,
          "Name of the award/fellowship":
            item?.Auto_Criteria03_313?.Auto_AFellowship,
          "Year of Award": item?.Auto_Criteria03_313?.Auto_Award,
          "Awarding Agency": item?.Auto_Criteria03_313?.Auto_Agency,
          "Description (min 500 words)(3.1.3)":
            item?.Auto_Criteria03_313?.Auto_Desc6,
          "Document(3.1.3)": item?.Auto_Criteria03_313?.Auto_Link6,

          // Criteria 3_321
          "Criteria_3.2.1": item?.Auto_Criteria03_321?.Auto_criteria,
          "Sl.no.": item?.Auto_Criteria03_321?.Auto_SLNO,
          "Name of the Principal Investigator/ Co Investigator (if applicable)":
            item?.Auto_Criteria03_321?.Auto_Invest,
          "Name of the Funding agency": item?.Auto_Criteria03_321?.Auto_FAgency,
          "Type (Government/Non-Government)":
            item?.Auto_Criteria03_321?.Auto_Type,
          "Department of Principal Investigator/ Co Investigator":
            item?.Auto_Criteria03_321?.Auto_Dep,
          Year_of_Award: item?.Auto_Criteria03_321?.Auto_Award1,
          "Funds provided (INR in lakhs)":
            item?.Auto_Criteria03_321?.Auto_Funds,
          "Duration of the project": item?.Auto_Criteria03_321?.Auto_Project,
          "Description (min 500 words)(3.2.1)":
            item?.Auto_Criteria03_321?.Auto_Desc7,
          "Document(3.2.1)": item?.Auto_Criteria03_321?.Auto_Link7,

          // Criteria 3_332
          "Criteria_3.3.2": item?.Auto_Criteria03_332?.Auto_criteria,
          "Name of the workshop/ seminar":
            item?.Auto_Criteria03_332?.Auto_Seminar,
          "Name of the Participants": item?.Auto_Criteria03_332?.Auto_Parti,
          "Date (From)": moment(item?.Auto_Criteria03_332?.Auto_DFrom).format(
            "DD-MM-YYYY"
          ),
          "Date (To)": moment(item?.Auto_Criteria03_332?.Auto_DTo).format(
            "DD-MM-YYYY"
          ),
          "Description (min 500 words)(3.3.2)":
            item?.Auto_Criteria03_332?.Auto_Desc8,
          "Document(3.3.2)": item?.Auto_Criteria03_332?.Auto_Link8,

          // Criteria 3_343
          "Criteria_3.4.3": item?.Auto_Criteria03_343?.Auto_criteria,
          Title_of_paper: item?.Auto_Criteria03_343?.Auto_Paper,
          "Name of the author/s": item?.Auto_Criteria03_343?.Auto_Author,
          "Department of the teacher": item?.Auto_Criteria03_343?.Auto_Dept,
          "Name of journal": item?.Auto_Criteria03_343?.Auto_Journal,
          "Year-of-publication": item?.Auto_Criteria03_343?.Auto_Pub,
          "ISSN number": item?.Auto_Criteria03_343?.Auto_ISSN,
          "Link to the recognition in UGC enlistment of the Journal /Digital Object Identifier (doi) number":
            tableMarkup1,

          "Description min 500 words(3.4.3)":
            item?.Auto_Criteria03_343?.Auto_Desc9,
          // "Link to website of the Journal(3.4.3)":
          //   item?.Auto_Criteria03_343?.Auto_Link9,
          // "Link to article/paper/abstract of the article(3.4.3)":
          //   item?.Auto_Criteria03_343?.Auto_Link10,

          // Criteria 3_344
          "Criteria_3.4.4": item?.Auto_Criteria03_344?.Auto_criteria,
          "Sl. No.": item?.Auto_Criteria03_344?.Auto_SLno,
          "Name of the teacher": item?.Auto_Criteria03_344?.Auto_Name,
          "Title of the book/chapters published":
            item?.Auto_Criteria03_344?.Auto_Book,
          "Title-of-the-paper": item?.Auto_Criteria03_344?.Auto_Paper1,
          "Title of the proceedings of the conference":
            item?.Auto_Criteria03_344?.Auto_Title4,
          "Year of publication": item?.Auto_Criteria03_344?.Auto_Year2,
          "ISBN/ISSN number of the proceeding":
            item?.Auto_Criteria03_344?.Auto_ISBN,
          "Name of the publisher": item?.Auto_Criteria03_344?.Auto_Name1,
          "Whether at the time of publication Affiliating Institution Was same":
            item?.Auto_Criteria03_344?.Auto_Whether,
          "Description min 500 words(3.4.4)":
            item?.Auto_Criteria03_344?.Auto_Desc10,
          "Link to relevnt document(3.4.4)":
            item?.Auto_Criteria03_344?.Auto_Link11,

          // Criteria 3_351
          "Criteria_3.5.1": item?.Auto_Criteria03_351?.Auto_criteria,
          "Total amount generated from consultancy and corporate training year wise":
            table1,
          "Total amount spent on developing facilities, training teachers and staff for undertaking consultancy during the last five years":
            table2,
          "Description min 500 words(3.5.1)":
            item?.Auto_Criteria03_351?.Auto_Desc11,
          "Link to relevnt document(3.5.1)":
            item?.Auto_Criteria03_351?.Auto_Link12,

          // Criteria 3_362
          "Criteria_3.6.2": item?.Auto_Criteria03_362?.Auto_criteria,
          "Name of the activity ": item?.Auto_Criteria03_362?.Auto_Name2,
          "Name of the Award/ recognition":
            item?.Auto_Criteria03_362?.Auto_Name3,
          "Name of the Awarding government/ government recognised bodies":
            item?.Auto_Criteria03_362?.Auto_Name4,
          "Year-of-award": item?.Auto_Criteria03_362?.Auto_Year4,
          "Description min 500 words(3.6.2)":
            item?.Auto_Criteria03_362?.Auto_Desc12,
          "Link to relevnt document(3.6.2)":
            item?.Auto_Criteria03_362?.Auto_Link13,

          // Criteria 3_363
          "Criteria_3.6.3": item?.Auto_Criteria03_363?.Auto_criteria,
          "Name of the activity": item?.Auto_Criteria03_363?.Auto_Name5,
          "Organising unit/ agency/ collaborating agency":
            item?.Auto_Criteria03_363?.Auto_org,
          "Name of the scheme": item?.Auto_Criteria03_363?.Auto_Scheme,
          "Year Of Activity": item?.Auto_Criteria03_363?.Auto_Year5,
          "Number of students participated in such activities":
            item?.Auto_Criteria03_363?.Auto_Participants1,
          "Description min 500 words(3.6.3)":
            item?.Auto_Criteria03_363?.Auto_Desc13,
          "Link to relevnt document(3.6.3)":
            item?.Auto_Criteria03_363?.Auto_Link14,

          // Criteria 3_371
          "Criteria_3.7.1": item?.Auto_Criteria03_371?.Auto_criteria,
          "Sl/No.": item?.Auto_Criteria03_371?.Auto_SLNO1,
          "Name of the collaborating agency with contact details":
            item?.Auto_Criteria03_371?.Auto_Colloborative,
          "Title of the collaborative activity":
            item?.Auto_Criteria03_371?.Auto_Agency2,
          "Name of the participant":
            item?.Auto_Criteria03_371?.Auto_Participants3,
          "Year of collaboration": item?.Auto_Criteria03_371?.Auto_Collo,
          Duration: item?.Auto_Criteria03_371?.Auto_Durat,
          "Nature of the activity": item?.Auto_Criteria03_371?.Auto_Activity,
          "Description min 500 words(3.7.1)":
            item?.Auto_Criteria03_371?.Auto_Desc14,
          "Link to relevnt document(3.7.1)":
            item?.Auto_Criteria03_371?.Auto_Link15,

          // Criteria 3_372
          "Criteria_3.7.2": item?.Auto_Criteria03_372?.Auto_criteria,
          "Organisation with which MoU is signed":
            item?.Auto_Criteria03_372?.Auto_Organ,
          "Name of the institution/ industry/ corporate house":
            item?.Auto_Criteria03_372?.Auto_IIC,
          "Year of signing MoU": item?.Auto_Criteria03_372?.Auto_Signing,
          "The Duration": item?.Auto_Criteria03_372?.Auto_Durat1,
          "List the actual activities under each MOU year-wise":
            item?.Auto_Criteria03_372?.Auto_List,
          "Description min 500 words(3.7.2)":
            item?.Auto_Criteria03_372?.Auto_Desc15,
          "Link to relevnt document(3.7.2)":
            item?.Auto_Criteria03_372?.Auto_Link16,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editAuto_Criteria03_312 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Auto_Desc5) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_312/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Seed: Auto_Seed,
          Auto_SeedMoney: Auto_SeedMoney,
          Auto_Receiving: Auto_Receiving,
          Auto_Desc5: Auto_Desc5,
          Auto_Link5: Auto_Link5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          handleClose();
          setedit1(false);
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria03_313 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc6) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_313/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_FellowShip: Auto_FellowShip,
          Auto_AFellowship: Auto_AFellowship,
          Auto_Award: Auto_Award,
          Auto_Agency: Auto_Agency,
          Auto_Desc6: Auto_Desc6,
          Auto_Link6: Auto_Link6,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria03_321 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc7;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc7) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_321/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_SLNO: Auto_SLNO,
          Auto_Invest: Auto_Invest,
          Auto_FAgency: Auto_FAgency,
          Auto_Type: Auto_Type,
          Auto_Dep: Auto_Dep,
          Auto_Award1: Auto_Award1,
          Auto_Funds: Auto_Funds,
          Auto_Project: Auto_Project,
          Auto_Desc7: Auto_Desc7,
          Auto_Link7: Auto_Link7,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria03_332 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc8;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc8) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_332/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Seminar: Auto_Seminar,
          Auto_Parti: Auto_Parti,
          Auto_DFrom: Auto_DFrom,
          Auto_DTo: Auto_DTo,
          Auto_Desc8: Auto_Desc8,
          Auto_Link8: Auto_Link8,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editAuto_Criteria03_343 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc9;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (Auto_Desc9) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_343/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Paper: Auto_Paper,
          Auto_Author: Auto_Author,
          Auto_Dept: Auto_Dept,
          Auto_Journal: Auto_Journal,
          Auto_Pub: Auto_Pub,
          Auto_ISSN: Auto_ISSN,
          Auto_ISIT: Auto_ISIT,
          Auto_Desc9: Auto_Desc9,
          Auto_Link9: Auto_Link9,
          Auto_Link10: Auto_Link10,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria03_344 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc10;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc10) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_344/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_SLno: Auto_SLno,
          Auto_Name: Auto_Name,
          Auto_Book: Auto_Book,
          Auto_Paper1: Auto_Paper1,
          Auto_Title4: Auto_Title4,
          Auto_Year2: Auto_Year2,
          Auto_ISBN: Auto_ISBN,
          Auto_Whether: Auto_Whether,
          Auto_Name1: Auto_Name1,
          Auto_Desc10: Auto_Desc10,
          Auto_Link11: Auto_Link11,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria03_351 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc11;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc11) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_351/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Consult: Auto_Consult,
          Auto_Project1: Auto_Project1,
          Auto_Agency1: Auto_Agency1,
          Auto_Revenue: Auto_Revenue,
          Auto_Trainee: Auto_Trainee,
          Auto_Teacher1: Auto_Teacher1,
          Auto_Facility1: Auto_Facility1,
          Auto_Agen1: Auto_Agen1,
          Auto_Consult2: Auto_Consult2,
          Auto_Total1: Auto_Total1,
          Auto_Desc11: Auto_Desc11,
          Auto_Link12: Auto_Link12,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria03_362 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc12;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc12) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_362/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Name2: Auto_Name2,
          Auto_Name3: Auto_Name3,
          Auto_Name4: Auto_Name4,
          Auto_Year4: Auto_Year4,
          Auto_Desc12: Auto_Desc12,
          Auto_Link13: Auto_Link13,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit8(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria03_363 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc13;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc13) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_363/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Name5: Auto_Name5,
          Auto_org: Auto_org,
          Auto_Scheme: Auto_Scheme,
          Auto_Year5: Auto_Year5,
          Auto_Participants1: Auto_Participants1,
          Auto_Desc13: Auto_Desc13,
          Auto_Link14: Auto_Link14,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit9(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria03_371 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc14;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc14) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_371/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_SLNO1: Auto_SLNO1,
          Auto_Colloborative: Auto_Colloborative,
          Auto_Agency2: Auto_Agency2,
          Auto_Participants3: Auto_Participants3,
          Auto_Collo: Auto_Collo,
          Auto_Durat: Auto_Durat,
          Auto_Activity: Auto_Activity,
          Auto_Desc14: Auto_Desc14,
          Auto_Link15: Auto_Link15,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit10(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editAuto_Criteria03_372 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Auto_Desc15;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Auto_Desc15) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editAuto_Criteria03_372/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Organ: Auto_Organ,
          Auto_IIC: Auto_IIC,
          Auto_Signing: Auto_Signing,
          Auto_Durat1: Auto_Durat1,
          Auto_List: Auto_List,
          Auto_Desc15: Auto_Desc15,
          Auto_Link16: Auto_Link16,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getautocri03details();
          setedit11(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getautocri03details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getautocri03details"
      );
      if (res.status == 200) {
        setAllDetails(res.data.autocriteria01);
        setAuto_Criteria03(res.data.autocriteria03);
        setdata1(res.data.autocriteria03);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria03);
    }
  };

  useEffect(() => {
    getautocri03details();
  }, []);
  console.log(Auto_Criteria03);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  // const autoCriteria01Details = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria1"
  //     );
  //     if (res.status === 200) {
  //       setAllDetails(res.data.pgcriteria01);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setAllDetails(error.response.data.pgcriteria01);
  //   }
  // };

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-03
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/autocriteria003">
                <button className="btn btn-success ">
                  Add Criteria 3{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "15000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Name of the teacher provided with seed money</th>
                  <th>The amount of seed money (INR in Lakhs)</th>
                  <th>Year of receiving</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </th>
                  <th>Name of the award/fellowship</th>
                  <th>Year of Award</th>
                  <th>Awarding Agency</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </th>
                  <th>Name of the Funding agency</th>
                  <th>Type (Government/Non-Government)</th>
                  <th>Department of Principal Investigator/ Co Investigator</th>
                  <th>Year of Award</th>
                  <th>Funds provided (INR in lakhs)</th>
                  <th>Duration of the project</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the workshop/ seminar</th>
                  <th>Name of the Participants</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Department of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>Document Journal</th>
                  <th>Document of the article</th>

                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the teacher-consultants</th>
                  <th>
                    Name of consultancy project/corporate training program
                  </th>
                  <th>Consulting/Sponsoring agency with contact details</th>
                  <th>Revenue generated (INR in Lakhs)</th>
                  <th>Number of trainees</th>
                  <th>Names of the teachers/staff</th>
                  <th>Name of the facilities developed and department</th>
                  <th>Agency seeking training with contact details</th>
                  <th>Name of consultancy</th>
                  <th>Total amount spent (INR in Lakhs)</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Name of the Award/ recognition</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Year of award</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of activity</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the collaborating agency with contact details</th>
                  <th>Title of the collaborative activity</th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Nature of the activity</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Organisation with which MoU is signed</th>
                  <th>Name of the institution/ industry/ corporate house</th>
                  <th>Year of signing MoU</th>
                  <th>Duration</th>
                  <th>List the actual activities under each MOU year-wise</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Auto_Criteria03?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Auto_Criteria03_312?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_SeedMoney?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_Seed?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_Receiving?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_FellowShip?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_AFellowship?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_Award?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_Agency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_SLNO?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Invest?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_FAgency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Dep?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Award1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Funds?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Project?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_332?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_332?.Auto_Seminar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_332?.Auto_Parti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Auto_Criteria03_332?.Auto_DFrom)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Auto_Criteria03_332?.Auto_DTo)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Auto_Criteria03_332?.Auto_Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Paper?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Author?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Dept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Journal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Pub?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_ISSN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_ISIT?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Desc9?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_SLno?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Name?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Book?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Paper1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Title4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Year2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_ISBN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Name1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Consult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Project1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Agency1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Revenue?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Trainee?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Teacher1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Facility1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Agen1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Consult2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Desc11?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Name2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Name3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Name4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Year4?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Name5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_org?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Scheme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Year5?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Participants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Desc13?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_SLNO1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Colloborative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Agency2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Participants3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Collo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Durat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Activity?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_IIC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Signing?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Durat1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Auto_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Auto_Criteria03_312?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_312?.Auto_Seed}</td>
                      <td>{item?.Auto_Criteria03_312?.Auto_SeedMoney}</td>
                      <td>{item?.Auto_Criteria03_312?.Auto_Receiving}</td>
                      <td>
                        {item?.Auto_Criteria03_312?.Auto_Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria03_313?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_FellowShip}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_AFellowship}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_Award}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_Agency}</td>
                      <td>
                        {item?.Auto_Criteria03_313?.Auto_Desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria03_321?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_SLNO}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Invest}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_FAgency}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Type}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Dep}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Award1}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Funds}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Project}</td>
                      <td>
                        {item?.Auto_Criteria03_321?.Auto_Desc7?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria03_332?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_332?.Auto_Seminar}</td>
                      <td>{item?.Auto_Criteria03_332?.Auto_Parti}</td>
                      <td>
                        {moment(item?.Auto_Criteria03_332?.Auto_DFrom).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Auto_Criteria03_332?.Auto_DTo).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {item?.Auto_Criteria03_332?.Auto_Desc8?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria03_343?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Paper}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Author}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Dept}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Journal}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Pub}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_ISSN}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_ISIT}</td>
                      <td>
                        {item?.Auto_Criteria03_343?.Auto_Desc9?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow17();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria03_344?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_SLno}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Name}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Book}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Paper1}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Title4}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Year2}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_ISBN}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Name1}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Whether}</td>
                      <td>
                        {item?.Auto_Criteria03_344?.Auto_Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow18();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria03_351?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Consult}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Project1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Agency1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Revenue}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Trainee}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Teacher1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Facility1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Agen1}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Consult2}</td>
                      <td>{item?.Auto_Criteria03_351?.Auto_Total1}</td>
                      <td>
                        {item?.Auto_Criteria03_351?.Auto_Desc11?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow19();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Auto_Criteria03_362?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Name2}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Name3}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Name4}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Year4}</td>
                      <td>
                        {item?.Auto_Criteria03_362?.Auto_Desc12?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow20();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria03_363?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Name5}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_org}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Scheme}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Year5}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Participants1}</td>
                      <td>
                        {item?.Auto_Criteria03_363?.Auto_Desc13?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow21();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria03_371?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_SLNO1}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Agency2}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Colloborative}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Participants3}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Collo}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Durat}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Activity}</td>
                      <td>
                        {item?.Auto_Criteria03_371?.Auto_Desc14?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow22();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria03_372?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_Organ}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_IIC}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_Signing}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_Durat1}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_List}</td>
                      <td>
                        {item?.Auto_Criteria03_372?.Auto_Desc15?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow23();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Modal */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                Autonomous_Criteria-03
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------3.1.2------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the teacher provided with seed money
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_312?.Auto_Seed}
                        onChange={(e) => setAuto_Seed(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_312?.Auto_Seed}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    The amount of seed money (INR in Lakhs)
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_312?.Auto_SeedMoney}
                        onChange={(e) => setAuto_SeedMoney(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_312?.Auto_SeedMoney}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of receiving
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_312?.Auto_Receiving}
                        onChange={(e) => setAuto_Receiving(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_312?.Auto_Receiving}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_312?.Auto_Desc5}
                        onChange={(e) => setAuto_Desc5(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_312?.Auto_Desc5}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_312?.Auto_Link5}
                        onChange={(e) => setAuto_Link5(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_312?.Auto_Link5}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit1(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_312(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.1.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria03_313?.Auto_FellowShip
                        }
                        onChange={(e) => {
                          setAuto_FellowShip(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_313?.Auto_FellowShip}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the award/fellowship
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria03_313?.Auto_AFellowship
                        }
                        onChange={(e) => {
                          setAuto_AFellowship(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_313?.Auto_AFellowship}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Award
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_313?.Auto_Award}
                        onChange={(e) => setAuto_Award(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_313?.Auto_Award}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Awarding Agency
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_313?.Auto_Agency}
                        onChange={(e) => setAuto_Agency(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_313?.Auto_Agency}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_313?.Auto_Desc6}
                        onChange={(e) => setAuto_Desc6(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_313?.Auto_Desc6}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload2"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_313?.Auto_Link6}
                        onChange={(e) => setAuto_Link6(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_313?.Auto_Link6}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit2(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_313(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.2.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    Sl.no.
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_SLNO}
                        onChange={(e) => {
                          setAuto_SLNO(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_SLNO}</>
                  )}
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_Invest}
                        onChange={(e) => {
                          setAuto_Invest(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_Invest}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Funding agency
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_FAgency}
                        onChange={(e) => {
                          setAuto_FAgency(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_FAgency}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Type (Government/Non-Government)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_Type}
                        onChange={(e) => {
                          setAuto_Type(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_Type}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of Principal Investigator/ Co Investigator
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_Dep}
                        onChange={(e) => {
                          setAuto_Dep(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_Dep}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Award
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_Award1}
                        onChange={(e) => {
                          setAuto_Award1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_Award1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Funds provided (INR in lakhs)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_Funds}
                        onChange={(e) => {
                          setAuto_Funds(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_Funds}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of the project
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_Project}
                        onChange={(e) => {
                          setAuto_Project(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_Project}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_Desc7}
                        onChange={(e) => setAuto_Desc7(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_Desc7}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload3 "
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_321?.Auto_Link7}
                        onChange={(e) => setAuto_Link7(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_321?.Auto_Link7}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit3(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_321(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.3.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the workshop/ seminar
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_332?.Auto_Seminar}
                        onChange={(e) => {
                          setAuto_Seminar(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_332?.Auto_Seminar}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of the Participants
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_332?.Auto_Parti}
                        onChange={(e) => {
                          setAuto_Parti(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_332?.Auto_Parti}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date (From)
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_332?.Auto_DFrom}
                        onChange={(e) => {
                          setAuto_DFrom(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_332?.Auto_DFrom}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Date (To)
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_332?.Auto_DTo}
                        onChange={(e) => {
                          setAuto_DTo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_332?.Auto_DTo}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        rows={3}
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_332?.Auto_Desc8}
                        onChange={(e) => setAuto_Desc8(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_332?.Auto_Desc8}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_332?.Auto_Link8}
                        onChange={(e) => setAuto_Link8(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_332?.Auto_Link8}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit4(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_332(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.4.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of paper
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_Paper}
                        onChange={(e) => {
                          setAuto_Paper(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_Paper}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the author/s
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_Author}
                        onChange={(e) => {
                          setAuto_Author(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_Author}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of the teacher
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_Dept}
                        onChange={(e) => {
                          setAuto_Dept(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_Dept}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of journal
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_Journal}
                        onChange={(e) => {
                          setAuto_Journal(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_Journal}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of publication
                  </label>
                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_Pub}
                        onChange={(e) => {
                          setAuto_Pub(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_Pub}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ISSN number
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_ISSN}
                        onChange={(e) => {
                          setAuto_ISSN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_ISSN}</>
                  )}
                </div>
                <h6>
                  Link to the recognition in UGC enlistment of the Journal{" "}
                </h6>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_ISIT}
                        onChange={(e) => {
                          setAuto_ISIT(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_ISIT}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_Desc7}
                        onChange={(e) => setAuto_Desc9(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_Desc9}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to website of the Journal
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload5"
                        id="upload5 "
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_Link9}
                        onChange={(e) => setAuto_Link9(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_Link9}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to article/paper/abstract of the article
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload6"
                        id="upload6 "
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_343?.Auto_Link10}
                        onChange={(e) => setAuto_Link10(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_343?.Auto_Link10}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit5(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_343(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.4.4-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.4
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_SLno}
                        onChange={(e) => {
                          setAuto_SLno(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_SLno}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_Name}
                        onChange={(e) => {
                          setAuto_Name(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_Name}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the book/chapters published
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_Book}
                        onChange={(e) => {
                          setAuto_Book(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_Book}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the paper
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_Paper1}
                        onChange={(e) => {
                          setAuto_Paper1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_Paper1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the proceedings of the conference
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_Title4}
                        onChange={(e) => {
                          setAuto_Title4(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_Title4}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of publication
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_Year2}
                        onChange={(e) => {
                          setAuto_Year2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_Year2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ISBN/ISSN number of the proceeding
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_ISBN}
                        onChange={(e) => {
                          setAuto_ISBN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_ISBN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the publisher
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_Name1}
                        onChange={(e) => {
                          setAuto_Name1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_Name1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </label>

                  {edit6 ? (
                    <>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Auto_Criteria03_344?.Auto_Whether}
                        onChange={(e) => setAuto_Whether(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_Whether}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_Desc10}
                        onChange={(e) => setAuto_Desc10(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_Desc10}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to website of the Journal
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload7"
                        id="upload7 "
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_344?.Auto_Link11}
                        onChange={(e) => setAuto_Link11(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_344?.Auto_Link11}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit6(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_344(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.5.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.5.1 & 3.5.2
                </span>
              </div>
              <div className="row  m-0 ">
                <h6 className="text-center text-decoration-underline">
                  Total amount generated from consultancy and corporate training
                  year wise{" "}
                </h6>

                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher-consultants
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Consult}
                        onChange={(e) => {
                          setAuto_Consult(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Consult}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of consultancy project/corporate training program
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Project1}
                        onChange={(e) => {
                          setAuto_Project1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Project1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Consulting/Sponsoring agency with contact details
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Agency1}
                        onChange={(e) => {
                          setAuto_Agency1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Agency1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Revenue generated (INR in Lakhs)
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Revenue}
                        onChange={(e) => {
                          setAuto_Revenue(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Revenue}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of trainees
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Trainee}
                        onChange={(e) => {
                          setAuto_Trainee(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Trainee}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  Total amount spent on developing facilities, training teachers
                  and staff for undertaking consultancy during the last five
                  years{" "}
                </h6>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Names of the teachers/staff
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Teacher1}
                        onChange={(e) => {
                          setAuto_Teacher1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Teacher1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the facilities developed and department
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Facility1}
                        onChange={(e) => {
                          setAuto_Facility1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Facility1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Agency seeking training with contact details
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Agen1}
                        onChange={(e) => {
                          setAuto_Agen1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Agen1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of consultancy
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Consult2}
                        onChange={(e) => {
                          setAuto_Consult2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Consult2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total amount spent (INR in Lakhs)
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Total1}
                        onChange={(e) => {
                          setAuto_Total1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Total1}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Desc11}
                        onChange={(e) => setAuto_Desc11(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Desc11}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to website of the Journal
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload8"
                        id="upload8"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_351?.Auto_Link12}
                        onChange={(e) => setAuto_Link12(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_351?.Auto_Link12}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit7(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_351(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.6.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.6.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_362?.Auto_Name2}
                        onChange={(e) => {
                          setAuto_Name2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_362?.Auto_Name2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Award/ recognition
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_362?.Auto_Name3}
                        onChange={(e) => {
                          setAuto_Name3(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_362?.Auto_Name3}</>
                  )}
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awarding government/ government recognised
                    bodies
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_362?.Auto_Name4}
                        onChange={(e) => {
                          setAuto_Name4(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_362?.Auto_Name4}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of award
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_362?.Auto_Year4}
                        onChange={(e) => {
                          setAuto_Year4(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_362?.Auto_Year4}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit8 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_362?.Auto_Desc12}
                        onChange={(e) => setAuto_Desc12(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_362?.Auto_Desc12}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to website of the Journal
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload9"
                        id="upload9"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_362?.Auto_Link13}
                        onChange={(e) => setAuto_Link13(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_362?.Auto_Link13}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit8(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_362(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.6.3-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.6.3 & 3.6.4
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_363?.Auto_Name5}
                        onChange={(e) => {
                          setAuto_Name5(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_363?.Auto_Name5}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Organising unit/ agency/ collaborating agency
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_363?.Auto_org}
                        onChange={(e) => {
                          setAuto_org(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_363?.Auto_org}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scheme
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_363?.Auto_Scheme}
                        onChange={(e) => {
                          setAuto_Scheme(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_363?.Auto_Scheme}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of the activity
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_363?.Auto_Year5}
                        onChange={(e) => {
                          setAuto_Year5(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_363?.Auto_Year5}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students participated in such activities
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria03_363?.Auto_Participants1
                        }
                        onChange={(e) => {
                          setAuto_Participants1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_363?.Auto_Participants1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit9 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_363?.Auto_Desc13}
                        onChange={(e) => setAuto_Desc13(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_363?.Auto_Desc13}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to website of the Journal
                  </label>

                  {edit9 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload10"
                        id="upload10"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_363?.Auto_Link14}
                        onChange={(e) => setAuto_Link14(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_363?.Auto_Link14}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit9(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_363(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.7.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_371?.Auto_SLNO1}
                        onChange={(e) => {
                          setAuto_SLNO1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_371?.Auto_SLNO1}</>
                  )}
                </div>
                <div className="col-md-8 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the collaborating agency with contact details
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_371?.Auto_Agency2}
                        onChange={(e) => {
                          setAuto_Agency2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_371?.Auto_Agency2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the collaborative activity
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria03_371?.Auto_Colloborative
                        }
                        onChange={(e) => {
                          setAuto_Colloborative(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_371?.Auto_Colloborative}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the participant
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.Auto_Criteria03_371?.Auto_Participants3
                        }
                        onChange={(e) => {
                          setAuto_Participants3(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_371?.Auto_Participants3}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of collaboration
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_371?.Auto_Collo}
                        onChange={(e) => {
                          setAuto_Collo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_371?.Auto_Collo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_371?.Auto_Durat}
                        onChange={(e) => {
                          setAuto_Durat(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_371?.Auto_Durat}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of the activity
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_371?.Auto_Activity}
                        onChange={(e) => {
                          setAuto_Activity(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_371?.Auto_Activity}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit10 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_371?.Auto_Desc14}
                        onChange={(e) => setAuto_Desc14(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_371?.Auto_Desc14}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Link to website of the Journal
                  </label>

                  {edit10 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload11"
                        id="upload11"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_371?.Auto_Link15}
                        onChange={(e) => setAuto_Link15(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_371?.Auto_Link15}</>
                  )}
                </div>

                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit10(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_371(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* ------3.7.2-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.7.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Organisation with which MoU is signed
                  </label>

                  {edit11 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_372?.Auto_Organ}
                        onChange={(e) => {
                          setAuto_Organ(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_372?.Auto_Organ}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the institution/ industry/ corporate house
                  </label>

                  {edit11 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_372?.Auto_IIC}
                        onChange={(e) => {
                          setAuto_IIC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_372?.Auto_IIC}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of signing MoU
                  </label>

                  {edit11 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_372?.Auto_Signing}
                        onChange={(e) => {
                          setAuto_Signing(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_372?.Auto_Signing}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>

                  {edit11 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_372?.Auto_Durat1}
                        onChange={(e) => {
                          setAuto_Durat1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_372?.Auto_Durat1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    List the actual activities under each MOU year-wise
                  </label>

                  {edit11 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_372?.Auto_List}
                        onChange={(e) => {
                          setAuto_List(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_372?.Auto_List}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit11 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Auto_Criteria03_372?.Auto_Desc15}
                        onChange={(e) => setAuto_Desc15(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_372?.Auto_Desc15}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit11 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload12"
                        id="upload12"
                        accept="image/*"
                        placeholder={Value?.Auto_Criteria03_372?.Auto_Link16}
                        onChange={(e) => setAuto_Link16(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Auto_Criteria03_372?.Auto_Link16}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div
                      className="d-flex "
                      style={{
                        float: "right",
                        gap: "10px",
                        marginLeft: "35rem",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit11(true)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editAuto_Criteria03_372(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_312?.Auto_Desc5}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_313?.Auto_Desc6}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_321?.Auto_Desc7}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_332?.Auto_Desc8}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_343?.Auto_Desc9}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_344?.Auto_Desc10}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_351?.Auto_Desc11}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_8 */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_362?.Auto_Desc12}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_9 */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_363?.Auto_Desc13}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_10 */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_371?.Auto_Desc14}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_11 */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria03_372?.Auto_Desc15}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 Modal */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_312?.Auto_Link5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 Modal */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_313?.Auto_Link6}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 Modal */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_321?.Auto_Link7}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 Modal */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_332?.Auto_Link8}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 Modal */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_343?.Auto_Link9}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 Modal */}
        <Modal show={show17} onHide={handleClose17}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_343?.Auto_Link10}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 Modal */}
        <Modal show={show18} onHide={handleClose18}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_344?.Auto_Link11}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 Modal */}
        <Modal show={show19} onHide={handleClose19}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_351?.Auto_Link12}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 9 Modal */}
        <Modal show={show20} onHide={handleClose20}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_362?.Auto_Link13}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 10 Modal */}
        <Modal show={show21} onHide={handleClose21}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_363?.Auto_Link14}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 11 Modal */}
        <Modal show={show22} onHide={handleClose22}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_371?.Auto_Link15}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 12 Modal */}
        <Modal show={show23} onHide={handleClose23}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_372?.Auto_Link16}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Auto_Criteria03;
