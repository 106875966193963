import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function SSRreportsList() {
  const navigate = useNavigate();
  const [SSRreport, setSSRreport] = useState([]);
  const getSSRFinalReports = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/pri/SSRDetails");
      if (res.status === 200) {
        setSSRreport(res.data.reports);
      }
    } catch (error) {
      console.log(error);
      setSSRreport(error.response.data.reports);
    }
  };

  useEffect(() => {
    getSSRFinalReports();
  }, []);
  return (
    <div style={{ padding: "20px" }}>
      <Button onClick={() => navigate("/aaraddrepoprt")}>
        Generate SSR Report
      </Button>
      <div style={{ textAlign: "center" }}>
        <Table>
          <thead>
            <th>Year</th>
            <th>View</th>
          </thead>
          <tbody>
            {SSRreport?.map((item) => {
              return (
                <tr>
                  <td>{`${item.startDate}-${item.endDate}`}</td>
                  <td>
                    <FaEye
                      style={{ fontSize: "25px" }}
                      onClick={() => {
                        navigate("/ssrviewreport", {
                          state: {
                            startYearReport: item.startDate,
                            endYearReport: item.endDate,
                            itemDetails: item,
                          },
                        });
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default SSRreportsList;
