import axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import PG6623aqar from "./PG6623aqar";
import PG6632aqar from "./PG6632aqar";
import PG6633aqar from "./PG6633aqar";
import PG6634aqar from "./PG6634aqar";
import PG6653aqar from "./PG6653aqar";
import PG6642aqar from "./PG6642aqar";
import PG6612aqar from "./PG6612aqar";
import PG6611aqar from "./PG6611aqar";

const PGC6aqar = React.memo(({ startYeardata, endYeardata }) => {
  const navigate = useNavigate();

  const [pg6Details, setpg6Details] = useState([]);
  const pgCriteria06Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/pgadmin/getpgcri6"
      );
      if (res.status === 200) {
        setpg6Details(
          res.data.pgcriteria06?.filter(
            (item) =>
              item?.Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.pgcriteria06?.filter(
          (item) =>
            item?.Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setpg6Details(error.response.data.pgcriteria06);
    }
  };

  console.log("pg6Details: ", pg6Details);

  useEffect(() => {
    pgCriteria06Details();
  }, [startYeardata, endYeardata]);
  return (
    <div>
      <div className="text-center">
        <b>Criteria 6</b>
      </div>
      {pg6Details?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.1.1)
            </div>
            <PG6611aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.1.2)
            </div>
            <PG6612aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.2.3)
            </div>
            <PG6623aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.3.2)
            </div>
            <PG6632aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.3.3)
            </div>
            <PG6633aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.3.4)
            </div>
            <PG6634aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.4.2)
            </div>
            <PG6642aqar item={item} />
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 6 (6.5.3)
            </div>
            <PG6653aqar item={item} />
          </div>
        );
      })}
    </div>
  );
});

export default PGC6aqar;
