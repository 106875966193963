import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function About_us() {
  const location = useLocation();
  const { id } = location?.state;
  // =================== calling api for getting the aboutus content======================
  const [AboutusContent, setAboutusContent] = useState([]);
  const getaboutusContent = async () => {
    let res = await axios.get(
      "https://brightnaac.co.in/api/admin/getAboutusDetails"
    );
    if (res.status === 200) {
      setAboutusContent(res.data.allContent);
    }
  };

  // =================== calling api for getting the aboutus content======================
  const [valFrameContent, setvalFrameContent] = useState([]);
  const getvalFrameContent = async () => {
    let res = await axios.get(
      "https://brightnaac.co.in/api/admin/getvalFrameDetails"
    );
    if (res.status === 200) {
      setvalFrameContent(res.data.allContent);
    }
  };

  // =================== calling api for getting the Leadership content======================
  const [LeadershipDetails, setLeadershipDetails] = useState([]);
  const getLeadershipContent = async () => {
    let res = await axios.get(
      "https://brightnaac.co.in/api/admin/getleadershipDetails"
    );
    if (res.status === 200) {
      setLeadershipDetails(res.data.allContent);
    }
  };

  // =================== calling api for getting the Leadership content======================
  const [CampusDetails, setCampusDetails] = useState([]);
  const getCampusContent = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getCampusDetails"
      );
      if (res.status === 200) {
        setCampusDetails(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getaboutusContent();
    getvalFrameContent();
    getLeadershipContent();
    getCampusContent();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [id]);

  return (
    <>
      <div className="about_us">
        <div
          className="ab-0"
          style={{
            backgroundImage: `url(https://brightnaac.co.in/Aboutus/${AboutusContent[0]?.img})`,
            height: "250px",
            position: "center",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="edg container">
            <div className="edgt-has-inline-style">About us</div>
            <div class="edgt-breadcrumbs-info">
              <div
                itemProp="breadcrumb"
                classname="edgt-breadcrumbs "
                style={{ color: "#e1e0e0" }}
              >
                <a
                  itemProp="url"
                  href="/"
                  style={{ color: "#e1e0e0", textDecoration: "none" }}
                >
                  Home
                </a>
                <span classname="edgt-delimiter">&nbsp; / &nbsp;</span>
                <span classname="edgt-current">About</span>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../gallery/logob.png"
          class="img-logo"
          style={{ color: "white" }}
        />
        <section id="demo">
          <Container>
            <div className="content">
              <h4 className="text-center">{AboutusContent[0]?.topic}</h4>
              <p className="educat">
                <span className="capital">
                  {" "}
                  {AboutusContent[0]?.body?.slice(0, 1)}
                </span>
                {AboutusContent[0]?.body?.slice(1)}
              </p>
            </div>
          </Container>
        </section>
        <section id="VM1">
          <div className="vi-0">
            <Container>
              <div className="v-0">
                <Row>
                  <Col md={6}>
                    <div
                      className="rgv"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <div className="rainbow rgh">
                        <div className="bdm">Vision</div>
                        <p>{AboutusContent[0]?.vision}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div
                      className="rgv"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <div className="rainbow rgh">
                        <div className="bdm">Mission</div>
                        <p>{AboutusContent[0]?.mission}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </section>
        <section id="VF1">
          <div
            className="commited"
            data-aos="fade-up"
            data-aos-duration="2000"
            style={{ marginBottom: "-20px" }}
          >
            <Container>
              <Row>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12" md={6}>
                  <div className="edgt-eh-item-inner">
                    <div className="edgt-eh-custom-2737">
                      <div className="edgt-st-inner">
                        <h4 className="edgt-st-title ">Value FrameWork</h4>
                        <p
                          className="edgt-st-text"
                          style={{
                            // color: "#888",
                            fontSize: 18,
                            marginTop: 20,
                            textAlign: "justify",
                          }}
                        >
                          {valFrameContent[0]?.valFrameBody}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <Col md={6}>
                  <div className="committed-group">
                    <img
                      src={`https://brightnaac.co.in/ValueFrameWork/${valFrameContent[0]?.valFrameImg}`}
                      alt=""
                      style={{ width: "100%", maxHeight: "475px" }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section id="LD1">
          <div className="ab-sec" data-aos="fade-up" data-aos-duration="2000">
            {LeadershipDetails?.map((item) => {
              return (
                <Container>
                  <div className="content">
                    <h4 className="text-center mb-3">LEADERSHIP</h4>
                    <div className="row mb-5 ">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <div className="edgt-eh-item-inner">
                          <div className="edgt-eh-custom-2737">
                            <div className="edgt-st-inner">
                              <p
                                className="edgt-st-text"
                                style={{
                                  fontSize: 18,
                                  // marginTop: 20,
                                  padding: "10px 10px ",
                                  textAlign: "justify",
                                }}
                              >
                                {item?.leadershipBody}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="committed-group">
                          <img
                            src={`https://brightnaac.co.in/Leadership/${item?.leadershipImg}`}
                            alt=""
                            className="img-fluid p-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              );
            })}
          </div>
        </section>
        {/* <section>
          <div className="container">
            <h4 className="text-center mb-3 ">ORGANISATION</h4>
            <div className="fs-3 text-center border p-3 mb-5">
              <Link>Click here to see organisation chart</Link>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="cs"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="m-2 ">
                  <Link to="#" className=" btn btn-primary">
                    {" "}
                    Academic
                  </Link>
                </div>

                <div className="m-2">
                  <Link to="#" className=" btn btn-primary">
                    {" "}
                    Administration
                  </Link>
                </div>

                <div className="m-2">
                  <Link to="#" className=" btn btn-primary">
                    {" "}
                    Finance
                  </Link>
                </div>

                <div className="m-2">
                  <Link to="#" className=" btn btn-primary">
                    {" "}
                    Former Chairpersons
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="m-2">
                    <Link to="#" className=" btn btn-primary">
                      {" "}
                      Former Presidents
                    </Link>
                  </div>
                  <div className="m-2">
                    <Link to="#" className=" btn btn-primary">
                      {" "}
                      Former Directors
                    </Link>
                  </div>
                  <div className="m-2">
                    <Link to="#" className=" btn btn-primary">
                      {" "}
                      Committees
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section id="CM1">
          <div className="container">
            <h4 className="text-center mb-3">CAMPUS</h4>
            <div>
              <figure>
                <img
                  src={`https://brightnaac.co.in/Campus/${CampusDetails[0]?.CampusImg1}`}
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p
                  className="edgt-st-text"
                  style={{
                    fontSize: 18,
                    // marginTop: 20,
                    padding: "10px 10px ",
                    textAlign: "justify",
                  }}
                >
                  {CampusDetails[0]?.CampusBody}
                </p>
              </div>

              <div className="col-md-4">
                <div>
                  <figure>
                    <img
                      src={`https://brightnaac.co.in/Campus/${CampusDetails[0]?.CampusImg2}`}
                      className="img-fluid"
                    />
                  </figure>
                </div>

                <div className="fs-6 ">
                  {CampusDetails[0]?.linksDescription}
                </div>

                <ul>
                  {CampusDetails[0]?.linksAvail?.map((item1) => {
                    return (
                      <li>
                        <Link to={item1?.link} className="text-decoration-none">
                          {" "}
                          <li>{item1?.linkName}</li>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div></div>
          </div>
        </section>
      </div>
    </>
  );
}

export default About_us;
