import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { BsArrowUp } from "react-icons/bs";
import "../AdminPanel/Css/dashboard.css";
import axios from "axios";
function CollegeAdmindashboard() {
  let approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [collegeApp, setCollegeApp] = useState([]);
  const getallAppColleges = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/app/approvedlist"
      );
      if (res.status === 200) {
        setCollegeApp(res.data.Register);
      }
    } catch (error) {
      console.log(error);
      setCollegeApp(error.response.data.Register);
    }
  };

  // =================== calling api for getting the Course content======================
  const [CoursesList, setCoursesList] = useState([]);
  const getCourseList = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getCourseDetails"
      );
      if (res.status === 200) {
        setCoursesList(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =================== calling api for getting the Event content======================
  const [eventDetails, seteventDetails] = useState([]);
  const getEventContent = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getEvents");
      if (res.status === 200) {
        seteventDetails(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // ==========================================
  const [Faculty, setFacultyAdded] = useState([]);
  const getallregisteruser = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/paf/getallpusers"
      );
      if (res.status === 200) {
        setFacultyAdded(res.data.AddFaculty);
      }
    } catch (error) {
      console.log(error);
      setFacultyAdded(error.response.data.AddFaculty);
    }
  };

  // ================ calling api to get all final reports list ===============================

  const [finalReportList, setfinalReportList] = useState([]);
  const getAllFinalReports = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/pri/getAllReports"
      );
      if (res.status === 200) {
        setfinalReportList(res.data.getFinalReports);
      }
    } catch (error) {
      console.log(error);
      setfinalReportList(error.response.data.getFinalReports);
    }
  };

  const [Student, setStudent] = useState([]);
  const getallstudentdata = async () => {
    try {
      const response = await axios.get(
        "https://brightnaac.co.in/api/student/studentsdetails"
      );
      if (response.status === 200) {
        setStudent(response.data.success);
      }
    } catch (error) {
      alert("no data present");
    }
  };

  // ==========================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getallregisteruser();
      getAllFinalReports();
      getallstudentdata();
    }
  }, []);

  return (
    <>
      <div className="ad-dash mt-5">
        <div className="container">
          <div className="vina">
            <div className="np-p mt-4">
              <div className="row">
                <div className="col-md-4">
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: "18px",
                          fontFamily: "sans-serif",
                          fontWeight: "bold",
                          borderBottom: "2px solid ",
                          textAlign: "center",
                        }}
                      >
                        Total Student Registered
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted text-center">
                        {Student?.length}
                      </Card.Subtitle>
                      <Card.Text>
                        Where Dreams Take Flight, and Journeys Begin
                      </Card.Text>
                      <Card.Link href="/studentdeglist">View</Card.Link>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4">
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: "18px",
                          fontFamily: "sans-serif",
                          fontWeight: "bold",
                          borderBottom: "2px solid ",
                          textAlign: "center",
                        }}
                      >
                        Total Faculty Registered
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted text-center">
                        {
                          Faculty?.filter((ele) => ele.ftype === "Faculty")
                            ?.length
                        }
                      </Card.Subtitle>
                      <Card.Text>
                        Serving as Pillars of Education, Building Brighter
                        Futures
                      </Card.Text>
                      <Card.Link href="/addfaculty">View</Card.Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-md-4">
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: "18px",
                          fontFamily: "sans-serif",
                          fontWeight: "bold",
                          borderBottom: "2px solid ",
                          textAlign: "center",
                        }}
                      >
                        Total Librarian Registered
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted text-center">
                        {
                          Faculty?.filter((ele) => ele.ftype === "Library")
                            ?.length
                        }
                      </Card.Subtitle>
                      <Card.Text>
                        Advocating for Access to Knowledge, Promoting Lifelong
                        Learning
                      </Card.Text>
                      <Card.Link href="/addfaculty">View</Card.Link>
                    </Card.Body>
                  </Card>
                </div>
              </div>

              <div className="row mt-5">
                <div
                  className="col-sm-6"
                  style={{
                    overflow: "hidden",
                    height: "250px",
                    overflowY: "scroll",
                  }}
                >
                  <h6>Faculty List</h6>
                  <Table>
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Faculty?.filter((ele) => ele.ftype === "Faculty")?.map(
                        (item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{item?.FName}</td>
                              <td>{item?.Email}</td>
                              <td>{item?.PNumber}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    overflow: "hidden",
                    height: "250px",
                    overflowY: "scroll",
                  }}
                >
                  <h6>Librarian List</h6>
                  <Table>
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Faculty?.filter((ele) => ele.ftype === "Library")?.map(
                        (item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{item?.FName}</td>
                              <td>{item?.Email}</td>
                              <td>{item?.PNumber}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollegeAdmindashboard;
