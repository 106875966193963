import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap/cjs";

function Librarydashboard1() {
  const [PG_Criteria04, setPG_Criteria04] = useState([]);
  const getpgcri04details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getpgcriteria04"
      );
      if (res.status === 200) {
        setPG_Criteria04(res.data.pgcriteria04);
      }
    } catch (error) {
      console.log(error);
      setPG_Criteria04(error.response.pgcriteria04);
    }
  };
  const [UG_Criteria04, setUG_Criteria04] = useState([]);
  const getugcri04details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getugcriteria04"
      );
      if (res.status === 200) {
        setUG_Criteria04(res.data.ugcriteria04);
      }
    } catch (error) {
      console.log(error);
      setUG_Criteria04(error.response.ugcriteria04);
    }
  };

  useEffect(() => {
    getpgcri04details();
    getugcri04details();
  }, []);

  return (
    <>
      <div className="ad-dash mt-5">
        <div className="container">
          <div className="vina">
            <div className="np-p mt-4">
              <div className="row">
                <div className="col-md-4">
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: "18px",
                          fontFamily: "sans-serif",
                          fontWeight: "bold",
                          borderBottom: "2px solid ",
                          textAlign: "center",
                        }}
                      >
                        Total UnderGraduate
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted text-center">
                        {UG_Criteria04?.length}
                      </Card.Subtitle>
                      <Card.Text>
                        Influencing Undergraduate Education, Enhancing Learning
                        and Discovery
                      </Card.Text>
                      <Card.Link href="/ugcriteria04">View</Card.Link>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4">
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontSize: "18px",
                          fontFamily: "sans-serif",
                          fontWeight: "bold",
                          borderBottom: "2px solid ",
                          textAlign: "center",
                        }}
                      >
                        Total PostGraduate
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted text-center">
                        {PG_Criteria04?.length}
                      </Card.Subtitle>
                      <Card.Text>
                        Inspiring Postgraduate Innovation, Strengthening
                        Institutional Impact
                      </Card.Text>
                      <Card.Link href="/pgcriteria04">View</Card.Link>
                    </Card.Body>
                  </Card>
                </div>

                {/* <div className="col-md-4">
              <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: "18px",
                        fontFamily: "sans-serif",
                        fontWeight: "bold",
                        borderBottom: "2px solid ",
                        textAlign: "center",
                      }}
                    >
                      Total Teacher Feedback
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted text-center">
                      9
                    </Card.Subtitle>
                    <Card.Text>
                    Leading the Way in Teacher Development, Empowering Student Progress
                    </Card.Text>
                    <Card.Link href="/teacherfeedbacklist">View</Card.Link>
                  </Card.Body>
                </Card>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Librarydashboard1;
