import axios from "axios";
import React, { useEffect, useState } from "react";
import UG7777aqar from "./UG7777aqar";
import { useNavigate } from "react-router-dom";
const UGC7aqar = ({ startYeardata, endYeardata }) => {
  const [AllDetails3, setAllDetails3] = useState([]);
  const navigate = useNavigate();
  const getCriteria07 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getcri07details"
      );
      if (res.status === 200) {
        setAllDetails3(
          res.data.ugcriteria07?.filter(
            (item) =>
              item?.Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.ugcriteria07?.filter(
          (item) =>
            item?.Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setAllDetails3(error.response.data.ugcriteria07);
    }
  };
  useEffect(() => {
    getCriteria07();
  }, [startYeardata, endYeardata]);
  return (
    <div>
      <div className="text-center">
        <b>Criteria 7</b>
      </div>
      {AllDetails3?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">Criteria : 7</div>
            <UG7777aqar item={item} />
          </div>
        );
      })}
    </div>
  );
};

export default UGC7aqar;
