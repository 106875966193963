import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const PDFprintUG3_333 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }

  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("l", "mm", [297, 210]);
    //  new jsPDF('landscape', "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("3.3.3.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);

  if (location?.state == null) {
    navigate("/UGCri03333");
  }
  console.log(location, "vvvvv");
  return (
    <div>
      <div className="m-auto">
        <div id="pdf">
          <div className="">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className="pdfimg pt-2 "
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                Year: {location?.state["0"]["Year"]}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state[location?.state?.length - 1]["criteria"]}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {location?.state["0"]["Criteria03_333"]["criteria"]}
              </div>
            </div>
            {finalArr?.map((item, i) => {
              return (
                <div
                  className="container-fluid report-pdf mt-5"
                  style={{ padding: "0px 100px" }}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Name of the activity</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria03_333?.NaActi}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Organising unit/ agency/ collaborating agency</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Criteria03_333?.Organ}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Name of the scheme</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Criteria03_333?.SchName}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Year of the activity</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Criteria03_333?.ActiYear}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>
                        Number of students participated in such activities
                      </span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Criteria03_333?.NStudents}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Description</span>
                    </div>
                    <div className="col-lg-12 report-text  mb-3">
                      <span> {item?.Criteria03_333?.Desc17}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>
                        <a href={`${item?.Criteria03_333?.Link18}`}>
                          {item?.Criteria03_333?.Link18}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="text-center">
          <Button className="ad" style={{}} onClick={createPDF}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PDFprintUG3_333;
