import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay } from "swiper";
import "../Component/Styles/Home.css";
import About from "../Component/About";
import NewsVideo from "../Component/NewsVideo";
import CommitedEducation from "../Component/CommitedEducation";
// import OwlCarousel from "react-owl-carousel";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactElasticCarousel from "react-elastic-carousel";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Home({ item }) {
  const navigate = useNavigate();
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 5 },
  ];

  const [SliderImage, setSliderImage] = useState([]);
  const getallsliderimage = async () => {
    let res = await axios.get(
      "https://brightnaac.co.in/api/admin/getallsliderimage"
    );
    if (res.status === 200) {
      setSliderImage(res.data.Simage);
    }
  };

  // =================== calling api for getting the Course content======================
  const [CoursesList, setCoursesList] = useState([]);
  const getCourseList = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getCourseDetails"
      );
      if (res.status === 200) {
        setCoursesList(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const imageUrl = `https://brightnaac.co.in/Homeslider/${item?.Image}`;
  useEffect(() => {
    getallsliderimage();
    getCourseList();
  }, []);
  return (
    <>
      <div className="dvsd">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >
          {SliderImage?.map((item) => {
            return (
              <SwiperSlide>
                <div className="cars-ss">
                  <div
                    className="carousel-slider kenburns-top"
                    style={{
                      backgroundImage: `url("https://brightnaac.co.in/Homeslider/${item?.Image} ")`,

                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      width: "100%",
                      height: "500px",
                    }}
                  >
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="tag-c">
                        <h2
                          // class="animate__animated animate__bounceInUp"
                          style={{ color: "white" }}
                        >
                          {" "}
                          {item?.topic}
                        </h2>
                        <p
                        //  class="animate__animated animate__bounceInUp ele"
                        >
                          {item?.body}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}

          {/* <SwiperSlide>
            <div className="cars-ss">
              <div
                className="carousel-slider kenburns-top"
                style={{
                  backgroundImage: "url('../../Image/h5-slide-2.jpg')",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  width: "100%",
                  height: "500px",
                }}
              >
                <div
                  className=""
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="tag-c">
                    <h1
                      class="animate__animated animate__bounceInUp"
                      style={{ color: "white" }}
                    >
                      {" "}
                      Supreme Language School
                    </h1>
                    <p class="animate__animated animate__bounceInUp ele">
                      A simply amazing opportunity to expand your knowledge
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>

      <About />
      {CoursesList?.filter((item1) => item1?.popular === true)?.length === 0 ? (
        <></>
      ) : (
        <section>
          <div className="pap-0 " data-aos="fade-up" data-aos-duration="2000">
            <section class="wrapper">
              <div class="container">
                <h4 className="headpc"> Popular Courses</h4>
                <div class="content">
                  <div class="container">
                    <ReactElasticCarousel
                      breakPoints={breakPoints}
                      enableAutoPlay
                    >
                      <div class="row">
                        {CoursesList?.filter(
                          (item1) => item1?.popular === true
                        )?.map((item) => {
                          return (
                            <div class="col-xs-12 col-sm-4">
                              <div
                                class="card h-100"
                                onClick={() =>
                                  navigate("/coursesingle", { state: item })
                                }
                              >
                                <a class="img-card">
                                  <img
                                    src={`https://brightnaac.co.in/Course/${item?.courseImg}`}
                                    alt="image"
                                    className="img-max"
                                  />
                                </a>
                                <div class="card-content">
                                  <h4 class="card-title">
                                    <a>{item?.courseName}</a>
                                  </h4>
                                  <p class="">
                                    {item?.courseDescription?.slice(0, 150)}
                                    ...
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </ReactElasticCarousel>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      )}
      <NewsVideo />
      <CommitedEducation />
    </>
  );
}

export default Home;
