import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

function PDFprintUG6_612() {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }

  console.log("finalArr", finalArr);
  // to print the pdf ----->
  const createPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("6.1.2.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);
  if (location?.state == null) {
    navigate("/UG_06_612");
  }
  return (
    <div>
      <div className="m-auto ">
        <div id="pdf">
          <div className="p-2">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className=" pdfimg pt-2"
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                Year:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.UGYear}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state[location?.state?.length - 1]?.criteria}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {/* {location?.state == null ? (
                <></>
              ) : (
                <>{location?.state["0"]["UgCriteria06_611"]["criteria"]}</>
              )} */}
              </div>
            </div>

            {finalArr?.map((item, i) => {
              return (
                <div
                  className="container-fluid report-pdf mt-5"
                  style={{ padding: "0px 100px" }}
                >
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Participative management</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.UGCriteria06_612?.ParticipativeMng}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Acadamic monitoring committee Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.UGCriteria06_612?.AcadamicMont}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Office Order Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.UGCriteria06_612?.OficeOrder}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Decentralisation_20-21 Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.UGCriteria06_612?.Decentralisation1}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Decentralisation_21-22 </span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.UGCriteria06_612?.Decentralisation2}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Decentralisation_2022-23 Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.UGCriteria06_612?.Decentralisation3}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>
                        Academic Monitoring Committee_2022-23 Document
                      </span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.UGCriteria06_612?.AcadamicMont1}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="text-center">
          <button className="ad" style={{}} onClick={createPDF}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default PDFprintUG6_612;
