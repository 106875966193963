import React from "react";

const UG3342aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Name of the institution/ industry/ corporate house with whom MoU is
            signed
          </span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria03_342?.NMou}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of signing MOU</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_342?.Mou}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Duration</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_342?.Durat}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            List the actual activities under each MOU and web links year-wise
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.Criteria03_342?.List}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria03_342?.Desc19}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria03/${item?.Criteria03_342?.Link20}`}
              target="blank_"
            >
              View Documents
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG3342aqar;
