import React from "react";

const PG2242aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B Super
            speciality/D.Sc./D.Litt.
          </span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria02_242?.PG_SerTeacher}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
            D.Litt.)
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_242?.PG_Qualify}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of obtaining the qualification</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_242?.PG_YOfQuali}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of Recognition as a Research Guide</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_242?.YOfRecog}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            {" "}
            Whether recognised as a Research Guide for Ph.D./D.M/M.Ch./D.N.B
            Super speciality/D.Sc./D’Lit.
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria02_242?.PG_Whether}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Is the teacher still serving the institution/If not last year of the
            service of Faculty to the Institution
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_242?.PG_IsThereTeacher}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the scholar</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_242?.PG_NameScholar}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Year of registration of the scholar</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_242?.PG_YearScholar}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Title of the thesis of the for scholar</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.PG_Criteria02_242?.PG_Thesis}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria02_242?.PG_Desc9}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/PG_Criteria02/${item?.PG_Criteria02_242?.PG_Link9}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG2242aqar;
