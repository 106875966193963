import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
function PDFprintA07() {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }
  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    console.log("ddkd1", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("ddkd2", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("ddkd3", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("ddkd4", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

    // const input = document.getElementById("pdf");
    // const options = { scrollY: -window.scrollY, useCORS: true };
    // const canvas = await html2canvas(input, options);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    // pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    pdf.save("7.0.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);
  if (location?.state == null) {
    navigate("/criteria7");
  }
  return (
    <div>
      <div className="m-auto ">
        <div id="pdf">
          <div className="p-2">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className="img-fluid mt-5"
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                year:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.Auto_Year}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state[location?.state?.length - 1]?.criteria}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>
                    {
                      location?.state["0"]["Auto_Criteria07_007"][
                        "Auto_criteria"
                      ]
                    }
                  </>
                )}
              </div>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ border: "1px solid black" }}>Sl.No</th>
                  <th style={{ border: "1px solid black" }}>Year</th>

                  <th style={{ border: "1px solid black" }}>
                    Total Lighting requirements
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Budget allocated for green initiatives
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Status of implementation of CBCS / Elective Course System
                    (ECS)
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Date and Duration
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Number of participants by Gender
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Power Requirement Renewable
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Initiative names
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Duration of the Initiative
                  </th>
                  <th style={{ border: "1px solid black" }}>Description</th>
                  <th style={{ border: "1px solid black" }}>
                    Relevant document
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Relevant document
                  </th>
                  <th style={{ border: "1px solid black" }}>
                    Relevant document
                  </th>
                </tr>
              </thead>
              <tbody>
                {finalArr?.map((item, i) => {
                  return (
                    <tr>
                      <td style={{ border: "1px solid black" }}>{++i}.</td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Year}
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_Lighting}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_Budget}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>
                              Physical Facilities
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Provision for Lift
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Ramp/ Rails
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Braille Software/Facilities
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Rest Rooms
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Scribes for Examination
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Special skill Development for Differently Abled
                              Students
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Any other Similar Facility
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Annual expenditure excluding salary component of
                              the institution
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of initiatives to address locational
                              Advantages and Disadvantages
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of initiatives taken to Engage with and
                              contribute to Local Community
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Date and Duration of the Initiative Name of the
                              Initiative Issues Addressed
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Power Requirement met by Renewable Energy Sources
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Total Power requirements
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Renewable Energy Source
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Renewable Energy Generated and Used
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Energy Supplied to the Grid
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Percentage Lighting through LED bulbs
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Percentage Lighting through other sources
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of Participating Students and Staff
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              No. of Activities
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              TTitle of the Program/Activity
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Number of Participants
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_PhyFac}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Prolift}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Ramp}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Braille}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_RestRoom}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Scribes}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_SpecialSkills}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_AnyOther}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Annual}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_NoOfIniti1}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_NoOfIniti2}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_DateDuration}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_Power}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_TPower}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_ReEnergyS}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_ReEnergyG}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_EnergySup}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_PercentageLED}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {
                                  item?.Auto_Criteria07_007
                                    ?.Auto_PercentageLight
                                }
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {
                                  item?.Auto_Criteria07_007
                                    ?.Auto_NoOfParticipants
                                }
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_NoOfActivities}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {item?.Auto_Criteria07_007?.Auto_TitlePro}
                              </td>
                              <td style={{ border: "1px solid black" }}>
                                {
                                  item?.Auto_Criteria07_007
                                    ?.Auto_NoOfParticipants1
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>From</th>
                            <th style={{ border: "1px solid black" }}>To</th>
                          </thead>
                          <tbody>
                            <td style={{ border: "1px solid black" }}>
                              {moment(
                                item?.Auto_Criteria07_007?.Auto_Date1
                              ).format("DD-MM-YYYY")}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {moment(
                                item?.Auto_Criteria07_007?.Auto_Date2
                              ).format("DD-MM-YYYY")}
                            </td>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        <Table responsive>
                          <thead>
                            <th style={{ border: "1px solid black" }}>MALE</th>
                            <th style={{ border: "1px solid black" }}>
                              FEMALE
                            </th>
                            <th style={{ border: "1px solid black" }}>Year</th>
                            <th style={{ border: "1px solid black" }}>Total</th>
                          </thead>
                          <tbody>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Auto_Criteria07_007?.Auto_Male}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Auto_Criteria07_007?.Auto_Female}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Auto_Criteria07_007?.Auto_Yea}
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              {item?.Auto_Criteria07_007?.Auto_Total}
                            </td>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_PowRe}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_InitiName}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_DurInitiative}
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        {item?.Auto_Criteria07_007?.Auto_Desc29}
                      </td>

                      <td>
                        <img
                          src={`https://brightnaac.co.in/Auto_Criteria07/${item?.Auto_Criteria07_007?.Auto_Link29}`}
                          alt="img"
                          width={50}
                        />
                      </td>

                      <td>
                        <img
                          src={`https://brightnaac.co.in/Auto_Criteria07/${item?.Auto_Criteria07_007?.Auto_Link30}`}
                          alt="img"
                          width={50}
                        />
                      </td>

                      <td>
                        <img
                          src={`https://brightnaac.co.in/Auto_Criteria07/${item?.Auto_Criteria07_007?.Auto_Link31}`}
                          alt="img"
                          width={50}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="text-center">
          <button className="ad" style={{}} onClick={createPDF}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default PDFprintA07;
