import React from "react";

const UG6653aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Regular meetings of the IQAC held</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_653?.iqac}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Conferences, Seminars, Workshops on quality conducted</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_653?.cswq}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Collaborative quality initiatives with other institution(s) (Provide
            name of the institution and activity)
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_653?.iaa}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Participation in NIRF along with Status.</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_653?.nirfs}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (from)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_653?.Sdate2}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (to)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_653?.Edate2}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>
            Any other quality audit as recognized by the State, National or
            International agencies (ISO certification, NBA and such others)
          </span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span> {item?.UgCriteria06_653?.nba}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.UgCriteria06_653?.ugdesc6}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria06/${item?.UgCriteria06_653?.doc9}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG6653aqar;
