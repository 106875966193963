import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
function PDFprintU07() {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }
  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    console.log("ddkd1", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("ddkd2", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("ddkd3", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("ddkd4", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

    // const input = document.getElementById("pdf");
    // const options = { scrollY: -window.scrollY, useCORS: true };
    // const canvas = await html2canvas(input, options);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    // pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    pdf.save("7.0.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);
  if (location?.state == null) {
    navigate("/u-criteria7");
  }
  return (
    <div>
      <div className="m-auto ">
        <div id="pdf">
          <div className="p-2">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className="img-fluid mt-5"
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                year:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.Uni_Year}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state[location?.state?.length - 1]?.criteria}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>
                    {location?.state["0"]["Uni_Criteria07_007"]["Uni_criteria"]}
                  </>
                )}
              </div>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Total Lighting requirements</th>
                  <th>Budget allocated for green initiatives:</th>
                  <th>Physical Facilities:</th>
                  <th>Provision for Lift:</th>
                  <th>Ramp/ Rails:</th>
                  <th>Braille Software/Facilities:</th>
                  <th>Rest Rooms:</th>
                  <th>Scribes for Examination</th>
                  <th>
                    Special skill Development for Differently Abled Students:
                  </th>
                  <th>Any other Similar Facility:</th>
                  <th>
                    Annual expenditure excluding salary component of the
                    institution
                  </th>
                  <th>
                    Number of initiatives to address locational Advantages and
                    Disadvantages
                  </th>
                  <th>
                    Number of initiatives taken to Engage with and contribute to
                    Local Community
                  </th>
                  <th>
                    Date and Duration of the Initiative Name of the Initiative
                    Issues Addressed
                  </th>
                  <th>Power Requirement met by Renewable Energy Sources</th>
                  <th>Total Power requirements</th>
                  <th>Renewable Energy Source:</th>
                  <th>Renewable Energy Generated and Used :</th>
                  <th>Energy Supplied to the Grid:</th>
                  <th>Percentage Lighting through LED bulbs:</th>
                  <th>Percentage Lighting through other sources</th>
                  <th>Number of Participating Students and Staff:</th>
                  <th>No. of Activities:</th>
                  <th>TTitle of the Program/Activity:</th>
                  <th>Number of Participants:</th>
                  <th>From:</th>
                  <th>To:</th>
                  <th>MALE:</th>
                  <th>FEMALE:</th>
                  <th>Year</th>
                  <th>Total</th>
                  <th>Power Requirement Renewable</th>
                  <th>Initiative names:</th>
                  <th>Duration_of_the_Initiative</th>
                  <th>Description (min 500 characters)</th>
                  <th>Document Upload Select file to upload:</th>
                  <th>Document Upload Select file to upload:</th>
                  <th>Document Upload Select file to upload:</th>
                </tr>
              </thead>
              <tbody>
                {finalArr?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Uni_Year}</td>

                      <td>{item?.Uni_Criteria07_007?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Lighting}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Budget}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PhyFac}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Prolift}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Ramp}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Braille}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_RestRoom}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Scribes}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_SpecialSkills}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_AnyOther}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Annual}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfIniti1}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfIniti2}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_DateDuration}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Power}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_TPower}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_ReEnergyS}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_ReEnergyG}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_EnergySup}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PercentageLED}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PercentageLight}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfParticipants}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfActivities}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_TitlePro}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_NoOfParticipants1}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Date1}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Date2}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Male}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Female}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Yea}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Total}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_PowRe}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_InitiName}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_DurInitiative}</td>
                      <td>{item?.Uni_Criteria07_007?.Uni_Desc29}</td>
                      <td>
                        <img
                          src={`https://brightnaac.co.in/Uni_Criteria07/${item?.Uni_Criteria07_007?.Uni_Link29}`}
                          alt="img"
                          width={50}
                        />
                      </td>

                      <td>
                        <img
                          src={`https://brightnaac.co.in/Uni_Criteria07/${item?.Uni_Criteria07_007?.Uni_Link30}`}
                          alt="img"
                          width={50}
                        />
                      </td>

                      <td>
                        <img
                          src={`https://brightnaac.co.in/Uni_Criteria07/${item?.Uni_Criteria07_007?.Uni_Link31}`}
                          alt="img"
                          width={50}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="text-center">
          <button className="ad" style={{}} onClick={createPDF}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default PDFprintU07;
