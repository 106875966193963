import React from "react";

const UG5521aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of student placed and contact details</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_521?.PlaName}</span>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Program graduated from</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_521?.Graduate}</span>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the employer with contact details</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_521?.ConDetails}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Pay package at appointment (In INR per annum)</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.Criteria05_521?.Pay}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.Criteria05_521?.Desc24}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            <a
              href={`https://brightnaac.co.in/UG_Criteria05/${item?.Criteria05_521?.Link24}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default UG5521aqar;
