import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Nav } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import { AiOutlineEye, AiOutlineRadarChart } from "react-icons/ai";
import exportFromJSON from "export-from-json";

const Criteria02 = () => {
  const navigate = useNavigate();
  let principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  console.log("principalDetails ", principalDetails);
  const [selectedYear, setselectedYear] = useState("");

  //integrating get method
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [View, setView] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosesm = () => setSmShow(false);
  const handleShowsm = () => setSmShow(true);

  // ================Show doc1 modal======================

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [Auto_Criteria02, setAuto_Criteria02] = useState([]);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getautocri02details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.autocriteria02);
        setAuto_Criteria02(res.data.autocriteria02);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria02);
    }
  };

  useEffect(() => {
    getContent();
  }, []);
  console.log(Auto_Criteria02);

  // ===========================================================

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };

  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaA2/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getContent();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                Autonomous_Criteria-02
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className="">
            <Nav defaultActiveKey="/pgcriteria02">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/A_02_211")}
                >
                  <Button className="criteriabtn  btn-sub1">2.1.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/A_02_212")}
                >
                  <Button className="criteriabtn  btn-sub">2.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_02_241")}
                >
                  <Button className="criteriabtn  btn-sub">
                    2.4.1 & 2.4.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_02_242")}
                >
                  <Button className="criteriabtn  btn-sub">2.4.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_02_251")}
                >
                  <Button className="criteriabtn  btn-sub">2.5.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_02_263")}
                >
                  <Button className="criteriabtn  btn-sub">2.6.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/A_02_271")}
                >
                  <Button className="criteriabtn btn-sub">2.7.1</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Number of seats sanctioned</th>
                  <th>Number of Students admitted</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>SC</th>
                  <th>ST</th>
                  <th>OBC</th>
                  <th>Gen</th>
                  <th>Divyangjan</th>
                  <th>Others</th>
                  <th>SSC</th>
                  <th>SST</th>
                  <th>OOBC</th>
                  <th>GGen</th>
                  <th>DDivyangjan</th>
                  <th>OOthers</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the Full-time teacher</th>
                  <th>PAN</th>
                  <th>Designation</th>
                  <th>Year of appointment</th>
                  <th>
                    Nature of appointment (Against Sanctioned post,
                    temporary,permanent)
                  </th>
                  <th>Name of the Department</th>
                  <th>Total years of Experience in the same institution</th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution.
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </th>
                  <th>
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </th>
                  <th>Year of obtaining the qualification</th>
                  <th>Year of Recognition as a Research Guide</th>
                  <th>
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </th>
                  <th>
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </th>
                  <th>Name of the scholar</th>
                  <th>Year of registration of the scholar</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Semester/ year</th>
                  <th>Program Code</th>
                  <th>
                    Last date of the last semester-end/ year- end examination
                  </th>
                  <th>
                    Date of declaration of results of semester-end/ year- end
                    examination
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    Number of students appeared in the final year examination
                  </th>
                  <th>Number of students passed in final year examination</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Name of the student</th>
                  <th>Gender</th>
                  <th>Category</th>
                  <th>State of Domicile</th>
                  <th>Nationality if othern than Indian</th>
                  <th>Email-ID</th>
                  <th>Mobile Number</th>
                  <th>Year of joining</th>
                  <th>
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {Auto_Criteria02?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    principalDetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "Pending" &&
                    // selectedYear === item1?.Auto_Year &&
                    (SearchItem === "" ||
                      item1?.Auto_Year?.includes(SearchItem) ||
                      item1?.Auto_Criteria02_211?.Auto_PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria02_211?.Auto_PrgCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_211?.Auto_NoSeatSanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_211?.Auto_NoStdAdmit?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria02_211?.Auto_Desc32?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Auto_Year}</td>
                      <td>{item?.Auto_Criteria02_211?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_211?.Auto_PrgName1}</td>
                      <td>{item?.Auto_Criteria02_211?.Auto_PrgCode}</td>
                      <td>
                        {item?.Auto_Criteria02_211?.Auto_NoSeatSanctioned}
                      </td>
                      <td>{item?.Auto_Criteria02_211?.Auto_NoStdAdmit}</td>

                      <td>
                        {item?.Auto_Criteria02_211?.Auto_Desc32?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            fontSize={20}
                          />
                        </td>
                      </td>

                      <td>{item?.Auto_Criteria02_212?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_SC}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_ST}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_OBC}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_Gen}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_Divyangjan}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_Others}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_SSC}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_SST}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_OOBC}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_GGen}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_DDivyangjan}</td>
                      <td>{item?.Auto_Criteria02_212?.Auto_OOthers}</td>

                      <td>
                        {item?.Auto_Criteria02_212?.Auto_Desc33?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_241?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_TName}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_PAN}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_Designation}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_YOfAppoint}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_NoOfAppoint}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_DeptName}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_TYofExp}</td>
                      <td>{item?.Auto_Criteria02_241?.Auto_TService}</td>

                      <td>
                        {item?.Auto_Criteria02_241?.Auto_Desc34?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_242?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_SerTeacher}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_Qualify}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_YOfQuali}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_YOfRecog}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_Whether}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_IsThereTeacher}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_NameScholar}</td>
                      <td>{item?.Auto_Criteria02_242?.Auto_YearScholar}</td>
                      <td>
                        {item?.Auto_Criteria02_242?.Auto_Desc35?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_251?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_pname1}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_semester}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_pcode1}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_last}</td>
                      <td>{item?.Auto_Criteria02_251?.Auto_Date}</td>
                      <td>
                        {item?.Auto_Criteria02_251?.Auto_Desc36?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow12();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_263?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_263?.Auto_PrgName2}</td>
                      <td>{item?.Auto_Criteria02_263?.Auto_pCode}</td>
                      <td>{item?.Auto_Criteria02_263?.Auto_NStdAppeared}</td>
                      <td>{item?.Auto_Criteria02_263?.Auto_NStdPassed}</td>

                      <td>
                        {item?.Auto_Criteria02_263?.Auto_Desc37?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Auto_Criteria02_271?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_PrgName3}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_NStd}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_Gender}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_Category}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_SDomicile}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_NIndian}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_Email}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_MNo}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_YJoining}</td>
                      <td>{item?.Auto_Criteria02_271?.Auto_UID}</td>
                      <td>
                        {item?.Auto_Criteria02_271?.Auto_Desc38?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-evenly align-items-center"
                          style={{ marginTop: "15px" }}
                        >
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                Autonomous_Criteria-02
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme name
                  </label>

                  <>{Value?.Auto_Criteria02_211?.Auto_PrgName1}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme code
                  </label>

                  <>{Value?.Auto_Criteria02_211?.Auto_PrgCode}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of seats sanctioned
                  </label>

                  <>{Value?.Auto_Criteria02_211?.Auto_NoSeatSanctioned}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students admitted
                  </label>

                  <>
                    <>{Value?.Auto_Criteria02_211?.Auto_NoStdAdmit}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria02_211?.Auto_Desc32}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria02_211?.Auto_Link32}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.1.2------ */}
                <h6 className="text-center text-decoration-underline">
                  Number of seats earmarked for reserved category as per GOI or
                  State Government rule
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_SC}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST{" "}
                  </label>

                  <>{Value?.Criteria02_212?.Auto_ST}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_OBC}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_Gen}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_Divyangjan}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_Others}</>
                </div>
                <h6 className="text-center text-decoration-underline mt-3">
                  Number of students admitted from the reserved category
                </h6>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    SC
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_SSC}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    ST
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_SST}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    OBC
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_OOBC}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gen
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_GGen}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Divyangjan
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_DDivyangjan}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Others
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_OOthers}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_Desc33}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria02_212?.Auto_Link33}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.1 & 2.4.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.1 & 2.4.3------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Full-time teacher
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_TName}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    PAN
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_PAN}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Designation
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_Designation}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of appointment
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_YOfAppoint}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Department
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_NoOfAppoint}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Nature of appointment (Against Sanctioned post, temporary,
                    permanent)
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_DeptName}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Total years of Experience in the same institution
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_TYofExp}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_TService}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_Desc34}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria02_241?.Auto_Link34}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.4.2------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of full time serving teacher with Ph.D./D.M/M.Ch./D.N.B
                    Super speciality/D.Sc./D.Litt.
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_SerTeacher}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Qualification (Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./
                    D.Litt.)
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_Qualify}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of obtaining the qualification
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_YOfQuali}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Recognition as a Research Guide
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_YOfRecog}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Whether recognised as a Research Guide for
                    Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit.
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_Whether}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Is the teacher still serving the institution/If not last
                    year of the service of Faculty to the Institution
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_IsThereTeacher}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scholar
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_NameScholar}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of registration of the scholar
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_YearScholar}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_Desc35}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria02_242?.Auto_Link35}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.5.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------2.5.1------ */}
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme name
                  </label>

                  <>{Value?.Auto_Criteria02_251?.Auto_pname1}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Semester/ year
                  </label>

                  <>{Value?.Auto_Criteria02_251?.Auto_semester}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Programme code
                  </label>

                  <>{Value?.Auto_Criteria02_251?.Auto_pcode1}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Last date of the last semester-end/ year- end examination
                  </label>

                  <>{Value?.Auto_Criteria02_251?.Auto_last}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date of declaration of results of semester-end/ year- end
                    examination
                  </label>

                  <>{Value?.Auto_Criteria02_251?.Auto_Date}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria02_251?.Auto_Desc36}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria02_251?.Auto_Link36}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.6.3
                </span>
              </div>
              <div className="row  m-0 ">
                {/* -------2.6.3------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  <>{Value?.Auto_Criteria02_263?.Auto_PrgName2}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  <>{Value?.Auto_Criteria02_263?.Auto_pCode}</>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students appeared in the final year examination
                    </label>

                    <>{Value?.Auto_Criteria02_263?.Auto_NStdAppeared}</>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students passed in final year examination
                  </label>

                  <>{Value?.Auto_Criteria02_263?.Auto_NStdPassed}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria02_263?.Auto_Desc37}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria02_263?.Auto_Link37}</>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-2.7.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_PrgName3}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Student Name
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_NStd}</>
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Gender
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_Gender}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Category
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_Category}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    State of Domicile
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_SDomicile}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Nationality if othern than Indian
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_NIndian}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Email-ID
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_Email}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Mobile Number
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_MNo}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of joining
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_YJoining}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Unique Enrolment ID / College ID/ University enrolment
                    number
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_UID}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_Desc38}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload6">
                    {" "}
                    Document
                  </label>

                  <>{Value?.Auto_Criteria02_271?.Auto_Link38}</>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  className="btn btn-success"
                  onClick={(e) => {
                    approveCriteria(e, true, Value?._id);
                    handleClose();
                  }}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  className="btn btn-danger"
                  onClick={() => setSmShow(true)}
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }
        <Modal
          // size="sm"
          show={smShow}
          onHide={() => handleClosesm()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              onChange={(e) => setremark(e.target.value)}
              style={{ height: "200px" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                approveCriteria(e, false, Value?._id);
                handleClose();
                handleClosesm();
              }}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_211?.Auto_Link32}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_212?.Auto_Link33}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_241?.Auto_Link34}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_242?.Auto_Link35}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_251?.Auto_Link36}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 6 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_263?.Auto_Link37}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 7 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Auto_Criteria02/${View?.Auto_Criteria02_271?.Auto_Link38}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 8 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_211?.Auto_Desc32}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 9 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_212?.Auto_Desc33}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose9}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 10 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_241?.Auto_Desc34}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 11 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_242?.Auto_Desc35}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 12 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_251?.Auto_Desc36}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose12}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 13 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_263?.Auto_Desc37}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose13}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 14 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Auto_Criteria02_271?.Auto_Desc38}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose14}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Criteria02;
