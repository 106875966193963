import React from "react";
import LibraryHeader from "./LibraryHeader";
import LibrarySideBar from "./LibrarySideBar";

function LibraryDashboard(props) {
  return (
    <div style={{ display: "flex", height: "100%", minHeight: "100vh" }}>
        <LibrarySideBar />
      <main className="container-fluid p-0" style={{}}>
        <div>
          <LibraryHeader />
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default LibraryDashboard;
