import React from 'react'
import "./CollegeAdmin.css"

function CollegeAdminLogin() {
  return (
   <>
   <div className='add'>
    <div className='container'>
       <div className='fw_90'>
       <div className='add_0'>
        <div className='im09'>
                <img src='./login.png' alt='login' className='di_90'/>
            </div>
            <div className='add-90'>
            <form>
                <div className='sd_00 mb-2'>
                      <label>Email</label> <br/>
                      <input type='text' placeholder='email@gmail.com' className='name_0'/>
                </div>
                <div className='sd_00 mb-2'>
                      <label>Password</label><br/>
                      <input type='password' placeholder='password' className='name_0'/>
                </div>
                <div className='sd_00 mt-2'>
                    <a className=' btn btn-primary' href='/dashboard'>Login</a>
                </div>
            </form>

            </div>
           
        </div>
       </div>
    </div>
   </div>
   </>
  )
}

export default CollegeAdminLogin