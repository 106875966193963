import moment from "moment";
import React from "react";

const PG3322aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Number of Participants</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria03_322?.PG_NPati}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (From)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {moment(item?.PG_Criteria03_322?.PG_FDate).format("DD-MM-YYYY")}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date (To)</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {moment(item?.PG_Criteria03_322?.PG_TDate).format("DD-MM-YYYY")}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the workshop/ seminar/ conference</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>{item?.PG_Criteria03_322?.PG_NWorkshop}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria03_322?.PG_Desc13}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {/* <a href={`${item?.PG_Criteria03_322?.PG_Link13}`}> */}
            {/* {item?.PG_Criteria03_322?.PG_Link13} */}
            {/* </a> */}

            <a
              href={`https://brightnaac.co.in/PG_Criteria03/${item?.PG_Criteria03_322?.PG_Link13}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG3322aqar;
