import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";

export default function Editiiqa() {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const location = useLocation();
  const { View } = location.state;
  console.log("View", View);

  const [allYears, setallYears] = useState([]);
  const [MainYear1, setMainYear1] = useState("");

  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  useEffect(() => {
    if (!pricipalDetails) {
      alert("Please Login first");
      window.location.assign("/login");
    } else {
      getAllYears();
    }
  }, []);

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  function validatename(inputtxt) {
    var phoneno = /^[a-zA-Z ]{2,30}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid name!");
      return false;
    }
  }

  function phonenumber(inputtxt) {
    var phoneno = /^[6-9]\d{9}$/; // var no = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    } else {
      alert("You have entered an invalid mobile number!");
      return false;
    }
  }

  const [selectedyear, setselectedyear] = useState("");
  const [Dateofsubmission, setDateofsubmission] = useState("");
  const [AISHEID, setAISHEID] = useState("");
  const [InstitutionTrackID, setInstitutionTrackID] = useState("");
  const [ApplicationFor, setApplicationFor] = useState("");
  const [Cyclename, setCyclename] = useState("");
  const [CycleNumber, setCycleNumber] = useState("");
  const [Cycledate, setCycledate] = useState("");
  const [Cyclegrade, setCyclegrade] = useState("");
  const [CollegeName, setCollegeName] = useState("");
  const [Collegeestablishmentdate, setCollegeestablishmentdate] = useState("");
  const [Collegeheadname, setCollegeheadname] = useState("");
  const [Designation, setDesignation] = useState("");
  const [OwnCampus, setOwnCampus] = useState("");
  const [Collegeaddress, setCollegeaddress] = useState("");
  const [CollegeState, setCollegeState] = useState("");
  const [CollegeDistrict, setCollegeDistrict] = useState("");
  const [CollegeCity, setCollegeCity] = useState("");
  const [CollegePin, setCollegePin] = useState("");
  const [CollegePhoneNo, setCollegePhoneNo] = useState("");
  const [CollegeFaxNo, setCollegeFaxNo] = useState("");
  const [CollegeMobileNo, setCollegeMobileNo] = useState("");
  const [CollegeRegisteredEmail, setCollegeRegisteredEmail] = useState("");
  const [CollegeAlternateEmail, setCollegeAlternateEmail] = useState("");
  const [AlternateFaculty, setAlternateFaculty] = useState("");
  const [ADesignation, setADesignation] = useState("");
  const [ACollegeaddress, setACollegeaddress] = useState("");
  const [ACollegeState, setACollegeState] = useState("");
  const [ACollegeCity, setACollegeCity] = useState("");
  const [ACollegePin, setACollegePin] = useState("");
  const [ACollegePhoneNo, setACollegePhoneNo] = useState("");
  const [ACollegeFaxNo, setACollegeFaxNo] = useState("");
  const [ACollegeMobileNo, setACollegeMobileNo] = useState("");
  const [ACollegeRegisteredEmail, setACollegeRegisteredEmail] = useState("");
  const [ACollegeAlternateEmail, setACollegeAlternateEmail] = useState("");
  const [Website, setWebsite] = useState("");
  const [Natureofthecollege, setNatureofthecollege] = useState("");
  const [CollegeAffiliation, setCollegeAffiliation] = useState("");
  const [Instc6yoexistence, setInstc6yoexistence] = useState("");
  const [graduationy1, setgraduationy1] = useState("");
  const [graduationy2, setgraduationy2] = useState("");
  const [affiliating_Uni_State, setaffiliating_Uni_State] = useState("");
  const [affiliating_Uni_Name, setaffiliating_Uni_Name] = useState("");
  const [affiliating_Uni_Doc, setaffiliating_Uni_Doc] = useState("");
  const [section2UGCAct, setsection2UGCAct] = useState("");
  const [section2UGCAct_Doc, setsection2UGCAct_Doc] = useState("");
  const [section12BUGCAct, setsection12BUGCAct] = useState("");
  const [section12BUGCAct_Doc, setsection12BUGCAct_Doc] = useState("");
  const [recognised_Aut_Clg, setrecognised_Aut_Clg] = useState("");
  const [CPE, setCPE] = useState("");
  const [College_Excellence, setCollege_Excellence] = useState("");
  const [recognised_SRA, setrecognised_SRA] = useState("");
  const [SRA_AIU, setSRA_AIU] = useState("");
  const [SRA_program, setSRA_program] = useState("");
  const [SRA_Document, setSRA_Document] = useState("");
  const [UG, setUG] = useState();
  const [PG, setPG] = useState();
  const [Ayurveda, setAyurveda] = useState();
  const [M_Phil, setM_Phil] = useState();
  const [PhD, setPhD] = useState();
  const [LLD, setLLD] = useState();
  const [PG_Diploma, setPG_Diploma] = useState();
  const [Diploma, setDiploma] = useState();
  const [Certificate, setCertificate] = useState();
  const [Programme_Array, setProgramme_Array] = useState([]);
  const [Programme_Doc, setProgramme_Doc] = useState("");
  const [Teaching_P_Male, setTeaching_P_Male] = useState();
  const [Teaching_P_Female, setTeaching_P_Female] = useState();
  const [Teaching_P_Transgender, setTeaching_P_Transgender] = useState();
  const [Teaching_T_Male, setTeaching_T_Male] = useState();
  const [Teaching_T_Female, setTeaching_T_Female] = useState();
  const [Teaching_T_Transgender, setTeaching_T_Transgender] = useState();
  const [N_Teaching_P_Male, setN_Teaching_P_Male] = useState();
  const [N_Teaching_P_Female, setN_Teaching_P_Female] = useState();
  const [N_Teaching_P_Transgender, setN_Teaching_P_Transgender] = useState();
  const [N_Teaching_T_Male, setN_Teaching_T_Male] = useState();
  const [N_Teaching_T_Female, setN_Teaching_T_Female] = useState();
  const [N_Teaching_T_Transgender, setN_Teaching_T_Transgender] = useState();
  const [Students_M, setStudents_M] = useState();
  const [Students_F, setStudents_F] = useState();
  const [Students_T, setStudents_T] = useState();
  const [committees, setcommittees] = useState();
  const [Date_establishment_IQAC, setDate_establishment_IQAC] = useState("");
  const [IQAC_meeting_Date, setIQAC_meeting_Date] = useState("");
  const [IQAC_meeting_Doc, setIQAC_meeting_Doc] = useState("");
  const [Date_submission_AQARs1, setDate_submission_AQARs1] = useState("");
  const [Date_submission_AQARs_Doc1, setDate_submission_AQARs_Doc1] =
    useState("");
  const [Date_submission_AQARs2, setDate_submission_AQARs2] = useState("");
  const [Date_submission_AQARs_Doc2, setDate_submission_AQARs_Doc2] =
    useState("");
  const [Date_submission_AQARs3, setDate_submission_AQARs3] = useState("");
  const [Date_submission_AQARs_Doc3, setDate_submission_AQARs_Doc3] =
    useState("");
  const [Date_submission_AQARs4, setDate_submission_AQARs4] = useState("");
  const [Date_submission_AQARs_Doc4, setDate_submission_AQARs_Doc4] =
    useState("");
  const [RTI_Act_2005, setRTI_Act_2005] = useState("");
  const [RTI_Act_2005_Doc, setRTI_Act_2005_Doc] = useState("");
  const [MoU_foreign, setMoU_foreign] = useState("");
  const [MHRD_Date, setMHRD_Date] = useState("");
  const [MHRD_Doc, setMHRD_Doc] = useState("");
  const [AttachCertification, setAttachCertification] = useState("");
  const [Registration_Fee, setRegistration_Fee] = useState();

  const [editProgramme_Array, seteditProgramme_Array] = useState(false);
  const [Program_Name, setProgram_Name] = useState("");
  const [Program_Department, setProgram_Department] = useState("");
  const [Program_University, setProgram_University] = useState("");
  const [Program_SRA, setProgram_SRA] = useState("");
  const [Program_Affiliation, setProgram_Affiliation] = useState("");

  const addPrograms = () => {
    if (
      !Program_Name ||
      !Program_Department ||
      !Program_University ||
      !Program_SRA ||
      !Program_Affiliation
    ) {
      alert("Please fill all the fields");
    } else {
      let obj = {
        Program_Name: Program_Name,
        Program_Department: Program_Department,
        Program_University: Program_University,
        Program_SRA: Program_SRA,
        Program_Affiliation: Program_Affiliation,
      };
      Programme_Array.push(obj);
      setProgram_Name("");
      setProgram_Department("");
      setProgram_University("");
      setProgram_SRA("");
      setProgram_Affiliation("");
    }
  };

  const handleMainBody = (e, editor) => {
    const data = editor.getData();
    setcommittees(data);
  };

  const handleMainBody1 = (e, editor) => {
    const data = editor.getData();
    setRegistration_Fee(data);
  };

  const editiiqa = async () => {
    try {
      const config = {
        url: "/pri/editIIQA",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Iiqa_ID: View?._id,
          CollegeId: pricipalDetails?._id,
          selectedyear: selectedyear ? selectedyear : View?.selectedyear,
          Dateofsubmission: Dateofsubmission
            ? Dateofsubmission
            : View?.Dateofsubmission,
          AISHEID: AISHEID ? AISHEID : View?.AISHEID,
          InstitutionTrackID: InstitutionTrackID
            ? InstitutionTrackID
            : View?.InstitutionTrackID,
          ApplicationFor: ApplicationFor
            ? ApplicationFor
            : View?.ApplicationFor,
          Cyclename: Cyclename ? Cyclename : View?.Cyclename,
          CycleNumber: CycleNumber ? CycleNumber : View?.CycleNumber,
          Cycledate: Cycledate ? Cycledate : View?.Cycledate,
          Cyclegrade: Cyclegrade ? Cyclegrade : View?.Cyclegrade,
          CollegeName: CollegeName ? CollegeName : View?.CollegeName,
          Collegeestablishmentdate: Collegeestablishmentdate
            ? Collegeestablishmentdate
            : View?.Collegeestablishmentdate,
          Collegeheadname: Collegeheadname
            ? Collegeheadname
            : View?.Collegeheadname,
          Designation: Designation ? Designation : View?.Designation,
          OwnCampus: OwnCampus ? OwnCampus : View?.OwnCampus,
          Collegeaddress: Collegeaddress
            ? Collegeaddress
            : View?.Collegeaddress,
          CollegeState: CollegeState ? CollegeState : View?.CollegeState,
          CollegeDistrict: CollegeDistrict
            ? CollegeDistrict
            : View?.CollegeDistrict,
          CollegeCity: CollegeCity ? CollegeCity : View?.CollegeCity,
          CollegePin: CollegePin ? CollegePin : View?.CollegePin,
          CollegePhoneNo: CollegePhoneNo
            ? CollegePhoneNo
            : View?.CollegePhoneNo,
          CollegeFaxNo: CollegeFaxNo ? CollegeFaxNo : View?.CollegeFaxNo,
          CollegeMobileNo: CollegeMobileNo
            ? CollegeMobileNo
            : View?.CollegeMobileNo,
          CollegeRegisteredEmail: CollegeRegisteredEmail
            ? CollegeRegisteredEmail
            : View?.CollegeRegisteredEmail,
          CollegeAlternateEmail: CollegeAlternateEmail
            ? CollegeAlternateEmail
            : View?.CollegeAlternateEmail,
          AlternateFaculty: AlternateFaculty
            ? AlternateFaculty
            : View?.AlternateFaculty,
          ADesignation: ADesignation ? ADesignation : View?.ADesignation,
          ACollegeaddress: ACollegeaddress
            ? ACollegeaddress
            : View?.ACollegeaddress,
          ACollegeState: ACollegeState ? ACollegeState : View?.ACollegeState,
          ACollegeCity: ACollegeCity ? ACollegeCity : View?.ACollegeCity,
          ACollegePin: ACollegePin ? ACollegePin : View?.ACollegePin,
          ACollegePhoneNo: ACollegePhoneNo
            ? ACollegePhoneNo
            : View?.ACollegePhoneNo,
          ACollegeFaxNo: ACollegeFaxNo ? ACollegeFaxNo : View?.ACollegeFaxNo,
          ACollegeMobileNo: ACollegeMobileNo
            ? ACollegeMobileNo
            : View?.ACollegeMobileNo,
          ACollegeRegisteredEmail: ACollegeRegisteredEmail
            ? ACollegeRegisteredEmail
            : View?.ACollegeRegisteredEmail,
          ACollegeAlternateEmail: ACollegeAlternateEmail
            ? ACollegeAlternateEmail
            : View?.ACollegeAlternateEmail,
          Website: Website ? Website : View?.Website,
          Natureofthecollege: Natureofthecollege
            ? Natureofthecollege
            : View?.Natureofthecollege,
          CollegeAffiliation: CollegeAffiliation
            ? CollegeAffiliation
            : View?.CollegeAffiliation,
          Instc6yoexistence: Instc6yoexistence
            ? Instc6yoexistence
            : View?.Instc6yoexistence,
          graduationy1: graduationy1 ? graduationy1 : View?.graduationy1,
          graduationy2: graduationy2 ? graduationy2 : View?.graduationy2,
          affiliating_Uni_State: affiliating_Uni_State
            ? affiliating_Uni_State
            : View?.affiliating_Uni_State,
          affiliating_Uni_Name: affiliating_Uni_Name
            ? affiliating_Uni_Name
            : View?.affiliating_Uni_Name,
          affiliating_Uni_Doc: affiliating_Uni_Doc
            ? affiliating_Uni_Doc
            : View?.affiliating_Uni_Doc,
          section2UGCAct: section2UGCAct
            ? section2UGCAct
            : View?.section2UGCAct,
          section2UGCAct_Doc: section2UGCAct_Doc
            ? section2UGCAct_Doc
            : View?.section2UGCAct_Doc,
          section12BUGCAct: section12BUGCAct
            ? section12BUGCAct
            : View?.section12BUGCAct,
          section12BUGCAct_Doc: section12BUGCAct_Doc
            ? section12BUGCAct_Doc
            : View?.section12BUGCAct_Doc,
          recognised_Aut_Clg: recognised_Aut_Clg
            ? recognised_Aut_Clg
            : View?.recognised_Aut_Clg,
          CPE: CPE ? CPE : View?.CPE,
          College_Excellence: College_Excellence
            ? College_Excellence
            : View?.College_Excellence,
          recognised_SRA: recognised_SRA
            ? recognised_SRA
            : View?.recognised_SRA,
          SRA_AIU: SRA_AIU ? SRA_AIU : View?.SRA_AIU,
          SRA_program: SRA_program ? SRA_program : View?.SRA_program,
          SRA_Document: SRA_Document ? SRA_Document : View?.SRA_Document,
          UG: UG ? UG : View?.UG,
          PG: PG ? PG : View?.PG,
          Ayurveda: Ayurveda ? Ayurveda : View?.Ayurveda,
          M_Phil: M_Phil ? M_Phil : View?.M_Phil,
          PhD: PhD ? PhD : View?.PhD,
          LLD: LLD ? LLD : View?.LLD,
          PG_Diploma: PG_Diploma ? PG_Diploma : View?.PG_Diploma,
          Diploma: Diploma ? Diploma : View?.Diploma,
          Certificate: Certificate ? Certificate : View?.Certificate,
          Programme_Doc: Programme_Doc ? Programme_Doc : View?.Programme_Doc,
          Teaching_P_Male: Teaching_P_Male
            ? Teaching_P_Male
            : View?.Teaching_P_Male,
          Teaching_P_Female: Teaching_P_Female
            ? Teaching_P_Female
            : View?.Teaching_P_Female,
          Teaching_P_Transgender: Teaching_P_Transgender
            ? Teaching_P_Transgender
            : View?.Teaching_P_Transgender,
          Teaching_T_Male: Teaching_T_Male
            ? Teaching_T_Male
            : View?.Teaching_T_Male,
          Teaching_T_Female: Teaching_T_Female
            ? Teaching_T_Female
            : View?.Teaching_T_Female,
          Teaching_T_Transgender: Teaching_T_Transgender
            ? Teaching_T_Transgender
            : View?.Teaching_T_Transgender,
          N_Teaching_P_Male: N_Teaching_P_Male
            ? N_Teaching_P_Male
            : View?.N_Teaching_P_Male,
          N_Teaching_P_Female: N_Teaching_P_Female
            ? N_Teaching_P_Female
            : View?.N_Teaching_P_Female,
          N_Teaching_P_Transgender: N_Teaching_P_Transgender
            ? N_Teaching_P_Transgender
            : View?.N_Teaching_P_Transgender,
          N_Teaching_T_Male: N_Teaching_T_Male
            ? N_Teaching_T_Male
            : View?.N_Teaching_T_Male,
          N_Teaching_T_Female: N_Teaching_T_Female
            ? N_Teaching_T_Female
            : View?.N_Teaching_T_Female,
          N_Teaching_T_Transgender: N_Teaching_T_Transgender
            ? N_Teaching_T_Transgender
            : View?.N_Teaching_T_Transgender,
          Students_M: Students_M ? Students_M : View?.Students_M,
          Students_F: Students_F ? Students_F : View?.Students_F,
          Students_T: Students_T ? Students_T : View?.Students_T,
          committees: committees ? committees : View?.committees,
          Date_establishment_IQAC: Date_establishment_IQAC
            ? Date_establishment_IQAC
            : View?.Date_establishment_IQAC,
          IQAC_meeting_Date: IQAC_meeting_Date
            ? IQAC_meeting_Date
            : View?.IQAC_meeting_Date,
          IQAC_meeting_Doc: IQAC_meeting_Doc
            ? IQAC_meeting_Doc
            : View?.IQAC_meeting_Doc,
          Date_submission_AQARs1: Date_submission_AQARs1
            ? Date_submission_AQARs1
            : View?.Date_submission_AQARs1,
          Date_submission_AQARs_Doc1: Date_submission_AQARs_Doc1
            ? Date_submission_AQARs_Doc1
            : View?.Date_submission_AQARs_Doc1,
          Date_submission_AQARs2: Date_submission_AQARs2
            ? Date_submission_AQARs2
            : View?.Date_submission_AQARs2,
          Date_submission_AQARs_Doc2: Date_submission_AQARs_Doc2
            ? Date_submission_AQARs_Doc2
            : View?.Date_submission_AQARs_Doc2,
          Date_submission_AQARs3: Date_submission_AQARs3
            ? Date_submission_AQARs3
            : View?.Date_submission_AQARs3,
          Date_submission_AQARs_Doc3: Date_submission_AQARs_Doc3
            ? Date_submission_AQARs_Doc3
            : View?.Date_submission_AQARs_Doc3,
          Date_submission_AQARs4: Date_submission_AQARs4
            ? Date_submission_AQARs4
            : View?.Date_submission_AQARs4,
          Date_submission_AQARs_Doc4: Date_submission_AQARs_Doc4
            ? Date_submission_AQARs_Doc4
            : View?.Date_submission_AQARs_Doc4,
          RTI_Act_2005: RTI_Act_2005 ? RTI_Act_2005 : View?.RTI_Act_2005,
          RTI_Act_2005_Doc: RTI_Act_2005_Doc
            ? RTI_Act_2005_Doc
            : View?.RTI_Act_2005_Doc,
          MoU_foreign: MoU_foreign ? MoU_foreign : View?.MoU_foreign,
          MHRD_Date: MHRD_Date ? MHRD_Date : View?.MHRD_Date,
          MHRD_Doc: MHRD_Doc ? MHRD_Doc : View?.MHRD_Doc,
          AttachCertification: AttachCertification
            ? AttachCertification
            : View?.AttachCertification,
          Registration_Fee: Registration_Fee
            ? Registration_Fee
            : View?.Registration_Fee,
          Programme_Array:
            Programme_Array?.length > 0
              ? Programme_Array
              : View?.Programme_Array,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("IIQA added succesfully");
        window.location.assign("/IiqaMain");
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  console.log("Programme_Array", Programme_Array);

  return (
    <div style={{ overflow: "hidden", height: "700px", overflowY: "scroll" }}>
      <div className="text-center">
        <img
          src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
          alt="img"
          className=" pdfimg pt-2 "
        />
        <h3>Institutional Information for Quality Assessment(IIQA)</h3>
        <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
      </div>
      <div>
        <div
          class=" col-md-12-fluid border"
          style={{
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <div>
            <label>Select Year</label>

            <select
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              onChange={(e) => setselectedyear(e.target.value)}
            >
              <option>{View?.selectedyear}</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}{" "}
            </select>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Date of submission</label>
              <input
                type="date"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={
                  Dateofsubmission ? Dateofsubmission : View?.Dateofsubmission
                }
                onChange={(e) => setDateofsubmission(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>AISHE ID</label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.AISHEID}
                onChange={(e) => setAISHEID(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Institution Track ID</label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.InstitutionTrackID}
                onChange={(e) => setInstitutionTrackID(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          class=" col-md-12-fluid border mt-5"
          style={{
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <label>Application For</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ApplicationFor}
                onChange={(e) => setApplicationFor(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Cycle of Accreditation </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.Cyclename}
                onChange={(e) => setCyclename(e.target.value)}
              />
              <Table responsive>
                <thead>
                  <th>Cycle</th>
                  <th>Date</th>
                  <th>Grade</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "120px" }}
                        placeholder={View?.CycleNumber}
                        onChange={(e) => setCycleNumber(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "120px" }}
                        value={Cycledate ? Cycledate : View?.Cycledate}
                        onChange={(e) => setCycledate(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "120px" }}
                        placeholder={View?.Cyclegrade}
                        onChange={(e) => setCyclegrade(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Name of the College</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegeName}
                onChange={(e) => setCollegeName(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Date of establishment of the Institution </label>
              <input
                type="date"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={
                  Collegeestablishmentdate
                    ? Collegeestablishmentdate
                    : View?.Collegeestablishmentdate
                }
                onChange={(e) => setCollegeestablishmentdate(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label> Name of the Head of the Institution </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.Collegeheadname}
                onChange={(e) => setCollegeheadname(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>Designation </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.Designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label> Does the college function from Own Campus </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.OwnCampus}
                onChange={(e) => setOwnCampus(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Address of the College </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.Collegeaddress}
                onChange={(e) => setCollegeaddress(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> State/UT </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegeState}
                onChange={(e) => setCollegeState(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> District </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegeDistrict}
                onChange={(e) => setCollegeDistrict(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> City </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegeCity}
                onChange={(e) => setCollegeCity(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Pin </label>
              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={6}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegePin}
                onChange={(e) => setCollegePin(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Phone No </label>

              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                // maxLength={10}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegePhoneNo}
                onChange={(e) => setCollegePhoneNo(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Fax No </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegeFaxNo}
                onChange={(e) => setCollegeFaxNo(e.target.value)}
              />
            </div>

            <div className="col-md-4">
              <label> Mobile No </label>

              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={10}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegeMobileNo}
                onChange={(e) => setCollegeMobileNo(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Registered Email </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegeRegisteredEmail}
                onChange={(e) => setCollegeRegisteredEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label> Alternate Email </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegeAlternateEmail}
                onChange={(e) => setCollegeAlternateEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Alternate Faculty Contact Details </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.AlternateFaculty}
                onChange={(e) => setAlternateFaculty(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label>Designation</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ADesignation}
                onChange={(e) => setADesignation(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Address</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ACollegeaddress}
                onChange={(e) => setACollegeaddress(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> State/UT </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ACollegeState}
                onChange={(e) => setACollegeState(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> City</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ACollegeCity}
                onChange={(e) => setACollegeCity(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Pin </label>
              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={6}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ACollegePin}
                onChange={(e) => setACollegePin(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Phone No</label>

              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ACollegePhoneNo}
                onChange={(e) => setACollegePhoneNo(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Fax No </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ACollegeFaxNo}
                onChange={(e) => setACollegeFaxNo(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Mobile No</label>

              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={10}
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ACollegeMobileNo}
                onChange={(e) => setACollegeMobileNo(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label> Email </label>
              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ACollegeRegisteredEmail}
                onChange={(e) => setACollegeRegisteredEmail(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Alternate Email</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.ACollegeAlternateEmail}
                onChange={(e) => setACollegeAlternateEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label> Website</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.Website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> Nature of the college</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.Natureofthecollege}
                onChange={(e) => setNatureofthecollege(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label> College Affiliation</label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.CollegeAffiliation}
                onChange={(e) => setCollegeAffiliation(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Has the Institution completed 6 years of existence</label>
              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setInstc6yoexistence(e.target.value)}
              >
                <option>{View?.Instc6yoexistence}</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                <div>
             <label>Yes</label>
              <input
                type="checkbox"
              />
             </div>
             <div>
               <label>No</label>
               <input
                type="checkbox"
              
              /></div>
              </div> */}
            </div>
            <div className="col-md-6">
              <label>Years of graduation of last two batches</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div>
                  <label>Year1</label>
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={4}
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "100px" }}
                    placeholder={View?.graduationy1}
                    onChange={(e) => setgraduationy1(e.target.value)}
                  />
                </div>
                <div>
                  <label>Year2</label>
                  <input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={4}
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "100px" }}
                    placeholder={View?.graduationy2}
                    onChange={(e) => setgraduationy2(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <label>
              Name of the affiliating University(ies) and the state(s) in which
              the University(ies) is located
            </label>

            <Table responsive>
              <thead>
                <th>State</th>
                <th>University Name</th>
                <th>Documents</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.affiliating_Uni_State}
                      onChange={(e) => setaffiliating_Uni_State(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.affiliating_Uni_Name}
                      onChange={(e) => setaffiliating_Uni_Name(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.affiliating_Uni_Doc}
                      onChange={(e) =>
                        setaffiliating_Uni_Doc(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="col-md-12">
            <label>
              Is the Institution recognized under section 2(f) of the UGC Act?
            </label>
            <div className="row">
              <div className="col-md-6">
                <textarea
                  rows={5}
                  className="vi_0"
                  aria-label="Default select example"
                  //   style={{ width: "270px" }}
                  placeholder={View?.section2UGCAct}
                  onChange={(e) => setsection2UGCAct(e.target.value)}
                ></textarea>
              </div>
              <div className="col-md-6">
                <input
                  type="file"
                  className="vi_0"
                  aria-label="Default select example"
                  //   style={{ width: "270px" }}
                  placeholder={View?.section2UGCAct_Doc}
                  onChange={(e) => setsection2UGCAct_Doc(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <label>
              Is the Institution recognized under section 12B of the UGC Act? If
              yes, date of recognition by UGC under section 12B along with
              latest Plan General Development Grant release letter
            </label>
            <div className="row">
              <div className="col-md-6">
                <textarea
                  rows={5}
                  className="vi_0"
                  aria-label="Default select example"
                  //   style={{ width: "270px" }}
                  placeholder={View?.section12BUGCAct}
                  onChange={(e) => setsection12BUGCAct(e.target.value)}
                ></textarea>
              </div>
              <div className="col-md-6">
                <input
                  type="file"
                  className="vi_0"
                  aria-label="Default select example"
                  //   style={{ width: "270px" }}
                  placeholder={View?.section12BUGCAct_Doc}
                  onChange={(e) => setsection12BUGCAct_Doc(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>
                Is the institution recognised as an Autonomous College by the
                UGC?
              </label>

              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setrecognised_Aut_Clg(e.target.value)}
              >
                <option>{View?.recognised_Aut_Clg}</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-md-6">
              <label>
                Is the institution recognised as a ‘College with Potential for
                Excellence (CPE)’ by the UGC?
              </label>

              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setCPE(e.target.value)}
              >
                <option>{View?.CPE}</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>
                Is the institution recognised as a ‘College of Excellence’ by
                the UGC?
              </label>

              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setCollege_Excellence(e.target.value)}
              >
                <option>{View?.College_Excellence}</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-md-6">
              <label>
                Is the College offering any programmes recognised by any
                Statutory Regulatory Authority (SRA)
              </label>

              <select
                style={{ width: "270px" }}
                className="vi_0"
                aria-label="Default select example"
                onChange={(e) => setrecognised_SRA(e.target.value)}
              >
                <option>{View?.recognised_SRA}</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>
                If the institution is not affiliated to a university and is
                offering programmes recognized by any Statutory Regulatory
                Authorities (SRA), are the programmes recognized by Association
                of Indian Universities(AIU) or other appropriate Government
                authorities as equivalent to UG / PG Programmes of a University
              </label>

              <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.SRA_AIU}
                onChange={(e) => setSRA_AIU(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label> Statutory Regulatory Authorities</label>

              <Table responsive>
                <thead>
                  <th>SRA program</th>
                  <th>Document</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={View?.SRA_program}
                        onChange={(e) => setSRA_program(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="file"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={View?.SRA_Document}
                        onChange={(e) => setSRA_Document(e.target.files[0])}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div className="col-md-12">
            <label> Number of programmes offered</label>
            <Table responsive>
              <thead>
                <th>Programmes</th>
                <th>Number</th>
              </thead>
              <tbody>
                <tr>
                  <td>UG</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.UG}
                      onChange={(e) => setUG(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>PG</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.PG}
                      onChange={(e) => setPG(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Post Master's (DM, Ayurveda Vachaspathi, M.ch)</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Ayurveda}
                      onChange={(e) => setAyurveda(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pre Doctoral (M.Phil)</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.M_Phil}
                      onChange={(e) => setM_Phil(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Doctoral (Ph.D)</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.PhD}
                      onChange={(e) => setPhD(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Post Doctoral (D.Sc, D.Litt, LLD)</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.LLD}
                      onChange={(e) => setLLD(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    PG Diploma recognised by statutory authority including
                    university
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.PG_Diploma}
                      onChange={(e) => setPG_Diploma(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Diploma</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Diploma}
                      onChange={(e) => setDiploma(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Certificate / Awareness</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Certificate}
                      onChange={(e) => setCertificate(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="col-md-12">
            <label> Programme Details</label>
            <Table responsive>
              <thead>
                <th>Program</th>
                <th>Department</th>
                <th>University Affiliation</th>
                <th> SRA Recognition</th>
                <th> Affiliation Status</th>
              </thead>
              <tbody>
                {!editProgramme_Array ? (
                  <>
                    {View?.Programme_Array?.map((item) => (
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="vi_0"
                            aria-label="Default select example"
                            style={{ width: "270px" }}
                            value={item?.Program_Name}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="vi_0"
                            aria-label="Default select example"
                            style={{ width: "270px" }}
                            value={item?.Program_Department}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="vi_0"
                            aria-label="Default select example"
                            style={{ width: "270px" }}
                            value={item?.Program_University}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="vi_0"
                            aria-label="Default select example"
                            style={{ width: "270px" }}
                            value={item?.Program_SRA}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="vi_0"
                            aria-label="Default select example"
                            style={{ width: "270px" }}
                            value={item?.Program_Affiliation}
                          />
                        </td>
                      </tr>
                    ))}
                    <button
                      onClick={() =>
                        seteditProgramme_Array(!editProgramme_Array)
                      }
                    >
                      Edit
                    </button>
                  </>
                ) : (
                  <>
                    {Programme_Array?.length > 0
                      ? Programme_Array?.map((item) => (
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="vi_0"
                                aria-label="Default select example"
                                style={{ width: "270px" }}
                                value={item?.Program_Name}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="vi_0"
                                aria-label="Default select example"
                                style={{ width: "270px" }}
                                value={item?.Program_Department}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="vi_0"
                                aria-label="Default select example"
                                style={{ width: "270px" }}
                                value={item?.Program_University}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="vi_0"
                                aria-label="Default select example"
                                style={{ width: "270px" }}
                                value={item?.Program_SRA}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="vi_0"
                                aria-label="Default select example"
                                style={{ width: "270px" }}
                                value={item?.Program_Affiliation}
                              />
                            </td>
                          </tr>
                        ))
                      : ""}
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="vi_0"
                          aria-label="Default select example"
                          style={{ width: "270px" }}
                          value={Program_Name}
                          onChange={(e) => setProgram_Name(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="vi_0"
                          aria-label="Default select example"
                          style={{ width: "270px" }}
                          value={Program_Department}
                          onChange={(e) =>
                            setProgram_Department(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="vi_0"
                          aria-label="Default select example"
                          style={{ width: "270px" }}
                          value={Program_University}
                          onChange={(e) =>
                            setProgram_University(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="vi_0"
                          aria-label="Default select example"
                          style={{ width: "270px" }}
                          value={Program_SRA}
                          onChange={(e) => setProgram_SRA(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="vi_0"
                          aria-label="Default select example"
                          style={{ width: "270px" }}
                          value={Program_Affiliation}
                          onChange={(e) =>
                            setProgram_Affiliation(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <button style={{ right: 10 }} onClick={addPrograms}>
                          Add
                        </button>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
            <input
              type="file"
              className="vi_0"
              aria-label="Default select example"
              placeholder={View?.Programme_Doc}
              onChange={(e) => setProgramme_Doc(e.target.files[0])}
            />
          </div>

          <div className="col-md-12">
            <label>
              Number of Teaching Staff by employment status (permanent /
              temporary) and by gender
            </label>
            <Table responsive>
              <thead>
                <th>Type</th>
                <th>Male</th>
                <th>Female</th>
                <th>Transgender</th>
                <th> Total</th>
              </thead>
              <tbody>
                <tr>
                  <td>Permanent</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Teaching_P_Male}
                      onChange={(e) => setTeaching_P_Male(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Teaching_P_Female}
                      onChange={(e) => setTeaching_P_Female(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Teaching_P_Transgender}
                      onChange={(e) =>
                        setTeaching_P_Transgender(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        !Teaching_P_Male &&
                        !Teaching_P_Female &&
                        !Teaching_P_Transgender
                          ? parseInt(View?.Teaching_P_Male) +
                            parseInt(View?.Teaching_P_Female) +
                            parseInt(View?.Teaching_P_Transgender)
                          : Teaching_P_Male &&
                            !Teaching_P_Female &&
                            !Teaching_P_Transgender
                          ? parseInt(Teaching_P_Male) +
                            parseInt(View?.Teaching_P_Female) +
                            parseInt(View?.Teaching_P_Transgender)
                          : !Teaching_P_Male &&
                            Teaching_P_Female &&
                            !Teaching_P_Transgender
                          ? parseInt(View?.Teaching_P_Male) +
                            parseInt(Teaching_P_Female) +
                            parseInt(View?.Teaching_P_Transgender)
                          : !Teaching_P_Male &&
                            !Teaching_P_Female &&
                            Teaching_P_Transgender
                          ? parseInt(View?.Teaching_P_Male) +
                            parseInt(View?.Teaching_P_Female) +
                            parseInt(Teaching_P_Transgender)
                          : Teaching_P_Male &&
                            Teaching_P_Female &&
                            !Teaching_P_Transgender
                          ? parseInt(Teaching_P_Male) +
                            parseInt(Teaching_P_Female) +
                            parseInt(View?.Teaching_P_Transgender)
                          : !Teaching_P_Male &&
                            Teaching_P_Female &&
                            Teaching_P_Transgender
                          ? parseInt(View?.Teaching_P_Male) +
                            parseInt(Teaching_P_Female) +
                            parseInt(Teaching_P_Transgender)
                          : Teaching_P_Male &&
                            !Teaching_P_Female &&
                            Teaching_P_Transgender
                          ? parseInt(Teaching_P_Male) +
                            parseInt(View?.Teaching_P_Female) +
                            parseInt(Teaching_P_Transgender)
                          : Teaching_P_Male &&
                            Teaching_P_Female &&
                            Teaching_P_Transgender
                          ? parseInt(Teaching_P_Male) +
                            parseInt(Teaching_P_Female) +
                            parseInt(Teaching_P_Transgender)
                          : ""
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Temporary</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Teaching_T_Male}
                      onChange={(e) => setTeaching_T_Male(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Teaching_T_Female}
                      onChange={(e) => setTeaching_T_Female(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Teaching_T_Transgender}
                      onChange={(e) =>
                        setTeaching_T_Transgender(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        !Teaching_T_Male &&
                        !Teaching_T_Female &&
                        !Teaching_T_Transgender
                          ? parseInt(View?.Teaching_T_Male) +
                            parseInt(View?.Teaching_T_Female) +
                            parseInt(View?.Teaching_T_Transgender)
                          : Teaching_T_Male &&
                            !Teaching_T_Female &&
                            !Teaching_T_Transgender
                          ? parseInt(Teaching_T_Male) +
                            parseInt(View?.Teaching_T_Female) +
                            parseInt(View?.Teaching_T_Transgender)
                          : !Teaching_T_Male &&
                            Teaching_T_Female &&
                            !Teaching_T_Transgender
                          ? parseInt(View?.Teaching_T_Male) +
                            parseInt(Teaching_T_Female) +
                            parseInt(View?.Teaching_T_Transgender)
                          : !Teaching_T_Male &&
                            !Teaching_T_Female &&
                            Teaching_T_Transgender
                          ? parseInt(View?.Teaching_T_Male) +
                            parseInt(View?.Teaching_T_Female) +
                            parseInt(Teaching_T_Transgender)
                          : Teaching_T_Male &&
                            Teaching_T_Female &&
                            !Teaching_T_Transgender
                          ? parseInt(Teaching_T_Male) +
                            parseInt(Teaching_T_Female) +
                            parseInt(View?.Teaching_T_Transgender)
                          : !Teaching_T_Male &&
                            Teaching_T_Female &&
                            Teaching_T_Transgender
                          ? parseInt(View?.Teaching_T_Male) +
                            parseInt(Teaching_T_Female) +
                            parseInt(Teaching_T_Transgender)
                          : Teaching_T_Male &&
                            !Teaching_T_Female &&
                            Teaching_T_Transgender
                          ? parseInt(Teaching_T_Male) +
                            parseInt(View?.Teaching_T_Female) +
                            parseInt(Teaching_T_Transgender)
                          : Teaching_T_Male &&
                            Teaching_T_Female &&
                            Teaching_T_Transgender
                          ? parseInt(Teaching_T_Male) +
                            parseInt(Teaching_T_Female) +
                            parseInt(Teaching_T_Transgender)
                          : ""
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="col-md-12">
            <label>
              Number of Non-Teaching Staff by employment status (permanent /
              temporary) and by gender
            </label>
            <Table responsive>
              <thead>
                <th>Type</th>
                <th>Male</th>
                <th>Female</th>
                <th>Transgender</th>
                <th> Total</th>
              </thead>
              <tbody>
                <tr>
                  <td>Permanent</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.N_Teaching_P_Male}
                      onChange={(e) => setN_Teaching_P_Male(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.N_Teaching_P_Female}
                      onChange={(e) => setN_Teaching_P_Female(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.N_Teaching_P_Transgender}
                      onChange={(e) =>
                        setN_Teaching_P_Transgender(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        !N_Teaching_P_Male &&
                        !N_Teaching_P_Female &&
                        !N_Teaching_P_Transgender
                          ? parseInt(View?.N_Teaching_P_Male) +
                            parseInt(View?.N_Teaching_P_Female) +
                            parseInt(View?.N_Teaching_P_Transgender)
                          : N_Teaching_P_Male &&
                            !N_Teaching_P_Female &&
                            !N_Teaching_P_Transgender
                          ? parseInt(N_Teaching_P_Male) +
                            parseInt(View?.N_Teaching_P_Female) +
                            parseInt(View?.N_Teaching_P_Transgender)
                          : !N_Teaching_P_Male &&
                            N_Teaching_P_Female &&
                            !N_Teaching_P_Transgender
                          ? parseInt(View?.N_Teaching_P_Male) +
                            parseInt(N_Teaching_P_Female) +
                            parseInt(View?.N_Teaching_P_Transgender)
                          : !N_Teaching_P_Male &&
                            !N_Teaching_P_Female &&
                            N_Teaching_P_Transgender
                          ? parseInt(View?.N_Teaching_P_Male) +
                            parseInt(View?.N_Teaching_P_Female) +
                            parseInt(N_Teaching_P_Transgender)
                          : N_Teaching_P_Male &&
                            N_Teaching_P_Female &&
                            !N_Teaching_P_Transgender
                          ? parseInt(N_Teaching_P_Male) +
                            parseInt(N_Teaching_P_Female) +
                            parseInt(View?.N_Teaching_P_Transgender)
                          : !N_Teaching_P_Male &&
                            N_Teaching_P_Female &&
                            N_Teaching_P_Transgender
                          ? parseInt(View?.N_Teaching_P_Male) +
                            parseInt(N_Teaching_P_Female) +
                            parseInt(N_Teaching_P_Transgender)
                          : N_Teaching_P_Male &&
                            !N_Teaching_P_Female &&
                            N_Teaching_P_Transgender
                          ? parseInt(N_Teaching_P_Male) +
                            parseInt(View?.N_Teaching_P_Female) +
                            parseInt(N_Teaching_P_Transgender)
                          : N_Teaching_P_Male &&
                            N_Teaching_P_Female &&
                            N_Teaching_P_Transgender
                          ? parseInt(N_Teaching_P_Male) +
                            parseInt(N_Teaching_P_Female) +
                            parseInt(N_Teaching_P_Transgender)
                          : ""
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Temporary</td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.N_Teaching_T_Male}
                      onChange={(e) => setN_Teaching_T_Male(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.N_Teaching_T_Female}
                      onChange={(e) => setN_Teaching_T_Female(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.N_Teaching_T_Transgender}
                      onChange={(e) =>
                        setN_Teaching_T_Transgender(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        !N_Teaching_T_Male &&
                        !N_Teaching_T_Female &&
                        !N_Teaching_T_Transgender
                          ? parseInt(View?.N_Teaching_T_Male) +
                            parseInt(View?.N_Teaching_T_Female) +
                            parseInt(View?.N_Teaching_T_Transgender)
                          : N_Teaching_T_Male &&
                            !N_Teaching_T_Female &&
                            !N_Teaching_T_Transgender
                          ? parseInt(N_Teaching_T_Male) +
                            parseInt(View?.N_Teaching_T_Female) +
                            parseInt(View?.N_Teaching_T_Transgender)
                          : !N_Teaching_T_Male &&
                            N_Teaching_T_Female &&
                            !N_Teaching_T_Transgender
                          ? parseInt(View?.N_Teaching_T_Male) +
                            parseInt(N_Teaching_T_Female) +
                            parseInt(View?.N_Teaching_T_Transgender)
                          : !N_Teaching_T_Male &&
                            !N_Teaching_T_Female &&
                            N_Teaching_T_Transgender
                          ? parseInt(View?.N_Teaching_T_Male) +
                            parseInt(View?.N_Teaching_T_Female) +
                            parseInt(N_Teaching_T_Transgender)
                          : N_Teaching_T_Male &&
                            N_Teaching_T_Female &&
                            !N_Teaching_T_Transgender
                          ? parseInt(N_Teaching_T_Male) +
                            parseInt(N_Teaching_T_Female) +
                            parseInt(View?.N_Teaching_T_Transgender)
                          : !N_Teaching_T_Male &&
                            N_Teaching_T_Female &&
                            N_Teaching_T_Transgender
                          ? parseInt(View?.N_Teaching_T_Male) +
                            parseInt(N_Teaching_T_Female) +
                            parseInt(N_Teaching_T_Transgender)
                          : N_Teaching_T_Male &&
                            !N_Teaching_T_Female &&
                            N_Teaching_T_Transgender
                          ? parseInt(N_Teaching_T_Male) +
                            parseInt(View?.N_Teaching_T_Female) +
                            parseInt(N_Teaching_T_Transgender)
                          : N_Teaching_T_Male &&
                            N_Teaching_T_Female &&
                            N_Teaching_T_Transgender
                          ? parseInt(N_Teaching_T_Male) +
                            parseInt(N_Teaching_T_Female) +
                            parseInt(N_Teaching_T_Transgender)
                          : ""
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="col-md-12">
            <label> Number of Students on roll by gender</label>
            <Table responsive>
              <thead>
                <th>Male</th>
                <th>Female</th>
                <th>Transgender</th>
                <th> Total</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Students_M}
                      onChange={(e) => setStudents_M(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Students_F}
                      onChange={(e) => setStudents_F(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Students_T}
                      onChange={(e) => setStudents_T(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        !Students_M && !Students_F && !Students_T
                          ? parseInt(View?.Students_M) +
                            parseInt(View?.Students_F) +
                            parseInt(View?.Students_T)
                          : Students_M && !Students_F && !Students_T
                          ? parseInt(Students_M) +
                            parseInt(View?.Students_F) +
                            parseInt(View?.Students_T)
                          : !Students_M && Students_F && !Students_T
                          ? parseInt(View?.Students_M) +
                            parseInt(Students_F) +
                            parseInt(View?.Students_T)
                          : !Students_M && !Students_F && Students_T
                          ? parseInt(View?.Students_M) +
                            parseInt(View?.Students_F) +
                            parseInt(Students_T)
                          : Students_M && Students_F && !Students_T
                          ? parseInt(Students_M) +
                            parseInt(Students_F) +
                            parseInt(View?.Students_T)
                          : !Students_M && Students_F && Students_T
                          ? parseInt(View?.Students_M) +
                            parseInt(Students_F) +
                            parseInt(Students_T)
                          : Students_M && !Students_F && Students_T
                          ? parseInt(Students_M) +
                            parseInt(View?.Students_F) +
                            parseInt(Students_T)
                          : Students_M && Students_F && Students_T
                          ? parseInt(Students_M) +
                            parseInt(Students_F) +
                            parseInt(Students_T)
                          : ""
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label>
                Does the institution have statutory cells / committees{" "}
              </label>

              <div className="">
                <CKEditor
                  editor={ClassicEditor}
                  data={committees ? committees : View?.committees}
                  onChange={handleMainBody}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label> Date of establishment of IQAC</label>

              <input
                type="date"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                value={
                  Date_establishment_IQAC
                    ? Date_establishment_IQAC
                    : View?.Date_establishment_IQAC
                }
                onChange={(e) => setDate_establishment_IQAC(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>
                The minutes of IQAC meeting and Action Taken Report should be
                uploaded on the institutional website.
              </label>

              <Table responsive>
                <thead>
                  <th>Date</th>
                  <th>View Document</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="date"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "270px" }}
                        value={
                          IQAC_meeting_Date
                            ? IQAC_meeting_Date
                            : View?.IQAC_meeting_Date
                        }
                        onChange={(e) => setIQAC_meeting_Date(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="file"
                        className="vi_0"
                        aria-label="Default select example"
                        style={{ width: "270px" }}
                        placeholder={View?.IQAC_meeting_Doc}
                        onChange={(e) => setIQAC_meeting_Doc(e.target.files[0])}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div className="col-md-12">
            <label> Date of submission of AQARs of last 4 years to NAAC</label>
            <Table responsive>
              <thead>
                <th>Date</th>
                <th>View Document</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        Date_submission_AQARs1
                          ? Date_submission_AQARs1
                          : View?.Date_submission_AQARs1
                      }
                      onChange={(e) =>
                        setDate_submission_AQARs1(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Date_submission_AQARs_Doc1}
                      onChange={(e) =>
                        setDate_submission_AQARs_Doc1(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        Date_submission_AQARs2
                          ? Date_submission_AQARs2
                          : View?.Date_submission_AQARs2
                      }
                      onChange={(e) =>
                        setDate_submission_AQARs2(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Date_submission_AQARs_Doc2}
                      onChange={(e) =>
                        setDate_submission_AQARs_Doc2(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        Date_submission_AQARs3
                          ? Date_submission_AQARs3
                          : View?.Date_submission_AQARs3
                      }
                      onChange={(e) =>
                        setDate_submission_AQARs3(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Date_submission_AQARs_Doc3}
                      onChange={(e) =>
                        setDate_submission_AQARs_Doc3(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      value={
                        Date_submission_AQARs4
                          ? Date_submission_AQARs4
                          : View?.Date_submission_AQARs4
                      }
                      onChange={(e) =>
                        setDate_submission_AQARs4(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder={View?.Date_submission_AQARs_Doc4}
                      onChange={(e) =>
                        setDate_submission_AQARs_Doc4(e.target.files[0])
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="col-md-12">
            <label>
              {" "}
              Has the institution made statutory declaration on the institution
              website under Section 4 (1) (b) of the RTI Act 2005 as issued and
              amended from time to time.
            </label>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px", marginRight: "10px" }}
              placeholder={View?.RTI_Act_2005}
              onChange={(e) => setRTI_Act_2005(e.target.value)}
            />
            <input
              type="file"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              placeholder={View?.RTI_Act_2005_Doc}
              onChange={(e) => setRTI_Act_2005_Doc(e.target.files[0])}
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>
                Does the college have an academic MoU with any foreign
                institution{" "}
              </label>

              {/* <input
                type="text"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                onChange={(e) => setMoU_foreign(e.target.value)}
              /> */}
              <select
                className="vi_0"
                style={{ width: "270px" }}
                onChange={(e) => setMoU_foreign(e.target.value)}
              >
                <option>{View?.MoU_foreign}</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-md-6">
              <label>
                {" "}
                Date of uploading data on MHRD website for All India Survey on
                Higher Education (AISHE).
              </label>
              <input
                type="date"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px", marginRight: "10px" }}
                value={MHRD_Date ? MHRD_Date : View?.MHRD_Date}
                onChange={(e) => setMHRD_Date(e.target.value)}
              />
              <input
                type="file"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.MHRD_Doc}
                onChange={(e) => setMHRD_Doc(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>
                {" "}
                Attach Certification by the Head of the Institution for having
                complied with Rules & Regulations of Central Government, UGC and
                other Statutory Bodies, State Government and Affiliating
                University in the prescribed format enclosed herewith.
              </label>
              <input
                type="file"
                className="vi_0"
                aria-label="Default select example"
                style={{ width: "270px" }}
                placeholder={View?.AttachCertification}
                onChange={(e) => setAttachCertification(e.target.files[0])}
              />
            </div>
            <div className="col-md-6">
              <label> Registration Fee paid details.</label>
              <div className="">
                <CKEditor
                  editor={ClassicEditor}
                  data={
                    Registration_Fee ? Registration_Fee : View?.Registration_Fee
                  }
                  onChange={handleMainBody1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <button onClick={editiiqa}>Save Changes</button>
      </div>
    </div>
  );
}
