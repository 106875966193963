import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Container, Table } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

function PrincipalFeedback() {
  let PrincipalDetails = JSON.parse(sessionStorage.getItem("Puser"));

  const [selectedDate, setSelectedDate] = useState(null);
  const handleChange = (date) => {
    setSelectedDate(date);
  };

  const [OrganisationType, setOrganisationtype] = useState("");
  const [OrganisationName, setOrganisationName] = useState("");
  const [Employername, setEmployername] = useState("");
  const [HeadofOrganisation, setHeadofOrganisation] = useState("");
  const [ContactNoEmpl, setContactNoEmpl] = useState("");
  const [NameOfAlumni, setNameOfAlumni] = useState("");
  const [ContactNoAlumni, setContactNoAlumni] = useState("");
  const [AlumniWorking, setAlumniWorking] = useState("");
  const [Survey1, setSurvey1] = useState("");
  const [Survey2, setSurvey2] = useState("");
  const [Survey3, setSurvey3] = useState("");
  const [Survey4, setSurvey4] = useState("");
  const [Survey5, setSurvey5] = useState("");
  const [Survey6, setSurvey6] = useState("");
  const [Survey7, setSurvey7] = useState("");
  const [Survey8, setSurvey8] = useState("");
  const [Survey9, setSurvey9] = useState("");
  const [Suggestions, setSuggestions] = useState("");
  const Principalfeedback = async () => {
    try {
      const config = {
        url: "pri/principalfeedback",
        method: "post",
        baseURL: "https://brightnaac.co.in/api/",
        headers: { "Content-Type": "application/json" },
        data: {
          reviewyear: selectedDate,
          principalid: PrincipalDetails?._id,
          organisationtype: OrganisationType,
          organisationname: OrganisationName,
          employername: Employername,
          headoforganisation: HeadofOrganisation,
          ContactNoEmpl: ContactNoEmpl,
          NameOfAlumni: NameOfAlumni,
          ContactNoAlumni: ContactNoAlumni,
          AlumniWorking: AlumniWorking,

          survey1: Survey1,
          survey2: Survey2,
          survey3: Survey3,
          survey4: Survey4,
          survey5: Survey5,
          survey6: Survey6,
          survey7: Survey7,
          survey8: Survey8,
          survey9: Survey9,
          suggestion: Suggestions,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        window.location.assign("/principalfeedbacklist");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  return (
    <div>
      <Container className="pt-4">
        <div>
          <div className="bgdesign">
            <h2>Feedback from Employer on Curriculum and Students</h2>
            <p className="text">Dear Students,</p>
            <p className="text">
              Greetings from Govt. First Grade College, RANEBENNUR.
            </p>
            <p>
              You are hereby informed to fill the bellow required data as it is
              required for NAAC Process.
            </p>
            <p className="text">Thank you.</p>
            <p> IQAC Team </p>
            <hr />
            <div className="d-flex justify-content-between">
              <b>{PrincipalDetails?.Email} </b>
              <div>
                <span>Select Year : </span>
                <ReactDatePicker
                  placeholderText="select year"
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleChange}
                  dateFormat="yyyy"
                  showYearPicker
                  maxDate={new Date()}
                />
              </div>
            </div>
            <hr />
            <p style={{ color: "red" }}>* Indicates required question</p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  1.Type of Setting of Organisation :{" "}
                  <span style={{ color: "red" }}>*</span>
                  <p className="text">
                    {" "}
                    (Please mention the type of organisation of which our Alumni
                    is working)
                  </p>
                </FormLabel>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose..
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setOrganisationtype(e.target.value)}
                    >
                      <MenuItem value={"Goverment"}>Goverment</MenuItem>
                      <MenuItem value={"semi-Goverment"}>
                        semi-Goverment
                      </MenuItem>
                      <MenuItem value={"Private"}>Private</MenuItem>
                      <MenuItem value={"Option 4"}>Option 4</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  2. Name of the Organisation :{" "}
                  <span style={{ color: "red" }}>*</span>
                  <p>
                    {" "}
                    (Please mention the name of organisation/Office/Dept. of
                    which our Alumni is working)
                  </p>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setOrganisationName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  3. Name of the Employer :{" "}
                  <span style={{ color: "red" }}>*</span>
                  <p> Please mention the name of Head of the organisation</p>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setEmployername(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  4. Please mention the name of Head of the organisation :{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setHeadofOrganisation(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  5. Contact Number of Employer :{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setContactNoEmpl(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  6. Name of the Employee (Our Alumni){" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setNameOfAlumni(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  7. Contact No. of Employee (Our Alumni){" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  style={{ width: "100%" }}
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  onChange={(e) => setContactNoAlumni(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bgdesign mt-2">
                <FormLabel>
                  8. Is our alumnus are working in your organization{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  id="filled-multiline-flexible"
                  label="Your Answer"
                  multiline
                  maxRows={4}
                  variant="filled"
                  style={{ width: "100%" }}
                  onChange={(e) => setAlumniWorking(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="bgdesign mt-2">
            <FormLabel>
              Please give your response on bellow points, as per the above
              Legends: <span style={{ color: "red" }}>*</span>
            </FormLabel>

            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Very Good</th>
                  <th>Good</th>
                  <th>Average</th>
                  <th>Acceptable</th>
                  <th>Poor</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      1. Ability to contribute to achieve the goals and
                      objectives of the organization
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey1 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey1 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey1 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey1 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey1("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey1 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      2. Ability to manage relationship (seniors, subordinates
                      and juniors ){" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey2 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey2 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey2 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey2 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey2("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey2 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">3. Communication skills </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey3 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey3 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey3 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey3 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey3("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey3 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      4. Commitment towards completing the task in time
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey4 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey4 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey4 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey4 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey4("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey4 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      5. Ability to work in a group and leadership skills{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey5 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey5 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey5 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey5 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey5("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey5 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      6. Ability to take extra responsibility{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey6 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey6 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey6 === "Average"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey6 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey6("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey6 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      7. Overall performance of the alumni(your employee){" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey7 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey7 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey7 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey7 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey7("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey7 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      8. The present curriculum is as per the needs of the
                      industry{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey8 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey8 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey8 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey8 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey8("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey8 === "Poor"} />}
                    />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <p className="text">
                      9. The syllabus has good balance between theory and
                      application{" "}
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Very Good")}
                      value={"Very Good"}
                      control={<Radio checked={Survey9 === "Very Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Good")}
                      value={"Good"}
                      control={<Radio checked={Survey9 === "Good"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Average")}
                      value={"Average"}
                      control={<Radio checked={Survey9 === "Average"} />}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Acceptable")}
                      value={"Acceptable"}
                      control={<Radio checked={Survey9 === "Acceptable"} />}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      onClick={() => setSurvey9("Poor")}
                      value={"Poor"}
                      control={<Radio checked={Survey9 === "Poor"} />}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="bgdesign mt-2">
            <FormLabel>
              <p className="text">
                10. Your suggestion about the college/academics.
              </p>{" "}
            </FormLabel>
            <TextField
              id="filled-multiline-flexible"
              label="Your Answer"
              multiline
              maxRows={4}
              variant="filled"
              style={{ width: "100%" }}
              onChange={(e) => setSuggestions(e.target.value)}
            />
          </div>

          <div className="mt-3">
            <Button
              variant="contained"
              className="mb-3"
              onClick={Principalfeedback}
            >
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PrincipalFeedback;
