import axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import PG3311aqar from "./PG3311aqar";
import PG3322aqar from "./PG3322aqar";
import PG3332aqar from "./PG3332aqar";
import PG3333aqar from "./PG3333aqar";
import PG3342aqar from "./PG3342aqar";
import PG3343aqar from "./PG3343aqar";
import PG3351aqar from "./PG3351aqar";
import PG3352aqar from "./PG3352aqar";
const PGC3aqar = React.memo(({ startYeardata, endYeardata }) => {
  const navigate = useNavigate();
  // =================================================================
  const [pg3Details, setpg3Details] = useState([]);
  const pgCriteria03Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria03"
      );
      if (res.status === 200) {
        setpg3Details(
          res.data.pgcriteria03?.filter(
            (item) =>
              item?.PG_Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.pgcriteria03?.filter(
          (item) =>
            item?.PG_Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setpg3Details(error.response.data.pgcriteria03);
    }
  };

  useEffect(() => {
    pgCriteria03Details();
  }, [startYeardata, endYeardata]);

  return (
    <div>
      <div className="text-center">
        <b>Criteria 3</b>
      </div>
      {pg3Details?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.1.1)
            </div>
            <PG3311aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.2.2)
            </div>
            <PG3322aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.3.2)
            </div>
            <PG3332aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.3.3)
            </div>
            <PG3333aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.4.2)
            </div>
            <PG3342aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.4.3)
            </div>
            <PG3343aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.5.1)
            </div>
            <PG3351aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 3 (3.5.2)
            </div>
            <PG3352aqar item={item} />
          </div>
        );
      })}
    </div>
  );
});

export default PGC3aqar;
