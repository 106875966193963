import React from "react";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PG_Criteria005 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);
  const [edit8, setedit8] = useState(false);
  const [add8, setadd8] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  //post method for criteria_05
  const [PG_Year, setPG_Year] = useState("");

  const [PG_NameS, setPG_NameS] = useState("");
  const [PG_SName, setPG_SName] = useState("");
  const [PG_Amount, setPG_Amount] = useState("");
  const [PG_SName1, setPG_SName1] = useState("");
  const [PG_Amount1, setPG_Amount1] = useState("");
  const [PG_Desc21, setPG_Desc21] = useState("");
  const [PG_Link21, setPG_Link21] = useState("");

  const [PG_NamCap, setPG_NamCap] = useState("");
  const [PG_IDate, setPG_IDate] = useState("");
  const [PG_StdEnr, setPG_StdEnr] = useState("");
  const [PG_AgeName, setPG_AgeName] = useState("");
  const [PG_Desc22, setPG_Desc22] = useState("");
  const [PG_Link22, setPG_Link22] = useState("");

  const [PG_CampName, setPG_CampName] = useState("");
  const [PG_Nameact, setPG_Nameact] = useState("");
  const [PG_NamePart, setPG_NamePart] = useState("");
  const [PG_Desc23, setPG_Desc23] = useState("");
  const [PG_Link23, setPG_Link23] = useState("");

  const [PG_PlaName, setPG_PlaName] = useState("");
  const [PG_Graduate, setPG_Graduate] = useState("");
  const [PG_ConDetails, setPG_ConDetails] = useState("");
  const [PG_Pay, setPG_Pay] = useState("");
  const [PG_Desc24, setPG_Desc24] = useState("");
  const [PG_Link24, setPG_Link24] = useState("");

  const [PG_HighName, setPG_HighName] = useState("");
  const [PG_PGrad, setPG_PGrad] = useState("");
  const [PG_Institute, setPG_Institute] = useState("");
  const [PG_Admitted, setPG_Admitted] = useState("");
  const [PG_Desc25, setPG_Desc25] = useState("");
  const [PG_Link25, setPG_Link25] = useState("");

  const [PG_No1, setPG_No1] = useState("");
  const [PG_RollNo1, setPG_RollNo1] = useState("");
  const [PG_StdSelected1, setPG_StdSelected1] = useState("");
  const [PG_NET1, setPG_NET1] = useState("");
  const [PG_SLET1, setPG_SLET1] = useState("");
  const [PG_GATE1, setPG_GATE1] = useState("");
  const [PG_GMAT1, setPG_GMAT1] = useState("");
  const [PG_CAT1, setPG_CAT1] = useState("");
  const [PG_GRE1, setPG_GRE1] = useState("");
  const [PG_JAM1, setPG_JAM1] = useState("");
  const [PG_IELTS1, setPG_IELTS1] = useState("");
  const [PG_TOEFL1, setPG_TOEFL1] = useState("");
  const [PG_Civil1, setPG_Civil1] = useState("");
  const [PG_SGExam1, setPG_SGExam1] = useState("");
  const [PG_OtherExam1, setPG_OtherExam1] = useState("");
  const [PG_Total1, setPG_Total1] = useState("");
  const [PG_GTotal1, setPG_GTotal1] = useState("");
  const [PG_Desc26, setPG_Desc26] = useState("");
  const [PG_Link26, setPG_Link26] = useState("");

  const [PG_Meda, setPG_Meda] = useState("");
  const [PG_TIndi, setPG_TIndi] = useState("");
  const [PG_USNI1, setPG_USNI1] = useState("");
  const [PG_SCult, setPG_SCult] = useState("");
  const [PG_NaStd, setPG_NaStd] = useState("");
  const [PG_Desc27, setPG_Desc27] = useState("");
  const [PG_Link27, setPG_Link27] = useState("");

  const [PG_NStdParti, setPG_NStdParti] = useState("");
  const [PG_EDate, setPG_EDate] = useState("");
  const [PG_EName, setPG_EName] = useState("");
  const [PG_Desc28, setPG_Desc28] = useState("");
  const [PG_Link28, setPG_Link28] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getpgcriteria5 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));

      let am8 = sessionStorage.getItem("cat8");
      setobj8(JSON.parse(am8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpgcriteria5();
  }, []);

  const pgcriteria5 = async () => {
    if (!PG_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/PG_adddetails05",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_Year: PG_Year,

          PG_Criteria05_511: obj1,
          PG_Link21: PG_Link21,

          PG_Criteria05_513: obj2,
          PG_Link22: PG_Link22,

          PG_Criteria05_514: obj3,
          PG_Link23: PG_Link23,

          PG_Criteria05_521: obj4,
          PG_Link24: PG_Link24,

          PG_Criteria05_522: obj5,
          PG_Link25: PG_Link25,

          PG_Criteria05_523: obj6,
          PG_Link26: PG_Link26,

          PG_Criteria05_531: obj7,
          PG_Link27: PG_Link27,

          PG_Criteria05_533: obj8,
          PG_Link28: PG_Link28,
          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          sessionStorage.removeItem("cat8");

          navigate("/pgcriteria05");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc21;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !PG_NameS ||
          !PG_SName ||
          !PG_Amount ||
          !PG_SName1 ||
          !PG_Amount1 ||
          !PG_Desc21 ||
          !PG_Link21
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", PG_Link21);
      let obj = {
        PG_criteria: "5.1.1 & 5.1.2",
        PG_NameS: PG_NameS,
        PG_SName: PG_SName,
        PG_Amount: PG_Amount,
        PG_SName1: PG_SName1,
        PG_Amount1: PG_Amount1,
        PG_Desc21: PG_Desc21,
        PG_Link21: PG_Link21,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getpgcriteria5();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc22;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !PG_NamCap ||
          !PG_IDate ||
          !PG_StdEnr ||
          !PG_AgeName ||
          !PG_Desc22 ||
          !PG_Link22
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", PG_Link22);
      let obj = {
        PG_criteria: "5.1.3",
        PG_NamCap: PG_NamCap,
        PG_IDate: PG_IDate,
        PG_StdEnr: PG_StdEnr,
        PG_AgeName: PG_AgeName,
        PG_Desc22: PG_Desc22,
        PG_Link22: PG_Link22,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getpgcriteria5();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc23;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !PG_CampName ||
          !PG_Nameact ||
          !PG_NamePart ||
          !PG_Desc23 ||
          !PG_Link23
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", PG_Link23);
      let obj = {
        PG_criteria: "5.1.4",
        PG_CampName: PG_CampName,
        PG_Nameact: PG_Nameact,
        PG_NamePart: PG_NamePart,
        PG_Desc23: PG_Desc23,
        PG_Link23: PG_Link23,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getpgcriteria5();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc24;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !PG_PlaName ||
          !PG_Graduate ||
          !PG_ConDetails ||
          !PG_Pay ||
          !PG_Desc24 ||
          !PG_Link24
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", PG_Link24);
      let obj = {
        PG_criteria: "5.2.1",
        PG_PlaName: PG_PlaName,
        PG_Graduate: PG_Graduate,
        PG_ConDetails: PG_ConDetails,
        PG_Pay: PG_Pay,
        PG_Desc24: PG_Desc24,
        PG_Link24: PG_Link24,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getpgcriteria5();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc25;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !PG_HighName ||
          !PG_PGrad ||
          !PG_Institute ||
          !PG_Admitted ||
          !PG_Desc25 ||
          !PG_Link25
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("qqqq,", PG_Link25);
      let obj = {
        PG_criteria: "5.2.2",
        PG_HighName: PG_HighName,
        PG_PGrad: PG_PGrad,
        PG_Institute: PG_Institute,
        PG_Admitted: PG_Admitted,
        PG_Desc25: PG_Desc25,
        PG_Link25: PG_Link25,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getpgcriteria5();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc26;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !PG_No1 ||
          !PG_RollNo1 ||
          !PG_StdSelected1 ||
          !PG_NET1 ||
          !PG_SLET1 ||
          !PG_GATE1 ||
          !PG_GMAT1 ||
          !PG_CAT1 ||
          !PG_GRE1 ||
          !PG_JAM1 ||
          !PG_IELTS1 ||
          !PG_TOEFL1 ||
          !PG_Civil1 ||
          !PG_SGExam1 ||
          !PG_OtherExam1 ||
          !PG_Total1 ||
          !PG_GTotal1 ||
          !PG_Desc26 ||
          !PG_Link26
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("rrr,", PG_Link26);
      let obj = {
        PG_criteria: "5.2.3",
        PG_No1: PG_No1,
        PG_RollNo1: PG_RollNo1,
        PG_StdSelected1: PG_StdSelected1,
        PG_NET1: PG_NET1,
        PG_SLET1: PG_SLET1,
        PG_GATE1: PG_GATE1,
        PG_GMAT1: PG_GMAT1,
        PG_CAT1: PG_CAT1,
        PG_GRE1: PG_GRE1,
        PG_JAM1: PG_JAM1,

        PG_IELTS1: PG_IELTS1,
        PG_TOEFL1: PG_TOEFL1,
        PG_Civil1: PG_Civil1,
        PG_SGExam1: PG_SGExam1,
        PG_OtherExam1: PG_OtherExam1,
        PG_Total1: PG_Total1,
        PG_GTotal1: PG_GTotal1,
        PG_Desc26: PG_Desc26,
        PG_Link26: PG_Link26,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getpgcriteria5();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc27;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !PG_Meda ||
          !PG_TIndi ||
          !PG_USNI1 ||
          !PG_SCult ||
          !PG_NaStd ||
          !PG_Desc27 ||
          !PG_Link27
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd7(false);
        }
      }

      console.log("sss,", PG_Link27);
      let obj = {
        PG_criteria: "5.3.1",
        PG_Meda: PG_Meda,
        PG_TIndi: PG_TIndi,
        PG_USNI1: PG_USNI1,
        PG_SCult: PG_SCult,
        PG_NaStd: PG_NaStd,
        PG_Desc27: PG_Desc27,
        PG_Link27: PG_Link27,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getpgcriteria5();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat8 = () => {
    try {
      const minwords = 500;
      const newText = PG_Desc28;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add8) {
        if (
          !PG_NStdParti ||
          !PG_EDate ||
          !PG_EName ||
          !PG_Desc28 ||
          !PG_Link28
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd8(false);
        }
      }

      console.log("ttt,", PG_Link28);
      let obj = {
        PG_criteria: "5.3.3",
        PG_NStdParti: PG_NStdParti,
        PG_EDate: PG_EDate,
        PG_EName: PG_EName,
        PG_Desc28: PG_Desc28,
        PG_Link28: PG_Link28,
      };
      sessionStorage.setItem("cat8", JSON.stringify(obj));
      alert("Successfully added cat8");
      getpgcriteria5();
      setedit8(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            PostGraduation_Criteria-05
          </span>
        </div>

        <div className="container">
          <div className="row-fluid border bs">
            <div class=" col-md-6" style={{ padding: "20px" }}>
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.PG_Year ? (
                <p>{obj1?.PG_Year}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setPG_Year(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.1.1 & 5.1.2)</h5>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the scheme
                </label>
                {obj1?.PG_NameS ? (
                  <p>{obj1?.PG_NameS}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NameS(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline">
              (Number of students benefited by government scheme and amount){" "}
            </h6>

            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students
                </label>
                {obj1?.PG_SName ? (
                  <p>{obj1?.PG_SName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_SName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Amount
                </label>
                {obj1?.PG_Amount ? (
                  <p>{obj1?.PG_Amount}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Amount(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline">
              (Number of students benefited by the institution's schemes and
              amount){" "}
            </h6>

            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students
                </label>
                {obj1?.PG_SName1 ? (
                  <p>{obj1?.PG_SName1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_SName1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Amount
                </label>
                {obj1?.PG_Amount1 ? (
                  <p>{obj1?.PG_Amount1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Amount1(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj1?.PG_Desc21 ? (
                <p>{obj1?.PG_Desc21}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  // cols={57}
                  rows={3}
                  onChange={(e) => {
                    setPG_Desc21(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload1"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link21(e.target.files[0]);
                }}
              />
            </div>
            <div className="text-end">
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success  mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria05_(5.1.1 &5.1.2)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the scheme
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NameS}
                          onChange={(e) => {
                            setPG_NameS(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NameS}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  (Number of students benefited by government scheme and amount){" "}
                </h6>

                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_SName}
                          onChange={(e) => {
                            setPG_SName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SName}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Amount
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Amount}
                          onChange={(e) => {
                            setPG_Amount(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Amount}</>
                    )}
                  </div>
                </div>
                <h6 className="text-center text-decoration-underline">
                  (Number of students benefited by the institution's schemes and
                  amount){" "}
                </h6>

                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_SName1}
                          onChange={(e) => {
                            setPG_SName1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SName1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Amount
                    </label>

                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Amount1}
                          onChange={(e) => {
                            setPG_Amount1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Amount1}</>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={PG_Desc21}
                        onChange={(e) => setPG_Desc21(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{PG_Desc21}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Upload the relevant document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="upload1"
                        accept="image/*"
                        onChange={(e) => setPG_Link21(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{PG_Link21?.name}</>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat1();
                    handleClose();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.1.3)</h5>
            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the capacity development and skills enhancement
                  program
                </label>

                {obj2?.PG_NamCap ? (
                  <p>{obj2?.PG_NamCap}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setPG_NamCap(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Date of implementation
                </label>

                {obj2?.PG_IDate ? (
                  <p>{obj2?.PG_IDate}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_IDate(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students enrolled
                </label>

                {obj2?.PG_StdEnr ? (
                  <p>{obj2?.PG_StdEnr}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_StdEnr(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the agencies/consultants involved with contact details
                  (if any)
                </label>

                {obj2?.PG_AgeName ? (
                  <p>{obj2?.PG_AgeName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_AgeName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>

                {obj2?.PG_Desc22 ? (
                  <p>{obj2?.PG_Desc22}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc22(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload2">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload2"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link22(e.target.files[0]);
                }}
              />
            </div>
            <div className="text-end">
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow1}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria05--(5.1.3)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the capacity development and skills enhancement
                      program
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NamCap}
                          onChange={(e) => {
                            setPG_NamCap(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NamCap}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Date of implementation
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="date"
                          name="fname"
                          id="name"
                          placeholder={PG_IDate}
                          onChange={(e) => {
                            setPG_IDate(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_IDate}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students enrolled
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_StdEnr}
                          onChange={(e) => {
                            setPG_StdEnr(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_StdEnr}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the agencies/consultants involved with contact
                      details (if any)
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_AgeName}
                          onChange={(e) => {
                            setPG_AgeName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_AgeName}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit2 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc22}
                          onChange={(e) => setPG_Desc22(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc22}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload2">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload1"
                          onChange={(e) => setPG_Link22(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link22?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit2(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat2();
                    handleClose1();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.1.4)</h5>
            <div className="row">
              <div className="col-md-12 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of students placed through campus placement
                </label>
                {obj3?.PG_CampName ? (
                  <p>{obj3?.PG_CampName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_CampName(e.target.value);
                    }}
                  />
                )}
              </div>
              <h6 className="text-center text-decoration-underline">
                (Name of the Activity conducted by the HEI to offer guidance for
                competitive examinations/ career counseling offered by the
                institution during the last five years)
              </h6>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the Activity
                </label>
                {obj3?.PG_Nameact ? (
                  <p>{obj3?.PG_Nameact}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Nameact(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of students attended / participated
                </label>
                {obj3?.PG_NamePart ? (
                  <p>{obj3?.PG_NamePart}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NamePart(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj3?.PG_Desc23 ? (
                  <p>{obj3?.PG_Desc23}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc23(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link23(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="text-end">
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn  btn-warning"
                    onClick={handleShow2}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria05--(5.1.4)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of students placed through campus placement
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_CampName}
                          onChange={(e) => {
                            setPG_CampName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_CampName}</>
                    )}
                  </div>
                  <h6 className="text-center text-decoration-underline">
                    (Name of the Activity conducted by the HEI to offer guidance
                    for competitive examinations/ career counseling offered by
                    the institution during the last five years)
                  </h6>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the Activity
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Nameact}
                          onChange={(e) => {
                            setPG_Nameact(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Nameact}</>
                    )}
                  </div>
                  <div className="col-md-8 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of students attended / participated
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NamePart}
                          onChange={(e) => {
                            setPG_NamePart(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NamePart}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit3 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc23}
                          onChange={(e) => setPG_Desc23(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc23}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="upload3">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit3 ? (
                      <>
                        <input
                          className=" name"
                          type="file"
                          name="Year"
                          id="upload3 "
                          onChange={(e) => setPG_Link23(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{PG_Link23?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit3(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat3();
                    handleClose2();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.2.1)</h5>
            <div className="row">
              <div className="col-md-12 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of student placed and contact details
                </label>
                {obj4?.PG_PlaName ? (
                  <p>{obj4?.PG_PlaName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_PlaName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Program graduated from
                </label>
                {obj4?.PG_Graduate ? (
                  <p>{obj4?.PG_Graduate}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Graduate(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the employer with contact details
                </label>
                {obj4?.PG_ConDetails ? (
                  <p>{obj4?.PG_ConDetails}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_ConDetails(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Pay package at appointment (In INR per annum)
                </label>
                {obj4?.PG_Pay ? (
                  <p>{obj4?.PG_Pay}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Pay(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj4?.PG_Desc24 ? (
                  <p>{obj4?.PG_Desc24}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc24(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload4">
                {" "}
                Upload the relevant document
              </label>

              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload4"
                accept="image/*"
                onChange={(e) => {
                  setPG_Link24(e.target.files[0]);
                }}
              />
            </div>

            <div className="text-end">
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow3}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria05--(5.2.1)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of student placed and contact details
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_PlaName}
                          onChange={(e) => {
                            setPG_PlaName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PlaName}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the employer with contact details
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Graduate}
                          onChange={(e) => {
                            setPG_Graduate(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Graduate}</>
                    )}
                  </div>
                  <div className="col-md-5 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program graduated from
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_ConDetails}
                          onChange={(e) => {
                            setPG_ConDetails(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_ConDetails}</>
                    )}
                  </div>
                  <div className="col-md-7 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Pay package at appointment (In INR per annum)
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Pay}
                          onChange={(e) => {
                            setPG_Pay(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Pay}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit4 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc24}
                          onChange={(e) => setPG_Desc24(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc24}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload4"
                          onChange={(e) => setPG_Link24(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Link24}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit4(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat4();
                    handleClose3();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.2.2)</h5>
            <div className="row">
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of student enrolling into higher education
                </label>
                {obj5?.PG_HighName ? (
                  <p>{obj5?.PG_HighName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_HighName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Program graduated from
                </label>
                {obj5?.PG_PGrad ? (
                  <p>{obj5?.PG_PGrad}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_PGrad(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of institution joined
                </label>
                {obj5?.PG_Institute ? (
                  <p>{obj5?.PG_Institute}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Institute(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of programme admitted to
                </label>
                {obj5?.PG_Admitted ? (
                  <p>{obj5?.PG_Admitted}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_Admitted(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj5?.PG_Desc25 ? (
                  <p>{obj5?.PG_Desc25}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc25(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload5">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload5"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link25(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="text-end">
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat5();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow4}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show4} onHide={handleClose4}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria05_522</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-8 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of student enrolling into higher education
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_HighName}
                          onChange={(e) => {
                            setPG_HighName(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_HighName}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Program graduated from
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_PGrad}
                          onChange={(e) => {
                            setPG_PGrad(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_PGrad}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of institution joined
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Institute}
                          onChange={(e) => {
                            setPG_Institute(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Institute}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of programme admitted to
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Admitted}
                          onChange={(e) => {
                            setPG_Admitted(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Admitted}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit5 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc25}
                          onChange={(e) => setPG_Desc25(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc25}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload5">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload5"
                          accept="image/*"
                          onChange={(e) => {
                            setPG_Link25(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Link25?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit5(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat5();
                    handleClose4();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5> Criteria_5 (5.2.3)</h5>
            <div className="row">
              <div className="row">
                <div className="row">
                  <div className="col-md-4 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Sl.no.
                    </label>
                    {obj6?.PG_No1 ? (
                      <p>{obj6?.PG_No1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_No1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-8 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Registration number/roll number for the exam
                    </label>
                    {obj6?.PG_RollNo1 ? (
                      <p>{obj6?.PG_RollNo1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_RollNo1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-9 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the student /qualified
                    </label>
                    {obj6?.PG_StdSelected1 ? (
                      <p>{obj6?.PG_StdSelected1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_StdSelected1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      NET
                    </label>
                    {obj6?.PG_NET1 ? (
                      <p>{obj6?.PG_NET1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_NET1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      SLET
                    </label>
                    {obj6?.PG_SLET1 ? (
                      <p>{obj6?.PG_SLET1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_SLET1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GATE
                    </label>
                    {obj6?.PG_GATE1 ? (
                      <p>{obj6?.PG_GATE1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_GATE1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GMAT
                    </label>
                    {obj6?.PG_GMAT1 ? (
                      <p>{obj6?.PG_GMAT1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_GMAT1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      CAT
                    </label>
                    {obj6?.PG_CAT1 ? (
                      <p>{obj6?.PG_CAT1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_CAT1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      GRE
                    </label>
                    {obj6?.PG_GRE1 ? (
                      <p>{obj6?.PG_GRE1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_GRE1(e.target.value);
                        }}
                      />
                    )}
                  </div>

                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      JAM
                    </label>
                    {obj6?.PG_JAM1 ? (
                      <p>{obj6?.PG_JAM1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_JAM1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      IELTS
                    </label>
                    {obj6?.PG_IELTS1 ? (
                      <p>{obj6?.PG_IELTS1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_IELTS1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      TOEFL
                    </label>
                    {obj6?.PG_TOEFL1 ? (
                      <p>{obj6?.PG_TOEFL1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_TOEFL1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-3 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Civil Services
                    </label>
                    {obj6?.PG_Civil1 ? (
                      <p>{obj6?.PG_Civil1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_Civil1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-9 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      State government examinations
                    </label>
                    {obj6?.PG_SGExam1 ? (
                      <p>{obj6?.PG_SGExam1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_SGExam1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Other examinations conducted by the State / Central
                      Government Agencies (Specify)
                    </label>
                    {obj6?.PG_OtherExam1 ? (
                      <p>{obj6?.PG_OtherExam1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_OtherExam1(e.target.value);
                        }}
                      />
                    )}
                  </div>

                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Total
                    </label>
                    {obj6?.PG_Total1 ? (
                      <p>{obj6?.PG_Total1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_Total1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Grand Total
                    </label>
                    {obj6?.PG_GTotal1 ? (
                      <p>{obj6?.PG_GTotal1}</p>
                    ) : (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setPG_GTotal1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description (min 500 words)
                    </label>
                    {obj6?.PG_Desc26 ? (
                      <p>{obj6?.PG_Desc26}</p>
                    ) : (
                      <textarea
                        className="vi_0"
                        id="name"
                        cols={57}
                        rows={3}
                        onChange={(e) => {
                          setPG_Desc26(e.target.value);
                        }}
                      ></textarea>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload6">
                      {" "}
                      Upload the relevant document
                    </label>

                    <input
                      className="vi_0"
                      type="file"
                      name="Year"
                      id="upload6"
                      accept="image/*"
                      onChange={(e) => {
                        setPG_Link26(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              {add6 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat6();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow5}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show5} onHide={handleClose5}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria05_5.2.3</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="row">
                    <div className="row">
                      <div className="col-md-4 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Sl.no.
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_No1}
                              onChange={(e) => {
                                setPG_No1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_No1}</>
                        )}
                      </div>
                      <div className="col-md-8 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Registration number/roll number for the exam
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_RollNo1}
                              onChange={(e) => {
                                setPG_RollNo1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_RollNo1}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          PG_NameS of students selected/ qualified
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_StdSelected1}
                              onChange={(e) => {
                                setPG_StdSelected1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_StdSelected1}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          NET
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_NET1}
                              onChange={(e) => {
                                setPG_NET1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_NET1}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          SLET
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_SLET1}
                              onChange={(e) => {
                                setPG_SLET1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_SLET1}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          GATE
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_GATE1}
                              onChange={(e) => {
                                setPG_GATE1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_GATE1}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          GMAT
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_GMAT1}
                              onChange={(e) => {
                                setPG_GMAT1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_GMAT1}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          CAT
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_CAT1}
                              onChange={(e) => {
                                setPG_CAT1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_CAT1}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          GRE
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_GRE1}
                              onChange={(e) => {
                                setPG_GRE1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_GRE1}</>
                        )}
                      </div>

                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          JAM
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_JAM1}
                              onChange={(e) => {
                                setPG_JAM1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_JAM1}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          IELTS
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_IELTS1}
                              onChange={(e) => {
                                setPG_IELTS1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_IELTS1}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          TOEFL
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_TOEFL1}
                              onChange={(e) => {
                                setPG_TOEFL1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_TOEFL1}</>
                        )}
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Civil Services
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_Civil1}
                              onChange={(e) => {
                                setPG_Civil1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_Civil1}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          State government examinations
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_SGExam1}
                              onChange={(e) => {
                                setPG_SGExam1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_SGExam1}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Other examinations conducted by the State / Central
                          Government Agencies (Specify)
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_OtherExam1}
                              onChange={(e) => {
                                setPG_OtherExam1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_OtherExam1}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Total
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_Total1}
                              onChange={(e) => {
                                setPG_Total1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_Total1}</>
                        )}
                      </div>
                      <div className="col-md-6 mt-3 mb-3">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Grand Total
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="text"
                              name="fname"
                              id="name"
                              placeholder={PG_GTotal1}
                              onChange={(e) => {
                                setPG_GTotal1(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_GTotal1}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3 ">
                        <label className="content1" htmlFor="email">
                          {" "}
                          Description(min 500 words)
                        </label>

                        {edit6 ? (
                          <>
                            <textarea
                              className="vi_0"
                              type="text"
                              name="email"
                              id="name"
                              placeholder={PG_Desc26}
                              onChange={(e) => setPG_Desc26(e.target.value)}
                            />
                          </>
                        ) : (
                          <>{PG_Desc26}</>
                        )}
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="content1" htmlFor="upload6">
                          {" "}
                          Upload the relevant document
                        </label>

                        {edit6 ? (
                          <>
                            <input
                              className="vi_0"
                              type="file"
                              name="email"
                              id="upload6"
                              accept="image/*"
                              onChange={(e) => {
                                setPG_Link26(e.target.files[0]);
                              }}
                            />
                          </>
                        ) : (
                          <>{PG_Link26?.name}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit6(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat6();
                    handleClose5();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.3.1)</h5>
            <div className="row">
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the award/ medal
                </label>
                {obj7?.PG_Meda ? (
                  <p>{obj7?.PG_Meda}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="fname"
                    id="name"
                    onChange={(e) => {
                      setPG_Meda(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Team / Individual
                </label>
                {obj7?.PG_TIndi ? (
                  <p>{obj7?.PG_TIndi}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_TIndi(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  University/State/National/ International
                </label>
                1
                {obj7?.PG_USNI1 ? (
                  <p>{obj7?.PG_USNI1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_USNI1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Sports/ Cultural
                </label>
                {obj7?.PG_SCult ? (
                  <p>{obj7?.PG_SCult}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_SCult(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the student
                </label>
                {obj7?.PG_NaStd ? (
                  <p>{obj7?.PG_NaStd}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NaStd(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj7?.PG_Desc27 ? (
                  <p>{obj7?.PG_Desc27}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={77}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc27(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload7">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload7"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link27(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="text-end">
              {add7 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat7();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow6}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show6} onHide={handleClose6}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria05__(5.3.1)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the award/ medal
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_Meda}
                          onChange={(e) => {
                            setPG_Meda(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Meda}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Team / Individual
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_TIndi}
                          onChange={(e) => {
                            setPG_TIndi(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_TIndi}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      University/State/National/ International
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_USNI1}
                          onChange={(e) => {
                            setPG_USNI1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_USNI1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Sports/ Cultural
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_SCult}
                          onChange={(e) => {
                            setPG_SCult(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_SCult}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the student
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={PG_NaStd}
                          onChange={(e) => {
                            setPG_NaStd(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_NaStd}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit7 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc27}
                          onChange={(e) => setPG_Desc27(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc27}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload7">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload7"
                          accept="image/*"
                          onChange={(e) => {
                            setPG_Link27(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Link27?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit7(true)}
                >
                  Edit
                </button>
                <button
                  classNaame="btn btn-success"
                  onClick={() => {
                    cat7();
                    handleClose6();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_5 (5.3.3)</h5>
            <div className="row">
              <div className="col-md-12 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the student participated
                </label>
                {obj8?.PG_NStdParti ? (
                  <p>{obj8?.PG_NStdParti}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_NStdParti(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Date of event/competition (DD-MM-YYYY)
                </label>
                {obj8?.PG_EDate ? (
                  <p>{obj8?.PG_EDate}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_EDate(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 mb-3">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the event/competition
                </label>
                {obj8?.PG_EName ? (
                  <p>{obj8?.PG_EName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setPG_EName(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj8?.PG_Desc28 ? (
                  <p>{obj8?.PG_Desc28}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={87}
                    rows={3}
                    onChange={(e) => {
                      setPG_Desc28(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload8">
                  {" "}
                  Upload the relevant document
                </label>

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload8"
                  accept="image/*"
                  onChange={(e) => {
                    setPG_Link28(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="text-end">
              {add8 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat8();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow7}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <Modal size="lg" show={show7} onHide={handleClose7}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Criteria05__(5.3.3)</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the student participated
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_NStdParti}
                          onChange={(e) => setPG_NStdParti(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_NStdParti}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date of event/competition (DD-MM-YYYY)
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="date"
                          name="email"
                          id="name"
                          placeholder={PG_EDate}
                          onChange={(e) => setPG_EDate(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_EDate}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 mb-3">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the event/competition
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_EName}
                          onChange={(e) => setPG_EName(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_EName}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>

                    {edit8 ? (
                      <>
                        <textarea
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={PG_Desc28}
                          onChange={(e) => setPG_Desc28(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{PG_Desc28}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload8">
                      {" "}
                      Upload the relevant document
                    </label>

                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload8"
                          accept="image/*"
                          onChange={(e) => {
                            setPG_Link28(e.target.files[0]);
                          }}
                        />
                      </>
                    ) : (
                      <>{PG_Link28?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit8(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    cat8();
                    handleClose7();
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => pgcriteria5()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PG_Criteria005;
