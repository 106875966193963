import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";

function DisplayFaculty() {
  let displayFaculty = JSON.parse(sessionStorage.getItem("Admin"));

  const [addFaculty, setFacultyAdded] = useState([]);
  const getallregisteruser = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/paf/getallpusers"
      );
      if (res.status === 200) {
        setFacultyAdded(res.data.AddFaculty);
      }
    } catch (error) {
      console.log(error);
      setFacultyAdded(error.response.data.AddFaculty);
    }
  };

  // ==========================================
  useEffect(() => {
    displayFaculty = JSON.parse(sessionStorage.getItem("Admin"));
    if (!displayFaculty) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      getallregisteruser();
    }
  }, []);

  //  ===========================================

  useEffect(() => {
    getallregisteruser();
  }, []);

  return (
    <>
      <div className="add-gr">
        <div className="container">
          <h5 className="mt-4">Faculty List</h5>
          <div
            className="ad-b mt-4"
            style={{
              display: "flex",
              gap: "21px",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <div className="do-sear mt-2 d-flex ">
              <input type="text" placeholder="Search" className="vi_0" />
              <button>Search</button>
            </div>
            {/* <div className="">
              {" "}
              <button onClick={handleShow}>
                Add Faculty{" "}
                <i
                  class="fa-solid fa-plus fa-sm"
                  style={{ color: "#ffffff" }}
                ></i>
              </button>
            </div> */}
          </div>
        </div>
        <div className="container">
          <div className="mo-gra mt-5">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th> Faculty Name</th>
                  <th>Email</th>
                  <th>Phone No</th>
                  <th>Faculty Type</th>
                  <th>Gender</th>
                  <th>Designation</th>
                </tr>
              </thead>
              <tbody>
                {addFaculty?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.FName}</td>
                      <td>{item?.Email}</td>
                      <td>{item?.PNumber}</td>
                      <td>{item?.ftype}</td>
                      <td>{item?.Gender}</td>
                      <td>{item?.Designation}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DisplayFaculty;
