import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

import exportFromJSON from "export-from-json";
const UG_Criteria03 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);
  const [edit7, setedit7] = useState(false);
  const [edit8, setedit8] = useState(false);

  const [ProName, setProName] = useState("");
  const [PrinciName, setPrinciName] = useState("");
  const [PrinciDept, setPrinciDept] = useState("");
  const [YOfAw, setYOfAw] = useState("");
  const [ASanctioned, setASanctioned] = useState("");
  const [DProject, setDProject] = useState("");
  const [NFunding, setNFunding] = useState("");
  const [Type, setType] = useState("");
  const [Desc12, setDesc12] = useState("");
  const [Link12, setLink12] = useState("");

  const [NPati, setNPati] = useState("");
  const [FDate, setFDate] = useState("");
  const [TDate, setTDate] = useState("");
  const [NWorkshop, setNWorkshop] = useState("");
  const [Desc13, setDesc13] = useState("");
  const [Link13, setLink13] = useState("");

  const [TPapaer, setTPapaer] = useState("");
  const [AName, setAName] = useState("");
  const [TDept, setTDept] = useState("");
  const [JName, setJName] = useState("");
  const [PubliYear, setPubliYear] = useState("");
  const [IISSN, setIISSN] = useState("");
  const [UUGC, setUUGC] = useState("");
  const [Desc14, setDesc14] = useState("");
  const [Link14, setLink14] = useState("");
  const [Link15, setLink15] = useState("");

  const [SNo, setSNo] = useState("");
  const [NTea, setNTea] = useState("");
  const [Title1, setTitle1] = useState("");
  const [Title2, setTitle2] = useState("");
  const [Title3, setTitle3] = useState("");
  const [YPub, setYPub] = useState("");
  const [IIISBN, setIIISBN] = useState("");
  const [NPub, setNPub] = useState("");
  const [TimeWhether, setTimeWhether] = useState("");
  const [Desc15, setDesc15] = useState("");
  const [Link16, setLink16] = useState("");

  const [ActiN, setActiN] = useState("");
  const [YearA, setYearA] = useState("");
  const [INAme, setINAme] = useState("");
  const [Bodies, setBodies] = useState("");
  const [Desc16, setDesc16] = useState("");
  const [Link17, setLink17] = useState("");

  const [NaActi, setNaActi] = useState("");
  const [Organ, setOrgan] = useState("");
  const [SchName, setSchName] = useState("");
  const [ActiYear, setActiYear] = useState("");
  const [NStudents, setNStudents] = useState("");
  const [Desc17, setDesc17] = useState("");
  const [Link18, setLink18] = useState("");

  const [SlNo, setSlNo] = useState("");
  const [Collo, setCollo] = useState("");
  const [NCollo, setNCollo] = useState("");
  const [NameParti, setNameParti] = useState("");
  const [ColloYear, setColloYear] = useState("");
  const [Dur, setDur] = useState("");
  const [Desc18, setDesc18] = useState("");
  const [Link19, setLink19] = useState("");

  const [NMou, setNMou] = useState("");
  const [Mou, setMou] = useState("");
  const [Durat, setDurat] = useState("");
  const [List, setList] = useState("");
  const [Desc19, setDesc19] = useState("");
  const [Link20, setLink20] = useState("");

  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  const [UG_Criteria03, setUG_Criteria03] = useState([]);
  const [Value, setValue] = useState({});

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Cri3.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let tableMarkup1 = `
        <table>
          <tr>
            <td>Is it listed in UGC Care list/Scopus/Web of Science/other, mention</td>
            <td>${item?.Criteria03_321?.UUGC}</td>
          </tr>
          <tr>
            <td>Link to website of the Journal</td>
            <td>${item?.Criteria03_321?.Link14}</td>
          </tr>
          <tr>
            <td>Link to article/paper/abstract of the article</td>
            <td>${item?.Criteria03_321?.Link15}</td>
          </tr>
        </table>
      `;
        let obj = {
          //  Criteria 3_312

          Year: item?.Year,
          "Criteria_(3.1.2)": item?.Criteria03_312?.criteria,
          "Name of the Principal Investigator/Co-Investigator":
            item?.Criteria03_312?.PrinciName,
          "Name of the Project/ Endowments, Chairs":
            item?.Criteria03_312?.ProName,
          "Department of Principal Investigator":
            item?.Criteria03_312?.PrinciDept,
          "Year of Award": item?.Criteria03_312?.YOfAw,
          "Amount Sanctioned": item?.Criteria03_312?.ASanctioned,
          "Duration of the project": item?.Criteria03_312?.DProject,
          "Name of the Funding Agency": item?.Criteria03_312?.NFunding,
          "Type (Government/non-Government)": item?.Criteria03_312?.Type,
          "Description(min 500 words)_(3.1.2)": item?.Criteria03_312?.Desc12,
          "Upload the relevant document_(3.1.2)": item?.Criteria03_312?.Link12,

          // Criteria 3_313

          "Criteria_(3.1.3)": item?.Criteria03_313?.criteria,
          "Number of Participants": item?.Criteria03_313?.NPati,
          "Date (From)": moment(item?.Criteria03_313?.FDate).format(
            "DD-MM-YYYY"
          ),
          "Date (To)": moment(item?.Criteria03_313?.TDate).format("DD-MM-YYYY"),
          "Name of the workshop/ seminar/ conference":
            item?.Criteria03_313?.NWorkshop,
          "Description(min 500 words)_(3.1.3)": item?.Criteria03_313?.Desc13,
          "Upload the relevant document_(3.1.3)": item?.Criteria03_313?.Link13,

          // Criteria 3 _321
          "Criteria_(3.2.1)": item?.Criteria03_321?.criteria,
          "Title of paper": item?.Criteria03_321?.TPapaer,
          "Name of the author/s": item?.Criteria03_321?.AName,
          "Department of the teacher": item?.Criteria03_321?.TDept,
          "Name of journal": item?.Criteria03_321?.JName,
          "Year of publication": item?.Criteria03_321?.PubliYear,
          "ISSN number": item?.Criteria03_321?.IISSN,
          "Link to the recognition in UGC enlistment of the Journal /Digital Object Identifier (doi) number":
            tableMarkup1,
          "Description(min 500 words)_(3.2.1)": item?.Criteria03_321?.Desc14,
          // "Upload the relevant document_(3.2.1)": item?.Criteria03_321?.Link14,
          // "Upload the relevant document_(3.2.1)": item?.Criteria03_321?.Link15,

          // Criteria 3_322
          "Criteria_(3.2.2)": item?.Criteria03_322?.criteria,
          "Sl.No": item?.Criteria03_322?.SNo,
          "Name of the teacher": item?.Criteria03_322?.NTea,
          "Title of the book/chapters published": item?.Criteria03_322?.Title1,
          "Title of the paper": item?.Criteria03_322?.Title2,
          "Title of the proceedings of the conference":
            item?.Criteria03_322?.Title3,
          "Year of publication1": item?.Criteria03_322?.YPub,
          "ISBN/ISSN number of the proceeding": item?.Criteria03_322?.IIISBN,
          "Name of the publisher": item?.Criteria03_322?.NPub,
          "Whether at the time of publication Affiliating InstitutionWas same":
            item?.Criteria03_322?.TimeWhether,
          "Description (min 500 words)_(3.2.2)": item?.Criteria03_322?.Desc15,
          "Upload the relevant document_(3.2.2)": item?.Criteria03_322?.Link16,

          // Criteria  3_332

          "Criteria_(3.3.2)": item?.Criteria03_332?.criteria,
          "Name of the activity": item?.Criteria03_332?.ActiN,
          "Year of award": item?.Criteria03_332?.YearA,
          "Name of the Award/ recognition for Institution":
            item?.Criteria03_332?.INAme,
          "Name of the Awarding government/ government recognised bodies":
            item?.Criteria03_332?.Bodies,
          "Description (min 500 words)_(3.3.2)": item?.Criteria03_332?.Desc16,
          "Upload the relevant document_(3.3.2)": item?.Criteria03_332?.Link17,

          //  Criteria 3_333
          "Criteria_(3.3.3)": item?.Criteria03_333?.criteria,
          "Name of the Activity": item?.Criteria03_333?.NaActi,
          "Organising unit/ agency/ collaborating agency":
            item?.Criteria03_333?.Organ,
          "Name of the scheme": item?.Criteria03_333?.SchName,
          "Year of the activity": item?.Criteria03_333?.ActiYear,
          "Number of students participated in such activities":
            item?.Criteria03_333?.NStudents,
          "Description(min 500 words)_(3.3.3)": item?.Criteria03_333?.Desc17,
          "Upload the relevant document_(3.3.3)": item?.Criteria03_333?.Link18,

          // Criteria 3_341
          "Criteria_(3.4.1)": item?.Criteria03_341?.criteria,
          "SL.No": item?.Criteria03_341?.SlNo,
          "Title of the collaborative activity": item?.Criteria03_341?.Collo,
          "   Name of the collaborating / linking agency with contact details":
            item?.Criteria03_341?.NCollo,
          "Name of the participant": item?.Criteria03_341?.NameParti,
          "Year of collaboration": item?.Criteria03_341?.ColloYear,
          Duration: item?.Criteria03_341?.Dur,
          "Description(min 500 words)_(3.4.1)": item?.Criteria03_341?.Desc18,
          "Upload the relevant document_(3.4.1)": item?.Criteria03_341?.Link19,

          // Criteria 3_342

          "Criteria_(3.4.2)": item?.Criteria03_342?.criteria,
          "   Name of the institution/ industry/ corporate house with whom MoU is signed":
            item?.Criteria03_342?.NMou,
          "Year of signing MOU": item?.Criteria03_342?.Mou,
          Duration1: item?.Criteria03_342?.Durat,
          "  List the actual activities under each MOU and web links year-wise":
            item?.Criteria03_342?.List,
          "Description(min 500 words)_(3.4.2)": item?.Criteria03_342?.Desc19,
          "Upload the relevant document_(3.4.2)": item?.Criteria03_342?.Link20,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const editCriteria03_312 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc12;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc12) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editCriteria03_312/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          criteria: "3.1.1 & 3.1.2",
          ProName: ProName,
          PrinciName: PrinciName,
          PrinciDept: PrinciDept,
          YOfAw: YOfAw,
          ASanctioned: ASanctioned,
          DProject: DProject,
          NFunding: NFunding,
          Type: Type,
          Desc12: Desc12,
          Link12: Link12,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri03details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria03_313 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc13;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc13) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria03_313/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          criteria: "3.1.3",
          NPati: NPati,
          FDate: FDate,
          TDate: TDate,
          NWorkshop: NWorkshop,
          Desc13: Desc13,
          Link13: Link13,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri03details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria03_321 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc14;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc14) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria03_321/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          criteria: "3.2.1",
          TPapaer: TPapaer,
          AName: AName,
          TDept: TDept,
          JName: JName,
          PubliYear: PubliYear,
          IISSN: IISSN,
          UUGC: UUGC,
          Desc14: Desc14,
          Link14: Link14,
          Link15: Link15,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri03details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria03_322 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc15;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc15) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria03_322/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          criteria: "3.2.2",
          SNo: SNo,
          NTea: NTea,
          Title1: Title1,
          Title2: Title2,
          Title3: Title3,
          YPub: YPub,
          IIISBN: IIISBN,
          NPub: NPub,
          TimeWhether: TimeWhether,
          Desc15: Desc15,
          Link16: Link16,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri03details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria03_332 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc16;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc16) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria03_332/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          criteria: "3.3.2",
          ActiN: ActiN,
          YearA: YearA,
          INAme: INAme,
          Bodies: Bodies,
          Desc16: Desc16,
          Link17: Link17,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri03details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editCriteria03_333 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc17;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc17) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria03_333/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          criteria: "3.3.3 & 3.3.4",
          NaActi: NaActi,
          Organ: Organ,
          SchName: SchName,
          ActiYear: ActiYear,
          NStudents: NStudents,
          Desc17: Desc17,
          Link18: Link18,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri03details();
          setedit6(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria03_341 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc18;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc18) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria03_341/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          criteria: "3.4.1",
          SlNo: SlNo,
          Collo: Collo,
          NCollo: NCollo,
          NameParti: NameParti,
          ColloYear: ColloYear,
          Dur: Dur,
          Desc18: Desc18,
          Link19: Link19,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri03details();
          setedit7(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const editCriteria03_342 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Desc19;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Desc19) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editCriteria03_342/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          criteria: "3.4.2",
          NMou: NMou,
          Mou: Mou,
          Durat: Durat,
          List: List,
          Desc19: Desc19,
          Link20: Link20,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getcri03details();
          setedit8(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const getcri03details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria03"
      );
      if (res.status === 200) {
        setUG_Criteria03(res.data.ugcriteria03);
        setdata1(res.data.ugcriteria03);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  useEffect(() => {
    getcri03details();
  }, []);
  console.log(Value);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center p-2">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                UnderGraduate_Criteria-03
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/ugcriteria003">
                <button className="btn-success btn">
                  Add Criteria 3
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Name of the Project/ Endowments, Chairs</th>
                  <th>Name of the Principal Investigator/Co-Investigator</th>
                  <th>Department of Principal Investigator</th>
                  <th>Year of Award</th>
                  <th>Amount Sanctioned</th>
                  <th>Duration of the project</th>
                  <th>Name of the Funding Agency</th>
                  <th>Type (Government/non-Government)</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Number of Participants</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Name of the workshop/ seminar/ conference</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Department of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th> Name of the activity</th>
                  <th>Year of award</th>
                  <th>Name of the Award/ recognition for Institution</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>

                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of the activity</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>

                  <th>Sl. No.</th>
                  <th>Title of the collaborative activity</th>
                  <th>
                    Name of the collaborating / linking agency with contact
                    details
                  </th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the institution/ industry/ corporate house with whom
                    MoU is signed
                  </th>
                  <th>Year of signing MOU</th>
                  <th>Duration</th>
                  <th>
                    List the actual activities under each MOU and web links
                    year-wise
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {UG_Criteria03?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Criteria03_312?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_312?.ProName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.PrinciName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.PrinciDept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.YOfAw?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_312?.ASanctioned?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_312?.DProject?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_312?.NFunding?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_312?.Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_313?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_313?.NPati?.toString()?.includes(
                        SearchItem
                      ) ||
                      moment(item1?.Criteria03_313?.FDate)
                        .format("DD/MM/YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Criteria03_313?.TDate)
                        .format("DD/MM/YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_313?.NWorkshop?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_313?.Desc13?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_321?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_321?.TPapaer?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_321?.AName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_321?.PubliYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_321?.IISSN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_321?.UUGC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_321?.Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_322?.SNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_322?.NTea?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.Title1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.Title2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.Title3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.YPub?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_322?.IIISBN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_322?.NPub?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.TimeWhether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_322?.Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_332?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_332?.ActiN?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_332?.YearA?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_332?.INAme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_332?.Bodies?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_332?.Desc16?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_333?.NaActi?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_333?.Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_333?.SchName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_333?.ActiYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_333?.NStudents?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_333?.Desc17?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_341?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_341?.SlNo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_341?.Collo?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_341?.NCollo?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_341?.NameParti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_341?.ColloYear?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_341?.Dur?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_341?.Desc18?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_342?.criteria
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria03_342?.NMou?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_342?.Mou?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_342?.Durat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria03_342?.List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria03_342?.Desc19?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Criteria03_312?.criteria}</td>
                      <td>{item?.Criteria03_312?.ProName}</td>
                      <td>{item?.Criteria03_312?.PrinciName}</td>
                      <td>{item?.Criteria03_312?.PrinciDept}</td>
                      <td>{item?.Criteria03_312?.YOfAw}</td>
                      <td>{item?.Criteria03_312?.ASanctioned}</td>
                      <td>{item?.Criteria03_312?.DProject}</td>
                      <td>{item?.Criteria03_312?.NFunding}</td>
                      <td>{item?.Criteria03_312?.Type}</td>
                      <td>
                        {item?.Criteria03_312?.Desc12?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria03_313?.criteria}</td>
                      <td>{item?.Criteria03_313?.NPati}</td>
                      <td>
                        {moment(item?.Criteria03_313?.FDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Criteria03_313?.TDate).format(
                          "DD-MM-YYYYY"
                        )}
                      </td>
                      <td>{item?.Criteria03_313?.NWorkshop}</td>
                      <td>
                        {item?.Criteria03_313?.Desc13?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria03_321?.criteria}</td>
                      <td>{item?.Criteria03_321?.TPapaer}</td>
                      <td>{item?.Criteria03_321?.AName}</td>
                      <td>{item?.Criteria03_321?.TDept}</td>
                      <td>{item?.Criteria03_321?.JName}</td>
                      <td>{item?.Criteria03_321?.PubliYear}</td>
                      <td>{item?.Criteria03_321?.IISSN}</td>
                      <td>{item?.Criteria03_321?.UUGC}</td>
                      <td>
                        {item?.Criteria03_321?.Desc14?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria03_322?.criteria}</td>
                      <td>{item?.Criteria03_322?.SNo}</td>
                      <td>{item?.Criteria03_322?.NTea}</td>
                      <td>{item?.Criteria03_322?.Title1}</td>
                      <td>{item?.Criteria03_322?.Title2}</td>
                      <td>{item?.Criteria03_322?.Title3}</td>
                      <td>{item?.Criteria03_322?.YPub}</td>
                      <td>{item?.Criteria03_322?.IIISBN}</td>
                      <td>{item?.Criteria03_322?.NPub}</td>
                      <td>{item?.Criteria03_322?.TimeWhether}</td>
                      <td>
                        {item?.Criteria03_322?.Desc15?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow13();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria03_332?.criteria}</td>
                      <td>{item?.Criteria03_332?.ActiN}</td>
                      <td>{item?.Criteria03_332?.YearA}</td>
                      <td>{item?.Criteria03_332?.INAme}</td>
                      <td>{item?.Criteria03_332?.Bodies}</td>
                      <td>
                        {item?.Criteria03_332?.Desc16?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow14();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria03_333?.criteria}</td>
                      <td>{item?.Criteria03_333?.NaActi}</td>
                      <td>{item?.Criteria03_333?.Organ}</td>
                      <td>{item?.Criteria03_333?.SchName}</td>
                      <td>{item?.Criteria03_333?.ActiYear}</td>
                      <td>{item?.Criteria03_333?.NStudents}</td>
                      <td>
                        {item?.Criteria03_333?.Desc17?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow15();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria03_341?.criteria}</td>
                      <td>{item?.Criteria03_341?.SlNo}</td>
                      <td>{item?.Criteria03_341?.Collo}</td>
                      <td>{item?.Criteria03_341?.NCollo}</td>
                      <td>{item?.Criteria03_341?.NameParti}</td>
                      <td>{item?.Criteria03_341?.ColloYear}</td>
                      <td>{item?.Criteria03_341?.Dur}</td>
                      <td>
                        {item?.Criteria03_341?.Desc18?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow16();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Criteria03_342?.criteria}</td>
                      <td>{item?.Criteria03_342?.NMou}</td>
                      <td>{item?.Criteria03_342?.Mou}</td>
                      <td>{item?.Criteria03_342?.Durat}</td>
                      <td>{item?.Criteria03_342?.List}</td>
                      <td>
                        {item?.Criteria03_342?.Desc19?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow17();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* View Model */}
        <Modal
          size="lg"
          show={show}
          onHide={() => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            setedit6(false);
            setedit7(false);
            setedit8(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              {" "}
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                UnderGraduate_Criteria-03
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 m-0 ">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Project/ Endowments, Chairs
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria03_312?.ProName}
                        onChange={(e) => {
                          setProName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.ProName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Principal Investigator/Co-Investigator
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_312?.PrinciName}
                        onChange={(e) => {
                          setPrinciName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.PrinciName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Department of Principal Investigator
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_312?.PrinciDept}
                        onChange={(e) => {
                          setPrinciDept(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.PrinciDept}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of Award
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="number"
                        id="name"
                        placeholder={Value?.Criteria03_312?.YOfAw}
                        onChange={(e) => {
                          setYOfAw(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.YOfAw}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Amount Sanctioned
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        id="name"
                        placeholder={Value?.Criteria03_312?.ASanctioned}
                        onChange={(e) => {
                          setASanctioned(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.ASanctioned}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration of the project
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_312?.DProject}
                        onChange={(e) => {
                          setDProject(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.DProject}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of Funding Agency
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_312?.NFunding}
                        onChange={(e) => {
                          setNFunding(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.NFunding}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Type (Government/non-Government)
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_312?.Type}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.Type}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_312?.Desc12}
                        onChange={(e) => setDesc12(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.Desc12}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_312?.Link12}
                        accept="image/*"
                        onChange={(e) => setLink12(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_312?.Link12}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit1 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria03_312(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------3.1.3------ */}
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Participants
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_313?.NPati}
                        onChange={(e) => {
                          setNPati(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_313?.NPati}</>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date (From)
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_313?.FDate}
                        onChange={(e) => {
                          setFDate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.Criteria03_313?.FDate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-4 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Date (To)
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="date"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_313?.TDate}
                        onChange={(e) => {
                          setTDate(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {moment(Value?.Criteria03_313?.TDate).format(
                        "DD-MM-YYYY"
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of workshop/seminar/conference
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_313?.NWorkshop}
                        onChange={(e) => {
                          setNWorkshop(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_313?.NWorkshop}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_313?.Desc13}
                        onChange={(e) => setDesc13(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_313?.Desc13}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_313?.Link13}
                        accept="image/*"
                        onChange={(e) => setLink13(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_313?.Link13}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit2 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria03_313(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span style={{ fontSize: "19px", fontWeight: "bold" }}>
                  Sub_Criteria-3.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                {/* -------3.2.1------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of paper
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria03_321?.TPapaer}
                        onChange={(e) => {
                          setTPapaer(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.TPapaer}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the author/s
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_321?.AName}
                        onChange={(e) => {
                          setAName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.AName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of the teacher
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_321?.TDept}
                        onChange={(e) => {
                          setTDept(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.TDept}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of journal
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_321?.JName}
                        onChange={(e) => {
                          setJName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.JName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of publication
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="number"
                        id="name"
                        placeholder={Value?.Criteria03_321?.PubliYear}
                        onChange={(e) => {
                          setPubliYear(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.PubliYear}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    ISSN number
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_321?.IISSN}
                        onChange={(e) => {
                          setIISSN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.IISSN}</>
                  )}
                </div>
                <h6 className="text-center text-decoration-underline">
                  Link to the recognition in UGC enlistment of the Journal
                  /Digital Object Identifier (doi) number
                </h6>
                <div className="col-md-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_321?.UUGC}
                        onChange={(e) => {
                          setUUGC(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.UUGC}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_321?.Desc14}
                        onChange={(e) => setDesc14(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.Desc14}</>
                  )}
                </div>
                <div className="col-md-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_321?.Link14}
                        accept="image/*"
                        onChange={(e) => setLink14(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.Link14}</>
                  )}
                </div>
                <div className="col-md-12">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_321?.Link15}
                        accept="image/*"
                        onChange={(e) => setLink15(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_321?.Link15}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit3 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria03_321(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria03_322?.SNo}
                        onChange={(e) => {
                          setSNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.SNo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_322?.NTea}
                        onChange={(e) => {
                          setNTea(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.NTea}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the book/chapters published
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_322?.Title1}
                        onChange={(e) => {
                          setTitle1(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.Title1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Title of the proceedings of conference
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_322?.Title2}
                        onChange={(e) => {
                          setTitle2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.Title2}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the paper
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_322?.Title3}
                        onChange={(e) => {
                          setTitle3(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.Title3}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of publication
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_322?.YPub}
                        onChange={(e) => {
                          setYPub(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.YPub}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    ISBN/ISSN number of the proceeding
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_322?.IIISBN}
                        onChange={(e) => {
                          setIIISBN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.IIISBN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the publisher
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_322?.NPub}
                        onChange={(e) => {
                          setNPub(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.NPub}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </label>

                  {edit4 ? (
                    <>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        style={{ width: "270px" }}
                        placeholder={Value?.Criteria03_322?.TimeWhether}
                        onChange={(e) => {
                          setTimeWhether(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.TimeWhether}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_322?.Desc15}
                        onChange={(e) => setDesc15(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.Desc15}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_322?.Link16}
                        accept="image/*"
                        onChange={(e) => setLink16(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_322?.Link16}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit4 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria03_322(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                {/* -------3.3.2------ */}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    Name of the activity
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria03_332?.ActiN}
                        onChange={(e) => {
                          setActiN(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_332?.ActiN}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of award
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_332?.YearA}
                        onChange={(e) => {
                          setYearA(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_332?.YearA}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Award/ recognition for Institution
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_332?.INAme}
                        onChange={(e) => {
                          setINAme(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_332?.INAme}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Awarding government/ government recognised
                    bodies
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_332?.Bodies}
                        onChange={(e) => {
                          setBodies(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_332?.Bodies}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_332?.Desc16}
                        onChange={(e) => setDesc16(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_332?.Desc16}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_332?.Link17}
                        accept="image/*"
                        onChange={(e) => setLink17(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_332?.Link17}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit5 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria03_332(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the activity
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria03_333?.NaActi}
                        onChange={(e) => {
                          setNaActi(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_333?.NaActi}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Organising unit/agency/collaborating agency
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_333?.Organ}
                        onChange={(e) => {
                          setOrgan(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_333?.Organ}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the scheme
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_333?.SchName}
                        onChange={(e) => {
                          setSchName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_333?.SchName}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of the activity
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_333?.ActiYear}
                        onChange={(e) => {
                          setActiYear(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_333?.ActiYear}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students participated in such activities
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_333?.NStudents}
                        onChange={(e) => {
                          setNStudents(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_333?.NStudents}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit6 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_333?.Desc17}
                        onChange={(e) => setDesc17(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_333?.Desc17}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit6 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_333?.Link18}
                        accept="image/*"
                        onChange={(e) => setLink18(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_333?.Link18}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit6 ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria03_333(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit6(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.1
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Sl. No.
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria03_341?.SlNo}
                        onChange={(e) => {
                          setSlNo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_341?.SlNo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Title of the collaborative activity
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_341?.Collo}
                        onChange={(e) => {
                          setCollo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_341?.Collo}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the collaborating / linking agency with contact
                    details
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_341?.NCollo}
                        onChange={(e) => {
                          setNCollo(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_341?.NCollo}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the participant
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_341?.NameParti}
                        onChange={(e) => {
                          setNameParti(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_341?.NameParti}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of collaboration
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_341?.ColloYear}
                        onChange={(e) => {
                          setColloYear(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_341?.ColloYear}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_341?.Dur}
                        onChange={(e) => {
                          setDur(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_341?.Dur}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit7 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_341?.Desc18}
                        onChange={(e) => setDesc18(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_341?.Desc18}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit7 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_341?.Link19}
                        accept="image/*"
                        onChange={(e) => setLink19(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_341?.Link19}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit7 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria03_341(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit7(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                {/* -------3.4.2------ */}
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the institution/ industry/ corporate house with whom
                    MoU is signed
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.Criteria03_342?.NMou}
                        onChange={(e) => {
                          setNMou(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_342?.NMou}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of signing MOU
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_342?.Mou}
                        onChange={(e) => {
                          setMou(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_342?.Mou}</>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_342?.Durat}
                        onChange={(e) => {
                          setDurat(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_342?.Durat}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    List the actual activities under each MOU and web links
                    year-wise
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Criteria03_342?.List}
                        onChange={(e) => {
                          setList(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_342?.List}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit8 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.Criteria03_342?.Desc19}
                        onChange={(e) => setDesc19(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_342?.Desc19}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit8 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="Year"
                        id="name"
                        placeholder={Value?.Criteria03_342?.Link20}
                        accept="image/*"
                        onChange={(e) => setLink20(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Criteria03_342?.Link20}</>
                  )}
                </div>
                {Value?.approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit8 ? (
                        <>
                          <button
                            className="btn btn-success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editCriteria03_342(e, Value?._id);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit8(true)}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
                setedit1(false);
                setedit2(false);
                setedit3(false);
                setedit4(false);
                setedit5(false);
                setedit6(false);
                setedit7(false);
                setedit8(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea className="form-control" style={{ height: "200px" }} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary">Submit </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria03_312?.Desc12}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria03_313?.Desc13}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria03_321?.Desc14}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria03_322?.Desc15}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria03_332?.Desc16}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_6 */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria03_333?.Desc17}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_7 */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria03_341?.Desc18}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_8 */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Criteria03_342?.Desc19}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose8}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_312?.Link12}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_313?.Link13}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_321?.Link14}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_321?.Link15}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_322?.Link16}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_332?.Link17}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_333?.Link18}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_341?.Link19}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 9 model */}
        <Modal show={show17} onHide={handleClose17}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_342?.Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default UG_Criteria03;
