import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

function PDFprintA1_112() {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }
  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    console.log("ddkd1", img);
    const imgProperties = pdf.getImageProperties(img);
    console.log("ddkd2", imgProperties);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    console.log("ddkd3", pdfWidth);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    console.log("ddkd4", pdfHeight);
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

    // const input = document.getElementById("pdf");
    // const options = { scrollY: -window.scrollY, useCORS: true };
    // const canvas = await html2canvas(input, options);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "pt", [canvas.width, canvas.height]);
    // pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    pdf.save("1.1.2&1.2.2.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);
  if (location?.state == null) {
    navigate("/A_01_112");
  }
  return (
    <div>
      <div className="m-auto ">
        <div id="pdf">
          <div className="p-2">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className=" pdfimg pt-2 "
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                year:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.Auto_Year}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state[location?.state?.length - 1]?.criteria}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>
                    {
                      location?.state["0"]["Auto_Criteria01_112"][
                        "Auto_criteria"
                      ]
                    }
                  </>
                )}
              </div>
            </div>

            <div className="container mt-5 " style={{ padding: "0px 100px" }}>
              {finalArr?.map((item, i) => {
                return (
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <h6>Program Name</h6>
                      </div>
                      <div className="col-lg-6">
                        <p>{item?.Auto_Criteria01_112?.Auto_Pname}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <h6>Status of implementation of CBCS / ECS</h6>
                      </div>
                      <div className="col-lg-6">
                        <p>{item?.Auto_Criteria01_112?.Auto_SOInt}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <h6>Programme code</h6>
                      </div>
                      <div className="col-lg-6">
                        <p>{item?.Auto_Criteria01_112?.Auto_Pcode}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <h6>Year of Introduction</h6>
                      </div>
                      <div className="col-lg-6">
                        <p>{item?.Auto_Criteria01_112?.Auto_YOInt}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <h6>Year of revision (if any)</h6>
                      </div>
                      <div className="col-lg-6">
                        <p>{item?.Auto_Criteria01_112?.Auto_YRevi}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <h6>Year of implementation of CBCS / ECS</h6>
                      </div>
                      <div className="col-lg-6">
                        <p>{item?.Auto_Criteria01_112?.Auto_YOImple}</p>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-lg-6">
                        <h6>
                          If revision has been carried out in the syllabus
                          during last 5 years, Percentage of content added or
                          replaced
                        </h6>
                      </div>
                      <div className="col-lg-6 ">
                        <p>{item?.Auto_Criteria01_112?.Auto_Revi}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <h6>Description</h6>
                      </div>
                      <div className="col-lg-12">
                        <p>{item?.Auto_Criteria01_112?.Auto_Desc1}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <h6>Document</h6>
                      </div>
                      <div className="col-lg-6">
                        <p>
                          {" "}
                          <img
                            src={`https://brightnaac.co.in/Auto_Criteria01/${item?.Auto_Criteria01_112?.Auto_Link1}`}
                            alt="img"
                            width={50}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="text-center">
          <button className="ad" style={{}} onClick={createPDF}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default PDFprintA1_112;
