import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";

const UnCriteria6 = () => {
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [View, setView] = useState({});

  // ================Show doc modal======================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  // ===========================================================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  // ===========================================================

  //============= criteria 623 =============== //

  const [Uni_Year, setUni_Year] = useState("");
  const [Uni_yoi1, setUni_yoi1] = useState("");
  const [Uni_yoi2, setUni_yoi2] = useState("");
  const [Uni_yoi3, setUni_yoi3] = useState("");
  const [Uni_yoi4, setUni_yoi4] = useState("");
  const [Uni_doc1, setUni_doc1] = useState("");
  const [Uni_doc2, setUni_doc2] = useState("");
  const [Uni_doc3, setUni_doc3] = useState("");
  const [Uni_doc4, setUni_doc4] = useState("");
  const [Uni_desc1, setUni_desc1] = useState("");

  //============= criteria 632 =============== //
  const [Uni_Not, setUni_Not] = useState("");
  const [Uni_Asr, setUni_Asr] = useState("");
  const [Uni_NOC, setUni_NOC] = useState("");
  const [Uni_Nop, setUni_Nop] = useState("");
  const [Uni_desc2, setUni_desc2] = useState("");
  const [Uni_doc5, setUni_doc5] = useState("");

  //============= criteria 633 =============== //

  const [Uni_Sdate, setUni_Sdate] = useState("");
  const [Uni_Edate, setUni_Edate] = useState("");
  const [Uni_titlep, setUni_titlep] = useState("");
  const [Uni_Nfp, setUni_Nfp] = useState("");
  const [Uni_doc6, setUni_doc6] = useState("");
  const [Uni_desc3, setUni_desc3] = useState("");

  //============= criteria 634 =============== //

  const [Uni_Sdate1, setUni_Sdate1] = useState("");
  const [Uni_Edate1, setUni_Edate1] = useState("");
  const [Uni_namet, setUni_namet] = useState("");
  const [Uni_prgm50, setUni_prgm50] = useState("");
  const [Uni_doc7, setUni_doc7] = useState("");
  const [Uni_desc4, setUni_desc4] = useState("");

  //============= criteria 642 =============== //

  const [Uni_pgrant, setUni_pgrant] = useState("");
  const [Uni_Fundg, setUni_Fundg] = useState("");
  const [Uni_namefgov, setUni_namefgov] = useState("");
  const [Uni_doc8, setUni_doc8] = useState("");
  const [Uni_desc5, setUni_desc5] = useState("");

  //============= criteria 652 =============== //

  const [Uni_Sdate2, setUni_Sdate2] = useState("");
  const [Uni_Edate2, setUni_Edate2] = useState("");
  const [Uni_nirfs, setUni_nirfs] = useState("");
  const [Uni_aaa, setUni_aaa] = useState("");
  const [Uni_nba, setUni_nba] = useState("");
  const [Uni_cswq, setUni_cswq] = useState("");
  const [Uni_iaa, setUni_iaa] = useState("");
  const [Uni_doc9, setUni_doc9] = useState("");
  const [Uni_desc6, setUni_desc6] = useState("");

  // =============================================

  const [add1, setAdd1] = useState(true);
  const [add2, setAdd2] = useState(true);
  const [add3, setAdd3] = useState(true);
  const [add4, setAdd4] = useState(true);
  const [add5, setAdd5] = useState(true);
  const [add6, setAdd6] = useState(true);

  const [edit1, setedit1] = useState();
  const [edit2, setedit2] = useState();
  const [edit3, setedit3] = useState();
  const [edit4, setedit4] = useState();
  const [edit5, setedit5] = useState();
  const [edit6, setedit6] = useState();

  // ==================================

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getucriteria = async () => {
    try {
      let am1 = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am1));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));
      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getucriteria();
  }, []);

  const Uncriteria = async () => {
    try {
      const config = {
        url: "/PrinipalAdmin/detailsUni06",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Year: Uni_Year,
          Uni_Criteria06_623: obj1,
          Uni_Criteria06_632: obj2,
          Uni_Criteria06_633: obj3,
          Uni_Criteria06_634: obj4,
          Uni_Criteria06_642: obj5,
          Uni_Criteria06_652: obj6,
          Uni_doc1: Uni_doc1,
          Uni_doc2: Uni_doc2,
          Uni_doc3: Uni_doc3,
          Uni_doc4: Uni_doc4,
          Uni_doc5: Uni_doc5,
          Uni_doc6: Uni_doc6,
          Uni_doc7: Uni_doc7,
          Uni_doc8: Uni_doc8,
          Uni_doc9: Uni_doc9,
          addedByPri: pricipalDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");
          sessionStorage.removeItem("cat2");
          sessionStorage.removeItem("cat3");
          sessionStorage.removeItem("cat4");
          sessionStorage.removeItem("cat5");
          sessionStorage.removeItem("cat6");
          navigate("/u-Criteria6");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //get method for 1.1.3
  // const [ugcriteria01, setugcriteria01] = useState({});
  // Criteria 6.2.3
  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Uni_desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Uni_yoi1 ||
          !Uni_yoi2 ||
          !Uni_yoi3 ||
          !Uni_yoi4 ||
          !Uni_doc1 ||
          !Uni_doc2 ||
          !Uni_doc3 ||
          !Uni_doc4 ||
          !Uni_desc1
        ) {
          return alert("fill all details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setAdd1(false);
        }
      }
      let obj = {
        Uni_criteria: "6.2.3",
        Uni_yoi1: Uni_yoi1,
        Uni_yoi2: Uni_yoi2,
        Uni_yoi3: Uni_yoi3,
        Uni_yoi4: Uni_yoi4,
        Uni_doc1: Uni_doc1,
        Uni_doc2: Uni_doc2,
        Uni_doc3: Uni_doc3,
        Uni_doc4: Uni_doc4,
        Uni_desc1: Uni_desc1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getucriteria();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.3.2
  const cat2 = () => {
    const minwords = 500;
    const newText = Uni_desc2;
    const wordsArr = newText?.trim()?.split(/\s+/);

    if (add2) {
      if (
        !Uni_Not ||
        !Uni_Asr ||
        !Uni_NOC ||
        !Uni_Nop ||
        !Uni_doc5 ||
        !Uni_desc2
      ) {
        return alert("fill all details");
      }
      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      } else {
        setAdd2(false);
      }
    }

    try {
      let obj = {
        Uni_criteria: "6.3.2",
        Uni_Not: Uni_Not,
        Uni_Asr: Uni_Asr,
        Uni_NOC: Uni_NOC,
        Uni_Nop: Uni_Nop,
        Uni_doc5: Uni_doc5,
        Uni_desc2: Uni_desc2,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getucriteria();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.3.3
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Uni_desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !Uni_Sdate ||
          !Uni_Edate ||
          !Uni_titlep ||
          !Uni_Nfp ||
          !Uni_doc6 ||
          !Uni_desc3
        ) {
          return alert("fill all details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setAdd3(false);
        }
      }
      let obj = {
        Uni_criteria: "6.3.3",
        Uni_Sdate: Uni_Sdate,
        Uni_Edate: Uni_Edate,
        Uni_titlep: Uni_titlep,
        Uni_Nfp: Uni_Nfp,
        Uni_doc6: Uni_doc6,
        Uni_desc3: Uni_desc3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));
      alert("Successfully added cat3");
      getucriteria();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };

  //  Criteria 6.3.4

  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Uni_desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Uni_Sdate1 ||
          !Uni_Edate1 ||
          !Uni_namet ||
          !Uni_prgm50 ||
          !Uni_doc7 ||
          !Uni_desc4
        ) {
          return alert("fill all details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setAdd4(false);
        }
      }
      let obj = {
        Uni_criteria: "6.3.4",
        Uni_Sdate1: Uni_Sdate1,
        Uni_Edate1: Uni_Edate1,
        Uni_namet: Uni_namet,
        Uni_prgm50: Uni_prgm50,
        Uni_doc7: Uni_doc7,
        Uni_desc4: Uni_desc4,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getucriteria();
    } catch (error) {
      console.log(error);
    }
  };

  //   Criteria 6.4.2

  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Uni_desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);
      if (add5) {
        if (
          !Uni_pgrant ||
          !Uni_Fundg ||
          !Uni_namefgov ||
          !Uni_doc8 ||
          !Uni_desc5
        ) {
          return alert("fill all details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setAdd5(false);
        }
      }
      let obj = {
        Uni_criteria: "6.4.2",
        Uni_pgrant: Uni_pgrant,
        Uni_Fundg: Uni_Fundg,
        Uni_namefgov: Uni_namefgov,
        Uni_doc8: Uni_doc8,
        Uni_desc5: Uni_desc5,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getucriteria();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.5.2

  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Uni_desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !Uni_Sdate2 ||
          !Uni_Edate2 ||
          !Uni_nirfs ||
          !Uni_aaa ||
          !Uni_nba ||
          !Uni_cswq ||
          !Uni_iaa ||
          !Uni_desc6 ||
          !Uni_doc9
        ) {
          return alert("fill all details");
        }
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setAdd5(false);
        }
      }
      let obj = {
        Uni_criteria: "6.5.2",
        Uni_Sdate2: Uni_Sdate2,
        Uni_Edate2: Uni_Edate2,
        Uni_nirfs: Uni_nirfs,
        Uni_aaa: Uni_aaa,
        Uni_nba: Uni_nba,
        Uni_cswq: Uni_cswq,
        Uni_iaa: Uni_iaa,
        Uni_desc6: Uni_desc6,
        Uni_doc9: Uni_doc9,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getucriteria();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  // ==========================//
  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid   ">
          <div className="pt-4   " style={{ padding: "10px" }}>
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              University_Criteria-06
            </span>
          </div>

          <div
            className="row-fluid border mt-3"
            style={{
              padding: "30px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <div class=" col-md-6">
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Uni_Year ? (
                <p>{obj1?.Uni_Year}</p>
              ) : (
                <select
                  className="form-select form-select-sm vi_0"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setUni_Year(e.target.value)}
                >
                  <option>Select Year</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>

          <div className="container bs mt-5 p-5">
            <h3>Criteria 6.2.3</h3>
            <h5>Areas of E_Governance</h5>

            <div className="row ">
              <h6>Administration</h6>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {obj1?.Uni_yoi1 ? (
                    <p>{obj1?.Uni_yoi1}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setUni_yoi1(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>

                  <input
                    type="file"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_doc1(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <h6>Finance and Accounts</h6>

              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {obj1?.Uni_yoi2 ? (
                    <p>{obj1?.Uni_yoi2}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setUni_yoi2(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>

                  <input
                    type="file"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_doc2(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <h6>Student Admissions and Support</h6>

              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {obj1?.Uni_yoi3 ? (
                    <p>{obj1?.Uni_yoi3}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setUni_yoi3(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>

                  <input
                    type="file"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_doc3(e.target.files[0])}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <h6>Examinations</h6>
              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Year of implementation:</label>

                  {obj1?.Uni_yoi4 ? (
                    <p>{obj1?.Uni_yoi4}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setUni_yoi4(e.target.value)}
                    />
                  )}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>

                  <input
                    type="file"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_doc4(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description with minimum 500 Characters
                </label>
                {obj1?.Uni_desc1 ? (
                  <p>{obj1?.Uni_desc1}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    onChange={(e) => setUni_desc1(e.target.value)}
                  />
                )}
              </div>

              <div className="text-end mt-4">
                {add1 ? (
                  <button
                    type="submit"
                    className="btn btn-success m-2 mt-3 "
                    onClick={() => {
                      cat1();
                    }}
                  >
                    ADD
                  </button>
                ) : (
                  <>
                    {" "}
                    <Button
                      type="submit"
                      className=" m-2 mt-3"
                      onClick={handleShow1}
                      variant="warning"
                      style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                    >
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* 06-623 */}

          <Modal
            show={show1}
            onHide={() => {
              handleClose1();
              setedit1(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <h6>Administration</h6>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>

                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Uni_yoi1}
                            className="vi_0"
                            onChange={(e) => {
                              setUni_yoi1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{Uni_yoi1}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Relevant document</label>

                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload1"
                            className="vi_0"
                            placeholder={Uni_doc1}
                            onChange={(e) => setUni_doc1(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_doc1?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <h6>Finance and Accounts</h6>

                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>

                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Uni_yoi2}
                            className="vi_0"
                            onChange={(e) => setUni_yoi2(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_yoi2}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Relevant document</label>

                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload2"
                            className="vi_0"
                            placeholder={Uni_doc2}
                            onChange={(e) => setUni_doc2(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_doc2?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <h6>Student Admissions and Support</h6>

                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>

                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Uni_yoi3}
                            className="vi_0"
                            onChange={(e) => setUni_yoi3(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_yoi3}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Relevant document</label>

                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload3"
                            className="vi_0"
                            placeholder={Uni_doc3}
                            onChange={(e) => setUni_doc3(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_doc3?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <h6>Examinations</h6>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>

                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Uni_yoi4}
                            className="vi_0"
                            onChange={(e) => setUni_yoi4(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_yoi4}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Relevant document</label>

                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload4"
                            className="vi_0"
                            placeholder={Uni_doc4}
                            onChange={(e) => setUni_doc4(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{Uni_doc4?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        placeholder={Uni_desc1}
                        onChange={(e) => setUni_desc1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Uni_desc1}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit1 ? (
                <>
                  <Button
                    className="btn btn-success"
                    style={{ color: "black" }}
                    onClick={() => {
                      cat1();
                      handleClose1();
                      setedit1(false);
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit1(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container bs mt-5 p-5">
            <h3>Criteria 6.3.2</h3>
            <h6 className="mb-5">
              Average percentage of teachers provided with financial support to
              attend conferences / workshops and towards membership fee of
              professional bodies during the last five years (8)
            </h6>
            <div className="row ">
              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Name of the teacher</label>

                  {obj2?.Uni_Not ? (
                    <p>{obj2?.Uni_Not}</p>
                  ) : (
                    <input
                      type="text"
                      className="vi_0"
                      onChange={(e) => setUni_Not(e.target.value)}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Amount of support </label>

                  {obj2?.Uni_Asr ? (
                    <p>{obj2?.Uni_Asr}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=""
                      className="vi_0"
                      onChange={(e) => setUni_Asr(e.target.value)}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="lab mb-4">
              <label>
                Name of conference/ workshop attended for which financial
                support provided
              </label>

              {obj2?.Uni_NOC ? (
                <p>{obj2?.Uni_NOC}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_NOC(e.target.value)}
                />
              )}
            </div>

            <div className="lab mb-4">
              <label>
                Name of the professional body for which membership fee is
                provided
              </label>

              {obj2?.Uni_Nop ? (
                <p>{obj2?.Uni_Nop}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_Nop(e.target.value)}
                />
              )}
            </div>

            <div className="col-md-12 mb-3 ">
              <label className="content1" htmlFor="name">
                Description
              </label>
              {obj2?.Uni_desc2 ? (
                <p>{obj2?.Uni_desc2}</p>
              ) : (
                <textarea
                  className="vi_0"
                  type="text"
                  id="name"
                  // placeholder={ugdesc1}
                  onChange={(e) => setUni_desc2(e.target.value)}
                />
              )}
            </div>

            <div className="col-12">
              <div className="lab mb-4">
                <label>Upload the relevant document</label>

                <input
                  type="file"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_doc5(e.target.files[0])}
                />
              </div>
            </div>

            <div className="text-end mt-4">
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success m-2 mt-3 "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <Button
                    type="submit"
                    onClick={handleShow2}
                    className=" m-2 mt-3"
                    variant="warning"
                    style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                  >
                    Preview
                  </Button>
                </>
              )}
            </div>
          </div>

          {/* 06_632 */}
          <Modal
            show={show2}
            onHide={() => {
              handleClose2();
              setedit2(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit2</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row ">
                  <div className="col-lg-12">
                    <div className="lab mb-3">
                      <label>Name of the teacher</label>

                      {edit2 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Uni_Not}
                            className="vi_0"
                            onChange={(e) => setUni_Not(e.target.value)}
                          />{" "}
                        </>
                      ) : (
                        <>{Uni_Not}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-3">
                      <label>Amount of support received (in INR)</label>

                      {edit2 ? (
                        <>
                          <input
                            type="text"
                            placeholder={Uni_Asr}
                            className="vi_0"
                            onChange={(e) => setUni_Asr(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{Uni_Asr}</>
                      )}
                    </div>
                  </div>
                </div>

                <div className="lab mb-4">
                  <label>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={Uni_NOC}
                        className="vi_0"
                        onChange={(e) => setUni_NOC(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Uni_NOC}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    Name of the professional body for which membership fee is
                    provided
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={Uni_Nop}
                        className="vi_0"
                        onChange={(e) => setUni_Nop(e.target.value)}
                      />{" "}
                    </>
                  ) : (
                    <>{Uni_Nop}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit2 ? (
                    <>
                      <textarea
                        type="text"
                        className="vi_0"
                        id="name"
                        placeholder={Uni_desc2}
                        onChange={(e) => {
                          setUni_desc2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Uni_desc2}</>
                  )}
                </div>

                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Upload the relevant document</label>

                    {edit2 ? (
                      <input
                        type="file"
                        placeholder=" "
                        className="vi_0"
                        onChange={(e) => setUni_doc5(e.target.files[0])}
                      />
                    ) : (
                      <>{Uni_doc5?.name}</>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit2 ? (
                <>
                  <Button
                    variant="warning"
                    style={{ color: "black" }}
                    onClick={() => {
                      cat2();
                      handleClose2();
                      setedit2(false);
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => setedit2(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container bs mt-5 p-5">
            <h3>Criteria 6.3.3</h3>
            <h6 className="mb-5">
              {" "}
              Average number of professional development / administrative
              training programs organized by the institution for teaching and
              non teaching staff during the last five years (8)
            </h6>
            <div className="row">
              <div className=" col-lg-6 lab mb-4">
                <label>Date (from)</label>

                {obj3?.Uni_Sdate ? (
                  <p>{obj3.Uni_Sdate}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_Sdate(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label>Date (to)</label>

                {obj3?.Uni_Edate ? (
                  <p>{obj3?.Uni_Edate}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_Edate(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-12 lab mb-4">
                <label>No. of participants</label>

                {obj3?.Uni_Nfp ? (
                  <p>{obj3?.Uni_Nfp}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_Nfp(e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className="lab mb-4">
              <label>
                {" "}
                Title of the professional development/ administrative training
                programs organised for teaching staff (Professional development/
                administrative training programs)
              </label>

              {obj3?.Uni_titlep ? (
                <p>{obj3?.Uni_titlep}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_titlep(e.target.value)}
                />
              )}
            </div>

            <div className="col-md-12 mb-3 ">
              <label className="content1" htmlFor="name">
                {" "}
                Description with minimum 500 Characters
              </label>
              {obj3?.Uni_desc3 ? (
                <p>{obj3?.Uni_desc3}</p>
              ) : (
                <textarea
                  className="vi_0"
                  type="text"
                  id="name"
                  // placeholder={ugdesc1}
                  onChange={(e) => setUni_desc3(e.target.value)}
                />
              )}
            </div>

            <div className="col-12">
              <div className="lab mb-4">
                <label>Upload the relevant document</label>

                <input
                  type="file"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_doc6(e.target.files[0])}
                />
              </div>
            </div>
            <div className="text-end mt-4">
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success m-2 mt-3 "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <Button
                    type="submit"
                    onClick={handleShow3}
                    className=" m-2 mt-3"
                    variant="warning"
                    style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                  >
                    Preview
                  </Button>
                </>
              )}
            </div>
          </div>

          {/* 06_633 */}

          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (from)</label>

                    {edit3 ? (
                      <input
                        type="date"
                        className="vi_0"
                        placeholder={Uni_Sdate}
                        onChange={(e) => setUni_Sdate(e.target.value)}
                      />
                    ) : (
                      <>{moment(Uni_Sdate).format("DD-MM-YYYY")}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (to)</label>

                    {edit3 ? (
                      <input
                        type="date"
                        placeholder={Uni_Edate}
                        className="vi_0"
                        onChange={(e) => setUni_Edate(e.target.value)}
                      />
                    ) : (
                      <>{moment(Uni_Edate).format("DD-MM-YYYY")}</>
                    )}
                  </div>

                  <div className=" col-lg-12 lab mb-4">
                    <label>
                      Title of the professional development/ administrative
                      training programs organised for teaching staff
                      (Professional development/ administrative training
                      programs)
                    </label>

                    {edit3 ? (
                      <input
                        type="text"
                        placeholder={Uni_titlep}
                        className="vi_0"
                        onChange={(e) => setUni_titlep(e.target.value)}
                      />
                    ) : (
                      <>{Uni_titlep}</>
                    )}
                  </div>

                  <div className=" col-lg-12 lab mb-4">
                    <label>No. of participants</label>

                    {edit3 ? (
                      <input
                        type="text"
                        placeholder={Uni_Nfp}
                        className="vi_0"
                        onChange={(e) => setUni_Nfp(e.target.value)}
                      />
                    ) : (
                      <>{Uni_Nfp}</>
                    )}
                  </div>

                  <div className=" col-lg-12 lab mb-4">
                    <label>Relevant document</label>

                    {edit3 ? (
                      <input
                        type="file"
                        placeholder={Uni_doc6}
                        className="vi_0"
                        id="upload6"
                        onChange={(e) => setUni_doc6(e.target.files[0])}
                      />
                    ) : (
                      <>{Uni_doc6?.name}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3 ">
                    <label className="content1" htmlFor="name">
                      Description
                    </label>
                    {edit3 ? (
                      <textarea
                        className="vi_0"
                        type="text"
                        placeholder={Uni_desc3}
                        onChange={(e) => {
                          setUni_desc3(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Uni_desc3}</>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit3 ? (
                <>
                  {" "}
                  <Button
                    variant="warning"
                    style={{ color: "black" }}
                    onClick={() => {
                      cat3();
                      handleClose3();
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => setedit3(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container bs mt-5 p-5">
            <h3>Criteria 6.3.4</h3>
            <h6 className="mb-5">
              {" "}
              Average percentage of teachers undergoing online/ face-to-face
              Faculty Development Programmes during the last five years{" "}
            </h6>
            <div className="row">
              <div className=" col-lg-6 lab mb-4">
                <label>Date (from)</label>

                {obj4?.Uni_Sdate1 ? (
                  <p>{obj4?.Uni_Sdate1}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_Sdate1(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label>Date (to) </label>

                {obj4?.Uni_Edate1 ? (
                  <p>{obj4?.Uni_Edate1}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_Edate1(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-6 lab mb-4">
                <label>Name of teacher who attended</label>

                {obj4?.Uni_namet ? (
                  <p>{obj4?.Uni_namet}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_namet(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label>Title of the program</label>

                {obj4?.Uni_prgm50 ? (
                  <p>{obj4?.Uni_prgm50}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_prgm50(e.target.value)}
                  />
                )}
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description with minimum 500 Characters
                </label>
                {obj4?.Uni_desc4 ? (
                  <p>{obj4?.Uni_desc4}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    // placeholder={ugdesc1}
                    onChange={(e) => setUni_desc4(e.target.value)}
                  />
                )}
              </div>

              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Upload the relevant document</label>

                  <input
                    type="file"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_doc7(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
            <div className="text-end mt-4">
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success m-2 mt-3 "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <Button
                    type="submit"
                    onClick={handleShow4}
                    className=" m-2 mt-3"
                    variant="warning"
                    style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                  >
                    Preview
                  </Button>
                </>
              )}
            </div>
          </div>

          {/* 06_634*/}

          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (from) </label>

                    {edit4 ? (
                      <input
                        type="date"
                        className="vi_0"
                        placeholder={Uni_Sdate1}
                        onChange={(e) => setUni_Sdate1(e.target.value)}
                      />
                    ) : (
                      <>{moment(Uni_Sdate1).format("DD-MM-YYYY")}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (to) </label>

                    {edit4 ? (
                      <input
                        type="date"
                        placeholder={Uni_Edate1}
                        className="vi_0"
                        onChange={(e) => setUni_Edate1(e.target.value)}
                      />
                    ) : (
                      <>{Uni_Edate1}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className=" col-lg-12 lab mb-4">
                    <label>Name of teacher who attended</label>

                    {edit4 ? (
                      <input
                        type="text"
                        placeholder={Uni_namet}
                        className="vi_0"
                        onChange={(e) => setUni_namet(e.target.value)}
                      />
                    ) : (
                      <>{Uni_namet}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label>Title of the program</label>

                    {edit4 ? (
                      <input
                        type="text"
                        placeholder={Uni_prgm50}
                        className="vi_0"
                        onChange={(e) => setUni_prgm50(e.target.value)}
                      />
                    ) : (
                      <>{Uni_prgm50}</>
                    )}
                  </div>
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit4 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      placeholder={Uni_desc4}
                      onChange={(e) => {
                        setUni_desc4(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_desc4}</>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>Relevant document</label>

                  {edit4 ? (
                    <input
                      type="file"
                      className="vi_0"
                      placeholder={Uni_doc7}
                      onChange={(e) => setUni_doc7(e.target.files[0])}
                    />
                  ) : (
                    <>{Uni_doc7.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit4 ? (
                <>
                  <Button
                    variant="warning"
                    style={{ color: "black" }}
                    onClick={() => {
                      cat4();
                      handleClose4();
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => setedit4(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container bs mt-5 p-5">
            <h3>Criteria 6.4.2</h3>
            <h6 className="mb-5">
              {" "}
              Funds / Grants received from non-government bodies, individuals,
              philanthropists during the last five years (not covered in
              Criterion III and V) (INR in Lakhs) (10)
            </h6>
            <div className="row">
              <div className=" col-lg-6 lab mb-4">
                <label>Purpose of the Grant</label>

                {obj5?.Uni_pgrant ? (
                  <p>{obj5?.Uni_pgrant}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_pgrant(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label> Funds/ Grants received (INR in lakhs)</label>

                {obj5?.Uni_Fundg ? (
                  <p>{obj5?.Uni_Fundg}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_Fundg(e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className="  lab mb-4">
              <label>
                {" "}
                Name of the non government funding agencies/ individuals
              </label>

              {obj5?.Uni_namefgov ? (
                <p>{obj5?.Uni_namefgov}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_namefgov(e.target.value)}
                />
              )}
            </div>

            <div className="col-md-12 mb-3 ">
              <label className="content1" htmlFor="name">
                {" "}
                Description with minimum 500 Characters
              </label>
              {obj5?.Uni_desc5 ? (
                <p>{obj5?.Uni_desc5}</p>
              ) : (
                <textarea
                  className="vi_0"
                  type="text"
                  id="name"
                  // placeholder={ugdesc1}
                  onChange={(e) => setUni_desc5(e.target.value)}
                />
              )}
            </div>

            <div className="lab mb-4">
              <label> Upload the relevant doument </label>

              <input
                type="file"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setUni_doc8(e.target.files[0])}
              />
            </div>

            <div className="text-end mt-4">
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success m-2 mt-3 "
                  onClick={() => {
                    cat5();
                    setAdd5(false);
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <Button
                    type="submit"
                    onClick={handleShow5}
                    className=" m-2 mt-3"
                    variant="warning"
                    style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                  >
                    Preview
                  </Button>
                </>
              )}
            </div>
          </div>

          <Modal
            show={show5}
            onHide={() => {
              handleClose5();
              setedit5(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <div className=" col-lg-6 lab mb-4">
                    <label>Purpose of the Grant</label>

                    {edit5 ? (
                      <input
                        type="text"
                        placeholder={Uni_pgrant}
                        className="vi_0"
                        onChange={(e) => setUni_pgrant(e.target.value)}
                      />
                    ) : (
                      <>{Uni_pgrant}</>
                    )}
                  </div>
                  <div className=" col-lg-6 lab mb-4">
                    <label> Funds/ Grants received (INR in lakhs)</label>

                    {edit5 ? (
                      <input
                        type="text"
                        placeholder={Uni_Fundg}
                        className="vi_0"
                        onChange={(e) => setUni_Fundg(e.target.value)}
                      />
                    ) : (
                      <>{Uni_Fundg}</>
                    )}
                  </div>
                </div>

                <div className="  lab mb-4">
                  <label>
                    Name of the non government funding agencies/ individuals
                  </label>

                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={Uni_namefgov}
                      className="vi_0"
                      onChange={(e) => setUni_namefgov(e.target.value)}
                    />
                  ) : (
                    <>{Uni_namefgov}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit5 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      placeholder={Uni_desc5}
                      onChange={(e) => {
                        setUni_desc5(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_desc5}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    Upload the Audited Statement of Accounts reflecting the
                    receipts
                  </label>

                  {edit5 ? (
                    <input
                      type="file"
                      className="vi_0"
                      onChange={(e) => setUni_doc8(e.target.files[0])}
                    />
                  ) : (
                    <>{Uni_doc8.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.Footer>
                {edit5 ? (
                  <>
                    <Button
                      variant="warning"
                      style={{ color: "black" }}
                      onClick={() => {
                        cat5();
                        handleClose5();
                        setedit5(false);
                      }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={() => setedit5(true)}
                    >
                      Edit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </Modal.Footer>
          </Modal>

          <div className="container bs mt-5 p-5">
            <h3>Criteria 6.5.2</h3>
            <h6 className="mb-5 ">
              Quality assurance initiatives of the institution include: (10) 1.
              Regular meeting of Internal Quality Assurance Cell (IQAC);
              Feedback collected, analysed and used for improvements 2.
              Collaborative quality intitiatives with other institution(s) 3.
              Participation in NIRF 4.Any other quality audit recognized by
              state, national or international agencies (ISO Certification)
            </h6>{" "}
            <div className="row">
              <div className=" col-lg-12 lab mb-4">
                <label> Participation in NIRF along with Status.</label>

                {obj6?.Uni_nirfs ? (
                  <p>{obj6?.Uni_nirfs}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_nirfs(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="lab">
              <label>
                Orientation programme on quality issues for teachers and
                students,{" "}
              </label>
            </div>
            <div className="row">
              <div className=" col-lg-6 lab mb-4">
                <label>Date (from)</label>

                {obj6?.Uni_Sdate2 ? (
                  <p>{obj6?.Uni_Sdate2}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_Sdate2(e.target.value)}
                  />
                )}
              </div>
              <div className=" col-lg-6 lab mb-4">
                <label>Date (to)</label>

                {obj6?.Uni_Edate2 ? (
                  <p>{obj6?.Uni_Edate2}</p>
                ) : (
                  <input
                    type="date"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setUni_Edate2(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="lab mb-4">
              <label>
                {" "}
                Academic Administrative Audit (AAA) and follow up action{" "}
              </label>

              {obj6?.Uni_aaa ? (
                <p>{obj6?.Uni_aaa}</p>
              ) : (
                <input
                  type=""
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_aaa(e.target.value)}
                />
              )}
            </div>
            <div className="lab mb-4">
              <label>
                {" "}
                Any other quality audit recognized by State, National or
                International agencies (ISO Certification, NBA)
              </label>

              {obj6?.Uni_nba ? (
                <p>{obj6?.Uni_nba}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_nba(e.target.value)}
                />
              )}
            </div>
            <div className="  lab mb-4">
              <label>
                {" "}
                Conferences, Seminars, Workshops on quality conducted{" "}
              </label>

              {obj6?.Uni_cswq ? (
                <p>{obj6?.Uni_cswq}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_cswq(e.target.value)}
                />
              )}
            </div>
            <div className="lab mb-4">
              <label>
                {" "}
                Collaborative quality initiatives with other institution(s)
                (Provide name of the institution and activity)
              </label>

              {obj6?.Uni_iaa ? (
                <p>{obj6?.Uni_iaa}</p>
              ) : (
                <input
                  type="text"
                  placeholder=" "
                  className="vi_0"
                  onChange={(e) => setUni_iaa(e.target.value)}
                />
              )}
            </div>
            <div className="col-md-12 mb-3 ">
              <label className="content1" htmlFor="name">
                {" "}
                Description with minimum 500 Characters
              </label>
              {obj6?.Uni_desc6 ? (
                <p>{obj6?.Uni_desc6}</p>
              ) : (
                <textarea
                  className="vi_0"
                  type="text"
                  id="name"
                  // placeholder={ugdesc1}
                  onChange={(e) => setUni_desc6(e.target.value)}
                />
              )}
            </div>
            <div className="lab mb-4">
              <label> Upload the relevant doument </label>

              <input
                type="file"
                placeholder=" "
                className="vi_0"
                onChange={(e) => setUni_doc9(e.target.files[0])}
              />
            </div>
            <div className="text-end mt-4">
              {add6 ? (
                <button
                  type="submit"
                  className="btn btn-success m-2 mt-3 "
                  onClick={() => {
                    cat6();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <Button
                    type="submit"
                    onClick={handleShow6}
                    className=" m-2 mt-3"
                    variant="warning"
                    style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                  >
                    Preview
                  </Button>
                </>
              )}
            </div>
          </div>

          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="lab">
                  <label>
                    Orientation programme on quality issues for teachers and
                    students,
                  </label>
                </div>

                <div className="row">
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (from) </label>

                    {edit6 ? (
                      <input
                        type="date"
                        placeholder={Uni_Sdate2}
                        className="vi_0"
                        onChange={(e) => setUni_Sdate2(e.target.value)}
                      />
                    ) : (
                      <>{Uni_Sdate2}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (to) </label>

                    {edit6 ? (
                      <input
                        type="date"
                        placeholder={Uni_Edate2}
                        className="vi_0"
                        onChange={(e) => setUni_Edate2(e.target.value)}
                      />
                    ) : (
                      <>{Uni_Edate2}</>
                    )}
                  </div>
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label> Participation in NIRF along with Status.</label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Uni_nirfs}
                      className="vi_0"
                      onChange={(e) => setUni_nirfs(e.target.value)}
                    />
                  ) : (
                    <>{Uni_nirfs}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>Academic Administrative Audit (AAA)</label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Uni_aaa}
                      className="vi_0"
                      onChange={(e) => setUni_aaa(e.target.value)}
                    />
                  ) : (
                    <>{Uni_aaa}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    {" "}
                    Any other quality audit recognized by State, National or
                    International agencies (ISO Certification, NBA)
                  </label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Uni_nba}
                      className="vi_0"
                      onChange={(e) => setUni_nba(e.target.value)}
                    />
                  ) : (
                    <>{Uni_nba}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    Conferences, Seminars, Workshops on quality conducted{" "}
                  </label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Uni_cswq}
                      className="vi_0"
                      onChange={(e) => setUni_cswq(e.target.value)}
                    />
                  ) : (
                    <>{Uni_cswq}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    {" "}
                    Collaborative quality initiatives with other institution(s)
                    (Provide name of the institution and activity)
                  </label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={Uni_iaa}
                      className="vi_0"
                      onChange={(e) => setUni_iaa(e.target.value)}
                    />
                  ) : (
                    <>{Uni_iaa}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit6 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      onChange={(e) => {
                        setUni_desc6(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Uni_desc6}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label> Upload the relevant document</label>

                  {edit6 ? (
                    <>
                      <input
                        type="file"
                        className="vi_0"
                        placeholder={Uni_doc9}
                        onChange={(e) => setUni_doc9(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Uni_doc9.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit6 ? (
                <>
                  <Button
                    variant="warning"
                    style={{ color: "black" }}
                    onClick={() => {
                      cat6();
                      handleClose6();
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => setedit6(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="text-center mt-5 mb-5">
            <button
              type="submit"
              className="btn btn-success "
              onClick={Uncriteria}
              style={{ color: "white", marginTop: "15px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnCriteria6;
