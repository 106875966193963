import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";

import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
function AddCommitedtoeducating() {
  let naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // show modal
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [img, setimg] = useState({});
  const [View, setView] = useState({});
  const [description, setdescription] = useState("");
  const [showMore, setshowMore] = useState("");
  const [certificatePercent, setcertificatePercent] = useState("");
  const [educationPercent, seteducationPercent] = useState("");
  const AddCTE = async (e) => {
    e.preventDefault();
    if (!description || !certificatePercent || !educationPercent || !img) {
      return alert("Please fill all the fields!!!");
    }
    const obj = {
      img: img,
      description: description,
      certificatePercent: certificatePercent,
      educationPercent: educationPercent,
    };
    try {
      const config = {
        url: "/admin/addCTE",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert("Added Successfully");
          setcertificatePercent("");
          setimg("");
          setdescription("");
          seteducationPercent("");
          getCTE();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  // =================== calling api for getting the CTE======================
  const [CTE, setCTE] = useState([]);
  const getCTE = async () => {
    try {
      let res = await axios.get("https://brightnaac.co.in/api/admin/getCTE");
      if (res.status === 200) {
        setCTE(res.data.allContent);
      }
    } catch (error) {
      console.log(error);
      setCTE(error.response.data.allContent);
    }
  };

  const UpdateCTE = async (e) => {
    e.preventDefault();
    if (!description && !certificatePercent && !educationPercent && !img) {
      return alert("Please Provide atleast one detail to edit!!!");
    }
    const obj = {
      img: img,
      description: description,
      certificatePercent: certificatePercent,
      educationPercent: educationPercent,
    };
    try {
      const config = {
        url: `/admin/editCTE/${View?._id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setcertificatePercent("");
          setimg("");
          setdescription("");
          seteducationPercent("");
          getCTE();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose2();
    }
  };

  const deleteCTE = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/admin/deleteCTE/${View?._id}`,
        method: "delete",
        baseURL: "https://brightnaac.co.in/api",
        headers: {
          "content-type": "application/json",
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Deleted Successfully");
          setcertificatePercent("");
          setimg("");
          setdescription("");
          seteducationPercent("");
          getCTE();
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      handleClose1();
    }
  };

  useEffect(() => {
    naacMainAdmin = JSON.parse(sessionStorage.getItem("Admin"));
    if (!naacMainAdmin) {
      alert("Please login first!!!");
      window.location.assign("/admin");
    }
    getCTE();
  }, []);
  return (
    <>
      <div className="conrainer-fluid">
        <div className="row m-0 align-items-center justify-content-center pt-5">
          <div className="header-01 col-lg-6">
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              {" "}
              Comitted to Educating
            </span>
          </div>
          <div className="header-02 col-lg-6 text-end">
            {CTE?.length > 0 ? (
              <></>
            ) : (
              <Button onClick={handleShow}>Add</Button>
            )}
          </div>
        </div>
        <br />
        <div className="row m-0 pt-4">
          <Table responsive bordered striped>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "30px",
                  verticalAlign: "middle",
                }}
              >
                <th>Sl.No</th>
                <th>Image</th>
                <th>Description</th>
                <th>Certificates percentage</th>
                <th>Education skills percentage</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {CTE?.map((item, i) => {
                return (
                  <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <td>{++i}</td>
                    <td>
                      <img
                        src={`https://brightnaac.co.in/CTE/${item?.img}`}
                        style={{ width: "30%" }}
                        alt="no-image"
                      />
                    </td>
                    <td>
                      {item?.description?.slice(0, 70)}
                      <Link
                        onClick={() => {
                          setshowMore(item?.description);
                          handleShow3();
                        }}
                      >
                        Read More...
                      </Link>
                    </td>
                    <td>{item?.certificatePercent}%</td>
                    <td>{item?.educationPercent}%</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-evenly">
                        <div className="">
                          <span>
                            <BiEdit
                              size={20}
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                margin: "10px",
                              }}
                              onClick={() => {
                                setView(item);
                                handleShow2();
                              }}
                            />
                          </span>
                        </div>
                        <div className="">
                          <span
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <AiFillDelete
                              size={20}
                              style={{
                                cursor: "pointer",
                                color: "red",
                                margin: "10px",
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Update Comitted to Educating</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>Upload Image</label>
              <div className="">
                <input
                  type="file"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setimg(e.target.files[0])}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>Description</label>
              <div className="">
                <textarea
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  rows="5"
                  cols="30"
                  onChange={(e) => setdescription(e.target.value)}
                >
                  {View?.description}
                </textarea>
              </div>
            </div>

            <div className="mb-1">
              <label>Certificates percentage</label>
              <div className="">
                <input
                  type="number"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.certificatePercent}
                  onChange={(e) => setcertificatePercent(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>Education skills percentage</label>
              <div className="">
                <input
                  type="number"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  placeholder={View?.educationPercent}
                  onChange={(e) => seteducationPercent(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => UpdateCTE(e)}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Commited to educating</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>
                Upload Image <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setimg(e.target.files[0])}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Description <span className="font-css top">*</span>
              </label>
              <div className="">
                <textarea
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  rows="5"
                  cols="30"
                  onChange={(e) => setdescription(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="mb-1">
              <label>
                Certificates percentage <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => setcertificatePercent(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Education skills percentage{" "}
                <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                  onChange={(e) => seteducationPercent(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => AddCTE(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <span className="header-03">
              <Button onClick={(e) => deleteCTE(e)}>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>

        {/* show modal */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <p> {showMore}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AddCommitedtoeducating;
