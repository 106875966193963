import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const PG_Criteria01 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);

  const [PG_TeacherPar, setPG_TeacherPar] = useState("");
  const [PG_BodyName, setPG_BodyName] = useState("");
  const [PG_Desc1, setPG_Desc1] = useState("");
  const [PG_Link1, setPG_Link1] = useState("");

  const [PG_PrgmCode, setPG_PrgmCode] = useState("");
  const [PG_YOfIntro, setPG_YOfIntro] = useState("");
  const [PG_PrgName1, setPG_PrgName1] = useState("");
  const [PG_Status, setPG_Status] = useState("");
  const [PG_YOfCBCS, setPG_YOfCBCS] = useState("");
  const [PG_Desc2, setPG_Desc2] = useState("");
  const [PG_Link2, setPG_Link2] = useState("");

  const [PG_CertiName, setPG_CertiName] = useState("");
  const [PG_CourseCode, setPG_CourseCode] = useState("");
  const [PG_YOfOffering, setPG_YOfOffering] = useState("");
  const [PG_NoOfTimes, setPG_NoOfTimes] = useState("");
  const [PG_CourseDuration, setPG_CourseDuration] = useState("");
  const [PG_NoOfStudents, setPG_NoOfStudents] = useState("");
  const [PG_CourseCompletion, setPG_CourseCompletion] = useState("");
  const [PG_Desc3, setPG_Desc3] = useState("");
  const [PG_Link3, setPG_Link3] = useState("");

  const [PG_PPrgmCode, setPG_PPrgmCode] = useState("");
  const [PG_PrgName2, setPG_PrgName2] = useState("");
  const [PG_YYOfOffering, setPG_YYOfOffering] = useState("");
  const [course_code, setCourse_Code] = useState("");
  const [PG_StdCourseStudied, setPG_StdCourseStudied] = useState("");
  const [PG_ExpCourse, setPG_ExpCourse] = useState("");
  const [PG_Desc4, setPG_Desc4] = useState("");
  const [PG_Link4, setPG_Link4] = useState("");

  const [PG_PrgName3, setPG_PrgName3] = useState("");
  const [PG_PPPrgmCode, setPG_PPPrgmCode] = useState("");
  const [PG_StdList, setPG_StdList] = useState("");
  const [PG_Desc5, setPG_Desc5] = useState("");
  const [PG_Link5, setPG_Link5] = useState("");

  //integrating get method
  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);
  const [PG_Criteria01, setPG_Criteria01] = useState([]);
  const [Value, setValue] = useState({});

  //   ===============================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("PG_Cri1.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.PG_approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          //  Criteria 1_113

          Year: item?.PG_Year,
          "Criteria_1.1.3": item?.PG_Criteria01_113?.PG_criteria,
          "Name of the teacher participated":
            item?.PG_Criteria01_113?.PG_TeacherPar,
          "Name of the body in which full time teacher participated":
            item?.PG_Criteria01_113?.PG_BodyName,
          "Description (min 500 words)(1.1.3)":
            item?.PG_Criteria01_113?.PG_Desc1,
          "Upload the relevant document(1.1.3)":
            item?.PG_Criteria01_113?.PG_Link1,

          // Criteria 1_121

          "Criteria_1.2.1": item?.PG_Criteria01_121?.PG_criteria,
          "Program Code1": item?.PG_Criteria01_121?.PG_PrgmCode,
          "Program Name1": item?.PG_Criteria01_121?.PG_PrgName1,
          "Year Of Introduction": item?.PG_Criteria01_121?.PG_YOfIntro,
          "Status of implementation of CBCS/elective course":
            item?.PG_Criteria01_121?.PG_Status,
          "Year of implementation of CBCS/elective course":
            item?.PG_Criteria01_121?.PG_YOfCBCS,
          "Description (min 500 words)(1.2.1)":
            item?.PG_Criteria01_121?.PG_Desc2,
          "Upload the relevant document(1.2.1)":
            item?.PG_Criteria01_121?.PG_Link2,

          // Criteria 1_122
          "Criteria_1.2.2": item?.PG_Criteria01_122?.PG_criteria,
          "Name of Add on /Certificate programs offered":
            item?.PG_Criteria01_122?.PG_CertiName,
          "Course Code (if any)": item?.PG_Criteria01_122?.PG_CourseCode,
          "Year of offering": item?.PG_Criteria01_122?.PG_YOfOffering,
          "No. of times offered during the same year":
            item?.PG_Criteria01_122?.PG_NoOfTimes,
          "Duration of course": item?.PG_Criteria01_122?.PG_CourseDuration,
          "Number of students enrolled in the year":
            item?.PG_Criteria01_122?.PG_NoOfStudents,
          "Number of Students completing the course in the year":
            item?.PG_Criteria01_122?.PG_CourseCompletion,
          "Description (min 500 words)(1.2.2)":
            item?.PG_Criteria01_122?.PG_Desc3,
          "Upload the relevant document(1.2.2)":
            item?.PG_Criteria01_122?.PG_Link3,

          // Criteria 1_132
          "Criteria_1.3.2": item?.PG_Criteria01_132?.PG_criteria,
          "Program name": item?.PG_Criteria01_132?.PG_PrgName2,
          "Program code": item?.PG_Criteria01_132?.PG_PPrgmCode,
          "Year of offering1": item?.PG_Criteria01_132?.PG_YYOfOffering,
          "Course-Code": item?.PG_Criteria01_132?.PG_Course_code,
          "Name of the student studied course on experiential learning through project work/field work/internship":
            item?.PG_Criteria01_132?.PG_StdCourseStudied,
          "Name of the Course that include experiential learning through project work/field work/internship":
            item?.PG_Criteria01_132?.PG_ExpCourse,
          "Description (min 500 words)(1.3.2)":
            item?.PG_Criteria01_132?.PG_Desc4,
          "Upload the relevant document(1.3.2)":
            item?.PG_Criteria01_132?.PG_Link4,

          // Criteria 1_133
          "Criteria_1.3.3": item?.PG_Criteria01_133?.PG_criteria,
          "Program Name": item?.PG_Criteria01_133?.PG_PrgName3,
          "Program Code": item?.PG_Criteria01_133?.PG_PPPrgmCode,
          "List of students undertaking project work/field work/internship":
            item?.PG_Criteria01_133?.PG_StdList,
          "Description (min 500 words)(1.3.3)":
            item?.PG_Criteria01_133?.PG_Desc5,
          "Upload the relevant document(1.3.3)":
            item?.PG_Criteria01_133?.PG_Link5,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editPG_Criteria01_113 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc1) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria01_113/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_TeacherPar: PG_TeacherPar,
          PG_BodyName: PG_BodyName,
          PG_Desc1: PG_Desc1,
          PG_Link1: PG_Link1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri01details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria01_121 = async (e, id) => {
    e.preventDefault();

    try {
      const minwords = 500;
      const newText = PG_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc2) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria01_121/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_PrgmCode: PG_PrgmCode,
          PG_PrgName1: PG_PrgName1,
          PG_YOfIntro: PG_YOfIntro,
          PG_Status: PG_Status,
          PG_YOfCBCS: PG_YOfCBCS,
          PG_Desc2: PG_Desc2,
          PG_Link2: PG_Link2,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri01details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria01_122 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc3) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria01_122/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_CertiName: PG_CertiName,
          PG_CourseCode: PG_CourseCode,
          PG_YOfOffering: PG_YOfOffering,
          PG_NoOfTimes: PG_NoOfTimes,
          PG_CourseDuration: PG_CourseDuration,
          PG_NoOfStudents: PG_NoOfStudents,
          PG_CourseCompletion: PG_CourseCompletion,
          PG_Desc3: PG_Desc3,
          PG_Link3: PG_Link3,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri01details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria01_132 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc4) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editPG_Criteria01_132/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_PPrgmCode: PG_PPrgmCode,
          PG_PrgName2: PG_PrgName2,
          PG_YYOfOffering: PG_YYOfOffering,
          PG_Course_code: course_code,
          PG_StdCourseStudied: PG_StdCourseStudied,
          PG_ExpCourse: PG_ExpCourse,
          PG_Desc4: PG_Desc4,
          PG_Link4: PG_Link4,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri01details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editPG_Criteria01_133 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = PG_Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (PG_Desc5) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editPG_Criteria01_133/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          PG_PrgName3: PG_PrgName3,
          PG_PPPrgmCode: PG_PPPrgmCode,
          PG_StdList: PG_StdList,
          PG_Desc5: PG_Desc5,
          PG_Link5: PG_Link5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getpgcri01details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getpgcri01details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcri01details"
      );
      if (res.status == 200) {
        setPG_Criteria01(res.data.pgcriteria01);
        setdata1(res.data.pgcriteria01);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getpgcri01details();
  }, []);
  console.log(PG_Criteria01);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  const pgCriteria01Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria1"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria01);
    }
  };

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                PostGraduate_Criteria-01
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/pgcriteria001">
                <button className=" btn btn-success">
                  Add Criteria 1{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>

                  <th>Criteria</th>
                  <th>Name of the teacher participated</th>
                  <th>
                    Name of the body in which full time teacher participated
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Code</th>
                  <th>Program Name</th>
                  <th>Year Of Introduction</th>
                  <th>Status of implementation of CBCS/elective course</th>
                  <th>Year of implementation of CBCS/elective course</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of Add on /Certificate programs offered</th>
                  <th>Course Code (if any)</th>
                  <th>Year of offering</th>
                  <th>No. of times offered during the same year</th>
                  <th>Duration of course</th>
                  <th>Number of students enrolled in the year</th>
                  <th>Number of Students completing the course in the year</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>Year of offering</th>
                  <th>Course Code</th>
                  <th>
                    Name of the student studied course on experiential learning
                    through project work/field work/internship
                  </th>
                  <th>
                    {" "}
                    Name of the Course that include experiential learning
                    through project work/field work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Program Name</th>
                  <th>Program Code</th>
                  <th>
                    List of students undertaking project work/field
                    work/internship
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {PG_Criteria01?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.PG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.PG_approve === "true" ||
                      item1?.PG_approve === "Pending" ||
                      item1?.PG_approve === "false") &&
                    (SearchItem === "" ||
                      item1?.PG_Criteria01_113?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_113?.PG_TeacherPar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_113?.PG_BodyName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_113?.PG_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_121?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_121?.PG_PrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_121?.PG_PrgName1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_121?.PG_YOfIntro?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_121?.PG_Status?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_121?.PG_YOfCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_121?.PG_YOfRevision?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_121?.PG_Desc2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_122?.PG_CertiName?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_122?.PG_CourseCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_122?.PG_YOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_122?.PG_NoOfTimes?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_122?.PG_CourseDuration?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_122?.PG_NoOfStudents?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_122?.PG_CourseCompletion?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_122?.PG_Desc3?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_132?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_132?.PG_PPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_132?.PG_PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_132?.PG_YYOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_132?.PG_StdCourseStudied?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_132?.PG_ExpCourse?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_132?.PG_Desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_133?.PG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_133?.PG_PrgName3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_133?.PG_PPPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.PG_Criteria01_133?.PG_StdList?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.PG_Criteria01_133?.PG_Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?.PG_Year}</td>
                      <td>{Facultydetails?.FName}</td>
                      <td>{item?.PG_Criteria01_113?.PG_criteria}</td>
                      <td>{item?.PG_Criteria01_113?.PG_TeacherPar}</td>
                      <td>{item?.PG_Criteria01_113?.PG_BodyName}</td>
                      <td>
                        {item?.PG_Criteria01_113?.PG_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria01_121?.PG_criteria}</td>
                      <td>{item?.PG_Criteria01_121?.PG_PrgmCode}</td>
                      <td>{item?.PG_Criteria01_121?.PG_PrgName1}</td>
                      <td>{item?.PG_Criteria01_121?.PG_YOfIntro}</td>
                      <td>{item?.PG_Criteria01_121?.PG_Status}</td>
                      <td>{item?.PG_Criteria01_121?.PG_YOfCBCS}</td>

                      <td>
                        {" "}
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria01_122?.PG_criteria}</td>
                      <td>{item?.PG_Criteria01_122?.PG_CertiName}</td>
                      <td>{item?.PG_Criteria01_122?.PG_CourseCode}</td>
                      <td>{item?.PG_Criteria01_122?.PG_YOfOffering}</td>
                      <td>{item?.PG_Criteria01_122?.PG_NoOfTimes}</td>
                      <td>{item?.PG_Criteria01_122?.PG_CourseDuration}</td>
                      <td>{item?.PG_Criteria01_122?.PG_NoOfStudents}</td>
                      <td>{item?.PG_Criteria01_122?.PG_CourseCompletion}</td>
                      <td>
                        {item?.PG_Criteria01_122?.PG_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria01_132?.PG_criteria}</td>
                      <td>{item?.PG_Criteria01_132?.PG_PrgName2}</td>
                      <td>{item?.PG_Criteria01_132?.PG_PPrgmCode}</td>
                      <td>{item?.PG_Criteria01_132?.PG_YYOfOffering}</td>
                      <td>{item?.PG_Criteria01_132?.PG_Course_code}</td>
                      <td>{item?.PG_Criteria01_132?.PG_StdCourseStudied}</td>
                      <td>{item?.PG_Criteria01_132?.PG_ExpCourse}</td>
                      <td>
                        {item?.PG_Criteria01_132?.PG_Desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.PG_Criteria01_133?.PG_criteria}</td>
                      <td>{item?.PG_Criteria01_133?.PG_PrgName3}</td>
                      <td>{item?.PG_Criteria01_133?.PG_PPPrgmCode}</td>
                      <td>{item?.PG_Criteria01_133?.PG_StdList}</td>
                      <td>
                        {item?.PG_Criteria01_133?.PG_Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {item?.PG_approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.PG_approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.PG_remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={(e) => {
            handleClose();
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                PostGraduate_Criteria-01
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* ------1.1.3------ */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the teacher participated
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria01_113?.PG_TeacherPar}
                        onChange={(e) => {
                          setPG_TeacherPar(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_113?.PG_TeacherPar}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the body in which full time teacher participated
                  </label>
                  {edit1 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_113?.PG_BodyName}
                        onChange={(e) => setPG_BodyName(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_113?.PG_BodyName}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_113?.PG_Desc1}
                        onChange={(e) => setPG_Desc1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_113?.PG_Desc1}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="file"
                        name="upload1"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria01_113?.PG_Link1}
                        onChange={(e) => setPG_Link1(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_113?.PG_Link1}</>
                  )}
                </div>
                {Value?.PG_approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit1 ? (
                        <>
                          <Button
                            variant="success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria01_113(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit1(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* ------1.2.1-------- */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria01_121?.PG_PrgmCode}
                        onChange={(e) => {
                          setPG_PrgmCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_121?.PG_PrgmCode}</>
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit2 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.PG_Criteria01_121?.PG_PrgName1}
                        onChange={(e) => setPG_PrgName1(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="MCA">MCA</option>
                        <option value="MSC">MSC</option>
                        <option value="MBA">MBA</option>
                        <option value="M.Com">M.Com</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_121?.PG_PrgName1}</>
                    </>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year Of Introduction
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_121?.PG_YOfIntro}
                        onChange={(e) => setPG_YOfIntro(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_121?.PG_YOfIntro}</>
                    </>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Status of implementation of CBCS/elective course
                  </label>

                  {edit2 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.PG_Criteria01_121?.PG_Status}
                        onChange={(e) => setPG_Status(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_121?.PG_Status}</>
                    </>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of implementation of CBCS/elective course
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_121?.PG_YOfCBCS}
                        onChange={(e) => setPG_YOfCBCS(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_121?.PG_YOfCBCS}</>
                    </>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        id="name"
                        placeholder={Value?.PG_Criteria01_121?.PG_Desc2}
                        onChange={(e) => setPG_Desc2(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_121?.PG_Desc2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria01_121?.PG_Link2}
                        onChange={(e) => setPG_Link2(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_121?.PG_Link2}</>
                  )}
                </div>
                {Value?.PG_approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit2 ? (
                        <>
                          <Button
                            variant="success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria01_121(e, Value?._id);
                              handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit2(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.2.2 & 1.2.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of Add on /Certificate programs offered
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="year"
                        id="name"
                        placeholder={Value?.PG_Criteria01_122?.PG_CertiName}
                        onChange={(e) => {
                          setPG_CertiName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_122?.PG_CertiName}</>
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code (if any)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria01_122?.PG_CourseCode}
                        onChange={(e) => {
                          setPG_CourseCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_122?.PG_CourseCode}</>
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of offering
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria01_122?.PG_YOfOffering}
                        onChange={(e) => {
                          setPG_YOfOffering(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_122?.PG_YOfOffering}</>
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    No. of times offered during the same year
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria01_122?.PG_NoOfTimes}
                        onChange={(e) => {
                          setPG_NoOfTimes(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_122?.PG_NoOfTimes}</>
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Duration of course
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.PG_Criteria01_122?.PG_CourseDuration
                        }
                        onChange={(e) => {
                          setPG_CourseDuration(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_122?.PG_CourseDuration}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of students enrolled in the year
                  </label>
                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria01_122?.PG_NoOfStudents}
                        onChange={(e) => {
                          setPG_NoOfStudents(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_122?.PG_NoOfStudents}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Number of Students completing the course in the year
                  </label>
                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={
                          Value?.PG_Criteria01_122?.PG_CourseCompletion
                        }
                        onChange={(e) => {
                          setPG_CourseCompletion(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_122?.PG_CourseCompletion}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit3 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        id="name"
                        placeholder={Value?.PG_Criteria01_122?.PG_Desc3}
                        onChange={(e) => setPG_Desc3(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_122?.PG_Desc3}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="file"
                        name="upload3"
                        id="name"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria01_122?.PG_Link3}
                        onChange={(e) => setPG_Link3(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_122?.PG_Link3}</>
                  )}
                </div>

                {Value?.PG_approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit3 ? (
                        <>
                          <Button
                            variant="success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria01_122(e, Value?._id);
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit3(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit4 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.PG_Criteria01_132?.PG_PrgName2}
                        onChange={(e) => {
                          setPG_PrgName2(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="MCA">MCA</option>
                        <option value="MSC">MSC</option>
                        <option value="MBA">MBA</option>
                        <option value="M.Com">M.Com</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_132?.PG_PrgName2}</>
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria01_132?.PG_PPrgmCode}
                        onChange={(e) => {
                          setPG_PPrgmCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_132?.PG_PPrgmCode}</>
                    </>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of offering
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_132?.PG_YYOfOffering}
                        onChange={(e) => {
                          setPG_YYOfOffering(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_132?.PG_YYOfOffering}</>
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Course Code
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_132?.PG_Course_code}
                        onChange={(e) => {
                          setCourse_Code(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_132?.PG_Course_code}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the student studied course on experiential learning
                    through project work/field work/internship
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={
                          Value?.PG_Criteria01_132?.PG_StdCourseStudied
                        }
                        onChange={(e) => {
                          setPG_StdCourseStudied(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_132?.PG_StdCourseStudied}</>
                    </>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Name of the Course that include experiential learning
                    through project work/field work/internship
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_132?.PG_ExpCourse}
                        onChange={(e) => {
                          setPG_ExpCourse(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_132?.PG_ExpCourse}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_132?.PG_Desc4}
                        onChange={(e) => setPG_Desc4(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_132?.PG_Desc4}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload4">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="file"
                        name="email"
                        id="upload4"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria01_132?.PG_Link4}
                        onChange={(e) => {
                          setPG_Link4(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_132?.PG_Link4}</>
                  )}
                </div>
                {Value?.PG_approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end ">
                      {edit4 ? (
                        <>
                          <Button
                            variant="success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria01_132(e, Value?._id);
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit4(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Name
                  </label>

                  {edit5 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.PG_Criteria01_133?.PG_PrgName3}
                        onChange={(e) => {
                          setPG_PrgName3(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="MCA">MCA</option>
                        <option value="MSC">MSC</option>
                        <option value="MBA">MBA</option>
                        <option value="M.Com">M.Com</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_133?.PG_PrgName3}</>
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className=" vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.PG_Criteria01_133?.PG_PPPrgmCode}
                        onChange={(e) => {
                          setPG_PPPrgmCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_133?.PG_PPPrgmCode}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    List of students undertaking project work/field
                    work/internship
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_133?.PG_StdList}
                        onChange={(e) => {
                          setPG_StdList(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>{Value?.PG_Criteria01_133?.PG_StdList}</>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description
                  </label>

                  {edit5 ? (
                    <>
                      <textarea
                        className=" vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Value?.PG_Criteria01_133?.PG_Desc5}
                        onChange={(e) => setPG_Desc5(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_133?.PG_Desc5}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Document
                  </label>

                  {edit5 ? (
                    <>
                      <input
                        className="name"
                        type="file"
                        name="email"
                        id="upload5"
                        accept="image/*"
                        placeholder={Value?.PG_Criteria01_133?.PG_Link5}
                        onChange={(e) => {
                          setPG_Link5(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.PG_Criteria01_133?.PG_Link5}</>
                  )}
                </div>
                {Value?.PG_approve == "true" ? (
                  <></>
                ) : (
                  <div className="row mt-3">
                    <div className="text-end">
                      {edit5 ? (
                        <>
                          <Button
                            variant="success"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              editPG_Criteria01_133(e, Value?._id);
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => setedit5(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={() => {
                handleClose();
                setedit1(false);
                setedit2(false);
                setedit3(false);
                setedit4(false);
                setedit5(false);
              }}
              className="me-2"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {
          // remarks model
        }

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria01_113?.PG_Desc1}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria01_121?.PG_Desc2}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria01_122?.PG_Desc3}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria01_132?.PG_Desc4}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.PG_Criteria01_133?.PG_Desc5}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_113?.PG_Link1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_121?.PG_Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_122?.PG_Link3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_132?.PG_Link4}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Documment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/PG_Criteria01/${View?.PG_Criteria01_133?.PG_Link5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default PG_Criteria01;
