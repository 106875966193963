import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

const UnderGcriteria6 = () => {
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // =============================================
  //post method for criteria

  //611
  const [VisionMission, setVisionMission] = useState("");
  const [VisionMissionStatement, setVisionMissionStatement] = useState("");
  const [VisionMissionyesr, setVisionMissionyear] = useState("");

  // 612
  const [ParticipativeMng, setParticipativeMng] = useState("");
  const [AcadamicMont, setAcadamicMont] = useState("");
  const [OficeOrder, setOficeOrder] = useState("");
  const [Decentralisation1, setDecentralisation1] = useState("");
  const [Decentralisation2, setDecentralisation2] = useState("");
  const [Decentralisation3, setDecentralisation3] = useState("");
  const [AcadamicMont1, setAcadamicMont1] = useState("");

  const [SYear, setSyear] = useState("");

  const [yoi1, setyoi1] = useState("");
  const [yoi2, setyoi2] = useState("");

  const [yoi3, setyoi3] = useState("");
  const [yoi4, setyoi4] = useState("");

  const [doc1, setdoc1] = useState("");
  const [doc2, setdoc2] = useState("");

  const [doc3, setdoc3] = useState("");
  const [doc4, setdoc4] = useState("");

  const [ugdesc1, setugdesc1] = useState("");

  // 632
  const [UGNot, setUGNot] = useState("");
  const [UGAsr, setUGAsr] = useState("");
  const [NOC, setNOC] = useState("");
  const [Nop, setNop] = useState("");
  const [ugdesc2, setugdesc2] = useState("");
  const [doc5, setdoc5] = useState("");

  //============= criteria 633 =============== //
  const [Sdate, setSdate] = useState("");
  const [Edate, setEdate] = useState("");
  const [titlep, settitlep] = useState("");
  const [title_dev, settitle_dev] = useState("");
  const [Nfp, setNfp] = useState("");
  const [doc6, setdoc6] = useState("");
  const [ugdesc3, setugdesc3] = useState("");

  //============= criteria 634 =============== //

  const [Sdate1, setSdate1] = useState("");
  const [Edate1, setEdate1] = useState("");
  const [namet, setnamet] = useState("");
  const [prgm50, setprgm50] = useState("");
  const [doc7, setdoc7] = useState("");
  const [ugdesc4, setugdesc4] = useState("");

  //============= criteria 642 =============== //

  const [pgrant, setpgrant] = useState("");
  const [Fundg, setFundg] = useState("");
  const [namefgov, setnamefgov] = useState("");
  const [doc8, setdoc8] = useState("");
  const [ugdesc5, setugdesc5] = useState("");

  //============= criteria 652 =============== //

  const [Sdate2, setSdate2] = useState("");
  const [Edate2, setEdate2] = useState("");
  const [nirfs, setnirfs] = useState("");
  const [iqac, setIqac] = useState("");
  const [nba, setnba] = useState("");
  const [cswq, setcswq] = useState("");
  const [iaa, setiaa] = useState("");
  const [doc9, setdoc9] = useState("");
  const [ugdesc6, setugdesc6] = useState("");

  // ===========================================

  const [add1, setAdd1] = useState(true);
  const [add2, setAdd2] = useState(true);
  const [add3, setAdd3] = useState(true);
  const [add4, setAdd4] = useState(true);
  const [add5, setAdd5] = useState(true);
  const [add6, setAdd6] = useState(true);

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  const [edit6, setedit6] = useState(false);

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getugcriteria6 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));
      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));
      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));
      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));
      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));
      let am7 = sessionStorage.getItem("cat61");
      setobj7(JSON.parse(am7));
      let am8 = sessionStorage.getItem("cat612");
      setobj8(JSON.parse(am8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getugcriteria6();
  }, []);

  const Ugcriteria6 = async () => {
    if (!SYear) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/ugadmin/detailscri6",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          UGYear: SYear,
          UGCriteria06_611: obj7,
          UGCriteria06_612: obj8,
          UGCriteria06_623: obj1,
          UgCriteria06_632: obj2,
          UgCriteria06_633: obj3,
          UgCriteria06_634: obj4,
          UgCriteria06_642: obj5,
          UgCriteria06_653: obj6,
          doc1: doc1,
          doc2: doc2,
          doc3: doc3,
          doc4: doc4,
          doc5: doc5,
          doc6: doc6,
          doc7: doc7,
          doc8: doc8,
          doc9: doc9,
          VisionMission: VisionMission,
          VisionMissionStatement: VisionMissionStatement,
          VisionMissionyesr: VisionMissionyesr,

          ParticipativeMng: ParticipativeMng,
          AcadamicMont: AcadamicMont,
          OficeOrder: OficeOrder,
          Decentralisation1: Decentralisation1,
          Decentralisation2: Decentralisation2,
          Decentralisation3: Decentralisation3,
          AcadamicMont1: AcadamicMont1,
          addedByPri: pricipalDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");
          sessionStorage.removeItem("cat2");
          sessionStorage.removeItem("cat3");
          sessionStorage.removeItem("cat4");
          sessionStorage.removeItem("cat5");
          sessionStorage.removeItem("cat6");
          navigate("/ug-Criteria6");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //get method for 1.1.3
  // const [ugcriteria01, setugcriteria01] = useState({});

  // Criteria 6.1.1

  const cat61 = () => {
    try {
      let obj = {
        criteria: "6.1.1",
        VisionMission: VisionMission,
        VisionMissionStatement: VisionMissionStatement,
        VisionMissionyesr: VisionMissionyesr,
      };
      sessionStorage.setItem("cat61", JSON.stringify(obj));
      alert("Successfully added cat 6.1.1");
      getugcriteria6();
    } catch (error) {
      console.log(error);
    }
  };
  const cat612 = () => {
    try {
      let obj = {
        criteria: "6.1.2",
        ParticipativeMng: ParticipativeMng,
        AcadamicMont: AcadamicMont,
        OficeOrder: OficeOrder,
        Decentralisation1: Decentralisation1,
        Decentralisation2: Decentralisation2,
        Decentralisation3: Decentralisation3,
        AcadamicMont1: AcadamicMont1,
      };
      sessionStorage.setItem("cat612", JSON.stringify(obj));
      alert("Successfully added cat 6.1.2");
      getugcriteria6();
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.2.3
  const cat1 = () => {
    const minwords = 500;
    const newText = ugdesc1;
    const wordsArr = newText?.trim()?.split(/\s+/);

    if (add1) {
      if (
        !yoi1 ||
        !doc1 ||
        !yoi2 ||
        !doc2 ||
        !yoi3 ||
        !doc3 ||
        !yoi4 ||
        !doc4 ||
        !ugdesc1
      ) {
        return alert("fill all details");
      }
      if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      } else {
        setAdd1(false);
      }
    }

    try {
      let obj = {
        criteria: "6.2.3",
        yoi1: yoi1,
        doc1: doc1,
        yoi2: yoi2,
        doc2: doc2,
        yoi3: yoi3,
        doc3: doc3,
        yoi4: yoi4,
        doc4: doc4,
        ugdesc1: ugdesc1,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getugcriteria6();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.3.2

  const cat2 = () => {
    const minwords = 500;
    const newText = ugdesc2;
    const wordsArr = newText?.trim()?.split(/\s+/);

    if (add2) {
      if (!UGNot || !UGAsr || !NOC || !Nop || !doc5 || !ugdesc2) {
        return alert("Please fill all the details");
      } else if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      } else {
        setAdd2(false);
      }
    }

    try {
      let obj = {
        criteria: "6.3.2",
        UGNot: UGNot,
        UGAsr: UGAsr,
        NOC: NOC,
        Nop: Nop,
        ugdesc2: ugdesc2,
        doc5: doc5,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getugcriteria6();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.3.3
  const cat3 = () => {
    const minwords = 500;
    const newText = ugdesc3;
    const wordsArr = newText?.trim()?.split(/\s+/);

    if (add3) {
      if (
        !Sdate ||
        !Edate ||
        !titlep ||
        !title_dev ||
        !Nfp ||
        !doc6 ||
        !ugdesc3
      ) {
        return alert("fill all details");
      } else if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      } else {
        setAdd3(false);
      }
    }

    try {
      let obj = {
        criteria: "6.3.3",
        Sdate: Sdate,
        Edate: Edate,
        title_dev: title_dev,
        titlep: titlep,
        Nfp: Nfp,
        doc6: doc6,
        ugdesc3: ugdesc3,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getugcriteria6();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };

  //  Criteria 6.3.4

  const cat4 = () => {
    const minwords = 500;
    const newText = ugdesc4;
    const wordsArr = newText?.trim()?.split(/\s+/);

    if (add4) {
      if (!Sdate1 || !prgm50 || !doc7 || !Edate1 || !namet || !ugdesc4) {
        return alert("fill all details");
      } else if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      } else {
        setAdd4(false);
      }
    }

    try {
      let obj = {
        criteria: "6.3.4",
        Sdate1: Sdate1,
        prgm50: prgm50,
        doc7: doc7,
        Edate1: Edate1,
        namet: namet,
        ugdesc4: ugdesc4,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getugcriteria6();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };

  //   Criteria 6.4.2

  const cat5 = () => {
    const minwords = 500;
    const newText = ugdesc5;
    const wordsArr = newText?.trim()?.split(/\s+/);

    if (add5) {
      if (!pgrant || !Fundg || !namefgov || !doc8 || !ugdesc5) {
        return alert("fill all details");
      } else if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      } else {
        setAdd5(false);
      }
    }

    try {
      let obj = {
        criteria: "6.4.2",
        pgrant: pgrant,
        Fundg: Fundg,
        namefgov: namefgov,
        doc8: doc8,
        ugdesc5: ugdesc5,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getugcriteria6();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Criteria 6.5.3

  const cat6 = () => {
    const minwords = 500;
    const newText = ugdesc6;
    const wordsArr = newText?.trim()?.split(/\s+/);

    if (add6) {
      if (
        !Sdate2 ||
        !Edate2 ||
        !nba ||
        !cswq ||
        !nirfs ||
        !iaa ||
        !doc9 ||
        !iqac ||
        !ugdesc6
      ) {
        return alert("fill all details");
      } else if (wordsArr?.length < minwords) {
        return alert("There should be minimum 500 words!!!");
      } else {
        setAdd6(false);
      }
    }

    try {
      let obj = {
        criteria: "6.5.3",
        Sdate2: Sdate2,
        Edate2: Edate2,
        nba: nba,
        cswq: cswq,
        nirfs: nirfs,
        iaa: iaa,
        doc9: doc9,
        iqac: iqac,
        ugdesc6: ugdesc6,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getugcriteria6();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  // ====================================================

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getAllYears();
    }
  }, []);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className="pt-4   " style={{ padding: "10px" }}>
            <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
              UnderGraduate_Criteria-06
            </span>
          </div>

          <div className="container mt-3">
            <div className="row-fluid">
              <div
                class=" col-md-6-fluid border"
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <label>Select Year</label>
                {obj1?.UGYear ? (
                  <p>{obj1?.UGYear}</p>
                ) : (
                  <select
                    className="vi_0"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => setSyear(e.target.value)}
                  >
                    <option>Select Year</option>
                    {allYears
                      ?.sort((a, b) => {
                        return Number(a?.from) - Number(b?.from);
                      })
                      ?.map((item) => {
                        return (
                          <option value={`${item?.from}-${item?.to}`}>
                            {item?.from} - {item?.to}
                          </option>
                        );
                      })}{" "}
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="container  ">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 6.1.1</h3>
              <h5>Institutional Vision and Leadership</h5>
              <div className="row  ">
                <h6>
                  The governance of the institution is reflective of an
                  effective leadership in tune with the vision and mission of
                  the institution
                </h6>
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Vision_Mission:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab">
                    <input
                      type="file"
                      id="upload1"
                      className="vi_0"
                      onChange={(e) => setVisionMission(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3 ">
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Vision_Mission_Statement:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab">
                    <input
                      type="file"
                      id="upload2"
                      className="vi_0"
                      onChange={(e) =>
                        setVisionMissionStatement(e.target.files[0])
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Vision_Mission_2022-23:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setVisionMissionyear(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  className="btn-success"
                  type="submit"
                  onClick={() => {
                    cat61();
                  }}
                >
                  ADD
                </Button>
              </div>
            </div>
          </div>
          <div className="container  ">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 6.1.2</h3>
              <h5>
                The institution practices decentralization and participative
                management
              </h5>
              <div className="row  ">
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Participative management:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab">
                    <input
                      type="file"
                      id="upload1"
                      className="vi_0"
                      onChange={(e) => setParticipativeMng(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3 ">
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Acadamic monitoring committee:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab">
                    <input
                      type="file"
                      id="upload2"
                      className="vi_0"
                      onChange={(e) => setAcadamicMont(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Office Order:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setOficeOrder(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Decentralisation_20-21:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setDecentralisation1(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Decentralisation_21-22:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setDecentralisation2(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Decentralisation_2022-23:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setDecentralisation3(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Academic Monitoring Committee_2022-23:</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setAcadamicMont1(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="text-end mt-4">
                <Button
                  className="btn-success"
                  type="submit"
                  onClick={() => {
                    cat612();
                  }}
                >
                  ADD
                </Button>
              </div>
            </div>
          </div>

          <div className="container  ">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 6.2.3</h3>
              <h5>Areas Of E-Governance</h5>
              <div className="row  ">
                <h6>Administration</h6>
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Year of implementation:</label>

                    {obj1?.yoi1 ? (
                      <p>{obj1?.yoi1}</p>
                    ) : (
                      <input
                        type="text"
                        placeholder=" "
                        className="vi_0"
                        onChange={(e) => {
                          setyoi1(e.target.value);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Document</label>

                    <input
                      type="file"
                      id="upload1"
                      className="vi_0"
                      onChange={(e) => setdoc1(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3 ">
                <h6>Finance and Accounts</h6>

                <div className="col-lg-6">
                  <div className="lab">
                    <label>Year of implementation:</label>

                    {obj1?.yoi2 ? (
                      <p>{obj1?.yoi2}</p>
                    ) : (
                      <input
                        type="text"
                        placeholder=" "
                        className="vi_0"
                        onChange={(e) => setyoi2(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab">
                    <label>Document</label>

                    <input
                      type="file"
                      id="upload2"
                      className="vi_0"
                      onChange={(e) => setdoc2(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="row ">
                <h6>Student Admissions and Support</h6>

                <div className="col-lg-6">
                  <div className="lab">
                    <label>Year of implementation:</label>

                    {obj1?.yoi3 ? (
                      <p>{obj1?.yoi3}</p>
                    ) : (
                      <input
                        type="text"
                        className="vi_0"
                        onChange={(e) => setyoi3(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Document</label>

                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setdoc3(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h6>Examinations</h6>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Year of implementation:</label>

                    {obj1?.yoi4 ? (
                      <p>{obj1?.yoi4}</p>
                    ) : (
                      <input
                        type="text"
                        className="vi_0"
                        placeholder=" "
                        onChange={(e) => setyoi4(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Document</label>

                    <input
                      type="file"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setdoc4(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description with minimum 500 Characters
                </label>
                {obj1?.ugdesc1 ? (
                  <p>{obj1?.ugdesc1}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    placeholder={ugdesc1}
                    onChange={(e) => setugdesc1(e.target.value)}
                  />
                )}
              </div>

              <div className="text-end mt-4">
                {add1 ? (
                  <Button
                    className="btn-success"
                    type="submit"
                    onClick={() => {
                      cat1();
                    }}
                  >
                    ADD
                  </Button>
                ) : (
                  <>
                    <Button
                      type="submit"
                      onClick={handleShow1}
                      className="m-2"
                      variant="warning "
                      style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                    >
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* 06-623 */}

          <Modal
            show={show1}
            onHide={() => {
              handleClose1();
              setedit1(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <h6>Administration</h6>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>

                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={yoi1}
                            className="vi_0"
                            onChange={(e) => {
                              setyoi1(e.target.value);
                            }}
                          />
                        </>
                      ) : (
                        <>{yoi1}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Relevant document</label>

                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload1"
                            className="vi_0"
                            placeholder={doc1}
                            onChange={(e) => setdoc1(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{doc1?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <h6>Finance and Accounts</h6>

                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>

                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={yoi2}
                            className="vi_0"
                            onChange={(e) => setyoi2(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{yoi2}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Relevant document</label>

                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload2"
                            className="vi_0"
                            placeholder={doc2}
                            onChange={(e) => setdoc2(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{doc2?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <h6>Student Admissions and Support</h6>

                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>

                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={yoi3}
                            className="vi_0"
                            onChange={(e) => setyoi3(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{yoi3}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Relevant document</label>

                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload3"
                            className="vi_0"
                            placeholder={doc4}
                            onChange={(e) => setdoc4(e.target.files[0])}
                          />
                        </>
                      ) : (
                        <>{doc4?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <h6>Examinations</h6>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Year of implementation:</label>

                      {edit1 ? (
                        <>
                          <input
                            type="text"
                            placeholder={yoi4}
                            className="vi_0"
                            onChange={(e) => setyoi4(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{yoi4}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-4">
                      <label>Relevant document</label>

                      {edit1 ? (
                        <>
                          <input
                            type="file"
                            id="upload4"
                            className="vi_0"
                            placeholder={doc4}
                            onChange={(e) => e.target.files[0]}
                          />
                        </>
                      ) : (
                        <>{doc4?.name}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        type="text"
                        placeholder={ugdesc1}
                        onChange={(e) => setugdesc1(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{ugdesc1}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit1 ? (
                <>
                  <Button
                    className="btn btn-success"
                    onClick={() => {
                      cat1();
                      handleClose1();
                      setedit1(false);
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit1(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container  ">
            <div className="row-fluid border p-4 bs  mt-5">
              <h3>Criteria 6.3.2</h3>

              <h6 className="mb-5">
                {" "}
                Average percentage of teachers provided with financial support
                to attend conferences / workshops and towards membership fee of
                professional bodies during the last five years (8)
              </h6>
              <div className="row ">
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Name of the teacher</label>

                    {obj2?.UGNot ? (
                      <p>{obj2?.UGNot}</p>
                    ) : (
                      <input
                        type="text"
                        placeholder=" "
                        className="vi_0"
                        onChange={(e) => setUGNot(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lab mb-4">
                    <label>Amount of support received (in INR)</label>

                    {obj2?.UGAsr ? (
                      <p>{obj2?.UGAsr}</p>
                    ) : (
                      <input
                        type="text"
                        placeholder=" "
                        className="vi_0"
                        onChange={(e) => setUGAsr(e.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="lab mb-4">
                <label>
                  Name of conference/ workshop attended for which financial
                  support provided
                </label>

                {obj2?.NOC ? (
                  <p>{obj2?.NOC}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setNOC(e.target.value)}
                  />
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  Name of the professional body for which membership fee is
                  provided
                </label>

                {obj2?.Nop ? (
                  <p>{obj2?.Nop}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setNop(e.target.value)}
                  />
                )}
              </div>

              <div className="col-lg-12">
                <div className="lab mb-4">
                  <label>Document</label>

                  <input
                    type="file"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setdoc5(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description with minimum 500 Characters
                </label>
                {obj2?.ugdesc2 ? (
                  <p>{obj2?.ugdesc2}</p>
                ) : (
                  <>
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      onChange={(e) => {
                        setugdesc2(e.target.value);
                      }}
                    />
                  </>
                )}
              </div>

              <div className="text-end mt-4">
                {add2 ? (
                  <Button
                    className="btn-success"
                    type="submit"
                    onClick={() => {
                      cat2();
                    }}
                  >
                    ADD
                  </Button>
                ) : (
                  <>
                    {" "}
                    <Button
                      type="submit"
                      onClick={handleShow2}
                      className="m-2"
                      variant="warning "
                      style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                    >
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* 06_632 */}

          <Modal
            show={show2}
            onHide={() => {
              handleClose2();
              setedit2(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row ">
                  <div className="col-lg-12">
                    <div className="lab mb-3">
                      <label>Name of the teacher</label>

                      {edit2 ? (
                        <>
                          <input
                            type="text"
                            placeholder={UGNot}
                            className="vi_0"
                            onChange={(e) => setUGNot(e.target.value)}
                          />{" "}
                        </>
                      ) : (
                        <>{UGNot}</>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="lab mb-3">
                      <label>Amount of support received (in INR)</label>

                      {edit2 ? (
                        <>
                          <input
                            type="text"
                            placeholder={UGAsr}
                            className="vi_0"
                            onChange={(e) => setUGAsr(e.target.value)}
                          />
                        </>
                      ) : (
                        <>{UGAsr}</>
                      )}
                    </div>
                  </div>
                </div>

                <div className="lab mb-4">
                  <label>
                    Name of conference/ workshop attended for which financial
                    support provided
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={NOC}
                        className="vi_0"
                        onChange={(e) => setNOC(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{NOC}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    Name of the professional body for which membership fee is
                    provided
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        type="text"
                        placeholder={Nop}
                        className="vi_0"
                        onChange={(e) => setNop(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Nop}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>
                  {edit2 ? (
                    <>
                      <textarea
                        type="text"
                        className="vi_0"
                        id="name"
                        placeholder={ugdesc2}
                        onChange={(e) => {
                          setugdesc2(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <p>{ugdesc2}</p>
                  )}
                </div>

                <div className="col-lg-12">
                  <div className="lab mb-4">
                    <label>Upload the relevant document</label>

                    {edit2 ? (
                      <input
                        type="file"
                        placeholder=" "
                        className="vi_0"
                        onChange={(e) => setdoc5(e.target.files[0])}
                      />
                    ) : (
                      <>{doc5?.name}</>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit2 ? (
                <>
                  <Button
                    className="btn btn-success"
                    onClick={() => {
                      cat2();
                      handleClose2();
                      setedit2(false);
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit2(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 6.3.3</h3>
              <h6 className="mb-5">
                {" "}
                Average number of professional development / administrative
                training programs organized by the institution for teaching and
                non teaching staff during the last five years (8)
              </h6>
              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from) </label>

                  {obj3?.Sdate ? (
                    <p>{obj3.Sdate}</p>
                  ) : (
                    <input
                      type="date"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setSdate(e.target.value)}
                    />
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to)</label>

                  {obj3?.Edate ? (
                    <p>{obj3?.Edate}</p>
                  ) : (
                    <input
                      type="date"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setEdate(e.target.value)}
                    />
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>
                    {" "}
                    Title of the professional development program organised for
                    teaching staff
                  </label>

                  {obj3?.title_dev ? (
                    <p>{obj3?.title_dev}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => settitle_dev(e.target.value)}
                    />
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>
                    {" "}
                    Title of the professional development/ administrative
                    training programs organised for teaching staff (Professional
                    development/ administrative training programs)
                  </label>

                  {obj3?.titlep ? (
                    <p>{obj3?.titlep}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => settitlep(e.target.value)}
                    />
                  )}
                </div>

                <div className=" col-lg-6 lab mb-4">
                  <label>No. of participants</label>

                  {obj3?.Nfp ? (
                    <p>{obj3?.Nfp}</p>
                  ) : (
                    <input
                      type="number"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setNfp(e.target.value)}
                    />
                  )}
                </div>

                <div className=" col-lg-6 lab mb-4">
                  <label>Upload the relevant document</label>

                  <input
                    type="file"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setdoc6(e.target.files[0])}
                  />
                </div>
                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description with minimum 500 Characters
                  </label>
                  {obj3?.ugdesc3 ? (
                    <p>{obj3?.ugdesc3}</p>
                  ) : (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      onChange={(e) => {
                        setugdesc3(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="text-end mt-4">
                {add3 ? (
                  <Button
                    className="btn-success"
                    type="submit"
                    onClick={() => {
                      cat3();
                    }}
                  >
                    ADD
                  </Button>
                ) : (
                  <>
                    {" "}
                    <Button
                      type="submit"
                      onClick={handleShow3}
                      className="m-2"
                      variant="warning "
                      style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                    >
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>{" "}
          </div>

          {/* 06_633 */}

          <Modal
            show={show3}
            onHide={() => {
              handleClose3();
              setedit3(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (from)</label>

                    {edit3 ? (
                      <input
                        type="date"
                        className="vi_0"
                        placeholder={Sdate}
                        onChange={(e) => setSdate(e.target.value)}
                      />
                    ) : (
                      <>{Sdate}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (to)</label>

                    {edit3 ? (
                      <input
                        type="date"
                        placeholder={Edate}
                        className="vi_0"
                        onChange={(e) => setEdate(e.target.value)}
                      />
                    ) : (
                      <>{Edate}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label>
                      Title of the professional development program organised
                      for teaching staff
                    </label>

                    {edit3 ? (
                      <input
                        type="text"
                        placeholder={title_dev}
                        className="vi_0"
                        onChange={(e) => settitle_dev(e.target.value)}
                      />
                    ) : (
                      <>{title_dev}</>
                    )}
                  </div>

                  <div className=" col-lg-12 lab mb-4">
                    <label>
                      Title of the professional development/ administrative
                      training programs organised for teaching staff
                      (Professional development/ administrative training
                      programs)
                    </label>

                    {edit3 ? (
                      <input
                        type="text"
                        placeholder={titlep}
                        className="vi_0"
                        onChange={(e) => settitlep(e.target.value)}
                      />
                    ) : (
                      <>{titlep}</>
                    )}
                  </div>

                  <div className=" col-lg-12 lab mb-4">
                    <label>No. of participants</label>

                    {edit3 ? (
                      <input
                        type="text"
                        placeholder={Nfp}
                        className="vi_0"
                        onChange={(e) => setNfp(e.target.value)}
                      />
                    ) : (
                      <>{Nfp}</>
                    )}
                  </div>

                  <div className=" col-lg-12 lab mb-4">
                    <label>Relevant document</label>

                    {edit3 ? (
                      <input
                        type="file"
                        placeholder={doc6}
                        className="vi_0"
                        id="upload6"
                        onChange={(e) => setdoc6(e.target.files[0])}
                      />
                    ) : (
                      <>{doc6?.name}</>
                    )}
                  </div>
                  <div className="col-md-12 mb-3 ">
                    <label className="content1" htmlFor="name">
                      Description
                    </label>
                    {edit3 ? (
                      <textarea
                        className="vi_0"
                        type="text"
                        placeholder={ugdesc3}
                        onChange={(e) => {
                          setugdesc3(e.target.value);
                        }}
                      />
                    ) : (
                      <p>{ugdesc3}</p>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit3 ? (
                <>
                  <Button
                    className="btn btn-success"
                    onClick={() => {
                      cat3();
                      handleClose3();
                      setedit3(false);
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit3(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container  ">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 6.3.4</h3>
              <h6 className="mb-5">
                {" "}
                Average percentage of teachers undergoing online/ face-to-face
                Faculty Development Programmes during the last five years{" "}
              </h6>
              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from) </label>

                  {obj4?.Sdate1 ? (
                    <p>{obj4?.Sdate1}</p>
                  ) : (
                    <input
                      type="date"
                      className="vi_0"
                      onChange={(e) => setSdate1(e.target.value)}
                    />
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to) </label>

                  {obj4?.Edate1 ? (
                    <p>{obj4?.Edate1}</p>
                  ) : (
                    <input
                      type="date"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setEdate1(e.target.value)}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Name of teacher who attended</label>

                  {obj4?.namet ? (
                    <p>{obj4?.namet}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setnamet(e.target.value)}
                    />
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Title of the program</label>

                  {obj4?.prgm50 ? (
                    <p>{obj4?.prgm50}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setprgm50(e.target.value)}
                    />
                  )}
                </div>
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label>Upload the relevant document</label>

                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => setdoc7(e.target.files[0])}
                />
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description with minimum 500 Characters
                </label>
                {obj4?.ugdesc4 ? (
                  <p>{obj4?.ugdesc4}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    onChange={(e) => {
                      setugdesc4(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="text-end mt-4">
                {add4 ? (
                  <Button
                    type="submit"
                    className="btn-success"
                    onClick={() => {
                      cat4();
                    }}
                  >
                    Add
                  </Button>
                ) : (
                  <>
                    {" "}
                    <Button
                      type="submit"
                      onClick={handleShow4}
                      className="m-2"
                      variant="warning "
                      style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                    >
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>{" "}
          </div>

          {/* 06_634*/}

          <Modal
            show={show4}
            onHide={() => {
              handleClose4();
              setedit4(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (from) </label>

                    {edit4 ? (
                      <input
                        type="date"
                        className="vi_0"
                        placeholder={Sdate1}
                        onChange={(e) => setSdate1(e.target.value)}
                      />
                    ) : (
                      <>{Sdate1}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (to) </label>

                    {edit4 ? (
                      <input
                        type="date"
                        placeholder={Edate1}
                        className="vi_0"
                        onChange={(e) => setEdate1(e.target.value)}
                      />
                    ) : (
                      <>{Edate1}</>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className=" col-lg-12 lab mb-4">
                    <label>Name of teacher who attended</label>

                    {edit4 ? (
                      <input
                        type="text"
                        placeholder={namet}
                        className="vi_0"
                        onChange={(e) => setnamet(e.target.value)}
                      />
                    ) : (
                      <>{namet}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label>Title of the program</label>

                    {edit4 ? (
                      <input
                        type="text"
                        placeholder={prgm50}
                        className="vi_0"
                        onChange={(e) => setprgm50(e.target.value)}
                      />
                    ) : (
                      <>{prgm50}</>
                    )}
                  </div>
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit4 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      placeholder={ugdesc4}
                      onChange={(e) => {
                        setugdesc4(e.target.value);
                      }}
                    />
                  ) : (
                    <p>{ugdesc4}</p>
                  )}
                </div>

                <div className=" col-lg-12 lab mb-4">
                  <label>Relevant document</label>

                  {edit4 ? (
                    <input
                      type="file"
                      className="vi_0"
                      placeholder={doc7}
                      onChange={(e) => setdoc7(e.target.files[0])}
                    />
                  ) : (
                    <>{doc7.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit4 ? (
                <>
                  <Button
                    className="btn btn-success"
                    onClick={() => {
                      cat4();
                      handleClose4();
                      setedit4(false);
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit4(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container  ">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 6.4.2</h3>
              <h6 className="mb-5">
                {" "}
                Funds / Grants received from non-government bodies, individuals,
                philanthropists during the last five years (not covered in
                Criterion III and V) (INR in Lakhs) (10)
              </h6>
              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Purpose of the Grant</label>

                  {obj5?.pgrant ? (
                    <p>{obj5?.pgrant}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setpgrant(e.target.value)}
                    />
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label> Funds/ Grants received (INR in lakhs)</label>

                  {obj5?.Fundg ? (
                    <p>{obj5?.Fundg}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setFundg(e.target.value)}
                    />
                  )}
                </div>
              </div>

              <div className="  lab mb-4">
                <label>
                  {" "}
                  Name of the non government funding agencies/ individuals
                </label>

                {obj5?.namefgov ? (
                  <p>{obj5?.namefgov}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setnamefgov(e.target.value)}
                  />
                )}
              </div>

              <div className="lab mb-4">
                <label>
                  {" "}
                  Upload the Audited Statement of Accounts reflecting the
                  receipts{" "}
                </label>

                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => setdoc8(e.target.files[0])}
                />
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description
                </label>
                {obj5?.ugdesc5 ? (
                  <p>{obj5?.ugdesc5}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    onChange={(e) => {
                      setugdesc5(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="text-end mt-4">
                {add5 ? (
                  <Button
                    type="submit"
                    className="btn-success"
                    onClick={() => {
                      cat5();
                    }}
                  >
                    ADD
                  </Button>
                ) : (
                  <>
                    {" "}
                    <Button
                      type="submit"
                      onClick={handleShow5}
                      className="m-2"
                      variant="warning "
                      style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                    >
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={show5}
            onHide={() => {
              handleClose5();
              setedit5(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <div className=" col-lg-12 lab mb-4">
                    <label>Purpose of the Grant</label>

                    {edit5 ? (
                      <input
                        type="text"
                        placeholder={pgrant}
                        className="vi_0"
                        onChange={(e) => setpgrant(e.target.value)}
                      />
                    ) : (
                      <>{pgrant}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label> Funds/ Grants received (INR in lakhs)</label>

                    {edit5 ? (
                      <input
                        type="text"
                        placeholder={Fundg}
                        className="vi_0"
                        onChange={(e) => setFundg(e.target.value)}
                      />
                    ) : (
                      <>{Fundg}</>
                    )}
                  </div>
                </div>

                <div className="col-lg-12  lab mb-4">
                  <label>
                    Name of the non government funding agencies/ individuals
                  </label>

                  {edit5 ? (
                    <input
                      type="text"
                      placeholder={namefgov}
                      className="vi_0"
                      onChange={(e) => setnamefgov(e.target.value)}
                    />
                  ) : (
                    <>{namefgov}</>
                  )}
                </div>

                <div className="col-lg-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit5 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      placeholder={ugdesc5}
                      onChange={(e) => {
                        setugdesc5(e.target.value);
                      }}
                    />
                  ) : (
                    <p>{ugdesc5}</p>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    Upload the Audited Statement of Accounts reflecting the
                    receipts
                  </label>

                  {edit5 ? (
                    <input
                      type="file"
                      className="vi_0"
                      onChange={(e) => setdoc8(e.target.files[0])}
                    />
                  ) : (
                    <>{doc8.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit5 ? (
                <>
                  <Button
                    className="btn btn-success"
                    onClick={() => {
                      cat5();
                      handleClose5();
                      setedit5(false);
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit5(true)}
                  >
                    Edit{" "}
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container  ">
            <div className="row-fluid border p-4 bs mt-5 ">
              <h3>Criteria 6.5.3</h3>
              <h6 className="mb-5 ">
                Quality assurance initiatives of the institution include: (10)
                1. Regular meeting of Internal Quality Assurance Cell (IQAC);
                Feedback collected, analysed and used for improvements 2.
                Collaborative quality intitiatives with other institution(s) 3.
                Participation in NIRF 4.Any other quality audit recognized by
                state, national or international agencies (ISO Certification)
              </h6>
              <div className="col-lg-12 mb-4">
                <label> Regular meetings of the IQAC held</label>

                {obj6?.iqac ? (
                  <p>{obj6?.iqac}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setIqac(e.target.value)}
                  />
                )}
              </div>

              <div className="col-lg-12 mb-4">
                <label>
                  {" "}
                  Conferences, Seminars, Workshops on quality conducted{" "}
                </label>

                {obj6?.cswq ? (
                  <p>{obj6?.cswq}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setcswq(e.target.value)}
                  />
                )}
              </div>

              <div className="col-lg-12 mb-4">
                <label>
                  {" "}
                  Collaborative quality initiatives with other institution(s)
                  (Provide name of the institution and activity)
                </label>

                {obj6?.iaa ? (
                  <p>{obj6?.iaa}</p>
                ) : (
                  <input
                    type=""
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setiaa(e.target.value)}
                  />
                )}
              </div>

              <div className=" col-lg-12 lab mb-4">
                <label> Participation in NIRF along with Status.</label>

                {obj6?.nirfs ? (
                  <p>{obj6?.nirfs}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setnirfs(e.target.value)}
                  />
                )}
              </div>

              <div className="lab">
                <label>
                  Orientation programme on quality issues for teachers and
                  students,{" "}
                </label>
              </div>

              <div className="row">
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (from) </label>

                  {obj6?.Sdate2 ? (
                    <p>{obj6?.Sdate2}</p>
                  ) : (
                    <input
                      type="date"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setSdate2(e.target.value)}
                    />
                  )}
                </div>
                <div className=" col-lg-6 lab mb-4">
                  <label>Date (to) </label>

                  {obj6?.Edate2 ? (
                    <p>{obj6?.Edate2}</p>
                  ) : (
                    <input
                      type="date"
                      placeholder=" "
                      className="vi_0"
                      onChange={(e) => setEdate2(e.target.value)}
                    />
                  )}
                </div>
              </div>

              <div className="col-lg-12 mb-4">
                <label>
                  {" "}
                  Any other quality audit as recognized by the State, National
                  or International agencies (ISO certification, NBA and such
                  others)
                </label>

                {obj6?.nba ? (
                  <p>{obj6?.nba}</p>
                ) : (
                  <input
                    type="text"
                    placeholder=" "
                    className="vi_0"
                    onChange={(e) => setnba(e.target.value)}
                  />
                )}
              </div>

              <div className="lab mb-4">
                <label> Upload the relevant document</label>

                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => setdoc9(e.target.files[0])}
                />
              </div>

              <div className="col-md-12 mb-3 ">
                <label className="content1" htmlFor="name">
                  Description with minimum 500 Characters
                </label>
                {obj6?.ugdesc6 ? (
                  <p>{obj6?.ugdesc6}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    type="text"
                    id="name"
                    onChange={(e) => {
                      setugdesc6(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="text-end mt-4">
                {add6 ? (
                  <Button
                    type="submit"
                    className="btn-success"
                    onClick={() => {
                      cat6();
                    }}
                  >
                    ADD
                  </Button>
                ) : (
                  <>
                    <Button
                      type="submit"
                      onClick={handleShow6}
                      className="m-2"
                      variant="warning "
                      style={{ fontSize: "16px", color: "", fontWeight: "600" }}
                    >
                      Preview
                    </Button>
                  </>
                )}
              </div>
            </div>{" "}
          </div>

          <Modal
            show={show6}
            onHide={() => {
              handleClose6();
              setedit6(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="lab mb-4">
                  <label> Regular meetings of the IQAC held</label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={iqac}
                      className="vi_0"
                      onChange={(e) => setIqac(e.target.value)}
                    />
                  ) : (
                    <>{iqac}</>
                  )}
                </div>
                <div className="  lab mb-4">
                  <label>
                    {" "}
                    Conferences, Seminars, Workshops on quality conducted{" "}
                  </label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={cswq}
                      className="vi_0"
                      onChange={(e) => setcswq(e.target.value)}
                    />
                  ) : (
                    <>{cswq}</>
                  )}
                </div>

                <div className="lab mb-4">
                  <label>
                    {" "}
                    Collaborative quality initiatives with other institution(s)
                    (Provide name of the institution and activity)
                  </label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={iaa}
                      className="vi_0"
                      onChange={(e) => setiaa(e.target.value)}
                    />
                  ) : (
                    <>{iaa}</>
                  )}
                </div>
                <div className=" col-lg-12 lab mb-4">
                  <label> Participation in NIRF along with Status.</label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={nirfs}
                      className="vi_0"
                      onChange={(e) => setnirfs(e.target.value)}
                    />
                  ) : (
                    <>{nirfs}</>
                  )}
                </div>

                <div className="lab">
                  <label>
                    Orientation programme on quality issues for teachers and
                    students,
                  </label>
                </div>

                <div className="row">
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (from)</label>

                    {edit6 ? (
                      <input
                        type="date"
                        placeholder={Sdate2}
                        className="vi_0"
                        onChange={(e) => setSdate2(e.target.value)}
                      />
                    ) : (
                      <>{Sdate2}</>
                    )}
                  </div>
                  <div className=" col-lg-12 lab mb-4">
                    <label>Date (to)</label>

                    {edit6 ? (
                      <input
                        type="date"
                        placeholder={Edate2}
                        className="vi_0"
                        onChange={(e) => setEdate2(e.target.value)}
                      />
                    ) : (
                      <>{Edate2}</>
                    )}
                  </div>
                </div>

                <div className="lab mb-4">
                  <label>
                    {" "}
                    Any other quality audit as recognized by the State, National
                    or International agencies (ISO certification, NBA and such
                    others
                  </label>

                  {edit6 ? (
                    <input
                      type="text"
                      placeholder={nba}
                      className="vi_0"
                      onChange={(e) => setnba(e.target.value)}
                    />
                  ) : (
                    <>{nba}</>
                  )}
                </div>

                <div className="col-md-12 mb-3 ">
                  <label className="content1" htmlFor="name">
                    Description
                  </label>
                  {edit6 ? (
                    <textarea
                      className="vi_0"
                      type="text"
                      id="name"
                      onChange={(e) => {
                        setugdesc6(e.target.value);
                      }}
                    />
                  ) : (
                    <p>{ugdesc6}</p>
                  )}
                </div>

                <div className="lab mb-4">
                  <label> Upload the relevant document</label>

                  {edit6 ? (
                    <>
                      <input
                        type="file"
                        className="vi_0"
                        placeholder={doc9}
                        onChange={(e) => setdoc9(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{doc9.name}</>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {edit6 ? (
                <>
                  <Button
                    className="btn btn-success"
                    onClick={() => {
                      cat6();
                      handleClose6();
                      setedit6(false);
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    onClick={() => setedit6(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>

          <div className="container mt-3 mb-3">
            <div className="row-fluid">
              <div
                class=" col-md-6-fluid border bs"
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <Button
                  type="submit"
                  className="btn-success "
                  onClick={() => Ugcriteria6()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderGcriteria6;
