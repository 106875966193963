import moment from "moment";
import React from "react";

const PG5533aqar = React.memo(({ item }) => {
  return (
    <div
      className="container-fluid report-pdf mt-5"
      style={{ padding: "0px 100px" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the student participated</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_533?.PG_NStdParti}</span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Date of Event/Activity (DD-MM-YYYY)</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>
            {" "}
            {moment(item?.PG_Criteria05_533?.PG_EDate).format("DD-MM-YYYY")}
          </span>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6 report-head  mb-3">
          <span>Name of the event/competition</span>
        </div>
        <div className="col-lg-6 report-text   mb-3">
          <span>{item?.PG_Criteria05_533?.PG_EName}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Description</span>
        </div>
        <div className="col-lg-12 report-text  mb-3">
          <span> {item?.PG_Criteria05_533?.PG_Desc28}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 report-head  mb-3">
          <span> Document</span>
        </div>
        <div className="col-lg-6 report-text  mb-3">
          <span>
            {/* <a href={`${item?.PG_Criteria05_533?.PG_Link28}`}> */}

            {/* </a> */}
            <a
              href={`https://brightnaac.co.in/PG_Criteria05/${item?.PG_Criteria05_533?.PG_Link28}`}
              target="blank_"
            >
              View Document
            </a>
          </span>
        </div>
      </div>
    </div>
  );
});

export default PG5533aqar;
