import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

const AutonomousCri3 = () => {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  const [show18, setShow18] = useState(false);
  const handleClose18 = () => setShow18(false);
  const handleShow18 = () => setShow18(true);

  const [show19, setShow19] = useState(false);
  const handleClose19 = () => setShow19(false);
  const handleShow19 = () => setShow19(true);

  const [show20, setShow20] = useState(false);
  const handleClose20 = () => setShow20(false);
  const handleShow20 = () => setShow20(true);

  const [show21, setShow21] = useState(false);
  const handleClose21 = () => setShow21(false);
  const handleShow21 = () => setShow21(true);

  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);

  const [show23, setShow23] = useState(false);
  const handleClose23 = () => setShow23(false);
  const handleShow23 = () => setShow23(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const autoCriteria03Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getautocri03details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.autocriteria03);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.autocriteria03);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatingauto3`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Auto_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          autoCriteria03Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      autoCriteria03Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="mt-4"> Autonomous Criteria3 </h4>
            </div>
            <div className="col-lg-6 text-end">
              <div className="d-flex justify-content-end">
                <h6 className="mt-4">Current Rating: </h6>
                <div className="mt-4">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Auto_Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div>
                        <span className="">{item?.rating}</span>
                        <span>
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center">
            <div className="ad-b mt-4 row align-items-center">
              <div className=" col-lg-4 do-sear mt-4 d-flex ">
                <input
                  type="text"
                  placeholder="Search"
                  className="vi_0"
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {/* <button>Search</button> */}
              </div>

              <div className=" col-lg-2 lab">
                <label>From</label>
                <br />
                <DatePicker
                  onChange={onChangeFrom}
                  picker="year"
                  className="vi_0"
                />
              </div>
              <div className=" col-lg-2 lab">
                <label>To</label>
                <br />
                <DatePicker
                  onChange={onChangeTo}
                  picker="year"
                  className="vi_0"
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-5 do-sear  d-flex align-items-center   ">
                <label style={{ width: "130px" }}>Select Year</label>
                <select
                  className="criteriaform "
                  style={{ width: "150px" }}
                  onChange={(e) => setselectedYear(e.target.value)}
                >
                  {allYears
                    ?.sort((a, b) => {
                      return Number(b?.from) - Number(a?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg-5">
                <Nav defaultActiveKey="/autocriteria03">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-1"
                      onClick={() =>
                        navigate(`/adminclgautocri3312/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.1.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-2"
                      onClick={() =>
                        navigate(`/adminclgautocri3313/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.1.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri3321/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">
                        3.2.1 & 3.2.2 & 3.2.4
                      </Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri3332/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.3.2</Button>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri3343/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.4.3</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri3344/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.4.4</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri3351/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.5.1&3.5.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri3362/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.6.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri3363/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.6.3 & 3.6.4</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri3371/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.7.1</Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-3"
                      onClick={() =>
                        navigate(`/adminclgautocri3372/${params?.id}`)
                      }
                    >
                      <Button className="criteriabtn">3.7.2</Button>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              {/* <div className="col-lg-2">
                <Button
                  type="Submit"
                  className="btn btn-warning"
                  style={{ color: "white" }}
                  onClick={() => {
                    // setValue(item);
                    handleShow();
                  }}
                >
                  Add Rating
                </Button>
              </div> */}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 w-100"></div>
        </div>
        <div className="container">
          <div className="mo-gra mt-2">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 3.1.2 */}
                  <th>Criteria</th>
                  <th>Name of the teacher provided with seed money</th>
                  <th>The amount of seed money (INR in Lakhs)</th>
                  <th>Year of receiving</th>
                  <th>Description</th>
                  <th>Relevant document</th>

                  {/* 3.1.3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the teacher awarded national/ international
                    fellowship/financial support
                  </th>
                  <th>Name of the award/fellowship</th>
                  <th>Year of Award</th>
                  <th>Awarding Agency</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 3.2.1 & 3.2.2 & 3.2.4 */}
                  <th>Criteria</th>
                  <th>Sl.no.</th>
                  <th>
                    Name of the Principal Investigator/ Co Investigator (if
                    applicable)
                  </th>
                  <th>Name of the Funding agency</th>
                  <th>Type (Government/Non-Government)</th>
                  <th>Department of Principal Investigator/ Co Investigator</th>
                  <th>Year of Award</th>
                  <th>Funds provided (INR in lakhs)</th>
                  <th>Duration of the project</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 3.3.2 */}
                  <th>Criteria</th>
                  <th>Name of the workshop/ seminar</th>
                  <th>Name of the students</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Description</th>
                  <th>Link to the relevant document</th>

                  {/* 3.4.3 */}
                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Department of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>website of the Journal</th>
                  <th>Link to article/paper/abstract of the article</th>

                  {/* 3.4.4 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>

                  {/* 3.5.1 & 3.5.2 */}
                  <th>Criteria</th>
                  <th>
                    Total amount generated from consultancy and corporate
                    training year wise
                  </th>
                  <th>
                    Total amount spent on developing facilities, training
                    teachers and staff for undertaking consultancy during the
                    last five years
                  </th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>

                  {/* 3.6.2 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Name of the Award/ recognition</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Year of award</th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>

                  {/* 3.6.3 & 3.6.4 */}
                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of award</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>

                  {/* 3.7.1 */}
                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the collaborating agency with contact details</th>
                  <th>Title of the collaborative activity</th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Nature of the activity</th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>

                  {/* 3.7.2 */}
                  <th>Criteria</th>
                  <th>Organisation with which MoU is signed</th>
                  <th>Name of the institution/ industry/ corporate house</th>
                  <th>Year of signing MoU</th>
                  <th>Duration</th>
                  <th>List the actual activities under each MOU year-wise</th>
                  <th>Description</th>
                  <th>Link to website of the Journal</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Auto_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Auto_Year &&
                    (SearchItem === "" ||
                      item1?.Auto_Criteria03_312?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_SeedMoney?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_Seed?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_Receiving?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_312?.Auto_Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_FellowShip?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_AFellowship?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_Award?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_Agency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_313?.Auto_Desc6?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_SLNO?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Invest?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_FAgency?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Dep?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Award1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Funds?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Project?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_321?.Auto_Desc7?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_332?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_332?.Auto_Seminar?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_332?.Auto_Parti?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Auto_Criteria03_332?.Auto_DFrom)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      moment(item1?.Auto_Criteria03_332?.Auto_DTo)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Auto_Criteria03_332?.Auto_Desc8?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Paper?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Author?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Dept?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Journal?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Pub?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_ISSN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_ISIT?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_343?.Auto_Desc9?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_SLno?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Name?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Book?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Paper1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Title4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Year2?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_ISBN?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Whether?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Name1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_344?.Auto_Desc10?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Consult?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Project1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Agency1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Revenue?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Trainee?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Teacher1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Facility1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Agen1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Consult2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Total1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_351?.Auto_Desc11?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Name2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Name3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Name4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Year4?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_362?.Auto_Desc12?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Name5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_org?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Scheme?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Year5?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Participants1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_363?.Auto_Desc13?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_SLNO1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Colloborative?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Agency2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Participants3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Collo?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Durat?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Activity?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_371?.Auto_Desc14?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Organ?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_IIC?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Signing?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Durat1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_List?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Auto_Criteria03_372?.Auto_Desc15?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Auto_Year}</td>

                      {/* 3.1.2 */}
                      <td>{item?.Auto_Criteria03_312?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_312?.Auto_Seed}</td>
                      <td>{item?.Auto_Criteria03_312?.Auto_SeedMoney}</td>
                      <td>{item?.Auto_Criteria03_312?.Auto_Receiving}</td>
                      <td>
                        {item?.Auto_Criteria03_312?.Auto_Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow13();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.1.3 */}
                      <td>{item?.Auto_Criteria03_313?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_FellowShip}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_AFellowship}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_Award}</td>
                      <td>{item?.Auto_Criteria03_313?.Auto_Agency}</td>
                      <td>
                        {item?.Auto_Criteria03_313?.Auto_Desc6?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow14();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.2.1 & 3.2.2 & 3.2.4 */}
                      <td>{item?.Auto_Criteria03_321?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_SLNO}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Invest}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_FAgency}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Type}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Dep}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Award1}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Funds}</td>
                      <td>{item?.Auto_Criteria03_321?.Auto_Project}</td>
                      <td>
                        {item?.Auto_Criteria03_321?.Auto_Desc7?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow15();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.3.2 */}
                      <td>{item?.Auto_Criteria03_332?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_332?.Auto_Seminar}</td>
                      <td>{item?.Auto_Criteria03_332?.Auto_Parti}</td>
                      <td>
                        {moment(item?.Auto_Criteria03_332?.Auto_DFrom).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item?.Auto_Criteria03_332?.Auto_DTo).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {item?.Auto_Criteria03_332?.Auto_Desc8?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow16();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.3 */}
                      <td>{item?.Auto_Criteria03_343?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Paper}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Author}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Dept}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Journal}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_Pub}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_ISSN}</td>
                      <td>{item?.Auto_Criteria03_343?.Auto_ISIT}</td>
                      <td>
                        {item?.Auto_Criteria03_343?.Auto_Desc9?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow17();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.4.4 */}
                      <td>{item?.Auto_Criteria03_344?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_SLno}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Name}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Book}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Paper1}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Title4}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Year2}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_ISBN}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Name1}</td>
                      <td>{item?.Auto_Criteria03_344?.Auto_Whether}</td>
                      <td>
                        {item?.Auto_Criteria03_344?.Auto_Desc10?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow18();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.5.1 & 3.5.2 */}
                      <td>{item?.Auto_Criteria03_351?.Auto_criteria}</td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Name of the teacher-consultants</th>
                            <th>
                              Name of consultancy project/corporate training
                              program
                            </th>
                            <th>
                              Consulting/Sponsoring agency with contact details
                            </th>
                            <th>Revenue generated (INR in Lakhs)</th>
                            <th>Number of trainees</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Auto_Criteria03_351?.Auto_Consult}</td>
                              <td>
                                {item?.Auto_Criteria03_351?.Auto_Project1}
                              </td>
                              <td>{item?.Auto_Criteria03_351?.Auto_Agency1}</td>
                              <td>{item?.Auto_Criteria03_351?.Auto_Revenue}</td>
                              <td>{item?.Auto_Criteria03_351?.Auto_Trainee}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table responsive>
                          <thead>
                            <th>Names of the teachers/staff</th>
                            <th>
                              Name of the facilities developed and department
                            </th>
                            <th>
                              Agency seeking training with contact details
                            </th>
                            <th>Name of consultancy</th>
                            <th>Total amount spent (INR in Lakhs)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {item?.Auto_Criteria03_351?.Auto_Teacher1}
                              </td>
                              <td>
                                {item?.Auto_Criteria03_351?.Auto_Facility1}
                              </td>
                              <td>{item?.Auto_Criteria03_351?.Auto_Agen1}</td>
                              <td>
                                {item?.Auto_Criteria03_351?.Auto_Consult2}
                              </td>
                              <td>{item?.Auto_Criteria03_351?.Auto_Total1}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>

                      <td>
                        {item?.Auto_Criteria03_351?.Auto_Desc11?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow19();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.2 */}
                      <td>{item?.Auto_Criteria03_362?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Name2}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Name3}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Name4}</td>
                      <td>{item?.Auto_Criteria03_362?.Auto_Year4}</td>
                      <td>
                        {item?.Auto_Criteria03_362?.Auto_Desc12?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow20();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.6.3 & 3.6.4 */}
                      <td>{item?.Auto_Criteria03_363?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Name5}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_org}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Scheme}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Year5}</td>
                      <td>{item?.Auto_Criteria03_363?.Auto_Participants1}</td>
                      <td>
                        {item?.Auto_Criteria03_363?.Auto_Desc13?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow21();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.1 */}
                      <td>{item?.Auto_Criteria03_371?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_SLNO1}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Agency2}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Colloborative}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Participants3}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Collo}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Durat}</td>
                      <td>{item?.Auto_Criteria03_371?.Auto_Activity}</td>
                      <td>
                        {item?.Auto_Criteria03_371?.Auto_Desc14?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow22();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 3.7.2 */}
                      <td>{item?.Auto_Criteria03_372?.Auto_criteria}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_Organ}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_IIC}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_Signing}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_Durat1}</td>
                      <td>{item?.Auto_Criteria03_372?.Auto_List}</td>
                      <td>
                        {item?.Auto_Criteria03_372?.Auto_Desc15?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow23();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow12();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_312?.Auto_Link5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_313?.Auto_Link6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_321?.Auto_Link7}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_332?.Auto_Link8}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_343?.Auto_Link9}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_343?.Auto_Link10}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 7 model */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_344?.Auto_Link11}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 8 model */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_351?.Auto_Link12}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 9 model */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_362?.Auto_Link13}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 10 model */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_363?.Auto_Link14}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 11 model */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_371?.Auto_Link15}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 12 model */}
          <Modal show={show12} onHide={handleClose12}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Final Report</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Auto_Criteria03/${View?.Auto_Criteria03_372?.Auto_Link16}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal_13 */}
          <Modal show={show13} onHide={handleClose13}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_312?.Auto_Desc5}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose13}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_14 */}
          <Modal show={show14} onHide={handleClose14}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_313?.Auto_Desc6}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose14}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_15 */}
          <Modal show={show15} onHide={handleClose15}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_321?.Auto_Desc7}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose15}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_16 */}
          <Modal show={show16} onHide={handleClose16}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_332?.Auto_Desc8}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose16}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal_17 */}
          <Modal show={show17} onHide={handleClose17}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_343?.Auto_Desc9}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose17}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal_18 */}
          <Modal show={show18} onHide={handleClose18}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_344?.Auto_Desc10}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose18}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal_19 */}
          <Modal show={show19} onHide={handleClose19}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_351?.Auto_Desc11}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose19}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal_20 */}
          <Modal show={show20} onHide={handleClose20}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_362?.Auto_Desc12}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose20}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_21 */}
          <Modal show={show21} onHide={handleClose21}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_363?.Auto_Desc13}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose21}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_22 */}
          <Modal show={show22} onHide={handleClose22}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_371?.Auto_Desc14}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose22}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Show description modal_23 */}
          <Modal show={show23} onHide={handleClose23}>
            <Modal.Body>
              <div>{View?.Auto_Criteria03_372?.Auto_Desc15}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose23}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AutonomousCri3;
