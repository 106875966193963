import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Auto_Criteria003 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);
  const [edit2, setedit2] = useState(false);
  const [add2, setadd2] = useState(true);
  const [edit3, setedit3] = useState(false);
  const [add3, setadd3] = useState(true);
  const [edit4, setedit4] = useState(false);
  const [add4, setadd4] = useState(true);
  const [edit5, setedit5] = useState(false);
  const [add5, setadd5] = useState(true);
  const [edit6, setedit6] = useState(false);
  const [add6, setadd6] = useState(true);
  const [edit7, setedit7] = useState(false);
  const [add7, setadd7] = useState(true);
  const [edit8, setedit8] = useState(false);
  const [add8, setadd8] = useState(true);
  const [edit9, setedit9] = useState(false);
  const [add9, setadd9] = useState(true);
  const [edit10, setedit10] = useState(false);
  const [add10, setadd10] = useState(true);
  const [edit11, setedit11] = useState(false);
  const [add11, setadd11] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  //post method for auto_criteria_03
  const [Auto_Year, setAuto_Year] = useState("");

  const [Auto_Seed, setAuto_Seed] = useState("");
  const [Auto_SeedMoney, setAuto_SeedMoney] = useState("");
  const [Auto_Receiving, setAuto_Receiving] = useState("");
  const [Auto_Desc5, setAuto_Desc5] = useState("");
  const [Auto_Link5, setAuto_Link5] = useState("");

  const [Auto_FellowShip, setAuto_FellowShip] = useState("");
  const [Auto_AFellowship, setAuto_AFellowship] = useState("");
  const [Auto_Award, setAuto_Award] = useState("");
  const [Auto_Agency, setAuto_Agency] = useState("");
  const [Auto_Desc6, setAuto_Desc6] = useState("");
  const [Auto_Link6, setAuto_Link6] = useState("");

  const [Auto_SLNO, setAuto_SLNO] = useState("");
  const [Auto_Invest, setAuto_Invest] = useState("");
  const [Auto_FAgency, setAuto_FAgency] = useState("");
  const [Auto_Type, setAuto_Type] = useState("");
  const [Auto_Dep, setAuto_Dep] = useState("");
  const [Auto_Award1, setAuto_Award1] = useState("");
  const [Auto_Funds, setAuto_Funds] = useState("");
  const [Auto_Project, setAuto_Project] = useState("");
  const [Auto_Desc7, setAuto_Desc7] = useState("");
  const [Auto_Link7, setAuto_Link7] = useState("");

  const [Auto_Seminar, setAuto_Seminar] = useState("");
  const [Auto_Parti, setAuto_Parti] = useState("");
  const [Auto_DFrom, setAuto_DFrom] = useState("");
  const [Auto_DTo, setAuto_DTo] = useState("");
  const [Auto_Desc8, setAuto_Desc8] = useState("");
  const [Auto_Link8, setAuto_Link8] = useState("");

  const [Auto_Paper, setAuto_Paper] = useState("");
  const [Auto_Author, setAuto_Author] = useState("");
  const [Auto_Dept, setAuto_Dept] = useState("");
  const [Auto_Journal, setAuto_Journal] = useState("");
  const [Auto_Pub, setAuto_Pub] = useState("");
  const [Auto_ISSN, setAuto_ISSN] = useState("");
  const [Auto_ISIT, setAuto_ISIT] = useState("");
  const [Auto_Desc9, setAuto_Desc9] = useState("");
  const [Auto_Link9, setAuto_Link9] = useState("");
  const [Auto_Link10, setAuto_Link10] = useState("");

  const [Auto_SLno, setAuto_SLno] = useState("");
  const [Auto_Name, setAuto_Name] = useState("");
  const [Auto_Book, setAuto_Book] = useState("");
  const [Auto_Paper1, setAuto_Paper1] = useState("");
  const [Auto_Title4, setAuto_Title4] = useState("");
  const [Auto_Year2, setAuto_Year2] = useState("");
  const [Auto_ISBN, setAuto_ISBN] = useState("");
  const [Auto_Whether, setAuto_Whether] = useState("");
  const [Auto_Name1, setAuto_Name1] = useState("");
  const [Auto_Desc10, setAuto_Desc10] = useState("");
  const [Auto_Link11, setAuto_Link11] = useState("");

  const [Auto_Consult, setAuto_Consult] = useState("");
  const [Auto_Project1, setAuto_Project1] = useState("");
  const [Auto_Agency1, setAuto_Agency1] = useState("");
  const [Auto_Revenue, setAuto_Revenue] = useState("");
  const [Auto_Trainee, setAuto_Trainee] = useState("");
  const [Auto_Teacher1, setAuto_Teacher1] = useState("");
  const [Auto_Facility1, setAuto_Facility1] = useState("");
  const [Auto_Agen1, setAuto_Agen1] = useState("");
  const [Auto_Consult2, setAuto_Consult2] = useState("");
  const [Auto_Total1, setAuto_Total1] = useState("");
  const [Auto_Desc11, setAuto_Desc11] = useState("");
  const [Auto_Link12, setAuto_Link12] = useState("");

  const [Auto_Name2, setAuto_Name2] = useState("");
  const [Auto_Name3, setAuto_Name3] = useState("");
  const [Auto_Name4, setAuto_Name4] = useState("");
  const [Auto_Year4, setAuto_Year4] = useState("");
  const [Auto_Desc12, setAuto_Desc12] = useState("");
  const [Auto_Link13, setAuto_Link13] = useState("");

  const [Auto_Name5, setAuto_Name5] = useState("");
  const [Auto_org, setAuto_org] = useState("");
  const [Auto_Scheme, setAuto_Scheme] = useState("");
  const [Auto_Year5, setAuto_Year5] = useState("");
  const [Auto_Participants1, setAuto_Participants1] = useState("");
  const [Auto_Desc13, setAuto_Desc13] = useState("");
  const [Auto_Link14, setAuto_Link14] = useState("");

  const [Auto_SLNO1, setAuto_SLNO1] = useState("");
  const [Auto_Colloborative, setAuto_Colloborative] = useState("");
  const [Auto_Agency2, setAuto_Agency2] = useState("");
  const [Auto_Participants3, setAuto_Participants3] = useState("");
  const [Auto_Collo, setAuto_Collo] = useState("");
  const [Auto_Durat, setAuto_Durat] = useState("");
  const [Auto_Activity, setAuto_Activity] = useState("");
  const [Auto_Desc14, setAuto_Desc14] = useState("");
  const [Auto_Link15, setAuto_Link15] = useState("");

  const [Auto_Organ, setAuto_Organ] = useState("");
  const [Auto_IIC, setAuto_IIC] = useState("");
  const [Auto_Signing, setAuto_Signing] = useState("");
  const [Auto_Durat1, setAuto_Durat1] = useState("");
  const [Auto_List, setAuto_List] = useState("");
  const [Auto_Desc15, setAuto_Desc15] = useState("");
  const [Auto_Link16, setAuto_Link16] = useState("");

  const [obj1, setobj1] = useState({});
  const [obj2, setobj2] = useState({});
  const [obj3, setobj3] = useState({});
  const [obj4, setobj4] = useState({});
  const [obj5, setobj5] = useState({});
  const [obj6, setobj6] = useState({});
  const [obj7, setobj7] = useState({});
  const [obj8, setobj8] = useState({});
  const [obj9, setobj9] = useState({});
  const [obj10, setobj10] = useState({});
  const [obj11, setobj11] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getautocriteria3 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));

      let am2 = sessionStorage.getItem("cat2");
      setobj2(JSON.parse(am2));

      let am3 = sessionStorage.getItem("cat3");
      setobj3(JSON.parse(am3));

      let am4 = sessionStorage.getItem("cat4");
      setobj4(JSON.parse(am4));

      let am5 = sessionStorage.getItem("cat5");
      setobj5(JSON.parse(am5));

      let am6 = sessionStorage.getItem("cat6");
      setobj6(JSON.parse(am6));

      let am7 = sessionStorage.getItem("cat7");
      setobj7(JSON.parse(am7));

      let am8 = sessionStorage.getItem("cat8");
      setobj8(JSON.parse(am8));

      let am9 = sessionStorage.getItem("cat9");
      setobj9(JSON.parse(am9));

      let am10 = sessionStorage.getItem("cat10");
      setobj10(JSON.parse(am10));

      let am11 = sessionStorage.getItem("cat11");
      setobj11(JSON.parse(am11));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getautocriteria3();
  }, []);

  const autocriteria3 = async () => {
    if (!Auto_Year) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/autoadddetails03",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Year: Auto_Year,

          Auto_Criteria03_312: obj1,
          Auto_Link5: Auto_Link5,

          Auto_Criteria03_313: obj2,
          Auto_Link6: Auto_Link6,

          Auto_Criteria03_321: obj3,
          Auto_Link7: Auto_Link7,

          Auto_Criteria03_332: obj4,
          Auto_Link8: Auto_Link8,

          Auto_Criteria03_343: obj5,
          Auto_Link9: Auto_Link9,
          Auto_Link10: Auto_Link10,

          Auto_Criteria03_344: obj6,
          Auto_Link11: Auto_Link11,

          Auto_Criteria03_351: obj7,
          Auto_Link12: Auto_Link12,

          Auto_Criteria03_362: obj8,
          Auto_Link13: Auto_Link13,

          Auto_Criteria03_363: obj9,
          Auto_Link14: Auto_Link14,

          Auto_Criteria03_371: obj10,
          Auto_Link15: Auto_Link15,

          Auto_Criteria03_372: obj11,
          Auto_Link16: Auto_Link16,

          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          sessionStorage.removeItem("cat2");

          sessionStorage.removeItem("cat3");

          sessionStorage.removeItem("cat4");

          sessionStorage.removeItem("cat5");

          sessionStorage.removeItem("cat6");

          sessionStorage.removeItem("cat7");

          sessionStorage.removeItem("cat8");

          sessionStorage.removeItem("cat9");

          sessionStorage.removeItem("cat10");

          sessionStorage.removeItem("cat11");

          navigate("/autocriteria03");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Auto_Seed ||
          !Auto_SeedMoney ||
          !Auto_Receiving ||
          !Auto_Desc5 ||
          !Auto_Link5
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }

      console.log("nnnnnnnn,", Auto_Link5);
      let obj = {
        Auto_criteria: "3.1.2",
        Auto_Seed: Auto_Seed,
        Auto_SeedMoney: Auto_SeedMoney,
        Auto_Receiving: Auto_Receiving,
        Auto_Desc5: Auto_Desc5,
        Auto_Link5: Auto_Link5,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getautocriteria3();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat2 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc6;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add2) {
        if (
          !Auto_FellowShip ||
          !Auto_AFellowship ||
          !Auto_Award ||
          !Auto_Agency ||
          !Auto_Desc6 ||
          !Auto_Link6
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd2(false);
        }
      }

      console.log("mmmmm,", Auto_Link6);
      let obj = {
        Auto_criteria: "3.1.3",
        Auto_FellowShip: Auto_FellowShip,
        Auto_AFellowship: Auto_AFellowship,
        Auto_Award: Auto_Award,
        Auto_Agency: Auto_Agency,
        Auto_Desc6: Auto_Desc6,
        Auto_Link6: Auto_Link6,
      };
      sessionStorage.setItem("cat2", JSON.stringify(obj));

      alert("Successfully added cat2");
      getautocriteria3();
      setedit2(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat3 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc7;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add3) {
        if (
          !Auto_SLNO ||
          !Auto_Invest ||
          !Auto_FAgency ||
          !Auto_Type ||
          !Auto_Dep ||
          !Auto_Award1 ||
          !Auto_Funds ||
          !Auto_Project ||
          !Auto_Desc7 ||
          !Auto_Link7
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd3(false);
        }
      }

      console.log("ooo,", Auto_Link7);
      let obj = {
        Auto_criteria: "3.2.1 & 3.2.2 & 3.2.4",
        Auto_SLNO: Auto_SLNO,
        Auto_Invest: Auto_Invest,
        Auto_FAgency: Auto_FAgency,
        Auto_Type: Auto_Type,
        Auto_Dep: Auto_Dep,
        Auto_Award1: Auto_Award1,
        Auto_Funds: Auto_Funds,
        Auto_Project: Auto_Project,
        Auto_Desc7: Auto_Desc7,
        Auto_Link7: Auto_Link7,
      };
      sessionStorage.setItem("cat3", JSON.stringify(obj));

      alert("Successfully added cat3");
      getautocriteria3();
      setedit3(false);
    } catch (error) {
      console.log(error);
    }
  };
  const cat4 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc8;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add4) {
        if (
          !Auto_Seminar ||
          !Auto_Parti ||
          !Auto_DFrom ||
          !Auto_DTo ||
          !Auto_Desc8 ||
          !Auto_Link8
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd4(false);
        }
      }

      console.log("pppp,", Auto_Link8);
      let obj = {
        Auto_criteria: "3.3.2",
        Auto_Seminar: Auto_Seminar,
        Auto_Parti: Auto_Parti,
        Auto_DFrom: Auto_DFrom,
        Auto_DTo: Auto_DTo,
        Auto_Desc8: Auto_Desc8,
        Auto_Link8: Auto_Link8,
      };
      sessionStorage.setItem("cat4", JSON.stringify(obj));
      alert("Successfully added cat4");
      getautocriteria3();
      setedit4(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat5 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc9;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add5) {
        if (
          !Auto_Paper ||
          !Auto_Author ||
          !Auto_Dept ||
          !Auto_Journal ||
          !Auto_Pub ||
          !Auto_ISSN ||
          !Auto_ISIT ||
          !Auto_Desc9 ||
          !Auto_Link9 ||
          !Auto_Link10
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd5(false);
        }
      }

      console.log("pppp,", Auto_Link9);
      console.log("qqq,", Auto_Link10);
      let obj = {
        Auto_criteria: "3.4.3",
        Auto_Paper: Auto_Paper,
        Auto_Author: Auto_Author,
        Auto_Dept: Auto_Dept,
        Auto_Journal: Auto_Journal,
        Auto_Pub: Auto_Pub,
        Auto_ISSN: Auto_ISSN,
        Auto_ISIT: Auto_ISIT,
        Auto_Desc9: Auto_Desc9,
        Auto_Link9: Auto_Link9,
        Auto_Link10: Auto_Link10,
      };
      sessionStorage.setItem("cat5", JSON.stringify(obj));
      alert("Successfully added cat5");
      getautocriteria3();
      setedit5(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat6 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc10;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add6) {
        if (
          !Auto_SLno ||
          !Auto_Name ||
          !Auto_Book ||
          !Auto_Paper1 ||
          !Auto_Title4 ||
          !Auto_Year2 ||
          !Auto_ISBN ||
          !Auto_Whether ||
          !Auto_Name1 ||
          !Auto_Desc10 ||
          !Auto_Link11
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd6(false);
        }
      }

      console.log("sss,", Auto_Link11);
      let obj = {
        Auto_criteria: "3.4.4",
        Auto_SLno: Auto_SLno,
        Auto_Name: Auto_Name,
        Auto_Book: Auto_Book,
        Auto_Paper1: Auto_Paper1,
        Auto_Title4: Auto_Title4,
        Auto_Year2: Auto_Year2,
        Auto_ISBN: Auto_ISBN,
        Auto_Whether: Auto_Whether,
        Auto_Name1: Auto_Name1,
        Auto_Desc10: Auto_Desc10,
        Auto_Link11: Auto_Link11,
      };
      sessionStorage.setItem("cat6", JSON.stringify(obj));
      alert("Successfully added cat6");
      getautocriteria3();
      setedit6(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat7 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc11;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add7) {
        if (
          !Auto_Consult ||
          !Auto_Project1 ||
          !Auto_Agency1 ||
          !Auto_Revenue ||
          !Auto_Trainee ||
          !Auto_Teacher1 ||
          !Auto_Facility1 ||
          !Auto_Agen1 ||
          !Auto_Consult2 ||
          !Auto_Total1 ||
          !Auto_Desc11 ||
          !Auto_Link12
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd7(false);
        }
      }

      console.log("ttt,", Auto_Link12);
      let obj = {
        Auto_criteria: "3.5.1 & 3.5.2",
        Auto_Consult: Auto_Consult,
        Auto_Project1: Auto_Project1,
        Auto_Agency1: Auto_Agency1,
        Auto_Revenue: Auto_Revenue,
        Auto_Trainee: Auto_Trainee,
        Auto_Teacher1: Auto_Teacher1,
        Auto_Facility1: Auto_Facility1,
        Auto_Agen1: Auto_Agen1,
        Auto_Consult2: Auto_Consult2,
        Auto_Total1: Auto_Total1,
        Auto_Desc11: Auto_Desc11,
        Auto_Link12: Auto_Link12,
      };
      sessionStorage.setItem("cat7", JSON.stringify(obj));
      alert("Successfully added cat7");
      getautocriteria3();
      setedit7(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat8 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc12;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add8) {
        if (
          !Auto_Name2 ||
          !Auto_Name3 ||
          !Auto_Name4 ||
          !Auto_Year4 ||
          !Auto_Desc12 ||
          !Auto_Link13
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd8(false);
        }
      }

      console.log("uuu,", Auto_Link13);
      let obj = {
        Auto_criteria: "3.6.2",
        Auto_Name2: Auto_Name2,
        Auto_Name3: Auto_Name3,
        Auto_Name4: Auto_Name4,
        Auto_Year4: Auto_Year4,
        Auto_Desc12: Auto_Desc12,
        Auto_Link13: Auto_Link13,
      };
      sessionStorage.setItem("cat8", JSON.stringify(obj));
      alert("Successfully added cat8");
      getautocriteria3();
      setedit8(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat9 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc13;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add9) {
        if (
          !Auto_Name5 ||
          !Auto_org ||
          !Auto_Scheme ||
          !Auto_Year5 ||
          !Auto_Participants1 ||
          !Auto_Desc13 ||
          !Auto_Link14
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd9(false);
        }
      }

      console.log("uuu,", Auto_Link14);
      let obj = {
        Auto_criteria: "3.6.3 & 3.6.4",
        Auto_Name5: Auto_Name5,
        Auto_org: Auto_org,
        Auto_Scheme: Auto_Scheme,
        Auto_Year5: Auto_Year5,
        Auto_Participants1: Auto_Participants1,
        Auto_Desc13: Auto_Desc13,
        Auto_Link14: Auto_Link14,
      };
      sessionStorage.setItem("cat9", JSON.stringify(obj));
      alert("Successfully added cat9");
      getautocriteria3();
      setedit9(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat10 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc14;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add10) {
        if (
          !Auto_SLNO1 ||
          !Auto_Colloborative ||
          !Auto_Agency2 ||
          !Auto_Participants3 ||
          !Auto_Collo ||
          !Auto_Durat ||
          !Auto_Activity ||
          !Auto_Desc14 ||
          !Auto_Link15
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd10(false);
        }
      }

      console.log("vvv,", Auto_Link15);
      let obj = {
        Auto_criteria: "3.7.1",
        Auto_SLNO1: Auto_SLNO1,
        Auto_Colloborative: Auto_Colloborative,
        Auto_Agency2: Auto_Agency2,
        Auto_Participants3: Auto_Participants3,
        Auto_Collo: Auto_Collo,
        Auto_Durat: Auto_Durat,
        Auto_Activity: Auto_Activity,
        Auto_Desc14: Auto_Desc14,
        Auto_Link15: Auto_Link15,
      };
      sessionStorage.setItem("cat10", JSON.stringify(obj));
      alert("Successfully added cat10");
      getautocriteria3();
      setedit10(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cat11 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc15;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add11) {
        if (
          !Auto_Organ ||
          !Auto_IIC ||
          !Auto_Signing ||
          !Auto_Durat1 ||
          !Auto_List ||
          !Auto_Desc15 ||
          !Auto_Link16
        ) {
          return alert("Please fill all the details");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd11(false);
        }
      }

      console.log("www,", Auto_Link16);
      let obj = {
        Auto_criteria: "3.7.2",
        Auto_Organ: Auto_Organ,
        Auto_IIC: Auto_IIC,
        Auto_Signing: Auto_Signing,
        Auto_Durat1: Auto_Durat1,
        Auto_List: Auto_List,
        Auto_Desc15: Auto_Desc15,
        Auto_Link16: Auto_Link16,
      };
      sessionStorage.setItem("cat11", JSON.stringify(obj));
      alert("Successfully added cat11");
      getautocriteria3();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            Autonomous_Criteria-03
          </span>
        </div>

        <div className="container">
          <div
            className="row-fluid border bs"
            style={{
              padding: "30px",
            }}
          >
            <div class=" col-md-6">
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Auto_Year ? (
                <p>{obj1?.Auto_Year}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setAuto_Year(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.1.2)</h5>

            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Name of the teacher provided with seed money
                </label>
                {obj1?.Auto_Seed ? (
                  <p>{obj1?.Auto_Seed}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Seed(e.target.value)}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  The amount of seed money (INR in Lakhs)
                </label>
                {obj1?.Auto_SeedMoney ? (
                  <p>{obj1?.Auto_SeedMoney}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_SeedMoney(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of receiving
                </label>
                {obj1?.Auto_Receiving ? (
                  <p>{obj1?.Auto_Receiving}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => setAuto_Receiving(e.target.value)}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.Auto_Desc5 ? (
                  <p>{obj1?.Auto_Desc5}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    // cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc5(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Upload the relevant document
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="Year"
                  id="upload1"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link5(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success  mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria-[3.1.2]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Name of the teacher provided with seed money
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Seed}
                          onChange={(e) => setAuto_Seed(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Seed}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      The amount of seed money (INR in Lakhs)
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_SeedMoney}
                          onChange={(e) => setAuto_SeedMoney(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_SeedMoney}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of receiving
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Receiving}
                          onChange={(e) => setAuto_Receiving(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Receiving}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc5}
                          onChange={(e) => setAuto_Desc5(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc5}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit1 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="Year"
                          id="upload1"
                          accept="image/*"
                          onChange={(e) => setAuto_Link5(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link5?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit1(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat1();
                    handleClose();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.1.3)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the teacher awarded national/ international
                  fellowship/financial support
                </label>
                {obj2?.Auto_FellowShip ? (
                  <p>{obj2?.Auto_FellowShip}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_FellowShip(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the award/fellowship
                </label>
                {obj2?.Auto_AFellowship ? (
                  <p>{obj2?.Auto_AFellowship}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_AFellowship(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year of Award
                </label>
                {obj2?.Auto_Award ? (
                  <p>{obj2?.Auto_Award}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Award(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Awarding Agency
                </label>
                {obj2?.Auto_Agency ? (
                  <p>{obj2?.Auto_Agency}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Agency(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj2?.Auto_Desc6 ? (
                <p>{obj2?.Auto_Desc6}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc6(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload2">
                {" "}
                Upload the relevant document
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="upload2"
                id="upload2"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link6(e.target.files[0]);
                }}
              />
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add2 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat2();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow1}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria03_[3.1.3]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher awarded national/ international
                      fellowship/financial support
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_FellowShip}
                          onChange={(e) => {
                            setAuto_FellowShip(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_FellowShip}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the award/fellowship
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_AFellowship}
                          onChange={(e) => {
                            setAuto_AFellowship(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_AFellowship}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Year of Award
                    </label>
                    <br />
                    {edit2 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Award}
                          onChange={(e) => setAuto_Award(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Award}</>
                    )}
                  </div>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Awarding Agency
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Agency}
                        onChange={(e) => setAuto_Agency(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Agency}</>
                  )}
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Description(min 500 words)
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        placeholder={Auto_Desc6}
                        onChange={(e) => setAuto_Desc6(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Auto_Desc6}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Upload the relevant document
                  </label>
                  <br />
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="name"
                        onChange={(e) => setAuto_Link6(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Auto_Link6?.name}</>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit2(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat2();
                    handleClose1();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.2.1 & 3.2.2 & 3.2.4)</h5>
            <h6 className="text-center text-decoration-underline">
              Average enrolement percentage(Average of last five years)
            </h6>
            <div className="row">
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Sl.no.
                </label>

                {obj3?.Auto_SLNO ? (
                  <p>{obj3?.Auto_SLNO}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_SLNO(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-8 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Principal Investigator/ Co Investigator (if
                  applicable)
                </label>
                {obj3?.Auto_Invest ? (
                  <p>{obj3?.Auto_Invest}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Invest(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Funding agency
                </label>
                {obj3?.Auto_FAgency ? (
                  <p>{obj3?.Auto_FAgency}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_FAgency(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1">
                  {" "}
                  Type (Government/Non-Government)
                </label>
                {obj3?.Auto_Type ? (
                  <p>{obj3?.Auto_Type}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Type(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-8 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Department of Principal Investigator/ Co Investigator
                </label>
                {obj3?.Auto_Dep ? (
                  <p>{obj3?.Auto_Dep}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Dep(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of Award
                </label>
                {obj3?.Auto_Award1 ? (
                  <p>{obj3?.Auto_Award1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Award1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Funds provided (INR in lakhs)
                </label>
                {obj3?.Auto_Funds ? (
                  <p>{obj3?.Auto_Funds}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Funds(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Duration of the project
                </label>
                {obj3?.Auto_Project ? (
                  <p>{obj3?.Auto_Project}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Project(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj3?.Auto_Desc7 ? (
                  <p>{obj3?.Auto_Desc7}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc7(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Upload the relevant document
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload3"
                  id="upload3"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link7(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add3 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat3();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow2}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[3.2.1 & 3.2.2 & 3.2.4]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Sl.no.
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_SLNO}
                          onChange={(e) => {
                            setAuto_SLNO(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_SLNO}</>
                    )}
                  </div>
                  <div className="col-md-8 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Principal Investigator/ Co Investigator (if
                      applicable)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Invest}
                          onChange={(e) => {
                            setAuto_Invest(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Invest}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Funding agency
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_FAgency}
                          onChange={(e) => {
                            setAuto_FAgency(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_FAgency}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Type (Government/Non-Government)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Type}
                          onChange={(e) => {
                            setAuto_Type(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Type}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Department of Principal Investigator/ Co Investigator
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Dep}
                          onChange={(e) => {
                            setAuto_Dep(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Dep}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of Award
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Award1}
                          onChange={(e) => {
                            setAuto_Award1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Award1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Funds provided (INR in lakhs)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Funds}
                          onChange={(e) => {
                            setAuto_Funds(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Funds}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Duration of the project
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Project}
                          onChange={(e) => {
                            setAuto_Project(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Project}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc7}
                          onChange={(e) => setAuto_Desc7(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc7}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit3 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload3"
                          id="upload3 "
                          onChange={(e) => setAuto_Link7(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link7?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit3(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat3();
                    handleClose2();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.3.2)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the workshop/ seminar
                </label>
                {obj4?.Auto_Seminar ? (
                  <p>{obj4?.Auto_Seminar}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Seminar(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of the Participants
                </label>
                {obj4?.Auto_Parti ? (
                  <p>{obj4?.Auto_Parti}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Parti(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Date (From)
                </label>
                {obj4?.Auto_DFrom ? (
                  <p>{obj4?.Auto_DFrom}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_DFrom(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Date (To)
                </label>
                {obj4?.Auto_DTo ? (
                  <p>{obj4?.Auto_DTo}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="Year"
                    id="name"
                    onChange={(e) => {
                      setAuto_DTo(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="name">
                {" "}
                Description (min 500 words)
              </label>
              {obj4?.Auto_Desc8 ? (
                <p>{obj4?.Auto_Desc8}</p>
              ) : (
                <textarea
                  className="vi_0"
                  id="name"
                  cols={57}
                  rows={3}
                  onChange={(e) => {
                    setAuto_Desc8(e.target.value);
                  }}
                ></textarea>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload4">
                {" "}
                Upload the relevant document
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload4"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link8(e.target.files[0]);
                }}
              />
            </div>

            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add4 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat4();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow3}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[3.3.2]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the workshop/ seminar
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Seminar}
                          onChange={(e) => {
                            setAuto_Seminar(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Seminar}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Number of the Participants
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Parti}
                          onChange={(e) => {
                            setAuto_Parti(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Parti}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date (From)
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_DFrom}
                          onChange={(e) => {
                            setAuto_DFrom(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_DFrom}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Date (To)
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_DTo}
                          onChange={(e) => {
                            setAuto_DTo(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_DTo}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <textarea
                          rows={3}
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc8}
                          onChange={(e) => setAuto_Desc8(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc8}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload4">
                      {" "}
                      Upload the relevant document
                    </label>
                    <br />
                    {edit4 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="email"
                          id="upload4"
                          onChange={(e) => setAuto_Link8(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link8.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit4(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat4();
                    handleClose3();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.4.3)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Title of paper
                </label>

                {obj5?.Auto_Paper ? (
                  <p>{obj5?.Auto_Paper}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Paper(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the author/s
                </label>
                {obj5?.Auto_Author ? (
                  <p>{obj5?.Auto_Author}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Author(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Department of the teacher
                </label>
                {obj5?.Auto_Dept ? (
                  <p>{obj5?.Auto_Dept}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Dept(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Name of journal</label>
                {obj5?.Auto_Journal ? (
                  <p>{obj5?.Auto_Journal}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Journal(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of publication
                </label>
                {obj5?.Auto_Pub ? (
                  <p>{obj5?.Auto_Pub}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Pub(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  ISSN number
                </label>
                {obj5?.Auto_ISSN ? (
                  <p>{obj5?.Auto_ISSN}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_ISSN(e.target.value);
                    }}
                  />
                )}
              </div>
              <h6 className="text-center text-decoration-underline">
                Link to the recognition in UGC enlistment of the Journal{" "}
              </h6>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Is it listed in UGC Care list/Scopus/Web of Science/other,
                  mention
                </label>
                {obj5?.Auto_ISIT ? (
                  <p>{obj5?.Auto_ISIT}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_ISIT(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description min 500 words
                </label>
                {obj5?.Auto_Desc9 ? (
                  <p>{obj5?.Auto_Desc9}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc9(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload5">
                  {" "}
                  Link to website of the Journal
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload5"
                  id="upload5"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link9(e.target.files[0]);
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload5">
                  {" "}
                  Link to article/paper/abstract of the article
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload5"
                  id="upload5"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link10(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add5 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat5();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow4}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show4} onHide={handleClose4}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[3.4.3]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of paper
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Paper}
                          onChange={(e) => {
                            setAuto_Paper(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Paper}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the author/s
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Author}
                          onChange={(e) => {
                            setAuto_Author(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Author}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Department of the teacher
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Dept}
                          onChange={(e) => {
                            setAuto_Dept(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Dept}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of journal
                    </label>{" "}
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Journal}
                          onChange={(e) => {
                            setAuto_Journal(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Journal}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of publication
                    </label>{" "}
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Pub}
                          onChange={(e) => {
                            setAuto_Pub(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Pub}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ISSN number
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_ISSN}
                          onChange={(e) => {
                            setAuto_ISSN(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_ISSN}</>
                    )}
                  </div>
                  <h6 className="text-center text-decoration-underline">
                    Link to the recognition in UGC enlistment of the Journal{" "}
                  </h6>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Is it listed in UGC Care list/Scopus/Web of Science/other,
                      mention
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_ISIT}
                          onChange={(e) => {
                            setAuto_ISIT(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_ISIT}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc9}
                          onChange={(e) => setAuto_Desc9(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc9}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Link to website of the Journal
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload5"
                          id="upload5 "
                          onChange={(e) => setAuto_Link9(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link9?.name}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Link to article/paper/abstract of the article
                    </label>
                    <br />
                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload6"
                          id="upload6 "
                          onChange={(e) => setAuto_Link10(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link10?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit5(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat5();
                    handleClose4();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.4.4)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Sl. No.
                </label>

                {obj6?.Auto_SLno ? (
                  <p>{obj6?.Auto_SLno}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_SLno(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the teacher
                </label>
                {obj6?.Auto_Name ? (
                  <p>{obj6?.Auto_Name}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Name(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Title of the book/chapters published
                </label>
                {obj6?.Auto_Book ? (
                  <p>{obj6?.Auto_Book}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Book(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Title of the paper</label>
                {obj6?.Auto_Paper1 ? (
                  <p>{obj6?.Auto_Paper1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Paper1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Title of the proceedings of the conference
                </label>
                {obj6?.Auto_Title4 ? (
                  <p>{obj6?.Auto_Title4}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Title4(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of publication
                </label>
                {obj6?.Auto_Year2 ? (
                  <p>{obj6?.Auto_Year2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Year2(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  ISBN/ISSN number of the proceeding
                </label>
                {obj6?.Auto_ISBN ? (
                  <p>{obj6?.Auto_ISBN}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_ISBN(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the publisher
                </label>
                {obj6?.Auto_Name1 ? (
                  <p>{obj6?.Auto_Name1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Name1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Whether at the time of publication Affiliating Institution Was
                  same
                </label>
                {obj6?.Auto_Whether ? (
                  <p>{obj6?.Auto_Whether}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "100%" }}
                    onChange={(e) => setAuto_Whether(e.target.value)}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description min 500 words
                </label>
                {obj6?.Auto_Desc10 ? (
                  <p>{obj6?.Auto_Desc10}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc10(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload7">
                  {" "}
                  Link to website of the Journal
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload7"
                  id="upload7"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link11(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add6 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat6();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow5}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show5} onHide={handleClose5}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[3.4.4]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Sl. No.
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_SLno}
                          onChange={(e) => {
                            setAuto_SLno(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_SLno}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Name}
                          onChange={(e) => {
                            setAuto_Name(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Name}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the book/chapters published
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Book}
                          onChange={(e) => {
                            setAuto_Book(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Book}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the paper
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Paper1}
                          onChange={(e) => {
                            setAuto_Paper1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Paper1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the proceedings of the conference
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Title4}
                          onChange={(e) => {
                            setAuto_Title4(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Title4}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of publication
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Year2}
                          onChange={(e) => {
                            setAuto_Year2(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Year2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      ISBN/ISSN number of the proceeding
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_ISBN}
                          onChange={(e) => {
                            setAuto_ISBN(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_ISBN}</>
                    )}
                  </div>

                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the publisher
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Name1}
                          onChange={(e) => {
                            setAuto_Name1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Name1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Whether at the time of publication Affiliating Institution
                      Was same
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          style={{ width: "100%" }}
                          onChange={(e) => setAuto_Whether(e.target.value)}
                        >
                          <option selected>--Select--</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </>
                    ) : (
                      <>{Auto_Whether}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc10}
                          onChange={(e) => setAuto_Desc10(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc10}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Link to website of the Journal
                    </label>
                    <br />
                    {edit6 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload7"
                          id="upload7 "
                          onChange={(e) => setAuto_Link11(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link11?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit6(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat6();
                    handleClose5();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.5.1 & 3.5.2)</h5>
            <div className="row">
              <h6 className="text-center text-decoration-underline">
                Total amount generated from consultancy and corporate training
                year wise{" "}
              </h6>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the teacher-consultants
                </label>

                {obj7?.Auto_Consult ? (
                  <p>{obj7?.Auto_Consult}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Consult(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of consultancy project/corporate training program
                </label>
                {obj7?.Auto_Project1 ? (
                  <p>{obj7?.Auto_Project1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Project1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Consulting/Sponsoring agency with contact details
                </label>
                {obj7?.Auto_Agency1 ? (
                  <p>{obj7?.Auto_Agency1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Agency1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1">
                  {" "}
                  Revenue generated (INR in Lakhs)
                </label>
                {obj7?.Auto_Revenue ? (
                  <p>{obj7?.Auto_Revenue}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Revenue(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Number of trainees
                </label>
                {obj7?.Auto_Trainee ? (
                  <p>{obj7?.Auto_Trainee}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Trainee(e.target.value);
                    }}
                  />
                )}
              </div>
              <h6 className="text-center text-decoration-underline">
                Total amount spent on developing facilities, training teachers
                and staff for undertaking consultancy during the last five years{" "}
              </h6>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Names of the teachers/staff
                </label>

                {obj7?.Auto_Teacher1 ? (
                  <p>{obj7?.Auto_Teacher1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Teacher1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the facilities developed and department
                </label>
                {obj7?.Auto_Facility1 ? (
                  <p>{obj7?.Auto_Facility1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Facility1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Agency seeking training with contact details
                </label>
                {obj7?.Auto_Agen1 ? (
                  <p>{obj7?.Auto_Agen1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Agen1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Name of consultancy</label>
                {obj7?.Auto_Consult2 ? (
                  <p>{obj7?.Auto_Consult2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Consult2(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Total amount spent (INR in Lakhs)
                </label>
                {obj7?.Auto_Total1 ? (
                  <p>{obj7?.Auto_Total1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Total1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description min 500 words
                </label>
                {obj7?.Auto_Desc11 ? (
                  <p>{obj7?.Auto_Desc11}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc11(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload8">
                  {" "}
                  Link to website of the Journal
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload8"
                  id="upload8"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link12(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add7 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat7();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow6}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show6} onHide={handleClose6}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[3.5.1 & 3.5.2]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <h6 className="text-center text-decoration-underline">
                    Total amount generated from consultancy and corporate
                    training year wise{" "}
                  </h6>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the teacher-consultants
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Consult}
                          onChange={(e) => {
                            setAuto_Consult(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Consult}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of consultancy project/corporate training program
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Project1}
                          onChange={(e) => {
                            setAuto_Project1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Project1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Consulting/Sponsoring agency with contact details
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Agency1}
                          onChange={(e) => {
                            setAuto_Agency1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Agency1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Revenue generated (INR in Lakhs)
                    </label>{" "}
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Revenue}
                          onChange={(e) => {
                            setAuto_Revenue(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Revenue}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of trainees
                    </label>{" "}
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Trainee}
                          onChange={(e) => {
                            setAuto_Trainee(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Trainee}</>
                    )}
                  </div>
                  <h6 className="text-center text-decoration-underline">
                    Total amount spent on developing facilities, training
                    teachers and staff for undertaking consultancy during the
                    last five years{" "}
                  </h6>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Names of the teachers/staff
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Teacher1}
                          onChange={(e) => {
                            setAuto_Teacher1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Teacher1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the facilities developed and department
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Facility1}
                          onChange={(e) => {
                            setAuto_Facility1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Facility1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Agency seeking training with contact details
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Agen1}
                          onChange={(e) => {
                            setAuto_Agen1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Agen1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of consultancy
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Consult2}
                          onChange={(e) => {
                            setAuto_Consult2(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Consult2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Total amount spent (INR in Lakhs)
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Total1}
                          onChange={(e) => {
                            setAuto_Total1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Total1}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc11}
                          onChange={(e) => setAuto_Desc11(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc11}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Link to website of the Journal
                    </label>
                    <br />
                    {edit7 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload8"
                          id="upload8"
                          onChange={(e) => setAuto_Link12(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link12?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit7(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat7();
                    handleClose6();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.6.2)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the activity
                </label>

                {obj8?.Auto_Name2 ? (
                  <p>{obj8?.Auto_Name2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Name2(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Award/ recognition
                </label>
                {obj8?.Auto_Name3 ? (
                  <p>{obj8?.Auto_Name3}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Name3(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-8 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the Awarding government/ government recognised bodies
                </label>
                {obj8?.Auto_Name4 ? (
                  <p>{obj8?.Auto_Name4}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Name4(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label className="content1"> Year of award</label>
                {obj8?.Auto_Year4 ? (
                  <p>{obj8?.Auto_Year4}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Year4(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description min 500 words
                </label>
                {obj8?.Auto_Desc12 ? (
                  <p>{obj8?.Auto_Desc12}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc12(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload9">
                  {" "}
                  Link to website of the Journal
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload9"
                  id="upload9"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link13(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add8 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat8();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow7}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show7} onHide={handleClose7}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[3.6.2]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the activity
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Name2}
                          onChange={(e) => {
                            setAuto_Name2(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Name2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Award/ recognition
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Name3}
                          onChange={(e) => {
                            setAuto_Name3(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Name3}</>
                    )}
                  </div>
                  <div className="col-md-8 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the Awarding government/ government recognised
                      bodies
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Name4}
                          onChange={(e) => {
                            setAuto_Name4(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Name4}</>
                    )}
                  </div>
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of award
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Year4}
                          onChange={(e) => {
                            setAuto_Year4(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Year4}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc12}
                          onChange={(e) => setAuto_Desc12(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc12}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Link to website of the Journal
                    </label>
                    <br />
                    {edit8 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload9"
                          id="upload9"
                          onChange={(e) => setAuto_Link13(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link13?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit8(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat8();
                    handleClose7();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.6.3 & 3.6.4)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the activity
                </label>

                {obj9?.Auto_Name5 ? (
                  <p>{obj9?.Auto_Name5}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Name5(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Organising unit/ agency/ collaborating agency
                </label>
                {obj9?.Auto_org ? (
                  <p>{obj9?.Auto_org}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_org(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the scheme
                </label>
                {obj9?.Auto_Scheme ? (
                  <p>{obj9?.Auto_Scheme}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Scheme(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Year of the activity</label>
                {obj9?.Auto_Year5 ? (
                  <p>{obj9?.Auto_Year5}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Year5(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1">
                  {" "}
                  Number of students participated in such activities
                </label>
                {obj9?.Auto_Participants1 ? (
                  <p>{obj9?.Auto_Participants1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Participants1(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description min 500 words
                </label>
                {obj9?.Auto_Desc13 ? (
                  <p>{obj9?.Auto_Desc13}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc13(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload10">
                  {" "}
                  Link to website of the Journal
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload10"
                  id="upload10"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link14(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add9 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat9();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow8}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show8} onHide={handleClose8}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[3.6.3 & 3.6.4]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the activity
                    </label>
                    <br />
                    {edit9 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Name5}
                          onChange={(e) => {
                            setAuto_Name5(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Name5}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Organising unit/ agency/ collaborating agency
                    </label>
                    <br />
                    {edit9 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_org}
                          onChange={(e) => {
                            setAuto_org(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_org}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the scheme
                    </label>
                    <br />
                    {edit9 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Scheme}
                          onChange={(e) => {
                            setAuto_Scheme(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Scheme}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of the activity
                    </label>
                    <br />
                    {edit9 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Year5}
                          onChange={(e) => {
                            setAuto_Year5(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Year5}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Number of students participated in such activities
                    </label>
                    <br />
                    {edit9 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Participants1}
                          onChange={(e) => {
                            setAuto_Participants1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Participants1}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit9 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc13}
                          onChange={(e) => setAuto_Desc13(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc13}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Link to website of the Journal
                    </label>
                    <br />
                    {edit9 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload10"
                          id="upload10"
                          onChange={(e) => setAuto_Link14(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link14?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit9(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat9();
                    handleClose8();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.7.1)</h5>
            <div className="row">
              <div className="col-md-4 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Sl. No.
                </label>

                {obj10?.Auto_SLNO1 ? (
                  <p>{obj10?.Auto_SLNO1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_SLNO1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-8 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the collaborating agency with contact details
                </label>
                {obj10?.Auto_Agency2 ? (
                  <p>{obj10?.Auto_Agency2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Agency2(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Title of the collaborative activity
                </label>
                {obj10?.Auto_Colloborative ? (
                  <p>{obj10?.Auto_Colloborative}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Colloborative(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Name of the participant</label>
                {obj10?.Auto_Participants3 ? (
                  <p>{obj10?.Auto_Participants3}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Participants3(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Year of collaboration</label>
                {obj10?.Auto_Collo ? (
                  <p>{obj10?.Auto_Collo}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Collo(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Duration</label>
                {obj10?.Auto_Durat ? (
                  <p>{obj10?.Auto_Durat}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Durat(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Nature of the activity</label>
                {obj10?.Auto_Activity ? (
                  <p>{obj10?.Auto_Activity}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Activity(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description min 500 words
                </label>
                {obj10?.Auto_Desc14 ? (
                  <p>{obj10?.Auto_Desc14}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc14(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload11">
                  {" "}
                  Link to website of the Journal
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload11"
                  id="upload11"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link15(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add10 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat10();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-warning"
                    onClick={handleShow9}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show9} onHide={handleClose9}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[3.7.1]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Sl. No.
                    </label>
                    <br />
                    {edit10 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_SLNO1}
                          onChange={(e) => {
                            setAuto_SLNO1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_SLNO1}</>
                    )}
                  </div>
                  <div className="col-md-8 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the collaborating agency with contact details
                    </label>
                    <br />
                    {edit10 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Agency2}
                          onChange={(e) => {
                            setAuto_Agency2(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Agency2}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Title of the collaborative activity
                    </label>
                    <br />
                    {edit10 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Colloborative}
                          onChange={(e) => {
                            setAuto_Colloborative(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Colloborative}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the participant
                    </label>
                    <br />
                    {edit10 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Participants3}
                          onChange={(e) => {
                            setAuto_Participants3(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Participants3}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of collaboration
                    </label>
                    <br />
                    {edit10 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Collo}
                          onChange={(e) => {
                            setAuto_Collo(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Collo}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Duration
                    </label>
                    <br />
                    {edit10 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Durat}
                          onChange={(e) => {
                            setAuto_Durat(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Durat}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Nature of the activity
                    </label>
                    <br />
                    {edit10 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Activity}
                          onChange={(e) => {
                            setAuto_Activity(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Activity}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit10 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc14}
                          onChange={(e) => setAuto_Desc14(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc14}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Link to website of the Journal
                    </label>
                    <br />
                    {edit10 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload11"
                          id="upload11"
                          onChange={(e) => setAuto_Link15(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link15?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit10(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat10();
                    handleClose9();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_3 (3.7.2)</h5>
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Organisation with which MoU is signed
                </label>

                {obj11?.Auto_Organ ? (
                  <p>{obj11?.Auto_Organ}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Organ(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Name of the institution/ industry/ corporate house
                </label>
                {obj11?.Auto_IIC ? (
                  <p>{obj11?.Auto_IIC}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_IIC(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Year of signing MoU
                </label>
                {obj11?.Auto_Signing ? (
                  <p>{obj11?.Auto_Signing}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Signing(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1"> Duration</label>
                {obj11?.Auto_Durat1 ? (
                  <p>{obj11?.Auto_Durat1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_Durat1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label className="content1">
                  {" "}
                  List the actual activities under each MOU year-wise
                </label>
                {obj11?.Auto_List ? (
                  <p>{obj11?.Auto_List}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="year"
                    id="name"
                    onChange={(e) => {
                      setAuto_List(e.target.value);
                    }}
                  />
                )}
              </div>

              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="name">
                  {" "}
                  Description min 500 words
                </label>
                {obj11?.Auto_Desc15 ? (
                  <p>{obj11?.Auto_Desc15}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    id="name"
                    cols={57}
                    rows={3}
                    onChange={(e) => {
                      setAuto_Desc15(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload12">
                  {" "}
                  Link to website of the Journal
                </label>
                <br />

                <input
                  className="vi_0"
                  type="file"
                  name="upload12"
                  id="upload12"
                  accept="image/*"
                  onChange={(e) => {
                    setAuto_Link16(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="d-flex " style={{ float: "right", gap: "10px" }}>
              {add11 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat11();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow10}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}
            </div>
            <Modal size="lg" show={show10} onHide={handleClose10}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Criteria_[3.7.2]</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Organisation with which MoU is signed
                    </label>
                    <br />
                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="year"
                          id="name"
                          placeholder={Auto_Organ}
                          onChange={(e) => {
                            setAuto_Organ(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Organ}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Name of the institution/ industry/ corporate house
                    </label>
                    <br />
                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_IIC}
                          onChange={(e) => {
                            setAuto_IIC(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_IIC}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Year of signing MoU
                    </label>
                    <br />
                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Signing}
                          onChange={(e) => {
                            setAuto_Signing(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Signing}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Duration
                    </label>
                    <br />
                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_Durat1}
                          onChange={(e) => {
                            setAuto_Durat1(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_Durat1}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      List the actual activities under each MOU year-wise
                    </label>
                    <br />
                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="fname"
                          id="name"
                          placeholder={Auto_List}
                          onChange={(e) => {
                            setAuto_List(e.target.value);
                          }}
                        />
                      </>
                    ) : (
                      <>{Auto_List}</>
                    )}
                  </div>

                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Description(min 500 words)
                    </label>
                    <br />
                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="text"
                          name="email"
                          id="name"
                          placeholder={Auto_Desc15}
                          onChange={(e) => setAuto_Desc15(e.target.value)}
                        />
                      </>
                    ) : (
                      <>{Auto_Desc15}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Link to website of the Journal
                    </label>
                    <br />
                    {edit11 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload12"
                          id="upload12"
                          onChange={(e) => setAuto_Link16(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Auto_Link16?.name}</>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => setedit11(true)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-success"
                  style={{ color: "white" }}
                  onClick={() => {
                    cat11();
                    handleClose10();
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <br />
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => autocriteria3()}
            style={{ color: "white", marginTop: "15px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Auto_Criteria003;
