import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";

import { BiEdit } from "react-icons/bi";

function AddSlider() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
      <div className="conrainer">
        <div className="row">
          <div className="header-01">
            <h2
              style={{
                textAlign: "center",
                fontFamily: "fangsong",
                fontWeight: "600",
              }}
            >
              Slider
            </h2>
          </div>
          <div className="header-02">
            <Button onClick={handleShow}>Add</Button>
          </div>
        </div>
        <br />
        <div className="row">
          <table>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#161632",
                  lineHeight: "41px",
                }}
              >
                <th>Sl.No</th>
                <th>Image</th>
                <th>Text1</th>
                <th>Text2</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: "center", border: "1px solid #161632" }}>
                <td style={{ border: "1px solid #161632" }}>1</td>
                <td style={{ border: "1px solid #161632" }}>
                  <img src="" alt="image here" />
                </td>
                <td style={{ border: "1px solid #161632" }}>Lorem</td>
                <td style={{ border: "1px solid #161632" }}>lorem 2</td>

                <td style={{ border: "1px solid #161632" }}>
                  <div className="d-flex align-items-center justify-content-evenly">
                    <div className="">
                      <span>
                        <BiEdit onClick={handleShow2} />
                      </span>
                    </div>
                    <div className="">
                      <span onClick={handleShow1} style={{ cursor: "pointer" }}>
                        <AiFillDelete />
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Slider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>
                Upload Image <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Text1 <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Text2 <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary">Submit</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Slider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-1">
              <label>
                Upload Image <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="file"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Text1 <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                />
              </div>
            </div>

            <div className="mb-1">
              <label>
                Text2 <span className="font-css top">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  id="file-input"
                  name="ImageStyle"
                  className="vi_0"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary">Update</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#b00b39" }}>Warning..!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span style={{ fontSize: "20px", fontWeight: "700" }}>
              Are you sure..!,{" "}
              <span style={{ color: "#b00b39" }}>
                you want to delete this item....!
              </span>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <span className="header-03">
              <Button>Delete</Button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AddSlider;
