import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Space } from "antd";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

function UniversityCri1() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  let collegeDetails = JSON.parse(sessionStorage.getItem("collegeDetails"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rating, setrating] = useState();

  const ratingChanged = (newRating) => {
    console.log("aaa", newRating);
    setrating(newRating);
  };
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // show description model
  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  const [View, setView] = useState({});
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const uniCriteria01Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUnicriteria01details"
      );
      if (res.status === 200) {
        setAllDetails(res.data.Unicriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.Unicriteria01);
    }
  };

  const [selectedYear, setselectedYear] = useState("");
  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
        setselectedYear(
          res.data.allYears?.sort((a, b) => {
            return Number(b?.from) - Number(a?.from);
          })[0]?.from +
            "-" +
            res.data.allYears?.sort((a, b) => {
              return Number(b?.from) - Number(a?.from);
            })[0]?.to
        );
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };
  const AddRating = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/addRatinguni1`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Uni_Year: selectedYear,
          rating: rating,
          clgid: collegeDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          uniCriteria01Details();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      uniCriteria01Details();
      getAllYears();
    }
  }, []);
  console.log("fjfjfjfjf", AllDetails);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center mt-4">
            <div className="col-lg-6 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-01
              </span>
            </div>
            <div className="col-lg-6 text-end">
              <div className="row justify-content-end align-items-center">
                {/* <p className="mt-4 col-lg-6">Current Rating: </p> */}
                <div className=" col-lg-6 text-center">
                  {AllDetails?.filter((item1) => {
                    if (
                      params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.Uni_Year
                    ) {
                      return true;
                    }
                    return false;
                  })?.map((item) => {
                    return (
                      <div className=" mb-2">
                        Current Rating :
                        <span className="m-1">{item?.rating}</span>
                        <span className="m-1">
                          <BsStarFill style={{ color: "#FFD700" }} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ad-b  row align-items-center m-0 mt-4">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            {/* <div className="col-lg-2 text-end">
              <Button
                type="Submit"
                className="btn btn-warning"
                style={{ color: "white" }}
                onClick={() => {
                  // setValue(item);
                  handleShow();
                }}
              >
                Add Rating
              </Button>
            </div> */}
          </div>
        </div>
        <div className="row m-0 mt-4">
          <div className=" col-lg-3 do-sear  d-flex align-items-center  m-0 ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              {allYears
                ?.sort((a, b) => {
                  return Number(b?.from) - Number(a?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/unicriteria01">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  onClick={() => navigate(`/adminclgunicri1112/${params?.id}`)}
                >
                  <Button className="criteriabtn">1.1.2 & 1.2.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-2"
                  onClick={() => navigate(`/adminclgunicri1113/${params?.id}`)}
                >
                  <Button className="criteriabtn">1.1.3 & 1.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri1132/${params?.id}`)}
                >
                  <Button className="criteriabtn">1.3.2 & 1.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri1134/${params?.id}`)}
                >
                  <Button className="criteriabtn">1.3.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgunicri1141/${params?.id}`)}
                >
                  <Button className="criteriabtn">1.4.1 & 1.4.2</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Id</th>
                  <th>Year</th>

                  {/* 1.1.2 & 1.2.2 */}
                  <th>Criteria</th>
                  <th>Programme Code</th>
                  <th>Programme name</th>
                  <th>Year of Introduction</th>
                  <th>
                    Status of implementation of CBCS / Elective Course System
                    (ECS)
                  </th>
                  <th>
                    Year of implemetation of CBCS / Elective Course System (ECS)
                  </th>
                  <th>Year of revision</th>
                  <th>
                    If revision has been carried out in the syllabus during last
                    5 years, Percentage of content added or replaced
                  </th>
                  <th>Document</th>
                  <th>Description</th>

                  {/* 1.1.3 & 1.2.1 */}
                  <th>Criteria</th>
                  <th>Course Code</th>
                  <th>Name of the Course</th>
                  <th>Year of introduction</th>
                  <th>
                    Activities/Content with direct bearing on Employability/
                    Entrepreneurship/ Skill development
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 1.3.2 & 1.3.3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the value added courses offered (with 30 or more
                    contact hours)
                  </th>
                  <th>Course Code (if any)</th>
                  <th>Year of offering</th>
                  <th>No. of times offered during the same year</th>
                  <th>Duration of course</th>
                  <th>Number of students enrolled in the year</th>
                  <th>Number of Students completing the course in the year</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 1.3.4 */}
                  <th>Criteria</th>
                  <th>Program Code</th>
                  <th>Program Name</th>
                  <th>Name of the students</th>
                  <th>Description</th>
                  <th>Document</th>

                  {/* 1.4.1 & 1.4.2 */}
                  <th>Criteria</th>
                  <th>Select Semester</th>
                  <th>
                    Feedback processes of the institution may be classified as
                    follows:
                    <Table responsive bordered striped color="#393186">
                      <tbody>
                        <tr>
                          <td>
                            A. Feedback collected, analysed and action taken and
                            feedback available on website
                          </td>
                          <td>
                            B. Feedback collected, analysed and action has been
                            taken
                          </td>
                          <td>C. Feedback collected and analysed</td>
                          <td>D. Feedback collected</td>
                          <td>E. Feedback not collected</td>
                        </tr>
                      </tbody>
                    </Table>
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Uni_Year &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria1_112_122?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_ProCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_ProName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YoI?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_StCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_StEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YICBCS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YIEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YrCBCS?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YrEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_IfCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_IfEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_Desc1?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_CourCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_CourName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_CourYOfIntro?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_CourActivities?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_ValAddCour?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_CourseCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_YOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_NoOfTimes?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_CourseDuration?.toString()?.includes(
                        SearchItem?.toString()
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_NoOfStudents?.toString()?.includes(
                        SearchItem?.toString()
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_CourseCompletion?.toString()?.includes(
                        SearchItem?.toString()
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_PPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_NoofStu?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_Desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_141_142?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_141_142?.Uni_Sem?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_141_142?.Uni_Feedback?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_141_142?.Uni_Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}.</td>
                      <td>{item?._id}</td>
                      <td>{item?.Uni_Year}</td>

                      <td>{item?.Uni_Criteria1_112_122?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_ProCode}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_ProName}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_YoI}</td>
                      <td>
                        <Table bordered responsive striped>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_StCBCS}</td>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_StEcs}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table bordered responsive striped>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_YICBCS}</td>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_YIEcs}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table bordered responsive striped>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_YrCBCS}</td>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_YrEcs}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table bordered responsive striped>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_IfCBCS}</td>
                              <td>{item?.Uni_Criteria1_112_122?.Uni_IfEcs}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        <Table bordered responsive striped>
                          <thead>
                            <th>CBCS</th>
                            <th>ECS</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow1();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                              <td>
                                <AiOutlineEye
                                  onClick={() => {
                                    setView(item);
                                    handleShow2();
                                  }}
                                  fontSize={20}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td>
                        {item?.Uni_Criteria1_112_122?.Uni_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>{item?.Uni_Criteria01_113_121?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria01_113_121?.Uni_CourCode}</td>
                      <td>{item?.Uni_Criteria01_113_121?.Uni_CourName}</td>
                      <td>{item?.Uni_Criteria01_113_121?.Uni_CourYOfIntro}</td>
                      <td>
                        {item?.Uni_Criteria01_113_121?.Uni_CourActivities}
                      </td>
                      <td>
                        {item?.Uni_Criteria01_113_121?.Uni_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow8();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria01_132_133?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_ValAddCour}</td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_CourseCode}</td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_YOfOffering}</td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_NoOfTimes}</td>
                      <td>
                        {item?.Uni_Criteria01_132_133?.Uni_CourseDuration}
                      </td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_NoOfStudents}</td>
                      <td>
                        {item?.Uni_Criteria01_132_133?.Uni_CourseCompletion}
                      </td>
                      <td>
                        {item?.Uni_Criteria01_132_133?.Uni_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow9();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow4();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 4 */}
                      <td>{item?.Uni_Criteria01_134?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria01_134?.Uni_PPrgmCode}</td>
                      <td>{item?.Uni_Criteria01_134?.Uni_PrgName2}</td>
                      <td>{item?.Uni_Criteria01_134?.Uni_NoofStu}</td>
                      <td>
                        {item?.Uni_Criteria01_134?.Uni_Desc4?.slice(0, 70)}...
                        <Link
                          onClick={() => {
                            handleShow10();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow5();
                          }}
                          fontSize={20}
                        />
                      </td>

                      {/* 5 */}
                      <td>{item?.Uni_Criteria01_141_142?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria01_141_142?.Uni_Sem}</td>
                      <td>{item?.Uni_Criteria01_141_142?.Uni_Feedback}</td>
                      <td>
                        {item?.Uni_Criteria01_141_142?.Uni_Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow11();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <Modal size="sm" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={(e) => AddRating(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* show 1 model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria1_112_122?.Uni_Link1}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 2 model */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria1_112_122?.Uni_Link2}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 3 model */}
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria01_113_121?.Uni_Link3}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 4 model */}
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria01_132_133?.Uni_Link4}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 5 model */}
          <Modal show={show5} onHide={handleClose5}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria01_134?.Uni_Link5}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* show 6 model */}
          <Modal show={show6} onHide={handleClose6}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria01_141_142?.Uni_Link6}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Show description modal */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Uni_Criteria1_112_122?.Uni_Desc1}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show8} onHide={handleClose8}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Uni_Criteria01_113_121?.Uni_Desc2}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose8}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show9} onHide={handleClose9}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Uni_Criteria01_132_133?.Uni_Desc3}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show10} onHide={handleClose10}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria01_134?.Uni_Desc4}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose10}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Show description modal */}
          <Modal show={show11} onHide={handleClose11}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p> {View?.Uni_Criteria01_141_142?.Uni_Desc5}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose11}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UniversityCri1;
