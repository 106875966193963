import React from 'react'

const Newlyreg = () => {
  return (
    <div className='container'>
         {/* <div className="row align-items-center justify-content-start p-3">
        <div className="col-lg-5 d-flex">
        <input className="vi_0" />
<button className="p-2">Search</button>
        </div>
        </div> */}
         <div className="row table-responsive">
         <table class=" table">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Id</th>
      <th scope="col">College Name</th>
      
      <th scope="col">Email</th>
      <th scope="col">Date</th>
      <th scope="col">status</th>
      <th scope="col">Action</th>
    
    </tr>
  </thead>
  <tbody>
            <tr>
              <th scope="row">  <span >1</span>
              </th>
              <td>  <span>453657</span>
              </td>
              <td> <span>   Srivenkateshwara College</span>
           </td>
              <td> <span>srivenkatesh334@gmail.com</span>
              </td>
              <td>   <span>22/06/2023</span>
               </td>
              
              <td >
                <span>Pending</span>
               
                    </td>
            
              <td>
                <span className='btn btn-primary'
               
                >
                    Reject
                </span>
              </td>
            </tr>
          </tbody>
</table>
        </div>
    </div>
  )
}

export default Newlyreg