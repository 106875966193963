import React, { useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import parse from "react-html-parser"
function SSRAddRepoprt() {
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  console.log("pricipalDetails", pricipalDetails);

  const [prefaceFor, setprefaceFor] = useState("");
  const [prefaceWebsiteURI, setprefaceWebsiteURI] = useState("");
  const [prefaceDate, setprefaceDate] = useState("");
  const [dateOFEstOFCollege21, setdateOFEstOFCollege21] = useState("");
  const [underSecUGC21, setunderSecUGC21] = useState("");
  const [DateUGC21, setDateUGC21] = useState("");
  const [docUGC21, setdocUGC21] = useState("");
  const [UGCRecgArr, setUGCRecgArr] = useState([]);
  const [res41, setres41] = useState("");
  const [res43, setres43] = useState("");
  const [res44, setres44] = useState("");
  const [exeSum1Intro, setexeSum1Intro] = useState("");
  const [exeSum1Vision, setexeSum1Vision] = useState("");
  const [exeSum1Mission, setexeSum1Mission] = useState("");
  const [swoc12InstStrength, setswoc12InstStrength] = useState("");
  const [swoc12InstWeakness, setswoc12InstWeakness] = useState("");
  const [swoc12InstOppo, setswoc12InstOppo] = useState("");
  const [swoc12InstChallenge, setswoc12InstChallenge] = useState("");
  const [criSum13CurrAsp, setcriSum13CurrAsp] = useState("");
  const [criSum13TeachLearn, setcriSum13TeachLearn] = useState("");
  const [criSum13Research, setcriSum13Research] = useState("");
  const [criSum13Infrastructure, setcriSum13Infrastructure] = useState("");
  const [criSum13StuSupport, setcriSum13StuSupport] = useState("");
  const [criSum13Gov, setcriSum13Gov] = useState("");
  const [criSum13InstValue, setcriSum13InstValue] = useState("");
  const [profile21Name, setprofile21Name] = useState("");
  const [profile21Address, setprofile21Address] = useState("");
  const [profile21City, setprofile21City] = useState("");
  const [profile21State, setprofile21State] = useState("");
  const [profile21Pin, setprofile21Pin] = useState("");
  const [profile21Website, setprofile21Website] = useState("");
  const [inst21Status, setinst21Status] = useState("");
  const [typeOfInst21Gender, settypeOfInst21Gender] = useState("");
  const [typeOfInst21Shift, settypeOfInst21Shift] = useState("");
  const [regMinInst21, setregMinInst21] = useState("");
  const [collegeAff21State, setcollegeAff21State] = useState("");
  const [collegeAff21UniName, setcollegeAff21UniName] = useState("");
  const [collegeAff21Doc, setcollegeAff21Doc] = useState("");
  const [detailsAutonomy21, setdetailsAutonomy21] = useState("");
  const [recog211, setrecog211] = useState("");
  const [recog212, setrecog212] = useState("");
  const [campType21, setcampType21] = useState("");
  const [campAddress21, setcampAddress21] = useState("");
  const [campLoc21, setcampLoc21] = useState("");
  const [campAreaInAcre21, setcampAreaInAcre21] = useState("");
  const [campBuildUp21, setcampBuildUp21] = useState("");
  const [professorMale1, setprofessorMale1] = useState("");
  const [professorFemale1, setprofessorFemale1] = useState("");
  const [professorOther1, setprofessorOther1] = useState("");
  const [AssoProfessorMale1, setAssoProfessorMale1] = useState("");
  const [AssoProfessorFemale1, setAssoProfessorFemale1] = useState("");
  const [AssoProfessorOther1, setAssoProfessorOther1] = useState("");
  const [AssisProfessorMale1, setAssisProfessorMale1] = useState("");
  const [AssisProfessorFemale1, setAssisProfessorFemale1] = useState("");
  const [AssisProfessorOther1, setAssisProfessorOther1] = useState("");
  const [professorYTR1, setprofessorYTR1] = useState("");
  const [AssoProfessorYTR1, setAssoProfessorYTR1] = useState("");
  const [AssisProfessorYTR1, setAssisProfessorYTR1] = useState("");
  const [professorMale2, setprofessorMale2] = useState("");
  const [professorFemale2, setprofessorFemale2] = useState("");
  const [professorOther2, setprofessorOther2] = useState("");
  const [AssoProfessorMale2, setAssoProfessorMale2] = useState("");
  const [AssoProfessorFemale2, setAssoProfessorFemale2] = useState("");
  const [AssoProfessorOther2, setAssoProfessorOther2] = useState("");
  const [AssisProfessorMale2, setAssisProfessorMale2] = useState("");
  const [AssisProfessorFemale2, setAssisProfessorFemale2] = useState("");
  const [AssisProfessorOther2, setAssisProfessorOther2] = useState("");
  const [professorYTR2, setprofessorYTR2] = useState("");
  const [AssoProfessorYTR2, setAssoProfessorYTR2] = useState("");
  const [AssisProfessorYTR2, setAssisProfessorYTR2] = useState("");

  const [NTSMale1, setNTSMale1] = useState("");
  const [NTSFemale1, setNTSFemale1] = useState("");
  const [NTSOther1, setNTSOther1] = useState("");
  const [NTSYTR1, setNTSYTR1] = useState("");

  const [NTSMale2, setNTSMale2] = useState("");
  const [NTSFemale2, setNTSFemale2] = useState("");
  const [NTSOther2, setNTSOther2] = useState("");
  const [NTSYTR2, setNTSYTR2] = useState("");

  const [TSMale1, setTSMale1] = useState("");
  const [TSFemale1, setTSFemale1] = useState("");
  const [TSOther1, setTSOther1] = useState("");
  const [TSYTR1, setTSYTR1] = useState("");

  const [TSMale2, setTSMale2] = useState("");
  const [TSFemale2, setTSFemale2] = useState("");
  const [TSOther2, setTSOther2] = useState("");
  const [TSYTR2, setTSYTR2] = useState("");

  const [detailsOfVisitingMale, setdetailsOfVisitingMale] = useState("");
  const [detailsOfVisitingFemale, setdetailsOfVisitingFemale] = useState("");
  const [detailsOfVisitingOther, setdetailsOfVisitingOther] = useState("");

  const [scMaleYear1, setscMaleYear1] = useState("");
  const [scMaleYear2, setscMaleYear2] = useState("");
  const [scMaleYear3, setscMaleYear3] = useState("");
  const [scMaleYear4, setscMaleYear4] = useState("");

  const [scFemaleYear1, setscFemaleYear1] = useState("");
  const [scFemaleYear2, setscFemaleYear2] = useState("");
  const [scFemaleYear3, setscFemaleYear3] = useState("");
  const [scFemaleYear4, setscFemaleYear4] = useState("");

  const [scOtherYear1, setscOtherYear1] = useState("");
  const [scOtherYear2, setscOtherYear2] = useState("");
  const [scOtherYear3, setscOtherYear3] = useState("");
  const [scOtherYear4, setscOtherYear4] = useState("");

  const [stMaleYear1, setstMaleYear1] = useState("");
  const [stMaleYear2, setstMaleYear2] = useState("");
  const [stMaleYear3, setstMaleYear3] = useState("");
  const [stMaleYear4, setstMaleYear4] = useState("");

  const [stFemaleYear1, setstFemaleYear1] = useState("");
  const [stFemaleYear2, setstFemaleYear2] = useState("");
  const [stFemaleYear3, setstFemaleYear3] = useState("");
  const [stFemaleYear4, setstFemaleYear4] = useState("");

  const [stOtherYear1, setstOtherYear1] = useState("");
  const [stOtherYear2, setstOtherYear2] = useState("");
  const [stOtherYear3, setstOtherYear3] = useState("");
  const [stOtherYear4, setstOtherYear4] = useState("");

  const [obcMaleYear1, setobcMaleYear1] = useState("");
  const [obcMaleYear2, setobcMaleYear2] = useState("");
  const [obcMaleYear3, setobcMaleYear3] = useState("");
  const [obcMaleYear4, setobcMaleYear4] = useState("");

  const [obcFemaleYear1, setobcFemaleYear1] = useState("");
  const [obcFemaleYear2, setobcFemaleYear2] = useState("");
  const [obcFemaleYear3, setobcFemaleYear3] = useState("");
  const [obcFemaleYear4, setobcFemaleYear4] = useState("");

  const [obcOtherYear1, setobcOtherYear1] = useState("");
  const [obcOtherYear2, setobcOtherYear2] = useState("");
  const [obcOtherYear3, setobcOtherYear3] = useState("");
  const [obcOtherYear4, setobcOtherYear4] = useState("");

  const [genMaleYear1, setgenMaleYear1] = useState("");
  const [genMaleYear2, setgenMaleYear2] = useState("");
  const [genMaleYear3, setgenMaleYear3] = useState("");
  const [genMaleYear4, setgenMaleYear4] = useState("");

  const [genFemaleYear1, setgenFemaleYear1] = useState("");
  const [genFemaleYear2, setgenFemaleYear2] = useState("");
  const [genFemaleYear3, setgenFemaleYear3] = useState("");
  const [genFemaleYear4, setgenFemaleYear4] = useState("");

  const [genOtherYear1, setgenOtherYear1] = useState("");
  const [genOtherYear2, setgenOtherYear2] = useState("");
  const [genOtherYear3, setgenOtherYear3] = useState("");
  const [genOtherYear4, setgenOtherYear4] = useState("");

  const [otherMaleYear1, setotherMaleYear1] = useState("");
  const [otherMaleYear2, setotherMaleYear2] = useState("");
  const [otherMaleYear3, setotherMaleYear3] = useState("");
  const [otherMaleYear4, setotherMaleYear4] = useState("");

  const [otherFemaleYear1, setotherFemaleYear1] = useState("");
  const [otherFemaleYear2, setotherFemaleYear2] = useState("");
  const [otherFemaleYear3, setotherFemaleYear3] = useState("");
  const [otherFemaleYear4, setotherFemaleYear4] = useState("");

  const [otherOtherYear1, setotherOtherYear1] = useState("");
  const [otherOtherYear2, setotherOtherYear2] = useState("");
  const [otherOtherYear3, setotherOtherYear3] = useState("");
  const [otherOtherYear4, setotherOtherYear4] = useState("");

  const [AddInfoCon5, setAddInfoCon5] = useState("");
  const [remarkCon5, setremarkCon5] = useState("");

  // const [imageURL2, setimageURL2] = useState(null);

  // const onImageChange2 = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setimageURL2(URL.createObjectURL(event.target.files[0]));
  //   }
  // };
  const UGCRecgFn = () => {
    const obj = {
      underSecUGC21: underSecUGC21,
      DateUGC21: DateUGC21,
      docUGC21: docUGC21,
    };

    setUGCRecgArr([...UGCRecgArr, obj]);
  };

  const removeItemUGCRecgFn = (val) => {
    // const updatedGallery = ;
    setUGCRecgArr([...UGCRecgArr.filter((item, index) => index !== val)]);
  };

  const startYear = 1900;
  const endYear = new Date().getFullYear();
  const [startYearReport1, setstartYearReport1] = useState(endYear);
  const [endYearReport1, setendYearReport1] = useState();
  const [ep1Year1Val, setep1Year1Val] = useState("");
  const [ep1Year2Val, setep1Year2Val] = useState("");
  const [ep1Year3Val, setep1Year3Val] = useState("");
  const [ep1Year4Val, setep1Year4Val] = useState("");
  const [ep1Year5Val, setep1Year5Val] = useState("");
  const [ep12Year1Val, setep12Year1Val] = useState("");
  const [ep12Year2Val, setep12Year2Val] = useState("");
  const [ep12Year3Val, setep12Year3Val] = useState("");
  const [ep12Year4Val, setep12Year4Val] = useState("");
  const [ep12Year5Val, setep12Year5Val] = useState("");

  const [ep21Year1Val, setep21Year1Val] = useState("");
  const [ep21Year2Val, setep21Year2Val] = useState("");
  const [ep21Year3Val, setep21Year3Val] = useState("");
  const [ep21Year4Val, setep21Year4Val] = useState("");
  const [ep21Year5Val, setep21Year5Val] = useState("");

  const [ep22Year1Val, setep22Year1Val] = useState("");
  const [ep22Year2Val, setep22Year2Val] = useState("");
  const [ep22Year3Val, setep22Year3Val] = useState("");
  const [ep22Year4Val, setep22Year4Val] = useState("");
  const [ep22Year5Val, setep22Year5Val] = useState("");

  const [ep23Year1Val, setep23Year1Val] = useState("");
  const [ep23Year2Val, setep23Year2Val] = useState("");
  const [ep23Year3Val, setep23Year3Val] = useState("");
  const [ep23Year4Val, setep23Year4Val] = useState("");
  const [ep23Year5Val, setep23Year5Val] = useState("");

  const [ep31Year1Val, setep31Year1Val] = useState("");
  const [ep31Year2Val, setep31Year2Val] = useState("");
  const [ep31Year3Val, setep31Year3Val] = useState("");
  const [ep31Year4Val, setep31Year4Val] = useState("");
  const [ep31Year5Val, setep31Year5Val] = useState("");

  const [ep32Year1Val, setep32Year1Val] = useState("");
  const [ep32Year2Val, setep32Year2Val] = useState("");
  const [ep32Year3Val, setep32Year3Val] = useState("");
  const [ep32Year4Val, setep32Year4Val] = useState("");
  const [ep32Year5Val, setep32Year5Val] = useState("");

  const [ep42Year1Val, setep42Year1Val] = useState("");
  const [ep42Year2Val, setep42Year2Val] = useState("");
  const [ep42Year3Val, setep42Year3Val] = useState("");
  const [ep42Year4Val, setep42Year4Val] = useState("");
  const [ep42Year5Val, setep42Year5Val] = useState("");

  const [fileDesc11, setfileDesc11] = useState("");
  const [Doc11, setDoc11] = useState("");
  const [fileDesc21, setfileDesc21] = useState("");
  const [Doc21, setDoc21] = useState("");

  const [fileDesc22, setfileDesc22] = useState("");
  const [Doc22, setDoc22] = useState("");

  const [fileDesc23, setfileDesc23] = useState("");
  const [Doc23, setDoc23] = useState("");

  const [fileDesc31, setfileDesc31] = useState("");
  const [Doc31, setDoc31] = useState("");

  const [fileDesc32, setfileDesc32] = useState("");
  const [Doc32, setDoc32] = useState("");

  const yearList = [];
  for (let i = endYear; i >= startYear; i--) {
    yearList.push(i);
  }

  function ChangeYearsSSR(value) {
    if (Number(value)) {
      setstartYearReport1(Number(value));
      setendYearReport1(Number(value) + 5);
    } else {
      setstartYearReport1("");
      setendYearReport1("");
    }
  }

  const [ep1Year1, setep1Year1] = useState(`${Number(startYearReport1) + 4}-
    ${Number(startYearReport1) + 5}`);
  const [ep1Year2, setep1Year2] = useState(`${Number(startYearReport1) + 3}-
  ${Number(startYearReport1) + 4}`);
  const [ep1Year3, setep1Year3] = useState(`${Number(startYearReport1) + 2}-
  ${Number(startYearReport1) + 3}`);
  const [ep1Year4, setep1Year4] = useState(`${Number(startYearReport1) + 1}-
  ${Number(startYearReport1) + 2}`);
  const [ep1Year5, setep1Year5] = useState(
    `${Number(startYearReport1)}-${Number(startYearReport1) + 1}`
  );

  const [ep12Year1, setep12Year1] = useState(`${Number(startYearReport1) + 4}-
    ${Number(startYearReport1) + 5}`);
  const [ep12Year2, setep12Year2] = useState(`${Number(startYearReport1) + 3}-
  ${Number(startYearReport1) + 4}`);
  const [ep12Year3, setep12Year3] = useState(`${Number(startYearReport1) + 2}-
  ${Number(startYearReport1) + 3}`);
  const [ep12Year4, setep12Year4] = useState(`${Number(startYearReport1) + 1}-
  ${Number(startYearReport1) + 2}`);
  const [ep12Year5, setep12Year5] = useState(
    `${Number(startYearReport1)}-${Number(startYearReport1) + 1}`
  );

  const [ep21Year1, setep21Year1] = useState(`${Number(startYearReport1) + 4}-
    ${Number(startYearReport1) + 5}`);
  const [ep21Year2, setep21Year2] = useState(`${Number(startYearReport1) + 3}-
  ${Number(startYearReport1) + 4}`);
  const [ep21Year3, setep21Year3] = useState(`${Number(startYearReport1) + 2}-
  ${Number(startYearReport1) + 3}`);
  const [ep21Year4, setep21Year4] = useState(`${Number(startYearReport1) + 1}-
  ${Number(startYearReport1) + 2}`);
  const [ep21Year5, setep21Year5] = useState(
    `${Number(startYearReport1)}-${Number(startYearReport1) + 1}`
  );

  const [ep22Year1, setep22Year1] = useState(`${Number(startYearReport1) + 4}-
    ${Number(startYearReport1) + 5}`);
  const [ep22Year2, setep22Year2] = useState(`${Number(startYearReport1) + 3}-
  ${Number(startYearReport1) + 4}`);
  const [ep22Year3, setep22Year3] = useState(`${Number(startYearReport1) + 2}-
  ${Number(startYearReport1) + 3}`);
  const [ep22Year4, setep22Year4] = useState(`${Number(startYearReport1) + 1}-
  ${Number(startYearReport1) + 2}`);
  const [ep22Year5, setep22Year5] = useState(
    `${Number(startYearReport1)}-${Number(startYearReport1) + 1}`
  );

  const [ep23Year1, setep23Year1] = useState(`${Number(startYearReport1) + 4}-
    ${Number(startYearReport1) + 5}`);
  const [ep23Year2, setep23Year2] = useState(`${Number(startYearReport1) + 3}-
  ${Number(startYearReport1) + 4}`);
  const [ep23Year3, setep23Year3] = useState(`${Number(startYearReport1) + 2}-
  ${Number(startYearReport1) + 3}`);
  const [ep23Year4, setep23Year4] = useState(`${Number(startYearReport1) + 1}-
  ${Number(startYearReport1) + 2}`);
  const [ep23Year5, setep23Year5] = useState(
    `${Number(startYearReport1)}-${Number(startYearReport1) + 1}`
  );

  const [ep31Year1, setep31Year1] = useState(`${Number(startYearReport1) + 4}-
    ${Number(startYearReport1) + 5}`);
  const [ep31Year2, setep31Year2] = useState(`${Number(startYearReport1) + 3}-
  ${Number(startYearReport1) + 4}`);
  const [ep31Year3, setep31Year3] = useState(`${Number(startYearReport1) + 2}-
  ${Number(startYearReport1) + 3}`);
  const [ep31Year4, setep31Year4] = useState(`${Number(startYearReport1) + 1}-
  ${Number(startYearReport1) + 2}`);
  const [ep31Year5, setep31Year5] = useState(
    `${Number(startYearReport1)}-${Number(startYearReport1) + 1}`
  );

  const [ep32Year1, setep32Year1] = useState(`${Number(startYearReport1) + 4}-
    ${Number(startYearReport1) + 5}`);
  const [ep32Year2, setep32Year2] = useState(`${Number(startYearReport1) + 3}-
  ${Number(startYearReport1) + 4}`);
  const [ep32Year3, setep32Year3] = useState(`${Number(startYearReport1) + 2}-
  ${Number(startYearReport1) + 3}`);
  const [ep32Year4, setep32Year4] = useState(`${Number(startYearReport1) + 1}-
  ${Number(startYearReport1) + 2}`);
  const [ep32Year5, setep32Year5] = useState(
    `${Number(startYearReport1)}-${Number(startYearReport1) + 1}`
  );

  const [ep42Year1, setep42Year1] = useState(`${Number(startYearReport1) + 4}-
    ${Number(startYearReport1) + 5}`);
  const [ep42Year2, setep42Year2] = useState(`${Number(startYearReport1) + 3}-
  ${Number(startYearReport1) + 4}`);
  const [ep42Year3, setep42Year3] = useState(`${Number(startYearReport1) + 2}-
  ${Number(startYearReport1) + 3}`);
  const [ep42Year4, setep42Year4] = useState(`${Number(startYearReport1) + 1}-
  ${Number(startYearReport1) + 2}`);
  const [ep42Year5, setep42Year5] = useState(
    `${Number(startYearReport1)}-${Number(startYearReport1) + 1}`
  );

  // Add Gallery Image
  const [Designation, setDesignation] = useState("");
  const [Name, setName] = useState("");
  const [Telephonecode, setTelephonecode] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Fax, setFax] = useState("");
  const [Email, setEmail] = useState("");
  const [ContractCom, setContractCom] = useState([]);

  const AddGalleryImage = () => {
    if (!Designation) {
      return alert("Please, enter Designation");
    }
    if (!Name) {
      return alert("Please, enter Name");
    }
    if (!Telephonecode) {
      return alert("Please, enter Telephonecode");
    }
    if (!Mobile) {
      return alert("Please, enter Mobile");
    }
    if (!Fax) {
      return alert("Please, enter Fax");
    }
    if (!Email) {
      return alert("Please, enter Email");
    }

    //  const imageExists = ContractCom.find((img) => img?.imgUrl === Image);
    //  if (imageExists) {
    //      return alert("Image already exists in the gallery.");
    //  }

    const obj = {
      Designation: Designation,
      Name: Name,
      Telephonecode: Telephonecode,
      Mobile: Mobile,
      Fax: Fax,
      Email: Email,
    };

    setContractCom([...ContractCom, obj]);
  };
  const removeItem2 = (val) => {
    const updatedGallery = ContractCom.filter((item, index) => index !== val);
    setContractCom(updatedGallery);
  };

  const [Statutory, setStatutory] = useState("");
  const [Recognition, setRecognition] = useState("");
  const [DMY, setDMY] = useState("");
  const [ValidMonth, setValidMonth] = useState("");
  const [Remark, setRemark] = useState("");
  const [Approve, setApprove] = useState([]);

  const DetailsofApprove = () => {
    if (!Statutory) {
      return alert("Please, enter Statutory");
    }
    if (!Recognition) {
      return alert("Please, enter Recognition");
    }
    if (!DMY) {
      return alert("Please, enter D-M-Y");
    }
    if (!ValidMonth) {
      return alert("Please, enter Mobile");
    }
    if (!Remark) {
      return alert("Please, enter Remark");
    }

    //  const imageExists = ContractCom.find((img) => img?.imgUrl === Image);
    //  if (imageExists) {
    //      return alert("Image already exists in the gallery.");
    //  }

    const obj = {
      Statutory: Statutory,
      Recognition: Recognition,
      DMY: DMY,
      ValidMonth: ValidMonth,
      Remark: Remark,
    };

    setApprove([...Approve, obj]);
  };
  const removeItem3 = (val) => {
    const updatedGallery = Approve.filter((item, index) => index !== val);
    setApprove(updatedGallery);
  };

  const [ProgramLevel, setProgramLevel] = useState("");
  const [Programme, setProgramme] = useState("");
  const [DUration, setDUration] = useState("");
  const [Qualification, setQualification] = useState("");
  const [Instruction, setInstruction] = useState("");
  const [Strength, setStrength] = useState("");
  const [Admitted, setAdmitted] = useState("");

  const [AcademinInfo, setAcademinInfo] = useState([]);

  const AcademicInformation = () => {
    if (!ProgramLevel) {
      return alert("Please, enter ProgramLevel");
    }
    if (!Programme) {
      return alert("Please, enter Name of Programme/Course");
    }
    if (!DUration) {
      return alert("Please, enter Duration in Months");
    }
    if (!Qualification) {
      return alert("Please, enter Entry Qualification");
    }
    if (!Instruction) {
      return alert("Please, enter Medium of Instruction");
    }
    if (!Strength) {
      return alert("Please, enter Sanctioned Strength");
    }
    if (!Admitted) {
      return alert("Please, enter No.of Students Admitted");
    }

    //  const imageExists = ContractCom.find((img) => img?.imgUrl === Image);
    //  if (imageExists) {
    //      return alert("Image already exists in the gallery.");
    //  }

    const obj = {
      ProgramLevel: ProgramLevel,
      Programme: Programme,
      DUration: DUration,
      Qualification: Qualification,
      Instruction: Instruction,
      Strength: Strength,
      Admitted: Admitted,
    };

    setAcademinInfo([...AcademinInfo, obj]);
  };
  const removeItem4 = (val) => {
    const updatedGallery = AcademinInfo.filter((item, index) => index !== val);
    setAcademinInfo(updatedGallery);
  };

  const [PermannentTech, setPermannentTech] = useState([]);
  const [PhightestQ, setPhightestQ] = useState("");
  const [Pmale1, setPmale1] = useState("");
  const [Pfemale1, setPfemale1] = useState("");
  const [Pother1, setPother1] = useState("");
  const [Pmale2, setPmale2] = useState("");
  const [Pfemale2, setPfemale2] = useState("");
  const [Pother2, setPother2] = useState("");
  const [Pmale3, setPmale3] = useState("");
  const [Pfemale3, setPfemale3] = useState("");
  const [Pother3, setPother3] = useState("");

  const PermanentTeacher = () => {
    const obj = {
      PhightestQ: PhightestQ,
      Pmale1: Pmale1,
      Pfemale1: Pfemale1,
      Pother1: Pother1,
      Pmale2: Pmale2,
      Pfemale2: Pfemale2,
      Pother2: Pother2,
      Pmale3: Pmale3,
      Pfemale3: Pfemale3,
      Pother3: Pother3,
    };

    setPermannentTech([...PermannentTech, obj]);
  };
  const removeItem5 = (val) => {
    const updatedGallery = PermannentTech.filter(
      (item, index) => index !== val
    );
    setPermannentTech(updatedGallery);
  };

  const [TemporaryTech, setTemporaryTech] = useState([]);
  const [ThightestQ, setThightestQ] = useState("");
  const [Tmale1, setTmale1] = useState("");
  const [Tfemale1, setTfemale1] = useState("");
  const [Tother1, setTother1] = useState("");
  const [Tmale2, setTmale2] = useState("");
  const [TPfemale2, setTfemale2] = useState("");
  const [Tother2, setTother2] = useState("");
  const [Tmale3, setTmale3] = useState("");
  const [Tfemale3, setTfemale3] = useState("");
  const [Tother3, setTother3] = useState("");
  const TemporaryTeacher = () => {
    const obj = {
      ThightestQ: ThightestQ,
      Tmale1: Tmale1,
      Tfemale1: Tfemale1,
      Tother1: Tother1,
      Tmale2: Tmale2,
      TPfemale2: TPfemale2,
      Tother2: Tother2,
      Tmale3: Tmale3,
      Tfemale3: Tfemale3,
      Tother3: Tother3,
    };

    setTemporaryTech([...TemporaryTech, obj]);
  };
  const removeItem6 = (val) => {
    const updatedGallery = TemporaryTech.filter((item, index) => index !== val);
    setTemporaryTech(updatedGallery);
  };
  const [PartTimeTech, setPartTimeTech] = useState([]);
  const [PThightestQ, setPThightestQ] = useState("");
  const [PTmale1, setPTmale1] = useState("");
  const [PTfemale1, setPTfemale1] = useState("");
  const [PTother1, setPTother1] = useState("");
  const [PTmale2, setPTmale2] = useState("");
  const [PTfemale2, setPTfemale2] = useState("");
  const [PTother2, setPTother2] = useState("");
  const [PTmale3, setPTmale3] = useState("");
  const [PTfemale3, setPTfemale3] = useState("");
  const [PTother3, setPTother3] = useState("");
  const PartTimeTeacher = () => {
    const obj = {
      PThightestQ: PThightestQ,
      PTmale1: PTmale1,
      PTfemale1: PTfemale1,
      PTother1: PTother1,
      PTmale2: PTmale2,
      PTfemale2: PTfemale2,
      PTother2: PTother2,
      PTmale3: PTmale3,
      PTfemale3: PTfemale3,
      PTother3: PTother3,
    };

    setPartTimeTech([...PartTimeTech, obj]);
  };
  const removeItem7 = (val) => {
    const updatedGallery = PartTimeTech.filter((item, index) => index !== val);
    setPartTimeTech(updatedGallery);
  };

  const [AnnuxtureArr, setAnnuxtureArr] = useState([]);
  const [IndexId, setIndexId] = useState("");
  const [ANXDesc, setANXDesc] = useState("");
  const Annexure = () => {
    const obj = {
      IndexId: IndexId,
      ANXDesc: ANXDesc,
    };

    setAnnuxtureArr([...AnnuxtureArr, obj]);
  };
  const removeItem8 = (val) => {
    const updatedGallery = AnnuxtureArr.filter((item, index) => index !== val);
    setAnnuxtureArr(updatedGallery);
  };

  const [StudentsEnrolled, setStudentsEnrolled] = useState([]);
  const [Course, setCourse] = useState("");
  const [CollegeStateM, setCollegeStateM] = useState("");
  const [OtherStateM, setOtherStateM] = useState("");
  const [NRIstudentM, setNRIstudentM] = useState("");
  const [ForeignStudentM, setForeignStudentM] = useState("");
  const [CollegeStateF, setCollegeStateF] = useState("");
  const [OtherStateF, setOtherStateF] = useState("");
  const [NRIstudentF, setNRIstudentF] = useState("");
  const [ForeignStudentF, setForeignStudentF] = useState("");
  const [CollegeStateO, setCollegeStateO] = useState("");
  const [OtherStateO, setOtherStateO] = useState("");
  const [NRIstudentO, setNRIstudentO] = useState("");
  const [ForeignStudentO, setForeignStudentO] = useState("");

  const [CFCollegeStateM, setCFCollegeStateM] = useState("");
  const [CFOtherStateM, setCFOtherStateM] = useState("");
  const [CFNRIstudentM, setCFNRIstudentM] = useState("");
  const [CFForeignStudentM, setCFForeignStudentM] = useState("");
  const [CFCollegeStateF, setCFCollegeStateF] = useState("");
  const [CFOtherStateF, setCFOtherStateF] = useState("");
  const [CFNRIstudentF, setCFNRIstudentF] = useState("");
  const [CFForeignStudentF, setCFForeignStudentF] = useState("");
  const [CFCollegeStateO, setCFCollegeStateO] = useState("");
  const [CFOtherStateO, setCFOtherStateO] = useState("");
  const [CFNRIstudentO, setCFNRIstudentO] = useState("");
  const [CFForeignStudentO, setCFForeignStudentO] = useState("");

  const StudentsEnrolledAcedemic = () => {
    const obj = {
      Course: Course,
      CollegeStateM: CollegeStateM,
      OtherStateM: OtherStateM,
      NRIstudentM: NRIstudentM,
      ForeignStudentM: ForeignStudentM,
      CollegeStateF: CollegeStateF,
      OtherStateF: OtherStateF,
      NRIstudentF: NRIstudentF,
      ForeignStudentF: ForeignStudentF,
      CollegeStateO: CollegeStateO,
      OtherStateO: OtherStateO,
      NRIstudentO: NRIstudentO,
      ForeignStudentO: ForeignStudentO,

      CFCollegeStateM: CFCollegeStateM,
      CFOtherStateM: CFOtherStateM,
      CFNRIstudentM: CFNRIstudentM,
      CFForeignStudentM: CFForeignStudentM,
      CFCollegeStateF: CFCollegeStateF,
      CFOtherStateF: CFOtherStateF,
      CFNRIstudentF: CFNRIstudentF,
      CFForeignStudentF: CFForeignStudentF,
      CFCollegeStateO: CFCollegeStateO,
      CFOtherStateO: CFOtherStateO,
      CFNRIstudentO: CFNRIstudentO,
      CFForeignStudentO: CFForeignStudentO,
    };

    setStudentsEnrolled([...StudentsEnrolled, obj]);
  };
  const removeItem9 = (val) => {
    const updatedGallery = StudentsEnrolled.filter(
      (item, index) => index !== val
    );
    setStudentsEnrolled(updatedGallery);
  };

  const parseHTML = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };

  async function AddSSR() {
    try {
      const obj = {
        startDate: startYearReport1,
        endDate: endYearReport1,
        prefaceFor: prefaceFor,
        prefaceWebsiteURI: prefaceWebsiteURI,
        prefaceDate: prefaceDate,
        exeSum1Intro: exeSum1Intro,
        exeSum1Vision: exeSum1Vision,
        exeSum1Mission: exeSum1Mission,
        swoc12InstStrength: swoc12InstStrength,
        swoc12InstWeakness: swoc12InstWeakness,
        swoc12InstOppo: swoc12InstOppo,
        swoc12InstChallenge: swoc12InstChallenge,
        criSum13CurrAsp: criSum13CurrAsp,
        criSum13TeachLearn: criSum13TeachLearn,
        criSum13Research: criSum13Research,
        criSum13Infrastructure: criSum13Infrastructure,
        criSum13StuSupport: criSum13StuSupport,
        criSum13Gov: criSum13Gov,
        criSum13InstValue: criSum13InstValue,
        profile21Name: profile21Name,
        profile21Address: profile21Address,
        profile21City: profile21City,
        profile21State: profile21State,
        profile21Pin: profile21Pin,
        profile21Website: profile21Website,
        ContractCom: ContractCom,
        inst21Status: inst21Status,
        typeOfInst21Gender: typeOfInst21Gender,
        typeOfInst21Shift: typeOfInst21Shift,
        regMinInst21: regMinInst21,
        dateOFEstOFCollege21: dateOFEstOFCollege21,
        collegeAff21State: collegeAff21State,
        collegeAff21UniName: collegeAff21UniName,
        collegeAff21Doc: collegeAff21Doc,
        UGCRecgArr: UGCRecgArr,
        Approve: Approve,
        detailsAutonomy21: detailsAutonomy21,
        recog211: recog211,
        recog212: recog212,
        campType21: campType21,
        campAddress21: campAddress21,
        campLoc21: campLoc21,
        campAreaInAcre21: campAreaInAcre21,
        campBuildUp21: campBuildUp21,
        AcademinInfo: AcademinInfo,
        professorMale1: professorMale1,
        professorFemale1: professorFemale1,
        professorOther1: professorOther1,
        AssoProfessorMale1: AssoProfessorMale1,
        AssoProfessorFemale1: AssoProfessorFemale1,
        AssoProfessorOther1: AssoProfessorOther1,
        AssisProfessorMale1: AssisProfessorMale1,
        AssisProfessorFemale1: AssisProfessorFemale1,
        AssisProfessorOther1: AssisProfessorOther1,
        professorYTR1: professorYTR1,
        AssoProfessorYTR1: AssoProfessorYTR1,
        AssisProfessorYTR1: AssisProfessorYTR1,
        professorMale2: professorMale2,
        professorFemale2: professorFemale2,
        professorOther2: professorOther2,
        AssoProfessorMale2: AssoProfessorMale2,
        AssoProfessorFemale2: AssoProfessorFemale2,
        AssoProfessorOther2: AssoProfessorOther2,
        AssisProfessorMale2: AssisProfessorMale2,
        AssisProfessorFemale2: AssisProfessorFemale2,
        AssisProfessorOther2: AssisProfessorOther2,
        professorYTR2: professorYTR2,
        AssoProfessorYTR2: AssoProfessorYTR2,
        AssisProfessorYTR2: AssisProfessorYTR2,
        NTSMale1: NTSMale1,
        NTSFemale1: NTSFemale1,
        NTSOther1: NTSOther1,
        NTSYTR1: NTSYTR1,
        NTSMale2: NTSMale2,
        NTSFemale2: NTSFemale2,
        NTSOther2: NTSOther2,
        NTSYTR2: NTSYTR2,
        TSMale1: TSMale1,
        TSFemale1: TSFemale1,
        TSOther1: TSOther1,
        TSYTR1: TSYTR1,
        TSMale2: TSMale2,
        TSFemale2: TSFemale2,
        TSOther2: TSOther2,
        TSYTR2: TSYTR2,
        PermannentTech: PermannentTech,
        TemporaryTech: TemporaryTech,
        PartTimeTech: PartTimeTech,
        detailsOfVisitingMale: detailsOfVisitingMale,
        detailsOfVisitingFemale: detailsOfVisitingFemale,
        detailsOfVisitingOther: detailsOfVisitingOther,
        StudentsEnrolled: StudentsEnrolled,
        scMaleYear1: scMaleYear1,
        scMaleYear2: scMaleYear2,
        scMaleYear3: scMaleYear3,
        scMaleYear4: scMaleYear4,
        scFemaleYear1: scFemaleYear1,
        scFemaleYear2: scFemaleYear2,
        scFemaleYear3: scFemaleYear3,
        scFemaleYear4: scFemaleYear4,
        scOtherYear1: scOtherYear1,
        scOtherYear2: scOtherYear2,
        scOtherYear3: scOtherYear3,
        scOtherYear4: scOtherYear4,
        stMaleYear1: stMaleYear1,
        stMaleYear2: stMaleYear2,
        stMaleYear3: stMaleYear3,
        stMaleYear4: stMaleYear4,
        stFemaleYear1: stFemaleYear1,
        stFemaleYear2: stFemaleYear2,
        stFemaleYear3: stFemaleYear3,
        stFemaleYear4: stFemaleYear4,
        stOtherYear1: stOtherYear1,
        stOtherYear2: stOtherYear2,
        stOtherYear3: stOtherYear3,
        stOtherYear4: stOtherYear4,
        obcMaleYear1: obcMaleYear1,
        obcMaleYear2: obcMaleYear2,
        obcMaleYear3: obcMaleYear3,
        obcMaleYear4: obcMaleYear4,
        obcFemaleYear1: obcFemaleYear1,
        obcFemaleYear2: obcFemaleYear2,
        obcFemaleYear3: obcFemaleYear3,
        obcFemaleYear4: obcFemaleYear4,
        obcOtherYear1: obcOtherYear1,
        obcOtherYear2: obcOtherYear2,
        obcOtherYear3: obcOtherYear3,
        obcOtherYear4: obcOtherYear4,
        genMaleYear1: genMaleYear1,
        genMaleYear2: genMaleYear2,
        genMaleYear3: genMaleYear3,
        genMaleYear4: genMaleYear4,
        genFemaleYear1: genFemaleYear1,
        genFemaleYear2: genFemaleYear2,
        genFemaleYear3: genFemaleYear3,
        genFemaleYear4: genFemaleYear4,
        genOtherYear1: genOtherYear1,
        genOtherYear2: genOtherYear2,
        genOtherYear3: genOtherYear3,
        genOtherYear4: genOtherYear4,
        otherMaleYear1: otherMaleYear1,
        otherMaleYear2: otherMaleYear2,
        otherMaleYear3: otherMaleYear3,
        otherMaleYear4: otherMaleYear4,
        otherFemaleYear1: otherFemaleYear1,
        otherFemaleYear2: otherFemaleYear2,
        otherFemaleYear3: otherFemaleYear3,
        otherFemaleYear4: otherFemaleYear4,
        otherOtherYear1: otherOtherYear1,
        otherOtherYear2: otherOtherYear2,
        otherOtherYear3: otherOtherYear3,
        otherOtherYear4: otherOtherYear4,

        ep1Year1: ep1Year1,
        ep1Year1Val: ep1Year1Val,
        ep1Year2: ep1Year2,
        ep1Year2Val: ep1Year2Val,
        ep1Year3: ep1Year3,
        ep1Year3Val: ep1Year3Val,
        ep1Year4: ep1Year4,
        ep1Year4Val: ep1Year4Val,
        ep1Year5: ep1Year5,
        ep1Year5Val: ep1Year5Val,
        fileDesc11: fileDesc11,
        Doc11: Doc11,
        ep12Year1: ep12Year1,
        ep12Year1Val: ep12Year1Val,
        ep12Year2: ep12Year2,
        ep12Year2Val: ep12Year2Val,
        ep12Year3: ep12Year3,
        ep12Year3Val: ep12Year3Val,
        ep12Year4: ep12Year4,
        ep12Year4Val: ep12Year4Val,
        ep12Year5: ep12Year5,
        ep12Year5Val: ep12Year5Val,
        ep21Year1: ep21Year1,
        ep21Year1Val: ep21Year1Val,
        ep21Year2: ep21Year2,
        ep21Year2Val: ep21Year2Val,
        ep21Year3: ep21Year3,
        ep21Year3Val: ep21Year3Val,
        ep21Year4: ep21Year4,
        ep21Year4Val: ep21Year4Val,
        ep21Year5: ep21Year5,
        ep21Year5Val: ep21Year5Val,
        fileDesc21: fileDesc21,
        Doc21: Doc21,
        ep22Year1: ep22Year1,
        ep22Year1Val: ep22Year1Val,
        ep22Year2: ep22Year2,
        ep22Year2Val: ep22Year2Val,
        ep22Year3: ep22Year3,
        ep22Year3Val: ep22Year3Val,
        ep22Year4: ep22Year4,
        ep22Year4Val: ep22Year4Val,
        ep22Year5: ep22Year5,
        ep22Year5Val: ep22Year5Val,
        fileDesc22: fileDesc22,
        Doc22: Doc22,
        ep23Year1: ep23Year1,
        ep23Year1Val: ep23Year1Val,
        ep23Year2: ep23Year2,
        ep23Year2Val: ep23Year2Val,
        ep23Year3: ep23Year3,
        ep23Year3Val: ep23Year3Val,
        ep23Year4: ep23Year4,
        ep23Year4Val: ep23Year4Val,
        ep23Year5: ep23Year5,
        ep23Year5Val: ep23Year5Val,
        fileDesc23: fileDesc23,
        Doc23: Doc23,
        ep31Year1: ep31Year1,
        ep31Year1Val: ep31Year1Val,
        ep31Year2: ep31Year2,
        ep31Year2Val: ep31Year2Val,
        ep31Year3: ep31Year3,
        ep31Year3Val: ep31Year3Val,
        ep31Year4: ep31Year4,
        ep31Year4Val: ep31Year4Val,
        ep31Year5: ep31Year5,
        ep31Year5Val: ep31Year5Val,
        fileDesc31: fileDesc31,
        Doc31: Doc31,
        ep32Year1: ep32Year1,
        ep32Year1Val: ep32Year1Val,
        ep32Year2: ep32Year2,
        ep32Year2Val: ep32Year2Val,
        ep32Year3: ep32Year3,
        ep32Year3Val: ep32Year3Val,
        ep32Year4: ep32Year4,
        ep32Year4Val: ep32Year4Val,
        ep32Year5: ep32Year5,
        ep32Year5Val: ep32Year5Val,
        fileDesc32: fileDesc32,
        Doc32: Doc32,

        res41: res41,
        ep42Year1: ep42Year1,
        ep42Year1Val: ep42Year1Val,
        ep42Year2: ep42Year2,
        ep42Year2Val: ep42Year2Val,
        ep42Year3: ep42Year3,
        ep42Year3Val: ep42Year3Val,
        ep42Year4: ep42Year4,
        ep42Year4Val: ep42Year4Val,
        ep42Year5: ep42Year5,
        ep42Year5Val: ep42Year5Val,
        res43: res43,
        res44: res44,
        AddInfoCon5: AddInfoCon5,
        remarkCon5: remarkCon5,
        AnnuxtureArr: AnnuxtureArr,

        AddInfoCon5: AddInfoCon5,
        remarkCon5: remarkCon5,
        AnnuxtureArr: AnnuxtureArr,
      };

      const config = {
        url: "/pri/postSSRDetails",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      // const config = {
      //   url: "/pri/postSSRDetails",
      //   method: "post",
      //   // baseURL: "https://brightnaac.co.in/api",
      //   baseURL: "https://brightnaac.co.in/api",
      //   headers: { "content-type": "multipart/form-data" },
      //   data: obj,
      // };
      await axios(config).then((res) => {
        if (res.status === 201) {
          alert(res.data.success);
          navigate("/ssr-List");
          // getAllFinalReports();
          // handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      return alert(error.response.data.error);
    }
  }

  return (
    <div style={{ backgroundColor: "#ced3be" }}>
      <label>Date</label>
      <div className="d-flex">
        <div style={{ marginRight: "10px" }}>
          <div>Start Date: </div>
          <select
            style={{ width: "124px" }}
            onChange={(e) => ChangeYearsSSR(e.target.value)}
          >
            <option>Choose option</option>
            {yearList?.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <div> End Date:</div>
          <input value={endYearReport1} style={{ width: "124px" }} disabled />
        </div>
      </div>
      <Container style={{ backgroundColor: "white", width: "80%" }}>
        <div className=" mt-3 d-flex justify-content-center">
          <img src="./header.png" alt="" />
        </div>
        <div className=" mt-3 d-flex justify-content-center">
          <h4>
            <b>SELF STUDY REPORT</b>
          </h4>
        </div>
        <div className=" mt-3 d-flex justify-content-center">
          <h2>
            <b>FOR</b>
          </h2>
        </div>
        <div className=" mt-3 d-flex justify-content-center">
          <h2>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              placeholder="3rd CYCLE OF ACCREDITATION"
              value={prefaceFor}
              onChange={(e) => setprefaceFor(e.target.value)}
            />
          </h2>
        </div>
        <div className=" mt-3 d-flex justify-content-center">
          <h2>
            <b>{pricipalDetails?.HeiName}</b>
          </h2>
        </div>
        <div className=" mt-3 d-flex justify-content-center">
          <h6>{pricipalDetails?.Address}</h6>
        </div>
        {/* <div className=" mt-3 d-flex justify-content-center">
          <h6>796007</h6>
        </div> */}
        <div className=" mt-3 d-flex justify-content-center">
          <h6>
            {/* <a href="https://www.gzrsc.edu.in/" target="_blank">
              www.gzrsc.edu.in
            </a> */}
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              placeholder="Enter Your Wesite URl"
              value={prefaceWebsiteURI}
              onChange={(e) => setprefaceWebsiteURI(e.target.value)}
            />
          </h6>
        </div>
        <div className=" mt-5 d-flex justify-content-center">
          <h6>Submitted To</h6>
        </div>
        <div className=" mt-3 d-flex justify-content-center">
          <h5>NATIONAL ASSESSMENT AND ACCREDITATION COUNCIL</h5>
        </div>
        <div className=" mt-3 d-flex justify-content-center">
          <h5>BANGALORE</h5>
        </div>
        <div className=" mt-3 d-flex justify-content-center">
          <h5>
            <input
              type="text"
              className="vi_0"
              aria-label="Default select example"
              style={{ width: "270px" }}
              placeholder="July 2021"
              value={prefaceDate}
              onChange={(e) => setprefaceDate(e.target.value)}
            />
          </h5>
        </div>
        <br />
        <br />
      </Container>

      <Container
        style={{ backgroundColor: "white", width: "80%" }}
        className="mt-3"
      >
        <div>
          <div
            className=" mt-3 d-flex justify-content-start"
            style={{ borderBottom: "1px solid" }}
          >
            <h5 className="mt-4">
              <b>1. EXECUTIVE SUMMARY</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>1.1 INTRODUCTION</b>
            </h5>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={exeSum1Intro}
              onChange={(event, editor) => {
                const data = editor.getData();
                setexeSum1Intro(data);
              }}
            />
          </div>
          <div className=" mt-3 d-flex ">
            <h6>
              <b>Vision</b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={exeSum1Vision}
              onChange={(event, editor) => {
                const data = editor.getData();
                setexeSum1Vision(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b>Mission</b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={exeSum1Mission}
              onChange={(event, editor) => {
                const data = editor.getData();
                setexeSum1Mission(data);
              }}
            />
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>1.2 Strength, Weakness, Opportunity and Challenges(SWOC)</b>
            </h5>
          </div>
          <div className=" mt-3">
            <h6>
              <b> Institutional Strength </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={swoc12InstStrength}
              onChange={(event, editor) => {
                const data = editor.getData();
                setswoc12InstStrength(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b> Institutional Weakness </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={swoc12InstWeakness}
              onChange={(event, editor) => {
                const data = editor.getData();
                setswoc12InstWeakness(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b> Institutional Opportunity </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={swoc12InstOppo}
              onChange={(event, editor) => {
                const data = editor.getData();
                setswoc12InstOppo(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b> Institutional Challenge </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={swoc12InstChallenge}
              onChange={(event, editor) => {
                const data = editor.getData();
                setswoc12InstChallenge(data);
              }}
            />
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>1.3 CRITERIA WISE SUMMARY</b>
            </h5>
          </div>
          <div className=" mt-3">
            <h6>
              <b> Curricular Aspects </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={criSum13CurrAsp}
              onChange={(event, editor) => {
                const data = editor.getData();
                setcriSum13CurrAsp(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b> Teaching-learning and Evaluation </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={criSum13TeachLearn}
              onChange={(event, editor) => {
                const data = editor.getData();
                setcriSum13TeachLearn(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b> Research, Innovations and Extension </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={criSum13Research}
              onChange={(event, editor) => {
                const data = editor.getData();
                setcriSum13Research(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b> Infrastructure and Learning Resources </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={criSum13Infrastructure}
              onChange={(event, editor) => {
                const data = editor.getData();
                setcriSum13Infrastructure(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b> Student Support and Progression </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={criSum13StuSupport}
              onChange={(event, editor) => {
                const data = editor.getData();
                setcriSum13StuSupport(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b> Governance, Leadership and Management </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={criSum13Gov}
              onChange={(event, editor) => {
                const data = editor.getData();
                setcriSum13Gov(data);
              }}
            />
          </div>
          <div className=" mt-3">
            <h6>
              <b> Institutional Values and Best Practices </b>
            </h6>
          </div>
          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={criSum13InstValue}
              onChange={(event, editor) => {
                const data = editor.getData();
                setcriSum13InstValue(data);
              }}
            />
          </div>{" "}
          <br />
        </div>
      </Container>

      <Container
        style={{ backgroundColor: "white", width: "80%" }}
        className="mt-3"
      >
        <div>
          <div
            className=" mt-3 d-flex justify-content-start"
            style={{ borderBottom: "1px solid" }}
          >
            <h5 className="mt-4">
              <b>2. PROFILE</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>2.1 BASIC INFORMATION</b>
            </h5>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  Name and Address of the College
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="Name"
                      value={profile21Name}
                      onChange={(e) => setprofile21Name(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="Address"
                      value={profile21Address}
                      onChange={(e) => setprofile21Address(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="City"
                      value={profile21City}
                      onChange={(e) => setprofile21City(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="State"
                      value={profile21State}
                      onChange={(e) => setprofile21State(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pin</td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="Pin"
                      value={profile21Pin}
                      onChange={(e) => setprofile21Pin(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="Website link"
                      value={profile21Website}
                      onChange={(e) => setprofile21Website(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="7"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Contacts for Communication
                  </th>
                </tr>
              </thead>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Designation
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Name
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Telephone with STD Code
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Mobile
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Fax
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Email
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Designation"
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Telephone with STD Code"
                      onChange={(e) => setTelephonecode(e.target.value)}
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Mobile"
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      onChange={(e) => setFax(e.target.value)}
                      placeholder="Fax"
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                  </td>
                  <th>
                    <Button onClick={AddGalleryImage}>Add</Button>
                  </th>
                </tr>
                {ContractCom?.map((item, i) => {
                  return (
                    <tr>
                      <td>{item?.Designation}</td>
                      <td>{item?.Name}</td>
                      <td>{item?.Telephonecode}</td>
                      <td>{item?.Mobile}</td>
                      <td>{item?.Fax}</td>
                      <td>{item?.Email}</td>
                      <td>
                        <MdDelete
                          onClick={() => removeItem2(i)}
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="2"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    {" "}
                    Status of the Institution
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <div>Institution Status</div>
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="Government"
                      value={inst21Status}
                      onChange={(e) => setinst21Status(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="2"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Type of Institution
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <div>By Gender</div>
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="Co-education"
                      value={typeOfInst21Gender}
                      onChange={(e) => settypeOfInst21Gender(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <div>By Shift</div>
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="Regular"
                      value={typeOfInst21Shift}
                      onChange={(e) => settypeOfInst21Shift(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="2"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Recognized Minority institution
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <div>If it is a recognized minroity institution</div>
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="No"
                      value={regMinInst21}
                      onChange={(e) => setregMinInst21(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="2"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Establishment Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <div>Date of establishment of the college</div>
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder="21-06-2000"
                      value={dateOFEstOFCollege21}
                      onChange={(e) => setdateOFEstOFCollege21(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <br />
      </Container>
      <Container
        style={{ backgroundColor: "white", width: "80%", paddingTop: "10px" }}
        className="mt-3"
      >
        <div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="3"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    University to which the college is affiliated/ or which
                    governs the college (if it is a constituent college)
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>State</th>
                  <th>University name</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="State"
                      value={collegeAff21State}
                      onChange={(e) => setcollegeAff21State(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="University name"
                      value={collegeAff21UniName}
                      onChange={(e) => setcollegeAff21UniName(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Document"
                      onChange={(e) => setcollegeAff21Doc(e.target.files[0])}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="3"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Details of UGC recognition
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Under Section</th>
                  <th>Date</th>
                  <th>View Document</th>
                  <th>
                    <Button onClick={UGCRecgFn}>Add</Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Under Section"
                      value={underSecUGC21}
                      onChange={(e) => setunderSecUGC21(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Date"
                      value={DateUGC21}
                      onChange={(e) => setDateUGC21(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Document"
                      // accept="image/*"
                      onChange={(e) => {
                        // onImageChange2(e);
                        setdocUGC21(e.target.files[0]);
                      }}
                    />
                  </td>
                </tr>
                {UGCRecgArr?.map((valUgc, i) => {
                  return (
                    <tr key={i}>
                      <td>{valUgc?.underSecUGC21}</td>
                      <td>{valUgc?.DateUGC21}</td>
                      <td>
                        {valUgc?.docUGC21 && (
                          <a
                            href={URL.createObjectURL(valUgc.docUGC21)}
                            target="_blank"
                          >
                            View Document
                          </a>
                        )}
                      </td>
                      <td>
                        <MdDelete
                          onClick={() => removeItemUGCRecgFn(i)}
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="6"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Details of recognition/approval by stationary/regulatory
                    bodies like AICTE,NCTE,MCI,DCI,PCI,RCI etc(other than UGC)
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Statutory Regulatory Authority</th>
                  <th>
                    Recognition/App roval details Inst itution/Departme nt
                    programme
                  </th>
                  <th>Day,Month and year(dd-mm-yyyy)</th>
                  <th>Validity in months</th>
                  <th>Remarks</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder=""
                      onChange={(e) => setStatutory(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "200px" }}
                      onChange={(e) => setRecognition(e.target.value)}
                      placeholder=""
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="date"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      onChange={(e) => setDMY(e.target.value)}
                      placeholder="Date"
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      onChange={(e) => setValidMonth(e.target.value)}
                      placeholder="Valid Month"
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Remark"
                    />
                  </td>
                  <td>
                    <Button onClick={DetailsofApprove}>Add</Button>
                  </td>
                </tr>
                {Approve?.map((item, i) => {
                  return (
                    <tr>
                      <td>{item?.Statutory}</td>
                      <td>{item?.Recognition}</td>
                      <td>{item?.DMY}</td>
                      <td>{item?.ValidMonth}</td>
                      <td>{item?.Remark}</td>
                      <td>
                        <MdDelete
                          onClick={() => removeItem3(i)}
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="2"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Details of autonomy
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <div>
                      Does the affiliating university Act provide for conferment
                      of autonomy (as recognized by the UGC), on its affiliated
                      colleges?
                    </div>
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder=""
                      value={detailsAutonomy21}
                      onChange={(e) => setdetailsAutonomy21(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="2"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Recognitions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <div>
                      Is the College recognized by UGC as a College with
                      Potential for Excellence(CPE)?
                    </div>
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder=""
                      value={recog211}
                      onChange={(e) => setrecog211(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <div>
                      Is the College recognized for its performance by any other
                      governmental agency?
                    </div>
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      style={{ width: "270px" }}
                      placeholder=""
                      value={recog212}
                      onChange={(e) => setrecog212(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="5"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Location and Area of Campus
                  </th>
                </tr>
              </thead>
              <thead>
                <th>Campus Type</th>
                <th>Address</th>
                <th>Location*</th>
                <th>Campus Area in Acres</th>
                <th>Built up Area in sq.mts.</th>
              </thead>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Campus Type"
                      value={campType21}
                      onChange={(e) => setcampType21(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Address"
                      value={campAddress21}
                      onChange={(e) => setcampAddress21(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Location"
                      value={campLoc21}
                      onChange={(e) => setcampLoc21(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Campus Area
                      in Acres"
                      value={campAreaInAcre21}
                      onChange={(e) => setcampAreaInAcre21(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Built up Area in
                      sq.mts."
                      value={campBuildUp21}
                      onChange={(e) => setcampBuildUp21(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <br />
        </div>
      </Container>
      <Container
        style={{ backgroundColor: "white", width: "80%", paddingTop: "10px" }}
        className="mt-3"
      >
        <div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>2.2 ACADEMIC INFORMATION</b>
            </h5>
          </div>

          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="8"
                    style={{
                      textAlign: "start",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Details of Programmes Offered by the College (Give Data for
                    Current Academic year)
                  </th>
                </tr>
              </thead>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Programme Level
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Name of Programme/Course
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Duration in Months
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Entry Qualification
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Medium of Instruction
                  </th>
                  <th style={{ backgroundColor: "white", color: "black" }}>
                    Sanctioned Strength
                  </th>
                  <th>No.of Students Admitted</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      placeholder=" Programme Level"
                      onChange={(e) => setProgramLevel(e.target.value)}
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder="Name of Programme/Course"
                      onChange={(e) => setProgramme(e.target.value)}
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder=" Duration in Months"
                      onChange={(e) => setDUration(e.target.value)}
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      placeholder=" Entry Qualification"
                      onChange={(e) => setQualification(e.target.value)}
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      onChange={(e) => setInstruction(e.target.value)}
                      placeholder=" Medium of Instruction"
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      onChange={(e) => setStrength(e.target.value)}
                      placeholder="Sanctioned Strength"
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      // style={{ width: "270px" }}
                      onChange={(e) => setAdmitted(e.target.value)}
                      placeholder="No.of Students Admitted"
                    />
                  </td>

                  <th>
                    <Button onClick={AcademicInformation}>Add</Button>
                  </th>
                </tr>
                {AcademinInfo?.map((item, i) => {
                  return (
                    <tr>
                      <td>{item?.ProgramLevel}</td>
                      <td>{item?.Programme}</td>
                      <td>{item?.DUration}</td>
                      <td>{item?.Qualification}</td>
                      <td>{item?.Instruction}</td>
                      <td>{item?.Strength}</td>
                      <td>{item?.Admitted}</td>
                      <td>
                        <MdDelete
                          onClick={() => removeItem4(i)}
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="mt-2">
            <h6>Position Details of Faculty & Staff in the College</h6>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="4"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    {" "}
                    Teaching Faculty
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th></th>
                  <th colSpan="4">Professor</th>
                  <th colSpan="4">Associate Professor</th>
                  <th colSpan="4">Assistant Professor</th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th></th>
                  <th>male</th>
                  <th>female</th>
                  <th>other</th>
                  <th>Total</th>
                  <th>male</th>
                  <th>female</th>
                  <th>other</th>
                  <th>Total</th>
                  <th>male</th>
                  <th>female</th>
                  <th>other</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sanctioned by the UGC /University State Government</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(professorMale1) +
                      Number(professorFemale1) +
                      Number(professorOther1) +
                      Number(professorYTR1)}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(AssoProfessorMale1) +
                      Number(AssoProfessorFemale1) +
                      Number(AssoProfessorOther1) +
                      Number(AssoProfessorYTR1)}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(AssisProfessorMale1) +
                      Number(AssisProfessorFemale1) +
                      Number(AssisProfessorOther1) +
                      Number(AssisProfessorYTR1)}
                  </td>
                </tr>
                <tr>
                  <td>Recruited</td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={professorMale1}
                      onChange={(e) => setprofessorMale1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={professorFemale1}
                      onChange={(e) => setprofessorFemale1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={professorOther1}
                      onChange={(e) => setprofessorOther1(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(professorMale1) +
                      Number(professorFemale1) +
                      Number(professorOther1)}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssoProfessorMale1}
                      onChange={(e) => setAssoProfessorMale1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssoProfessorFemale1}
                      onChange={(e) => setAssoProfessorFemale1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssoProfessorOther1}
                      onChange={(e) => setAssoProfessorOther1(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(AssoProfessorMale1) +
                      Number(AssoProfessorFemale1) +
                      Number(AssoProfessorOther1)}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssisProfessorMale1}
                      onChange={(e) => setAssisProfessorMale1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssisProfessorFemale1}
                      onChange={(e) => setAssisProfessorFemale1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssisProfessorOther1}
                      onChange={(e) => setAssisProfessorOther1(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(AssisProfessorMale1) +
                      Number(AssisProfessorFemale1) +
                      Number(AssisProfessorOther1)}
                  </td>
                </tr>
                <tr>
                  <td>Yet to Recruit</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={professorYTR1}
                      onChange={(e) => setprofessorYTR1(e.target.value)}
                    />
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssoProfessorYTR1}
                      onChange={(e) => setAssoProfessorYTR1(e.target.value)}
                    />
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssisProfessorYTR1}
                      onChange={(e) => setAssisProfessorYTR1(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Sanctioned by the Management/Society or Other Authorized
                    Bodies
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(professorMale2) +
                      Number(professorFemale2) +
                      Number(professorOther2) +
                      Number(professorYTR2)}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(AssoProfessorMale2) +
                      Number(AssoProfessorFemale2) +
                      Number(AssoProfessorOther2) +
                      Number(AssoProfessorYTR2)}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(AssisProfessorMale2) +
                      Number(AssisProfessorFemale2) +
                      Number(AssisProfessorOther2) +
                      Number(AssisProfessorYTR2)}
                  </td>
                </tr>
                <tr>
                  <td>Recruited</td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={professorMale2}
                      onChange={(e) => setprofessorMale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={professorFemale2}
                      onChange={(e) => setprofessorFemale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={professorOther2}
                      onChange={(e) => setprofessorOther2(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(professorMale2) +
                      Number(professorFemale2) +
                      Number(professorOther2)}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssoProfessorMale2}
                      onChange={(e) => setAssoProfessorMale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssoProfessorFemale2}
                      onChange={(e) => setAssoProfessorFemale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssoProfessorOther2}
                      onChange={(e) => setAssoProfessorOther2(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(AssoProfessorMale2) +
                      Number(AssoProfessorFemale2) +
                      Number(AssoProfessorOther2)}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssisProfessorMale2}
                      onChange={(e) => setAssisProfessorMale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssisProfessorFemale2}
                      onChange={(e) => setAssisProfessorFemale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssisProfessorOther2}
                      onChange={(e) => setAssisProfessorOther2(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(AssisProfessorMale2) +
                      Number(AssisProfessorFemale2) +
                      Number(AssisProfessorOther2)}
                  </td>
                </tr>
                <tr>
                  <td>Yet to Recruit</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={professorYTR2}
                      onChange={(e) => setprofessorYTR2(e.target.value)}
                    />
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssoProfessorYTR2}
                      onChange={(e) => setAssoProfessorYTR2(e.target.value)}
                    />
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={AssisProfessorYTR2}
                      onChange={(e) => setAssisProfessorYTR2(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped border>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="5"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Non-Teaching Staff
                  </th>
                </tr>
              </thead>
              <thead>
                <th></th>
                <th>Male</th>
                <th>Female</th>
                <th>Others</th>
                <th>Total</th>
              </thead>
              <tbody>
                <tr>
                  <td>Sanctioned by the UGC /University State Government</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(NTSMale1) +
                      Number(NTSFemale1) +
                      Number(NTSOther1) +
                      Number(NTSYTR1)}
                  </td>
                </tr>
                <tr>
                  <td>Recruited </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={NTSMale1}
                      onChange={(e) => setNTSMale1(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={NTSFemale1}
                      onChange={(e) => setNTSFemale1(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={NTSOther1}
                      onChange={(e) => setNTSOther1(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    {Number(NTSMale1) + Number(NTSFemale1) + Number(NTSOther1)}
                  </td>
                </tr>
                <tr>
                  <td>Yet to Recruit </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={NTSYTR1}
                      onChange={(e) => setNTSYTR1(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    Sanctioned by the Management/Society or Other Authorized
                    Bodies
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(NTSMale2) +
                      Number(NTSFemale2) +
                      Number(NTSOther2) +
                      Number(NTSYTR2)}
                  </td>
                </tr>
                <tr>
                  <td>Recruited </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={NTSMale2}
                      onChange={(e) => setNTSMale2(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={NTSFemale2}
                      onChange={(e) => setNTSFemale2(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={NTSOther2}
                      onChange={(e) => setNTSOther2(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    {Number(NTSMale2) + Number(NTSFemale2) + Number(NTSOther2)}
                  </td>
                </tr>
                <tr>
                  <td>Yet to Recruit </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={NTSYTR2}
                      onChange={(e) => setNTSYTR2(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <br />
      </Container>
      <Container
        style={{ backgroundColor: "white", width: "80%", paddingTop: "10px" }}
        className="mt-3"
      >
        <div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="5"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Technical Staff
                  </th>
                </tr>
              </thead>
              <thead>
                <th></th>
                <th>Male</th>
                <th>Female</th>
                <th>Others</th>
                <th>Total</th>
              </thead>
              <tbody>
                <tr>
                  <td>Sanctioned by the UGC /University State Government</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(TSMale1) +
                      Number(TSFemale1) +
                      Number(TSOther1) +
                      Number(TSYTR1)}
                  </td>
                </tr>
                <tr>
                  <td>Recruited </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={TSMale1}
                      onChange={(e) => setTSMale1(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={TSFemale1}
                      onChange={(e) => setTSFemale1(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={TSOther1}
                      onChange={(e) => setTSOther1(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    {Number(TSMale1) + Number(TSFemale1) + Number(TSOther1)}
                  </td>
                </tr>
                <tr>
                  <td>Yet to Recruit </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={TSYTR1}
                      onChange={(e) => setTSYTR1(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    Sanctioned by the Management/Society or Other Authorized
                    Bodies
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {Number(TSMale2) +
                      Number(TSFemale2) +
                      Number(TSOther2) +
                      Number(TSYTR2)}
                  </td>
                </tr>
                <tr>
                  <td>Recruited </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={TSMale2}
                      onChange={(e) => setTSMale2(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={TSFemale2}
                      onChange={(e) => setTSFemale2(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={TSOther2}
                      onChange={(e) => setTSOther2(e.target.value)}
                    />
                  </td>
                  <td style={{ backgroundColor: "white", color: "black" }}>
                    {Number(TSMale2) + Number(TSFemale2) + Number(TSOther2)}
                  </td>
                </tr>
                <tr>
                  <td>Yet to Recruit </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={TSYTR2}
                      onChange={(e) => setTSYTR2(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="mt-2">
            <h6>Qualification Details of the Teaching Staff</h6>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="6"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Permanent Teachers
                  </th>
                </tr>
              </thead>
              <thead>
                <th>Highest Qualification</th>
                <th colSpan="3">Professor</th>
                <th colSpan="3">Associate Professor</th>
                <th colSpan="3">Assistant Professor</th>
                <th></th>
                <th></th>
              </thead>
              <thead>
                <th></th>
                <th>male</th>
                <th>female</th>
                <th>other</th>
                <th>male</th>
                <th>female</th>
                <th>other</th>
                <th>male</th>
                <th>female</th>
                <th>other</th>
                <th>Total</th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPhightestQ(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPmale1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      onChange={(e) => setPfemale1(e.target.value)}
                      aria-label="Default select example"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPother1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPmale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPfemale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPother2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPmale3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPfemale3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPother3(e.target.value)}
                    />
                  </td>

                  <td>
                    {Number(Pmale1) +
                      Number(Pfemale1) +
                      Number(Pother1) +
                      Number(Pmale2) +
                      Number(Pfemale2) +
                      Number(Pother2) +
                      Number(Pmale3) +
                      Number(Pfemale3) +
                      Number(Pother3)}
                  </td>
                  <td>
                    <Button onClick={PermanentTeacher}>Add</Button>
                  </td>
                </tr>

                {PermannentTech?.map((item, i) => {
                  return (
                    <tr>
                      <td>{item?.PhightestQ}</td>
                      <td>{item?.Pmale1}</td>
                      <td>{item?.Pfemale1}</td>
                      <td>{item?.Pother1}</td>
                      <td>{item?.Pmale2}</td>
                      <td>{item?.Pfemale2}</td>
                      <td>{item?.Pother2}</td>
                      <td>{item?.Pmale3}</td>
                      <td>{item?.Pfemale3}</td>
                      <td>{item?.Pother3}</td>
                      <td>
                        {Number(item?.Pmale1) +
                          Number(item?.Pfemale1) +
                          Number(item?.Pother1) +
                          Number(item?.Pmale2) +
                          Number(item?.Pfemale2) +
                          Number(item?.Pother2) +
                          Number(item?.Pmale3) +
                          Number(item?.Pfemale3) +
                          Number(item?.Pother3)}
                      </td>
                      <td>
                        <MdDelete
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => removeItem5(i)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="6"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Temporary Teachers
                  </th>
                </tr>
              </thead>
              <thead>
                <th>Highest Qualification</th>
                <th colSpan="3">Professor</th>
                <th colSpan="3">Associate Professor</th>
                <th colSpan="3">Assistant Professor</th>
                <th></th>
                <th></th>
              </thead>
              <thead>
                <th></th>
                <th>male</th>
                <th>female</th>
                <th>other</th>
                <th>male</th>
                <th>female</th>
                <th>other</th>
                <th>male</th>
                <th>female</th>
                <th>other</th>
                <th>Total</th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setThightestQ(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setTmale1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      onChange={(e) => setTfemale1(e.target.value)}
                      aria-label="Default select example"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setTother1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setTmale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setTfemale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setTother2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setTmale3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setTfemale3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setTother3(e.target.value)}
                    />
                  </td>

                  <td>
                    {Number(Tmale1) +
                      Number(Tfemale1) +
                      Number(Tother1) +
                      Number(Tmale2) +
                      Number(TPfemale2) +
                      Number(Tother2) +
                      Number(Tmale3) +
                      Number(Tfemale3) +
                      Number(Tother3)}
                  </td>
                  <td>
                    <Button onClick={TemporaryTeacher}>Add</Button>
                  </td>
                </tr>

                {TemporaryTech?.map((item, i) => {
                  return (
                    <tr>
                      <td>{item?.ThightestQ}</td>
                      <td>{item?.Tmale1}</td>
                      <td>{item?.Tfemale1}</td>
                      <td>{item?.Tother1}</td>
                      <td>{item?.Tmale2}</td>
                      <td>{item?.TPfemale2}</td>
                      <td>{item?.Tother2}</td>
                      <td>{item?.Tmale3}</td>
                      <td>{item?.Tfemale3}</td>
                      <td>{item?.Tother3}</td>
                      <td>
                        {Number(item?.Tmale1) +
                          Number(item?.Tfemale1) +
                          Number(item?.Tother1) +
                          Number(item?.Tmale2) +
                          Number(item?.TPfemale2) +
                          Number(item?.Tother2) +
                          Number(item?.Tmale3) +
                          Number(item?.Tfemale3) +
                          Number(item?.Tother3)}
                      </td>
                      <td>
                        <MdDelete
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => removeItem6(i)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="6"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Part Time Teachers
                  </th>
                </tr>
              </thead>
              <thead>
                <th>Highest Qualification</th>
                <th colSpan="3">Professor</th>
                <th colSpan="3">Associate Professor</th>
                <th colSpan="3">Assistant Professor</th>
                <th></th>
                <th></th>
              </thead>
              <thead>
                <th></th>
                <th>male</th>
                <th>female</th>
                <th>other</th>
                <th>male</th>
                <th>female</th>
                <th>other</th>
                <th>male</th>
                <th>female</th>
                <th>other</th>
                <th>Toatal</th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPThightestQ(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPTmale1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      onChange={(e) => setPTfemale1(e.target.value)}
                      aria-label="Default select example"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPTother1(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPTmale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPTfemale2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPTother2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPTmale3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPTfemale3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setPTother3(e.target.value)}
                    />
                  </td>

                  <td>
                    {Number(PTmale1) +
                      Number(PTfemale1) +
                      Number(PTother1) +
                      Number(PTmale2) +
                      Number(PTfemale2) +
                      Number(PTother2) +
                      Number(PTmale3) +
                      Number(PTfemale3) +
                      Number(PTother3)}
                  </td>
                  <td>
                    <Button onClick={PartTimeTeacher}>Add</Button>
                  </td>
                </tr>

                {PartTimeTech?.map((item, i) => {
                  return (
                    <tr>
                      <td>{item?.PThightestQ}</td>
                      <td>{item?.PTmale1}</td>
                      <td>{item?.PTfemale1}</td>
                      <td>{item?.PTother1}</td>
                      <td>{item?.PTmale2}</td>
                      <td>{item?.PTfemale2}</td>
                      <td>{item?.PTother2}</td>
                      <td>{item?.PTmale3}</td>
                      <td>{item?.PTfemale3}</td>
                      <td>{item?.PTother3}</td>
                      <td>
                        {Number(item?.PTmale1) +
                          Number(item?.PTfemale1) +
                          Number(item?.PTother1) +
                          Number(item?.PTmale2) +
                          Number(item?.PTfemale2) +
                          Number(item?.PTother2) +
                          Number(item?.PTmale3) +
                          Number(item?.PTfemale3) +
                          Number(item?.PTother3)}
                      </td>
                      <td>
                        <MdDelete
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => removeItem7(i)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <br />
      </Container>
      <Container
        style={{ backgroundColor: "white", width: "80%", paddingTop: "10px" }}
        className="mt-3"
      >
        <div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th
                    colspan="5"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Details of Visting/Guest Faculties
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Number of Visiting/Guest Faculty engaged with the college?
                  </td>
                  <td colSpan="1">Male</td>
                  <td colSpan="1">Female</td>
                  <td colSpan="1">Others</td>
                  <td colSpan="1">Total</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={detailsOfVisitingMale}
                      onChange={(e) => setdetailsOfVisitingMale(e.target.value)}
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={detailsOfVisitingFemale}
                      onChange={(e) =>
                        setdetailsOfVisitingFemale(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={detailsOfVisitingOther}
                      onChange={(e) =>
                        setdetailsOfVisitingOther(e.target.value)
                      }
                    />
                  </td>
                  <td>
                    {Number(detailsOfVisitingMale) +
                      Number(detailsOfVisitingFemale) +
                      Number(detailsOfVisitingOther)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="mt-2">
            <h6>
              Provide the Following Details of Students Enrolled in the College
              During the Current Academic Year
            </h6>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th colspan="2">Programme</th>
                  <th>From the State Where College is Located</th>
                  <th>From Other States of India</th>
                  <th>NRI Students</th>
                  <th>Foreign Students</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{ border: "2px solid" }}>
                <tr>
                  <td rowspan="3">
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      placeholder="UG"
                      onChange={(e) => setCourse(e.target.value)}
                    />
                  </td>

                  <td> Male </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCollegeStateM(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setOtherStateM(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setNRIstudentM(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setForeignStudentM(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(CollegeStateM) +
                      Number(OtherStateM) +
                      Number(NRIstudentM) +
                      Number(ForeignStudentM)}
                  </td>
                </tr>
                <tr>
                  <td> Female </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCollegeStateF(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setOtherStateF(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setNRIstudentF(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setForeignStudentF(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(CollegeStateF) +
                      Number(OtherStateF) +
                      Number(NRIstudentF) +
                      Number(ForeignStudentF)}
                  </td>
                </tr>
                <tr>
                  <td> Other </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCollegeStateO(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setOtherStateO(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setNRIstudentO(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setForeignStudentO(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(CollegeStateO) +
                      Number(OtherStateO) +
                      Number(NRIstudentO) +
                      Number(ForeignStudentO)}
                  </td>
                  <td>
                    <Button onClick={StudentsEnrolledAcedemic}>Add</Button>
                  </td>
                </tr>
              </tbody>
              <tbody style={{ border: "2px solid" }}>
                <tr>
                  <td rowspan="3">Certificate / Awareness</td>

                  <td> Male </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFCollegeStateM(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFOtherStateM(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFNRIstudentM(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFForeignStudentM(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(CFCollegeStateM) +
                      Number(CFOtherStateM) +
                      Number(CFNRIstudentM) +
                      Number(CFForeignStudentM)}
                  </td>
                </tr>
                <tr>
                  <td> Female </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFCollegeStateF(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFOtherStateF(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFNRIstudentF(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFForeignStudentF(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(CFCollegeStateF) +
                      Number(CFOtherStateF) +
                      Number(CFNRIstudentF) +
                      Number(CFForeignStudentF)}
                  </td>
                </tr>
                <tr>
                  <td> Other </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFCollegeStateO(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFOtherStateO(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFNRIstudentO(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setCFForeignStudentO(e.target.value)}
                    />
                  </td>
                  <td>
                    {Number(CFCollegeStateO) +
                      Number(CFOtherStateO) +
                      Number(CFNRIstudentO) +
                      Number(CFForeignStudentO)}
                  </td>
                </tr>
              </tbody>

              {StudentsEnrolled?.map((item, i) => {
                return (
                  <>
                    <tbody style={{ border: "2px solid" }}>
                      <tr>
                        <td rowspan="3">{item?.Course}</td>

                        <td> Male </td>
                        <td>{item?.CollegeStateM}</td>
                        <td>{item?.OtherStateM}</td>
                        <td>{item?.NRIstudentM}</td>
                        <td>{item?.ForeignStudentM}</td>
                        <td>
                          {Number(item?.CollegeStateM) +
                            Number(item?.OtherStateM) +
                            Number(item?.NRIstudentM) +
                            Number(item?.ForeignStudentM)}
                        </td>
                      </tr>
                      <tr>
                        <td> Female </td>
                        <td>{item?.CollegeStateF}</td>
                        <td>{item?.OtherStateF}</td>
                        <td>{item?.NRIstudentF}</td>
                        <td>{item?.ForeignStudentF}</td>
                        <td>
                          {Number(item?.CollegeStateF) +
                            Number(item?.OtherStateF) +
                            Number(item?.NRIstudentF) +
                            Number(item?.ForeignStudentF)}
                        </td>
                      </tr>
                      <tr>
                        <td> Other </td>
                        <td>{item?.CollegeStateO}</td>
                        <td>{item?.OtherStateO}</td>
                        <td>{item?.NRIstudentO}</td>
                        <td>{item?.ForeignStudentO}</td>
                        <td>
                          {Number(item?.CollegeStateO) +
                            Number(item?.OtherStateO) +
                            Number(item?.NRIstudentO) +
                            Number(item?.ForeignStudentO)}
                        </td>
                        <td>
                          <MdDelete
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => removeItem9(i)}
                          />
                        </td>
                      </tr>
                    </tbody>
                    <tbody style={{ border: "2px solid" }}>
                      <tr>
                        <td rowspan="3">Certificate / Awareness</td>

                        <td> Male </td>
                        <td>{item?.CFCollegeStateM}</td>
                        <td>{item?.CFOtherStateM}</td>
                        <td>{item?.CFNRIstudentM}</td>
                        <td>{item?.CFForeignStudentM}</td>
                        <td>
                          {Number(item?.CFCollegeStateM) +
                            Number(item?.CFOtherStateM) +
                            Number(item?.CFNRIstudentM) +
                            Number(item?.CFForeignStudentM)}
                        </td>
                      </tr>
                      <tr>
                        <td> Female </td>
                        <td>{item?.CFCollegeStateF}</td>
                        <td>{item?.CFOtherStateF}</td>
                        <td>{item?.CFNRIstudentF}</td>
                        <td>{item?.CFForeignStudentF}</td>
                        <td>
                          {Number(item?.CFCollegeStateF) +
                            Number(item?.CFOtherStateF) +
                            Number(item?.CFNRIstudentF) +
                            Number(item?.CFForeignStudentF)}
                        </td>
                      </tr>
                      <tr>
                        <td> Other </td>
                        <td>{item?.CFCollegeStateO}</td>
                        <td>{item?.CFOtherStateO}</td>
                        <td>{item?.CFNRIstudentO}</td>
                        <td>{item?.CFForeignStudentO}</td>
                        <td>
                          {Number(item?.CFCollegeStateO) +
                            Number(item?.CFOtherStateO) +
                            Number(item?.CFNRIstudentO) +
                            Number(item?.CFForeignStudentO)}
                        </td>
                      </tr>
                    </tbody>
                  </>
                );
              })}
            </Table>
          </div>
          <div
            className=" mt-2 d-flex"
            style={{ border: "2px solid black", padding: "10px" }}
          >
            <Table striped>
              <thead>
                <th colspan="6">
                  Provide the Following Details of Students admitted to the
                  College During the last four Academic Years
                </th>
              </thead>
              <thead>
                <tr style={{ backgroundColor: "white", color: "black" }}>
                  <th colspan="2">Programme</th>
                  <th>Year 1 </th>
                  <th>Year 2</th>
                  <th>Year 3</th>
                  <th>Year 4</th>
                </tr>
              </thead>
              <tbody className="mt-1" style={{ border: "2px solid" }}>
                <tr>
                  <td rowspan="3">SC</td>

                  <td> Male </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scMaleYear1}
                      onChange={(e) => setscMaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scMaleYear2}
                      onChange={(e) => setscMaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scMaleYear3}
                      onChange={(e) => setscMaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scMaleYear4}
                      onChange={(e) => setscMaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Female </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scFemaleYear1}
                      onChange={(e) => setscFemaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scFemaleYear2}
                      onChange={(e) => setscFemaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scFemaleYear3}
                      onChange={(e) => setscFemaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scFemaleYear4}
                      onChange={(e) => setscFemaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Other </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scOtherYear1}
                      onChange={(e) => setscOtherYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scOtherYear2}
                      onChange={(e) => setscOtherYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scOtherYear3}
                      onChange={(e) => setscOtherYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={scOtherYear4}
                      onChange={(e) => setscOtherYear4(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody className="mt-1" style={{ border: "2px solid" }}>
                <tr>
                  <td rowspan="3">ST</td>

                  <td> Male </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stMaleYear1}
                      onChange={(e) => setstMaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stMaleYear2}
                      onChange={(e) => setstMaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stMaleYear3}
                      onChange={(e) => setstMaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stMaleYear4}
                      onChange={(e) => setstMaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Female </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stFemaleYear1}
                      onChange={(e) => setstFemaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stFemaleYear2}
                      onChange={(e) => setstFemaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stFemaleYear3}
                      onChange={(e) => setstFemaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stFemaleYear4}
                      onChange={(e) => setstFemaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Other </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stOtherYear1}
                      onChange={(e) => setstOtherYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stOtherYear2}
                      onChange={(e) => setstOtherYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stOtherYear3}
                      onChange={(e) => setstOtherYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={stOtherYear4}
                      onChange={(e) => setstOtherYear4(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody className="mt-1" style={{ border: "2px solid" }}>
                <tr>
                  <td rowspan="3">OBC</td>

                  <td> Male </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcMaleYear1}
                      onChange={(e) => setobcMaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcMaleYear2}
                      onChange={(e) => setobcMaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcMaleYear3}
                      onChange={(e) => setobcMaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcMaleYear4}
                      onChange={(e) => setobcMaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Female </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcFemaleYear1}
                      onChange={(e) => setobcFemaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcFemaleYear2}
                      onChange={(e) => setobcFemaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcFemaleYear3}
                      onChange={(e) => setobcFemaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcFemaleYear4}
                      onChange={(e) => setobcFemaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Other </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcOtherYear1}
                      onChange={(e) => setobcOtherYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcOtherYear2}
                      onChange={(e) => setobcOtherYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcOtherYear3}
                      onChange={(e) => setobcOtherYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={obcOtherYear4}
                      onChange={(e) => setobcOtherYear4(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody className="mt-1" style={{ border: "2px solid" }}>
                <tr>
                  <td rowspan="3">General</td>

                  <td> Male </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genMaleYear1}
                      onChange={(e) => setgenMaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genMaleYear2}
                      onChange={(e) => setgenMaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genMaleYear3}
                      onChange={(e) => setgenMaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genMaleYear4}
                      onChange={(e) => setgenMaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Female </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genFemaleYear1}
                      onChange={(e) => setgenFemaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genFemaleYear2}
                      onChange={(e) => setgenFemaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genFemaleYear3}
                      onChange={(e) => setgenFemaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genFemaleYear4}
                      onChange={(e) => setgenFemaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Other </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genOtherYear1}
                      onChange={(e) => setgenOtherYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genOtherYear2}
                      onChange={(e) => setgenOtherYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genOtherYear3}
                      onChange={(e) => setgenOtherYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={genOtherYear4}
                      onChange={(e) => setgenOtherYear4(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody className="mt-1" style={{ border: "2px solid" }}>
                <tr>
                  <td rowspan="3">Other</td>

                  <td> Male </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherMaleYear1}
                      onChange={(e) => setotherMaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherMaleYear2}
                      onChange={(e) => setotherMaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherMaleYear3}
                      onChange={(e) => setotherMaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherMaleYear4}
                      onChange={(e) => setotherMaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Female </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherFemaleYear1}
                      onChange={(e) => setotherFemaleYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherFemaleYear2}
                      onChange={(e) => setotherFemaleYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherFemaleYear3}
                      onChange={(e) => setotherFemaleYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherFemaleYear4}
                      onChange={(e) => setotherFemaleYear4(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td> Other </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherOtherYear1}
                      onChange={(e) => setotherOtherYear1(e.target.value)}
                    />{" "}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherOtherYear2}
                      onChange={(e) => setotherOtherYear2(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherOtherYear3}
                      onChange={(e) => setotherOtherYear3(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={otherOtherYear4}
                      onChange={(e) => setotherOtherYear4(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody className="mt-1" style={{ border: "2px solid" }}>
                <tr>
                  <td>Total</td>

                  <td></td>
                  <td>
                    {" "}
                    {Number(scMaleYear1) +
                      Number(scFemaleYear1) +
                      Number(scOtherYear1) +
                      Number(stMaleYear1) +
                      Number(stFemaleYear1) +
                      Number(stOtherYear1) +
                      Number(obcMaleYear1) +
                      Number(obcFemaleYear1) +
                      Number(obcOtherYear1) +
                      Number(genMaleYear1) +
                      Number(genFemaleYear1) +
                      Number(genOtherYear1) +
                      Number(otherMaleYear1) +
                      Number(otherFemaleYear1) +
                      Number(otherOtherYear1)}{" "}
                  </td>
                  <td>
                    {Number(scMaleYear2) +
                      Number(scFemaleYear2) +
                      Number(scOtherYear2) +
                      Number(stMaleYear2) +
                      Number(stFemaleYear2) +
                      Number(stOtherYear2) +
                      Number(obcMaleYear2) +
                      Number(obcFemaleYear2) +
                      Number(obcOtherYear2) +
                      Number(genMaleYear2) +
                      Number(genFemaleYear2) +
                      Number(genOtherYear2) +
                      Number(otherMaleYear2) +
                      Number(otherFemaleYear2) +
                      Number(otherOtherYear2)}
                  </td>
                  <td>
                    {Number(scMaleYear3) +
                      Number(scFemaleYear3) +
                      Number(scOtherYear3) +
                      Number(stMaleYear3) +
                      Number(stFemaleYear3) +
                      Number(stOtherYear3) +
                      Number(obcMaleYear3) +
                      Number(obcFemaleYear3) +
                      Number(obcOtherYear3) +
                      Number(genMaleYear3) +
                      Number(genFemaleYear3) +
                      Number(genOtherYear3) +
                      Number(otherMaleYear3) +
                      Number(otherFemaleYear3) +
                      Number(otherOtherYear3)}
                  </td>
                  <td>
                    {Number(scMaleYear4) +
                      Number(scFemaleYear4) +
                      Number(scOtherYear4) +
                      Number(stMaleYear4) +
                      Number(stFemaleYear4) +
                      Number(stOtherYear4) +
                      Number(obcMaleYear4) +
                      Number(obcFemaleYear4) +
                      Number(obcOtherYear4) +
                      Number(genMaleYear4) +
                      Number(genFemaleYear4) +
                      Number(genOtherYear4) +
                      Number(otherMaleYear4) +
                      Number(otherFemaleYear4) +
                      Number(otherOtherYear4)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <br />
      </Container>
      <Container
        style={{ backgroundColor: "white", width: "80%" }}
        className="mt-3"
      >
        <div>
          <div
            className=" mt-3 d-flex justify-content-start"
            style={{ borderBottom: "1px solid" }}
          >
            <h5 className="mt-4">
              <b>Extended Profile</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>1 Program</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>1.1</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>
                Number of courses offered by the Institution across all programs
                during the last five years
              </b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <tbody style={{ border: "2px solid black" }}>
                <tr>
                  <td>
                    {Number(startYearReport1) + 4}-
                    {Number(startYearReport1) + 5}
                  </td>
                  <td>
                    {Number(startYearReport1) + 3}-
                    {Number(startYearReport1) + 4}
                  </td>
                  <td>
                    {Number(startYearReport1) + 2}-
                    {Number(startYearReport1) + 3}
                  </td>
                  <td>
                    {Number(startYearReport1) + 1}-
                    {Number(startYearReport1) + 2}
                  </td>
                  <td>
                    {startYearReport1}-{Number(startYearReport1) + 1}
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep1Year1Val}
                      onChange={(e) => {
                        setep1Year1Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep1Year2Val}
                      onChange={(e) => {
                        setep1Year2Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep1Year3Val}
                      onChange={(e) => {
                        setep1Year3Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep1Year4Val}
                      onChange={(e) => {
                        setep1Year4Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep1Year5Val}
                      onChange={(e) => {
                        setep1Year5Val(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <thead>
                <tr>
                  <th>File Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={fileDesc11}
                      onChange={(e) => setfileDesc11(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setDoc11(e.target.files[0])}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>1.2</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>Number of programs offered year-wise for last five years</b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <tbody style={{ border: "2px solid black" }}>
                <tr>
                  <td>
                    {Number(startYearReport1) + 4}-
                    {Number(startYearReport1) + 5}
                  </td>
                  <td>
                    {Number(startYearReport1) + 3}-
                    {Number(startYearReport1) + 4}
                  </td>
                  <td>
                    {Number(startYearReport1) + 2}-
                    {Number(startYearReport1) + 3}
                  </td>
                  <td>
                    {Number(startYearReport1) + 1}-
                    {Number(startYearReport1) + 2}
                  </td>
                  <td>
                    {startYearReport1}-{Number(startYearReport1) + 1}
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep12Year1Val}
                      onChange={(e) => {
                        setep12Year1Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep12Year2Val}
                      onChange={(e) => {
                        setep12Year2Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep12Year3Val}
                      onChange={(e) => {
                        setep12Year3Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep12Year4Val}
                      onChange={(e) => {
                        setep12Year4Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep12Year5Val}
                      onChange={(e) => {
                        setep12Year5Val(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>2 Students</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>2.1</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>Number of students year-wise during last five years</b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <tbody style={{ border: "2px solid black" }}>
                <tr>
                  <td>
                    {Number(startYearReport1) + 4}-
                    {Number(startYearReport1) + 5}
                  </td>
                  <td>
                    {Number(startYearReport1) + 3}-
                    {Number(startYearReport1) + 4}
                  </td>
                  <td>
                    {Number(startYearReport1) + 2}-
                    {Number(startYearReport1) + 3}
                  </td>
                  <td>
                    {Number(startYearReport1) + 1}-
                    {Number(startYearReport1) + 2}
                  </td>
                  <td>
                    {startYearReport1}-{Number(startYearReport1) + 1}
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep21Year1Val}
                      onChange={(e) => {
                        setep21Year1Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep21Year2Val}
                      onChange={(e) => {
                        setep21Year2Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep21Year3Val}
                      onChange={(e) => {
                        setep21Year3Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep21Year4Val}
                      onChange={(e) => {
                        setep21Year4Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep21Year5Val}
                      onChange={(e) => {
                        setep21Year5Val(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <thead>
                <tr>
                  <th>File Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={fileDesc21}
                      onChange={(e) => setfileDesc21(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setDoc21(e.target.files[0])}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>2.2</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>
                Number of seats earmarked for reserved category as per GOI/State
                Govt rule year-wise during last five years
              </b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <tbody style={{ border: "2px solid black" }}>
                <tr>
                  <td>
                    {Number(startYearReport1) + 4}-
                    {Number(startYearReport1) + 5}
                  </td>
                  <td>
                    {Number(startYearReport1) + 3}-
                    {Number(startYearReport1) + 4}
                  </td>
                  <td>
                    {Number(startYearReport1) + 2}-
                    {Number(startYearReport1) + 3}
                  </td>
                  <td>
                    {Number(startYearReport1) + 1}-
                    {Number(startYearReport1) + 2}
                  </td>
                  <td>
                    {startYearReport1}-{Number(startYearReport1) + 1}
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep22Year1Val}
                      onChange={(e) => {
                        setep22Year1Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep22Year2Val}
                      onChange={(e) => {
                        setep22Year2Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep22Year3Val}
                      onChange={(e) => {
                        setep22Year3Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep22Year4Val}
                      onChange={(e) => {
                        setep22Year4Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep22Year5Val}
                      onChange={(e) => {
                        setep22Year5Val(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <thead>
                <tr>
                  <th>File Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={fileDesc22}
                      onChange={(e) => setfileDesc22(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setDoc22(e.target.files[0])}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>2.3</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>
                Number of outgoing / final year students year-wise during last
                five years
              </b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <tbody style={{ border: "2px solid black" }}>
                <tr>
                  <td>
                    {Number(startYearReport1) + 4}-
                    {Number(startYearReport1) + 5}
                  </td>
                  <td>
                    {Number(startYearReport1) + 3}-
                    {Number(startYearReport1) + 4}
                  </td>
                  <td>
                    {Number(startYearReport1) + 2}-
                    {Number(startYearReport1) + 3}
                  </td>
                  <td>
                    {Number(startYearReport1) + 1}-
                    {Number(startYearReport1) + 2}
                  </td>
                  <td>
                    {startYearReport1}-{Number(startYearReport1) + 1}
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep23Year1Val}
                      onChange={(e) => {
                        setep23Year1Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep23Year2Val}
                      onChange={(e) => {
                        setep23Year2Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep23Year3Val}
                      onChange={(e) => {
                        setep23Year3Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep23Year4Val}
                      onChange={(e) => {
                        setep23Year4Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep23Year5Val}
                      onChange={(e) => {
                        setep23Year5Val(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <thead>
                <tr>
                  <th>File Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={fileDesc23}
                      onChange={(e) => setfileDesc23(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setDoc23(e.target.files[0])}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <br />
        </div>
      </Container>
      <Container
        style={{ backgroundColor: "white", width: "80%" }}
        className="mt-3"
      >
        <div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>3 Teachers</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>3.1</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>
                Number of full time teachers year-wise during the last five
                years
              </b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <tbody style={{ border: "2px solid black" }}>
                <tr>
                  <td>
                    {Number(startYearReport1) + 4}-
                    {Number(startYearReport1) + 5}
                  </td>
                  <td>
                    {Number(startYearReport1) + 3}-
                    {Number(startYearReport1) + 4}
                  </td>
                  <td>
                    {Number(startYearReport1) + 2}-
                    {Number(startYearReport1) + 3}
                  </td>
                  <td>
                    {Number(startYearReport1) + 1}-
                    {Number(startYearReport1) + 2}
                  </td>
                  <td>
                    {startYearReport1}-{Number(startYearReport1) + 1}
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep31Year1Val}
                      onChange={(e) => {
                        setep31Year1Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep31Year2Val}
                      onChange={(e) => {
                        setep31Year2Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep31Year3Val}
                      onChange={(e) => {
                        setep31Year3Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep31Year4Val}
                      onChange={(e) => {
                        setep31Year4Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep31Year5Val}
                      onChange={(e) => {
                        setep31Year5Val(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <thead>
                <tr>
                  <th>File Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={fileDesc31}
                      onChange={(e) => setfileDesc31(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setDoc31(e.target.files[0])}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>3.2</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>Number of sanctioned posts year-wise during last five years</b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <tbody style={{ border: "2px solid black" }}>
                <tr>
                  <td>
                    {Number(startYearReport1) + 4}-
                    {Number(startYearReport1) + 5}
                  </td>
                  <td>
                    {Number(startYearReport1) + 3}-
                    {Number(startYearReport1) + 4}
                  </td>
                  <td>
                    {Number(startYearReport1) + 2}-
                    {Number(startYearReport1) + 3}
                  </td>
                  <td>
                    {Number(startYearReport1) + 1}-
                    {Number(startYearReport1) + 2}
                  </td>
                  <td>
                    {startYearReport1}-{Number(startYearReport1) + 1}
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep32Year1Val}
                      onChange={(e) => {
                        setep32Year1Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep32Year2Val}
                      onChange={(e) => {
                        setep32Year2Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep32Year3Val}
                      onChange={(e) => {
                        setep32Year3Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep32Year4Val}
                      onChange={(e) => {
                        setep32Year4Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep32Year5Val}
                      onChange={(e) => {
                        setep32Year5Val(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <thead>
                <tr>
                  <th>File Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={fileDesc32}
                      onChange={(e) => setfileDesc32(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setDoc32(e.target.files[0])}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>4 Institution</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>4.1</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>Total number of classrooms and seminar halls</b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>
                Response:{" "}
                <input
                  type="text"
                  className="vi_0"
                  aria-label="Default select example"
                  value={res41}
                  onChange={(e) => {
                    setres41(e.target.value);
                  }}
                />
              </b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>4.2</b>
            </h6>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h6>
              <b>
                Total Expenditure excluding salary year-wise during last five
                years ( INR in Lakhs)
              </b>
            </h6>
          </div>

          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <tbody style={{ border: "2px solid black" }}>
                <tr>
                  <td>
                    {Number(startYearReport1) + 4}-
                    {Number(startYearReport1) + 5}
                  </td>
                  <td>
                    {Number(startYearReport1) + 3}-
                    {Number(startYearReport1) + 4}
                  </td>
                  <td>
                    {Number(startYearReport1) + 2}-
                    {Number(startYearReport1) + 3}
                  </td>
                  <td>
                    {Number(startYearReport1) + 1}-
                    {Number(startYearReport1) + 2}
                  </td>
                  <td>
                    {startYearReport1}-{Number(startYearReport1) + 1}
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep42Year1Val}
                      onChange={(e) => {
                        setep42Year1Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep42Year2Val}
                      onChange={(e) => {
                        setep42Year2Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep42Year3Val}
                      onChange={(e) => {
                        setep42Year3Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep42Year4Val}
                      onChange={(e) => {
                        setep42Year4Val(e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      value={ep42Year5Val}
                      onChange={(e) => {
                        setep42Year5Val(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>4.3</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>Number of Computers</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>
                Response:{" "}
                <input
                  type="text"
                  className="vi_0"
                  aria-label="Default select example"
                  value={res43}
                  onChange={(e) => {
                    setres43(e.target.value);
                  }}
                />
              </b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>4.4</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>
                Total number of computers in the campus for academic purpose
              </b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>
                Response:
                <input
                  type="text"
                  className="vi_0"
                  aria-label="Default select example"
                  value={res44}
                  onChange={(e) => {
                    setres44(e.target.value);
                  }}
                />
              </b>
            </h5>
          </div>

          <br />
        </div>
      </Container>

      <Container
        style={{ backgroundColor: "white", width: "80%" }}
        className="mt-3"
      >
        <div>
          <div
            className=" mt-3 d-flex justify-content-start"
            style={{ borderBottom: "1px solid" }}
          >
            <h5 className="mt-4">
              <b>5. CONCLUSION</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>Additional Information :</b>
            </h5>
          </div>

          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={AddInfoCon5}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAddInfoCon5(data);
              }}
            />
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>Concluding Remarks :</b>
            </h5>
          </div>

          <div className=" mt-3 d-flex ">
            <CKEditor
              editor={ClassicEditor}
              data={remarkCon5}
              onChange={(event, editor) => {
                const data = editor.getData();
                setremarkCon5(data);
              }}
            />
          </div>
        </div>
        <br />
      </Container>
      <Container
        style={{ backgroundColor: "white", width: "80%" }}
        className="mt-3"
      >
        <div>
          <div
            className=" mt-3 d-flex justify-content-start"
            style={{ borderBottom: "1px solid" }}
          >
            <h5 className="mt-4">
              <b>6.ANNEXURE</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <h5>
              <b>1.Metrics Level Deviations</b>
            </h5>
          </div>
          <div className=" mt-2 d-flex justify-content-start">
            <Table>
              <thead>
                <tr>
                  <th>Metric ID</th>
                  <th>
                    Sub Questions and Answers before and after DVV Verification
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="vi_0"
                      aria-label="Default select example"
                      onChange={(e) => setIndexId(e.target.value)}
                    />
                  </td>
                  <td>
                    <div className=" mt-3 d-flex ">
                      <CKEditor
                        editor={ClassicEditor}
                        data={ANXDesc}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setANXDesc(data);
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <Button onClick={Annexure}>ADD</Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {AnnuxtureArr?.map((item, i) => {
                  return (
                    <tr style={{ border: "2px solid" }}>
                      <td style={{ border: "1px solid" }}>{item?.IndexId}</td>
                      <td>{parseHTML(`<div>${item?.ANXDesc}</div>`)}</td>
                      <td>
                        <MdDelete
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => removeItem8(i)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <br />
      </Container>
      <div style={{ textAlign: "center" }}>
        <Button onClick={AddSSR}>Add</Button>
      </div>
    </div>
  );
}

export default SSRAddRepoprt;
