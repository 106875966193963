import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";

function UG_06_612() {
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [selectedYear, setselectedYear] = useState("");
  const [content, setContent] = useState([]);
  const [View, setView] = useState([]);
  console.log("View", View);
  console.log("content", content);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  // ================Show doc4 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  // ================Show doc4 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ================date filter=====================

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  // ================date filter=====================

  const navigate = useNavigate();
  const [Value, setValue] = useState({});
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("UG_Criteria(6.2.3)");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          //  Criteria 06 6.2.3

          Year: item?.UGYear,
          Sub_Criteria1: item?.UGCriteria06_623?.criteria,
          "Administration Year of implementation": item?.UGCriteria06_623?.yoi1,
          "Document 1": item?.UGCriteria06_623?.doc1,

          "Finance and Accounts Year of implementation":
            item?.UGCriteria06_623?.yoi2,
          "Document 2": item?.UGCriteria06_623?.doc2,

          " Student Admissions and Support Year of implementation":
            item?.UGCriteria06_623?.yoi3,
          "Document 3": item?.UGCriteria06_623?.doc3,
          "Examinations Year of implementation": item?.UGCriteria06_623?.yoi4,
          "Document 4": item?.UGCriteria06_623?.doc4,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [filterPdf, setfilterPdf] = useState([]);
  let [sendData, setsendData] = useState([]);
  const filterYearPdf = () => {
    sendData = filterPdf?.filter((item1) => {
      if (
        pricipalDetails?._id === item1?.addedByPri &&
        item1?.approve === "true" &&
        selectedYear === item1?.UGYear
      ) {
        return true;
      }
      return false;
    });
    sendData.push({ criteria: 6 });
  };

  //  ===========================================================================================

  const getContent = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/ugadmin/Ugcri06_612"
      );
      if (res.status === 200) {
        setContent(res.data.getUG_C612);
        setdata1(res.data.getUG_C612);
        setfilterPdf(res.data.getUG_C612);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      getContent();
      getAllYears();
    }
  }, []);
  console.log("content", content);

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      filterYearPdf();
    }
  }, [selectedYear]);

  console.log("principal", pricipalDetails?._id);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-06-612
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>

            <div className=" col-lg-2 text-end ">
              <Button className="btn-success" onClick={ExportToExcel}>
                Export Excel
              </Button>
            </div>

            <div className=" col-lg-2 lab text-end ">
              <Button
                className="btn-success"
                onClick={() => {
                  let arr1 = content?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.UGYear?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id === item1?.addedByPri &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.UGYear &&
                      (SearchItem === "" ||
                        item1?.UGCriteria06_611?.criteria?.includes(
                          SearchItem
                        ) ||
                        item1?.UGYear?.includes(SearchItem) ||
                        item1?.UGCriteria06_611?.ParticipativeMng?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_611?.AcadamicMont?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_611?.OficeOrder?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_611?.Decentralisation1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_611?.Decentralisation2?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_611?.Decentralisation3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_611?.AcadamicMont1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  });

                  if (arr1?.length > 0) {
                    navigate("/PDFprintUG6_612", { state: sendData });
                  } else {
                    alert("No Data available!!!");
                  }
                }}
              >
                Report
              </Button>
            </div>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4 align-items-center">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform vi_0"
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="col-lg-9">
            <Nav defaultActiveKey="/UG_06_623">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UG_06_611")}
                >
                  <Button className="criteriabtn btn-sub">6.1.1</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UG_06_623")}
                >
                  <Button
                    variant="warning"
                    style={{ backgroundColor: "#ffc107" }}
                    className="criteriabtn btn-sub"
                  >
                    6.2.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UG_06_632")}
                >
                  <Button className="criteriabtn btn-sub">6.3.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UG_06_633")}
                >
                  {" "}
                  <Button className="criteriabtn btn-sub">6.3.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-4"
                  onClick={() => navigate("/UG_06_634")}
                >
                  {" "}
                  <Button className="criteriabtn btn-sub">6.3.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UG_06_642")}
                >
                  <Button className="criteriabtn btn-sub">6.4.2</Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UG_06_653")}
                >
                  <Button className="criteriabtn btn-sub">6.5.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Participative management</th>
                  <th>Acadamic monitoring committee</th>
                  <th>Office Order</th>
                  <th>Decentralisation_20-21</th>
                  <th>Decentralisation_21-22</th>
                  <th>Decentralisation_2022-23</th>
                  <th>Academic Monitoring Committee_2022-23</th>
                </tr>
              </thead>
              <tbody>
                {content
                  ?.filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.UGYear?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      pricipalDetails?._id === item1?.addedByPri &&
                      item1?.approve === "true" &&
                      selectedYear === item1?.UGYear &&
                      (SearchItem === "" ||
                        item1?.UGCriteria06_612?.criteria?.includes(
                          SearchItem
                        ) ||
                        item1?.UGYear?.includes(SearchItem) ||
                        item1?.UGCriteria06_612?.ParticipativeMng?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_612?.AcadamicMont?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_612?.OficeOrder?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_612?.Decentralisation1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_612?.Decentralisation2?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_612?.Decentralisation3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.UGCriteria06_612?.AcadamicMont1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?.UGYear}</td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              handleShow1();
                              setView(item);
                            }}
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              handleShow2();
                              setView(item);
                            }}
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              handleShow3();
                              setView(item);
                            }}
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              handleShow4();
                              setView(item);
                            }}
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              handleShow6();
                              setView(item);
                            }}
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              handleShow7();
                              setView(item);
                            }}
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        </td>
                        <td>
                          <AiOutlineEye
                            onClick={() => {
                              handleShow8();
                              setView(item);
                            }}
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {View?.UGCriteria06_612?.ParticipativeMng && (
                  <>
                    {View?.UGCriteria06_612?.ParticipativeMng.endsWith(
                      ".pdf"
                    ) ? (
                      <iframe
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.ParticipativeMng}`}
                        width="100%"
                        height="400"
                        frameborder="0"
                        allowfullscreen
                      >
                        Sorry, your browser doesn't support embedded PDFs.
                        <a
                          href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.ParticipativeMng}`}
                        >
                          Download the PDF
                        </a>{" "}
                        instead.
                      </iframe>
                    ) : (
                      <img
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.ParticipativeMng}`}
                        alt="Image"
                        width="100%"
                        height="auto"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {View?.UGCriteria06_612?.AcadamicMont && (
                  <>
                    {View?.UGCriteria06_612?.AcadamicMont.endsWith(".pdf") ? (
                      <iframe
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.AcadamicMont}`}
                        width="100%"
                        height="400"
                        frameborder="0"
                        allowfullscreen
                      >
                        Sorry, your browser doesn't support embedded PDFs.
                        <a
                          href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.AcadamicMont}`}
                        >
                          Download the PDF
                        </a>{" "}
                        instead.
                      </iframe>
                    ) : (
                      <img
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.AcadamicMont}`}
                        alt="Image"
                        width="100%"
                        height="auto"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {View?.UGCriteria06_612?.OficeOrder && (
                  <>
                    {View?.UGCriteria06_612?.OficeOrder.endsWith(".pdf") ? (
                      <iframe
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.OficeOrder}`}
                        width="100%"
                        height="400"
                        frameborder="0"
                        allowfullscreen
                      >
                        Sorry, your browser doesn't support embedded PDFs.
                        <a
                          href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.OficeOrder}`}
                        >
                          Download the PDF
                        </a>{" "}
                        instead.
                      </iframe>
                    ) : (
                      <img
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.OficeOrder}`}
                        alt="Image"
                        width="100%"
                        height="auto"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {View?.UGCriteria06_612?.Decentralisation1 && (
                  <>
                    {View?.UGCriteria06_612?.Decentralisation1.endsWith(
                      ".pdf"
                    ) ? (
                      <iframe
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation1}`}
                        width="100%"
                        height="400"
                        frameborder="0"
                        allowfullscreen
                      >
                        Sorry, your browser doesn't support embedded PDFs.
                        <a
                          href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation1}`}
                        >
                          Download the PDF
                        </a>{" "}
                        instead.
                      </iframe>
                    ) : (
                      <img
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation1}`}
                        alt="Image"
                        width="100%"
                        height="auto"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {View?.UGCriteria06_612?.Decentralisation2 && (
                  <>
                    {View?.UGCriteria06_612?.Decentralisation2.endsWith(
                      ".pdf"
                    ) ? (
                      <iframe
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation2}`}
                        width="100%"
                        height="400"
                        frameborder="0"
                        allowfullscreen
                      >
                        Sorry, your browser doesn't support embedded PDFs.
                        <a
                          href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation2}`}
                        >
                          Download the PDF
                        </a>{" "}
                        instead.
                      </iframe>
                    ) : (
                      <img
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation2}`}
                        alt="Image"
                        width="100%"
                        height="auto"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {View?.UGCriteria06_612?.Decentralisation3 && (
                  <>
                    {View?.UGCriteria06_612?.Decentralisation3.endsWith(
                      ".pdf"
                    ) ? (
                      <iframe
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation3}`}
                        width="100%"
                        height="400"
                        frameborder="0"
                        allowfullscreen
                      >
                        Sorry, your browser doesn't support embedded PDFs.
                        <a
                          href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation3}`}
                        >
                          Download the PDF
                        </a>{" "}
                        instead.
                      </iframe>
                    ) : (
                      <img
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.Decentralisation3}`}
                        alt="Image"
                        width="100%"
                        height="auto"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                {View?.UGCriteria06_612?.AcadamicMont1 && (
                  <>
                    {View?.UGCriteria06_612?.AcadamicMont1.endsWith(".pdf") ? (
                      <iframe
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.AcadamicMont1}`}
                        width="100%"
                        height="400"
                        frameborder="0"
                        allowfullscreen
                      >
                        Sorry, your browser doesn't support embedded PDFs.
                        <a
                          href={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.AcadamicMont1}`}
                        >
                          Download the PDF
                        </a>{" "}
                        instead.
                      </iframe>
                    ) : (
                      <img
                        src={`https://brightnaac.co.in/UG_Criteria06/${View?.UGCriteria06_612?.AcadamicMont1}`}
                        alt="Image"
                        width="100%"
                        height="auto"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.UGCriteria06_623?.ugdesc1}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default UG_06_612;
