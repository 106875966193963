import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function StudentRegList() {
  const navigate = useNavigate();

  const [Student, setStudent] = useState([]);
  const getallstudentdata = async () => {
    try {
      const response = await axios.get(
        "https://brightnaac.co.in/api/student/studentsdetails"
      );
      if (response.status === 200) {
        setStudent(response.data.success);
      }
    } catch (error) {
      alert("no data present");
    }
  };
  useEffect(() => {
    getallstudentdata();
  }, []);

  console.log("Student", Student);

  const block = async (id) => {
    try {
      const config = {
        url: "/approve",
        method: "put",
        baseURL: "https://brightnaac.co.in/api/student",
        headers: { "content-type": "application/json" },
        data: {
          userid: id,
        },
      };

      // Make the HTTP request
      const response = await axios(config);
      if (response.status === 200) {
        alert(response.data.success);
        getallstudentdata();
      }
    } catch (error) {
      console.error("Error:", error); // Log any errors
      alert(error.response.data.error);
    }
  };
  return (
    <div className="add-gr">
      <div className="container">
        <h5 className="mt-4">Register Student List</h5>
        <div
          className="ad-b mt-4"
          style={{
            display: "flex",
            gap: "21px",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <div className="do-sear mt-2 d-flex ">
            <input
              type="text"
              placeholder="Search"
              className="vi_0"
              // value={SearchData}
              // onChange={(e) => setSearchData(e.target.value)}
            />
          </div>
          {/* <div className="">
          <button style={{ backgroundColor: "#393186" }} onClick={()=>navigate("/studentregister")}>
           Register Student
            <i
              class="fa-solid fa-plus fa-sm"
              style={{ color: "#ffffff" }}
            ></i>
          </button>
        </div> */}
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table
            responsive
            striped
            bordered
            hover
            style={{ textAlign: "center" }}
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Student Status</th>
                <th>Year</th>
                <th>Collage Name</th>
                <th>HEI</th>
                <th>Course </th>
                <th>Status </th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {Student?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.status}</td>
                    <td>
                      {moment(item?.coursestatr).format("YYYY")} -{" "}
                      {moment(item?.courseend).format("YYYY")}{" "}
                    </td>
                    <td>{item?.collagename}</td>
                    <td>{item?.hei}</td>
                    <td>{item?.course}</td>
                    <td>
                      {item?.isBlocked === false ? (
                        <>
                          <p style={{ color: "green", fontWeight: "bold" }}>
                            Approved
                          </p>
                        </>
                      ) : (
                        <>
                          <p style={{ color: "red", fontWeight: "bold" }}>
                            Not Approved
                          </p>
                        </>
                      )}
                    </td>
                    <td>
                      {item?.isBlocked === false ? (
                        <>
                          <Button
                            variant="danger"
                            onClick={() => block(item?._id)}
                          >
                            Not Approve
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="success"
                            onClick={() => block(item?._id)}
                          >
                            Approve
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default StudentRegList;
