import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import axios from "axios";
import exportFromJSON from "export-from-json";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";

const Uni_Criteria01 = () => {
  let Facultydetails = JSON.parse(sessionStorage.getItem("Fuser"));
  console.log("Faculty details", Facultydetails);
  const [selectedYear, setselectedYear] = useState("");

  const [edit1, setedit1] = useState(false);
  const [edit2, setedit2] = useState(false);
  const [edit3, setedit3] = useState(false);
  const [edit4, setedit4] = useState(false);
  const [edit5, setedit5] = useState(false);
  //criteria 1_112_122
  const [Uni_MainYear1, setUni_MainYear1] = useState("");

  const [Uni_ProCode, setUni_ProCode] = useState("");
  const [Uni_ProName, setUni_ProName] = useState("");
  const [Uni_YoI, setUni_YoI] = useState("");
  const [Uni_StCBCS, setUni_StCBCS] = useState("");
  const [Uni_StEcs, setUni_StEcs] = useState("");
  const [Uni_YICBCS, setUni_YICBCS] = useState("");
  const [Uni_YIEcs, setUni_YIEcs] = useState("");
  const [Uni_YrCBCS, setUni_YrCBCS] = useState("");
  const [Uni_YrEcs, setUni_YrEcs] = useState("");
  const [Uni_IfCBCS, setUni_IfCBCS] = useState("");
  const [Uni_IfEcs, setUni_IfEcs] = useState("");
  const [Uni_LCBCS, setUni_LCBCS] = useState("");
  const [Uni_LEcs, setUni_LEcs] = useState("");
  const [Uni_Desc1, setUni_Desc1] = useState("");
  const [Uni_Link1, setUni_Link1] = useState("");
  const [Uni_Link2, setUni_Link2] = useState("");

  const [Uni_CourCode, setUni_CourCode] = useState("");
  const [Uni_CourName, setUni_CourName] = useState("");
  const [Uni_CourYOfIntro, setUni_CourYOfIntro] = useState("");
  const [Uni_CourActivities, setUni_CourActivities] = useState("");
  const [Uni_Courlink, setUni_Courlink] = useState("");
  const [Uni_Desc2, setUni_Desc2] = useState("");
  const [Uni_Link3, setUni_Link3] = useState("");

  const [Uni_ValAddCour, setUni_ValAddCour] = useState("");
  const [Uni_CourseCode, setUni_CourseCode] = useState("");
  const [Uni_YOfOffering, setUni_YOfOffering] = useState("");
  const [Uni_NoOfTimes, setUni_NoOfTimes] = useState("");
  const [Uni_CourseDuration, setUni_CourseDuration] = useState("");
  const [Uni_NoOfStudents, setUni_NoOfStudents] = useState("");
  const [Uni_CourseCompletion, setUni_CourseCompletion] = useState("");
  const [Uni_Link4, setUni_Link4] = useState("");
  const [Uni_Desc3, setUni_Desc3] = useState("");

  const [Uni_PPrgmCode, setUni_PPrgmCode] = useState("");
  const [Uni_PrgName2, setUni_PrgName2] = useState("");
  const [Uni_NoofStu, setUni_NoofStu] = useState("");
  const [Uni_Relvdoc, setUni_Relvdoc] = useState("");
  const [Uni_Link5, setUni_Link5] = useState("");
  const [Uni_Desc4, setUni_Desc4] = useState("");

  const [Uni_Sem, setUni_Sem] = useState("");
  const [Uni_Feedback, setUni_Feedback] = useState("");
  const [Uni_Link6, setUni_Link6] = useState("");
  const [Uni_Desc5, setUni_Desc5] = useState("");

  //integrating get method
  const [View, setView] = useState({});

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);
  const [Uni_Criteria01, setUni_Criteria01] = useState([]);
  const [Value, setValue] = useState({});

  //   ===============================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("Uni_Cri1.0");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (
          item1?.approve === "true"
          // && selectedYear === item1?.Year
        ) {
          return true;
        }
        return false;
      })
      .map((item) => {
        let obj = {
          //  Criteria 1_113

          Uni_Year: item?.Uni_Year,
          Uni_Criteria1_112_122: item?.Uni_Criteria1_112_122?.Uni_criteria,
          "Program Code1": item?.Uni_Criteria1_112_122?.Uni_ProCode,
          "Programme name": item?.Uni_Criteria1_112_122?.Uni_ProName,
          "Year of Introduction": item?.Uni_Criteria1_112_122?.Uni_YoI,
          "Status of implementation of CBCS ":
            item?.Uni_Criteria1_112_122?.Uni_StCBCS,
          "Status of implementation of ECS":
            item?.Uni_Criteria1_112_122?.Uni_StEcs,
          "Year of implemetation of CBCS":
            item?.Uni_Criteria1_112_122?.Uni_YICBCS,
          "Year of implemetation of ECS":
            item?.Uni_Criteria1_112_122?.Uni_YIEcs,
          "Year of revision (CBCS)": item?.Uni_Criteria1_112_122?.Uni_YrCBCS,
          "Year of revision (ECS)": item?.Uni_Criteria1_112_122?.Uni_YrEcs,
          "If revision has been carried out in the syllabus during last 5 years, Percentage of content added or replaced(CBCS)":
            item?.Uni_Criteria1_112_122?.Uni_IfCBCS,
          "If revision has been carried out in the syllabus during last 5 years, Percentage of content added or replaced(ECS)":
            item?.Uni_Criteria1_112_122?.Uni_IfEcs,
          "Description (min 500 words) (Uni_Criteria1_112_122)":
            item?.Uni_Criteria1_112_122?.Uni_Desc1,
          "Link to the relevant documents (CBCS)":
            item?.Uni_Criteria1_112_122?.Uni_Link1,
          "Link to the relevant documents (ECS)":
            item?.Uni_Criteria1_112_122?.Uni_Link2,

          // Uni_Criteria01_113_121

          Uni_Criteria01_113_121: item?.Uni_Criteria01_113_121?.Uni_criteria,
          "Course Code": item?.Uni_Criteria01_113_121?.Uni_CourCode,
          "Name of the Course": item?.Uni_Criteria01_113_121?.Uni_CourName,
          "Year Of Introduction1":
            item?.Uni_Criteria01_113_121?.Uni_CourYOfIntro,
          "Activities/Content with direct bearing on Employability/ Entrepreneurship/ Skill development":
            item?.Uni_Criteria01_113_121?.Uni_CourActivities,
          // "Link to the relevant document":
          //   item?.Uni_Criteria01_113_121?.Uni_Courlink,
          // // "Year of revision (if any)":
          // //   item?.Uni_Criteria01_113_121?.YOfRevision,

          "Description (min 500 words)(Uni_Criteria01_113_121)":
            item?.Uni_Criteria01_113_121?.Uni_Desc2,
          "Upload the relevant document(Uni_Criteria01_113_121)":
            item?.Uni_Criteria01_113_121?.Uni_Link3,

          // Uni_Criteria01_132_133
          Uni_Criteria01_132_133: item?.Uni_Criteria01_132_133?.Uni_criteria,
          "Name of the value added courses offered (with 30 or more contact hours)":
            item?.Uni_Criteria01_132_133?.Uni_ValAddCour,
          "Course Code (if any)": item?.Uni_Criteria01_132_133?.Uni_CourseCode,
          "Year of Offering": item?.Uni_Criteria01_132_133?.Uni_YOfOffering,
          "No. of times offered during the same year":
            item?.Uni_Criteria01_132_133?.Uni_NoOfTimes,
          "Duration of course":
            item?.Uni_Criteria01_132_133?.Uni_CourseDuration,
          "Number of students enrolled in the year":
            item?.Uni_Criteria01_132_133?.Uni_NoOfStudents,
          "Number of Students completing the course in the year":
            item?.Uni_Criteria01_132_133?.Uni_CourseCompletion,
          "Description (min 500 words)(Uni_Criteria01_132_133)":
            item?.Uni_Criteria01_132_133?.Uni_Desc3,
          "Upload the relevant document(Uni_Criteria01_132_133)":
            item?.Uni_Criteria01_132_133?.Uni_Link4,

          // Uni_Criteria01_134
          Uni_Criteria01_134: item?.Uni_Criteria01_134?.Uni_criteria,
          "Program Code": item?.Uni_Criteria01_134?.Uni_PPrgmCode,
          "Program Name": item?.Uni_Criteria01_134?.Uni_PrgName2,
          "Name of the students": item?.Uni_Criteria01_134?.Uni_NoofStu,
          "Description (min 500 words)(Uni_Criteria01_134)":
            item?.Uni_Criteria01_134?.Uni_Desc4,
          "Upload the relevant document(Uni_Criteria01_134)":
            item?.Uni_Criteria01_134?.Uni_Link5,

          //  Uni_Criteria01_141_142
          Uni_Criteria01_141_142: item?.Uni_Criteria01_141_142?.Uni_criteria,
          "Select Semester": item?.Uni_Criteria01_141_142?.Uni_Sem,
          Feedback: item?.Uni_Criteria01_141_142?.Uni_Feedback,

          "Description (min 500 words)(Uni_Criteria01_141_142)":
            item?.Uni_Criteria01_141_142?.Uni_Desc5,
          "Upload the relevant document(Uni_Criteria01_141_142)":
            item?.Uni_Criteria01_141_142?.Uni_Link6,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //   ======================================================

  const editUni_Criteria1_112_122 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc1;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc1) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }

      const config = {
        url: `/FacultyAdmin/editUni_Criteria1_112_122/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_ProCode: Uni_ProCode,
          Uni_ProName: Uni_ProName,
          Uni_YoI: Uni_YoI,
          Uni_StCBCS: Uni_StCBCS,
          Uni_StEcs: Uni_StEcs,
          Uni_YICBCS: Uni_YICBCS,
          Uni_YIEcs: Uni_YIEcs,
          Uni_YrCBCS: Uni_YrCBCS,
          Uni_YrEcs: Uni_YrEcs,
          Uni_IfCBCS: Uni_IfCBCS,
          Uni_IfEcs: Uni_IfEcs,
          Uni_LCBCS: Uni_LCBCS,
          Uni_LEcs: Uni_LEcs,
          Uni_Desc1: Uni_Desc1,
          Uni_Link1: Uni_Link1,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicriteria01details();
          setedit1(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria01_113_121 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc2;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc2) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria01_113_121/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_CourCode: Uni_CourCode,
          Uni_CourName: Uni_CourName,
          Uni_CourYOfIntro: Uni_CourYOfIntro,
          Uni_CourActivities: Uni_CourActivities,
          Uni_Courlink: Uni_Courlink,
          Uni_Desc2: Uni_Desc2,
          Uni_Link2: Uni_Link2,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicriteria01details();
          setedit2(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria01_132_133 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc3;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc3) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria01_132_133/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_ValAddCour: Uni_ValAddCour,
          Uni_CourseCode: Uni_CourseCode,
          Uni_YOfOffering: Uni_YOfOffering,
          Uni_NoOfTimes: Uni_NoOfTimes,
          Uni_CourseDuration: Uni_CourseDuration,
          Uni_NoOfStudents: Uni_NoOfStudents,
          Uni_CourseCompletion: Uni_CourseCompletion,
          Uni_Link3: Uni_Link3,
          Uni_Desc3: Uni_Desc3,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicriteria01details();
          setedit3(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria01_134 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc4;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc4) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria01_134/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_PPrgmCode: Uni_PPrgmCode,
          Uni_PrgName2: Uni_PrgName2,
          Uni_NoofStu: Uni_NoofStu,
          Uni_Desc4: Uni_Desc4,
          Uni_Link4: Uni_Link4,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicriteria01details();
          setedit4(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const editUni_Criteria01_141_142 = async (e, id) => {
    e.preventDefault();
    try {
      const minwords = 500;
      const newText = Uni_Desc5;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (Uni_Desc5) {
        if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        }
      }
      const config = {
        url: `/FacultyAdmin/editUni_Criteria01_141_142/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Uni_Sem: Uni_Sem,
          Uni_Feedback: Uni_Feedback,
          Uni_Link5: Uni_Link5,
          Uni_Desc5: Uni_Desc5,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getUnicriteria01details();
          setedit5(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getUnicriteria01details = async () => {
    try {
      // alert("danger");
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getUnicriteria01details"
      );
      if (res.status == 200) {
        setUni_Criteria01(res.data.Unicriteria01);
        setdata1(res.data.Unicriteria01);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUnicriteria01details();
  }, []);
  console.log(Uni_Criteria01);

  // ===========================================================
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);

  const pgCriteria01Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getpgcriteria1"
      );
      if (res.status === 200) {
        setAllDetails(res.data.pgcriteria01);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.pgcriteria01);
    }
  };
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center">
            <div className="col-lg-8">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                University_Criteria-01
              </span>
            </div>

            <div className=" col-lg-4 lab mt-4 text-end">
              <Link to="/unicriteria001">
                <Button className=" btn btn-success">
                  Add Criteria 1{" "}
                  <i
                    class="fa-solid fa-plus fa-sm"
                    style={{ color: "#ffffff" }}
                  ></i>
                </Button>
              </Link>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 text-end ">
              <button className="btn btn-success " onClick={ExportToExcel}>
                Export Excel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="mo-gra mt-4">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead style={{ verticalAlign: "middle" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Year</th>
                  <th>Faculty Name</th>
                  {/* criteria 1 */}
                  <th>Criteria</th>
                  <th>Program code</th>
                  <th>Program name</th>
                  <th>Year of Introduction</th>
                  <th>Status of implementation of CBCS </th>
                  <th>Status of implementation of ECS </th>
                  <th>Year of implemetation of CBCS </th>
                  <th>Year of implemetation of ECS</th>
                  <th>Year of revision (CBCS)</th>
                  <th>Year of revision (ECS)</th>
                  <th>
                    If revision has been carried out in the syllabus during last
                    5 years, Percentage of content added or replaced(CBCS)
                  </th>
                  <th>
                    If revision has been carried out in the syllabus during last
                    5 years, Percentage of content added or replaced(ECS)
                  </th>
                  <th>Description </th>
                  <th>Document (CBCS)</th>
                  <th>Document (ECS)</th>

                  {/* criteria 2 */}
                  <th>Criteria</th>
                  <th>Course Code</th>
                  <th>Name of the Course</th>

                  <th>Year of introduction</th>
                  <th>
                    Activities/Content with direct bearing on Employability/
                    Entrepreneurship/ Skill development
                  </th>
                  <th>Description </th>
                  <th>Document</th>

                  {/* criteria3 */}
                  <th>Criteria</th>
                  <th>
                    Name of the value added courses offered (with 30 or more
                    contact hours)
                  </th>
                  <th>Course Code (if any)</th>
                  <th>Year of offering</th>
                  <th>No. of times offered during the same year</th>
                  <th>Duration of the course</th>
                  <th>Number of students enrolled in the year</th>
                  <th>Number of Students completing the course in the year</th>
                  <th>Description </th>
                  <th>Document</th>

                  {/* criteria 4 */}
                  <th>Criteria</th>
                  <th>Program Code</th>
                  <th>Program name</th>
                  <th>Name of the students</th>
                  <th>Description </th>
                  <th>Document</th>

                  {/* criteria 5 */}
                  <th>Criteria</th>
                  <th> Semester</th>
                  <th>Feedback</th>
                  <th>Description </th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                {Uni_Criteria01?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Uni_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    Facultydetails?._id === item1?.addedByFac?._id &&
                    (item1?.approve === "true" ||
                      item1?.approve === "Pending" ||
                      item1?.approve === "false") &&
                    (SearchItem === "" ||
                      item1?.Uni_Criteria1_112_122?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_ProCode?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_ProName?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YoI?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_StCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_StEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YICBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YIEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YrCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_YrEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_IfCBCS?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_IfEcs?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria1_112_122?.Uni_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_CourName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_CourCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_CourActivities?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_CourYOfIntro?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_113_121?.Uni_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_ValAddCour?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_CourseCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_YOfOffering?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_NoOfTimes?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_CourseDuration?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_NoOfStudents?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_CourseCompletion?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_132_133?.Uni_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_PPrgmCode?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_PrgName2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_NoofStu?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_134?.Uni_Desc4?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_141_142?.Uni_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Uni_Criteria01_141_142?.Uni_Sem?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_141_142?.Uni_Feedback?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Uni_Criteria01_141_142?.Uni_Desc5?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.Uni_Year}</td>
                      <td>{Facultydetails?.FName}</td>

                      <td>{item?.Uni_Criteria1_112_122?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_ProCode}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_ProName}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_YoI}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_StCBCS}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_StEcs}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_YICBCS}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_YIEcs}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_YrCBCS}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_YrEcs}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_IfCBCS}</td>
                      <td>{item?.Uni_Criteria1_112_122?.Uni_IfEcs}</td>
                      <td>
                        {item?.Uni_Criteria1_112_122?.Uni_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow1();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow6();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow7();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria01_113_121?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria01_113_121?.Uni_CourCode}</td>
                      <td>{item?.Uni_Criteria01_113_121?.Uni_CourName}</td>
                      <td>{item?.Uni_Criteria01_113_121?.Uni_CourYOfIntro}</td>
                      <td>
                        {item?.Uni_Criteria01_113_121?.Uni_CourActivities}
                      </td>

                      <td>
                        {item?.Uni_Criteria01_113_121?.Uni_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow2();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>

                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow8();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria01_132_133?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_ValAddCour}</td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_CourseCode}</td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_YOfOffering}</td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_NoOfTimes}</td>
                      <td>
                        {item?.Uni_Criteria01_132_133?.Uni_CourseDuration}
                      </td>
                      <td>{item?.Uni_Criteria01_132_133?.Uni_NoOfStudents}</td>
                      <td>
                        {item?.Uni_Criteria01_132_133?.Uni_CourseCompletion}
                      </td>
                      <td>
                        {item?.Uni_Criteria01_132_133?.Uni_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow3();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow9();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>{item?.Uni_Criteria01_134?.Uni_criteria}</td>
                      <td> {item?.Uni_Criteria01_134?.Uni_PPrgmCode} </td>
                      <td>{item?.Uni_Criteria01_134?.Uni_PrgName2}</td>
                      <td>{item?.Uni_Criteria01_134?.Uni_NoofStu}</td>
                      {/* <td>{item?.Uni_Criteria01_134?.Uni_YYOfOffering}</td> */}
                      <td>
                        {item?.Uni_Criteria01_134?.Uni_Desc4?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow10();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.Uni_Criteria01_141_142?.Uni_criteria}</td>
                      <td>{item?.Uni_Criteria01_141_142?.Uni_Sem}</td>
                      <td>{item?.Uni_Criteria01_141_142?.Uni_Feedback}</td>
                      <td>
                        {item?.Uni_Criteria01_141_142?.Uni_Desc5?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow11();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          {/* <i class="fa-solid fa-check fa-lg" style={{color: "#26a269"}}></i> */}
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        {" "}
                        {item?.approve === "true" ? (
                          <div style={{ color: "green" }}>Approved</div>
                        ) : (
                          <>
                            {item?.approve === "false" ? (
                              <div style={{ color: "red" }}> Not Approved</div>
                            ) : (
                              <div> Pending</div>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={() => {
            setedit1(false);
            setedit2(false);
            setedit3(false);
            setedit4(false);
            setedit5(false);
            handleClose();
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#" }}>Criteria 1 </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            {/* Criteria_1 (1.1.2 & 1.2.2) */}

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.2 & 1.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-4 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        value={Value?.Uni_Criteria1_112_122?.Uni_ProCode}
                        onChange={(e) => {
                          setUni_ProCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_ProCode}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program name
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_ProName}
                        onChange={(e) => setUni_ProName(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                        <option value="BSC">BSC</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_ProName}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of Introduction
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_YoI}
                        onChange={(e) => setUni_YoI(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_YoI}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline">
                Status of implementation of CBCS / Elective Course System (ECS)
              </h6>
              <div className="row d-flex">
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    CBCS
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_StCBCS}
                        onChange={(e) => setUni_StCBCS(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_StCBCS}</>
                  )}
                </div>
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    ECS
                  </label>

                  {edit1 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_StEcs}
                        onChange={(e) => setUni_StEcs(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_StEcs}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline">
                Year of implemetation of CBCS / Elective Course System (ECS)
              </h6>
              <div className="row d-flex">
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    CBCS
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_YICBCS}
                        onChange={(e) => {
                          setUni_YICBCS(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_YICBCS}</>
                  )}
                </div>
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    ECS
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_YIEcs}
                        onChange={(e) => {
                          setUni_YIEcs(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_YIEcs}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline">
                Year of revision (if any)
              </h6>
              <div className="row d-flex">
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    CBCS
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_YrCBCS}
                        onChange={(e) => {
                          setUni_YrCBCS(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_YrCBCS}</>
                  )}
                </div>
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    ECS
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_YrEcs}
                        onChange={(e) => {
                          setUni_YrEcs(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_YrEcs}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline">
                If revision has been carried out in the syllabus during last 5
                years, Percentage of content added or replaced
              </h6>
              <div className="row d-flex">
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    CBCS
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_IfCBCS}
                        onChange={(e) => {
                          setUni_IfCBCS(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_IfCBCS}</>
                  )}
                </div>
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    ECS
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_IfEcs}
                        onChange={(e) => {
                          setUni_IfEcs(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_IfEcs}</>
                  )}
                </div>
              </div>

              <h6 className="text-center text-decoration-underline">
                Documents
              </h6>
              <div className="row m-0">
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    CBCS
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload1"
                        id="upload1"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_Link1}
                        onChange={(e) => setUni_Link1(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_Link1}</>
                  )}
                </div>
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    ECS
                  </label>

                  {edit1 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload2"
                        id="upload1"
                        accept="image/*"
                        placeholder={Uni_Link2}
                        onChange={(e) => setUni_Link2(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_Link2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>

                  {edit1 ? (
                    <>
                      <textarea
                        className="vi_0"
                        id="name"
                        // cols={57}
                        rows={3}
                        placeholder={Value?.Uni_Criteria1_112_122?.Uni_Desc1}
                        onChange={(e) => setUni_Desc1(e.target.value)}
                      ></textarea>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria1_112_122?.Uni_Desc1}</>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="text-end">
                  {edit1 ? (
                    <>
                      <button
                        className="btn btn-success"
                        style={{ color: "white" }}
                        onClick={(e) => {
                          editUni_Criteria1_112_122(e, Value?._id);
                          handleClose();
                        }}
                      >
                        Submit
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="btn btn-danger"
                        onClick={() => setedit1(true)}
                      >
                        Edit
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.1.3 & 1.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code
                  </label>{" "}
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        value={Value?.Uni_Criteria01_113_121?.Uni_CourCode}
                        onChange={(e) => {
                          setUni_CourCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_113_121?.Uni_CourCode}</>
                  )}
                </div>

                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Year of introduction
                  </label>{" "}
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        value={Value?.Uni_Criteria01_113_121?.Uni_CourYOfIntro}
                        onChange={(e) => {
                          setUni_CourYOfIntro(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_113_121?.Uni_CourYOfIntro}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the Course
                  </label>{" "}
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        value={Value?.Uni_Criteria01_113_121?.Uni_CourName}
                        onChange={(e) => {
                          setUni_CourName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_113_121?.Uni_CourName}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Activities/Content with direct bearing on Employability/
                    Entrepreneurship/ Skill development
                  </label>{" "}
                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        value={
                          Value?.Uni_Criteria01_113_121?.Uni_CourActivities
                        }
                        onChange={(e) => {
                          setUni_CourActivities(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_113_121?.Uni_CourActivities}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>{" "}
                  {edit2 ? (
                    <>
                      <textarea
                        className="vi_0"
                        id="name"
                        // cols={57}
                        rows={3}
                        placeholder={Value?.Uni_Criteria01_113_121?.Uni_Desc2}
                        onChange={(e) => setUni_Desc2(e.target.value)}
                      ></textarea>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_113_121?.Uni_Desc2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Document
                  </label>

                  {edit2 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload1"
                        accept="image/*"
                        placeholder={Uni_Link3}
                        onChange={(e) => {
                          setUni_Link3(e.target.files[0]);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_113_121?.Uni_Link3}</>
                  )}
                </div>

                <div className="row mt-3">
                  <div className="text-end">
                    {edit2 ? (
                      <>
                        <button
                          className="btn btn-success"
                          style={{ color: "white" }}
                          onClick={(e) => {
                            editUni_Criteria01_113_121(e, Value?._id);
                            handleClose();
                          }}
                        >
                          Submit
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit2(true)}
                        >
                          Edit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.2 & 1.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the value added courses offered (with 30 or more
                    contact hours)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="year"
                        id="name"
                        value={Value?.Uni_Criteria01_132_133?.Uni_ValAddCour}
                        onChange={(e) => {
                          setUni_ValAddCour(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_132_133?.Uni_ValAddCour}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Course Code (if any)
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        value={Value?.Uni_Criteria01_132_133?.Uni_CourseCode}
                        onChange={(e) => {
                          setUni_CourseCode(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_132_133?.Uni_CourseCode}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year of offering
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        value={Value?.Uni_Criteria01_132_133?.Uni_YOfOffering}
                        onChange={(e) => {
                          setUni_YOfOffering(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_132_133?.Uni_YOfOffering}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    No. of times offered during the same year
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        value={Value?.Uni_Criteria01_132_133?.Uni_NoOfTimes}
                        onChange={(e) => {
                          setUni_NoOfTimes(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_132_133?.Uni_NoOfTimes}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Duration of the course
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        value={
                          Value?.Uni_Criteria01_132_133?.Uni_CourseDuration
                        }
                        onChange={(e) => {
                          setUni_CourseDuration(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_132_133?.Uni_CourseDuration}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of students enrolled in the year
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        value={Value?.Uni_Criteria01_132_133?.Uni_NoOfStudents}
                        onChange={(e) => {
                          setUni_NoOfStudents(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_132_133?.Uni_NoOfStudents}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Students completing the course in the year
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        value={
                          Value?.Uni_Criteria01_132_133?.Uni_CourseCompletion
                        }
                        onChange={(e) => {
                          setUni_CourseCompletion(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_132_133?.Uni_CourseCompletion}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>{" "}
                  {edit3 ? (
                    <>
                      <textarea
                        className="vi_0"
                        id="name"
                        // cols={57}
                        rows={3}
                        placeholder={Value?.Uni_Criteria01_132_133?.Uni_Desc3}
                        onChange={(e) => {
                          setUni_Desc3(e.target.value);
                        }}
                      ></textarea>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_132_133?.Uni_Desc3}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  {edit3 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="upload3"
                        id="upload1"
                        accept="image/*"
                        placeholder={Value?.Uni_Criteria01_132_133?.Uni_Link4}
                        onChange={(e) => setUni_Link4(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_132_133?.Uni_Link4}</>
                  )}
                </div>

                <div className="row mt-3">
                  <div className="text-end">
                    {edit3 ? (
                      <>
                        <button
                          className="btn btn-success"
                          onClick={(e) => {
                            editUni_Criteria01_132_133(e, Value?._id);
                            handleClose();
                          }}
                        >
                          Submit
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit3(true)}
                        >
                          Edit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.3.4
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program Code
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria01_134?.Uni_PPrgmCode}
                        onClick={(e) => setUni_PPrgmCode(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_134?.Uni_PPrgmCode}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Program name
                  </label>

                  {edit4 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "100%" }}
                        placeholder={Value?.Uni_Criteria01_134?.Uni_PrgName2}
                        onChange={(e) => setUni_PrgName2(e.target.value)}
                      >
                        <option selected>--Select--</option>
                        <option value="BCA">BCA</option>
                        <option value="BBA">BBA</option>
                        <option value="B.Com">B.Com</option>
                        <option value="BSC">BSC</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_134?.Uni_PrgName2}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Name of the students
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="text"
                        name="fname"
                        id="name"
                        placeholder={Value?.Uni_Criteria01_134?.Uni_NoofStu}
                        onClick={(e) => setUni_NoofStu(e.target.value)}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_134?.Uni_NoofStu}</>
                  )}
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Description
                  </label>

                  {edit4 ? (
                    <>
                      <textarea
                        className="vi_0"
                        id="name"
                        // cols={57}
                        rows={3}
                        placeholder={Uni_Desc4}
                        onClick={(e) => setUni_Desc4(e.target.value)}
                      ></textarea>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_134?.Uni_Desc4}</>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="upload1">
                    {" "}
                    Document
                  </label>

                  {edit4 ? (
                    <>
                      <input
                        className="vi_0"
                        type="file"
                        name="fname"
                        id="name"
                        placeholder={Uni_Link5}
                        onChange={(e) => setUni_Link5(e.target.files[0])}
                      />
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_134?.Uni_Link5}</>
                  )}
                </div>

                <div className="row mt-3">
                  <div className="text-end">
                    {edit4 ? (
                      <>
                        <button
                          className="btn btn-success"
                          style={{ color: "white" }}
                          onClick={(e) => {
                            editUni_Criteria01_134(e, Value?._id);
                            handleClose();
                          }}
                        >
                          Submit
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit4(true)}
                        >
                          Edit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-1.4.1 & 1.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div class=" col-md-6 mb-3">
                  <label for="" class="form-label">
                    Select Semester
                  </label>

                  {edit5 ? (
                    <>
                      <select
                        class="form-select form-select-sm vi_0"
                        aria-label="Default select example"
                        style={{ width: "270px" }}
                        placeholder={Value?.Uni_Criteria01_141_142?.Uni_Sem}
                        onChange={(e) => {
                          setUni_Sem(e.target.value);
                        }}
                      >
                        <option selected>--Select--</option>
                        <option value="Semester-01">Semester-01</option>
                        <option value="Semester-02">Semester-02</option>
                        <option value="Semester-03">Semester-03</option>
                        <option value="Semester-04">Semester-04</option>
                        <option value="Semester-05">Semester-05</option>
                        <option value="Semester-06">Semester-06</option>
                      </select>
                    </>
                  ) : (
                    <>{Value?.Uni_Criteria01_141_142?.Uni_Sem}</>
                  )}
                </div>

                <h6>
                  <label htmlFor="">
                    Feedback processes of the institution may be classified as
                    follows:
                  </label>

                  <label htmlFor="">
                    A. Feedback collected, analysed and action taken and
                    feedback available on website <br />
                    B. Feedback collected, analysed and action has been taken C.
                    Feedback collected and analysed <br />
                    D. Feedback collected <br />
                    E. Feedback not collected <br />
                  </label>
                </h6>
                {edit5 ? (
                  <>
                    <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                      <input
                        type="radio"
                        value="Any 4 of the above"
                        name="one"
                        onChange={(e) => setUni_Feedback(e.target.value)}
                      />
                      <label htmlFor="">Any 4 of the above</label>
                    </div>
                    <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                      <input
                        type="radio"
                        value="Any 3 of the above"
                        name="one"
                        onChange={(e) => setUni_Feedback(e.target.value)}
                      />
                      <label htmlFor="">Any 3 of the above</label>
                    </div>

                    <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                      <input
                        type="radio"
                        value="Any 2 of the above"
                        name="one"
                        onChange={(e) => setUni_Feedback(e.target.value)}
                      />
                      <label htmlFor="">Any 2 of the above</label>
                    </div>

                    <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                      <input
                        type="radio"
                        value="Any 1 of the above"
                        name="one"
                        onChange={(e) => setUni_Feedback(e.target.value)}
                      />
                      <label htmlFor="">Any 1 of the above</label>
                    </div>

                    <div className="col-md-6 d-flex" style={{ gap: "10px" }}>
                      <input
                        type="radio"
                        value="None of the above"
                        name="one"
                        onChange={(e) => setUni_Feedback(e.target.value)}
                      />
                      <label htmlFor="">None of the above</label>
                    </div>
                  </>
                ) : (
                  <>{Value?.Uni_Criteria01_141_142?.Uni_Feedback}</>
                )}
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="name">
                      {" "}
                      Description
                    </label>

                    {edit5 ? (
                      <>
                        <textarea
                          className="vi_0"
                          id="name"
                          // cols={57}
                          rows={3}
                          value={Value?.Uni_Criteria01_141_142?.Uni_Desc5}
                          onChange={(e) => setUni_Desc5(e.target.value)}
                        ></textarea>
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria01_141_142?.Uni_Desc5}</>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label className="content1" htmlFor="upload1">
                      {" "}
                      Document
                    </label>

                    {edit5 ? (
                      <>
                        <input
                          className="vi_0"
                          type="file"
                          name="upload5"
                          id="upload1"
                          accept="image/*"
                          onChange={(e) => setUni_Link6(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>{Value?.Uni_Criteria01_141_142?.Uni_Link6}</>
                    )}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="text-end ">
                    {edit5 ? (
                      <>
                        <button
                          className="btn btn-success"
                          style={{ color: "white" }}
                          onClick={(e) => {
                            editUni_Criteria01_141_142(e, Value?._id);
                            handleClose();
                          }}
                        >
                          Submit
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => setedit5(true)}
                        >
                          Edit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} className="me-2">
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_1 */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria1_112_122?.Uni_Desc1}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_2 */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria01_113_121?.Uni_Desc2}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_3 */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria01_132_133?.Uni_Desc3}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose3}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal_4 */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria01_134?.Uni_Desc4}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal_5 */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{View?.Uni_Criteria01_141_142?.Uni_Desc5}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* show 1 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria1_112_122?.Uni_Link1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 2 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria1_112_122?.Uni_Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 3 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria01_113_121?.Uni_Link3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 4 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria01_132_133?.Uni_Link4}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show 5 model */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria01_134?.Uni_Link5}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 6 model */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/Uni_Criteria01/${View?.Uni_Criteria01_141_142?.Uni_Link6}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Uni_Criteria01;
