import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import { DatePicker, Space } from "antd";
import exportFromJSON from "export-from-json";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

function AdminClgUG05_513() {
  const navigate = useNavigate();
  const params = useParams();
  let approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
  // show description model
  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [View, setView] = useState({});

  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [selectedYear, setselectedYear] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  // ==================================Export to excel=========================================
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState("5.1.3");

  const ExportToExcel = () => {
    // let arr = [];
    data1
      ?.filter((item1) => {
        if (item1?.approve === "true" && selectedYear === item1?.Year) {
          return true;
        }
        return false;
      })
      .map((item) => {
        //     let tableMarkup1 = `
        //     <table>
        //       <tr>
        //         <td>Number of students</td>
        //         <td>${item?.Criteria05_511?.SName}</td>
        //       </tr>
        //       <tr>
        //         <td>Amount</td>
        //         <td>${item?.Criteria05_511?.Amount}</td>
        //       </tr>
        //     </table>
        //   `;
        //   let tableMarkup2 = `
        //     <table>
        //       <tr>
        //         <td>Number of students</td>
        //         <td>${item?.Criteria05_511?.SName1}</td>
        //       </tr>
        //       <tr>
        //         <td>Amount</td>
        //         <td>${item?.Criteria05_511?.Amount1}</td>
        //       </tr>
        //     </table>
        //   `;
        let obj = {
          Year: item?.Year,
          "Name of the capacity development and skills enhancement program":
            item?.Criteria05_513?.NamCap,
          "Date of implementation": moment(item?.Criteria05_513?.IDate).format(
            "DD-MM-YYYY"
          ),
          "Number of students enrolled": item?.Criteria05_513?.StdEnr,
          "Name of the agencies/consultants involved with contact details (if any)":
            item?.Criteria05_513?.AgeName,
          Description: item?.Criteria05_513?.Desc22,
          "Relevant document": item?.Criteria05_513?.Link22,
        };
        data.push(obj);
      });
    // setdata(arr1);

    if (fileName) {
      exportFromJSON({ data, fileName, exportType });
      setEnterfilenameModal(false);
      setfileName("");
    } else {
      alert("Enter file name to export");
    }
  };

  //  ===========================================================================================

  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({});
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");

  // =================================================================
  const [AllDetails, setAllDetails] = useState([]);
  const getCriteria05_513 = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/ugcri05_513"
      );
      if (res.status === 200) {
        setAllDetails(res.data.getC513);
        setdata1(res.data.getC513);
      }
    } catch (error) {
      console.log(error);
      setAllDetails(error.response.data.getC513);
    }
  };
  console.log("AllDetails513", AllDetails);

  // =================================================================
  useEffect(() => {
    approvedetails = JSON.parse(sessionStorage.getItem("Admin"));
    if (!approvedetails) {
      alert("Please login first");
      window.location.assign("/admin");
    } else {
      // approveCriteria();
      getCriteria05_513();
      getAllYears();
    }
  }, []);
  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-05-513
              </span>
            </div>{" "}
          </div>
        </div>

        <div className="ad-b  row align-items-center m-0">
          <div className=" col-lg-3 do-sear  d-flex ">
            <input
              type="text"
              placeholder="Search"
              className="vi_0"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>

          <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
            <DatePicker
              placeholder="Select Year (from)"
              onChange={onChangeFrom}
              picker="year"
              className="vi_0"
            />
          </div>
          <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
            <DatePicker
              onChange={onChangeTo}
              placeholder="Select Year (to)"
              picker="year"
              className="vi_0"
            />
          </div>

          <div className=" col-lg-2 text-end ">
            <Button className="btn-success" onClick={ExportToExcel}>
              Export Excel
            </Button>
          </div>
        </div>

        <div className="row justify-content-end m-0 mt-4">
          <div className=" col-lg-3 do-sear  d-flex align-items-center   ">
            <label style={{ width: "130px" }}>Select Year</label>
            <select
              className="criteriaform "
              style={{ width: "150px" }}
              onChange={(e) => setselectedYear(e.target.value)}
            >
              <option>Select Year</option>
              {allYears
                ?.sort((a, b) => {
                  return Number(a?.from) - Number(b?.from);
                })
                ?.map((item) => {
                  return (
                    <option value={`${item?.from}-${item?.to}`}>
                      {item?.from} - {item?.to}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-9">
            <Nav defaultActiveKey="/ugcriteria04">
              <Nav.Item>
                <Nav.Link
                  eventKey="link-1"
                  onClick={() => navigate(`/adminclgugcri5511/${params?.id}`)}
                >
                  <Button className="criteriabtn"> 5.1.1 & 5.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-2"
                  onClick={() => navigate(`/adminclgugcri5513/${params?.id}`)}
                >
                  <Button
                    variant="warning"
                    style={{ backgroundColor: "#ffc107" }}
                    className="criteriabtn-0"
                  >
                    {" "}
                    5.1.3
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri5514/${params?.id}`)}
                >
                  <Button className="criteriabtn">5.1.4</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri5521/${params?.id}`)}
                >
                  <Button className="criteriabtn">5.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri5522/${params?.id}`)}
                >
                  <Button className="criteriabtn">5.2.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri5523/${params?.id}`)}
                >
                  <Button className="criteriabtn">5.2.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri5531/${params?.id}`)}
                >
                  <Button className="criteriabtn">5.3.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="link-3"
                  onClick={() => navigate(`/adminclgugcri5533/${params?.id}`)}
                >
                  <Button className="criteriabtn">5.3.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "1500px" }}
            >
              <thead>
                <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <th>Sl.No</th>
                  <th>Year</th>

                  <th>
                    Name of the capacity development and skills enhancement
                    program
                  </th>
                  <th>Date of implementation</th>
                  <th>Number of students enrolled</th>
                  <th>
                    Name of the agencies/consultants involved with contact
                    details (if any)
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {AllDetails?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    params?.id === item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "true" &&
                    selectedYear === item1?.Year &&
                    (SearchItem === "" ||
                      item1?.Year?.includes(SearchItem) ||
                      item1?.Criteria05_513?.NamCap?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      moment(item1?.Criteria05_513?.IDate)
                        .format("DD-MM-YYYY")
                        ?.toString()
                        ?.includes(SearchItem) ||
                      item1?.Criteria05_513?.StdEnr?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.Criteria05_513?.AgeName?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.Criteria05_513?.Desc22?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                })?.map((item, i) => {
                  return (
                    <tr
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <td>{++i}.</td>
                      <td>{item?.Year}</td>
                      <td>{item?.Criteria05_513?.NamCap}</td>
                      <td>
                        {moment(item?.Criteria05_513?.IDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{item?.Criteria05_513?.StdEnr}</td>
                      <td>{item?.Criteria05_513?.AgeName}</td>
                      <td>
                        {item?.Criteria05_513?.Desc22?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow7();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Show description modal */}
          <Modal show={show7} onHide={handleClose7}>
            <Modal.Header closeButton>
              <Modal.Title>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>{View?.Criteria05_513?.Desc22}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* show model */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Relevant Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <object
                    width="100%"
                    height="400"
                    data={`https://brightnaac.co.in/UG_Criteria05/${View?.Criteria05_513?.Link22}`}
                    type={
                      "image/png" ||
                      "application/pdf" ||
                      "image/jpg" ||
                      "image/jpeg"
                    }
                  >
                    {" "}
                  </object>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* <div className="d-flex justify-content-end pages">
            <div>
              <nav aria-label="Page navigation example ">
                <ul class="pagination pagination-lg">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AdminClgUG05_513;
