import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
function PDFprintUG2_271() {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }

  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("2.7.1.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);

  if (location?.state == null) {
    navigate("/PDFprintUG2_271");
  }
  console.log(location, "vvvvv");
  return (
    <div>
      <div className="m-auto">
        <div id="pdf">
          <div className="p-2">
            <div className="text-center">
              <img
                src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
                alt="img"
                className="pdfimg mt-5"
              />
              <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
            </div>
            <div className="row align-items-center m-0 p-3">
              <div className="col-4  text-center fs-5 fw-medium">
                year:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.Year}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state[location?.state?.length - 1]?.criteria}</>
                )}
              </div>
              <div className="col-4 text-center fs-5 fw-medium">
                Sub-Criteria:{" "}
                {location?.state == null ? (
                  <></>
                ) : (
                  <>{location?.state["0"]?.Criteria02_271?.criteria}</>
                )}
              </div>
            </div>

            {finalArr?.map((item, i) => {
              return (
                <div
                  className="container-fluid report-pdf mt-5"
                  style={{ padding: "0px 100px" }}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Program Name</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria02_271?.PrgName3}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Name of the student</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Criteria02_271?.NStd}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Gender</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Criteria02_271?.Gender}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Category</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.Criteria02_271?.Category}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>State of Domicile</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.Criteria02_271?.SDomicile}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Nationality if othern than Indian</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.Criteria02_271?.NIndian}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Email-ID</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.Criteria02_271?.Email}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Mobile Number</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.Criteria02_271?.MNo}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Year of joining</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.Criteria02_271?.YJoining}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>
                        Unique Enrolment ID / College ID/ University enrolment
                        number
                      </span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span> {item?.Criteria02_271?.UID}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>Description</span>
                    </div>
                    <div className="col-lg-12 report-text  mb-3">
                      <span> {item?.Criteria02_271?.Desc11}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>
                        <img
                          style={{ width: "54px" }}
                          src={`https://brightnaac.co.in/UG_Criteria02/${item?.Criteria02_271?.Link11}`}
                          alt="no-image"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="text-center">
          <button className="ad" style={{}} onClick={createPDF}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default PDFprintUG2_271;
