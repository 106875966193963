import axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import PG4413aqar from "./PG4413aqar";
import PG4414aqar from "./PG4414aqar";
import PG4422aqar from "./PG4422aqar";

const PGC4aqar = React.memo(({ startYeardata, endYeardata }) => {
  const navigate = useNavigate();
  // =================================================================
  const [pg4Details, setpg4Details] = useState([]);
  const pgCriteria04Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getpgcriteria04"
      );
      if (res.status === 200) {
        setpg4Details(
          res.data.pgcriteria04?.filter(
            (item) =>
              item?.PG_Year === `${startYeardata}-${endYeardata}` &&
              item?.approve === "true"
          )
        );
        let dataArr = res.data.pgcriteria04?.filter(
          (item) =>
            item?.PG_Year === `${startYeardata}-${endYeardata}` &&
            item?.approve === "true"
        );
        if (!dataArr?.length) {
          navigate("/finalreports");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setpg4Details(error.response.data.pgcriteria04);
    }
  };

  useEffect(() => {
    pgCriteria04Details();
  }, [startYeardata, endYeardata]);
  return (
    <div>
      <div className="text-center">
        <b>Criteria 4</b>
      </div>
      {pg4Details?.map((item, i) => {
        return (
          <div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 4 (4.1.3)
            </div>
            <PG4413aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 4 (4.1.4 & 4.4.1)
            </div>
            <PG4414aqar item={item} />

            <div className="col-4 text-center fs-5 fw-medium">
              Criteria : 4 (4.2.2 & 4.2.3)
            </div>
            <PG4422aqar item={item} />
          </div>
        );
      })}
    </div>
  );
});

export default PGC4aqar;
