import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Nav } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import { AiOutlineEye } from "react-icons/ai";
const UgCriteria4 = () => {
  let principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ================ Show doc1 modal  ===================

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================  Show doc2 modal ===================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [View, setView] = useState({});

  const navigate = useNavigate();

  const [Value, setValue] = useState({});
  const [UG_Criteria04, setUG_Criteria04] = useState([]);
  const ugCriteria04Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/LibraryAdmin/getugcriteria04"
      );
      if (res.status === 200) {
        setUG_Criteria04(res.data.ugcriteria04);
      }
    } catch (error) {
      console.log(error);
      setUG_Criteria04(error.response.data.ugcriteria04);
    }
  };

  const [SearchItem, setSearchItem] = useState("");
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [remark, setremark] = useState("");
  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/LibraryAdmin/approveCriteriaUG04/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria04Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      ugCriteria04Details();
    }
  }, []);

  console.log("UG_Criteria04", UG_Criteria04);
  return (
    <div>
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-04
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div>
            <Nav defaultActiveKey="">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/UGCri04413")}
                >
                  <Button className="criteriabtn btn-sub1"> 4.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UGCri04414")}
                >
                  <Button className="criteriabtn btn-sub">
                    {" "}
                    4.1.4 & 4.4.1
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UGCri04422")}
                >
                  <Button className="criteriabtn btn-sub">4.2.2 & 4.2.3</Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra ">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", width: "10000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>ID</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>
                    Room number or Name of Classrooms and Seminar Halls with
                    LCD/wifi/LAN facilities
                  </th>
                  <th>Type of ICT facility</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Budget Allocated for Infrastructure Augmentation (INR in
                    Lakhs)
                  </th>
                  <th>
                    Expenditure for Infrastructure Augmentation (INR in Lakhs)
                  </th>
                  <th>Total Expenditure Excluding Salary (INR in Lakh)</th>
                  <th>
                    Expenditure on Maintenance of Academic Facilities (Excluding
                    salary for Human Resources) (INR in Lakhs)
                  </th>
                  <th>
                    Expenditure on Maintenance of Physical Facilities (Excluding
                    Salary for Human Resources) (INR in Lakh)
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Library Resources</th>
                  <th>If yes, Details of Memberships/Subscriptions</th>
                  <th>
                    Expenditure on Subscription to E-Journals, E-Books (INR in
                    lakhs)
                  </th>
                  <th>Total Library Expenditure</th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {UG_Criteria04?.filter((item1) => {
                  let flag = 1;
                  if (FromDate && toDate) {
                    for (let i = FromDate; i <= toDate; i++) {
                      if (item1?.UG_Year?.includes(i.toString())) {
                        flag = 1;
                        break;
                      } else {
                        flag = 0;
                      }
                    }
                  }
                  if (
                    principalDetails?._id ===
                      item1?.addedByFac?.facultyAddedBy?._id &&
                    item1?.approve === "Pending" &&
                    (SearchItem === "" ||
                      item1?.UG_Criteria04_413?.UG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.UG_Criteria04_413?.UG_Room?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_413?.UG_Type?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_413?.UG_Desc1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_414?.UG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.UG_Criteria04_414?.UG_Budget?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_414?.UG_Expenditure?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_414?.UG_Total?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_414?.UG_Expend1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_414?.UG_Expend2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_414?.UG_Desc2?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_422?.UG_criteria?.toString()?.includes(
                        SearchItem
                      ) ||
                      item1?.UG_Criteria04_422?.UG_Library?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_422?.UG_IfYes?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_422?.UG_Expend3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_422?.UG_Total1?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      ) ||
                      item1?.UG_Criteria04_422?.UG_Desc3?.toLowerCase()?.includes(
                        SearchItem?.toLowerCase()
                      )) &&
                    flag
                  ) {
                    return true;
                  }
                  return false;
                }).map((item, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{item?._id}</td>
                      <td>{item?.UG_Year}</td>
                      <td>{item?.UG_Criteria04_413?.UG_criteria}</td>
                      <td>{item?.UG_Criteria04_413?.UG_Room}</td>
                      <td>{item?.UG_Criteria04_413?.UG_Type}</td>
                      <td>
                        {item?.UG_Criteria04_413?.UG_Desc1?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow4();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow1();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.UG_Criteria04_414?.UG_criteria}</td>
                      <td>{item?.UG_Criteria04_414?.UG_Budget}</td>
                      <td>{item?.UG_Criteria04_414?.UG_Expenditure}</td>
                      <td>{item?.UG_Criteria04_414?.UG_Total}</td>
                      <td>{item?.UG_Criteria04_414?.UG_Expend1}</td>
                      <td>{item?.UG_Criteria04_414?.UG_Expend2}</td>
                      <td>
                        {item?.UG_Criteria04_414?.UG_Desc2?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow5();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow2();
                          }}
                          fontSize={20}
                        />
                      </td>

                      <td>{item?.UG_Criteria04_422?.UG_criteria}</td>
                      <td>{item?.UG_Criteria04_422?.UG_Library}</td>
                      <td>{item?.UG_Criteria04_422?.UG_IfYes}</td>
                      <td>{item?.UG_Criteria04_422?.UG_Expend3}</td>
                      <td>{item?.UG_Criteria04_422?.UG_Total1}</td>
                      <td>
                        {item?.UG_Criteria04_422?.UG_Desc3?.slice(0, 70)}
                        ...
                        <Link
                          onClick={() => {
                            handleShow6();
                            setView(item);
                          }}
                        >
                          Read More...
                        </Link>
                      </td>
                      <td>
                        <AiOutlineEye
                          onClick={() => {
                            setView(item);
                            handleShow3();
                          }}
                          fontSize={20}
                        />
                      </td>
                      <td>
                        <div className="d-flex justify-content-evenly align-items-center">
                          {/* <i class="fa-solid fa-check fa-lg" style={{color: "#26a269"}}></i> */}
                          <i
                            class="fa-solid fa-eye fa-lg"
                            onClick={() => {
                              setValue(item);
                              handleShow();
                            }}
                            style={{ color: "#9a9996" }}
                          ></i>
                        </div>
                      </td>
                      <td>{item?.approve}</td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Show description modal */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.UG_Criteria04_413?.UG_Desc1}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.UG_Criteria04_414?.UG_Desc2}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show description modal */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>{" "}
          <Modal.Body>
            <div>
              <p> {View?.UG_Criteria04_422?.UG_Desc3}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose6}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Model */}
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton style={{ background: "#393186" }}>
            <Modal.Title>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold", color: "white" }}
              >
                UnderGraduate_Criteria-04
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-413
                </span>
              </div>
              <div className="row  m-0 ">
                <h6 className="text-center text-decoration-underline">
                  Average percentage of classrooms and seminar halls with ICT -
                  enabled facilities such as smart class, LMS, etc.
                </h6>

                <div className="col-md-12 mt-3">
                  <label className="content1">
                    {" "}
                    Room number or Name of Classrooms and Seminar halls with
                    LCD/wifi/LAN facilities
                  </label>{" "}
                  <>{Value?.UG_Criteria04_413?.UG_Room}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1"> Type of ICT facility</label>{" "}
                  <>{Value?.UG_Criteria04_413?.UG_Type}</>
                </div>

                <div className="col-md-12 mb-3">
                  <label className="content1"> Description</label>

                  <p>{Value?.UG_Criteria04_413?.UG_Desc1}</p>
                </div>

                <div className="col-md-12 mt-3">
                  <label className="content1">Document</label>

                  <>{Value?.UG_Criteria04_413?.UG_Link1}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-414 & 441
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    Budget allocated for infrastructure augmentation (INR in
                    Lakh)
                  </label>

                  <>{Value?.UG_Criteria04_414?.UG_Budget}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    Expenditure for infrastructure augmentation (INR in Lakh)
                  </label>

                  <>{Value?.UG_Criteria04_414?.UG_Expenditure}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    Total expenditure excluding Salary (INR in Lakh)
                  </label>

                  <>{Value?.UG_Criteria04_414?.UG_Total}</>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    Expenditure on maintenance of academic facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </label>

                  <>{Value?.UG_Criteria04_414?.UG_Expend1}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    Expenditure on maintenance of physical facilities (excluding
                    salary for human resources) (INR in Lakh)
                  </label>

                  <>{Value?.UG_Criteria04_414?.UG_Expend2}</>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    Description
                  </label>

                  <p>{Value?.UG_Criteria04_414?.UG_Desc2}</p>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="email">
                    Document
                  </label>

                  <>{Value?.UG_Criteria04_414?.UG_Link2}</>
                </div>
              </div>
            </div>

            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-422
                </span>
              </div>

              <div className="row  m-0 ">
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    Library resources
                  </label>

                  <>{Value?.UG_Criteria04_422?.UG_Library}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    If yes, details of memberships/subscriptions
                  </label>

                  <>{Value?.UG_Criteria04_422?.UG_IfYes}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    Expenditure on subscription to e-journals, e-books (INR in
                    lakhs)
                  </label>

                  <>{Value?.UG_Criteria04_422?.UG_Expend3}</>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="content1" htmlFor="name">
                    Total Library Expenditure
                  </label>

                  <>{Value?.UG_Criteria04_422?.UG_Total1}</>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    Description
                  </label>

                  <p>{Value?.UG_Criteria04_422?.UG_Desc3}</p>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    Document
                  </label>

                  <>{Value?.UG_Criteria04_422?.UG_Link3}</>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  className="btn-success m-2"
                  onClick={(e) => approveCriteria(e, true, Value?._id)}
                >
                  Approve
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        {/* show model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria4/${View?.UG_Criteria04_413?.UG_Link1}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                ></object>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>

        {/* show 1 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria4/${View?.UG_Criteria04_414?.UG_Link2}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                ></object>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>

        {/* show 2 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria4/${View?.UG_Criteria04_422?.UG_Link3}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>

        <Modal
          // size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              style={{ height: "200px" }}
              onChange={(e) => setremark(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => approveCriteria(e, false, Value?._id)}
            >
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UgCriteria4;
