import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Auto_Criteria007 = () => {
  const facultyDetails = JSON.parse(sessionStorage.getItem("Fuser"));
  const navigate = useNavigate();

  const [edit1, setedit1] = useState(false);
  const [add1, setadd1] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //post method for Auto_criteria_07
  const [Auto_MainYear7, setAuto_MainYear7] = useState("");

  const [Auto_Budget, setAuto_Budget] = useState("");
  const [Auto_Lighting, setAuto_Lighting] = useState("");
  const [Auto_PhyFac, setAuto_PhyFac] = useState("");
  const [Auto_Prolift, setAuto_Prolift] = useState("");
  const [Auto_Ramp, setAuto_Ramp] = useState("");
  const [Auto_Braille, setAuto_Braille] = useState("");
  const [Auto_RestRoom, setAuto_RestRoom] = useState("");
  const [Auto_Scribes, setAuto_Scribes] = useState("");
  const [Auto_SpecialSkills, setAuto_SpecialSkills] = useState("");
  const [Auto_AnyOther, setAuto_AnyOther] = useState("");
  const [Auto_Annual, setAuto_Annual] = useState("");
  const [Auto_NoOfIniti1, setAuto_NoOfIniti1] = useState("");
  const [Auto_NoOfIniti2, setAuto_NoOfIniti2] = useState("");
  const [Auto_Date1, setAuto_Date1] = useState("");
  const [Auto_DateDuration, setAuto_DateDuration] = useState("");
  const [Auto_Date2, setAuto_Date2] = useState("");
  const [Auto_Power, setAuto_Power] = useState("");
  const [Auto_TPower, setAuto_TPower] = useState("");
  const [Auto_ReEnergyS, setAuto_ReEnergyS] = useState("");
  const [Auto_ReEnergyG, setAuto_ReEnergyG] = useState("");
  const [Auto_EnergySup, setAuto_EnergySup] = useState("");
  const [Auto_PercentageLED, setAuto_PercentageLED] = useState("");
  const [Auto_PercentageLight, setAuto_PercentageLight] = useState("");
  const [Auto_NoOfParticipants, setAuto_NoOfParticipants] = useState("");
  const [Auto_NoOfActivities, setAuto_NoOfActivities] = useState("");
  const [Auto_TitlePro, setAuto_TitlePro] = useState("");
  const [Auto_NoOfParticipants1, setAuto_NoOfParticipants1] = useState("");
  const [Auto_Male, setAuto_Male] = useState("");
  const [Auto_Female, setAuto_Female] = useState("");
  const [Auto_Yea, setAuto_Yea] = useState("");
  const [Auto_Total, setAuto_Total] = useState("");
  const [Auto_PowRe, setAuto_PowRe] = useState("");
  const [Auto_InitiName, setAuto_InitiName] = useState("");
  const [Auto_DurInitiative, setAuto_DurInitiative] = useState("");
  const [Auto_Desc29, setAuto_Desc29] = useState("");
  const [Auto_Link29, setAuto_Link29] = useState("");
  const [Auto_Link30, setAuto_Link30] = useState("");
  const [Auto_Link31, setAuto_Link31] = useState("");

  const [obj1, setobj1] = useState({});

  const [allYears, setallYears] = useState([]);
  const getAllYears = async () => {
    try {
      let res = await axios.get(
        "https://brightnaac.co.in/api/admin/getallYears"
      );
      if (res.status === 200) {
        setallYears(res.data.allYears);
      }
    } catch (error) {
      console.log(error);
      setallYears(error.response.data.allYears);
    }
  };

  const getautocriteria7 = async () => {
    try {
      let am = sessionStorage.getItem("cat1");
      setobj1(JSON.parse(am));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getautocriteria7();
  }, []);

  const autocriteria7 = async () => {
    if (!Auto_MainYear7) {
      return alert("Please select the Year");
    }
    try {
      const config = {
        url: "/FacultyAdmin/autoadddetails07",
        method: "post",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "multipart/form-data" },
        data: {
          Auto_Year: Auto_MainYear7,

          Auto_Criteria07_007: obj1,
          Auto_Link29: Auto_Link29,
          Auto_Link30: Auto_Link30,
          Auto_Link31: Auto_Link31,

          addedByFac: facultyDetails?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          sessionStorage.removeItem("cat1");

          navigate("/autocriteria07");
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const cat1 = () => {
    try {
      const minwords = 500;
      const newText = Auto_Desc29;
      const wordsArr = newText?.trim()?.split(/\s+/);

      if (add1) {
        if (
          !Auto_Budget ||
          !Auto_Lighting ||
          !Auto_PhyFac ||
          !Auto_Prolift ||
          !Auto_Ramp ||
          !Auto_Braille ||
          !Auto_RestRoom ||
          !Auto_Scribes ||
          !Auto_SpecialSkills ||
          !Auto_AnyOther ||
          !Auto_Annual ||
          !Auto_NoOfIniti1 ||
          !Auto_NoOfIniti2 ||
          !Auto_Date1 ||
          !Auto_DateDuration ||
          !Auto_Date2 ||
          !Auto_Power ||
          !Auto_TPower ||
          !Auto_ReEnergyS ||
          !Auto_ReEnergyG ||
          !Auto_EnergySup ||
          !Auto_PercentageLED ||
          !Auto_PercentageLight ||
          !Auto_NoOfParticipants ||
          !Auto_NoOfActivities ||
          !Auto_TitlePro ||
          !Auto_NoOfParticipants1 ||
          !Auto_Male ||
          !Auto_Female ||
          !Auto_Yea ||
          !Auto_Total ||
          !Auto_PowRe ||
          !Auto_InitiName ||
          !Auto_DurInitiative ||
          !Auto_Desc29 ||
          !Auto_Link29 ||
          !Auto_Link30 ||
          !Auto_Link31
        ) {
          return alert("Please fill all the fields");
        } else if (wordsArr?.length < minwords) {
          return alert("There should be minimum 500 words!!!");
        } else {
          setadd1(false);
        }
      }
      console.log("nnnnnnnn,", Auto_Link29);
      console.log("mm,", Auto_Link30);
      console.log("ooo,", Auto_Link31);
      let obj = {
        Auto_criteria: "0.0.7",
        Auto_Budget: Auto_Budget,
        Auto_Lighting: Auto_Lighting,
        Auto_PhyFac: Auto_PhyFac,
        Auto_Prolift: Auto_Prolift,
        Auto_Ramp: Auto_Ramp,
        Auto_Braille: Auto_Braille,
        Auto_RestRoom: Auto_RestRoom,
        Auto_Scribes: Auto_Scribes,
        Auto_SpecialSkills: Auto_SpecialSkills,
        Auto_AnyOther: Auto_AnyOther,
        Auto_Annual: Auto_Annual,
        Auto_NoOfIniti1: Auto_NoOfIniti1,
        Auto_NoOfIniti2: Auto_NoOfIniti2,
        Auto_Date1: Auto_Date1,
        Auto_DateDuration: Auto_DateDuration,
        Auto_Date2: Auto_Date2,
        Auto_Power: Auto_Power,
        Auto_TPower: Auto_TPower,
        Auto_ReEnergyS: Auto_ReEnergyS,
        Auto_ReEnergyG: Auto_ReEnergyG,
        Auto_EnergySup: Auto_EnergySup,
        Auto_PercentageLED: Auto_PercentageLED,
        Auto_PercentageLight: Auto_PercentageLight,
        Auto_NoOfParticipants: Auto_NoOfParticipants,
        Auto_NoOfActivities: Auto_NoOfActivities,
        Auto_TitlePro: Auto_TitlePro,
        Auto_NoOfParticipants1: Auto_NoOfParticipants1,
        Auto_Male: Auto_Male,
        Auto_Female: Auto_Female,
        Auto_Yea: Auto_Yea,
        Auto_Total: Auto_Total,
        Auto_PowRe: Auto_PowRe,
        Auto_InitiName: Auto_InitiName,
        Auto_DurInitiative: Auto_DurInitiative,
        Auto_Desc29: Auto_Desc29,
        Auto_Link29: Auto_Link29,
        Auto_Link30: Auto_Link30,
        Auto_Link31: Auto_Link31,
      };
      sessionStorage.setItem("cat1", JSON.stringify(obj));
      alert("Successfully added cat1");
      getautocriteria7();
      setedit1(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!facultyDetails) {
      alert("Please Login first");
      window.location.assign("/flogin");
    } else {
      getAllYears();
    }
  }, []);
  return (
    <div>
      <div className="container">
        <div className="pt-4   " style={{ padding: "10px" }}>
          <span className="" style={{ fontSize: "19px", fontWeight: "bold" }}>
            {" "}
            Autonomous_Criteria-07
          </span>
        </div>
        <div className="container">
          <div
            className="row-fluid border bs"
            style={{
              padding: "30px",
            }}
          >
            <div class=" col-md-6">
              <label for="" class="form-label">
                Select Year
              </label>
              {obj1?.Auto_MainYear7 ? (
                <p>{obj1?.Auto_MainYear7}</p>
              ) : (
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  style={{ width: "270px" }}
                  onChange={(e) => setAuto_MainYear7(e.target.value)}
                >
                  <option selected>--Select Year--</option>
                  {allYears
                    ?.sort((a, b) => {
                      return Number(a?.from) - Number(b?.from);
                    })
                    ?.map((item) => {
                      return (
                        <option value={`${item?.from}-${item?.to}`}>
                          {item?.from} - {item?.to}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div
            className="row-fluid  border "
            style={{
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h5>Criteria_07</h5>

            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total Lighting requirements
                </label>
                {obj1?.Auto_Lighting ? (
                  <p>{obj1?.Auto_Lighting}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Lighting(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Budget allocated for green initiatives:
                </label>
                {obj1?.Auto_Budget ? (
                  <p>{obj1?.Auto_Budget}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Budget(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline mt-3">
              Status of implementation of CBCS / Elective Course System (ECS):
            </h6>
            <div className="row">
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Physical Facilities:
                </label>
                {obj1?.Auto_PhyFac ? (
                  <p>{obj1?.Auto_PhyFac}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setAuto_PhyFac(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Provision for Lift:
                </label>
                {obj1?.Auto_Prolift ? (
                  <p>{obj1?.Auto_Prolift}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setAuto_Prolift(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Ramp/ Rails:
                </label>
                {obj1?.Auto_Ramp ? (
                  <p>{obj1?.Auto_Ramp}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setAuto_Ramp(e.target.value);
                    }}
                  >
                    <option selected>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Braille Software/Facilities:
                </label>
                {obj1?.Auto_Braille ? (
                  <p>{obj1?.Auto_Braille}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setAuto_Braille(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Rest Rooms:{" "}
                </label>
                {obj1?.Auto_RestRoom ? (
                  <p>{obj1?.Auto_RestRoom}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setAuto_RestRoom(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Scribes for Examination
                </label>
                {obj1?.Auto_Scribes ? (
                  <p>{obj1?.Auto_Scribes}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setAuto_Scribes(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Special skill Development for Differently Abled Students:
                </label>
                {obj1?.Auto_SpecialSkills ? (
                  <p>{obj1?.Auto_SpecialSkills}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "593px" }}
                    onChange={(e) => {
                      setAuto_SpecialSkills(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
              <div className="col-md-4 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Any other Similar Facility:
                </label>
                {obj1?.Auto_AnyOther ? (
                  <p>{obj1?.Auto_AnyOther}</p>
                ) : (
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    style={{ width: "270px" }}
                    onChange={(e) => {
                      setAuto_AnyOther(e.target.value);
                    }}
                  >
                    <option selected>--Select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Annual expenditure excluding salary component of the institution
              </label>
              {obj1?.Auto_Annual ? (
                <p>{obj1?.Auto_Annual}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAuto_Annual(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Number of initiatives to address locational Advantages and
                Disadvantages
              </label>
              {obj1?.Auto_NoOfIniti1 ? (
                <p>{obj1?.Auto_NoOfIniti1}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAuto_NoOfIniti1(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Number of initiatives taken to Engage with and contribute to
                Local Community
              </label>
              {obj1?.Auto_NoOfIniti2 ? (
                <p>{obj1?.Auto_NoOfIniti2}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAuto_NoOfIniti2(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Date and Duration of the Initiative Name of the Initiative
                Issues Addressed
              </label>
              {obj1?.Auto_DateDuration ? (
                <p>{obj1?.Auto_DateDuration}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAuto_DateDuration(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="col-md-12 mt-3 ">
              <label className="content1" htmlFor="email">
                {" "}
                Power Requirement met by Renewable Energy Sources
              </label>
              {obj1?.Auto_Power ? (
                <p>{obj1?.Auto_Power}</p>
              ) : (
                <input
                  className="vi_0"
                  type="text"
                  name="email"
                  id="name"
                  onChange={(e) => {
                    setAuto_Power(e.target.value);
                  }}
                />
              )}
            </div>
            <div className="row">
              {" "}
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total Power requirements
                </label>
                {obj1?.Auto_TPower ? (
                  <p>{obj1?.Auto_TPower}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_TPower(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Renewable Energy Source:
                </label>
                {obj1?.Auto_ReEnergyS ? (
                  <p>{obj1?.Auto_ReEnergyS}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_ReEnergyS(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Renewable Energy Generated and Used :
                </label>
                {obj1?.Auto_ReEnergyG ? (
                  <p>{obj1?.Auto_ReEnergyG}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_ReEnergyG(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Energy Supplied to the Grid:
                </label>
                {obj1?.Auto_EnergySup ? (
                  <p>{obj1?.Auto_EnergySup}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_EnergySup(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Percentage Lighting through LED bulbs:
                </label>
                {obj1?.Auto_PercentageLED ? (
                  <p>{obj1?.Auto_PercentageLED}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_PercentageLED(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Percentage Lighting through other sources
                </label>
                {obj1?.Auto_PercentageLight ? (
                  <p>{obj1?.Auto_PercentageLight}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_PercentageLight(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of Participating Students and Staff:
                </label>
                {obj1?.Auto_NoOfParticipants ? (
                  <p>{obj1?.Auto_NoOfParticipants}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_NoOfParticipants(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  No. of Activities:
                </label>
                {obj1?.Auto_NoOfActivities ? (
                  <p>{obj1?.Auto_NoOfActivities}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_NoOfActivities(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  TTitle of the Program/Activity:
                </label>
                {obj1?.Auto_TitlePro ? (
                  <p>{obj1?.Auto_TitlePro}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_TitlePro(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of Participants:
                </label>
                {obj1?.Auto_NoOfParticipants1 ? (
                  <p>{obj1?.Auto_NoOfParticipants1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_NoOfParticipants1(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline mt-3">
              Date and Duration:
            </h6>
            <div className="row">
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  From:
                </label>
                {obj1?.Auto_Date1 ? (
                  <p>{obj1?.Auto_Date1}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Date1(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-6 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  To:
                </label>
                {obj1?.Auto_Date2 ? (
                  <p>{obj1?.Auto_Date2}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="date"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Date2(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <h6 className="text-center text-decoration-underline mt-3">
              Number of participants by Gender:
            </h6>
            <div className="row">
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  MALE:
                </label>
                {obj1?.Auto_Male ? (
                  <p>{obj1?.Auto_Male}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Male(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  FEMALE:
                </label>
                {obj1?.Auto_Female ? (
                  <p>{obj1?.Auto_Female}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Female(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Year
                </label>
                {obj1?.Auto_Yea ? (
                  <p>{obj1?.Auto_Yea}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Yea(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-3 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Total
                </label>
                {obj1?.Auto_Total ? (
                  <p>{obj1?.Auto_Total}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Total(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Power Requirement Renewable
                </label>
                {obj1?.Auto_PowRe ? (
                  <p>{obj1?.Auto_PowRe}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_PowRe(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Initiative names:
                </label>
                {obj1?.Auto_InitiName ? (
                  <p>{obj1?.Auto_InitiName}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_InitiName(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Duration_of_the_Initiative
                </label>
                {obj1?.Auto_DurInitiative ? (
                  <p>{obj1?.Auto_DurInitiative}</p>
                ) : (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_DurInitiative(e.target.value);
                    }}
                  />
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Description (min 500 words)
                </label>
                {obj1?.Auto_Desc29 ? (
                  <p>{obj1?.Auto_Desc29}</p>
                ) : (
                  <textarea
                    className="vi_0"
                    rows={3}
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Desc29(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload1">
                {" "}
                Document Upload Select file to upload:
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload1"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link29(e.target.files[0]);
                }}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload2">
                {" "}
                Document Upload Select file to upload:
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload2"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link30(e.target.files[0]);
                }}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="content1" htmlFor="upload3">
                {" "}
                Document Upload Select file to upload:
              </label>
              <br />
              <input
                className="vi_0"
                type="file"
                name="Year"
                id="upload3"
                accept="image/*"
                onChange={(e) => {
                  setAuto_Link31(e.target.files[0]);
                }}
              />
            </div>
            <div className="d-flex  " style={{ float: "right", gap: "10px" }}>
              {add1 ? (
                <button
                  type="submit"
                  className="btn btn-success mt-3 "
                  onClick={() => {
                    cat1();
                  }}
                >
                  ADD
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-warning"
                    onClick={handleShow}
                    style={{ color: "black", marginTop: "15px" }}
                  >
                    Preview
                  </button>
                </>
              )}{" "}
            </div>
            <br />
          </div>

          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Criteria_07</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Lighting requirements
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_Lighting(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_Lighting}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Budget allocated for green initiatives:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_Budget(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_Budget}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Status of implementation of CBCS / Elective Course System (ECS):
              </h6>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Physical Facilities:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setAuto_PhyFac(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Auto_PhyFac}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Provision for Lift:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setAuto_Prolift(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Auto_Prolift}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Ramp/ Rails:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setAuto_Ramp(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Auto_Ramp}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Braille Software/Facilities:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setAuto_Braille(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Auto_Braille}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Rest Rooms:{" "}
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setAuto_RestRoom(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Auto_RestRoom}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Scribes for Examination
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setAuto_Scribes(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Auto_Scribes}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Special skill Development for Differently Abled Students:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "500px" }}
                      onChange={(e) => {
                        setAuto_SpecialSkills(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Auto_SpecialSkills}</>
                  )}
                </div>
                <div className="col-md-4 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Any other Similar Facility:
                  </label>
                  <br />
                  {edit1 ? (
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example"
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setAuto_AnyOther(e.target.value);
                      }}
                    >
                      <option selected>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <>{Auto_AnyOther}</>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Annual expenditure excluding salary component of the
                  institution
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Annual(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_Annual}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives to address locational Advantages and
                  Disadvantages
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_NoOfIniti1(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_NoOfIniti1}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Number of initiatives taken to Engage with and contribute to
                  Local Community
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_NoOfIniti2(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_NoOfIniti2}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Date and Duration of the Initiative Name of the Initiative
                  Issues Addressed
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_DateDuration(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_DateDuration}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Power Requirement met by Renewable Energy Sources
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Power(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_Power}</>
                )}
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total Power requirements
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_TPower(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_TPower}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Source:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_ReEnergyS(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_ReEnergyS}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Renewable Energy Generated and Used :
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_ReEnergyG(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_ReEnergyG}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Energy Supplied to the Grid:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_EnergySup(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_EnergySup}</>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Percentage Lighting through other sources
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_PercentageLight(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_PercentageLight}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participating Students and Staff:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_NoOfParticipants(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_NoOfParticipants}</>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      Percentage Lighting through LED bulbs:
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_PercentageLED(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Auto_PercentageLED}</>
                    )}
                  </div>
                  <div className="col-md-6 mt-3 ">
                    <label className="content1" htmlFor="email">
                      {" "}
                      No. of Activities:
                    </label>
                    <br />
                    {edit1 ? (
                      <input
                        className="vi_0"
                        type="text"
                        name="email"
                        id="name"
                        onChange={(e) => {
                          setAuto_NoOfActivities(e.target.value);
                        }}
                      />
                    ) : (
                      <>{Auto_NoOfActivities}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    TTitle of the Program/Activity:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_TitlePro(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_TitlePro}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Number of Participants:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_NoOfParticipants1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_NoOfParticipants1}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Date and Duration:
              </h6>
              <div className="row">
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    From:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_Date1(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_Date1}</>
                  )}
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    To:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="date"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_Date2(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_Date2}</>
                  )}
                </div>
              </div>
              <h6 className="text-center text-decoration-underline mt-3">
                Number of participants by Gender:
              </h6>
              <div className="row">
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    MALE:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_Male(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_Male}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    FEMALE:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_Female(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_Female}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Year
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_Yea(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_Yea}</>
                  )}
                </div>
                <div className="col-md-3 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Total
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_Total(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_Total}</>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Power Requirement Renewable
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_PowRe(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_PowRe}</>
                  )}
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1" htmlFor="email">
                    {" "}
                    Initiative names:
                  </label>
                  <br />
                  {edit1 ? (
                    <input
                      className="vi_0"
                      type="text"
                      name="email"
                      id="name"
                      onChange={(e) => {
                        setAuto_InitiName(e.target.value);
                      }}
                    />
                  ) : (
                    <>{Auto_InitiName}</>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Duration_of_the_Initiative
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_DurInitiative(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_DurInitiative}</>
                )}
              </div>
              <div className="col-md-12 mt-3 ">
                <label className="content1" htmlFor="email">
                  {" "}
                  Description (min 50 words)
                </label>
                <br />
                {edit1 ? (
                  <textarea
                    className="vi_0"
                    rows={3}
                    type="text"
                    name="email"
                    id="name"
                    onChange={(e) => {
                      setAuto_Desc29(e.target.value);
                    }}
                  />
                ) : (
                  <>{Auto_Desc29}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload1">
                  {" "}
                  Document Upload Select file to upload:
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload1"
                    accept="image/*"
                    onChange={(e) => {
                      setAuto_Link29(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{Auto_Link29?.name}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload2">
                  {" "}
                  Document Upload Select file to upload:
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload2"
                    accept="image/*"
                    onChange={(e) => {
                      setAuto_Link30(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{Auto_Link30?.name}</>
                )}
              </div>
              <div className="col-md-12 mt-3">
                <label className="content1" htmlFor="upload3">
                  {" "}
                  Document Upload Select file to upload:
                </label>
                <br />
                {edit1 ? (
                  <input
                    className="vi_0"
                    type="file"
                    name="Year"
                    id="upload3"
                    accept="image/*"
                    onChange={(e) => {
                      setAuto_Link31(e.target.files[0]);
                    }}
                  />
                ) : (
                  <>{Auto_Link31?.name}</>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => setedit1(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-success"
                style={{ color: "white" }}
                onClick={() => {
                  cat1();
                  handleClose();
                }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <br />
        </div>
        <div className="text-center  mb-3">
          <button
            type="submit"
            className="btn btn-success "
            onClick={() => autocriteria7()}
            style={{ color: "white" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Auto_Criteria007;
