import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { DatePicker, Space } from "antd";
import Nav from "react-bootstrap/Nav";
import moment from "moment";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
const UgCriteria3 = () => {
  let principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  const [View, setView] = useState([]);

  // ================Show doc1 modal======================
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ================Show doc2 modal======================
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ================Show doc3 modal======================
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // ================Show doc4 modal======================
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ================Show doc5 modal======================
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // ================Show doc6 modal======================
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ================Show doc7 modal======================
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  // ================Show doc8 modal======================
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // ================Show doc9 modal======================
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);

  // ================Show doc10 modal======================
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  // ================Show doc11 modal======================
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);

  // ================Show doc12 modal======================
  const [show12, setShow12] = useState(false);
  const handleClose12 = () => setShow12(false);
  const handleShow12 = () => setShow12(true);

  // ================Show doc13 modal======================
  const [show13, setShow13] = useState(false);
  const handleClose13 = () => setShow13(false);
  const handleShow13 = () => setShow13(true);

  // ================Show doc14 modal======================
  const [show14, setShow14] = useState(false);
  const handleClose14 = () => setShow14(false);
  const handleShow14 = () => setShow14(true);

  // ================Show doc15 modal======================
  const [show15, setShow15] = useState(false);
  const handleClose15 = () => setShow15(false);
  const handleShow15 = () => setShow15(true);

  // ================Show doc16 modal======================
  const [show16, setShow16] = useState(false);
  const handleClose16 = () => setShow16(false);
  const handleShow16 = () => setShow16(true);

  // ================Show doc17 modal======================
  const [show17, setShow17] = useState(false);
  const handleClose17 = () => setShow17(false);
  const handleShow17 = () => setShow17(true);

  const navigate = useNavigate();
  const [remark, setremark] = useState("");
  const [SearchItem, setSearchItem] = useState("");
  const [FromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const onChangeFrom = (date, dateString) => {
    if (toDate !== "") {
      if (date?.$y > toDate) {
        return alert("Invalid date filteration!!!");
      } else {
        setFromDate(date?.$y);
      }
    } else {
      setFromDate(date?.$y);
    }
  };
  const onChangeTo = (date, dateString) => {
    if (FromDate !== "") {
      if (date?.$y < FromDate) {
        return alert("Invalid date filteration!!!");
      } else {
        settoDate(date?.$y);
      }
    } else {
      settoDate(date?.$y);
    }
  };

  const [Value, setValue] = useState({});
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // =================================================================
  const [ugcriteria03, setugcriteria03] = useState([]);
  const ugCriteria03Details = async () => {
    try {
      const res = await axios.get(
        "https://brightnaac.co.in/api/FacultyAdmin/getugcriteria03"
      );
      if (res.status === 200) {
        setugcriteria03(res.data.ugcriteria03);
      }
    } catch (error) {
      console.log(error);
      setugcriteria03(error.response.data.ugcriteria03);
    }
  };

  // ============================

  const approveCriteria = async (e, val, id) => {
    e.preventDefault();
    try {
      const config = {
        url: `/FacultyAdmin/approveCriteriaUG3/${id}`,
        method: "put",
        baseURL: "https://brightnaac.co.in/api",
        headers: { "content-type": "application/json" },
        data: {
          approve: val,
          remark: remark,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          ugCriteria03Details();
          setSmShow(false);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    principalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!principalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    } else {
      ugCriteria03Details();
    }
  }, []);
  // =================================================================
  console.log("ugcriteria03", ugcriteria03);

  return (
    <div>
      {" "}
      <div className="add-gr">
        <div className="container-fluid">
          <div className=" ad-b row justify-content-between align-items-center pt-4 pb-3">
            <div className="col-lg-12 ">
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                {" "}
                UnderGraduate_Criteria-03
              </span>
            </div>
          </div>

          <div className="ad-b  row align-items-center m-0">
            <div className=" col-lg-3 do-sear d-flex p-0 ">
              <input
                type="text"
                placeholder="Search"
                className="vi_0"
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>

            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                placeholder="Select Year (from)"
                onChange={onChangeFrom}
                picker="year"
                className="vi_0"
              />
            </div>
            <div className=" col-lg-2 lab p-0" style={{ marginLeft: "20px" }}>
              <DatePicker
                onChange={onChangeTo}
                placeholder="Select Year (to)"
                picker="year"
                className="vi_0"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end m-0 mt-4">
          <div>
            <Nav defaultActiveKey="">
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-1"
                  onClick={() => navigate("/UGCri03312")}
                >
                  <Button className="criteriabtn btn-sub1">3.1.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-2"
                  onClick={() => navigate("/UGCri03313")}
                >
                  <Button className="criteriabtn btn-sub">3.1.3</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-3"
                  onClick={() => navigate("/UGCri03321")}
                >
                  <Button className="criteriabtn btn-sub">3.2.1</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-4"
                  onClick={() => navigate("/UGCri03322")}
                >
                  <Button className="criteriabtn btn-sub">3.2.2</Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UGCri03332")}
                >
                  <Button style={{}} className="criteriabtn btn-sub">
                    {" "}
                    3.3.2
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UGCri03333")}
                >
                  <Button style={{}} className="criteriabtn btn-sub">
                    {" "}
                    3.3.3
                  </Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UGCri03341")}
                >
                  <Button style={{}} className="criteriabtn btn-sub">
                    {" "}
                    3.4.1
                  </Button>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="p-0"
                  eventKey="link-5"
                  onClick={() => navigate("/UGCri03342")}
                >
                  <Button style={{}} className="criteriabtn btn-sub">
                    {" "}
                    3.4.2
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="mo-gra">
            <Table
              responsive
              striped
              bordered
              hover
              style={{ textAlign: "center", minWidth: "10000px" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Id</th>
                  <th>Year</th>
                  <th>Criteria</th>
                  <th>Name of the Project/ Endowments, Chairs</th>
                  <th>Name of the Principal Investigator/Co-Investigator</th>
                  <th>Department of Principal Investigator</th>
                  <th>Year of Award</th>
                  <th>Amount Sanctioned</th>
                  <th>Duration of the project</th>
                  <th>Name of the Funding Agency</th>
                  <th>Type (Government/non-Government)</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Number of Participants</th>
                  <th>Date (From)</th>
                  <th>Date (To)</th>
                  <th>Name of the workshop/ seminar/ conference</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Title of paper</th>
                  <th>Name of the author/s</th>
                  <th>Department of the teacher</th>
                  <th>Name of journal</th>
                  <th>Year of publication</th>
                  <th>ISSN number</th>
                  <th>
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </th>
                  <th>Description</th>
                  <th>Document</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Name of the teacher</th>
                  <th>Title of the book/chapters published</th>
                  <th>Title of the paper</th>
                  <th>Title of the proceedings of the conference</th>
                  <th>Year of publication</th>
                  <th>ISBN/ISSN number of the proceeding</th>
                  <th>Name of the publisher</th>
                  <th>
                    Whether at the time of publication Affiliating Institution
                    Was same
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th> Name of the activity</th>
                  <th>Year of award</th>
                  <th>Name of the Award/ recognition for Institution</th>
                  <th>
                    Name of the Awarding government/ government recognised
                    bodies
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Name of the activity</th>
                  <th>Organising unit/ agency/ collaborating agency</th>
                  <th>Name of the scheme</th>
                  <th>Year of the activity</th>
                  <th>Number of students participated in such activities</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>Sl. No.</th>
                  <th>Title of the collaborative activity</th>
                  <th>
                    Name of the collaborating / linking agency with contact
                    details
                  </th>
                  <th>Name of the participant</th>
                  <th>Year of collaboration</th>
                  <th>Duration</th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>Criteria</th>
                  <th>
                    Name of the institution/ industry/ corporate house with whom
                    MoU is signed
                  </th>
                  <th>Year of signing MOU</th>
                  <th>Duration</th>
                  <th>
                    List the actual activities under each MOU and web links
                    year-wise
                  </th>
                  <th>Description</th>
                  <th>Document</th>

                  <th>View</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {ugcriteria03
                  .filter((item1) => {
                    let flag = 1;
                    if (FromDate && toDate) {
                      for (let i = FromDate; i <= toDate; i++) {
                        if (item1?.Year?.includes(i.toString())) {
                          flag = 1;
                          break;
                        } else {
                          flag = 0;
                        }
                      }
                    }
                    if (
                      principalDetails?._id ===
                        item1?.addedByFac?.facultyAddedBy?._id &&
                      (item1?.approve === "false" ||
                        item1?.approve === "Pending") &&
                      (SearchItem === "" ||
                        item1?.Criteria03_312?.ProName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_312?.PrinciName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_312?.PrinciDept?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_312?.YOfAw?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_312?.ASanctioned?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_312?.DProject?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_312?.NFunding?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_312?.Type?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_312?.Desc1?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_312?.Link1?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_313?.NPati?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_313?.FDate?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_313?.TDate?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_313?.NWorkshop?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_313?.Desc2?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_313?.Link2?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_321?.TPapaer?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_321?.AName?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_321?.PubliYear?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_321?.IISSN?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_321?.UUGC?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_321?.Desc3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_321?.Link3?.toString()?.includes(
                          SearchItem
                        ) ||
                        item1?.Criteria03_321?.Link4?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.SNo?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.NTea?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Title1?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Title2?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Title3?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.YPub?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.IIISBN?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.NPub?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.TimeWhether?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Desc4?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        ) ||
                        item1?.Criteria03_322?.Link5?.toLowerCase()?.includes(
                          SearchItem?.toLowerCase()
                        )) &&
                      flag
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .map((item, i) => {
                    return (
                      <tr>
                        <td>{++i}.</td>
                        <td>{item?._id}</td>
                        <td>{item?.Year}</td>
                        <td>{item?.Criteria03_312?.criteria}</td>
                        <td>{item?.Criteria03_312?.ProName}</td>
                        <td>{item?.Criteria03_312?.PrinciName}</td>
                        <td>{item?.Criteria03_312?.PrinciDept}</td>
                        <td>{item?.Criteria03_312?.YOfAw}</td>
                        <td>{item?.Criteria03_312?.ASanctioned}</td>
                        <td>{item?.Criteria03_312?.DProject}</td>
                        <td>{item?.Criteria03_312?.NFunding}</td>
                        <td>{item?.Criteria03_312?.Type}</td>
                        <td>
                          {item?.Criteria03_312?.Desc1?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow17();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow1();
                            }}
                            fontSize={20}
                          />
                        </td>
                        <td>{item?.Criteria03_313?.criteria}</td>
                        <td>{item?.Criteria03_313?.NPati}</td>
                        <td>
                          {moment(item?.Criteria03_313?.FDate).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>
                          {moment(item?.Criteria03_313?.TDate).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{item?.Criteria03_313?.NWorkshop}</td>

                        <td>
                          {item?.Criteria03_313?.Desc2?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow10();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow2();
                            }}
                            fontSize={20}
                          />
                        </td>
                        <td>{item?.Criteria03_321?.criteria}</td>
                        <td>{item?.Criteria03_321?.TPapaer}</td>
                        <td>{item?.Criteria03_321?.AName}</td>
                        <td>{item?.Criteria03_321?.TDept}</td>
                        <td>{item?.Criteria03_321?.JName}</td>
                        <td>{item?.Criteria03_321?.PubliYear}</td>
                        <td>{item?.Criteria03_321?.IISSN}</td>
                        <td>{item?.Criteria03_321?.UUGC}</td>

                        <td>
                          {item?.Criteria03_321?.Desc3?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow11();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow3();
                            }}
                            fontSize={20}
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow4();
                            }}
                            fontSize={20}
                          />
                        </td>
                        <td>{item?.Criteria03_322?.criteria}</td>
                        <td>{item?.Criteria03_322?.SNo}</td>
                        <td>{item?.Criteria03_322?.NTea}</td>
                        <td>{item?.Criteria03_322?.Title1}</td>
                        <td>{item?.Criteria03_322?.Title2}</td>
                        <td>{item?.Criteria03_322?.Title3}</td>
                        <td>{item?.Criteria03_322?.YPub}</td>
                        <td>{item?.Criteria03_322?.IIISBN}</td>
                        <td>{item?.Criteria03_322?.NPub}</td>
                        <td>{item?.Criteria03_322?.TimeWhether}</td>

                        <td>
                          {item?.Criteria03_322?.Desc4?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow12();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow5();
                            }}
                            fontSize={20}
                          />
                        </td>
                        <td>{item?.Criteria03_332?.criteria}</td>
                        <td>{item?.Criteria03_332?.ActiN}</td>
                        <td>{item?.Criteria03_332?.YearA}</td>
                        <td>{item?.Criteria03_332?.INAme}</td>
                        <td>{item?.Criteria03_332?.Bodies}</td>

                        <td>
                          {item?.Criteria03_332?.Desc5?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow13();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow6();
                            }}
                            fontSize={20}
                          />
                        </td>
                        <td>{item?.Criteria03_333?.criteria}</td>
                        <td>{item?.Criteria03_333?.NaActi}</td>
                        <td>{item?.Criteria03_333?.Organ}</td>
                        <td>{item?.Criteria03_333?.SchName}</td>
                        <td>{item?.Criteria03_333?.ActiYear}</td>
                        <td>{item?.Criteria03_333?.NStudents}</td>

                        <td>
                          {item?.Criteria03_333?.Desc6?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow14();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow7();
                            }}
                            fontSize={20}
                          />
                        </td>
                        <td>{item?.Criteria03_341?.criteria}</td>
                        <td>{item?.Criteria03_341?.SlNo}</td>
                        <td>{item?.Criteria03_341?.Collo}</td>
                        <td>{item?.Criteria03_341?.NCollo}</td>
                        <td>{item?.Criteria03_341?.NameParti}</td>
                        <td>{item?.Criteria03_341?.ColloYear}</td>
                        <td>{item?.Criteria03_341?.Dur}</td>

                        <td>
                          {item?.Criteria03_341?.Desc7?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow15();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow8();
                            }}
                            fontSize={20}
                          />
                        </td>
                        <td>{item?.Criteria03_342?.criteria}</td>
                        <td>{item?.Criteria03_342?.NMou}</td>
                        <td>{item?.Criteria03_342?.Mou}</td>
                        <td>{item?.Criteria03_342?.Durat}</td>
                        <td>{item?.Criteria03_342?.List}</td>

                        <td>
                          {item?.Criteria03_342?.Desc8?.slice(0, 70)}
                          ...
                          <Link
                            onClick={() => {
                              handleShow16();
                              setView(item);
                            }}
                          >
                            Read More...
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <AiOutlineEye
                            onClick={() => {
                              setView(item);
                              handleShow9();
                            }}
                            fontSize={20}
                          />
                        </td>

                        <td>
                          <div className="d-flex justify-content-evenly align-items-center">
                            {/* <i class="fa-solid fa-check fa-lg" style={{color: "#26a269"}}></i> */}
                            <i
                              class="fa-solid fa-eye fa-lg"
                              onClick={() => {
                                setValue(item);
                                handleShow();
                              }}
                              style={{ color: "#9a9996" }}
                            ></i>
                          </div>
                        </td>
                        <td>
                          {item?.approve === "true" ? (
                            <div style={{ color: "green" }}>Approved</div>
                          ) : (
                            <>
                              {item?.approve === "false" ? (
                                <div style={{ color: "red" }}>
                                  {" "}
                                  Not Approved
                                </div>
                              ) : (
                                <div> Pending</div>
                              )}
                            </>
                          )}
                        </td>
                        <td>{item?.remark}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
        {/* Show description modal */}
        <Modal show={show17} onHide={handleClose17}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.Criteria03_312?.Desc12}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose17}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show10} onHide={handleClose10}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.Criteria03_313?.Desc13}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose10}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show11} onHide={handleClose11}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria03_321?.Desc14}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose11}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show12} onHide={handleClose12}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.Criteria03_322?.Desc15}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose12}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show13} onHide={handleClose13}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.Criteria03_332?.Desc16}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose13}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show14} onHide={handleClose14}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria03_333?.Desc17}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose14}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show15} onHide={handleClose15}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p> {View?.Criteria03_341?.Desc18}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose15}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Show description modal */}
        <Modal show={show16} onHide={handleClose16}>
          <Modal.Header closeButton>
            <Modal.Title>Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>{View?.Criteria03_342?.Desc19}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose16}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* show 1 model */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_312?.Link12}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 2 model */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_313?.Link13}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 3 model */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_321?.Link14}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 4 model */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_321?.Link15}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 5 model */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_322?.Link16}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>{" "}
        {/* show 6 model */}
        <Modal show={show6} onHide={handleClose6}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_332?.Link17}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 7 model */}
        <Modal show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_333?.Link18}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 8 model */}
        <Modal show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_341?.Link19}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* show 9 model */}
        <Modal show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title>Relevant Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <object
                  width="100%"
                  height="400"
                  data={`https://brightnaac.co.in/UG_Criteria03/${View?.Criteria03_342?.Link20}`}
                  type={
                    "image/png" ||
                    "application/pdf" ||
                    "image/jpg" ||
                    "image/jpeg"
                  }
                >
                  {" "}
                </object>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className=""
        >
          <Modal.Header closeButton style={{ background: "#393186" }}>
            <Modal.Title>
              <span
                className=""
                style={{ fontSize: "19px", fontWeight: "bold", color: "white" }}
              >
                UnderGraduate_Criteria-03
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.2
                </span>
              </div>
              <div className="row  m-0 ">
                {" "}
                <div className="col-lg-6 mt-3">
                  <label className="content1">
                    Name of the Principal Investigator/Co-Investigator
                  </label>{" "}
                  <br />
                  <>{Value?.Criteria03_312?.ProName}</>
                </div>
                <div className="col-lg-6 mt-3 ">
                  <label className="content1">
                    Name of the Project/ Endowments, Chairs
                  </label>{" "}
                  <br />
                  <>
                    <>{Value?.Criteria03_312?.PrinciName}</>
                  </>
                </div>
                <div className="col-lg-6 mt-3 ">
                  <label className="content1">
                    Department of Principal Investigator
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_312?.PrinciDept}</>
                  </>
                </div>
                <div className="col-lg-6 mt-3 ">
                  <label className="content1"> Year of Award</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_312?.YOfAw}</>
                  </>
                </div>
                <div className="col-lg-6 mt-3 ">
                  <label className="content1">Amount Sanctioned</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_312?.ASanctioned}</>
                  </>
                </div>
                <div className="col-lg-6 mt-3 ">
                  <label className="content1">Duration of the project</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_312?.DProject}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Name of the Funding Agency</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_312?.NFunding}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">
                    Type (Government/non-Government)
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_312?.Type}</>
                  </>
                </div>
                <div className="col-lg-12 mt-3 ">
                  <label className="content1">Description</label>
                  <br />
                  <>
                    <p>{Value?.Criteria03_312?.Desc12}</p>
                  </>
                </div>
                <div className="col-lg-12 mt-3 ">
                  <label className="content1">Document</label>
                  <br />

                  <>
                    <>{Value?.Criteria03_312?.Link12}</>
                  </>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.1.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 ">
                  <label className="content1">Number of Participants</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_313?.NPati}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Date (From)</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_313?.FDate}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Date (To)</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_313?.TDate}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    Name of the workshop/ seminar/ conference
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_313?.NWorkshop}</>
                  </>
                </div>

                <div className="col-lg-12 mt-3 ">
                  <label className="content1">Description</label>
                  <br />
                  <>
                    <p>{Value?.Criteria03_313?.Desc13}</p>
                  </>
                </div>

                <div className="col-lg-12 mt-3 ">
                  <label className="content1">Document</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_313?.Link13}</>
                  </>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.2.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Title of paper</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_321?.TPapaer}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Name of the author/s</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_321?.AName}</>
                  </>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    {" "}
                    Department of the teacher
                  </label>

                  <>{Value?.Criteria03_321?.TDept}</>
                </div>
                <div className="col-md-6 mt-3">
                  <label className="content1" htmlFor="name">
                    Name of journal
                  </label>
                  <>{Value?.Criteria03_321?.JName}</>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Year of publication</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_321?.PubliYear}</>
                  </>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1">ISSN number</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_321?.IISSN}</>
                  </>
                </div>

                <div className="col-lg-12 mt-3 ">
                  <label className="content1">
                    {" "}
                    Is it listed in UGC Care list/Scopus/Web of Science/other,
                    mention
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_321?.UUGC}</>
                  </>
                </div>

                <div className="col-lg-12 mt-3 ">
                  <label className="content1">Description</label>
                  <br />
                  <>
                    <p>{Value?.Criteria03_321?.Desc14}</p>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    Upload the website of the Journal
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_321?.Link14}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    Upload the article/paper/abstract of the article
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_321?.Link15}</>
                  </>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.2.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Sl. No.</label>
                  <br />
                  <>
                    remark
                    <>{Value?.Criteria03_322?.SNo}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Name of the teacher</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_322?.NTea}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">
                    Title of the book/chapters published
                  </label>

                  <>
                    <>{Value?.Criteria03_322?.Title1}</>
                  </>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1">Title of the paper</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_322?.Title2}</>
                  </>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1">
                    Title of the proceedings of the conference
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_322?.Title3}</>
                  </>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label classNaremarkme="content1">Year of publication</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_322?.YPub}</>
                  </>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1">
                    ISBN/ISSN number of the proceeding
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_322?.IIISBN}</>
                  </>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1">Name of the publisher</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_322?.NPub}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    Whether at the time of publication Affiliating
                    InstitutionWas same
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_322?.TimeWhether}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">Description</label>
                  <br />
                  <>
                    <p>{Value?.Criteria03_322?.Desc15}</p>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Document</label>

                  <>
                    <p>{Value?.Criteria03_322?.Link16}</p>
                  </>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Name of the activity</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_332?.ActiN}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Year of award</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_332?.YearA}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    Name of the Award/ recognition for Institution{" "}
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_332?.INAme}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    {" "}
                    Name of the Awarding government/ government recognised
                    bodies
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_332?.Bodies}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">Description</label>
                  <br />
                  <>
                    <p>{Value?.Criteria03_332?.Desc16}</p>
                  </>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1">Document</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_332?.Link17}</>
                  </>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.3.3
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 ">
                  <label className="content1">Name of the activity</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_333?.NaActi}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    Organising unit/ agency/ collaborating agency
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_333?.Organ}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1">Name of the scheme</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_333?.SchName}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1"> Year of the activity</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_333?.ActiYear}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    Number of students participated in such activities
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_333?.NStudents}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">Description</label>
                  <br />
                  <>
                    <p>{Value?.Criteria03_333?.Desc17}</p>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">Document</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_333?.Link18}</>
                  </>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.1
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 ">
                  <label className="content1">Sl. No.</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_341?.SlNo}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    Title of the collaborative activity
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_341?.Collo}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    {" "}
                    Name of the collaborating / linking agency with contact
                    details
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_341?.NCollo}</>
                  </>
                </div>
                <div className="col-md-12 mt-3 ">
                  <label className="content1"> Name of the participant</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_341?.NameParti}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">Year of collaboration</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_341?.ColloYear}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">Duration</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_341?.Dur}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">Description</label>
                  <br />
                  <>
                    <p>{Value?.Criteria03_341?.Desc18}</p>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">Document</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_341?.Link19}</>
                  </>
                </div>
              </div>
            </div>
            <div className="cri623">
              <div className="text-center mb-3">
                <span
                  className=""
                  style={{ fontSize: "19px", fontWeight: "bold" }}
                >
                  Sub_Criteria-3.4.2
                </span>
              </div>
              <div className="row  m-0 ">
                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    Name of the institution/ industry/ corporate house with whom
                    MoU is signed
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_342?.NMou}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1">Year of signing MOU</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_342?.Mou}</>
                  </>
                </div>
                <div className="col-md-6 mt-3 ">
                  <label className="content1"> Duration</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_342?.Durat}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">
                    {" "}
                    List the actual activities under each MOU and web links
                    year-wise
                  </label>
                  <br />
                  <>
                    <>{Value?.Criteria03_342?.List}</>
                  </>
                </div>

                <div className="col-md-12 mt-3 ">
                  <label className="content1">Description</label>
                  <br />
                  <>
                    <p>{Value?.Criteria03_342?.Desc19}</p>
                  </>
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="content1">Document</label>
                  <br />
                  <>
                    <>{Value?.Criteria03_342?.Link20}</>
                  </>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Value?.approve === "false" ? (
              <></>
            ) : (
              <div>
                <Button
                  className="btn-success"
                  onClick={(e) => approveCriteria(e, true, Value?._id)}
                >
                  Approve{" "}
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => setSmShow(true)}
                  className="me-2"
                >
                  Reject
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {
          // remarks model
        }
        <Modal
          // size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Tell me the reason?..
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              onChange={(e) => setremark(e.target.value)}
              style={{ height: "200px" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => approveCriteria(e, false, Value?._id)}>
              Submit{" "}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UgCriteria3;
