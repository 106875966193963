import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const PDFprint = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
  let finalArr = [];
  if (location?.state) {
    finalArr = JSON.parse(JSON.stringify(location?.state));
    finalArr?.pop();
  }

  // to print the pdf ----->
  const createPDF = async () => {
    // setRotate(360);

    // dynamic image is also adding in the PDF
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"), {
      useCORS: true,
    });
    console.log("hhhh", data);
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("1.1.3.pdf");
  };

  useEffect(() => {
    pricipalDetails = JSON.parse(sessionStorage.getItem("Puser"));
    if (!pricipalDetails) {
      alert("Please login first");
      window.location.assign("/login");
    }
  }, []);

  if (location?.state == null) {
    navigate("/UGCri01113");
  }
  console.log(location, "vvvvv");
  return (
    <div className="m-auto">
      <div id="pdf">
        <div className="">
          <div className="text-center">
            <img
              src={`https://brightnaac.co.in/register/${pricipalDetails?.CollegeLogo}`}
              alt="img"
              className=" pdfimg pt-2 "
            />
            <h5 className="mt-2">{pricipalDetails?.HeiName}</h5>
          </div>
          <div className="row align-items-center m-0 p-3">
            <div className="col-4  text-center fs-5 fw-medium">
              Year :{" "}
              {location?.state == null ? (
                <></>
              ) : (
                <>{location?.state["0"]?.Year}</>
              )}
            </div>
            <div className="col-4 text-center fs-5 fw-medium">
              Criteria :{" "}
              {location?.state == null ? (
                <></>
              ) : (
                <>{location?.state[location?.state?.length - 1]?.criteria}</>
              )}
            </div>
            <div className="col-4 text-center fs-5 fw-medium">
              Sub-Criteria :{" "}
              {location?.state == null ? (
                <></>
              ) : (
                <>{location?.state["0"]?.Criteria01_113?.criteria}</>
              )}
            </div>
          </div>
          <div>
            {finalArr?.map((item, i) => {
              return (
                <div
                  className="container-fluid report-pdf mt-5"
                  style={{ padding: "0px 100px" }}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Name of the teacher participated</span>
                    </div>
                    <div className="col-lg-6 report-text   mb-3">
                      <span>{item?.Criteria01_113?.TeacherPar}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span>
                        Name of the body in which full time teacher participated
                      </span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>{item?.Criteria01_113?.BodyName}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Description</span>
                    </div>
                    <div className="col-lg-12 report-text  mb-3">
                      <span> {item?.Criteria01_113?.Desc1}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 report-head  mb-3">
                      <span> Document</span>
                    </div>
                    <div className="col-lg-6 report-text  mb-3">
                      <span>
                        <a href={`${item?.Criteria01_113?.Link1}`}>
                          {item?.Criteria01_113?.Link1}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="text-center">
        <Button className="ad" style={{}} onClick={createPDF}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default PDFprint;
